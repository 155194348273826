<form fxLayout="column" fxLayoutGap="12px" [formGroup]="form">
  <div fxLayout="column" fxLayoutGap="4px">
    <app-tag-selector
      formControlName="categories"
      [label]="'CATEGORY_PREFERENCES_FORM.LABEL' | translate"
      [options]="categoryOptionsService.options$ | async"
    ></app-tag-selector>
    <mat-error class="typography-body-2" *ngIf="form.dirty && form.get('categories').errors?.required">
      {{'CATEGORY_PREFERENCES_FORM.ERROR.MIN' | translate}}
    </mat-error>
    <mat-error class="typography-body-2" *ngIf="form.dirty && form.get('categories').errors?.maxlength">
      {{'CATEGORY_PREFERENCES_FORM.ERROR.MAX' | translate}}
    </mat-error>
  </div>

  <div *ngIf="!hideButton" fxLayout="row" fxLayoutAlign="flex-end center">
    <button mat-button class="syncee-blue-button" (click)="handleSubmit()">
      {{'CATEGORY_PREFERENCES_FORM.SAVE' | translate}}
    </button>
  </div>
</form>
