import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SetUpAccountFormInputNames } from './model/set-up-account-form-input-names';
import { SetUpAccountFormValue } from './model/set-up-account-form-value';
import { CountryPhoneInputComponent } from '../country-phone-input/country-phone-input.component';
import { PhoneInputFormFieldComponent } from '../phone-input-form-field/phone-input-form-field.component';
import { SingleCountrySelectorComponent } from '../single-country-selector/single-country-selector.component';
import { SingleCountrySelectorFormFieldComponent } from '../single-country-selector-form-field/single-country-selector-form-field.component';
import { customPhoneInputValidator } from '../phone-input-form-field/custom-phone-input.validator';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../../service/user/user';
import { UpdateUserStart, UserActionTypes } from '../../../store/user/user.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { PreferenceNameEnum } from '../../../vo/Preferences/preference-name.enum';
import { PreferenceStoreService } from '../../../service/preference/preference.store.service';
import { SupplierDetailsService } from '../../../service/suppliers/supplier-details.service';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SupplierHeadquartersService } from '../../../service/headquarters/supplier-headquarters.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { PreferencesActionTypes } from 'app/store/preferences/preferences.action';
import { RolesEnum } from '../../../vo/roles/roles';
import { CountryCodeNameMapperService } from '../../../service/country-code-name-mapper.service';
import { PhoneNumberModel } from '../country-phone-input/phone-number-model';

@Component({
  selector: 'app-set-up-account-form',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    CountryPhoneInputComponent,
    PhoneInputFormFieldComponent,
    SingleCountrySelectorComponent,
    SingleCountrySelectorFormFieldComponent,
    TranslateModule,
  ],
  templateUrl: './set-up-account-form.component.html',
  styleUrls: ['./set-up-account-form.component.scss'],
})
export class SetUpAccountFormComponent implements OnInit, OnDestroy {
  @Input() accountType: RolesEnum[];
  @Input() labelled = false;
  @Input() fieldsToHide?: SetUpAccountFormInputNames[] = [];
  @Input() value: SetUpAccountFormValue;
  @Output() formValueChange = new EventEmitter<SetUpAccountFormValue>();
  inputNames = SetUpAccountFormInputNames;
  roles = RolesEnum;
  setUpAccountFormGroup: FormGroup;
  supplierExcists = false;

  ltMd: boolean;
  private unsubscribeAll: Subject<void>;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private preferenceStoreService: PreferenceStoreService,
    private supplierDetailsService: SupplierDetailsService,
    private supplierHeadquartersService: SupplierHeadquartersService,
    private actions$: Actions,
    private screenManagerService: ScreenManagerService,
    private countryCodeNameMapperService: CountryCodeNameMapperService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (!!this.value) {
      this.setUpAccountFormGroup.setValue({ ...this.value });
    }
    if (!!this.value.supplierPhoneNumber && !!this.value.supplierCountry) {
      this.supplierExcists = true;
    }
    this.handleFormValueChange();
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), takeUntil(this.unsubscribeAll))
      .subscribe((matches) => (this.ltMd = matches));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  saveUserDetails(): Observable<void> {
    const saveObservables = [
      ...(this.isUserPhoneAndNameDirty ? [this.saveUserPhoneAndName()] : []),
      ...(this.isUserCountryDirty ? [this.saveUserCountry()] : []),
    ];
    return combineLatest(saveObservables).pipe(map(() => {}));
  }

  private saveUserPhoneAndName(): Observable<void> {
    const firstName = this.firstName.dirty ? this.firstName.getRawValue() : undefined;
    const lastName = this.lastName.dirty ? this.lastName.getRawValue() : undefined;
    const phoneNumber = this.retailerPhoneNumber.dirty ? this.retailerPhoneNumber.getRawValue().phoneNumber : undefined;
    const phoneCode = this.retailerPhoneNumber.dirty ? this.retailerPhoneNumber.getRawValue().countryCode : undefined;
    const data: Partial<User> = {
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber,
      phoneCode: phoneCode,
    };
    this.store.dispatch(new UpdateUserStart({ user: data }));

    return this.actions$.pipe(ofType(UserActionTypes.UPDATE_USER_SUCCESS));
  }

  private saveUserCountry(): Observable<void> {
    const selectedCountry = this.retailerCountry.getRawValue();
    this.preferenceStoreService.savePreferencesByName(PreferenceNameEnum.MY_LOCATION, [
      { preferenceValue: this.countryCodeNameMapperService.mapCountryCodeToName(selectedCountry) },
    ]);

    return this.actions$.pipe(ofType(PreferencesActionTypes.SAVE_PREFERENCES_SUCCESS));
  }

  saveSupplierDetails(): Observable<void> {
    const saveObservables = [
      ...(this.isSupplierNameDirty ? [this.saveName()] : []),
      ...(this.isSupplierPhoneDirty ? [this.savePhone()] : []),
      ...(this.isSupplierCountryDirty ? [this.saveHeadquarters()] : []),
    ];
    return combineLatest(saveObservables).pipe(map(() => {}));
  }

  private saveName(): Observable<void> {
    const firstName = this.firstName.dirty ? this.firstName.getRawValue() : undefined;
    const lastName = this.lastName.dirty ? this.lastName.getRawValue() : undefined;
    const data: Partial<User> = {
      firstName: firstName,
      lastName: lastName,
    };
    this.store.dispatch(new UpdateUserStart({ user: data }));

    return this.actions$.pipe(ofType(UserActionTypes.UPDATE_USER_SUCCESS));
  }

  private savePhone(): Observable<void> {
    const phoneNumber: PhoneNumberModel = this.setUpAccountFormGroup.get(
      SetUpAccountFormInputNames.SUPPLIER_PHONE_NUMBER
    ).value;

    return this.store.select(getCurrentUserIdSelector).pipe(
      take(1),
      switchMap((userId) =>
        this.supplierDetailsService.save(
          {
            phoneNumber: phoneNumber.phoneNumber,
            phoneCode: phoneNumber.countryCode,
            userId,
          },
          this.supplierExcists
        )
      )
    );
  }

  private saveHeadquarters(): Observable<void> {
    const country = this.setUpAccountFormGroup.get(SetUpAccountFormInputNames.SUPPLIER_COUNTRY).value;

    return this.supplierExcists
      ? this.supplierHeadquartersService.update(country)
      : this.supplierHeadquartersService.save(country);
  }

  private initFormGroup(): void {
    if (this.accountType.includes(RolesEnum.SUPPLIER) && this.accountType.includes(RolesEnum.RETAILER)) {
      this.setUpAccountFormGroup = this.formBuilder.group({
        [this.inputNames.FIRST_NAME]: ['', Validators.required],
        [this.inputNames.LAST_NAME]: ['', Validators.required],
        [this.inputNames.RETAILER_COUNTRY]: ['', Validators.required],
        [this.inputNames.RETAILER_PHONE_NUMBER]: [
          { countryCode: '', phoneNumber: '' },
          [customPhoneInputValidator(), Validators.required],
        ],
        [this.inputNames.CONTACT_EMAIL]: new FormControl({ value: '', disabled: true }),
        [this.inputNames.SUPPLIER_COUNTRY]: ['', Validators.required],
        [this.inputNames.SUPPLIER_PHONE_NUMBER]: [
          { countryCode: '', phoneNumber: '' },
          [customPhoneInputValidator(), Validators.required],
        ],
      });
    } else if (this.accountType.includes(RolesEnum.RETAILER) && !this.accountType.includes(RolesEnum.SUPPLIER)) {
      this.setUpAccountFormGroup = this.formBuilder.group({
        [this.inputNames.FIRST_NAME]: ['', Validators.required],
        [this.inputNames.LAST_NAME]: ['', Validators.required],
        [this.inputNames.RETAILER_COUNTRY]: ['', Validators.required],
        [this.inputNames.RETAILER_PHONE_NUMBER]: [
          { countryCode: '', phoneNumber: '' },
          [customPhoneInputValidator(), Validators.required],
        ],
      });
    } else {
      this.setUpAccountFormGroup = this.formBuilder.group({
        [this.inputNames.FIRST_NAME]: ['', Validators.required],
        [this.inputNames.LAST_NAME]: ['', Validators.required],
        [this.inputNames.CONTACT_EMAIL]: new FormControl({ value: '', disabled: true }),
        [this.inputNames.SUPPLIER_COUNTRY]: ['', Validators.required],
        [this.inputNames.SUPPLIER_PHONE_NUMBER]: [
          { countryCode: '', phoneNumber: '' },
          [customPhoneInputValidator(), Validators.required],
        ],
      });
    }
  }

  get firstName(): AbstractControl<string> {
    return this.setUpAccountFormGroup.get<string>(this.inputNames.FIRST_NAME);
  }

  get lastName(): AbstractControl<string> {
    return this.setUpAccountFormGroup.get(this.inputNames.LAST_NAME);
  }

  get contactEmail(): AbstractControl<string> {
    return this.setUpAccountFormGroup.get(this.inputNames.CONTACT_EMAIL);
  }

  get supplierCountry(): AbstractControl<string> {
    return this.setUpAccountFormGroup.get(this.inputNames.SUPPLIER_COUNTRY);
  }

  get supplierPhoneNumber(): AbstractControl<PhoneNumberModel> {
    return this.setUpAccountFormGroup.get(this.inputNames.SUPPLIER_PHONE_NUMBER);
  }

  get retailerCountry(): AbstractControl<string> {
    return this.setUpAccountFormGroup.get(this.inputNames.RETAILER_COUNTRY);
  }

  get retailerPhoneNumber(): AbstractControl<PhoneNumberModel> {
    return this.setUpAccountFormGroup.get(this.inputNames.RETAILER_PHONE_NUMBER);
  }

  get formValue(): SetUpAccountFormValue {
    return this.setUpAccountFormGroup.getRawValue();
  }

  get isValid(): boolean {
    return this.setUpAccountFormGroup.valid;
  }

  get isDirty(): boolean {
    return this.setUpAccountFormGroup.dirty;
  }

  get isUserPhoneAndNameDirty(): boolean {
    return (
      this.setUpAccountFormGroup.get(this.inputNames.FIRST_NAME).dirty ||
      this.setUpAccountFormGroup.get(this.inputNames.LAST_NAME).dirty ||
      this.setUpAccountFormGroup.get(this.inputNames.RETAILER_PHONE_NUMBER).dirty
    );
  }

  get isUserCountryDirty(): boolean {
    return this.setUpAccountFormGroup.get(this.inputNames.RETAILER_COUNTRY).dirty;
  }

  get isUserDirty(): boolean {
    return this.isUserPhoneAndNameDirty || this.isUserCountryDirty;
  }

  get isSupplierNameDirty(): boolean {
    return (
      (this.setUpAccountFormGroup.get(this.inputNames.FIRST_NAME).dirty ||
        this.setUpAccountFormGroup.get(this.inputNames.LAST_NAME).dirty) &&
      !this.accountType.includes(RolesEnum.RETAILER)
    );
  }

  get isSupplierPhoneDirty(): boolean {
    return this.setUpAccountFormGroup.get(this.inputNames.SUPPLIER_PHONE_NUMBER).dirty;
  }

  get isSupplierCountryDirty(): boolean {
    return this.setUpAccountFormGroup.get(this.inputNames.SUPPLIER_COUNTRY).dirty;
  }

  get isSupplierDirty(): boolean {
    return this.isSupplierCountryDirty || this.isSupplierPhoneDirty || this.isSupplierNameDirty;
  }

  get form(): FormGroup {
    return this.setUpAccountFormGroup;
  }

  get isRetailer(): boolean {
    return this.accountType.includes(RolesEnum.RETAILER);
  }

  get isSupplier(): boolean {
    return this.accountType.includes(RolesEnum.SUPPLIER);
  }

  get isBothRole(): boolean {
    return this.accountType.includes(RolesEnum.SUPPLIER) && this.accountType.includes(RolesEnum.RETAILER);
  }

  handleFormValueChange(): void {
    this.setUpAccountFormGroup.valueChanges.subscribe(() => {
      this.formValueChange.emit(this.formValue);
    });
  }
}
