<div [ngClass]="wrapperClass">
  <div [ngClass]="innerClass">
    <app-section-title [title]="title"></app-section-title>
    <div class="cards" [style.grid-template-columns]="'repeat(' + columns + ', 1fr)'">
      <ng-container *ngFor="let widget of widgets">
        <div
          class="card"
          [style.height.px]="ltMd ? mobileHeight : height"
          [style.background]="'url(' + widget.image + ')'"
          (click)="cardClicked.emit(widget)"
        >
          <div [ngClass]="ltMd ? 'h4' : 'typography-body-large-3'">
            {{ widget.titles | widgetTranslatePipe : 'title' }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
