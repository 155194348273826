import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserService } from '../user/user.service';
import { map, tap } from 'rxjs/operators';
import { SharedService } from '../shared/shared.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  private readonly maintenancePath = '/maintenance';

  constructor(private _router: Router, private sharedService: SharedService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!environment.maintenance) {
      return true;
    }

    return this.sharedService.isMaintenanceExceptionIps().pipe(
      map((resp) => {
        if (!resp) {
          this._router.navigate([this.maintenancePath]);
        }
        return resp;
      })
    );
  }
}
