<div class="typography-body-1 no-wrap" [ngSwitch]="item.type">
  <a *ngIf="!!item.source && item.type === 'EXTERNAL_NAV'; else noSourceLink" target="_blank" href="{{ item.value }}">
    <img class="social-image" alt="social-image" src="{{ item.source }}" />
  </a>
  <span *ngSwitchCase="'NON_CLICK'">{{ 'FOOTER.' + item.key | translate }}</span>
  <span *ngSwitchCase="'INNER_NAV'" routerLink="{{ item.value }}">{{ 'FOOTER.' + item.key | translate }}</span>
  <span *ngSwitchCase="'DIALOG'" (click)="openDialog(item)">{{ 'FOOTER.' + item.key | translate }}</span>
</div>

<ng-template #noSourceLink>
  <a *ngIf="item.type === 'EXTERNAL_NAV'" target="_blank" href="{{ item.value }}">{{
    'FOOTER.' + item.key | translate
  }}</a>
  <a
    *ngIf="item.type === 'EMAIL'"
    href="{{ 'mailto:' + item.value }}"
    [innerHTML]="'FOOTER.' + item.key | translate"
  ></a>
</ng-template>
