import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash';

@Pipe({
  name: 'uniqby',
  standalone: true,
})
export class UniqbyPipe implements PipeTransform {
  transform<T extends { [key: string]: any }>(arr: T[], field: string): T[] {
    return uniqBy(arr, (badge) => badge[field]);
  }
}
