import { Component, Input, OnInit } from '@angular/core';
import { PlatformCardDataWithIsInstalled } from '../../../../account-settings/tabs/my-shops/my-shops.component';
import { ConnectYourStoreInstallDialogComponent } from '../../../../connect-your-store/connect-your-store-install-dialog/connect-your-store-install-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InstallService } from '../../../../../service/install/install.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { getUserRoleSelector } from '../../../../../store/user/user.selector';
import { RolesEnum } from '../../../../../vo/roles/roles';

@Component({
  selector: 'app-platform-dialog-actions',
  templateUrl: './platform-dialog-actions.component.html',
  styleUrls: ['./platform-dialog-actions.component.scss'],
})
export class PlatformDialogActionsComponent implements OnInit {
  @Input()
  platform: PlatformCardDataWithIsInstalled;
  @Input()
  preSetRole?: RolesEnum;

  constructor(private dialog: MatDialog, private installService: InstallService, private store: Store<AppState>) {}

  ngOnInit(): void {}

  connectStore(): void {
    this.store.select(getUserRoleSelector).subscribe((role) => this.handleConnect(role));
  }

  private handleConnect(role: RolesEnum): void {
    if (this.platform.platformCardData.needDialogForInstall) {
      this.dialog.open(ConnectYourStoreInstallDialogComponent, {
        width: '520px',
        maxWidth: '520px',
        panelClass: 'custom-modal-container',
        autoFocus: false,
        data: {
          ecomType: this.platform.platformCardData.type,
          role: role,
        },
      });
    } else {
      this.installService.redirectToPlatform(this.platform.platformCardData, this.preSetRole);
    }
  }

  handleTryItForFreeClick(): void {
    window.open(this.platform.platformCardData.url, '_blank');
  }
}
