<ng-container *ngIf="hasSessionData">
  <ng-container *ngIf="!isLoading; else skeleton">
    <div
      class="content content-row-padding-lt-xl content-row-max-width"
      *ngIf="!!searchTerms && searchTerms.length > 0"
    >
      <app-search-history-chip-list
        [searchTerms]="searchTerms"
        (chipClicked)="handleChipClicked($event)"
      ></app-search-history-chip-list>
    </div>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    fxLayoutGap="8px"
    class="content content-row-padding-lt-xl content-row-max-width"
  >
    <app-skeleton-display-text [maxWidth]="'300px'"></app-skeleton-display-text>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
      <app-skeleton-image
        *ngFor="let __ of [].constructor(10)"
        [borderRadius]="'9999px'"
        [width]="'70px'"
        [height]="'30px'"
      ></app-skeleton-image>
    </div>
  </div>
</ng-template>
