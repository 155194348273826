import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EcomVO } from './ecom.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { switchMap } from 'rxjs/operators';
import { selectedRetailerEcomSelector, selectedSupplierEcomSelector } from '../../store/ecom/ecom.selector';
import { getUserRolesSelector } from '../../store/user/user.selector';
import { RolesEnum } from '../../vo/roles/roles';

@Injectable({ providedIn: 'root' })
export class EcomManagerService {
  constructor(private store: Store<AppState>) {}

  selectedEcomByUserRoles(): Observable<EcomVO> {
    return this.store.select(getUserRolesSelector).pipe(
      switchMap((roles) => {
        return roles.includes(RolesEnum.RETAILER)
          ? this.store.select(selectedRetailerEcomSelector)
          : this.store.select(selectedSupplierEcomSelector);
      })
    );
  }
}
