<div
  [fxLayoutAlign]="message.source === aIChatMessageSource.COMMAND ? 'end start' : 'start start'"
  fxLayoutGap="16px"
  class="mx-20 my-24"
>
  <app-ai-chat-message-model-avatar
    fxFlex="0 0 32px"
    *ngIf="message.source === aIChatMessageSource.RESPONSE"
  ></app-ai-chat-message-model-avatar>
  <div class="message" [ngClass]="message.source" markdown [data]="messageMarkdown"></div>
</div>
