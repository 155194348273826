<div class="product-card-action-general-container">
  <div
    class="selectable-container typography-label"
    [style.background-color]="config[selected ? 'selected' : 'not-selected'].actionButtonStyle.backgroundColor"
    [style.color]="config[selected ? 'selected' : 'not-selected'].actionButtonStyle.color"
    (click)="selectedChange.emit(!selected)"
  >
    {{ selected ? 'Remove product' : 'Select product' }}
  </div>
</div>
