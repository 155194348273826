<ng-container *ngIf="!isLoading; else skeleton">
  <ng-container *ngIf="!!suppliers && suppliers.length > 0">
    <div class="content content-row-padding-lt-xl content-row-max-width">
      <app-suppliers-slider
        [title]="'EXPLORE_PRODUCTS.NEW_SUPPLIERS' | translate"
        [suppliers]="suppliers"
        [supplierImages]="supplierImages"
      ></app-suppliers-slider>
    </div>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <div class="content content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-supplier-slider-box></app-skeleton-supplier-slider-box>
  </div>
</ng-template>
