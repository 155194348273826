import { Injectable } from '@angular/core';

import { MicroserviceNames, SpringRestService } from 'app/service/rest/microservices/spring-rest.service';
import { SupplierGatewayService } from 'app/service/suppliers/supplier-gateway.service';

import { forkJoin, map, Observable } from 'rxjs';

import type { RetailerToCatalog } from '../model/retailer-to-catalog';
import type { ManagedProductNumber } from '../model/managed-product-number';
import type { WholesalePriceAndOrderNumber } from '../model/wholesale-price-and-order-number';
import type { SupplierTask } from 'app/vo/supplier/supplier-task';
import type { SupplierWithBadgesAndAutoOrder } from 'app/vo/supplier/supplier-with-badges-and-auto-order';
import type { SpringPage } from 'app/vo/pagination/spring-page';
import { RestService } from '../../../../service/rest/rest.service';
import { CatalogUpdateResponse } from '../model/catalog-update-response';
import { ApproveStatusParamEnum } from '../model/approve-status-param';
import { CatalogUpdateRestData } from '../model/catalog-update-rest.data';

@Injectable()
export class MySuppliersRestService {
  constructor(
    private springRestService: SpringRestService,
    private supplierGatewayService: SupplierGatewayService,
    private restService: RestService
  ) {}

  public getRetailerToCatalogPage(page: number, size: number = 10): Observable<SpringPage<RetailerToCatalog>> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      '/RetailerToCatalog/Paged',
      {
        size,
        page,
      },
      true
    );
  }

  public getRetailerToCatalogFilteredPageable(
    page: number,
    size: number = 10,
    approved?: ApproveStatusParamEnum,
    supplierCompanyName?: string
  ): Observable<SpringPage<RetailerToCatalog>> {
    const params = {
      page,
      size,
      ...(!!approved && { approved }),
      ...(!!supplierCompanyName && { supplierCompanyName }),
    };
    return this.springRestService.get(MicroserviceNames.USER, '/RetailerToCatalog/BySupplierCompanyName', params, true);
  }

  public getSuppliersByUserIDs(userIDs: number[]): Observable<SupplierWithBadgesAndAutoOrder[]> {
    return forkJoin({
      suppliersWithBadges: this.supplierGatewayService.getSuppliers(userIDs),
      isAutoOrder: this.supplierGatewayService.getAutoOrders(userIDs),
    }).pipe(
      map(({ suppliersWithBadges, isAutoOrder }): SupplierWithBadgesAndAutoOrder[] => {
        return suppliersWithBadges.map((element) => ({
          ...element,
          autoOrder: isAutoOrder.find((autoOrderItem) => autoOrderItem.userId === element.userId)?.automate,
        }));
      })
    );
  }

  public getCatalogsByCatalogIDs(catalogIDs: number[]): Observable<SupplierTask[]> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      '/Supplier/Catalog/FindByIds',
      {
        catalogIds: catalogIDs,
      },
      true
    );
  }

  public getManagedProductNumberByCatalogIDs(catalogIDs: number[]): Observable<ManagedProductNumber[]> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      '/RetailerToCatalog/CountManagedProductsByCatalogs',
      {
        supplierCatalogIds: catalogIDs.join(','),
      },
      true
    );
  }

  public getWholesalePriceAndOrderNumberByCatalogIDs(catalogIDs: number[]): Observable<WholesalePriceAndOrderNumber[]> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      '/Retailer/Statistics/OrderAndWholesalePriceSummaryByCatalogs',
      {
        catalogIds: catalogIDs,
      },
      true
    );
  }

  public updateCatalogSettings(restData: CatalogUpdateRestData): Observable<CatalogUpdateResponse[]> {
    return this.restService
      .post(`RetailerToCatalogService/update`, restData)
      .pipe(map((response) => response.getData()));
  }
}
