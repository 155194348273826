import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Emoji, EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NotificationService } from 'app/main/notification/notification.service';
import { ChatService } from 'app/service/chat/chat.service';
import { ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { TranslateService } from '@ngx-translate/core';

export interface ChatMessageInput {
  message: string;
  media?: ChatMediaInput;
}

interface ChatMediaInput {
  filePath: string;
  file: File;
}

@Component({
  selector: 'app-chat-body-input',
  templateUrl: './chat-body-input.component.html',
  styleUrls: ['./chat-body-input.component.scss'],
})
export class ChatBodyInputComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<ChatMessageInput>();
  @Input() isMobile = false;

  input = '';
  mediaInput: ChatMediaInput;

  constructor(
    private chatService: ChatService,
    private notifiService: NotificationService,
    private translateService: TranslateService
  ) {
    this.isMobile = this.chatService.isMobile();
  }

  ngOnInit(): void {}

  onFileSelected(event): void {
    const file: File = event.target.files[0];
    if (!this.fileValidation(file)) {
      this.notifiService.warning(this.translateService.instant('CHAT.FILE_LIMIT'));
      return;
    }

    this.mediaInput = { filePath: null, file: event.target.files[0] };
    if (!this.mediaInput.file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.mediaInput.filePath = reader.result as string;
    };
    reader.readAsDataURL(this.mediaInput.file);
  }

  fileValidation(file: File): boolean {
    return file.size <= ChatService.FILE_SIZE_LIMIT;
  }

  textValidation(): boolean {
    this.input = this.input.trim();
    if (this.input.length < 1 && !this.mediaInput) {
      return false;
    }

    if (this.input.length > ChatService.CHARACTER_COUNT_LIMIT) {
      this.notifiService.warning(
        this.translateService.instant('CHAT.CHARACTER_LIMIT', { character_number: ChatService.CHARACTER_COUNT_LIMIT })
      );
      return false;
    }

    return true;
  }

  handleSelection(event: EmojiEvent): void {
    this.input += ' ' + event.emoji.native + ' ';
  }

  send(): void {
    if (!this.textValidation()) {
      return;
    }

    this.messageEvent.emit({
      message: this.input,
      media: this.mediaInput,
    });
    this.resetInput();
  }

  resetMedia(): void {
    this.mediaInput = null;
  }

  resetMessage(): void {
    this.input = '';
  }

  resetInput(): void {
    this.resetMessage();
    this.resetMedia();
  }
}
