import { OverlayRef } from '@angular/cdk/overlay';

export class NotificationRef {
  constructor(private readonly overlay: OverlayRef) {}

  close(): void {
    this.overlay.dispose();
  }

  isVisible(): any {
    return this.overlay && this.overlay.overlayElement;
  }

  getPosition(): ClientRect {
    return this.overlay.overlayElement.getBoundingClientRect();
  }
}
