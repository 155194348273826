import { Pipe, PipeTransform } from '@angular/core';
import { isNil, omitBy } from 'lodash';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'productImageThumbnailCdn',
  standalone: true,
})
export class ProductImageThumbnailCdnPipe implements PipeTransform {
  transform(url: string): string {
    return transformUrl(url, undefined, 350, 'webp');
  }
}

@Pipe({
  name: 'productImageFullCdn',
  standalone: true,
})
export class ProductImageFullCdnPipe implements PipeTransform {
  transform(url: string): string {
    return transformUrl(url, undefined, undefined, 'webp');
  }
}

function transformUrl(url: string, width: number, height: number, extension: string): string {
  const queryParams = omitBy({ url, extension, width, height }, isNil);
  return `${environment.synceeImgURL}/v1/image?${new URLSearchParams(queryParams).toString()}`;
}
