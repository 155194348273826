import { FuseConfig } from '@fuse/types';
import { DefaultToolbarContents } from '../../@fuse/constatnt/toolbar/default-toolbar-contents';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
  // Color themes can be defined in src/app/app.theme.scss
  colorTheme: 'theme-default',
  customScrollbars: true,
  layout: {
    style: 'vertical-layout-1',
    width: 'fullwidth',
    navbar: {
      primaryBackground: 'primary-background',
      secondaryBackground: 'primary-background',
      folded: false,
      hidden: false,
      position: 'left',
      variant: 'vertical-style-1',
    },
    toolbar: {
      customBackgroundColor: false,
      background: 'white-500',
      hidden: false,
      title: '',
      ecom: null,
      position: 'top',
      content: DefaultToolbarContents,
      extraIcons: [],
    },
    footer: {
      topDivider: true,
      customBackgroundColor: false,
      background: 'fuse-navy-900',
      hidden: false,
      position: 'above',
      cta: null,
    },
    sidepanel: {
      hidden: false,
      position: 'right',
    },
    categorySelector: {
      hidden: false,
      redirect: true,
    },
    breadcrumb: {
      hidden: true,
      path: [],
    },
    submenu: {
      hidden: true,
      group: null,
    },
    contentContainer: 'full-width',
    fullHeight: false,
    title: null,
    translateTitle: true,
    backButton: null,
    aiChat: {
      hidden: true,
    },
    minimizedChat: {
      hidden: false,
    },
    setupGuideButton: {
      hidden: false,
      elevated: false,
    },
  },
  templates: null,
};
