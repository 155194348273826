import { Action } from '@ngrx/store';
import { EcomFeatureUsage } from '../../service/usage/model/ecom/ecom-feature-usage';
import { Catalog } from '../../vo/catalog';
import { UserFeatureUsage } from '../../service/usage/model/user/user-feature-usage';

export enum UsageActionType {
  GET_USER_USAGE_START = '[Usage] GET_USER_USAGE_START',
  GET_USER_USAGE_SUCCESS = '[Usage] GET_USER_USAGE_SUCCESS',
  GET_ECOM_USAGE_START = '[Usage] GET_ECOM_USAGE_START',
  GET_ECOM_USAGE_SUCCESS = '[Usage] GET_ECOM_USAGE_SUCCESS',
  EDIT_ECOM_USAGE = '[Usage] EDIT_ECOM_USAGE',
  EDIT_USER_USAGE = '[Usage] EDIT_USER_USAGE',
}

export class EditUserUsageAction implements Action {
  readonly type = UsageActionType.EDIT_USER_USAGE;

  constructor(public payload: UserFeatureUsage) {}
}

export class EditEcomUsageAction implements Action {
  readonly type = UsageActionType.EDIT_ECOM_USAGE;

  constructor(public payload: EditEcomUsagePayload) {}
}

export class GetEcomUsageStartAction implements Action {
  readonly type = UsageActionType.GET_ECOM_USAGE_START;

  constructor(public payload: number[]) {}
}

export class GetEcomUsageSuccessAction implements Action {
  readonly type = UsageActionType.GET_ECOM_USAGE_SUCCESS;

  public constructor(public payload: EcomFeatureUsage[]) {}
}

export class GetUserUsageStartAction implements Action {
  readonly type = UsageActionType.GET_USER_USAGE_START;
}

export class GetUserUsageSuccessAction implements Action {
  readonly type = UsageActionType.GET_USER_USAGE_SUCCESS;

  public constructor(public payload: UserFeatureUsage) {}
}

export type UsageAction =
  | GetEcomUsageStartAction
  | GetEcomUsageSuccessAction
  | GetUserUsageStartAction
  | GetUserUsageSuccessAction
  | EditUserUsageAction
  | EditEcomUsageAction;

export interface EditEcomUsagePayload {
  usage: EcomFeatureUsage;
  ecomId: number;
}
