import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImageWithAlt } from '../../../../vo/image-with-alt';

@UntilDestroy()
@Component({
  selector: 'app-header-1',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './header-1.component.html',
  styleUrls: ['./header-1.component.scss'],
})
export class Header1Component implements OnInit {
  @Input() upperText: string;
  @Input() title: string;
  @Input() description: string;
  @Input() backgroundImage: ImageWithAlt;
  @Input() mobileBackgroundImage?: ImageWithAlt;
  @Input() wrapperClasses: string[] | string;
  @Input() innerClasses: string[] | string;
  @Input() height: number;
  @Input() mobileHeight: number;
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreen();
  }

  private subscribeToScreen(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), untilDestroyed(this))
      .subscribe((matches) => (this.ltMd = matches));
  }
}
