import { Injectable } from '@angular/core';
import { GtmManagerService } from '../../../../service/google-tag-manager/gtm-manager.service';
import { FilterAction, FilterActionKey } from '../../../../service/google-tag-manager/actions/filter';
import { FilterPositionGtm } from '../../../../service/google-tag-manager/model/filter-position-gtm';

@Injectable({
  providedIn: 'root',
})
export class GTMFilterService {
  private _position: FilterPositionGtm;

  constructor(private gtmManagerService: GtmManagerService) {}

  sendFilter(key: FilterActionKey, value: GTMFilterItem): void {
    this.gtmManagerService.pushTag(
      new FilterAction({
        filter_value: value.id,
        filter_name: value.name,
        filter_key: key as FilterActionKey,
        filter_position: this.position,
      })
    );
  }

  set position(value: FilterPositionGtm) {
    this._position = value;
  }

  get position(): FilterPositionGtm {
    return this._position;
  }
}

export interface GTMFilterItem {
  id: string;
  name: string;
}
