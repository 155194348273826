import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeCustomComponent, MatTreeCustomNode } from '../mat-tree-custom/mat-tree-custom.component';
import { Store } from '@ngrx/store';
import { CategoryVo } from '../../../vo/category-vo';
import { Observable, Subject } from 'rxjs';
import { categorySelector } from '../../../store/category/category.selector';
import { AppState } from '../../../app.state';
import { take, takeUntil } from 'rxjs/operators';
import { LanguageService } from '../../../service/language/language.service';

@Component({
  selector: 'app-mat-tree-categories',
  standalone: true,
  imports: [CommonModule, MatTreeCustomComponent],
  templateUrl: './mat-tree-categories.html',
  styleUrls: ['./mat-tree-categories.scss'],
})
export class MatTreeCategoriesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('customTree') customTree: MatTreeCustomComponent;
  @Input() value: string[];
  @Input() inputPlaceholder = 'Search';
  @Input() selectable = false;
  @Output() valueChange = new EventEmitter<string[]>();
  categories$: Observable<CategoryVo>;
  tree: MatTreeCustomNode[];
  private unsubscribeAll = new Subject<void>();

  constructor(private store$: Store<AppState>, private languageService: LanguageService) {
    this.getCategories();
  }

  ngOnInit(): void {
    this.languageService
      .observeLanguageChange()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((language) => {
        this.createTree(language);
      });
  }

  ngAfterViewInit(): void {
    this.customTree.treeControl.collapseAll();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getCategories(): void {
    this.categories$ = this.store$.select(categorySelector);
  }

  createTree(language: string): void {
    this.categories$.pipe(take(1)).subscribe((value) => {
      const categoryArray = Object.values(value.children);
      this.tree = this.mapCategoriesToTree(categoryArray, language);
    });
  }

  private mapCategoriesToTree(categories: CategoryVo[], language: string): MatTreeCustomNode[] {
    return categories.map((category) => {
      const translatedName = category.categoryName.find((name) => name.languageCode === language);
      return {
        id: category.id.toString(),
        name: !!translatedName ? translatedName.name : category.name,
        children: category.children && this.mapCategoriesToTree(category.children, language),
        imgUrl: null,
      };
    });
  }
}
