<div
  class="icon-container-outside"
  [style.height.px]="size"
  [style.width.px]="size"
  [style]="{ '--bgColor': iconBackgroundColor }"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
    class="icon-container"
    [style.background-color]="iconBackgroundColor"
    [style.height.px]="innerSize"
    [style.width.px]="innerSize"
  >
    <mat-icon [ngClass]="iconClass" [style.color]="iconColor">{{ icon }}</mat-icon>
  </div>
</div>
