import { Routes } from '@angular/router';
import { CommonNavigation } from './navigation/navigation-routes/common/common.navigation';
import { RetailerNavigation } from './navigation/navigation-routes/retailer/retailer.navigation';
import { SupplierCenterNavigation } from './navigation/navigation-routes/supplier/supplier-center.navigation';
import { SupplierNavigation } from './navigation/navigation-routes/supplier/supplier.navigation';

export const redirects: Routes = [
  { path: 'activity-log', redirectTo: `${SupplierNavigation.ACTIVITY_LOG}` },
  { path: 'partners-dashboard', redirectTo: `${CommonNavigation.REFERRALS}` },
  { path: 'product-listing/automatic', redirectTo: `${SupplierNavigation.PRODUCT_LISTING.AUTOMATIC_PRODUCT_LISTING}` },
  {
    path: 'product-listing/automatic/wizard',
    redirectTo: `${SupplierNavigation.PRODUCT_LISTING.AUTOMATIC_PRODUCT_LISTING_TASK}`,
  },
  { path: 'product-listing/manual', redirectTo: `${SupplierCenterNavigation.CATALOG.PIM}` },
  {
    path: 'product-listing/manual/product',
    redirectTo: `${SupplierCenterNavigation.CATALOG.PIM_ADD}`,
  },
  { path: 'dashboard/supplier', redirectTo: `${SupplierNavigation.DASHBOARD}` },
  { path: 'my-retailers', redirectTo: `${SupplierNavigation.MY_RETAILERS.DEFAULT}` },
  { path: 'my-retailers/list', redirectTo: `${SupplierNavigation.MY_RETAILERS.MY_RETAILERS}` },
  { path: 'my-retailers/my-reviews', redirectTo: `${SupplierNavigation.MY_RETAILERS.MY_REVIEWS}` },
  //
  { path: 'supplier-profile', redirectTo: `${SupplierNavigation.PROFILE.DEFAULT}` },
  { path: 'profile/supplier', redirectTo: `${SupplierNavigation.PROFILE.DEFAULT}` },
  { path: 'supplier-profile/settings', redirectTo: `${SupplierNavigation.PROFILE.SETTINGS}` },
  { path: 'profile/supplier/settings', redirectTo: `${SupplierNavigation.PROFILE.SETTINGS}` },
  { path: 'supplier-profile/team', redirectTo: `${SupplierNavigation.PROFILE.TEAM}` },
  { path: 'profile/supplier/team', redirectTo: `${SupplierNavigation.PROFILE.TEAM}` },
  {
    path: 'supplier-profile/notification-settings',
    redirectTo: `${SupplierNavigation.PROFILE.NOTIFICATION_SETTINGS}`,
  },
  {
    path: 'profile/supplier/notification-settings',
    redirectTo: `${SupplierNavigation.PROFILE.NOTIFICATION_SETTINGS}`,
  },
  {
    path: 'supplier-profile/order-settings',
    redirectTo: `${SupplierNavigation.PROFILE.ORDER_SETTINGS}`,
  },
  {
    path: 'profile/supplier/order-settings',
    redirectTo: `${SupplierNavigation.PROFILE.ORDER_SETTINGS}`,
  },
  {
    path: 'supplier-profile/order-settings/:payment-provider',
    redirectTo: `${SupplierNavigation.PROFILE.ORDER_SETTINGS}/:payment-provider'`,
  },
  {
    path: 'profile/supplier/order-settings/:payment-provider',
    redirectTo: `${SupplierNavigation.PROFILE.ORDER_SETTINGS}/:payment-provider'`,
  },
  { path: 'my-listing', redirectTo: `${SupplierNavigation.STOREFRONT_SETTINGS.DEFAULT}` },
  { path: 'my-listing/supplier-details', redirectTo: `${SupplierNavigation.STOREFRONT_SETTINGS.BRANDING}` },
  { path: 'supplier-details', redirectTo: `${SupplierNavigation.STOREFRONT_SETTINGS.INFORMATION_FOR_RETAILERS}` },
  {
    path: 'my-listing/shipping-details',
    redirectTo: `${SupplierNavigation.STOREFRONT_SETTINGS.SHIPPING}`,
  },
  {
    path: 'my-listing/international-shipping',
    redirectTo: `${SupplierNavigation.STOREFRONT_SETTINGS.SHIPPING}`,
  },
  { path: 'orders/supplier', redirectTo: `${SupplierNavigation.ORDERS}` },
  { path: 'supplier-my-products', redirectTo: `${SupplierNavigation.MY_PRODUCTS}` },
  { path: 'my-products', redirectTo: `${SupplierNavigation.SUPPLIER_CATALOG_PRODUCTS}` },
  { path: 'orders-statistic', redirectTo: `${SupplierNavigation.ANALYTICS.ORDERS}` },
  { path: 'marketplace/search-products', redirectTo: RetailerNavigation.MARKETPLACE.EXPLORE_PRODUCTS },
  { path: 'marketplace/search-products/search', redirectTo: RetailerNavigation.MARKETPLACE.EXPLORE_PRODUCTS_SEARCH },
  {
    path: 'marketplace/search-products/search/:id',
    redirectTo: `${RetailerNavigation.MARKETPLACE.EXPLORE_PRODUCTS_SEARCH}/:id`,
  },
  {
    path: 'marketplace/search-products/storefront/:handle',
    redirectTo: `${RetailerNavigation.MARKETPLACE.EXPLORE_PRODUCTS_SUPPLIERS}/:handle`,
  },
  {
    path: 'marketplace/search-products/product/:id',
    redirectTo: `${RetailerNavigation.MARKETPLACE.EXPLORE_PRODUCTS_PRODUCTS}/:id`,
  },
  { path: 'retailer-orders-statistic', redirectTo: `${RetailerNavigation.ANALYTICS.ORDERS}` },
  {
    path: 'marketplace/alibaba/search-products',
    redirectTo: `${RetailerNavigation.MARKETPLACE.ALIBABA_SEARCH_PRODUCTS}`,
  },
  { path: 'marketplace/alibaba/import-list', redirectTo: `${RetailerNavigation.MARKETPLACE.ALIBABA_IMPORT_LIST}` },
  { path: 'marketplace/alibaba/my-catalogs', redirectTo: `${RetailerNavigation.MARKETPLACE.ALIBABA_MY_CATALOGS}` },
  {
    path: 'marketplace/alibaba/my-catalogs/:tabIndex',
    redirectTo: `${RetailerNavigation.MARKETPLACE.ALIBABA_MY_CATALOGS}/:tabIndex`,
  },
  {
    path: 'marketplace/alibaba/alibaba-coming-soon',
    redirectTo: `${RetailerNavigation.MARKETPLACE.ALIBABA_COMING_SOON}`,
  },
  { path: 'marketplace/alibaba/refresh-token', redirectTo: `${RetailerNavigation.MARKETPLACE.ALIBABA_REFRESH_TOKEN}` },
  {
    path: 'marketplace/find-suppliers/catalog/:catalogID',
    redirectTo: `${RetailerNavigation.MARKETPLACE.FIND_SUPPLIERS_CATALOG}/:catalogID`,
  },
  { path: 'marketplace/my-catalogs', redirectTo: `${RetailerNavigation.MARKETPLACE.MY_CATALOGS}` },
  {
    path: 'marketplace/my-catalogs/:tabIndex',
    redirectTo: `${RetailerNavigation.MARKETPLACE.MY_CATALOGS}/:tabIndex'`,
  },
  { path: 'marketplace/my-suppliers', redirectTo: `${RetailerNavigation.MY_SUPPLIERS}` },
  { path: 'orders/retailer', redirectTo: `${RetailerNavigation.ORDERS}`, pathMatch: 'full' },
  { path: 'datafeed-manager/my-catalogs', redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.MY_CATALOGS}` },
  {
    path: 'datafeed-manager/my-catalogs/:tabIndex',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.MY_CATALOGS}/:tabIndex'`,
  },
  { path: 'datafeed-manager/product-upload', redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD}` },
  {
    path: 'datafeed-manager/product-upload/wizard/shopify',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_RETAILER_TASK}`,
  },
  {
    path: 'datafeed-manager/product-upload/wizard/jumpseller',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_JUMPSELLER}`,
  },
  {
    path: 'datafeed-manager/product-upload/wizard/shoprenter',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_SHOPRENTER}`,
  },
  {
    path: 'datafeed-manager/product-upload/wizard/ecwid',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_ECWID}`,
  },
  { path: 'datafeed-manager/product-export', redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_EXPORT}` },
  {
    path: 'datafeed-manager/product-export/wizard',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.PRODUCT_EXPORT_WIZARD}`,
  },
  {
    path: 'datafeed-manager/variants-update',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE}`,
  },
  {
    path: 'datafeed-manager/variants-update/wizard/shopify',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE_WIZARD}`,
  },
  {
    path: 'datafeed-manager/variants-update/wizard/jumpseller',
    redirectTo: `${RetailerNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE_WIZARD_JUMPSELLER}`,
  },
  { path: 'retailer-profile', redirectTo: `${RetailerNavigation.SETTINGS.DEFAULT}` },
  { path: 'profile/retailer', redirectTo: `${RetailerNavigation.SETTINGS.DEFAULT}` },
  { path: 'retailer-profile/my-shops', redirectTo: `${RetailerNavigation.SETTINGS.MY_SHOPS}` },
  { path: 'profile/retailer/my-shops', redirectTo: `${RetailerNavigation.SETTINGS.MY_SHOPS}` },
  { path: 'retailer-profile/settings', redirectTo: `${RetailerNavigation.SETTINGS.SETTINGS}` },
  { path: 'profile/retailer/settings', redirectTo: `${RetailerNavigation.SETTINGS.SETTINGS}` },
  { path: 'retailer-profile/team', redirectTo: `${RetailerNavigation.SETTINGS.TEAM}` },
  { path: 'profile/retailer/team', redirectTo: `${RetailerNavigation.SETTINGS.TEAM}` },
  { path: 'retailer-profile/activity-log', redirectTo: `${RetailerNavigation.SETTINGS.ACTIVITY_LOG}` },
  { path: 'profile/retailer/activity-log', redirectTo: `${RetailerNavigation.SETTINGS.ACTIVITY_LOG}` },
  { path: 'retailer-profile/order-settings', redirectTo: `${RetailerNavigation.SETTINGS.ORDER_SETTINGS}` },
  { path: 'profile/retailer/order-settings', redirectTo: `${RetailerNavigation.SETTINGS.ORDER_SETTINGS}` },
  {
    path: 'retailer-profile/search-settings',
    redirectTo: `${RetailerNavigation.SETTINGS.SEARCH_SETTINGS}`,
  },
  {
    path: 'profile/retailer/search-settings',
    redirectTo: `${RetailerNavigation.SETTINGS.SEARCH_SETTINGS}`,
  },
  {
    path: 'retailer-profile/notification-settings',
    redirectTo: `${RetailerNavigation.SETTINGS.NOTIFICATION_SETTINGS}`,
  },
  {
    path: 'rprofile/retailer/notification-settings',
    redirectTo: `${RetailerNavigation.SETTINGS.NOTIFICATION_SETTINGS}`,
  },
  { path: 'billing/subscription', redirectTo: `${RetailerNavigation.BILLING.CHECKOUT}` },
  { path: 'billing/subscriptions', redirectTo: `${RetailerNavigation.BILLING.CHECKOUT}` },
  { path: 'billing/invoices', redirectTo: `${RetailerNavigation.BILLING.INVOICES}` },
  { path: 'billing/thank-you/wix', redirectTo: `${RetailerNavigation.BILLING.THANK_YOU_WIX}` },
  {
    path: 'billing/thank-you/woo',
    redirectTo: `${RetailerNavigation.BILLING.THANK_YOU_WOOCOMMERCE}`,
  },
  { path: 'billing/thank-you', redirectTo: `${RetailerNavigation.BILLING.THANK_YOU}` },
  { path: 'dashboard/retailer', redirectTo: `${RetailerNavigation.DASHBOARD}` },
  { path: 'inmail', redirectTo: `${RetailerNavigation.MESSAGES}` },
  { path: 'messenger', redirectTo: `${RetailerNavigation.MESSAGES}` },
  { path: 'inmail/:type', redirectTo: `${RetailerNavigation.MESSAGES}/:type` },
  { path: 'messenger/:type', redirectTo: `${RetailerNavigation.MESSAGES}/:type` },
];
