import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Constants } from '../../../../utils/Constants';
import { UserService } from '../../../../service/user/user.service';
import { EcomService } from '../../../../service/ecom/ecom.service';

@Component({
  selector: 'app-connecttypepopup',
  templateUrl: './connecttypepopup.component.html',
  styleUrls: ['./connecttypepopup.component.scss'],
})
export class ConnecttypepopupComponent implements OnInit {
  public connectionTypes = [];

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public taskId: any,
    private dialogRef: MatDialogRef<ConnecttypepopupComponent>,
    private ecomService: EcomService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.connectionTypes = JSON.parse(JSON.stringify(Constants.CONNECTIONTYPESWITHOUTDOMAINS));
    this.filterSourceDomains();

    this.form = this.fb.group({
      connType: ['', Validators.required],
    });
  }

  filterSourceDomains(): void {
    const ecomTypes = [];
    const role = this.userService.getActualRole();
    this.ecomService.getSourceSelectableDomains(this.taskId, role).subscribe((domains) => {
      domains.forEach((domain) => {
        const ucDomain = domain.ecomType.toUpperCase();
        if (!ecomTypes.includes(ucDomain)) {
          ecomTypes.push(ucDomain);
          this.connectionTypes.unshift({ name: ucDomain, type: ucDomain });
        }
      });
    });
  }

  selectedFtpConnectionType(event): void {
    console.log(event.value);
  }

  onSubmit(): void {
    console.log(this.form.value.connType);
    this.dialogRef.close(this.form.value.connType);
  }

  close(): void {
    console.log('close dialog');
    this.dialogRef.close(null);
  }
}
