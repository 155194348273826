import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { GeneralButtonService } from './general-button.service';

@Component({
  selector: 'app-general-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterModule, TranslateModule],
  templateUrl: './general-button.component.html',
  styleUrls: ['./general-button.component.scss'],
})
export class GeneralButtonComponent implements OnInit {
  @Input() config: GeneralButton;

  hovered = false;

  constructor(public generalButtonService: GeneralButtonService) {}

  ngOnInit(): void {}
}
