export class FileUploadResponseVo {
  private _status: boolean;

  private _taskId: number;

  private _fileId: number;

  private _remotePath: string;

  private _detectedType: string;

  private _name: string;

  get status(): boolean {
    return this._status;
  }

  set status(value: boolean) {
    this._status = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get detectedType(): string {
    return this._detectedType;
  }

  set detectedType(value: string) {
    this._detectedType = value;
  }

  get remotePath(): string {
    return this._remotePath;
  }

  set remotePath(value: string) {
    this._remotePath = value;
  }

  constructor() {}
}
