import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FuseSplashScreenService } from '../../@fuse/services/splash-screen.service';
import { tap } from 'rxjs/operators';
import { translationUrl } from '../../environments/environment';

export class StorageLoaderService implements TranslateLoader {
  constructor(private http: HttpClient, private splashScreenService: FuseSplashScreenService) {}

  getTranslation(lang: string): Observable<any> {
    const options = {
      params: new HttpParams().set('alt', 'media'),
    };

    this.splashScreenService.show();

    return this.http.get(`${translationUrl}${lang}.json`, options).pipe(tap(() => this.splashScreenService.hide()));
  }
}
