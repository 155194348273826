import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthDialogType } from '../../helpers/authentication-dialog-type';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss'],
})
export class RegistrationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public props: RegistrationDialogProps
  ) {}

  ngOnInit(): void {}
}

export interface RegistrationDialogProps {
  type: AuthDialogType;
  titleKey: string;
  descriptionKey: string;
}
