import { Action } from '@ngrx/store';
import { CategoryVo } from '../../vo/category-vo';
import { SupplierDto } from '../../vo/supplier/supplier-dto';

export enum ActionTypes {
  setCategories = 'setCategories',
  setFlattenCategories = 'setFlattenCategories',
  addTopSuppliersToCategory = 'addTopSuppliersToCategory',
}

export class SetCategoriesAction implements Action {
  readonly type = ActionTypes.setCategories;

  constructor(public payload: CategoryVo) {}
}

export class SetFlattenCategoriesAction implements Action {
  readonly type = ActionTypes.setFlattenCategories;

  constructor(public payload: CategoryVo[]) {}
}

export class AddTopSuppliersToCategory implements Action {
  readonly type = ActionTypes.addTopSuppliersToCategory;

  constructor(public payload: { categoryId: number; suppliers: SupplierDto[] }) {}
}

export type ActionsUnion = SetCategoriesAction | SetFlattenCategoriesAction | AddTopSuppliersToCategory;
