<div [ngSwitch]="data.ecomType" fxLayout="column" fxLayoutGap="32px">
  <div class="info-container">
    <mat-icon class="s-64 info-icon icon-filled">info</mat-icon>
  </div>
  <mat-dialog-content>
    <ng-container *ngSwitchCase="'shopify'">
      <app-shopify-new-store [role]="data.role"></app-shopify-new-store>
    </ng-container>
    <ng-container *ngSwitchCase="'woocommerce'" fxLayout="column" fxLayoutGap="12px">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="28px">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="12px">
          <h3 class="margin-0 centered-text">
            {{ 'ADD_NEW_STORE.ADD' | translate }}
          </h3>
          <div class="typography-body-1 centered-text">
            {{ 'ADD_NEW_STORE.WOOCOMMERCE.DESCRIPTION' | translate }}
          </div>
        </div>
        <form #form="ngForm" class="w-100p" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="32px">
          <mat-form-field class="domain-wrapper w-100p input-without-padding" appearance="fill">
            <mat-label>{{ 'ADD_NEW_STORE.STORE_URL' | translate }}</mat-label>
            <input matInput name="wp-url" placeholder="https://www.examplestore.com" [(ngModel)]="wpUrl" required />
            <mat-error *ngIf="!form.valid">
              {{ 'ADD_NEW_STORE.URL_IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="center">
            <button mat-button mat-dialog-close="">{{ 'ADD_NEW_STORE.CANCEL' | translate }}</button>
            <button [disabled]="!form.valid" mat-button class="syncee-blue-button" (click)="installWoocommerce()">
              {{ 'ADD_NEW_STORE.WOOCOMMERCE.INSTALL' | translate }}
            </button>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'jumpseller'">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="28px">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="12px">
          <h3 class="margin-0 centered-text">
            {{ 'ADD_NEW_STORE.JUMPSELLER.ADD' | translate }}
          </h3>
          <div class="typography-body-1 centered-text">
            {{ 'ADD_NEW_STORE.JUMPSELLER.DESCRIPTION' | translate }}
          </div>
        </div>
        <form
          novalidate
          (ngSubmit)="installJumpseller()"
          [formGroup]="jumpseller"
          class="w-100p"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          fxLayoutGap="32px"
        >
          <div class="input-field">
            <mat-form-field appearance="fill" class="w-100p input-without-padding">
              <mat-label>
                {{ 'ADD_NEW_STORE.STORE_URL' | translate }}
              </mat-label>
              <input matInput placeholder="example.jumpseller.com" formControlName="domain" required />
              <mat-error *ngIf="jumpsellerDomain.errors?.required && jumpsellerDomain.touched">
                {{ 'ADD_NEW_STORE.URL_IS_REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="center" class="install-button-mobile">
            <button mat-button mat-dialog-close="">{{ 'ADD_NEW_STORE.CANCEL' | translate }}</button>
            <button mat-button class="syncee-blue-button" type="submit" [disabled]="jumpseller.invalid">
              {{ 'ADD_NEW_STORE.CONNECT' | translate }}
            </button>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'shoprenter'">
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="28px">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="12px">
          <h3 class="margin-0 centered-text">
            {{ 'ADD_NEW_STORE.SHOPRENTER.ADD' | translate }}
          </h3>
          <div class="typography-body-1 centered-text">
            {{ 'ADD_NEW_STORE.SHOPRENTER.DESCRIPTION' | translate }}
          </div>
        </div>
        <form
          novalidate
          (ngSubmit)="installShoprenter()"
          [formGroup]="shoprenter"
          class="w-100p"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          fxLayoutGap="32px"
        >
          <div class="input-field">
            <mat-form-field appearance="fill" class="w-100p input-without-padding">
              <mat-label>
                {{ 'ADD_NEW_STORE.STORE_URL' | translate }}
              </mat-label>
              <input matInput placeholder="example.shoprenter.hu" formControlName="domain" required />
              <mat-error *ngIf="shoprenterDomain.errors?.required && shoprenterDomain.touched">
                {{ 'ADD_NEW_STORE.URL_IS_REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="center" class="install-button-mobile">
            <button mat-button mat-dialog-close="">{{ 'ADD_NEW_STORE.CANCEL' | translate }}</button>
            <button mat-button class="syncee-blue-button" type="submit" [disabled]="shoprenter.invalid">
              {{ 'ADD_NEW_STORE.CONNECT' | translate }}
            </button>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'squarespace'">
      <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
          <h3 class="margin-0 centered-text">
            {{ 'ADD_NEW_STORE.HOW_TO_CONNECT' | translate }}
          </h3>
          <div class="typography-body-1 centered-text">
            {{ 'ADD_NEW_STORE.SQUARESPACE.DESCRIPTION' | translate }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <button mat-button mat-dialog-close="">{{ 'ADD_NEW_STORE.CANCEL' | translate }}</button>
          <a
            href="https://help.syncee.co/en/articles/4805021-how-to-install-syncee-to-your-squarespace-store"
            target="_blank"
          >
            <button mat-button class="syncee-blue-button">
              {{ 'ADD_NEW_STORE.CHECK_GUIDE' | translate }}
            </button>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'shoplazza'" fxLayout="column" fxLayoutGap="12px">
      <div>{{ 'ADD_NEW_STORE.SHOPLAZZA.INSTALL' | translate }}</div>
      <!--        <div>You could install Syncee to your Shoplazza store by following the steps in this link:</div>-->
      <!--        <a>https://help.syncee.co/en/articles/4805021-how-to-install-syncee-to-your-squarespace-store</a>-->
      <!--        OLD_TODO ide kell még a link-->
    </ng-container>
  </mat-dialog-content>
</div>
