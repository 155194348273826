import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permission-fallback',
  templateUrl: './permission-fallback.component.html',
  styleUrls: ['./permission-fallback.component.scss'],
  standalone: true,
})
export class PermissionFallbackComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
