<div class="entry-dialog" fxLayout="column" fxLayoutGap="40px">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <button fxFlexAlign="end" mat-dialog-close mat-icon-button>
      <mat-icon class="s-20">close</mat-icon>
    </button>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px" class="entry-dialog-content">
      <app-avtar-with-icon-themed [icon]="icon" [themeType]="themeType"></app-avtar-with-icon-themed>
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px" class="centered-text">
        <h2>{{ title }}</h2>
        <div>{{ description }}</div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
