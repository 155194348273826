import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';
import { filter, map, take } from 'rxjs/operators';
import { EcomFeatureUsage } from '../../../service/usage/model/ecom/ecom-feature-usage';
import { subscriptionsSelector } from '../../../store/subscription/subscription.selector';
import { SubscriptionRMP } from '../../../vo/subscription-vo';
import { EditEcomUsageAction, EditUserUsageAction } from '../../../store/usage/usage.actions';
import { UserFeatureUsage } from '../../../service/usage/model/user/user-feature-usage';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { FeatureUsageService } from '../../../service/usage/feature-usage.service';

@Injectable({
  providedIn: 'root',
})
export class AiChatLimitationsService {
  private static AI_CHAT_LIMIT_DEFAULT = 50;
  currentUsage: Observable<number>;
  limitReached: Observable<boolean>;

  constructor(private store: Store<AppState>, private featureUsageService: FeatureUsageService) {
    this.limitReached = this.getLimitReached();
    this.currentUsage = this.getCurrentUsage();
  }

  increaseUsage(): void {
    this.getEcom()
      .pipe(take(1), this.getUsageWithEcomOperator(), take(1))
      .subscribe(({ usage, ecom }) => {
        if (!!ecom) {
          this.store.dispatch(
            new EditEcomUsageAction({
              ecomId: ecom.id,
              usage: { ...usage, aiChatLimit: usage.aiChatLimit + 1 } as EcomFeatureUsage,
            })
          );
        } else {
          this.store.dispatch(
            new EditUserUsageAction({ ...usage, aiChatLimit: usage.aiChatLimit + 1 } as UserFeatureUsage)
          );
        }
      });
  }

  private getCurrentUsage(): Observable<number> {
    return this.getEcom().pipe(
      this.featureUsageService.featureUsage(),
      map((usage) => usage.aiChatLimit)
    );
  }

  private getUsageWithEcomOperator(): (
    source: Observable<EcomVO>
  ) => Observable<{ usage: EcomFeatureUsage | UserFeatureUsage; ecom: EcomVO }> {
    return (source) =>
      source.pipe(
        switchMap((ecom) =>
          of(ecom).pipe(
            this.featureUsageService.featureUsage(),
            map((usage) => ({ usage, ecom }))
          )
        )
      );
  }

  private getSubscriptionData(): Observable<SubscriptionRMP> {
    return this.store.select(subscriptionsSelector).pipe(map((subscription) => subscription?.subscriptions?.rmp));
  }

  private getLimitReached(): Observable<boolean> {
    return combineLatest({ planData: this.getSubscriptionData(), usage: this.getCurrentUsage() }).pipe(
      map(({ planData, usage }) => usage >= (planData?.aiChatLimit ?? AiChatLimitationsService.AI_CHAT_LIMIT_DEFAULT))
    );
  }

  private getEcom(): Observable<EcomVO> {
    return this.store.select(selectedRetailerEcomSelector).pipe(filter((ecom) => ecom !== undefined));
  }
}
