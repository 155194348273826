import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'overwriteHrefs',
  standalone: true,
})
export class OverwriteHrefsPipe implements PipeTransform {
  transform(text: string, replaceValue = '#'): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    const anchorElements = doc.querySelectorAll('a');

    anchorElements.forEach((anchor) => {
      anchor.href = replaceValue;
    });

    return doc.body.innerHTML;
  }
}
