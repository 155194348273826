import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import { SupplierCard } from 'app/main/marketplace/supplier-card/supplier-card.component';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { SupplierCardModule } from 'app/main/marketplace/supplier-card/supplier-card.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SliderArrowComponent } from '../slider-arrow/slider-arrow.component';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';
import { SectionTitleSliderComponent } from '../../../static/components/section-title-slider/section-title-slider.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-suppliers-slider',
  templateUrl: './suppliers-slider.component.html',
  styleUrls: ['./suppliers-slider.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    TranslateModule,
    SlickCarouselModule,
    MatIconModule,
    SupplierCardModule,
    MatProgressSpinnerModule,
    SliderArrowComponent,
    SkeletonModule,
    SectionTitleComponent,
    SectionTitleSliderComponent,
  ],
  animations: [fuseAnimations],
})
export class SuppliersSliderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() suppliers: SupplierCard[];
  @Input() supplierImages: { [key: number]: string[] };
  @Input() selectedCategoryId: number;
  swiped = false;

  config: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: BreakPoint.xl,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: BreakPoint.lg,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    swipe: false,
    draggable: false,
  };
  ltMd: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(private screenManagerService: ScreenManagerService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (breakpoint) => {
          this.ltMd = breakpoint.matches;
        },
      });
  }
}
