import { Component, OnDestroy, OnInit } from '@angular/core';
import { ForgottenPasswordManagerService } from 'app/main/authentication/forgotten-password-manager.service';

@Component({
  selector: 'app-forgot-password-entry',
  templateUrl: './forgot-password-entry.component.html',
  styleUrls: ['./forgot-password-entry.component.scss'],
  standalone: true,
})
export class ForgotPasswordEntryComponent implements OnInit, OnDestroy {
  constructor(private forgottenPasswordManager: ForgottenPasswordManagerService) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {}

  private init(): void {
    this.forgottenPasswordManager
      .openResetPasswordStep()
      .afterClosed()
      .subscribe(() => {});
  }
}
