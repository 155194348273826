import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RetailerCatalogSettingsVO } from '../../marketplace/retailer-catalogs/retailer-catalog-details/retailer-catalog-settings/abstarct-retailer-settings';
import { CatalogFormVO } from '../../../vo/catalog-form-vo';
import { MappingSettingsService } from '../../../service/mapping-settings-service/mapping-settings.service';
import { CatalogService } from '../../../service/catalog/catalog.service';
import {
  JUMPSELLER_UPDATE_FIELDS,
  JUMPSELLER_UPDATE_OPTIONS,
} from '../../marketplace/retailer-catalogs/retailer-update-catalog-dialog.component';
import { Observable } from 'rxjs';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-retailer-jumpseller-vu-settings',
  templateUrl: './retailer-jumpseller-vu-settings.component.html',
  styleUrls: ['./retailer-jumpseller-vu-settings.component.scss'],
})
export class RetailerJumpsellerVuSettingsComponent implements OnInit {
  fieldsToUpdate: any[];
  optionFieldsUpdate: any[];

  fieldsNotToUpdate: any[];

  selectedItemIds: any[];
  fulfillmentServices: any[];
  locations: any[];

  settingsForm: UntypedFormGroup;

  @Input() retailerSettingsVO: RetailerCatalogSettingsVO;
  @Input() taskId: number;
  @Input() showSaveButton = true;
  @Input() rcatalogId: number;
  @Input() comments: any;
  @Input() supplierVO: CatalogFormVO;
  @Output() voChanged: EventEmitter<RetailerCatalogSettingsVO> = new EventEmitter<RetailerCatalogSettingsVO>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mappingSettingsService: MappingSettingsService,
    private catalogService: CatalogService
  ) {}

  ngOnInit(): void {
    this.getFulfillmentServices();
    this.getLocations();
    this.createForm();
  }

  private subscribeToFormValueChange(): void {
    this.settingsForm.valueChanges.subscribe(() => {
      this.updateChanges();
    });
  }

  private createForm(): void {
    this.loadFieldsToUpdateOptions();
    this.catalogService.getRetailerCatalogSettings(this.rcatalogId).subscribe((data) => {
      this.settingsForm = this.formBuilder.group({
        settings: this.formBuilder.group({
          taxes: ['dont_modify'],
          inventory: ['dont_modify'],
          backorder: ['dont_modify'],
          shipping: ['dont_modify'],
          fulfillment: ['dont_modify'],
          weightUnit: ['dont_modify'],
        }),
        updateOptions: this.formBuilder.group({
          collisionMethod: ['updateFirst'],
          variantCompare: ['sku'],
          multiLocationInventoryChanges: ['dont'],
        }),
      });

      if (data) {
        this.controls['settings'].patchValue(data);
        this.controls['updateOptions'].patchValue(data);
        this.fieldsToUpdate.forEach((item) => {
          if (data.fieldsToUpdate.includes(item.id)) {
            item.checked = true;
          }
        });
        this.optionFieldsUpdate.forEach((item) => {
          if (data.optionFieldsUpdate.includes(item.id)) {
            item.checked = true;
          }
        });
        this.selectedItemIds = this.fieldsToUpdate.filter((item) => item.checked).map((item) => item.id);
      }
      setTimeout(() => this.updateChanges(), 0);
      this.subscribeToFormValueChange();
    });
  }

  private loadFieldsToUpdateOptions(): void {
    this.fieldsToUpdate = JUMPSELLER_UPDATE_FIELDS;
    this.optionFieldsUpdate = JUMPSELLER_UPDATE_OPTIONS;
    this.optionFieldsUpdate.forEach((field) => {
      if (field.id !== 'published_scope') {
        field.checked = true;
      }
    });
  }

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};

  updateChanges(): void {
    this.onChange(this.settingsForm.value);
  }

  writeValue(value: any): void {
    if (!Utils.isNullOrUndefined(value)) {
      this.settingsForm.reset(value);
      this.updateChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getRetailerSettingsVO(): RetailerCatalogSettingsVO {
    this.retailerSettingsVO = new RetailerCatalogSettingsVO();
    this.retailerSettingsVO.id = this.rcatalogId;
    this.retailerSettingsVO.settings = {
      ...this.settingsForm.get('settings').value,
      ...this.settingsForm.get('updateOptions').value,
    };
    delete this.retailerSettingsVO.settings.backorder;
    delete this.retailerSettingsVO.settings.shipping;
    this.retailerSettingsVO.settings.fieldsToUpdate = this.fieldsToUpdate
      .filter((item) => item.checked)
      .map((item) => item.id);
    this.retailerSettingsVO.settings.fieldsToUpdate = this.retailerSettingsVO.settings.fieldsToUpdate.concat(
      this.optionFieldsUpdate.filter((item) => item.checked).map((item) => item.id)
    );

    return this.retailerSettingsVO;
  }

  public getFulfillmentServices(): void {
    this.mappingSettingsService.getFulfillmentServicesByTaskId(this.taskId).subscribe((data) => {
      if (!Utils.isNullOrUndefined(data)) {
        this.fulfillmentServices = data.fulfillment_services;
      }
    });
  }

  private getLocations(): void {
    this.mappingSettingsService.getLocations(this.rcatalogId).subscribe((data) => {
      this.locations = data;
      this.createForm();
      this.subscribeToFormValueChange();
    });
  }

  isStepValid(): boolean {
    return true;
  }

  public getSupplierSettingsVO(): CatalogFormVO {
    const settings = this.settingsForm.get('settings').value;
    this.supplierVO = new CatalogFormVO();
    this.supplierVO.id = this.taskId;
    this.supplierVO.settings = {
      backorder: settings.backorder,
      shipping: settings.shipping,
    };

    return this.supplierVO;
  }

  get controls(): {
    [key: string]: AbstractControl<any, any>;
  } {
    return this.settingsForm.controls;
  }

  saveStep(): Observable<any> {
    const vo = this.getRetailerSettingsVO();
    this.voChanged.emit(vo);
    this.mappingSettingsService.saveRetailerCatalogSettings(vo).subscribe();

    return this.catalogService.updateCatalog(this.getSupplierSettingsVO());
  }
}
