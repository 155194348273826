<div class="wrapper mt-container">
  <div class="inner-container">
    <app-syncee-logo [width]="!ltMd ? '220px' : '136px'"></app-syncee-logo>
    <div class="text-container">
      <div [ngClass]="{ 'typography-headline-3': !ltMd, h2: ltMd }">{{ 'MAINTENANCE.TITLE' | translate }}</div>
      <div
        [ngClass]="{ 'typography-h1-subtitle': !ltMd, 'typography-h3-subtitle': ltMd }"
        [innerHtml]="'MAINTENANCE.SUBTITLE' | translate"
      ></div>
    </div>
  </div>
  <div class="maintenance-footer">
    <div class="mt-footer-text-container">
      <span>Syncee</span>
      <div>|</div>
      <span>All rights reserved &copy; {{ currentYear }}</span>
    </div>
    <div class="mt-footer-text-container">
      <a *ngIf="!ltMd" href="{{ 'mailto:hello@syncee.co' }}" [style.text-decoration]="'none'">{{
        'hello@syncee.co'
      }}</a>
      <div *ngIf="!ltMd">|</div>
      <ng-container *ngTemplateOutlet="social"></ng-container>
    </div>
  </div>
</div>

<ng-template #social>
  <div class="mt-footer-social-icons">
    <app-footer-item *ngFor="let socialPlatform of socialPlatforms" [item]="socialPlatform"></app-footer-item>
  </div>
</ng-template>
