import { Component, Input, OnInit } from '@angular/core';
import { FooterMenuItem } from '../footer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-footer-item',
  templateUrl: './footer-item.component.html',
  styleUrls: ['./footer-item.component.scss'],
})
export class FooterItemComponent implements OnInit {
  @Input()
  item: FooterMenuItem;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(item: FooterMenuItem): void {
    this.dialog.open(item.component);
  }
}
