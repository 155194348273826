import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { AppType, AuthenticationMethod } from '../model/variables/general.variables';

export class SignUpGtmAction implements GtmAction {
  event = GtmEvent.sign_up;

  constructor(public payload: SignUpGtmActionPayload) {}
}

export interface SignUpGtmActionPayload extends AuthenticationMethod, AppType {}
