<ng-container [ngSwitch]="aiChatService.layout$ | async">
  <ng-container *ngSwitchCase="aiChatLayoutEnum.SIDEBAR">
    <app-ai-chat-layout-sidebar>
      <app-ai-chat-base class="base"></app-ai-chat-base>
    </app-ai-chat-layout-sidebar>
  </ng-container>
  <ng-container *ngSwitchCase="aiChatLayoutEnum.POPOVER">
    <app-ai-chat-layout-popover>
      <app-ai-chat-base class="base"></app-ai-chat-base>
    </app-ai-chat-layout-popover>
  </ng-container>
  <ng-container *ngSwitchCase="aiChatLayoutEnum.MOBILE">
    <app-ai-chat-layout-mobile>
      <app-ai-chat-base class="base"></app-ai-chat-base>
    </app-ai-chat-layout-mobile>
  </ng-container>
</ng-container>
