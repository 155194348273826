import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferenceCategoryComponent } from './components/preference-category/preference-category.component';
import { PreferenceCountryMultiComponent } from './components/preference-country-multi/preference-country-multi.component';
import { MultipleCountrySelectorComponent } from '../../shared/components/multiple-country-selector/multiple-country-selector.component';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ChipListSelectorComponent } from '../../shared/components/chip-list-selector/chip-list-selector.component';

@NgModule({
  declarations: [PreferenceCategoryComponent, PreferenceCountryMultiComponent],
  imports: [CommonModule, MultipleCountrySelectorComponent, FlexModule, TranslateModule, ChipListSelectorComponent],
  exports: [PreferenceCountryMultiComponent, PreferenceCategoryComponent],
})
export class PreferencesModule {}
