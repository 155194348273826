import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { IFilterItem } from './IFilterItem';
import { Utils } from '../../../utils/utils';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { FilterSuggestionsVO } from '../../../vo/filter-suggestions-vo';
import { FILTER_VALUES_OFFSETS, SUGGESTION_EXCEPTIONS } from '../../../utils/Constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent implements OnInit, AfterViewInit, IFilterItem, OnDestroy {
  @ViewChild('filterValueInput', { static: false }) filterValueInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('filtersForm', { static: false }) filtersForm: NgForm;
  @ViewChild('chipList', { static: false }) chipList: MatChipList;

  @ViewChild(MatAutocompleteTrigger, { static: false }) trigger;

  public matChipListForm: UntypedFormGroup;

  private _filterItem: FilterItem;
  @Output() filterItemChange = new EventEmitter<FilterItem>();

  @Input() set filterItem(val: FilterItem) {
    this._filterItem = val;
    this.filterItemChange.emit(this.filterItem);
    this.init();
  }

  get filterItem(): FilterItem {
    return this._filterItem;
  }

  selectedField: FieldStructure;
  fields: FieldStructure[] = [];
  conditions: FilterCondition[] = [];
  touchedMatChipList = false;

  disableInput = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  filterCtrl = new UntypedFormControl();
  filteredValues: Observable<string[]>;

  allValues: string[] = [];
  isAutoListSelected = false;
  pageSize = 10;
  maxLength = 10;
  filteredLength = 0;

  private _unsubscribeAll: Subject<void>;
  private timeout = null;
  private needPrefixAfterCharNum = 4;
  suggestions: string[];
  @Input() taskId?;
  public hasMore = true;

  constructor(
    private fb: UntypedFormBuilder,
    private productSearchService: ProductSearchService,
    private translate: TranslateService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  validateArrayNotEmpty(c: UntypedFormControl): any {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  ngOnInit(): void {
    this.matChipListForm
      .get('values')
      .statusChanges.subscribe((status) => (this.chipList.errorState = status === 'INVALID' ? true : false));
  }

  ngAfterViewInit(): void {}

  removeSuggestions(): void {
    this.suggestions = [];
  }

  public searchInputChanged(event): void {
    clearTimeout(this.timeout);
    if (event.key !== 'Enter') {
      this.timeout = setTimeout(() => {
        this.getAutocomplete();
      }, 300);
    }
  }

  private getSearchTerm(): string {
    const searchTerm = Utils.isNullOrUndefined(this.filterCtrl.value) ? '' : this.filterCtrl.value;
    const prefix =
      !Utils.isNullOrUndefined(this.filterCtrl.value) &&
      (this.filterItem.condition.indexOf('contains') > -1 || this.filterCtrl.value.length > this.needPrefixAfterCharNum)
        ? '*'
        : '';
    const suffix = '*';
    return prefix + searchTerm + suffix;
  }

  private getAutocomplete(loadMore = false): void {
    if (!Utils.isNullOrUndefined(this.taskId)) {
      // new autocomplete data if task id is provided
      if (this.filterItem.field && !SUGGESTION_EXCEPTIONS.includes(this.filterItem.field)) {
        const restData: FilterSuggestionsVO = {
          completionField: this.filterItem.field.replace('.keyword', ''),
          searchTerm: this.getSearchTerm(),
          from: loadMore ? this.maxLength - this.pageSize : 0,
          size: loadMore ? this.pageSize : this.maxLength,
          suggestContexts: [
            {
              contextName: 'TASK_ID',
              contextValues: [this.taskId],
            },
          ],
        };

        this.productSearchService
          .filterSuggestions(restData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            this.suggestions = loadMore ? this.suggestions.concat(res) : res;
            this.hasMore = res.length !== 0;
          });
      }
    } else {
      // old autocomplete data if no task id is provided
      this.filteredValues = this.filterCtrl.valueChanges.pipe(
        startWith(null),
        map((sValue: string | null) => {
          if (this.filterCtrl.value) {
            // this.maxLength = this.pageSize;
            return this._filter(this.filterCtrl.value);
          } else {
            // this.maxLength = this.pageSize;
            this.filteredLength = 0;
            return this.allValues.slice(0, this.maxLength);
          }
        })
      );
    }
  }

  init(): void {
    this.fields = this.filterItem.fieldStructures;
    if (!Utils.isNullOrUndefined(this.filterItem.field)) {
      const selectedFields = this.fields.filter((value) => {
        return value.key === this.filterItem.field;
      });
      if (!Utils.isNullOrUndefined(selectedFields) && selectedFields.length > 0) {
        this.selectedField = selectedFields[0];
        if (
          this.selectedField.valuesLimit > 0 &&
          this.filterItem.values.length >= this.selectedField.valuesLimit + this.getLimitOffset()
        ) {
          this.disableInput = true;
        }
        this.conditions = selectedFields[0].conditions;
        this.allValues = selectedFields[0].autoValues;
      }
    }
    this.matChipListForm = this.fb.group({
      values: this.fb.array(this.filterItem.values, this.validateArrayNotEmpty),
    });
  }

  add(event: MatChipInputEvent): void {
    setTimeout(() => {
      if (this.isAutoListSelected) {
        this.isAutoListSelected = false;
      } else {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
          this.addValue(value);
        }

        if (input) {
          input.value = '';
        }

        this.filterCtrl.setValue(null);
      }
    }, 1000);
  }

  remove(form, formValueIndex: number, filterValue: string = null): void {
    if (formValueIndex >= 0) {
      form.get('values').removeAt(formValueIndex);
      this.filterItem.values.splice(formValueIndex, 1);
      this.filterItemChange.emit(this.filterItem);
      this.disableInput = false;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.isAutoListSelected = true;
    this.addValue(event.option.value);
    this.filterValueInput.nativeElement.value = '';
    this.filterCtrl.setValue(null);
  }

  private checkAndDisableInput(): void {
    if (
      this.selectedField.valuesLimit > 0 &&
      this.filterItem.values.length >= this.selectedField.valuesLimit + this.getLimitOffset()
    ) {
      this.disableInput = true;
    }
  }

  private getLimitOffset(): number {
    const fieldLimits = FILTER_VALUES_OFFSETS.find((limit) => limit.field === this._filterItem.field);
    if (Utils.isNullOrUndefined(fieldLimits)) {
      return 0; // no offset is set for the selected filter
    } else {
      const offset = fieldLimits['conditions'].find((condition) => condition.key === this._filterItem.condition);
      return Utils.isNullOrUndefined(offset) ? 0 : offset.offset;
    }
  }

  public getTooltipForInput(): string {
    // You cannot set more than 50 filter values for a field type.
    if (!Utils.isNullOrUndefined(this.selectedField)) {
      return (
        this.translate.instant('FILTER.FILTER_ITEM.TOOLTIP_PRE') +
        (this.selectedField.valuesLimit + this.getLimitOffset()) +
        this.translate.instant('FILTER.FILTER_ITEM.TOOLTIP_AFTER')
      );
    } else {
      return null;
    }
  }

  private _filter(searchValue: string): string[] {
    searchValue = searchValue.toLowerCase();
    const usedValues = this.matChipListForm.get('values') as UntypedFormArray;
    this.filteredLength = this.allValues.filter(
      (value) =>
        value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && usedValues.getRawValue().indexOf(searchValue)
    ).length;
    return this.allValues
      .filter(
        (value) =>
          value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && usedValues.getRawValue().indexOf(searchValue)
      )
      .slice(0, this.maxLength);
  }

  compareFieldByKey(field: FieldStructure, key: string): boolean {
    return field && key && field.key === key;
  }

  fieldChanged(event): void {
    this.selectedField = event.value;
    this.conditions = event.value.conditions;
    this.allValues = event.value.autoValues;
    this.filterItem.field = event.value.key;
    if (
      this.selectedField.valuesLimit > 0 &&
      this.filterItem.values.length >= this.selectedField.valuesLimit + this.getLimitOffset()
    ) {
      this.filterItem.values = [];
      this.disableInput = false;
      const valuesFormArray = <UntypedFormArray>this.matChipListForm.get('values');
      while (valuesFormArray.length !== 0) {
        valuesFormArray.removeAt(0);
      }
    } else {
      this.disableInput = false;
    }
    this.filterItemChange.emit(this.filterItem);
  }

  setDisableValue(value): boolean {
    const usedValues = this.matChipListForm.get('values') as UntypedFormArray;
    return usedValues.getRawValue().indexOf(value) > -1;
  }

  conditionChanged(event: MatSelectChange): void {
    this.disableInput =
      this.selectedField.valuesLimit > 0 &&
      this.filterItem.values.length >= this.selectedField.valuesLimit + this.getLimitOffset();
    this.filterItemChange.emit(this.filterItem);
  }

  isValid(): boolean {
    Utils.markFormGroupTouched(this.filtersForm.control);
    if (Utils.isNullOrUndefined(this.chipList)) {
      return this.filtersForm.valid;
    }
    Utils.markFormGroupTouched(this.matChipListForm);
    this.touchedMatChipList = true;
    if (!this.matChipListForm.valid) {
      this.chipList.errorState = true;
    }
    return this.matChipListForm.valid && this.filtersForm.valid && this.filterItem.values.length > 0;
  }

  matChipListFocusOut(): void {
    this.touchedMatChipList = true;
  }

  addValue(value: string): void {
    if (
      this.selectedField.valuesLimit > 0 &&
      this.filterItem.values.length >= this.selectedField.valuesLimit + this.getLimitOffset()
    ) {
      return;
    }
    const valuesFormArray = <UntypedFormArray>this.matChipListForm.get('values');
    valuesFormArray.push(this.fb.control(value));
    this.filterItem.values.push(value);
    this.checkAndDisableInput();
    this.filterItemChange.emit(this.filterItem);
  }

  loadMore(): void {
    this.maxLength = this.maxLength + this.pageSize;
    this.isAutoListSelected = true;
    this.getAutocomplete(true);
    // this.setFilteredValuesChange();
  }

  setDefault(): void {
    this.maxLength = this.pageSize;
    this.filteredLength = 0;
    this.getAutocomplete();
    // this.setFilteredValuesChange();
  }

  openAutoComplete(): void {
    this.setDefault();
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  public isExistsCondition(): boolean {
    return ['exists', 'not_exists'].includes(this.filterItem.condition);
  }

  ngOnDestroy(): void {
    console.log('adsadsa');
  }
}

export interface FilterItem {
  fieldStructures: FieldStructure[];
  field: string;
  condition: string;
  values: string[];
}

export interface FieldStructure {
  name: string;
  key: string;
  valuesLimit: number;
  valuesOperator: string;
  conditions: FilterCondition[];
  autoValues: string[];
}

export interface FilterCondition {
  value: string;
  display: string;
}
