import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from 'app/service/rest/microservices/spring-rest.service';
import { fetchAllPages } from 'app/utils/operator/fetch-all-pages';
import { Utils } from 'app/utils/utils';
import { SpringPage } from 'app/vo/pagination/spring-page';
import { ShippingProfileDto, ShippingProfileType } from 'app/vo/shipping-zones/shipping-profile-dto';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShippingProfileRestService {
  constructor(private springRestService: SpringRestService) {}

  createShippingProfile(shippingProfileData: ShippingProfileData): Observable<ShippingProfileDto> {
    return this.springRestService.post(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile`,
      shippingProfileData,
      undefined,
      true
    );
  }

  deleteShippingProfile(shippingProfileID: number): Observable<void> {
    return this.springRestService
      .delete(MicroserviceNames.ORDER, `/v1/Shipping/Profile/${shippingProfileID}`, undefined, true)
      .pipe(map(() => {}));
  }

  patchShippingProfile(
    shippingProfileID: number,
    shippingProfileData: Partial<ShippingProfileData>
  ): Observable<ShippingProfileDto> {
    return this.springRestService.patch(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/${shippingProfileID}`,
      shippingProfileData,
      undefined,
      true
    );
  }

  putShippingProfile(
    shippingProfileID: number,
    shippingProfileData: ShippingProfileData
  ): Observable<ShippingProfileDto> {
    return this.springRestService.put(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/${shippingProfileID}`,
      shippingProfileData,
      undefined,
      true
    );
  }

  getShippingProfiles(from: number, size: number): Observable<SpringPage<ShippingProfileDto>> {
    return this.springRestService.get(MicroserviceNames.ORDER, `/v1/Shipping/Profile`, { from, size }, true);
  }

  getAllShippingProfiles(): Observable<ShippingProfileDto[]> {
    return this.getShippingProfiles(0, 100).pipe(fetchAllPages((from, size) => this.getShippingProfiles(from, size)));
  }

  getShippingProfileById(shippingProfileID: number): Observable<ShippingProfileDto> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/${shippingProfileID}`,
      undefined,
      true
    );
  }

  getShippingProfileByUserIds(
    size: number,
    page: number,
    full: boolean,
    ...userIds: number[]
  ): Observable<SpringPage<ShippingProfileDto>> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/FindByUserIds`,
      { size, page, userIds, full },
      true
    );
  }

  getAllShippingProfileByUserIds(full: boolean, ...userIds: number[]): Observable<ShippingProfileDto[]> {
    return this.getShippingProfileByUserIds(100, 0, full, ...userIds).pipe(
      fetchAllPages((from, size) => this.getShippingProfileByUserIds(from, size, full, ...userIds))
    );
  }

  saveShippingProfile(
    shippingProfileData: ShippingProfileData,
    shippingProfileID?: number
  ): Observable<ShippingProfileDto> {
    if (Utils.isNullOrUndefined(shippingProfileID)) {
      return this.createShippingProfile(shippingProfileData);
    } else {
      return this.patchShippingProfile(shippingProfileID, shippingProfileData);
    }
  }
}

export interface ShippingProfileData {
  name: string;
  type: ShippingProfileType;
}
