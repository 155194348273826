import { SubscriptionVO } from 'app/vo/subscription-vo';
import { SubscriptionActionTypes, SUBSCRIPTION_ALL_ACTIONS } from './subscription.actions';

export interface SubscriptionsState {
  currentSubscriptions: SubscriptionVO;
  isLoading: boolean;
}

const initialState: SubscriptionsState = {
  currentSubscriptions: !!localStorage.getItem('currentsubscriptions')
    ? JSON.parse(localStorage.getItem('currentsubscriptions'))
    : undefined,
  isLoading: false,
};

export function subscriptionsReducer(state = initialState, action: SUBSCRIPTION_ALL_ACTIONS): SubscriptionsState {
  switch (action.type) {
    case SubscriptionActionTypes.SUBSCRIPTION_FETCH_SUCCESS: {
      return {
        ...state,
        currentSubscriptions: action.payload.subscriptions,
        isLoading: false,
      };
    }
    case SubscriptionActionTypes.SUBSCRIPTION_FETCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default: {
      return state;
    }
  }
}
