import { Injectable } from '@angular/core';
import { IMPORT_LIST_PRICE_OPERATOR_TYPE } from 'app/shared/components/import-list-price-settings/types/operator.type';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PricingPerRanges, PricingResponse, PricingService } from '../../../../service/pricing/pricing.service';
import { ImportListCollection } from '../../../../shared/components/import-list-price-settings/model/import-list-collection';
import { ImportListPrice } from '../../../../shared/components/import-list-price-settings/model/import-list-price';

@Injectable()
export class SupplierTaskPriceSettingsService {
  constructor(private pricingService: PricingService) {}

  public getDataForPricingComponent(catalogId: number): Observable<SupplierCategoriesAndPricesMapped> {
    return combineLatest([this.getPricing(catalogId), this.getCategories(catalogId)]).pipe(
      map(([pricingResponse, categoriesResponse]) => {
        const pricing: ImportListPrice[] = this.mapPricingResponseToImportListPriceArray(pricingResponse);
        const collections: ImportListCollection[] = this.mapCategoriesToImportListCollections(
          categoriesResponse,
          pricingResponse.pricing
        );
        return {
          collections,
          pricing,
          enabledPercentageOperators: [1, -1],
          enabledFixOperators: [1, -1],
          defaultPercentageOperator: 1,
          defaultFixOperator: 1,
        };
      })
    );
  }

  private mapPricingResponseToImportListPriceArray(response: PricingResponse): ImportListPrice[] {
    return Object.entries(response.pricing).map(([key, pricingValues]) => ({
      collectionId: key ?? '',
      priceItemList: Object.entries(pricingValues).map(([rangeKey, rangeValues], index, ranges) => ({
        from: +rangeKey,
        fixAmount: Math.abs(rangeValues.fix),
        fixAmountOperator: rangeValues.fix == 0 ? null : rangeValues.fix.toString().includes('-') ? -1 : 1,
        percentage: Math.abs(rangeValues.perc),
        percAmountOperator: rangeValues.perc == 0 ? null : rangeValues.perc.toString().includes('-') ? -1 : 1,
        to: ranges[index + 1] ? +ranges[index + 1][0] : null,
      })),
    }));
  }

  private mapCategoriesToImportListCollections(
    categories: string[],
    pricing: Record<string, PricingPerRanges>
  ): ImportListCollection[] {
    return categories.map((category) => ({
      id: category,
      title: category,
      hasPriceSettings: !!Object.entries(pricing).some(([key, value]) => key === category),
    }));
  }

  private getPricing(catalogId: number): Observable<PricingResponse> {
    return this.pricingService.getPricing(catalogId).pipe(map((response) => response as PricingResponse));
  }

  private getCategories(catalogId: number): Observable<string[]> {
    return this.pricingService.getCategoriesByCatalogId(catalogId).pipe(map((value) => value as string[]));
  }
}
export interface SupplierCategoriesAndPricesMapped {
  collections: ImportListCollection[];
  pricing: ImportListPrice[];
  enabledPercentageOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[];
  enabledFixOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[];
  defaultPercentageOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE;
  defaultFixOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE;
}
