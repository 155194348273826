export class TabFileSettsavedOutVo {
  private _tabIndex;
  private _fileId;
  private _extension: string;

  get tabIndex(): number {
    return this._tabIndex;
  }

  set tabIndex(value) {
    this._tabIndex = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value) {
    this._fileId = value;
  }

  get extension(): string {
    return this._extension;
  }

  set extension(value: string) {
    this._extension = value;
  }

  constructor(tabIndex, fileId, extension?: string) {
    this.tabIndex = tabIndex;
    this.fileId = fileId;
    this._extension = extension;
  }
}
