<div class="outer-container" [ngClass]="wrapperClass">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
    fxLayoutGap.lt-md="28px"
    class="h-100-p"
    [ngClass]="innerWrapperClass"
  >
    <div fxLayout="column" fxLayoutAlign="center center" class="integration-title-container">
      <app-section-title
        [title]="titleKey | translate"
        [description]="descriptionKey | translate"
        [hasBottomPadding]="false"
      ></app-section-title>
    </div>
    <div class="inner-container">
      <ng-container *ngFor="let card of cardContent">
        <a
          fxLayout="row"
          fxLayoutAlign="center center"
          class="card-container"
          [href]="card.href"
          [target]="card?.target ?? '_blank'"
        >
          <img class="image-card" [src]="card.logo" [alt]="card.name + ' logo'" />
        </a>
      </ng-container>
    </div>
  </div>
</div>
