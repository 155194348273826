import { EnterComponent } from './components/enter/enter.component';
import { EcomComponent } from './components/ecom/ecom.component';
import { KeepRoleComponent } from './components/keep-role/keep-role.component';

import { Step } from './model/step';
import { ChangeRoleDialogDataStepTextRewrite } from './model/change-role-dialog-data';

const BASE = 'CHANGE_ROLE_DIALOG.';

export const CENTER_REWRITE_RETAILER: ChangeRoleDialogDataStepTextRewrite[] = [
  {
    key: Step.ENTER,
    title: `${BASE}RETAILER.REWRITE_TEXT.CENTER.ENTER.TITLE`,
    description: `${BASE}RETAILER.REWRITE_TEXT.CENTER.ENTER.DESCRIPTION`,
  },
];

export const CENTER_REWRITE_SUPPLIER: ChangeRoleDialogDataStepTextRewrite[] = [
  {
    key: Step.ENTER,
    title: `${BASE}SUPPLIER.REWRITE_TEXT.CENTER.ENTER.TITLE`,
    description: `${BASE}SUPPLIER.REWRITE_TEXT.CENTER.ENTER.DESCRIPTION`,
  },
];

export const SUPPLIER_CONFIG_LIST = [
  {
    step: Step.ENTER,
    component: EnterComponent,
    payload: {
      title: `${BASE}SUPPLIER.ENTER.TITLE`,
      description: `${BASE}SUPPLIER.ENTER.DESCRIPTION`,
      next: `${BASE}SUPPLIER.ENTER.NEXT`,
      cancel: `${BASE}SUPPLIER.ENTER.CANCEL`,
      banner_image_large: 'assets/images/change-role-dialog/supplier-center-popup-large.png',
      banner_image_small: 'assets/images/change-role-dialog/supplier-center-popup-small.png',
    },
  },
  {
    step: Step.KEEP_ROLE,
    component: KeepRoleComponent,
    payload: {
      title: `${BASE}SUPPLIER.KEEP_ROLE.TITLE`,
      description: `${BASE}SUPPLIER.KEEP_ROLE.DESCRIPTION`,
      next: `${BASE}SUPPLIER.KEEP_ROLE.NEXT`,
      keep_role: `${BASE}SUPPLIER.KEEP_ROLE.KEEP_ROLE`,
      remove_role: `${BASE}SUPPLIER.KEEP_ROLE.REMOVE_ROLE`,
    },
  },
  {
    step: Step.ECOM,
    component: EcomComponent,
    payload: {
      title: `${BASE}SUPPLIER.ECOM.TITLE`,
      description: `${BASE}SUPPLIER.ECOM.DESCRIPTION`,
      continue: `${BASE}SUPPLIER.ECOM.CONTINUE`,
      close: `${BASE}SUPPLIER.ECOM.CLOSE`,
    },
  },
];

export const RETAILER_CONFIG_LIST = [
  {
    step: Step.ENTER,
    component: EnterComponent,
    payload: {
      title: `${BASE}RETAILER.ENTER.TITLE`,
      description: `${BASE}RETAILER.ENTER.DESCRIPTION`,
      next: `${BASE}RETAILER.ENTER.NEXT`,
      cancel: `${BASE}SUPPLIER.ENTER.CANCEL`,
      banner_image_large: 'assets/images/change-role-dialog/retailer-center-popup-large.png',
      banner_image_small: 'assets/images/change-role-dialog/retailer-center-popup-small.png',
    },
  },
  {
    step: Step.KEEP_ROLE,
    component: KeepRoleComponent,
    payload: {
      title: `${BASE}RETAILER.KEEP_ROLE.TITLE`,
      description: `${BASE}RETAILER.KEEP_ROLE.DESCRIPTION`,
      next: `${BASE}RETAILER.KEEP_ROLE.NEXT`,
      keep_role: `${BASE}RETAILER.KEEP_ROLE.KEEP_ROLE`,
      remove_role: `${BASE}RETAILER.KEEP_ROLE.REMOVE_ROLE`,
    },
  },
  {
    step: Step.ECOM,
    component: EcomComponent,
    payload: {
      title: `${BASE}RETAILER.ECOM.TITLE`,
      description: `${BASE}RETAILER.ECOM.DESCRIPTION`,
      continue: `${BASE}RETAILER.ECOM.CONTINUE`,
      close: `${BASE}RETAILER.ECOM.CLOSE`,
    },
  },
];
