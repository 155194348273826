import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RolesEnum } from 'app/vo/roles/roles';
import { NotificationItemVO } from '../notifications-list.component';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() item: NotificationItemVO;
  @Input() isAdmin: boolean;
  @Input() actualRole: RolesEnum;
  @Output() deleteNotification = new EventEmitter<number>();
  @Output() changeStatus = new EventEmitter<number>();

  roles = RolesEnum;

  constructor() {}

  ngOnInit(): void {}
}
