import { Action } from '@ngrx/store';
import { ContinentDto } from 'app/vo/countries-location/continent-dto';
import { CountryLocationDto } from 'app/vo/countries-location/country-location-dto';

export enum CountriesLocationActionTypes {
  INIT_COUNTRIES = '[Countries] Init countries',
  GET_COUNTRIES = '[Countries] Get countries',
  GET_COUNTRIES_SUCCESS = '[Countries] Get countries success',
  GET_CONTINENTS = '[Countries] Get continents',
  GET_CONTINENTS_SUCCESS = '[Countries] Get continents success',
}

export class InitCountries implements Action {
  readonly type = CountriesLocationActionTypes.INIT_COUNTRIES;
  constructor() {}
}

export class GetCountriesLocations implements Action {
  readonly type = CountriesLocationActionTypes.GET_COUNTRIES;
  constructor() {}
}

export class GetCountriesLocationsSuccess implements Action {
  readonly type = CountriesLocationActionTypes.GET_COUNTRIES_SUCCESS;
  constructor(public payload: CountryLocationDto[]) {}
}

export class GetContinents implements Action {
  readonly type = CountriesLocationActionTypes.GET_CONTINENTS;
  constructor() {}
}

export class GetContinentsSuccess implements Action {
  readonly type = CountriesLocationActionTypes.GET_CONTINENTS_SUCCESS;
  constructor(public payload: ContinentDto[]) {}
}

export type COUNTRIES_LOCATIONS_ACTIONS_ALL =
  | GetCountriesLocations
  | GetCountriesLocationsSuccess
  | GetContinents
  | GetContinentsSuccess;
