import { Component, Input } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import type { ProductCardWidths } from '../../product-card-list/product-card-list.component';

@Component({
  selector: 'app-skeleton-product-card-list',
  templateUrl: './skeleton-product-card-list.component.html',
  styleUrls: ['./skeleton-product-card-list.component.scss'],
  animations: [fuseAnimations],
})
export class SkeletonProductCardListComponent {
  @Input() numberOfProducts: number;

  @Input() needsFillers = false;

  @Input() productCardWidth: ProductCardWidths = {
    xs: '50%',
    sm: '50%',
    md: '33.333%',
    lg: '20%',
    xl: '16.666%',
  };
}
