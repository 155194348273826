<ng-container *ngIf="!messagesLoading; else loadingState">
  <ng-container *ngIf="!messagesError; else errorMessage">
    <div *ngIf="channel" class="chat-body-container">
      <app-chat-body-header
        [isInnerChat]="isInnerChat"
        [currentUserId]="currUserId"
        [channel]="channel"
        [isMobile]="isMobile"
        (backClick)="handleBackClick()"
        [channelSideHidden]="channelSideHidden"
      ></app-chat-body-header>
      <div
        #scrollDesk
        overflowDirective
        infiniteScroll
        [infiniteScrollUpDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollTop]="scrollable ? scrollDesk.scrollHeight : 160"
        [scrollWindow]="false"
        (scrolledUp)="onScrollHandle()"
        class="chat-body-messages"
        [class.innerChat]="isInnerChat"
      >
        <div #messageContainer class="messages-container">
          <ng-container *ngIf="!noMessagesYet; else startConversation">
            <ng-container *ngFor="let messageGroup of groupedMessages">
              <div class="message-group" fxLayout="column" fxLayoutGap="16px">
                <div class="centered-text message-date">{{ messageGroup.date | localizedDate }}</div>
                <ng-container *ngFor="let mObj of messageGroup.messages">
                  <div
                    class="message-payload"
                    fxLayout="row"
                    fxLayoutAlign="stretch center"
                    fxLayoutGap="8px"
                    [ngClass]="mObj.userId == currUserId ? 'side-right' : 'side-left'"
                  >
                    <ng-container *ngIf="mObj.userId !== currUserId">
                      <img
                        #avatarImage
                        class="channel-avatar"
                        *ngIf="!!channel.avatar && !useFallbackAvatar"
                        [src]="channel.avatar"
                        [alt]="channel.name"
                        (error)="useFallbackAvatar = true"
                      />
                      <app-avatar
                        type="text"
                        class="chat-user-avatar"
                        *ngIf="!channel.avatar || !!useFallbackAvatar"
                        [diameter]="50"
                        >{{ channel.name.substring(0, 2) }}</app-avatar
                      >
                    </ng-container>
                    <div class="message-text-wrapper" [class.innerChat]="isInnerChat">
                      <app-chat-message-item
                        [direction]="mObj.userId == currUserId ? 'chat-side-right' : 'chat-side-left'"
                        [options]="{
                          background:
                            mObj.userId == currUserId ? hostMessageTheme.background : recipientMessageTheme.background,
                          color:
                            mObj.userId == currUserId ? hostMessageTheme.textColor : recipientMessageTheme.textColor
                        }"
                        [message]="mObj"
                      >
                      </app-chat-message-item>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div>
        <app-chat-body-input (messageEvent)="newMessageHandler($event)"></app-chat-body-input>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #startConversation>
  <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="16px" class="centered">
    <app-single-alert-with-icon
      icon="forum"
      titleKey="CHAT.NEW_CONVERSATION.TITLE"
      descriptionKey="CHAT.NEW_CONVERSATION.DESCRIPTION"
    ></app-single-alert-with-icon>
  </div>
</ng-template>

<ng-template #loadingState>
  <div style="height: 100%" class="centered">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #errorMessage>
  <div style="height: 100%" class="centered">
    <app-error-message></app-error-message>
  </div>
</ng-template>
