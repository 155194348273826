import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryShippingInfo } from '../vo/country-shipping-info';
import { CurrencyExchangeToPipe } from '../../../pipes/currency-exchange-to.pipe';
import { CurrencyTransformPipe } from '../../../pipes/currency-transform-pipe.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { ShippingRateConditionDto, ShippingRateType } from '../../../../vo/shipping-zones/shipping-rate-condition-dto';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-shipping-details-rates-mobile',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyExchangeToPipe,
    CurrencyTransformPipe,
    TranslateModule,
    FlexModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
  ],
  templateUrl: './shipping-details-rates-mobile.component.html',
  styleUrls: ['./shipping-details-rates-mobile.component.scss'],
})
export class ShippingDetailsRatesMobileComponent implements OnInit {
  @Input() rates: CountryShippingInfo[];
  @Input() supplierCurrency: string;
  @Input() hasAppliedShippingZone: boolean;
  @Input() isAppliedShippingZone: boolean;

  protected readonly shippingRateType = ShippingRateType;

  currency: string;

  readonly displayedColumns = ['location', 'condition', 'incrementalFee', 'shippingTime', 'shippingCost'];

  constructor(private _marketplaceEcomService: MarketplaceEcomService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currency = this._marketplaceEcomService.getCurrency();
  }
}
