<form class="form-wrapper" [formGroup]="basicFormGroup" novalidate>
  <div>
    <mat-form-field class="w-100p" [appearance]="'fill'">
      <mat-label>{{ 'AUTHENTICATION.EMAIL' | translate }}</mat-label>
      <input
        id="email"
        name="email"
        matInput
        placeholder="{{ 'AUTHENTICATION.EMAIL' | translate }}"
        formControlName="email"
        required
      />
      <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100p" [appearance]="'fill'">
      <mat-label>{{ 'AUTHENTICATION.PASSWORD' | translate }}</mat-label>
      <input
        id="password"
        name="password"
        matInput
        type="password"
        formControlName="password"
        placeholder="{{ 'AUTHENTICATION.PASSWORD' | translate }}"
        required
      />
      <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <div class="remember-forgot-password" fxLayout="row" fxLayoutAlign="flex-end center">
      <a class="forgot-password-dialog" (click)="openForgottenPasswordDialog()">
        {{ 'AUTHENTICATION.LOGIN.FORGOT_PASSWORD' | translate }}
      </a>
    </div>
  </div>
  <button mat-flat-button color="primary" [disabled]="!submitEnabled" (click)="submitBasicLogin()">
    {{ 'AUTHENTICATION.SIGN_IN' | translate }}
  </button>
  <app-login-google-button
    [googleBtnId]="role === RETAILER ? 'retailerLoginBtn' : 'supplierLoginBtn'"
    (success)="successSociaLoginHandle('google')"
    [role]="role"
  ></app-login-google-button>
  <!-- <app-retailer-login-footer></app-retailer-login-footer> -->
</form>
