<form id="FTPUploadCard" #shopifyFileForm="ngForm" novalidate class="simple-form">
  <div *ngIf="domains" fxLayout="column" fxLayoutAlign="start start">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'TASKWIZARD.ADDFILES.FTP.DOMAIN' | translate }}</mat-label>
      <mat-select name="ecoms" [(ngModel)]="ecomId" placeholder="Select a domain">
        <mat-option *ngFor="let item of domains" [value]="item.id">
          {{ item.domain }}
        </mat-option>
      </mat-select>
      <!--<input name="domain" matInput appShopifyValidation placeholder="Domain | example: https://example.myshopify.com"-->
      <!--[(ngModel)]="domain" #domainModel="ngModel" required>-->
      <!--<mat-error *ngIf="domainModel.errors?.required">Shopify domain is required!</mat-error>-->
      <!--<mat-error *ngIf="!domainModel.errors?.required && domainModel.errors?.shopifyDomainIsNotValid">Shopify domain is invalid!</mat-error>-->
    </mat-form-field>
    <!--<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">-->
    <!--&lt;!&ndash;UserName Input&ndash;&gt;-->
    <!--<mat-form-field fxFlex="50">-->
    <!--<input id="ApiKey" name="ApiKey" matInput placeholder="API key" autocomplete="off"-->
    <!--[(ngModel)]="apiKey" required>-->
    <!--<mat-error>API key is required!</mat-error>-->
    <!--</mat-form-field>-->

    <!--&lt;!&ndash;Password Input&ndash;&gt;-->
    <!--<mat-form-field fxFlex="50">-->
    <!--<input id="shopifyPassword" name="shopifyPassword" matInput type="password" placeholder="Password" autocomplete="off"-->
    <!--[(ngModel)]="password" required>-->
    <!--<mat-error>Password is required!</mat-error>-->
    <!--</mat-form-field>-->
    <!--</div>-->
  </div>
</form>
