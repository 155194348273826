import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';

import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';

import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-skeleton-product-card',
  templateUrl: './skeleton-product-card.component.html',
  styleUrls: ['./skeleton-product-card.component.scss'],
})
export class SkeletonProductCardComponent implements OnInit, OnDestroy {
  public ltMd: boolean;

  public isAuthenticated$: Observable<boolean>;

  private unsubscribe = new Subject<void>();

  constructor(private store: Store<AppState>, private screenManagerService: ScreenManagerService) {}

  public ngOnInit(): void {
    this.initScreenSizeSubscription();
    this.initAuthenticationSubscription();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initScreenSizeSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this.unsubscribe), this.screenManagerService.stateMatchesOperator())
      .subscribe((ltMd) => (this.ltMd = ltMd));
  }

  private initAuthenticationSubscription(): void {
    this.isAuthenticated$ = this.store.select(isAuthenticatedSelector).pipe(takeUntil(this.unsubscribe));
  }
}
