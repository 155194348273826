import { Directive, HostListener } from '@angular/core';
import { Intercom } from 'ng-intercom';

/**
 * Opens the intercom's dialog.
 *
 * Add 'intercom' id to the corresponding item.
 *
 * @example
 * <a href="intercom">contact us</a>
 *
 * */
@Directive({
  selector: '[intercomOpen]',
  standalone: true,
})
export class IntercomOpenDirective {
  private readonly INTERCOM_ID = 'openIntercom';
  @HostListener('click', ['$event']) onClick(event): void {
    if (event.target.id === this.INTERCOM_ID) {
      this.openIntercom();
    }
  }

  constructor(private intercom: Intercom) {}

  private openIntercom(): void {
    this.intercom.show();
  }
}
