<div fxLayoutAlign="start start" fxLayoutGap="16px" class="mx-20 my-24">
  <app-ai-chat-message-model-avatar fxFlex="0 0 32px"></app-ai-chat-message-model-avatar>
  <div class="loading-container">
    <div class="pulse-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
