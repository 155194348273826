import { Component, Input, OnInit } from '@angular/core';
import { SupplierPublicInformationVariant } from './model/supplier-public-information-variant';
import { SupplierPublicInformationService } from './service/supplier-public-information.service';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-supplier-public-information',
  templateUrl: './supplier-public-information.component.html',
  styleUrls: ['./supplier-public-information.component.scss'],
  providers: [SupplierPublicInformationService],
})
export class SupplierPublicInformationComponent implements OnInit {
  @Input() variant: SupplierPublicInformationVariant = 'DEFAULT';
  @Input() hasSaveButton = true;

  constructor(public supplierPublicInformationService: SupplierPublicInformationService) {}

  ngOnInit(): void {}

  save(): Observable<boolean> {
    return this.supplierPublicInformationService.save();
  }

  get form(): FormGroup {
    return this.supplierPublicInformationService.form;
  }
}
