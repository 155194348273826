import { Component, Input, OnInit } from '@angular/core';
import { AiChatMessagesService } from '../../../services/ai-chat-messages.service';

@Component({
  selector: 'app-ai-chat-message-search-term-suggestion',
  templateUrl: './ai-chat-message-search-term-suggestion.component.html',
  styleUrls: ['./ai-chat-message-search-term-suggestion.component.scss'],
})
export class AiChatMessageSearchTermSuggestionComponent implements OnInit {
  @Input() searchTerms: string[];

  constructor(private aiChatMessagesService: AiChatMessagesService) {}

  ngOnInit(): void {}

  handleClick(term: string): void {
    this.aiChatMessagesService.pushCommand(term);
  }
}
