<div id="main-container">
  <!--Previous-->
  <button #viewerel mat-fab (click)="previous()" [disabled]="!hasPreviousItem()" class="prev">
    <mat-icon class="centered">arrow_back</mat-icon>
  </button>

  <!--Image container-->
  <div #viewerel id="image-container" class="viewer-el" fxLayoutAlign="center center">
    <img [src]="imageUrls[currentIndex]" />
    <img hidden *ngIf="hasNextItem()" [src]="imageUrls[currentIndex + 1]" />
  </div>

  <!--Next-->
  <button #viewerel mat-fab (click)="next()" [disabled]="!hasNextItem()" class="viewer-el next">
    <mat-icon class="centered">arrow_forward</mat-icon>
  </button>
</div>
