export const htmlPieLegend: any = {
  id: 'htmlPieLegend',
  afterUpdate(chart, args, options): void {
    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    const legendContainer = getOrCreatePieLegendContainer(chart, options.containerID);

    while (legendContainer.firstChild) {
      legendContainer.firstChild.remove();
    }

    for (let i = 0; i < Math.floor(items.length / 8) + 1; i++) {
      const groupDiv = document.createElement('div');
      groupDiv.style.display = 'flex';
      groupDiv.style.flexDirection = 'column';
      groupDiv.style.rowGap = '12px';

      items.forEach((item, index) => {
        if (Math.floor(index / 8) === i) {
          const div = document.createElement('div');
          div.style.alignItems = 'center';
          div.style.cursor = 'pointer';
          div.style.display = 'flex';
          div.style.flexDirection = 'row';
          div.style.marginLeft = '10px';

          div.onclick = () => {
            const { type } = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            }
            chart.update();
          };

          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '12px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '12px';
          boxSpan.style.borderRadius = '12px';

          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.style.margin = '0';
          textContainer.style.padding = '0';
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          div.appendChild(boxSpan);
          div.appendChild(textContainer);
          groupDiv.appendChild(div);
        }
      });

      legendContainer.appendChild(groupDiv);
    }
  },
};

export const getOrCreatePieLegendContainer = (chart, id) => {
  const legendContainer = document.getElementById(id);

  let listContainer = legendContainer.querySelector('div');

  if (!listContainer) {
    listContainer = document.createElement('div');
    listContainer.classList.add('pie-legend');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    // listContainer.style.flexWrap = 'wrap';
    listContainer.style.rowGap = '12px';
    listContainer.style.columnGap = '40px';
    // listContainer.style.maxHeight = '250px';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const emptyPlugin: any = {
  id: 'emptyPie',
  afterDraw(chart, args, options): void {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = options;
    let hasData = false;

    hasData = datasets.some((dataset) => dataset.data.length !== 0);

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
    }
  },
};
