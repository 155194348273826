<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">
  <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
  <!-- TOOLBAR: Above fixed -->
  <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-fixed'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>
  <!-- / TOOLBAR: Above fixed -->

  <div id="container-1" class="container" overflowDirective>
    <!-- TOOLBAR: Above static -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-static'">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above static -->

    <div id="container-2" class="container">
      <!-- NAVBAR: Left -->
      <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
        <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Left -->

      <!-- CONTENT -->
      <content
        [ngClass]="{
          'ml-32': fuseConfig.layout.navbar.position === 'left',
          'mr-32': fuseConfig.layout.navbar.position === 'right'
        }"
      >
      </content>
      <!-- / CONTENT -->

      <!-- NAVBAR: Right -->
      <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
        <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above static -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-static'">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above static -->
  </div>

  <!-- FOOTER: Above fixed -->
  <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-container>
  <!-- FOOTER: Above fixed -->
</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">
  <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar
    *ngIf="!fuseConfig.layout.toolbar.hidden"
    [ngClass]="
      fuseConfig.layout.toolbar.customBackgroundColor === true
        ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
        : fuseConfig.layout.toolbar.position
    "
  >
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  <footer
    *ngIf="!fuseConfig.layout.footer.hidden"
    [ngClass]="
      fuseConfig.layout.footer.customBackgroundColor === true
        ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background
        : fuseConfig.layout.footer.position
    "
  ></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  <fuse-sidebar
    name="navbar"
    class="navbar-fuse-sidebar"
    [folded]="fuseConfig.layout.navbar.folded"
    lockedOpen="gt-md"
    *ngIf="!fuseConfig.layout.navbar.hidden"
  >
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
  <fuse-sidebar
    name="navbar"
    position="right"
    class="navbar-fuse-sidebar"
    [folded]="fuseConfig.layout.navbar.folded"
    lockedOpen="gt-md"
    *ngIf="!fuseConfig.layout.navbar.hidden"
  >
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
