import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionService } from 'app/main/billing-new/service/subscription.service';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogMultiWrapperComponent } from '../../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { take } from 'rxjs/operators';
import { CouponResponse } from '../../../../main/billing-new/subscription/model/coupon-response';

@Component({
  selector: 'app-coupon-code-dialog',
  templateUrl: './coupon-code-dialog.component.html',
  styleUrls: ['./coupon-code-dialog.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    MatDialogModule,
    CommonModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    DialogMultiWrapperComponent,
  ],
})
export class CouponCodeDialogComponent implements OnInit {
  form: UntypedFormGroup;
  coupon: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CouponCodeProps,
    public dialogRef: MatDialogRef<CouponCodeDialogComponent, CouponResponse>,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close(null);
  }

  activate(): void {
    this.subscriptionService
      .validateCouponeCode(this.data.ecomId, this.data.planId, this.coupon)
      .pipe(take(1))
      .subscribe((resp) => {
        this.dialogRef.close(resp);
      });
  }
}

export interface CouponCodeProps {
  ecomId: number;
  planId: number;
}
