import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectYourStoreComponent } from './connect-your-store.component';
import { ConnectYourStoreComponentItemComponent } from './connect-your-store-item/connect-your-store-item.component';
import { ConnectYourStoreInstallDialogComponent } from './connect-your-store-install-dialog/connect-your-store-install-dialog.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { UniversalBadgeComponent } from 'app/shared/components/universal-badge/universal-badge.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ShopifyNewStoreComponent } from 'app/shared/components/shopify-new-store/shopify-new-store.component';

@NgModule({
  declarations: [
    ConnectYourStoreComponent,
    ConnectYourStoreComponentItemComponent,
    ConnectYourStoreInstallDialogComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatInputModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    UniversalBadgeComponent,
    ShopifyNewStoreComponent,
    FormsModule,
  ],
  exports: [ConnectYourStoreComponent],
})
export class ConnectYourStoreModule {}
