<form *ngIf="filterItem" #filtersForm="ngForm" name="form">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="min-height: 80px">
    <mat-form-field appearance="fill">
      <mat-select
        fxFlex="20"
        name="filterField"
        class="filter-box-fields"
        placeholder="{{ 'FILTER.FILTER_ITEM.SELECT_FIELD_PLACE' | translate }}"
        [(ngModel)]="filterItem.field"
        [compareWith]="compareFieldByKey"
        (selectionChange)="fieldChanged($event)"
        required
      >
        <mat-option *ngFor="let field of fields" [value]="field">{{ field.name }}</mat-option>
      </mat-select>
      <mat-error>
        {{ 'FILTER.FILTER_ITEM.FIELD_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-select
        fxFlex="20"
        name="filterCondition"
        class="filter-box-fields"
        placeholder="{{ 'FILTER.FILTER_ITEM.SELECT_CONDITION_PLACE' | translate }}"
        [(ngModel)]="filterItem.condition"
        (selectionChange)="conditionChanged($event)"
        required
      >
        <mat-option *ngFor="let condition of conditions" [value]="condition.value">{{ condition.display }}</mat-option>
      </mat-select>
      <mat-error>
        {{ 'FILTER.FILTER_ITEM.CONDITION_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <form [formGroup]="matChipListForm" fxFlex="70">
      <ng-container *ngIf="!isExistsCondition(); else tmpExistsValues">
        <mat-form-field
          style="width: 100%"
          appearance="fill"
          [matTooltip]="getTooltipForInput()"
          [matTooltipDisabled]="!disableInput"
          [matTooltipPosition]="'above'"
        >
          <mat-chip-list #chipList formArrayName="values">
            <mat-chip
              *ngFor="let valueCtrl of $any(matChipListForm.get('values')).controls; let formValueIndex = index"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(matChipListForm, formValueIndex, valueCtrl.value)"
            >
              {{ valueCtrl.value }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="{{ 'FILTER.FILTER_ITEM.FILTER_VALUE' | translate }}"
              matInput
              #filterValueInput
              [disabled]="disableInput"
              [matChipInputFor]="chipList"
              [formControl]="filterCtrl"
              [matAutocomplete]="auto"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (click)="openAutoComplete()"
              (focusout)="matChipListFocusOut()"
              (matChipInputTokenEnd)="add($event)"
              (keyup)="searchInputChanged($event)"
            />
          </mat-chip-list>
          <mat-error *ngIf="chipList.errorState">{{ 'FILTER.FILTER_ITEM.AT_LEAST' | translate }}</mat-error>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option
              *ngFor="let value of taskId ? suggestions : (filteredValues | async)"
              [value]="value"
              [disabled]="setDisableValue(value)"
            >
              {{ value }}
            </mat-option>
            <div
              [class.display-none]="!hasMore"
              *ngIf="taskId; else noTaskId"
              (click)="loadMore()"
              class="supplier-load-more"
            >
              <span fxLayoutAlign="center center">
                {{ 'FILTER.LOAD_MORE' | translate }}
              </span>
            </div>
            <ng-template #noTaskId>
              <div
                [class.display-none]="
                  !(
                    (filteredLength == 0 && maxLength < allValues.length) ||
                    (maxLength < filteredLength && maxLength != -1)
                  )
                "
                (click)="loadMore()"
                class="supplier-load-more"
              >
                <span fxLayoutAlign="center center">
                  {{ 'FILTER.LOAD_MORE' | translate }}
                </span>
              </div>
            </ng-template>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
      <ng-template #tmpExistsValues>
        <h3 id="existsTmplMessage">{{ 'FILTER.FILTER_ITEM.THIS_CONDITION' | translate }}</h3>
      </ng-template>
    </form>
  </div>
</form>
