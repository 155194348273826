<div *ngIf="!!searchTerms && searchTerms.length > 0" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
  <h3 class="h3">{{ 'EXPLORE_PRODUCTS.RECENT_SEARCHES' | translate }}</h3>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-chip
      class="grey-chip mt-8 cursor-pointer"
      *ngFor="let searchTerm of searchTerms"
      (click)="chipClicked.emit(searchTerm)"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
        <mat-icon class="s-20">search</mat-icon>
        <div>{{ searchTerm }}</div>
      </div>
    </mat-chip>
  </div>
</div>
