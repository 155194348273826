<div
  *ngIf="!starter && usage >= mediumLimit"
  class="typography-subtitle-small warning-wrapper"
  [class.info]="upgraded"
  [class.medium]="!upgraded && usage >= mediumLimit"
  [class.high]="!upgraded && usage >= highLimit"
>
  <mat-icon
    class="s-20"
    [fontIcon]="
      !upgraded ? (usage === max ? 'warning' : usage >= highLimit ? 'warning_amber' : 'error_outline') : 'info'
    "
  ></mat-icon>

  <div class="select-none">
    {{
      (!upgraded
        ? usage === max
          ? 'USAGE_ERROR.TOTAL_USAGE' + (short ? '_SHORT' : '')
          : usage >= highLimit
          ? 'USAGE_ERROR.HIGH_USAGE' + (short ? '_SHORT' : '')
          : 'USAGE_ERROR.MEDIUM_USAGE' + (short ? '_SHORT' : '')
        : 'USAGE_ERROR.ALREADY_UPGRADED'
      ) | translate
    }}
  </div>

  <div *ngIf="!upgraded" class="upgrade select-none" (click)="onUpgrade.emit()">
    {{ 'USAGE_ERROR.UPGRADE' | translate }}
  </div>
</div>
