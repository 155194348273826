import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AuthDialogType } from './helpers/authentication-dialog-type';
import { LoginDialogComponent } from './login/login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from './registration/registration-dialog/registration-dialog.component';
import { AuthenticationDialogResponse } from './helpers/authentication-dialog-response';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationManagerService {
  private _openLoginDialogSubject = new Subject<AuthenticationDialog>();
  private _openRegistrationDialogSubject = new Subject<AuthenticationDialog>();

  constructor(private dialogService: MatDialog) {
    this.openDialogListener();
    this.openSupplierDialogListener();
  }

  emitOpenLoginDialog(type: AuthDialogType = AuthDialogType.RETAILER, callbackAfterClosed?: CallbackAfterClosed): void {
    this._openLoginDialogSubject.next({ type, callbackAfterClosed });
  }

  emitOpenRegistrationDialog(
    type: AuthDialogType = AuthDialogType.RETAILER,
    callbackAfterClosed?: CallbackAfterClosed,
    titleKey: string = `AUTHENTICATION.REGISTRATION.${type.toUpperCase()}_REGISTRATION_CONTENT.TITLE`,
    descriptionKey: string = `AUTHENTICATION.REGISTRATION.${type.toUpperCase()}_REGISTRATION_CONTENT.SUBTITLE`
  ): void {
    this._openRegistrationDialogSubject.next({ type, callbackAfterClosed, titleKey, descriptionKey });
  }

  private openDialogListener(): void {
    this._openLoginDialogSubject
      .asObservable()
      .pipe(debounce(() => interval(100)))
      .subscribe((authenticationDialog) => {
        const ref = this.openDialog(
          authenticationDialog.type,
          LoginDialogComponent,
          authenticationDialog.titleKey,
          authenticationDialog.descriptionKey
        );
        this.subscribeToAfterRedirect(ref, authenticationDialog.callbackAfterClosed);
      });
  }

  private openSupplierDialogListener(): void {
    this._openRegistrationDialogSubject
      .asObservable()
      .pipe(debounce(() => interval(100)))
      .subscribe((authenticationDialog) => {
        const ref = this.openDialog(
          authenticationDialog.type,
          RegistrationDialogComponent,
          authenticationDialog.titleKey,
          authenticationDialog.descriptionKey
        );
        this.subscribeToAfterRedirect(ref, authenticationDialog.callbackAfterClosed);
      });
  }

  private openDialog(
    type: AuthDialogType,
    component: ComponentType<LoginDialogComponent | RegistrationDialogComponent>,
    titleKey?: string,
    descriptionKey?: string
  ): MatDialogRef<LoginDialogComponent | RegistrationDialogComponent> {
    this.dialogService.closeAll();
    return this.dialogService.open(component, {
      maxWidth: '990px',
      data: { type, titleKey, descriptionKey },
      panelClass: ['custom-modal-full-width-lt-md', 'custom-modal-overflow-auto'],
      maxHeight: 'auto',
    });
  }

  private subscribeToAfterRedirect(
    ref: MatDialogRef<LoginDialogComponent | RegistrationDialogComponent>,
    callbackAfterClosed?: CallbackAfterClosed
  ): void {
    if (!!ref && !!callbackAfterClosed) {
      ref.afterClosed().subscribe((response) => callbackAfterClosed(response));
    }
  }
}

type CallbackAfterClosed = (response: AuthenticationDialogResponse) => void;

interface AuthenticationDialog {
  type: AuthDialogType;
  callbackAfterClosed?: CallbackAfterClosed;
  titleKey?: string;
  descriptionKey?: string;
}
