import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule, MatIconModule, MatButtonModule],
})
export class ImageViewerComponent implements OnInit {
  @ViewChildren('viewerel') private views: QueryList<ElementRef>;

  currentIndex = 0;
  imageUrls: string[] = [];
  private timeout = null;
  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!event.target.attributes['emitStatus']) {
      this.modalRef.close();
    }
  }

  constructor(
    private modalRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private eRef: ElementRef
  ) {
    this.imageUrls = data.imageUrls;
  }

  ngOnInit(): void {}

  public next(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.currentIndex < this.imageUrls.length - 1) {
        this.currentIndex = this.currentIndex + 1;
      }
    }, 300);
  }

  public previous(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1;
      }
    }, 300);
  }

  public hasNextItem(): boolean {
    return this.currentIndex < this.imageUrls.length - 1;
  }

  public hasPreviousItem(): boolean {
    return this.currentIndex > 0;
  }
}
