<!--{{ dateBeforeFrom | date }}-{{ dateFrom | date }}-->
<div class="w-100p numeric-cards" fxLayout.lt-lg="column" *ngIf="dataSets">
  <app-numeric-card
    fxFlex="1 0 200px"
    fxFlex.lt-lg="1 0 0"
    [value]=""
    [percentage]="getPercentage('REVENUE')"
    [unitOfMeasurement]="
      getValue('REVENUE')
        | currencyExchangeTo : selectedEcomCurrency : ecomCurrency
        | async
        | currencyTransform : ecomCurrency
        | async
    "
    [title]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.REVENUE.TITLE' | translate"
    [tooltip]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.REVENUE.TOOLTIP' | translate"
  ></app-numeric-card>
  <app-numeric-card
    fxFlex="1 0 200px"
    fxFlex.lt-lg="1 0 0"
    [value]=""
    [percentage]="getPercentage('PROFIT')"
    [unitOfMeasurement]="
      getValue('PROFIT')
        | currencyExchangeTo : selectedEcomCurrency : ecomCurrency
        | async
        | currencyTransform : ecomCurrency
        | async
    "
    [title]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.PROFIT.TITLE' | translate"
    [tooltip]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.PROFIT.TOOLTIP' | translate"
  ></app-numeric-card>
  <app-numeric-card
    fxFlex="1 0 200px"
    fxFlex.lt-lg="1 0 0"
    [value]="getValue('PRODUCTS_SOLD')"
    [percentage]="getPercentage('PRODUCTS_SOLD')"
    [unitOfMeasurement]=""
    [title]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.PRODUCTS_SOLD.TITLE' | translate"
    [tooltip]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.PRODUCTS_SOLD.TOOLTIP' | translate"
  ></app-numeric-card>
  <app-numeric-card
    fxFlex="1 0 200px"
    fxFlex.lt-lg="1 0 0"
    [value]="getValue('ORDERS')"
    [percentage]="getPercentage('ORDERS')"
    [title]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.ORDERS.TITLE' | translate"
    [unitOfMeasurement]=""
    [tooltip]="'DASHBOARD.RETAILER.NUMERIC_CARD_LIST.ORDERS.TOOLTIP' | translate"
  ></app-numeric-card>
</div>
