<div id="fileUploadCard">
  <h3 class="mt-0">
    {{
      isCover
        ? ('USERS.SUPPLIER_DETAILS.COVER.COVER_UPLOAD' | translate)
        : ('USERS.SUPPLIER_DETAILS.LOGO.LOGO_UPLOAD' | translate)
    }}
    <ng-container *ngIf="isCover"
      ><mat-icon style="font-size: 20px" [matTooltip]="'USERS.SUPPLIER_DETAILS.COVER.HEIGHT' | translate"
        >help</mat-icon
      ></ng-container
    >
  </h3>
  <div class="dragzone-holder cursor-pointer">
    <div class="dragzone-overlay" *ngIf="isUploadInProgress"></div>
    <div
      ngfDrop
      multiple="1"
      selectable="1"
      [(files)]="files"
      accept=".png,.svg"
      [(validDrag)]="validComboDrag"
      class="well dragzone"
      [class.invalid-drag]="validComboDrag === false"
      [class.valid-drag]="validComboDrag"
      (filesChange)="setFileToUpload(files)"
    >
      <div [hidden]="!selectedFile">
        <img #logo class="w-252 h-100" id="supplierLogo" [src]="selectedFile" (error)="handleError()" />
      </div>
      <div class="upload-holder" *ngIf="!selectedFile">
        <div>
          <div class="upload-image"></div>
          <p>
            {{
              isCover
                ? ('USERS.SUPPLIER_DETAILS.COVER.DROP_FILES' | translate)
                : ('USERS.SUPPLIER_DETAILS.LOGO.DROP_FILES' | translate)
            }}
          </p>
          <button mat-button class="syncee-blue-button">
            {{ 'USERS.SUPPLIER_DETAILS.LOGO.SELECT_FILE' | translate }}
          </button>
        </div>
        <div class="file-content" *ngIf="selectedFileName">
          {{ selectedFileName }}
          <span class="file-size" *ngIf="selectedFileConvertedSize">{{ selectedFileConvertedSize }}</span>
        </div>
      </div>
    </div>
  </div>
  <!--<div id="uploaded-files">-->
  <!--<button-->
  <!--[disabled]="!isFileSelected || disableUploadBtn"-->
  <!--class="upload-button"-->
  <!--mat-button color="accent"-->
  <!--(click)="uploadFiles(selectedFile)"-->
  <!--&gt;UPLOAD-->
  <!--</button>-->
  <!--</div>-->
</div>
