<div *ngIf="showDetails">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div
      adminComment
      [adminCommentTaskId]="taskId"
      [adminCommentTaskType]="'import'"
      [adminCommentStep]="'summary'"
      [adminCommentMappingField]="'summary'"
      [adminCommentComments]="comments"
    ></div>
    <div class="h3 m-16">{{ 'TASKWIZARD.SUMMARY.TASK_SUMMARY' | translate }}</div>
    <div fxLayout="column">
      <div>
        <strong>{{ 'TASKWIZARD.SUMMARY.TASK_NAME' | translate }}</strong> {{ taskName }}
      </div>
      <div *ngIf="sourceType">
        <strong>{{ 'TASKWIZARD.SUMMARY.FILES_SOURCE' | translate }}</strong> {{ sourceType }}
      </div>
      <div>
        <strong>{{ 'TASKWIZARD.SUMMARY.VARIANTS_NUMBER' | translate }}</strong> {{ variantsNumber }}
      </div>
      <div *ngIf="scheduleType">
        <strong
          >{{ 'TASKWIZARD.SUMMARY.TASK_WILL' | translate }}
          {{ 'TASKWIZARD.SUMMARY.' + scheduleType.toUpperCase() | translate }}</strong
        >:
      </div>
      <mat-list *ngIf="scheduleTimes" class="summary-list" role="list" fxFlexAlign="center">
        <mat-list-item role="listitem" *ngFor="let scheduleTime of scheduleTimes">- {{ scheduleTime }}</mat-list-item>
      </mat-list>
      <mat-form-field appearance="fill" class="mt-20">
        <mat-label>{{ 'TASKWIZARD.SUMMARY.EMAIL_NOTI' | translate }}</mat-label>
        <mat-select [(value)]="selectedNotification" disableOptionCentering panelClass="mat-select-display-change">
          <mat-option value="dont">{{ 'TASKWIZARD.SUMMARY.DONT_SEND' | translate }}</mat-option>
          <mat-option value="email">{{ 'TASKWIZARD.SUMMARY.SEND_EMAIL_WHEN' | translate }}</mat-option>
          <mat-option value="report">{{ 'TASKWIZARD.SUMMARY.SEND_EMAIL_AND' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--<div class="pt-24" fxLayout="row" fxLayoutAlign="end center">-->
  <!--<button class="mr-8" mat-button matStepperPrevious type="button" color="accent">-->
  <!--Previous-->
  <!--</button>-->
  <!--<button mat-button type="button" color="accent" (click)="finishTask()">-->
  <!--Finish-->
  <!--</button>-->
  <!--</div>-->
</div>
