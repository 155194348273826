import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-support-team-member',
  templateUrl: './support-team-member.component.html',
  styleUrls: ['./support-team-member.component.scss'],
})
export class SupportTeamMemberComponent {
  @Input() image: string;
}
