import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { combineLatest, fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppState } from '../../app.state';
import {
  Action,
  AuthorizationEcomService,
  PermissionPayload,
} from '../../service/authorization-ecom/authorization-ecom.service';
import { EcomVO } from '../../service/ecom/ecom.service';
import { hasRoleSelector } from '../../store/user/user.selector';
import { Utils } from '../../utils/utils';
import { RolesEnum } from '../../vo/roles/roles';

@Directive({
  selector: '[hasPermissionButton]',
  standalone: true,
})
export class HasPermissionButtonDirective implements AfterViewInit, OnDestroy {
  @Input() needsPermission = true;
  @Input() actionType: Action;
  @Input() descriptionTranslationKey: string;
  @Input() payload: Partial<PermissionPayload>;
  @Input() ecom: EcomVO;

  private unsubscribeAll: Subject<void>;
  private subscription: Subscription;

  constructor(
    private element: ElementRef<HTMLElement>,
    private authorizationService: AuthorizationEcomService,
    private store: Store<AppState>
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngAfterViewInit(): void {
    if (this.actionType && this.needsPermission) {
      this.createSubscription();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private hasPermission$(): Observable<boolean> {
    return this.store.select(hasRoleSelector(RolesEnum.ADMIN)).pipe(
      omitNullOrUndefined(),
      switchMap((isAdmin: boolean): Observable<boolean> => {
        if (isAdmin) {
          return of(true);
        } else {
          if (!Utils.isNullOrUndefined(this.ecom)) {
            return of(this.authorizationService.hasPermission(this.actionType, this.ecom, isAdmin, this.payload));
          } else {
            return this.authorizationService.hasPermissionObs(this.actionType, this.payload);
          }
        }
      })
    );
  }

  private createSubscription(): void {
    if (!Utils.isNullOrUndefined(this.subscription)) {
      return;
    }

    this.subscription = combineLatest([
      fromEvent(this.element.nativeElement, 'click', { capture: true }),
      this.authorizationService.isAuthenticatedUserOrNot(),
      this.hasPermission$(),
    ])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(([event, isAuth, hasPermission]) => {
        if (!hasPermission && !!isAuth) {
          event.stopPropagation();
          this.authorizationService.openSubscribeDialog(this.descriptionTranslationKey);
        }
      });
  }
}
