import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupplierCard } from 'app/main/marketplace/supplier-card/supplier-card.component';
import { SupplierTaxSettings } from 'app/vo/supplier/supplier-tax-settings';
import { SupplierWithBadgesAndAutoOrder } from 'app/vo/supplier/supplier-with-badges-and-auto-order';
import { SupplierWithBadgesDto } from 'app/vo/supplier/supplier-with-badges-dto';
import { flatten, uniq } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { getCurrentUserIdSelector } from '../../store/user/user.selector';
import { SpringPage } from '../../vo/pagination/spring-page';
import { SpringPageable } from '../../vo/pagination/spring-pageable';
import { ShippingDetailsDto } from '../../vo/shipping-details/shipping-details-dto';
import { SupplierDto } from '../../vo/supplier/supplier-dto';
import { CatalogMainCategories } from '../catalog/catalog-gateway.service';
import { SupplierOrderLimit } from '../manage-orders/syncee-orders.service';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierGatewayService {
  private url = '/Supplier';
  // todo: maybe clear when navigating away?
  private fetchedDetails: { userId: number; details: ShippingDetailsDto[] }[] = [];

  private microserviceName = 'userService';
  constructor(private springRestService: SpringRestService, private store: Store<AppState>) {}

  getSupplierOrderLimit(userId: number[]): Observable<SupplierOrderLimit[]> {
    return this.springRestService.get(MicroserviceNames.ORDER, `/v1/MinimumOrderAmount`, { userId }, true);
  }

  getSuppliers(userIds: number[]): Observable<SupplierWithBadgesDto[]> {
    return this.springRestService.get(this.microserviceName, this.url, { userId: userIds }, true);
  }

  getSingleSupplier(userId: number): Observable<SupplierWithBadgesDto> {
    return this.getSuppliers([userId]).pipe(map((result) => result[0]));
  }

  getSupplierWithAutoOrder(userId: number): Observable<SupplierWithBadgesAndAutoOrder> {
    return forkJoin([this.getSingleSupplier(userId), this.getAutoOrderForSingleSupplier(userId)]).pipe(
      map(([supplier, autoOrder]) => {
        return { ...supplier, autoOrder: autoOrder?.automate };
      })
    );
  }

  getCurrentUserSupplier(): Observable<SupplierWithBadgesDto> {
    return this.store.select(getCurrentUserIdSelector).pipe(
      take(1),
      switchMap((userId) => this.getSingleSupplier(userId))
    );
  }

  getSupplierShippingDetailsLegacy(userId: number): Observable<ShippingDetailsDto[]> {
    const url = `${this.url}/${userId}/ShippingDetails`;

    const shippingDetails = this.fetchedDetails.find((details) => details.userId === userId);

    return !!shippingDetails
      ? of(shippingDetails.details)
      : this.springRestService
          .get(this.microserviceName, url, undefined, true)
          .pipe(tap((details) => this.fetchedDetails.push({ details, userId: userId })));
  }

  revalidateSupplierShippingDetails(userId: number): Observable<void> {
    const url = `${this.url}/${userId}/ShippingDetails`;
    return this.springRestService.get(this.microserviceName, url, undefined, true).pipe(
      tap((shippingDetails) => {
        const index = this.fetchedDetails.findIndex((user) => user.userId === userId);
        if (index !== -1) {
          this.fetchedDetails[index].details = shippingDetails;
        } else {
          this.fetchedDetails.push({ details: shippingDetails, userId: userId });
        }
      }),
      map(() => {})
    );
  }

  getAutoOrders(userIds: number[]): Observable<AutoOrderDto[]> {
    const url = `${this.url}/automateOrder`;
    return this.springRestService.get(MicroserviceNames.USER, url, { userId: userIds }, true);
  }

  getAutoOrderForSingleSupplier(userId: number): Observable<AutoOrderDto> {
    return this.getAutoOrders([userId]).pipe(map((result) => result[0]));
  }

  findByCountryAndCategory(
    countryCode: string,
    categoryId: number,
    vip = false,
    pageable: SpringPageable = { page: 0, size: 4 }
  ): Observable<SpringPage<SupplierDto>> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      `${this.url}/FindByCountryAndCategory`,
      { countryCode, categoryId, vip, ...pageable },
      true
    );
  }

  getRandomSupplierProductImages(
    supplierUserId: number[],
    categoryId?: number,
    maxImagesPerSupplier?: number
  ): Observable<RandomImageVo[]> {
    const params: RandomImageRequestPayload = { supplierUserId };
    if (!!categoryId) {
      params.categoryId = categoryId;
    }
    if (!!maxImagesPerSupplier) {
      params.maxImagesPerSupplier = maxImagesPerSupplier;
    }
    return this.springRestService.get(MicroserviceNames.PRODUCT_SEARCH, '/Search/Products/RandomImages', params, true);
  }

  mapRandomSupplierVosToRecord(imageVos: RandomImageVo[]): Record<number, string[]> {
    const imageRecord: Record<number, string[]> = {};
    imageVos.forEach((imageVo) => {
      imageRecord[imageVo.supplierUserId] = imageVo.images;
    });
    return imageRecord;
  }

  getSupplierRecommendations(countryCode: string, springPageable: SpringPageable): Observable<SpringPage<SupplierDto>> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      `${this.url}/GetSupplierRecommendations`,
      { countryCode, ...springPageable },
      true
    );
  }

  mapSupplierDtoToSupplierCardModel(
    similarSuppliers: SupplierDto[],
    mainCategories: CatalogMainCategories[]
  ): SupplierCard[] {
    return similarSuppliers.map((supplier) => {
      return {
        userId: supplier.userId,
        mainWarehouseCountry: supplier.mainWarehouseLocation,
        mainCategoryIds: this.getUniqeMainCategoryIdsBySupplier(mainCategories, supplier),
        companyName: supplier.companyName,
        handle: supplier.handle,
      };
    });
  }

  private getUniqeMainCategoryIdsBySupplier(mainCategories: CatalogMainCategories[], supplier: SupplierDto): number[] {
    return uniq(
      flatten(
        mainCategories
          .filter((mainCategory) => mainCategory.userId === +supplier.userId)
          .map((mainCategory) => mainCategory.categoryIds)
      )
    );
  }

  getOrdersCountBySupplierIds(supplierId: number[], status: PAYMENT_STATUS): Observable<OrderCountBySupplier[]> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      `/SynceeOrder/getOrdersCountBySupplier`,
      { supplierId, status },
      true
    );
  }

  getSupplierTaxSettings(supplierUserId: number): Observable<SupplierTaxSettings> {
    return this.springRestService
      .get(MicroserviceNames.ORDER, `/v1/Supplier/TaxSettings/${supplierUserId}`, false, true)
      .pipe(
        catchError((error) =>
          error.errorCode == HttpStatusCode.NotFound
            ? of({ id: null, productHasTax: false, shippingHasTax: false, taxOrders: false })
            : of(null)
        )
      );
  }
}

export enum PAYMENT_STATUS {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  UNSUCCESSFUL = 'UNSUCCESSFUL',
  REFUND = 'REFUND',
}

export interface AutoOrderDto {
  userId: number;
  automate: boolean;
  lastModified: Date;
}

export interface RandomImageVo {
  supplierUserId: number;
  images: string[];
}

export interface RandomImageRequestPayload {
  supplierUserId: number[];
  categoryId?: number;
  maxImagesPerSupplier?: number;
}

export interface OrderCountBySupplier {
  supplierUserId: number;
  count: number;
}
