<div class="shipping-time-filter">
  <div class="title-container">
    <div class="title-left">
      <div class="font-weight-700">{{ 'FILTER_COMMON.TITLES.SHIPPING_TIME' | translate }}</div>
      <mat-icon class="s-20" [matTooltip]="'FILTER_COMMON.TITLES.SHIPPING_TIME_TOOLTIP' | translate"> info </mat-icon>
    </div>
    <div
      *ngIf="hasClearAll && (value.minShippingTime || value.maxShippingTime)"
      (click)="handleClearAllClicked()"
      class="clear-all"
    >
      {{ 'FILTER_COMMON.SELECTOR.CONTROLS.CLEAR_ALL' | translate }}
    </div>
  </div>
  <mat-radio-group (change)="handleChange($event.value)">
    <div class="options">
      <mat-radio-button
        *ngFor="let option of options"
        [value]="{
          minShippingTime: option.min,
          maxShippingTime: option.max
        }"
        [checked]="value.minShippingTime === option.min && value.maxShippingTime === option.max"
        >{{ option.label | translate }}</mat-radio-button
      >
    </div>
  </mat-radio-group>
</div>
