import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryVo } from 'app/vo/category-vo';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { CategoryNameByIdPipe } from 'app/shared/pipes/category-name-by-id.pipe';
import { SkeletonModule } from '../skeleton/skeleton.module';

@Component({
  selector: 'app-category-sidebar-selector',
  templateUrl: './category-sidebar-selector.component.html',
  styleUrls: ['./category-sidebar-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, CategoryNameByIdPipe, SkeletonModule],
})
export class CategorySidebarSelectorComponent implements OnInit {
  @Input() topLevelParentCategory: CategoryVo;
  @Input() selectedCategoryPath: number[];
  @Input() selectedCategory: CategoryVo;
  @Output() selectedCategoryChange = new EventEmitter<CategoryVo>();

  constructor() {}

  ngOnInit(): void {}

  handleCategoryChange(category: CategoryVo): void {
    this.selectedCategoryChange.emit(category);
  }
}
