<div fxLayoutAlign="space-between center">
  <!--    <div class="header"-->
  <!--         fxLayout="column" fxLayoutAlign="center center"-->
  <!--         fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">-->
  <!--        <div class="logo pb-24 mb-sm-0"-->
  <!--             fxLayout="row" fxLayoutAlign="start center" fxFlex="30">-->
  <!--            &lt;!&ndash;<mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">&ndash;&gt;-->
  <!--                &lt;!&ndash;assignment&ndash;&gt;-->
  <!--            &lt;!&ndash;</mat-icon>&ndash;&gt;-->
  <!--            <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'RETAILER_PROFILE.ACTIVITY_LOG_LABEL' | translate}}</span>-->
  <!--        </div>-->
  <!--    </div>-->
</div>

<app-activity-log-content></app-activity-log-content>
