import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CatalogSidebarService } from '../../../service/catalog-sidebar/catalog-sidebar.service';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { SuppliersService } from '../../../service/suppliers/suppliers.service';
import { Action, AuthorizationEcomService } from '../../../service/authorization-ecom/authorization-ecom.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { NeedSubscriptionBadgeComponent } from '../need-subscription-badge/need-subscription-badge.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomDialogWrapperComponent } from '../dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    ExtendedModule,
    NeedSubscriptionBadgeComponent,
    LoadingSpinnerComponent,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CustomDialogWrapperComponent,
  ],
})
export class ApprovalDialogComponent implements OnInit {
  approveData: ApproveDataVO;
  catalogId;
  message = new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]);
  // acceptCheckbox = new FormControl(false);
  // acceptCheckboxError = false;
  sendApproveLoading = false;
  contentType;

  constructor(
    public dialogRef: MatDialogRef<ApprovalDialogComponent>,
    public catalogSidebarService: CatalogSidebarService,
    private suppliersService: SuppliersService,
    private authorizationService: AuthorizationEcomService
  ) {}

  ngOnInit(): void {
    // ha még nincs feltöltve a supplierhez a website infó
    if (this.approveData && !this.approveData.website) {
      this.suppliersService.getSupplierInformation({ id: this.approveData.supplierId }).subscribe((rest) => {
        this.approveData.website = rest[0].website;
        console.log(this.approveData.website);
      });
    }
  }

  get hasAccessToReadContact(): Observable<boolean> {
    return this.authorizationService.hasPermissionObs(Action.CONTACTS_READ);
  }

  sendApprove(): void {
    // if (this.acceptCheckbox.value){
    this.dialogRef.disableClose = true;
    this.sendApproveLoading = true;
    let restData;
    if (this.approveData.needResponse) {
      restData = {
        catalogId: this.catalogId,
        message: this.message.value,
      };
    } else {
      restData = {
        catalogId: this.catalogId,
      };
    }
    this.addRetailerToSupplierCatalog(restData);
    // }else{
    //   this.acceptCheckboxError = true;
    // }
  }

  accept(): void {
    // if (this.acceptCheckbox.value){
    this.addRetailerToSupplierCatalog({ catalogId: this.catalogId });
    // }else{
    //   this.acceptCheckboxError = true;
    // }
  }

  addRetailerToSupplierCatalog(restData): void {
    this.catalogSidebarService.addRetailerToCatalogWithRest(restData).subscribe(
      (res) => {
        this.catalogSidebarService.updateRetailerToCatalogList({
          approved: res.approved,
          catalogId: res.catalog.id,
        });
        this.dialogRef.close(true);
      },
      (error) => {
        this.sendApproveLoading = false;
        console.log(error);
      }
    );
  }

  // checkboxChanged(): void{
  //   this.acceptCheckboxError = !this.acceptCheckbox.value;
  // }

  checkURL(website): any {
    return this.suppliersService.sanitizeURL(website);
  }
}

export class ApproveDataVO {
  supplierName: string;
  supplierId: number;
  approveDescription: string;
  needResponse: boolean;
  website: string;
  email: string;
  isAutoOrder: boolean;
}
