<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="pagination-container">
  <label
    >{{ selectedIndex * itemPerPage - itemPerPage + 1 | number : '.0-2' : 'en-US' }} -
    {{
      selectedIndex * itemPerPage < maxNumber
        ? (selectedIndex * itemPerPage | number : '.0-2' : 'en-US')
        : (maxNumber | number : '.0-2' : 'en-US')
    }}
    of {{ maxNumber | number : '.0-2' : 'en-US' }}</label
  >

  <mat-icon class="cursor-pointer page-changer noselect" [class.unselectable]="selectedIndex == 1" (click)="minus()"
    >keyboard_arrow_left
  </mat-icon>
  <mat-icon
    class="cursor-pointer page-changer noselect"
    [class.unselectable]="selectedIndex == maxRange"
    (click)="plus()"
    >keyboard_arrow_right
  </mat-icon>
</div>
