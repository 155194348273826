<div class="supplier-list-container py-32" *ngIf="!!suppliers && !!supplierImages; else progress">
  <app-section-title-slider
    [title]="title"
    [description]="description"
    [hasBottomPadding]="false"
  ></app-section-title-slider>
  <div class="slider-container pt-16" fxLayoutAlign="center center">
    <ng-container *ngIf="!ltMd; else mobile">
      <app-slider-arrow
        *ngIf="swiped"
        variant="previous"
        fxFlex="0 0 auto"
        (click)="slickModal.slickPrev()"
        [style.visibility]="suppliers.length < config.slidesToShow && 'hidden'"
      ></app-slider-arrow>
      <ngx-slick-carousel
        fxFlex="1 0 0"
        #slickModal
        class="supplier-list-items"
        [@animateStagger]="{ value: '50' }"
        [config]="config"
      >
        <ng-container *ngFor="let supplier of suppliers">
          <app-supplier-card
            class="supplier-item"
            ngxSlickItem
            [supplier]="supplier"
            [supplierImages]="supplierImages[supplier.userId]"
            [categoryId]="selectedCategoryId"
          ></app-supplier-card>
        </ng-container>
      </ngx-slick-carousel>
      <app-slider-arrow
        variant="next"
        fxFlex="0 0 auto"
        (click)="slickModal.slickNext(); swiped = true"
        [style.visibility]="suppliers.length < config.slidesToShow && 'hidden'"
      ></app-slider-arrow>
    </ng-container>
  </div>
</div>

<ng-template #progress>
  <app-skeleton-supplier-slider-box></app-skeleton-supplier-slider-box>
</ng-template>

<ng-template #mobile>
  <div class="mobile-carousel">
    <app-supplier-card
      *ngFor="let supplier of suppliers"
      class="supplier-item"
      [supplier]="supplier"
      [supplierImages]="supplierImages[supplier.userId]"
      [categoryId]="selectedCategoryId"
    ></app-supplier-card>
  </div>
</ng-template>
