import {ToolbarContent} from "../../types";

export const DefaultToolbarContents: ToolbarContent = {
    hideSearchbar: false,
    hideTopCallToAction: false,
    hideUpperRow: false,
    hideAiChatButton: false,
    hideLogo: false,
    hideBottomCallToAction: false,
    actionbar: {
        hideSetupGuideButton: false,
        hideProfileButton: false,
        hideNotificationButton: false,
        hideFavouritesButton: false,
        hideEcomSelector: false,
        hideCartButton: false,
    }
}
