import { Component, OnInit } from '@angular/core';
import { WizardCategoryHeaderService } from '../../../service/wizard-category-header.service';

@Component({
  selector: 'app-wizard-mapping-header-right-controls',
  templateUrl: './wizard-mapping-header-right-controls.component.html',
  styleUrls: ['./wizard-mapping-header-right-controls.component.scss'],
})
export class WizardMappingHeaderRightControlsComponent implements OnInit {
  constructor(public headerService: WizardCategoryHeaderService) {}

  ngOnInit(): void {}
}
