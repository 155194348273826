<div fxLayoutAlign="start center" [fxLayoutGap]="gap" class="marketplace-badges-container">
  <div
    *ngFor="let badge of badges | uniqby : 'ID' | marketplaceBadgesFilter"
    marketplaceBadge
    [name]="badge.NAME"
    class="badge typography-label-small"
  >
    {{ 'BADGES.' + badge.NAME | translate }}
  </div>
</div>
