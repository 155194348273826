import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryNode } from '../../../utils/Countries';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomMatSelectSearchComponent } from '../custom-mat-select-search/custom-mat-select-search.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-single-country-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    CustomMatSelectSearchComponent,
  ],
  templateUrl: './single-country-selector.component.html',
  styleUrls: ['./single-country-selector.component.scss'],
})
export class SingleCountrySelectorComponent implements OnInit {
  @Input() countryCode: string;
  @Input() placeholder = 'Country';
  @Input() label?: string;
  @Input() required = false;
  @Input() error?: string;
  @Input() hasError?: boolean;
  @Output() valueChange = new EventEmitter<string>();

  allCountries: CountryNode[];
  filteredCountries: CountryNode[];

  private unsubscribeAll$ = new Subject<void>();

  constructor(private countriesManagerService: CountriesManagerService) {
    this.getCountryNodesWithTranslatedNames();
  }

  ngOnInit(): void {}

  handleSelectionChange(event: MatSelectChange): void {
    this.valueChange.emit(event.value);
  }

  initSearch(value: string): void {
    if (!value) {
      this.filteredCountries = [...this.allCountries];
    } else {
      this.filteredCountries = this.allCountries.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  private getCountryNodesWithTranslatedNames(): void {
    this.countriesManagerService
      .getCountriesWithTranslatedNames()
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((countries) => this.setCountries(countries));
  }

  private setCountries(countryNodes: CountryNode[]): void {
    this.allCountries = [...countryNodes];
    this.filteredCountries = [...this.allCountries];
  }
}
