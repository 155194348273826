<mat-card
  class="top-selling-categories-container p-40"
  fxLayout="column"
  fxLayoutAlign="stretch center"
  fxLayoutGap="28px"
>
  <h4>{{ 'DASHBOARD.CHART_STATISTIC_TYPES.TOP_SELLING_CATEGORIES' | translate }}</h4>
  <div
    class="row-gap-md"
    [ngClass.lt-lg]="'w-100p'"
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    [fxLayoutGap]="!empty ? '64px' : '0px'"
  >
    <div class="pie-canvas-container">
      <canvas
        baseChart
        [labels]="doughnutChartLabels"
        [datasets]="doughnutChartDatasets"
        [options]="doughnutChartOptions"
        [plugins]="doughnutChartPlugins"
        [type]="'doughnut'"
      >
      </canvas>
    </div>
    <div id="pie-legend-container" fxLayout.lt-md="column" [hidden]="empty"></div>
    <div *ngIf="empty" class="empty-chart-overlay">
      <app-blurred-overlay [blurValue]="'100'">
        <app-single-alert-with-icon
          icon="inbox"
          titleKey="DASHBOARD_NEW.NO_ORDERS_YET.TITLE"
          [button]="{
            type: 'inner_redirect',
            textKey: 'DASHBOARD_NEW.NO_ORDERS_YET.BUTTON',
            buttonClass: 'syncee-blue-button',
            url: navigateToMarketplace,
            queryParams: {
              category: '1',
              searchType: 'PRODUCTS'
            }
          }"
        >
        </app-single-alert-with-icon>
      </app-blurred-overlay>
    </div>
  </div>
</mat-card>
