<div fxLayout="column" fxLayoutGap="4px">
  <div [ngStyle]="{ 'text-align': direction === 'chat-side-left' ? 'right' : 'left' }" class="message-time">
    {{ message.date | dateAgo }}
  </div>
  <div
    *ngIf="!containsOnlyEmoji"
    class="message-item-container"
    [ngClass]="direction"
    [style.background]="options.background"
    [style.color]="options.color"
  >
    <div class="message-text">{{ message.message }}</div>
    <img
      (click)="openImageViewer()"
      emitStatus="true"
      class="img-holder"
      *ngIf="message.media && message.media.length > 0"
      [src]="message.media[0].value"
    />
  </div>
  <div class="message-emoji-container" *ngIf="containsOnlyEmoji">
    <div>{{ message.message }}</div>
  </div>
</div>
