import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LanguageAndCurrencySelectorDialogComponent } from './language-and-currency-selector-dialog/language-and-currency-selector-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { getLanguages, getSelectedLanguageCode } from '../../../store/language/language.selector';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-and-currency-selector.component.html',
  styleUrls: ['./language-and-currency-selector.component.scss'],
  standalone: true,
  imports: [FlexModule, MatIconModule, MatMenuModule, NgForOf, NgClass, AsyncPipe, TranslateModule],
})
export class LanguageAndCurrencySelectorComponent implements OnInit {
  @Input()
  isCurrencyActive = true;
  menuOpen = false;
  currentLanguage$: Observable<LanguageOption>;
  unsubscribeAll: Subject<void> = new Subject<void>();
  selectedLanguage: LanguageOption;

  constructor(private dialog: MatDialog, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.currentLanguage$ = this.getCurrentLanguage();
  }

  getCurrentLanguage(): Observable<LanguageOption> {
    return this.store
      .select(getSelectedLanguageCode)
      .pipe(
        switchMap((selectedLanguageCode) =>
          this.store
            .select(getLanguages)
            .pipe(map((languages) => languages.find((lanuage) => lanuage.code === selectedLanguageCode)))
        )
      );
  }

  openLanguageAndCurrencyChangeDialog(): void {
    this.dialog.open(LanguageAndCurrencySelectorDialogComponent, {
      width: '450px',
      maxWidth: '450px',
      panelClass: 'custom-modal-full-width-lt-md',
      autoFocus: false,
      data: { isCurrencyActive: this.isCurrencyActive },
    });
  }
}

export interface LanguageOption {
  code: string;
  name: string;
}
