import { Injectable } from '@angular/core';
import { LeaveUserReviewService } from '../../../../../../main/review/service/leave-user-review.service';
import { map, mergeMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subject } from 'rxjs';
import {
  AddRecentlyAddedProductsAction,
  RCatalogsActionTypes,
} from '../../../../../../store/rcatalogs/rcatalogs.action';
import { RecentlyAddedProduct } from '../../../../../../store/rcatalogs/rcatalogs.reducer';
import { groupBy } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Injectable()
@UntilDestroy()
export class CatalogOverlayService {
  private recentlyAddedProductsActionPayload: Observable<RecentlyAddedProduct[]>;
  private addedProducts: Record<string, RecentlyAddedProduct[]> = {};
  private supplierIdWithFiveAddedProducts = new Subject<number>();

  constructor(private reviewDialogService: LeaveUserReviewService, private actions: Actions) {
    this.recentlyAddedProductsActionPayload = this.actions.pipe(
      ofType(RCatalogsActionTypes.addRecentlyAddedProducts),
      map((action: AddRecentlyAddedProductsAction) => action.payload)
    );
    this.watchAddedProducts();
  }

  subscribeToSupplierIdChange(): void {
    this.supplierIdWithFiveAddedProducts
      .pipe(
        mergeMap((id) => {
          return this.reviewDialogService.autoOpen(id);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private watchAddedProducts(): void {
    this.recentlyAddedProductsActionPayload
      .pipe(
        untilDestroyed(this),
        map((products) => groupBy(products, 'supplierId'))
      )
      .subscribe((value) => {
        Object.entries(value).forEach(([supplierId, productEntries]) => {
          if (!this.addedProducts[supplierId]) {
            this.addedProducts[supplierId] = productEntries;
          } else {
            this.addedProducts[supplierId] = [...this.addedProducts[supplierId], ...productEntries];
          }
          if (this.addedProducts[supplierId].length === 5) {
            this.supplierIdWithFiveAddedProducts.next(Number(supplierId));
          }
        });
      });
  }
}
