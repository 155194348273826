import { Utils } from 'app/utils/utils';

export class RestResponse<T = any> {
  readonly status: Status;
  readonly errorCode: string;
  readonly errorMessage: string;
  warning: string;
  private readonly data: T[];

  public static create<T>(rawResponse: any): RestResponse<T> {
    return new RestResponse<T>(rawResponse);
  }

  constructor(rawResponse: any) {
    this.status = rawResponse.status;
    this.errorCode = rawResponse.errorCode;
    this.errorMessage = rawResponse.errorMessage;
    this.warning = rawResponse.warning;
    this.data = rawResponse.data;
  }

  public isSuccess(): boolean {
    return this.status === Status.OK;
  }

  public getData(): T[] {
    return this.data;
  }

  public getFirstData(): T {
    return this.data[0];
  }

  public isEmpty(): boolean {
    return Utils.isNullOrUndefined(this.data) || this.data.length === 0;
  }

  public isWarning(): boolean {
    return !Utils.isNullOrUndefined(this.warning);
  }

  public isNotEmpty(): boolean {
    return !this.isEmpty();
  }
}

export class RestResponseWithPagination<T> extends RestResponse<T> {
  readonly page: number;
  readonly count: number;
  readonly pageSize: number;
  readonly totalCount: number;

  constructor(rawResponse: any) {
    super(rawResponse);
    this.page = rawResponse.page;
    this.count = rawResponse.count;
    this.pageSize = rawResponse.pageSize;
    this.totalCount = rawResponse.totalCount;
  }

  public static create<T>(rawResponse: any): RestResponseWithPagination<T> {
    return new RestResponseWithPagination<T>(rawResponse);
  }
}

enum Status {
  OK = 'OK',
  FAILED = 'FAILED',
}
