import { Action } from '@ngrx/store';
import { GettingStartedStepDto } from '../../main/getting-started-new/model/getting-started-step-dto';
import { GettingStartedStepType } from '../../main/getting-started-new/model/getting-started-step-type';

export enum GettingStartedActionTypes {
  GETTING_STARTED_CHAIN_START = '[GettingStarted] Getting Started Chain Start',
  GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_START = '[GettingStarted] Complete steps for team members start',
  GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_SUCCESS = '[GettingStarted] Complete steps for team members success',
  GETTING_STARTED_CHECK_UNCOMPLETED_ACTION = '[GettingStarted] Check uncompleted steps',
  GETTING_STARTED_COMPLETED_STEPS_START = '[GettingStarted] Get Completed Steps Start',
  GETTING_STARTED_COMPLETED_STEPS_SUCCESS = '[GettingStarted] Get Completed Steps Success',
  GETTING_STARTED_ALL_STEPS_START = '[GettingStarted] Get All Steps Start',
  GETTING_STARTED_ALL_STEPS_SUCCESS = '[GettingStarted] Get All Steps Success',
  GETTING_STARTED_FINISH_START = '[GettingStarted] Finished All Steps Start',
  GETTING_STARTED_REDIRECT = '[GettingStarted] User redirect',
}

export class GettingStartedChainStartAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_CHAIN_START;
}

export class GettingStartedCompleteStepsForTeamMembersStartAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_START;
}

export class GettingStartedCompleteStepsForTeamMembersSuccessAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_SUCCESS;
}

export class GettingStartedCheckUncompletedAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_CHECK_UNCOMPLETED_ACTION;
}

export class GettingStartedCompletedStepsStartAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_COMPLETED_STEPS_START;
}

export class GettingStartedCompletedStepsSuccessAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_COMPLETED_STEPS_SUCCESS;

  constructor(public payload: GettingStartedStepDto[]) {}
}

export class GettingStartedAllStepsStartAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_ALL_STEPS_START;
}

export class GettingStartedAllStepsSuccessAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_ALL_STEPS_SUCCESS;

  constructor(public payload: GettingStartedStepDto[]) {}
}

export class GettingStartedFinishStartAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_FINISH_START;

  constructor(public payload: GettingStartedStepType) {}
}

export class GettingStartedRedirectAction implements Action {
  readonly type = GettingStartedActionTypes.GETTING_STARTED_REDIRECT;

  constructor() {}
}

export type ActionsUnion =
  | GettingStartedChainStartAction
  | GettingStartedCompleteStepsForTeamMembersStartAction
  | GettingStartedCompleteStepsForTeamMembersSuccessAction
  | GettingStartedCheckUncompletedAction
  | GettingStartedCompletedStepsStartAction
  | GettingStartedCompletedStepsSuccessAction
  | GettingStartedAllStepsStartAction
  | GettingStartedAllStepsSuccessAction
  | GettingStartedFinishStartAction
  | GettingStartedRedirectAction;
