import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceType } from '../enums/price-type.enum';
import { ConfirmationDialogsService } from '../../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-supplier-task-pricing-type-settings',
  templateUrl: './supplier-task-pricing-type-settings.component.html',
  styleUrls: ['./supplier-task-pricing-type-settings.component.scss'],
})
export class SupplierTaskPricingTypeSettingsComponent implements OnInit {
  OPTION_PRICE = PriceType.PRICE;
  OPTION_RETAIL_PRICE = PriceType.RETAIL_PRICE;

  @Input() isDatafeed: boolean;
  @Input() selected: PriceType;
  @Input() locked: boolean;
  @Output() selectedChange = new EventEmitter<PriceType>();

  storedSelected: PriceType;

  constructor(
    private confirmationDialogsService: ConfirmationDialogsService,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.storedSelected = this.selected;
  }

  onChange(value: PriceType): void {
    this.confirmationDialogsService
      .confirm(
        this._translateService.instant('TASKS.PRICE_SETTINGS.CONFIRMATION_DIALOG.TITLE'),
        this._translateService.instant('TASKS.PRICE_SETTINGS.CONFIRMATION_DIALOG.MESSAGE'),
        this._translateService.instant('TASKS.PRICE_SETTINGS.CONFIRMATION_DIALOG.BTN_CONTINUE'),
        this._translateService.instant('TASKS.PRICE_SETTINGS.CONFIRMATION_DIALOG.BTN_CANCEL')
      )
      .subscribe((status) => {
        if (!status) {
          this.selected = this.storedSelected;
          return;
        }
        this.storedSelected = this.selected;
        this.selectedChange.emit(value);
      });
  }
}
