<app-loading-screen
  *ngIf="isUpdate || loadingService.isForceLoad"
  [message]="'RETAILER_TASK_WIZARD.WAIT' | translate"
></app-loading-screen>
<mat-card class="mat-card m-20 taskwizard-container">
  <h2 class="task-name">{{ taskName }}</h2>

  <mat-horizontal-stepper [linear]="true" #taskStepper>
    <!--Mapping-->
    <mat-step #mappingStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.MAPPING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #addFilesWrapper [backNavigationUrl]="variantsUpdateListUrl">
          <app-addfiles
            #stepComponent
            [role]="'RETAILER_VARIANTS_UPDATE'"
            [currentTaskId]="taskId"
            (sourceTypeCallBack)="setSourceType($event)"
          ></app-addfiles>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Filter-->
    <!--<mat-step #filterStep>-->
    <!--<ng-template matStepLabel>Filter</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #filterWrapper>-->
    <!--<app-filter #stepComponent *ngIf="filterWrapper.isStepActive()" [taskId]="taskId" [usedComponent]="'productUpload'" [catalogId]="rcatalogId"></app-filter>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Categories-->
    <!--<mat-step #categoryMappingStep>-->
    <!--<ng-template matStepLabel>Categories</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #categoryMappingWrapper>-->
    <!--<app-retailer-category-mapping *ngIf="categoryMappingWrapper.isStepActive()" #stepComponent-->
    <!--[taskId]="rcatalogId"></app-retailer-category-mapping>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Pricing-->
    <mat-step #pricingEditorStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.PRICING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <div style="overflow: auto">
          <app-step-wrapper #pricingWrapper>
            <app-retailer-pricing
              *ngIf="stepper.selected === pricingEditorStep"
              #stepComponent
              [type]="'IMPORT'"
            ></app-retailer-pricing>
          </app-step-wrapper>
        </div>
      </div>
    </mat-step>

    <!--Settings-->
    <mat-step #scheduleStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.SETTINGS' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #scheduleWrapper [backNavigationUrl]="variantsUpdateListUrl">
          <app-retailer-variants-update-settings
            #stepComponent
            [taskId]="taskId"
            [rcatalogId]="rcatalogId"
            (voChanged)="handleVoChanged($event)"
            [retailerSettingsVO]="retailerVO"
          >
          </app-retailer-variants-update-settings>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Summary-->
    <mat-step #scheduleStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.SUMMARY' | translate }}</ng-template>
      <div *ngIf="enableComponents && retailerVoLoaded">
        <app-step-wrapper
          #summaryWrapper
          [nextButtonLabel]="'RETAILER_TASK_WIZARD.FINISH' | translate"
          [backNavigationUrl]="variantsUpdateListUrl"
        >
          <app-retailer-variants-update-summary
            #stepComponent
            [taskId]="taskId"
            [rcatalogId]="rcatalogId"
            [retailerSettingsVO]="retailerVO"
          ></app-retailer-variants-update-summary>
        </app-step-wrapper>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-card>
