import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { SearchSession } from './model/search-session';
import { map } from 'rxjs/operators';
import { SearchSessionData } from './model/search-session-data';
import { SearchData } from './model/search-data';
import { ProductClicksData } from './model/product-clicks-data';
import { SpringPage } from '../../vo/pagination/spring-page';
import { SpringPageable } from '../../vo/pagination/spring-pageable';

@Injectable({
  providedIn: 'root',
})
export class SearchSessionRestService {
  constructor(private springRestService: SpringRestService) {}

  createGuestSession(): Observable<SearchSession> {
    return this.springRestService.post(
      MicroserviceNames.PRODUCT_SEARCH,
      '/v1/SearchSession',
      undefined,
      undefined,
      true
    );
  }

  createUserSession(userId: number): Observable<SearchSession> {
    return this.springRestService.post(
      MicroserviceNames.PRODUCT_SEARCH,
      `/v1/SearchSession/${userId}`,
      undefined,
      undefined,
      true
    );
  }

  patchUserSession(searchSessionId: number, userId: number): Observable<SearchSession> {
    return this.springRestService.patch(
      MicroserviceNames.PRODUCT_SEARCH,
      `/v1/SearchSession/${searchSessionId}`,
      {
        userId: userId,
      },
      undefined,
      true
    );
  }

  sendProductClick(searchSessionId: number, productId: string): Observable<void> {
    return this.springRestService
      .post(MicroserviceNames.PRODUCT_SEARCH, '/v1/ProductClicks', { searchSessionId, productId }, undefined, true)
      .pipe(map(() => {}));
  }

  sendSupplierClick(searchSessionId: number, supplierUserId: number): Observable<void> {
    return this.springRestService
      .post(
        MicroserviceNames.PRODUCT_SEARCH,
        '/v1/SupplierClicks',
        { searchSessionId, supplierUserId },
        undefined,
        true
      )
      .pipe(map(() => {}));
  }

  sendSearchData(searchSessionId: number, searchData: SearchSessionData): Observable<void> {
    return this.springRestService
      .post(MicroserviceNames.PRODUCT_SEARCH, '/v1/SearchData', { searchSessionId, ...searchData }, undefined, true)
      .pipe(map(() => {}));
  }

  getSearchData(userId: number, page = 0, size = 5, sortOrder = 'DESC'): Observable<SpringPage<SearchData>> {
    return this.springRestService.get(
      MicroserviceNames.PRODUCT_SEARCH,
      '/v1/SearchData/GetSearchData',
      { userId, page, size, sortOrder },
      true
    );
  }

  getProductClicks(
    userId: number,
    closedSessionsOnly = true,
    pagination?: SpringPageable
  ): Observable<SpringPage<ProductClicksData>> {
    return this.springRestService.get(
      MicroserviceNames.PRODUCT_SEARCH,
      '/v1/SearchSession/GetSessionsWithProductClicks',
      { userId, ...pagination, closedSessionsOnly },
      true
    );
  }
}
