import { Injectable } from '@angular/core';
import { BillingInfoVO } from '../../vo/billing-vo';
import { PaymentAccount } from '../payment/payment.service';

@Injectable({
  providedIn: 'root',
})
export class BillingMapperService {
  constructor() {}

  mapBillingInfoVOToPaymentAccount(
    billingInfo: BillingInfoVO,
    paymentAccountId?: number,
    paymentAddressId?: number,
    sandbox?: boolean
  ): Partial<PaymentAccount> {
    return {
      ...(!!paymentAccountId && { id: paymentAccountId }),
      firstName: billingInfo.firstName,
      lastName: billingInfo.lastName,
      companyName: billingInfo.companyName,
      euVat: billingInfo.euVat,
      taxNumber: billingInfo.taxNumber,
      sandbox: sandbox,
      paymentAddress: {
        ...(!!paymentAddressId && { id: paymentAddressId }),
        address1: billingInfo.address1,
        address2: billingInfo.address2,
        city: billingInfo.city,
        zipCode: billingInfo.zip,
        country: billingInfo.country,
      },
    };
  }

  mapPaymentAccountToBillingInfoVO(paymentAccount: PaymentAccount): BillingInfoVO {
    return {
      firstName: paymentAccount.firstName,
      lastName: paymentAccount.lastName,
      companyName: paymentAccount.companyName,
      euVat: paymentAccount.euVat,
      taxNumber: paymentAccount.taxNumber,
      country: paymentAccount.paymentAddress?.country,
      city: paymentAccount.paymentAddress?.city,
      address1: paymentAccount.paymentAddress?.address1,
      address2: paymentAccount.paymentAddress?.address2,
      zip: paymentAccount.paymentAddress?.zipCode,
    };
  }
}
