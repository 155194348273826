import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-partners',
  standalone: true,
  imports: [CommonModule, FlexModule],
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit {
  /**
   * provide fxLayout values
   */
  @Input() layoutAlign = 'start  center';
  @Input() gap = 4;
  constructor() {}

  ngOnInit(): void {}

  protected readonly partners = partners;
}

export interface PartnerItem {
  key: string;
  value: string;
}

export const partners: PartnerItem[] = [
  {
    key: 'PAYPAL',
    value: '/assets/images/logos/footer/partner/paypal.png',
  },
  {
    key: 'STRIPE',
    value: '/assets/images/logos/footer/partner/stripe.png',
  },
  {
    key: 'VISA',
    value: '/assets/images/logos/footer/partner/visa.png',
  },
  {
    key: 'MASTERCARD',
    value: '/assets/images/logos/footer/partner/mastercard.png',
  },
  {
    key: 'MAESTRO',
    value: '/assets/images/logos/footer/partner/maestro.png',
  },
  {
    key: 'AMERICAN_EXPRESS',
    value: '/assets/images/logos/footer/partner/american_experss.png',
  },
  {
    key: 'DISCOVER',
    value: '/assets/images/logos/footer/partner/discover.png',
  },
  {
    key: 'JCB',
    value: '/assets/images/logos/footer/partner/jcb.png',
  },
  {
    key: 'DINERS_CLUB',
    value: '/assets/images/logos/footer/partner/diners_club.png',
  },
];
