import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlatformDialogComponent, PlatformDialogData } from '../platform-dialog/platform-dialog.component';
import { EcomTypeEnum } from '../../../../vo/enums/ecom-type-enum';
import { SynceeAppType } from '../../../../service/user-apps/syncee-apps.service';
import { RolesEnum } from '../../../../vo/roles/roles';

@Component({
  selector: 'app-platform-card',
  templateUrl: './platform-card.component.html',
  styleUrls: ['./platform-card.component.scss'],
})
export class PlatformCardComponent implements OnInit {
  @Input()
  cardData: PlatformCardData;
  @Input()
  type: CardType;
  @Input()
  isInstalled: boolean;
  @Input() role: RolesEnum;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  handleCardClick(): void {
    switch (this.type) {
      case 'PARTNER_APP':
        window.open(this.cardData.learnMoreUrlRetailer, '_blank');
        break;
      case 'PLATFORM':
      case 'SYNCEE_APPS': {
        this.dialog.open<PlatformDialogComponent, PlatformDialogData>(PlatformDialogComponent, {
          width: '1136px',
          maxWidth: '1136px',
          panelClass: ['custom-modal-container', 'custom-modal-full-width-lt-md'],
          autoFocus: false,
          data: {
            platformData: {
              platformCardData: this.cardData,
              isInstalled: this.isInstalled,
            },
            type: this.type,
            role: this.role,
          },
        });
        break;
      }
    }
  }
}

export type PlatformType = SynceeAppType | EcomTypeEnum | PartnerAppType;

export type CardType = 'PLATFORM' | 'SYNCEE_APPS' | 'PARTNER_APP';

export enum PartnerAppType {
  PAGE_FLY = 'PAGEFLY',
  UP_PROMOTE = 'UP_PROMOTE',
  RE_CONVERT = 'RE_CONVERT',
  AVADA = 'AVADA',
  VIDEO_AD_MACHINE = 'VIDEO_AD_MACHINE',
  UPSELL_CROSS_SELL = 'UPSELL_CROSS_SELL',
  TOASTI_BAR = 'TOASTI_BAR',
  BUNDLER = 'BUNDLER',
  ORDER_17TRACK = 'ORDER_17TRACK',
  SMS_AND_PUSH_MARKETING = 'SMS_AND_PUSH_MARKETING',
  ULTIMATE_SALES_BOOST = 'ULTIMATE_SALES_BOOST',
  PREFIXBOX = 'PREFIXBOX',
  SEARCHPIE = 'SEARCHPIE',
  FOXIFY = 'FOXIFY',
  FOXKIT = 'FOXKIT',
  FORDEER = 'FORDEER',
  ATTRIBULY_MARKETING_ANALYTICS = 'ATTRIBULY_MARKETING_ANALYTICS',
}

export interface PlatformCardData {
  id?: number;
  title: string;
  description: string;
  descriptionLong?: string;
  logoIconPath: string;
  logoImagePath?: string;
  url: string;
  type: PlatformType;
  needDialogForInstall: boolean;
  learnMoreUrlRetailer?: string;
}
