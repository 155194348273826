<div class="supplier-list-container py-32">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; margin-bottom: 12px">
    <app-skeleton-display-text></app-skeleton-display-text>
  </div>

  <div class="slider-container" fxLayoutAlign="center center">
    <ngx-slick-carousel fxFlex="1 0 0" class="supplier-list-items" [config]="SkeletonSupplierSliderBoxComponent.CONFIG">
      <ng-container *ngFor="let _ of [].constructor(SkeletonSupplierSliderBoxComponent.CONFIG.slidesToShow)">
        <app-skeleton-supplier-card ngxSlickItem class="supplier-item"></app-skeleton-supplier-card>
      </ng-container>
    </ngx-slick-carousel>

    <button fxFlex="0 0 auto" class="navigation-buttons" disabled>
      <mat-icon class="s-16">arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>
