import { Pipe, PipeTransform } from '@angular/core';
import { CPF_COUNTRY_CODES } from '../../../utils/Constants';

@Pipe({
  name: 'isCpfCountry',
})
export class IsCpfCountryPipe implements PipeTransform {
  transform(countryCode: string, ...args: unknown[]): boolean {
    return CPF_COUNTRY_CODES.includes(countryCode);
  }
}
