import { SupplierCatalogNavigation } from './catalog.navigation';
import { MyRetailersNavigation } from './my-retailers.navigation';
import { ProfileNavigation } from './profile.navigation';
import { StorefrontSettingsNavigation } from './storefront-settings.navigation';

const prefix = 'supplier/';

export type SupplierCenterNavigation = (typeof SupplierCenterNavigation)[keyof typeof SupplierCenterNavigation];
export const SupplierCenterNavigation = {
  DASHBOARD: `${prefix}dashboard-overview`,
  CATALOG: SupplierCatalogNavigation,
  ORDERS: `${prefix}orders`,
  MY_RETAILERS: MyRetailersNavigation,
  MESSAGES: `${prefix}messages`,
  STOREFRONT_SETTINGS: StorefrontSettingsNavigation,
  SETTINGS: ProfileNavigation,
} as const;
