<div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 100%" fxLayoutGap="5px">
  <h2 mat-dialog-title>{{ 'TASKS.NEW_TASK' | translate }}</h2>

  <div *ngIf="hasNotValidEcom">
    <span class="error" *ngIf="isExportTask()">{{ 'TASKS.ECOM_ERROR_EXPORT' | translate }}</span>
    <span class="error" *ngIf="isVUTask()">{{ 'TASKS.ECOM_ERROR_VARIANTS' | translate }}</span>
    <span class="error" *ngIf="!isExportTask() && !isVUTask()"
      >{{ 'TASKS.ECOM_ERROR_IMPORT' | translate }} {{ AvailableEcomTypesForImportDatafeed.join(', ') }}.</span
    >
  </div>

  <form id="add-task-form" mat-dialog-content [formGroup]="form">
    <mat-form-field fxFlex="100" appearance="fill">
      <input matInput placeholder="{{ 'TASKS.NAME_PLACEHOLDER' | translate }}" formControlName="name" required />
      <mat-error>{{ 'TASKS.TASK_NAME_REQUIRED' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field id="domain-select" *ngIf="isDomainVisible()" appearance="fill">
      <mat-select placeholder="{{ 'TASKS.DOMAIN_PLACEHOLDER' | translate }}" formControlName="domain">
        <mat-option *ngFor="let domain of domains" [value]="domain.domain">
          {{ domain.domain }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field id="domain-ecomid-select" *ngIf="isDomainWithEcomIDVisible()" appearance="fill">
      <mat-select placeholder="{{ 'TASKS.DOMAIN_PLACEHOLDER' | translate }}" formControlName="domain">
        <mat-option *ngFor="let domain of domains" [value]="domain.id">
          {{ domain.domain }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!starterPlanIsntFull" class="starter-exceed-error">
      <mat-icon>warning</mat-icon>
      <span
        >{{ 'TASKS.STARTER_FULL' | translate }}
        <a (click)="navTo('/billing/subscription')">{{ 'TOOLBAR.UPGRADE_PLAN' | translate }}</a></span
      >
    </div>

    <div *ngIf="isExportTask()">
      <mat-form-field appearance="fill">
        <mat-select
          #fileType
          [disabled]="eTypeDisable"
          placeholder="{{ 'TASKS.FILE_TYPE_PLACEHOLDER' | translate }}"
          formControlName="fileType"
        >
          <mat-option *ngFor="let type of exportTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="isCSVFileType" class="csv-settings" formGroupName="csvSettings">
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px">
          <mat-form-field fxFlex="33" appearance="fill">
            <mat-select placeholder="{{ 'TASKS.DELIMETER_PLACEHOLDER' | translate }}" formControlName="delimiter">
              <mat-option *ngFor="let del of delimiters" [value]="del.code">{{ del.name }} </mat-option>
            </mat-select>
            <mat-error>
              {{ 'TASKS.DELIMETER_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="33" appearance="fill">
            <mat-select placeholder="{{ 'TASKS.ENCLOSURE_PLACEHOLDER' | translate }}" formControlName="enclosure">
              <mat-option *ngFor="let enclosure of enclosures" [value]="enclosure.code"
                >{{ enclosure.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'TASKS.ENCLOSURE_REQURIED' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="33" appearance="fill">
            <mat-select placeholder="{{ 'TASKS.END_PLACEHOLDER' | translate }}" formControlName="eol">
              <mat-option *ngFor="let endofline of endoflines" [value]="endofline.code"
                >{{ endofline.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'TASKS.END_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex="1 0 auto" appearance="fill">
            <mat-select formControlName="encoding" placeholder="{{ 'TASKS.CHARACTER_PLACEHOLDER' | translate }}">
              <mat-option *ngFor="let encoding of characterEncodings" [value]="encoding.code">
                {{ encoding.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'TASKS.CHARACTER' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>{{ 'TASKS.USE_TEMPLATE' | translate }}</div>
      <mat-form-field appearance="fill">
        <mat-select
          (selectionChange)="templateSelected($event)"
          placeholder="{{ 'TASKS.TEMPLATES_PLACEHOLDER' | translate }}"
          formControlName="template"
        >
          <mat-option [value]="'0'">
            {{ 'TASKS.DONT_USE_TEMPLATE' | translate }}
          </mat-option>
          <mat-option *ngFor="let tmp of templates" [value]="tmp.id">
            {{ tmp.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
    <div>
      <button class="mat-button" style="margin-right: 5px" (click)="close()">
        {{ 'TASKS.CLOSE' | translate }}
      </button>
      <button
        class="mat-button mat-accent"
        [disabled]="form.invalid || !starterPlanIsntFull || isLoading"
        (click)="onSubmit()"
      >
        {{ 'TASKS.SAVE' | translate }}
      </button>
    </div>
    <div *ngIf="isLoading" class="loading-spinner-container">
      <mat-spinner [diameter]="24"></mat-spinner>
    </div>
  </div>
</div>
