import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../layout/layout.service';
import { AiChatLayout } from './model/layout/ai-chat-layout';
import { AiChatService } from './services/ai-chat.service';

@Component({
  selector: 'app-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.scss'],
})
export class AiChatComponent implements OnInit {
  @Input() layout: AiChatLayout;
  aiChatLayoutEnum = AiChatLayout;

  constructor(public layoutService: LayoutService, public aiChatService: AiChatService) {}

  ngOnInit(): void {}
}
