import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  RetailerDashboardAnalyticsService,
  TimelineDataSetDto,
} from '../../../service/analytics/retailer-dashboard-analytics.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { getSelectedEcomByRole } from '../../../store/ecom/ecom.selector';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { CurrencyService } from '../../../service/currency-service/currency.service';
import { StatisticType } from '../orders-chart/orders-chart.component';
import { camelCase } from 'lodash';
import { RolesEnum } from '../../../vo/roles/roles';

@Component({
  selector: 'app-numeric-card-list',
  templateUrl: './numeric-card-list.component.html',
  styleUrls: ['./numeric-card-list.component.scss'],
})
export class NumericCardListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() role: RolesEnum;
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  @Input()
  hidePercentage = false;
  dataSets: TimelineDataSetDto[];
  beforeDataSets: TimelineDataSetDto[];
  ecomCurrency: string;
  protected selectedEcomCurrency: string;

  private readonly unsubscribeAll: Subject<void>;

  constructor(
    private retailerDashboardAnalyticsService: RetailerDashboardAnalyticsService,
    private store: Store<AppState>
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getCurrencyFromEcom();
    this.getDailyAnalytics();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.getDailyAnalytics();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private getCurrencyFromEcom(): void {
    this.store
      .select(getSelectedEcomByRole(this.role))
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((ecom) => !!ecom),
        tap((ecom) => (this.selectedEcomCurrency = ecom?.options?.currency))
      )
      .subscribe((selectedEcom) => (this.ecomCurrency = CurrencyService.getCurrency(selectedEcom)));
  }

  getValue(type: StatisticType): number {
    const propertyName = camelCase(type.toLowerCase());
    return this.dataSets[0].rangeDtos[0][propertyName] + this.dataSets[1].rangeDtos[0][propertyName] || 0;
  }

  getPercentage(type: StatisticType): number | null {
    if (!this.beforeDataSets || this.hidePercentage) {
      return null;
    }
    const propertyName = camelCase(type.toLowerCase());
    const sum = this.dataSets
      .map((dataSetDto) => dataSetDto.rangeDtos[0][propertyName] as number)
      .reduce((prev, curr) => prev + curr, 0);

    const sumBefore = this.beforeDataSets
      .map((dataSetDto) => dataSetDto.rangeDtos[0][propertyName] as number)
      .reduce((prev, curr) => prev + curr, 0);

    if (sumBefore === 0) {
      if (sum > 0) {
        return 1;
      } else if (sum === 0) {
        return 0;
      } else {
        return -1;
      }
    } else {
      return sum / sumBefore;
    }
  }

  private getDailyAnalytics(): void {
    this.retailerDashboardAnalyticsService
      .getDailyOrderAnalytics(this.dateFrom, this.dateTo, 1)
      .pipe(take(1))
      .subscribe((dataSets) => (this.dataSets = dataSets));
    this.getDailyAnalyticsBeforeRange();
  }

  private getDailyAnalyticsBeforeRange(): void {
    if (!!this.dateFrom) {
      const to = !!this.dateTo ? this.dateTo.getTime() : new Date().getTime();
      const dateBeforeFrom = new Date(this.dateFrom.getTime() - (to - this.dateFrom.getTime()));
      this.retailerDashboardAnalyticsService
        .getDailyOrderAnalytics(dateBeforeFrom, this.dateFrom, 1)
        .pipe(take(1))
        .subscribe((dataSets) => (this.beforeDataSets = dataSets));
    }
  }
}
