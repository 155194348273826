<div *ngIf="dataSource; else skeleton" class="w-100p">
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-sm="start start"
    fxLayoutGap="8px"
  >
    <h4 class="m-0">{{ title }}</h4>
  </div>
  <div class="w-100p top-selling-table-container">
    <table mat-table [dataSource]="dataSource" class="w-100p">
      <ng-container matColumnDef="product-image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let product">
          <img class="product-image" src="{{ product.imageUrl }}" alt="product_image" />
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.TITLE' | translate }}
        </th>
        <td mat-cell class="product-title" *matCellDef="let product">{{ product.title }}</td>
      </ng-container>
      <ng-container matColumnDef="products-sold">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.PRODUCTS_SOLD' | translate }}
        </th>
        <td mat-cell *matCellDef="let product">{{ product.productsSold }}</td>
      </ng-container>
      <ng-container matColumnDef="revenue">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.REVENUE' | translate }}
        </th>
        <td mat-cell *matCellDef="let product">
          {{ product.revenue | currencyTransform : paymentCurrency | async }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        class="cursor-pointer"
        *matRowDef="let product; columns: columns"
        [routerLink]="getProductPath(product.synceeId)"
      ></tr>
    </table>
  </div>
  <div
    *ngIf="dataSource && !dataSource.data.length"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="no-data typography-body-1 centered-text"
  >
    {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.NO_DATA' | translate }}
  </div>
</div>

<ng-template #skeleton>
  <app-skeleton-dashboard-box [withCard]="false" [title]="title"></app-skeleton-dashboard-box>
</ng-template>
