import { Pipe, PipeTransform } from '@angular/core';
import { transformToCdnUrl } from 'app/utils/transform-to-cdn-url';

@Pipe({ name: 'countryFlag', standalone: true })
export class CountryFlagPipe implements PipeTransform {
  constructor() {}
  transform(code: string): string {
    return transformToCdnUrl(`country_flags_png/${code.toLowerCase()}.png`);
  }
}
