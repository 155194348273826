import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CountriesLocationsService } from 'app/service/countries-locations/countries-locations.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CountriesLocationActionTypes,
  GetContinentsSuccess,
  GetCountriesLocationsSuccess,
  InitCountries,
} from './countries-locations.actions';

@Injectable()
export class CountriesLocationsEffects implements OnInitEffects {
  constructor(private actions$: Actions, private countriesLocationsService: CountriesLocationsService) {}

  ngrxOnInitEffects(): Action {
    return new InitCountries();
  }

  GetCountries: Observable<GetCountriesLocationsSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesLocationActionTypes.INIT_COUNTRIES),
      switchMap(() =>
        this.countriesLocationsService
          .getLocations()
          .pipe(map((countriesLocations) => new GetCountriesLocationsSuccess(countriesLocations)))
      )
    )
  );

  GetContinents: Observable<GetContinentsSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesLocationActionTypes.INIT_COUNTRIES),
      switchMap(() =>
        this.countriesLocationsService.getContinents().pipe(map((continents) => new GetContinentsSuccess(continents)))
      )
    )
  );
}
