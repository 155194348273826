import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbContainerStyle = new BehaviorSubject<object>(null);

  constructor() {}

  get breadcrumbContainerStyle(): Observable<object> {
    return this._breadcrumbContainerStyle.asObservable();
  }

  setBreadcrumbContainerStyle(style: object): void {
    this._breadcrumbContainerStyle.next(style);
  }
}
