import { Component, OnInit } from '@angular/core';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';

@Component({
  selector: 'app-favorite-icon',
  templateUrl: './favorite-icon.component.html',
  styleUrls: ['./favorite-icon.component.scss'],
})
export class FavoriteIconComponent implements OnInit {
  public readonly favoriteNavigateUrl = `/${RetailerCenterNavigation.FAVORITES.DEFAULT}`;

  constructor() {}

  ngOnInit(): void {}
}
