import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CsvSettingsInputVo } from '../vo/csv-settings-input-vo';
import { ExcelSettingsInputVo } from '../vo/excel-settings-input-vo';
import { FileSettingsService } from '../../../../service/taskwizard/file-settings.service';
import { SettingsInterface } from './settings-interface';
import { SettingsOutputVo } from '../vo/settings-output.vo';
import { Constants } from '../../../../utils/Constants';
import { NgForm, NgModel } from '@angular/forms';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-excel-settings',
  templateUrl: './excel-settings.component.html',
  styleUrls: ['./excel-settings.component.scss'],
})
export class ExcelSettingsComponent implements OnInit, SettingsInterface {
  @ViewChild('form', { static: false }) settingsForm: NgForm;
  @ViewChild('encoding', { static: false }) encoding: NgModel;

  private _input: ExcelSettingsInputVo;

  @Input() set inputs(value: ExcelSettingsInputVo) {
    this._input = value;
    this.init();
  }

  get inputs(): ExcelSettingsInputVo {
    return this._input;
  }

  sheets: string[] = [];
  startRowNum = 1;
  selectedSheet: string;
  isFirstRowHeader = true;
  encodingSelect: string;
  characterEncodings = [
    { id: 1, name: 'UTF-8' },
    { id: 2, name: 'ANSI' },
    { id: 3, name: 'ASCII' },
  ];

  constructor(private settingsService: FileSettingsService) {}

  ngOnInit(): void {}

  init(): void {
    console.log(this.inputs);
    this.settingsService.getSheetsFromExcel(this.inputs.taskId, this.inputs.fileId).subscribe((response) => {
      if (response === false) {
        return;
      }
      this.sheets = response;
    });
    this.startRowNum = !Utils.isNullOrUndefined(this.inputs.startRowNum) ? this.inputs.startRowNum : 1;
    this.isFirstRowHeader = !Utils.isNullOrUndefined(this.inputs.firstRowIsHeader)
      ? this.inputs.firstRowIsHeader
      : true;
    this.selectedSheet = !Utils.isNullOrUndefined(this.inputs.sheet) ? this.inputs.sheet : '';
    this.encodingSelect = this.inputs.encoding;
  }

  getSettings(): SettingsOutputVo {
    Utils.markFormGroupTouched(this.settingsForm.control);
    if (!this.settingsForm.valid) {
      return null;
    }
    const settings = {
      firstRowIsHeader: this.isFirstRowHeader,
      startRowNum: this.startRowNum,
      sheet: this.selectedSheet,
      encoding: this.encodingSelect,
    };
    return new SettingsOutputVo(this.inputs.fileType, settings);
  }

  getSupportedFileTypes(): string[] {
    return [Constants.EXTENSION_XLSX, Constants.EXTENSION_XLS];
  }

  refresh(fileType: string): void {}

  update(): void {}
}
