const COLLECTIONS = 'collections';
const PRODUCT = 'product';
const STOREFRONT = 'supplier';
const DISCOVERY = 'discovery';

export type MARKETPLACE_NAVIGATION = (typeof MARKETPLACE_NAVIGATION)[keyof typeof MARKETPLACE_NAVIGATION];
export const MARKETPLACE_NAVIGATION = {
  COLLECTIONS: COLLECTIONS,
  PRODUCT: PRODUCT,
  STOREFRONT: STOREFRONT,
  DISCOVERY: DISCOVERY,
  DEFAULT_PRODUCTS_PATH: `${COLLECTIONS}/1/products`,
} as const;
