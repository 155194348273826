import { AfterViewInit, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BreakPoint } from '../../service/screen-manager/screen-manager.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Directive({
  selector: '[showOnBreakpoint]',
  standalone: true,
})
export class ShowOnBreakpointDirective implements AfterViewInit, OnDestroy {
  @Input() showOnBreakpoint: BreakPoints;
  @Input() showOnBreakpointVariant: Variant = 'show';
  @Input() showOnBreakpointFallback: TemplateRef<any>;
  private breakPointId: string;
  private condition: Condition;
  private subscription: Subscription;
  private hasView = false;
  private readonly unsubscribeAll: Subject<void>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngAfterViewInit(): void {
    this.getConfig();
    this.subscribeToResize();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private observer(): Observable<boolean> {
    return this.breakpointObserver
      .observe(
        `(${this.getBreakpointObserverValueCondition()}: ${
          BreakPoint[this.breakPointId] + this.getBreakpointObserverValueOffset()
        }px)`
      )
      .pipe(
        takeUntil(this.unsubscribeAll),
        distinctUntilChanged(),
        map((state) => state.matches)
      );
  }

  private getBreakpointObserverValueCondition(): string {
    switch (this.condition) {
      case 'lt':
      case 'exact': {
        return 'max-width';
      }
      case 'gt':
        return 'min-width';
    }
  }

  private getBreakpointObserverValueOffset(): number {
    switch (this.condition) {
      case 'lt': {
        return -1;
      }
      case 'exact':
        return 0;
      case 'gt': {
        return 1;
      }
    }
  }

  private subscribeToResize(): void {
    if (!this.subscription) {
      this.subscription = this.observer().subscribe((match) => {
        this.handleChange(match);
      });
    }
  }

  private getConfig(): void {
    const split = this.showOnBreakpoint.split('-');
    if (split.length === 1) {
      this.condition = 'exact';
      this.breakPointId = split[0];
    } else {
      this.condition = split[0] as Condition;
      this.breakPointId = split[1];
    }
  }

  private handleChange(matched: boolean): void {
    if (matched) {
      this.showOnBreakpointVariant === 'show' ? this.show() : this.hide();
    } else {
      this.showOnBreakpointVariant === 'show' ? this.hide() : this.show();
    }
  }

  private show(): void {
    if (!this.hasView) {
      this.container.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      this.container.clear();
      this.container.createEmbeddedView(this.templateRef);
    }
  }

  private hide(): void {
    if (!!this.showOnBreakpointFallback) {
      this.container.clear();
      this.container.createEmbeddedView(this.showOnBreakpointFallback);
      this.hasView = true;
    } else {
      if (this.hasView) {
        this.container.clear();
        this.hasView = false;
      }
    }
  }
}

type BreakPoints =
  | 'lt-sm'
  | 'lt-md'
  | 'lt-lg'
  | 'lt-xl'
  | 'gt-sm'
  | 'gt-md'
  | 'gt-lg'
  | 'gt-xl'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl';
type Condition = 'lt' | 'gt' | 'exact';
type Variant = 'show' | 'hide';
