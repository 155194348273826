<app-custom-dialog-wrapper [hideCloseBtn]="true">
  <div *conditionLoading="!loading" fxLayout="column" fxLayoutGap="40px" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutGap="12" fxLayoutAlign="start center">
      <h4 class="mt-0">{{ 'SYNCEE_REVIEW_DIALOG.QUESTIONS.' + questionKey + '.TITLE' | translate }}</h4>
      <div>
        {{
          rating === 3
            ? '3/5 is fine but not awesome. Any comment on Syncee is super helpful!'
            : 'Any comment on Syncee is super helpful!'
        }}
      </div>
    </div>
    <app-chip-list-selector
      [items]="chipListItems"
      [(selectedItems)]="selectedItems"
      (selectedItemsChange)="handleSelectionChange($event)"
    ></app-chip-list-selector>
    <ng-container *ngIf="isCustomSelected">
      <mat-form-field class="input-without-padding w-100p" appearance="fill">
        <textarea
          matInput
          [placeholder]="'Write a comment...'"
          [(ngModel)]="customAnswer"
          (ngModelChange)="handleCustomAnswerChange($event)"
        ></textarea>
      </mat-form-field>
    </ng-container>
  </div>
</app-custom-dialog-wrapper>
