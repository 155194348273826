<!--<div class="settings-field">-->
<!--<div>-->
<!--<p>Type</p>-->
<!--</div>-->

<!--<mat-form-field appearance="fill">-->
<!--<mat-select value="insert" [(ngModel)]="updateType">-->
<!--<mat-option value="insert">Insert (upload)</mat-option>-->
<!--<mat-option value="update">Update</mat-option>-->
<!--<mat-option value="upsert">Upsert (upload and update)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<div *ngIf="settingsForm" [formGroup]="settingsForm">
  <mat-card class="retailer-mapping-section">
    <!--    <mat-card-header>-->
    <div
      adminComment
      [adminCommentTaskId]="taskId"
      [adminCommentTaskType]="'import'"
      [adminCommentStep]="'settings'"
      [adminCommentMappingField]="'settings'"
      [adminCommentComments]="comments"
    ></div>
    <div class="h3 settings-title">{{ 'RETAILER_TASK_WIZARD.RETAILER.BASIC_MAPPING_SETTINGS' | translate }}</div>
    <!--    </mat-card-header>-->

    <mat-card-content formGroupName="settings">
      <!--Published-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.PUBLISHED' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.IF_PUBLISHED' | translate }}</p>
        </div>

        <div>
          <mat-slide-toggle [checked]="true" formControlName="published"></mat-slide-toggle>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select [value]="true" formControlName="published">-->
          <!--<mat-option *ngFor="let option of mappingSettingsService?.trueOrFalseOptions"-->
          <!--[value]="option.value">-->
          <!--{{option.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--Charge taxes on this product-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.CHARGE_TAXES' | translate }}</p>
        </div>

        <div>
          <mat-slide-toggle [checked]="true" formControlName="taxes"></mat-slide-toggle>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select [value]="true" formControlName="taxes">-->
          <!--<mat-option *ngFor="let option of mappingSettingsService?.trueOrFalseOptions"-->
          <!--[value]="option.value">-->
          <!--{{option.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--Inventory management-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.INVENTORY_MANAGEMENT' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.IF_YOU_ARE_USING' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="inventory">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DONT_TRACK' | translate }} </mat-option>
              <mat-option value="track">{{ 'RETAILER_TASK_WIZARD.RETAILER.SHOPIFY_TRACKS' | translate }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Backorder-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.BACKORDER' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.ALLOW_CUSTOMER' | translate }}</p>
        </div>

        <div>
          <mat-slide-toggle [checked]="true" formControlName="backorder"></mat-slide-toggle>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select value="supplierDefault" formControlName="backorder">-->
          <!--<mat-option value="supplierDefault">Supplier default</mat-option>-->
          <!--<mat-option value="allow">Allow</mat-option>-->
          <!--<mat-option value="notAllow">Not allow</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--This product requires shipping-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.THIS_PRODUCT' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.REQUIRE_SHIPPING_DESC' | translate }}</p>
        </div>

        <div>
          <mat-slide-toggle [checked]="true" formControlName="requires_shipping"></mat-slide-toggle>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select [value]="true" formControlName="shipping">-->
          <!--<mat-option *ngFor="let option of mappingSettingsService?.yesOrNoOptions"-->
          <!--[value]="option.value">-->
          <!--{{option.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--Fulfillment Service-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.FULFILLMENT_SERVICE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.PLEASE_NOTE_THAT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="manual" formControlName="fulfillment">
              <mat-option value="manual">{{ 'RETAILER_TASK_WIZARD.RETAILER.MANUAL' | translate }} </mat-option>
              <mat-option *ngFor="let option of fulfillmentServices" [value]="option.handle">
                {{ option.service_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Weight unit-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WEIGHT_UNIT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="kg" formControlName="weightUnit">
              <mat-option value="kg">kg</mat-option>
              <mat-option value="oz">oz</mat-option>
              <mat-option value="lb">lb</mat-option>
              <mat-option value="g">g</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Point of Sale visibility-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.POINT_OF_SALE' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="global" formControlName="pointOfSale">
              <mat-option value="global"
                >{{ 'RETAILER_TASK_WIZARD.RETAILER.THE_PRODUCTS_ARE' | translate }}
              </mat-option>
              <mat-option value="onlineStore"
                >{{ 'RETAILER_TASK_WIZARD.RETAILER.THE_PRODUCTS_ARE_NOT' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Locations-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>Locations</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select [value]="locations[0].id" (change)="updateChanges()" formControlName="locations">-->
      <!--<mat-option *ngFor="let option of locations"-->
      <!--[value]="option.id">-->
      <!--{{option.name}}-->
      <!--</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->
    </mat-card-content>
  </mat-card>

  <mat-card class="retailer-mapping-section">
    <!--    <mat-card-header>-->
    <div class="h3 settings-title">{{ 'RETAILER_TASK_WIZARD.RETAILER.UPDATE_OPTIONS' | translate }}</div>
    <!--    </mat-card-header>-->

    <!--        <mat-card-content formGroupName="updateOptions">-->
    <!--            <div class="settings-field">-->
    <!--                <div>-->
    <!--                    <p>-->
    <!--                        Upload new products-->
    <!--                    </p>-->
    <!--                    <p>-->
    <!--                        If you use an entire supplier catalog Syncee will upload new products when the supplier adds new products to the catalog.-->
    <!--                    </p>-->
    <!--                </div>-->
    <!--                <div>-->
    <!--                    <mat-slide-toggle [checked]="true" formControlName="uploadNewProducts">-->

    <!--                    </mat-slide-toggle>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </mat-card-content>-->

    <mat-card-content formGroupName="updateOptions">
      <!--Update type-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.UPDATE_TYPE' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="insert" formControlName="updateType">
              <mat-option value="UPLOAD_DF_MODE">{{ 'RETAILER_TASK_WIZARD.RETAILER.INSERT' | translate }} </mat-option>
              <mat-option value="UPDATE_DF_MODE">{{ 'RETAILER_TASK_WIZARD.RETAILER.UPDATE' | translate }} </mat-option>
              <mat-option value="CRUD_DF_MODE">{{ 'RETAILER_TASK_WIZARD.RETAILER.UPSERT' | translate }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Product not in the feed-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WHAT_SHALL' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.THIS_SETTINGS_ONLY' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0hidden" formControlName="productNotInFeed">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_TOUCH' | translate }} </mat-option>
              <mat-option value="qty0">{{ 'RETAILER_TASK_WIZARD.RETAILER.SET_THE_QTY' | translate }} </mat-option>
              <mat-option value="qty0hidden">{{
                'RETAILER_TASK_WIZARD.RETAILER.HIDE_THE_PRODUCT' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!--0 quantity feed-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WHAT_SHALL_WE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.THIS_SETTING_ONLY_MODIFIES' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0" formControlName="productQuantity0">
              <mat-option value="qty0">{{ 'RETAILER_TASK_WIZARD.RETAILER.SET_THE_QTY' | translate }} </mat-option>
              <mat-option value="qty0hidden">{{
                'RETAILER_TASK_WIZARD.RETAILER.HIDE_THE_PRODUCT' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Compare-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.HOW_CAN_SYNCEE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.IF_YOU_ALREADY' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="compare">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_COMPARE' | translate }} </mat-option>
              <mat-option value="sku">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_SKU' | translate }}</mat-option>
              <mat-option value="title">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_PRODUCT_TITLE' | translate }} </mat-option>
              <mat-option value="handle">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_HANDLE' | translate }} </mat-option>
              <mat-option value="barcode">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_BARCODE' | translate }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Variant compare-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.HOW_CAN_SYNCEE2' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_DEFAULT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="variantCompare">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_COMPARE' | translate }} </mat-option>
              <mat-option value="sku">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_VARIANT_SKU' | translate }} </mat-option>
              <mat-option value="barcode">{{
                'RETAILER_TASK_WIZARD.RETAILER.BY_VARIANT_BARCODE' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Rename images-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WOULD_YOU_LIKE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.IF_YOU_SELECT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="yes" formControlName="renameImages">
              <mat-option value="yes">{{ 'RETAILER_TASK_WIZARD.RETAILER.YES' | translate }}</mat-option>
              <mat-option value="no">{{ 'RETAILER_TASK_WIZARD.RETAILER.NO' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Multi-location inventory changes-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.HOW_SHOULD_SYNCEE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.SHOULD_SYNCEE' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="multiLocationInventoryChanges">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_RELOCATE' | translate }} </mat-option>
              <mat-option value="relocate">{{ 'RETAILER_TASK_WIZARD.RETAILER.RELOCATE' | translate }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- 0/delete qty variant -->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPIFY.WHAT_SHALL_3' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0" formControlName="outVariantDelete">
              <mat-option value="qty0"
                >{{ 'RETAILER_CATALOG_SETTING.SHOPIFY.SET_TO_ZERO_QTY' | translate }}
              </mat-option>
              <mat-option value="delete">{{
                'RETAILER_CATALOG_SETTING.SHOPIFY.DELETE_THESE_VARIANTS' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- upload rule qty variant -->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPIFY.WHAT_SHALL_4' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0" formControlName="variantQtyUpload">
              <mat-option value="dont">{{ 'RETAILER_CATALOG_SETTING.SHOPIFY.NOT_UPLOAD' | translate }} </mat-option>
              <mat-option value="qty0">{{
                'RETAILER_CATALOG_SETTING.SHOPIFY.UPLOAD_WITH_ZERO_QTY' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Fields to update-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WHAT_FIELDS' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.SYNCEE_UPDATES' | translate }}</p>
        </div>

        <app-checkbox-selection-list (checkBoxesChange)="updateChanges()" [(checkBoxes)]="fieldsToUpdate">
        </app-checkbox-selection-list>
      </div>

      <!--Fields not to update-->
      <div id="fields-not-to-update-container" class="settings-field">
        <div></div>

        <app-checkbox-selection-list (checkBoxesChange)="updateChanges()" [(checkBoxes)]="optionFieldsUpdate">
        </app-checkbox-selection-list>
      </div>
    </mat-card-content>
  </mat-card>

  <!--Save button-->
  <!--<div id="retailer-catalog-settings-save" class="centered" *ngIf="showSaveButton">
        <button mat-button color="primary">Save</button>
    </div>-->
</div>
