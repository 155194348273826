import { Injectable } from '@angular/core';
import { GettingStartedStepperService } from './getting-started-stepper.service';

@Injectable()
export class GettingStartedStepBaseService {
  constructor(private gettingStartedStepperService: GettingStartedStepperService) {}
  goToNextStep(skip: boolean): void {
    this.gettingStartedStepperService.goToNextStep(skip);
  }

  goToPreviousStep(): void {
    this.gettingStartedStepperService.goToPreviousStep();
  }

  hasNextStep(): boolean {
    return this.gettingStartedStepperService.hasNextStep();
  }

  hasPreviousStep(): boolean {
    return this.gettingStartedStepperService.hasPreviousStep();
  }

  isCompleted(): boolean {
    return this.gettingStartedStepperService.currentStepIsCompleted();
  }

  setCurrentSubStep(index: number): void {
    this.gettingStartedStepperService.currentSubStep = index;
  }

  getCurrentSubStep(): number {
    return this.gettingStartedStepperService.currentSubStep;
  }
}
