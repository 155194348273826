import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskIsActiveBackground',
})
export class TaskIsActiveBackgroundPipe implements PipeTransform {
  transform(value: string): string {
    return value === '1' ? 'var(--app-syncee-primary-50)' : 'var(--app-red-100)';
  }
}
