import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { SearchSessionRestService } from './search-session-rest.service';
import { SearchSessionService } from './search-session.service';
import { SearchSessionData } from './model/search-session-data';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SearchSessionEventService {
  private stopRetry = new Subject<void>();
  private static RETRY_LIMIT = 2;
  private sendSearchDataEvent$ = new Subject<SearchSessionData>();
  private sendProductClickEvent$ = new Subject<string>();
  private sendSupplierClickEvent$ = new Subject<number>();

  constructor(
    private searchSessionRestService: SearchSessionRestService,
    private searchSessionService: SearchSessionService
  ) {
    this.subscribeToEvents();
  }

  sendProductClickEvent(productId: string): void {
    this.sendProductClickEvent$.next(productId);
  }

  sendSupplierClickEvent(supplierUserId: number): void {
    this.sendSupplierClickEvent$.next(supplierUserId);
  }

  sendSearchDataEvent(searchData: SearchSessionData): void {
    this.sendSearchDataEvent$.next(searchData);
  }

  private subscribeToEvents(): void {
    this.sendSearchDataEvent$
      .pipe(this.sendEventOperator((sessionId, data) => this.searchSessionRestService.sendSearchData(sessionId, data)))
      .subscribe();
    this.sendProductClickEvent$
      .pipe(
        this.sendEventOperator((sessionId, data) =>
          this.searchSessionRestService
            .sendProductClick(sessionId, data)
            .pipe(tap(() => this.searchSessionService.addProductClickManually(data)))
        )
      )
      .subscribe();
    this.sendSupplierClickEvent$
      .pipe(
        this.sendEventOperator((sessionId, data) => this.searchSessionRestService.sendSupplierClick(sessionId, data))
      )
      .subscribe();
  }

  private apiCallOperator<T>(
    api: (sessionId: number, data: T) => Observable<void>
  ): (source: Observable<T>) => Observable<void> {
    return (source: Observable<T>): Observable<void> =>
      combineLatest([source, this.searchSessionService.session$]).pipe(
        switchMap(([data, session]) => api(session.id, data).pipe(this.repeaterOperator(1)))
      );
  }

  private repeaterOperator(retryCount: number): (source: Observable<void>) => Observable<void> {
    return (source: Observable<void>): Observable<void> =>
      source.pipe(
        catchError((error) => {
          if (error.errorCode == HttpStatusCode.Locked) {
            return this.searchSessionService.createNewSession$.pipe(
              tap(() => this.handleRetry(retryCount)),
              switchMap(() => source.pipe(this.repeaterOperator(retryCount + 1)))
            );
          } else {
            return throwError(error);
          }
        }),
        takeUntil(this.stopRetry)
      );
  }

  private sendEventOperator<T>(
    mapTo: (sessionId: number, data: T) => Observable<void>
  ): (source: Observable<T>) => Observable<void> {
    return (source: Observable<T>): Observable<void> =>
      source.pipe(
        filter(() => this.searchSessionService.isEventValid()),
        distinctUntilChanged<T>(isEqual),
        this.apiCallOperator<T>(mapTo)
      );
  }

  private handleRetry(retryCount: number): void {
    if (retryCount >= SearchSessionEventService.RETRY_LIMIT) {
      this.stopRetry.next();
    }
  }
}
