import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentication/authentication.service';
import { RestService } from '../../../../service/rest/rest.service';
import { HttpHeaders } from '@angular/common/http';
import { FileUploadResponseVo } from '../vo/file-upload-response-vo';
import { Constants } from '../../../../utils/Constants';
import { Observable, ReplaySubject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { FileUploadDownloadInputVo } from '../vo/file-upload-download-input-vo';
import { NotificationService } from '../../../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/service/user/user.service';
import { AlreadyUploadedFile } from '../../../../shared/components/drag-and-drop-file/drag-and-drop-file.component';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { Utils } from 'app/utils/utils';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { hasRolesSelector } from '../../../../store/user/user.selector';
import { RolesEnum } from '../../../../vo/roles/roles';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  public static FILE_LIMIT = 150;

  private _inputSync: ReplaySubject<FileUploadDownloadInputVo> = new ReplaySubject<FileUploadDownloadInputVo>(1);

  @Input() set inputs(value: FileUploadDownloadInputVo) {
    this._inputSync.next(value);
  }

  @Output() callBack = new EventEmitter<FileUploadResponseVo>();
  @Output() downloadFileActive = new EventEmitter<boolean>();
  @Output() selectedTypeCallBack = new EventEmitter<string>();

  currentTaskId: number;

  postUrl = 'TaskFileUploadService/uploadFile';
  files: File[] = [];

  isFileSelected = false;
  selectedFile: File;
  selectedFileConvertedSize: string;

  isUploadInProgress = false;

  validComboDrag;

  items = [];

  fileExtensions = Constants.UPLOAD_FILE_EXTENSIONS;

  selectedFileExtension: string = Constants.EXTENSION_AUTO;

  isParamsPassed = false;
  selectedFileName: string;
  fileId: number;
  disableFileTypeSelector = false;

  disableUploadBtn = false;
  alreadyUploaded: AlreadyUploadedFile;
  allowedExtensions = ['json', 'xml', 'csv', 'xls', 'xlsx'];

  allowedMIMETypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/msword',
    'application/xml',
    'application/vnd.ms-excel',
  ];

  ltMd$: Observable<boolean>;

  constructor(
    private auth: AuthenticationService,
    private notService: NotificationService,
    private userService: UserService,
    private translateService: TranslateService,
    private restService: RestService,
    private screenManagerService: ScreenManagerService,
    private store: Store<AppState>
  ) {
    this.ltMd$ = this.getLtMd();
  }

  ngOnInit(): void {
    this._inputSync.pipe(takeWhile(() => !this.isParamsPassed)).subscribe((res: FileUploadDownloadInputVo) => {
      this.currentTaskId = res.taskId;
      if (!!res.remotePath) {
        this.alreadyUploaded = {
          filenameWithExtension: res.remotePath,
        };
      }

      if (!Utils.isNullOrUndefined(res.selectedType)) {
        const isTypeExist = Constants.UPLOAD_FILE_EXTENSIONS.some(function (obj): boolean {
          return obj.type === res.selectedType;
        });
        if (isTypeExist) {
          this.selectedFileExtension = res.selectedType;
        }
      }
      if (!Utils.isNullOrUndefined(res.remotePath)) {
        this.selectedFileName = res.remotePath;
      }
      if (!Utils.isNullOrUndefined(res.fileId)) {
        this.fileId = res.fileId;
      }
      this.disableFileTypeSelector = res.isUpdate;
      this.isParamsPassed = true;
    });
  }

  setFileToUpload(files: File[]): void {
    if (typeof files !== 'undefined' && files != null && files.length != null && files.length > 0) {
      this.isFileSelected = true;
      this.selectedFile = files[0];
      this.selectedFileName = this.selectedFile.name;
      this.selectedFileExtension = Constants.EXTENSION_AUTO;
      this.selectedFileConvertedSize = this.bytesToSize(this.selectedFile.size);
      // this.files = [];
    }
  }

  bytesToSize(bytes): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024,
      dm = 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFiles(file: File): void {
    this.store
      .select(hasRolesSelector, RolesEnum.ADMIN)
      .pipe(take(1))
      .subscribe((isAdmin) => {
        if (!isAdmin && file.size / (1000 * 1000) > FileUploadComponent.FILE_LIMIT) {
          this.notService.warning(this.translateService.instant('TASKWIZARD.ADDFILES.FILE_UPLOAD.LIMIT_REACHED'));
          return;
        }
        this.disableUploadBtn = true;
        this.isUploadInProgress = true;
        const formDatas = new FormData();
        formDatas.append('taskId', this.currentTaskId + '');
        formDatas.append('fileToUpload', file, file.name);
        if (this.selectedFileExtension !== 'auto') {
          formDatas.append('extension', this.selectedFileExtension);
        }
        if (!Utils.isNullOrUndefined(this.fileId)) {
          formDatas.append('fileId', this.fileId.toString());
        }

        const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
        this.downloadFileActive.emit(true);
        this.restService.post(this.postUrl, formDatas, requestOptions).subscribe(
          (res) => {
            console.log(res);
            const resData = res.getFirstData();
            if (!Utils.isNullOrUndefined(resData.error)) {
              this.updateState(resData);
              this.notService.error(this.translateService.instant('TASKWIZARD.ADDFILES.URL_UPLOAD.ERROR_MESSAGE'));
              this.downloadFileActive.emit(false);
              return;
            }
            const response = new FileUploadResponseVo();
            response.status = true;
            response.taskId = resData.pmsFile.taskId;
            response.fileId = resData.pmsFile.fileId;
            response.remotePath = resData.pmsFile.remotePath;
            response.detectedType = resData.detectedType;
            this.updateState(resData);
            this.isUploadInProgress = false;
            this.disableFileTypeSelector = false;
            this.disableUploadBtn = false;
            this.downloadFileActive.emit(false);
            this.callBack.emit(response);
          },
          (error) => {
            console.log(error);
            this.isUploadInProgress = false;
            this.disableUploadBtn = false;
            this.downloadFileActive.emit(false);
          }
        );
      });
  }

  updateState(respData): void {
    this.fileId = respData.pmsFile.fileId;
    if (!Utils.isNullOrUndefined(respData.detectedType)) {
      this.selectedFileExtension = respData.detectedType;
    }
  }

  selectFileTypeChanged(event): void {
    this.selectedTypeCallBack.emit(event);
  }

  private getLtMd(): Observable<boolean> {
    return this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator());
  }
}
