import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-selector-image',
  templateUrl: './image-selector-image.component.html',
  styleUrls: ['./image-selector-image.component.scss'],
})
export class ImageSelectorImageComponent implements OnInit, OnChanges {
  @Input() source: Blob;
  @Input() selected: boolean;

  @Output() setSelected: EventEmitter<Blob> = new EventEmitter<Blob>();

  blobUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.getBlobUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.getBlobUrl();
    }
  }

  getBlobUrl(): void {
    if (this.source) {
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.source));
    }
  }
}
