import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReplacerRef } from './replacer-ref';
import { Replacer } from './replacer';
import { ReplacerSettingsSupplier } from './replacer-settings-supplier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-replacer',
  templateUrl: './replacer.component.html',
  styleUrls: ['./replacer.component.scss'],
})
export class ReplacerComponent implements OnInit, ReplacerSettingsSupplier {
  @Input() ref: ReplacerRef;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();

  public replacerForm: UntypedFormGroup;
  public comparators: any[] = [
    { code: '==', name: this.translate.instant('TASKWIZARD.REPLACER.IS_EQUALS_TO') },
    { code: '!=', name: this.translate.instant('TASKWIZARD.REPLACER.IS_NOT_EQUALS_TO') },
    { code: '<', name: this.translate.instant('TASKWIZARD.REPLACER.LESS_THAN') },
    { code: '>', name: this.translate.instant('TASKWIZARD.REPLACER.GREATER_THAN') },
    { code: '^', name: this.translate.instant('TASKWIZARD.REPLACER.START_WITH') },
    { code: '$', name: this.translate.instant('TASKWIZARD.REPLACER.END_WITH') },
    { code: 'RE', name: 'RegEx' },
  ];

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService) {
    this.replacerForm = this.fb.group({
      comparator: ['=='],
      value: [''],
      replace: [''],
    });
  }

  ngOnInit(): void {
    this.ref.setSupplier(this);
    if (this.ref.initialSettings) {
      this.replacerForm.setValue(this.ref.initialSettings);
    }
  }

  public getReplacerSettings(): Replacer {
    return this.replacerForm.value;
  }

  public removeSelf(): void {
    this.remove.emit();
  }
}
