import { TaskFile } from '../../../../service/taskwizard/taskwizard-update-response';

export class TabFileManagerInputVo {
  private _conType: string;
  private _taskId: number;
  private _tabIndex: number;
  private _taskFile: TaskFile;
  private _isDownloading: boolean;

  get conType(): string {
    return this._conType;
  }

  set conType(value: string) {
    this._conType = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get tabIndex(): number {
    return this._tabIndex;
  }

  set tabIndex(value: number) {
    this._tabIndex = value;
  }

  get taskFile(): TaskFile {
    return this._taskFile;
  }

  set taskFile(value: TaskFile) {
    this._taskFile = value;
  }

  get isDownloading(): boolean {
    return this._isDownloading;
  }

  set isDownloading(value: boolean) {
    this._isDownloading = value;
  }
}
