import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImageWithAlt } from '../../../vo/image-with-alt';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BreakPoint } from '../../../service/screen-manager/screen-manager.service';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';
import { fuseAnimations } from '../../../../@fuse/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';

@UntilDestroy()
@Component({
  selector: 'app-reviews-slider',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatIconModule, TranslateModule, SlickCarouselModule, SectionTitleComponent],
  templateUrl: './reviews-slider.component.html',
  styleUrls: ['./reviews-slider.component.scss'],
  animations: [fuseAnimations],
})
export class ReviewsSliderComponent implements OnInit {
  @Input() titleKey: string;
  @Input() titlei18nPayload: { [key: string]: any };
  @Input() reviewCardsContent: ReviewCardsContent;
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  translations: { [key: string]: { REVIEW: string } };
  config: JQuerySlickOptions = {
    arrows: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    swipe: false,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    lazyLoad: 'progressive',
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService
      .stream(this.reviewCardsContent.translationPrefix)
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((t) => (this.translations = t));
  }
}

export interface ReviewCardsContent {
  translationPrefix: string;
  reviews: ReviewCardsContentReview[];
}

export interface ReviewCardsContentReview {
  key: string;
  stars: number;
  reviewerName: string;
  reviewerLogo: ImageWithAlt;
}
