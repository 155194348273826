<div>
  <mat-form-field
    class="w-100p input-without-padding input-without-padding-except-error"
    [class.select-without-label]="!label"
    [class.mat-form-field-invalid]="!!hasError"
    appearance="fill"
  >
    <mat-label *ngIf="!!label">{{ label }}</mat-label>
    <mat-select
      #countrySelector
      [placeholder]="!!placeholder ? (required ? placeholder + ' *' : placeholder) : ''"
      [value]="countryCode"
      [class.custom-input-error]="!!hasError"
      (selectionChange)="handleSelectionChange($event)"
      [required]="required"
    >
      <mat-option>
        <app-custom-mat-select-search
          [parentSelect]="countrySelector"
          [numberOfResults]="filteredCountries.length"
          (searchTermChange)="initSearch($event)"
        ></app-custom-mat-select-search>
      </mat-option>
      <ng-container *ngFor="let country of filteredCountries">
        <mat-option [value]="country.code"> {{ 'COUNTRIES.' + country.code | translate }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-error *ngIf="!!error" class="lint">{{ error }}</mat-error>
</div>
