import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'translateConditionally',
  standalone: true,
  pure: false,
})
export class TranslateConditionallyPipe implements PipeTransform {
  value?: string;

  constructor(private translateService: TranslateService, private changeDetectorRef: ChangeDetectorRef) {}

  transform(value: string, condition: boolean): string {
    if (condition && !this.value) {
      this.translateService
        .stream(value)
        .pipe(omitNullOrUndefined(), untilDestroyed(this))
        .subscribe((translatedValue) => {
          this.value = translatedValue;
          this.changeDetectorRef.markForCheck();
        });
    }
    return condition ? this.value : value;
  }
}
