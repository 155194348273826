<div class="ftp-holder mb-12">
  <mat-expansion-panel [expanded]="isExpanded" (afterExpand)="expandCallback()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.FTP' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #ftpConnectionForm="ngForm" novalidate class="simple-form">
      <div fxLayout="column" fxLayout.gt-lg="row wrap" fxLayoutAlign.gt-lg="start center">
        <!--Host-->
        <mat-form-field appearance="fill">
          <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.HOST_PLACE' | translate }}</mat-label>
          <input
            id="ftpHost"
            name="host"
            matInput
            placeholder="{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.HOST_PLACE' | translate }}"
            #ftpHost="ngModel"
            [disabled]="disableNotCredentialsInput"
            [(ngModel)]="ftpCredentials.host"
            required
            class="user-input"
          />
          <mat-error>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.HOST' | translate }}</mat-error>
        </mat-form-field>
        <!--Port-->
        <mat-form-field appearance="fill">
          <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PORT_PLACE' | translate }}</mat-label>
          <input
            id="ftpPort"
            name="port"
            matInput
            placeholder="{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PORT_PLACE' | translate }}"
            #ftpPort="ngModel"
            [disabled]="disableNotCredentialsInput"
            [(ngModel)]="ftpCredentials.port"
            required
            class="user-input"
          />
          <mat-error>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PORT' | translate }}</mat-error>
        </mat-form-field>
        <!--UserName Input-->
        <mat-form-field appearance="fill">
          <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.NAME_PLACE' | translate }}</mat-label>
          <input
            id="userName"
            name="name"
            matInput
            placeholder="{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.NAME_PLACE' | translate }}"
            #username="ngModel"
            [(ngModel)]="ftpCredentials.username"
            required
            class="user-input"
          />
          <mat-error>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.NAME' | translate }}</mat-error>
        </mat-form-field>
        <!--Password Input-->
        <mat-form-field appearance="fill">
          <mat-label>{{
            'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PASSWORD_PLACE' | translate
          }}</mat-label>
          <input
            id="userPassword"
            name="password"
            matInput
            type="password"
            autocomplete="new-password"
            placeholder="{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PASSWORD_PLACE' | translate }}"
            [(ngModel)]="ftpCredentials.password"
            required
          />
          <mat-error>
            {{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PASSWORD' | translate }}
          </mat-error>
        </mat-form-field>
        <!--Root-->
        <mat-form-field appearance="fill">
          <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.ROOT_PLACE' | translate }}</mat-label>
          <input
            id="ftpRoot"
            name="root"
            matInput
            placeholder="{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.ROOT_PLACE' | translate }}"
            #ftpRoot="ngModel"
            [disabled]="disableNotCredentialsInput"
            [(ngModel)]="ftpCredentials.root"
            class="user-input"
          />
          <mat-error>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.ROOT' | translate }}</mat-error>
        </mat-form-field>
        <div class="action-panel">
          <mat-checkbox
            id="ftpPassive"
            name="passive"
            matCheckbox
            #passive="ngModel"
            [(ngModel)]="ftpCredentials.passive"
            class="user-input"
          >
            {{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.PASSIVE' | translate }}
          </mat-checkbox>

          <mat-checkbox
            id="ftpSsl"
            name="ssl"
            matCheckbox
            [checked]="ftpCredentials.isSsl"
            #ssl="ngModel"
            [(ngModel)]="ftpCredentials.isSsl"
            style="margin-left: 5px"
            class="user-input"
          >
            SSL
          </mat-checkbox>

          <button
            style="margin-left: 10px"
            mat-button
            color="accent"
            (click)="connectFtp()"
            [disabled]="isConnectButtonPressed"
          >
            {{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.CONNECT' | translate }}
          </button>
        </div>
      </div>
    </form>

    <div [hidden]="hideFtpManager">
      <p>Ftp Manager</p>
      <p>
        <b
          >{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.DEST_PATH' | translate }}
          {{ ftpCredentials.root }}</b
        >
      </p>
      <div class="mat-card mat-elevation-z4 p-24">
        <app-breadcrumb-navigation
          [navItems]="breadCrumb"
          (navItemChange)="breadCrumbCalback($event)"
        ></app-breadcrumb-navigation>
        <hr />
        <mat-grid-list cols="2" rowHeight="50px" class="ftp-file-list">
          <ng-container *ngFor="let file of fileList">
            <mat-grid-tile
              class="text-inside-grid"
              fxLayoutAlign="start center"
              (click)="rowSingleClick(file)"
              [ngClass]="{ selected: file == selected }"
              matRipple
              [@animate]="{ value: '*', params: { y: '100%' } }"
              *ngIf="file.isDirectory"
            >
              <div class="ftp-align-left">
                <mat-icon mat-list-icon>{{ file.isDirectory ? 'folder' : 'insert_drive_file' }}</mat-icon>
                {{ file.name }}
              </div>
            </mat-grid-tile>

            <mat-grid-tile
              class="text-inside-grid"
              fxLayoutAlign="start center"
              class="disabled"
              matRipple
              [@animate]="{ value: '*', params: { y: '100%' } }"
              *ngIf="!file.isDirectory"
            >
              <div class="ftp-align-left">
                <mat-icon mat-list-icon>{{ file.isDirectory ? 'folder' : 'insert_drive_file' }}</mat-icon>
                {{ file.name }}
              </div>
            </mat-grid-tile>
          </ng-container>
        </mat-grid-list>
      </div>
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px" class="modal-action-panel">
        <div>
          <!--<button class="mat-button" style="margin-right: 5px;" (click)="close()">Close</button>-->
          <button class="mat-button mat-accent" [disabled]="disableDownloadButton" (click)="saveSelectedPath()">
            {{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.RETAILER_EXPORT.USE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
