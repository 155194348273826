<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="explore-products-search">
  <app-category-sidebar
    *ngIf="!(isMdView$ | async)"
    class="category-sidebar"
    [style.top.px]="(layoutService.stickyHeaderVisible | async) ? (layoutService.toolbarHeight | async) : 0"
    [style.transition]="
      !(layoutService.stickyHeaderVisible | async) ? '400ms cubic-bezier(0.1, 0.7, 0.6, 1)' : '400ms linear'
    "
    [sendGtmFilter]="true"
    fxFlex="0 0 208px"
  ></app-category-sidebar>
  <div id="container" class="contents-container" fxFlex="1 0 0" #productsStructure>
    <div fxLayout="column" fxLayoutGap="18px">
      <div fxLayout="column" fxLayoutGap="20px">
        <div class="font-weight-700 h3 pt-8">
          <ng-container *ngIf="title | async as category">
            {{ category }}
          </ng-container>
        </div>
        <div fxLayoutAlign="space-between center">
          <app-marketplace-view-selector
            (marketplaceViewChange)="handleSearchTypeChange($event)"
            [marketplaceView]="searchType | async"
          ></app-marketplace-view-selector>
          <div fxFlex="0 0 auto" class="product-number">
            <div>
              <b>{{ resultNumber | number }}</b> {{ 'PRODUCT_LIST_UPPER_BAR.SEARCH_RESULT' | translate }}
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div
        class="sticky-row"
        #filterRow
        [style.top.px]="(layoutService.stickyHeaderVisible | async) ? (layoutService.toolbarHeight | async) : -1"
        [style.transition]="
          !(layoutService.stickyHeaderVisible | async) ? '400ms cubic-bezier(0.1, 0.7, 0.6, 1)' : '400ms linear'
        "
      >
        <app-filter-selector-row
          [sendFilterToGtm]="true"
          [hiddenFilters]="hiddenFilters"
          [isSupplierSearch]="(searchType | async) === 'SUPPLIERS'"
        ></app-filter-selector-row>
      </div>
      <div class="content-container" *ngIf="!loading">
        <app-product-list
          *ngIf="(searchType | async) === 'PRODUCTS'"
          class="w-100p"
          [selectedEcom]="selectedEcom"
          [ecomCurrency]="ecomCurrency"
          (productNumberChanged)="handleResultNumberChanged($event)"
        ></app-product-list>
        <app-supplier-list
          *ngIf="(searchType | async) === 'SUPPLIERS'"
          [selectedEcom]="selectedEcom"
          (allItemsCountChange)="handleResultNumberChanged($event)"
        ></app-supplier-list>
      </div>
    </div>
  </div>
</div>
<div class="pt-24" *onlyLoggedIn>
  <app-simple-banner-with-image
    *ngIf="!!affiliateCta"
    [image]="affiliateCta.image"
    [imageLtSm]="affiliateCta.imageLtSm"
    [imageLtMd]="affiliateCta.imageLtMd"
    [button]="affiliateCta.button"
    [containerClasses]="affiliateCta.containerClasses"
    [translateText]="affiliateCta.translateText"
    [text]="affiliateCta.text"
  ></app-simple-banner-with-image>
</div>
