<div id="fileUploadCard" fxLayout="column" fxLayoutGap="20px">
  <div class="h5">
    {{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.FILE_UPLOAD' | translate }}
  </div>
  <app-drag-and-drop-file
    [allowedMIMETypes]="allowedMIMETypes"
    [allowedExtensions]="allowedExtensions"
    [(files)]="files"
    (filesChange)="setFileToUpload(files)"
    [alreadyUploadedFiles]="!!alreadyUploaded ? [alreadyUploaded] : null"
    [title]="(ltMd$ | async) ? 'Select a file' : undefined"
    [mobileButtonText]="'Select'"
  ></app-drag-and-drop-file>
  <!--  <div class="dragzone-holder">-->
  <!--    <div class="dragzone-overlay" *ngIf="isUploadInProgress"></div>-->
  <!--    <div-->
  <!--      ngfDrop-->
  <!--      multiple="1"-->
  <!--      selectable="1"-->
  <!--      [(files)]="files"-->
  <!--      accept=".json,application/json,.xml,application/xml,.csv,.xls,.xlsx"-->
  <!--      [(validDrag)]="validComboDrag"-->
  <!--      class="well dragzone"-->
  <!--      [class.invalid-drag]="validComboDrag === false"-->
  <!--      [class.valid-drag]="validComboDrag"-->
  <!--      (filesChange)="setFileToUpload(files)"-->
  <!--    >-->
  <!--      <div class="upload-holder">-->
  <!--        <div>-->
  <!--          <div class="upload-image"></div>-->
  <!--          <p>{{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.DROP_FILES' | translate }}</p>-->
  <!--          <button mat-button color="accent">-->
  <!--            {{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.SELECT_FILE' | translate }}-->
  <!--          </button>-->
  <!--        </div>-->
  <!--        <div class="file-content" *ngIf="selectedFileName">-->
  <!--          {{ selectedFileName }}-->
  <!--          <span class="file-size" *ngIf="selectedFileConvertedSize">{{ selectedFileConvertedSize }}</span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div id="uploaded-files" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-form-field appearance="fill" class="input-without-padding">
      <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.FILE_TYPE_PLACEHOLDER' | translate }}</mat-label>
      <mat-select
        [disabled]="disableFileTypeSelector"
        [(ngModel)]="selectedFileExtension"
        name="type"
        (valueChange)="selectFileTypeChanged($event)"
      >
        <mat-option *ngFor="let fileExt of fileExtensions" [value]="fileExt.type">
          {{ fileExt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      [disabled]="!isFileSelected || disableUploadBtn"
      class="upload-button"
      mat-flat-button
      color="primary"
      (click)="uploadFiles(selectedFile)"
    >
      {{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.UPLOAD' | translate }}
    </button>
  </div>
</div>
