<ng-container *ngIf="!error; else errorComponent">
  <ng-container *ngIf="!!product; else loadingSpinner">
    <div class="product-details-page-container" itemscope itemtype="https://schema.org/Product">
      <section class="product-section">
        <meta itemprop="name" [content]="product.TITLE" />
        <meta itemprop="description" [content]="metaTagsService.truncateDescription(product.DESCRIPTION)" />
        <meta itemprop="category" [content]="product.SYNCEE_CATEGORY?.[0] | categoryNameByLanguage | async" />
        <div class="item-display-none" itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
          <meta itemprop="sku" [content]="product.ID" />
          <meta itemprop="price" [content]="metaTagsService.calculateProductRRP(product)" />
          <meta itemprop="priceCurrency" content="USD" />
          <div class="item-display-none" itemprop="seller" itemscope itemtype="https://schema.org/Organization">
            <meta itemprop="name" [content]="product.BRAND" />
          </div>
        </div>
        <div class="item-display-none" itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
          <meta itemprop="name" [content]="product.BRAND" />
          <meta itemprop="url" [content]="product.USER_ID | createSupplierFullUrl : product.SUPPLIER?.handle" />
          <meta itemprop="logo" [content]="product.USER_ID | supplierLogoFromUserid" />
        </div>
        <div class="images-and-variant-selector-wrapper">
          <div fxLayout="column" fxLayoutGap="16px" class="image-viewer-wrapper">
            <meta itemprop="image" [content]="(product | productImageList)[0]" />
            <app-product-page-image-viewer
              #imageViewerComponent
              [productImages]="product | productImageList"
              [productImageAlt]="product.TITLE"
              [loading]="loading"
            ></app-product-page-image-viewer>
            <ng-container *ngIf="!isMobileSize" [ngTemplateOutlet]="detailsAccordion"></ng-container>
          </div>
          <div class="variant-selector-wrapper" *ngIf="product; else productVariantSelectorSkeleton">
            <div fxLayout="column" fxLayoutGap="16px">
              <app-supplier-rating-display
                [supplierUserId]="product.USER_ID"
                [supplier]="product.SUPPLIER"
                [isAutomated]="orderInfoData.isAutomated"
              ></app-supplier-rating-display>
              <div class="w-100p" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
                <h1 class="m-0">{{ product.TITLE }}</h1>
                <app-favourite-heart [productId]="product.ID" class="pt-4"></app-favourite-heart>
              </div>
              <app-marketplace-badges [badges]="product.BADGES"></app-marketplace-badges>
            </div>
            <div fxLayout="column" fxLayoutGap="30px">
              <app-product-page-price-display
                *onlyLoggedIn
                [ecomCurrency]="ecomCurrency"
                [supplierCurrency]="product.SUPPLIER.paymentCurrency"
                [quantity]="quantity"
                [possibleMargin]="toNumber(product.SETTINGS.possibleMargin)"
                [selectedVariant]="selectedVariant"
              ></app-product-page-price-display>
              <app-product-page-price-display-guest *onlyGuest></app-product-page-price-display-guest>
              <ng-container *onlyLoggedIn>
                <app-product-page-variant-selector
                  *ngIf="optionNameFields.length"
                  [optionNameFields]="optionNameFields"
                  [productVariants]="product.VARIANTS"
                  (variantChange)="handleVariantChange($event)"
                ></app-product-page-variant-selector>
              </ng-container>
              <ng-container *onlyLoggedIn>
                <div *ngIf="getIsRetailer() | async" fxLayout="column" fxLayoutGap="16px">
                  <app-product-page-add-to-import-list
                    *onlyLoggedIn
                    [productId]="product.ID"
                    [approveNeeded]="product?.SETTINGS?.approveNeeded"
                    [catalogIsConnected]="catalogIsConnected"
                    (addToClick)="addToImportList()"
                  ></app-product-page-add-to-import-list>

                  <div
                    fxLayout="row"
                    fxLayoutAlign="unset center"
                    fxLayoutGap="20px"
                    class="add-to-cart"
                    *ngIf="orderInfoData.isAutomated && hasCartService.hasCart && !!selectedVariant?.VARIANT_ID"
                  >
                    <app-quantity-selector
                      [quantity]="quantity"
                      [maxQuantity]="selectedVariant.QTY"
                      (quantityChange)="handleQuantityChange($event)"
                    ></app-quantity-selector>
                    <button
                      dialogType="register"
                      roleType="retailer"
                      fxFlex="1 1 100%"
                      class="syncee-white-button font-weight-500"
                      mat-button
                      (click)="addToCart()"
                      [disabled]="selectedVariant.QTY < 1 || quantity < 1"
                    >
                      {{ 'PRODUCT_PAGE.ADD_TO_CART' | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
            <div fxLayout="column" fxLayoutGap="8px">
              <div fxLayout="column" fxLayoutGap="8px">
                <h4>{{ 'PRODUCT_PAGE.SHIPPING' | translate }}</h4>
                <ul>
                  <li>{{ 'PRODUCT_PAGE.SHIPS_FROM' | translate }} {{ product.SETTINGS.warehouseLocation }}</li>
                </ul>
                <div guestOpenRegistration (click)="openShippingDialog()" class="see-shipping-details">
                  {{ 'PRODUCT_PAGE.SEE_SHIPPING_DETAILS' | translate }}
                </div>
              </div>
              <app-return-and-refund-policy [supplierId]="product.USER_ID"></app-return-and-refund-policy>
            </div>
            <ng-container *ngIf="isMobileSize" [ngTemplateOutlet]="detailsAccordion"></ng-container>
          </div>
        </div>
      </section>
      <section>
        <app-more-from-supplier
          [supplierSeoUrl]="supplierSeoUrl"
          [ecomCurrency]="ecomCurrency"
          [selectedEcom]="selectedEcom"
          [supplier]="supplier"
        ></app-more-from-supplier>
      </section>
      <section *ngIf="productCategory">
        <app-similar-products
          [selectedEcom]="selectedEcom"
          [category]="productCategory"
          [generateMicrodata]="false"
          [loading]="loading"
        ></app-similar-products>
      </section>
      <app-recently-viewed-products [generateMicrodata]="false" *onlyLoggedIn></app-recently-viewed-products>
    </div>
  </ng-container>
</ng-container>

<ng-template #detailsAccordion>
  <div class="accordion-wrapper">
    <div class="accordion">
      <app-product-page-details-accordion
        [supplierUserId]="product.USER_ID"
        [catalogId]="product.TASK_ID"
        [description]="product.DESCRIPTION"
        [productDetails]="accordionData"
        [orderInfoData]="orderInfoData"
      ></app-product-page-details-accordion>
    </div>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div fxLayoutAlign="center center" class="py-80">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>

<ng-template #errorComponent>
  <app-error-message></app-error-message>
</ng-template>

<ng-template #ctaList>
  <div fxLayout="column" fxLayoutGap="8px">
    <ng-container *ngFor="let item of ctaItems">
      <div fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-icon>done</mat-icon>
        <div class="typography-body-3">{{ item.key | translate : item.values }}</div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #productVariantSelectorSkeleton>
  <div>
    <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="60px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <app-skeleton-image height="40px" width="40px" borderRadius="50%"></app-skeleton-image>
        <app-skeleton-text [lines]="1" style="width: min(240px, 40%)"></app-skeleton-text>
      </div>

      <div fxLayout="column" fxLayoutGap="12px" style="margin-bottom: 16px">
        <app-skeleton-display-text maxWidth="100%"></app-skeleton-display-text>
        <app-skeleton-display-text maxWidth="45%"></app-skeleton-display-text>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <app-skeleton-display-text></app-skeleton-display-text>
        <app-skeleton-display-text></app-skeleton-display-text>
      </div>

      <app-skeleton-display-text maxWidth="100%"></app-skeleton-display-text>

      <app-skeleton-display-text maxWidth="100%"></app-skeleton-display-text>

      <div>
        <app-skeleton-text style="width: min(120px, 10%)"></app-skeleton-text>
      </div>
    </div>
  </div>
</ng-template>
