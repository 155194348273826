<div class="container">
  <img
    class="banner-image"
    [src]="screenService.small ? config.payload['banner_image_small'] : config.payload['banner_image_large']"
  />

  <div class="content-container" [ngStyle]="{ gap: screenService.small ? '28px' : '32px' }">
    <div class="text-container" [ngStyle]="{ gap: screenService.small ? '4.65px' : '12px' }">
      <div class="h3 syncee-grey-800-fg text-center">{{ config.payload['title'] | translate }}</div>

      <div [ngClass]="descriptionStyle">
        {{ config.payload['description'] | translate }}
      </div>
    </div>

    <div class="actions-container">
      <button mat-button class="syncee-blue-button fit-content" (click)="stepperService.forward()">
        {{ config.payload['next'] | translate }}
      </button>

      <button [mat-dialog-close]="null" mat-flat-button>{{ config.payload['cancel'] | translate }}</button>
    </div>
  </div>
</div>
