<div class="search-field-wrapper">
  <mat-form-field fxFlex="1 0 0" class="form-field select-without-label" appearance="fill">
    <input
      #searchInput
      autocomplete="off"
      matInput
      [placeholder]="'Search'"
      name="searchterm"
      [(ngModel)]="searchTerm"
      (ngModelChange)="handleSearch()"
      (focus)="this.handleFocus()"
      (blur)="this.handleBlur()"
      [ngModelOptions]="{ standalone: true }"
    />
    <span matPrefix><mat-icon class="s-24">search</mat-icon></span>
    <span matSuffix
      ><mat-icon *ngIf="!!searchInput.value.length" class="clear-icon" (click)="handleClear()">close</mat-icon>
      <mat-icon *ngIf="!searchInput.value.length"></mat-icon
    ></span>
  </mat-form-field>
</div>
