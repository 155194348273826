import { NgModule } from '@angular/core';
import { FilterComponent } from './filter.component';
import { FiltersComponent } from './filters/filters.component';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import { ShipsFromFilterComponent } from './filter-items/filter-item/ships-from-filter/ships-from-filter.component';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { ShipsToFilterComponent } from './filter-items/filter-item/ships-to-filter/ships-to-filter.component';
import { SupplierFilterComponent } from './filter-items/filter-item/supplier-filter/supplier-filter.component';
import { FilterGroupComponent } from './filter-items/filter-group/filter-group.component';
import { ProductLanguageFilterComponent } from './filter-items/filter-item/product-language-filter/product-language-filter.component';
import { SupplierTypeFilterComponent } from './filter-items/filter-item/supplier-type-filter/supplier-type-filter.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterSelectorComponent } from './filter-selector/filter-selector.component';
import { FilterActionBarComponent } from './filter-action-bar/filter-action-bar.component';
import { ShippingTypeFilterComponent } from './filter-items/filter-item/shipping-type-filter/shipping-type-filter.component';
import { StockStatusFilterComponent } from './filter-items/filter-item/stock-status-filter/stock-status-filter.component';
import { AutoOrderFilterComponent } from './filter-items/filter-item/auto-order-filter/auto-order-filter.component';
import { PriceFilterComponent } from './filter-items/filter-item/price-filter/price-filter.component';
import { CategoriesFilterComponent } from './filter-items/filter-item/categories-filter/categories-filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { CountryFlagPipe } from '../../../shared/pipes/country_flag.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RecreateViewDirective } from 'app/shared/directives/RecreateView.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilterChipsComponent } from 'app/shared/components/filter-chips/filter-chips.component';
import { CountryNameToCodePipe } from 'app/shared/pipes/legacy/country-name-to-code.pipe';

@NgModule({
  declarations: [
    FilterComponent,
    FiltersComponent,
    FilterHeaderComponent,
    FilterGroupComponent,
    ShipsFromFilterComponent,
    ShipsToFilterComponent,
    SupplierFilterComponent,
    ProductLanguageFilterComponent,
    SupplierTypeFilterComponent,
    FilterSelectorComponent,
    FilterActionBarComponent,
    ShippingTypeFilterComponent,
    StockStatusFilterComponent,
    AutoOrderFilterComponent,
    PriceFilterComponent,
    CategoriesFilterComponent,
  ],
  imports: [
    MatIconModule,
    FuseSharedModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
    MatRadioModule,
    TranslateModule,
    RecreateViewDirective,
    MatFormFieldModule,
    MatCheckboxModule,
    FilterChipsComponent,
  ],
  exports: [FilterComponent, PriceFilterComponent, FilterGroupComponent],
  providers: [CountryFlagPipe, CountryNameToCodePipe],
})
export class FilterModule {}
