import { SettingsInputVo } from './settings-input-vo';

export class CsvSettingsInputVo extends SettingsInputVo {
  private _firstRowIsHeader: boolean;
  private _startRowNum: number;
  private _delimiter: string;
  private _enclosure: string;
  private _endofline: string;
  private _encoding: string;

  get firstRowIsHeader(): boolean {
    return this._firstRowIsHeader;
  }

  set firstRowIsHeader(value: boolean) {
    this._firstRowIsHeader = value;
  }

  get startRowNum(): number {
    return this._startRowNum;
  }

  set startRowNum(value: number) {
    this._startRowNum = value;
  }

  get delimiter(): string {
    return this._delimiter;
  }

  set delimiter(value: string) {
    this._delimiter = value;
  }

  get enclosure(): string {
    return this._enclosure;
  }

  set enclosure(value: string) {
    this._enclosure = value;
  }

  get endofline(): string {
    return this._endofline;
  }

  set endofline(value: string) {
    this._endofline = value;
  }

  get encoding(): string {
    return this._encoding;
  }

  set encoding(value: string) {
    this._encoding = value;
  }
}
