import { Component, Inject, OnInit } from '@angular/core';
import { MySupplierCatalog } from '../../../../../vo/my-suppliers/my-supplier-vo';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MySuppliersPageService } from '../../service/my-suppliers-page.service';

@Component({
  selector: 'app-my-suppliers-mobile-catalog-details',
  templateUrl: './my-suppliers-mobile-catalog-details.component.html',
  styleUrls: ['./my-suppliers-mobile-catalog-details.component.scss'],
})
export class MySuppliersMobileCatalogDetailsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<MySuppliersMobileCatalogDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MySuppliersMobileCatalogData,

    private mySuppliersPageService: MySuppliersPageService
  ) {}

  ngOnInit(): void {
    this.normalizeWebAddress();
  }

  public toggleChanged(event: MatSlideToggleChange): void {
    // this.mySuppliersPageService.updatedShippingType(this.data.catalog, event.checked);
  }

  public closeAndBackToMySuppliers(): void {
    this.dialog.closeAll();
    // this.mySuppliersPageService.dataReloadSubject.next();
  }

  private normalizeWebAddress(): void {
    if (this.data.website && !this.data.website.match(/^https?:\/\//i)) {
      this.data.website = 'http://' + this.data.website;
    }
  }
}

export interface MySuppliersMobileCatalogData {
  catalog: MySupplierCatalog;

  supplierName: string;
  hasAccessToContacts: boolean;

  isAutoOrder: boolean;

  website: string;
}
