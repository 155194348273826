<div class="w-100p h-100-p" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
  <app-sub-steps-stepper
    #stepperComponent
    [steps]="steps"
    [defaultStep]="currentStepIndex"
    (stepChange)="handleSubStepChange($event)"
  ></app-sub-steps-stepper>
  <div
    fxFlex="1 0 0"
    class="w-100p personalization-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
  >
    <ng-container *conditionLoading="!isLoading">
      <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
        <app-step-title-description
          [title]="stepTitles[currentStepIndex].title | translate"
          [description]="stepTitles[currentStepIndex].description | translate"
        ></app-step-title-description>
        <ng-container [ngSwitch]="currentStepIndex">
          <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
            <div
              *ngSwitchCase="0"
              class="w-100p"
              [style.max-width]="contentMaxWidth"
              fxLayout="column"
              fxLayoutGap="20px"
            >
              <app-storefront-upload-images #storefrontUploadImages [hasDivider]="false"></app-storefront-upload-images>
              <app-alert-multi
                *ngIf="uploadedImagesMissing"
                [type]="'danger'"
                [messages]="['Please upload all the images']"
              >
              </app-alert-multi>
            </div>
            <div *ngSwitchCase="1" class="w-100p" [style.max-width]="contentMaxWidth">
              <app-supplier-public-information
                [hasSaveButton]="false"
                #supplierDetails
              ></app-supplier-public-information>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <app-step-navigation-buttons (prevClick)="prevClicked()" (nextClick)="nextClicked()"></app-step-navigation-buttons>
  </div>
</div>
