import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { includes } from 'lodash';
import { SCOPES } from './scopes';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { getIsAdminScopeSelector, getScopesSelector } from 'app/store/authentication/authentication.selector';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private permissions: SCOPES[] = [];

  constructor(private store: Store<AppState>) {}

  setPermissions(...permissions: SCOPES[]): void {
    this.permissions = permissions;
  }

  getPermissionsObs(mergeScopes: boolean = false): Observable<SCOPES[]> {
    return this.store.select(getScopesSelector(mergeScopes));
  }

  getHasAdminScope(): Observable<boolean> {
    return this.store.select(getIsAdminScopeSelector());
  }

  getLocalPermissions(): SCOPES[] {
    return this.permissions;
  }

  hasPermissions(...args: SCOPES[]): Observable<boolean> {
    return this.getPermissionsObs(true).pipe(map((permissions) => args.every((arg) => includes(permissions, arg))));
  }
}
