<div class="list" fxLayoutAlign="center stretch" fxLayout="column" fxLayoutGap="24px">
  <ng-container *ngFor="let item of items; let last = last">
    <div
      class="item"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start start"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="12px"
    >
      <div
        class="key"
        [fxFlex]="'0 0 ' + (config.keyWidth ?? '280px')"
        fxFlex.lt-md="0 0 auto"
        [ngClass]="ltMd ? 'h5' : 'h6'"
      >
        {{ item.key | translateConditionally : config.translateKey | capitalise }}
      </div>
      <div class="value" fxFlex="1 0 0" [innerHTML]="item.value | translateConditionally : config.translateValue"></div>
    </div>
    <app-custom-divider *ngIf="!last"></app-custom-divider>
  </ng-container>
</div>
