<div class="submenu-tab">
  <h3 class="title">{{ group.title | translate }}</h3>
  <div class="submenu type-tab">
    <nav mat-tab-nav-bar [disablePagination]="ltMd | async">
      <span
        mat-tab-link
        *ngFor="let link of group.items; let i = index"
        [routerLink]="link.url"
        routerLinkActive
        #rla="routerLinkActive"
        class="submenu-item h5"
        [active]="rla.isActive"
      >
        {{ link.translate | translate }}
      </span>
    </nav>
  </div>
</div>
