import { Component, Input, OnInit } from '@angular/core';
import { FooterGroup } from '../footer.component';

@Component({
  selector: 'app-md-groups',
  templateUrl: './md-groups.component.html',
  styleUrls: ['./md-groups.component.scss'],
})
export class MdGroupsComponent implements OnInit {
  @Input()
  footerStructure: FooterGroup[];

  constructor() {}

  ngOnInit(): void {}
}
