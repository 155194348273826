import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../../layout/layout.service';

@Component({
  selector: 'app-ai-chat-layout-sidebar',
  templateUrl: './ai-chat-layout-sidebar.component.html',
  styleUrls: ['./ai-chat-layout-sidebar.component.scss'],
})
export class AiChatLayoutSidebarComponent implements OnInit {
  constructor(public layoutService: LayoutService) {}

  ngOnInit(): void {}
}
