import { Component, OnInit } from '@angular/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { RoleTypesEnum } from 'app/vo/roles/roles';

@Component({
  selector: 'app-retailer-login-content',
  templateUrl: './retailer-login-content.component.html',
  styleUrls: ['./retailer-login-content.component.scss'],
})
export class RetailerLoginContentComponent implements OnInit {
  retailerRole = RoleTypesEnum.RETAILER;

  constructor(private authManagerService: AuthenticationManagerService) {}

  ngOnInit(): void {}

  navigate(event): void {
    event.preventDefault();
    this.authManagerService.emitOpenLoginDialog(AuthDialogType.SUPPLIER);
  }

  navigateToSupplierRegister(event): void {
    event.preventDefault();
    this.authManagerService.emitOpenRegistrationDialog(AuthDialogType.SUPPLIER);
  }

  navigateToRetailerRegister(event): void {
    event.preventDefault();
    this.authManagerService.emitOpenRegistrationDialog(AuthDialogType.RETAILER);
  }
}
