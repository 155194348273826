<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'tooltip'">
    <div (mouseenter)="isOpen = true" (mouseleave)="isOpen = false" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPositions]="positions"
    >
      <mat-card class="custom-elevation-z1 legend" fxLayout="column" fxLayoutGap="12px" *ngIf="isOpen" @fadeInOut>
        <ng-container [ngTemplateOutlet]="legend"></ng-container>
      </mat-card>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'expendable'">
    <div fxLayout="column" fxLayoutAlign="center center" (click)="isOpen = !isOpen">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
      <button mat-icon-button><mat-icon>keyboard_arrow_down</mat-icon></button>
    </div>
    <div [@detailExpand]="isOpen ? 'expanded' : 'collapsed'" class="expandable">
      <div class="expandable-legend legend">
        <ng-container [ngTemplateOutlet]="legend"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #marker let-item="item">
  <ng-container [ngSwitch]="item.legendMarker">
    <ng-container *ngSwitchCase="'donut'">
      <div class="marker marker-donut" [style.border-color]="item.color"></div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="marker marker-default" [style.background-color]="item.color"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #legend>
  <ng-container *ngIf="filteredData?.length > 0; else noData">
    <ng-container *ngFor="let item of filteredData">
      <div fxLayoutAlign="space-between center" fxLayoutGap="12px">
        <div fxLayoutAlign="center center" fxLayoutGap="8px">
          <ng-container [ngTemplateOutlet]="marker" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          <div class="typography-body-2">{{ item.label }}</div>
        </div>
        <div class="typography-subtitle-small">{{ item.value | number : '.0-2' }}</div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noData
    ><div class="typography-body-2">
      {{ 'RETAILER_IMPORT_TASKS.HISTORY_INDICATOR.NO_DATA' | translate }}
    </div>
  </ng-template>
</ng-template>

<ng-template #content>
  <div class="history-indicator-container" fxLayoutAlign="center center">
    <div
      class="sub-bar"
      *ngFor="let item of filteredData"
      [fxFlex]="((item.value / allItemsCount) * 100).toString()"
      [style.background-color]="item.color"
    ></div>
  </div>
</ng-template>
