import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Utils } from 'app/utils/utils';
import { interval, Subject } from 'rxjs';
import { debounce, filter, take } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { ChangeRoleDialogComponent } from '../../main/change-role/change-role-dialog.component';
import type {
  ChangeRoleDialogData,
  ChangeRoleDialogDataStepTextRewrite,
} from '../../main/change-role/model/change-role-dialog-data';
import { ChangeRoleDialogResponse } from '../../main/change-role/model/change-role-dialog-response';
import {
  PlatformDialogComponent,
  PlatformDialogData,
} from '../../main/users/platform-list/platform-dialog/platform-dialog.component';
import { LogoutAction } from '../../store/authentication/authentication.actions';
import { UpdateUserStart } from '../../store/user/user.actions';
import { hasRoleSelector } from '../../store/user/user.selector';
import { getPlatformByType } from '../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';
import { NUM_ROLE_MAP_TO_STR, RolesEnum, RoleTypesEnum } from '../../vo/roles/roles';
import { RolesService } from '../user/roles.service';

@Injectable({
  providedIn: 'root',
})
export class RoleDialogService {
  private _openChangeRoleDialogSubject = new Subject<ChangeRoleDialogData>();

  constructor(
    private store: Store<AppState>,
    private dialogService: MatDialog,
    private router: Router,
    private rolesService: RolesService
  ) {
    this.createOpenChangeRoleDialogListener();
  }

  public handleCenterNavigationAttempt(role: RolesEnum, textRewrite?: ChangeRoleDialogDataStepTextRewrite[]): void {
    if (role === 3) {
      return;
    }

    this.store
      .select(hasRoleSelector(role))
      .pipe(
        omitNullOrUndefined(),
        take(1),
        filter((hasRole) => !hasRole)
      )
      .subscribe((): void => {
        this.emitChangeRoleDialog({ role, textRewrite: textRewrite || [] });
      });
  }

  private createOpenChangeRoleDialogListener(): void {
    this._openChangeRoleDialogSubject
      .asObservable()
      .pipe(debounce(() => interval(100)))
      .subscribe((changeRoleDialog) => {
        this.handleOpenChangeRoleDialog(changeRoleDialog)
          .afterClosed()
          .pipe(filter((response): boolean => !Utils.isNullOrUndefined(response)))
          .subscribe(({ ecomType, role, keepRole }: ChangeRoleDialogResponse): void => {
            /* open platform dialog */
            if (ecomType) {
              this.handleOpenPlatformDialog(ecomType, role);
            } else {
              /* update role based on the selected options and logout */
              this.updateRole(role, keepRole);
            }
          });
      });
  }

  private handleOpenChangeRoleDialog(payload: ChangeRoleDialogData): MatDialogRef<ChangeRoleDialogComponent> {
    this.dialogService.closeAll();
    return this.dialogService.open<ChangeRoleDialogComponent, ChangeRoleDialogData>(ChangeRoleDialogComponent, {
      panelClass: 'custom-modal-full-width-lt-md',
      autoFocus: false,
      maxWidth: '900px',
      data: payload,
    });
  }

  private emitChangeRoleDialog(payload: ChangeRoleDialogData): void {
    this._openChangeRoleDialogSubject.next(payload);
  }

  private patchUser(changeToRole: RolesEnum, keepRole: boolean): void {
    this.store.dispatch(
      new UpdateUserStart({
        user: {
          retailer: keepRole || changeToRole === RolesEnum.RETAILER,
          supplier: keepRole || changeToRole === RolesEnum.SUPPLIER,
        },
      })
    );

    this.router.navigate(['/']);
  }

  private updateRole(changeToRole: RolesEnum, keepRole: boolean): void {
    this.rolesService.addRole({ roles: [NUM_ROLE_MAP_TO_STR[changeToRole]] }).subscribe(() => {
      if (!keepRole) {
        this.rolesService
          .removeRole({
            roles: [changeToRole === RolesEnum.RETAILER ? RoleTypesEnum.SUPPLIER : RoleTypesEnum.RETAILER],
          })
          .subscribe(() => {
            this.store.dispatch(new LogoutAction());
          });
      } else {
        this.store.dispatch(new LogoutAction());
      }
    });
  }

  private handleOpenPlatformDialog(ecomType: EcomTypeEnum, role: RolesEnum): void {
    this.dialogService.open<PlatformDialogComponent, PlatformDialogData>(PlatformDialogComponent, {
      width: '1136px',
      maxWidth: '1136px',
      panelClass: ['custom-modal-container', 'custom-modal-full-width-lt-md'],
      autoFocus: false,
      data: {
        platformData: {
          platformCardData: { ...getPlatformByType(ecomType).install, type: ecomType },
          isInstalled: false,
        },
        type: 'PLATFORM',
        role: role,
      },
    });
  }
}
