import { Routes } from '@angular/router';
import { MARKETPLACE_NAVIGATION } from '../navigation/navigation-routes/common/marketplace.navigation';
import { ProductDetailsPageComponent } from '../main/product-details-page/product-details-page.component';
import { ExploreProductsSearchComponent } from '../main/marketplace/explore-products/explore-products-search/explore-products-search.component';
import { DiscoveryGuard } from '../service/guards/discovery.guard';

export const marketplaceRoutes: Routes = [
  { path: 'explore-products/products/:id', redirectTo: `${MARKETPLACE_NAVIGATION.PRODUCT}/:id` },
  {
    path: `${MARKETPLACE_NAVIGATION.PRODUCT}/:id`,
    data: { hideSidebar: true, contentContainer: 'full-width-without-padding' },
    children: [
      {
        path: '**',
        component: ProductDetailsPageComponent,
        data: {
          cleanRoute: `${MARKETPLACE_NAVIGATION.PRODUCT}`,
          pageTitle: 'App - Explore Products - Product',
          skipMetaTagUpdate: true,
        },
      },
    ],
  },
  {
    path: `${MARKETPLACE_NAVIGATION.DISCOVERY}/:search_term`,
    canActivate: [DiscoveryGuard],
    component: ExploreProductsSearchComponent,
  },
  {
    path: `${MARKETPLACE_NAVIGATION.COLLECTIONS}/:category_id`,
    data: { hideSidebar: true, contentContainer: 'full-width-without-padding' },
    children: [
      {
        path: '**',
        component: ExploreProductsSearchComponent,
        data: {
          cleanRoute: `${MARKETPLACE_NAVIGATION.COLLECTIONS}`,
          pageTitle: 'App - Explore Products - Search',
          skipMetaTagUpdate: true,
        },
      },
    ],
  },
  {
    path: `${MARKETPLACE_NAVIGATION.STOREFRONT}/:supplier_user_id/:supplier_name`,
    data: {
      cleanRoute: `${MARKETPLACE_NAVIGATION.STOREFRONT}`,
      pageTitle: 'App - Storefront',
      hideSidebar: true,
      contentContainer: 'base-container',
      skipMetaTagUpdate: true,
    },
    loadChildren: () => import('app/main/marketplace/storefront/storefront.module').then((m) => m.StorefrontModule),
  },
];
