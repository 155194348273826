import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { REVIEW_IMAGES } from './review-images.config';

@Component({
  selector: 'app-review-images',
  standalone: true,
  imports: [CommonModule, FlexModule, NgOptimizedImage],
  templateUrl: './review-images.component.html',
  styleUrls: ['./review-images.component.scss'],
})
export class ReviewImagesComponent implements OnInit {
  @Input() width = 79;
  @Input() height = 48;
  @Input() gap = 8;
  protected readonly reviews = REVIEW_IMAGES;

  constructor() {}

  ngOnInit(): void {}
}
