import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AddFilterWithRedirectAction } from 'app/store/product-search/product-search.action';

import { SearchWidgetService } from 'app/service/search-widget/search-widget.service';

import type { AppState } from 'app/app.state';
import type { Widget } from 'app/vo/widget';
import type { MarketplaceFilter } from 'app/vo/search-product-vo';

@Component({
  selector: 'app-category-card-item',
  templateUrl: './category-card-item.component.html',
  styleUrls: ['./category-card-item.component.scss'],
})
export class CategoryCardItemComponent implements OnInit {
  @Input() widget: Widget;

  @Input() cardHeight: number;

  public filter: MarketplaceFilter;

  public title: string;

  constructor(private store: Store<AppState>, private searchWidgetService: SearchWidgetService) {}

  public ngOnInit(): void {
    this.initFilter();
  }

  public handleClick(): void {
    this.store.dispatch(
      new AddFilterWithRedirectAction({
        needRedirect: true,
        skipLocationChange: true,
        filter: this.filter,
      })
    );
  }

  private initFilter(): void {
    this.filter = this.searchWidgetService.mapWidgetFilterDataToMarketplaceFilter(this.widget.filterData);
  }
}
