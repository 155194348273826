<div [hidden]="hasError" [style.height]="'100%'">
  <div fxLayout="column" fxLayoutGap="10px" [style.height]="'100%'">
    <app-custom-panel
      class="panel"
      [class.has-selected-item]="selectedItems.length > 0"
      [class.none-border]="!hasBorder"
      [overflowScroll]="overflowScroll"
    >
      <div
        *ngIf="enableFilters"
        class="filter-row-container"
        fxLayout="column"
        fxLayoutGap="8px"
        fxLayoutAlign="center space-between"
      >
        <app-index-table-search-group
          (filterGroupOpen)="filterGroupOpen = !filterGroupOpen"
          (searchTermChange)="searchTermChange.emit($event)"
          [hasActiveFilter]="(!!activeFilters && activeFilters.length > 0) || !!dateFrom || !!dateTo"
          [flexAlign]="'space-between'"
          [permanentSearchbar]="permanentSearchbar"
          [enableFilterButton]="showFilterButton"
          [enableExpandButton]="showExpandButton"
          [enableSearchbar]="showSearchbar"
          [loading]="fetcherLogic ? loading : isLoading"
          [views]="viewArray"
          (viewChange)="viewChange.emit($event)"
          (clearAllClicked)="clearAllClicked.emit()"
        ></app-index-table-search-group>
        <div
          class="w-100-p"
          [ngClass]="{ 'm-0': !filterGroupOpen }"
          *ngIf="(!!filterArray && filterArray.length > 0) || enableFilterDatePicker"
        >
          <app-filter-factory
            [openFilters]="filterGroupOpen"
            [filterArray]="filterArray"
            (filterChange)="filterChange.emit($event)"
            [activeFilters]="activeFilters"
            [enableDatePicker]="enableFilterDatePicker"
            (dateFromChange)="dateFromChange.emit($event)"
            (dateToChange)="dateToChange.emit($event)"
            [dateFrom]="dateFrom"
            [dateTo]="dateTo"
            (clearAllClicked)="clearAllClicked.emit()"
            [loading]="fetcherLogic ? loading : isLoading"
          ></app-filter-factory>
        </div>
        <app-custom-divider></app-custom-divider>
      </div>
      <div class="overlay-container">
        <div *ngIf="selectedItems.length > 0" class="overlay" @fadeInOut>
          <div fxLayoutAlign="start center" fxLayoutGap="16px" class="overlay-content">
            <span class="h5">{{
              'CUSTOM_TABLE.PRODUCT' + (selectedItems.length != 1 ? 'S' : '') + '_SELECTED'
                | translate : { count: selectedItems.length }
            }}</span>
            <app-actions-button-with-menu
              [buttonText]="actionsButtonText | translate"
              [actions]="selectedActions"
              [height]="'30px'"
            ></app-actions-button-with-menu>
          </div>
        </div>
      </div>

      <div [hidden]="fetcherLogic ? loading : isLoading" [style.height]="'100%'">
        <div
          [hidden]="fetcherLogic ? isEmpty : !isLoading && !!dataSource && dataSource.data?.length === 0"
          [style.height]="'100%'"
        >
          <div></div>
          <table
            class="custom-table"
            mat-table
            [dataSource]="dataSource"
            [multiTemplateDataRows]="isMultiTemplateDataRows"
          >
            <ng-container matColumnDef="checkbox">
              <th mat-header-cell *matHeaderCellDef class="checkbox-column">
                <mat-checkbox
                  class="syncee-blue-checkbox"
                  [checked]="allCheckboxStatus === 'checked'"
                  [indeterminate]="allCheckboxStatus === 'indeterminate'"
                  (change)="handleAllCheckboxClicked()"
                ></mat-checkbox>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let dataIndex = dataIndex; let index = index"
                class="checkbox-column"
              >
                <mat-checkbox
                  class="syncee-blue-checkbox"
                  [(ngModel)]="selectedCheckboxes[isMultiTemplateDataRows ? dataIndex : index]"
                  (ngModelChange)="handleCheckboxClicked($event, element, isMultiTemplateDataRows ? dataIndex : index)"
                  (click)="$event.stopPropagation()"
                ></mat-checkbox>
              </td>
            </ng-container>
            <ng-content></ng-content>
            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: innerDisplayedColumns"
              (click)="clickOnRow(row)"
              [class.custom-table-row-disabled]="row['customTableRowDisabled']"
            ></tr>
            <ng-container *ngIf="!!expandedColumn">
              <tr mat-row *matRowDef="let row; columns: [expandedColumn]" class="expandable-row"></tr>
            </ng-container>
          </table>
        </div>
      </div>
      <ng-container *ngIf="fetcherLogic ? loading : isLoading" [ngTemplateOutlet]="skeletonRows"></ng-container>
      <div
        [hidden]="fetcherLogic ? !isEmpty : !isLoading && !!dataSource && dataSource.data?.length > 0"
        [style.height]="'100%'"
      >
        <app-no-search-results *ngIf="!noResultComponent" [titleKey]=""></app-no-search-results>
        <ng-container
          *ngIf="
            !!noResultComponent &&
            (fetcherLogic ? isEmpty : !isLoading && !!dataSource && dataSource.data?.length === 0)
          "
          [ngTemplateOutlet]="noResultComponent"
        ></ng-container>
      </div>
    </app-custom-panel>
    <app-simple-paginator-with-arrows
      *ngIf="isPaginate && !!dataSource"
      [allItem]="allItemsCount"
      [page]="page"
      [size]="size"
      [currentlyShownItem]="dataSource.data.length"
      [hasBorder]="false"
      [hasSizeSelector]="true"
      (next)="nextPage()"
      (previous)="previousPage()"
      (sizeChange)="sizeChangeHandler($event)"
    ></app-simple-paginator-with-arrows>
  </div>
</div>

<ng-container *ngIf="(!loading || (!fetcherLogic && !isLoading)) && !!hasError">
  <app-error-message></app-error-message>
</ng-container>

<ng-template #skeletonRows>
  <ng-container *ngFor="let _ of [].constructor(numberOfSkeletonRows)">
    <tr [style.height.px]="64" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
      <td *ngIf="selectable" [style.width.px]="56" [style.paddingBlock.px]="12" [style.paddingLeft.px]="24">
        <app-skeleton-image height="16px" width="16px" borderRadius="2px"></app-skeleton-image>
      </td>

      <ng-container *ngTemplateOutlet="skeletonTemplateColumns ?? defaultSkeletonColumns"></ng-container>
    </tr>
  </ng-container>
</ng-template>

<ng-template #defaultSkeletonColumns>
  <ng-container *ngFor="let __ of [].constructor(numberOfSkeletonColumns)">
    <td [style.flex]="'1 0 0'" [style.paddingInline.px]="8" [style.paddingBlock.px]="12">
      <app-skeleton-text [lines]="1" [style.width]="'100%'"></app-skeleton-text>
    </td>
  </ng-container>
</ng-template>
