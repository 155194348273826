<form
  [formGroup]="billingInformationService.billingInfoForm"
  fxLayout="column"
  fxLayoutAlign="center stretch"
  fxLayoutAlign.lt-md="center stretch"
  fxLayoutGap="20px"
>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <!--First name-->
    <mat-form-field class="input-without-padding-except-error" appearance="fill" fxFlex="1 0 0">
      <mat-label>{{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}</mat-label>
      <input matInput formControlName="firstName" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.FIRST_NAME_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <!--Last name-->
    <mat-form-field class="input-without-padding-except-error" appearance="fill" fxFlex="1 0 0">
      <mat-label>{{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}</mat-label>
      <input matInput formControlName="lastName" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.LAST_NAME_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <!--company name-->
  <mat-form-field class="input-without-padding-except-error" appearance="fill">
    <mat-label>{{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}</mat-label>
    <input matInput formControlName="companyName" />
    <mat-error>{{ 'BILLING_INFO_FORM.COMPANY_NAME_REQUIRED' | translate }}</mat-error>
  </mat-form-field>

  <!--country-->
  <app-single-country-selector-form-field
    formControlName="country"
    [label]="'BILLING_INFO_FORM.COUNTRY' | translate"
    [error]="
      billingInformationService.billingInfoForm.get('country').invalid
        ? ('BILLING_INFO_FORM.COUNTRY_REQUIRED' | translate)
        : null
    "
  >
  </app-single-country-selector-form-field>

  <!--Address1-->
  <mat-form-field class="input-without-padding-except-error" appearance="fill">
    <mat-label>{{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}</mat-label>
    <input matInput formControlName="address1" />
    <mat-error>
      {{ 'BILLING_INFO_FORM.ADDRESS1_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>

  <!--Address2-->
  <mat-form-field class="input-without-padding-except-error" appearance="fill">
    <mat-label>{{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}</mat-label>
    <input matInput formControlName="address2" />
  </mat-form-field>

  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <!--City-->
    <mat-form-field fxFlex="1 0 0" class="input-without-padding-except-error" appearance="fill">
      <mat-label>{{ 'BILLING_INFO_FORM.CITY' | translate }}</mat-label>
      <input matInput formControlName="city" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.CITY_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <!--zip-->
    <mat-form-field fxFlex="1 0 0" class="input-without-padding-except-error" appearance="fill">
      <mat-label>{{ 'BILLING_INFO_FORM.ZIP' | translate }}</mat-label>
      <input matInput formControlName="zip" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.ZIP_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <!--euVat-->
    <mat-form-field
      fxFlex="1 0 0"
      *ngIf="!billingInformationService.hideEUVat"
      appearance="fill"
      class="input-prefix-fix input-without-padding-except-error eu-vat"
    >
      <mat-label>{{
        (billingInformationService.billingInfoForm.get('country').value | isCpfCountry)
          ? 'CPF (Cadastro de Pessoas Físicas)'
          : ('BILLING_INFO_FORM.EU_VAT' | translate)
      }}</mat-label>
      <input matInput formControlName="euVat" />
      <span matPrefix *ngIf="!(billingInformationService.billingInfoForm.get('country').value | isCpfCountry)">{{
        billingInformationService.billingInfoForm.get('country').value == 'GR'
          ? 'EL'
          : billingInformationService.billingInfoForm.get('country').value
      }}</span>
    </mat-form-field>

    <!--tax number-->
    <mat-form-field
      fxFlex="1 0 0"
      *ngIf="!billingInformationService.hideTaxNumber"
      appearance="fill"
      class="input-prefix-fix input-without-padding-except-error"
    >
      <mat-label>{{ 'BILLING_INFO_FORM.TAX_NUMBER' | translate }}</mat-label>
      <input matInput formControlName="taxNumber" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.TAX_NUMBER_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>

<ng-template #error>
  <div>{{ 'BILLING_INFO_FORM.SOMETHING_WRONG' | translate }}</div>
</ng-template>
