import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { User } from '../../../../service/user/user';
import { Preference } from '../../../../vo/Preferences/preference';
import { getCurrentUserSelector } from '../../../../store/user/user.selector';
import { PreferenceNameEnum } from '../../../../vo/Preferences/preference-name.enum';
import { SetUpAccountFormValue } from '../model/set-up-account-form-value';
import { catchError, map, switchMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { PreferenceStoreService } from '../../../../service/preference/preference.store.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { CountriesManagerService } from '../../../../service/countries-manager/countries-manager.service';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { SupplierWithBadgesDto } from '../../../../vo/supplier/supplier-with-badges-dto';
import {
  SupplierHeadquarterDto,
  SupplierHeadquartersService,
} from '../../../../service/headquarters/supplier-headquarters.service';

@Injectable()
export class SetUpAccountFormService {
  constructor(
    private preferenceStoreService: PreferenceStoreService,
    private store: Store<AppState>,
    private countryManager: CountriesManagerService,
    private supplierGatewayService: SupplierGatewayService,
    private supplierHeadquartersService: SupplierHeadquartersService
  ) {}

  getSetUpAccountFormValueRetailer(): Observable<SetUpAccountFormValue> {
    return this.getUserAndLocation().pipe(
      map(([user, location]) =>
        this.getValueFromStoreResponse(
          user,
          !isEmpty(location) ? this.countryManager.getCountryCodeByCountryName(location[0].preferenceValue) : ''
        )
      )
    );
  }

  getSetUpAccountFormValueSupplier(): Observable<SetUpAccountFormValue> {
    return combineLatest([
      this.store.select(getCurrentUserSelector),
      this.supplierHeadquartersService.get().pipe(catchError(() => of(null))),
    ]).pipe(
      switchMap(([user, supplierHq]) =>
        this.supplierGatewayService.getSuppliers([user.id]).pipe(
          map((suppliers) => suppliers[0]),
          map((supplier) => this.getValueSupplier(user, supplierHq, supplier))
        )
      )
    );
  }

  private getUserAndLocation(): Observable<[User, Preference[]]> {
    return combineLatest([
      this.store.select(getCurrentUserSelector),
      this.preferenceStoreService.getPreferencesByPreferenceName(PreferenceNameEnum.MY_LOCATION),
    ]);
  }

  private getValueFromStoreResponse(user: User, country: string): SetUpAccountFormValue {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      retailerPhoneNumber: { countryCode: user.phoneCode, phoneNumber: user.phone },
      retailerCountry: country,
    };
  }

  private getValueSupplier(
    user: User,
    country: SupplierHeadquarterDto,
    supplier: SupplierWithBadgesDto
  ): SetUpAccountFormValue {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      supplierPhoneNumber: !!supplier && { countryCode: supplier.phoneCode, phoneNumber: supplier.phoneNumber },
      supplierCountry: !country ? null : country.countryCode,
      contactEmail: user.email,
    };
  }
}
