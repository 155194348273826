import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupplierDashboardAnalyticsService } from '../../../service/analytics/supplier-dashboard-analytics.service';
import { ColorPaletteService } from '../../../service/color-palette/color-palette.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { StatisticType } from '../orders-chart/orders-chart.component';

@Component({
  selector: 'app-supplier-orders-chart',
  templateUrl: './supplier-orders-chart.component.html',
  styleUrls: ['./supplier-orders-chart.component.scss'],
})
export class SupplierOrdersChartComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective, { static: false }) chartView: BaseChartDirective;
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  @Input()
  datasets: ChartDataset<'bar', (number | [number, number])[]>[];
  @Input()
  barChartOptions: ChartConfiguration<'bar'>['options'];
  @Input()
  datasetsChanged: Subject<void>;
  @Input()
  labels: string[];
  @Input()
  statisticTypes: StatisticType[];
  @Input()
  anyOrdersReceived;

  @Output()
  statisticTypeChange: EventEmitter<StatisticType> = new EventEmitter<StatisticType>();
  private unsubscribeAll: Subject<void> = new Subject<void>();

  selectedStatisticType: StatisticType = 'PRODUCTS_SOLD';

  defaultBarChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: this.colorPaletteService.getColorVariable('--app-grey-100'),
        barThickness: 'flex',
        borderRadius: 8,
      },
      {
        data: [],
        backgroundColor: this.colorPaletteService.getColorVariable('--app-grey-100'),
        barThickness: 'flex',
        borderRadius: 8,
      },
    ],
  };

  barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: [],
  };

  constructor(
    private supplierDashboardAnalyticsService: SupplierDashboardAnalyticsService,
    private colorPaletteService: ColorPaletteService,
    private screenManagerService: ScreenManagerService
  ) {}

  ngOnInit(): void {
    this.setDefaultBarchartData();
    this.createLgSubscription();
    this.createSmSubscription();
    this.createChartDataChangedSubscription();
    this.statisticTypeChange.emit(this.selectedStatisticType);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleStatisticTypeChange(event: MatRadioChange): void {
    this.selectedStatisticType = event.value;
    this.chartView.chart.update();
    this.statisticTypeChange.emit(this.selectedStatisticType);
  }

  private createChartDataChangedSubscription(): void {
    this.datasetsChanged.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.barChartData.datasets = this.datasets;
      this.barChartData.labels = this.labels;
      // needed for change detection
      this.barChartOptions = { ...this.barChartOptions };
    });
  }

  private setDefaultBarchartData(): void {
    const base = 5;
    const exponent = 2;
    const numOfValues = 10;
    const middle = Math.round(numOfValues / 2);
    const multiplier = 2;

    for (let i = 0; i < numOfValues; i++) {
      const closenessToMiddle = (middle - Math.abs(middle - i)) / 2;
      const value = base + Math.floor(Math.pow(exponent, i) / 10) + closenessToMiddle * multiplier;

      this.defaultBarChartData.datasets[0].data.push(value);
      this.defaultBarChartData.labels.push(i);
    }
  }

  private createLgSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.lg)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((event) => {
        this.barChartOptions.scales.x.ticks.display = !event.matches;

        this.barChartOptions = { ...this.barChartOptions };

        if (this.chartView) {
          this.chartView.chart.update();
          this.chartView.chart.resize();
        }
      });
  }

  private createSmSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.sm)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((event) => {
        this.barChartOptions.scales.x.title.display = !event.matches;
        this.barChartOptions.scales.y.title.display = !event.matches;
        this.barChartOptions.scales.y.ticks.display = !event.matches;

        this.barChartOptions = { ...this.barChartOptions };

        if (this.chartView) {
          this.chartView.chart.update();
          this.chartView.chart.resize();
        }
      });
  }
}
