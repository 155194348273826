import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { CartService } from 'app/main/cart/service/cart.service';
import { HasCartService } from 'app/service/has-cart/has-cart.service';
import { hasRolesSelector } from 'app/store/user/user.selector';
import { RolesEnum } from 'app/vo/roles/roles';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
})
export class CartIconComponent implements OnInit, OnDestroy {
  count: string = null;
  private _unsubscribeAll: Subject<void>;

  constructor(private route: Router, private cartService: CartService, public hasCartService: HasCartService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getCartLineItemsCount();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  clickHandle(): void {
    this.route.navigate(['/cart']);
  }

  getCartLineItemsCount(): void {
    this.cartService
      .getCartLineItemsCount()
      .pipe(filter((count) => count !== null))
      .subscribe((count) => {
        this.count = count.toString();
      });
  }
}
