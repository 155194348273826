import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomSpinnerComponent } from 'app/shared/components/custom-spinner/custom-spinner.component';
import { ErrorMessageComponent } from '../components/error-message/error-message.component';

@Directive({
  selector: '[conditionLoading]',
  standalone: true,
})
export class ConditionLoadingDirective {
  private hasView = false;
  private _spinnerInstance: CustomSpinnerComponent = null;
  private _errorInstance: ErrorMessageComponent = null;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set conditionLoading(c: any) {
    if (!!c && !this.hasView) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef, { conditionLoading: c });
      this.hasView = true;
    } else if (!c) {
      this.viewContainer.clear();
      this._spinnerInstance = this.viewContainer.createComponent(CustomSpinnerComponent).instance;
      this.hasView = false;
    }
  }

  @Input() set conditionLoadingDiameter(diameter: number) {
    if (this._spinnerInstance) {
      this._spinnerInstance.diameter = diameter;
    }
  }

  @Input() set conditionLoadingHasError(hasError: boolean) {
    if (hasError) {
      this.viewContainer.clear();
      this._errorInstance = this.viewContainer.createComponent(ErrorMessageComponent).instance;
      this.hasView = true;
    }
  }
}
