import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { FromToDatePickerComponent } from '../from-to-date-picker/from-to-date-picker.component';
import { IndexTableFilterDropdownComponent } from '../index-table-filter/index-table-filter-dropdown/index-table-filter-dropdown.component';
import { Choice } from '../filter-factory/filter-factory.component';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { isEqual } from 'lodash';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-date-picker-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
    IndexTableFilterDropdownComponent,
    TranslateModule,
    FromToDatePickerComponent,
    CustomDividerComponent,
    MatRadioModule,
    FormsModule,
  ],
  templateUrl: './date-picker-dropdown.component.html',
  styleUrls: ['./date-picker-dropdown.component.scss'],
})
export class DatePickerDropdownComponent implements OnInit {
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() closeOverlay$: Subject<void>;

  @Output() dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange: EventEmitter<Date> = new EventEmitter<Date>();

  choices: Choice<DateChoice>[];
  dropdownOpen = false;
  customDatePickerOpen = false;

  constructor() {
    this.choices = DEFAULT_DATE_CHOICES.map((choice) => ({
      value: {
        dateFrom: choice.dateFrom,
        dateTo: choice.dateTo,
      },
      label: `INDEX_FILTER.DATE_LABELS.${choice.id}`,
      translate: true,
    }));
  }

  ngOnInit(): void {
    this.customDatePickerOpen =
      (!!this.dateFrom || !!this.dateTo) &&
      !this.choices.find((c) => isEqual(c.value, { dateFrom: this.dateFrom, dateTo: this.dateTo }));
  }
}

export interface DateChoice {
  dateFrom: Date;
  dateTo: Date;
}

export const DEFAULT_DATE_CHOICES = [
  {
    dateFrom: moment().startOf('day').toDate(),
    dateTo: null,
    id: 'TODAY',
  },
  {
    dateFrom: moment().startOf('day').subtract(7, 'days').toDate(),
    dateTo: null,
    id: 'LAST_WEEK',
  },
  {
    dateFrom: moment().startOf('day').subtract(30, 'days').toDate(),
    dateTo: null,
    id: 'LAST_MONTH',
  },
  {
    dateFrom: moment().startOf('day').subtract(90, 'days').toDate(),
    dateTo: null,
    id: 'LAST_QUARTER',
  },
  {
    dateFrom: moment().startOf('day').subtract(365, 'days').toDate(),
    dateTo: null,
    id: 'LAST_YEAR',
  },
];
