<app-single-alert-with-icon
  type="warning"
  [titleKey]="titleKey"
  [descriptionKey]="subTitleKey"
  [button]="{
    type: 'inner_redirect',
    textKey: 'COMMON_ALERTS.NO_ECOM.BUTTON',
    buttonClass: 'syncee-blue-button',
    url: redirectUrl
  }"
>
</app-single-alert-with-icon>
