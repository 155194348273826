import { Component, OnInit } from '@angular/core';
import { UpsellService } from '../../../../service/upsell/upsell.service';

@Component({
  selector: 'app-ai-chat-limit-reached',
  templateUrl: './ai-chat-limit-reached.component.html',
  styleUrls: ['./ai-chat-limit-reached.component.scss'],
})
export class AiChatLimitReachedComponent implements OnInit {
  constructor(public upsellService: UpsellService) {}

  ngOnInit(): void {}
}
