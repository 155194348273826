<div
  fxLayout="column"
  fxLayoutAlign="start"
  fxLayoutGap="24px"
  class="outer-container"
  [ngClass]="wrapperClass"
  [style.background-color]="background"
>
  <div [ngClass]="innerWrapperClass" class="inner-wrapper">
    <div
      class="ml-4 mb-24"
      [fxLayout]="titlePosition === 'start' ? 'column' : 'row'"
      [fxLayoutAlign]="titlePosition === 'start' ? 'start' : 'center'"
      fxLayoutGap="4px"
    >
      <h2 [ngClass]="'h2'" [ngClass.lt-md]="'h3'" *ngIf="title">{{ title }}</h2>

      <div [ngClass]="'typography-h2-subtitle'" [ngClass.lt-md]="'typography-h3-subtitle'" *ngIf="description">
        {{ description }}
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" class="inner-container">
      <ng-container *ngFor="let card of cardsContent">
        <div class="card-container" (click)="emitCardClicked(card)">
          <div
            class="image-card {{ cardOrientation }} {{ cardTitleHorizontalPosition }} {{ cardTitleVerticalPosition }}"
            [style.background-image]="'url(' + card.image + ')'"
            [style.height.px]="cardHeight"
          >
            <h4 class="card-title" [ngClass]="'typography-body-large-3'" [ngClass.lt-md]="'h4'">
              {{ card.titles | widgetTranslatePipe : 'title' }}
            </h4>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
