<div fxLayout="column" fxLayoutGap="20px">
  <ng-container *ngIf="isLocked">
    <app-task-wizard-step-locked-warning></app-task-wizard-step-locked-warning>
  </ng-container>
  <div class="supplier-pricing-container" fxLayout="column" fxLayoutGap="20px">
    <ng-container *ngIf="priceSettingsObservable | async as priceSettings">
      <app-custom-panel>
        <div class="typography-body-large-2 mb-4">
          {{ 'TASKS.PRICE_SETTINGS.' + (isDatafeed ? 'DATAFEED' : 'NON_DATAFEED') + '_TITLE' | translate }}
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
          <app-supplier-task-pricing-type-settings
            [locked]="isLocked"
            [isDatafeed]="isDatafeed"
            [selected]="priceType"
            (selectedChange)="handleChangePriceType($event)"
          ></app-supplier-task-pricing-type-settings>
          <app-import-list-pricing
            [locked]="isLocked"
            [isDatafeed]="isDatafeed"
            [priceType]="priceType"
            [pricing]="priceSettings.pricing"
            [collections]="priceSettings.collections"
            [hasSaveButton]="false"
            [comments]="comments"
            [catalogId]="catalogId"
            [enabledPercentageOperators]="priceSettings.enabledPercentageOperators"
            [enabledFixOperators]="priceSettings.enabledFixOperators"
            [defaultPercentageOperator]="priceSettings.defaultPercentageOperator"
            [defaultFixOperator]="priceSettings.defaultFixOperator"
            (submitValue)="taskPricingService.setPriceSettings($event)"
            [ecomCurrency]="ecomCurrency"
          ></app-import-list-pricing>
        </div>
      </app-custom-panel>
    </ng-container>
    <ng-container *ngIf="roundingObservable$ | async as roundingRule">
      <app-custom-panel>
        <app-component-header
          [title]="'IMPORT_LIST.ROUNDING.TITLE'"
          [description]="'IMPORT_LIST.ROUNDING.' + (priceType | uppercase) + '_DESCRIPTION'"
        >
        </app-component-header>
        <app-import-list-price-rounding
          [locked]="isLocked"
          [hasSaveButton]="false"
          [roundingRule]="roundingRule"
          (submitValue)="taskPricingService.setRoundingRule($event)"
          [ecomCurrency]="ecomCurrency"
        ></app-import-list-price-rounding>
      </app-custom-panel>
    </ng-container>
  </div>
</div>
