<app-filter-selector
  [options]="options"
  [hasSearch]="true"
  [value]="
    (productSearchStore$ | async)?.temporaryFilter?.shipsFrom || (productSearchStore$ | async)?.filter?.shipsFrom
  "
  (valueChange)="handleSelectedChange($any($event))"
  [searchTerm]="searchTerm"
  (searchTermChange)="handleSearchChange($event)"
>
</app-filter-selector>
