<app-filter-selector-base
  [title]="'SHIPS_TO'"
  [searchLabel]="'SHIPPING'"
  [options]="filteredOptions"
  [hasSearch]="true"
  [value]="value"
  [multiple]="true"
  (valueChange)="handleShipsToChange($event)"
  [searchTerm]="searchTerm"
  (searchTermChange)="handleSearchChange($event)"
  [hasSeeMore]="hasSeeMore"
  (seeMore)="onSeeMore()"
  (seeLess)="onSeeLess()"
  [hasTopOptions]="true"
  [noOptionsAvailable]="noOptionsAvailable"
  [hasClearAll]="hasClearAll"
  (clearAll)="onClearAll()"
  [overlayOpen]="overlayOpen"
>
</app-filter-selector-base>
