import { Injectable } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { SidebarMenu } from '../../../service/sidebar-menu/sidebar-menu.service';
import { MarketplaceFilter } from '../../../vo/search-product-vo';
import { AutoOrderFilterComponent } from './filter-items/filter-item/auto-order-filter/auto-order-filter.component';
import { CategoriesFilterComponent } from './filter-items/filter-item/categories-filter/categories-filter.component';
import { PriceFilterComponent } from './filter-items/filter-item/price-filter/price-filter.component';
import { ProductLanguageFilterComponent } from './filter-items/filter-item/product-language-filter/product-language-filter.component';
import { ShippingTypeFilterComponent } from './filter-items/filter-item/shipping-type-filter/shipping-type-filter.component';
import { ShipsFromFilterComponent } from './filter-items/filter-item/ships-from-filter/ships-from-filter.component';
import { ShipsToFilterComponent } from './filter-items/filter-item/ships-to-filter/ships-to-filter.component';
import { StockStatusFilterComponent } from './filter-items/filter-item/stock-status-filter/stock-status-filter.component';
import { SupplierFilterComponent } from './filter-items/filter-item/supplier-filter/supplier-filter.component';
import { SupplierTypeFilterComponent } from './filter-items/filter-item/supplier-type-filter/supplier-type-filter.component';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filterMenu: SidebarMenu<FilterVariant> = {
    id: 'MAIN',
    variant: 'group',
    children: [
      {
        id: 'CATEGORY',
        variant: 'item',
        component: CategoriesFilterComponent,
      },
      {
        id: 'PRODUCT_LANGUAGE',
        variant: 'item',
        component: ProductLanguageFilterComponent,
      },
      {
        id: 'SUPPLIER_GROUP',
        variant: 'group',
        children: [
          {
            id: 'SUPPLIER',
            variant: 'item',
            component: SupplierFilterComponent,
          },
          {
            id: 'SUPPLIER_TYPE',
            variant: 'item',
            component: SupplierTypeFilterComponent,
          },
        ],
      },
      {
        id: 'SHIPPING_GROUP',
        variant: 'group',
        children: [
          {
            id: 'SHIPS_FROM',
            variant: 'item',
            component: ShipsFromFilterComponent,
          },
          {
            id: 'SHIPS_TO',
            variant: 'item',
            component: ShipsToFilterComponent,
          },
          {
            id: 'SHIPPING_TYPE',
            variant: 'item',
            component: ShippingTypeFilterComponent,
          },
        ],
      },
      {
        id: 'STOCK_STATUS',
        variant: 'item',
        component: StockStatusFilterComponent,
      },
      {
        id: 'AUTO_ORDER',
        variant: 'item',
        component: AutoOrderFilterComponent,
      },
      {
        id: 'PRICE',
        variant: 'item',
        component: PriceFilterComponent,
      },
    ],
  };

  public normalizeFilterFromStrings(filter: MarketplaceFilter): MarketplaceFilter {
    return {
      ...filter,
      ...(!Utils.isNullOrUndefined(filter.ecomId) && { ecomId: Number(filter.ecomId) }),
      ...(!Utils.isNullOrUndefined(filter.category) && { category: Number(filter.category) }),
      ...(!Utils.isNullOrUndefined(filter.maxPrice) && { maxPrice: Number(filter.maxPrice) }),
      ...(!Utils.isNullOrUndefined(filter.minPrice) && { minPrice: Number(filter.minPrice) }),
      ...(!Utils.isNullOrUndefined(filter.minShippingTime) && { minShippingTime: Number(filter.minShippingTime) }),
      ...(!Utils.isNullOrUndefined(filter.maxShippingTime) && { maxShippingTime: Number(filter.maxShippingTime) }),
      ...(!Utils.isNullOrUndefined(filter.supplierId) && { supplierId: Number(filter.supplierId) }),
      ...(!Utils.isNullOrUndefined(filter.taskId) && { taskId: Number(filter.taskId) }),
      ...(!Utils.isNullOrUndefined(filter.supplier) &&
        !Array.isArray(filter.supplier) && { supplier: filter.supplier.split(',') }),
      ...(!Utils.isNullOrUndefined(filter.shipsFrom) &&
        !Array.isArray(filter.shipsFrom) && { shipsFrom: filter.shipsFrom.split(',') }),
      ...(!Utils.isNullOrUndefined(filter.shipsTo) &&
        !Array.isArray(filter.shipsTo) && { shipsTo: filter.shipsTo.split(',') }),
    };
  }
}

type FilterItemVariant =
  | 'SHIPPING_TYPE'
  | 'PRICING'
  | 'SUPPLIER'
  | 'SHIPS_FROM'
  | 'SHIPS_TO'
  | 'PRODUCT_LANGUAGE'
  | 'SUPPLIER_TYPE'
  | 'STOCK_STATUS'
  | 'AUTO_ORDER'
  | 'PRICE'
  | 'CATEGORY';
type FilterGroupVariant = 'SHIPPING_GROUP' | 'SUPPLIER_GROUP';
export type FilterVariant = FilterItemVariant | FilterGroupVariant | 'MAIN';

export interface FilterSidebarMetadata {
  hasCategorySelector: boolean;
}
