import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RetailerToCatalog } from '../../../../main/marketplace/my-suppliers-new/model/retailer-to-catalog';
import { MySuppliersRestService } from '../../../../main/marketplace/my-suppliers-new/service/my-suppliers-rest.service';
import { NotificationService } from '../../../../main/notification/notification.service';
import { NavigationService } from '../../../../service/navigation/navigation.service';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { RolesEnum, ROLE_TYPE_ADMIN_EXCLUDED } from '../../../../vo/roles/roles';
import { SupplierWithBadgesAndAutoOrder } from '../../../../vo/supplier/supplier-with-badges-and-auto-order';
import { CatalogApproveStatusBadgeComponent } from '../../catalog-approve-status-badge/catalog-approve-status-badge.component';
import { CustomDividerComponent } from '../../custom-divider/custom-divider.component';
import { TaskInformationBaseDialogComponent } from '../task-information-base-dialog/task-information-base-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-my-suppliers-catalog-information-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TaskInformationBaseDialogComponent,
    MatButtonModule,
    CatalogApproveStatusBadgeComponent,
    CustomDividerComponent,
    MatSlideToggleModule,
    FlexModule,
    MatIconModule,
    FormsModule,
    TranslateModule,
    MatTooltipModule,
  ],
  templateUrl: './my-suppliers-catalog-information-dialog.component.html',
  styleUrls: ['./my-suppliers-catalog-information-dialog.component.scss'],
})
export class MySuppliersCatalogInformationDialogComponent implements OnInit {
  role: ROLE_TYPE_ADMIN_EXCLUDED = RolesEnum.SUPPLIER;
  isMobile$: Observable<boolean>;
  dropship: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MySuppliersCatalogInformationDialogData,
    private dialogRef: MatDialogRef<MySuppliersCatalogInformationDialogComponent>,
    private screenManager: ScreenManagerService,
    private navigationService: NavigationService,
    private mySuppliersRestService: MySuppliersRestService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.screenManager.observeIfBreakpointMatches(BreakPoint.md);
    if (!!this.data) {
      this.dropship = this.data.retailerToCatalog.dropship;
    }
  }

  handleSaveClick(): void {
    this.mySuppliersRestService
      .updateCatalogSettings({
        id: this.data.retailerToCatalog.catalogId,
        dropship: this.dropship,
      })
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.dropship = this.data.retailerToCatalog.dropship;
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response) {
          const message = this.translateService.instant('MY_SUPPLIERS.CATALOG_INFO_DIALOG.SHIPPING_TYPE_MODIFY_ALERT');
          this.notificationService.success(message);
          this.data.retailerToCatalog.dropship = this.dropship;
          this.dialogRef.close();
        }
      });
  }

  redirectToStoreFront(): void {
    this.dialogRef.close();
    this.navigationService.navigateToStorefront(this.data.supplierInfo.userId, this.data.supplierInfo.handle, true);
  }
}

export interface MySuppliersCatalogInformationDialogData {
  supplierInfo: SupplierWithBadgesAndAutoOrder;
  retailerToCatalog: RetailerToCatalog;
  taskName: string;
}
