import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { snippetSelector } from '../../../store/snippet/snippet.selector';
import { SnippetState } from '../../../store/snippet/snippet.reducer';
import { OnboardItem, OnboardService } from './onboard.service';
import { flatMap } from 'lodash';
import { ONBOARD_EARN_CREDIT_AMOUNT } from './constant';
import { SetupGuideService } from '../../../main/setup-guide/service/setup-guide.service';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { RolesEnum } from '../../../vo/roles/roles';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss'],
})
export class OnboardComponent implements OnInit, OnDestroy {
  @Input() elevated = false;
  private _unsubscribeAll: Subject<void>;
  show: boolean;
  isRetailer: Observable<boolean>;
  protected readonly amount = ONBOARD_EARN_CREDIT_AMOUNT;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store<AppState>,
    public onboardService: OnboardService,
    private setupGuideService: SetupGuideService
  ) {
    this._unsubscribeAll = new Subject();

    this.subscribeToRouteChange();
  }

  ngOnInit(): void {
    this.getIsRetailer();
    this.getSnippetSate();
  }

  private getSnippetSate(): void {
    combineLatest([
      this.store.select(snippetSelector).pipe(omitNullOrUndefined(), distinctUntilChanged()),
      this.setupGuideService.getAllStepsCompleted().pipe(omitNullOrUndefined(), distinctUntilChanged()),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null)
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([snippetState, allStepsCompleted]) =>
        allStepsCompleted ? (this.show = false) : this.handleChange(snippetState)
      );
  }

  private getIsRetailer(): void {
    this.isRetailer = this.setupGuideService.getRoleForSetupGuideSnippet().pipe(
      untilDestroyed(this),
      map((role) => role === RolesEnum.RETAILER)
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private subscribeToRouteChange(): void {
    this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
      }
    });
  }

  private handleChange(state: SnippetState): void {
    this.show = state.show;
    if (state.show) {
      this.initData(state);
    }
  }

  private initData(state: SnippetState): void {
    this.onboardService.selectedOnboard = this.findSnippetWithUrl(this.getCurrentUrl(), state);
  }

  private findSnippetWithUrl(currentUrl: string, state: SnippetState): OnboardItem {
    if (!currentUrl) {
      return null;
    }
    const allSnippet: OnboardItem[] = flatMap(Object.values(state.items));
    return allSnippet.find((item) => item.url.split(',').some((itemUrl) => this.checkUrl(itemUrl, currentUrl)));
  }

  private checkUrl(urlFromSnippet: string, currentUrl: string): boolean {
    if (urlFromSnippet.indexOf('*') > -1) {
      return currentUrl.includes(urlFromSnippet.substring(0, urlFromSnippet.indexOf('/*')).trim());
    } else {
      return urlFromSnippet.trim() === currentUrl;
    }
  }

  private getCurrentUrl(): string {
    return this.route.snapshot['_routerState'].url.split('?')[0];
  }

  openDialog(): void {
    this.onboardService.openOnboardDialog();
  }
}
