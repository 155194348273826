import { Directive, ElementRef, Inject, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[badge]',
  standalone: true,
})
export class BadgeDirective implements OnChanges, OnDestroy {
  @Input() badge: string = null;
  @Input() badgePosition: BadgePositions = 'top-right';
  @Input() customBadgeClasses: string | null = null;
  @Input() badgeVariant: BadgeVariants = 'without-number';
  @Input() badgeOffsetX = 0;
  @Input() badgeOffsetY = 0;

  badgeElement: HTMLElement | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elemRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('badge' in changes) {
      this.handleBadgeChange(changes.badge.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.clearBadge();
  }

  private handleBadgeChange(value: string): void {
    if (value?.length > 0) {
      this.updateBadgeText(value);
    } else {
      this.clearBadge();
    }
  }

  private updateBadgeText(value: string): void {
    if (!this.badgeElement) {
      this.badgeElement = this.createBadge(value);
    } else {
      this.badgeElement.textContent = value;
    }
  }

  private createBadge(value: string): HTMLElement {
    const badgeElement = this.document.createElement('span');
    this.addClasses(badgeElement);
    this.addOffset(badgeElement);
    if (this.badgeVariant === 'with-number') {
      badgeElement.textContent = value;
    }
    this.elemRef.nativeElement.classList.add('badge-directive-container');
    this.elemRef.nativeElement.appendChild(badgeElement);
    return badgeElement;
  }

  private addClasses(badgeElement: HTMLElement): void {
    const [vPos, hPos] = this.badgePosition.split('-');
    badgeElement.classList.add('badge', vPos, hPos);
    if (this.customBadgeClasses) {
      const customClasses = this.customBadgeClasses.split(' ');
      badgeElement.classList.add(...customClasses);
    }
    badgeElement.classList.add(this.badgeVariant);
  }

  private addOffset(badgeElement: HTMLElement): void {
    if (this.badgeOffsetX) {
      this.renderer.setStyle(badgeElement, 'transform', `translateX(${this.badgeOffsetX}px)`);
    }
    if (this.badgeOffsetY) {
      this.renderer.setStyle(badgeElement, 'transform', `translateY(${this.badgeOffsetX}px)`);
    }
    if (this.badgeOffsetY || this.badgeOffsetX) {
      this.renderer.setStyle(this.elemRef.nativeElement, 'overflow', 'visible');
    }
  }

  private clearBadge(): void {
    if (this.badgeElement) {
      this.badgeElement.remove();
    }
  }
}

export type BadgeVariants = 'with-number' | 'without-number';
export type BadgePositions = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
