import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionUpsellComponent } from 'app/shared/components/subscription-upsell/subscription-upsell.component';
import { LocalizedDatePipe } from 'app/shared/pipes/localized-date.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgPipesModule } from 'ngx-pipes';
import { AvatarComponent } from '../../../shared/components/avatar/avatar.component';
import { CustomDialogWrapperComponent } from '../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { ErrorMessageComponent } from '../../../shared/components/error-message/error-message.component';
import { SingleAlertWithIconComponent } from '../../../shared/components/single-alert-with-icon/single-alert-with-icon.component';
import { SupplierIconComponent } from '../../../shared/components/supplier-icon/supplier-icon.component';
import { ConditionLoadingDirective } from '../../../shared/directives/condition-loading.directive';
import { OverflowDirective } from '../../../shared/directives/overflow.directive';
import { ChatActivePanelComponent } from './chat-active-panel/chat-active-panel.component';
import { ChatNoMessagesYetComponent } from './chat-active-panel/chat-no-messages-yet/chat-no-messages-yet.component';
import { ChatAppContainerComponent } from './chat-app-container/chat-app-container.component';
import { ChatBodyHeaderComponent } from './chat-body/chat-body-header/chat-body-header.component';
import { ChatBodyInputComponent } from './chat-body/chat-body-input/chat-body-input.component';
import { ChatBodyComponent } from './chat-body/chat-body.component';
import { ChatMessageItemComponent } from './chat-body/chat-message-item/chat-message-item.component';
import { ChatChannelItemComponent } from './chat-channel-item/chat-channel-item.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { ChatSearchBarComponent } from './chat-search-bar/chat-search-bar.component';
import { ChatSearchPanelComponent } from './chat-search-panel/chat-search-panel.component';
import { ChatUserItemComponent } from './chat-user-item/chat-user-item.component';
import { ChatComponent } from './chat.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    PickerModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    FuseSharedModule,
    MatTabsModule,
    MatSelectModule,
    NgPipesModule,
    OverlayModule,
    SupplierIconComponent,
    MatMenuModule,
    ErrorMessageComponent,
    RouterLink,
    RouterLinkActive,
    CustomDialogWrapperComponent,
    RouterLinkWithHref,
    SingleAlertWithIconComponent,
    OverflowDirective,
    AvatarComponent,
    ConditionLoadingDirective,
    MatTooltipModule,
    TranslateModule,
    SubscriptionUpsellComponent,
    LocalizedDatePipe,
  ],
  declarations: [
    ChatComponent,
    ChatChannelItemComponent,
    ChatUserItemComponent,
    ChatBodyComponent,
    ChatBodyHeaderComponent,
    ChatBodyInputComponent,
    ChatMessageItemComponent,
    ChatDialogComponent,
    ChatAppContainerComponent,
    ChatActivePanelComponent,
    ChatSearchPanelComponent,
    ChatSearchBarComponent,
    ChatNoMessagesYetComponent,
  ],
  exports: [ChatComponent, ChatAppContainerComponent],
})
export class ChatModule {}
