import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RecentlyDroppedItemNotifier {
  private justDropped$: Subject<any> = new Subject<any>();

  public get(): Observable<any> {
    return this.justDropped$.asObservable();
  }

  public dropItem(val: any): void {
    this.justDropped$.next(val);
  }
}
