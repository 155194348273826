import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SectionTitleComponent } from '../section-title/section-title.component';

@UntilDestroy()
@Component({
  selector: 'app-feature-cards-with-square-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, SectionTitleComponent],
  templateUrl: './feature-cards-with-square-icon.component.html',
  styleUrls: ['./feature-cards-with-square-icon.component.scss'],
})
export class FeatureCardsWithSquareIconComponent implements OnInit {
  @Input() wrapperClass: string | string[];
  @Input() innerClass: string | string[];
  @Input() title: string;
  @Input() description: string;
  @Input() items: FeatureCardsWithSquareIconItem[];
  @Input() columns: number;
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }
}

export interface FeatureCardsWithSquareIconItem {
  title: string;
  description: string;
  icon: string;
  iconColor: string;
  iconBackgroundColor: string;
}
