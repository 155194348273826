<div class="w-100-p py-32">
  <app-section-title-slider
    [title]="title"
    [description]="description"
    [hasAll]="!!filter"
    (allClicked)="handleSeeAllClicked()"
    [hasBottomPadding]="false"
  ></app-section-title-slider>
  <div class="slider-container pt-16" fxLayoutAlign="center center" *ngIf="!!productlist">
    <ng-container *ngIf="!ltMd; else mobile">
      <app-slider-arrow
        *ngIf="swiped"
        [style.visibility]="productlist.length < config.slidesToShow && 'hidden'"
        variant="previous"
        fxFlex="0 0 auto"
        (click)="slickModal.slickPrev()"
      ></app-slider-arrow>
      <ngx-slick-carousel
        fxFlex="1 0 0"
        #slickModal
        class="product-list-items"
        [@animateStagger]="{ value: '50' }"
        [config]="config"
      >
        <ng-container *ngIf="!loading">
          <ng-container *ngFor="let product of productlist; let i = index; let last = last; trackBy: trackByFn">
            <app-product-card
              ngxSlickItem
              [product]="product"
              [inCatalog]="inCatalog(product.ID)"
              [inStore]="inStore(product.ID)"
              [catalogStatus]="catalogStatusCheck(product.TASK_ID)"
              [ecomCurrency]="ecomCurrency"
              [generateMicrodata]="generateMicrodata"
              class="product-item slide-up"
            ></app-product-card>
          </ng-container>
        </ng-container>
      </ngx-slick-carousel>
      <app-slider-arrow
        [style.visibility]="productlist.length < config.slidesToShow && 'hidden'"
        variant="next"
        fxFlex="0 0 auto"
        (click)="slickModal.slickNext(); swiped = true"
      ></app-slider-arrow>
    </ng-container>
  </div>
  <ng-container *ngIf="!!filter && ltMd">
    <ng-container *ngTemplateOutlet="mobileSeeAll"> </ng-container>
  </ng-container>
</div>

<ng-template #mobileSeeAll>
  <div fxLayout="row" fxLayoutAlign="center center" class="w-100-p">
    <button mat-button class="syncee-white-button" (click)="handleSeeAllClicked()">
      {{ 'EXPLORE_PRODUCTS.SEE_ALL' | translate }}
    </button>
  </div>
</ng-template>

<ng-container *ngIf="generateMicrodata">
  <ngx-json-ld *ngIf="!!jsonLD" [json]="jsonLD"></ngx-json-ld>
</ng-container>

<ng-template #mobile>
  <div class="mobile-carousel" *ngIf="!loading">
    <app-product-card
      *ngFor="let product of productlist; let i = index; let last = last; trackBy: trackByFn"
      [product]="product"
      [inCatalog]="inCatalog(product.ID)"
      [inStore]="inStore(product.ID)"
      [catalogStatus]="catalogStatusCheck(product.TASK_ID)"
      [ecomCurrency]="ecomCurrency"
      [generateMicrodata]="generateMicrodata"
      class="product-item slide-up"
    ></app-product-card>
  </div>
</ng-template>
