<app-authentication-layout>
  <app-authentication-dialog-banner
    [textList]="bannerTextList"
    [coverImgPath]="bannerCoverImage"
    banner-side
  ></app-authentication-dialog-banner>
  <app-registration-retailer-content
    content-side
    [titleKey]="titleKey"
    [descriptionKey]="descriptionKey"
  ></app-registration-retailer-content>
</app-authentication-layout>
