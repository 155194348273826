import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

import type { WebVitalMetric } from '../web-vital-metric';
import { WebVitalEventAction, WebVitalEventLabel, WebVitalEventValue } from '../model/variables/general.variables';

export class WebVitalsAction implements GtmAction {
  event = GtmEvent.web_vitals;

  constructor(public payload: WebVitalsGtmActionPayload) {}
}

export interface WebVitalsGtmActionPayload extends WebVitalEventAction, WebVitalEventValue, WebVitalEventLabel {}
