import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ChatUser } from '../../../../service/chat/model/chat-models';

@Component({
  selector: 'app-chat-user-item',
  templateUrl: './chat-user-item.component.html',
  styleUrls: ['./chat-user-item.component.scss'],
})
export class ChatUserItemComponent implements OnInit {
  @Input() user: ChatUser;
  @Input() selected = false;
  @Output() selectUser = new EventEmitter<ChatUser>();
  useFallbackAvatar: boolean;

  constructor() {}

  ngOnInit(): void {}

  handleUserSelection(): void {
    this.selectUser.emit(this.user);
  }
}
