import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskVoExtended } from '../tasks.component';

@Component({
  selector: 'app-tasks-menu',
  templateUrl: './tasks-menu.component.html',
  styleUrls: ['./tasks-menu.component.scss'],
})
export class TasksMenuComponent implements OnInit {
  @Input() menus: any[] = [];
  @Input() task: TaskVoExtended;
  @Output() menuItemClicked = new EventEmitter<TaskMenuItemClickedEvent>();

  constructor() {}

  ngOnInit(): void {}
}

export interface TaskMenuItemClickedEvent {
  id: any;
  task: TaskVoExtended;
}
