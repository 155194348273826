import { AuthenticationState } from './store/authentication/authentication.reducer';
import { BootstrapState } from './store/bootstrap/bootstrap.reducer';
import { CategoryState } from './store/category/category.reducer';
import { CounterState } from './store/counter/counter.reducer';
import { CountriesLocationsState } from './store/countries-locations/countries-locations.reducer';
import { CurrencyState } from './store/currency/currency.reducer';
import { EcomsState } from './store/ecom/ecom.reducer';
import { FavoritesState } from './store/favorites/favorites.reducer';
import { GettingStartedState } from './store/getting-started/getting-started.reducer';
import { LanguageState } from './store/language/language.reducer';
import { PreferencesState } from './store/preferences/preferences.reducer';
import { ProductSearchState } from './store/product-search/product-search.reducer';
import { RcatalogsState } from './store/rcatalogs/rcatalogs.reducer';
import { RetailerToCatalogState } from './store/retailer-to-catalog/retailer-to-catalog.reducer';
import { SnippetState } from './store/snippet/snippet.reducer';
import { SubscriptionsState } from './store/subscription/subscription.reducer';
import { SynceeAppsState } from './store/syncee-apps/syncee-apps.reducer';
import { TasksState } from './store/tasks/tasks.reducer';
import { UsageState } from './store/usage/usage.reducer';
import { UserState } from './store/user/user.reducer';

export interface AppState {
  readonly count: CounterState;
  readonly productSearch: ProductSearchState;
  readonly categories: CategoryState;
  readonly preferences: PreferencesState;
  readonly rcatalogs: RcatalogsState;
  readonly authentication: AuthenticationState;
  readonly bootstrap: BootstrapState;
  readonly users: UserState;
  readonly ecoms: EcomsState;
  readonly subscriptions: SubscriptionsState;
  readonly currency: CurrencyState;
  readonly synceeApps: SynceeAppsState;
  readonly favorites: FavoritesState;
  readonly languages: LanguageState;
  readonly snippet: SnippetState;
  readonly retailerToCatalog: RetailerToCatalogState;
  readonly gettingStarted: GettingStartedState;
  readonly tasks: TasksState;
  readonly usage: UsageState;
  readonly countriesLocations: CountriesLocationsState;
}

export const defaultAppState: AppState = {
  productSearch: undefined,
  count: undefined,
  categories: undefined,
  preferences: undefined,
  rcatalogs: undefined,
  authentication: undefined,
  bootstrap: undefined,
  users: undefined,
  ecoms: undefined,
  subscriptions: undefined,
  currency: undefined,
  synceeApps: undefined,
  favorites: undefined,
  languages: undefined,
  snippet: undefined,
  retailerToCatalog: undefined,
  gettingStarted: undefined,
  tasks: undefined,
  usage: undefined,
  countriesLocations: undefined,
};
