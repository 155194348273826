import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpringRestService } from '../rest/microservices/spring-rest.service';
import { Preference } from '../../vo/Preferences/preference';
import { PreferenceType } from '../../vo/Preferences/preference-type';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  private readonly microServiceName = 'productSearch';
  constructor(private restService: SpringRestService) {}

  public getPreferenceTypes(): Observable<PreferenceType[]> {
    return this.restService.get(this.microServiceName, '/PreferenceTypes', undefined, true);
  }

  public getPreference(userId: number): Observable<Preference[]> {
    return this.restService.get(this.microServiceName, `/PreferenceValues`, { userId }, true);
  }

  public postPreferences(preferences: Preference[]): Observable<Preference[]> {
    return this.restService.post(this.microServiceName, `/PreferenceValues`, preferences, undefined, true);
  }

  public deletePreferences(preferenceValueIds: number[]): Observable<number[]> {
    const deletedIds = preferenceValueIds.join();
    return this.restService
      .delete(this.microServiceName, `/PreferenceValues?preferenceValueIds=${deletedIds}`, undefined, true)
      .pipe(map(() => preferenceValueIds));
  }
}
