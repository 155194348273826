import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { GetAssetFromStoragePipe } from '../../pipes/get-asset-from-storage';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-explore-products-advertisement',
  templateUrl: './explore-products-advertisement.component.html',
  styleUrls: ['./explore-products-advertisement.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, FlexLayoutModule, GetAssetFromStoragePipe, TranslateModule, MatButtonModule],
})
export class ExploreProductsAdvertisementComponent implements OnInit {
  public ads: ExploreProductsAdvertisement[] = [
    {
      redirectLink: '/billing/subscription',
      backgroundColor: 'var(--app-syncee-primary-100)',
      imageUrl: 'images/explore-products-ads/upgrade_cards_illustration_pc_01.webp',
      weight: 1,
      titleKey: 'UPSELL.PRODUCT_CARD_ADS.1.TITLE',
      descriptionKey: 'UPSELL.PRODUCT_CARD_ADS.1.DESCRIPTION',
      buttonKey: 'UPSELL.PRODUCT_CARD_ADS.1.BUTTON',
    },
    {
      redirectLink: '/billing/subscription',
      backgroundColor: 'var(--app-ashy-beige-100)',
      imageUrl: 'images/explore-products-ads/upgrade_cards_illustration_pc_02.webp',
      weight: 1,
      titleKey: 'UPSELL.PRODUCT_CARD_ADS.2.TITLE',
      descriptionKey: 'UPSELL.PRODUCT_CARD_ADS.2.DESCRIPTION',
      buttonKey: 'UPSELL.PRODUCT_CARD_ADS.2.BUTTON',
    },
    {
      redirectLink: '/billing/subscription',
      backgroundColor: 'var(--app-lilac-100)',
      imageUrl: 'images/explore-products-ads/upgrade_cards_illustration_pc_03.webp',
      weight: 1,
      titleKey: 'UPSELL.PRODUCT_CARD_ADS.3.TITLE',
      descriptionKey: 'UPSELL.PRODUCT_CARD_ADS.3.DESCRIPTION',
      buttonKey: 'UPSELL.PRODUCT_CARD_ADS.3.BUTTON',
    },
    {
      redirectLink: '/billing/subscription',
      backgroundColor: 'var(--app-light-green-100)',
      imageUrl: 'images/explore-products-ads/upgrade_cards_illustration_pc_04.webp',
      weight: 1,
      titleKey: 'UPSELL.PRODUCT_CARD_ADS.4.TITLE',
      descriptionKey: 'UPSELL.PRODUCT_CARD_ADS.4.DESCRIPTION',
      buttonKey: 'UPSELL.PRODUCT_CARD_ADS.4.BUTTON',
    },
    {
      redirectLink: '/billing/subscription',
      backgroundColor: 'var(--app-syncee-primary-100)',
      imageUrl: 'images/explore-products-ads/upgrade_cards_illustration_pc_05.webp',
      weight: 1,
      titleKey: 'UPSELL.PRODUCT_CARD_ADS.5.TITLE',
      descriptionKey: 'UPSELL.PRODUCT_CARD_ADS.5.DESCRIPTION',
      buttonKey: 'UPSELL.PRODUCT_CARD_ADS.5.BUTTON',
    },
    {
      redirectLink: '/billing/subscription',
      backgroundColor: 'var(--app-turquoise-50)',
      imageUrl: 'images/explore-products-ads/upgrade_cards_illustration_pc_06.webp',
      weight: 1,
      titleKey: 'UPSELL.PRODUCT_CARD_ADS.6.TITLE',
      descriptionKey: 'UPSELL.PRODUCT_CARD_ADS.6.DESCRIPTION',
      buttonKey: 'UPSELL.PRODUCT_CARD_ADS.6.BUTTON',
    },
  ];
  public randomNumber: number;
  isLoggedIn = false;
  ltMd = false;

  private unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>, private screenManagerService: ScreenManagerService) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.randomNumber = this.getIndexForAd(this.ads);
    this.getIsAuthenticated();
    this.subscribeToScreenChange();
  }

  private getIsAuthenticated(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((res) => (this.isLoggedIn = res));
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this.unsubscribeAll), this.screenManagerService.stateMatchesOperator())
      .subscribe((matched) => {
        this.ltMd = matched;
      });
  }

  private sumWeights(): number {
    let sum = 0;
    this.ads.forEach((ad) => {
      sum += ad.weight;
    });
    return sum;
  }

  private getRandomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private getIndexForAd(ads: ExploreProductsAdvertisement[]): number {
    let rand = this.getRandomIntFromInterval(1, this.sumWeights());
    let randomIndex = ads.length - 1;
    for (let i = 0; i < ads.length; i++) {
      rand = rand - ads[i].weight;
      if (rand <= 0) {
        randomIndex = i;
        break;
      }
    }
    return randomIndex;
  }
}

export interface ExploreProductsAdvertisement {
  imageUrl: string;
  redirectLink: string;
  backgroundColor: string;
  weight: number;
  titleKey: string;
  descriptionKey: string;
  buttonKey: string;
}
