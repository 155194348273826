import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-slide-toggle-with-text',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, MatRippleModule, TranslateModule],
  templateUrl: './slide-toggle-with-text.component.html',
  styleUrls: ['./slide-toggle-with-text.component.scss'],
  animations: [
    trigger('isChecked', [
      state('1', style({ left: 'calc(100% - 25px)' })),
      state('0', style({ left: '0' })),
      transition('0 => 1', [animate('150ms ease-in-out')]),
      transition('1 => 0', [animate('150ms ease-in-out')]),
    ]),
  ],
})
export class SlideToggleWithTextComponent implements OnInit {
  isLoading = true;
  @Input() falseText = 'NO';
  @Input() trueText = 'YES';
  @Input() falseBackground = 'var(--app-syncee-primary-700)';
  @Input() trueBackground = 'var(--app-syncee-primary-700)';
  @Input() falseTextColor = 'var(--app-white-500)';
  @Input() trueTextColor = 'var(--app-white-500)';
  @Input() checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    const root = document.querySelector(':root');
    root['style'].setProperty('--false-text string', 'nagds');
    root['style'].setProperty('--true-text', this.trueText);
    this.isLoading = false;
  }
}
