<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div *ngIf="saveMappingWarning" class="notification warning">
    <div class="msg-container">
      <mat-icon class="type-icon">warning</mat-icon>
      <ng-container>
        {{ 'RETAILER_TASK_WIZARD.SHOPIFY.YOU_HAVE' | translate }}
      </ng-container>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
    <div class="fuse-card mapping-container" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" class="m-w-0">
      <form #productDetatailsForm="ngForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
        <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BASIC' | translate }}</div>
        <div class="product-details">
          <div
            *ngIf="mappingData.productIds"
            id="product-id-container"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
          >
            <div class="mr-0">
              <div
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'ID'"
                [adminCommentComments]="comments"
              ></div>
            </div>
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="ID_{{ prod.id }}"
              name="productId_{{ prod.id }}"
              [(ngModel)]="prod.value.chips"
              [(operations)]="prod.value.operations"
              *ngFor="let prod of mappingData.productIds"
              fxFlex="{{ 100 / mappingData.productIds.length }}"
              title="ID"
              placeholder="ID"
            ></app-droppable-input>
          </div>

          <!--&lt;!&ndash;Title&ndash;&gt;-->
          <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
          <!--<app-droppable-input #normalProcess [required]="true"-->
          <!--id="TITLE"-->
          <!--title="Title"-->
          <!--name="TITLE"-->
          <!--[(ngModel)]="mappingData.singleFields.TITLE.chips"-->
          <!--[(operations)]="mappingData.singleFields.TITLE.operations"-->
          <!--name="ngModelTest"-->
          <!--placeholder="Title"></app-droppable-input>-->
          <!--</div>-->

          <!--&lt;!&ndash;Description&ndash;&gt;-->
          <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
          <!--<app-droppable-input #normalProcess-->
          <!--id="DESCRIPTION"-->
          <!--name="DESCRIPTION"-->
          <!--[(ngModel)]="mappingData.singleFields.DESCRIPTION.chips"-->
          <!--[(operations)]="mappingData.singleFields.DESCRIPTION.operations"-->
          <!--title="Description"-->
          <!--[textAreaMode]="true"-->
          <!--placeholder="Description"></app-droppable-input>-->
          <!--</div>-->

          <div id="product-variants-container" fxLayout="column" fxLayoutAlign="start">
            <div id="product-price-and-stuff-container">
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'PRICE'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="PRICE"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRICE_PLACE' | translate }}"
                    name="PRICE"
                    [isPriceSettings]="true"
                    [(ngModel)]="mappingData.singleFields.PRICE.chips"
                    [(settings)]="mappingData.singleFields.PRICE.settings"
                    [(operations)]="mappingData.singleFields.PRICE.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRICE_PLACE' | translate }}"
                  ></app-droppable-input>
                </div>
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'ORIGINAL_PRICE'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="ORIGINAL_PRICE"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COMPARE_PLACE' | translate }}"
                    name="ORIGINAL_PRICE"
                    [isPriceSettings]="true"
                    [(ngModel)]="mappingData.singleFields.ORIGINAL_PRICE.chips"
                    [(settings)]="mappingData.singleFields.ORIGINAL_PRICE.settings"
                    [(operations)]="mappingData.singleFields.ORIGINAL_PRICE.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COMPARE_PLACE' | translate }}"
                  ></app-droppable-input>
                </div>
              </div>

              <div
                id="product-basic-fields"
                fxLayout="row"
                fxLayout.lt-lg="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-lg="0"
              >
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'UPC'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="UPC"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BARCODE_PLACE' | translate }}"
                    name="UPC"
                    [(ngModel)]="mappingData.singleFields.UPC.chips"
                    [(operations)]="mappingData.singleFields.UPC.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BARCODE_PLACE' | translate }}"
                  ></app-droppable-input>
                </div>
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'WEIGHT'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="WEIGHT"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                    name="WEIGHT"
                    [isWeightSettings]="true"
                    [(ngModel)]="mappingData.singleFields.WEIGHT.chips"
                    [(operations)]="mappingData.singleFields.WEIGHT.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                  ></app-droppable-input>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'COST_PER_ITEM'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="COST_PER_ITEM"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COST_PER_ITEM_PLACE' | translate }}"
                    name="COST_PER_ITEM"
                    [isPriceSettings]="true"
                    [(ngModel)]="mappingData.singleFields.COST_PER_ITEM.chips"
                    [(settings)]="mappingData.singleFields.COST_PER_ITEM.settings"
                    [(operations)]="mappingData.singleFields.COST_PER_ITEM.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COST_PER_ITEM_PLACE' | translate }}"
                  ></app-droppable-input>
                </div>
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <div fxLayout="column">
                    <div
                      *ngFor="let qty of mappingData.quantities; let i = index; last as isLast"
                      fxLayout="row"
                      flex="1 0 0"
                      fxLayoutGap="20px"
                    >
                      <div fxFlex="1 0 0">
                        <app-droppable-input
                          adminComment
                          [adminCommentTaskId]="taskId"
                          [adminCommentTaskType]="'import'"
                          [adminCommentStep]="'mapping'"
                          [adminCommentMappingField]="'QTY_' + (i + 1)"
                          [adminCommentComments]="comments"
                          #quantities
                          [id]="'QTY'"
                          title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.INVENTORY_QUANTITY_LOCATION_PLACE' | translate }} - {{
                            i + 1
                          }}"
                          name="QTY_{{ i + 1 }}"
                          [taskId]="taskId"
                          [isLocationSettings]="true"
                          [maxValues]="1"
                          [(ngModel)]="qty.chips"
                          [(settings)]="qty.settings"
                          [(operations)]="qty.operations"
                          placeholder="{{
                            'RETAILER_TASK_WIZARD.SHOPIFY.INVENTORY_QUANTITY_LOCATION_PLACE' | translate
                          }} - {{ i + 1 }}"
                        ></app-droppable-input>
                      </div>
                      <div class="icon-holder" fxLayoutAlign="start center">
                        <button
                          mat-icon-button
                          *ngIf="mappingData.quantities.length > 1"
                          (click)="mappingData.removeQty(i)"
                        >
                          <mat-icon aria-label="Delete icon"> delete </mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewQty()">
                          <mat-icon aria-label="new Image icon"> add_circle_outline </mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<div id="product-images-container" fxLayout="column" fxLayoutAlign="end" fxFlex="1 1 auto">-->
        <!--<div id="product-images-info" class="product-imgs" fxLayout="row" fxLayoutAlign="start"-->
        <!--ngModelGroup="image"-->
        <!--*ngFor="let img of mappingData.images; last as isLast; let i = index">-->

        <!--&lt;!&ndash;Image URL&ndash;&gt;-->
        <!--<app-droppable-input #imagesURLProcess-->
        <!--id="IMAGES"-->
        <!--title="Image URL"-->
        <!--name="IMAGES-{{i}}"-->
        <!--[(ngModel)]="img.url.chips"-->
        <!--[(operations)]="img.url.operations"-->
        <!--[style.width.%]="60"-->
        <!--placeholder="Image URL"></app-droppable-input>-->

        <!--&lt;!&ndash;Image Alt&ndash;&gt;-->
        <!--<app-droppable-input #imagesALTProcess-->
        <!--id="IMAGES_ALTS"-->
        <!--name="IMAGES_ALTS-{{i}}"-->
        <!--[(ngModel)]="img.value.chips"-->
        <!--[(settings)]="img.value.settings"-->
        <!--[(operations)]="img.value.operations"-->
        <!--title="Image Alt"-->
        <!--[style.width.%]="30"-->
        <!--placeholder="Image Alt"></app-droppable-input>-->

        <!--<div class="icon-holder" fxLayoutAlign="start center">-->
        <!--<button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeProdImage(i)">-->
        <!--<mat-icon aria-label="Delete icon" [style.width.%]="5">-->
        <!--delete-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--<button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProdImage()">-->
        <!--<mat-icon aria-label="new Image icon" [style.width.%]="5">-->
        <!--add_circle_outline-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="h2 mb-24">Organization Fields</div>-->
        <!--<div id="product-categories" fxLayout="column" fxLayoutAlign="start stretch">-->

        <!--&lt;!&ndash;Product type&ndash;&gt;-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="PRODUCT_TYPE"-->
        <!--name="PRODUCT_TYPE"-->
        <!--[(ngModel)]="mappingData.singleFields.PRODUCT_TYPE.chips"-->
        <!--[(operations)]="mappingData.singleFields.PRODUCT_TYPE.operations"-->
        <!--title="Product Type"-->
        <!--placeholder="Product Type" ></app-droppable-input>-->

        <!--&lt;!&ndash;Tags&ndash;&gt;-->
        <!--<div id="product-tags" fxLayout="row" fxLayoutAlign="start"-->
        <!--*ngFor="let tag of mappingData.tags; last as isLast; let i = index">-->
        <!--<app-droppable-input #tagsProcess-->
        <!--id="TAGS"-->
        <!--title="Tag"-->
        <!--name="TAGS-{{i}}"-->
        <!--[(ngModel)]="tag.chips"-->
        <!--[(operations)]="tag.operations"-->
        <!--[style.width.%]="90"-->
        <!--placeholder="Tag"></app-droppable-input>-->
        <!--<div class="icon-holder" fxLayoutAlign="start center">-->
        <!--<button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeTag(i)">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--delete-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--<button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewTag()">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--add_circle_outline-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->

        <!--&lt;!&ndash;Vendor&ndash;&gt;-->
        <!--<div id="product-brands" fxLayout="row" fxLayoutAlign="start">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="BRAND"-->
        <!--title="Vendor"-->
        <!--name="BRAND"-->
        <!--[(ngModel)]="mappingData.singleFields.BRAND.chips"-->
        <!--[(operations)]="mappingData.singleFields.BRAND.operations"-->
        <!--[style.width.%]="100"-->
        <!--placeholder="Vendor"></app-droppable-input>-->
        <!--</div>-->

        <!--&lt;!&ndash;Collections&ndash;&gt;-->
        <!--<div id="product-collections" fxLayout="row" fxLayoutAlign="start"-->
        <!--*ngFor="let collection of mappingData.collections; last as isLast; let i = index">-->
        <!--<app-droppable-input #collectionsProcess-->
        <!--id="COLLECTIONS"-->
        <!--title="Collection"-->
        <!--name="COLLECTIONS-{{i}}"-->
        <!--[(ngModel)]="collection.chips"-->
        <!--[(operations)]="collection.operations"-->
        <!--[style.width.%]="90"-->
        <!--placeholder="Collection"></app-droppable-input>-->
        <!--<div class="icon-holder" fxLayoutAlign="start center">-->
        <!--<button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeCollection(i)">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--delete-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--<button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewCollection()">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--add_circle_outline-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->

        <!--</div>-->

        <app-custom-divider [margin]="'32px'"></app-custom-divider>

        <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT' | translate }}</div>

        <!--<div fxLayout="row" fxLayoutAlign="start">-->

        <!--&lt;!&ndash;Image URL&ndash;&gt;-->
        <!--<app-droppable-input #imagesURLProcess-->
        <!--id="V_IMAGES"-->
        <!--title="Image URL"-->
        <!--name="V_IMAGES"-->
        <!--[(ngModel)]="mappingData.singleFields.V_IMAGES.chips"-->
        <!--[(operations)]="mappingData.singleFields.V_IMAGES.operations"-->
        <!--[style.width.%]="50"-->
        <!--placeholder="Image URL"></app-droppable-input>-->

        <!--&lt;!&ndash;Image Alt&ndash;&gt;-->
        <!--<app-droppable-input #imagesALTProcess-->
        <!--id="V_IMAGES_ALTS"-->
        <!--name="V_IMAGES_ALTS"-->
        <!--[(ngModel)]="mappingData.singleFields.V_IMAGES_ALTS.chips"-->
        <!--[(operations)]="mappingData.singleFields.V_IMAGES_ALTS.operations"-->
        <!--title="Image Alt"-->
        <!--[style.width.%]="50"-->
        <!--placeholder="Image Alt"></app-droppable-input>-->

        <!--</div>-->

        <!--Variant SKU-->
        <div fxLayout="column" fxLayoutAlign="start stretch" class="mb-0">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SKU'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SKU"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_SKU_PLACE' | translate }}"
            name="SKU"
            [(ngModel)]="mappingData.singleFields.SKU.chips"
            [(operations)]="mappingData.singleFields.SKU.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_SKU_PLACE' | translate }}"
          ></app-droppable-input>
        </div>

        <form
          #shopifyOptionsForm="ngForm"
          fxLayout="column"
          class="options-container"
          fxLayoutAlign="start"
          fxLayoutGap="8px"
        >
          <div id="shopify-options-1" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
            <div fxFlex="3 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_1'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_NAME_1"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
                name="SHOPIFY_OPTION_NAME_1"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
            <div fxFlex="7 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_1'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_VALUE_1"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_1_PLACE' | translate }}"
                name="SHOPIFY_OPTION_VALUE_1"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_1_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
          </div>
          <div id="shopify-options-2" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
            <div fxFlex="3 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_2'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_NAME_2"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_2_PLACE' | translate }}"
                name="SHOPIFY_OPTION_NAME_2"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_2_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
            <div fxFlex="7 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_2'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_VALUE_2"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_2_PLACE' | translate }}"
                name="SHOPIFY_OPTION_VALUE_2"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_2_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
          </div>
          <div id="shopify-options-3" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
            <div fxFlex="3 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_3'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_NAME_3"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_3_PLACE' | translate }}"
                name="SHOPIFY_OPTION_NAME_3"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_3_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
            <div fxFlex="7 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_3'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_VALUE_3"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_3_PLACE' | translate }}"
                name="SHOPIFY_OPTION_VALUE_3"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_3_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
          </div>
        </form>

        <!--<div class="h2 mb-24">SEO Fields</div>-->

        <!--&lt;!&ndash;Handle&ndash;&gt;-->
        <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="SHOPIFY_HANDLE"-->
        <!--title="Handle"-->
        <!--name="SHOPIFY_HANDLE"-->
        <!--[(ngModel)]="mappingData.singleFields.SHOPIFY_HANDLE.chips"-->
        <!--[(operations)]="mappingData.singleFields.SHOPIFY_HANDLE.operations"-->
        <!--placeholder="Handle"></app-droppable-input>-->
        <!--</div>-->

        <!--&lt;!&ndash;Page title (website seo)&ndash;&gt;-->
        <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"-->
        <!--title="Page title"-->
        <!--name="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"-->
        <!--[(ngModel)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.chips"-->
        <!--[(operations)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.operations"-->
        <!--placeholder="Page title"></app-droppable-input>-->
        <!--</div>-->

        <!--&lt;!&ndash;Meta Description (website seo)&ndash;&gt;-->
        <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="SHOPIFY_META_DESC"-->
        <!--title="Meta Description"-->
        <!--name="SHOPIFY_META_DESC"-->
        <!--[(ngModel)]="mappingData.singleFields.SHOPIFY_META_DESC.chips"-->
        <!--[(operations)]="mappingData.singleFields.SHOPIFY_META_DESC.operations"-->
        <!--[textAreaMode]="true"-->
        <!--placeholder="Meta Description"></app-droppable-input>-->
        <!--</div>-->
      </form>
    </div>
  </div>
</div>
<button mat-button class="syncee-black-flat-button mt-16" (click)="takonySave()" *ngIf="isAdmin">
  <mat-icon class="type-icon">visibility_off</mat-icon>{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SAVE' | translate }}
</button>
