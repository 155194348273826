import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardComponent } from './onboard.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { OnboardDialogComponent } from './onboard-dialog/onboard-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SetupGuideModule } from '../../../main/setup-guide/setup-guide.module';
import { CustomDialogWrapperComponent } from '../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { ReviewBadgesComponent } from '../../../shared/components/review-badges/review-badges.component';
import { OverflowDirective } from '../../../shared/directives/overflow.directive';
import { InfoStripComponent } from '../../../shared/components/info-strip/info-strip.component';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizeHtmlPipe } from 'app/shared/pipes/sanitize-html.pipe';

@NgModule({
  declarations: [OnboardComponent, OnboardDialogComponent],
  imports: [
    CommonModule,
    // Material
    MatCardModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    // Fuse
    FuseSharedModule,
    RouterModule,
    // app
    SetupGuideModule,
    CustomDialogWrapperComponent,
    ReviewBadgesComponent,
    OverflowDirective,
    InfoStripComponent,
    TranslateModule,
    SanitizeHtmlPipe,
  ],
  exports: [OnboardComponent],
})
export class OnboardModule {}
