import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { Utils } from 'app/utils/utils';
import { Observable } from 'rxjs';
import { filter as rxFilter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { SetCatalogsAction, SetSelectedCatalogAction } from '../../store/rcatalogs/rcatalogs.action';
import { GetRetailerToCatalogStartAction } from '../../store/retailer-to-catalog/retailer-to-catalog.actions';
import { CATALOG_PRODUCT_LIMIT_STARTER } from '../../utils/Constants';
import { Catalog } from '../../vo/catalog';
import { SearchProductVO } from '../../vo/search-product-vo';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { RcatalogService } from '../rcatalog/rcatalog.service';
import { RestService } from '../rest/rest.service';
import { GtmManagerService } from '../google-tag-manager/gtm-manager.service';
import { CatalogCreateAction } from '../google-tag-manager/actions/catalog_create';
import { CatalogTypeType } from '../google-tag-manager/model/variables/catalog.variables';

@Injectable({
  providedIn: 'root',
})
export class CatalogSidebarService {
  selectedCatalogId: number;
  catalogList: any[];
  selectedProductId: string;
  hasStore: boolean;
  usedCatalogList: CatalogApproveStatus[];

  // OLD_TODO: termék catalógushoz hozzáadás/eltávolítását kezelni tömbként!
  newProductAdded: EventEmitter<CatalogChangeEventHandleVO> = new EventEmitter<CatalogChangeEventHandleVO>();
  removedFromCatalog: EventEmitter<CatalogChangeEventHandleVO> = new EventEmitter<CatalogChangeEventHandleVO>();

  constructor(
    private restService: RestService,
    private marketplaceEcomService: MarketplaceEcomService,
    private rcatalogService: RcatalogService,
    private store: Store<AppState>,
    private gtmManagerService: GtmManagerService
  ) {}

  public getRetailerCatalogs(ecomId: number): Observable<Catalog[]> {
    return this.restService.get(`RetailerCatalogService/getRetailersCatalogs?ecomId=${ecomId}`).pipe(
      map((response) => {
        this.catalogList = response.getData(); // OLD_TODO: remove catalogList
        return response.getData();
      })
    );
  }

  public getProductNumberOfCatalog(selectedCatalogId?, cataloglist?): Observable<any> {
    // return new Observable<any>( observe => {
    //     observe.next(true);
    //     observe.complete();
    // });
    const catalogId = selectedCatalogId ? selectedCatalogId : this.selectedCatalogId;
    const filter = cataloglist
      ? cataloglist.find((elem) => elem.id === catalogId).filter
      : this.catalogList.find((elem) => elem.id === catalogId).filter;
    if (this.marketplaceEcomService.checkForStarterPlan() && filter[0].values.length >= CATALOG_PRODUCT_LIMIT_STARTER) {
      return new Observable<any>((observe) => {
        observe.error({
          limitError: 'maxStarter',
        });
        observe.complete();
      });
    } else {
      return new Observable<any>((observe) => {
        observe.next(true);
        observe.complete();
      });
    }
  }

  public getProductsOfCatalog(catalogId: number, size: number = 10, from: number = 0): Observable<SearchProductVO[]> {
    const urlBase = 'RetailerCatalogService/getProductsOfCatalog';
    return this.restService
      .get<CatalogProducts>(`${urlBase}?catalogId=${catalogId}&size=${size}&from=${from}`)
      .pipe(map((response) => response.getFirstData().products));
  }

  public removeProductFromCatalog(catalogId: number, productIds: string[]): Observable<any> {
    return this.restService.post('RetailerCatalogService/productFilterManager', {
      catalogId: catalogId,
      add: false,
      ids: productIds,
    });
  }

  public addProductToCatalog(productId: string, catalogId?: number): Observable<any> {
    return this.restService.post('RetailerCatalogService/productFilterManager', {
      catalogId: catalogId || this.rcatalogService.getSelectedCatalog().id,
      add: true,
      ids: [productId],
    });
  }

  public addBulkProductsToCatalog(productIds: string[], catalogId?: number): Observable<any> {
    return this.restService.post('RetailerCatalogService/productFilterManager', {
      catalogId: catalogId || this.rcatalogService.getSelectedCatalog().id,
      add: true,
      ids: productIds,
    });
  }

  public openSidebar(): void {
    // const catalogSidebar = document.getElementById('catalog-sidebar-open');
    // catalogSidebar.click();
  }

  // TODO: bootstrap it
  public getRetailerToCatalogList(): void {
    this.getRetailerToCatalogForRetailer().subscribe((res) => {
      this.usedCatalogList = res;
    });
  }

  public getRetailerToCatalogForRetailer(): Observable<CatalogApproveStatus[]> {
    return this.store.select(isAuthenticatedSelector).pipe(
      rxFilter((auth) => !!auth),
      take(1),
      switchMap(() =>
        this.restService
          .get(`RetailerToCatalogService/getRetailerToCatalogForRetailer`)
          .pipe(map((response) => response.getData()))
      )
    );
  }

  // public addRetailerToCatalog(catalogId?: number): Observable<any> {
  //     return this.restService.post('RetailerCatalogService/addRetailerToCatalog', {
  //         catalogId: catalogId
  //     }).pipe(map(response => response.getFirstData()));
  // }

  public addRetailerToCatalogWithRest(rest): Observable<any> {
    return this.restService
      .post('RetailerCatalogService/addRetailerToCatalog', rest)
      .pipe(map((response) => response.getFirstData()));
  }

  public createCatalog(name: string, ecomId, margin, filter, excludeUpdateFields?, isAlibaba?): Observable<any> {
    const body = {
      name: name,
      ecomId: ecomId,
      margin: margin,
      filter: filter,
    };
    if (excludeUpdateFields && excludeUpdateFields.length > 0) {
      body['excludeUpdateFields'] = excludeUpdateFields;
    }
    if (isAlibaba) {
      body['alibabaCatalog'] = true;
    }
    return this.restService.post('RetailerCatalogService/add', body).pipe(
      tap(() => {
        this.rcatalogService.fetchCatalogs().subscribe((catalogs) => {
          this.store.dispatch(new SetCatalogsAction(catalogs));
        });
      }),
      tap((response) =>
        this.sendCatalogCreateActionIntoGTM(isAlibaba ? 'alibaba' : 'selection', response.getFirstData().id)
      ),
      map((response) => {
        this.store.dispatch(new SetSelectedCatalogAction(response.getFirstData()));
        return response.getFirstData();
      })
    );
  }

  public createEmptyCatalog(
    ecomId: number,
    margin: number,
    excludeUpdateFields?: string[],
    isAlibaba?: boolean,
    name = 'Default Import List'
  ): Observable<void> {
    const emptyFilter = [{ key: 'ID', condition: 'equals', values: [] }];
    return this.createCatalog(name, ecomId, margin, emptyFilter, excludeUpdateFields, isAlibaba);
  }

  public updateRetailerToCatalogList(updateList): void {
    this.usedCatalogList.push(updateList);
    // we need to dispatch the GetRetailerToCatalogStartAction in order to update the retailer to catalog connection in the store too
    this.store.dispatch(new GetRetailerToCatalogStartAction());
  }

  public catalogStatusCheck(catalogID): CatalogApproveStatus {
    if (!Utils.isNullOrUndefined(catalogID)) {
      return !Utils.isNullOrUndefined(this.usedCatalogList)
        ? this.usedCatalogList.find((element) => element.catalogId === catalogID.toString())
        : null;
    } else {
      return null;
    }
  }

  public clearAll(): void {
    this.selectedCatalogId = null;
    this.catalogList = null;
    this.selectedProductId = null;
    this.hasStore = null;
    this.usedCatalogList = [];
  }

  public addRetailerToCatalogCheck(supplierCatalogID): Observable<boolean> {
    return new Observable<boolean>((observe) => {
      if (
        Utils.isNullOrUndefined(
          this.usedCatalogList.find((element) => element.catalogId.toString() === supplierCatalogID.toString())
        )
      ) {
        this.addRetailerToCatalogWithRest({ catalogId: supplierCatalogID }).subscribe(
          (res) => {
            this.updateRetailerToCatalogList({
              approved: res.approved,
              catalogId: res.catalog.id,
            });
            observe.next(true);
            observe.complete();
          },
          (error) => {
            console.log(error);
            observe.error(error);
          }
        );
      } else {
        observe.next(true);
        observe.complete();
      }
    });
  }

  private sendCatalogCreateActionIntoGTM(catalogTypeGtm: CatalogTypeType, catalogId: number): void {
    this.gtmManagerService.pushTag(
      new CatalogCreateAction({
        catalog_type: catalogTypeGtm,
        catalog_id: catalogId,
      })
    );
  }
}

export class CatalogChangeEventHandleVO {
  productId: string;
  catalogId: string;
  catalogName: string;
}

export interface CatalogApproveStatus {
  approved: '1' | '0' | '-1';
  catalogId: string;
  message: string;
}

export interface CatalogProducts {
  aggregations: any[];
  matches: number;
  products: SearchProductVO[];
  query: CatalogProductsQuery;
  result: number;
  tasks: number[];
  took: number;
  totalRows: number;
  users: number[];
}

interface CatalogProductsQuery {
  from: number;
  size: number;
}
