import { Action } from '@ngrx/store';
import { CatalogApproveStatus } from '../../service/catalog-sidebar/catalog-sidebar.service';

export enum RetailerToCatalogActionTypes {
  GET_RETAILER_TO_CATALOG_START = '[RetailerToCatalogs] Get Retailer to Catalog Start',
  GET_RETAILER_TO_CATALOG_SUCCESS = '[RetailerToCatalogs] Get Retailer to Catalog Success',
}

export class GetRetailerToCatalogStartAction implements Action {
  readonly type = RetailerToCatalogActionTypes.GET_RETAILER_TO_CATALOG_START;
}

export class GetRetailerToCatalogSuccessAction implements Action {
  readonly type = RetailerToCatalogActionTypes.GET_RETAILER_TO_CATALOG_SUCCESS;

  constructor(public payload: CatalogApproveStatus[]) {}
}

export type ActionsUnion = GetRetailerToCatalogStartAction | GetRetailerToCatalogSuccessAction;
