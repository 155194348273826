import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { IndexTableFilterComponent } from '../index-table-filter/index-table-filter.component';
import { fuseAnimations } from '@fuse/animations';
import { IndexTableDatePickerComponent } from '../index-table-filter/index-table-date-picker/index-table-date-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-filter-factory',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    IndexTableFilterComponent,
    IndexTableDatePickerComponent,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './filter-factory.component.html',
  styleUrls: ['./filter-factory.component.scss'],
  animations: [fuseAnimations],
})
export class FilterFactoryComponent {
  @Input() filterArray: IndexTableFilter<any>[];
  @Input() openFilters: boolean;
  @Input() activeFilters: TableFilterItem[];
  @Input() enableDatePicker = false;
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() loading: boolean;
  @Output() filterChange: EventEmitter<TableFilterItem> = new EventEmitter<TableFilterItem>();
  @Output() clearAllClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() {}

  findActiveFilterByType(filterType: string): TableFilterItem {
    return this.activeFilters.find((filter) => filter.type === filterType);
  }
}

export interface IndexTableFilter<T> {
  type: string;
  labelKey: string;
  choices: Choice<T>[];
  static?: boolean;
  multi?: boolean;
  radio?: boolean;
  hasSearch?: boolean;
  fetcher?: (from: number, size: number, searchTerm?: string) => Observable<Partial<T[]>>;
  size?: number;
  choiceLabelProperty?: string;
}

export interface Choice<T> {
  label: string;
  value: T;
  translate?: boolean;
  hasBadge?: boolean;
  badgeLabelKey?: string;
  badgeColorKey?: string;
}

export interface TableFilterItem {
  value: any;
  label: string;
  type: string;
}

export interface TableViewItem {
  labelKey: string;
  filters: TableFilterItem[];
  translate?: boolean;
}
