<ng-container [ngSwitch]="config.type">
  <ng-container *ngSwitchCase="'inner_redirect'">
    <a [href]="config.url" [routerLink]="config.url" [queryParams]="config.queryParams">
      <button
        mat-button
        (mouseenter)="hovered = true"
        (mouseleave)="hovered = false"
        [ngClass]="config.buttonClass"
        [disableRipple]="config?.style?.disableRipple"
      >
        {{ config.textKey | translate }}
      </button>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'external_redirect'">
    <a target="_blank" [href]="config.url">
      <button
        mat-button
        (mouseenter)="hovered = true"
        (mouseleave)="hovered = false"
        [ngClass]="config.buttonClass"
        [disableRipple]="config?.style?.disableRipple"
      >
        {{ config.textKey | translate }}
      </button>
    </a>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <button
      mat-button
      (mouseenter)="hovered = true"
      (mouseleave)="hovered = false"
      [ngClass]="config.buttonClass"
      [disableRipple]="config?.style?.disableRipple"
      (click)="generalButtonService.onClick(config)"
    >
      {{ config.textKey | translate }}
    </button>
  </ng-container>
</ng-container>
