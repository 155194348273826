import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SearchbarCustomComponent } from '../searchbar-custom/searchbar-custom.component';
import { FlexModule } from '@angular/flex-layout';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { CategoryData } from '../wizard-category-mapping/model/category-mapping-data';
import { MatTreeCategoriesComponent } from '../mat-tree-categories/mat-tree-categories';
import { CategoryVo } from '../../../vo/category-vo';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { flattenCategoriesSelector } from '../../../store/category/category.selector';
import { filter } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryImageFromId } from '../../pipes/category-image-from-id';
import { CategoryNameByIdPipe } from '../../pipes/category-name-by-id.pipe';

@Component({
  selector: 'app-category-selector-dialog',
  templateUrl: './category-selector-dialog.component.html',
  styleUrls: ['./category-selector-dialog.component.scss'],
  standalone: true,
  imports: [
    SearchbarCustomComponent,
    FlexModule,
    MatCheckboxModule,
    MatButtonModule,
    NgForOf,
    NgIf,
    MatDialogModule,
    MatTreeCategoriesComponent,
    TranslateModule,
    CategoryImageFromId,
    CategoryNameByIdPipe,
  ],
})
export class CategorySelectorDialogComponent implements OnInit {
  selectedCategories: CategoryData[] = [];
  variant: CategorySelectorComponentVariant;
  synceeCategories: CategoryVo[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CategorySelectorDialogComponentData,
    private dialogRef: MatDialogRef<CategorySelectorDialogComponent, CategoryData[]>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.variant = this.data.variant ? this.data.variant : 'default';
    this.selectedCategories = this.data.selectedCategories ?? [];
    if (this.variant === 'synceeCategoryTree') {
      this.getSynceeCategories();
    }
  }

  handleCategorySelect(category: CategoryData, event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories = this.selectedCategories.filter((item) => item.id !== category.id);
    }
  }

  private getSynceeCategories(): void {
    this.store
      .select(flattenCategoriesSelector)
      .pipe(filter((value) => !!value))
      .subscribe((value) => {
        this.synceeCategories = value;
      });
  }

  synceeCategoryTreeValueChange(value: string[]): void {
    const data: CategoryData[] = [];
    this.synceeCategories.forEach((item) => {
      if (value.includes(String(item.id))) {
        data.push({ id: String(item.id), name: item.name });
      }
    });
    this.selectedCategories = data;
  }
  get selectedCategoryIds(): string[] {
    return this.selectedCategories.map((cat) => cat.id);
  }

  isSelected(category: CategoryData): boolean {
    return this.selectedCategoryIds.includes(category.id);
  }
}

export interface CategorySelectorDialogComponentData {
  title?: string;
  instruction?: string;
  hasSearchBar?: boolean;
  categories?: CategoryData[];
  selectedCategories?: CategoryData[];
  variant?: CategorySelectorComponentVariant;
}

export type CategorySelectorComponentVariant = 'default' | 'synceeCategoryTree';
