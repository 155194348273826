import { Action } from '@ngrx/store';
import { ProductSearchHelper } from './product-search.reducer';
import { MarketplaceFilter, MarketplaceFilterPagination } from '../../vo/search-product-vo';
import { MarketplaceView } from '../../main/marketplace/explore-products/marketplace-view-selector/marketplace-view-selector.component';

export enum ActionTypes {
  setTemporaryFilter = 'setTemporaryFilter',
  clearTemporaryFilter = 'clearTemporaryFilter',
  addFilter = 'addFilter',
  setFilter = 'setFilter',
  addFilterWithRedirect = 'addFilterWithRedirect',
  clearFilter = 'clearFilter',
  setHelper = 'setHelper',
  setAdvancedLanguages = 'setAdvancedLanguages',
  setPaginator = 'setPaginator',
  increasePaginator = 'increasePaginator',
  decreasePaginator = 'decreasePaginator',
  clearPaginator = 'clearPaginator',
  setSearchType = 'setSearchType',
}

export class SetTemporaryFilter implements Action {
  readonly type = ActionTypes.setTemporaryFilter;

  constructor(public payload: { value: MarketplaceFilter }) {}
}

export class AddFilter implements Action {
  readonly type = ActionTypes.addFilter;

  constructor(public payload: { value: MarketplaceFilter }) {}
}

export class SetFilterAction implements Action {
  readonly type = ActionTypes.setFilter;

  constructor(public payload: SetFilterActionPayload) {}
}

export class ClearFilter implements Action {
  readonly type = ActionTypes.clearFilter;
}

export class ClearTemporaryFilter implements Action {
  readonly type = ActionTypes.clearTemporaryFilter;
}

export class SetHelperAction implements Action {
  readonly type = ActionTypes.setHelper;

  constructor(public payload: Partial<ProductSearchHelper>) {}
}

export class SetAdvancedLanguagesAction implements Action {
  readonly type = ActionTypes.setAdvancedLanguages;

  constructor(public payload: string[]) {}
}

export class SetPaginator implements Action {
  readonly type = ActionTypes.setPaginator;

  constructor(public payload: MarketplaceFilterPagination) {}
}

export class IncreasePaginator implements Action {
  readonly type = ActionTypes.increasePaginator;

  constructor() {}
}

export class DecreasePaginator implements Action {
  readonly type = ActionTypes.decreasePaginator;

  constructor() {}
}

export class ClearPaginator implements Action {
  readonly type = ActionTypes.clearPaginator;

  constructor() {}
}

export class SetSearchType implements Action {
  readonly type = ActionTypes.setSearchType;
  constructor(public payload: { value: MarketplaceView }) {}
}

export class AddFilterWithRedirectAction implements Action {
  readonly type = ActionTypes.addFilterWithRedirect;

  constructor(public payload: AddFilterWithRedirectActionPayload) {}
}

export type ActionsUnion =
  | SetTemporaryFilter
  | ClearTemporaryFilter
  | AddFilter
  | SetFilterAction
  | ClearFilter
  | SetHelperAction
  | SetAdvancedLanguagesAction
  | SetPaginator
  | IncreasePaginator
  | DecreasePaginator
  | ClearPaginator
  | AddFilterWithRedirectAction
  | SetSearchType;

interface AddFilterWithRedirectActionPayload {
  filter: MarketplaceFilter;
  needRedirect: boolean;
  skipLocationChange?: boolean;
}

interface SetFilterActionPayload {
  filter: MarketplaceFilter;
  skipLocationChange?: boolean;
}
