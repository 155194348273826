<div *ngIf="currUser.id" class="chat-container" fxLayout="column" fxFlex="1 0 0">
  <div class="chat-wrapper" [class.error]="channelError" fxLayout="row">
    <ng-container *ngIf="!channelError; else errorMessage">
      <ng-container *ngIf="!options.hideChannelSide">
        <div
          *ngIf="(isMobile && !currChannelUser) || !isMobile"
          class="channel-side"
          fxFlex="0 0 320px"
          fxFlex.lt-md="1 0 0"
          fxLayout="column"
          fxLayoutGap="20px"
        >
          <ng-container *ngIf="isMobile && showActiveChatPanel">
            <div class="p-20" fxLayout="row" fxLayoutAlign="flex-end center">
              <button (click)="chatHandler.closeAppDialog()" mat-icon-button>
                <mat-icon class="s-20">close</mat-icon>
              </button>
            </div>
          </ng-container>
          <div class="search-panel-wrapper" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
            <div class="search-bar-wrapper" fxFlex="1 0 0">
              <app-chat-search-bar
                [searchTerm]="chatHandler.searchTerm | async"
                (search)="search($event)"
                (focus)="handleSearchFocus()"
                (click)="$event.stopPropagation()"
              ></app-chat-search-bar>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="4px"
              fxLayoutAlign="start center"
              class="cursor-pointer"
              [class.primary-700-fg]="!!(chatHandler.unseenFilter | async)"
              *ngIf="showActiveChatPanel"
              (click)="chatHandler.toggleUnseenFilter()"
              [matTooltip]="'CHAT.UNREAD_TOOLTIP' | translate"
            >
              <mat-icon class="s-20">mark_chat_unread</mat-icon>
              <div>
                {{ 'CHAT.UNREAD' | translate }}
              </div>
            </div>
            <button
              mat-icon-button
              class="show-chat-button"
              *ngIf="!showActiveChatPanel"
              (click)="switchBackToActiveChatsPanel()"
              [matTooltip]="'CHAT.SHOW_ACTIVE_CHATS' | translate"
            >
              <mat-icon class="s-20">{{ isMobile ? 'close' : 'chat_bubble_outline' }}</mat-icon>
            </button>
          </div>
          <ng-container>
            <app-chat-search-panel
              fxFlex="1 0 0"
              *ngIf="!showActiveChatPanel"
              (supplierClicked)="recipientClickHandler($event)"
              (channelClicked)="channelClickHandler($event)"
              [searchTermObs]="searchTermObs"
              [channels]="channels"
              [channelsLoading]="channelsLoading"
              [hasLoadMore]="hasLoadMore | async"
              (click)="handleSearchFocus()"
              (loadMoreClicked)="handleLoadMore()"
              [selectedChannel]="selectedChannel"
            ></app-chat-search-panel>
            <app-chat-active-panel
              fxFlex="1 0 0"
              fxLayout="column"
              *ngIf="!!showActiveChatPanel"
              (channelClicked)="channelClickHandler($event)"
              [options]="options"
              [channels]="channels"
              [channelsLoading]="channelsLoading"
              [channelsError]="channelError"
              [selectedChannel]="selectedChannel"
              [hasLoadMore]="hasLoadMore | async"
              (loadMoreClicked)="handleLoadMore()"
              [hasFiltered]="!!(chatHandler.unseenFilter | async) || !!(chatHandler.searchTerm | async)"
            >
            </app-chat-active-panel>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!options.hideDirectMessageSide">
        <ng-container *ngIf="(isMobile && currChannelUser) || !isMobile">
          <div class="chat-body-side">
            <ng-container *ngIf="!!channels">
              <ng-container *ngIf="!!channels.length || !!selectedChannel; else noChatsMessage">
                <app-chat-body
                  *ngIf="currChannelUser"
                  [isInnerChat]="options.innerChat"
                  [currUserId]="currChannelUser.id"
                  [channel]="selectedChannel"
                  [channelSideHidden]="options.hideChannelSide"
                  (backClick)="currChannelUser = null"
                  (sentNewMessage)="resetChannels()"
                >
                </app-chat-body>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noChatsMessage>
  <div style="width: 100%; height: 100%" class="centered">
    <app-chat-no-messages-yet></app-chat-no-messages-yet>
  </div>
</ng-template>

<ng-template #errorMessage>
  <app-custom-dialog-wrapper [closeWithRedirect]="true" [hideCloseBtn]="!isMobile">
    <div fxFlex="1 0 0" class="centered">
      <app-error-message></app-error-message>
    </div>
  </app-custom-dialog-wrapper>
</ng-template>
