import { AfterViewInit, Component, inject, TemplateRef, ViewChild } from '@angular/core';

import { MySuppliersCacheService } from '../service/my-suppliers-cache.service';
import { MySuppliersPageService } from '../service/my-suppliers-page.service';

import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';

import { Router } from '@angular/router';
import type { MySupplierData } from 'app/vo/my-suppliers/my-supplier-vo';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { CommonNavigation } from '../../../../navigation/navigation-routes/common/common.navigation';
import { ReferralCampaignType } from '../../../referrals/model/referral-campaign-type';
import { ApproveStatusParamEnum } from '../model/approve-status-param';
import { MySuppliersFilterService } from '../service/my-suppliers-filter.service';
import { MySuppliersHelperService } from '../service/my-suppliers-helper.service';

@Component({
  selector: 'app-my-suppliers',
  templateUrl: './my-suppliers.component.html',
  styleUrls: ['./my-suppliers.component.scss'],
  providers: [MySuppliersHelperService, MySuppliersFilterService, MySuppliersPageService, MySuppliersCacheService],
})
export class MySuppliersComponent implements AfterViewInit {
  @ViewChild('inviteButton') topRightTemplate: TemplateRef<any>;
  public hasError = false;

  public currentSearchTerm = '';

  public noSearchResults = false;

  public hasAccessToChat = false;

  public hasAccessToReadContacts = false;

  public supplierData: MySupplierData[];

  public readonly MARKETPLACE_URL = MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH;

  private router: Router = inject<Router>(Router);

  constructor(
    public pageService: MySuppliersPageService,
    public cacheService: MySuppliersCacheService,
    public filterService: MySuppliersFilterService,
    private fuseConfigService: FuseConfigService
  ) {}

  ngAfterViewInit(): void {
    this.initTheme();
  }

  /* 
    private getAuthorizations(): void {
      combineLatest([this.mySuppliersPageService.isAdmin, this.mySuppliersPageService.selectedEcom])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([isAdmin, selectedEcom]) => {
          this.selectedEcom = selectedEcom;
          if (selectedEcom.subscriptions) {
            this.hasAccessToChat = this.mySuppliersPageService.hasAccessToChat(selectedEcom, isAdmin);
            this.hasAccessToReadContacts = this.mySuppliersPageService.hasAccessToContacts(selectedEcom, isAdmin);
          }
        });
    }
  */

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: {
        title: 'MY_SUPPLIERS.TITLE',
        contentContainer: 'full-width',
      },
      templates: { topRightContent: this.topRightTemplate },
    };
  }

  public handleSearchChange(value: string): void {
    this.pageService.page$.next(0);
    this.filterService.setSearchTerm(value);
  }

  public handleFilterChange(value: ApproveStatusParamEnum): void {
    this.pageService.page$.next(0);
    this.filterService.setApprovedStatusFilter(value);
  }

  public handleInviteButtonClick(): void {
    this.router.navigate([CommonNavigation.REFERRALS, ReferralCampaignType.REFER_SUPPLIER]);
  }
}
