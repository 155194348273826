import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ReviewBadgesComponent } from '../../../../shared/components/review-badges/review-badges.component';
import { CustomDialogWrapperComponent } from '../../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-select-platform',
  templateUrl: './select-platform.component.html',
  styleUrls: ['./select-platform.component.scss'],
  standalone: true,
  imports: [FlexModule, TranslateModule, ReviewBadgesComponent, CustomDialogWrapperComponent],
})
export class SelectPlatformComponent implements OnInit {
  @Output() platformClick = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}
}
