import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { selectedCurrencySelector } from 'app/store/currency/currency.selector';
import { Observable, switchMap } from 'rxjs';
import { CurrencyExchangeToPipe } from './currency-exchange-to.pipe';

@Pipe({
  name: 'currencyExchangeToSelected',
  standalone: true,
})
export class CurrencyExchangeToSelectedPipe implements PipeTransform {
  constructor(private store: Store<AppState>, private currencyExchangeToPipe: CurrencyExchangeToPipe) {}

  transform(value: number, from: string): Observable<number> {
    return this.store
      .select(selectedCurrencySelector)
      .pipe(switchMap((selectedCurrency) => this.currencyExchangeToPipe.transform(value, from, selectedCurrency)));
  }
}
