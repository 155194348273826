import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { map, Observable } from 'rxjs';
import { AppState } from '../../../../../app.state';
import { getUserRoleSelector } from '../../../../../store/user/user.selector';
import { RolesEnum } from '../../../../../vo/roles/roles';

@Component({
  selector: 'app-chat-no-messages-yet',
  templateUrl: './chat-no-messages-yet.component.html',
  styleUrls: ['./chat-no-messages-yet.component.scss'],
})
export class ChatNoMessagesYetComponent implements OnInit {
  isRetailer$: Observable<boolean>;
  readonly navigateToMarketplace = `/${MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH}`;

  constructor(private store: Store<AppState>) {
    this.isRetailer$ = this.store.select(getUserRoleSelector).pipe(
      omitNullOrUndefined(),
      map((role) => role === RolesEnum.RETAILER)
    );
  }

  ngOnInit(): void {}
}
