import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { NotificationService } from 'app/main/notification/notification.service';
import { AuthenticationService } from 'app/service/authentication/authentication.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit {
  @Input() type: AuthDialogType = AuthDialogType.RETAILER;
  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _authManagerService: AuthenticationManagerService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this._fb.group({
      email: ['', Validators.required],
    });
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  submitHandle(): void {
    if (this.form.invalid) {
      return;
    }

    this._authService.forgotPassword(this.form.value.email).subscribe(() => {
      this._notificationService.success('Successfully sent reset password email');
      this.goBackToLogin();
    });
  }

  goBackToLogin(): void {
    this._authManagerService.emitOpenLoginDialog(this.type);
  }
}
