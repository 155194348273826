import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerDropdownDirective } from '../../directives/date-picker-dropdown.directive';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonWithIconDirective } from '../../directives/button-with-icon.directive';
import { MatChipsModule } from '@angular/material/chips';
import { DEFAULT_DATE_CHOICES } from '../date-picker-dropdown/date-picker-dropdown.component';
import { cloneDeep, isEqual } from 'lodash';

@Component({
  selector: 'app-date-selector-button',
  standalone: true,
  imports: [
    CommonModule,
    DatePickerDropdownDirective,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ButtonWithIconDirective,
    MatChipsModule,
  ],
  templateUrl: './date-selector-button.component.html',
  styleUrls: ['./date-selector-button.component.scss'],
})
export class DateSelectorButtonComponent implements OnInit, OnChanges {
  @Input() dateFrom: Date;
  @Input() dateTo: Date;

  @Output() dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange: EventEmitter<Date> = new EventEmitter<Date>();
  selectedLabel: string;
  private predefinedChoices = cloneDeep(DEFAULT_DATE_CHOICES);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.selectedLabel = this.translateLabel();
  }

  private translateLabel(): string {
    const preDefinedChoice = this.getPredefinedChoice();
    if (!!preDefinedChoice) {
      return `INDEX_FILTER.DATE_LABELS.${preDefinedChoice}`;
    }
    if (!!this.dateFrom && !!this.dateTo) {
      return 'INDEX_FILTER.ACTIVE_LABELS.DATE_FULL';
    } else if (!!this.dateFrom && !this.dateTo) {
      return 'INDEX_FILTER.ACTIVE_LABELS.DATE_START';
    } else if (!this.dateFrom && !!this.dateTo) {
      return 'INDEX_FILTER.ACTIVE_LABELS.DATE_END';
    } else {
      return 'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.DATE';
    }
  }

  private getPredefinedChoice(): string {
    return this.predefinedChoices.find((choice) =>
      isEqual({ to: choice.dateTo, from: choice.dateFrom }, { to: this.dateTo || null, from: this.dateFrom || null })
    )?.id;
  }
}
