import { Injectable } from '@angular/core';
import { identity, pickBy } from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { SpringPage } from '../../vo/pagination/spring-page';
import { SpringPageable } from '../../vo/pagination/spring-pageable';
import {
  FilterItemsVO,
  FullMarketplaceFilterVO,
  MarketplaceFilterPagination,
  SearchProductType,
} from '../../vo/search-product-vo';
import { SupplierDto } from '../../vo/supplier/supplier-dto';
import { EcomVO } from '../ecom/ecom.service';
import { PreferenceStoreService } from '../preference/preference.store.service';
import { ProductSearchService } from '../product-search/product-search.service';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierSearchGatewayService {
  microserviceName = 'productSearch';
  url = '/Search/Supplier';

  constructor(
    private springRestService: SpringRestService,
    private productSearchService: ProductSearchService,
    private preferenceStoreService: PreferenceStoreService
  ) {}

  getSupplierIdsBySearch(
    ecom: EcomVO,
    filters: Partial<FullMarketplaceFilterVO>,
    pagination?: MarketplaceFilterPagination,
    searchType: SearchProductType = SearchProductType.HYBRID
  ): Observable<SpringPage<number>> {
    return this.preferenceStoreService.getPreferredLocation().pipe(
      take(1),
      switchMap((location) => {
        const filterItems = new FilterItemsVO({ ...filters, ...(!pagination ? {} : pagination) });
        return this.springRestService.post(
          this.microserviceName,
          this.url,
          {
            ...this.productSearchService.filterItemsToPostQuery(ecom, filterItems),
            countryCode: location?.code,
            searchType,
          },
          undefined,
          true
        );
      })
    );
  }

  public searchSupplierForAutocomplete(
    searchString: string,
    pageable: SpringPageable = { page: 0, size: 3 }
  ): Observable<SpringPage<SupplierDto>> {
    const rest = pickBy({ companyName: searchString, ...pageable }, identity);
    return this.springRestService.get(MicroserviceNames.USER, '/Supplier/SearchByCompanyNameAndRoleV5', rest, true);
  }
}
