import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from '../../../utils/Constants';
import { Utils } from 'app/utils/utils';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { DragAndDropFileUploadDirective } from 'app/shared/directives/drag-and-drop-file-upload.directive';
import { ngfModule } from 'angular-file';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    DragAndDropFileUploadDirective,
    ngfModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
})
export class LogoUploadComponent implements OnInit, AfterViewInit {
  // OLD_TODO szar az egísz

  isFileSelected: boolean;
  selectedFile: any;
  selectedFileName: string;
  selectedFileExtension: string;
  selectedFileConvertedSize: string;
  files: File[];

  supplier_logo: string;

  validComboDrag;
  isUploadInProgress = false;

  @Output() fileChanged = new EventEmitter<File>();
  @Input() logoLink;
  @Input() isCover = false;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.getAccountInformation();
  }

  ngAfterViewInit(): void {
    if (!Utils.isNullOrUndefined(this.selectedFile)) {
      this.fileChanged.emit(this.selectedFile);
    }
  }

  setFileToUpload(files: File[]): void {
    if (typeof files !== 'undefined' && files != null && files.length != null && files.length > 0) {
      this.isFileSelected = true;
      this.selectedFile = files[0];
      this.selectedFileName = this.selectedFile.name;
      this.selectedFileExtension = Constants.EXTENSION_AUTO;
      this.selectedFileConvertedSize = this.bytesToSize(this.selectedFile.size);
      this.files = [];

      this.fileChanged.emit(this.selectedFile);
      this.getImageSource(files[0]);
    }
  }

  bytesToSize(bytes): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024,
      dm = 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getImageSource(file): void {
    const image = this.element.nativeElement.querySelector('#supplierLogo');
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (_event) => {
      image.src = reader.result;
    };
  }

  getAccountInformation(): void {
    if (this.logoLink) {
      this.selectedFile = this.logoLink;
    }
  }

  setLogoPathToAvoidCache(logo): void {
    const d = new Date();
    const n = d.getTime();
    this.selectedFile = logo + '?' + n.toString();
  }

  handleError(): void {
    this.selectedFile = null;
    this.fileChanged.emit(this.selectedFile);
  }
}
