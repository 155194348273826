<div fxLayoutAlign="center center" fxLayoutGap="24px">
  <div class="action">
    <a class="link-without-underline" href="/suppliers" routerLink="/suppliers">{{
      'HEADER.SELL_ON_SYNCEE' | translate
    }}</a>
  </div>
  <div class="action" (click)="emitLoginDialog()">{{ 'HEADER.LOG_IN' | translate }}</div>
  <button mat-flat-button color="primary" (click)="emitRegistrationDialog()">
    {{ 'HEADER.REGISTER' | translate }}
  </button>
  <button mat-icon-button (click)="emitLoginDialog()" *ngIf="hasCartService.hasCart">
    <mat-icon svgIcon="cart_custom" [badge]="" [badgeVariant]="'with-number'" [badgeOffsetX]="4"></mat-icon>
  </button>
</div>
