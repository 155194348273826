import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/utils/utils';

@Pipe({
  name: 'currencyTransformToSymbol',
  standalone: true,
})
export class CurrencyTransformToSymbolPipe implements PipeTransform {
  transform(currency: string): unknown {
    return Utils.getCurrencySymbol(currency);
  }
}
