import { Injectable, OnDestroy } from '@angular/core';
import { ShippingProfileRestService } from 'app/service/shipping/shipping-profile-rest.service';
import { ShippingProfileDto, ShippingProfileType } from 'app/vo/shipping-zones/shipping-profile-dto';
import { BehaviorSubject, Observable, Subject, iif, of, switchMap, take, takeUntil } from 'rxjs';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { CountryLocationDto } from '../../../../vo/countries-location/country-location-dto';
import { ShippingDetailsDto } from '../../../../vo/shipping-details/shipping-details-dto';
import { ShippingRateType } from '../../../../vo/shipping-details/shipping-rate-type';
import { ShippingRateDto } from '../../../../vo/shipping-zones/shipping-rate.dto';
import { ShippingZoneDto } from '../../../../vo/shipping-zones/shipping-zone-dto';

@Injectable()
export class ShippingDetailsNewFullService implements OnDestroy {
  shippingProfiles$: Observable<ShippingProfileDto[]>;
  fetchProfiles$: Subject<void> = new Subject<void>();
  userId: number;
  taskId: number;

  private profiles: BehaviorSubject<ShippingProfileDto[]> = new BehaviorSubject<ShippingProfileDto[]>(undefined);
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private shippingProfilesRestService: ShippingProfileRestService,
    private _supplierGatewayService: SupplierGatewayService
  ) {
    this.shippingProfiles$ = this.profiles.asObservable();
    this.subscribeToFetchSubject();
    // this.fetchProfiles$.next();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getProfiles(): void {
    this.shippingProfilesRestService
      .getAllShippingProfileByUserIds(true, this.userId)
      .pipe(
        take(1),
        switchMap((profiles) =>
          iif(
            () => profiles && profiles.length > 0,
            of(
              profiles.filter(
                (elem) =>
                  elem.tasks.length > 0 &&
                  elem.shippingZones.length > 0 &&
                  (!this.taskId || !!elem.tasks.find((task) => task.id === this.taskId))
              )
            ),
            this.getOldShippingDetails()
          )
        )
      )
      .subscribe({
        next: (profileData) => {
          this.profiles.next(profileData);
        },
      });
  }

  private getOldShippingDetails(): Observable<ShippingProfileDto[]> {
    return this._supplierGatewayService.getSupplierShippingDetailsLegacy(this.userId).pipe(
      take(1),
      switchMap((data) => this.mapOldShippingToNew(data))
    );
  }

  private subscribeToFetchSubject(): void {
    this.fetchProfiles$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: () => {
        this.getProfiles();
      },
    });
  }

  private mapOldShippingToNew = (shippingDetailsList: ShippingDetailsDto[]): Observable<ShippingProfileDto[]> => {
    return of([
      {
        id: 1,
        userId: this.userId,
        name: '',
        type: ShippingProfileType.GENERAL,
        shippingZones: this.mapOldShippingZonesToNew(shippingDetailsList),
        tasks: [],
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
      },
    ]);
  };

  private mapOldShippingZonesToNew = (details: ShippingDetailsDto[]): ShippingZoneDto[] => {
    return details.map((shippingData) => {
      return {
        id: null,
        shippingProfileId: null,
        name: '',
        shippingRates:
          shippingData.shippingZoneRates.length == 0
            ? this.mapEmptyRates(shippingData)
            : this.mapOldRates(shippingData),
        countriesLocations: shippingData.countries as CountryLocationDto[],
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
      };
    });
  };

  private mapEmptyRates = (shippingData: ShippingDetailsDto): ShippingRateDto[] => {
    return [
      {
        id: null,
        shippingCarrierId: null,
        shippingCarrier: null,
        shippingZoneId: null,
        cost: shippingData.cost,
        additionalCost: shippingData.additionalCost,
        minShippingDays: shippingData.minShippingTime,
        maxShippingDays: shippingData.maxShippingTime,
        condition: null,
      },
    ];
  };

  private mapOldRates = (shippingData: ShippingDetailsDto): ShippingRateDto[] => {
    return shippingData.shippingZoneRates.map((rate) => {
      return {
        id: rate.id,
        shippingCarrierId: null,
        shippingCarrier: {
          id: null,
          userId: null,
          name: rate.rateName,
          type: null,
          logo: null,
          createdAt: null,
          updatedAt: null,
          deletedAt: null,
        },
        shippingZoneId: null,
        cost: rate.price,
        additionalCost: null,
        minShippingDays: shippingData.minShippingTime,
        maxShippingDays: shippingData.maxShippingTime,
        condition: {
          id: 1,
          shippingRateId: rate.id,
          type: rate.type as ShippingRateType,
          minValue: rate.type == ShippingRateType.PRICE ? rate.minAmount : rate.minAmount * 1000,
          maxValue: rate.type == ShippingRateType.PRICE ? rate.maxAmount : rate.maxAmount * 1000,
        },
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
      } as any;
    });
  };
}
