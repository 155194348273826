<div fxLayoutAlign="start center" fxLayoutGap="8px">
  <app-badge-custom
    [badgeSize]="'sm'"
    [text]="'RETAILER_IMPORT_TASKS.BADGES.IS_ACTIVE.' + task.isActive + '.LABEL' | translate"
    [bgColor]="task.isActive | taskIsActiveBackground"
    [textColor]="task.isActive | taskIsActiveColor"
    [matTooltip]="'RETAILER_IMPORT_TASKS.BADGES.IS_ACTIVE.' + task.isActive + '.TOOLTIP' | translate"
  ></app-badge-custom>
  <app-badge-custom
    *ngIf="!!task.status"
    [badgeSize]="'sm'"
    [text]="'RETAILER_IMPORT_TASKS.BADGES.SYNC.' + task.status + '.LABEL' | translate"
    [bgColor]="task.status | taskIsSyncedBackground"
    [textColor]="task.status | taskIsSyncedColor"
    [matTooltip]="'RETAILER_IMPORT_TASKS.BADGES.SYNC.' + task.status + '.TOOLTIP' | translate"
  ></app-badge-custom>
</div>
