<mat-card class="overview-container p-40" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="32px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h4 class="m-0">{{ 'DASHBOARD.OVERVIEW.TITLE' | translate }}</h4>

    <ng-container [ngSwitch]="role">
      <mat-icon
        *ngSwitchCase="RolesEnum.SUPPLIER"
        class="icon-filled s-20"
        [matTooltip]="'DASHBOARD.OVERVIEW.SUPPLIER_TOOLTIP' | translate"
        >info</mat-icon
      >

      <mat-icon
        *ngSwitchCase="RolesEnum.RETAILER"
        class="icon-filled s-20"
        [matTooltip]="'DASHBOARD.OVERVIEW.RETALIER_TOOLTIP' | translate"
        >info</mat-icon
      >
    </ng-container>
  </div>
  <div
    *ngFor="let record of overViewRecords; let i = index"
    fxLayout="column"
    fxLayoutAlign="stretch start"
    fxLayoutGap="18px"
  >
    <div class="w-100p" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <div class="typography-body-1" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <span>{{ record.title }}</span>

        <mat-icon *ngIf="record.tooltip" class="icon-filled s-20" [matTooltip]="record.tooltip">info</mat-icon>
      </div>

      <ng-container [ngSwitch]="record.unitOfMeasurement">
        <div class="typography-body-1 no-wrap" *ngSwitchCase="'CURRENCY'">
          {{
            record.value
              | currencyExchangeTo : fromCurrency ?? currency : currency
              | async
              | currencyTransform : currency
              | async
          }}
        </div>

        <div class="typography-body-1 no-wrap" *ngSwitchCase="'PERCENT'">
          {{ record.value | percent }}
        </div>
      </ng-container>
    </div>

    <mat-divider *ngIf="overViewRecords.length !== i + 1" style="margin: 8px 0" class="w-100p s-20"></mat-divider>
  </div>
</mat-card>
