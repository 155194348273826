import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ConditionLoadingDirective } from 'app/shared/directives/condition-loading.directive';
import { AdminCommentDirective } from '../../directives/admin-comment.directive';
import { BadgeLabelComponent } from '../badge-label/badge-label.component';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { ImportListPriceActionsComponent } from './import-list-price-actions/import-list-price-actions.component';
import { ImportListPriceCollectionSelectorComponent } from './import-list-price-collection-selector/import-list-price-collection-selector.component';
import { ImportListPriceExampleComponent } from './import-list-price-example/import-list-price-example.component';
import { ImportListPriceItemComponent } from './import-list-price-item/import-list-price-item.component';
import { ImportListPriceRoundingExampleComponent } from './import-list-price-rounding/import-list-price-rounding-example/import-list-price-rounding-example.component';
import { ImportListPriceRoundingComponent } from './import-list-price-rounding/import-list-price-rounding.component';
import { ImportListPricingComponent } from './import-list-pricing.component';

@NgModule({
  declarations: [
    ImportListPricingComponent,
    ImportListPriceItemComponent,
    ImportListPriceActionsComponent,
    ImportListPriceRoundingComponent,
    ImportListPriceCollectionSelectorComponent,
    ImportListPriceExampleComponent,
    ImportListPriceRoundingExampleComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatRadioModule,
    FormsModule,
    BadgeLabelComponent,
    CustomDividerComponent,
    ConditionLoadingDirective,
    TranslateModule,
    AdminCommentDirective,
    MatTooltipModule,
  ],
  exports: [ImportListPricingComponent, ImportListPriceRoundingComponent],
})
export class ImportListPriceSettingsModule {}
