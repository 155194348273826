import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { CategoryVo } from '../../../../vo/category-vo';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { flattenCategoriesSelector } from '../../../../store/category/category.selector';
import { filter, map } from 'rxjs/operators';
import { MobileMainMenuInnerInputs } from '../mobile-main-menu-inner-sidebar/mobile-main-menu-inner-sidebar.component';
import { AddFilterWithRedirectAction } from '../../../../store/product-search/product-search.action';

@Component({
  selector: 'app-mobile-main-menu-categories',
  templateUrl: './mobile-main-menu-categories.component.html',
  styleUrls: ['./mobile-main-menu-categories.component.scss'],
})
export class MobileMainMenuCategoriesComponent implements OnInit, OnDestroy, MobileMainMenuInnerInputs {
  @Input() key: string;
  @Input() previouslyOpenedSidebars: string[] = [];
  @Input() needRedirect: boolean;
  @Output() itemClicked = new EventEmitter<void>();
  selectedCategoryTree: Observable<CategoryVo>;
  selectedParentCategoryTree: Observable<CategoryVo>;
  customBackText: Observable<string>;
  sidebarCategoriesClose: Subject<boolean>;
  private selectedCategoryId = new BehaviorSubject(1);
  private unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>) {
    this.sidebarCategoriesClose = new Subject();
    this.unsubscribeAll = new Subject();
    const flattenCategories = this.store.select(flattenCategoriesSelector);
    this.selectedCategoryTree = combineLatest([flattenCategories, this.selectedCategoryId]).pipe(
      filter(([categories]) => !!categories),
      map(([categories, id]) => {
        return categories.find((category) => category.id === id) ?? categories.find((category) => category.id === 1);
      })
    );
    this.selectedParentCategoryTree = combineLatest([flattenCategories, this.selectedCategoryTree]).pipe(
      filter(([categories, selected]) => !!categories && !!selected),
      map(([categories, selected]) => {
        return categories.find((category) => category.id === selected.parent);
      })
    );
    this.customBackText = this.selectedParentCategoryTree.pipe(
      map((parent) => {
        if (!parent) {
          return null;
        }
        return `Back to ${parent.id === 1 ? 'All Categories' : parent.name}`;
      })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private handleSelectCategory(node: CategoryVo): void {
    this.store.dispatch(
      new AddFilterWithRedirectAction({ filter: { category: node.id }, needRedirect: this.needRedirect })
    );
    this.sidebarCategoriesClose.next(true);
  }

  selectCategory(node: CategoryVo): void {
    if (!!node.children && node.children.length > 0) {
      this.selectedCategoryId.next(node.id);
    } else {
      this.handleSelectCategory(node);
    }
  }

  back(node: CategoryVo): void {
    this.selectedCategoryId.next(node.parent);
  }

  openChanged(open: boolean): void {
    if (open) {
      this.selectedCategoryId.next(1);
    }
  }

  viewAll(node: CategoryVo): void {
    this.handleSelectCategory(node);
  }
}
