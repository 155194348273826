<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
  <button *ngIf="!hideAddButton" mat-icon-button class="s-20" (click)="add.emit()">
    <mat-icon class="s-20">add</mat-icon>
  </button>
  <button *ngIf="!hideRemoveButton" mat-icon-button (click)="remove.emit()" class="s-20">
    <mat-icon class="s-20">delete</mat-icon>
  </button>
  <!-- <button mat-icon-button (click)="calculate.emit()" class="s-20">
    <mat-icon class="s-20 icon-filled">info</mat-icon>
  </button> -->
</div>
