import {
  AddToFavoriteIdsSuccessAction,
  AddToFollowedSupplierIdsSuccessAction,
  FavoriteProductsActionUnion,
  FavoritesActionTypes,
  GetFavoriteProductsIdsSuccessAction,
  GetFollowedSupplierIdsSuccessAction,
  RemoveFromFavoriteIdsSuccessAction,
  RemoveFromFollowedSupplierIdsSuccessAction,
} from './favorites.actions';

export interface FavoritesState {
  favoriteProductsIds: string[];
  followedSuppliersIds: number[];
}

const initialState: FavoritesState = {
  favoriteProductsIds: [],
  followedSuppliersIds: [],
};

export function favoriteProductsReducer(state = initialState, action: FavoriteProductsActionUnion): FavoritesState {
  switch (action.type) {
    case FavoritesActionTypes.GET_FAVORITE_PRODUCT_IDS_SUCCESS: {
      return setFavoriteProductIds(state, action);
    }
    case FavoritesActionTypes.ADD_TO_FAVORITE_PRODUCT_IDS_SUCCESS: {
      return addToFavoriteIds(state, action);
    }
    case FavoritesActionTypes.REMOVE_FROM_FAVORITE_PRODUCT_IDS_SUCCESS: {
      return removeFromFavoriteIds(state, action);
    }
    case FavoritesActionTypes.REMOVE_ALL_FAVORITE_PRODUCT_IDS_SUCCESS: {
      return removeAllFavoriteIds(state);
    }

    case FavoritesActionTypes.GET_FOLLOWED_SUPPLIER_IDS_SUCCESS: {
      return setFollowedSupplierIds(state, action);
    }
    case FavoritesActionTypes.ADD_TO_FOLLOWED_SUPPLIER_IDS_SUCCESS: {
      return addToFollowedSupplierIds(state, action);
    }
    case FavoritesActionTypes.REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_SUCCESS: {
      return removeFromFollowedSupplierIds(state, action);
    }
    case FavoritesActionTypes.REMOVE_ALL_FOLLOWED_SUPPLIER_IDS_SUCCESS: {
      return removeAllFollowedSupplierIds(state);
    }

    default: {
      return state;
    }
  }
}

function setFavoriteProductIds(state: FavoritesState, action: GetFavoriteProductsIdsSuccessAction): FavoritesState {
  return {
    ...state,
    favoriteProductsIds: action.payload,
  };
}

function addToFavoriteIds(state: FavoritesState, action: AddToFavoriteIdsSuccessAction): FavoritesState {
  return {
    ...state,
    favoriteProductsIds: [...state.favoriteProductsIds, ...action.payload],
  };
}

function removeFromFavoriteIds(state: FavoritesState, action: RemoveFromFavoriteIdsSuccessAction): FavoritesState {
  return {
    ...state,
    favoriteProductsIds: state.favoriteProductsIds.filter((productId) => !action.payload.includes(productId)),
  };
}

function removeAllFavoriteIds(state: FavoritesState): FavoritesState {
  return {
    ...state,
    favoriteProductsIds: initialState.favoriteProductsIds,
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function setFollowedSupplierIds(state: FavoritesState, action: GetFollowedSupplierIdsSuccessAction): FavoritesState {
  return {
    ...state,
    followedSuppliersIds: action.payload,
  };
}

function addToFollowedSupplierIds(
  state: FavoritesState,
  action: AddToFollowedSupplierIdsSuccessAction
): FavoritesState {
  return {
    ...state,
    followedSuppliersIds: [...state.followedSuppliersIds, ...action.payload],
  };
}

function removeFromFollowedSupplierIds(
  state: FavoritesState,
  action: RemoveFromFollowedSupplierIdsSuccessAction
): FavoritesState {
  return {
    ...state,
    followedSuppliersIds: state.followedSuppliersIds.filter((supplierId) => !action.payload.includes(supplierId)),
  };
}

function removeAllFollowedSupplierIds(state: FavoritesState): FavoritesState {
  return {
    ...state,
    followedSuppliersIds: initialState.followedSuppliersIds,
  };
}
