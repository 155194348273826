import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { AuthenticationStorage } from 'app/service/authentication/authentication-storage';
import { AuthenticationService } from 'app/service/authentication/authentication.service';
import { LogInSuccess } from 'app/store/authentication/authentication.actions';
import { LoginVO } from 'app/vo/authentication/login-vo';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../../service/user/user.service';

@Component({
  selector: 'app-cross-login',
  templateUrl: './cross-login.component.html',
  styleUrls: ['./cross-login.component.scss'],
})
@UntilDestroy()
export class CrossLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService,
    private authService: AuthenticationService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.handleQueryParams();
  }

  handleQueryParams(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      const tid = this.cookieService.get('_fprom_track');
      if (tid) {
        this.userService.affiliateSignUp(params.ecomId, tid).pipe(untilDestroyed(this)).subscribe();
      }

      if (!params['auth_data']) {
        return;
      }

      this._setTokensFromParams(params);

      this._redirect(decodeURI(params['redirect_uri'] || '/'));
    });
  }

  private _setTokensFromParams(params: Params): void {
    const authDataRaw = JSON.parse(atob(params['auth_data'])) as LoginVO;
    const parsedData = this.authService.getParsedAuthData(authDataRaw);
    AuthenticationStorage.storeData(parsedData);
    this.store.dispatch(new LogInSuccess(parsedData));
  }

  private _redirect(path: string = '/'): void {
    location.href = path;
  }
}
