import { SelectButtonItem } from '../../shared/components/select-button/select-button.component';
import { RolesEnum } from '../../vo/roles/roles';

export const HomepageBannerSelectButton: SelectButtonItem<RolesEnum>[] = [
  {
    label: 'EXPLORE_PRODUCTS.GUEST.BANNER.BUTTONS.RETAILER',
    value: RolesEnum.RETAILER,
  },
  {
    label: 'EXPLORE_PRODUCTS.GUEST.BANNER.BUTTONS.SUPPLIER',
    value: RolesEnum.SUPPLIER,
  },
];
