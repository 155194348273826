import { Component, ContentChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
import { RestResponse } from '../../service/rest/rest-response';
import { StepperService } from '../../service/taskwizard/stepper.service';
import { NotificationService } from '../notification/notification.service';
import { StepBase } from '../taskwizard/step-base';

@Component({
  selector: 'app-step-wrapper',
  templateUrl: './step-wrapper.component.html',
  styleUrls: ['./step-wrapper.component.scss'],
})
export class StepWrapperComponent {
  @ContentChild('stepComponent', { static: false }) component: StepBase;

  @Input() nextButtonLabel: string;
  @Input() backNavigationUrl = '/supplier/catalog/list';

  constructor(
    private router: Router,
    private stepperService: StepperService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  public goToPreviousStep(): void {
    this.stepperService.previous();
  }

  public saveAndGoToNextStep(): void {
    this.component.saveStep().subscribe(
      (response) => {
        // if (!!response) {
        this.handleNext(response);
        // }
      },
      (error) => this.handleError(error)
    );
  }

  private handleNext(response: RestResponse): void {
    if (this.isFunctionImplemented(this.component.onSuccessfulSave)) {
      this.component.onSuccessfulSave(response);
    }
    this.notificationService.success(this.translate.instant('TASKWIZARD.STEP_WRAPPER.STEP_SAVED'));
    this.handleNavigation();
  }

  private handleError(error: RestResponse): void {
    if (this.isFunctionImplemented(this.component.onUnsuccessfulSave)) {
      this.component.onUnsuccessfulSave(error);
    }
  }

  private isFunctionImplemented(functionRef: any): boolean {
    return typeof functionRef !== 'undefined';
  }

  private handleNavigation(): void {
    if (this.isLastStep()) {
      this.navigateBackToMyTasks();
    } else {
      this.stepperService.next();
    }
  }

  public reInitIfNeeded(): void {
    if (
      this.stepperService.stepWrappers.length > this.stepperService.currentStepIndex &&
      !Utils.isNullOrUndefined(this.stepperService.stepWrappers[this.stepperService.currentStepIndex].component) &&
      !Utils.isNullOrUndefined(
        this.stepperService.stepWrappers[this.stepperService.currentStepIndex].component.reInit
      ) &&
      this.isFunctionImplemented(
        this.stepperService.stepWrappers[this.stepperService.currentStepIndex].component.reInit
      )
    ) {
      this.stepperService.stepWrappers[this.stepperService.currentStepIndex].component.reInit();
    }
  }

  public isStepValid(): boolean {
    return !Utils.isNullOrUndefined(this.component) && this.component.isStepValid();
  }

  public isFirstStep(): boolean {
    return this.stepperService.isFirstStep();
  }

  public isLastStep(): boolean {
    return this.stepperService.isLastStep();
  }

  public navigateBackToMyTasks(): void {
    this.router.navigate([this.backNavigationUrl]);
  }

  public isStepActive(): boolean {
    const currentWrapper = this.stepperService.stepWrappers.indexOf(this);
    const currentStepIndex = this.stepperService.currentStepIndex;

    return currentWrapper === currentStepIndex || false;
  }

  public getNextButtonLabel(): string {
    if (Utils.isNullOrUndefined(this.nextButtonLabel)) {
      return !this.isLastStep()
        ? this.translate.instant('TASKWIZARD.STEP_WRAPPER.SAVE_AND_NEXT')
        : this.translate.instant('TASKWIZARD.STEP_WRAPPER.SAVE_AND_FINISH');
    } else {
      return this.nextButtonLabel;
    }
  }

  triggerValidation(): void {
    return (
      !Utils.isNullOrUndefined(this.component) &&
      !Utils.isNullOrUndefined(this.component.triggerValidation) &&
      this.component.triggerValidation()
    );
  }
}
