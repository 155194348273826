import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { map, of, switchMap, take } from 'rxjs';
import { MARKETPLACE_NAVIGATION } from '../../navigation/navigation-routes/common/marketplace.navigation';
import { SupplierGatewayService } from '../suppliers/supplier-gateway.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public static NAVIGATE_TO_PRODUCT = `/${MARKETPLACE_NAVIGATION.PRODUCT}`;
  public static NAVIGATE_TO_SUBSCRIPTION =
    '/billing/subscription?utm_source=inapp&utm_medium=button&utm_content=mycatalogs&utm_campaign=inappstarter';

  constructor(private router: Router, private supplierGatewayService: SupplierGatewayService) {}

  getProductPath(synceeId: string, title?: string): string {
    const normalizedTitle = this.normalizeTextToUrl(title);
    return `${NavigationService.NAVIGATE_TO_PRODUCT}/${encodeURIComponent(synceeId)}${
      !!normalizedTitle ? '/' + normalizedTitle : ''
    }`;
  }

  navigateToProduct(synceeId: string, title?: string, newPage = false): void {
    const path = this.getProductPath(synceeId, title);
    if (newPage) {
      window.open(path, '_blank');
    } else {
      this.router.navigate([path]);
    }
  }

  getStorefrontPath(supplierId: number, name: string): string {
    return `/${MARKETPLACE_NAVIGATION.STOREFRONT}/${supplierId}/${name}`;
  }

  navigateToStorefront(supplierId: number, name: string, newPage = false): void {
    of(name)
      .pipe(
        switchMap((handle) =>
          !!handle
            ? of(handle)
            : this.supplierGatewayService
                .getSingleSupplier(supplierId)
                .pipe(map((res) => (res?.handle ? res.handle : this.normalizeTextToUrl(res.companyName))))
        ),
        take(1)
      )
      .subscribe({
        next: (handle) => {
          const path = this.getStorefrontPath(supplierId, handle);
          if (newPage) {
            window.open(path, '_blank');
          } else {
            this.router.navigateByUrl(path);
          }
        },
      });
  }

  navigateToSubscription(): void {
    this.router.navigate([NavigationService.NAVIGATE_TO_SUBSCRIPTION]);
  }

  navigateToCatalog(catalogId: number, ecomType: string, uploadedProducts: number, tab: string = ''): void {
    this.router.navigate([RetailerCenterNavigation.IMPORT_LIST, catalogId, tab], {
      queryParams: { ecomType, uploadedProducts },
    });
  }

  private normalizeTextToUrl(text: string): string {
    if (!text) {
      return null;
    }
    const MAX_LENGTH = 100;
    const split = text.toLowerCase().split(' ');
    const newArray: string[] = [];
    let charCount = 0;
    for (const string of split) {
      if (charCount + string.length > MAX_LENGTH) {
        break;
      }
      if (string.length === 1) {
        continue;
      }
      let word = '';
      for (const char of string) {
        switch (true) {
          case this.isLetter(char) || /^\d+$/.test(char) || char === '-':
            word += char;
            break;
          case char === '*' || char === '&':
            word += '-';
            break;
        }
      }
      if (word.length > 0) {
        newArray.push(word);
        charCount += word.length;
      }
    }
    return encodeURIComponent(newArray.join('-'));
  }

  private isLetter(char: string): boolean {
    return char.toLowerCase() != char.toUpperCase();
  }
}
