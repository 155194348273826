<div class="outer-container" [ngClass]="wrapperClass">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="160px"
    fxLayoutGap.lt-md="0"
    class="h-100-p"
    [ngClass]="innerWrapperClass"
  >
    <div
      *ngIf="imagePosition === 'left'"
      fxFlex="1 0 0"
      class="display-image"
      [style.background-image]="'url(' + image.src + ')'"
      [ngClass]="'display-block'"
      [ngClass.lt-md]="'display-none'"
    ></div>
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-md="start stretch"
      fxLayoutGap="40px"
      fxLayoutGap.lt-md="28px"
      fxFlex="1 0 0"
      class="inner-container"
    >
      <div fxLayout="column" fxLayoutAlign="start" class="cta-text-box">
        <h2 [ngClass]="'h2'" [ngClass.lt-md]="'h3'">{{ title }}</h2>
        <div [ngClass]="'typography-h2-subtitle'" [ngClass.lt-md]="'typography-body-1'">{{ description }}</div>
      </div>
      <button
        mat-button
        class="syncee-blue-button landing-page-button"
        [routerLink]="redirectURL"
        [state]="skipFuseUpdate"
      >
        {{ buttonText }}
      </button>
    </div>
    <div
      *ngIf="imagePosition === 'right'"
      fxFlex="1 0 0"
      class="display-image"
      [style.background-image]="'url(' + image.src + ')'"
      [ngClass]="'display-block'"
      [ngClass.lt-md]="'display-none'"
    ></div>
    <img
      [ngClass]="'display-none'"
      [ngClass.lt-md]="'display-block'"
      class="display-image"
      [src]="image.src"
      [alt]="image.alt"
      fxFlex="1 0 0"
    />
  </div>
</div>
