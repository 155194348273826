<div fxLayout="column" fxLayoutGap="40px">
  <div class="h3">{{ 'SUPPLIER_TAX_SETTINGS.ADD_SETTINGS_DIALOG.TITLE' | translate }}</div>
  <form class="settings-form" fxLayout="row" fxLayoutGap="20px" [formGroup]="settingsForm">
    <mat-form-field fxFlex="1 0 0" class="input-without-padding" appearance="fill">
      <mat-label>{{ 'SUPPLIER_TAX_SETTINGS.ADD_SETTINGS_DIALOG.COUNTRY_LABEL' | translate }}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of data.countries" [value]="country">{{ country.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="0 0 90px" class="input-without-padding select-without-label" appearance="fill">
      <input matInput formControlName="taxRate" />
      <span matSuffix>%&nbsp;</span>
    </mat-form-field>
  </form>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button mat-button (click)="cancel()">
      {{ 'SUPPLIER_TAX_SETTINGS.ADD_SETTINGS_DIALOG.CANCEL_BUTTON' | translate }}
    </button>
    <button mat-button class="syncee-blue-button" [disabled]="this.settingsForm.invalid" (click)="saveSettings()">
      {{ 'SUPPLIER_TAX_SETTINGS.ADD_SETTINGS_DIALOG.ADD_BUTTON' | translate }}
    </button>
  </div>
</div>
