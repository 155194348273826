import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SupplierOrderInfoData } from 'app/shared/components/supplier-order-info/supplier-order-info.component';
import { ProductPricingUtils } from 'app/utils/pricing/product-pricing-utils';
import { Utils } from 'app/utils/utils';
import { ProductAccordionData } from '../product-page-details-accordion.component';

@Component({
  selector: 'app-accordion-product-details',
  templateUrl: './accordion-product-details.component.html',
  styleUrls: ['./accordion-product-details.component.scss'],
})
export class AccordionProductDetailsComponent implements OnInit, OnChanges {
  @Input() details: ProductAccordionData;
  @Input() orderInfoData: SupplierOrderInfoData;

  variantOptionColumns: VariantColumnData[];
  currentLang = 'en';

  protected readonly isNullOrUndefined = Utils.isNullOrUndefined;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateOptionColumns();
  }

  public getPrice(variant): number {
    return ProductPricingUtils.getCostPrice(variant);
  }

  private calculateOptionColumns(): void {
    const optionColumns: VariantColumnData[] = [];
    this.details.optionNameFields.forEach((field) => {
      const variantOptionValues: VariantValueField[] = [];
      this.details.variants.forEach((variant) => {
        const matchingValueField = Object.entries(variant).find(
          (entry) => entry[0].charAt(entry[0].length - 1) === field[0].charAt(field[0].length - 1)
        );
        if (!!matchingValueField) {
          variantOptionValues.push({
            variantId: variant.VARIANT_ID,
            value: matchingValueField[1],
          });
        } else {
          variantOptionValues.push({
            variantId: variant.VARIANT_ID,
            value: '-',
          });
        }
      });
      optionColumns.push({
        title: field[1],
        valueFields: variantOptionValues,
      });
    });
    this.variantOptionColumns = optionColumns;
  }
}

interface VariantColumnData {
  title: string;
  valueFields: VariantValueField[];
}

interface VariantValueField {
  variantId: string;
  value: string;
}
