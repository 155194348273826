import { Injectable } from '@angular/core';
import { SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogGatewayService {
  private microserviceName = 'userService';
  private baseUrl = '/Supplier/Catalog';
  constructor(private springRestService: SpringRestService) {}

  getCatalogCategories(userIds: number[]): Observable<CatalogMainCategories[]> {
    const url = `${this.baseUrl}/Settings/Categories`;
    return this.springRestService.get(this.microserviceName, url, { userId: userIds }, true);
  }
}

export interface CatalogMainCategories {
  userId: number;
  categoryIds: number[];
}
