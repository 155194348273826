import { Component, Input, OnInit } from '@angular/core';
import { RetailerCatalogSettingsVO } from '../../marketplace/retailer-catalogs/retailer-catalog-details/retailer-catalog-settings/abstarct-retailer-settings';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { CatalogService } from '../../../service/catalog/catalog.service';
import { Constants } from '../../../utils/Constants';
import { Observable } from 'rxjs';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-retailer-jumpseller-vu-summary',
  templateUrl: './retailer-jumpseller-vu-summary.component.html',
  styleUrls: ['./retailer-jumpseller-vu-summary.component.scss'],
})
export class RetailerJumpsellerVuSummaryComponent implements OnInit {
  @Input() taskId: number;
  @Input() rcatalogId: number;
  @Input() retailerSettingsVO: RetailerCatalogSettingsVO;
  @Input() comments: any;
  selectedNotification = 'dont';

  taskName: string;
  sourceType: string;
  productNumber: string;
  filteredNumber: string;
  scheduleType: string;
  scheduleTimes: string[];

  showDetails = false;

  constructor(private taskWService: TaskwizardService, private catalogService: CatalogService) {}

  ngOnInit(): void {
    this.taskWService.getSummaryDetails(this.taskId).subscribe((summDetails) => {
      this.taskName = summDetails.taskName;
      const srcTypeObj = Constants.CONNECTIONTYPES.find((sTypeObj) => {
        return sTypeObj.type === summDetails.sourceType;
      });
      this.sourceType =
        Utils.isNullOrUndefined(srcTypeObj) || summDetails.sourceType === Constants.SHOPIFY_CONNECTION_TYPE
          ? null
          : srcTypeObj.name;
      this.productNumber = Utils.isNullOrUndefined(summDetails.productCount)
        ? 'still running the process'
        : summDetails.productCount.toString();
      this.filteredNumber = Utils.isNullOrUndefined(summDetails.filteredNumber)
        ? this.productNumber
        : summDetails.filteredNumber.toString();
      if (Utils.isNullOrUndefined(summDetails.schedulers)) {
        this.showDetails = true;
        return;
      }
      this.showDetails = true;
    });
    if (this.retailerSettingsVO.settings.sendMail) {
      this.selectedNotification = this.retailerSettingsVO.settings.sendMail;
    }
  }

  isStepValid(): boolean {
    return true;
  }

  getRetailerSettingsVO(): RetailerCatalogSettingsVO {
    this.retailerSettingsVO.settings = {
      ...this.retailerSettingsVO.settings,
      ...{ sendMail: this.selectedNotification },
    };

    return this.retailerSettingsVO;
  }

  saveStep(): Observable<any> {
    return this.catalogService.saveRetailerCatalogSettings(this.getRetailerSettingsVO());
  }
}
