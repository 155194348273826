import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelDialogComponent } from './cancel-dialog.component';
import { CancelDialogStepperComponent } from './cancel-dialog-stepper/cancel-dialog-stepper.component';
import { CancelDialogPlanInfoComponent } from './cancel-dialog-stepper/cancel-dialog-plan-info/cancel-dialog-plan-info.component';
import { CancelDialogSubscribeComponent } from './cancel-dialog-stepper/cancel-dialog-subscribe/cancel-dialog-subscribe.component';
import { CancelDialogReasonComponent } from './cancel-dialog-stepper/cancel-dialog-reason/cancel-dialog-reason.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { StepperDialogHeaderComponent } from '../../shared/components/stepper-dialog-header/stepper-dialog-header.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { PlanUpsellBoxComponent } from '../../shared/components/plan-upsell-box/plan-upsell-box.component';
import { MatInputModule } from '@angular/material/input';
import { InformationChipComponent } from '../../shared/components/information-chip/information-chip.component';
import { ListMarkersComponent } from '../../shared/components/list-markers/list-markers.component';

@NgModule({
  declarations: [
    CancelDialogComponent,
    CancelDialogStepperComponent,
    CancelDialogPlanInfoComponent,
    CancelDialogSubscribeComponent,
    CancelDialogReasonComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    CdkStepperModule,
    FuseSharedModule,
    StepperDialogHeaderComponent,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatIconModule,
    PlanUpsellBoxComponent,
    InformationChipComponent,
    ListMarkersComponent,
  ],
  exports: [CancelDialogComponent],
})
export class CancelDialogModule {}
