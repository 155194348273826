import { Component, Input, OnInit } from '@angular/core';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import { ToolbarConfig, ToolbarExtraIcon } from '../../../../../../@fuse/types';
import { AuthenticationManagerService } from '../../../../../main/authentication/authentication-manager.service';
import { MOBILE_MENU_SIDEBAR_ID } from '../../../mobile-main-menu/mobile-main-menu-sidebar-ids';

@Component({
  selector: 'app-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.scss'],
})
export class MobileToolbarComponent implements OnInit {
  @Input() role: number;
  @Input() extraIcons: ToolbarExtraIcon[];
  @Input() needRedirect = true;
  @Input() config: ToolbarConfig;

  constructor(
    private authenticationManagerService: AuthenticationManagerService,
    private fuseSidebarService: FuseSidebarService
  ) {}

  ngOnInit(): void {}

  emitLoginDialog(): void {
    this.authenticationManagerService.emitOpenLoginDialog();
  }

  emitRegistrationDialog(): void {
    this.authenticationManagerService.emitOpenRegistrationDialog();
  }

  openMobileMainMenu(): void {
    this.fuseSidebarService.getSidebar(MOBILE_MENU_SIDEBAR_ID).open();
  }
}
