import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { transformToCdnUrl } from 'app/utils/transform-to-cdn-url';
import { Utils } from 'app/utils/utils';
import { Observable } from 'rxjs';
import { AppState } from '../../../../../../app.state';
import { CountryNameToCodePipe } from '../../../../../../shared/pipes/legacy/country-name-to-code.pipe';
import { ProductSearchService } from '../../../../../../service/product-search/product-search.service';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { FilterSelectorOption } from '../../../filter-selector/filter-selector.component';

@Component({
  selector: 'app-ships-from',
  templateUrl: './ships-from-filter.component.html',
  styleUrls: ['./ships-from-filter.component.scss'],
})
export class ShipsFromFilterComponent implements OnInit {
  allCountries: FilterSelectorOption<string, string>[] = [];
  options: FilterSelectorOption<string, string>[] = [];
  productSearchStore$: Observable<ProductSearchState>;
  searchTerm: string;

  constructor(
    private productSearchService: ProductSearchService,
    private countryNameToCodePipe: CountryNameToCodePipe,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
  }

  ngOnInit(): void {
    this.getCountries().subscribe((countries) => {
      this.options = this.mapCountryListToOptions(countries.sort(this.sortCountries));
      this.allCountries = [...this.options];
    });
  }

  handleSelectedChange(value: string): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { shipsFrom: value } }));
  }

  handleSearchChange(value: string): void {
    this.searchTerm = value;
    this.options =
      Utils.isNullOrUndefined(value) || value.length === 0
        ? this.allCountries
        : this.allCountries.filter((option) => option.value.toLowerCase().indexOf(value.toLowerCase()) > -1);
  }

  private mapCountryListToOptions(countries: string[]): FilterSelectorOption<string, string>[] {
    return countries.map((country) => {
      const code = this.countryNameToCodePipe.transform(country);
      return {
        key: country,
        value: this.translateService.instant(`COUNTRIES.${code}`),
        image: {
          src: transformToCdnUrl(`assets/images/country_flags_png/${code.toLowerCase()}.png`),
          width: '20px',
          height: '17px',
        },
      };
    });
  }

  private getCountries(): Observable<string[]> {
    return this.productSearchService.getShipsFromForFilters();
  }

  private sortCountries(a: string, b: string): number {
    return a.localeCompare(b);
  }
}
