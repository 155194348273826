import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
import { LayoutPortalService } from '../layout-portal/layout-portal.service';
import { LayoutPortalPosition } from '../layout-portal/layout-portal-position';
import { UpdateAvailableComponent } from '../../shared/components/update-available/update-available.component';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private deferredPrompt: any;

  constructor(private swUpdate: SwUpdate, private layoutPortalService: LayoutPortalService) {
    this.handleVersionUpdates();
    this.subscribeToPrompt();
  }

  pwaInstallClicked(): void {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  private subscribeToPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
  }

  private handleVersionUpdates(): void {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        this.layoutPortalService.registerComponentPortal(
          LayoutPortalPosition.LAYOUT_TOP_RIGHT_FLOATING,
          UpdateAvailableComponent
        );
      });
  }

  get hasPrompt(): boolean {
    return !!this.deferredPrompt;
  }
}
