import { MarketplaceFilter } from './search-product-vo';

export enum WidgetType {
  FLAG_WIDGET = 'FLAG_WIDGET',
  CATEGORY_TILE_LEFT = 'CATEGORY_TILE_LEFT',
  CATEGORY_TILE_RIGHT_TOP = 'CATEGORY_TILE_RIGHT_TOP',
  CATEGORY_TILE_RIGHT_BOTTOM = 'CATEGORY_TILE_RIGHT_BOTTOM',
  FILTER_CARD = 'FILTER_CARD',
  FULL_WIDTH_FEATURED_BRAND = 'FULL_WIDTH_FEATURED_BRAND',
  FULL_WIDTH_SUPPLIER_SPOTLIGHT = 'FULL_WIDTH_SUPPLIER_SPOTLIGHT',
  EXPLORE_PRODUCT_CARDS = 'EXPLORE_PRODUCT_CARDS',
  GUEST_CATEGORY_TILE = 'GUEST_CATEGORY_TILE',
  PREMIUM_BRANDS = 'PREMIUM_BRANDS',
  PREMIUM_BRANDS_FEATURED_LIST_1 = 'PREMIUM_BRANDS_FEATURED_LIST_1',
  PREMIUM_BRANDS_FEATURED_LIST_2 = 'PREMIUM_BRANDS_FEATURED_LIST_2',
  PREMIUM_BRANDS_FEATURED_LIST_3 = 'PREMIUM_BRANDS_FEATURED_LIST_3',
  CATEGORY_CARD = 'CATEGORY_CARD',
}

export interface Widget {
  id: number;
  image: string;
  titles: WidgetTitle[];
  buttons: WidgetButton[];
  filterData: WidgetFilterData[];
  isActive: boolean;
  orderNumber: number;
  widgetType: WidgetType;
}

export interface WidgetTitle {
  title: string;
  languageCode: string;
}

export interface WidgetButton {
  buttonText: string;
  languageCode: string;
}

export interface WidgetFilterData {
  fieldName: keyof MarketplaceFilter;
  fieldValue: string;
}

export interface FlagWidget {
  title: string;
  image: string;
  filter: MarketplaceFilter;
}
