import { Component, Input } from '@angular/core';
import { ChipColor } from '../droppable-input/chip';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
})
export class FileFieldComponent {
  @Input() public path: string;
  @Input() public value: string;
  @Input() public fileId: number;
  @Input() public chipColor: ChipColor;

  public getValue(): string {
    return this.path;
  }

  public getFileId(): number {
    return this.fileId;
  }

  public getColor(): ChipColor {
    return this.chipColor;
  }
}
