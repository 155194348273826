<div class="supplier-card-container">
  <app-skeleton-image class="supplier-card-image-container" borderRadius="6px 6px 0 0"></app-skeleton-image>

  <div class="supplier-image-container">
    <app-skeleton-image
      height="70px"
      width="70px"
      borderRadius="50%"
      style="position: absolute; top: -35px; right: 20px"
    ></app-skeleton-image>
  </div>

  <div fxLayout="column" fxLayoutAlgin="space-between start" fxLayoutGap="12px" style="padding: 30px; width: 100%">
    <app-skeleton-text [lines]="1" style="width: 40%"></app-skeleton-text>
    <app-skeleton-text [lines]="1" style="width: 45%"></app-skeleton-text>
    <app-skeleton-text [lines]="1" style="width: 35%"></app-skeleton-text>
  </div>
</div>
