import {
  IndexTableFilter,
  TableViewItem,
} from '../../../../../shared/components/filter-factory/filter-factory.component';

import { ApproveStatusParamEnum } from '../../model/approve-status-param';

export const APPROVE_STATUS_FILTER_TYPE = 'catalogApproveStatus';

export const MY_SUPPLIERS_TABLE_FILTER: IndexTableFilter<ApproveStatusParamEnum>[] = [
  {
    type: APPROVE_STATUS_FILTER_TYPE,
    labelKey: 'MY_SUPPLIERS.TABLE_FILTER.LABEL',
    choices: [
      {
        value: ApproveStatusParamEnum.ACCEPTED,
        label: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.APPROVED',
        translate: true,
      },
      {
        value: ApproveStatusParamEnum.PENDING,
        label: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.PENDING',
        translate: true,
      },
      {
        value: ApproveStatusParamEnum.DECLINED,
        label: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.DECLINED',
        translate: true,
      },
    ],
  },
];

export const MY_SUPPLIERS_TABLE_VIEWS: TableViewItem[] = [
  {
    labelKey: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.APPROVED',
    translate: true,
    filters: [
      {
        value: ApproveStatusParamEnum.ACCEPTED,
        label: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.APPROVED',
        type: APPROVE_STATUS_FILTER_TYPE,
      },
    ],
  },
  {
    labelKey: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.PENDING',
    translate: true,
    filters: [
      {
        value: ApproveStatusParamEnum.PENDING,
        label: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.PENDING',
        type: APPROVE_STATUS_FILTER_TYPE,
      },
    ],
  },
  {
    labelKey: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.DECLINED',
    translate: true,
    filters: [
      {
        value: ApproveStatusParamEnum.DECLINED,
        label: 'MY_SUPPLIERS.TABLE_FILTER.OPTIONS.DECLINED',
        type: APPROVE_STATUS_FILTER_TYPE,
      },
    ],
  },
];
