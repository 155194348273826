import { Injectable } from '@angular/core';
import { FavoritesGatewayService } from '../../../service/favorites/favorites-gateway.service';
import { FullMarketplaceFilterVO, MarketplaceFilter } from '../../../vo/search-product-vo';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ClearFilter } from '../../../store/product-search/product-search.action';

@Injectable({
  providedIn: 'root',
})
export class FavoritesPageService {
  prevFilter: MarketplaceFilter;

  public filterStore$: Observable<Partial<FullMarketplaceFilterVO>>;

  public userId: number;

  private unsubscribeAll: Subject<void>;
  constructor(
    private favoritesService: FavoritesGatewayService,
    private marketplaceEcomService: MarketplaceEcomService,
    private store: Store<AppState>
  ) {
    this.unsubscribeAll = new Subject<void>();
    this.initFilterStore();
    this.getUserIdFromStore();
  }

  private initFilterStore(): void {
    this.filterStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.filter)
    );
  }

  private getUserIdFromStore(): void {
    this.store
      .select('users')
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((value) => {
        this.userId = value.currentUser.id;
      });
  }

  public handleFilterStoreResponse(
    filter: Partial<FullMarketplaceFilterVO>,
    emitIndex: number,
    handler: (filter: Partial<FullMarketplaceFilterVO>, isNewSearch: boolean) => void
  ): Observable<Partial<FullMarketplaceFilterVO>> {
    if (emitIndex === 0) {
      return of(filter).pipe(
        tap(() => {
          handler(filter, false);
        })
      );
    } else {
      return of(filter).pipe(
        tap(() => {
          handler(filter, true);
        })
      );
    }
  }

  public clearFilter(): void {
    this.store.dispatch(new ClearFilter());
  }
}
