<ng-container *ngIf="!isError; else errorMessage">
  <ng-container *ngIf="!!supplierIds; else skeleton">
    <div fxLayout="column" fxLayoutGap="16px" *ngIf="supplierIds.length; else noResults">
      <app-supplier-card-list
        [supplierIds]="supplierIds"
        [isInfinite]="true"
        [from]="pagination.from"
        [selectedCategoryId]="selectedCategoryId"
      ></app-supplier-card-list>
      <app-paginator-load-more
        *ngIf="!!supplierIds"
        (loadMoreClicked)="handleLoadMore()"
        [fetchedNumber]="numberOfFetchedIds"
        [totalNumber]="supplierCount"
        [loading]="isLoading"
      ></app-paginator-load-more>
    </div>
  </ng-container>
</ng-container>

<ng-template #noResults>
  <app-no-search-results></app-no-search-results>
  <app-supplier-no-result-list [selectedCategoryId]="selectedCategoryId"></app-supplier-no-result-list>
</ng-template>

<ng-template #skeleton>
  <app-skeleton-supplier-card-list [numberOfSuppliers]="pagination.size"></app-skeleton-supplier-card-list>
</ng-template>

<ng-template #errorMessage>
  <app-error-message *ngIf="isError"></app-error-message>
</ng-template>
