<app-custom-dialog-wrapper [hideCloseBtn]="true">
  <div fxLayout="column" fxLayoutGap="52px" fxLayoutAlign="start center">
    <div class="centered-text" fxLayout="column" fxLayoutGap="12px">
      <h4 class="mt-0">{{ 'SYNCEE_REVIEW_DIALOG.RATING.TITLE' | translate }}</h4>
      <div>{{ 'SYNCEE_REVIEW_DIALOG.RATING.SUBTITLE' | translate }}</div>
    </div>
    <app-rating-with-icons
      [selectedRating]="selectedRating"
      (selectedChange)="ratingChanged.emit($event)"
    ></app-rating-with-icons>
  </div>
</app-custom-dialog-wrapper>
