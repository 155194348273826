import { Pipe, PipeTransform } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';
import { CategoryNameByIdPipe } from './category-name-by-id.pipe';
import { AppState } from '../../app.state';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/utils';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'categoryNamesByIds',
  standalone: true,
})
export class CategoryNamesByIdsPipe implements PipeTransform {
  private static instance: CategoryNameByIdPipe;

  constructor(private store: Store<AppState>, private translateService: TranslateService) {
    if (Utils.isNullOrUndefined(CategoryNamesByIdsPipe.instance)) {
      CategoryNamesByIdsPipe.instance = new CategoryNameByIdPipe(this.store, this.translateService);
    }
  }

  private static parseInt(value: string | number): number {
    if (typeof value === 'string') {
      return parseInt(value, 0);
    }

    return value;
  }

  public transform(categories: (string | number)[]): Observable<string> {
    return of(categories).pipe(
      map((ids: (string | number)[]) => ids.map((id: string | number) => CategoryNamesByIdsPipe.parseInt(id))),
      mergeMap((ids: number[]) =>
        combineLatest(ids.map((id: number) => CategoryNamesByIdsPipe.instance?.transform?.(id)))
      ),
      map((names: string[]) => names.join(', '))
    );
  }
}
