<div
  class="body-1"
  [innerHTML]="
    (numberOfOrders === 1 ? 'MY_SUPPLIERS.TABLE_CONTENT.ORDER_INFO' : 'MY_SUPPLIERS.TABLE_CONTENT.ORDER_INFO_MULTIPLE')
      | translate
        : {
            total: total | currencyTransform : currency | async,
            numberOfOrders,
            multiple: numberOfOrders !== 1
          }
  "
></div>
