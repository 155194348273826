import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, take } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private readonly defaultObs: Observable<unknown>[];

  constructor(
    private route: ActivatedRoute,
    private fuseConfigService: FuseConfigService,
    private translateService: TranslateService
  ) {
    this.defaultObs = this.getDefaultObs();
  }

  scroll(id: string, options?: Partial<ScrollOptions>): void {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    const top = element.getBoundingClientRect().y;
    document.getElementsByClassName('outer-container')?.[0].scrollTo({
      top: top - (options?.offset ?? 0),
      left: 0,
      behavior: 'smooth',
      ...(options ?? {}),
    });
  }

  scrollToTop(): void {
    document.getElementsByClassName('outer-container')?.[0].scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  waitAndScroll(id: string, observables: Observable<unknown>[], options?: Partial<ScrollOptions>): void {
    combineLatest([...observables, ...this.defaultObs].map((obs) => obs.pipe(omitNullOrUndefined())))
      .pipe(take(1))
      .subscribe(() => this.scroll(id, options));
  }

  initAnchor(observables?: Observable<unknown>[]): void {
    this.route.fragment
      .pipe(take(1))
      .subscribe((fragment) => this.waitAndScroll(fragment, observables ?? [], { behavior: 'auto', offset: 187 }));
  }
  private getDefaultObs(): Observable<unknown>[] {
    return [
      this.translateService.stream('TRANSLATION_LOADED'),
      this.fuseConfigService.getConfig().pipe(debounceTime(0)),
    ];
  }
}

export interface ScrollOptions extends ScrollToOptions {
  offset: number;
}
