import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MARKETPLACE_NAVIGATION } from '../../../navigation/navigation-routes/common/marketplace.navigation';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent implements OnInit {
  @Input() images: string[] = [];
  @Input() productId: string;
  @Input() productName: string;
  @Input() supplierUserId: string | number;
  env = environment;
  prevDisabled = true;
  nextDisabled = false;
  productPath: string;
  ltMd: boolean;
  readonly marketplaceNavigation = MARKETPLACE_NAVIGATION;

  config: JQuerySlickOptions = {
    infinite: false,
    arrows: false,
    speed: 200,
    lazyLoad: 'ondemand',
  };
  isLoading = true;
  private index = 0;

  constructor(private navigationService: NavigationService, private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToLtMd();
    this.productPath = this.navigationService.getProductPath(this.productId, this.productName);
  }

  handleImageChanged(data): void {
    this.nextDisabled = data.nextSlide === this.images.length - 1;
    this.prevDisabled = data.nextSlide === 0;
    this.index = data.nextSlide;
  }

  private subscribeToLtMd(): void {
    this.screenManagerService
      .observeIfBreakpointMatches(BreakPoint.md)
      .pipe(untilDestroyed(this))
      .subscribe((matches) => (this.ltMd = matches));
  }
}
