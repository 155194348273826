import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { DialogMultiType } from '../../../vo/dialog-multi';

@Component({
  selector: 'app-dialog-multi-wrapper',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule, FlexModule],
  templateUrl: './dialog-multi-wrapper.component.html',
  styleUrls: ['./dialog-multi-wrapper.component.scss'],
})
export class DialogMultiWrapperComponent implements OnInit {
  @Input() type: DialogMultiType = 'info';
  @Input() icon: string;
  @Input() iconFilled = true;
  @Input() iconWithCircle = false;
  @Input() title = 'Dialog title';
  @Input() closeButtonText = 'Close';
  @Input() confirmButtonText = 'Confirm';
  @Input() buttonsAlignment: ButtonAlignment = 'center';
  @Input() hideCloseButton = false;
  @Input() hideConfirmButton = false;
  @Input() confirmDisabled = false;
  @Input() customButtons: TemplateRef<HTMLElement>;
  @Input() contentRef: TemplateRef<HTMLElement>;
  @Input() contentButtonsDistance = 28;
  @Input() titleContentDistance = 12;
  @Output() confirmClick = new EventEmitter<void>();

  constructor(private dialogRef: MatDialogRef<DialogMultiWrapperComponent>) {
    this.dialogRef.addPanelClass('custom-modal-container');
  }

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon(): void {
    if (!this.icon) {
      this.icon = this.getDefaultIcon();
    }
  }

  private getDefaultIcon(): string {
    switch (this.type) {
      case 'danger':
        return 'warning';
      case 'warning':
        return 'error';
      case 'info':
        return 'info';
      case 'success':
        return 'check_circle';
      default:
        return null;
    }
  }

  handleConfirmClick(): void {
    this.confirmClick.emit();
  }
}
type ButtonAlignment = 'start' | 'center' | 'end';
