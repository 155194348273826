import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { getCurrentUserSelector } from '../../store/user/user.selector';
import { ROLE_TYPE, RolesEnum } from '../../vo/roles/roles';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { CountManagedProductsByRetailersDto } from '../my-retailers/my-retailers.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(private intercom: Intercom, private store: Store<AppState>, private gatewayService: SpringRestService) {}

  initIntercomWithUserData(roles: ROLE_TYPE[]): void {
    if (!roles.includes(RolesEnum.ADMIN)) {
      this.store
        .select(getCurrentUserSelector)
        .pipe(take(1))
        .subscribe((user) => {
          this.getUserHash()
            .pipe(take(1))
            .subscribe((hash) => {
              this.intercom.boot({
                user_id: user.id.toString(),
                user_hash: hash.hash,
                email: user.email,
                name: user.userName,
                Syncee_Retailer: roles.includes(RolesEnum.RETAILER),
                Syncee_Supplier: roles.includes(RolesEnum.SUPPLIER),
                widget: {
                  activator: '#intercom',
                },
              });
            });
        });
    }
  }

  initGuestIntercom(): void {
    this.intercom.boot({
      widget: {
        activator: '#intercom',
      },
    });
  }

  shutDownIntercom(): void {
    this.intercom.shutdown();
  }

  getUserHash(): Observable<{ hash: string }> {
    return this.gatewayService.get(MicroserviceNames.USER, `/Intercom/UserHash`, {}, true);
  }
}
