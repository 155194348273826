import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FeedbackDto,
  FeedbackGroup,
  FullFeedbackDto,
  UserFeedbackDto,
  UserFeedbackService,
} from '../../../service/user-feedback/user-feedback.service';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';

@Component({
  selector: 'app-user-feedback',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    FlexModule,
    ConditionLoadingDirective,
  ],
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent implements OnInit {
  @Input()
  group: FeedbackGroup;

  /**
   key is the question key of the role feedback
   */
  @Input()
  customOptions: Record<string, CustomOptionDto[]>;
  feedbackFormGroup: FormGroup;
  feedbacks$: Observable<FullFeedbackDto[]>;
  selectedAnswers: Record<number, UserFeedbackDto> = {};
  hasAnswers = false;
  hasError = false;

  constructor(private userFeedbackService: UserFeedbackService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.feedbacks$ = combineLatest([
      this.userFeedbackService.getFeedbacks(this.group),
      this.userFeedbackService.getAnswers(this.group),
    ]).pipe(
      tap(
        ([feedbacks, answers]) => this.onSuccess(feedbacks, answers),
        () => (this.hasError = true)
      ),
      map(([feedbacks]) => feedbacks)
    );
  }

  private onSuccess(feedbacks: FullFeedbackDto[], answers: UserFeedbackDto[]): void {
    this.initSelectedAnswers(feedbacks, answers);
    this.initForm(feedbacks, answers);
    this.hasAnswers = answers.length > 0;
  }

  handleSelectionChange(optionId: number, feedback: FullFeedbackDto): void {
    this.handleOtherInputFormControl(feedback, optionId);
    this.selectedAnswers[feedback.feedback.id] = { feedbackId: optionId } as UserFeedbackDto;
  }

  private handleOtherInputFormControl(feedback: FullFeedbackDto, optionId: number): void {
    const otherInputKey = feedback.feedback.question + '_OTHER_INPUT';
    const selectedOption = this.getFeedbackById(optionId, feedback.options);

    if (selectedOption.key === 'OTHER') {
      this.feedbackFormGroup.controls[otherInputKey] = new FormControl(null, Validators.required);
    } else {
      delete this.feedbackFormGroup.controls[otherInputKey];
    }
  }

  handleCustomAnswerChange(feedback: FeedbackDto, message: string): void {
    this.selectedAnswers[feedback.roleFeedbackId] = {
      feedbackId: feedback.id,
      message: message,
    } as UserFeedbackDto;
  }

  saveFeedbacks(): Observable<void> {
    if (this.feedbackFormGroup.dirty) {
      if (this.hasAnswers) {
        return this.userFeedbackService
          .deleteFeedbacksByUser(this.group)
          .pipe(switchMap(() => this.userFeedbackService.saveFeedbacks(this.answers, this.group)));
      } else {
        return this.userFeedbackService.saveFeedbacks(this.answers, this.group);
      }
    } else {
      return of(void 0);
    }
  }

  getFeedbackById(id: number, options: FeedbackDto[]): FeedbackDto {
    return options.find((feedback) => feedback.id === id);
  }

  private initSelectedAnswers(feedbacks: FullFeedbackDto[], answers: UserFeedbackDto[]): void {
    feedbacks.forEach((feedback) => {
      const existingAnswer = this.getExistingFeedbackFromOptions(feedback, answers);
      this.selectedAnswers[feedback.feedback.id] = existingAnswer || ({} as UserFeedbackDto);
    });
  }

  private initForm(feedbacks: FullFeedbackDto[], answers: UserFeedbackDto[]): void {
    const controls = {};
    feedbacks.forEach((feedback) => {
      const existingAnswer = this.getExistingFeedbackFromOptions(feedback, answers);
      controls[feedback.feedback.question] = new FormControl(
        this.getInitialValue(existingAnswer, feedback),
        Validators.required
      );

      if (existingAnswer) {
        this.setOtherInputFormControls(feedback, controls, existingAnswer);
      }
    });

    this.feedbackFormGroup = this.formBuilder.group(controls);
  }

  private setOtherInputFormControls(feedback: FullFeedbackDto, controls: {}, existingAnswer: UserFeedbackDto): void {
    const selectedOption = this.getFeedbackById(existingAnswer.feedbackId, feedback.options);
    if (selectedOption.key === 'OTHER') {
      controls[feedback.feedback.question + '_OTHER_INPUT'] = new FormControl(
        existingAnswer ? existingAnswer.message : null,
        Validators.required
      );
    }
  }

  private getInitialValue(existingAnswer: UserFeedbackDto, feedback: FullFeedbackDto): number {
    return existingAnswer
      ? feedback.options[0].key === 'CUSTOM'
        ? +existingAnswer.message
        : existingAnswer.feedbackId
      : null;
  }

  private getExistingFeedbackFromOptions(feedback: FullFeedbackDto, answers: UserFeedbackDto[]): UserFeedbackDto {
    const optionIds = feedback.options.map((option) => option.id);
    return answers.find((answer) => optionIds.includes(answer.feedbackId));
  }

  get answers(): UserFeedbackDto[] {
    return Object.values(this.selectedAnswers);
  }
}

export interface CustomOptionDto {
  id: number;
  value: any;
}
