import { Component } from '@angular/core';
import { WizardCategoryHeaderService } from '../../../service/wizard-category-header.service';

@Component({
  selector: 'app-wizard-mapping-header-left-controls',
  templateUrl: './wizard-mapping-header-left-controls.component.html',
  styleUrls: ['./wizard-mapping-header-left-controls.component.scss'],
})
export class WizardMappingHeaderLeftControlsComponent {
  constructor(public headerService: WizardCategoryHeaderService) {}
}
