import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retailer-login',
  templateUrl: './retailer-login.component.html',
  styleUrls: ['./retailer-login.component.scss'],
})
export class RetailerLoginComponent implements OnInit {
  public readonly bannerCoverImage = 'assets/images/backgrounds/retailer-login.webp';
  public readonly bannerTextList: string[] = [
    'AUTHENTICATION.BANNER.LOGIN.RETAILER.FIRST',
    'AUTHENTICATION.BANNER.LOGIN.RETAILER.SECOND',
    'AUTHENTICATION.BANNER.LOGIN.RETAILER.THIRD',
  ];

  constructor() {}

  ngOnInit(): void {}
}
