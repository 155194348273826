<div class="h-100-p" fxLayoutAlign="space-between start" fxLayout="column">
  <div *ngIf="parentCategory" fxFlex="0 0 auto" class="w-100p" fxLayoutAlign="space-between center" class="header">
    <div>
      <div (click)="handleBackToClicked()" fxLayoutAlign="center center" fxLayoutGap="4px" class="back-to">
        <mat-icon>arrow_back_ios</mat-icon>
        <ng-container *ngIf="parentCategory.id != ROOT_CATEGORY_ID; else rootCategory">
          {{ 'FILTER_SIDEBAR.SELECTOR.CATEGORIES.BACK_TO' | translate : { name: getCategoryName(parentCategory) } }}
        </ng-container>
      </div>
      <div class="current-category">{{ getCategoryName(selectedCategoryForHeader) }}</div>
    </div>
    <div
      class="view-all"
      (click)="handleViewAllClicked()"
      [innerHTML]="
        'FILTER_SIDEBAR.SELECTOR.CATEGORIES.VIEW_ALL' | translate : { name: getCategoryName(selectedCategoryForHeader) }
      "
    ></div>
  </div>
  <app-filter-selector
    class="w-100p"
    fxFlex="1 0 0"
    [value]="selectedCategoryId"
    (valueChange)="handleCategorySelected($any($event))"
    [options]="options"
  ></app-filter-selector>
</div>
<ng-template #rootCategory> {{ 'FILTER_SIDEBAR.SELECTOR.CATEGORIES.BACK_TO_ALL' | translate }} </ng-template>
