import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MarketplaceFilter } from '../../../vo/search-product-vo';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AddFilter } from '../../../store/product-search/product-search.action';

const HIDDEN_FILTERS: (keyof MarketplaceFilter)[] = [
  'minPrice',
  'approveType',
  'autoOrder',
  'category',
  'maxPrice',
  'shippingType',
  'shipsTo',
  'shipsFrom',
  'ecomId',
  'lang',
  'maxShippingTime',
  'minShippingTime',
  'stock',
  'premium',
];

@Component({
  selector: 'app-favorites-page-filter',
  templateUrl: './favorites-page-filter.component.html',
  styleUrls: ['./favorites-page-filter.component.scss'],
})
export class FavoritesPageFilterComponent implements OnInit, OnDestroy {
  @Input() isSupplierSearch = false;
  readonly hiddenFilters = HIDDEN_FILTERS;
  private unsubscribeAll: Subject<void>;
  searchTerm: string;
  searchTermChange = new Subject<string>();

  constructor(private store: Store<AppState>) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToSearchChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToSearchChange(): void {
    this.searchTermChange
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscribeAll))
      .subscribe((value) => {
        this.store.dispatch(new AddFilter({ value: { search: value } }));
      });
  }
}
