import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-import-list-price-actions',
  templateUrl: './import-list-price-actions.component.html',
  styleUrls: ['./import-list-price-actions.component.scss'],
})
export class ImportListPriceActionsComponent implements OnInit {
  @Input() hideRemoveButton = false;
  @Input() hideAddButton = false;

  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() calculate = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
