<app-index-table-filter-dropdown
  [choices]="choices"
  (selectedChoiceChanged)="
    dateFromChange.emit($event.value?.dateFrom);
    dateToChange.emit($event.value?.dateTo);
    customDatePickerOpen = false;
    closeOverlay$.next()
  "
  [radio]="true"
  [selectedChoice]="
    !dateFrom && !dateTo ? null : customDatePickerOpen ? undefined : { dateFrom: dateFrom, dateTo: dateTo }
  "
>
  <div class="w-100-p" fxLayout="column" fxLayoutAlign="center start">
    <mat-radio-group [ngModel]="customDatePickerOpen">
      <mat-radio-button class="custom-date-radio" [value]="true" (click)="customDatePickerOpen = true">
        <div fxLayout="row" fxLayoutAlign="start">
          {{ 'INDEX_FILTER.DATE_LABELS.CUSTOM' | translate }}
        </div>
      </mat-radio-button>
    </mat-radio-group>
    <div class="date-picker-container" *ngIf="customDatePickerOpen">
      <app-from-to-date-picker
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        (dateFromChange)="dateFromChange.emit($event)"
        (dateToChange)="dateToChange.emit($event)"
        [direction]="'column'"
      ></app-from-to-date-picker>
    </div>
  </div>
</app-index-table-filter-dropdown>
