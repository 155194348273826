<div class="shipping-type-filter-container">
  <div>
    <label class="label">{{ 'FILTER_SIDEBAR.SELECTOR.SUPPLIER_TYPE.APPROVE_NEEDED.TITLE' | translate }}</label>
    <mat-radio-group
      class="approve-radio-group radio-group"
      [value]="(productSearchStore$ | async)?.temporaryFilter?.approveType"
      (change)="handleApproveChange($event.value)"
    >
      <mat-radio-button class="radio-button" *ngFor="let type of approveType" [value]="type.id">
        {{ 'FILTER_SIDEBAR.SELECTOR.SUPPLIER_TYPE.APPROVE_NEEDED.' + type.label | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <label class="label">{{ 'FILTER_SIDEBAR.SELECTOR.SUPPLIER_TYPE.PREMIUM.TITLE' | translate }}</label>
    <mat-radio-group
      class="premium-radio-group radio-group"
      [value]="(productSearchStore$ | async)?.temporaryFilter?.premium"
      (change)="handlePremiumChange($event.value)"
    >
      <mat-radio-button class="radio-button" *ngFor="let type of premiumType" [value]="type.id">
        {{ 'FILTER_SIDEBAR.SELECTOR.SUPPLIER_TYPE.PREMIUM.' + type.label | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
