import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-section-title',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() hasBottomPadding = true;
  @Input() position: 'left' | 'center' = 'center';
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }
}
