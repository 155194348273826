import { UntypedFormGroup } from '@angular/forms';
import { FieldStructure } from '../main/filter/filter-item/filter-item.component';
import { FileList } from '../main/ftp/filelist';

export class Utils {
  private static pathSeparators: string[] = ['/', '\\'];

  public static mapTaskFiltersFieldStructure(structureObjArray): FieldStructure[] {
    const fieldStructures = [];
    structureObjArray.forEach((structureObj) => {
      fieldStructures.push(<FieldStructure>{
        name: structureObj.name,
        key: structureObj.key,
        valuesLimit: structureObj.valuesLimit,
        valuesOperator: structureObj.valuesOperator,
        autoValues: structureObj.autoValues,
        conditions: structureObj.conditions,
      });
    });
    return fieldStructures;
  }

  public static markFormGroupTouched(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls)
      .map((x) => formGroup.controls[x])
      .forEach((control: UntypedFormGroup) => {
        control.markAsTouched();
        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
  }

  public static getOrderedFileList(fileList: Array<FileList>): Array<FileList> {
    const tempDirList = new Array<FileList>();
    const tempFileList = new Array<FileList>();
    for (const item of fileList) {
      if (item.isDirectory) {
        tempDirList.push(item);
      } else {
        tempFileList.push(item);
      }
    }

    tempDirList.sort(function (a, b): any {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    // console.log(tempDirList);

    tempFileList.sort(function (a, b): any {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    // console.log(tempFileList);

    return tempDirList.concat(tempFileList);
  }

  public static isNullOrUndefined(object: any): boolean {
    return object === null || object === undefined;
  }

  public static isNullOrUndefinedOrLengthZero(object: any): boolean {
    return this.isNullOrUndefined(object) || object.length === 0;
  }

  public static sprintf(format, ...args): string {
    let i = 0;
    return format.replace(/%s/g, function (): void {
      return args[i++];
    });
  }

  public static getFileNameFromPath(path: string): string {
    let splittedPath = [];
    this.pathSeparators.some(function (separator: string): boolean {
      splittedPath = path.split(separator);
      return splittedPath.length > 1;
    });
    return splittedPath.pop();
  }

  public static getCredentialsFromURL(urlRaw: string): URLPieces {
    const urlPieces = new URLPieces();
    const pattern = /:\/\/(.*):(.*)@/;
    const matches = urlRaw.match(pattern);
    if (this.isNullOrUndefined(matches) || matches.length < 2) {
      urlPieces.url = urlRaw;
      return urlPieces;
    }
    urlPieces.url = urlRaw.replace(pattern, '://');
    urlPieces.username = matches[1];
    urlPieces.password = matches[2];
    return urlPieces;
  }

  public static isEmptyObject(obj): boolean {
    return this.isNullOrUndefined(obj) || Object.keys(obj).length === 0;
  }

  public static monthDayNumToString(dayNum: number): string {
    switch (dayNum) {
      case 1:
      case 21:
      case 31:
        return dayNum + 'st';
      case 2:
      case 22:
        return dayNum + 'nd';
      case 3:
      case 23:
        return dayNum + 'rd';
      default:
        return dayNum + 'th';
    }
  }

  public static checkShopifyDomainIsValid(path: string): boolean {
    const pattern = /^https:\/\/(.*)\.myshopify\.com$/;
    const matches = path.match(pattern);
    if (this.isNullOrUndefined(matches) || matches.length < 2) {
      return false;
    }
    return true;
  }

  public static getRandomInt(min, max): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static objectToFormData(obj, form, namespace = null): FormData {
    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          this.objectToFormData(obj[property], fd, property);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;
  }

  public static formatMoney(number: number): string {
    return (Math.round(number * 100) / 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  public static copyToClipboard(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  public static truncateByDecimalPlace(value, numDecimalPlaces): number {
    return Math.trunc(value * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces);
  }

  public static getPrice(price: number, discountedPrice: number): number {
    if (!this.isNullOrUndefined(discountedPrice) && discountedPrice !== 0) {
      return discountedPrice;
    }
    return price;
  }

  public static explodeSynceeID(synceeID: string): SynceeIDPieces {
    const pieces = synceeID.split('_');
    return { userID: parseInt(pieces[0], undefined), taskID: parseInt(pieces[1], undefined), ID: pieces[2] };
  }

  public static parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c: string): string {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  public static buildVariantTitle(...optionValues: string[]): string {
    return optionValues.filter((optionValue) => !!optionValue).join(' / ');
  }

  public static buildRestApiURL(urlPiece: string, params?: any): string {
    console.log(params);
    if (!Utils.isNullOrUndefinedOrLengthZero(params)) {
      Object.keys(params).forEach((key) => {
        if (!Utils.isNullOrUndefinedOrLengthZero(params[key])) {
          urlPiece = urlPiece.replace('{' + key + '}', params[key]);
        } else {
          urlPiece = urlPiece.replace('/{' + key + '}', '');
        }
      });
    }
    return urlPiece;
  }

  public static isNumeric(str: string): boolean {
    if (typeof str != 'string') {
      return false;
    } // we only process strings!
    return (
      !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  /** Levenshtein distance calculation
   *
   * The returned value represents the number of single-character edits (insertions,
   * deletions, or substitutions) required to change string a into string b.
   *
   * If the returned value is 0, it means the strings a and b are identical.
   *
   * If the returned value is greater than 0, it indicates the number of edits needed to make a and b identical.
   *
   * Lower values generally indicate greater similarity between the strings, as fewer edits are required to make them identical.
   */
  public static stringSimilarity(a: string, b: string): number {
    function _min(d_0: number, d_1: number, d_2: number, b_x: number, a_y: number): number {
      return d_0 < d_1 || d_2 < d_1 ? (d_0 > d_2 ? d_2 + 1 : d_0 + 1) : b_x === a_y ? d_1 : d_1 + 1;
    }

    if (a === b) {
      return 0;
    }

    if (a.length > b.length) {
      const tmp = a;
      a = b;
      b = tmp;
    }

    let la = a.length;
    let lb = b.length;

    while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
      la--;
      lb--;
    }

    let offset = 0;

    while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
      offset++;
    }

    la -= offset;
    lb -= offset;

    if (la === 0 || lb < 3) {
      return lb;
    }

    let x = 0;
    let y: number;
    let d0: number;
    let d1: number;
    let d2: number;
    let d3: number;
    let dd: number;
    let dy: number;
    let ay: number;
    let bx0: number;
    let bx1: number;
    let bx2: number;
    let bx3: number;

    const vector = [];

    for (y = 0; y < la; y++) {
      vector.push(y + 1);
      vector.push(a.charCodeAt(offset + y));
    }

    const len = vector.length - 1;

    for (; x < lb - 3; ) {
      bx0 = b.charCodeAt(offset + (d0 = x));
      bx1 = b.charCodeAt(offset + (d1 = x + 1));
      bx2 = b.charCodeAt(offset + (d2 = x + 2));
      bx3 = b.charCodeAt(offset + (d3 = x + 3));
      dd = x += 4;
      for (y = 0; y < len; y += 2) {
        dy = vector[y];
        ay = vector[y + 1];
        d0 = _min(dy, d0, d1, bx0, ay);
        d1 = _min(d0, d1, d2, bx1, ay);
        d2 = _min(d1, d2, d3, bx2, ay);
        dd = _min(d2, d3, dd, bx3, ay);
        vector[y] = dd;
        d3 = d2;
        d2 = d1;
        d1 = d0;
        d0 = dy;
      }
    }

    for (; x < lb; ) {
      bx0 = b.charCodeAt(offset + (d0 = x));
      dd = ++x;
      for (y = 0; y < len; y += 2) {
        dy = vector[y];
        vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1]);
        d0 = dy;
      }
    }

    return dd;
  }

  public static getCurrencySymbol(currency: string): string {
    return Number().toLocaleString('en', { style: 'currency', currency: currency }).replace('0.00', '');
  }
}

export class URLPieces {
  url: string;
  username: string;
  password: string;
}

interface SynceeIDPieces {
  userID: number;
  taskID: number;
  ID: string;
}

export interface Cancelable {
  cancel(): void;
  flush(): void;
}
