import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProductPrices, ProductPricingUtils } from '../../../utils/pricing/product-pricing-utils';
import { VariantVO } from '../../../vo/search-product-vo';

@Component({
  selector: 'app-product-page-price-display',
  templateUrl: './product-page-price-display.component.html',
  styleUrls: ['./product-page-price-display.component.scss'],
})
export class ProductPagePriceDisplayComponent implements OnInit, OnChanges {
  @Input() selectedVariant: VariantVO;
  @Input() quantity: number;
  @Input() ecomCurrency = 'USD';
  @Input() possibleMargin: number;
  @Input() supplierCurrency = 'USD';
  prices: ProductPrices;

  constructor() {}

  ngOnInit(): void {
    this.prices = ProductPricingUtils.calculatePrices(this.selectedVariant, this.possibleMargin);
  }

  ngOnChanges(): void {
    this.prices = ProductPricingUtils.calculatePrices(this.selectedVariant, this.possibleMargin);
  }
}
