import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { NeedSubscriptionBadgeComponent } from '../components/need-subscription-badge/need-subscription-badge.component';
import { SubscriptionUpsellComponent } from '../components/subscription-upsell/subscription-upsell.component';
import {
  Action,
  AuthorizationEcomService,
  PermissionPayload,
} from '../../service/authorization-ecom/authorization-ecom.service';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { Utils } from '../../utils/utils';

@Directive({
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnDestroy {
  private hasView = false;
  private _needSubscriptionInstance: NeedSubscriptionBadgeComponent = null;
  private _needFullPageSubscriptionInstance: SubscriptionUpsellComponent = null;
  private unsubscribeAll: Subject<void>;
  private subscription: Subscription;
  private withFullScreen: boolean;
  private permission: Action;
  private hasActionBar: boolean;
  private ecom: EcomVO;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationEcomService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
  @Input() set hasPermission(action: Action) {
    this.permission = action;
  }

  @Input() set hasPermissionData(data: {
    ecom: EcomVO;
    isAdmin?: boolean;
    payload?: Partial<PermissionPayload>;
    hasActionBar?: boolean;
  }) {
    this.hasActionBar = data.hasActionBar;
    if (data?.ecom) {
      this.ecom = data.ecom;
      const hasPermission = this.authorizationService.hasPermission(
        this.permission,
        data.ecom,
        data.isAdmin,
        data.payload
      );
      this.setView(hasPermission);
    } else {
      if (!this.subscription) {
        this.subscription = this.authorizationService
          .hasPermissionObs(this.permission, data.payload)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((hasPermission) => {
            this.setView(hasPermission);
          });
      }
    }
  }

  @Input() set hasPermissionWithFullPage(c: boolean) {
    this.withFullScreen = true;
  }

  private setView(hasPermission: boolean): void {
    if (!!hasPermission && !this.hasView) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef, { hasPermission: hasPermission });
      this.hasView = true;
    } else if (!hasPermission) {
      this.viewContainer.clear();
      if (this.withFullScreen) {
        const instance = this.viewContainer.createComponent(SubscriptionUpsellComponent);
        if (!Utils.isNullOrUndefined(this.hasActionBar)) {
          instance.instance.hasActionBar = this.hasActionBar;
        }
        if (!Utils.isNullOrUndefined(this.ecom)) {
          instance.instance.selectedEcom = this.ecom;
        }
        // this._needFullPageSubscriptionInstance = instance.instance;
      } else {
        this._needSubscriptionInstance = this.viewContainer.createComponent(NeedSubscriptionBadgeComponent).instance;
      }
      this.hasView = false;
    }
  }
}
