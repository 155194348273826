import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { Billing } from 'app/service/order/order.service';
import { Utils } from 'app/utils/utils';
import { of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BillingService } from '../../../service/billing/billing.service';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { EU_COUNTRIES_CODES } from '../../../utils/Constants';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-billing-info-form',
  templateUrl: './billing-info-form.component.html',
  styleUrls: ['./billing-info-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    LoadingSpinnerComponent,
    MatInputModule,
  ],
})
export class BillingInfoFormComponent implements OnInit, OnDestroy {
  @Input()
  labelled = false;

  @Input()
  ltMd: boolean;

  @Output()
  onSubmit = new EventEmitter();

  private _unsubscribeAll: Subject<void>;
  private _filterInited = false;

  private _EU_COUNTRY_CODES = EU_COUNTRIES_CODES;
  private _COUNTRY_LIST;

  public isLoading = true;
  public hasError = false;
  public hideEUVat = true;
  public hideTaxNumber = true;

  public filteredCountries$;

  public billingInfoForm: FormGroup;

  public countryFilter = new UntypedFormControl();

  @Input() disable = false;

  @Input() billing: Billing;

  constructor(
    private fb: FormBuilder,
    private billingService: BillingService,
    private countriesManagerService: CountriesManagerService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.buildForm();

    this.initCountrySubscription();

    if (this.billing) {
      this.initForm(this.billing);
    } else {
      this.initGetBillingInformationSubscription();
    }

    this.initCountryList();

    this.handleDisable();
  }

  handleDisable(): void {
    if (this.disable) {
      this.billingInfoForm.disable();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private buildForm(): void {
    this.billingInfoForm = this.fb.group({
      id: [null],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      companyName: [''],
      euVat: [''],
      taxNumber: [''],
    });
  }

  initForm(billing: Billing): void {
    this.billingInfoForm.patchValue(billing);
    this.isLoading = false;
  }

  get taxNumber(): AbstractControl<string> {
    return this.billingInfoForm.get('taxNumber');
  }

  get companyName(): AbstractControl<string> {
    return this.billingInfoForm.get('companyName');
  }

  get euVat(): AbstractControl<string> {
    return this.billingInfoForm.get('euVat');
  }

  private initCountrySubscription(): void {
    this.billingInfoForm.controls['country'].valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((countryCode: string): void => {
        if (countryCode === 'HU') {
          this.hideTaxNumber = false;

          // making tax number required if country code = 'HU'
          this.taxNumber.setValidators([Validators.required]);
          this.taxNumber.updateValueAndValidity();

          // making company name required if country code = 'HU'
          this.companyName.setValidators([Validators.required]);
          this.companyName.updateValueAndValidity();
        } else {
          this.hideTaxNumber = true;

          // making tax number optional if country code = 'HU'
          this.taxNumber.setValidators([]);
          this.taxNumber.reset('');
          this.taxNumber.updateValueAndValidity();

          // making company name optional if country code = 'HU'
          this.companyName.setValidators([]);
          this.companyName.updateValueAndValidity();
        }

        if (this._EU_COUNTRY_CODES.includes(countryCode)) {
          this.hideEUVat = false;
        } else {
          this.hideEUVat = true;
          this.euVat.reset('');
        }
      });
  }

  private initGetBillingInformationSubscription(): void {
    this.billingService
      .getBillingInformation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response): void => {
          console.warn(response);
          if (!Utils.isNullOrUndefined(response)) {
            if (typeof response === 'string') {
              const json = JSON.parse(response);
              for (const [key, value] of Object.entries(json)) {
                this.billingInfoForm.controls[key].setValue(value);
              }
            } else {
              for (const [key, value] of Object.entries(response)) {
                this.billingInfoForm.controls[key].setValue(value);
              }
            }
          }

          this.isLoading = false;
        },
        (error): void => {
          this.isLoading = false;
          this.hasError = true;
        }
      );
  }

  private initCountryList(): void {
    this._COUNTRY_LIST = this.countriesManagerService.getOnlyCountries();
    this.filteredCountries$ = of(this._COUNTRY_LIST);
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this._COUNTRY_LIST.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  public initFilter(): void {
    if (!this._filterInited) {
      this.filteredCountries$ = this.countryFilter.valueChanges.pipe(map((value) => this._filter(value)));
      this._filterInited = true;
    }
  }
}
