import { FuseNavigation } from '@fuse/types';
import { User } from '../service/user/user';
import { SupplierNavigation } from './navigation-routes/supplier/supplier.navigation';
import { CommonNavigation } from './navigation-routes/common/common.navigation';
import { RetailerCenterNavigation } from './navigation-routes/retailer/retailer-center.navigation';
import { SupplierCenterNavigation } from './navigation-routes/supplier/supplier-center.navigation';
import { ProductSearchSortField, ProductSearchSortOrder } from '../vo/search-product-vo';
import { MarketplaceView } from '../main/marketplace/explore-products/marketplace-view-selector/marketplace-view-selector.component';

// Admin menus' children
export const admin_navigation_children: FuseNavigation[] = [
  {
    id: 'kioskGroup',
    title: 'Admin',
    type: 'group',
    children: [
      {
        id: 'kiosk',
        title: 'KIOSK',
        type: 'item',
        icon: 'dashboard',
        url: '/admin/kiosk',
      },
      {
        id: 'managers-menu',
        title: 'Managers',
        type: 'collapsable',
        icon: 'build',
        children: [
          {
            id: 'elasticsearch-manager',
            title: 'Elasticsearch Manager',
            type: 'item',
            // icon: 'book',
            url: '/admin/elasticsearch-manager',
          },
          {
            id: 'session-manager',
            title: 'Session Manager',
            type: 'item',
            // icon: 'book',
            url: '/admin/session-manager',
          },
          {
            id: 'postman',
            title: 'Postman',
            type: 'item',
            // icon: 'book',
            url: '/admin/postman',
          },
          {
            id: 'general',
            title: 'General',
            type: 'item',
            // icon: 'book',
            url: '/admin/general',
          },
          {
            id: 'currency-exchange',
            title: 'Currency Exchange',
            type: 'item',
            // icon: 'book',
            url: '/admin/currency-exchange',
          },
          {
            id: 'product-management',
            title: 'MProducts Cleaner',
            type: 'item',
            // icon: 'book',
            url: '/admin/product-management',
          },
          {
            id: 'reset-run-count',
            title: 'Reset Run Count',
            type: 'item',
            // icon: 'book',
            url: '/admin/reset-run-count',
          },
          {
            id: 'email-changer',
            title: 'Email Changer',
            type: 'item',
            url: '/admin/email-changer',
          },
          {
            id: 'coupons',
            title: 'Coupons',
            type: 'item',
            url: '/admin/coupons',
          },
          {
            id: 'orders-manager',
            title: 'Orders',
            type: 'item',
            url: '/admin/orders-manager',
          },
          {
            id: 'kiosk-alibaba',
            title: 'Alibaba Management',
            type: 'item',
            url: '/admin/kiosk-alibaba',
          },
          {
            id: 'guide-manager',
            title: 'Guide Manager',
            type: 'item',
            url: '/admin/guide-manager',
          },
        ],
      },
      {
        id: 'logs-menu',
        title: 'Logs',
        type: 'collapsable',
        icon: 'pending_actions',
        children: [
          {
            id: 'exception-log',
            title: 'Exception Log',
            type: 'item',
            // icon: 'book',
            url: '/admin/exception-log',
          },
          {
            id: 'intercom-archive-log',
            title: 'Intercom Archive Log',
            type: 'item',
            // icon: 'book',
            url: '/admin/intercom-archive-log',
          },
        ],
      },
      {
        id: 'analytics',
        title: 'Analytics',
        type: 'collapsable',
        icon: 'analytics',
        children: [
          {
            id: 'admin_dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/admin/analytics-dashboard',
          },
          {
            id: 'admin_order_analytics',
            title: 'Order analytics',
            type: 'item',
            url: '/admin/order-analytics-admin',
          },
          {
            id: 'admin_customers_analytics',
            title: 'Customers analytics',
            type: 'item',
            url: '/admin/customers-analytics-admin',
          },
          {
            id: 'admin_suppliers_analytics',
            title: 'Suppliers analytics',
            type: 'item',
            url: '/admin/supplier-analytics',
          },
          {
            id: 'admin_subscriber_statistics',
            title: 'Subscriber statistics',
            type: 'item',
            url: '/admin/subscriber-statistics',
          },
          {
            id: 'admin_user_feedbacks',
            title: 'User feedbacks',
            type: 'item',
            url: '/admin/user-feedbacks',
          },
        ],
      },
      {
        id: 'supplier_kiosk',
        title: 'Supplier KIOSK',
        type: 'item',
        icon: 'local_shipping',
        url: '/admin/supplier-kiosk',
      },
      //
      {
        id: 'admin-settings',
        title: 'Admin Settings',
        type: 'collapsable',
        icon: 'settings_applications',
        children: [
          {
            id: 'admin-categories',
            title: 'Admin Categories',
            type: 'item',
            url: '/admin/admin-categories',
          },
          // {
          //     id: 'recommendation-settings',
          //     title: 'Recommendation Settings',
          //     type: 'item',
          //     url: '/recommendation-settings'
          // },
          {
            id: 'admin-notification-settings',
            title: 'Notification Settings',
            type: 'item',
            url: '/admin/admin-notification-settings',
          },
          {
            id: 'onboard-settings',
            title: 'Onboard Settings',
            type: 'item',
            url: '/admin/onboard-settings',
          },
          {
            id: 'chip-filter-settings',
            title: 'SP Chip Filter Settings',
            type: 'item',
            url: '/admin/chip-filter-settings',
          },
          {
            id: 'marketplace-settings',
            title: 'Marketplace Settings',
            type: 'item',
            url: '/admin/marketplace-settings',
          },
        ],
      },
      {
        id: 'changerole',
        title: 'Change Role',
        type: 'item',
        icon: 'swap_horizontal_circle',
        hidden: true,
        function: () => {},
      },
      // {
      //     id: 'logoutAdmin',
      //     title: 'Logout',
      //     type: 'item',
      //     icon: 'exit_to_app',
      //     function: () => {
      //
      //     }
      // }
    ],
  },
];

/**
 * Navigation items for USER_ROLE = SUPPLIER
 */
export const navigation: FuseNavigation[] = [
  {
    id: 'supplierNavigation',
    title: 'Supplier Navigations',
    translate: 'NAV.SUPPLIER_NAVIGATION_TITLE',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'supplierActivities',
        title: 'Activities',
        type: 'group',
        translate: 'NAV.SUPPLIER_ACTIVITIES',
        children: [
          {
            id: 'supplierDashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'speed',
            translate: 'NAV.DASHBOARD',
            url: SupplierCenterNavigation.DASHBOARD,
          },
          {
            id: 'my_products',
            title: 'Products',
            type: 'item',
            icon: 'sell',
            translate: 'NAV.MY_PRODUCTS',
            url: SupplierCenterNavigation.CATALOG.DEFAULT,
          },
          // {
          //   id: 'mytasks',
          //   title: 'Catalogs',
          //   type: 'collapsable',
          //   icon: 'list_alt',
          //   translate: 'NAV.PRODUCT_UPLOAD',
          //   children: [
          //     {
          //       id: 'datafeed_upload',
          //       title: 'DataFeed Manager',
          //       // icon: 'local_offer',
          //       type: 'item',
          //       translate: 'NAV.DATAFEED_UPLOAD',
          //       url: '/mytasks',
          //     },
          //     {
          //       id: 'manual_upload',
          //       title: 'Manual Upload',
          //       // icon: 'local_offer',
          //       type: 'item',
          //       translate: 'NAV.MANUAL_UPLOAD',
          //       url: '/pim-products',
          //     },
          //   ],
          // },
          {
            id: 'manage_orders',
            title: 'Orders',
            type: 'item',
            icon: 'inbox',
            translate: 'NAV.MANAGE_ORDERS',
            url: SupplierCenterNavigation.ORDERS,
          },
          {
            id: 'my-retailers',
            title: 'Retailers',
            type: 'item',
            icon: 'person',
            translate: 'NAV.MY_RETAILERS',
            url: SupplierCenterNavigation.MY_RETAILERS.DEFAULT,
          },
          {
            id: 'storefrontPreview',
            title: 'Storefront',
            type: 'item',
            icon: 'storefront',
            translate: 'NAV.STOREFRONT_PREVIEW',
            url: SupplierCenterNavigation.STOREFRONT_SETTINGS.DEFAULT,
          },
          {
            id: 'inmail',
            title: 'Messages',
            type: 'item',
            icon: 'chat_bubble_outline',
            translate: 'NAV.MESSENGER',
            url: SupplierCenterNavigation.MESSAGES,
          },
          {
            id: 'affilate_partners_dashboard',
            title: 'Referrals',
            type: 'item',
            icon: 'campaign',
            translate: 'NAV.BECOME_PARTNER',
            url: CommonNavigation.REFERRALS,
          },
        ],
      },
      {
        id: 'supplierMyAccount',
        type: 'group',
        title: 'My Account',
        translate: 'NAV.SUPPLIER_MY_ACCOUNT',
        children: [
          {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            icon: 'settings',
            translate: 'NAV.SETTINGS',
            url: SupplierNavigation.PROFILE.DEFAULT,
          },
          // {
          //   id: 'supplier_billing',
          //   title: 'Billing',
          //   type: 'item',
          //   icon: 'credit_card',
          //   // translate: 'NAV.SETTINGS',
          //   function: () => {},
          // },
        ],
      },
      {
        id: 'supplierNeedHelp',
        type: 'group',
        title: 'Need Help?',
        translate: 'NAV.SUPPLIER_NEED_HELP',
        children: [
          {
            id: 'supplierHelpCenter',
            title: 'Help Center',
            type: 'item',
            icon: 'help_outline',
            translate: 'NAV.HELP_CENTER',
            externalUrl: true,
            openInNewTab: true,
            function: () => {
              window.open(`https://help.syncee.io/${getLanguage()}`, '_blank');
            },
          },
        ],
      },
    ],
  },
];

export const navigation_bottom: FuseNavigation[] = [
  {
    id: 'navigationBottom',
    title: 'Navigations Bottom',
    translate: 'NAV.NAVIGATION_BOTTOM',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'notification',
        title: 'Notification',
        type: 'item',
        icon: 'notifications',
        translate: 'NAV.NOTIFICATION',
        function: () => {},
      },
      {
        id: 'profile',
        title: 'Profile',
        type: 'collapsable',
        icon: 'settings',
        children: [
          {
            id: 'myprofile',
            title: 'Profile',
            type: 'item',
            icon: 'person_outline',
            translate: 'NAV.PROFILE',
            url: '/supplier-profile',
          },
          {
            id: 'myShops',
            title: 'My shops',
            type: 'item',
            icon: 'store_mall_directory',
            translate: 'NAV.MY_SHOPS_SUPPLIER',
            url: '/my-shops',
          },
          {
            id: 'activitylog',
            title: 'Activity Log',
            type: 'item',
            icon: 'assignment',
            translate: 'NAV.ACTIVITY_LOG',
            url: '/activity_log',
          },
          // {
          //     id: 'changetoretailer',
          //     title: 'Switch to Retailer mode',
          //     type: 'item',
          //     icon: 'person_outline',
          //     translate: 'NAV.CHANGE_TO_RETAILER',
          //     url: '/confirm',
          //     function: () => {
          //         // localStorage.setItem('actualRole', USER_ROLES.AGENT.toString());
          //         // location.reload();
          //     }
          // },
          {
            id: 'helpcenter',
            title: 'Help center',
            type: 'item',
            icon: 'not_listed_location',
            translate: 'NAV.HELP_CENTER',
            // url: 'https://help.syncee.io/',
            externalUrl: true,
            openInNewTab: true,
            function: () => {
              window.open(`https://help.syncee.io/${getLanguage()}`, '_blank');
            },
          },
        ],
      },
    ],
  },
];

/**
 * Navigation items for USER_ROLE = RETAILER
 */
export const navigation_agency: FuseNavigation[] = [
  // agentNavigation
  {
    id: 'agentNavigation',
    title: 'Retailer Navigations',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'activities',
        title: 'Activities',
        type: 'group',
        translate: 'NAV.ACTIVITIES',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'speed',
            translate: 'NAV.DASHBOARD',
            url: RetailerCenterNavigation.DASHBOARD,
          },
          {
            id: 'my_catalogs',
            title: 'My Catalogs',
            type: 'item',
            icon: 'list_alt',
            translate: 'NAV.MY_CATALOGS',
            url: RetailerCenterNavigation.IMPORT_LIST,
          },
          {
            id: 'manage_orders',
            title: 'Orders',
            type: 'item',
            icon: 'inbox',
            translate: 'NAV.MANAGE_ORDERS',
            url: RetailerCenterNavigation.ORDERS,
          },
          {
            id: 'favorites',
            title: 'Favorites',
            type: 'item',
            icon: 'favorite_border',
            translate: 'NAV.FAVORITES',
            url: RetailerCenterNavigation.FAVORITES.DEFAULT,
            function: () => {},
          },
          {
            id: 'my_suppliers',
            title: 'My Suppliers',
            type: 'item',
            icon: 'people_outline',
            translate: 'NAV.MY_SUPPLIERS',
            url: RetailerCenterNavigation.MY_SUPPLIERS,
          },
          {
            id: 'inmail',
            title: 'Messenger',
            type: 'item',
            icon: 'chat_bubble_outline',
            translate: 'NAV.MESSENGER',
            url: RetailerCenterNavigation.MESSAGES,
          },
          {
            id: 'affilate_partners_dashboard',
            title: 'Affilate',
            type: 'item',
            icon: 'campaign',
            translate: 'NAV.BECOME_PARTNER',
            url: CommonNavigation.REFERRALS,
          },
        ],
      },
      {
        id: 'my_account',
        title: 'My Account',
        type: 'group',
        translate: 'NAV.SUPPLIER_MY_ACCOUNT',
        children: [
          {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            icon: 'settings',
            translate: 'NAV.RETAILER_SETTINGS',
            url: RetailerCenterNavigation.SETTINGS.DEFAULT,
          },
          {
            id: 'billing',
            title: 'Billing',
            type: 'item',
            icon: 'credit_card',
            translate: 'NAV.RETAILER_BILLING',
            url: RetailerCenterNavigation.BILLING.DEFAULT,
          },
        ],
      },
      {
        id: 'apps',
        title: 'Apps',
        type: 'group',
        translate: 'NAV.APPS',
        children: [
          {
            id: 'all_apps',
            title: 'All Apps',
            type: 'item',
            icon: 'apps',
            translate: 'NAV.ALL_APPS',
            url: RetailerCenterNavigation.APPS,
          },
        ],
      },
      {
        id: 'need_help',
        title: 'Need Help?',
        type: 'group',
        translate: 'NAV.NEED_HELP',
        children: [
          {
            id: 'helpcenter',
            title: 'Help center',
            type: 'item',
            icon: 'help_outline',
            translate: 'NAV.HELP_CENTER',
            externalUrl: true,
            openInNewTab: true,
            function: () => {
              window.open(`https://help.syncee.io/${getLanguage()}`, '_blank');
            },
          },
        ],
      },
    ],
  },
];

export const navigation_retailer_bottom: FuseNavigation[] = [
  {
    id: 'navigationBottom',
    title: 'Navigations Bottom',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'helpcenter',
        title: 'Help center',
        type: 'item',
        icon: 'help_outline',
        translate: 'NAV.HELP_CENTER',
        // url: 'https://help.syncee.io/',
        externalUrl: true,
        openInNewTab: true,
        function: () => {
          window.open(`https://help.syncee.io/${getLanguage()}`, '_blank');
        },
      },
    ],
  },
];

/**
 * Navigation items for USER_ROLE = GENERAL
 */
export const navigation_general: FuseNavigation[] = [
  // generalNavigation
  {
    id: 'generalNavigation',
    title: 'General Navigations',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'myprofile',
        title: 'Profile',
        type: 'item',
        icon: 'person_outline',
        url: '/supplier-details',
      },
    ],
  },
];

/**
 * Navigation items ONLY for USER_ROLE = GLOBAL_ADMIN
 */
export const navigation_admin: FuseNavigation[] = [
  // generalNavigation
  {
    id: 'adminNavigation',
    title: 'Admin Navigations',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    hideTitle: true,
    children: admin_navigation_children,
  },
];

export const navigation_admin_with_collapse: FuseNavigation[] = [
  // generalNavigation
  {
    id: 'adminNavigation',
    title: 'Admin Navigations',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'admin_collapse',
        title: 'Admin',
        type: 'collapsable',
        icon: 'local_offer',
        children: admin_navigation_children,
      },
    ],
  },
];

export const navigation_admin_with_collapse_supplier: FuseNavigation[] = [
  // generalNavigation
  {
    id: 'adminNavigation',
    title: 'Admin Navigations',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'admin_collapse',
        title: 'Global Admin Supplier navigation',
        type: 'collapsable',
        icon: 'supervised_user_circle',
        children: navigation,
      },
    ],
  },
];

export const navigation_admin_with_collapse_retailer: FuseNavigation[] = [
  // generalNavigation
  {
    id: 'adminNavigation',
    title: 'Admin Navigations',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'admin_collapse',
        title: 'Global Admin Retailer navigation',
        type: 'collapsable',
        icon: 'supervised_user_circle',
        children: navigation_agency,
      },
    ],
  },
];

export const mobile_menu_upper_navigation: FuseNavigation[] = [
  {
    id: 'mobile_menu_upper_navigation',
    title: '',
    type: 'group',
    hideTitle: true,
    children: [
      {
        id: 'new_arrivals',
        type: 'item',
        title: 'New Arrivals',
        url: '/collections/1/products',
        marketplaceFilter: true,
        exactMatch: true,
        queryParams: {
          sortField: ProductSearchSortField.CREATED,
          sortOrder: ProductSearchSortOrder.DESC,
          category: 1,
          searchType: 'PRODUCTS' as MarketplaceView,
        },
        translate: 'MOBILE_MAIN_MENU.NEW_ARRIVALS',
      },
      {
        id: 'bestsellers',
        type: 'item',
        url: '/collections/1/products',
        title: 'Bestsellers',
        marketplaceFilter: true,
        exactMatch: true,
        queryParams: {
          category: 1,
          searchType: 'PRODUCTS' as MarketplaceView,
        },
        translate: 'MOBILE_MAIN_MENU.BESTSELLERS',
      },
      {
        id: 'premium_suppliers',
        type: 'item',
        url: '/collections/1/products',
        title: 'Premium Suppliers',
        exactMatch: true,
        marketplaceFilter: true,
        queryParams: {
          category: 1,
          premium: true,
        },
        translate: 'MOBILE_MAIN_MENU.PREMIUM_SUPPLIERS',
      },
    ],
  },
];

/**
 * Navigation items for USER_ROLE = GLOBAL_ADMIN
 * All the navigations combined for admin
 */
// export const navigation_global_admin: FuseNavigation[] =
// [].concat(navigation_admin, navigation, navigation_agency, navigation_general);
export const navigation_global_admin: FuseNavigation[] = navigation_admin;

export const navigation_global_admin_as_own_supplier: FuseNavigation[] = [].concat(
  navigation_admin,
  navigation_admin_with_collapse_supplier
);

export const navigation_global_admin_as_own_retailer: FuseNavigation[] = [].concat(
  navigation_admin,
  navigation_admin_with_collapse_retailer
);

export const navigation_global_admin_as_supplier: FuseNavigation[] = [].concat(
  navigation_admin_with_collapse,
  navigation
);

export const navigation_global_admin_as_agency: FuseNavigation[] = [].concat(
  navigation_admin_with_collapse,
  navigation_agency
);

function getUser(): User {
  return JSON.parse(localStorage.getItem('currentUser'));
}

function getLanguage(): string {
  const user = getUser();
  return user && user.language ? user.language : '';
}

// function isAdmin(): boolean {
//   const user = getUser();
//   return user && user.role === 3;
// }
