<div class="cancel-dialog-reason-container" *ngIf="!isLoading; else spinner">
  <div class="step-title typography-body-1">
    {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.TITLE' | translate }}
  </div>
  <div>
    <div>
      <form [formGroup]="form">
        <div *ngFor="let reason of reasons" class="reason-list">
          <mat-checkbox [formControlName]="reason" [disabled]="optionsToDisable.includes(reason)">{{
            'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.OPTIONS.' + reason | translate
          }}</mat-checkbox>
        </div>
        <div>
          <mat-error *ngIf="(form.touched || form.dirty) && form.errors?.atLeast1Checked">{{
            'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.SELECT_ONE' | translate
          }}</mat-error>
        </div>
        <div>
          <mat-form-field class="w-100p" appearance="fill">
            <mat-label>{{
              (isCommentOptional
                ? 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.COMMENT_OPTIONAL'
                : 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.COMMENT'
              ) | translate
            }}</mat-label>
            <textarea matInput [formControlName]="COMMENT_ID" rows="5"></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div fxLayout.gt-sm="row">
      <div fxFlex.gt-sm="1 1 50%">
        <ul>
          <li>
            {{
              'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.WILL_BE_CANCELED'
                | translate : { date: (selectedEcom.subscribed | date : 'short') }
            }}
          </li>
        </ul>
      </div>
      <div fxFlex.gt-sm="1 1 50%">
        <ul>
          <li>
            {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.REASON.KEEP_DATA' | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div fxLayoutAlign="center center">
    <mat-spinner diameter="60"></mat-spinner>
  </div>
</ng-template>
