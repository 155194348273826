<div class="outer-container" [ngClass]="wrapperClass">
  <div
    class="inner-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="28px"
    [ngClass]="innerWrapperClass"
  >
    <app-section-title [title]="titleKey | translate"></app-section-title>
    <div fxLayout="row" fxLayoutAlign="center center" class="slider-container">
      <ngx-slick-carousel
        fxFlex="1 0 0"
        #slickModal
        class="brand-slider-items"
        [@animateStagger]="{ value: '50' }"
        [config]="config"
        (afterChange)="handleAfterChange()"
        (beforeChange)="handleBeforeChange()"
      >
        <ng-container *ngFor="let brand of widgetContent; let i = index; let last = last">
          <div ngxSlickItem class="brand-container">
            <img
              [src]="brand.image"
              [alt]="brand.titles | widgetTranslatePipe : 'title'"
              class="brand-image"
              (click)="emitBrandClicked(brand)"
            />
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
