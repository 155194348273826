<div *conditionLoading="loadingStatus" fxLayout="column" fxLayoutGap="8px">
  <div fxLayout="column" fxLayoutGap="20px">
    <div
      *ngIf="connectedData && !!connectedData.activated"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <div class="typography-body-large-2">
        {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.DISCONNECT_STRIPE_ACCOUNT' | translate }}
      </div>
      <app-custom-icon [icon]="'check'" [type]="'success'"></app-custom-icon>
    </div>

    <div
      *ngIf="!connectedData || !connectedData.activated"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <div class="typography-body-large-2">
        {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.CONNECT_WITH_STRIPE' | translate }}
      </div>
      <app-custom-icon [icon]="'close'" [type]="'danger'"></app-custom-icon>
    </div>
    <ng-container [ngSwitch]="true">
      <div *ngSwitchCase="!!connectedData && !!connectedData.activated && !connectedData.expired">
        <div
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-sm="center end"
          fxLayoutGap="10px"
        >
          <div>
            <strong>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.CONNECTED_STRIPE_ACCOUNT' | translate }}</strong>
            {{ connectedData.accountId }}
          </div>
          <button mat-button color="warn" (click)="disconnect()">
            {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.DISCONNECT_BTN' | translate }}
          </button>
        </div>
      </div>
      <div *ngSwitchCase="!!connectedData && !connectedData.activated && !connectedData.expired">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div [ngStyle]="{ 'padding-bottom': '5px' }">
            {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.ACTIVATE_YOUR_ACCOUNT' | translate }}
          </div>
          <button mat-button class="syncee-blue-button" (click)="completeConnect(connectedData.accountId)">
            <div [ngStyle]="{ padding: '5px' }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.SUBMIT_BTN' | translate }}</div>
              <mat-icon matTooltip="{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.SUBMIT_TOOLTIP' | translate }}"
                >info
              </mat-icon>
            </div>
          </button>
          <button mat-button class="try-again-btn" (click)="openDialogForNewLink()">
            <div [ngStyle]="{ padding: '5px' }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK.AGAIN_BTN' | translate }}</div>
              <mat-icon matTooltip="{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.RECONNECT_TOOLTIP' | translate }}"
                >info
              </mat-icon>
            </div>
          </button>
        </div>
      </div>
      <div *ngSwitchCase="!!connectedData && !!connectedData.expired">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.SETUP_LINK_EXPIRED' | translate }}</div>
          <button mat-button class="syncee-blue-button" (click)="generateAccountLink()">
            {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK_BTN' | translate }}
          </button>
        </div>
      </div>
      <div *ngSwitchCase="!connectedData" fxLayout="column" fxLayoutGap="20px">
        <div class="stripe-connect" (click)="startConnect()">
          <span>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.CONNECT_WITH' | translate }}</span>
        </div>
        <div *ngIf="deletedAccounts.length > 0" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
          <div class="typography-body-1">
            {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.MESSAGE' | translate }}
          </div>

          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="20px"
            fxLayoutAlign="stretch center"
            fxLayoutAlign.lt-md="center center"
          >
            <mat-form-field id="domain-selector" fxFlex="1 0 0" class="w-100p input-without-padding" appearance="fill">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.DISCONNECTED_ACCOUNTS' | translate }}</mat-label>
              <mat-select panelClass="mat-select-display-change" [(value)]="selectedAccount">
                <mat-option *ngFor="let account of deletedAccounts" [value]="account">
                  {{ account.accountId }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button (click)="reUseAccount()" mat-button class="add-new-store syncee-blue-button">
              {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.CONNECTION' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!--  <div fxLayout="row" fxLayoutAlign="end center">-->
  <!--    <img-->
  <!--      class="h-32"-->
  <!--      src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"-->
  <!--    />-->
  <!--  </div>-->
</div>
