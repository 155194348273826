import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule],
})
export class AlertComponent implements OnInit {
  @Input() type;
  @Input() text;

  constructor() {}

  ngOnInit(): void {}

  isInfo(): boolean {
    return this.type === 'info';
  }

  isWarning(): boolean {
    return this.type === 'warning';
  }

  isDanger(): boolean {
    return this.type === 'danger';
  }
}
