import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImageSeo } from '../../../vo/image-seo';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { GeneralButtonComponent } from '../general-button/general-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';

@Component({
  selector: 'app-article-cards-row',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, GeneralButtonComponent, TranslateModule, SectionTitleComponent],
  templateUrl: './article-cards-row.component.html',
  styleUrls: ['./article-cards-row.component.scss'],
})
export class ArticleCardsRowComponent implements OnInit {
  @Input() cards: ArticleCardsRowItem[];
  @Input() wrapperClass: string | string[];
  @Input() innerClass: string | string[];
  @Input() title: string;
  @Input() description: string;

  constructor() {}

  ngOnInit(): void {}
}

export interface ArticleCardsRowItem {
  id: number;
  image: ImageSeo;
  subtitleKey: string;
  titleKey: string;
  descriptionKey: string;
  button: GeneralButton;
}
