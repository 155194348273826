import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'getAssetFromStorage',
  standalone: true,
})
export class GetAssetFromStoragePipe implements PipeTransform {
  transform(resourcePath: string): string {
    return `${environment.assetsUrl}/${resourcePath}`;
  }
}
