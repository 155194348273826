import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { IndexTableFilterDropdownComponent } from './index-table-filter-dropdown/index-table-filter-dropdown.component';
import { Choice, TableFilterItem } from '../filter-factory/filter-factory.component';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-index-table-filter',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
    IndexTableFilterDropdownComponent,
    TranslateModule,
  ],
  templateUrl: './index-table-filter.component.html',
  styleUrls: ['./index-table-filter.component.scss'],
})
export class IndexTableFilterComponent<T> implements OnInit {
  @Input() type: string;
  @Input() labelKey: string;
  @Input() choices: Choice<T>[];
  @Input() static = true;
  @Input() multi = false;
  @Input() radio = false;
  @Input() fetcher: (from: number, size: number, searchTerm?: string) => Observable<T[]>;
  @Input() hasSearch = false;
  @Input() size = 10;
  @Input() choiceLabelProperty: string;
  @Input() selectedChoice: TableFilterItem;
  @Output() selectedChoiceChanged: EventEmitter<TableFilterItem> = new EventEmitter<TableFilterItem>();

  dropdownOpen = false;

  constructor() {}

  ngOnInit(): void {}

  findChoice(value: T): Choice<T> {
    return this.choices.find((choice) => isEqual(choice.value, value));
  }
}
