<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px"
  class="w-100-p"
  @expandCollapse
  *ngIf="openFilters"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="w-100-p">
    <ng-container *ngFor="let filter of filterArray">
      <app-index-table-filter
        [type]="filter.type"
        [labelKey]="filter.labelKey"
        [choices]="filter.choices"
        [static]="filter.static"
        [multi]="filter.multi"
        [radio]="filter.radio"
        [hasSearch]="filter.hasSearch"
        [fetcher]="filter.fetcher"
        [size]="filter.size"
        [choiceLabelProperty]="filter.choiceLabelProperty"
        [selectedChoice]="findActiveFilterByType(filter.type)"
        (selectedChoiceChanged)="filterChange.emit($event)"
      ></app-index-table-filter>
    </ng-container>
    <app-index-table-date-picker
      *ngIf="enableDatePicker"
      (dateFromChange)="dateFromChange.emit($event)"
      (dateToChange)="dateToChange.emit($event)"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
    >
    </app-index-table-date-picker>
    <div
      *ngIf="!!activeFilters && activeFilters.length > 0"
      class="typography-link cursor-pointer no-wrap"
      (click)="clearAllClicked.emit()"
    >
      {{ 'INDEX_FILTER.CLEAR_ALL' | translate }}
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" class="w-100-p">
    <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
  </div>
</div>
