import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { OverflowDirective } from 'app/shared/directives/overflow.directive';
import { UserService } from '../../../service/user/user.service';
import { TaskTypes } from '../../../utils/Constants';

@Component({
  selector: 'app-history-timeline',
  templateUrl: './history-timeline.component.html',
  styleUrls: ['./history-timeline.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatCardModule,
    MatTooltipModule,
    OverflowDirective,
  ],
})
export class HistoryTimelineComponent implements OnInit, AfterViewInit {
  @Input() taskType: TaskTypes;
  @ViewChild('hourCell', { static: false }) hourCellRef: ElementRef;
  @ViewChild('taskName', { static: false }) taskNameRef: ElementRef;
  @ViewChild('nowCell', { static: false }) nowCellRef: ElementRef;
  @ViewChild('overLay', { static: false }) overLayRef: ElementRef;

  numberOfHours = Array(168);
  minuteLength: number;

  now = new Date();
  startDate: any;
  dates: any;
  isOnMobile;

  taskHistories = [{ histories: [] }];

  @HostListener('window:resize', ['$event'])
  onResize(event): any {
    this.calculateMinuteLength();
    this.calculateBarLength();
  }

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this.generateDates();
    this.isOnMobile = window.screen.width <= 1050;
  }

  ngAfterViewInit(): void {
    this.getHistoryOfTasks();
  }

  getHistoryOfTasks(): void {
    this.userService.getHistoryOfUserTasks(this.taskType).subscribe((data) => {
      this.taskHistories = data;
      this.calculateMinuteLength();
      this.calculateBarLength();
    });
  }

  generateDates(): any {
    const dates = [];
    const lastDate = new Date();
    lastDate.setHours(lastDate.getHours(), 0, 0);

    const firstDate = new Date();
    firstDate.setDate(firstDate.getDate() - 7);
    firstDate.setHours(firstDate.getHours(), 0, 0);
    this.startDate = new Date(firstDate);
    do {
      dates.push(firstDate.toLocaleString().split(','));
      dates.push(['', '']);
      firstDate.setHours(firstDate.getHours() + 2);
    } while (firstDate < lastDate);
    dates.push(lastDate.toLocaleString().split(','));

    this.dates = dates;
  }

  calculateBarLength(): void {
    this.taskHistories.forEach((task) => {
      task.histories.forEach((history) => {
        const lastTick = new Date(history.h_lastTick);
        const date = new Date(history.h_date);
        const barLength = ((lastTick.getTime() - date.getTime()) / 60000) * this.minuteLength;

        history['barLength'] = barLength < 1 ? 4 : barLength;
        // history['barData'] = this.getItemsForProgressBar(history);
        history['sum'] =
          history['h_resultUpdated'] +
          history['h_resultNew'] +
          history['h_resultDeleted'] +
          history['h_resultNotModified'];
        history['startPos'] = ((date.getTime() - this.startDate.getTime()) / 60000) * this.minuteLength;
      });
    });
  }

  calculateMinuteLength(): void {
    this.minuteLength = this.hourCellRef.nativeElement.offsetWidth / 60;
  }

  getItemsForProgressBar(history: any): any {
    return [
      {
        label: 'Updated',
        value: history.resultUpdated,
        background: 'dodgerblue',
      },
      {
        label: 'New',
        value: history.resultNew,
        background: 'green',
      },
      {
        label: 'Deleted',
        value: history.resultDeleted,
        background: 'red',
      },
      {
        label: 'Not modified',
        value: history.resultNotModified,
        background: 'grey',
      },
    ];
  }

  trackByFn(index, item): any {
    return index;
  }

  trackByFnHistory(index, item): any {
    return item.entityId;
  }

  get userService(): UserService {
    return this._userService;
  }

  set userService(value: UserService) {
    this._userService = value;
  }
}
