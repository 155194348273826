import { EcomFeatureUsage } from '../../service/usage/model/ecom/ecom-feature-usage';
import { UsageAction, UsageActionType } from './usage.actions';
import { UserFeatureUsage } from '../../service/usage/model/user/user-feature-usage';

export interface UsageState {
  ecom: EcomFeatureUsage[];
  user: UserFeatureUsage;
}

const initState: UsageState = {
  ecom: undefined,
  user: undefined,
};

export function usageReducer(state = initState, action: UsageAction): UsageState {
  switch (action.type) {
    case UsageActionType.GET_USER_USAGE_SUCCESS:
      return { ...state, user: action.payload };
    case UsageActionType.GET_ECOM_USAGE_SUCCESS:
      return { ...state, ecom: action.payload };
    case UsageActionType.EDIT_ECOM_USAGE:
      return {
        ...state,
        ecom: state.ecom.map((usage) => (usage.ecomId === action.payload.ecomId ? action.payload.usage : usage)),
      };
    case UsageActionType.EDIT_USER_USAGE:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
