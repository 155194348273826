<div
  [style.backdrop-filter]="blurValue"
  [style.-webkit-backdrop-filter]="blurValue"
  class="blur"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
>
  <ng-content></ng-content>
</div>
