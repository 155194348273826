<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <app-alert-multi
    *ngIf="saveMappingWarning"
    type="warning"
    [messages]="['TASKWIZARD.ADDFILES.MAPPING.YOU_HAVE' | translate]"
  ></app-alert-multi>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
    <div class="mapping-container" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
      <form
        #productDetatailsForm="ngForm"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayoutGap="32px"
        fxFlex="1 0 auto"
        name="form"
      >
        <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.BASIC_FIELDS' | translate }}</div>
        <div class="product-details">
          <div
            *ngIf="mappingData.productIds"
            id="product-id-container"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
          >
            <div class="mr-0">
              <div
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'ID'"
                [adminCommentComments]="comments"
              ></div>
            </div>
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="ID_{{ prod.id }}"
              name="productId_{{ prod.id }}"
              class="m-w-0"
              [(ngModel)]="prod.value.chips"
              [(operations)]="prod.value.operations"
              *ngFor="let prod of mappingData.productIds"
              fxFlex="{{ 100 / mappingData.productIds.length }}"
              fxFlex.lt-md="1 0 0"
              title="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_ID_PLACEHOLDER' | translate }} - {{ prod.placeholder }}"
              placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_ID_PLACEHOLDER' | translate }} {{
                prod.placeholder
              }}"
              [prodSampleInputVo]="prodSampleInputVo"
            >
            </app-droppable-input>
          </div>
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'TITLE'"
            [adminCommentComments]="comments"
            #normalProcess
            [required]="true"
            id="TITLE"
            title="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_NAME_PLACEHOLDER' | translate }}"
            name="TITLE"
            [(ngModel)]="mappingData.singleFields.TITLE.chips"
            [(operations)]="mappingData.singleFields.TITLE.operations"
            placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_NAME_PLACEHOLDER' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
          <div id="product-variants-container" fxLayout="column" fxLayoutAlign="start">
            <div id="product-price-and-stuff-container">
              <div fxLayout="column" fxLayoutAlign="start">
                <div
                  fxFlex="1 0 0"
                  class="m-w-0"
                  fxLayout="row"
                  fxLayout.lt-lg="column"
                  fxLayoutAlign="start"
                  fxLayoutGap="20px"
                  fxLayoutGap.lt-lg="0"
                >
                  <div fxFlex="1 0 0" class="m-w-0">
                    <app-droppable-input
                      adminComment
                      [adminCommentTaskId]="taskId"
                      [adminCommentTaskType]="'import'"
                      [adminCommentStep]="'mapping'"
                      [adminCommentMappingField]="'PRICE'"
                      [adminCommentComments]="comments"
                      [isAlternativeFields]="isAdmin"
                      #normalProcess
                      [required]="priceFieldIsRequired"
                      id="PRICE"
                      title="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_PRICE_PLACEHOLDER' | translate }}"
                      name="PRICE"
                      [isPriceSettings]="true"
                      [(ngModel)]="mappingData.singleFields.PRICE.chips"
                      [(settings)]="mappingData.singleFields.PRICE.settings"
                      [(operations)]="mappingData.singleFields.PRICE.operations"
                      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_PRICE_PLACEHOLDER' | translate }}"
                      [prodSampleInputVo]="prodSampleInputVo"
                    >
                    </app-droppable-input>
                  </div>
                  <div fxFlex="1 0 0" class="m-w-0">
                    <app-droppable-input
                      adminComment
                      [adminCommentTaskId]="taskId"
                      [adminCommentTaskType]="'import'"
                      [adminCommentStep]="'mapping'"
                      [adminCommentMappingField]="'ORIGINAL_PRICE'"
                      [adminCommentComments]="comments"
                      #normalProcess
                      id="ORIGINAL_PRICE"
                      title="{{ 'TASKWIZARD.ADDFILES.MAPPING.ORIGINAL_PRICE_PLACEHOLDER' | translate }}"
                      name="ORIGINAL_PRICE"
                      [isPriceSettings]="true"
                      [(ngModel)]="mappingData.singleFields.ORIGINAL_PRICE.chips"
                      [(settings)]="mappingData.singleFields.ORIGINAL_PRICE.settings"
                      [(operations)]="mappingData.singleFields.ORIGINAL_PRICE.operations"
                      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.ORIGINAL_PRICE_PLACEHOLDER' | translate }}"
                      [prodSampleInputVo]="prodSampleInputVo"
                    >
                    </app-droppable-input>
                  </div>
                </div>
                <div
                  fxFlex="1 0 0"
                  class="m-w-0"
                  fxLayout="row"
                  fxLayout.lt-lg="column"
                  fxLayoutAlign="start"
                  fxLayoutGap="20px"
                  fxLayoutGap.lt-lg="0"
                >
                  <div fxFlex="1 0 0" class="m-w-0">
                    <app-droppable-input
                      adminComment
                      [adminCommentTaskId]="taskId"
                      [adminCommentTaskType]="'import'"
                      [adminCommentStep]="'mapping'"
                      [adminCommentMappingField]="'DISCOUNTED_PRICE'"
                      [adminCommentComments]="comments"
                      #normalProcess
                      id="DISCOUNTED_PRICE"
                      title="{{ 'TASKWIZARD.ADDFILES.MAPPING.DISCOUNTED_PRICE_PLACEHOLDER' | translate }}"
                      name="DISCOUNTED_PRICE"
                      [isPriceSettings]="true"
                      [(ngModel)]="mappingData.singleFields.DISCOUNTED_PRICE.chips"
                      [(settings)]="mappingData.singleFields.DISCOUNTED_PRICE.settings"
                      [(operations)]="mappingData.singleFields.DISCOUNTED_PRICE.operations"
                      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.DISCOUNTED_PRICE_PLACEHOLDER' | translate }}"
                      [prodSampleInputVo]="prodSampleInputVo"
                    >
                    </app-droppable-input>
                  </div>
                  <div fxFlex="1 0 0" class="m-w-0">
                    <app-droppable-input
                      adminComment
                      [adminCommentTaskId]="taskId"
                      [adminCommentTaskType]="'import'"
                      [adminCommentStep]="'mapping'"
                      [adminCommentMappingField]="'QTY'"
                      [adminCommentComments]="comments"
                      #normalProcess
                      id="QTY"
                      title="{{ 'TASKWIZARD.ADDFILES.MAPPING.QUANTITY_PLACEHOLDER' | translate }}"
                      name="QTY"
                      [(ngModel)]="mappingData.singleFields.QTY.chips"
                      [(operations)]="mappingData.singleFields.QTY.operations"
                      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.QUANTITY_PLACEHOLDER' | translate }}"
                      [prodSampleInputVo]="prodSampleInputVo"
                    >
                    </app-droppable-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-droppable-multiline-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'DESCRIPTION'"
            [adminCommentComments]="comments"
            id="DESCRIPTION"
            title="Description"
            [(chips)]="mappingData.singleFields.DESCRIPTION.chips"
            [(operations)]="mappingData.singleFields.DESCRIPTION.operations"
          >
          </app-droppable-multiline-input>
        </div>
        <app-custom-divider></app-custom-divider>
        <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_IMAGE' | translate }}</div>
        <div id="product-images-container" fxLayout="column" fxLayoutAlign="end" fxFlex="1 1 auto">
          <div
            id="product-images-info"
            class="product-imgs"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
            ngModelGroup="image"
            *ngFor="let img of mappingData.images; last as isLast; let i = index"
          >
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'IMAGES'"
                [adminCommentComments]="comments"
                #imagesURLProcess
                id="IMAGES"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_URL_PLACEHOLDER' | translate }}"
                name="IMAGES-{{ i }}"
                [(ngModel)]="img.url.chips"
                [(operations)]="img.url.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_URL_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
            <div fxFlex="1 0 0" class="m-w-0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
              <div fxFlex="1 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'IMAGES_ALTS'"
                  [adminCommentComments]="comments"
                  #imagesALTProcess
                  id="IMAGES_ALTS"
                  name="IMAGES_ALTS-{{ i }}"
                  [(ngModel)]="img.value.chips"
                  [(settings)]="img.value.settings"
                  [(operations)]="img.value.operations"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_ALT_PLACEHOLDER' | translate }}"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_ALT_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div class="icon-holder" fxLayoutAlign="start center">
                <button mat-icon-button *ngIf="i !== 0" (click)="mappingData.removeProdImage(i)">
                  <mat-icon class="s-20" aria-label="Delete icon"> delete </mat-icon>
                </button>
                <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProdImage()">
                  <mat-icon class="s-20" aria-label="new Image icon"> add_circle_outline </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.VARIANT_IMAGES' | translate }}</div>
        <div id="prodimg-warranty-container" fxLayout="column" fxLayoutAlign="end" fxFlex="1 1 auto">
          <div
            id="prodimg-warranty-images-info"
            class="product-imgs"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
            ngModelGroup="image"
          >
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'V_IMAGES'"
                [adminCommentComments]="comments"
                #normalProcess
                id="V_IMAGES"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_URL_PLACEHOLDER' | translate }}"
                name="V_IMAGES"
                [(ngModel)]="mappingData.singleFields.V_IMAGES.chips"
                [(operations)]="mappingData.singleFields.V_IMAGES.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_URL_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'V_IMAGES_ALTS'"
                [adminCommentComments]="comments"
                #normalProcess
                id="V_IMAGES_ALTS"
                name="V_IMAGES_ALTS"
                [(ngModel)]="mappingData.singleFields.V_IMAGES_ALTS.chips"
                [(operations)]="mappingData.singleFields.V_IMAGES_ALTS.operations"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_ALT_PLACEHOLDER' | translate }}"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_ALT_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <div class="h5 mb-20">Product organization</div>
        <div id="product-categories" fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'CATEGORY'"
            [adminCommentComments]="comments"
            #normalProcess
            name="CATEGORY"
            [(ngModel)]="mappingData.singleFields.CATEGORY.chips"
            [(operations)]="mappingData.singleFields.CATEGORY.operations"
            id="CATEGORY"
            title="{{ 'TASKWIZARD.ADDFILES.MAPPING.CATEGORIES_PLACEHOLDER' | translate }}"
            placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.CATEGORIES_PLACEHOLDER' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
          <div
            id="product-tags"
            fxLayout="row"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            *ngFor="let tag of mappingData.tags; last as isLast; let i = index; trackBy: trackByFn"
          >
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'TAGS'"
                [adminCommentComments]="comments"
                #tagsProcess
                id="TAGS"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.TAG_PLACEHOLDER' | translate }}"
                name="TAG-{{ i }}"
                [(ngModel)]="tag.chips"
                [(operations)]="tag.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.TAG_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
            <div class="icon-holder" fxLayoutAlign="start center">
              <button mat-icon-button *ngIf="mappingData.tags.length >= 2" (click)="mappingData.removeTag(i)">
                <mat-icon class="s-20" aria-label="Example icon-button with a heart icon"> delete </mat-icon>
              </button>
              <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewTag()">
                <mat-icon class="s-20" aria-label="Example icon-button with a heart icon">
                  add_circle_outline
                </mat-icon>
              </button>
            </div>
          </div>
          <div
            id="product-brands"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
          >
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'BRAND'"
                [adminCommentComments]="comments"
                #normalProcess
                id="BRAND"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.BRAND_PLACEHOLDER' | translate }}"
                name="BRAND"
                [(ngModel)]="mappingData.singleFields.BRAND.chips"
                [(operations)]="mappingData.singleFields.BRAND.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.BRAND_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'CO'"
                [adminCommentComments]="comments"
                #normalProcess
                id="CO"
                title="Country of Origin"
                name="CO"
                [(ngModel)]="mappingData.singleFields.CO.chips"
                [(operations)]="mappingData.singleFields.CO.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.COUNTRY_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
          </div>
          <div id="product-warranty-and-material">
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'WARRANTY'"
              [adminCommentComments]="comments"
              #normalProcess
              id="WARRANTY"
              title="{{ 'TASKWIZARD.ADDFILES.MAPPING.WARRANTY_PLACEHOLDER' | translate }}"
              name="WARRANTY"
              [(ngModel)]="mappingData.singleFields.WARRANTY.chips"
              [(operations)]="mappingData.singleFields.WARRANTY.operations"
              placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.WARRANTY_PLACEHOLDER' | translate }}"
              [prodSampleInputVo]="prodSampleInputVo"
            ></app-droppable-input>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <div id="product-basic-or-advanced" fxLayout="column" fxLayoutAlign="start">
          <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT' | translate }}</div>
          <div
            id="product-basic-fields"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
          >
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SKU'"
                [adminCommentComments]="comments"
                [isAlternativeFields]="isAdmin"
                #normalProcess
                id="SKU"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.SKU_PLACEHOLDER' | translate }}"
                name="SKU"
                [(ngModel)]="mappingData.singleFields.SKU.chips"
                [(operations)]="mappingData.singleFields.SKU.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.SKU_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'UPC'"
                [adminCommentComments]="comments"
                #normalProcess
                id="UPC"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.UPC_PLACEHOLDER' | translate }}"
                name="UPC"
                [(ngModel)]="mappingData.singleFields.UPC.chips"
                [(operations)]="mappingData.singleFields.UPC.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.UPC_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
          </div>

          <div id="product-advanced-fields" fxLayout="column" fxLayoutAlign="start">
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
              <div fxFlex="1 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'PART_NUMBER'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="PART_NUMBER"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.MANUFACTURER_PLACEHOLDER' | translate }}"
                  name="PART_NUMBER"
                  [(ngModel)]="mappingData.singleFields.PART_NUMBER.chips"
                  [(operations)]="mappingData.singleFields.PART_NUMBER.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.MANUFACTURER_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div fxFlex="1 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'WEIGHT'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="WEIGHT"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.WEIGHT_PLACEHOLDER' | translate }}"
                  name="WEIGHT"
                  [isWeightSettings]="true"
                  [(ngModel)]="mappingData.singleFields.WEIGHT.chips"
                  [(settings)]="mappingData.singleFields.WEIGHT.settings"
                  [(operations)]="mappingData.singleFields.WEIGHT.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.WEIGHT_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
            </div>

            <app-dimension-fields
              #dComponent
              [widthChip]="mappingData.singleFields.WIDTH"
              [heightChip]="mappingData.singleFields.HEIGHT"
              [lengthChip]="mappingData.singleFields.LENGTH"
              [taskId]="taskId"
              [comments]="comments"
              [prodSampleInputVo]="prodSampleInputVo"
            >
            </app-dimension-fields>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <div>
          <div class="h5 mb-20">Custom attributes</div>
          <div
            id="product-attributes"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
            ngModelGroup="image"
            *ngFor="let attribute of mappingData.attributes; last as isLast; let i = index"
          >
            <div fxFlex="1 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'NAME'"
                [adminCommentComments]="comments"
                #attributesNamesProcess
                id="NAME"
                title="{{ 'TASKWIZARD.ADDFILES.MAPPING.KEY_PLACEHOLDER' | translate }}"
                name="NAME-{{ i }}"
                [(ngModel)]="attribute.name.chips"
                [(operations)]="attribute.name.operations"
                placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.KEY_PLACEHOLDER' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              >
              </app-droppable-input>
            </div>
            <div fxFlex="1 0 0" class="m-w-0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
              <div fxFlex="1 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'VALUE'"
                  [adminCommentComments]="comments"
                  #attributesValuesProcess
                  id="VALUE"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.VALUE_PLACEHOLDER' | translate }}"
                  name="VALUE-{{ i }}"
                  [(ngModel)]="attribute.value.chips"
                  [(operations)]="attribute.value.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.VALUE_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div class="icon-holder" fxLayoutAlign="start center">
                <button mat-icon-button *ngIf="i !== 0" (click)="mappingData.removeProdAttr(i)">
                  <mat-icon class="s-20" aria-label="Delete icon"> delete </mat-icon>
                </button>
                <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProductAttribute()">
                  <mat-icon class="s-20" aria-label="new Image icon"> add_circle_outline </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="true">
          <app-droppable-input
            #normalProcess
            id="SHOPIFY_PUBLISHED"
            title="{{ 'TASKWIZARD.ADDFILES.MAPPING.SHOPIFY_PUBLISHED_PLACEHOLDER' }}"
            name="SHOPIFY_PUBLISHED"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_PUBLISHED.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_PUBLISHED.operations"
            placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.SHOPIFY_PUBLISHED_PLACEHOLDER' }}"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
        </div>
        <app-custom-divider></app-custom-divider>
        <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.SEO_FIELDS' | translate }}</div>
        <div>
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_HANDLE'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SHOPIFY_HANDLE"
            title="{{ 'TASKWIZARD.ADDFILES.MAPPING.SEO_URL_PLACEHOLDER' | translate }}"
            name="SHOPIFY_HANDLE"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_HANDLE.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_HANDLE.operations"
            placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.SEO_URL_PLACEHOLDER' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            title="{{ 'TASKWIZARD.ADDFILES.MAPPING.META_TITLE_PLACEHOLDER' | translate }}"
            name="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.operations"
            placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.META_TITLE_PLACEHOLDER' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
          <app-droppable-multiline-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_META_DESC'"
            [adminCommentComments]="comments"
            id="SHOPIFY_META_DESC"
            title="Meta Description"
            [(chips)]="mappingData.singleFields.SHOPIFY_META_DESC.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_META_DESC.operations"
          >
          </app-droppable-multiline-input>
        </div>
        <app-custom-divider></app-custom-divider>
        <div>
          <div class="h5 mb-20">Variants</div>
          <form
            #shopifyOptionsForm="ngForm"
            fxLayout="column"
            class="options-container"
            fxLayoutAlign="start"
            fxLayoutGap="8px"
          >
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'VARIANT_ID'"
              [adminCommentComments]="comments"
              #normalProcess
              id="VARIANT_ID"
              title="{{ 'TASKWIZARD.ADDFILES.MAPPING.VARIANT_ID' | translate }}"
              name="VARIANT_ID"
              [(ngModel)]="mappingData.singleFields.VARIANT_ID.chips"
              [(operations)]="mappingData.singleFields.VARIANT_ID.operations"
              placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.VARIANT_ID' | translate }}"
              [prodSampleInputVo]="prodSampleInputVo"
            >
            </app-droppable-input>
            <div
              id="shopify-options-1"
              fxLayout="row"
              fxLayout.lt-lg="column"
              fxLayoutAlign="start"
              fxLayoutGap="20px"
              fxLayoutGap.lt-lg="0"
            >
              <div fxFlex="3 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_1'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="SHOPIFY_OPTION_NAME_1"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_NAME_1_PLACEHOLDER' | translate }}"
                  name="SHOPIFY_OPTION_NAME_1"
                  [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.chips"
                  [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_NAME_1_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div fxFlex="7 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_1'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="SHOPIFY_OPTION_VALUE_1"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_VALUE_1_PLACEHOLDER' | translate }}"
                  name="SHOPIFY_OPTION_VALUE_1"
                  [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.chips"
                  [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.operations"
                  [(settings)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1['settings']"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_VALUE_1_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
            </div>
            <div
              id="shopify-options-2"
              fxLayout="row"
              fxLayout.lt-lg="column"
              fxLayoutAlign="start"
              fxLayoutGap="20px"
              fxLayoutGap.lt-lg="0"
            >
              <div fxFlex="3 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_2'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="SHOPIFY_OPTION_NAME_2"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_NAME_2_PLACEHOLDER' | translate }}"
                  name="SHOPIFY_OPTION_NAME_2"
                  [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.chips"
                  [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.operations"
                  [(settings)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2['settings']"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_NAME_2_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div fxFlex="7 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_2'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="SHOPIFY_OPTION_VALUE_2"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_VALUE_2_PLACEHOLDER' | translate }}"
                  name="SHOPIFY_OPTION_VALUE_2"
                  [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.chips"
                  [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.operations"
                  [(settings)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3['settings']"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_VALUE_2_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
            </div>
            <div
              id="shopify-options-3"
              fxLayout="row"
              fxLayout.lt-lg="column"
              fxLayoutAlign="start"
              fxLayoutGap="20px"
              fxLayoutGap.lt-lg="0"
            >
              <div fxFlex="3 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_3'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="SHOPIFY_OPTION_NAME_3"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_NAME_3_PLACEHOLDER' | translate }}"
                  name="SHOPIFY_OPTION_NAME_3"
                  [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.chips"
                  [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_NAME_3_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div fxFlex="7 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_3'"
                  [adminCommentComments]="comments"
                  #normalProcess
                  id="SHOPIFY_OPTION_VALUE_3"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_VALUE_3_PLACEHOLDER' | translate }}"
                  name="SHOPIFY_OPTION_VALUE_3"
                  [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.chips"
                  [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.OPTION_VALUE_3_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
            </div>
          </form>
        </div>
        <app-custom-divider></app-custom-divider>
        <div>
          <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.FILTERABLE_FIELDS' | translate }}</div>
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'FILTERABLE_PRODUCT_DATA'"
            [adminCommentComments]="comments"
            #normalProcess
            id="FILTERABLE_PRODUCT_DATA"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.FILTERABLE_PRODUCT_DATA' | translate }}"
            [isFilterSettings]="true"
            [chips]="mappingData.singleFields.FILTERABLE_PRODUCT_DATA.chips"
            [(operations)]="mappingData.singleFields.FILTERABLE_PRODUCT_DATA.operations"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'FILTERABLE_VARIANT_DATA'"
            [adminCommentComments]="comments"
            #normalProcess
            id="FILTERABLE_VARIANT_DATA"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.FILTERABLE_VARIANT_DATA' | translate }}"
            [isFilterSettings]="true"
            [chips]="mappingData.singleFields.FILTERABLE_VARIANT_DATA.chips"
            [(operations)]="mappingData.singleFields.FILTERABLE_VARIANT_DATA.operations"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
        </div>
      </form>
    </div>
  </div>
</div>
<button
  mat-button
  class="syncee-black-flat-button"
  (click)="takonySave()"
  *ngIf="isAdmin"
  buttonWithIcon="visibility_off"
>
  {{ 'TASKWIZARD.ADDFILES.MAPPING.SAVE' | translate }}
</button>
