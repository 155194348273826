import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MySuppliersCacheService } from '../service/my-suppliers-cache.service';
import { Observable } from 'rxjs';
import { WholesalePriceAndOrderNumber } from '../model/wholesale-price-and-order-number';
@Injectable()
@Pipe({
  name: 'getOrderInfoFromCacheByCatalogId',
})
export class GetOrderInfoFromCacheByCatalogIdPipe implements PipeTransform {
  constructor(private cacheService: MySuppliersCacheService) {}
  transform(catalogID: number): Observable<WholesalePriceAndOrderNumber> {
    return this.cacheService.getWholesalePriceAndOrderNumberFromCache(catalogID);
  }
}
