import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { ReviewBadge } from '../../../vo/review-badge';
import { EXTRA_REVIEW_BADGES } from '../../../utils/Constants';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import * as _ from 'lodash';
import { NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { PLATFORM } from '../../../utils/platforms/platform-config';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-review-badges',
  templateUrl: './review-badges.component.html',
  styleUrls: ['./review-badges.component.scss'],
  standalone: true,
  imports: [NgStyle, NgForOf, FlexModule, NgIf, NgTemplateOutlet, TranslateModule],
})
export class ReviewBadgesComponent implements OnInit {
  private _unsubscribeAll: Subject<void>;
  public ecomList: EcomVO[] = [];
  public badges: ReviewBadge[] = [];
  @Input() gridColumnNumber;
  @Input() needsRedirect = true;
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Input() orientation: 'vertical' | 'horizontal' | 'grid' = 'grid';
  @Output() platformClick = new EventEmitter<string>();

  constructor(private marketplaceEcomService: MarketplaceEcomService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.marketplaceEcomService.getOnlyDomains().subscribe((res) => {
      this.ecomList = res;
      this.setBadgeUrls();
    });
  }

  private whitelistWooCommerce(ecom: EcomVO): string {
    if (ecom.ecomType === 'woocommerce' && !ecom.options.fromWoo) {
      return 'wordpress';
    } else {
      return ecom.ecomType;
    }
  }

  private setBadgeUrls(): void {
    const ecomTypes = _.uniq(this.ecomList.map((ecom) => this.whitelistWooCommerce(ecom)));
    this.badges = [
      ...PLATFORM.filter((platform) => ecomTypes.includes(platform.key) && !!platform.reviewBadge).map((platform) => ({
        ecomType: platform.key,
        imgUrl: platform.reviewBadge.image,
        redirectLink: platform.reviewBadge.redirectLink,
      })),
      ...EXTRA_REVIEW_BADGES.filter((badge) => badge.ecomType === 'wordpress').filter((badge) =>
        ecomTypes.includes(badge.ecomType)
      ),
      ...EXTRA_REVIEW_BADGES.filter((badge) => badge.ecomType === 'google' || badge.ecomType === 'trustpilot'),
    ];
  }

  // private checkIfCompleted(stepId: number): boolean {
  //   return this.onboardService.completedSteps.includes(stepId);
  // }

  public redirectAndSaveSnippet(badge: ReviewBadge): void {
    // const saveIds = this.specialContent.filter((content) => content.type === 'review').map((content) => content.key);
    // if (!this.checkIfCompleted(saveIds[0])) {
    //   this.onboardService.addUserToSnippets(saveIds).subscribe(() => {
    //     this.onboardService.getCompletedSteps().subscribe();
    //   });
    // }
    if (this.needsRedirect) {
      window.open(badge.redirectLink, '_blank');
    }
    this.platformClick.emit(badge.ecomType);
  }
}
