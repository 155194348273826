<div id="fileUploadCard">
  <h1>
    {{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.FILE_UPLOAD' | translate }}
  </h1>
  <div class="dragzone-holder">
    <div class="dragzone-overlay" *ngIf="isUploadInProgress"></div>
    <div
      ngfDrop
      multiple="1"
      selectable="1"
      [(files)]="files"
      accept=".json,application/json,.xml,application/xml,.csv,.xls,.xlsx"
      [(validDrag)]="validComboDrag"
      class="well dragzone"
      [class.invalid-drag]="validComboDrag === false"
      [class.valid-drag]="validComboDrag"
      (filesChange)="setFileToUpload(files)"
    >
      <div class="upload-holder">
        <div>
          <div class="upload-image"></div>
          <p>{{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.DROP_FILES' | translate }}</p>
          <button mat-button color="accent">
            {{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.SELECT_FILE' | translate }}
          </button>
        </div>
        <div class="file-content" *ngIf="selectedFileName">
          {{ selectedFileName }}
          <span class="file-size" *ngIf="selectedFileConvertedSize">{{ selectedFileConvertedSize }}</span>
        </div>
      </div>
    </div>
  </div>
  <div id="uploaded-files">
    <mat-form-field>
      <mat-select
        [disabled]="true"
        placeholder="{{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.FILE_TYPE_PLACEHOLDER' | translate }}"
        [(ngModel)]="selectedFileExtension"
        name="type"
        (valueChange)="selectFileTypeChanged($event)"
      >
        <mat-option *ngFor="let fileExt of fileExtensions" [value]="fileExt.type">
          {{ fileExt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      [disabled]="!isFileSelected || disableUploadBtn"
      class="upload-button"
      mat-button
      color="accent"
      (click)="uploadFiles(selectedFile)"
    >
      {{ 'TASKWIZARD.ADDFILES.FILE_UPLOAD.UPLOAD' | translate }}
    </button>
  </div>
</div>
