import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { SpringPageable } from '../../vo/pagination/spring-pageable';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { getCurrentUserIdSelector } from '../../store/user/user.selector';
import { selectedSupplierEcomSelector } from '../../store/ecom/ecom.selector';
import { CurrencyService } from '../currency-service/currency.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierDashboardAnalyticsService {
  private baseUrl = '/Supplier/Analytics';
  constructor(private springRestService: SpringRestService, private store: Store<AppState>) {}

  getTopRetailers(
    dateFrom: Date,
    dateTo: Date,
    pageable: SpringPageable = { page: 0, size: 3, sort: 'productsSold,desc' }
  ): Observable<TopRetailerDto[]> {
    const url = `${this.baseUrl}/TopRetailers`;
    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) =>
        this.springRestService.get(MicroserviceNames.ORDER, url, { ...requestDto, ...pageable }, true)
      )
    );
  }

  getTopSellingProducts(
    dateFrom: Date,
    dateTo: Date,
    pageable: SpringPageable = { page: 0, size: 3, sort: 'profit,desc' }
  ): Observable<SupplierTopSellingProductsDto[]> {
    const url = `${this.baseUrl}/TopSellingProducts`;
    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) =>
        this.springRestService.get(MicroserviceNames.ORDER, url, { ...requestDto, ...pageable }, true)
      )
    );
  }

  getOverviewAnalytics(): Observable<SupplierOverviewAnalyticsDto> {
    const url = `${this.baseUrl}/OverviewAnalytics`;

    return this.getRequestDto().pipe(
      switchMap((requestDto) => this.springRestService.get(MicroserviceNames.ORDER, url, requestDto, true))
    );
  }

  getDailyOrderAnalytics(
    dateFrom: Date,
    dateTo: Date,
    timeFrames: number = 10
  ): Observable<SupplierTimelineRangeDto[]> {
    const url = `${this.baseUrl}/DailyOrderAnalytics`;
    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) =>
        this.springRestService.get(MicroserviceNames.ORDER, url, { ...requestDto, timeFrames }, true)
      )
    );
  }

  private getRequestDto(fromDate?: Date, toDate?: Date): Observable<SupplierDashboardRequestDto> {
    return this.store.select(getCurrentUserIdSelector).pipe(
      take(1),
      map((supplierUserId) => {
        const requestDto = {
          supplierUserId: supplierUserId,
        } as SupplierDashboardRequestDto;

        if (fromDate) {
          requestDto.fromDate = fromDate.toISOString();
        }

        if (toDate) {
          requestDto.toDate = toDate.toISOString();
        }

        return requestDto;
      })
    );
  }
}

export interface SupplierDashboardRequestDto {
  supplierUserId: number;
  fromDate: string;
  toDate: string;
}

export interface SupplierTimelineRangeDto {
  orders: number;
  productsSold: number;
  revenue: number;
  fromDate: string;
  toDate: string;
}

export interface TopRetailerDto {
  userName: string;
  orders: number;
  productsSold: number;
  revenue: number;
}

export interface SupplierTopSellingProductsDto {
  synceeId: string;
  title: string;
  imageUrl: string;
  productsSold: number;
  revenue: number;
}

export interface SupplierOverviewAnalyticsDto {
  totalRevenue: number;
  averageRevenue: number;
  maxRevenueOnOrder: number;
}
