import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationChipComponent } from '../../../../shared/components/information-chip/information-chip.component';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-left-and-right-section',
  standalone: true,
  imports: [CommonModule, InformationChipComponent],
  templateUrl: './left-and-right-section.component.html',
  styleUrls: ['./left-and-right-section.component.scss'],
})
export class LeftAndRightSectionComponent implements OnInit {
  @Input() position: LeftAndRightSectionContentPosition = 'left';
  @Input() chipText: string;
  @Input() title: string;
  @Input() description: string;
  @Input() descriptionSmall: string;
  @Input() wrapperClass: string | string[];
  @Input() innerClass: string | string[];
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }
}

export type LeftAndRightSectionContentPosition = 'left' | 'right';
