import { AfterViewInit, Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { AuthorizationEcomService } from '../../service/authorization-ecom/authorization-ecom.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[onlyGuest]',
  standalone: true,
})
export class OnlyGuestDirective implements AfterViewInit, OnDestroy {
  private unsubscribeAll: Subject<void>;
  private hasView = false;
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private store: Store<AppState>,
    private authorizationService: AuthorizationEcomService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngAfterViewInit(): void {
    this.subscribeToAuthenticationChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToAuthenticationChange(): void {
    if (!this.subscription) {
      this.subscription = this.authorizationService
        .isAuthenticatedUserOrNot()
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((isAuthenticated) => {
          this.handler(isAuthenticated);
        });
    }
  }

  private handler(isAuthenticated: boolean): void {
    if (isAuthenticated) {
      this.hide();
    } else {
      this.show();
    }
  }

  private show(): void {
    if (!this.hasView) {
      this.container.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  private hide(): void {
    if (this.hasView) {
      this.container.clear();
      this.hasView = false;
    }
  }
}
