import { Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export function switchMapWith<T, R>(project: (value: T) => Observable<R>): OperatorFunction<T, [T, R]> {
  return (source: Observable<T>) =>
    source.pipe(
      switchMap((previousValue) =>
        project(previousValue).pipe(map((currentValue) => [previousValue, currentValue] as [T, R]))
      )
    );
}
