import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomThumbnailComponent } from '../../../shared/components/custom-thumbnail/custom-thumbnail.component';
import { ProductImageSelectorComponent } from '../../../shared/components/product-image-selector/product-image-selector.component';
import { Subject } from 'rxjs';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-page-image-viewer',
  templateUrl: './product-page-image-viewer.component.html',
  styleUrls: ['./product-page-image-viewer.component.scss'],
})
export class ProductPageImageViewerComponent implements OnInit {
  @ViewChild('thumbnailComponent') thumbnail: CustomThumbnailComponent;
  @ViewChild('imageSelectorComponent') imageViewer: ProductImageSelectorComponent;

  @Input() productImages: string[];
  @Input() productImageAlt: string;
  @Input() loading: boolean;
  isMobile = false;

  thumbnailConfig: JQuerySlickOptions = {
    slidesToShow: 8,
    slidesToScroll: 1,
    speed: 200,
    vertical: true,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 7,
          vertical: false,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 5,
          vertical: false,
        },
      },
    ],
  };

  private _unsubscribeAll: Subject<void>;

  constructor(private screenManager: ScreenManagerService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToScreenManager();
  }

  handleImageViewerIndexChange(newIndex: number): void {
    this.thumbnail.currentImageIndex = newIndex;
    this.thumbnail.slick.slickGoTo(newIndex);
  }

  handleThumbnailIndexChange(newIndex: number): void {
    this.imageViewer.slick.slickGoTo(newIndex);
  }

  private subscribeToScreenManager(): void {
    this.screenManager
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.isMobile = value.matches;
      });
  }
}
