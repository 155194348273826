import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { User } from '../../../service/user/user';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { getCurrentUserSelector } from '../../../store/user/user.selector';
import { map, takeUntil } from 'rxjs/operators';
import { favoriteProductsIdsSelector } from '../../../store/favorites/favorites.selector';
import {
  AddToFavoriteIdsStartAction,
  RemoveFromFavoriteIdsStartAction,
} from '../../../store/favorites/favorites.actions';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GuestOpenRegistrationDirective } from '../../directives/guest-open-registration.directive';
import { RoleOpenRegistrationDirective } from '../../directives/role-open-registration.directive';
import { RolesEnum } from '../../../vo/roles/roles';

@Component({
  selector: 'app-favourite-heart',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    GuestOpenRegistrationDirective,
    RoleOpenRegistrationDirective,
  ],
  templateUrl: './favourite-heart.component.html',
  styleUrls: ['./favourite-heart.component.scss'],
})
export class FavouriteHeartComponent implements OnInit, OnDestroy {
  @Input() productId: string;
  @Output() favoriteChange = new EventEmitter<void>();
  selected = false;
  isLoading = false;
  private currentUser: Observable<User>;
  private readonly _unsubscribeAll: Subject<void>;
  protected readonly RolesEnum = RolesEnum;

  constructor(private productSearch: ProductSearchService, private store: Store<AppState>) {
    this._unsubscribeAll = new Subject<void>();
    this.initCurrentUser();
  }

  ngOnInit(): void {
    this.subscribeToFavoriteChange();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private initCurrentUser(): void {
    this.currentUser = this.store.select(getCurrentUserSelector);
  }

  private getUserId(): Observable<number> {
    return this.currentUser.pipe(
      takeUntil(this._unsubscribeAll),
      map((user) => {
        return user.id;
      })
    );
  }

  private subscribeToFavoriteChange(): void {
    this.store
      .select(favoriteProductsIdsSelector)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.selected = value.includes(this.productId);
        this.isLoading = false;
      });
  }

  onFavoriteClicked(currentSelected: boolean): void {
    this.isLoading = true;
    if (currentSelected === true) {
      this.removeFromFavorites();
    } else {
      this.addToFavorites();
    }
  }

  private addToFavorites(): void {
    this.getUserId().subscribe((id) => {
      this.store.dispatch(
        new AddToFavoriteIdsStartAction({
          userId: id,
          productIds: [this.productId],
        })
      );
    });
  }

  private removeFromFavorites(): void {
    this.getUserId().subscribe((id) => {
      this.store.dispatch(
        new RemoveFromFavoriteIdsStartAction({
          userId: id,
          productIds: [this.productId],
        })
      );
    });
  }
}
