<div class="price-filter-container">
  <div>
    <div
      *ngFor="let priceSet of predefinedPrices"
      (click)="handlePredefinedClick(priceSet.min, priceSet.max)"
      class="predefined-price"
    >
      {{
        priceSet.template
          | translate : { min: priceSet.calculatedMin, max: priceSet.calculatedMax, ecomCurrency: ecomCurrency }
      }}
    </div>
  </div>
  <div fxLayoutGap="4px" fxLayoutAlign="center center" class="inputs">
    <mat-form-field appearance="fill" fxFlex="1 0 0">
      <mat-label>{{ 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.FROM' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [min]="1"
        [ngModel]="
          (productSearchStore$ | async)?.temporaryFilter?.minPrice || (productSearchStore$ | async)?.filter?.minPrice
        "
        (ngModelChange)="debouncedMinChange($event)"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" fxFlex="1 0 0">
      <mat-label>{{ 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.TO' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [min]="1"
        [ngModel]="
          (productSearchStore$ | async)?.temporaryFilter?.maxPrice || (productSearchStore$ | async)?.filter?.maxPrice
        "
        (ngModelChange)="debouncedMaxChange($event)"
      />
    </mat-form-field>
  </div>
</div>
