import { AfterViewInit, Component, Inject, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, NgForm, NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplacerRef } from './replacer/replacer-ref';
import { Replacer } from './replacer/replacer';
import {
  CurrencyWrapper,
  DimWrapper,
  EvalWrapper,
  FieldOperation,
  FieldSetting,
  FillTheEmptyWrapper,
  HPCCWrapper,
  LocationWrapper,
  ReplacerWrapper,
  SelectOnlyWrapper,
  SplitterWrapper,
  WeightWrapper,
} from '../droppable-input/chip';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SettingsDialogData } from '../droppable-input/droppable-input.component';
import { Currencies, DEFAULT_WEIGHT_UNIT, WeightUnits } from '../../utils/Constants';
import { Utils } from '../../utils/utils';
import { DimSettingsService } from '../droppable-input/dim-settings.service';
import { MfieldQuantitySettingsService } from '../../service/taskwizard/mfield-quantity-settings.service';

export class ReplaceRefWrapper {
  replacers: ReplacerRef[] = [];
}

export type CFieldOperation = ReplaceRefWrapper | EvalWrapper | SplitterWrapper | SelectOnlyWrapper;

@Component({
  selector: 'app-field-settings',
  templateUrl: './field-settings.component.html',
  styleUrls: ['./field-settings.component.scss'],
})
export class FieldSettingsComponent implements AfterViewInit {
  REPLACER_REF = 'replacerRef';
  REPLACER = 'replacer';
  EVAL = 'eval';
  SPLITTER = 'splitter';

  currencyWrapper: CurrencyWrapper;
  hpccWrapper: HPCCWrapper;
  weightWrapper: WeightWrapper;
  dimWrapper: DimWrapper;
  locationWrapper: LocationWrapper;
  fillTheEmptyWrapper: FillTheEmptyWrapper;

  settingsType: number;

  currencies: any[] = [];
  weightUnits: any[] = [];

  operationFields: CFieldOperation[] = [];

  // Yeah I know this is a piece of shit, it was my first steps in angular
  isPriceSettings = false;
  isWeightSettings = false;
  isDimSettings = false;
  isLocationSettings = false;
  isFilterSettings = false;
  isFillEmptySettings = false;
  isChipSettings: boolean;
  locations: any[];

  dimensions: any[] = [];
  fieldName: string;

  isCancelBtnDisabled: () => boolean = (): boolean => {
    return false;
  };

  isActionBtnDisabled: () => boolean = (): boolean => {
    return false;
  };

  @ViewChildren('currency') currencyInput: QueryList<NgModel>;
  @ViewChildren('settingsPriceForm') settingsPriceForm: QueryList<NgForm>;
  @ViewChildren('weightSettingsForm') weightSettingsForm: QueryList<NgForm>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private settings: SettingsDialogData,
    private fb: UntypedFormBuilder,
    private quantitySettingsService: MfieldQuantitySettingsService,
    public dimSettings: DimSettingsService,
    private dialogRef: MatDialogRef<FieldSettingsComponent>
  ) {
    this.currencyWrapper = new CurrencyWrapper('');
    this.hpccWrapper = new HPCCWrapper(true);
    this.weightWrapper = new WeightWrapper('');
    this.dimWrapper = new DimWrapper('');
    this.locationWrapper = new LocationWrapper();
    this.fillTheEmptyWrapper = new FillTheEmptyWrapper();
    this.isChipSettings = settings.isChipSettings;

    this.settingsType = this.settings.settingsType;
    this.fieldName = this.settings.fieldName || null;
  }

  initSettingsType(): void {
    switch (this.settingsType) {
      case 0:
        this.isActionBtnDisabled = () => {
          return this.isChipSettings && this.currencyWrapper.currency.length < 1;
        };
        this.isPriceSettings = true;
        break;
      case 1:
        this.isWeightSettings = true;
        break;
      case 2:
        this.isDimSettings = true;
        break;
      case 3:
        this.isActionBtnDisabled = () => {
          return Utils.isNullOrUndefined(this.locationWrapper.location);
        };

        this.isLocationSettings = true;
        break;
      case 4:
        this.isFilterSettings = true;
        break;
    }
  }

  ngAfterViewInit(): void {
    this.init();
  }

  initCurrencies(): void {
    this.currencies = Object.keys(Currencies).map((key) => {
      return { code: key, name: Currencies[key] };
    });
  }

  initWeightUnits(): void {
    this.weightUnits = Object.keys(WeightUnits).map((key) => {
      return { code: key, name: WeightUnits[key] };
    });
  }

  init(): void {
    this.initSettingsType();
    this.initFillEmptySettings(this.settings.data.settings);
    if (this.isPriceSettings) {
      this.initCurrencies();
      this.initSettings(this.settings.data.settings);
      this.settingsPriceForm.changes.subscribe((element) => {
        Utils.markFormGroupTouched(element.first.control);
      });
    }
    if (this.isWeightSettings) {
      this.initWeightUnits();
      this.initWeightSettings(this.settings.data.settings);
    }
    if (this.isDimSettings) {
      this.initDimSettings(this.settings.data.settings);
    }
    if (this.isLocationSettings) {
      this.initLocationSettings(this.settings.data.settings, this.settings.taskId);
    }
    this.initOperations(this.settings.data.operations);
  }

  initLocationSettings(settings: any[], taskId): void {
    this.quantitySettingsService.getLocationsByTaskId(taskId).subscribe((locations) => {
      const locWrapperObj = settings.find((s) => s instanceof LocationWrapper) as LocationWrapper;
      if (!this.settings.isAlterField) {
        this.locations = locations.filter((loc) => {
          return (
            this.quantitySettingsService.isLocationFree(this.fieldName, loc.id) ||
            (!Utils.isNullOrUndefined(locWrapperObj) && loc.id == locWrapperObj.location)
          );
        });
      } else {
        this.locations = locations;
      }

      if (!Utils.isNullOrUndefined(locWrapperObj)) {
        this.locationWrapper = new LocationWrapper(locWrapperObj.location);
      } else {
        if (this.locations.length > 0) {
          this.locationWrapper = new LocationWrapper(this.locations[0].id);
        }

        this.isCancelBtnDisabled = () => {
          return true;
        };
      }
    });
  }

  initFillEmptySettings(settings: any[]): void {
    const fillEmptyObj = settings.find((s) => s instanceof FillTheEmptyWrapper) as FillTheEmptyWrapper;
    if (!Utils.isNullOrUndefined(fillEmptyObj)) {
      this.fillTheEmptyWrapper = fillEmptyObj;
    }
  }

  initDimSettings(settings: any[]): void {
    this.dimensions = this.dimSettings.getDimUnits();
    if (settings.length < 1) {
      return;
    }
    const dimObj = settings.find((s) => s instanceof DimWrapper) as DimWrapper;
    if (!Utils.isNullOrUndefined(dimObj)) {
      this.dimWrapper = dimObj;
    }
  }

  initWeightSettings(settings: any[]): void {
    if (settings.length < 1 && !this.isChipSettings) {
      this.weightWrapper.weightUnit = DEFAULT_WEIGHT_UNIT;
      return;
    }
    this.weightWrapper = settings.find((s) => s instanceof WeightWrapper) as WeightWrapper;
  }

  initSettings(settings: any[]): void {
    if (settings.length < 1) {
      return;
    }
    const currObj = settings.find((s) => s instanceof CurrencyWrapper);
    if (!Utils.isNullOrUndefined(currObj)) {
      this.currencyWrapper = currObj as CurrencyWrapper;
    } else {
      this.currencyWrapper = new CurrencyWrapper('');
    }
    const hpcObj = settings.find((s) => s instanceof HPCCWrapper);
    if (!Utils.isNullOrUndefined(hpcObj)) {
      this.hpccWrapper = hpcObj as HPCCWrapper;
    } else {
      this.hpccWrapper = new HPCCWrapper(false);
    }
  }

  initOperations(operations: FieldOperation[]): void {
    if (operations.length < 1) {
      return;
    }
    operations.forEach((opField) => {
      if (opField instanceof ReplacerWrapper) {
        const replaceRefWrapper = new ReplaceRefWrapper();
        replaceRefWrapper.replacers = (opField as ReplacerWrapper).replacer.map((r) => new ReplacerRef(r));
        this.operationFields.push(replaceRefWrapper);
      } else {
        this.operationFields.push(opField);
      }
    });
  }

  getOperationFieldType(operation): string {
    switch (true) {
      case operation instanceof ReplaceRefWrapper:
        return this.REPLACER_REF;
      case operation instanceof ReplacerWrapper:
        return this.REPLACER;
      case operation instanceof EvalWrapper:
        return this.EVAL;
      case operation instanceof SplitterWrapper:
        return this.SPLITTER;
      default:
        return '';
    }
  }

  drop(event: CdkDragDrop<ViewContainerRef[]>): void {
    console.log('drop event');
    moveItemInArray(this.operationFields, event.previousIndex, event.currentIndex);
  }

  private getKeyByValue(object, value): any {
    return Object.keys(object).find((key) => object[key] === value);
  }

  public close(): void {
    const operationsWithChips: OperationsWithSettings = { operations: [], settings: [this.fillTheEmptyWrapper] };

    if (this.isPriceSettings) {
      operationsWithChips.settings.push(this.currencyWrapper);
      operationsWithChips.settings.push(this.hpccWrapper);
    }

    if (this.isWeightSettings) {
      operationsWithChips.settings.push(this.weightWrapper);
    }

    if (this.isDimSettings) {
      operationsWithChips.settings.push(this.dimWrapper);
      this.dimSettings.setDimensionCode(this.dimWrapper.dimUnit);
    }

    if (this.isLocationSettings) {
      const locWrapperObj = this.settings.data.settings.find((s) => s instanceof LocationWrapper) as LocationWrapper;
      if (!Utils.isNullOrUndefined(locWrapperObj)) {
        this.quantitySettingsService.removeLocation(this.settings.fieldName, locWrapperObj.location);
      }
      operationsWithChips.settings.push(this.locationWrapper);
      this.quantitySettingsService.occupeLocation(this.fieldName, this.locationWrapper.location);
    }

    this.operationFields.forEach((opField) => {
      let finalOpField = null;
      finalOpField = opField;
      if (opField instanceof ReplaceRefWrapper) {
        finalOpField = new ReplacerWrapper(this.getReplacerSettings(opField));
      }
      if (!this.checkOperationFieldIsEmpty(finalOpField)) {
        operationsWithChips.operations.push(finalOpField);
      }
    });

    this.dialogRef.close(operationsWithChips);
  }

  private getReplacerSettings(replaceRefW: ReplaceRefWrapper): Replacer[] {
    return replaceRefW.replacers.map((r: ReplacerRef) => r.getSettings()).filter((val) => val.replace || val.value);
  }

  public removeReplacer(replaceRefW: ReplaceRefWrapper, id: number): void {
    const replacerRef = replaceRefW.replacers.find((r) => r.id === id);
    if (replacerRef) {
      replaceRefW.replacers.splice(replaceRefW.replacers.indexOf(replacerRef), 1);
    }
  }

  public addNewReplacer(replaceRefW: ReplaceRefWrapper): void {
    replaceRefW.replacers.push(new ReplacerRef());
  }

  addReplacer(): void {
    const replaceRefWrapper = new ReplaceRefWrapper();
    this.addNewReplacer(replaceRefWrapper);
    this.operationFields.unshift(replaceRefWrapper);
  }

  addOperation(): void {
    this.operationFields.unshift(new EvalWrapper(''));
  }

  addSplit(): void {
    this.operationFields.unshift(new SplitterWrapper(''));
  }

  removeOperation(opField: CFieldOperation): void {
    const index = this.operationFields.indexOf(opField);
    if (index > -1) {
      this.operationFields.splice(index, 1);
    }
  }

  checkOperationFieldIsEmpty(opField: FieldOperation): boolean {
    switch (true) {
      case opField instanceof ReplacerWrapper:
        if ((opField as ReplacerWrapper).replacer.length < 1) {
          return true;
        }
        break;
      case opField instanceof EvalWrapper:
        if (!(opField as EvalWrapper).eval) {
          return true;
        }
        break;
      case opField instanceof SplitterWrapper:
        if (!(opField as SplitterWrapper).splitter) {
          return true;
        }
        break;
    }
  }

  isSettings(): boolean {
    return (
      this.isPriceSettings ||
      this.isLocationSettings ||
      this.isDimSettings ||
      (this.isWeightSettings && !this.isChipSettings) ||
      !this.isChipSettings
    );
  }
}

export interface OperationsWithSettings {
  operations: FieldOperation[];
  settings: FieldSetting[];
}

export interface OperationData<T> {
  order: number;
  operObj: T;
}
