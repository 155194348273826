import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformCardDataWithIsInstalled } from '../../../account-settings/tabs/my-shops/my-shops.component';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { Observable } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';
import { CardType } from '../platform-card/platform-card.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { getEcomsByRole } from '../../../../store/ecom/ecom.selector';
import { map } from 'rxjs/operators';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { RolesEnum } from '../../../../vo/roles/roles';

@Component({
  selector: 'app-platform-dialog',
  templateUrl: './platform-dialog.component.html',
  styleUrls: ['./platform-dialog.component.scss'],
})
export class PlatformDialogComponent implements OnInit {
  mdBreakpoint: Observable<BreakpointState>;
  platformDomains: Observable<EcomVO[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PlatformDialogData,
    public screenManagerService: ScreenManagerService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.mdBreakpoint = this.screenManagerService.observeBreakpoint(BreakPoint.md);
    if (this.data.type === 'PLATFORM' && this.data.platformData.isInstalled) {
      this.setPlatformDomains();
    }
  }

  private setPlatformDomains(): void {
    this.platformDomains = this.store
      .select(getEcomsByRole(this.data.role))
      .pipe(map((ecoms) => ecoms.filter((ecom) => ecom.ecomType === this.data.platformData.platformCardData.type)));
  }
}
export interface PlatformDialogData {
  platformData: PlatformCardDataWithIsInstalled;
  type: CardType;
  role: RolesEnum;
}
