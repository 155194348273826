import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingScreenService } from '../../../service/loading-screen/loading-screen.service';
import { Subscription } from 'rxjs';
import { Utils } from 'app/utils/utils';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, LoadingSpinnerComponent],
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  loadingSubscription: Subscription;
  @Input() message: string;
  @Input() needStatusCheck = true;

  constructor(private loadingScreenService: LoadingScreenService, private changeDetect: ChangeDetectorRef) {}

  ngOnInit(): void {
    console.log(this.loading);
    if (this.needStatusCheck) {
      this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((status) => {
        if (status === null) {
          return;
        }
        this.loading = status;
        this.changeDetect.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    if (!Utils.isNullOrUndefined(this.loadingSubscription)) {
      this.loadingSubscription.unsubscribe();
    }
  }
}
