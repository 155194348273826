import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../service/user/user.service';
import { EcomService } from '../../../service/ecom/ecom.service';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-shopify-new-store',
  templateUrl: './shopify-new-store.component.html',
  styleUrls: ['./shopify-new-store.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class ShopifyNewStoreComponent implements OnInit {
  shopify: UntypedFormGroup;
  shopifySecret: UntypedFormGroup;

  @Input() role: 1 | 2 = 2;

  domainError = false;
  keyError = false;
  passwordError = false;

  constructor(private userService: UserService, private fb: UntypedFormBuilder, private ecomService: EcomService) {}

  ngOnInit(): void {
    this.initShopifyInstall();
  }

  initShopifyInstall(): void {
    this.shopify = this.fb.group({
      domain: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9\\-.]+\\.myshopify\\.com$')]],
    });
    this.shopifySecret = this.fb.group({
      key: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  installShopify(): void {
    if (this.shopify.valid) {
      this.ecomService.installShopify(this.shopify.get('domain').value, this.role);
    }
  }

  installShopifyWithKey(): void {
    if (!this.shopify.invalid && !this.shopifySecret.invalid) {
      const rest = {
        domain: this.shopify.get('domain').value,
        key: this.shopifySecret.get('key').value,
        password: this.shopifySecret.get('password').value,
        type: 'shopify',
      };

      console.log('Domain + API', rest);
    } else {
      if (this.shopify.invalid) {
        this.domainError = true;
      }
      if (this.shopifySecret.invalid) {
        if (!this.shopifySecret.get('key').valid) {
          this.keyError = true;
        }
        if (!this.shopifySecret.get('password').valid) {
          this.passwordError = true;
        }
      }
      console.log('error');
    }
  }

  clearValidatorText(): void {
    this.domainError = false;
    this.keyError = false;
    this.passwordError = false;
  }

  get domain(): AbstractControl<any, any> {
    return this.shopify.get('domain');
  }
}
