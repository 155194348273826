import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { ButtonWithIconDirective } from '../../directives/button-with-icon.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-actions-button-with-menu',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, ButtonWithIconDirective, MatMenuModule, MatIconModule],
  templateUrl: './actions-button-with-menu.component.html',
  styleUrls: ['./actions-button-with-menu.component.scss'],
})
export class ActionsButtonWithMenuComponent implements OnInit {
  @Input() buttonText: string;
  @Input() actions: ActionsButtonWithMenuAction[] = [];
  @Input() height: string;
  @Input() fullWidth = false;

  constructor() {}

  ngOnInit(): void {}
}

export interface ActionsButtonWithMenuAction {
  action: () => void;
  title: string;
  icon?: string;
}
