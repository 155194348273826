<ng-container *ngIf="followedSupplierIds; else progress">
  <ng-container *ngIf="!hasError; else errorComponent">
    <div fxLayout="column" fxLayoutGap="32px" class="followed-suppliers-container">
      <div fxLayout="column" fxLayoutGap="12px" class="supplier-cards-container">
        <ng-container *ngIf="!noFollowedSupplier; else noFollowedSuppliers">
          <ng-container *ngIf="!!followedSupplierIds.length || !!noResultsForSearch">
            <app-favorites-page-filter [isSupplierSearch]="true"></app-favorites-page-filter>
          </ng-container>
          <ng-container *ngIf="!noResultsForSearch; else noResults">
            <app-supplier-card-list
              [supplierIds]="followedSupplierIds"
              [from]="pagination.from"
            ></app-supplier-card-list>
          </ng-container>
        </ng-container>
      </div>
      <app-paginator-load-more
        [totalNumber]="total"
        [fetchedNumber]="fetchedIds"
        (loadMoreClicked)="handleLoadMore()"
      ></app-paginator-load-more>
    </div>
  </ng-container>
</ng-container>

<ng-template #noFollowedSuppliers>
  <app-no-followed-suppliers></app-no-followed-suppliers>
</ng-template>

<ng-template #progress>
  <div fxLayout="column" fxLayoutAlign="center center" class="py-20">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #noResults>
  <app-favorite-no-results-for-search icon="add_circle"></app-favorite-no-results-for-search>
</ng-template>

<ng-template #errorComponent>
  <app-error-message></app-error-message>
</ng-template>
