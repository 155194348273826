<form #form="ngForm" fxLayout="column" fxLayoutGap="10px">
  <div class="h2">{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.ADD_FIELD' | translate }}</div>
  <div fxLayout="column" fxLayoutGap="10px">
    <app-fields-maker
      #fieldsMaker
      (fieldsChanged)="fieldsChanged($event)"
      [selectedFields]="selectedFields"
      [mappingFieldConfig]="mappingFieldConfig"
    ></app-fields-maker>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.DISPLAY_PLACE' | translate }}</mat-label>
      <input name="origDisplayTitle" [(ngModel)]="mappingField.originalName" matInput required />
      <mat-error>{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.DISPLAY' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="isCustomDisplayTitle()" appearance="fill">
      <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.CUSTOM_PLACE' | translate }}</mat-label>
      <input name="customDisplayTitle" [(ngModel)]="mappingField.customDisplayName" matInput />
    </mat-form-field>
    <ng-container *ngIf="selectedFields">
      <div fxLayoutAlign="start center">
        <div class="h4">{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.FIELD' | translate }}</div>
        <mat-icon
          class="title-tooltip"
          matTooltip="{{ 'RETAILER_EXPORT_TASK_WIZARD.TOOLTIP.FIELD_SETTINGS' | translate }}"
          [matTooltipPosition]="'right'"
          >help_outline</mat-icon
        >
      </div>
      <div fxLayout="column" fxLayoutGap="3px" fxLayoutAlign="start start" class="field-settings">
        <mat-button-toggle-group
          name="directionFieldSettings"
          (change)="directionChanged($event)"
          [(ngModel)]="mappingField.settings.fieldDirection"
          #group="matButtonToggleGroup"
          required
        >
          <mat-button-toggle
            *ngIf="canBeFlat"
            value="{{ settingsConsts.DIRECTION_FLAT }}"
            matTooltip="{{ 'RETAILER_EXPORT_TASK_WIZARD.TOOLTIP.DIRECTION_FLAT' | translate }}"
            [matTooltipPosition]="'right'"
            aria-label="Text align left"
          >
            <img class="direction-horizontal" src="assets/images/tasks-export/csv-direction-horizontal.png" />
          </mat-button-toggle>
          <mat-button-toggle
            aria-label="Text align center"
            value="{{ settingsConsts.DIRECTION_RAW }}"
            matTooltip="{{ 'RETAILER_EXPORT_TASK_WIZARD.TOOLTIP.DIRECTION_RAW' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <img src="assets/images/tasks-export/csv-direction-vertical.png" />
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group
          name="selectedDirRepeatitiveFieldSettings"
          [(ngModel)]="mappingField.settings.fieldRepetitive"
          *ngIf="mappingField.settings.fieldDirection"
          required
        >
          <mat-button-toggle
            value="{{ settingsConsts.DIRECTION_NO_REPETITIVE }}"
            aria-label="Text align left"
            matTooltip="{{ 'RETAILER_EXPORT_TASK_WIZARD.TOOLTIP.DIRECTION_NO_REPETITIVE' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <img src="assets/images/tasks-export/csv-repeatitive-no.png" />
          </mat-button-toggle>
          <mat-button-toggle
            value="{{ settingsConsts.DIRECTION_REPETITIVE }}"
            aria-label="Text align center"
            matTooltip="{{ 'RETAILER_EXPORT_TASK_WIZARD.TOOLTIP.DIRECTION_REPETITIVE' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <img src="assets/images/tasks-export/csv-repeatitive-yes.png" />
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </ng-container>
  </div>
  <div class="h4">{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.FIELD_O' | translate }}</div>
  <mat-card style="border: 0.5px solid lightgray">
    <form>
      <div fxLayout="column" cdkDropList (cdkDropListDropped)="drop($event)" fxLayoutGap="20px">
        <mat-card *ngFor="let opField of operationFields; let i = index" cdkDragData="{{ opField }}" cdkDrag>
          <div>
            <ng-container *ngIf="getOperationFieldType(opField) === REPLACER_REF">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon
                    style="user-select: none; cursor: pointer"
                    (click)="addNewReplacer($any(opField))"
                    matRipple
                  >
                    add_circle_outline
                  </mat-icon>
                  <div class="h5">{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.REPLACERS' | translate }}</div>
                </div>
                <button mat-icon-button (click)="removeOperation(opField)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <app-replacer
                *ngFor="let rep of $any(opField).replacers"
                [ref]="rep"
                (remove)="removeReplacer($any(opField), rep.id)"
              ></app-replacer>
            </ng-container>
            <ng-container *ngIf="getOperationFieldType(opField) === EVAL">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
                <div class="h5">{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.OPERATION' | translate }}</div>
                <button mat-icon-button (click)="removeOperation(opField)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div fxLayout="column">
                <mat-form-field class="operation-field input-without-padding" appearance="fill">
                  <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.OPERATION' | translate }}</mat-label>
                  <input
                    matInput
                    name="eval-{{ i }}"
                    [(ngModel)]="$any(opField).eval"
                    placeholder="*1.2"
                    (mousedown)="$event.stopPropagation()"
                  />
                </mat-form-field>
                <mat-hint>{{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.YOU' | translate }}</mat-hint>
              </div>
            </ng-container>
            <ng-container *ngIf="getOperationFieldType(opField) === PREFIX">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
                <div class="h5">{{ getOperationFieldType(opField) }}</div>
                <button mat-icon-button (click)="removeOperation(opField)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-form-field class="operation-field input-without-padding" appearance="fill">
                <mat-label>{{ getOperationFieldType(opField) }}</mat-label>
                <input
                  matInput
                  name="split-{{ i }}"
                  [(ngModel)]="$any(opField).prefix"
                  placeholder="{{ getOperationFieldType(opField) }}"
                  (mousedown)="$event.stopPropagation()"
                />
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="getOperationFieldType(opField) === SUFIX">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
                <div class="h5">{{ getOperationFieldType(opField) }}</div>
                <button mat-icon-button (click)="removeOperation(opField)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-form-field class="operation-field input-without-padding" appearance="fill">
                <mat-label>{{ getOperationFieldType(opField) }}</mat-label>
                <input
                  matInput
                  name="split-{{ i }}"
                  [(ngModel)]="$any(opField).sufix"
                  placeholder="{{ getOperationFieldType(opField) }}"
                  (mousedown)="$event.stopPropagation()"
                />
              </mat-form-field>
            </ng-container>
          </div>
        </mat-card>
      </div>
    </form>
    <div class="add-action-buttons" fxLayout="row" fxLayoutGap="5px">
      <button mat-button color="accent" (click)="addReplacer()">
        <mat-icon>add</mat-icon>
        {{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.REPLACER' | translate }}
      </button>
      <button mat-button color="accent" (click)="addOperation()">
        <mat-icon>add</mat-icon>
        {{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.OPERATION' | translate }}
      </button>
      <button mat-button color="accent" (click)="addPrefix()">
        <mat-icon>add</mat-icon>
        {{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.PREFIX' | translate }}
      </button>
      <button mat-button color="accent" (click)="addSufix()">
        <mat-icon>add</mat-icon>
        {{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.SUFIX' | translate }}
      </button>
    </div>
  </mat-card>
  <button
    [disabled]="!form.valid"
    id="submit-btn"
    fxFlexAlign="end"
    mat-button
    class="syncee-blue-button"
    (click)="submit()"
  >
    {{ 'RETAILER_EXPORT_TASK_WIZARD.NEW_FIELD.ADD' | translate }}
  </button>
</form>
