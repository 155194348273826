import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ActionTypes, AddFilterWithRedirectAction } from './product-search.action';
import { filter, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { categorySelector } from '../category/category.selector';
import { CategoryService } from '../../service/product-search/category.service';
import { MARKETPLACE_NAVIGATION } from '../../navigation/navigation-routes/common/marketplace.navigation';
import { switchMapWith } from '../../utils/operator/switch-map-with';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';

@Injectable()
export class ProductSearchEffects {
  redirectToSearchPage = createEffect(
    () =>
      this.getSetFilterAction().pipe(
        switchMapWith(() => this.store.select(categorySelector).pipe(omitNullOrUndefined(), take(1))),
        tap(([action, categories]) => {
          const filterFromPayload = action.payload.filter;
          const categoryId = !filterFromPayload || !filterFromPayload.category ? 1 : filterFromPayload.category;
          const categoryName = this.categoryService.getHandleForCategory(
            this.categoryService.searchInCategories(categories, filterFromPayload.category ?? 1)
          );
          this.router.navigate([`${MARKETPLACE_NAVIGATION.COLLECTIONS}/${categoryId}/${categoryName}`], {
            skipLocationChange: !!action.payload.skipLocationChange ? action.payload.skipLocationChange : false,
            queryParams: {
              ...filterFromPayload,
              category: filterFromPayload?.category ?? 1,
              // searchType: 'PRODUCTS',
            },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  private getSetFilterAction(): Observable<AddFilterWithRedirectAction> {
    return this.actions.pipe(
      ofType(ActionTypes.addFilterWithRedirect),
      filter((action: AddFilterWithRedirectAction) => action.payload.needRedirect)
    );
  }

  constructor(
    private actions: Actions,
    private router: Router,
    private store: Store<AppState>,
    private categoryService: CategoryService
  ) {}
}
