<ng-container *ngIf="isDialog">
  <div (click)="openDialog(contentForDialog)">
    <ng-container [ngTemplateOutlet]="triggerContent"></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!isDialog">
  <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="isOverlayOpen = !isOverlayOpen">
    <ng-container [ngTemplateOutlet]="triggerContent"></ng-container>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="['cdk-overlay-transparent-backdrop']"
    [cdkConnectedOverlayWidth]="overlayWidth"
    [cdkConnectedOverlayPositions]="positions"
    (backdropClick)="isOverlayOpen = false"
  >
    <div class="custom-elevation-z1 w-100p">
      <ng-container [ngTemplateOutlet]="overlayContent"></ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #overlayContent>
  <ng-content select="[slot=overlay-content]"></ng-content>
</ng-template>

<ng-template #contentForDialog>
  <app-custom-dialog-wrapper>
    <ng-container [ngTemplateOutlet]="overlayContent"></ng-container>
  </app-custom-dialog-wrapper>
</ng-template>

<ng-template #triggerContent>
  <ng-content select="[slot=trigger]"></ng-content>
</ng-template>
