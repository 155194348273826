import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizardCategoryHeaderService } from '../../service/wizard-category-header.service';
import { WizardCategoryMappingListService } from '../../service/wizard-category-mapping-list.service';
import { CategoryData, CategoryMappingData } from '../../model/category-mapping-data';
import { RemoveMappingData } from '../../model/remove-mapping-data';

@Component({
  selector: 'app-wizard-category-mapping-list',
  templateUrl: './wizard-category-mapping-list.component.html',
  styleUrls: ['./wizard-category-mapping-list.component.scss'],
})
export class WizardCategoryMappingListComponent implements OnInit {
  @Input() categoryItems: CategoryMappingData[];
  @Output() removeItem = new EventEmitter<RemoveMappingData>();
  @Output() addMapping = new EventEmitter<CategoryMappingData>();
  @Output() showProducts = new EventEmitter<CategoryData>();
  constructor(
    public headerService: WizardCategoryHeaderService,
    public listService: WizardCategoryMappingListService
  ) {}

  ngOnInit(): void {}

  onRemoveClick(mapFromId: string, mappedId: string): void {
    this.removeItem.emit({ mapFromCategoryId: mapFromId, mappedCategoryId: mappedId });
  }

  onShowProductsClick(item: CategoryMappingData): void {
    this.showProducts.emit({ id: item.id, name: item.name });
  }
}
