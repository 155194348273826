import { Component, OnInit } from '@angular/core';
import { productCardStatusConfigs } from '../../product-card.config';

@Component({
  selector: 'app-product-card-action-in-store',
  templateUrl: './product-card-action-in-store.component.html',
  styleUrls: ['./product-card-action-in-store.component.scss'],
})
export class ProductCardActionInStoreComponent implements OnInit {
  readonly config = productCardStatusConfigs;

  constructor() {}

  ngOnInit(): void {}
}
