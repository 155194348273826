import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-double-border-icon',
  standalone: true,
  imports: [CommonModule, FlexModule, MatIconModule],
  templateUrl: './double-border-icon.component.html',
  styleUrls: ['./double-border-icon.component.scss'],
})
export class DoubleBorderIconComponent implements OnInit {
  @Input() iconBackgroundColor: string;
  @Input() iconColor: string;
  @Input() icon: string;
  @Input() size = 66;
  @Input() innerSize = 46;
  @Input() iconClass = 's-28';

  constructor() {}

  ngOnInit(): void {}
}
