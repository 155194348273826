import { Injectable, OnDestroy } from '@angular/core';
import { ShippingProfileRestService } from '../../../../service/shipping/shipping-profile-rest.service';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { ShippingZoneDto } from '../../../../vo/shipping-zones/shipping-zone-dto';
import { Continent, CONTINENTS, CountryNode, newCountries } from '../../../../utils/Countries';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../utils/utils';
import { BasicCountryDto } from '../../../../vo/shipping-details/basic-country-dto';
import { ContinentShippingInfo } from './continent-shipping-info';

@Injectable()
export class ShippingDetailsZonesService {
  constructor() {}

  mapToObjectWithConcatenatedCountries(shippingDetails: ShippingZoneDto[]): ShippingDetailsWithConcatenatedCountries[] {
    return shippingDetails.map((shippingDetail) => {
      return {
        ...shippingDetail,
        countries: this.getConcatenatedCoutries(shippingDetail),
      };
    });
  }

  createShippingInformation(
    listOfShippingDetails: ShippingDetailsWithConcatenatedCountries[],
    isWorldwide: boolean
  ): ContinentShippingInfo[] {
    const shippingInformations: Map<Continent, ContinentShippingInfo> = new Map();

    // loop trough continents
    for (const continent of CONTINENTS) {
      const countriesOfContinent = this.getCountriesOfContinent(newCountries[continent]);

      // loop trough countries of continent
      for (const country of countriesOfContinent) {
        // find shippingDetails related to country
        const countryShippingDetails = listOfShippingDetails.find(
          (shipping) =>
            !Utils.isNullOrUndefined(shipping.countries) &&
            (shipping.countries.includes(country.code) || shipping.countries.includes(country.name))
        );

        // if there's no shipping information for the country, we skip the country
        if (Utils.isNullOrUndefined(countryShippingDetails)) {
          continue;
        }

        if (!countryShippingDetails && !isWorldwide) {
          continue;
        }

        // if the map doesn't have a zone shipping info for the country, we add it to the map
        if (!shippingInformations.has(continent)) {
          const zoneShippingInfo = new ContinentShippingInfo(continent, country, countryShippingDetails);
          shippingInformations.set(continent, zoneShippingInfo);

          continue;
        }

        // else we just add the new shipping info to the map
        shippingInformations.get(continent).addCountryShippingInfo(country, countryShippingDetails);
      }
    }

    return Array.from(shippingInformations.values());
  }

  private getCountriesOfContinent(countriesOfContinent: CountryNode[]): CountryNode[] {
    const countries: CountryNode[] = [];

    for (const country of countriesOfContinent) {
      if (country.code !== 'US') {
        countries.push(country);
        continue;
      }

      // we are adding each us states separetly
      for (const state of country.children) {
        const name = state.name + ' (US)';

        countries.push({ name, code: state.code });
      }

      // then we add the us as a country
      countries.push({ name: 'United States', code: 'US' });
    }

    return countries;
  }

  private getConcatenatedCoutries(shippingDetail: ShippingZoneDto): string[] {
    return shippingDetail.countriesLocations.map((country) => this.getConcatenatedCountryCode(country));
  }

  private getConcatenatedCountryCode(country: BasicCountryDto): string {
    return country.provinceCode ? country.countryCode + '-' + country.provinceCode : country.countryCode;
  }
}

export interface ShippingDetailsWithConcatenatedCountries extends Omit<ShippingZoneDto, 'countries'> {
  countries: string[];
}
