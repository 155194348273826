import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ngfModule } from 'angular-file';
import { BillingInfoFormComponent } from 'app/shared/components/billing-info-form/billing-info-form.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { LogoUploadComponent } from 'app/shared/components/logo-upload/logo-upload.component';
import { NotificationSettingsComponent } from 'app/shared/components/notification-settings/notification-settings.component';
import { PaginationWithNumbersComponent } from 'app/shared/components/paginations/pagination-with-numbers/pagination-with-numbers.component';
import { PaymentConnectComponent } from 'app/shared/components/payment-connect/payment-connect.component';
import { ShippingInfoFormComponent } from 'app/shared/components/shipping-info-form/shipping-info-form.component';
import { UniversalBadgeComponent } from 'app/shared/components/universal-badge/universal-badge.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AlertBaseComponent } from '../../shared/components/alert-base/alert-base.component';
import { AlertMultiComponent } from '../../shared/components/alert-multi/alert-multi.component';
import { ChipListSelectorComponent } from '../../shared/components/chip-list-selector/chip-list-selector.component';
import { CustomDividerComponent } from '../../shared/components/custom-divider/custom-divider.component';
import { CustomDialogWrapperComponent } from '../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { EntryComponentDialogWrapperComponent } from '../../shared/components/entry-component-dialog-wrapper/entry-component-dialog-wrapper.component';
import { ErrorMessageComponent } from '../../shared/components/error-message/error-message.component';
import { SingleAlertWithIconComponent } from '../../shared/components/single-alert-with-icon/single-alert-with-icon.component';
import { ConditionLoadingDirective } from '../../shared/directives/condition-loading.directive';
import { ShowOnBreakpointDirective } from '../../shared/directives/show-on-breakpoint.directive';
import { CountryFlagPipe } from '../../shared/pipes/country_flag.pipe';
import { EqualValidator } from '../../utils/EqualValidator.directive';
import { ConnectYourStoreModule } from '../connect-your-store/connect-your-store.module';
import { DraggableModule } from '../draggable/draggable.module';
import { InternationalShippingModule } from '../international-shipping/international-shipping.module';
import { PreferencesModule } from '../preferences/preferences.module';
import { AddfilesModule } from '../taskwizard/addfiles/addfiles.module';
import { PlatformListModule } from './platform-list/platform-list.module';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { SupplierInternationalShippingComponent } from './supplier-international-shipping/supplier-international-shipping.component';
import { SupplierSettingsDialogComponent } from './supplier-settings-dialog/supplier-settings-dialog.component';
import { AddNewTeamMemberComponent } from './user-team/add-new-team-member/add-new-team-member.component';
import { UserTeamListComponent } from './user-team/user-team-list/user-team-list.component';
import { UserTeamMembersComponent } from './user-team/user-team-members/user-team-members.component';
import { UserTeamComponent } from './user-team/user-team.component';
import { UserTeamsComponent } from './user-team/user-teams/user-teams.component';

@NgModule({
  declarations: [
    SupplierDetailsComponent,
    EqualValidator,
    UserTeamComponent,
    AddNewTeamMemberComponent,
    SupplierSettingsDialogComponent,
    SupplierInternationalShippingComponent,
    UserTeamMembersComponent,
    UserTeamsComponent,
    UserTeamListComponent,
  ],
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    RouterModule,
    AddfilesModule,
    DraggableModule,
    ngfModule,
    MatTreeModule,
    FuseSharedModule,
    MatListModule,
    PlatformListModule,
    CustomDividerComponent,
    ChipListSelectorComponent,
    AlertMultiComponent,
    AlertBaseComponent,
    ShowOnBreakpointDirective,
    ErrorMessageComponent,
    ConditionLoadingDirective,
    PreferencesModule,
    CountryFlagPipe,
    SingleAlertWithIconComponent,
    CustomDialogWrapperComponent,
    EntryComponentDialogWrapperComponent,
    ShippingInfoFormComponent,
    BillingInfoFormComponent,
    UniversalBadgeComponent,
    ConnectYourStoreModule,
    NotificationSettingsComponent,
    PaginationWithNumbersComponent,
    TranslateModule,
    MatTooltipModule,
    InternationalShippingModule,
    LoadingSpinnerComponent,
    PaymentConnectComponent,
    LogoUploadComponent,
  ],
  exports: [SupplierDetailsComponent, UserTeamComponent],
})
export class UsersModule {}
