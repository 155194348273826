import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WidgetTranslatePipe } from 'app/shared/pipes/widget-translate.pipe';
import { Widget } from 'app/vo/widget';
import { SkeletonModule } from '../skeleton/skeleton.module';

@Component({
  selector: 'app-category-cards',
  templateUrl: './category-cards.component.html',
  styleUrls: ['./category-cards.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, WidgetTranslatePipe, SkeletonModule],
})
export class CategoryCardsComponent implements OnInit {
  @Input() title: string;
  @Input() titlePosition: 'start' | 'center';
  @Input() description: string;
  @Input() cardsContent: Widget[];
  @Input() cardOrientation: 'landscape' | 'portrait';
  @Input() cardTitleHorizontalPosition: 'middle' | 'right' | 'left';
  @Input() cardTitleVerticalPosition: 'center' | 'bottom' | 'top';
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  @Input() background: string;
  @Input() cardHeight: number;
  @Output() cardClicked = new EventEmitter<Widget>();

  constructor() {}

  ngOnInit(): void {}

  emitCardClicked(card: Widget): void {
    this.cardClicked.emit(card);
  }
}
