import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notification-overlay-action-bar',
  templateUrl: './notification-overlay-action-bar.component.html',
  styleUrls: ['./notification-overlay-action-bar.component.scss'],
})
export class NotificationOverlayActionBarComponent implements OnInit {
  @Output() readAllClicked = new EventEmitter<void>();
  @Output() settingsClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
