import { RestResponse } from './rest-response';

export class FetchRestResponse extends RestResponse {
  readonly totalRows: number;

  public static create(rawResponse: any): FetchRestResponse {
    return new FetchRestResponse(rawResponse);
  }

  constructor(rawResponse: any) {
    super(rawResponse);
    this.totalRows = rawResponse.totalRows;
  }
}
