<ng-template #header_left>
  <h3 mat-dialog-title class="header-title">
    {{ 'APPROVAL_DIALOG.TITLE' | translate : { supplier: approveData.supplierName } }}
  </h3>
</ng-template>

<ng-template #action_bar>
  <ng-container [ngSwitch]="contentType">
    <div *ngSwitchCase="'approve'" class="p-20" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button
        mat-button
        type="button"
        class="od-button od-ripple small flat action-button"
        [disabled]="sendApproveLoading"
        (click)="dialogRef.close()"
      >
        {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.DISAGREE' | translate }}
      </button>
      <button
        mat-flat-button
        class="od-button small action-button syncee-light-blue-button"
        [disabled]="approveData.needResponse && message.invalid"
        *ngIf="!sendApproveLoading; else loadingTemp"
        (click)="sendApprove()"
      >
        {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.AGREE' | translate }}
      </button>
    </div>

    <div *ngSwitchCase="'accept'" class="p-20" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button mat-button type="button" class="od-button od-ripple small flat action-button" (click)="dialogRef.close()">
        {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.CLOSE' | translate }}
      </button>
      <button
        mat-button
        type="button"
        class="od-button small action-button syncee-light-blue-button"
        (click)="accept()"
      >
        {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT' | translate }}
      </button>
    </div>
  </ng-container>
</ng-template>

<app-custom-dialog-wrapper [hideCloseBtn]="true" [headerLeftRef]="header_left" [actionBarRef]="action_bar">
  <div class="p-20">
    <ng-container *ngIf="contentType == 'approve'">
      <div class="content">
        <h5 [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.TITLE' | translate" class="content-title"></h5>
        <div
          *ngIf="approveData.isAutoOrder"
          [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.CONTENT_HAS_ORDER_HAS_APPROVE' | translate"
        ></div>
        <div *ngIf="!approveData.isAutoOrder">
          <div [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.CONTENT_NO_ORDER_HAS_APPROVE' | translate"></div>
          <div>
            <a
              *ngIf="hasAccessToReadContact; else needSubscriptionBadge"
              [href]="checkURL(approveData.website)"
              target="_blank"
              >{{ approveData.website }}</a
            >
          </div>
        </div>
      </div>
    </ng-container>

    <div class="content">
      <h5 [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.TITLE' | translate" class="content-title"></h5>
      <div *ngIf="approveData.isAutoOrder" class="list-content">
        <span [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.CONTENT_HAS_ORDER' | translate"></span>
      </div>
      <div *ngIf="!approveData.isAutoOrder" class="list-content">
        <div [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.CONTENT_NO_ORDER' | translate"></div>
        <div>
          <a
            *ngIf="hasAccessToReadContact | async; else needSubscriptionBadge"
            [href]="checkURL(approveData.website)"
            target="_blank"
            >{{ approveData.website }}</a
          >
        </div>
        <div [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.CONTENT_SHIPPING_TAX_INFO' | translate"></div>
      </div>
    </div>

    <div class="content">
      <h5 [innerHTML]="'APPROVAL_DIALOG.CONTACT.TITLE' | translate" class="content-title"></h5>
      <div
        *ngIf="hasAccessToReadContact; else needSubscriptionBadge"
        [innerHTML]="'APPROVAL_DIALOG.CONTACT.CONTENT' | translate : { email: approveData.email }"
      ></div>
    </div>

    <div class="content" *ngIf="approveData.approveDescription && approveData.approveDescription.length > 0">
      <h5
        [innerHTML]="
          contentType == 'accept'
            ? ('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_MESSAGE_TITLE' | translate)
            : ('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.MESSAGE2' | translate)
        "
        class="content-title"
      ></h5>
      <div [innerHTML]="approveData.approveDescription" class="content-holder"></div>
    </div>

    <div *ngIf="approveData.needResponse">
      <h5
        [innerHTML]="'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.INFO_NEEDS' | translate"
        class="content-title"
      ></h5>
      <mat-form-field appearance="fill" class="w-100p">
        <textarea matInput [formControl]="message" rows="5"></textarea>
        <mat-error *ngIf="message.invalid">
          {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.LIMIT' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--    <ng-container *ngIf="approveData.approveDescription && approveData.approveDescription.length > 0">-->
    <!--        <h3 class="py-12 bold-text">{{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_MESSAGE_TITLE' | translate }}</h3>-->
    <!--        <div [innerHTML]="approveData.approveDescription" class="content-holder pb-8"></div>-->
    <!--    </ng-container>-->

    <!--    <div [innerHTML]="'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.MESSAGE' | translate"></div>-->
    <!--    <ng-container *ngIf="!approveData.isAutoOrder; else hasAutoOrder">-->
    <!--        <div class="py-12" *ngIf="approveData.website">-->
    <!--        &lt;!&ndash;      <div [innerHTML]="'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.MESSAGE3' | translate"> </div>&ndash;&gt;-->
    <!--          <div [innerHTML]="('GENERAL.SYNCEE_NO_ORDER_MANAGEMENT' | translate: {website: checkURL(approveData.website)})"></div>-->
    <!--          <br>-->
    <!--          <div [innerHTML]="'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.MESSAGE2563' | translate"></div>-->
    <!--            <br>-->
    <!--            <div [innerHTML]="('GENERAL.SHIPPING_INFORMATION' | translate)"></div>-->
    <!--            <br>-->
    <!--            <div [innerHTML]="('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.TECHNICAL_QUESTION' | translate)"></div>-->
    <!--        </div>-->
    <!--    </ng-container>-->
    <!--    <h3 *ngIf="approveData.approveDescription && approveData.approveDescription.length > 0">{{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.MESSAGE2' | translate }}: </h3>-->
    <!--    <div [innerHTML]="approveData.approveDescription" class="content-holder pb-8">-->
    <!--    </div>-->
    <!--    <div *ngIf="approveData.needResponse">-->
    <!--      <h3 class="py-12">{{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.INFO_NEEDS' | translate }}: </h3>-->
    <!--      <mat-form-field appearance="fill" class="w-100p">-->
    <!--        <textarea matInput [formControl]="message" rows="5"></textarea>-->
    <!--        <mat-error *ngIf="message.invalid">-->
    <!--          {{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.LIMIT' | translate}}-->
    <!--        </mat-error>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->
    <!--    <div class="pt-12">-->
    <!--      <mat-checkbox [formControl]="acceptCheckbox" (change)="checkboxChanged()">{{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_REQ' | translate}}</mat-checkbox>-->
    <!--    </div>-->
    <!--    <div *ngIf="acceptCheckboxError" class="req-error">-->
    <!--      {{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_REQ_ERROR' | translate}}-->
    <!--    </div>-->
  </div>
</app-custom-dialog-wrapper>

<!--<div *ngIf="contentType == 'accept'">-->
<!--  <mat-dialog-content class="box-content">-->
<!--    <ng-container *ngIf="!approveData.isAutoOrder; else hasAutoOrder">-->
<!--      <div *ngIf="approveData.website">-->
<!--          <span [innerHTML]="('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.FREE_TO_USE' | translate)"></span>-->
<!--          <span [innerHTML]="('GENERAL.SYNCEE_NO_ORDER_MANAGEMENT' | translate: {website: checkURL(approveData.website)})"></span>-->
<!--      </div>-->
<!--      <div>-->
<!--          <span [innerHTML]="('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_MESSAGE' | translate)"></span>-->
<!--      </div>-->
<!--      <div [innerHTML]="('GENERAL.SHIPPING_INFORMATION' | translate)" class="py-8"></div>-->
<!--    </ng-container>-->
<!--    <div>-->
<!--      <span [innerHTML]="('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.TECHNICAL_QUESTION' | translate)"></span>-->
<!--    </div>-->
<!--    <ng-container *ngIf="approveData.approveDescription && approveData.approveDescription.length > 0">-->
<!--        <h3 class="py-12 bold-text">{{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_MESSAGE_TITLE' | translate }}</h3>-->
<!--        <div [innerHTML]="approveData.approveDescription" class="content-holder pb-8"></div>-->
<!--    </ng-container>-->
<!--&lt;!&ndash;    <div class="pt-12">&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-checkbox [formControl]="acceptCheckbox" (change)="checkboxChanged()">{{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_REQ' | translate}}</mat-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div *ngIf="acceptCheckboxError" class="req-error">&ndash;&gt;-->
<!--&lt;!&ndash;      {{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_REQ_ERROR' | translate}}&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </mat-dialog-content>-->
<!--  <mat-dialog-actions class="action-container" fxLayoutAlign="end center">-->
<!--    <button mat-button type="button" class="od-button od-ripple small flat action-button"-->
<!--            (click)="dialogRef.close()">{{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.CLOSE' | translate}}</button>-->
<!--    <button mat-button type="button" color="warn" class="od-button small action-button" style="margin-right: 0;"-->
<!--            (click)="accept()"> {{'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT' | translate}} </button>-->
<!--  </mat-dialog-actions>-->
<!--</div>-->

<ng-template #loadingTemp>
  <app-loading-spinner [size]="40"></app-loading-spinner>
</ng-template>

<ng-template #needSubscriptionBadge>
  <app-need-subscription-badge></app-need-subscription-badge>
</ng-template>
