import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { FilterAddItemButtonComponent } from './components/filter-add-item-button/filter-add-item-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FilterEmptyGroupComponent } from './components/filter-empty-group/filter-empty-group.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FilterItemListComponent } from './components/filter-item-list/filter-item-list.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleWithTextComponent } from '../../shared/components/slide-toggle-with-text/slide-toggle-with-text.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { ButtonWithIconDirective } from '../../shared/directives/button-with-icon.directive';
import { FilterBaseModifiersService } from './service/filter-base-modifiers.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShowOnBreakpointDirective } from '../../shared/directives/show-on-breakpoint.directive';
import { AlertMultiComponent } from '../../shared/components/alert-multi/alert-multi.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FilterComponent,
    FilterGroupComponent,
    FilterItemComponent,
    FilterAddItemButtonComponent,
    FilterEmptyGroupComponent,
    FilterItemListComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatSlideToggleModule,
    SlideToggleWithTextComponent,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    ButtonWithIconDirective,
    MatTooltipModule,
    ShowOnBreakpointDirective,
    AlertMultiComponent,
    TranslateModule,
  ],
  exports: [FilterComponent],
  providers: [FilterBaseModifiersService],
})
export class FilterNewModule {}
