<app-custom-dialog-wrapper [headerLeftRef]="headerTitle" [actionBarRef]="actions">
  <div class="w-100-p px-20" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center start">
    <div class="typography-body-1">
      {{ 'IMAGE_TEMPLATES.DESCRIPTION' | translate }}
    </div>
    <app-image-scroll-area
      class="w-100-p"
      [templateType]="data.templateType"
      [selected]="selected"
      (setSelected)="handleSetSelected($event)"
    ></app-image-scroll-area>
  </div>
</app-custom-dialog-wrapper>

<ng-template #headerTitle>
  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start start">
    <div class="h3 mt-0">
      {{ 'IMAGE_TEMPLATES.SELECT' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #actions>
  <div class="w-100-p p-20" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button class="syncee-blue-button" (click)="closeDialog()">
      {{ 'IMAGE_TEMPLATES.SAVE' | translate }}
    </button>
  </div>
</ng-template>
