<div *ngIf="settingsForm" [formGroup]="settingsForm">
  <mat-card class="retailer-mapping-section">
    <div
      adminComment
      [adminCommentTaskId]="taskId"
      [adminCommentTaskType]="'import'"
      [adminCommentStep]="'settings'"
      [adminCommentMappingField]="'settings'"
      [adminCommentComments]="comments"
    ></div>

    <div class="h3 settings-title">{{ 'RETAILER_TASK_WIZARD.RETAILER.BASIC_MAPPING_SETTINGS' | translate }}</div>

    <mat-card-content formGroupName="settings">
      <!--Published-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.STATUS_LABEL' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.STATUS_CONTENT' | translate }}</p>
        </div>

        <div>
          <mat-slide-toggle [checked]="true" formControlName="published"></mat-slide-toggle>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select [value]="true" formControlName="published">-->
          <!--<mat-option *ngFor="let option of mappingSettingsService?.trueOrFalseOptions"-->
          <!--[value]="option.value">-->
          <!--{{option.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--Inventory management-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.TRACK_QTY_LABEL' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.TRACK_QTY_CONTENT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="inventory">
              <mat-option value="dont">{{
                'RETAILER_TASK_WIZARD.JUMPSELLER.TRACK_QTY_SELECT.DONT' | translate
              }}</mat-option>
              <mat-option value="track">{{
                'RETAILER_TASK_WIZARD.JUMPSELLER.TRACK_QTY_SELECT.TRACK' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Weight unit-->
      <!--      <div class="settings-field">-->
      <!--        <div>-->
      <!--          <p>Weight unit</p>-->
      <!--        </div>-->

      <!--        <div>-->
      <!--          <mat-form-field appearance="fill">-->
      <!--            <mat-select value="kg" formControlName="weightUnit">-->
      <!--              <mat-option value="kg">kg</mat-option>-->
      <!--              <mat-option value="oz">oz</mat-option>-->
      <!--              <mat-option value="lb">lb</mat-option>-->
      <!--              <mat-option value="g">g</mat-option>-->
      <!--            </mat-select>-->
      <!--          </mat-form-field>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.HEIGTH_UNIT_LABEL' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select formControlName="heightUnit">
              <mat-option *ngFor="let unit of dimensions | keyvalue" [value]="unit.key">
                {{ unit.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.WIDTH_UNIT_LABEL' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="kg" formControlName="widthUnit">
              <mat-option *ngFor="let unit of dimensions | keyvalue" [value]="unit.key">
                {{ unit.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.LENGTH_UNIT_LABEL' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="kg" formControlName="lengthUnit">
              <mat-option *ngFor="let unit of dimensions | keyvalue" [value]="unit.key">
                {{ unit.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="retailer-mapping-section">
    <div class="h3 settings-title">{{ 'RETAILER_TASK_WIZARD.RETAILER.UPDATE_OPTIONS' | translate }}</div>

    <mat-card-content formGroupName="updateOptions">
      <!--Update type-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.UPDATE_TYPE' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="insert" formControlName="updateType">
              <mat-option value="UPLOAD_DF_MODE">{{ 'RETAILER_TASK_WIZARD.RETAILER.INSERT' | translate }}</mat-option>
              <mat-option value="UPDATE_DF_MODE">{{ 'RETAILER_TASK_WIZARD.RETAILER.UPDATE' | translate }}</mat-option>
              <mat-option value="CRUD_DF_MODE">{{ 'RETAILER_TASK_WIZARD.RETAILER.UPSERT' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Product not in the feed-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WHAT_SHALL' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.THIS_SETTINGS_ONLY' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0hidden" formControlName="productNotInFeed">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_TOUCH' | translate }}</mat-option>
              <mat-option value="qty0">{{ 'RETAILER_TASK_WIZARD.RETAILER.SET_THE_QTY' | translate }}</mat-option>
              <mat-option value="qty0hidden">{{
                'RETAILER_TASK_WIZARD.RETAILER.HIDE_THE_PRODUCT' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--0 quantity feed-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WHAT_SHALL_WE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.THIS_SETTING_ONLY_MODIFIES' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0" formControlName="productQuantity0">
              <mat-option value="qty0">{{ 'RETAILER_TASK_WIZARD.RETAILER.SET_THE_QTY' | translate }}</mat-option>
              <mat-option value="qty0hidden">{{
                'RETAILER_TASK_WIZARD.RETAILER.HIDE_THE_PRODUCT' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- 0/delete qty variant -->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.JUMPSELLER.WHAT_SHALL_4' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="qty0" formControlName="variantQtyUpload">
              <mat-option value="dont">{{ 'RETAILER_CATALOG_SETTING.JUMPSELLER.NOT_UPLOAD' | translate }}</mat-option>
              <mat-option value="qty0">{{
                'RETAILER_CATALOG_SETTING.JUMPSELLER.UPLOAD_WITH_ZERO_QTY' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Compare-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.HOW_CAN_SYNCEE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.IF_YOU_ALREADY' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="compare">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_COMPARE' | translate }}</mat-option>
              <mat-option value="sku">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_SKU' | translate }}</mat-option>
              <mat-option value="title">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_PRODUCT_TITLE' | translate }}</mat-option>
              <mat-option value="handle">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_HANDLE' | translate }}</mat-option>
              <mat-option value="barcode">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_BARCODE' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Variant compare-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.HOW_CAN_SYNCEE2' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_DEFAULT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="dont" formControlName="variantCompare">
              <mat-option value="dont">{{ 'RETAILER_TASK_WIZARD.RETAILER.DO_NOT_COMPARE' | translate }}</mat-option>
              <mat-option value="sku">{{ 'RETAILER_TASK_WIZARD.RETAILER.BY_VARIANT_SKU' | translate }}</mat-option>
              <mat-option value="barcode">{{
                'RETAILER_TASK_WIZARD.RETAILER.BY_VARIANT_BARCODE' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Rename images-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WOULD_YOU_LIKE' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.IF_YOU_SELECT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill" class="select-without-label">
            <mat-select value="yes" formControlName="renameImages">
              <mat-option value="yes">{{ 'RETAILER_TASK_WIZARD.RETAILER.YES' | translate }}</mat-option>
              <mat-option value="no">{{ 'RETAILER_TASK_WIZARD.RETAILER.NO' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Fields to update-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.WHAT_FIELDS' | translate }}</p>
          <p>{{ 'RETAILER_TASK_WIZARD.RETAILER.SYNCEE_UPDATES' | translate }}</p>
        </div>

        <app-checkbox-selection-list
          (checkBoxesChange)="updateChanges()"
          [(checkBoxes)]="fieldsToUpdate"
        ></app-checkbox-selection-list>
      </div>

      <!--Fields not to update-->
      <div id="fields-not-to-update-container" class="settings-field">
        <div></div>

        <app-checkbox-selection-list
          (checkBoxesChange)="updateChanges()"
          [(checkBoxes)]="optionFieldsUpdate"
        ></app-checkbox-selection-list>
      </div>
    </mat-card-content>
  </mat-card>

  <!--Save button-->
  <!--<div id="retailer-catalog-settings-save" class="centered" *ngIf="showSaveButton">
      <button mat-button color="primary">Save</button>
  </div>-->
</div>
