import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { zipObject } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogService {
  private typeFields = [
    { value: '%', name: 'All activity' },
    { value: 'test', name: 'Test' },
  ];

  constructor(private restService: RestService) {}

  public getActivityLogs(from: any, to: any, type: any): Observable<ActivityLogItem[]> {
    return this.restService
      .get(`ActivityLogService/getActivityLogForUser?to=${to}&from=${from}&type=${type}`)
      .pipe(map((response) => response.getData()));
  }

  public getTypes(): Observable<any[]> {
    return this.restService.get(`ActivityLogService/getTypes`).pipe(map((response) => response.getData()));
  }

  public mapFetchedActivityLogsToTableItems(activityLogs: ActivityLogItem[]): ActivityLogTableItem[] {
    return activityLogs.map(function (item): ActivityLogTableItem {
      if (item.link) {
        const itemParams = item.link.substr(item.link.indexOf('?') + 1).split('&');
        const paramKeys: string[] = [];
        const paramValues: string[] = [];
        itemParams.forEach((param) => {
          const splitParam = param.split('=');
          paramKeys.push(splitParam[0]);
          paramValues.push(splitParam[1]);
        });
        return {
          ...item,
          path: item.link.substring(item.link.indexOf('/'), item.link.indexOf('?')),
          linkText: item.link.substring(0, item.link.indexOf('/')),
          params: zipObject(paramKeys, paramValues),
        };
      } else {
        return {
          ...item,
          path: null,
          linkText: null,
          params: null,
        };
      }
    });
  }
}

export interface ActivityLogItem {
  date: string;
  id: string;
  isAdmin: boolean;
  link: string;
  message: string;
  type: string;
  user: string;
}

export interface ActivityLogTableItem extends ActivityLogItem {
  path: string;
  linkText: string;
  params: Record<string, string>;
}
