<div
  fxLayoutAlign="start center"
  fxLayout.lt-sm="column"
  fxLayout="row"
  fxLayoutGap="20px"
  fxLayoutGap.lt-sm="12px"
  class="stepper-dialog-header-container"
>
  <div
    fxFlex="0 0 auto"
    fxLayoutAlign="center center"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="space-between center"
    fxLayoutGap="24px"
  >
    <h1 fxFlex="0 0 auto" class="title">{{ title | translate }}</h1>
    <div fxFlex="0 0 auto" class="typography-body-1 step-indicator">
      {{ 'STEPPER_DIALOG_HEADER.INDEX_PAGINATION' | translate : { current: currentStepIndex + 1, max: maxStepNumber } }}
    </div>
  </div>
  <div fxFlex="1 0 0" class="w-100p stepper-progress-bar" *ngIf="steps">
    <div *ngFor="let step of steps" class="step" [class]="step <= currentStepIndex ? 'selected' : ''"></div>
    <!--    <mat-progress-bar mode="determinate" [value]="((currentStepIndex + 1) / maxStepNumber) * 100"></mat-progress-bar>-->
  </div>
</div>
