import { HttpErrorResponse } from '@angular/common/http';
import { RestResponse } from './rest-response';

export class RequestError {
  public readonly errorCode: string;
  public readonly errorMessage: string;
  public readonly errorType: string;

  public static create(err: RestResponse | HttpErrorResponse): RequestError {
    if (err instanceof RestResponse) {
      return new RequestError(err.errorCode, err.errorMessage);
    } else if (err instanceof HttpErrorResponse) {
      return new RequestError(err.status.toString(), err.message, err?.error?.type);
    }
    return null;
  }

  constructor(errorCode: string, errorMessage: string, errorType?: string) {
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.errorType = errorType;
  }
}
