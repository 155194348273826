import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-numeric-card',
  templateUrl: './numeric-card.component.html',
  styleUrls: ['./numeric-card.component.scss'],
})
export class NumericCardComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  value: number;
  @Input()
  percentage: number;
  @Input()
  unitOfMeasurement: string;
  @Input()
  digitsInfo: string;
  @Input()
  tooltip: string;

  constructor() {}

  ngOnInit(): void {}
}
