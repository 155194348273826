import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { AlertBaseComponent, AlertBaseType, Layout } from '../alert-base/alert-base.component';

@Component({
  selector: 'app-alert-multi',
  templateUrl: './alert-multi.component.html',
  styleUrls: ['./alert-multi.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, FlexModule, AlertBaseComponent],
})
export class AlertMultiComponent implements OnInit {
  @Input() messages: string[];
  @Input() type: AlertBaseType;
  @Input() layout: Layout;
  @Input() matIcon?: string;
  @Input() matIconColor?: string;
  @Input() readMoreLink?: string;
  @Input() matIconClass?: string;
  @Input() hideIcon = false;

  ngOnInit(): void {}
}
