import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RestService } from '../../service/rest/rest.service';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { fuseAnimations } from '../../../@fuse/animations';
import { FileList } from './filelist';
import { Utils } from '../../utils/utils';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbNavigationItem } from 'app/shared/components/breadcrumb-navigation/breadcrumb-navigation.component';

@Component({
  selector: 'app-ftp',
  templateUrl: './ftp.component.html',
  styleUrls: ['./ftp.component.scss'],
  animations: fuseAnimations,
})
export class FtpComponent implements OnInit {
  @ViewChild('ftpConnectionForm', { static: true }) ftpConnectionForm: NgForm;
  public ftpCredentials = new FtpConnectionVO();
  public fileList = new Array<FileList>();
  selected: FileList;

  breadCrumb: Array<BreadcrumbNavigationItem> = new Array<BreadcrumbNavigationItem>();
  public upDir: FileList;
  public hideFtpManager = true;
  public disableDownloadButton = true;
  public isFileDownloadSuccessful = false;
  public nodeTree: any[];
  public selectedNodeTree: string[];
  public const;
  file: FileNodeTreeVO;

  public isConnectButtonPressed = false;

  isFtp = false;

  public connectionTypes = [
    {
      name: 'FTP',
      type: 'FTP',
    },
    {
      name: 'URL',
      type: 'URL',
    },
    {
      name: 'FILE UPLOAD',
      type: 'FU',
    },
  ];

  constructor(private route: ActivatedRoute, private restService: RestService, private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.ftpCredentials.taskId = 1;
    this.ftpCredentials.connectionType = 'FTP';

    // test esetéig nem kell mindig kitölteni a formot
    // this.ftpCredentials.host = '35.204.4.197';
    // this.ftpCredentials.port = '21';
    // this.ftpCredentials.username = 'agulyascustomsynceeftp';
    // this.ftpCredentials.password = '4KsseNxgFhD#T';
    // this.ftpCredentials.root = '/ldavid';

    this.upDir = new FileList();
    this.upDir.name = '..';
    this.upDir.isDirectory = true;

    this.route.queryParams.subscribe((params) => {
      this.ftpCredentials.taskId = params['task_id'];
      console.log(this.ftpCredentials.taskId);
    });
  }

  changedFtpConnection(event): void {
    if (event.value === 'FTP') {
      this.isFtp = true;
    } else {
      this.isFtp = false;
      this.hideFtpManager = true;
    }
  }

  connectFtp(): void {
    this.isConnectButtonPressed = true;
    if (this.ftpConnectionForm.valid) {
      const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
      this.restService.post('FtpService/connect', this.ftpCredentials, requestOptions).subscribe(
        (res) => {
          this.hideFtpManager = false;
          this.parseFileList(res.getData());
          this.setBreadCrumbPath(this.ftpCredentials.root);

          this.isConnectButtonPressed = false;
        },
        (error) => {
          this.isConnectButtonPressed = false;
          console.log(error);
        }
      );
    }
  }

  rowSingleClick(item: FileList): void {
    if (!item.isDirectory) {
      // this.selected = this.fileList.find(file => file.name == item.name);
      this.selected = item;
      this.disableDownloadButton = false;
    } else {
      this.disableDownloadButton = true;
    }
  }

  download(selected: FileList): void {
    this.disableDownloadButton = true;
    const downloadFile = new DownloadFileVO();
    downloadFile.taskId = this.ftpCredentials.taskId;
    downloadFile.remotePath = selected.path + '/' + selected.name;
    this.restService.post('FtpService/download', downloadFile).subscribe(
      (res) => {
        this.disableDownloadButton = false;
        if (res.isSuccess()) {
          this.isFileDownloadSuccessful = true;
          this.processFile(res.getFirstData().fileId);
          return;
        } else {
          alert('Error occurred during download file');
        }
      },
      (error) => {
        this.disableDownloadButton = false;
        console.log(error);
      }
    );
  }

  processFile(fileId: number): void {
    this.file = new FileNodeTreeVO();
    this.file.fileId = fileId;
    this.file.taskId = this.ftpCredentials.taskId;
    this.file.nodeTree = [];
    this.file.settings = [];

    this.restService.post('FileProcessService/getAndSetNodeTreeFW', this.file).subscribe(
      (res) => {
        this.nodeTree = res.getFirstData().nodeTree;
      },
      (error) => console.log(error)
    );
  }

  selectrionChange(selectedPath: string): void {
    this.file.settings = { container: selectedPath };

    this.restService.post('FileProcessService/saveFileSettings', this.file).subscribe(
      (res) => {
        console.log(res.getData());
      },
      (error) => console.log(error)
    );
  }

  breadCrumbCalback(bc: string): void {
    this.ftpCredentials.root = bc;
    this.restService.post('FtpService/listDir', this.ftpCredentials).subscribe(
      (res) => {
        this.parseFileList(res.getData());
        this.setBreadCrumbPath(this.ftpCredentials.root);
      },
      (error) => console.log(error)
    );
  }

  addBcElement(label: string, url: string): void {
    const bc: BreadcrumbNavigationItem = {
      key: label,
      url: url,
      id: url,
    };

    this.breadCrumb.push(bc);
  }

  rowDoubleClick(item: FileList): void {
    if (item.isDirectory) {
      let path = '';
      if (item.name === '..') {
        if (this.ftpCredentials.root === '/') {
          return;
        }
        const paths = this.ftpCredentials.root.split('/');

        const filtered = paths.filter(function (el): boolean {
          return el !== '';
        });

        for (let i = 0; i < filtered.length; i++) {
          path += paths[i] + '/';
        }
        this.ftpCredentials.root = path;
      } else {
        this.ftpCredentials.root = item.path.replace('//', '/') + '/' + item.name;
      }
      this.restService.post('FtpService/listDir', this.ftpCredentials).subscribe(
        (res) => {
          this.parseFileList(res.getData());
          this.setBreadCrumbPath(this.ftpCredentials.root);
        },
        (error) => console.log(error)
      );
    }
  }

  parseFileList(list: any): void {
    // this.fileList = new Array<FileList>();
    const tempFileList = new Array<FileList>();
    for (const item of list) {
      const splittedItems = item.split('\\#');
      const listItem: FileList = new FileList();
      listItem.isDirectory = splittedItems[0] === 'directory' ? true : false;

      const splittedPath = splittedItems[1].split('//');
      listItem.path = splittedPath[0];
      listItem.name = splittedPath[1];
      tempFileList.push(listItem);
      //    this.fileList.push(listItem);
    }
    this.fileList = Utils.getOrderedFileList(tempFileList);

    this.fileList.unshift(this.upDir);
  }

  setBreadCrumbPath(path: string): void {
    const paths = path.split('/');
    this.breadCrumb = new Array<BreadcrumbNavigationItem>();
    this.addBcElement('HOME', '/');
    let temp;
    for (const item of paths) {
      if (item === '') {
        temp = '/';
        continue;
      }
      temp += item + '/';
      const bc: BreadcrumbNavigationItem = {
        key: item,
        url: temp,
        id: temp,
      };

      this.breadCrumb.push(bc);
    }
  }
}

export class FtpConnectionVO {
  taskId: number;
  host: string;
  username: string;
  password: string;
  isSsl: string;
  port: string;
  timeout: string;
  passive: string;
  root: string;
  connectionType: string;
}

export class DownloadFileVO {
  taskId: number;
  remotePath: string;
}

export class FileNodeTreeVO {
  fileId: number;
  taskId: number;
  nodeTree: any[];
  settings: any;
}
