import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-dialog-wrapper',
  templateUrl: './custom-dialog-wrapper.component.html',
  styleUrls: ['./custom-dialog-wrapper.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, FlexLayoutModule, MatDialogModule, MatButtonModule],
})
export class CustomDialogWrapperComponent implements OnInit {
  @Input() hideCloseBtnDesktop = false;
  @Input() hideCloseBtnTablet = false;
  @Input() hideCloseBtnMobile = false;
  @Input() closeWithRedirect: boolean;
  @Input() hideCloseBtn = false;
  @Input() headerLeftRef: TemplateRef<any>;
  @Input() actionBarRef: TemplateRef<any>;
  @Input() headerPadding = '20px';
  @Input() headerCentered = false;

  closeButtonHidden = false;
  private _unsubscribeAll: Subject<void>;

  constructor() {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {}
}
