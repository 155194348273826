<div
  class="setup-guide-item-list-content"
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-lg="center start"
  fxLayoutGap="40px"
  fxLayoutGap.lt-md="20px"
  fxLayout="column"
  fxLayout.gt-md="row"
>
  <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="32px" fxLayoutGap.lt-md="20px">
    <div class="description" [innerHTML]="description | getI18nFromBackendJson"></div>
    <div *showOnBreakpoint="'gt-lg'">
      <button
        *ngIf="!!buttonPath"
        mat-flat-button
        color="primary"
        [routerLink]="buttonPath.path"
        [queryParams]="buttonPath.queryParams"
        (click)="handleClose.emit()"
      >
        {{ button | getI18nFromBackendJson }}
      </button>
    </div>
  </div>
  <div fxFlex="1 0 0" fxFlex.gt-lg="0 0 555px" *ngIf="!!videoUrl">
    <div class="iframe-container">
      <iframe
        class="snippet-iframe"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        [src]="videoUrl | sanitizeResourceUrl"
        width="1280"
        height="720"
      ></iframe>
    </div>
  </div>

  <div *showOnBreakpoint="'lg'">
    <button
      *ngIf="!!buttonPath"
      mat-flat-button
      color="primary"
      [routerLink]="buttonPath.path"
      [queryParams]="buttonPath.queryParams"
      (click)="handleClose.emit()"
    >
      {{ button | getI18nFromBackendJson }}
    </button>
  </div>
</div>
