import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { SetActualRole } from '../../store/user/user.actions';
import { hasRoleSelector } from '../../store/user/user.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { RoleDialogService } from './role-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AgentGuard implements CanActivate {
  constructor(private roleDialogService: RoleDialogService, private router: Router, private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    this.roleDialogService.handleCenterNavigationAttempt(RolesEnum.RETAILER);
    return this.store.select(hasRoleSelector(RolesEnum.RETAILER)).pipe(
      omitNullOrUndefined(),
      take(1),
      tap((hasRole) => {
        if (!hasRole) {
          this.router.navigate(['/login']);
        } else {
          this.store.dispatch(new SetActualRole(RolesEnum.RETAILER));
        }
      })
    );
  }
}
