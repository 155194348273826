<ng-container>
  <ng-container *ngIf="!hasError; else error">
    <div id="notification-settings">
      <app-custom-table
        [fetcher]="getSettings.bind(this)"
        [displayedColumns]="getDisplayedColumns()"
        [isPaginate]="false"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let item">
            {{ item.translateKey | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{ 'NOTIFICATION_SETTINGS.EMAIL' | translate }}</th>
          <td mat-cell *matCellDef="let item">
            <mat-checkbox
              class="syncee-blue-checkbox"
              [(ngModel)]="item.email"
              (change)="changeStatus($event, item.id, 'email')"
            ></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="web">
          <th mat-header-cell *matHeaderCellDef>{{ 'NOTIFICATION_SETTINGS.WEB' | translate }}</th>
          <td mat-cell *matCellDef="let item">
            <mat-checkbox
              class="syncee-blue-checkbox"
              [(ngModel)]="item.web"
              (change)="changeStatus($event, item.id, 'web')"
            ></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="slack">
          <th mat-header-cell *matHeaderCellDef>{{ 'NOTIFICATION_SETTINGS.SLACK' | translate }}</th>
          <td mat-cell *matCellDef="let item">
            <mat-checkbox
              class="syncee-blue-checkbox"
              [(ngModel)]="item.slack"
              (change)="changeStatus($event, item.id, 'slack')"
            ></mat-checkbox>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let item; columns: getDisplayedColumns()"></mat-row>
      </app-custom-table>
    </div>
  </ng-container>
</ng-container>

<ng-template #progress>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>

<ng-template #error>
  <div>{{ 'ERROR.ERROR_UNKNOWN' | translate }}</div>
</ng-template>
