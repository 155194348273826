import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-chat-layout-mobile',
  templateUrl: './ai-chat-layout-mobile.component.html',
  styleUrls: ['./ai-chat-layout-mobile.component.scss'],
})
export class AiChatLayoutMobileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
