import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryState } from './category.reducer';
import { isEmpty } from 'lodash';
import { Utils } from '../../utils/utils';

const getCategoryState = createFeatureSelector<CategoryState>('categories');

export const categorySelector = createSelector(getCategoryState, (state) => !!state && state.categories);
export const flattenCategoriesSelector = createSelector(
  getCategoryState,
  (state) => !!state && state.flattenCategories
);
export const firstLevelCategoriesSelector = createSelector(getCategoryState, (state) => {
  switch (true) {
    case Utils.isNullOrUndefined(state):
    case Utils.isNullOrUndefined(state?.categories):
      return null;
    default:
      return state.categories.children;
  }
});
export const categoryByIdSelector = createSelector(
  getCategoryState,
  (state: CategoryState, categoryId: number) =>
    state && !isEmpty(state.flattenCategories) && state.flattenCategories.find((category) => category.id === categoryId)
);
