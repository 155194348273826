<ng-container *ngIf="!isLoading">
  <div class="typography-subtitle-1 pt-32 pb-20">
    {{ 'SUPPLIER_NO_RESULT_LIST.EXPLORE_POPULAR_SUPPLIERS' | translate }}
  </div>
  <app-supplier-card-list
    [selectedCategoryId]="selectedCategoryId"
    [supplierIds]="supplierIds"
    [isInfinite]="false"
    [from]="0"
  ></app-supplier-card-list>
</ng-container>
