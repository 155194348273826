import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-retailer',
  templateUrl: './registration-retailer.component.html',
  styleUrls: ['./registration-retailer.component.scss'],
})
export class RegistrationRetailerComponent implements OnInit {
  @Input() titleKey: string;
  @Input() descriptionKey: string;
  public readonly bannerCoverImage = 'assets/images/backgrounds/retailer-register.webp';
  public readonly bannerTextList: string[] = [
    'AUTHENTICATION.BANNER.REGISTRATION.RETAILER.FIRST',
    'AUTHENTICATION.BANNER.REGISTRATION.RETAILER.SECOND',
    'AUTHENTICATION.BANNER.REGISTRATION.RETAILER.THIRD',
  ];

  constructor() {}

  ngOnInit(): void {}
}
