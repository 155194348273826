<div class="no-search-result" [style.padding-top.px]="paddingTop" [style.padding-bottom.px]="paddingBottom">
  <app-single-alert-with-icon
    [titleKey]="titleKey"
    [titleTooltipKey]="titleTooltipKey"
    [descriptionKey]="descriptionKey"
    [descriptionTooltipKey]="descriptionTooltipKey"
    [icon]="icon"
    [svgIcon]="svgIcon"
    [button]="button"
  ></app-single-alert-with-icon>
</div>
