import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

import { TileSelectItemComponent } from './tile-select-item/tile-select-item.component';

import * as _ from 'lodash';

import type { TileSelectOption } from './model/tile-select-option';
import type { TileSelectItemStyle } from './model/tile-select-item-style';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-tile-select',
  templateUrl: './tile-select.component.html',
  styleUrls: ['./tile-select.component.scss'],
  imports: [CommonModule, FlexModule, TileSelectItemComponent],
  standalone: true,
})
export class TileSelectComponent<T> implements OnInit {
  @Input()
  options: TileSelectOption<T>[];

  @Input()
  backgroundColor = '#FFFFFF';

  @Input()
  activeBackgroundColor = 'var(--app-custom-syncee-silver)';

  @Input()
  borderColor = 'var(--app-syncee-grey-300)';

  @Input()
  activeBorderColor = 'var(--app-syncee-primary-700)';

  @Input()
  columns = '4';

  @Input()
  rowHeight = 'max-content';

  @Input()
  columnWidth = '1fr';

  @Input()
  gridGap = '12px';

  @Input()
  borderRadius = '8px';

  @Input()
  defaultValue?: T;

  @Output()
  onChange = new EventEmitter<T | undefined>();

  private _value?: T;

  public tileSelectItemStyle: TileSelectItemStyle;

  public ngOnInit(): void {
    this._value = this.defaultValue;

    this.initTileSelectItemStyle();
  }

  private initTileSelectItemStyle(): void {
    this.tileSelectItemStyle = {
      backgroundColor: this.backgroundColor,
      activeBackgroundColor: this.activeBackgroundColor,
      borderColor: this.borderColor,
      activeBorderColor: this.activeBorderColor,
      borderRadius: this.borderRadius,
    };
  }

  public isOptionActive(option: TileSelectOption<T>): boolean {
    if (Utils.isNullOrUndefined(this._value)) {
      return false;
    }

    return _.isEqual(this._value, option.value);
  }

  public handleChange(value: T): void {
    if (_.isEqual(this._value, value)) {
      this.onChange.emit(undefined);
      this._value = undefined;
      return;
    }

    this.onChange.emit(value);
    this._value = value;
  }

  public get style(): { [key: string]: any } {
    return {
      'grid-template-columns': 'repeat(' + this.columns + ', ' + this.columnWidth + ')',
      'grid-auto-rows': this.rowHeight,
      'grid-gap': this.gridGap,
    };
  }
}
