import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { NotificationService } from 'app/main/notification/notification.service';
import { AuthenticationService } from 'app/service/authentication/authentication.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { ConfirmPasswordValidator, StrongPasswordValidator } from '../../../validators/password.validator';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() type: AuthDialogType;
  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService,
    private _authManagerService: AuthenticationManagerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this._fb.group({
      password: ['', [Validators.required, StrongPasswordValidator]],
      passwordConfirm: ['', [Validators.required, ConfirmPasswordValidator]],
    });
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get passwordConfirm(): AbstractControl {
    return this.form.get('passwordConfirm');
  }

  submitHandle(): void {
    if (this.form.invalid) {
      return;
    }

    this._activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params) => !!params['token']),
        switchMap((params) => {
          return this._authService.resetPassword(this.form.value.password, params['token']);
        }),
        take(1),
        tap(() => {
          this._notificationService.success(
            this.translateService.instant('AUTHENTICATION.RESET_PASSWORD.RESET_SUCCESS')
          );
        })
      )
      .subscribe(() => {
        this._authManagerService.emitOpenLoginDialog();
      });
  }
}
