<div *ngIf="!!description || !!title" class="header" [class.no-bottom-padding]="!hasBottomPadding">
  <h2 *ngIf="!!title" class="m-0" [class.h3]="ltMd" [class.centered-text]="position === 'center'">{{ title }}</h2>
  <div
    *ngIf="!!description"
    [class.centered-text]="position === 'center'"
    [ngClass]="ltMd ? 'typography-h3-subtitle' : 'typography-h2-subtitle'"
  >
    {{ description }}
  </div>
</div>
