import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationEcomService } from '../../../service/authorization-ecom/authorization-ecom.service';

@Component({
  selector: 'app-need-subscription-badge',
  templateUrl: './need-subscription-badge.component.html',
  styleUrls: ['./need-subscription-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class NeedSubscriptionBadgeComponent {
  constructor(private authorizationService: AuthorizationEcomService) {}

  openCompareDialog(): void {
    this.authorizationService.openSubscribeDialog();
  }
}
