import { Component, Input, OnInit } from '@angular/core';
import { HasCartService } from '../../../../service/has-cart/has-cart.service';
import { NavigationService } from '../../../../service/navigation/navigation.service';
import { UserReviewService } from '../../../../service/review/user-review.service';
import { ReviewAverage } from '../../../../vo/review/review-average';
import { SupplierVo } from '../../../../vo/search-product-vo';
import { ReviewHelperService } from '../../../review/service/review-helper.service';

@Component({
  selector: 'app-supplier-rating-display',
  templateUrl: './supplier-rating-display.component.html',
  styleUrls: ['./supplier-rating-display.component.scss'],
})
export class SupplierRatingDisplayComponent implements OnInit {
  @Input() supplierUserId: number;
  @Input() supplier: SupplierVo;
  @Input() isAutomated: boolean;
  reviewAverage: ReviewAverage[];

  average: number;
  count: number;
  constructor(
    private userReviewService: UserReviewService,
    private reviewHelperService: ReviewHelperService,
    private navigationService: NavigationService,
    public hasCartService: HasCartService
  ) {}

  ngOnInit(): void {
    this.getSupplierReviewInfo();
  }

  getSupplierReviewInfo(): void {
    this.userReviewService.getSupplierAverageReviews([Number(this.supplierUserId)]).subscribe((value) => {
      value = value ?? [];
      if (!value || value.length === 0) {
        return;
      }
      this.reviewAverage = value;
      this.getReviewAverage(value);
      this.count = this.reviewHelperService.getCountSum(value);
    });
  }

  navigateToStorefront(): void {
    this.navigationService.navigateToStorefront(Number(this.supplierUserId), this.supplier?.handle);
  }

  private getReviewAverage(averages: ReviewAverage[]): void {
    this.average = this.reviewHelperService.getWeightedAverage(
      averages.map((review) => ({ count: review.reviewCount, value: review.ratingAverage }))
    );
  }

  protected readonly Number = Number;
}
