import { SpringPage } from 'app/vo/pagination/spring-page';
import { EMPTY, expand, Observable, OperatorFunction, reduce } from 'rxjs';

export function fetchAllPages<T>(
  source: (page: number, size: number) => Observable<SpringPage<T>>,
  size = 100,
  config: Partial<FetchAllPagesConfig> = {
    paginationType: 'page',
  }
): OperatorFunction<SpringPage<T>, T[]> {
  const getPaginationNextValue = (type: FetchAllPagesConfigPaginationType, i: number): number => {
    return !type || type === 'page' ? i + 1 : (i + 1) * size;
  };

  return (initial: Observable<SpringPage<T>>): Observable<T[]> =>
    initial.pipe(
      expand((page, i) =>
        page.last || page.empty ? EMPTY : source(getPaginationNextValue(config?.paginationType, i), size)
      ),
      reduce((acc, page) => [...acc, ...page.content], [])
    );
}

export interface FetchAllPagesConfig {
  paginationType: FetchAllPagesConfigPaginationType;
}

export type FetchAllPagesConfigPaginationType = 'from' | 'page';
