import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

import { SupportTeamMemberComponent } from './support-team-member/support-team-member.component';
import { SupportTeamComponent } from './support-team.component';

import { GetAssetFromStoragePipe } from '../../pipes/get-asset-from-storage';

@NgModule({
  declarations: [SupportTeamComponent, SupportTeamMemberComponent],
  exports: [SupportTeamComponent],
  imports: [CommonModule, GetAssetFromStoragePipe, FlexModule],
})
export class SupportTeamModule {}
