import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiChatComponent } from './ai-chat.component';
import { AiChatHeaderComponent } from './components/ai-chat-header/ai-chat-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { CustomDividerComponent } from '../../shared/components/custom-divider/custom-divider.component';
import { AiChatBodyComponent } from './components/ai-chat-body/ai-chat-body.component';
import { AiChatBottomComponent } from './components/ai-chat-bottom/ai-chat-bottom.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AiChatMessageSimpleComponent } from './components/messages/ai-chat-message-simple/ai-chat-message-simple.component';
import { AiChatMessageComponent } from './components/messages/ai-chat-message/ai-chat-message.component';
import { AiChatMessageFilterComponent } from './components/messages/ai-chat-message-filter/ai-chat-message-filter.component';
import { AiChatMessageLoadingComponent } from './components/messages/ai-chat-message-loading/ai-chat-message-loading.component';
import { AiChatMessageModelAvatarComponent } from './components/messages/ai-chat-message-model-avatar/ai-chat-message-model-avatar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AiChatMessageSearchTermSuggestionComponent } from './components/messages/ai-chat-message-search-term-suggestion/ai-chat-message-search-term-suggestion.component';
import { MatChipsModule } from '@angular/material/chips';
import { AiChatLayoutSidebarComponent } from './layout/ai-chat-layout-sidebar/ai-chat-layout-sidebar.component';
import { AiChatLayoutPopoverComponent } from './layout/ai-chat-layout-popover/ai-chat-layout-popover.component';
import { AiChatBaseComponent } from './layout/base/ai-chat-base/ai-chat-base.component';
import { AiChatLayoutMobileComponent } from './layout/ai-chat-layout-mobile/ai-chat-layout-mobile.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { aiChatMarkedOptionsFactory } from './ai-chat-marked-options-factory';
import { AiChatLimitReachedComponent } from './components/ai-chat-limit-reached/ai-chat-limit-reached.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AiChatComponent,
    AiChatHeaderComponent,
    AiChatBodyComponent,
    AiChatBottomComponent,
    AiChatMessageSimpleComponent,
    AiChatMessageComponent,
    AiChatMessageFilterComponent,
    AiChatMessageLoadingComponent,
    AiChatMessageModelAvatarComponent,
    AiChatMessageSearchTermSuggestionComponent,
    AiChatLayoutSidebarComponent,
    AiChatLayoutPopoverComponent,
    AiChatBaseComponent,
    AiChatLayoutMobileComponent,
    AiChatLimitReachedComponent,
  ],
  exports: [AiChatComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexModule,
    CustomDividerComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
      markedOptions: { provide: MarkedOptions, useFactory: aiChatMarkedOptionsFactory },
    }),
    TranslateModule,
  ],
})
export class AiChatModule {}
