<ng-container *ngIf="!isLoading; else progress">
  <ng-container *ngIf="!hasError; else error">
    <form [formGroup]="billingInfoForm" fxLayout="column" fxLayoutGap="20px">
      <!--First name-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}</mat-label>
          <input matInput formControlName="firstName" placeholder="{{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}" />
          <mat-error>
            {{ 'BILLING_INFO_FORM.FIRST_NAME_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--Last name-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}</mat-label>
          <input matInput formControlName="lastName" placeholder="{{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}" />
          <mat-error>
            {{ 'BILLING_INFO_FORM.LAST_NAME_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--country-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.COUNTRY' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.COUNTRY' | translate }}</mat-label>
          <mat-select formControlName="country" placeholder="{{ 'BILLING_INFO_FORM.COUNTRY' | translate }}">
            <mat-option [disabled]="true">
              <ngx-mat-select-search
                [formControl]="countryFilter"
                [placeholderLabel]="'EXPLORE_PRODUCTS.TOP_SEARCH.COUNTRY_FILTER_SEARCH' | translate"
                [noEntriesFoundLabel]="'EXPLORE_PRODUCTS.TOP_SEARCH.COUNTRY_FILTER_NO_MATCH' | translate"
                (keypress)="initFilter()"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let countryItem of filteredCountries$ | async" [value]="countryItem.code">
              {{ countryItem.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'BILLING_INFO_FORM.COUNTRY_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--Address1-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}</mat-label>
          <input matInput formControlName="address1" placeholder="{{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}" />
          <mat-error>
            {{ 'BILLING_INFO_FORM.ADDRESS1_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--Address2-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}</mat-label>
          <input matInput formControlName="address2" placeholder="{{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}" />
        </mat-form-field>
      </div>

      <!--City-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.CITY' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.CITY' | translate }}</mat-label>
          <input matInput formControlName="city" placeholder="{{ 'BILLING_INFO_FORM.CITY' | translate }}" />
          <mat-error>
            {{ 'BILLING_INFO_FORM.CITY_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--zip-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.ZIP' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          class="w-100p input-without-padding-except-error"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.ZIP' | translate }}</mat-label>
          <input matInput formControlName="zip" placeholder="{{ 'BILLING_INFO_FORM.ZIP' | translate }}" />
          <mat-error>
            {{ 'BILLING_INFO_FORM.ZIP_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--company name-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
          class="w-100p input-without-padding-except-error input-prefix-fix"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}</mat-label>
          <input
            matInput
            formControlName="companyName"
            placeholder="{{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}"
          />
          <mat-error> {{ 'BILLING_INFO_FORM.COMPANY_NAME_REQUIRED' | translate }} </mat-error>
        </mat-form-field>
      </div>

      <!--euVat-->
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{ hidden: hideEUVat }">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.EU_VAT' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
          class="w-100p input-without-padding-except-error input-prefix-fix"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.EU_VAT' | translate }}</mat-label>
          <input matInput formControlName="euVat" placeholder="{{ 'BILLING_INFO_FORM.EU_VAT' | translate }}" />
          <span matPrefix>{{
            billingInfoForm.get('country').value == 'GR' ? 'EL' : billingInfoForm.get('country').value
          }}</span>
        </mat-form-field>
      </div>

      <!--tax number-->
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" *ngIf="!hideTaxNumber">
        <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
          {{ 'BILLING_INFO_FORM.TAX_NUMBER' | translate }}
        </div>
        <mat-form-field
          appearance="fill"
          fxFlex="1 0 0"
          [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
          class="w-100p input-without-padding-except-error input-prefix-fix"
        >
          <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.TAX_NUMBER' | translate }}</mat-label>
          <input matInput formControlName="taxNumber" placeholder="{{ 'BILLING_INFO_FORM.TAX_NUMBER' | translate }}" />
          <mat-error>
            {{ 'BILLING_INFO_FORM.TAX_NUMBER_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-template #progress>
  <div class="position-relative py-20" fxLayoutAlign="center center">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<ng-template #error>
  <div>{{ 'BILLING_INFO_FORM.SOMETHING_WRONG' | translate }}</div>
</ng-template>
