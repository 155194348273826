import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { AddfilesComponent } from '../taskwizard/addfiles/addfiles.component';
import { RetailerCatalogSettingsVO } from '../marketplace/retailer-catalogs/retailer-catalog-details/retailer-catalog-settings/abstarct-retailer-settings';
import { ActivatedRoute } from '@angular/router';
import { StepperService } from '../../service/taskwizard/stepper.service';
import { TaskwizardUpdateService } from '../../service/taskwizard/taskwizard-update.service';
import { ConnectionStateService } from '../../service/taskwizard/connection-state.service';
import { CatalogService } from '../../service/catalog/catalog.service';
import { StepWrapperComponent } from '../step-wrapper/step-wrapper.component';
import { Constants } from '../../utils/Constants';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-ecwid-df-task-wizard',
  templateUrl: './ecwid-df-task-wizard.component.html',
  styleUrls: ['./ecwid-df-task-wizard.component.scss'],
})
export class EcwidDfTaskWizardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('taskStepper', { static: true }) private stepper: MatStepper;

  @ViewChild('mappingStep', { static: true }) private mappingMatStep: MatStep;
  @ViewChild('scheduleStep', { static: true }) private scheduleMatStep: MatStep;
  @ViewChild('filterStep', { static: true }) private filterMatStep: MatStep;

  @ViewChild('summaryStep', { static: true }) private summaryStep: MatStep;
  @ViewChild('pricingEditorStep', { static: true }) private pricingEditorStep: MatStep;
  @ViewChild('categoryMappingStep', { static: true }) private categoryMappingStep: MatStep;

  addFilesComponent: AddfilesComponent;

  taskId: number;
  rcatalogId: number;
  retailerVO = new RetailerCatalogSettingsVO();
  comments: any;

  taskName: string;
  sourceType: string;

  enableComponents = false;

  isUpdate: boolean;

  constructor(
    private route: ActivatedRoute,
    private stepperService: StepperService,
    private twUpdateService: TaskwizardUpdateService,
    private connStateService: ConnectionStateService,
    private catalogService: CatalogService
  ) {}

  @ViewChildren(MatStep) set matSteps(steps: QueryList<MatStep>) {
    this.stepperService.setMatSteps(steps.toArray());
  }

  @ViewChildren(StepWrapperComponent) set stepWrappers(content: QueryList<StepWrapperComponent>) {
    if (!Utils.isNullOrUndefined(this.stepperService)) {
      this.stepperService.setStepWrappers(content.toArray());
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.stepperService.setStepper(this.stepper);
    this.route.queryParams.subscribe((params) => {
      this.taskId = params.task_id;

      if (!Utils.isNullOrUndefined(params.isUpdate)) {
        this.openToUpdate();
        this.stepperService.initStepperSteps(true);
      } else {
        this.taskName = Utils.isNullOrUndefined(params.task_name) ? '' : params.task_name;
        this.rcatalogId = params.rc_id;
        this.initToNew();
        this.stepperService.initStepperSteps(false);
      }
    });
  }

  getRetailerSettings(): void {
    this.catalogService.getRetailerCatalogSettings(this.rcatalogId).subscribe((data) => {
      this.retailerVO.settings = data;
      this.retailerVO.id = this.rcatalogId;
    });
  }

  handleVoChanged(event): void {
    this.retailerVO = event;
  }

  initToNew(): void {
    this.twUpdateService.isUpdate = false;
    this.connStateService.currentState = null;
    this.enableComponents = true;
  }

  openToUpdate(): void {
    this.twUpdateService.init(this.taskId).subscribe(
      (res) => {
        this.taskName = res.task.name;
        this.rcatalogId = res.rcatalogId;
        this.comments = res.comments;
        this.sourceType = res.task.sourceType;
        console.log('', this.sourceType);
        this.enableComponents = true;
        this.getRetailerSettings();
      },
      (error) => console.log(error)
    );
  }

  wizardStateChanged(): void {
    this.openToUpdate();
  }

  setSourceType(sourceType: string): void {
    this.sourceType = sourceType;
  }

  hasDefaultFilter(): boolean {
    return Constants.SHOP_SOURCE_CONNECTION_TYPES.includes(this.sourceType) && !this.isUpdate;
  }

  ngOnDestroy(): void {
    this.stepperService.reset();
  }
}
