<div class="dialog-multi-container" [ngClass]="type">
  <div class="dialog-multi-header background">
    <div class="icon-holder-wrapper">
      <mat-icon
        *ngIf="!iconWithCircle; else circledIcon"
        class="s-52 color-dark"
        [ngClass]="{ 'icon-filled': iconFilled }"
      >
        {{ icon }}
      </mat-icon>
    </div>
  </div>
  <div class="dialog-multi-body" fxLayout="column" [fxLayoutGap]="contentButtonsDistance + 'px'">
    <div class="content-wrapper" fxLayout="column" [fxLayoutGap]="titleContentDistance + 'px'">
      <div class="title-wrapper">
        <h3>{{ title }}</h3>
      </div>
      <ng-content></ng-content>
    </div>
    <div fxLayout="row" fxLayoutGap="8px" class="dialog-multi-buttons" [ngClass]="'align-' + buttonsAlignment">
      <ng-container *ngIf="!hideCloseButton">
        <button mat-button [mat-dialog-close]="null">{{ closeButtonText }}</button>
      </ng-container>
      <ng-container *ngIf="!!customButtons" [ngTemplateOutlet]="customButtons"></ng-container>
      <ng-container *ngIf="!hideConfirmButton">
        <button
          mat-flat-button
          class="background-dark confirm-button"
          [disabled]="confirmDisabled"
          (click)="handleConfirmClick()"
        >
          {{ confirmButtonText }}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #circledIcon>
  <div class="icon-circle background-dark w-52 h-52" fxLayoutAlign="center center">
    <mat-icon class="s-28 color-light" [ngClass]="{ 'icon-filled': iconFilled }">{{ icon }}</mat-icon>
  </div>
</ng-template>
