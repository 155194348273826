import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../../app.state';
import { Subject } from 'rxjs';
import { recentlyAddedProductsSelector } from '../../../../../store/rcatalogs/rcatalogs.selector';

@Component({
  selector: 'app-catalog-list-icon',
  templateUrl: './catalog-list-icon.component.html',
  styleUrls: ['./catalog-list-icon.component.scss'],
})
export class CatalogListIconComponent implements OnInit, OnDestroy {
  recentlyAddedProducts = 0;
  private unsubscribeAll: Subject<void>;
  openOverlay: Subject<boolean>;

  constructor(private store: Store<AppState>) {
    this.unsubscribeAll = new Subject<void>();
    this.openOverlay = new Subject();
  }

  ngOnInit(): void {
    this.subscribeToRecentlyAddedProducts();
  }

  ngOnDestroy(): void {}

  private subscribeToRecentlyAddedProducts(): void {
    this.store
      .select(recentlyAddedProductsSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((products) => (this.recentlyAddedProducts = products && products.length > 0 ? products.length : 0));
  }
}
