import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    const date = new Date(value);
    const monthName = date.toLocaleString(this.translateService.currentLang, { month: 'long' }).toUpperCase();
    const dayNumber = date.toLocaleString(this.translateService.currentLang, { day: 'numeric' });
    const dayName = date.toLocaleDateString(this.translateService.currentLang, { weekday: 'long' }).toUpperCase();
    return `${dayName}, ${dayNumber} ${monthName}`;
  }
}
