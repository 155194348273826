import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isArray } from 'lodash';
import { FilterSelectorOptionGroup } from '../../filter-selector-base/filter-selector-base.component';

@Component({
  selector: 'app-approval-filter',
  templateUrl: './approval-filter.component.html',
  styleUrls: ['./approval-filter.component.scss'],
})
export class ApprovalFilterComponent implements OnInit {
  @Input() hasClearAll = false;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  @Output() clearAll = new EventEmitter<void>();
  options: FilterSelectorOptionGroup<string, string>[] = [
    {
      options: [
        { value: 'FILTER_COMMON.SELECTOR.VALUES.APPROVAL.ANY', key: 'any' },
        { value: 'FILTER_COMMON.SELECTOR.VALUES.APPROVAL.APPROVE_NEEDED', key: 'true' },
        { value: 'FILTER_COMMON.SELECTOR.VALUES.APPROVAL.FREE_TO_USE', key: 'false' },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  onValueChange(value: string | string[]): void {
    this.valueChange.emit(isArray(value) ? value[0] : value);
  }

  onClearAll(): void {
    this.clearAll.emit();
  }
}
