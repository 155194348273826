import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../app.state';
import { Observable } from 'rxjs';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { FilterSelectorOption } from '../../../filter-selector/filter-selector.component';

@Component({
  selector: 'shipping-type-filter.component',
  templateUrl: './shipping-type-filter.component.html',
  styleUrls: ['./shipping-type-filter.component.scss'],
})
export class ShippingTypeFilterComponent {
  public productSearchStore$: Observable<ProductSearchState>;
  public options: FilterSelectorOption<string, string>[] = [
    { key: 'any', value: 'FILTER_SIDEBAR.SELECTOR.SHIPPING_TYPE.ANY' },
    { key: 'both', value: 'FILTER_SIDEBAR.SELECTOR.SHIPPING_TYPE.BOTH' },
    { key: 'dropship', value: 'FILTER_SIDEBAR.SELECTOR.SHIPPING_TYPE.DROPSHIP' },
    { key: 'wholesaling', value: 'FILTER_SIDEBAR.SELECTOR.SHIPPING_TYPE.WHOLESALING' },
  ];

  constructor(private store: Store<AppState>) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
  }

  handleChange(value: string): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { shippingType: value } }));
  }
}
