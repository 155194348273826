import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogMultiWrapperComponent } from '../../../../../shared/components/dialog-multi-wrapper/dialog-multi-wrapper.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { SanitizeUrlPipe } from '../../../../../shared/pipes/sanitize-url.pipe';
import { SanitizeHtmlPipe } from '../../../../../shared/pipes/sanitize-html.pipe';
import { NeedSubscriptionBadgeComponent } from 'app/shared/components/need-subscription-badge/need-subscription-badge.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-my-suppliers-order-info-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogMultiWrapperComponent,
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    SanitizeUrlPipe,
    SanitizeHtmlPipe,
    NeedSubscriptionBadgeComponent,
    TranslateModule,
  ],
  templateUrl: './my-suppliers-order-info-dialog.component.html',
  styleUrls: ['./my-suppliers-order-info-dialog.component.scss'],
})
export class MySuppliersOrderInfoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MySuppliersOrderInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MySuppliersOrderInfoDialogData
  ) {}

  ngOnInit(): void {
    this.normalizeWebAddress();
  }
  private normalizeWebAddress(): void {
    if (this.data.website && !this.data.website.match(/^https?:\/\//i)) {
      this.data.website = 'http://' + this.data.website;
    }
  }
}

export interface MySuppliersOrderInfoDialogData {
  isAutoOrder: boolean;
  website: string;
  hasAccessToContacts: boolean;
}
