import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { AIChatMessage, AIChatMessageSource, AIChatMessageType } from '../model/messages/ai-chat-message';
import { catchError, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AiChatRestService } from './ai-chat-rest.service';
import { AiChatRestMapperService } from './ai-chat-rest-mapper.service';
import { AiChatDto } from '../model/dto/ai-chat-dto';
import { AiChatService } from './ai-chat.service';
import { AiChatLimitationsService } from './ai-chat-limitations.service';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { Utils } from '../../../utils/utils';
import { valueChanged } from '../../../utils/operator/value-changed';

@Injectable({ providedIn: 'root' })
export class AiChatMessagesService {
  private _messages = new ReplaySubject<AIChatMessage>(1);
  private unsubscribeAll = new Subject<void>();
  lastMessage: Observable<AIChatMessage> = this._messages.asObservable();
  waiting = false;
  allMessage: AIChatMessage[] = [];

  constructor(
    private store: Store<AppState>,
    private aiChatService: AiChatService,
    private aiChatRestService: AiChatRestService,
    private aiChatRestMapperService: AiChatRestMapperService,
    private aiChatLimitationsService: AiChatLimitationsService
  ) {
    this.subscribeToOpen();
    this.subscribeToUserAndEcomChange();
  }

  pushMessage(message: AIChatMessage): void {
    this.allMessage.push(message);
    this._messages.next(message);
  }

  pushCommand(command: string): void {
    this.pushMessage({
      type: AIChatMessageType.SIMPLE_CHAT,
      source: AIChatMessageSource.COMMAND,
      payload: { simpleMessage: command },
      raw: [{ text: command }],
      needToRequest: true,
    });
  }

  private subscribeToUserAndEcomChange(): void {
    combineLatest({
      userId: this.store.select(getCurrentUserIdSelector).pipe(valueChanged(), startWith(null)),
      ecom: this.store.select(selectedRetailerEcomSelector).pipe(valueChanged(), startWith(null)),
    })
      .pipe(filter((data) => !Utils.isNullOrUndefined(data.userId) || !!data.ecom))
      .subscribe(() => {
        this.aiChatService.closeChat();
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.subscribeToOpen();
      });
  }

  private subscribeToOpen(): void {
    this.aiChatService.isOpen$
      .pipe(
        filter((isOpen) => isOpen),
        take(1),
        tap(() => this.subscribeToLastMessage())
      )
      .subscribe(() => {
        this.init();
      });
  }

  private handleResponse(aiChatDto: AiChatDto[]): void {
    this.waiting = false;
    this.aiChatLimitationsService.increaseUsage();
    aiChatDto.forEach((dto, index) => {
      const first = index === 0;
      const hasMultipleResponse = aiChatDto.length > 1;
      switch (true) {
        case !!dto.text && !dto.functionCall:
        case !dto.text && !!dto.functionCall:
          this.pushMessage(this.aiChatRestMapperService.mapDtoToMessage(aiChatDto, dto, first, hasMultipleResponse));
          break;
        case !!dto.text && !!dto.functionCall:
          this.pushMessage(
            this.aiChatRestMapperService.mapDtoToMessage(
              aiChatDto,
              dto,
              first,
              hasMultipleResponse,
              AIChatMessageType.SIMPLE_CHAT
            )
          );
          this.pushMessage(
            this.aiChatRestMapperService.mapDtoToMessage(
              aiChatDto,
              dto,
              first,
              hasMultipleResponse,
              this.aiChatRestMapperService.mapFunctionCallToType(dto.functionCall.name)
            )
          );
          break;
      }
    });
  }

  private init(): void {
    this.allMessage = [];
    this.aiChatLimitationsService.limitReached
      .pipe(
        omitNullOrUndefined(),
        filter((reached) => !reached),
        take(1),
        tap(() => (this.waiting = true)),
        switchMap(() => this.aiChatRestService.send([]).pipe(catchError(() => of([]))))
      )
      .subscribe((dto) => this.handleResponse(dto));
  }

  private subscribeToLastMessage(): void {
    this.lastMessage
      .pipe(
        takeUntil(this.unsubscribeAll),
        isAiChatMessageSourceOperator(AIChatMessageSource.COMMAND),
        tap(() => (this.waiting = true)),
        switchMap(() =>
          of(null).pipe(
            switchMap(() =>
              this.aiChatRestService.send(
                this.aiChatRestMapperService.mapMessagesToRequest(
                  this.allMessage.filter((message) => message.needToRequest)
                )
              )
            ),
            catchError(() => of([]))
          )
        )
      )
      .subscribe((aiChatDto) => this.handleResponse(aiChatDto));
  }
}

export function isAiChatMessageTypeOperator(
  type: AIChatMessageType
): (source: Observable<AIChatMessage>) => Observable<AIChatMessage> {
  return (source: Observable<AIChatMessage>): Observable<AIChatMessage> =>
    source.pipe(filter((message) => message.type === type));
}

export function isAiChatMessageSourceOperator(
  messageSource: AIChatMessageSource
): (source: Observable<AIChatMessage>) => Observable<AIChatMessage> {
  return (source: Observable<AIChatMessage>): Observable<AIChatMessage> =>
    source.pipe(filter((message) => message.source === messageSource));
}
