import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestResponse } from '../rest/rest-response';
import { RestService } from '../rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ScheduleTimeVO } from './taskwizard-update-response';
import { FtpConnectionStateVo } from '../../main/taskwizard/addfiles/vo/ftp-connection-state-vo';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ExportTaskwizardService {
  public static GET_PREVIEW = 'ExportTaskService/previewGenerator';
  public static SAVE_EXPORT_MAPPING = 'ExportTaskService/saveExportMapping';
  public static GET_MAPPING_FIELDS_CONFIG = 'ExportTaskService/getFieldsConfig';
  public static RUN_SHOPIFY_PRODUCT_EXPORT = 'ExportTaskService/runShopifyProductsExport';

  constructor(private restService: RestService) {}

  public runShopifyProductsExport(taskId, domain): Observable<any> {
    const httpParams = new HttpParams().set('taskId', taskId).set('ecomId', domain.toString());
    return this.restService.get(ExportTaskwizardService.RUN_SHOPIFY_PRODUCT_EXPORT, httpParams).pipe(
      map((resp) => {
        return resp.getData();
      })
    );
  }

  public getFieldsConfig(): Observable<any[]> {
    return this.restService.get(ExportTaskwizardService.GET_MAPPING_FIELDS_CONFIG).pipe(
      map((resp) => {
        return resp.getData();
      })
    );
  }

  public getPreview(mapping: any[], ecomId): Observable<any> {
    const params = { mapping: mapping, ecomId: ecomId };
    return this.restService.post(ExportTaskwizardService.GET_PREVIEW, params).pipe(
      map((resp) => {
        return resp.getFirstData();
      })
    );
  }

  public saveMapping(taskId: number, mapping: any[]): Observable<any> {
    const params = { mapping: mapping, taskId: taskId };
    return this.restService.post(ExportTaskwizardService.SAVE_EXPORT_MAPPING, params);
  }

  getSummaryDetails(taskId: number): Observable<SummaryDetails> {
    return this.restService
      .get('ExportTaskService/getTaskDetails', new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((rawRes: RestResponse) => {
          const res = rawRes.getFirstData();
          const summDetails = new SummaryDetails();
          summDetails.taskName = res.taskName;
          summDetails.sourceType = res.sourceType;
          summDetails.productCount = res.productCount;
          summDetails.filteredNumber = res.filteredNumber;
          summDetails.storeName = res.store;
          summDetails.ftpConnection = res.ftpConnection;
          summDetails.fileType = res.fileType;
          summDetails.csvSettings = {};
          summDetails.exportUrl = res.exportUrl;
          if (!Utils.isNullOrUndefined(res.csvSettings)) {
            summDetails.csvSettings['delimiter'] = res.csvSettings.delimiter;
            summDetails.csvSettings['enclosure'] = res.csvSettings.enclosure;
            summDetails.csvSettings['eol'] = res.csvSettings.eol;
          }

          if (!Utils.isNullOrUndefined(res.schedulers)) {
            summDetails.schedulers = new ScheduleTimeVO();
            summDetails.schedulers.type = res.schedulers.type;
            summDetails.schedulers.dates = res.schedulers.dates;
          }
          summDetails.isTaskRunning = res.isTaskRunning;

          return summDetails;
        })
      );
  }

  saveDestination(taskId: number, destination: string, ftpCon?: any): Observable<any> {
    let ret = {
      taskId: taskId,
      destination: destination,
    };

    if (!Utils.isNullOrUndefined(ftpCon)) {
      ret = { ...ret, ...ftpCon };
    }

    return this.restService.post('ExportTaskService/saveDestination', ret).pipe(
      map((res: RestResponse) => {
        return res;
      })
    );
  }

  getExportFileURL(taskId: number): Observable<any> {
    return this.restService
      .get('ExportTaskService/getExportFileURL', new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((res: RestResponse) => {
          return res;
        })
      );
  }

  getDefaultStructure(): any {
    return [
      {
        type: 'ShopifyProduct',
        fieldMappingElements: [
          {
            storeField: 'id',
            settings: [],
            outputName: 'Id',
            order: 1,
            operations: '',
            type: 'shopify field',
          },
          {
            storeField: 'title',
            settings: [],
            outputName: 'Title',
            order: 2,
            operations: '',
            type: 'shopify field',
          },
          {
            storeField: 'handle',
            settings: [],
            outputName: 'Handle',
            order: 3,
            operations: '',
            type: 'shopify field',
          },
          {
            storeField: 'body_html',
            settings: [],
            outputName: 'Body (HTML)',
            order: 4,
            operations: '',
            type: 'shopify field',
          },
        ],
      },
    ];
  }

  public getTaskRounding(taskId: number): Observable<string> {
    return this.restService
      .get('ExportTaskService/getTaskDetails', new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((res) => res.getFirstData()),
        map((res) => res.task.rounding)
      );
  }
}

export class SummaryDetails {
  taskName: string;
  storeName: string;
  sourceType: string;
  productCount: number;
  filteredNumber: number;
  fileType: string;
  exportUrl: string;
  csvSettings: any;
  schedulers: ScheduleTimeVO;
  ftpConnection: FtpConnectionStateVo;
  isTaskRunning: boolean;
}
