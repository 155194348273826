import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, share } from 'rxjs/operators';
import { ApproveStatusParamEnum } from '../model/approve-status-param';

@Injectable()
export class MySuppliersFilterService {
  private searchTerm$ = new BehaviorSubject<string>('');
  private approvedStatusFilter$ = new BehaviorSubject<ApproveStatusParamEnum>(null);
  constructor() {}

  public get searchTerm(): Observable<string> {
    return this.searchTerm$.asObservable().pipe(share(), distinctUntilChanged());
  }

  public setSearchTerm(value: string): void {
    this.searchTerm$.next(value);
  }

  public get approvedStatusFilter(): Observable<ApproveStatusParamEnum> {
    return this.approvedStatusFilter$.asObservable().pipe(share(), distinctUntilChanged());
  }

  public setApprovedStatusFilter(value: ApproveStatusParamEnum): void {
    this.approvedStatusFilter$.next(value);
  }
}
