<app-loading-screen [message]="'RETAILER_TASK_WIZARD.WAIT' | translate"></app-loading-screen>
<mat-card class="mat-card m-20 taskwizard-container">
  <div class="task-name h2">{{ taskName }}</div>

  <mat-horizontal-stepper [linear]="true" #taskStepper>
    <!--Mapping-->
    <mat-step #mappingStep>
      <ng-template matStepLabel>{{ 'RETAILER_EXPORT_TASK_WIZARD.MAPPING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #mappingWrapper [backNavigationUrl]="'/retailer/export-tasks'">
          <!--<app-field-mapping #stepComponent-->
          <!--*ngIf="mappingWrapper.isStepActive()" [taskId]="taskId"-->
          <!--[defaultStructure]="defaultStructure"></app-field-mapping>-->
          <app-new-field-mapping #stepComponent [ecomId]="ecomId" [taskId]="taskId"></app-new-field-mapping>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Filter-->
    <!--<mat-step #filterStep>-->
    <!--<ng-template matStepLabel>Filter</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #filterWrapper>-->
    <!--<app-filter #stepComponent *ngIf="filterWrapper.isStepActive()" [taskId]="taskId"></app-filter>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->
    <mat-step #filterStep>
      <ng-template matStepLabel>{{ 'RETAILER_EXPORT_TASK_WIZARD.FILTER' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #filterWrapper>
          <ng-container *ngIf="stepper.selected === filterStep">
            <ng-container *ngIf="etwUpdateService.filterIsInited; else loading">
              <app-task-filter
                #stepComponent
                [taskId]="taskId"
                [filterStructures]="getFieldStructures()"
                [taskFilters]="etwUpdateService.data.taskFilters.filter"
                [canUseTaskFilters]="etwUpdateService.isUpdate"
                [useDefaultFilter]="!etwUpdateService.data.hasTaskFilter()"
                [defaultFieldNames]="{
                  publishedField: 'published_at',
                  imagesField: 'images'
                }"
                [canUseDefaultFilter]="true"
                [isDefaultFilterLocked]="false"
                [hasVariantFilter]="false"
                [filterSave]="saveFilter.bind(this)"
              ></app-task-filter>
            </ng-container>
          </ng-container>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Pricing editor-->
    <mat-step #pricingEditorStep>
      <ng-template matStepLabel>{{ 'RETAILER_EXPORT_TASK_WIZARD.PRICING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <div style="overflow: auto">
          <app-step-wrapper #pricingWrapper>
            <app-retailer-pricing
              *ngIf="stepper.selected === pricingEditorStep"
              #stepComponent
              [type]="'EXPORT'"
            ></app-retailer-pricing>
          </app-step-wrapper>
        </div>
      </div>
    </mat-step>

    <!--&lt;!&ndash;Destination&ndash;&gt;-->
    <!--<mat-step #destinationStep>-->
    <!--<ng-template matStepLabel>Destination</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #destinationWrapper>-->
    <!--<app-destination #stepComponent-->
    <!--*ngIf="destinationWrapper.isStepActive()"-->
    <!--[currentTaskId]="taskId"></app-destination>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--&lt;!&ndash;Scheduler&ndash;&gt;-->
    <!--<mat-step #schedulerStep>-->
    <!--<ng-template matStepLabel>Scheduler</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<div style="overflow: auto">-->
    <!--<app-step-wrapper #schedulerWrapper>-->
    <!--<app-schedule *ngIf="schedulerWrapper.isStepActive()" #stepComponent [sourceType]="sourceType"-->
    <!--[taskId]="taskId"></app-schedule>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Summary-->
    <mat-step #summaryStep>
      <ng-template matStepLabel>{{ 'RETAILER_EXPORT_TASK_WIZARD.SETTINGS' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper
          #summaryWrapper
          nextButtonLabel="{{ 'RETAILER_EXPORT_TASK_WIZARD.FINISH' | translate }}"
          backNavigationUrl="/retailer/export-tasks"
        >
          <app-setting-and-summary
            *ngIf="summaryWrapper.isStepActive()"
            [taskId]="taskId"
            #stepComponent
          ></app-setting-and-summary>
        </app-step-wrapper>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-card>
<ng-template #loading>
  <div fxLayout="column" fxLayoutAlign="start center">
    <app-loading-spinner></app-loading-spinner>
    <div>{{ 'FILTER.LOADING_FILTER' | translate }}</div>
  </div>
</ng-template>
