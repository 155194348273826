import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'getResourceFromStorage',
  standalone: true,
})
export class GetResourceFromStoragePipe implements PipeTransform {
  transform(resourcePath: string, addTimestamp?: boolean): string {
    return `${environment.storageUrl}/o/${resourcePath.replace('/', '%2F')}?alt=media${
      addTimestamp ? `&timestamp=${new Date().toISOString()}` : ''
    }`;
  }
}
