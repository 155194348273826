<div class="container">
  <div class="header" [ngStyle]="{ position: stepperService.hasBanner ? 'absolute' : 'relative' }">
    <mat-icon [mat-dialog-close]="null" style="cursor: pointer"> close </mat-icon>

    <div *ngIf="stepperService.canGoBack" class="back" (click)="stepperService.back()">
      <mat-icon> chevron_left </mat-icon>
      Back
    </div>
  </div>

  <div class="body">
    <ng-content></ng-content>
  </div>
</div>
