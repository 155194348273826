export class TabFileDelTaskfileOutVo {
  private _tabIndex: number;
  private _taskId: number;
  private _fileId: number;

  get tabIndex(): number {
    return this._tabIndex;
  }

  set tabIndex(value: number) {
    this._tabIndex = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }
}
