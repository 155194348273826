<div *ngIf="dataSource; else skeleton" class="w-100p">
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-sm="start start"
    fxLayoutGap="8px"
  >
    <div class="h4">{{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.TITLE' | translate }}</div>
    <mat-form-field
      fxFlex="0 0 0"
      fxFlex.lt-sm="1 0 0"
      [ngClass.lt-sm]="'w-100p'"
      appearance="fill"
      class="input-without-padding select-without-label"
    >
      <mat-select (selectionChange)="pageable.sort = $event.value; setDataSource()" [value]="pageable.sort">
        <mat-option [value]="'revenue,desc'">{{
          'DASHBOARD.SUPPLIER.TOP_RETAILERS.BY_PRODUCTS_SOLD' | translate
        }}</mat-option>
        <mat-option [value]="'productsSold,desc'">{{
          'DASHBOARD.SUPPLIER.TOP_RETAILERS.BY_REVENUE' | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="w-100p top-retailers-table-container">
    <table mat-table [dataSource]="dataSource" class="w-100p">
      <ng-container matColumnDef="retailer">
        <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.RETAILER' | translate }}</th>
        <td mat-cell *matCellDef="let supplier">
          <div class="no-wrap user-name">{{ supplier.userName }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="orders">
        <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.ORDERS' | translate }}</th>
        <td mat-cell *matCellDef="let supplier">{{ supplier.orders }}</td>
      </ng-container>
      <ng-container matColumnDef="products-sold">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.PRODUCTS_SOLD' | translate }}
        </th>
        <td mat-cell *matCellDef="let supplier">{{ supplier.productsSold }}</td>
      </ng-container>
      <ng-container matColumnDef="revenue">
        <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.REVENUE' | translate }}</th>
        <td mat-cell *matCellDef="let supplier">
          {{ supplier.revenue | currencyTransform : paymentCurrency | async }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let product; columns: columns"></tr>
    </table>
  </div>
  <div
    *ngIf="dataSource && !dataSource.data.length"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="no-data typography-body-1 centered-text"
  >
    {{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.NO_DATA' | translate }}
  </div>
</div>

<ng-template #skeleton>
  <app-skeleton-dashboard-box
    [withCard]="false"
    title="{{ 'DASHBOARD.SUPPLIER.TOP_RETAILERS.TITLE' | translate }}"
  ></app-skeleton-dashboard-box>
</ng-template>
