import { Component, Input, OnInit } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ExpandableRowAnimation } from '../../../../styles/animations/expandable-row.animation';
import { FadeInOutAnimation } from '../../../../styles/animations/fade-in-out.animation';

@Component({
  selector: 'app-history-indicator',
  templateUrl: './history-indicator.component.html',
  styleUrls: ['./history-indicator.component.scss'],
  animations: [ExpandableRowAnimation, FadeInOutAnimation],
})
export class HistoryIndicatorComponent implements OnInit {
  @Input() data: HistoryIndicatorData[];
  @Input() variant: HistoryIndicatorVariant = 'tooltip';
  isOpen = false;
  positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 2,
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  get allItemsCount(): number {
    return this.data.reduce((acc, curr) => acc + curr.value, 0);
  }

  get filteredData(): HistoryIndicatorData[] {
    return this.data.filter((item) => item.value > 0);
  }
}

export interface HistoryIndicatorData {
  label: string;
  color: string;
  value: number;
  legendMarker?: HistoryIndicatorLegendMarker;
}

type HistoryIndicatorLegendMarker = 'donut' | 'default';
type HistoryIndicatorVariant = 'expendable' | 'tooltip';
