import { Component, OnInit } from '@angular/core';
import { BillingInformationService } from '../../service/billing-information.service';

@Component({
  selector: 'app-billing-information-default',
  templateUrl: './billing-information-default.component.html',
  styleUrls: ['./billing-information-default.component.scss'],
})
export class BillingInformationDefaultComponent implements OnInit {
  constructor(public billingInformationService: BillingInformationService) {}

  ngOnInit(): void {}
}
