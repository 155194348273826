import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardListComponent, ProductCardWidths } from '../product-card-list/product-card-list.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable, Subject } from 'rxjs';
import { selectedCurrencySelector } from '../../../store/currency/currency.selector';
// import { selectedEcomSelector } from '../../../store/ecom/ecom.selector';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { SearchProductVO } from '../../../vo/search-product-vo';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { isFreePlanSelector } from '../../../store/subscription/subscription.selector';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-product-no-result-list',
  standalone: true,
  imports: [CommonModule, ProductCardListComponent, TranslateModule],
  templateUrl: './product-no-result-list.component.html',
  styleUrls: ['./product-no-result-list.component.scss'],
})
export class ProductNoResultListComponent implements OnInit, OnDestroy {
  @Input() selectable: boolean;
  @Input() productCardWidth: ProductCardWidths = {
    xs: '50%',
    sm: '50%',
    md: '33.333%',
    lg: '20%',
    xl: '16.666%',
  };
  currency: Observable<string>;
  isFreePlan: Observable<boolean>;
  selectedEcom: Observable<EcomVO>;
  isLoading = true;
  products: SearchProductVO[] = [];
  private unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>, private productSearchService: ProductSearchService) {
    this.unsubscribeAll = new Subject();
    this.currency = this.store.select(selectedCurrencySelector);
    this.isFreePlan = this.store.select(isFreePlanSelector);
    this.selectedEcom = this.store.select(selectedRetailerEcomSelector);
  }

  ngOnInit(): void {
    this.selectedEcom
      .pipe(
        switchMap((ecom) => this.fetchProducts(ecom)),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((products) => this.handleProducts(products));
    this.isFreePlan.pipe(takeUntil(this.unsubscribeAll)).subscribe((free) => {
      console.log(free);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private fetchProducts(ecom: EcomVO): Observable<SearchProductVO[]> {
    this.isLoading = true;
    return this.productSearchService.searchProducts(ecom, {}).pipe(map((res) => res.result));
  }

  private handleProducts(products: SearchProductVO[]): void {
    this.products = products;
    this.isLoading = false;
  }
}
