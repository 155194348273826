<nav mat-tab-nav-bar *ngIf="showNav">
  <a
    *ngFor="let link of navLinks"
    mat-tab-link
    #rla="routerLinkActive"
    [active]="rla.isActive"
    [routerLink]="link.link"
    (isActiveChange)="tabClicked(link.link, $event)"
    routerLinkActive
    class="tab-item"
  >
    {{ link.title | translate }}
  </a>
</nav>
