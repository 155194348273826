<div class="product-list-container py-32">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; margin-bottom: 12px">
    <app-skeleton-display-text></app-skeleton-display-text>

    <ng-container *ngIf="hasSeeAll">
      <app-skeleton-text *ngIf="!(ltMd$ | async)" style="width: min(50px, 10%)" [lines]="1"></app-skeleton-text>
    </ng-container>
  </div>

  <div class="slider-container" fxLayoutAlign="center center">
    <ngx-slick-carousel
      fxFlex="1 0 0"
      class="product-list-items"
      [config]="SkeletonProductsListSliderBoxComponent.CONFIG"
    >
      <ng-container *ngFor="let product of [].constructor(SkeletonProductsListSliderBoxComponent.CONFIG.slidesToShow)">
        <app-skeleton-product-card ngxSlickItem class="product-item"></app-skeleton-product-card>
      </ng-container>
    </ngx-slick-carousel>

    <button fxFlex="0 0 auto" class="navigation-buttons" disabled>
      <mat-icon class="s-16">arrow_forward_ios</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="hasSeeAll">
    <ng-container *ngIf="ltMd$ | async">
      <app-skeleton-text style="width: min(100px, 20%)" [lines]="1"></app-skeleton-text>
    </ng-container>
  </ng-container>
</div>
