import { Action, ActionReducer } from '@ngrx/store';
import { AppState, defaultAppState } from 'app/app.state';
import { AuthenticationStorage } from 'app/service/authentication/authentication-storage';
import { SCOPES } from 'app/service/permission/scopes';
import { AuthActionTypes, AuthenticationActions, UserToUserLoginType } from './authentication.actions';
import { RolesEnum } from '../../vo/roles/roles';

export interface AuthenticationState {
  isAuthenticated: boolean;
  token?: string;
  refreshToken?: string;
  scopes: SCOPES[];
  loggedUserScopes: SCOPES[];
  isFailed?: boolean;
  loggedUserAccessToken: string;
  loggedUserRefreshToken: string;
  expiresIn: number;
  loggedUserExpiresIn: number;
  loggedInBy: UserToUserLoginType;
}

const initialState = (): AuthenticationState => {
  const authData = AuthenticationStorage.getAuthData();
  const loggedUserAuthData = AuthenticationStorage.getLoggedUserAuthData();
  return {
    isAuthenticated: !!authData?.accessToken,
    isFailed: false,
    token: authData?.accessToken,
    refreshToken: authData?.refreshToken,
    loggedUserAccessToken: loggedUserAuthData?.accessToken,
    loggedUserRefreshToken: loggedUserAuthData?.refreshToken,
    scopes: authData?.scopes || [],
    loggedUserScopes: loggedUserAuthData?.scopes || null,
    expiresIn: authData?.expiresIn || 0,
    loggedUserExpiresIn: loggedUserAuthData?.expiresIn || 0,
    loggedInBy: (localStorage.getItem('loggedInBy') as UserToUserLoginType) ?? null,
  };
};

export function authenticationReducer(state = initialState(), action: AuthenticationActions): AuthenticationState {
  switch (action.type) {
    case AuthActionTypes.BASIC_LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        scopes: action.payload.scopes,
        expiresIn: action.payload.expiresIn,
      };
    }
    case AuthActionTypes.REFRESH_AUTH_TOKENS: {
      return {
        ...state,
        token: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    }
    case AuthActionTypes.USER_TO_USER_AUTH_SUCCESS:
    case AuthActionTypes.REFRESH_CROSSLOGGED_USER_AUTH_TOKENS: {
      return {
        ...state,
        loggedUserAccessToken: action.payload.accessToken,
        loggedUserRefreshToken: action.payload.refreshToken,
        loggedUserExpiresIn: action.payload.expiresIn,
        loggedUserScopes: action.payload.scopes,
        isAuthenticated: true,
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        isFailed: true,
      };
    }
    case AuthActionTypes.USER_TO_USER_LOGIN_SUCCESS: {
      return { ...state, loggedInBy: action.payload.loginType };
    }
    case AuthActionTypes.USER_TO_USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        loggedUserAccessToken: null,
        loggedUserRefreshToken: null,
        loggedUserExpiresIn: null,
        loggedUserScopes: null,
        loggedInBy: null,
      };
    }
    case AuthActionTypes.LOGOUT: {
      return { ...state, isAuthenticated: false };
    }
    default: {
      return state;
    }
  }
}

export function authenticationMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: Action): AppState {
    switch (action.type) {
      case AuthActionTypes.USER_TO_USER_LOGIN_START:
      case AuthActionTypes.LOGOUT_SUCCESS: {
        return reducer(
          {
            ...defaultAppState,
            ...{
              categories: state.categories,
              currency: state.currency,
              productSearch: state.productSearch,
              countriesLocations: state.countriesLocations,
            },
            ...{
              users: {
                locationByIp: state.users.locationByIp,
                currentUser: undefined,
                actualRole: undefined,
                roles: undefined,
              },
            },
          },
          action
        );
      }

      case AuthActionTypes.USER_TO_USER_LOGOUT_START: {
        return reducer(
          {
            ...defaultAppState,
            ...{
              categories: state.categories,
              currency: state.currency,
              productSearch: state.productSearch,
              countriesLocations: state.countriesLocations,
            },
            ...{
              users: {
                locationByIp: state.users.locationByIp,
                currentUser: undefined,
                actualRole: RolesEnum.ADMIN,
                roles: [RolesEnum.ADMIN, RolesEnum.RETAILER, RolesEnum.SUPPLIER],
              },
            },
          },
          action
        );
      }
      default: {
        return reducer(state, action);
      }
    }
  };
}
