import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-guide-indicator',
  templateUrl: './setup-guide-indicator.component.html',
  styleUrls: ['./setup-guide-indicator.component.scss'],
})
export class SetupGuideIndicatorComponent implements OnInit {
  @Input() maxItems: number;
  @Input() completedItems: number;
  @Input() suffix = 'steps complete';
  @Input() textClass?: string;

  constructor() {}

  ngOnInit(): void {}
}
