import { Component, Input } from '@angular/core';

import type { SupportTeamMember } from './support-team-member';

@Component({
  selector: 'app-support-team',
  templateUrl: './support-team.component.html',
  styleUrls: ['./support-team.component.scss'],
})
export class SupportTeamComponent {
  @Input() items: SupportTeamMember[];
}
