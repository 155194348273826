import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BillingService } from '../../../../service/billing/billing.service';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-paypal-login-progress',
  templateUrl: './paypal-login-progress.component.html',
  styleUrls: ['./paypal-login-progress.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule, TranslateModule, MatButtonModule, LoadingSpinnerComponent],
})
export class PaypalLoginProgressComponent implements OnInit {
  code: string;
  loading = true;
  error = false;

  constructor(private route: ActivatedRoute, private billingService: BillingService, private _router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const data = {
        code: params.code,
      };

      this.billingService.connectWithPaypal(data).subscribe(
        () => {
          console.log(data);
          // alert('SUCCESS');
          this.loading = false;
          this._router.navigate(['/supplier-profile/order-settings']);
          // window.close();
        },
        (error) => {
          console.log(error);
          // alert('ERROR');
          this.loading = false;
          this.error = true;
          // window.close();
        }
      );
    });
  }

  navigateToGuide(): void {
    this._router.navigate(['/supplier-profile/order-settings']);
  }
}
