import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { flatten, uniq } from 'lodash';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { CatalogGatewayService, CatalogMainCategories } from '../../../../service/catalog/catalog-gateway.service';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { SeoUrlsBySupplierDto, SupplierTaskService } from '../../../../service/suppliers/supplier-task.service';
import { SupplierCard } from '../supplier-card.component';

@Component({
  selector: 'app-supplier-slider-box',
  templateUrl: './supplier-slider-box.component.html',
  styleUrls: ['./supplier-slider-box.component.scss'],
  animations: [fuseAnimations],
})
export class SupplierSliderBoxComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() newSuppliers: SupplierBlock;
  @Input() selectedCategoryId: number;

  swiped = false;
  suppliers: SupplierCard[];
  supplierImages: Record<number, string[]>;
  config: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: BreakPoint.xl,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: BreakPoint.lg,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    swipe: false,
    draggable: false,
  };
  ltMd: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private catalogGatewayService: CatalogGatewayService,
    private supplierTaskService: SupplierTaskService,
    private suppliersService: SupplierGatewayService,
    private screenManagerService: ScreenManagerService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    const userIds = this.newSuppliers.featuredSuppliers.map((featuredSupplier) => featuredSupplier.userId);
    this.getSupplierImages(userIds);
    forkJoin([
      this.catalogGatewayService.getCatalogCategories(userIds),
      this.supplierTaskService.getSeoUrlsByUserIds(userIds),
    ]).subscribe(([mainCategories, seoUrls]) => {
      this.suppliers = this.mapToSupplierCardModel(mainCategories, seoUrls);
    });

    this.subscribeToScreenChange();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (breakpoint) => {
          this.ltMd = breakpoint.matches;
        },
      });
  }

  private getSupplierImages(supplierIds: number[]): void {
    this.suppliersService.getRandomSupplierProductImages(supplierIds).subscribe((imageUrls) => {
      this.supplierImages = this.suppliersService.mapRandomSupplierVosToRecord(imageUrls);
    });
  }

  private mapToSupplierCardModel(
    mainCategories: CatalogMainCategories[],
    seoUrls: SeoUrlsBySupplierDto[]
  ): SupplierCard[] {
    return this.newSuppliers.featuredSuppliers.map((featuredSupplier) => {
      return {
        userId: featuredSupplier.userId,
        mainWarehouseCountry: featuredSupplier.mainWarehouseLocation,
        mainCategoryIds: this.getUniqeMainCategoryIdsBySupplier(mainCategories, featuredSupplier),
        companyName: featuredSupplier.companyName,
        seoUrls: seoUrls.length ? this.getSeoUrlsBySupplier(seoUrls, featuredSupplier) : [],
        handle: null,
      };
    });
  }

  private getSeoUrlsBySupplier(seoUrls: SeoUrlsBySupplierDto[], featuredSupplier: FeaturedSupplierVo): string[] {
    const seoUrlObj = seoUrls.find((seoUrlsBySupplier) => seoUrlsBySupplier.userId === +featuredSupplier.userId);
    if (!seoUrlObj) {
      return [];
    }
    return seoUrlObj?.seoUrls;
  }

  private getUniqeMainCategoryIdsBySupplier(
    mainCategories: CatalogMainCategories[],
    featuredSupplier: FeaturedSupplierVo
  ): number[] {
    return uniq(
      flatten(
        mainCategories
          .filter((mainCategory) => mainCategory.userId === +featuredSupplier.userId)
          .map((mainCategory) => mainCategory.categoryIds)
      )
    );
  }
}

export interface SupplierBlock {
  id: number;
  order: number;
  title: string;
  featuredSuppliers: FeaturedSupplierVo[];
  description: string;
  date: Date;
}

export interface FeaturedSupplierVo {
  id: number;
  supplierId: number;
  userId: number;
  order: number;
  companyName: string;
  description: string;
  logoLink: string;
  mainWarehouseLocation: string;
  seoUrl: string;
  reviews: Review[];
  date: Date;
}

export interface Review {
  id: number;
  ecomId: number;
  userId: number;
  rate: number;
  review: string;
  supplierComment: string;
  date: Date;
}
