import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyLoggedInDirective } from '../../directives/only-logged-in.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppState } from '../../../app.state';
import { Store } from '@ngrx/store';
import { followedSuppliersIdsSelector } from '../../../store/favorites/favorites.selector';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  AddToFollowedSupplierIdsStartAction,
  RemoveFromFollowedSupplierIdsStartAction,
} from '../../../store/favorites/favorites.actions';
import { User } from '../../../service/user/user';
import { getCurrentUserSelector } from '../../../store/user/user.selector';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-follow-supplier-button',
  standalone: true,
  imports: [CommonModule, OnlyLoggedInDirective, MatButtonModule, MatIconModule, TranslateModule],
  templateUrl: './follow-supplier-button.component.html',
  styleUrls: ['./follow-supplier-button.component.scss'],
})
export class FollowSupplierButtonComponent implements OnInit {
  @Input() supplierUserId: number;
  @Input() hasBorder = false;
  @Input() large = false;
  followed = false;
  isLoading = true;
  private currentUser: Observable<User>;

  private readonly unsubscribeAll: Subject<void>;
  constructor(private store: Store<AppState>) {
    this.unsubscribeAll = new Subject<void>();
    this.initCurrentUser();
  }

  ngOnInit(): void {
    this.subscribeToFollowedChange();
  }

  private getUserId(): Observable<number> {
    return this.currentUser.pipe(
      takeUntil(this.unsubscribeAll),
      map((user) => {
        return user.id;
      })
    );
  }

  handleFollowClick(): void {
    this.isLoading = true;
    if (this.followed) {
      this.removeFromFollowedSuppliers();
    } else {
      this.addToFollowedSuppliers();
    }
  }

  private initCurrentUser(): void {
    this.currentUser = this.store.select(getCurrentUserSelector);
  }

  private subscribeToFollowedChange(): void {
    this.store
      .select(followedSuppliersIdsSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((value) => {
        this.followed = value.includes(this.supplierUserId);
        this.isLoading = false;
      });
  }

  private addToFollowedSuppliers(): void {
    this.getUserId().subscribe((id) => {
      this.store.dispatch(
        new AddToFollowedSupplierIdsStartAction({
          userId: id,
          supplierIds: [this.supplierUserId],
        })
      );
    });
  }

  private removeFromFollowedSuppliers(): void {
    this.getUserId().subscribe((id) => {
      this.store.dispatch(
        new RemoveFromFollowedSupplierIdsStartAction({
          userId: id,
          supplierIds: [this.supplierUserId],
        })
      );
    });
  }
}
