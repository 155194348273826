import { Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleCountrySelectorComponent } from '../single-country-selector/single-country-selector.component';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

/**
 * has to be wrapped with mat-form-field, with appearance set to standard
 *
 you need to apply the following classes on the wrapping mat-form-field: custom-form-field-wrapper, input-without-padding-except-error
 */

@Component({
  selector: 'app-single-country-selector-form-field',
  standalone: true,
  imports: [CommonModule, SingleCountrySelectorComponent],
  templateUrl: './single-country-selector-form-field.component.html',
  styleUrls: ['./single-country-selector-form-field.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SingleCountrySelectorFormFieldComponent,
    },
  ],
})
export class SingleCountrySelectorFormFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnInit, OnDestroy, DoCheck
{
  @Input() placeholder: string;
  @Input() label?: string;
  @Input() error?: string;
  value: string;
  onChange: (value) => void;
  onTouched: () => void;
  touched = false;
  stateChanges = new Subject<void>();
  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  disabled: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = req;
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.ngControl.invalid && this.ngControl.touched;
  }

  constructor(public ref: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    if (!!this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.required = this.ngControl.control.hasValidator(Validators.required);
  }
  ngDoCheck(): void {
    if (this.errorState) {
      this.stateChanges.next();
    }
  }
  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.ref.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  valueChange(value: string): void {
    this.onChange(value);
    this.stateChanges.next();
  }
}
