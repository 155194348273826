import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDialogWrapperComponent } from '../custom-dialog-wrapper/custom-dialog-wrapper.component';
import { FlexModule } from '@angular/flex-layout';
import { CustomDividerComponent } from '../../custom-divider/custom-divider.component';
import { MatIconModule } from '@angular/material/icon';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { MySuppliersRetailersModule } from '../../my-suppliers-retailers/my-suppliers-retailers.module';
import { ROLE_TYPE_ADMIN_EXCLUDED, RolesEnum } from '../../../../vo/roles/roles';
import { CatalogApproveStatusBadgeComponent } from '../../catalog-approve-status-badge/catalog-approve-status-badge.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CatalogApprovalStatus } from '../../../../main/marketplace/my-suppliers-new/model/catalog-approval-status';
import { TranslateModule } from '@ngx-translate/core';
import { CapitalisePipe } from '../../../pipes/capitalise.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShowMessageBubbleDialogButtonComponent } from '../show-message-bubble-dialog-button/show-message-bubble-dialog-button.component';

@UntilDestroy()
@Component({
  selector: 'app-task-information-base-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CustomDialogWrapperComponent,
    FlexModule,
    CustomDividerComponent,
    MatIconModule,
    MySuppliersRetailersModule,
    CatalogApproveStatusBadgeComponent,
    MatSlideToggleModule,
    TranslateModule,
    CapitalisePipe,
    ShowMessageBubbleDialogButtonComponent,
  ],
  templateUrl: './task-information-base-dialog.component.html',
  styleUrls: ['./task-information-base-dialog.component.scss'],
})
export class TaskInformationBaseDialogComponent implements OnInit {
  @Input() role: ROLE_TYPE_ADMIN_EXCLUDED; // role to display information about
  @Input() name: string;
  @Input() userId: number;
  @Input() contactEmail: string;
  @Input() taskName: string;
  @Input() status: CatalogApprovalStatus;
  @Input() rejectedNoteRef: TemplateRef<any>;
  @Input() actionBarRef: TemplateRef<any>;
  @Input() shippingTypeRef: TemplateRef<any>;
  @Input() message?: string;
  protected readonly RolesEnum = RolesEnum;
  isMobile: boolean;
  defaultTitle = 'CATALOG_INFO_DIALOG.DEFAULT_TITLE';
  configData: TaskInformationDialogTypedConfig = {
    [RolesEnum.RETAILER]: {
      titleLabel: 'CATALOG_INFO_DIALOG.SUPPLIER_SIDE_TITLE',
      nameLabel: 'CATALOG_INFO_DIALOG.RETAILER_NAME_LABEL',
      taskNameLabel: 'CATALOG_INFO_DIALOG.CATALOG_NAME_LABEL',
    },
    [RolesEnum.SUPPLIER]: {
      titleLabel: 'CATALOG_INFO_DIALOG.RETAILER_SIDE_TITLE',
      nameLabel: 'CATALOG_INFO_DIALOG.SUPPLIER_NAME_LABEL',
      taskNameLabel: 'CATALOG_INFO_DIALOG.CATALOG_NAME_LABEL',
    },
  };
  constructor(private screenManager: ScreenManagerService) {}

  ngOnInit(): void {
    this.screenManager
      .observeIfBreakpointMatches(BreakPoint.md)
      .pipe(untilDestroyed(this))
      .subscribe((matches) => (this.isMobile = matches));
  }
}

export interface TaskInformationDialogConfig {
  titleLabel: string;
  nameLabel: string;
  taskNameLabel: string;
}

export type TaskInformationDialogTypedConfig = Record<ROLE_TYPE_ADMIN_EXCLUDED, TaskInformationDialogConfig>;
