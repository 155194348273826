<div class="container">
  <div class="typography-body-2 color-grey--300" *ngIf="!!label">{{ label }}</div>
  <div class="items">
    <app-tag-selector-item
      *ngFor="let option of options"
      [option]="option"
      [checked]="value.includes(option.value)"
      [disabled]="disabled"
      (click)="handleClick(option)"
    ></app-tag-selector-item>
  </div>
</div>
