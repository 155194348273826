import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MySuppliersCacheService } from '../service/my-suppliers-cache.service';
import { Observable } from 'rxjs';
import { SupplierTask } from '../../../../vo/supplier/supplier-task';
@Injectable()
@Pipe({
  name: 'getTaskFromCacheById',
})
export class GetTaskFromCacheByIdPipe implements PipeTransform {
  constructor(private cacheService: MySuppliersCacheService) {}
  transform(catalogID: number): Observable<SupplierTask> {
    return this.cacheService.getCatalogFromCache(catalogID);
  }
}
