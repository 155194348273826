<div fxLayout="column" fxLayoutGap="12px">
  <div *ngIf="selectable" class="filter-container">
    <mat-form-field appearance="fill" class="input-without-padding select-without-label w-100p">
      <input
        matInput
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchTermUpdate.next($event)"
        [placeholder]="inputPlaceholder"
      />
    </mat-form-field>
    <mat-icon *ngIf="!!inputTooltip" [matTooltip]="inputTooltip" matTooltipPosition="right" class="s-20 icon-filled">
      info
    </mat-icon>
  </div>

  <mat-list role="list" *ngIf="!!dataSource && !!treeControl" class="countries" [style.max-height]="maxHeight">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        [class.hide]="selectable && !filteredValues.isSelected(node)"
      >
        <mat-list-item>
          <mat-checkbox class="leaf-node" [checked]="isSelected(node)" (change)="todoLeafItemSelectionToggle(node)">
          </mat-checkbox>
          <img *ngIf="node.imgUrl" [src]="node.imgUrl" [alt]="node.name" />
          <span class="name" [class.typography-body-1]="node.level !== 0" [class.h5]="node.level === 0">
            {{ node.name }}
          </span>
        </mat-list-item>
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        [class.hide]="selectable && !filteredValues.isSelected(node)"
      >
        <mat-list-item>
          <mat-checkbox
            [checked]="descendantsAllSelected(node) || (selectParentExclusively && isSelected(node))"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node, $event)"
          >
          </mat-checkbox>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <img *ngIf="node.imgUrl" [src]="node.imgUrl" [alt]="node.name" class="ml-0" />
          <span class="name" [class.typography-body-1]="node.level !== 0" [class.h5]="node.level === 0">{{
            node.name
          }}</span>
        </mat-list-item>
      </mat-tree-node>
    </mat-tree>
  </mat-list>
</div>
