import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SupplierCard } from 'app/main/marketplace/supplier-card/supplier-card.component';
import { SupplierCardModule } from 'app/main/marketplace/supplier-card/supplier-card.module';
import { CatalogGatewayService, CatalogMainCategories } from 'app/service/catalog/catalog-gateway.service';
import { concat, flatten, merge, uniq } from 'lodash';
import { forkJoin } from 'rxjs';
import { SupplierListItemComponent } from '../../../main/marketplace/supplier-list/supplier-list-item/supplier-list-item.component';
import { RandomImageVo, SupplierGatewayService } from '../../../service/suppliers/supplier-gateway.service';
import { SeoUrlsBySupplierDto, SupplierTaskService } from '../../../service/suppliers/supplier-task.service';
import { SupplierWithBadgesDto } from '../../../vo/supplier/supplier-with-badges-dto';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

@Component({
  selector: 'app-supplier-card-list',
  standalone: true,
  imports: [
    CommonModule,
    SupplierListItemComponent,
    SupplierListItemComponent,
    FlexModule,
    MatProgressSpinnerModule,
    ErrorMessageComponent,
    SupplierCardModule,
    SkeletonModule,
  ],
  templateUrl: './supplier-card-list.component.html',
  styleUrls: ['./supplier-card-list.component.scss'],
})
export class SupplierCardListComponent implements OnInit, OnChanges {
  @Input() isIdListLoading: boolean;
  @Input() idListHasError: boolean;
  @Input() supplierIds: number[];
  @Input() isInfinite = false;
  @Input() from = 0;
  @Input() selectedCategoryId: number;

  isLoading = false;
  isError = false;
  supplierCards: SupplierCard[];
  supplierImages: Record<number, string[]>;

  constructor(
    private supplierGatewayService: SupplierGatewayService,
    private supplierTaskService: SupplierTaskService,
    private catalogGatewayService: CatalogGatewayService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.supplierIds) {
      this.isLoading = true;
      if (this.supplierIds.length > 0) {
        this.getSupplierData(this.supplierIds);
      } else {
        this.supplierCards = [];
        this.isLoading = false;
      }
    }
  }

  private setErrorState(): void {
    this.isError = true;
    this.isLoading = false;
  }

  private getSupplierData(supplierUserIds: number[]): void {
    forkJoin([
      this.supplierGatewayService.getSuppliers(supplierUserIds),
      this.catalogGatewayService.getCatalogCategories(supplierUserIds),
      this.supplierTaskService.getSeoUrlsByUserIds(supplierUserIds),
      this.supplierGatewayService.getRandomSupplierProductImages(supplierUserIds, this.selectedCategoryId),
    ]).subscribe(
      ([suppliers, mainCategories, seoUrls, supplierImages]) => {
        this.handleSupplierDataResponse(
          suppliers.sort((a, b) => supplierUserIds.indexOf(a.userId) - supplierUserIds.indexOf(b.userId)),
          mainCategories,
          seoUrls,
          supplierImages
        );
        this.isLoading = false;
        this.isError = false;
      },
      () => {
        this.setErrorState();
      }
    );
  }

  private handleSupplierDataResponse(
    suppliers: SupplierWithBadgesDto[],
    mainCategories: CatalogMainCategories[],
    seoUrls: SeoUrlsBySupplierDto[],
    supplierImages: RandomImageVo[]
  ): void {
    const cardItems = this.mapToSupplierCardModel(suppliers, mainCategories, seoUrls);
    if (this.from > 0 && this.isInfinite) {
      this.supplierImages = merge(
        this.supplierImages,
        this.supplierGatewayService.mapRandomSupplierVosToRecord(supplierImages)
      );
      this.supplierCards = concat(this.supplierCards, cardItems);
    } else {
      this.supplierImages = this.supplierGatewayService.mapRandomSupplierVosToRecord(supplierImages);
      this.supplierCards = cardItems;
    }
  }

  private mapToSupplierCardModel(
    suppliers: SupplierWithBadgesDto[],
    mainCategories: CatalogMainCategories[],
    seoUrls: SeoUrlsBySupplierDto[]
  ): SupplierCard[] {
    return suppliers.map((supplier) => {
      return {
        userId: supplier.userId,
        mainWarehouseCountry: supplier.mainWarehouseLocation,
        mainCategoryIds: this.getUniqeMainCategoryIdsBySupplier(mainCategories, supplier),
        companyName: supplier.companyName,
        seoUrls: seoUrls.length ? this.getSeoUrlsBySupplier(seoUrls, supplier) : [],
        handle: supplier.handle,
      };
    });
  }

  private getSeoUrlsBySupplier(seoUrls: SeoUrlsBySupplierDto[], supplier: SupplierWithBadgesDto): string[] {
    return seoUrls.find((seoUrlsBySupplier) => seoUrlsBySupplier.userId === +supplier.userId)?.seoUrls;
  }

  private getUniqeMainCategoryIdsBySupplier(
    mainCategories: CatalogMainCategories[],
    supplier: SupplierWithBadgesDto
  ): number[] {
    return uniq(
      flatten(
        mainCategories
          .filter((mainCategory) => mainCategory.userId === +supplier.userId)
          .map((mainCategory) => mainCategory.categoryIds)
      )
    );
  }
}
