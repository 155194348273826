import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogMultiWrapperComponent } from '../../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-show-message-bubble-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, DialogMultiWrapperComponent, FlexModule],
  templateUrl: './show-message-bubble-dialog.component.html',
  styleUrls: ['./show-message-bubble-dialog.component.scss'],
})
export class ShowMessageBubbleDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ShowMessageBubbleDialogComponentData) {}

  ngOnInit(): void {}
}

export interface ShowMessageBubbleDialogComponentData {
  message: string;
  titleKey: string;
  descriptionKey?: string;
}
