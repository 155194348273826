import { User } from 'app/service/user/user';
import { USER_ACTIONS_ALL, UserActionTypes } from './user.actions';
import { ROLE_TYPE, RolesEnum } from '../../vo/roles/roles';
import { AuthenticationStorage } from '../../service/authentication/authentication-storage';
import { SCOPES } from '../../service/permission/scopes';

export interface UserState {
  currentUser: User;
  actualRole: ROLE_TYPE;
  roles: ROLE_TYPE[];
  locationByIp: string;
}

const initialState = (): UserState => {
  return {
    currentUser: getUser(),
    actualRole: !!localStorage.getItem('actualRole') ? JSON.parse(localStorage.getItem('actualRole')) : undefined,
    roles: getRoles(),
    locationByIp: null,
  };
};

export const getUser = (): User => {
  const adminLoggedInUser = JSON.parse(localStorage.getItem('userDetails'));
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  return !!adminLoggedInUser ? adminLoggedInUser : !!currentUser ? currentUser : undefined;
};

export const getRoles = (): ROLE_TYPE[] => {
  const userScopes = AuthenticationStorage.getAuthData()?.scopes;
  const loggedUserScopes = AuthenticationStorage.getLoggedUserAuthData()?.scopes;
  const scopes = !!loggedUserScopes ? loggedUserScopes.concat(userScopes) : userScopes;

  if (scopes) {
    const roles: Set<ROLE_TYPE> = new Set();

    if (scopes.includes(SCOPES.ADMIN)) {
      roles.add(RolesEnum.ADMIN);
      roles.add(RolesEnum.RETAILER);
      roles.add(RolesEnum.SUPPLIER);
    }

    if (scopes.includes(SCOPES.REMOVE_SUPPLIER)) {
      roles.add(RolesEnum.SUPPLIER);
    }

    if (scopes.includes(SCOPES.REMOVE_RETAILER)) {
      roles.add(RolesEnum.RETAILER);
    }

    if (!!loggedUserScopes) {
      if (!loggedUserScopes.includes(SCOPES.REMOVE_RETAILER)) {
        roles.delete(RolesEnum.RETAILER);
      }

      if (!loggedUserScopes.includes(SCOPES.REMOVE_SUPPLIER)) {
        roles.delete(RolesEnum.SUPPLIER);
      }
    }

    return [...roles];
  }

  return [];
};

export function usersReducer(state = initialState(), action: USER_ACTIONS_ALL): UserState {
  switch (action.type) {
    case UserActionTypes.USER_DETAILS_CHAIN_TO_ECOM_FETCH_SUCCESS:
    case UserActionTypes.USER_DETAILS_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        roles: getRoles(),
      };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        roles: getRoles(),
      };
    case UserActionTypes.SET_ACTUAL_ROLE:
      return {
        ...state,
        actualRole: action.payload as ROLE_TYPE,
      };
    case UserActionTypes.GET_LOCATION_BY_IP:
      return {
        ...state,
        locationByIp: action.payload,
      };
    case UserActionTypes.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, settings: action.payload },
      };
    default: {
      return state;
    }
  }
}
