<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
  <app-single-alert-with-icon
    icon="forum"
    titleKey="CHAT.NO_CONVERSATION.TITLE"
    [descriptionKey]="
      (isRetailer$ | async) ? 'CHAT.NO_CONVERSATION.DESCRIPTION_RETAILER' : 'CHAT.NO_CONVERSATION.DESCRIPTION_SUPPLIER'
    "
    [button]="
      (isRetailer$ | async)
        ? {
            type: 'inner_redirect',
            textKey: 'CHAT.NO_CONVERSATION.BUTTON',
            buttonClass: 'syncee-blue-button',
            url: navigateToMarketplace,
            queryParams: {
              category: '1',
              searchType: 'SUPPLIERS'
            }
          }
        : null
    "
  ></app-single-alert-with-icon>
</div>
