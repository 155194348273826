import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryOptionsService } from './service/category-options.service';
import { CommonModule } from '@angular/common';
import { TagSelectorModule } from '../tag-selector/tag-selector.module';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CategoryPreferencesForm } from './model/category-preferences-form';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-category-preferences-form',
  templateUrl: './category-preferences-form.html',
  imports: [
    CommonModule,
    TagSelectorModule,
    ReactiveFormsModule,
    MatButtonModule,
    FlexModule,
    MatFormFieldModule,
    TranslateModule,
  ],
  providers: [CategoryOptionsService],
  standalone: true,
})
export class CategoryPreferencesFormComponent implements OnInit {
  @Input() categories: number[] = [];

  @Input() hideButton = false;

  @Output() submit = new EventEmitter<CategoryPreferencesForm>();

  public form: FormGroup;

  constructor(protected categoryOptionsService: CategoryOptionsService, private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.initForm();
  }

  handleSubmit(): void {
    if (!this.form.invalid) {
      this.submit.emit(this.form.getRawValue());
    }
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      categories: this.formBuilder.control(this.categories, [Validators.required, Validators.maxLength(3)]),
    });
  }
}
