import { Action } from '@ngrx/store';
import { InstalledAppsAndGroups } from '../../service/user-apps/syncee-apps.service';

export enum SynceeAppsActionTypes {
  GET_INSTALLED_SYNCEE_APPS_START = '[Syncee Apps] Get installed Syncee Apps Start',
  GET_INSTALLED_SYNCEE_APPS_SUCCESS = '[Syncee Apps] Get installed Syncee Apps Success',
  INSTALL_SYNCEE_APPS_START = '[Syncee Apps] Install Syncee Apps Start',
  INSTALL_SYNCEE_APPS_SUCCESS = '[Syncee Apps] Install Syncee Apps Success',
  UNINSTALL_SYNCEE_APPS_START = '[Syncee Apps] Uninstall Syncee Apps Start',
  UNINSTALL_SYNCEE_APPS_SUCCESS = '[Syncee Apps] Uninstall Syncee Apps Success',
}

export class GetInstalledSynceeAppsStartAction implements Action {
  readonly type = SynceeAppsActionTypes.GET_INSTALLED_SYNCEE_APPS_START;
}

export class GetInstalledSynceeAppsSuccessAction implements Action {
  readonly type = SynceeAppsActionTypes.GET_INSTALLED_SYNCEE_APPS_SUCCESS;

  constructor(public payload: InstalledAppsAndGroups) {}
}

export class InstallSynceeAppsStartAction implements Action {
  readonly type = SynceeAppsActionTypes.INSTALL_SYNCEE_APPS_START;

  constructor(public payload: number[]) {}
}

export class InstallSynceeAppsSuccessAction implements Action {
  readonly type = SynceeAppsActionTypes.INSTALL_SYNCEE_APPS_SUCCESS;
}

export class UninstallSynceeAppsStartAction implements Action {
  readonly type = SynceeAppsActionTypes.UNINSTALL_SYNCEE_APPS_START;

  constructor(public payload: number[]) {}
}

export class UninstallSynceeAppsSuccessAction implements Action {
  readonly type = SynceeAppsActionTypes.UNINSTALL_SYNCEE_APPS_SUCCESS;
}

export type SynceeAppActionsUnion =
  | GetInstalledSynceeAppsStartAction
  | GetInstalledSynceeAppsSuccessAction
  | InstallSynceeAppsStartAction
  | InstallSynceeAppsSuccessAction
  | UninstallSynceeAppsStartAction
  | UninstallSynceeAppsSuccessAction;
