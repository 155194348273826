import { Pipe, PipeTransform } from '@angular/core';
import { FulfillmentStatusEnum } from '../../main/manage-orders-new/model/enums/fulfillment-status-enum';
import { PaymentStatusEnum } from '../../main/manage-orders-new/model/enums/payment-status-enum';
import { AutoOrderStatusEnum } from '../../main/manage-orders-new/model/enums/auto-order-status-enum';
import { StatusBadgeColorsEnum } from '../../main/manage-orders-new/model/enums/status-badge-colors';
import { SyncStatus } from '../../main/manage-orders-new/model/enums/sync-status';

@Pipe({
  name: 'statusTextColor',
  standalone: true,
})
export class StatusTextColorPipe implements PipeTransform {
  transform(
    value: FulfillmentStatusEnum | PaymentStatusEnum | AutoOrderStatusEnum | SyncStatus | string,
    args?: any
  ): string {
    return StatusBadgeColorsEnum[value + '_TEXT'];
  }
}
