import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { RcatalogService } from 'app/service/rcatalog/rcatalog.service';
import { Catalog } from 'app/vo/catalog';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { switchMapWith } from '../../utils/operator/switch-map-with';
import {
  ClearSelectedCatalogAction,
  DeleteCatalogAction,
  GetCatalogsSuccessAction,
  RCatalogsActionTypes,
  SetSelectedCatalogAction,
} from './rcatalogs.action';
import { selectedCatalogSelector } from './rcatalogs.selector';

@Injectable()
export class RCatalogsEffects {
  constructor(private actions$: Actions, private rcatalogService: RcatalogService, private store: Store<AppState>) {}

  UserPreferencesStart: Observable<GetCatalogsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.GET_RCATALOGS_START),
      switchMap(() => this.rcatalogService.fetchCatalogs().pipe(catchError(() => of(null)))),
      map((catalogs: Catalog[]) => new GetCatalogsSuccessAction(catalogs))
    )
  );

  SetSelectedImportList: Observable<SetSelectedCatalogAction> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RCatalogsActionTypes.setSelectedCatalog),
        tap((action: SetSelectedCatalogAction) => {
          localStorage.setItem('selectedImportList', JSON.stringify(action.payload));
        })
      ),
    { dispatch: false }
  );

  ClearSelectedCatalog: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RCatalogsActionTypes.clearSelectedCatalog),
        tap(() => localStorage.removeItem('selectedImportList'))
      ),
    { dispatch: false }
  );

  HandleDeleteImportList: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.deleteCatalog),
      map((action: DeleteCatalogAction) => action.payload),
      switchMapWith(() => this.store.select(selectedCatalogSelector).pipe(take(1))),
      filter(
        ([importListToDelete, selectedImportList]) => Number(importListToDelete) === Number(selectedImportList.id)
      ),
      map(() => new ClearSelectedCatalogAction())
    )
  );
}
