import { Pipe, PipeTransform } from '@angular/core';
import { CatalogApproveStatus, CatalogSidebarService } from 'app/service/catalog-sidebar/catalog-sidebar.service';

@Pipe({
  name: 'catalogStatusCheck',
  standalone: true,
})
export class CatalogStatusCheckPipe implements PipeTransform {
  constructor(private _catalogSidebarService: CatalogSidebarService) {}

  transform(catalogId: number): CatalogApproveStatus {
    return this._catalogSidebarService.catalogStatusCheck(catalogId);
  }
}
