import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LeftAndRightSectionComponent,
  LeftAndRightSectionContentPosition,
} from '../left-and-right-section/left-and-right-section.component';
import { GeneralButtonComponent } from '../../../../shared/components/general-button/general-button.component';
import { GeneralButton } from '../../../../vo/general-button/general-button';

@Component({
  selector: 'app-left-and-right-section-with-general-button',
  standalone: true,
  imports: [CommonModule, LeftAndRightSectionComponent, GeneralButtonComponent],
  templateUrl: './left-and-right-section-with-general-button.component.html',
  styleUrls: ['./left-and-right-section-with-general-button.component.scss'],
})
export class LeftAndRightSectionWithGeneralButtonComponent implements OnInit {
  @Input() position: LeftAndRightSectionContentPosition = 'left';
  @Input() chipText: string;
  @Input() title: string;
  @Input() description: string;
  @Input() descriptionSmall: string;
  @Input() wrapperClass: string | string[];
  @Input() innerClass: string | string[];
  @Input() button: GeneralButton;

  constructor() {}

  ngOnInit(): void {}
}
