import { Pipe, PipeTransform } from '@angular/core';
import { I18nBackendJson } from '../../vo/translation/i18n-backend-json';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getI18nFromBackendJson',
  standalone: true,
})
export class GetI18nFromBackendJsonPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: I18nBackendJson): string {
    const currentLang = this.translateService.currentLang;
    if (!!value[currentLang] && value[currentLang].length !== 0) {
      return value[currentLang];
    } else if (!!value.en) {
      return value.en;
    } else {
      return null;
    }
  }
}
