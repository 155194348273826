import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountriesManagerService } from 'app/service/countries-manager/countries-manager.service';
import { ConnectStatus, PaymentAccount, PaymentService } from 'app/service/payment/payment.service';
import { UserService } from 'app/service/user/user.service';
import { EU_COUNTRIES_CODES, PAYPAL_VALID_COUNTRY_CODES, STRIPE_VALID_COUNTRY_CODES } from 'app/utils/Constants';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogsService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { StripeConnectComponent } from '../stripe-connect/stripe-connect.component';
import { LoginWithPaypalComponent } from '../login-with-paypal/login-with-paypal.component';
import { SupplierTaxSettingsComponent } from '../supplier-tax-settings/supplier-tax-settings.component';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { PaypalOnboardComponent } from '../paypal/paypal-onboard/paypal-onboard.component';

@Component({
  selector: 'app-payment-connect',
  templateUrl: './payment-connect.component.html',
  styleUrls: ['./payment-connect.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    StripeConnectComponent,
    LoginWithPaypalComponent,
    SupplierTaxSettingsComponent,
    AlertMultiComponent,
    MatCardModule,
    MatSelectModule,
    PaypalOnboardComponent,
  ],
})
export class PaymentConnectComponent implements OnInit, OnDestroy {
  @Output() connectStatusOut = new EventEmitter<ConnectStatus>();
  @Input() redirectStripeUrl = environment.stripe.redirectUrlSettings;
  @Input() redirectPaypalUrl = environment.paypal.returnurl;

  paymentAccount: PaymentAccount;
  billingForm: UntypedFormGroup;
  euCountryCodeList = EU_COUNTRIES_CODES;
  stripeValiCountries = STRIPE_VALID_COUNTRY_CODES;
  paypalValiCountries = PAYPAL_VALID_COUNTRY_CODES;

  paypalConnectStatus = false;
  stripeConnectStatus = false;

  private _unsubscribeAll: Subject<void>;

  hideEUVat = true;
  hideTax = true;

  constructor(
    private fb: UntypedFormBuilder,
    private paymentService: PaymentService,
    private userService: UserService,
    private aRoute: ActivatedRoute,
    private confirmationDialogsService: ConfirmationDialogsService,
    private countriesManager: CountriesManagerService,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();
    this.initForms();
    this.init();
  }

  initForms(): void {
    this.billingForm = this.fb.group({
      id: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      companyName: ['', Validators.required],
      euVat: [null],
      taxNumber: [null],
      paymentAddress: this.fb.group({
        id: [null],
        country: [null, Validators.required],
        address1: [null, Validators.required],
        address2: [null],
        city: [null, Validators.required],
        zipCode: [null, Validators.required],
      }),
    });

    this.initEvents();
  }

  init(): void {
    this.paymentService.getPaymentAccount().subscribe((resp) => {
      if (resp) {
        this.setup(resp);
      }
    });
  }

  setup(data): void {
    this.paymentAccount = data;
    this.billingForm.patchValue(this.paymentAccount);
  }

  initEvents(): void {
    const paymentAddress = <UntypedFormGroup>this.billingForm.controls['paymentAddress'];
    paymentAddress.controls['country'].valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((countryCode) => {
      this.checkTaxNumber(countryCode);
    });
  }

  get countries(): any[] {
    return this.countriesManager.getOnlyCountries();
  }

  checkTaxNumber(currentCountryCode): void {
    if (this.euCountryCodeList.includes(currentCountryCode)) {
      this.hideEUVat = false;
    } else {
      this.hideEUVat = true;
      this.billingForm.controls['euVat'].reset();
    }

    if ('HU' === currentCountryCode) {
      this.hideTax = false;
      this.billingForm.controls['taxNumber'].setValidators([Validators.required]);
      this.billingForm.controls['taxNumber'].updateValueAndValidity();
      this.billingForm.updateValueAndValidity();
      this.billingForm.controls['taxNumber'].markAsTouched();
    } else {
      this.hideTax = true;
      this.billingForm.controls['taxNumber'].clearValidators();
      this.billingForm.controls['taxNumber'].reset();
      this.billingForm.controls['taxNumber'].updateValueAndValidity();
      this.billingForm.updateValueAndValidity();
    }
  }

  submitBilling(): void {
    if (!this.billingForm.valid) {
      return;
    }

    if (this.paymentAccount && this.paymentAccount.stripeConnect && !this.isStripeExistsInCountry) {
      this.countryChangeWDialogOpen().subscribe((status) => {
        if (!status) {
          return;
        }
        this.paymentService.disconnectStripeAccount(this.paymentAccount.stripeConnect.id).subscribe((resp) => {
          this.paymentService.savePaymentAccount(this.billingForm.value).subscribe((result): void => {
            this.setup(result);
          });
        });
      });
      return;
    }
    this.paymentService.savePaymentAccount(this.billingForm.value).subscribe((resp) => {
      this.setup(resp);
    });
  }

  countryChangeWDialogOpen(): Observable<any> {
    return this.confirmationDialogsService.confirm(
      this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE'),
      this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE_MESSAGE'),
      this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE_YES'),
      this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE_NO')
    );
  }

  paypalConnectStatusEvent(status): void {
    this.paypalConnectStatus = status;
    this.emitConnectStatus();
  }

  stripeConnectStatusEvent(status): void {
    this.stripeConnectStatus = status;
    this.emitConnectStatus();
  }

  emitConnectStatus(): void {
    this.connectStatusOut.emit(<ConnectStatus>{
      paypalConnectStatus: this.paypalConnectStatus,
      stripeConnectStatus: this.stripeConnectStatus,
    });
  }

  get country(): string {
    return this.billingForm.get('paymentAddress').get('country').value;
  }

  get isStripeExistsInCountry(): boolean {
    return this.stripeValiCountries.includes(this.country);
  }

  get isPaypalExistsInCountry(): boolean {
    return this.paypalValiCountries.includes(this.country);
  }

  getBillingData(): any {
    return this.billingForm.value;
  }

  isConnectedPayment(): boolean {
    return this.paypalConnectStatus || this.stripeConnectStatus;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
