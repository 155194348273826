<div
  [fxLayout]="direction"
  [fxLayoutAlign]="direction === 'row wrap' ? 'end center' : 'center start'"
  [fxLayoutGap]="gap + gapUnit"
>
  <mat-form-field
    [style.width]="direction === 'column' ? '' : 'calc(50% - ' + gap / 2 + gapUnit + ')'"
    appearance="fill"
    class="input-without-padding custom-form-field"
  >
    <mat-label>{{ 'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.FROM' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="datePickerFrom"
      (dateChange)="handleFromDateChange($event)"
      [formControl]="dateFromControl"
    />
    <mat-datepicker-toggle matSuffix [for]="datePickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #datePickerFrom></mat-datepicker>
  </mat-form-field>
  <mat-form-field
    [style.width]="direction === 'column' ? '' : 'calc(50% - ' + gap / 2 + gapUnit + ')'"
    appearance="fill"
    class="input-without-padding custom-form-field"
  >
    <mat-label>{{ 'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.TO' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="datePickerTo"
      (dateChange)="handleToDateChange($event)"
      [min]="dateFrom"
      [formControl]="dateToControl"
    />
    <mat-datepicker-toggle matSuffix [for]="datePickerTo"></mat-datepicker-toggle>
    <mat-datepicker #datePickerTo></mat-datepicker>
  </mat-form-field>
</div>
