import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-usage-error',
  templateUrl: './usage-error.component.html',
  styleUrls: ['./usage-error.component.scss'],
  imports: [CommonModule, MatIconModule, TranslateModule],
  standalone: true,
})
export class UsageErrorComponent {
  @Input()
  usage: number;

  @Input()
  starter: boolean;

  @Input()
  upgraded?: boolean;

  @Input()
  short = false;

  @Output()
  onUpgrade = new EventEmitter<void>();

  static MEDIUM_LIMIT = 60;

  static HIGH_LIMIT = 80;

  static MAX = 100;

  get mediumLimit(): number {
    return UsageErrorComponent.MEDIUM_LIMIT;
  }

  get highLimit(): number {
    return UsageErrorComponent.HIGH_LIMIT;
  }

  get max(): number {
    return UsageErrorComponent.MAX;
  }
}
