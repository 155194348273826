import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'supplierBrandImage',
  standalone: true,
})
export class SupplierBrandImagePipe implements PipeTransform {
  constructor() {}
  transform(userId: number): unknown {
    const resourcePath = 'brand-logos/' + userId + '.png';
    return `${environment.storageUrl}/o/${resourcePath.replace('/', '%2F')}?alt=media`;
  }
}
