import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PaymentCardDialogComponent } from 'app/shared/components/payment-card-dialog/payment-card-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CardFormDialogService {
  constructor(private dialog: MatDialog) {}

  public subscribeToPaymentProvider(
    title: string = '',
    message: string = '',
    subscription: any,
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel',
    type: string = ''
  ): Observable<any> {
    let dialogRef: MatDialogRef<PaymentCardDialogComponent>;
    const config = new MatDialogConfig();
    config.autoFocus = false;
    if (window.screen.width <= 850) {
      config.width = '100vw';
      config.maxWidth = '100vw';
    } else {
      config.width = '38vw';
    }
    config.disableClose = true;

    dialogRef = this.dialog.open(PaymentCardDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.btnOkText = btnOkText;
    dialogRef.componentInstance.btnCancelText = btnCancelText;
    dialogRef.componentInstance.subscription = subscription;
    dialogRef.componentInstance.type = type;

    return dialogRef.afterClosed();
  }
}
