<!-- PROGRESS BAR -->
<!--<fuse-progress-bar></fuse-progress-bar>-->
<!-- / PROGRESS BAR -->

<!-- <ngx-json-ld [json]="commonJsonLD"></ngx-json-ld> -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
  <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
  <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
  <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
  <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!--<button *ngIf="fuseConfig.showThemeOptions"-->
<!--mat-icon-button class="warn mat-elevation-z2 theme-options-button"-->
<!--(click)="toggleSidebarOpen('themeOptionsPanel')">-->
<!--<mat-icon>settings</mat-icon>-->
<!--</button>-->
<!-- / THEME OPTIONS PANEL -->
<!-- <div *appHasPermissions="['CART.READ']"></div> -->
