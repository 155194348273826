<ng-template #header_left>
  <div class="h3">{{ 'STOREFRONT_SETTINGS.TAB.RETURN_AND_REFUND_POLICY.LABEL' | translate }}</div>
</ng-template>

<app-custom-dialog-wrapper [headerLeftRef]="header_left">
  <ng-container *ngIf="!!policyData">
    <div fxLayout="column" fxLayoutGap="20px" class="p-20">
      <ng-container *ngIf="!policyData.supplierPolicyData.noPolicies || policyData.supplierPolicyData.returnAndRefund">
        <div>{{ 'STOREFRONT_SETTINGS.TAB.RETURN_AND_REFUND_POLICY.REFUND_ISSUE' | translate }}</div>
        <div>
          {{ 'STOREFRONT_SETTINGS.TAB.RETURN_AND_REFUND_POLICY.CUSTOMER_CONTACT' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="policyData.supplierPolicyData.returnAndRefund">
        <app-custom-divider></app-custom-divider>
        <div class="policy-text-container">{{ policyData.supplierPolicyData.returnAndRefund }}</div>
      </ng-container>
      <ng-container
        *ngIf="!!policyData.supplierPolicyData.noPolicies || !policyData.supplierPolicyData.returnAndRefund"
      >
        <div>{{ 'STOREFRONT_SETTINGS.TAB.RETURN_AND_REFUND_POLICY.SUPPLIER_NO_POLICY' | translate }}</div>
      </ng-container>
    </div>
  </ng-container>
</app-custom-dialog-wrapper>
