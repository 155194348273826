import { Injectable } from '@angular/core';
import { SearchSession } from './model/search-session';

@Injectable({
  providedIn: 'root',
})
export class SearchSessionCacheService {
  static SESSION = 'searchSession';

  constructor() {}

  saveSession(session: SearchSession): void {
    localStorage.setItem(SearchSessionCacheService.SESSION, JSON.stringify(session));
  }

  getSession(): SearchSession | null {
    const raw = localStorage.getItem(SearchSessionCacheService.SESSION);
    return !!raw ? JSON.parse(raw) : null;
  }
}
