import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupGuidePageComponent } from './setup-guide-page/setup-guide-page.component';
import { SetupGuideIndicatorComponent } from './setup-guide-indicator/setup-guide-indicator.component';
import { SetupGuideBottomBarComponent } from './setup-guide-bottom-bar/setup-guide-bottom-bar.component';
import { SetupGuideItemListComponent } from './setup-guide-item-list/setup-guide-item-list.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { SanitizeHtmlPipe } from '../../shared/pipes/sanitize-html.pipe';
import { SetupGuideItemListContentComponent } from './setup-guide-item-list-content/setup-guide-item-list-content.component';
import { GetI18nFromBackendJsonPipe } from '../../shared/pipes/get-i18n-from-backend-json.pipe';
import { SanitizeResourceUrlPipe } from '../../shared/pipes/sanitize-resource-url.pipe';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { ShowOnBreakpointDirective } from '../../shared/directives/show-on-breakpoint.directive';
import { TranslateModule } from '@ngx-translate/core';
import { IntercomOpenDirective } from '../../shared/directives/intercom-open.directive';
import { ReviewBadgesComponent } from '../../shared/components/review-badges/review-badges.component';
import { AlertMultiComponent } from '../../shared/components/alert-multi/alert-multi.component';
import { SetupGuideReviewContentComponent } from './setup-guide-review-content/setup-guide-review-content.component';
import { IsStepCompletedPipe } from './pipe/is-step-completed.pipe';
import { SetupGuideReviewContentImageComponent } from './setup-guide-review-content/setup-guide-review-content-image/setup-guide-review-content-image.component';

@NgModule({
  declarations: [
    SetupGuidePageComponent,
    SetupGuideIndicatorComponent,
    SetupGuideBottomBarComponent,
    SetupGuideItemListComponent,
    SetupGuideItemListContentComponent,
    SetupGuideReviewContentComponent,
    IsStepCompletedPipe,
    SetupGuideReviewContentImageComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressBarModule,
    FlexModule,
    MatExpansionModule,
    MatIconModule,
    SanitizeHtmlPipe,
    GetI18nFromBackendJsonPipe,
    SanitizeResourceUrlPipe,
    MatButtonModule,
    RouterLink,
    ShowOnBreakpointDirective,
    IntercomOpenDirective,
    ReviewBadgesComponent,
    AlertMultiComponent,
    ExtendedModule,
  ],
  exports: [
    SetupGuidePageComponent,
    SetupGuideItemListContentComponent,
    SetupGuideItemListComponent,
    SetupGuideBottomBarComponent,
    SetupGuideIndicatorComponent,
  ],
})
export class SetupGuideModule {}
