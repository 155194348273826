<form
  [formGroup]="supplierPublicInformationService.form"
  fxLayout="column"
  fxLayoutGap="40px"
  fxLayoutGap.lt-md="20px"
  class="w-100p"
>
  <div class="row-group">
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.COMPANY_NAME' | translate }}</div>
      <mat-form-field appearance="fill" class="input-without-padding-except-error select-without-label content">
        <input
          matInput
          [formControlName]="supplierPublicInformationService.inputNames.COMPANY_NAME"
          type="text"
          [placeholder]="'SUPPLIER_PUBLIC_INFORMATION_FORM.COMPANY_NAME' | translate"
        />
        <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.COMPANY_EMAIL' | translate }}</div>
      <mat-form-field appearance="fill" class="input-without-padding-except-error select-without-label content">
        <input
          matInput
          [formControlName]="supplierPublicInformationService.inputNames.COMPANY_EMAIL"
          type="text"
          [placeholder]="'SUPPLIER_PUBLIC_INFORMATION_FORM.COMPANY_EMAIL' | translate"
        />
        <mat-error *ngIf="supplierPublicInformationService.companyEmail.invalid">
          {{
            supplierPublicInformationService.companyEmail.errors?.email
              ? ('FORM_VALIDATORS.EMAIL' | translate)
              : ('FORM_VALIDATORS.REQUIRED' | translate)
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.WEBSITE' | translate }}</div>
      <mat-form-field appearance="fill" class="input-without-padding-except-error select-without-label content">
        <input
          matInput
          [formControlName]="supplierPublicInformationService.inputNames.WEBSITE"
          type="text"
          [placeholder]="'SUPPLIER_PUBLIC_INFORMATION_FORM.WEBSITE' | translate"
        />
        <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-custom-divider></app-custom-divider>
  <div class="row-group">
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.DESCRIPTION' | translate }}</div>
      <div class="content" fxLayout="column" fxLayoutGap="12px">
        <mat-form-field appearance="fill" class="input-without-padding-except-error select-without-label content">
          <textarea
            matInput
            [formControlName]="supplierPublicInformationService.inputNames.DESCRIPTION"
            [placeholder]="'SUPPLIER_PUBLIC_INFORMATION_FORM.DESCRIPTION' | translate"
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
          ></textarea>
          <mat-error *ngIf="!!supplierPublicInformationService.description.errors?.['required']">{{
            'FORM_VALIDATORS.REQUIRED' | translate
          }}</mat-error>
          <mat-error *ngIf="!!supplierPublicInformationService.description.errors?.['minlength']">{{
            'FORM_VALIDATORS.MIN_LENGTH' | translate : { amount: descriptionMinValue }
          }}</mat-error>
          <mat-error *ngIf="!!supplierPublicInformationService.description.errors?.['maxlength']">{{
            'FORM_VALIDATORS.MAX_LENGTH' | translate : { amount: descriptionMaxValue }
          }}</mat-error>
        </mat-form-field>
        <div fxLayout="column" fxLayoutAlign="center end">
          <mat-hint>
            {{ supplierPublicInformationService.description?.value?.length || 0 }} / {{ descriptionMaxValue }}
          </mat-hint>
        </div>
      </div>
    </div>
  </div>
  <app-custom-divider></app-custom-divider>
  <div class="row-group">
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.MAIN_WAREHOUSE' | translate }}</div>
      <mat-form-field
        class="input-without-padding-except-error custom-form-field-wrapper content"
        appearance="standard"
      >
        <app-single-country-selector-form-field
          [label]="'SUPPLIER_PUBLIC_INFORMATION_FORM.MAIN_WAREHOUSE' | translate"
          [formControlName]="supplierPublicInformationService.inputNames.MAIN_WAREHOUSE_LOCATION"
        >
        </app-single-country-selector-form-field>
        <mat-error *ngIf="supplierPublicInformationService.mainWarehouseLocation.hasError('required')">
          {{ 'FORM_VALIDATORS.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.PHONE' | translate }}</div>
      <mat-form-field
        appearance="standard"
        class="input-without-padding-except-error custom-form-field-wrapper phone-form-field-wrapper content"
      >
        <app-phone-input-form-field
          [formControlName]="supplierPublicInformationService.inputNames.PHONE"
        ></app-phone-input-form-field>
        <mat-error *ngIf="!!supplierPublicInformationService.phone.errors">{{
          'FORM_VALIDATORS.REQUIRED' | translate
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-custom-divider></app-custom-divider>
  <div class="row-group">
    <div class="row">
      <div class="title">{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.DATE' | translate }}</div>
      <mat-form-field appearance="fill" class="input-without-padding-except-error select-without-label content">
        <input
          matInput
          type="number"
          min="0"
          [max]="currentYear"
          [formControlName]="supplierPublicInformationService.inputNames.DATE"
          [placeholder]="'SUPPLIER_PUBLIC_INFORMATION_FORM.DATE' | translate"
        />
        <mat-error *ngIf="supplierPublicInformationService.date.errors?.['required']">{{
          'FORM_VALIDATORS.REQUIRED' | translate
        }}</mat-error>
        <mat-error
          *ngIf="supplierPublicInformationService.date.errors?.['min'] || supplierPublicInformationService.date.errors?.['max']"
        >
          {{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.INVALID_DATE' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-custom-divider></app-custom-divider>
  <div class="row-group">
    <div class="row social-row">
      <div class="title" [innerHTML]="'SUPPLIER_PUBLIC_INFORMATION_FORM.SOCIAL.TITLE' | translate"></div>
      <div class="content" fxLayout="column" fxLayoutGap="12px">
        <div
          fxLayout="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-md="12px"
          class="social-platforms"
          [formGroupName]="supplierPublicInformationService.inputNames.SOCIAL_LINKS"
        >
          <mat-form-field
            *ngFor="let socialPlatform of supplierPublicInformationService.socialPlatforms"
            class="social-platform select-without-label input-without-padding-except-error w-100p"
            appearance="fill"
          >
            <input
              matInput
              [formControlName]="socialPlatform.name"
              type="text"
              [placeholder]="socialPlatform.name | titlecase"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
<div
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign="center center"
  fxLayoutAlign.lt-sm="center stretch"
  class="py-40"
>
  <button *ngIf="hasSaveButton" mat-flat-button color="primary" (click)="handleInnerSaveClicked()">
    {{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.SAVE' | translate }}
  </button>
</div>
