<ng-container *ngIf="products; else progress">
  <ng-container *ngIf="!hasError; else errorComponent">
    <div fxLayout="column" fxLayoutGap="32px" class="favorites-page-container">
      <div fxLayout="column" fxLayoutGap="20px" class="product-cards-container">
        <ng-container *ngIf="products.length || noResultsForSearch">
          <app-favorites-page-filter></app-favorites-page-filter>
        </ng-container>
        <app-product-card-list
          [from]="pagination.from"
          [products]="products"
          [numberOfProducts]="numberOfProducts"
          [areProductsLoading]="areProductsLoading"
          [ecomCurrency]="ecomCurrency"
          [generateMicrodata]="true"
        ></app-product-card-list>
        <ng-container *ngIf="products.length === 0 && !areProductsLoading">
          <ng-container [ngTemplateOutlet]="noResultsForSearch ? noSearchResults : noFavorites"> </ng-container>
        </ng-container>
      </div>
      <app-paginator-load-more
        (loadMoreClicked)="handleLoadMore()"
        [totalNumber]="numberOfProducts"
        [fetchedNumber]="products.length"
      ></app-paginator-load-more>
    </div>
  </ng-container>
</ng-container>

<ng-template #noFavorites>
  <div class="w-100p">
    <app-no-products-added></app-no-products-added>
  </div>
</ng-template>

<ng-template #noSearchResults>
  <div class="w-100p">
    <app-favorite-no-results-for-search icon="favorite"></app-favorite-no-results-for-search>
  </div>
</ng-template>

<ng-template #errorComponent>
  <app-error-message></app-error-message>
</ng-template>

<ng-template #progress>
  <div fxLayout="column" fxLayoutAlign="center center" class="py-20">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
