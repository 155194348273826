import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Params, Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { SanitizeHtmlPipe } from 'app/shared/pipes/sanitize-html.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SKIP_FUSE_UPDATE } from '@fuse/services/config.service';

@Component({
  selector: 'app-syncee-cta',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, RouterModule, SanitizeHtmlPipe],
  templateUrl: './syncee-cta.component.html',
  styleUrls: ['./syncee-cta.component.scss'],
})
export class SynceeCtaComponent implements OnInit {
  @Input() title: string;
  @Input() descriptionInnerHTML: string;
  @Input() descriptionTemplateRef: TemplateRef<any>;
  @Input() buttonText: string;
  @Input() redirectURL: string;
  @Input() callback: () => void;
  @Input() queryParams: Params;
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;

  readonly skipFuseUpdate = SKIP_FUSE_UPDATE;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirect(): void {
    this.router.navigate([this.redirectURL], { queryParams: this.queryParams });
  }
}
