<div class="countries-table-container">
  <ng-container *ngIf="!isMobile; else mobileView">
    <mat-card>
      <table
        mat-table
        [dataSource]="dataSource"
        *ngIf="!!dataSource?.length"
        class="countries-table syncee-grey-200-border"
        [trackBy]="null"
      >
        <!-- Location -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>
            <h5>{{ 'SHIPPING_DETAILS.LOCATION' | translate }}</h5>
          </th>
          <td mat-cell *matCellDef="let rate">
            <ng-container [ngTemplateOutlet]="carrierName" [ngTemplateOutletContext]="{ rate: rate }"></ng-container>
          </td>
        </ng-container>

        <!-- Condition -->
        <ng-container matColumnDef="condition">
          <th mat-header-cell *matHeaderCellDef class="centered-text">
            <h5>
              {{ 'SHIPPING_DETAILS.CONDITION' | translate }}
            </h5>
          </th>
          <td mat-cell *matCellDef="let rate" class="centered-text">
            <ng-container
              [ngTemplateOutlet]="condition"
              [ngTemplateOutletContext]="{ rate: rate, supplierCurrency: supplierCurrency }"
            >
            </ng-container>
          </td>
        </ng-container>

        <!-- Price -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef class="centered-text">
            <h5>{{ 'SHIPPING_DETAILS.SHIPPING_COST' | translate }}</h5>
          </th>
          <td mat-cell *matCellDef="let rate" class="centered-text">
            <ng-container
              *ngIf="!(rate?.cost | isNullOrUndefinedOrEmpty)"
              [ngTemplateOutlet]="price"
              [ngTemplateOutletContext]="{ cost: rate?.cost, supplierCurrency: supplierCurrency }"
            ></ng-container>
          </td>
        </ng-container>

        <!-- Incremental fee -->
        <ng-container matColumnDef="incrementalFee">
          <th mat-header-cell *matHeaderCellDef class="centered-text">
            <div class="incremental-fee-header">
              <h5>
                {{ 'SHIPPING_DETAILS.INCREMENTAL_FEE' | translate }}
              </h5>
              <mat-icon [matTooltip]="'SHIPPING_DETAILS.INCREMENTAL_FEE_TOOLTIP' | translate" class="s-20">
                info
              </mat-icon>
            </div>
          </th>
          <td mat-cell *matCellDef="let rate" class="centered-text">
            <span *ngIf="!(rate?.cost | isNullOrUndefinedOrEmpty)">
              {{ rate?.additionalCost | currencyTransform : supplierCurrency | async }}
            </span>
          </td>
        </ng-container>

        <!-- Shipping time -->
        <ng-container matColumnDef="shippingTime">
          <th mat-header-cell *matHeaderCellDef class="centered-text">
            <h5>
              {{ 'SHIPPING_DETAILS.SHIPPING_TIME' | translate }}
            </h5>
          </th>
          <td mat-cell *matCellDef="let rate" class="centered-text">
            <ng-container [ngTemplateOutlet]="shippingTime" [ngTemplateOutletContext]="{ rate: rate }"></ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.multiple-rates]="row.hasNested || row.isNestedElement"
        ></tr></table
    ></mat-card>
  </ng-container>
</div>

<ng-template #mobileView>
  <ng-container *ngFor="let rate of dataSource; let last = last">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="w-100-p">
      <!-- Carrier -->
      <div class="w-100-p text-truncate" [ngClass]="{ 'pt-8': !$any(rate)?.shippingCarrier }">
        <ng-container [ngTemplateOutlet]="carrierName" [ngTemplateOutletContext]="{ rate: rate }"></ng-container>
      </div>

      <!-- Condition -->
      <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!!$any(rate)?.condition">
        <div class="typography-body-1">
          {{ ('STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.CONDITION.TITLE' | translate) + ': ' }}
        </div>
        <ng-container
          [ngTemplateOutlet]="condition"
          [ngTemplateOutletContext]="{ rate: rate, supplierCurrency: supplierCurrency }"
        >
        </ng-container>
      </div>

      <!-- Price -->
      <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!($any(rate)?.cost | isNullOrUndefinedOrEmpty)">
        <div class="typography-body-1">
          {{ ('STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.PRICE' | translate) + ': ' }}
        </div>
        <ng-container
          [ngTemplateOutlet]="price"
          [ngTemplateOutletContext]="{ cost: $any(rate)?.cost, supplierCurrency: supplierCurrency }"
        ></ng-container>
      </div>

      <!-- Incremental fee -->
      <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!($any(rate)?.cost | isNullOrUndefinedOrEmpty)">
        <div class="typography-body-1">
          {{ ('STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.INCREMENTAL_FEE' | translate) + ': ' }}
        </div>
        <span>
          {{
            $any(rate)?.additionalCost
              | currencyExchangeToSelected : supplierCurrency
              | async
              | currencyTransformToSelected
              | async
          }}
        </span>
      </div>

      <!-- Shipping time -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        *ngIf="!!$any(rate)?.minShippingDays && $any(rate)?.maxShippingDays"
      >
        <div class="typography-body-1">
          {{ ('STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.SHIPPING_TIME' | translate) + ': ' }}
        </div>
        <ng-container [ngTemplateOutlet]="shippingTime" [ngTemplateOutletContext]="{ rate: rate }"></ng-container>
      </div>

      <app-custom-divider *ngIf="!last"></app-custom-divider>
    </div>
  </ng-container>
</ng-template>

<ng-template #carrierName let-rate="rate">
  <div>
    <div
      *ngIf="!!rate?.shippingCarrier && rate?.shippingCarrier?.type !== carrierType.GENERAL"
      [ngClass]="{ 'pl-12': !isMobile, h5: isMobile }"
    >
      {{
        rate?.shippingCarrier?.name +
          ' - ' +
          ('STOREFRONT_SETTINGS.TAB.SHIPPING_CARRIER.ADD_EDIT_CARRIER_FORM_DIALOG.OPTIONS.' +
            rate?.shippingCarrier?.type | translate)
      }}
    </div>
    <div
      *ngIf="!!rate?.shippingCarrier && rate?.shippingCarrier?.type === carrierType.GENERAL"
      [ngClass]="{ 'pl-12': !isMobile, h5: isMobile }"
    >
      {{ rate?.shippingCarrier?.name }}
    </div>
    <div *ngIf="!rate?.shippingCarrier" [ngClass]="{ h4: isMobile }">
      {{ 'COUNTRIES.' + rate | translate }}
    </div>
  </div>
</ng-template>

<ng-template #condition let-rate="rate" let-currency="currency">
  <ng-container>
    <span *ngIf="rate?.condition?.type === rateTypes.PRICE">
      <span *ngIf="rate?.condition?.maxValue > 0">
        {{
          'STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.CONDITION.MIN_TO_MAX'
            | translate
              : {
                  min:
                    (rate?.condition?.minValue
                    | currencyExchangeToSelected : supplierCurrency
                    | async
                    | currencyTransformToSelected
                    | async),
                  max:
                    (rate?.condition?.maxValue
                    | currencyExchangeToSelected : supplierCurrency
                    | async
                    | currencyTransformToSelected
                    | async)
                }
        }}
      </span>
      <span *ngIf="!rate?.condition?.maxValue">
        {{
          'STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.CONDITION.MIN_AND_UP'
            | translate
              : {
                  min:
                    rate?.condition?.minValue
                    | currencyExchangeToSelected : supplierCurrency
                    | async
                    | currencyTransformToSelected
                    | async
                }
        }}
      </span>
    </span>
    <span *ngIf="rate?.condition?.type === rateTypes.WEIGHT">
      <span *ngIf="rate?.condition?.maxValue > 0">
        {{
          'STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.CONDITION.MIN_TO_MAX'
            | translate
              : {
                  min: (+rate?.condition?.minValue | convertToUserWeightUnit | async),
                  max: (+rate?.condition?.maxValue | convertToUserWeightUnit | async)
                }
        }}
      </span>
      <span *ngIf="!rate?.condition?.maxValue">
        {{
          'STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.CONDITION.MIN_AND_UP'
            | translate : { min: (+rate?.condition?.minValue | convertToUserWeightUnit | async) }
        }}
      </span>
    </span>
  </ng-container>
</ng-template>

<ng-template #price let-cost="cost" let-currency="currency">
  <ng-container>
    <span *ngIf="cost > 0">
      {{ cost | currencyExchangeToSelected : supplierCurrency | async | currencyTransformToSelected | async }}
    </span>
    <span *ngIf="!cost || cost === 0">
      {{ 'STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.FREE' | translate }}
    </span>
  </ng-container>
</ng-template>

<ng-template #shippingTime let-rate="rate">
  <span *ngIf="!!rate?.minShippingDays && rate?.maxShippingDays">
    {{
      rate?.minShippingDays +
        ' - ' +
        rate?.maxShippingDays +
        ' ' +
        ('STOREFRONT_SETTINGS.TAB.SHIPPING.PROFILE_EDITOR.RATES.DAYS' | translate)
    }}
  </span>
</ng-template>
