import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { RestResponse, RestResponseWithPagination } from './rest-response';
import { Observable, pipe, UnaryFunction } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { RequestError } from './request-error';
import { NotificationService } from '../../main/notification/notification.service';
import { FetchRestResponse } from './FetchRestResponse';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private notificationService: NotificationService
  ) {}

  public get<T = any>(url: string, params?: HttpParams | any): Observable<RestResponse<T>> {
    return this.http.get<T>(env.restURL + url, { params: params }).pipe(this.processResponse<T>());
  }

  public getWithPagination<T>(url: string, params?: HttpParams | any): Observable<RestResponseWithPagination<T>> {
    return this.http.get<any>(env.restURL + url, { params: params }).pipe(this.processResponseWithPagination<T>());
  }

  public post(url: string, body: any, options: RequestOptions = {}): Observable<RestResponse> {
    return this.http.post<any>(env.restURL + url, body, options).pipe(this.processResponse());
  }

  public put(url: string, body: any, options: RequestOptions = {}): Observable<RestResponse> {
    return this.http.put<any>(env.restURL + url, body, options).pipe(this.processResponse());
  }

  public delete(url: string, body?: any): Observable<RestResponse> {
    const options = {
      body: body,
    };
    return this.http.request('delete', env.restURL + url, options).pipe(this.processResponse());
  }

  private processResponse<T>(): UnaryFunction<Observable<any>, Observable<RestResponse<T>>> {
    return pipe(
      map((value) => RestResponse.create<T>(value)),
      tap((response) => this.checkResponse(response, this.notificationService)),
      catchError((err: any) => this.handleError(err))
    );
  }

  private processResponseWithPagination<T>(): UnaryFunction<
    Observable<any>,
    Observable<RestResponseWithPagination<T>>
  > {
    return pipe(
      map((value) => RestResponseWithPagination.create<T>(value)),
      tap((response) => this.checkResponse(response, this.notificationService)),
      catchError((err: any) => this.handleError(err))
    );
  }

  private checkResponse(response: RestResponse, notService): void {
    if (!response.isSuccess()) {
      throw response;
    }
    if (response.isWarning()) {
      notService.warning(response.warning);
    }
  }

  public throwErrorIfNotSuccessOperator<T>(): (source: Observable<RestResponse<T>>) => Observable<RestResponse<T>> {
    return (source: Observable<RestResponse<T>>): Observable<RestResponse<T>> =>
      source.pipe(
        map((response) => {
          if (response.isSuccess()) {
            return response;
          } else {
            throw new Error(response?.errorMessage);
          }
        })
      );
  }

  public getWithClass(url: string, params?: HttpParams): Observable<FetchRestResponse> {
    return this.http.get<any>(env.restURL + url, { params: params }).pipe(this.processResponseWithClass());
  }

  public postWithClass(url: string, body: any, options: RequestOptions = {}): Observable<FetchRestResponse> {
    return this.http.post<any>(env.restURL + url, body, options).pipe(this.processResponseWithClass());
  }

  private processResponseWithClass(): UnaryFunction<Observable<any>, Observable<FetchRestResponse>> {
    return pipe(
      map(FetchRestResponse.create),
      tap(this.checkResponseWithClass),
      catchError((err: any) => this.handleError(err))
    );
  }

  private checkResponseWithClass(response: FetchRestResponse): void {
    if (!response.isSuccess()) {
      throw response;
    }
  }

  private handleError(err: any): never {
    this.logger.error(err);
    if (
      !Utils.isNullOrUndefined(err.errorMessage) &&
      !ERROR_CODES_EXCLUDED_FROM_ERROR_RESPONSE.includes(err.errorCode)
    ) {
      this.notificationService.error(err.errorMessage);
    }
    throw RequestError.create(err) || err;
  }
}

export interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export const ERROR_CODES_EXCLUDED_FROM_ERROR_RESPONSE = ['NO_ALIVE_NODE'];
