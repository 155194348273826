import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-simple-paginator-with-arrows',
  templateUrl: './simple-paginator-with-arrows.component.html',
  styleUrls: ['./simple-paginator-with-arrows.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    FlexLayoutModule,
    MatFormFieldModule,
  ],
})
export class SimplePaginatorWithArrowsComponent implements OnInit, OnChanges {
  @Input() allItem: number;
  @Input() page: number;
  @Input() size: number;
  @Input() currentlyShownItem: number;
  @Input() hasFullBorder = true;
  @Input() hasBorder = true;
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
  @Output() sizeChange = new EventEmitter<number>();

  @Input() sizes: number[] = [10, 25, 50, 100];
  @Input() hasSizeSelector = false;

  isPreviousDisabled = true;
  isNextDisabled = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isPreviousDisabled = this.page === 0;
    this.isNextDisabled = this.page * this.size + this.currentlyShownItem >= this.allItem;
  }

  sizeChangeHandler($event): void {
    this.sizeChange.emit($event.value);
  }
}
