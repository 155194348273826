import { Component, OnInit } from '@angular/core';
import { TOOLBAR_UPPER_ROW_CONFIG_LEFT, TOOLBAR_UPPER_ROW_CONFIG_RIGHT } from './upper-row.config';

@Component({
  selector: 'app-upper-row',
  templateUrl: './upper-row.component.html',
  styleUrls: ['./upper-row.component.scss'],
})
export class UpperRowComponent implements OnInit {
  itemsRight = TOOLBAR_UPPER_ROW_CONFIG_RIGHT;
  itemsLeft = TOOLBAR_UPPER_ROW_CONFIG_LEFT;

  constructor() {}

  ngOnInit(): void {}
}
