import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CatalogDetails } from '../../../main/retailer-import-list/model/catalog-details';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { CatalogService, ImportListCatalogRespDto } from 'app/service/catalog/catalog.service';
import { ActivatedRoute } from '@angular/router';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { RolesEnum } from 'app/vo/roles/roles';
import { PricingRange, PricingRow, PricingService } from 'app/service/pricing/pricing.service';
import { PricingVO } from 'app/vo/pricing/pricing-vo';
import { ConditionLoadingDirective } from 'app/shared/directives/condition-loading.directive';
import { FlexModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { CustomPanelComponent } from '../custom-panel/custom-panel.component';
import { ImportListPriceSettingsModule } from '../import-list-price-settings/import-list-price-settings.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RetailerImportListPricingService } from 'app/main/retailer-import-list/tab-items/retailer-import-list-pricing/retailer-import-list-pricing.service';
import { ImportListCollection } from '../import-list-price-settings/model/import-list-collection';
import { ImportListPrice } from '../import-list-price-settings/model/import-list-price';
import { ComponentHeaderComponent } from '../component-header/component-header.component';
import { NotificationService } from 'app/main/notification/notification.service';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { ExportPricingService } from '../../../service/export-pricing/export-pricing.service';
import { ImportListPriceItem } from '../import-list-price-settings/model/import-list-price-item';
import { ExportTaskwizardService } from 'app/service/taskwizard/export-taskwizard.service';
import { PriceType } from '../../../main/taskwizard/supplier-task-pricing/enums/price-type.enum';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-retailer-pricing',
  templateUrl: './retailer-pricing.component.html',
  styleUrls: ['./retailer-pricing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ConditionLoadingDirective,
    FlexModule,
    CustomPanelComponent,
    ImportListPriceSettingsModule,
    ComponentHeaderComponent,
    ErrorMessageComponent,
  ],
  providers: [RetailerImportListPricingService],
})
export class RetailerPricingComponent implements OnInit, OnDestroy {
  @Input() type: 'IMPORT' | 'EXPORT' = 'IMPORT';
  @Input() task_id: number;
  @Input() rcatalog_id: number;

  catalogDetails: CatalogDetails;
  ecomCurrency = 'USD';
  isCatalogLoading = true;
  isPricingLoading = true;
  isRoundingLoading = true;
  error = false;
  selectedRounding: string;
  selectedPricing: ImportListPrice;
  pricingVO: PricingVO;
  collections: ImportListCollection[];
  pricing: ImportListPrice[];
  roundingRule: string;
  pricingRanges: string;
  taskId: number;
  priceType = PriceType.RETAILER_DATAFEED;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private store: Store<AppState>,
    private catalogService: CatalogService,
    private route: ActivatedRoute,
    private pricingService: PricingService,
    private exportPricingService: ExportPricingService,
    private etwService: ExportTaskwizardService,
    private retailerImportListPricingService: RetailerImportListPricingService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getEcomCurrency();
    this.init();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private init(): void {
    if (this.type === 'IMPORT') {
      this.getRCatalogDetails();
    } else if (this.type === 'EXPORT') {
      this.getEtwTaskDetails();
    }
  }

  private getDFCatalogs(): Observable<DFCatalogsResp[]> {
    return this.catalogService.getMyDataFeedCatalogs();
  }

  private roundingDetails$(id: number): Observable<string> {
    return this.pricingService.getRoundingByCatalogId(id, 'RETAILER').pipe(map((rounding) => rounding.rounding));
  }

  private pricingDetails$(id: number): Observable<[ImportListCollection[], ImportListPrice[]]> {
    return this.retailerImportListPricingService.fetchPricingRelatedData(id);
  }

  private etwPricingDetails$(id: number): Observable<ImportListPrice[]> {
    return this.exportPricingService.getPricing(id).pipe(
      map((pricing) => pricing.pricing),
      map((pricing) => this.mapPricing(pricing))
    );
  }

  private etwRoundingDetails$(id: number): Observable<string> {
    return this.etwService.getTaskRounding(id);
  }

  private queryParamsRouteId$(): Observable<number> {
    if (!!this.task_id) {
      return of(this.task_id);
    }
    return this.route.queryParams.pipe(
      take(1),
      map((value) => {
        return parseInt(value.task_id, 10);
      }),
      tap((value) => (this.taskId = value))
    );
  }

  private dfCatalogId$(id: number): Observable<number> {
    if (!!this.rcatalog_id) {
      return of(this.rcatalog_id);
    }
    return this.getDFCatalogs().pipe(
      map((dfCatalogs) => dfCatalogs.find((dfCatalog) => dfCatalog.catalog.task_id === id.toString())?.catalog.id),
      tap((catalog) => this.handleError(!catalog)),
      filter((catalog) => !!catalog)
    );
  }

  private getRCatalogDetails(): void {
    this.queryParamsRouteId$()
      .pipe(
        mergeMap((taskId) =>
          this.dfCatalogId$(taskId).pipe(
            mergeMap((retailerCatalogId) =>
              this.catalogService
                .getRetailerCatalogById(retailerCatalogId)
                .pipe(tap((rcatalogId) => this.handleError(!rcatalogId)))
            ),
            tap((catalogDetails: CatalogDetails) => {
              this.catalogDetails = catalogDetails;
              this.isCatalogLoading = false;
            }),
            mergeMap(() =>
              combineLatest([
                this.pricingDetails$(parseInt(this.catalogDetails.id, 10)),
                this.roundingDetails$(parseInt(this.catalogDetails.id, 10)),
              ])
            ),
            takeUntil(this._unsubscribeAll),
            tap(([[collections, pricing], rounding]) => {
              this.collections = collections;
              this.pricing = pricing;
              this.isPricingLoading = false;
              this.roundingRule = rounding;
              this.isRoundingLoading = false;
            })
          )
        )
      )
      .subscribe();
  }

  private getEtwTaskDetails(): void {
    this.queryParamsRouteId$()
      .pipe(
        mergeMap((taskId) => combineLatest([this.etwPricingDetails$(taskId), this.etwRoundingDetails$(taskId)])),
        takeUntil(this._unsubscribeAll),
        tap(([pricing, rounding]) => {
          this.isCatalogLoading = false;
          this.collections = undefined;
          this.pricing = pricing;
          this.isPricingLoading = false;
          this.roundingRule = rounding;
          this.isRoundingLoading = false;
        })
      )
      .subscribe();
  }

  private getEcomCurrency(): void {
    this.store
      .select(getSelectedEcomByRole(RolesEnum.RETAILER))
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((data) => !!data)
      )
      .subscribe((res) => (this.ecomCurrency = res.options.currency));
  }

  setPricing(importListPrice: ImportListPrice): void {
    this.selectedPricing = importListPrice;
    this.updatePricingWithCurrent(importListPrice);
  }

  setRounding(roundingRule: string): void {
    this.selectedRounding = roundingRule;
  }

  updatePricingWithCurrent(importListPrice: ImportListPrice): void {
    const index = this.pricing.findIndex((pricing) => pricing.collectionId === importListPrice.collectionId);
    if (!Utils.isNullOrUndefined(index) && index > -1) {
      this.pricing[index] = importListPrice;
    } else {
      this.pricing.push(importListPrice);
    }
  }

  reducePricing(importListPrice: ImportListPrice[]): PricingVO {
    const pricing = importListPrice.reduce((acc, price) => {
      const pricingRange = price.priceItemList.reduce((acc2, priceItem) => {
        acc2[priceItem.from] = {
          perc: priceItem.percAmountOperator * priceItem.percentage,
          fix: priceItem.fixAmountOperator * priceItem.fixAmount,
        };
        return acc2;
      }, {});
      acc[price.collectionId] = pricingRange;
      return acc;
    }, {});

    return pricing;
  }

  savePricing(importListPrice: ImportListPrice[]): Observable<void> {
    return this.pricingService
      .updatePricing(
        { catalog_id: parseInt(this.catalogDetails.id, 10), pricing: this.reducePricing(importListPrice) },
        'RETAILER'
      )
      .pipe(
        tap(() => {
          this.notificationService.success(this.translateService.instant('RETAILER_CATALOG_DETAILS.PRICING_SAVED'));
        })
      );
  }

  saveRounding(roundingRule: string): Observable<void> {
    return this.pricingService
      .updateRounding({ id: parseInt(this.catalogDetails.id, 10), rounding: roundingRule }, 'RETAILER')
      .pipe(
        tap(() => {
          this.notificationService.success(this.translateService.instant('RETAILER_CATALOG_DETAILS.ROUNDING_SAVED'));
        })
      );
  }

  saveStep(): Observable<void> {
    if (this.type === 'IMPORT') {
      return this.savePricing(this.pricing).pipe(
        switchMap(() => {
          if (this.selectedRounding) {
            return this.saveRounding(this.selectedRounding);
          }
          return of(null);
        })
      );
    } else if (this.type === 'EXPORT') {
      return this.exportPricingService.updateExportPricing(
        this.reducePricing(this.pricing),
        this.selectedRounding ? this.selectedRounding : 'none',
        this.taskId
      );
    }
  }

  private handleError(condition: boolean): void {
    this.error = condition;
    this.isPricingLoading = !condition;
    this.isRoundingLoading = !condition;
  }

  public isFormInvalid(): boolean {
    return false;
  }

  public isStepValid(): boolean {
    return true;
  }

  private mapPricing(pricing: PricingRow): ImportListPrice[] {
    const mappedPricing: ImportListPrice[] = Object.keys(pricing).map((key) => {
      const mappedPricingRows: ImportListPriceItem[] = [];
      const pricingRules = Object.keys(pricing[key]);
      pricingRules.forEach((rangeKey, rangeIndex) => {
        const pricingRange: PricingRange = pricing[key][rangeKey];
        const mappedPricingRow: ImportListPriceItem = {
          from: parseInt(rangeKey, 10),
          to: pricingRules[rangeIndex + 1] ? parseInt(pricingRules[rangeIndex + 1], 10) : null,
          percAmountOperator: pricingRange.perc >= 0 ? 1 : -1,
          percentage: Math.abs(pricingRange.perc),
          fixAmountOperator: pricingRange.fix >= 0 ? 1 : -1,
          fixAmount: Math.abs(pricingRange.fix),
        };

        mappedPricingRows.push(mappedPricingRow);
      });

      return {
        collectionId: key,
        priceItemList: mappedPricingRows,
      };
    });

    return mappedPricing;
  }
}

export interface DFCatalogsResp {
  catalog: ImportListCatalogRespDto;
}
