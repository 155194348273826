<div fxLayout="column" fxLayoutGap="12px">
  <mat-card fxLayout="column">
    <ng-container *ngIf="!!categoryItems.length; else noResults">
      <ng-container *ngFor="let item of categoryItems; let isLast = last">
        <div fxLayout="row">
          <div class="p-12 w-100p">
            <div fxLayout="row" fxLayoutGap="16px">
              <mat-checkbox
                *ngIf="headerService.isBulkEditOn | async"
                [checked]="item.checked"
                (change)="listService.toggleSingleItemChecked(item.id, $event.checked)"
              ></mat-checkbox>
              <div fxLayout="column" fxLayoutGap="4px">
                <h5>{{ item.name }}</h5>
                <a (click)="onShowProductsClick(item)">{{ 'CATEGORY_MAPPING_COMPONENT.SHOW_PRODUCTS' | translate }}</a>
              </div>
            </div>
          </div>
          <app-custom-divider orientation="vertical"></app-custom-divider>
          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="space-between start"
            fxLayoutGap="12px"
            class="p-12 w-100p"
          >
            <mat-chip-list>
              <mat-chip *ngFor="let mappedCategory of item.mappedCategories || []" class="grey-chip"
                >{{ mappedCategory.name }}
                <button matChipRemove (click)="onRemoveClick(item.id, mappedCategory.id)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-list>
            <div fxFlexAlign.lt-md="end">
              <button
                mat-icon-button
                class="syncee-light-blue-button add-category-button"
                (click)="addMapping.emit(item)"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <app-custom-divider *ngIf="!isLast"></app-custom-divider>
      </ng-container>
    </ng-container>
  </mat-card>
</div>

<ng-template #noResults>
  <div class="w-100p p-20 centered" fxFlex="1 0 0">{{ 'CATEGORY_MAPPING_COMPONENT.NO_RESULTS' | translate }}</div>
</ng-template>
