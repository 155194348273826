import { AiChatFunctionCall } from './ai-chat-function-call';

export interface AiChatRequestMessage {
  role: AiChatRole;
  parts: AiChatFunctionCall[];
}

export enum AiChatRole {
  USER = 'user',
  MODEL = 'model',
}
