import { Component, Input, OnInit } from '@angular/core';
import {
  CallToAction,
  CallToActionPosition,
  ToolbarCallToActionContent,
  ToolbarCallToActionService,
} from './toolbar-call-to-action.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { fuseAnimations } from '../../../../../../@fuse/animations';

@Component({
  selector: 'app-toolbar-call-to-action',
  templateUrl: './toolbar-call-to-action.component.html',
  styleUrls: ['./toolbar-call-to-action.component.scss'],
  animations: [fuseAnimations],
})
export class ToolbarCallToActionComponent implements OnInit {
  @Input() position: CallToActionPosition;
  contents$: Observable<ToolbarCallToActionContent[]>;

  public config: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  constructor(private toolbarCallToActionService: ToolbarCallToActionService, private router: Router) {}

  ngOnInit(): void {
    this.contents$ = this.toolbarCallToActionService.getHighestPriorityContentsWithPosition(this.position);
  }

  handleActionClick(action: CallToAction): void {
    switch (action.type) {
      case 'callback': {
        action.callback();
        break;
      }
      case 'external-navigation': {
        window.open(action.url, '_blank');
        break;
      }
      case 'inner-navigation': {
        this.router.navigate([action.url], { queryParams: action.queryParams });
        break;
      }
      case 'non-click': {
      }
    }
  }
}
