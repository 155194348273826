import { MonoTypeOperatorFunction, pipe, retry, throwError, timer } from 'rxjs';

/**
 * Exponential backoff retry operator
 *
 * @param {number} delay
 * Specifies the starting delay value
 *
 * @param {number} maxDelay
 * Specifies the maximum delay value
 *
 * @param {number} [maxAttempts=Infinity]
 * Specifies the maximum retry attempt count, reaching this will cause the operator to stop retrying and throw an error,
 * if not set it will keep retrying indefinitely with the maximum delay value.
 */
export function backoff<T>(
  delay: number,
  maxDelay: number,
  maxAttempts: number = Infinity
): MonoTypeOperatorFunction<T> {
  return pipe(
    retry({
      resetOnSuccess: true,
      delay: (_error, retryCount) => {
        const backoffDelay = Math.min(delay * Math.pow(2, retryCount - 1), maxDelay);

        if (backoffDelay >= maxDelay && retryCount >= maxAttempts) {
          return throwError(() => new Error(_error));
        }

        return timer(backoffDelay);
      },
    })
  );
}
