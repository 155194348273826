import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FieldSelectorComponent, MappingField, SettingsConsts } from './field-selector/field-selector.component';
import { StepBase } from '../../taskwizard/step-base';
import { Observable } from 'rxjs';
import { ExportTaskwizardUpdateService } from '../../../service/taskwizard/export-taskwizard-update.service';
import { ExportTaskwizardService } from '../../../service/taskwizard/export-taskwizard.service';
import { LoadingScreenService } from '../../../service/loading-screen/loading-screen.service';
import { Utils } from 'app/utils/utils';

export interface Variant {
  price: number;
  compare_price: number;
  option1: string;
  option2: string;
  option3: string;
  weight: number;
}

export interface RawData {
  name: string;
  position: number;
  symbol: string;
  variants: Variant[];
}

export interface PeriodicElement {
  name: string;
  position: string;
  symbol: string;
  price?: string;
  compare_price?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  weight?: string;
}

export interface ETSettings {
  exportType?: string;
}

@Component({
  selector: 'app-new-field-mapping',
  templateUrl: './new-field-mapping.component.html',
  styleUrls: ['./new-field-mapping.component.scss'],
})
export class NewFieldMappingComponent implements OnInit, StepBase, OnDestroy {
  @ViewChild('table', { static: false }) table: MatTable<any>;
  @Input() ecomId: number;
  @Input() taskId: number;

  columns: MappingField[] = [];

  displayedColumns: string[] = [];
  dataSource = null;

  previousIndex: number;

  mappingFieldConfig: any[] = null;

  mappingHidden = true;

  settings: ETSettings = <ETSettings>{};
  timeoutId = null;

  constructor(
    private dialog: MatDialog,
    private etwUpdateService: ExportTaskwizardUpdateService,
    private eTaskWService: ExportTaskwizardService,
    private _loadingService: LoadingScreenService
  ) {}

  ngOnInit(): void {
    this.initForUpdate();
    this.init();
    this.setDisplayedColumns();
  }

  initForUpdate(): void {
    if (!this.etwUpdateService.isUpdate) {
      return;
    }
    if (!Utils.isNullOrUndefined(this.etwUpdateService.data.task.settings)) {
      this.settings = <ETSettings>this.etwUpdateService.data.task.settings;
    }
    if (this.etwUpdateService.data.isMapping()) {
      this.columns = this.etwUpdateService.data.mapping.fieldMappingElements;
      this.getPreview();
    }
  }

  init(): void {
    this.eTaskWService.getFieldsConfig().subscribe((fieldsConfig) => {
      this.mappingFieldConfig = fieldsConfig;
    });
  }

  setDisplayedColumns(): void {
    this.displayedColumns = [];
    this.columns.forEach((value, index) => {
      this.displayedColumns[index] = this.getColumnUniqueKey(value);
    });
  }

  dropListDropped(event: CdkDragDrop<MappingField>): void {
    if (event) {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.setDisplayedColumns();
    }
  }

  // this function is piece of shit, I KNOW!!!
  openFieldSelector(pColumn?): void {
    let columnParam = null;
    if (!Utils.isNullOrUndefined(pColumn)) {
      columnParam = this.fieldMappingDeepCopy(pColumn);
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '90%';
    dialogConfig.width = '1028px';
    dialogConfig.data = { mappingField: columnParam, mappingFieldConfig: this.mappingFieldConfig };
    dialogConfig.panelClass = 'overflow-auto-modal';
    const dialogRef = this.dialog.open(FieldSelectorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: MappingField) => {
      if (Utils.isNullOrUndefined(result)) {
        return;
      }

      this.setColumnKeyName(result);
      let oldCol = null;
      if (!Utils.isNullOrUndefined(pColumn)) {
        oldCol = this.fieldMappingDeepCopy(pColumn);
      }
      this.handleFieldSelectorResult(oldCol, result);

      this.getPreview(false);
    });
  }

  getPreview(pooling = true): void {
    this._loadingService.loading = true;
    this.eTaskWService.getPreview(this.columns, this.ecomId).subscribe(
      (data) => {
        if (pooling && !Utils.isNullOrUndefined(data) && (Utils.isNullOrUndefined(data.rows) || data.rows.length < 1)) {
          this.timeoutId = setTimeout(() => {
            this.getPreview();
          }, 5000);
        } else {
          this._loadingService.loading = false;
          this.handlePreviewData(data);
        }
      },
      () => {
        if (!Utils.isNullOrUndefined(this.timeoutId)) {
          clearTimeout(this.timeoutId);
        }
        this._loadingService.startLoading(false);
      }
    );
  }

  fieldMappingDeepCopy(field: MappingField): any {
    const col = Object.assign({}, field);
    col.settings = Object.assign({}, field.settings);
    return col;
  }

  handleFieldSelectorResult(oldCol: MappingField, newCol: MappingField): void {
    if (Utils.isNullOrUndefined(oldCol)) {
      this.columns.push(newCol);
      return;
    }

    if (
      oldCol.settings.fieldDirection === SettingsConsts.DIRECTION_FLAT &&
      newCol.settings.fieldDirection === SettingsConsts.DIRECTION_RAW
    ) {
      this.columns = this.columns.filter((col) => {
        return col.keyName !== oldCol.keyName;
      });
      newCol.displayName = newCol.originalName;
      newCol.customDisplayName = newCol.displayName;
      newCol.operations = oldCol.operations;
      this.columns.push(newCol);
      return;
    }

    this.columns.forEach((col) => {
      if (col.keyName === oldCol.keyName) {
        const originalName = col.originalName;
        col.keyName = newCol.keyName;
        col.originalName = newCol.originalName;
        col.fields = newCol.fields;
        col.settings = newCol.settings;
        col.operations = newCol.operations;
        if (col.settings.fieldDirection === SettingsConsts.DIRECTION_FLAT) {
          const displayName = col.displayName;
          const customDisplayName = col.customDisplayName;
          const matches = col.displayName.match(originalName + '_([0-9]*)');
          if (!Utils.isNullOrUndefined(matches) && matches.length > 1) {
            const origLastIndex = parseInt(matches[1], 10);
            col.displayName = col.originalName + '_' + origLastIndex;
            if (displayName === customDisplayName) {
              col.customDisplayName = col.displayName;
            }
          }
        } else {
          col.displayName = col.originalName;
        }
      }
    });
  }

  setColumnKeyName(mField: MappingField): void {
    const newOriginalName = mField.originalName;
    let origLastIndex = 0;
    let isNameAlreadyExists = false;
    this.columns.forEach((col) => {
      const matches = (col.keyName || '').match(newOriginalName + '_([0-9]*)');
      if (!Utils.isNullOrUndefined(matches) && matches.length > 1) {
        const tempLastIndex = parseInt(matches[1], 10);
        if (tempLastIndex > origLastIndex) {
          origLastIndex = tempLastIndex;
        }
        isNameAlreadyExists = true;
      }
      if (col.originalName === newOriginalName) {
        isNameAlreadyExists = true;
      }
    });
    if (isNameAlreadyExists) {
      mField.keyName = newOriginalName + '_' + (origLastIndex + 1);
    } else {
      mField.keyName = newOriginalName;
    }
  }

  handlePreviewData(data): void {
    this.columns = data.columns as MappingField[];
    this.dataSource = new MatTableDataSource<any>(data.rows);
    this.setDisplayedColumns();
  }

  createRequestParams(columns: MappingField[]): any[] {
    const uniqueCols = [];
    const paramCols = [];
    columns.forEach((col) => {
      if (uniqueCols.includes(col.originalName)) {
        return;
      }
      uniqueCols.push(col.originalName);
      const copy = Object.assign({}, col);
      copy.displayName = copy.originalName;
      paramCols.push(copy);
    });
    return paramCols;
  }

  addNewCol(): void {
    this.openFieldSelector();
  }

  edit(column: any): void {
    this.openFieldSelector(column);
  }

  deleteCol(column: MappingField): void {
    this.columns = this.columns.filter((col) => {
      return col.originalName !== column.originalName;
    });
    if (this.columns.length < 1) {
      this.setDisplayedColumns();
      this.dataSource = null;
      return;
    }
    this.setDisplayedColumns();
    this.eTaskWService.getPreview(this.columns, this.ecomId).subscribe((data) => {
      this.dataSource = new MatTableDataSource<any>(data.rows);
    });
  }

  getDisplayName(column: MappingField): string {
    return column.customDisplayName ? column.customDisplayName : column.displayName;
  }

  getColumnDef(column: MappingField): string {
    let fieldsNamesStr = '';
    column.fields.forEach((field) => {
      fieldsNamesStr += field.name;
    });

    return this.getColumnUniqueKey(column);
  }

  getColumnUniqueKey(column: MappingField): string {
    let fieldsNamesStr = '';
    column.fields.forEach((field) => {
      fieldsNamesStr += field.name;
    });

    return column.keyName + '_' + column.displayName + '_' + column.customDisplayName + '_' + fieldsNamesStr;
  }

  isStepValid(): boolean {
    return true;
  }

  saveStep(): Observable<any> {
    return this.eTaskWService.saveMapping(this.taskId, this.columns);
  }

  ngOnDestroy(): void {
    if (!Utils.isNullOrUndefined(this.timeoutId)) {
      clearTimeout(this.timeoutId);
    }
  }
}
