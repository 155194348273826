import { AfterViewInit, Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Subject } from 'rxjs';
import { getCurrentUserSelector } from '../../store/user/user.selector';
import { switchMap, takeUntil } from 'rxjs/operators';
import { isUserAuthenticatedByTeamMemberSelector } from 'app/store/authentication/authentication.selector';

@Directive({
  selector: '[isLoggedInByTeamMember]',
  standalone: true,
})
export class IsLoggedInByTeamMemberDirective implements AfterViewInit, OnDestroy {
  private unsubscribeAll: Subject<void> = new Subject<void>();
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>
  ) {}

  ngAfterViewInit(): void {
    this.createLoggedInByTeamMemberSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private createLoggedInByTeamMemberSubscription(): void {
    this.store
      .select(getCurrentUserSelector)
      .pipe(
        takeUntil(this.unsubscribeAll),
        switchMap(() => this.store.select(isUserAuthenticatedByTeamMemberSelector))
      )
      .subscribe((isLoggedIn) => this.handleIsLoggedInChange(isLoggedIn));
  }

  private handleIsLoggedInChange(isLoggedIn: boolean): void {
    if (isLoggedIn && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isLoggedIn && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
