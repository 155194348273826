import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagedProductNumber } from '../model/managed-product-number';
import { MySuppliersCacheService } from '../service/my-suppliers-cache.service';
@Injectable()
@Pipe({
  name: 'getManagedProductNumberFromCacheByCatalogId',
})
export class GetManagedProductNumberFromCacheByCatalogIdPipe implements PipeTransform {
  constructor(private cacheService: MySuppliersCacheService) {}
  transform(catalogID: number): Observable<ManagedProductNumber> {
    return this.cacheService.getManagedProductNumberFromCache(catalogID);
  }
}
