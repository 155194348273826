import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, AuthorizationEcomService } from '../../../../service/authorization-ecom/authorization-ecom.service';
import { ChatService } from 'app/service/chat/chat.service';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { MarketplaceEcomService } from 'app/service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { iif, Observable, of, switchMap } from 'rxjs';
import { ChatOptions } from '../chat.component';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { getUserRoleSelector, hasRolesSelector } from '../../../../store/user/user.selector';
import { ROLE_TYPE, RolesEnum } from '../../../../vo/roles/roles';
import { filter } from 'rxjs/operators';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { Utils } from 'app/utils/utils';
import { LayoutService } from '../../../../layout/layout.service';
import { ChatUser } from '../../../../service/chat/model/chat-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';

@UntilDestroy()
@Component({
  selector: 'app-chat-app-container',
  templateUrl: './chat-app-container.component.html',
  styleUrls: ['./chat-app-container.component.scss'],
})
export class ChatAppContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('mobileView') mobileView: TemplateRef<any>;
  currUser: Observable<ChatUser>;
  type: string;
  ecom: EcomVO;
  isMobile = false;
  options: ChatOptions = { hideChannelSide: false, hideDirectMessageSide: false, innerChat: false };
  hasPermission$: Observable<boolean>;
  role$: Observable<ROLE_TYPE>;

  constructor(
    private chatService: ChatService,
    public authorizationService: AuthorizationEcomService,
    public marketplaceEcomService: MarketplaceEcomService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private fuseConfigService: FuseConfigService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    public layoutService: LayoutService
  ) {
    this.aRoute.params.subscribe((params) => {
      if (params['type'] && params['type'] === 'supplier') {
        // this.options.hideDirectMessageSide = true;
      }
    });
    this.isMobile = this.chatService.isMobile();
    this.currUser = this.chatService.getCurrUser().pipe(filter((chatUser) => !!chatUser));
    this.role$ = this.userRole;
    this.hasPermission$ = this.hasPermission;
    this.initTheme();
  }

  ngOnInit(): void {
    if (this.options.hideDirectMessageSide) {
      return;
    }

    if (this.marketplaceEcomService.selectedEcom) {
      this.ecom = this.marketplaceEcomService.selectedEcom;
      return;
    }

    this.handlePlanLoaded();
    this.initDomainLoad();
  }

  private get userRole(): Observable<ROLE_TYPE> {
    return this.store.select(getUserRoleSelector).pipe(untilDestroyed(this), omitNullOrUndefined());
  }

  private get isSupplier(): Observable<boolean> {
    return this.store.select(hasRolesSelector, RolesEnum.SUPPLIER).pipe(omitNullOrUndefined());
  }

  private initDomainLoad(): void {
    this.marketplaceEcomService.initialized.subscribe((res) => {
      if (!Utils.isNullOrUndefined(res)) {
        this.marketplaceEcomService.setPlansForSelectedEcom();
      }
    });
  }

  private handlePlanLoaded(): void {
    this.marketplaceEcomService.onPlansLoaded.subscribe((ecom) => {
      this.ecom = ecom;
    });
  }

  ngAfterViewInit(): void {
    if (this.isMobile) {
      this.dialog
        .open(this.mobileView, {
          panelClass: 'custom-modal-container',
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          autoFocus: false,
          restoreFocus: false,
        })
        .afterClosed()
        .subscribe((value) => {
          if (!!value) {
            this.router.navigateByUrl('/');
          }
        });
    }
  }

  private get hasPermission(): Observable<boolean> {
    return this.isSupplier.pipe(
      untilDestroyed(this),
      switchMap((isSupplier) => iif(() => isSupplier, of(true), this.observePermission()))
    );
  }

  private observePermission(): Observable<boolean> {
    return this.authorizationService
      .hasPermissionObs(Action.CHAT_ACCESS)
      .pipe(untilDestroyed(this), omitNullOrUndefined());
  }

  upgradePlanLaterHandle(): void {
    this.router.navigate([RetailerCenterNavigation.DASHBOARD]);
  }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        contentContainer: 'full-width-without-padding',
        fullHeight: true,
        minimizedChat: {
          hidden: true,
        },
        setupGuideButton: { hidden: true },
        toolbar: {
          content: {
            hideTopCallToAction: true,
            hideBottomCallToAction: true,
          },
        },
      },
    };
  }
}
