import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { RouterLink } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-blurred-overlay',
  templateUrl: './blurred-overlay.component.html',
  styleUrls: ['./blurred-overlay.component.scss'],
  imports: [MatIconModule, MatButtonModule, FlexModule, RouterLink, MatTooltipModule, NgIf],
  standalone: true,
})
export class BlurredOverlayComponent implements OnInit {
  @Input()
  blurValue = '6px';

  constructor() {}

  ngOnInit(): void {}
}
