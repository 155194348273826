<div *ngIf="stepLocked && !hasPermissionToEditLockedCatalog" class="pb-20">
  <app-task-wizard-step-locked-warning></app-task-wizard-step-locked-warning>
</div>
<div fxLayout="column" fxLayoutGap="40px">
  <div>{{ 'FILTER.TITLE' | translate }}</div>
  <div fxLayout="column" fxLayoutGap="20px">
    <app-filter
      *conditionLoading="!!fieldStructures?.length"
      (filterItemsErrorRevalidate)="checkAndHandleErrors()"
      [filterStructures]="fieldStructures"
      [(filter)]="filterGroup"
      [filterItemErrors]="filterItemErrors"
      (needAutoComplete)="handleNeedAutoComplete($event)"
      [autocompleteSuggestion]="autocompleteSuggestion"
      (needMoreAutocomplete)="handleNeedMoreAutocomplete($event)"
      [hasNoMoreAutocomplete]="hasNoMoreAutocomplete"
      [locked]="stepLocked && !hasPermissionToEditLockedCatalog"
    ></app-filter>
  </div>
  <ng-container *ngIf="hasVariantFilter">
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="h3">{{ 'FILTER.VARIANT' | translate }}</div>
      <app-filter
        *conditionLoading="!!variantFieldStructures?.length"
        (filterItemsErrorRevalidate)="checkAndHandleVariantErrors()"
        [filterStructures]="variantFieldStructures"
        [(filter)]="variantFilterGroup"
        [filterItemErrors]="variantFilterItemErrors"
        (needAutoComplete)="handleNeedAutoComplete($event)"
        [autocompleteSuggestion]="autocompleteSuggestion"
        (needMoreAutocomplete)="handleNeedMoreAutocomplete($event)"
        [hasNoMoreAutocomplete]="hasNoMoreAutocomplete"
        [locked]="stepLocked && !hasPermissionToEditLockedCatalog"
      ></app-filter>
    </div>
  </ng-container>
</div>
