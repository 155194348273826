import { Pipe, PipeTransform } from '@angular/core';
import { WeightUnitConverter } from '../../utils/weight-unit-converter/WeightUnitConverter';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserSettingsWeightUnitSelector } from '../../store/user/user.selector';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'convertToUserWeightUnit',
  standalone: true,
})
export class ConvertToUserWeightUnitPipe implements PipeTransform {
  constructor(private store: Store<AppState>, private translateService: TranslateService) {}

  transform(value: number): Observable<string> {
    return combineLatest({
      unit: this.store.select(getCurrentUserSettingsWeightUnitSelector),
      translation: this.translateService.stream('WEIGHT_UNIT').pipe(omitNullOrUndefined(), take(1)),
    }).pipe(
      map(
        ({ unit, translation }) =>
          `${Number(WeightUnitConverter.convertFromGram[unit](value).toFixed(2))} ${translation[unit]}`
      )
    );
  }
}
