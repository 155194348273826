import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TranslationWatcherService {
  private _language$ = new BehaviorSubject<string>(this.translateService.currentLang);

  constructor(private translateService: TranslateService) {
    this.initLanguageSubscription();
  }

  get language$(): Observable<string> {
    return this._language$.asObservable();
  }

  private initLanguageSubscription(): void {
    this.translateService.onLangChange
      .pipe(omitNullOrUndefined(), untilDestroyed(this))
      .subscribe((event: LangChangeEvent): void => {
        this._language$.next(event.lang);
      });
  }
}
