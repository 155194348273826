import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-step-navigation-buttons',
  templateUrl: './step-navigation-buttons.component.html',
  styleUrls: ['./step-navigation-buttons.component.scss'],
})
export class StepNavigationButtonsComponent implements OnInit {
  @Input() nextDisabled = false;
  @Input() layout: 'row' | 'column-reverse' = 'row';
  @Input() hasPrevButton = true;
  @Input() prevButtonLabel = 'GETTING_STARTED.NAVIGATION_BUTTONS.PREVIOUS';
  @Input() nextButtonLabel = 'GETTING_STARTED.NAVIGATION_BUTTONS.NEXT';
  @Input() nextButtonClass = '';
  @Input() prevButtonClass = '';
  @Output() nextClick = new EventEmitter<void>();
  @Output() prevClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
