import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { PlanBox } from '../../../../shared/components/plan-upsell-box/plan-upsell-box.component';
import { PLANS_TO_DATAFEED_SHOW, PLANS_TO_MARKETPLACE_SHOW } from '../../../../utils/Constants';
import { PlanType } from '../../cancel-dialog.component';

@Component({
  selector: 'app-cancel-dialog-subscribe',
  templateUrl: './cancel-dialog-subscribe.component.html',
  styleUrls: ['./cancel-dialog-subscribe.component.scss'],
})
export class CancelDialogSubscribeComponent implements OnInit {
  @Input() selectedEcom: EcomVO = null;
  @Input() selectedPlanType: PlanType;
  @Output() closeDialogWithoutCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialogSetPlan: EventEmitter<{ isAnnual: boolean; planId: number }> = new EventEmitter();
  public plansToMarketplaceShow: PlanBox[] = PLANS_TO_MARKETPLACE_SHOW;
  public plansToDataFeedShow: PlanBox[] = PLANS_TO_DATAFEED_SHOW;
  public isAnnual = false;

  constructor() {}

  ngOnInit(): void {
    if (this.selectedEcom.cycle === 'monthly') {
      this.isAnnual = true;
    }
  }

  public buttonClicked(id): void {
    this.closeDialogSetPlan.emit({
      isAnnual: this.isAnnual,
      planId: id.id,
    });
  }

  handleAnnualBadgeClicked(): void {
    this.isAnnual = true;
  }

  handleMoreLinkClicked(): void {
    this.closeDialogWithoutCancel.emit();
  }
}
