<form
  [formGroup]="billingInformationService.billingInfoForm"
  fxLayout="column"
  fxLayoutAlign="center stretch"
  fxLayoutAlign.lt-md="center stretch"
  fxLayoutGap="20px"
>
  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error content select-without-label" appearance="fill">
      <input matInput formControlName="firstName" [placeholder]="'BILLING_INFO_FORM.FIRST_NAME' | translate" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.FIRST_NAME_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error select-without-label content" appearance="fill">
      <input matInput formControlName="lastName" [placeholder]="'BILLING_INFO_FORM.LAST_NAME' | translate" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.LAST_NAME_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error select-without-label content" appearance="fill">
      <input matInput formControlName="companyName" [placeholder]="'BILLING_INFO_FORM.COMPANY_NAME' | translate" />
      <mat-error>{{ 'BILLING_INFO_FORM.COMPANY_NAME_REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.COUNTRY' | translate }}</div>
    <app-single-country-selector-form-field
      class="content"
      formControlName="country"
      [placeholder]="'BILLING_INFO_FORM.COUNTRY' | translate"
      [error]="
        billingInformationService.billingInfoForm.get('country').invalid
          ? ('BILLING_INFO_FORM.COUNTRY_REQUIRED' | translate)
          : null
      "
    >
    </app-single-country-selector-form-field>
  </div>

  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error select-without-label content" appearance="fill">
      <input matInput formControlName="address1" [placeholder]="'BILLING_INFO_FORM.ADDRESS1' | translate" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.ADDRESS1_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error select-without-label content" appearance="fill">
      <input matInput formControlName="address2" [placeholder]="'BILLING_INFO_FORM.ADDRESS2' | translate" />
    </mat-form-field>
  </div>

  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.CITY' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error select-without-label content" appearance="fill">
      <input matInput formControlName="city" [placeholder]="'BILLING_INFO_FORM.CITY' | translate" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.CITY_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="title">{{ 'BILLING_INFO_FORM.ZIP' | translate }}</div>
    <mat-form-field class="input-without-padding-except-error select-without-label content" appearance="fill">
      <input matInput formControlName="zip" [placeholder]="'BILLING_INFO_FORM.ZIP' | translate" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.ZIP_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row" *ngIf="!billingInformationService.hideEUVat">
    <div class="title">
      {{
        (billingInformationService.billingInfoForm.get('country').value | isCpfCountry)
          ? 'CPF (Cadastro de Pessoas Físicas)'
          : ('BILLING_INFO_FORM.EU_VAT' | translate)
      }}
    </div>
    <mat-form-field
      appearance="fill"
      class="input-prefix-fix input-without-padding-except-error select-without-label content eu-vat"
    >
      <input
        matInput
        formControlName="euVat"
        [placeholder]="
          (billingInformationService.billingInfoForm.get('country').value | isCpfCountry)
            ? 'CPF (Cadastro de Pessoas Físicas)'
            : ('BILLING_INFO_FORM.EU_VAT' | translate)
        "
      />
      <span matPrefix *ngIf="!(billingInformationService.billingInfoForm.get('country').value | isCpfCountry)">{{
        billingInformationService.billingInfoForm.get('country').value == 'GR'
          ? 'EL'
          : billingInformationService.billingInfoForm.get('country').value
      }}</span>
    </mat-form-field>
  </div>

  <div class="row" *ngIf="!billingInformationService.hideTaxNumber">
    <div class="title">{{ 'BILLING_INFO_FORM.TAX_NUMBER' | translate }}</div>
    <mat-form-field
      appearance="fill"
      class="input-prefix-fix input-without-padding-except-error select-without-label content"
    >
      <input matInput formControlName="taxNumber" [placeholder]="'BILLING_INFO_FORM.TAX_NUMBER' | translate" />
      <mat-error>
        {{ 'BILLING_INFO_FORM.TAX_NUMBER_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
