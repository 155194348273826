import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsageState } from './usage.reducer';

const getUsageState = createFeatureSelector<UsageState>('usage');

export const userUsageSelector = createSelector(getUsageState, (state) => state.user);

export const ecomsUsageSelector = createSelector(getUsageState, (state) => state.ecom);

export const ecomUsageSelector = (ecomId: number) =>
  createSelector(getUsageState, (state) => state.ecom.find((usage) => usage.ecomId === ecomId));
