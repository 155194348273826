import { Pipe, PipeTransform } from '@angular/core';
import { NavigationService } from 'app/service/navigation/navigation.service';

@Pipe({
  name: 'createProductPageFullUrl',
  standalone: true,
})
export class CreateProductPageFullUrlPipe implements PipeTransform {
  constructor(private navigationService: NavigationService) {}

  transform(synceeId: string, productName: string): string {
    return `https://${location.hostname}${this.navigationService.getProductPath(synceeId, productName)}`;
  }
}
