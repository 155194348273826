import {
  GetInstalledSynceeAppsSuccessAction,
  SynceeAppActionsUnion,
  SynceeAppsActionTypes,
} from './syncee-apps.actions';
import { InstalledAppsAndGroups } from '../../service/user-apps/syncee-apps.service';

export interface SynceeAppsState {
  installedApps: InstalledAppsAndGroups;
}

const initialState: SynceeAppsState = {
  installedApps: null,
};

export function synceeAppsReducer(state = initialState, action: SynceeAppActionsUnion): SynceeAppsState {
  switch (action.type) {
    case SynceeAppsActionTypes.GET_INSTALLED_SYNCEE_APPS_SUCCESS: {
      return setSynceeApps(state, action);
    }
    default: {
      return state;
    }
  }
}

function setSynceeApps(state: SynceeAppsState, action: GetInstalledSynceeAppsSuccessAction): SynceeAppsState {
  return {
    ...state,
    installedApps: action.payload,
  };
}
