<div id="main-progress-bar-container">
  <!--Progress bar-->
  <div id="progress-bar-wrapper" style="width: 100%">
    <ng-container *ngIf="isValidPercentage">
      <div
        [ngStyle]="{ 'width.%': item?.percentage, background: item?.background }"
        *ngFor="let item of items"
        [matTooltip]="getTooltip(item)"
        matTooltipPosition="above"
      ></div>
    </ng-container>
  </div>

  <!--Legend-->
  <ng-template *ngIf="!isError; else error">
    <div id="legend-container" *ngIf="legend === 'normal'">
      <div class="legend-item" [style.color]="item.background" *ngFor="let item of items">
        <span>{{ item.label }}</span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <div id="legend-row-container" *ngIf="legend === 'row'">
      <div class="legend-item" [style.color]="item.background" *ngFor="let item of items">
        <span>{{ item.label }} {{ item.value }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #error>
    <div class="centered">{{ errorMessage }}</div>
  </ng-template>
</div>
