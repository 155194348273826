import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CardType, PlatformCardData } from '../../main/users/platform-list/platform-card/platform-card.component';
import { getPlatformByType, PLATFORM } from '../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';

@Pipe({
  name: 'platformTypeToName',
  standalone: true,
})
export class PlatformTypeToNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(cardData: PlatformCardData, ...args: unknown[]): string {
    const cardType: CardType = args[0] as CardType;
    switch (cardType) {
      case 'PARTNER_APP':
        return this.translateService.instant(cardData.title);
      case 'PLATFORM':
        return getPlatformByType(cardData.type as EcomTypeEnum).name;
      case 'SYNCEE_APPS':
        return this.translateService.instant(cardData.title);
    }
  }
}
