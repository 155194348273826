import { Inject, Injectable, InjectionToken } from '@angular/core';
import {ActivatedRoute, ResolveEnd, Router, RoutesRecognized} from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {FuseConfig} from '../types';
import {Subset} from '../../app/utils/subset';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';

// Create the injection token for the custom settings
export const FUSE_CONFIG = new InjectionToken('fuseCustomConfig');
export const SKIP_FUSE_UPDATE = { skipFuseConfigUpdate: true };
@Injectable({
    providedIn: 'root'
})
export class FuseConfigService
{
    // Private
    private _configSubject: BehaviorSubject<FuseConfig>;
    private readonly _defaultConfig: any;
    private _eventSub = null;

    /**
     * Constructor
     *
     * @param {Platform} _platform
     * @param {Router} _router
     * @param {Store<AppState>} _store
     * @param {ActivatedRoute} _activatedRoute
     * @param _config
     */
    constructor(
        private _platform: Platform,
        private _router: Router,
        private _store: Store<AppState>,
        private _activatedRoute: ActivatedRoute,
        @Inject(FUSE_CONFIG) private _config
    )
    {
        // Set the default config from the user provided config (from forRoot)
        this._defaultConfig = _config;

        // Initialize the service
        this._init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set and get the config
     */
    set config(value: Subset<FuseConfig>)
    {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();

        // Merge the new config
        config = _.merge({}, config, value);

        // Notify the observers
        this._configSubject.next(config);
    }

    get config(): any | Observable<any>
    {
        return this._configSubject.asObservable();
    }

    /**
     * Get default config
     *
     * @returns {any}
     */
    get defaultConfig(): any
    {
        return this._defaultConfig;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void
    {
        /**
         * Disable custom scrollbars if browser is mobile
         */
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this._defaultConfig.customScrollbars = false;
        }

        // Set the config from the default config
        this._configSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));

        // Reload the default layout config on every RoutesRecognized event
        // if the current layout config is different from the default one
        this._eventSub = combineLatest([this._router.events, this._store.select(isAuthenticatedSelector)])
            .pipe(
                map(([event,]) => event),
                filter((event) => {
                    return event instanceof ResolveEnd && event.urlAfterRedirects !== this._router.url;
                }),
                filter(() => !_.isEqual(this._configSubject.getValue().layout, this._defaultConfig.layout)),
                filter(() => !this._router.getCurrentNavigation().extras.state?.skipFuseConfigUpdate),
                map((event: ResolveEnd,) => {
                    const config: any = _.cloneDeep(_.omit(this._configSubject.getValue(), 'templates'));
                    config.layout = _.cloneDeep(this._defaultConfig.layout);
                    config.templates = null;
                    return {config, event};
                }),
                switchMap(({config, event}) => this.forceRewriteDefault(config, event))
            )
            .subscribe((config) => {
                this._configSubject.next(config);
            });
    }


    forceRewriteDefault(config: FuseConfig, event: ResolveEnd): Observable<FuseConfig> {
        return combineLatest([this._store.select(isAuthenticatedSelector), of(event)]).pipe(map(([isAuthenticated, event]) => {
            const clonedConfig = _.cloneDeep(config);
            if (!isAuthenticated || event.state.root.firstChild.data['hideSidebar']) {
                clonedConfig.layout.navbar.hidden = true;
            }
            if (!!event.state.root.firstChild.data['contentContainer']) {
                clonedConfig.layout.contentContainer = event.state.root.firstChild.data['contentContainer']
            }
            return clonedConfig;
        }));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set config
     *
     * @param value
     * @param {{emitEvent: boolean}} opts
     */
    setConfig(value, opts = {emitEvent: true}): void
    {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();

        // Merge the new config
        config = _.merge({}, config, value);

        // If emitEvent option is true...
        if ( opts.emitEvent === true )
        {
            // Notify the observers
            this._configSubject.next(config);
        }
    }

    /**
     * Get config
     *
     * @returns {Observable<any>}
     */
    getConfig(): Observable<FuseConfig>
    {
        return this._configSubject.asObservable();
    }

    /**
     * Reset to the default config
     */
    resetToDefaults(): void
    {
        // Set the config from the default config
        this._configSubject.next(_.cloneDeep(this._defaultConfig));
    }

    getConfigValue(): FuseConfig {
        return this._configSubject.getValue();
    }
}

