<div
  id="dim-units"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign="start center"
  fxLayoutAlign.lt-lg="center stretch"
  fxLayoutGap="20px"
>
  <div fxFlex="1 0 0" class="m-w-0">
    <app-droppable-input
      adminComment
      [adminCommentTaskId]="taskId"
      [adminCommentTaskType]="'import'"
      [adminCommentStep]="'mapping'"
      [adminCommentMappingField]="'WIDTH'"
      [adminCommentComments]="comments"
      #normalProcess
      id="WIDTH"
      title="Width"
      name="WIDTH"
      [(ngModel)]="widthChip.chips"
      [(operations)]="widthChip.operations"
      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.WIDTH' | translate }}"
      [prodSampleInputVo]="prodSampleInputVo"
    ></app-droppable-input>
  </div>
  <div fxFlex="1 0 0" class="m-w-0">
    <app-droppable-input
      adminComment
      [adminCommentTaskId]="taskId"
      [adminCommentTaskType]="'import'"
      [adminCommentStep]="'mapping'"
      [adminCommentMappingField]="'HEIGHT'"
      [adminCommentComments]="comments"
      #normalProcess
      id="HEIGHT"
      title="Height"
      name="HEIGHT"
      [(ngModel)]="heightChip.chips"
      [(operations)]="heightChip.operations"
      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.HEIGHT' | translate }}"
      [prodSampleInputVo]="prodSampleInputVo"
    ></app-droppable-input>
  </div>
  <div fxFlex="1 0 0" class="m-w-0">
    <app-droppable-input
      adminComment
      [adminCommentTaskId]="taskId"
      [adminCommentTaskType]="'import'"
      [adminCommentStep]="'mapping'"
      [adminCommentMappingField]="'LENGTH'"
      [adminCommentComments]="comments"
      #normalProcess
      id="LENGTH"
      title="Length"
      name="LENGTH"
      [(ngModel)]="lengthChip.chips"
      [(operations)]="lengthChip.operations"
      placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.LENGTH' | translate }}"
      [prodSampleInputVo]="prodSampleInputVo"
    ></app-droppable-input>
  </div>

  <div fxFlex="0 0 auto" fxFlex.lt-lg="1 0 0" fxLayoutAlign="center center" fxLayoutAlign.lt-lg="end center">
    <button mat-icon-button (click)="openDimSettingsModal()">
      <mat-icon
        class="s-20"
        matRipple
        [ngStyle]="{ color: dimSettings.dimUnit ? 'var(--app-syncee-primary-700)' : 'var(--app-syncee-grey-500)' }"
        style="user-select: none; cursor: pointer"
        >settings
      </mat-icon>
    </button>
  </div>
</div>
