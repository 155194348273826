import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  concatMap,
  filter,
  map,
  mergeScan,
  scan,
  switchMap,
  switchScan,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { NotificationsService } from '../notifications.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { RolesEnum } from 'app/vo/roles/roles';
import { getUserRoleSelector, getUserRolesSelector } from 'app/store/user/user.selector';
import { getHighestPriorityRole } from 'app/utils/operator/get-highest-priority-role';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { isUserAuthenticatedByAdminSelector } from 'app/store/authentication/authentication.selector';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  @Input() markAllAsSeenClicked: Subject<void>;
  isLoading: boolean;
  notifications: NotificationVO[] = [];
  isAdmin = false;
  loadMoreLoading = false;
  totalItems = 0;
  actualRole: RolesEnum;
  itemsPerPage = 25;
  fromPage = 0;

  private today = new Date();
  private yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  private _unsubscribeAll: Subject<void>;

  constructor(private notificationsService: NotificationsService, private store: Store<AppState>) {
    this._unsubscribeAll = new Subject();
    this.today.setHours(0, 0, 0);
    this.today.setMinutes(this.today.getMinutes() - this.today.getTimezoneOffset());
    this.yesterday.setHours(0, 0, 0);
    this.yesterday.setMinutes(this.today.getMinutes() - this.today.getTimezoneOffset());
  }

  ngOnInit(): void {
    this.init();
    this.subrcibeToMarkAllAsSeenClicked();
  }

  ngOnDestroy(): void {
    this.notificationsService.stopWatcher();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private init(): void {
    this.isLoading = true;
    this.loadNotifications().subscribe(() => {
      this.notificationsService.clearUnseenNotifications();
    });
  }

  deleteNotification(name, notiId): void {
    const dataToRest = {
      ids: [notiId],
    };

    this.notificationsService.delete(dataToRest).subscribe(() => {
      this.notifications.map((notificationItem) => {
        if (notificationItem.name === name) {
          notificationItem.content.map((elem, id) => {
            if (elem.id === notiId) {
              notificationItem.content.splice(id, 1);
            }
          });
        }
      });
    });
  }

  changeStatus(name, notiId): void {
    const dataToRest = {
      ids: [notiId],
    };

    this.notificationsService
      .markAsRead(dataToRest)
      .pipe(take(1))
      .subscribe(() => {
        this.notifications.map((notificationItem) => {
          if (notificationItem.name === name) {
            notificationItem.content.map((elem) => {
              if (elem.id === notiId) {
                elem.markAsRead = !elem.markAsRead;
              }
            });
          }
        });
      });
  }

  public markAllAsRead(): void {
    this.notificationsService
      .markAsRead({})
      .pipe(take(1))
      .subscribe(() => {
        this.notifications.map((notificationItem) => {
          notificationItem.content.map((elem) => {
            elem.markAsRead = true;
          });
        });
      });
  }

  public loadMore(): void {
    this.loadMoreLoading = true;
    this.fromPage = this.fromPage + this.itemsPerPage;

    this.fetchNotifications(this.actualRole).subscribe(
      (res) => {
        this.storeNotifications(res.notifications);
        this.loadMoreLoading = false;
      },
      () => {
        this.loadMoreLoading = false;
      }
    );
  }

  private loadNotifications(): Observable<void> {
    this.notificationsService.stopWatcher();

    return this.getUserRole$().pipe(
      takeUntil(this._unsubscribeAll),
      switchMap((actualRole) => this.fetchNotifications$(actualRole))
    );
  }

  private getUserRole$(): Observable<RolesEnum> {
    return this.store.select(getUserRolesSelector).pipe(
      filter((roles) => !!roles && roles.length > 0),
      tap((roles) => {
        this.isAdmin = roles.includes(RolesEnum.ADMIN);
      }),
      switchMap(() => this.store.select(isUserAuthenticatedByAdminSelector)),
      switchMap((loggedInByAdmin) => getHighestPriorityRole(loggedInByAdmin)(this.store.select(getUserRolesSelector))),
      tap((role) => {
        this.actualRole = role;
      })
    );
  }

  private fetchNotifications$(actualRole: RolesEnum): Observable<void> {
    return this.fetchNotifications(actualRole).pipe(
      map((res) => {
        this.totalItems = res.total;
        if (res.notifications.length > 0) {
          this.notifications = [];
          this.storeNotifications(res.notifications);
        } else {
          this.notifications = [];
        }
        this.isLoading = false;
        return;
      })
    );
  }

  private fetchNotifications(
    actualRole: RolesEnum
  ): Observable<{ notifications: NotificationItemVO[]; total: number }> {
    const dataToRest = {
      role: actualRole,
      setSeen: !this.isAdmin || actualRole === RolesEnum.ADMIN,
      from: this.fromPage,
      size: this.itemsPerPage,
    };
    return this.notificationsService.getNotifications(dataToRest).pipe(throttleTime(1000));
  }

  private storeNotifications(notifications: NotificationItemVO[]): void {
    const todayNotificationItems: NotificationItemVO[] = [];
    const yesterdayNotificationItems: NotificationItemVO[] = [];
    const notificationItems: NotificationItemVO[] = [];

    notifications.forEach((elem) => {
      const getDate = new Date(elem.date);
      getDate.setMinutes(getDate.getMinutes() - getDate.getTimezoneOffset());
      if (getDate > this.today) {
        todayNotificationItems.push(elem);
      } else if (getDate > this.yesterday) {
        yesterdayNotificationItems.push(elem);
      } else {
        notificationItems.push(elem);
      }
    });

    this.notifications.push(
      {
        name: 'TODAY',
        content: todayNotificationItems,
      },
      {
        name: 'YESTERDAY',
        content: yesterdayNotificationItems,
      },
      {
        name: 'OLDER',
        content: notificationItems,
      }
    );
  }

  private subrcibeToMarkAllAsSeenClicked(): void {
    this.markAllAsSeenClicked.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.markAllAsRead();
    });
  }
}

export class NotificationVO {
  name: string;
  content: NotificationItemVO[];
}

export class NotificationItemVO {
  id: number;
  notification: string;
  name: string;
  template: string;
  date: string;
  settings: NotificationItemSettings;
  markAsRead: boolean; // 0: unread / 1: read
}

interface NotificationItemSettings {
  color: string;
  icon: string;
  iconColor?: string;
}
