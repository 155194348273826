import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Language, languages } from '../../../../utils/Languages';
import {
  FilterSelectorOption,
  FilterSelectorOptionGroup,
} from '../../filter-selector-base/filter-selector-base.component';
import { ProductSearchState } from '../../../../store/product-search/product-search.reducer';
import { AppState } from '../../../../app.state';
import { isArray } from 'lodash';

@Component({
  selector: 'app-product-language-filter',
  templateUrl: './product-language-filter.component.html',
  styleUrls: ['./product-language-filter.component.scss'],
})
export class ProductLanguageFilterComponent implements OnInit, OnChanges {
  @Input() multiple = false;
  @Input() value: string | string[];
  @Input() overlayOpen: boolean;
  @Input() hasClearAll = false;
  @Output() valueChange = new EventEmitter<string | string[]>();
  @Output() languageChange = new EventEmitter<Language>();
  private allLanguages = languages;
  searchTerm = '';
  hasSeeMore = true;
  allOptions: FilterSelectorOptionGroup<string, string>[] = [];
  options: FilterSelectorOptionGroup<string, string>[] = [];
  productSearchStore$: Observable<ProductSearchState>;

  @Output() clearAll = new EventEmitter<void>();

  constructor(private store: Store<AppState>, private translateService: TranslateService) {
    this.productSearchStore$ = store.pipe(select((state) => state.productSearch));
  }

  ngOnInit(): void {
    this.getProductLanguages();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.overlayOpen || changes.overlayOpen.currentValue || changes.overlayOpen.isFirstChange()) {
      return;
    }

    this.reset();
  }

  private reset(): void {
    this.handleSearch('');
    this.setInitialOptions();
  }

  private getAllOptions(productLanguages: string[]): void {
    this.allOptions = [
      {
        options: this.mapLanguagesToOptions(
          this.allLanguages.filter((lang) => productLanguages.includes(lang.id.toLocaleUpperCase()))
        ),
      },
    ];
  }
  private setInitialOptions(): void {
    const filtersOptions = this.filterOptions()[0];
    this.options = [{ options: filtersOptions.options.slice(0, 4) }];
    this.hasSeeMore = filtersOptions.options.length > 4;
  }

  private filterOptions(): FilterSelectorOptionGroup<string, string>[] {
    if (!this.searchTerm) {
      return [{ options: [...this.allOptions[0].options] }];
    } else {
      return [
        {
          options: this.allOptions[0].options.filter(
            (option) => option.value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
          ),
        },
      ];
    }
  }

  private mapLanguagesToOptions(langs: { id: string; name: string }[]): FilterSelectorOption<string, string>[] {
    return langs.map((lang) => ({
      value: this.translateService.instant(`GENERAL.LANGUAGES.${lang.id.toUpperCase()}`),
      key: lang.id,
    }));
  }

  private getProductLanguages(): void {
    this.productSearchStore$
      .pipe(
        select((state) => state.advancedFiltersData),
        filter((state) => !!state && !!state.languages),
        select((state) => state.languages)
      )
      .subscribe((productLanguages) => {
        this.getAllOptions(productLanguages);
        this.setInitialOptions();
      });
  }

  handleSearch(value: string): void {
    this.searchTerm = value;
    this.options = this.filterOptions();
    this.hasSeeMore = this.options[0].options.length > 4;
  }

  onSeeMore(): void {
    this.options = this.filterOptions();
  }

  onSeeLess(): void {
    this.setInitialOptions();
  }

  onValueChange(value: string | string[]): void {
    this.valueChange.emit(value);
    const selectedLanguage = this.allLanguages.find((language) =>
      isArray(value) ? language.id === value[0] : language.id === value
    );
    this.languageChange.emit(selectedLanguage);
  }

  onClearAll(): void {
    this.clearAll.emit();
  }
}
