import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeResourceUrlPipe } from '../../pipes/sanitize-resource-url.pipe';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-youtube-open-video-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './youtube-open-video-dialog.component.html',
  styleUrls: ['./youtube-open-video-dialog.component.scss'],
})
export class YoutubeOpenVideoDialogComponent implements OnInit {
  @Input() url: string;

  constructor(private dialog: Dialog) {}

  ngOnInit(): void {}

  handleClick(): void {
    this.dialog.open<YoutubeVideoDialogContent, YoutubeVideoDialogContentData>(YoutubeVideoDialogContent, {
      data: { url: this.url },
      panelClass: ['p-0'],
      backdropClass: ['youtube-open-video-dialog-backdrop'],
      maxWidth: '90%',
      width: '1200px',
    });
  }
}

@Component({
  selector: 'youtube-video-dialog-content',
  template: ` <div class="youtube-open-video-dialog-content">
    <mat-icon (click)="close()">close</mat-icon>
    <div class="youtube-open-video-dialog iframe-container">
      <iframe
        class="youtube-open-video-dialog snippet-iframe"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        [src]="data.url | sanitizeResourceUrl"
        width="1280"
        height="720"
      ></iframe>
    </div>
  </div>`,
  standalone: true,
  imports: [SanitizeResourceUrlPipe, MatIconModule],
})
class YoutubeVideoDialogContent {
  constructor(@Inject(DIALOG_DATA) public data: YoutubeVideoDialogContentData, private dialogRef: DialogRef) {}

  close(): void {
    this.dialogRef.close();
  }
}

interface YoutubeVideoDialogContentData {
  url: string;
}
