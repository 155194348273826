export class CatalogFormVO {
  id: number;
  name: string;
  categories: string[];
  isService: boolean;
  catalogType: any;
  tasks: string[];
  tags: string[] | string;
  description: string;
  start: any;
  end: any;
  possibleMargin: any;
  settings: any;
  feedSettings: any;
  hasNoExpiryDate: boolean;
  isGetStarted?: boolean;
  alibabaCatalog?: any;
  platformExceptions?: [];
  target?: string;
}

export interface MappedCatalogSettings {
  name: string;
  tags: string[];
  isHidden: boolean;
  visibility: boolean;
  description: string;
  privateCode: string;
  settings: Partial<CatalogFormSettings>;
}

export interface CatalogSettingsRequest extends MappedCatalogSettings {
  id: string;
}

export interface CatalogFormSettings {
  possibleMargin: string;
  language: string;
  shipping_type: string;
  approveNeeded: boolean;
  approveDescription: string;
  needResponse: boolean;
  showPrices: boolean;
  sendEmail: string;
  warehouseLocation: string;
  mainCategories: string[];
  feedSettings: FeedSettings;
  translate_source: string;
  translate_target: string;
}

export interface FeedSettings {
  backorder: boolean;
  shipping: boolean;
}
