import { PaymentCurrency } from '../../../service/suppliers/suppliers.service';

export const SUPPLIER_PAYMENT_CURRENCIES: { label: string; value: PaymentCurrency }[] = [
  {
    label: 'United States Dollar',
    value: PaymentCurrency.USD,
  },
  {
    label: 'Euro',
    value: PaymentCurrency.EUR,
  },
  {
    label: 'Canadian Dollar',
    value: PaymentCurrency.CAD,
  },
  {
    label: 'Australian Dollar',
    value: PaymentCurrency.AUD,
  },
  {
    label: 'British Pound Sterling',
    value: PaymentCurrency.GBP,
  },
  {
    label: 'Hungarian Forint',
    value: PaymentCurrency.HUF,
  },
  {
    label: 'Emirates Dirham',
    value: PaymentCurrency.AED,
  },
];
