<div class="filters-component-container">
  <div *ngIf="hasError && !disableFilter" class="mt-20">
    <!--        <app-alert [text]="getErrorMessage()" [type]="'warning'"></app-alert>-->
    <app-alert-multi
      [type]="'warning'"
      [messages]="getErrorMessage()"
      readMoreLink="{{ 'FILTER.READ_MORE_LINK' | translate }}"
      [matIcon]="'error_outline'"
    ></app-alert-multi>
  </div>

  <div class="step-locked" *ngIf="stepLocked">
    <app-alert-multi
      matIcon="info"
      type="warning"
      [messages]="['SUPPLIER_PRICING.LOCKED' | translate]"
    ></app-alert-multi>
  </div>

  <div class="unlocked-step">
    <div class="filter-disable-layout" *ngIf="(stepLocked || disableFilter) && !isAdmin"></div>
    <div
      class="filters-container"
      fxLayout="column"
      fxLayoutAlign="start"
      fxLayoutGap="8px"
      [class.mx-32]="disableFilter && !isAdmin"
    >
      <app-loading-screen [message]="'FILTER.WAIT' | translate"></app-loading-screen>
      <h2>{{ 'FILTER.MAIN' | translate }}</h2>
      <div>{{ message | translate }}</div>
      <ng-container *ngIf="filterLoaded; else loading">
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <ng-container *ngFor="let filterBox of filterBoxes; let lastBox = last; let boxIndex = index">
            <div fxLayout="row" fxLayoutAlign="start">
              <div class="filter-box" fxFlex="96" [class.disable-filter]="disableFilter && !isAdmin">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="button-holder-container">
                    <button
                      mat-icon-button
                      class="syncee-blue-button mat-button no-pointer"
                      (click)="addNewItem(filterBox)"
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                    <mat-radio-group
                      [hidden]="!filterBox.filterItems || filterBox.filterItems.length < 2"
                      name="operators_{{ boxIndex }}"
                      [(ngModel)]="filterBox.operator"
                    >
                      <mat-radio-button *ngFor="let condition of allConditions" [value]="condition.key">{{
                        condition.display
                      }}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngFor="let fi of filterBox.filterItems; let lastItem = last">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <app-filter-item
                        (filterItemChange)="filterChange($event)"
                        [taskId]="taskIdForSuggestion"
                        #filterItems
                        [filterItem]="fi"
                        fxFlex="96"
                      ></app-filter-item>
                      <button
                        mat-icon-button
                        class="item-delete-btn"
                        fxFlex="4"
                        (click)="deleteFilterItem(filterBox, fi)"
                      >
                        <mat-icon aria-label="Delete icon"> delete_outline </mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!lastItem">{{ filterBox.operator | uppercase }}</div>
                  </div>
                </div>
              </div>
              <button
                fxFlexAlign="end"
                mat-icon-button
                class="box-delete-btn"
                fxFlex="4"
                (click)="deleteFilterBox(filterBox)"
              >
                <mat-icon aria-label="Delete icon"> delete_outline </mat-icon>
              </button>
            </div>
            <img
              *ngIf="!lastBox"
              [src]="
                condOperatorBetweenBoxes[boxIndex] === 'and'
                  ? 'assets/images/filters/switch-and.png'
                  : 'assets/images/filters/switch-or.png'
              "
              fxFlexAlign="start"
              (click)="boxesOperatorClick(boxIndex)"
            />
          </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <button mat-button fxFlexAlign="start" class="syncee-blue-button" (click)="addNewGroup()">
            {{ 'FILTER.ADD' | translate }}
          </button>
        </div>
        <div class="apply-filter" *ngIf="applyFilterVisible">
          <span
            [matTooltip]="$any(getErrorMessage())"
            [matTooltipDisabled]="!(disableFilter || hasError) || isAdmin"
            [matTooltipPosition]="'above'"
          >
            <button
              [disabled]="(disableFilter || hasError) && !isAdmin"
              mat-button
              fxFlexAlign="start"
              class="syncee-blue-button"
              (click)="applyFilter()"
            >
              {{ 'FILTER.APPLY' | translate }}
            </button>
          </span>
        </div>
      </ng-container>
      <app-task-comment
        [taskId]="taskId"
        [taskType]="'import'"
        [step]="'filter'"
        [mappingField]="'filter'"
        [comments]="comments"
      >
      </app-task-comment>
    </div>
    <ng-template #loading>
      <div fxLayout="column" fxLayoutAlign="start center">
        <app-loading-spinner></app-loading-spinner>
        <div>{{ 'FILTER.LOADING_FILTER' | translate }}</div>
      </div>
    </ng-template>

    <div
      class="filters-container"
      fxLayout="column"
      fxLayoutAlign="start"
      fxLayoutGap="8px"
      *ngIf="hasVariantFilter"
      [class.mx-32]="disableFilter && !isAdmin"
    >
      <app-loading-screen [message]="'FILTER.WAIT' | translate"></app-loading-screen>
      <h2>{{ 'FILTER.VARIANT' | translate }}</h2>
      <div>{{ message | translate }}</div>
      <ng-container *ngIf="variantFilterLoaded; else loading">
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <ng-container *ngFor="let vfilterBox of variantFilterBoxes; let lastBox = last; let boxIndex = index">
            <div fxLayout="row" fxLayoutAlign="start">
              <div class="filter-box" fxFlex="96">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <button
                      mat-icon-button
                      class="syncee-blue-button mat-button no-pointer"
                      (click)="addVariantNewItem(vfilterBox)"
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                    <mat-radio-group
                      [hidden]="!vfilterBox.filterItems || vfilterBox.filterItems.length < 2"
                      name="operators_variants_{{ boxIndex }}"
                      [(ngModel)]="vfilterBox.operator"
                    >
                      <mat-radio-button *ngFor="let condition of allConditions" [value]="condition.key">{{
                        condition.display
                      }}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngFor="let fi of vfilterBox.filterItems; let lastItem = last">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <app-filter-item
                        #filterItems
                        [filterItem]="fi"
                        [taskId]="taskIdForSuggestion"
                        fxFlex="96"
                      ></app-filter-item>
                      <button
                        mat-icon-button
                        class="item-delete-btn"
                        fxFlex="4"
                        (click)="deleteFilterItem(vfilterBox, fi)"
                      >
                        <mat-icon aria-label="Delete icon"> delete_outline </mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!lastItem">{{ vfilterBox.operator | uppercase }}</div>
                  </div>
                </div>
              </div>
              <button
                fxFlexAlign="end"
                mat-icon-button
                class="box-delete-btn"
                fxFlex="4"
                (click)="deleteVariantFilterBox(vfilterBox)"
              >
                <mat-icon aria-label="Delete icon"> delete_outline </mat-icon>
              </button>
            </div>
            <img
              *ngIf="!lastBox"
              [src]="
                condOperatorBetweenVariantBoxes[boxIndex] === 'and'
                  ? 'assets/images/filters/switch-and.png'
                  : 'assets/images/filters/switch-or.png'
              "
              fxFlexAlign="start"
              (click)="variantBoxesOperatorClick(boxIndex)"
            />
          </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <button mat-button fxFlexAlign="start" class="syncee-blue-button" (click)="addNewVariantGroup()">
            {{ 'FILTER.ADD' | translate }}
          </button>
        </div>
        <div class="apply-filter" *ngIf="applyFilterVisible">
          <button mat-button fxFlexAlign="start" class="syncee-blue-button" (click)="applyFilter()">
            {{ 'FILTER.APPLY' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
