<div class="outer-container" [ngClass]="wrapperClass">
  <div
    class="inner-container"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between stretch"
    fxLayoutAlign.lt-md="center space-between"
    fxLayoutGap="24px"
    fxLayoutGap.lt-md="8px"
    [ngClass]="innerWrapperClass"
  >
    <ng-container *ngFor="let content of cardContent">
      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        fxLayoutGap="20px"
        class="inner-card"
        [style.padding]="cardPadding"
      >
        <app-double-border-icon
          [icon]="content.icon"
          [iconBackgroundColor]="content.iconBackgroundColor"
          [iconColor]="content.iconColor"
        ></app-double-border-icon>
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
          <h4 class="h4 selling-point-title-text">{{ content.titleKey | translate }}</h4>
          <div class="typography-body-1">{{ content.descriptionKey | translate }}</div>
          <app-general-button *ngIf="!!content.button" [config]="content.button" class="pt-20"></app-general-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
