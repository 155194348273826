import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  retailerEcomsSelector,
  selectedRetailerEcomSelector,
  selectedSupplierEcomSelector,
  supplierEcomsSelector,
} from '../../../store/ecom/ecom.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { filter, takeUntil } from 'rxjs/operators';
import { SelectedRetailerEcomChangeStart, SelectedSupplierEcomChangeStart } from '../../../store/ecom/ecom.actions';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { getPlatformByType } from '../../../utils/platforms/platform-config';

import { ROLE_TYPE, RolesEnum } from '../../../vo/roles/roles';

@Component({
  selector: 'app-ecom-selector-dropdown',
  templateUrl: './ecom-selector-dropdown.component.html',
  styleUrls: ['./ecom-selector-dropdown.component.scss'],
  imports: [CommonModule, MatSelectModule, FormsModule],
  standalone: true,
})
export class EcomSelectorDropdownComponent implements OnInit, OnDestroy {
  @Input() roleType: ROLE_TYPE = RolesEnum.RETAILER;
  selectedEcom: EcomVO;

  private readonly unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>, private marketplaceEcomService: MarketplaceEcomService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getSelectedEcom();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  setSelectedEcom(ecomId: number): void {
    const action =
      this.roleType === RolesEnum.RETAILER
        ? new SelectedRetailerEcomChangeStart({ ecomId })
        : new SelectedSupplierEcomChangeStart({ ecomId });
    this.store.dispatch(action);
  }

  // getEcomLogo(): Observable<string> {
  //   return combineLatest([Observable.of(this.ecomLogo), this.selectedEcom]).pipe(
  //     map(
  //       ([logos, selectedEcom]) =>
  //         logos[this.marketplaceEcomService.getWhiteLabelEcomType(selectedEcom.ecomType, selectedEcom.domain)]
  //     )
  //   );
  // }

  getEcomLogo(ecom: EcomVO): string {
    return getPlatformByType(this.marketplaceEcomService.getWhiteLabelEcomType(ecom.ecomType, ecom.domain)).images.logo;
  }

  private getSelectedEcom(): void {
    this.store
      .select(this.roleType === RolesEnum.SUPPLIER ? selectedSupplierEcomSelector : selectedRetailerEcomSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((ecom) => (this.selectedEcom = ecom));
  }

  get ecoms(): Observable<EcomVO[]> {
    return this.store
      .select(this.roleType === RolesEnum.SUPPLIER ? supplierEcomsSelector : retailerEcomsSelector)
      .pipe(filter((value) => !!value));
  }
}
