import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getPlatformByType } from '../../../utils/platforms/platform-config';

@Component({
  selector: 'app-ecom-selector',
  standalone: true,
  imports: [CommonModule, FlexModule, MatIconModule],
  templateUrl: './ecom-selector.component.html',
  styleUrls: ['./ecom-selector.component.scss'],
  animations: [fuseAnimations],
})
export class EcomSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ecomList: EcomSelectorOverlayOption[] = [];
  @Input() selectedEcomId: number;
  @Input() close: Subject<void>;
  @Output() selectedEcomIdChange = new EventEmitter<number>();
  filteredEcomList: ({ logo: string } & EcomSelectorOverlayOption)[] = [];
  selectedEcom: EcomSelectorOverlayOption;
  collapseState: 'collapsed' | 'expanded' = 'collapsed';
  private unsubscribeAll: Subject<void>;

  constructor(private marketplaceEcomService: MarketplaceEcomService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.subscribeToClose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleInputChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleSelectedEcomClicked(): void {
    if (this.ecomList.length === 1) {
      return;
    }
    this.collapseState = this.collapseState === 'collapsed' ? 'expanded' : 'collapsed';
  }

  private handleInputChange(): void {
    if (this.selectedEcomId !== undefined) {
      this.filteredEcomList = this.ecomList
        .map((ecom) => ({
          ...ecom,
          logo: this.getLogoLink(ecom),
        }))
        .filter((ecom) => ecom.id !== this.selectedEcomId);
      this.selectedEcom = this.getSelectedEcom(this.selectedEcomId);
    }
  }

  private getSelectedEcom(id: number): EcomSelectorOverlayOption {
    return this.ecomList.find((ecom) => ecom.id === id);
  }

  private getLogoLink(ecom: EcomSelectorOverlayOption): string {
    return getPlatformByType(this.marketplaceEcomService.getWhiteLabelEcomType(ecom.ecomType, ecom.domain)).images.logo;
  }

  private subscribeToClose(): void {
    if (this.close) {
      this.close.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => (this.collapseState = 'collapsed'));
    }
  }
  get selectedEcomLogo(): string {
    return getPlatformByType(
      this.marketplaceEcomService.getWhiteLabelEcomType(this.selectedEcom.ecomType, this.selectedEcom.domain)
    ).images.logo;
  }
}

export interface EcomSelectorOverlayOption {
  id: number;
  domain: string;
  ecomType: string;
}
