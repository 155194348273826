import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AiChatMessagesService, isAiChatMessageTypeOperator } from '../../services/ai-chat-messages.service';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AIChatMessageType } from '../../model/messages/ai-chat-message';
import { AiChatLimitationsService } from '../../services/ai-chat-limitations.service';

@Component({
  selector: 'app-ai-chat-body',
  templateUrl: './ai-chat-body.component.html',
  styleUrls: ['./ai-chat-body.component.scss'],
})
export class AiChatBodyComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('body') private body: ElementRef<HTMLElement>;
  private unsubscribeAll = new Subject<void>();
  searchTermSuggestion: string[];

  constructor(
    public aiChatMessagesService: AiChatMessagesService,
    public aiChatLimitationsService: AiChatLimitationsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subscribeToLastMessage();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToLastMessage(): void {
    this.aiChatMessagesService.lastMessage
      .pipe(
        takeUntil(this.unsubscribeAll),
        debounceTime(100),
        tap(() => this.scrollToBottom()),
        tap(() => (this.searchTermSuggestion = null)),
        isAiChatMessageTypeOperator(AIChatMessageType.SEARCH_TERM_SUGGESTION)
      )
      .subscribe((message) => {
        this.searchTermSuggestion = message.payload.searchTerms;
      });
  }

  private scrollToBottom(): void {
    this.body.nativeElement.scrollTop = this.body.nativeElement.scrollHeight;
  }
}
