import { Injectable } from '@angular/core';
import { ChatService } from '../../../../service/chat/chat.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { ChannelItem, ChatUser } from '../../../../service/chat/model/chat-models';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { SpringPage } from '../../../../vo/pagination/spring-page';

@Injectable()
export class ChatHandlerService {
  private hasLoadMore$ = new BehaviorSubject<boolean>(false);
  private searchTerm$ = new BehaviorSubject<string>('');
  private onlyUnseen$ = new BehaviorSubject<boolean>(false);
  constructor(private chatService: ChatService) {}

  getChannels(
    userId: number,
    page: number,
    size: number,
    searchTerm?: string,
    unseen?: boolean
  ): Observable<ChannelItem[]> {
    return this.chatService.getChannelsPage(userId, { size, page }, searchTerm, unseen).pipe(
      tap((springPage) => this.setHasLoadMore(springPage, size)),
      map((springPage) => springPage.content)
    );
  }

  private setHasLoadMore(page: SpringPage<ChannelItem>, pageSize: number): void {
    this.hasLoadMore$.next(page.content.length === pageSize && !page.last);
  }

  get hasLoadMore(): Observable<boolean> {
    return this.hasLoadMore$.asObservable().pipe(distinctUntilChanged());
  }

  toggleUnseenFilter(): void {
    this.onlyUnseen$.next(!this.onlyUnseen$.value);
  }

  setUnseenFilter(value: boolean): void {
    this.onlyUnseen$.next(value);
  }

  get unseenFilter(): Observable<boolean> {
    return this.onlyUnseen$.asObservable().pipe(distinctUntilChanged());
  }

  setSearchTerm(value: string): void {
    this.searchTerm$.next(value);
  }

  get searchTerm(): Observable<string> {
    return this.searchTerm$.asObservable().pipe(distinctUntilChanged());
  }

  createChannel(user: ChatUser, currentUser: ChatUser): Observable<boolean> {
    return this.chatService.createChannel([user, currentUser]);
  }

  closeAppDialog(): void {
    this.chatService.closeChatAppDialog();
  }

  get filterChanged(): Observable<[string, boolean]> {
    return combineLatest([this.searchTerm.pipe(debounceTime(500)), this.unseenFilter]);
  }
}
