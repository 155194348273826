export class FileUploadDownloadInputVo {
  private _remotePath: string;
  private _urlSourceType: string;
  private _selectedType: string;
  private _fileId: number;
  private _taskId: number;
  private _isUpdate = false;
  private _placeholder: string;

  constructor() {}

  get isUpdate(): boolean {
    return this._isUpdate;
  }

  set isUpdate(value: boolean) {
    this._isUpdate = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get selectedType(): string {
    return this._selectedType;
  }

  set selectedType(value: string) {
    this._selectedType = value;
  }

  get remotePath(): string {
    return this._remotePath;
  }

  set remotePath(value: string) {
    this._remotePath = value;
  }

  get urlSourceType(): string {
    return this._urlSourceType;
  }

  set urlSourceType(value: string) {
    this._urlSourceType = value;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }
}
