<div *ngIf="!isLoading" class="variant-selector">
  <div fxLayout="column" fxLayoutGap="30px" class="variant-fields">
    <form [formGroup]="form">
      <div class="options" formArrayName="groups">
        <ng-container *ngFor="let _ of optionGroup.controls; let index = index">
          <div [formGroupName]="index">
            <mat-form-field appearance="fill" class="w-100p">
              <mat-label>{{ optionGroups[index].name }}</mat-label>
              <mat-select disableOptionCentering panelClass="mat-select-display-change" formControlName="value">
                <mat-option [value]="value" *ngFor="let value of optionGroups[index].options">{{ value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</div>
