import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../app.state';
import { Observable, Subject } from 'rxjs';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { debounce } from 'lodash';
import { MarketplaceEcomService } from '../../../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { take, takeUntil } from 'rxjs/operators';
import { Currencies } from '../../../../../../service/currency-service/currency.service';
import { retailerCurrencySelector } from '../../../../../../store/currency/currency.selector';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss'],
})
export class PriceFilterComponent implements OnInit, OnDestroy {
  private unsubscribeAll: Subject<void>;
  debouncedMaxChange: (value: number) => void;
  debouncedMinChange: (value: number) => void;
  ecomCurrency = 'USD';
  productSearchStore$: Observable<ProductSearchState>;
  predefinedPrices: PredefinedPrice[] = [
    { min: null, max: 5, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.UNDER' },
    { min: 5, max: 10, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.RANGE' },
    { min: 10, max: 20, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.RANGE' },
    { min: 20, max: null, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.ABOVE' },
  ];

  constructor(private store: Store<AppState>, private marketplaceEcomService: MarketplaceEcomService) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
    this.debouncedMaxChange = debounce(this.handleMaxChange, 300);
    this.debouncedMinChange = debounce(this.handleMinChange, 300);
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getEcomData();
    this.subscribeToEcomChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private handleMaxChange(value: number): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { maxPrice: value } }));
  }

  private handleMinChange(value: number): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { minPrice: value } }));
  }

  private subscribeToEcomChange(): void {
    this.marketplaceEcomService.onDomainChange.pipe(takeUntil(this.unsubscribeAll)).subscribe((ecom) => {
      this.handleCurrency(this.marketplaceEcomService.getCurrency());
    });
  }

  private subscribeToEcomInit(): void {
    this.marketplaceEcomService.initialized.pipe(takeUntil(this.unsubscribeAll)).subscribe((ecom) => {
      this.handleCurrency(this.marketplaceEcomService.getCurrency());
    });
  }

  private handleCurrency(currency: string): void {
    this.ecomCurrency = currency;
    this.predefinedPrices = this.predefinedPrices.map((priceSet) => ({
      ...priceSet,
      calculatedMax: this.calculatePrice(priceSet.max, currency),
      calculatedMin: this.calculatePrice(priceSet.min, currency),
    }));
  }

  private calculatePrice(value: number, currency: string): number {
    if (Utils.isNullOrUndefined(value)) {
      return null;
    } else {
      const currencies = this.getCurrencies();
      return value * (currencies[currency] ? currencies[currency] : 1);
    }
  }

  private getCurrencies(): Currencies {
    let currencies: Currencies;
    this.store
      .select(retailerCurrencySelector)
      .pipe(take(1))
      .subscribe((c) => {
        currencies = c;
      });
    return currencies;
  }

  private getEcomData(): void {
    if (this.marketplaceEcomService.initialized) {
      this.handleCurrency(this.marketplaceEcomService.getCurrency());
    } else {
      this.subscribeToEcomInit();
    }
  }

  handlePredefinedClick(min: number, max: number): void {
    this.store.dispatch(
      new SetTemporaryFilter({
        value: {
          minPrice: min,
          maxPrice: max,
        },
      })
    );
  }
}

interface PredefinedPrice {
  min: number;
  calculatedMin?: number;
  max: number;
  calculatedMax?: number;
  template: string;
}
