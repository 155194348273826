<div class="outer-container" [ngClass]="wrapperClass" [style.background-image]="'url(' + bgImageURL + ')'">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="center start"
    fxLayoutGap="80px"
    fxLayoutGap.lt-md="0"
    [ngClass]="innerWrapperClass"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutAlign.lt-md="start stretch"
      class="inner-container"
      fxLayoutGap="44px"
      fxLayoutGap.lt-md="28px"
      fxFlex="1 0 0"
    >
      <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="16px">
        <h1 class="white-500-fg" [ngClass]="'h1'" [ngClass.lt-md]="'h2'">{{ title }}</h1>
        <div class="white-500-fg" [ngClass]="'typography-h1-subtitle'" [ngClass.lt-md]="'typography-h3-subtitle'">
          {{ description }}
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="center stretch"
        fxLayoutGap="12px"
        fxLayoutGap.lt-md="4px"
      >
        <button
          mat-button
          class="syncee-white-button landing-page-button"
          [style.border]="'unset !important'"
          [routerLink]="buttonRedirectURL"
          [state]="skipFuseUpdate"
        >
          {{ buttonText }}
        </button>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center" class="link">
          <div class="typography-body-1 link-description white-500-fg">{{ linkDescription }}&nbsp;</div>
          <div
            *ngIf="linkTitle"
            class="typography-link cursor-pointer link-title white-500-fg"
            [routerLink]="linkRedirectURL"
          >
            {{ linkTitle }}
          </div>
        </div>
      </div>
    </div>
    <img class="display-image" [ngSrc]="image.src" width="760" height="715" [alt]="image.alt" fxFlex="1 0 0" />
  </div>
</div>
