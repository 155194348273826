<app-country-phone-input
  [phoneNumber]="value"
  [placeholder]="placeholder"
  [hideUnderline]="true"
  (phoneNumberChange)="valueChange($event)"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn()"
  [required]="required"
  [hasError]="errorState"
></app-country-phone-input>
