import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from '../../../service/rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { ProductViewDataSource } from './ProductViewDataSource';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  httpParams = new HttpParams();

  dataSource;
  displayedColumns = ['id', 'image', 'name', 'status'];
  length = 10;
  offset = 0;
  pageSize = 30;
  taskId = 0;

  constructor(private restService: RestService, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.taskId = params.task_id;

      this.dataSource = new ProductViewDataSource<ProductVO>(
        'ElasticsSearch/getProductView',
        this.restService,
        this.taskId,
        null
      );
      this.dataSource.loadValues(this.offset, this.pageSize);
    });

    this.dataSource.paginator = this.paginator;
  }
}

export class ProductVO {
  private _ID: number;
  private _IMAGES: string[] = [];
  private _TITLE: string;
  private _STATUS: string;

  get ID(): number {
    return this._ID;
  }

  set ID(value: number) {
    this._ID = value;
  }

  get IMAGES(): string[] {
    return this._IMAGES;
  }

  set IMAGES(value: string[]) {
    this._IMAGES = value;
  }

  get TITLE(): string {
    return this._TITLE;
  }

  set TITLE(value: string) {
    this._TITLE = value;
  }

  get STATUS(): string {
    return this._STATUS;
  }

  set STATUS(value: string) {
    this._STATUS = value;
  }
}
