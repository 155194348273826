import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { RestService } from '../../../../../service/rest/rest.service';
import { DroppableInputComponent } from '../../../../droppable-input/droppable-input.component';
import { RecentlyDroppedItemNotifier } from '../../../../drag-ndrop-zone/recently-dropped-item-notifier';
import { Observable } from 'rxjs';
import { MappingInputVo } from '../../vo/mapping-vo/mapping-input-vo';
import { NotificationService } from '../../../../notification/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskwizardUpdateService } from '../../../../../service/taskwizard/taskwizard-update.service';
import {
  Mapping,
  MappingTemplateSettings,
  ProdIds,
} from '../../../../../service/taskwizard/taskwizard-update-response';
import { TaskwizardService } from '../../../../../service/taskwizard/taskwizard.service';
import { StepperService } from '../../../../../service/taskwizard/stepper.service';
import { StepBase } from '../../../step-base';
import { RestResponse } from '../../../../../service/rest/rest-response';
import { DroppableMultilineInputComponent } from '../../../../droppable-input/droppable-multiline-input.component';
import { UserService } from '../../../../../service/user/user.service';
import { USER_ROLES } from '../../../../../utils/Constants';
import { DimensionFieldsComponent } from './dimension-fields/dimension-fields.component';
import { ProdSampleInputVo } from '../../vo/prod-sample-input-vo';
import { Utils } from 'app/utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PermissionService } from '../../../../../service/permission/permission.service';

export interface GroupInputFields {
  key: string;
  inputs: QueryList<DroppableInputComponent>;
}

@UntilDestroy()
@Component({
  selector: 'app-productdetailsbuilder',
  templateUrl: './productdetailsbuilder.component.html',
  styleUrls: ['./productdetailsbuilder.component.scss'],
})
export class ProductdetailsbuilderComponent implements OnInit, AfterViewInit, OnChanges, StepBase {
  @ViewChild('dComponent', { static: true }) private dComponent: DimensionFieldsComponent;

  @ViewChild('productDetatailsForm', { static: true }) productDetatailsForm: NgForm;
  @ViewChild('shopifyOptionsForm', { static: true }) shopifyOptionsForm: NgForm;

  private _fileInpuVO: Array<MappingInputVo>;

  /**Input count variable for files id*/
  @Input() set files(fileInputVO: Array<MappingInputVo>) {
    console.log('set ngOnChanges builder');
    this._fileInpuVO = fileInputVO;
    if (!Utils.isNullOrUndefined(this._fileInpuVO)) {
      this.ngOnInit();
    }
    this.updateFileProductIds();
  }

  @Input() taskId: number;
  @Input() prodSampleInputVo: ProdSampleInputVo;

  @ViewChildren(DroppableInputComponent) private allDroppableComponent: QueryList<DroppableInputComponent>;
  @ViewChildren(DroppableMultilineInputComponent)
  private mlDroppableComponents: QueryList<DroppableMultilineInputComponent>;

  @ViewChildren('imgDroppableInputs') private droppableInputsComponent: QueryList<DroppableInputComponent>;

  @ViewChildren('normalProcess') private inputsNormalProcess: QueryList<DroppableInputComponent>;

  @ViewChildren('attributesNamesProcess') private inputsAttrNamesProcess: QueryList<DroppableInputComponent>;
  @ViewChildren('attributesValuesProcess') private inputsAttrValuesProcess: QueryList<DroppableInputComponent>;

  @ViewChildren('shopifyOptionsName') private shopifyOptionNames: QueryList<DroppableInputComponent>;
  @ViewChildren('shopifyOptionsValues') private shopifyOptionValues: QueryList<DroppableInputComponent>;

  @ViewChildren('imagesURLProcess') private inputsImgsURLProcess: QueryList<DroppableInputComponent>;
  @ViewChildren('imagesALTProcess') private inputsImgsALTProcess: QueryList<DroppableInputComponent>;

  @ViewChildren('tagsProcess') private inputsTagsProcess: QueryList<DroppableInputComponent>;

  categories: string; // categories input-ra kell majd kötni
  brand: string;
  isVariantsEnabled = false;
  isAdvancedEnabled = false;
  priceFieldIsRequired = true;
  saveMappingWarning = false;
  @Input() comments: any;
  isAdmin: boolean;

  mappingData: Mapping;
  isRunTask = true;

  constructor(
    protected restService: RestService,
    protected droppedItemNotifier: RecentlyDroppedItemNotifier,
    protected notification: NotificationService,
    protected taskWizardUpdateService: TaskwizardUpdateService,
    protected dialog: MatDialog,
    protected taskWizardService: TaskwizardService,
    protected userService: UserService,
    protected stepperService: StepperService,
    protected permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    // this.isAdmin = this.userService.getCachedUser().role === USER_ROLES.GLOBAL_ADMIN;
    this.permissionService
      .getHasAdminScope()
      .pipe(untilDestroyed(this))
      .subscribe((adminScope) => {
        this.isAdmin = adminScope;
      });
    this.init();
    if (!this.taskWizardUpdateService.isUpdate || Utils.isNullOrUndefined(this.taskWizardUpdateService.data.mapping)) {
      this.mappingData = new Mapping();
      this.mappingData.initEmptyMapping();
    } else {
      this.mappingData = this.taskWizardUpdateService.data.mapping;
    }

    if (this.mappingData.isWrongData) {
      this.saveMappingWarning = true;
    }
    if (this.mappingData.isPunchOut()) {
      this.priceFieldIsRequired = false;
    }

    this.isVariantsEnabled = this.mappingData.settings.variantsOption;
  }

  init(): void {
    this.isRunTask = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges builder');
  }

  trackByFn(i: number): number {
    return i;
  }

  ngAfterViewInit(): void {
    this.updateFileProductIds();
  }

  updateFileProductIds(): void {
    if (Utils.isNullOrUndefined(this._fileInpuVO)) {
      this.mappingData.initEmptyMapping();
      return;
    }

    if (Utils.isNullOrUndefined(this.mappingData)) {
      return;
    }

    for (const fileInput of this._fileInpuVO) {
      if (
        Utils.isNullOrUndefined(
          this.mappingData.productIds.find((obj) => {
            return obj.id === fileInput.fileId;
          })
        )
      ) {
        const newFile = <ProdIds>{
          id: fileInput.fileId,
          value: this.mappingData.emptyMapInputDataObj(),
          placeholder: fileInput.fileName,
        };
        this.mappingData.productIds.push(newFile);
      }
    }
    let isDeleted = false;

    for (const prodIdObj of this.mappingData.productIds) {
      console.log(
        this._fileInpuVO.find((obj) => {
          return obj.fileId === prodIdObj.id;
        })
      );
      if (
        Utils.isNullOrUndefined(
          this._fileInpuVO.find((obj) => {
            return obj.fileId === prodIdObj.id;
          })
        )
      ) {
        this.removeChipsFromDroppableComponents(prodIdObj.id);
        this.mappingData.removeProdIdObj(prodIdObj);
        isDeleted = true;
      }
    }

    if (isDeleted) {
      this.saveMappingWarning = true;
    }
  }

  removeChipsFromDroppableComponents(fileId: number): void {
    if (!Utils.isNullOrUndefined(this.inputsAttrNamesProcess)) {
      this.inputsAttrNamesProcess.toArray().forEach((comp) => {
        comp.removeChipsByFileID(fileId);
      });
    }
    if (!Utils.isNullOrUndefined(this.inputsAttrValuesProcess)) {
      this.inputsAttrValuesProcess.toArray().forEach((comp) => {
        comp.removeChipsByFileID(fileId);
      });
    }
    if (!Utils.isNullOrUndefined(this.inputsNormalProcess)) {
      this.inputsNormalProcess.toArray().forEach((comp) => {
        comp.removeChipsByFileID(fileId);
      });
    }
    if (!Utils.isNullOrUndefined(this.inputsTagsProcess)) {
      this.inputsTagsProcess.toArray().forEach((comp) => {
        comp.removeChipsByFileID(fileId);
      });
    }
    if (!Utils.isNullOrUndefined(this.inputsImgsURLProcess)) {
      this.inputsImgsURLProcess.toArray().forEach((comp) => {
        comp.removeChipsByFileID(fileId);
      });
    }
    if (!Utils.isNullOrUndefined(this.inputsImgsALTProcess)) {
      this.inputsImgsALTProcess.toArray().forEach((comp) => {
        comp.removeChipsByFileID(fileId);
      });
    }
  }

  postImagesStructureFilter(structures): void {
    if (Utils.isNullOrUndefined(structures['IMAGES']) || Utils.isNullOrUndefined(structures['IMAGES_ALTS'])) {
      return;
    }
    const images = [];
    const imagesAlt = [];
    for (let i = 0; i < structures['IMAGES'].length; ++i) {
      if (!Utils.isNullOrUndefined(structures['IMAGES'][i]) || !Utils.isNullOrUndefined(structures['IMAGES_ALTS'][i])) {
        images.push(structures['IMAGES'][i]);
        imagesAlt.push(structures['IMAGES_ALTS'][i]);
      }
    }
    if (images.length < 1) {
      delete structures['IMAGES'];
      delete structures['IMAGES_ALTS'];
    } else {
      structures['IMAGES_ALTS'] = imagesAlt;
      structures['IMAGES'] = images;
    }
  }

  private addGroupFieldsToStructure(
    structures: any,
    prop: string,
    listFields: QueryList<DroppableInputComponent>,
    isImages: boolean = false
  ): void {
    structures[prop] = [];
    listFields.forEach((item) => {
      const structure = item.getStructure();
      const key = Object.keys(structure)[0];
      const filteredStructure = this.filterStructure(structure[key][0]);
      if (!Utils.isNullOrUndefined(filteredStructure) || isImages) {
        structures[prop].push(filteredStructure);
      }
    });
    if (structures[prop].length < 1) {
      delete structures[prop];
    }
  }

  private filterStructure(structureObj): any {
    let emptyCount = 0;
    if (structureObj.chips.length < 1) {
      return null;
    } else {
      structureObj.chips.forEach((chip) => {
        if (typeof chip === 'object' && chip !== null) {
          if (chip.path.length < 1) {
            delete chip.path;
          }
          if (chip.operations.length < 1) {
            delete chip.operations;
          }
          if (chip.settings.length < 1) {
            delete chip.settings;
          }
        }
      });
    }
    if (structureObj.operations.length < 1) {
      delete structureObj.operations;
      emptyCount++;
    }
    if (structureObj.settings.length < 1) {
      emptyCount++;
      delete structureObj.settings;
    }
    return structureObj;
  }

  public reset(): void {
    const settings = { mappingTemplate: null };
    this.taskWizardService.saveMapping(this.taskId, null, settings).subscribe();
  }

  isStepValid(): boolean {
    return true;
  }

  mapNormaliFieldsStructure(structures, item): any {
    const structure = item.getStructure();
    const key = Object.keys(structure)[0];
    const filteredStructure = this.filterStructure(structure[key][0]);
    if (!Utils.isNullOrUndefined(filteredStructure)) {
      structures[key] = [filteredStructure];
    }
  }

  protected getCustomGroupComponents(): GroupInputFields[] {
    return [];
  }

  protected getCustomNormalComponents(): QueryList<DroppableInputComponent> {
    if (Utils.isNullOrUndefined(this.dComponent)) {
      return new QueryList<DroppableInputComponent>();
    }
    return this.dComponent.getDimensionsInputComponents();
  }

  getMappingStructure(): any {
    const structures = {};

    this.mlDroppableComponents.forEach((item) => {
      this.mapNormaliFieldsStructure(structures, item);
    });

    this.getCustomNormalComponents().forEach((item) => {
      this.mapNormaliFieldsStructure(structures, item);
    });

    if (!Utils.isNullOrUndefined(this.mappingData.dimSettings) && this.mappingData.dimSettings.length > 0) {
      if (!Utils.isNullOrUndefined(structures['WIDTH'])) {
        structures['WIDTH']['settings'] = this.mappingData.dimSettings;
      }

      if (!Utils.isNullOrUndefined(structures['HEIGHT'])) {
        structures['HEIGHT']['settings'] = this.mappingData.dimSettings;
      }

      if (!Utils.isNullOrUndefined(structures['LENGTH'])) {
        structures['LENGTH']['settings'] = this.mappingData.dimSettings;
      }
    }

    this.inputsNormalProcess.forEach((item) => {
      this.mapNormaliFieldsStructure(structures, item);
    });

    if (this.getCustomGroupComponents()) {
      this.getCustomGroupComponents().forEach((groupFieldInputs) => {
        if (groupFieldInputs.inputs.length > 0) {
          this.addGroupFieldsToStructure(structures, groupFieldInputs.key, groupFieldInputs.inputs);
        }
      });
    }

    if (this.inputsTagsProcess.length > 0) {
      this.addGroupFieldsToStructure(structures, 'TAGS', this.inputsTagsProcess);
    }

    if (this.inputsImgsURLProcess.length > 0) {
      this.addGroupFieldsToStructure(structures, 'IMAGES', this.inputsImgsURLProcess, true);
    }

    if (this.inputsImgsALTProcess.length > 0) {
      this.addGroupFieldsToStructure(structures, 'IMAGES_ALTS', this.inputsImgsALTProcess, true);
    }

    this.postImagesStructureFilter(structures);

    const inputsAttrNamesProcessArray = this.inputsAttrNamesProcess.toArray();
    const inputsAttrValuesProcessArray = this.inputsAttrValuesProcess.toArray();

    if (
      inputsAttrNamesProcessArray.length > 0 &&
      inputsAttrValuesProcessArray.length === inputsAttrNamesProcessArray.length
    ) {
      structures['ATTRIBUTES'] = [];
      for (let i = 0; i < inputsAttrNamesProcessArray.length; ++i) {
        const itemName = inputsAttrNamesProcessArray[i];
        const itemValue = inputsAttrValuesProcessArray[i];

        const structureName = itemName.getStructure();
        const structureValue = itemValue.getStructure();

        const keyName = Object.keys(structureName)[0];
        const keyValue = Object.keys(structureValue)[0];

        const nameObj = this.filterStructure(structureName[keyName][0]);
        const valueObj = this.filterStructure(structureValue[keyValue][0]);
        if (!Utils.isNullOrUndefined(nameObj) && !Utils.isNullOrUndefined(valueObj)) {
          structures['ATTRIBUTES'].push({ name: nameObj, value: valueObj });
        } else if (!Utils.isNullOrUndefined(nameObj) || !Utils.isNullOrUndefined(valueObj)) {
          this.notification.warning('Please fill attribute pairs');
          return;
        }
      }
      if (structures['ATTRIBUTES'].length < 1) {
        delete structures['ATTRIBUTES'];
      }
    }

    return structures;
  }

  saveStep(): Observable<any> {
    if (this.checkErrors()) {
      return;
    }

    const structures = this.getMappingStructure();

    const settings = { mappingTemplate: <MappingTemplateSettings>{ variantsOption: this.isVariantsEnabled } };

    return this.taskWizardService.saveMapping(this.taskId, structures, settings, this.isRunTask);
  }

  takonySave(): void {
    if (this.checkErrors()) {
      return;
    }

    const structures = this.getMappingStructure();

    const settings = { mappingTemplate: <MappingTemplateSettings>{ variantsOption: this.isVariantsEnabled } };

    this.taskWizardService.saveMapping(this.taskId, structures, settings, false).subscribe(() => {
      this.notification.success('Mapping saved!');
    });
  }

  private checkErrors(): boolean {
    let isError = false;
    const errorMessages = [];
    for (const droppableComponent of this.allDroppableComponent.toArray()) {
      droppableComponent.touched(true);
      if (droppableComponent.hasErrors()) {
        isError = true;
        if (!Utils.isNullOrUndefined(droppableComponent.errors.message)) {
          errorMessages.push(droppableComponent.errors.message);
        }
      }
    }
    if (isError || (this.shopifyOptionsForm && !this.shopifyOptionsForm.valid)) {
      if (errorMessages.length === 0) {
        errorMessages.push('Please fill all required fields!');
      }
      errorMessages.forEach((message) => {
        this.notification.warning(message);
      });
      return true;
    }
    return false;
  }

  onSuccessfulSave(response: RestResponse): void {
    this.saveMappingWarning = false;
  }
}
