<div>
  <div class="pb-24">
    {{ 'SUPPLIER_CURRENCY_SELECTOR.TITLE' | translate }}
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.gt-xs="start center" fxLayoutGap="32px">
    <div class="title" fxFlex="50">{{ 'SUPPLIER_CURRENCY_SELECTOR.LABEL' | translate }}</div>
    <mat-form-field
      fxFlex="50"
      appearance="fill"
      class="input-without-padding input-without-padding-except-error select-without-label"
    >
      <mat-select [value]="currency" name="currency" (valueChange)="onChangeHandler($event)">
        <mat-option *ngFor="let currency of currencies" [value]="currency.value">{{
          currency.value + ' - ' + currency.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
