import { REVIEW_PLATFORM_URLS } from '../../../utils/Constants';

export const REVIEW_IMAGES = [
  {
    src: 'assets/images/footer/review/app-store.webp',
    alt: 'Shopify app store',
    link: REVIEW_PLATFORM_URLS.shopify,
  },
  {
    src: 'assets/images/footer/review/trustpilot.webp',
    alt: 'Trustpilot',
    // link: 'https://www.trustpilot.com/review/syncee.co',
    link: REVIEW_PLATFORM_URLS.trustpilot,
  },
  {
    src: 'assets/images/footer/review/google-customer-reviews.webp',
    alt: 'Google customer reviews',
    link: REVIEW_PLATFORM_URLS.google,
  },
  {
    src: 'assets/images/footer/review/g2.webp',
    alt: 'G2',
    link: REVIEW_PLATFORM_URLS.g2,
  },
];
