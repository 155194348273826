import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { getTaskById } from 'app/store/tasks/tasks.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Utils } from 'app/utils/utils';
import { CatalogFeedSettingsVO } from 'app/vo/catalog';
import { CatalogSettingsVO } from 'app/vo/catalog-settings-vo';
import { SupplierCatalog } from 'app/vo/supplier-catalog/supplier-catalog';
import { SupplierCatalogDetails } from 'app/vo/supplier-catalog/supplier-catalog-details';
import { combineLatest, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationService } from '../../../main/notification/notification.service';
import { StepBase } from '../../../main/taskwizard/step-base';
import { CatalogService } from '../../../service/catalog/catalog.service';
import { CatalogFormVO } from '../../../vo/catalog-form-vo';
import { TaskSettingsComponent } from '../task-settings/task-settings.component';

@Component({
  selector: 'app-catalog-editor',
  templateUrl: './catalog-editor.component.html',
  styleUrls: ['./catalog-editor.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, TaskSettingsComponent],
})
export class CatalogEditorComponent implements AfterViewInit, StepBase {
  @Input() catalogId: number;
  @Input() isNewCatalog: boolean;
  @Input() comments: any;

  @Output() catalogValidity: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('taskSettingsComponent', { static: false }) taskSettingsComponent: TaskSettingsComponent;

  addFilterCompletedStep = 'addCatalogSettingsSupplierDF';
  taskInStore: SupplierCatalog;
  isPrivateShown = false;
  settingsVO: CatalogFeedSettingsVO;

  constructor(
    private catalogService: CatalogService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}

  ngAfterViewInit(): void {
    this.loadCatalog();
  }

  private loadCatalog(): void {
    const getTaskById$: Observable<SupplierCatalog> = this.store.select(getTaskById(+this.catalogId));
    const getCatalogById$: Observable<SupplierCatalogDetails> = this.catalogService.getSupplierCatalogById(
      this.catalogId
    );

    combineLatest([getTaskById$.pipe(omitNullOrUndefined()), getCatalogById$.pipe(omitNullOrUndefined())])
      .pipe(take(1))
      .subscribe(([taskById, catalogDetails]) => {
        this.taskInStore = taskById;
        const assignValue = this.mapAssignValue({
          name: catalogDetails?.name,
          description: catalogDetails?.description,
          tags: !catalogDetails?.tags || catalogDetails?.tags === '' ? [] : catalogDetails.tags.split(','),
          privateCode: catalogDetails?.privateCode,
          visibility: catalogDetails?.visibility,
          isHidden: catalogDetails?.isHidden,
          platformExceptions: catalogDetails?.platformExceptions,
          ...catalogDetails?.settings,
        });

        this.taskSettingsComponent.catalogForm.patchValue(assignValue);
        this.taskSettingsComponent.tags = assignValue.tags;
        this.taskSettingsComponent.approveNeededShown = catalogDetails?.settings?.approveNeeded;
      });
  }

  private mapAssignValue(
    catalogSettingsVO: Omit<CatalogSettingsVO, 'mainCategories'> & { mainCategories: string[] }
  ): any {
    const data = {
      name: catalogSettingsVO?.name,
      tags: catalogSettingsVO?.tags?.length ? catalogSettingsVO.tags.map((tag) => tag.tag) : [],
      isHidden: catalogSettingsVO.isHidden,
      visibility: catalogSettingsVO.visibility,
      privateCode: catalogSettingsVO.privateCode,
      description: catalogSettingsVO.description ?? '',
      platformExceptions: catalogSettingsVO.platformExceptions,
      settings: {
        possibleMargin: catalogSettingsVO.possibleMargin,
        language: catalogSettingsVO.language,
        shipping_type: catalogSettingsVO.shippingType ?? 'both',
        approveNeeded: catalogSettingsVO.approveNeeded ?? false,
        approveDescription: catalogSettingsVO.approveDescription,
        needResponse: catalogSettingsVO.needResponse,
        showPrices: catalogSettingsVO.showPrices,
        sendEmail: catalogSettingsVO.sendEmail ?? 'dont',
        warehouseLocation: catalogSettingsVO.warehouseLocation ?? '',
        mainCategories: catalogSettingsVO.mainCategories ?? [],
        feedSettings: catalogSettingsVO.feedSettings
          ? {
              backorder: catalogSettingsVO.feedSettings?.backorder,
              shipping: catalogSettingsVO.feedSettings?.shipping ?? true,
            }
          : undefined,
        translate_source: catalogSettingsVO.translate_source,
        translate_target: catalogSettingsVO.translate_target,
      },
    };

    return data;
  }

  getCatalogFormVO(): CatalogFormVO {
    const formValue: CatalogFormVO = this.taskSettingsComponent.getCatalogFormValue();
    formValue.id = this.catalogId;
    formValue.tags =
      this.taskSettingsComponent.tags?.length === 0 || !this.taskSettingsComponent.tags
        ? ['']
        : this.taskSettingsComponent.tags;
    if (!Utils.isNullOrUndefined(formValue.settings) && !formValue.settings.approveNeeded) {
      formValue.settings.showPrices = true;
    }
    const feedSettings = this.getFeedSettingsVO();
    formValue.settings.feedSettings = feedSettings.settings;
    formValue.possibleMargin = Utils.isNullOrUndefined(formValue.possibleMargin) ? null : formValue.possibleMargin;

    return formValue;
  }

  getFeedSettingsVO(): CatalogFeedSettingsVO {
    this.settingsVO = new CatalogFeedSettingsVO();
    this.settingsVO.id = this.catalogId;
    this.settingsVO.settings = this.taskSettingsComponent.catalogForm.get('settings.feedSettings').value;

    if (
      !Utils.isNullOrUndefined(this.taskSettingsComponent.catalogForm.value.settings.platformExceptions) &&
      this.taskSettingsComponent.catalogForm.value.settings.platformExceptions.length !== 0
    ) {
      this.settingsVO.platformExceptions = this.taskSettingsComponent.catalogForm.value.settings.platformExceptions;
    }

    return this.settingsVO;
  }

  isStepValid(): boolean {
    return this.taskSettingsComponent.isFormValid;
  }

  saveStep(): Observable<any> {
    this.taskSettingsComponent.catalogForm.markAllAsTouched();
    if (this.taskSettingsComponent.isFormValid) {
      return this.catalogService.updateCatalog(this.getCatalogFormVO());
    }
    this.notificationService.error(this.translateService.instant('CATALOG_EDITOR.REQUIRED'));
    return of(null);
  }

  triggerValidation(): void {
    this.taskSettingsComponent.catalogForm.markAllAsTouched();
  }
}
