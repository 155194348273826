import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UserService } from 'app/service/user/user.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-alibaba-refresh-token',
  templateUrl: './alibaba-refresh-token.component.html',
  styleUrls: ['./alibaba-refresh-token.component.scss'],
})
export class AlibabaRefreshTokenComponent implements OnInit {
  currentUser: any;

  constructor(private userService: UserService, private _cookieService: CookieService) {
    this.currentUser = userService.getCachedUser().id;
  }

  ngOnInit(): void {
    this.setCookieForUser();
    location.href =
      'https://crosstrade.alibaba.com/ecology/service.htm?spm=a219a.7386653.0.0.a06e9b71XlKNF5&appKey=32583301';
  }

  private setCookieForUser(): void {
    this._cookieService.set('s_userId', this.currentUser, null, '/', environment.cookieDomain);
  }
}
