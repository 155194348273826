import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, of, ReplaySubject, switchMap } from 'rxjs';
import { shipsFromPreferenceSelector, shipsToPreferenceSelector } from '../../store/preferences/preferences.selector';
import { omitNullOrUndefinedArray } from '../../utils/operator/omit-null-or-undefined-array';
import { map, take, tap } from 'rxjs/operators';
import { Utils } from '../../utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { hasRoleSelector } from '../../store/user/user.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { gettingStartedUncompletedStepsSelector } from '../../store/getting-started/getting-started.selector';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { PreferenceDialogService } from './preference-dialog.service';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ShippingPreferencesService {
  private _hasPreferences$ = new BehaviorSubject<boolean>(null);
  hasPreferences$ = combineLatest({
    authenticated: this.store.select(isAuthenticatedSelector),
    admin: this.store.select(hasRoleSelector(RolesEnum.ADMIN)),
    hasRole: this.store.select(hasRoleSelector(RolesEnum.RETAILER)),
    gsReady: this.store
      .select(gettingStartedUncompletedStepsSelector, RolesEnum.RETAILER)
      .pipe(map((steps) => !steps?.length)),
    hasPreference: this._hasPreferences$.asObservable(),
  }).pipe(
    map(({ hasPreference, hasRole, gsReady, authenticated, admin }) => {
      if (!authenticated || !hasRole || !gsReady || admin) {
        return true;
      }
      return hasPreference;
    }),
    distinctUntilChanged()
  );

  constructor(private store: Store<AppState>) {
    this.initHasShippingPreferencesSubscription();
  }

  private initHasShippingPreferencesSubscription(): void {
    combineLatest([this.store.select(shipsToPreferenceSelector), this.store.select(shipsFromPreferenceSelector)])
      .pipe(
        untilDestroyed(this),
        omitNullOrUndefinedArray(),
        map((preferences: [string[], string[]]): boolean =>
          preferences.every((preference: string[]) => !Utils.isNullOrUndefinedOrLengthZero(preference))
        )
      )
      .subscribe((hasPreferences: boolean): void => {
        this._hasPreferences$.next(hasPreferences);
      });
  }
}
