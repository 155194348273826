export class DeleteModalVO {
  constructor(
    title: string,
    message: string = 'Are you sure you want to delete?',
    deleteMessage?: string,
    deleteString: string = 'delete'
  ) {
    this._title = title;
    this._message = message;
    this._deleteMessage = deleteMessage;
    this._deleteString = deleteString;
  }

  private _title: string;
  private _message: string;
  private _deleteMessage: string;
  private _deleteString: string;

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  get deleteMessage(): string {
    return this._deleteMessage;
  }

  set deleteMessage(value: string) {
    this._deleteMessage = value;
  }

  get deleteString(): string {
    return this._deleteString;
  }

  set deleteString(value: string) {
    this._deleteString = value;
  }
}
