import { Injectable } from '@angular/core';
import { firstLevelCategoriesSelector } from '../../../../store/category/category.selector';
import { map } from 'rxjs/operators';
import { CategoryName, CategoryVo } from '../../../../vo/category-vo';
import { TagOption } from '../../tag-selector/model/tag-option';
import { transformToCdnUrl } from '../../../../utils/transform-to-cdn-url';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Utils } from '../../../../utils/utils';
import { TranslationWatcherService } from '../../../../service/translation/translation-watcher.service';
import { omitNullOrUndefinedArray } from '../../../../utils/operator/omit-null-or-undefined-array';

@UntilDestroy()
@Injectable()
export class CategoryOptionsService {
  public _options$ = new BehaviorSubject<TagOption<number>[]>([]);

  constructor(private store: Store<AppState>, private translateWatcherService: TranslationWatcherService) {
    this.init();
  }

  get options$(): Observable<TagOption<number>[]> {
    return this._options$.asObservable();
  }

  private init(): void {
    combineLatest([this.store.select(firstLevelCategoriesSelector), this.translateWatcherService.language$])
      .pipe(
        omitNullOrUndefinedArray(),
        untilDestroyed(this),
        map(([categories, language]: [CategoryVo[], string]) => this.mapCategoriesIntoTagOptions(categories, language))
      )
      .subscribe((options: TagOption<number>[]): void => this._options$.next(options));
  }

  private getNameOfCategory(category: CategoryVo, language: string): string {
    let name = category.categoryName?.find((item: CategoryName): boolean => item.languageCode === language);

    if (!Utils.isNullOrUndefined(name)) {
      return name.name;
    }

    name = category.categoryName?.find((item: CategoryName): boolean => item.languageCode.toUpperCase() === 'EN');

    if (!Utils.isNullOrUndefined(name)) {
      return name.name;
    }

    return category.name;
  }

  private getCategoryIcon(category: CategoryVo): string {
    return transformToCdnUrl(`category-images/embedded/${category.id}.svg`);
  }

  private mapCategoryIntoTagOption(category: CategoryVo, language: string): TagOption<number> {
    return {
      label: this.getNameOfCategory(category, language),
      value: category.id,
      icon: this.getCategoryIcon(category),
    };
  }

  private mapCategoriesIntoTagOptions(categories: CategoryVo[], language: string): TagOption<number>[] {
    return categories.map((category: CategoryVo) => this.mapCategoryIntoTagOption(category, language));
  }
}
