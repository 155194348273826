<div [fxLayout]="layout" fxLayoutAlign="start center" [fxLayoutGap]="layout === 'row' ? '8px' : '20px'">
  <button mat-button [class]="prevButtonClass" (click)="prevClick.emit()" *ngIf="hasPrevButton">
    {{ prevButtonLabel | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [class]="nextButtonClass"
    (click)="nextClick.emit()"
    [disabled]="nextDisabled"
  >
    {{ nextButtonLabel | translate }}
  </button>
</div>
