import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { CustomOptionDto } from '../../shared/components/user-feedback/user-feedback.component';
import { getSelectedLanguageCode } from '../../store/language/language.selector';
import { map, switchMap } from 'rxjs/operators';
import { categorySelector } from '../../store/category/category.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { CategoryVo } from '../../vo/category-vo';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackService {
  private readonly url = '/User/Feedback';
  bestDescribesCustomSource$: Observable<Record<string, CustomOptionDto[]>>;

  constructor(private springRestService: SpringRestService, private store: Store<AppState>) {
    this.initBestDescribesCustomSource();
  }

  getFeedbacks(group: FeedbackGroup): Observable<FullFeedbackDto[]> {
    return this.springRestService.get(MicroserviceNames.USER, `${this.url}/${group}`, undefined, true);
  }

  getQuestions(group: FeedbackGroup): Observable<RoleFeedbackDto[]> {
    return this.springRestService.get(MicroserviceNames.USER, `${this.url}/${group}/Questions`, undefined, true);
  }

  getAnswers(group: FeedbackGroup): Observable<UserFeedbackDto[]> {
    return this.springRestService.get(MicroserviceNames.USER, `${this.url}/${group}/Answers`, undefined, true);
  }

  saveFeedbacks(feedbackDtos: UserFeedbackDto[], group: FeedbackGroup): Observable<void> {
    return this.springRestService.post(MicroserviceNames.USER, `${this.url}/${group}`, feedbackDtos, undefined, true);
  }

  deleteFeedbacksByUser(group: FeedbackGroup): Observable<void> {
    return this.springRestService.delete(MicroserviceNames.USER, `${this.url}/${group}`, undefined, true);
  }

  private initBestDescribesCustomSource(): void {
    this.bestDescribesCustomSource$ = this.store.select(getSelectedLanguageCode).pipe(
      switchMap((languageCode) =>
        this.store.select(categorySelector).pipe(
          map((parentCategory) => {
            return {
              ['BEST_DESCRIBES']: this.mapToCustomOptions(parentCategory.children, languageCode),
            };
          })
        )
      )
    );
  }

  private mapToCustomOptions(mainCategories: CategoryVo[], languageCode: string): CustomOptionDto[] {
    return mainCategories.map((category) => {
      const currentLanguageCategoryName = category.categoryName.find(
        (categoryName) => categoryName.languageCode === languageCode
      );
      return {
        value: currentLanguageCategoryName.name,
        id: category.id,
      };
    });
  }
}

export interface FullFeedbackDto {
  feedback: RoleFeedbackDto;
  options: FeedbackDto[];
}

export interface FeedbackDto {
  id: number;
  key: string;
  roleFeedbackId: number;
  date: string;
}

export interface UserFeedbackDto {
  id: number;
  userId: number;
  feedbackId: number;
  message: string;
  date: string;
}

export interface RoleFeedbackDto {
  id: number;
  question: string;
  feedbackGroupId: number;
  type: FeedbackType;
}

export type FeedbackGroup = 'BUSINESS_DETAILS_RETAILER' | 'BUSINESS_DETAILS_SUPPLIER';
export type FeedbackType = 'select' | 'input';
