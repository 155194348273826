import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../rest/rest.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionCancelService {
  constructor(private restService: RestService) {}
  public getReasons(): Observable<ReasonVo[]> {
    return this.restService.get('SubscriptionCancelService/getReasons').pipe(map((resp) => resp.getData()));
  }
  public addReasonsToEcom(data: AddReason): Observable<any> {
    return this.restService.post('SubscriptionCancelService/addReasonToEcom', data).pipe(map((resp) => resp.getData()));
  }
}

interface AddReason {
  ecomId: number;
  comment: string;
  reasons: string[];
}

interface ReasonVo {
  id: number;
  reason: string;
}
