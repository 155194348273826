import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { FilterGroup } from '../model/filter-group';
import { FilterGroupConditionEnum } from '../model/filter-group-condition';
import { FilterItem } from '../model/filter-item';
import { FilterBaseModifiersService } from './filter-base-modifiers.service';

@Injectable({ providedIn: 'root' })
export class FilterMapperService {
  constructor(private filterBaseModifiersService: FilterBaseModifiersService) {}

  mapFilterToFilterGroup(filter: any[]): FilterGroup {
    const mapped: FilterGroup = { id: uuidv4().toString(), filterItems: [], conditions: [], children: [] };

    const mapper = (item: any, groupId: string, depth: number) => {
      if (Array.isArray(item)) {
        const newGroupId = uuidv4().toString();
        this.filterBaseModifiersService.addNewGroupToTreeWithoutItem(mapped, groupId, newGroupId, false);
        item.forEach((child) => mapper(child, newGroupId, depth + 1));
      } else if (typeof item === 'string') {
        this.handleCondition(mapped, groupId, item);
      } else {
        this.handleFilterItem(depth, mapped, groupId, item);
      }
    };
    if (filter.length !== 0) {
      filter[0].forEach((child) => mapper(child, mapped.id, 0));
    }
    return mapped;
  }

  mapFilerGroupToFilter(group: FilterGroup): any {
    if (group.filterItems.length > 0) {
      return this.mapFilterItemsForFilter(group.filterItems, group.conditions);
    } else if (group.children.length > 0) {
      return group.children
        .map((child) => this.mapFilerGroupToFilter(child))
        .reduce(
          (array, item, currentIndex) =>
            this.filterGroupsReducer(array, item, currentIndex, group.conditions, group.children),
          []
        );
    } else {
      return [];
    }
  }

  private filterGroupsReducer(
    array,
    item,
    currentIndex: number,
    conditions: FilterGroupConditionEnum[],
    children: FilterGroup[]
  ): any[] {
    array.push(item);
    if (conditions.length > 0 && currentIndex !== children.length - 1) {
      array.push(conditions[currentIndex] === FilterGroupConditionEnum.AND ? 'and' : 'or');
    }
    return array;
  }

  private mapFilterItemsForFilter(items: FilterItem[], conditions: FilterGroupConditionEnum[]): any[] {
    return items
      .map((item) => ({ key: item.field, values: item.values, condition: item.condition, locked: item.locked }))
      .reduce((array, item, currentIndex) => {
        array.push(item);
        if (conditions.length > 0 && currentIndex !== items.length - 1) {
          array.push(conditions[0] === FilterGroupConditionEnum.AND ? 'and' : 'or');
        }
        return array;
      }, []);
  }

  private handleCondition(group: FilterGroup, groupId: string, item: any): void {
    this.filterBaseModifiersService.addConditionToGroup(
      group,
      groupId,
      item === 'and' ? FilterGroupConditionEnum.AND : FilterGroupConditionEnum.OR
    );
  }

  private handleFilterItem(depth: number, group: FilterGroup, groupId: string, item: any): void {
    let newGroupId: string;
    if (depth === 0) {
      newGroupId = uuidv4().toString();
      this.filterBaseModifiersService.addNewGroupToTreeWithoutItem(group, groupId, newGroupId, false);
    }
    this.filterBaseModifiersService.addNewItemToTree(
      group,
      !!newGroupId ? newGroupId : groupId,
      this.mapFilterObjectToFilterItem(item.values, item.key, item.condition, item.locked),
      false
    );
  }

  private mapFilterObjectToFilterItem(values: string[], field: string, condition: string, locked = false): FilterItem {
    return { id: uuidv4().toString(), values, condition, field, fieldStructures: [], locked };
  }
}
