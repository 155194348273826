<form id="FTPUploadCard" #connectFileForm="ngForm" novalidate class="simple-form">
  <div *ngIf="domains" fxLayout="column" fxLayoutAlign="start start">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'TASKWIZARD.ADDFILES.CONNECT_STORE.DOMAIN' | translate }}</mat-label>
      <mat-select
        name="ecoms"
        [(ngModel)]="ecomId"
        placeholder="{{ 'TASKWIZARD.ADDFILES.CONNECT_STORE.PLACEHOLDER' | translate }}"
      >
        <mat-option *ngFor="let item of domains" [value]="item.id">
          {{ item.domain }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
