<div class="prod-sample-list" *ngIf="prodSampleVo?.fileId">
  <div class="header" fxLayoutAlign="space-between center">
    <div class="title">
      {{ 'TASKWIZARD.ADDFILES.MAPPING.FIELDS_IN' | translate }} <span class="h5">{{ prodSampleVo.fileName }}</span>
    </div>
    <div fxLayoutAlign="center center" class="paginator">
      <button mat-icon-button [disabled]="(index | async)?.index === 0" (click)="prevProduct()">
        <mat-icon class="s-20">chevron_left</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!taskWizardSampleService.hasNextItem" (click)="nextProduct()">
        <mat-icon class="s-20">chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <div
    [class.p-20]="taskWizardSampleService.hasError || (!productFields && (taskWizardSampleService.isLoading$ | async))"
  >
    <ng-container *ngIf="!(taskWizardSampleService.isLoading$ | async); else loading">
      <ng-container *ngIf="!taskWizardSampleService.hasError; else error">
        <mat-list cdkDropList class="list" [cdkDropListData]="productFields" *ngIf="productFields?.length; else noData">
          <mat-list-item
            cdkDrag
            class="item"
            *ngFor="let productField of productFields"
            [cdkDragData]="{
              path: productField.name,
              value: productField.value,
              fileId: prodSampleVo.fileId,
              chipColor: prodSampleVo.chipColor
            }"
          >
            <mat-chip
              *cdkDragPreview
              [ngStyle]="{ background: prodSampleVo.chipColor.backgroundColor, color: prodSampleVo.chipColor.color }"
              class="drag-and-drop-chip"
            >
              <div class="typography-label">{{ productField.name }}</div>
            </mat-chip>
            <mat-chip
              *cdkDragPlaceholder
              [ngStyle]="{ background: prodSampleVo.chipColor.backgroundColor, color: prodSampleVo.chipColor.color }"
              class="drag-and-drop-chip"
            >
              <div class="typography-label">{{ productField.name }}</div>
            </mat-chip>
            <app-file-field
              mat-line
              [path]="productField.name"
              [value]="productField.value"
              [fileId]="prodSampleVo.fileId"
              [chipColor]="prodSampleVo.chipColor"
            >
            </app-file-field>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noData>
  <div class="px-20 py-12">{{ 'SUPPLIER_CATALOG.NO_PRODUCT_SAMPLE' | translate }}</div>
</ng-template>

<ng-template #loading><app-loading-spinner [size]="40"></app-loading-spinner></ng-template>

<ng-template #error><app-error-message></app-error-message></ng-template>
