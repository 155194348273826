import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-followed-suppliers',
  templateUrl: './no-followed-suppliers.component.html',
  styleUrls: ['./no-followed-suppliers.component.scss'],
})
export class NoFollowedSuppliersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
