<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div *ngIf="saveMappingWarning" class="notification warning">
    <div class="msg-container">
      <mat-icon class="type-icon">warning</mat-icon>
      <ng-container>
        {{ 'RETAILER_TASK_WIZARD.SHOPIFY.YOU_HAVE' | translate }}
      </ng-container>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
    <div class="mapping-container" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
      <form #productDetatailsForm="ngForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
        <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BASIC' | translate }}</div>
        <div class="product-details">
          <!--ID-->

          <div
            *ngIf="mappingData.productIds"
            id="product-id-container"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
          >
            <div class="mr-0">
              <div
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'ID'"
                [adminCommentComments]="comments"
              ></div>
            </div>
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="ID_{{ prod.id }}"
              name="productId_{{ prod.id }}"
              [(ngModel)]="prod.value.chips"
              [(operations)]="prod.value.operations"
              *ngFor="let prod of mappingData.productIds"
              fxFlex="{{ 100 / mappingData.productIds.length }}"
              title="ID"
              placeholder="ID"
            ></app-droppable-input>
          </div>

          <!--Title-->
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'TITLE'"
              [adminCommentComments]="comments"
              #normalProcess
              [required]="true"
              id="TITLE"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.TITLE_PLACE' | translate }}"
              name="TITLE"
              [(ngModel)]="mappingData.singleFields.TITLE.chips"
              [(operations)]="mappingData.singleFields.TITLE.operations"
              name="ngModelTest"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.TITLE_PLACE' | translate }}"
            ></app-droppable-input>
          </div>

          <!--Description-->
          <div id="description-container">
            <app-droppable-multiline-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'DESCRIPTION'"
              [adminCommentComments]="comments"
              id="DESCRIPTION"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.DESC_PLACE' | translate }}"
              [(chips)]="mappingData.singleFields.DESCRIPTION.chips"
              [(operations)]="mappingData.singleFields.DESCRIPTION.operations"
            ></app-droppable-multiline-input>
          </div>

          <div id="product-images-container" fxLayout="column">
            <div
              id="product-images-info"
              class="product-imgs"
              fxLayout="row"
              fxLayoutAlign="start"
              ngModelGroup="image"
              *ngFor="let img of mappingData.images; last as isLast; let i = index"
            >
              <!--Image URL-->
              <div class="mr-0">
                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'IMAGES'"
                  [adminCommentComments]="comments"
                ></div>
              </div>

              <app-droppable-input
                #imagesURLProcess
                id="IMAGES"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.IMAGE_URL_PLACE' | translate }}"
                name="IMAGES-{{ i }}"
                [(ngModel)]="img.url.chips"
                [(operations)]="img.url.operations"
                [style.width.%]="95"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.IMAGE_URL_PLACE' | translate }}"
              ></app-droppable-input>

              <div class="icon-holder" fxLayoutAlign="start center">
                <button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeProdImage(i)">
                  <mat-icon aria-label="Delete icon" [style.width.%]="5"> delete </mat-icon>
                </button>
                <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProdImage()">
                  <mat-icon aria-label="new Image icon" [style.width.%]="5"> add_circle_outline </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <app-custom-divider [margin]="'32px'"></app-custom-divider>
          <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.ORGAN' | translate }}</div>
          <div id="product-categories" fxLayout="column" fxLayoutAlign="start stretch">
            <!--Vendor-->
            <div id="product-brands">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'BRAND'"
                [adminCommentComments]="comments"
                #normalProcess
                id="BRAND"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VENDOR_PLACE' | translate }}"
                name="BRAND"
                [(ngModel)]="mappingData.singleFields.BRAND.chips"
                [(operations)]="mappingData.singleFields.BRAND.operations"
                [style.width.%]="100"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VENDOR_PLACE' | translate }}"
              ></app-droppable-input>
            </div>

            <!--Collections-->

            <div id="product-collections">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'COLLECTIONS'"
                [adminCommentComments]="comments"
                #normalProcess
                id="CATEGORY"
                title="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.CATEGORY_LABEL' | translate }}"
                name="CATEGORY"
                [(ngModel)]="mappingData.singleFields.CATEGORY.chips"
                [(operations)]="mappingData.singleFields.CATEGORY.operations"
                [style.width.%]="100"
                placeholder="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.CATEGORY_LABEL' | translate }}"
              ></app-droppable-input>
            </div>
          </div>
          <app-custom-divider [margin]="'32px'"></app-custom-divider>
          <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT' | translate }}</div>

          <div id="product-variants-container" fxLayout="column" fxLayoutAlign="start">
            <div id="product-price-and-stuff-container">
              <div fxLayout="row" fxLayoutAlign="start">
                <!--Price-->
                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'PRICE'"
                  [adminCommentComments]="comments"
                ></div>
                <app-droppable-input
                  #normalProcess
                  [required]="priceFieldIsRequired"
                  id="PRICE"
                  title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRICE_PLACE' | translate }}"
                  name="PRICE"
                  [isPriceSettings]="true"
                  [(ngModel)]="mappingData.singleFields.PRICE.chips"
                  [(settings)]="mappingData.singleFields.PRICE.settings"
                  [(operations)]="mappingData.singleFields.PRICE.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRICE_PLACE' | translate }}"
                ></app-droppable-input>

                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'DISCOUNTED_PRICE'"
                  [adminCommentComments]="comments"
                ></div>
                <app-droppable-input
                  #normalProcess
                  id="DISCOUNTED_PRICE"
                  title="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.DISCOUNTED_PRICE_LABEL' | translate }}"
                  name="DISCOUNTED_PRICE"
                  [isPriceSettings]="true"
                  [(ngModel)]="mappingData.singleFields.DISCOUNTED_PRICE.chips"
                  [(settings)]="mappingData.singleFields.DISCOUNTED_PRICE.settings"
                  [(operations)]="mappingData.singleFields.DISCOUNTED_PRICE.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.DISCOUNTED_PRICE_LABEL' | translate }}"
                ></app-droppable-input>
              </div>
              <div fxLayout="row" fxLayoutAlign="start">
                <!--Inventory Quantity-->
                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'QTY'"
                  [adminCommentComments]="comments"
                ></div>
                <app-droppable-input
                  #normalProcess
                  id="QTY"
                  title="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.QTY_LABEL' | translate }}"
                  name="QTY"
                  [(ngModel)]="mappingData.singleFields.QTY.chips"
                  [(operations)]="mappingData.singleFields.QTY.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.QTY_LABEL' | translate }}"
                ></app-droppable-input>

                <!--Barcode-->
                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'UPC'"
                  [adminCommentComments]="comments"
                ></div>

                <app-droppable-input
                  #normalProcess
                  id="UPC"
                  title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BARCODE_PLACE' | translate }}"
                  name="UPC"
                  [(ngModel)]="mappingData.singleFields.UPC.chips"
                  [(operations)]="mappingData.singleFields.UPC.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BARCODE_PLACE' | translate }}"
                ></app-droppable-input>
              </div>

              <div id="product-basic-fields" fxLayout="row" fxLayoutAlign="start">
                <!--Weight-->
                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'WEIGHT'"
                  [adminCommentComments]="comments"
                ></div>
                <app-droppable-input
                  #normalProcess
                  id="WEIGHT"
                  [style.width.%]="50"
                  title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                  name="WEIGHT"
                  [isWeightSettings]="true"
                  [(ngModel)]="mappingData.singleFields.WEIGHT.chips"
                  [(operations)]="mappingData.singleFields.WEIGHT.operations"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                ></app-droppable-input>

                <!--Variant SKU-->
                <div
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'SKU'"
                  [adminCommentComments]="comments"
                ></div>

                <app-droppable-input
                  #normalProcess
                  id="SKU"
                  title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_SKU_PLACE' | translate }}"
                  name="SKU"
                  [style.width.%]="50"
                  [(ngModel)]="mappingData.singleFields.SKU.chips"
                  [(operations)]="mappingData.singleFields.SKU.operations"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_SKU_PLACE' | translate }}"
                ></app-droppable-input>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start">
          <!--Image URL-->
          <div
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'V_IMAGES'"
            [adminCommentComments]="comments"
          ></div>

          <app-droppable-input
            #normalProcess
            id="V_IMAGES"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_IMAGE_URL_PLACE' | translate }}"
            name="V_IMAGES"
            [(ngModel)]="mappingData.singleFields.V_IMAGES.chips"
            [(operations)]="mappingData.singleFields.V_IMAGES.operations"
            [style.width.%]="100"
            placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_IMAGE_URL_PLACE' | translate }}"
          ></app-droppable-input>
        </div>

        <div [hidden]="true">
          <app-droppable-input
            #normalProcess
            id="SHOPIFY_PUBLISHED"
            title="SHOPIFY_PUBLISHED"
            name="SHOPIFY_PUBLISHED"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_PUBLISHED.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_PUBLISHED.operations"
            placeholder="SHOPIFY_PUBLISHED"
          ></app-droppable-input>
        </div>

        <form
          #shopifyOptionsForm="ngForm"
          fxLayout="column"
          class="options-container"
          fxLayoutAlign="start"
          fxLayoutGap="8px"
        >
          <div id="shopify-options-1" fxLayout="row" fxLayoutAlign="start">
            <!--OPTION NAME 1-->
            <div
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_1'"
              [adminCommentComments]="comments"
            ></div>

            <app-droppable-input
              fxFlex="30"
              #normalProcess
              id="SHOPIFY_OPTION_NAME_1"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
              name="SHOPIFY_OPTION_NAME_1"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
            ></app-droppable-input>

            <!--OPTION VALUE 1-->
            <div
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_1'"
              [adminCommentComments]="comments"
            ></div>
            <app-droppable-input
              fxFlex="80"
              #normalProcess
              id="SHOPIFY_OPTION_VALUE_1"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_1_PLACE' | translate }}"
              name="SHOPIFY_OPTION_VALUE_1"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.operations"
              [style.width.%]="30"
              placeholder="OPTION VALUE 1"
            ></app-droppable-input>
          </div>
          <div id="shopify-options-2" fxLayout="row" fxLayoutAlign="start">
            <!--OPTION NAME 2-->
            <div
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_2'"
              [adminCommentComments]="comments"
            ></div>
            <app-droppable-input
              fxFlex="30"
              #normalProcess
              id="SHOPIFY_OPTION_NAME_2"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_2_PLACE' | translate }}"
              name="SHOPIFY_OPTION_NAME_2"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_2_PLACE' | translate }}"
            ></app-droppable-input>

            <!--OPTION VALUE 2-->
            <div
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_2'"
              [adminCommentComments]="comments"
            ></div>
            <app-droppable-input
              fxFlex="80"
              #normalProcess
              id="SHOPIFY_OPTION_VALUE_2"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_2_PLACE' | translate }}"
              name="SHOPIFY_OPTION_VALUE_2"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_2_PLACE' | translate }}"
            ></app-droppable-input>
          </div>
          <div id="shopify-options-3" fxLayout="row" fxLayoutAlign="start">
            <!--OPTION NAME 3-->
            <div
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_3'"
              [adminCommentComments]="comments"
            ></div>
            <app-droppable-input
              fxFlex="30"
              #normalProcess
              id="SHOPIFY_OPTION_NAME_3"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_3_PLACE' | translate }}"
              name="SHOPIFY_OPTION_NAME_3"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_3_PLACE' | translate }}"
            ></app-droppable-input>

            <!--OPTION VALUE 3-->
            <div
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_3'"
              [adminCommentComments]="comments"
            ></div>
            <app-droppable-input
              fxFlex="80"
              #normalProcess
              id="SHOPIFY_OPTION_VALUE_3"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_3_PLACE' | translate }}"
              name="SHOPIFY_OPTION_VALUE_3"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_3_PLACE' | translate }}"
            ></app-droppable-input>
          </div>

          <app-dimension-fields
            #dimensionComponent
            [widthChip]="mappingData.singleFields.WIDTH"
            [heightChip]="mappingData.singleFields.HEIGHT"
            [lengthChip]="mappingData.singleFields.LENGTH"
            [taskId]="taskId"
            [comments]="comments"
          ></app-dimension-fields>
        </form>

        <app-custom-divider [margin]="'32px'"></app-custom-divider>
        <div class="h5 mb-24">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SEO' | translate }}</div>

        <!--Handle-->
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_HANDLE'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SHOPIFY_HANDLE"
            title="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.URL_LABEL' | translate }}"
            name="SHOPIFY_HANDLE"
            [(ngModel)]="mappingData.singleFields.URL.chips"
            [(operations)]="mappingData.singleFields.URL.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.URL_LABEL' | translate }}"
          ></app-droppable-input>
        </div>

        <!--Page title (website seo)-->
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PAGE_TITLE_PLACE' | translate }}"
            name="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            [(ngModel)]="mappingData.singleFields.SEO_PAGE_TITLE.chips"
            [(operations)]="mappingData.singleFields.SEO_PAGE_TITLE.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PAGE_TITLE_PLACE' | translate }}"
          ></app-droppable-input>
        </div>

        <!--Meta Description (website seo)-->
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-multiline-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_META_DESC'"
            [adminCommentComments]="comments"
            id="SHOPIFY_META_DESC"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.META_DESC_PLACE' | translate }}"
            name="SHOPIFY_META_DESC"
            [(chips)]="mappingData.singleFields.SEO_META_DESCRIPTION.chips"
            [(operations)]="mappingData.singleFields.SEO_META_DESCRIPTION.operations"
          >
          </app-droppable-multiline-input>
        </div>
      </form>
    </div>
  </div>
</div>
<button mat-button class="syncee-black-flat-button" (click)="takonySave()" *ngIf="isAdmin">
  <mat-icon class="type-icon">visibility_off</mat-icon>{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SAVE' | translate }}
</button>
