import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { InnerContentComponent } from 'app/layout/components/inner-content/inner-content.component';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';
import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import { PageBackButtonComponent } from 'app/shared/components/page-back-button/page-back-button.component';
import { OverflowDirective } from 'app/shared/directives/overflow.directive';
import { AiChatModule } from '../../../main/ai-chat/ai-chat.module';
import { MinimizedChatTaskbarComponent } from '../../../main/minimized-chat-taskbar/minimized-chat-taskbar.component';
import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';
import { CustomDividerComponent } from '../../../shared/components/custom-divider/custom-divider.component';
import { EcomSelectorDropdownComponent } from '../../../shared/components/ecom-selector-dropdown/ecom-selector-dropdown.component';
import { SynceeCtaComponent } from '../../../shared/components/syncee-cta/syncee-cta.component';
import { FilterRolesDirective } from '../../../shared/directives/filter-roles.directive';
import { ScrollToTopDirective } from '../../../shared/directives/scroll-to-top.directive';
import { CategorySelectorModule } from '../../components/category-selector/category-selector.module';
import { FilterModule } from '../../components/filter/filter.module';
import { MobileCenterModule } from '../../components/mobile-center/mobile-center.module';
import { MobileMainMenuModule } from '../../components/mobile-main-menu/mobile-main-menu.module';
import { OnboardModule } from '../../components/onboard/onboard.module';
import { SubmenuModule } from '../../components/submenu/submenu.module';
import { StickyOnScrollDirective } from '../directives/sticky-on-scroll.directive';

@NgModule({
  declarations: [VerticalLayout1Component, StickyOnScrollDirective, InnerContentComponent],
  imports: [
    RouterModule,
    FuseSharedModule,
    FuseSidebarModule,
    ContentModule,
    FooterModule,
    NavbarModule,
    ToolbarModule,
    OnboardModule,
    FilterModule,
    CategorySelectorModule,
    MatListModule,
    BreadcrumbComponent,
    FilterRolesDirective,
    EcomSelectorDropdownComponent,
    MobileMainMenuModule,
    MatTabsModule,
    SubmenuModule,
    MobileCenterModule,
    MinimizedChatTaskbarComponent,
    PageBackButtonComponent,
    ScrollToTopDirective,
    SynceeCtaComponent,
    AiChatModule,
    CustomDividerComponent,
    TranslateModule,
    OverflowDirective,
    PortalModule,
  ],
  exports: [VerticalLayout1Component],
})
export class VerticalLayout1Module {}
