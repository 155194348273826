import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RegistrationOpenDirective } from 'app/shared/directives/registration-open-directive.directive';
import { SanitizeHtmlPipe } from 'app/shared/pipes/sanitize-html.pipe';
import { TranslateConditionallyPipe } from '../../pipes/translate-conditionally.pipe';
import { FaqItem } from '../../../vo/faq/faq-item';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [
    MatExpansionModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    MatIconModule,
    RegistrationOpenDirective,
    SanitizeHtmlPipe,
    TranslateConditionallyPipe,
  ],
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent {
  @Input() faqs: FaqItem[];
  @Input() title?: string;
  @Input() translateTitle = true;
  @Input() translateValue = true;
  openedPanel = -1;
}
