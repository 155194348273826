import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable()
export class WizardCategoryHeaderService {
  private _mapToSearchTermsSubject = new BehaviorSubject<string>('');
  private _mappedSearchTermsSubject = new BehaviorSubject<string>('');
  private _bulkStateSubject = new BehaviorSubject<boolean>(false);
  private _categoryFilterSubject = new BehaviorSubject<WizardCategoryMappingFilter>('all');
  private _addInBulkSubject = new Subject<void>();
  private _removeInBulkSubject = new Subject<void>();
  private _clearSelectedMappingSubject = new Subject<void>();
  public titleConfig: WizardCategoryMappingTitleConfig = {
    customCategoriesTitle: 'Shop categories.',
    customCategoriesTooltip: 'You can filter through the categories you created.',
    originalCategoriesTitle: 'Original categories.',
    originalCategoriesTooltip: 'You can find category mappings by searching among syncee categories.',
  };
  constructor() {}

  public get mapToSearchTerm(): Observable<string> {
    return this._mapToSearchTermsSubject.asObservable();
  }

  public get mappedSearchTerm(): Observable<string> {
    return this._mappedSearchTermsSubject.asObservable();
  }

  public get categoryFilter(): Observable<WizardCategoryMappingFilter> {
    return this._categoryFilterSubject.asObservable();
  }

  public get isBulkEditOn(): Observable<boolean> {
    return this._bulkStateSubject.asObservable();
  }

  public get addInBulkClicked(): Observable<void> {
    return this._addInBulkSubject.asObservable();
  }

  public get removeInBulkClicked(): Observable<void> {
    return this._removeInBulkSubject.asObservable();
  }
  public get clearSelectedClicked(): Observable<void> {
    return this._clearSelectedMappingSubject.asObservable();
  }

  public setMapToSearchTerm(value: string): void {
    this._mapToSearchTermsSubject.next(value);
  }

  public setMappedSearchTerm(value: string): void {
    this._mappedSearchTermsSubject.next(value);
  }

  public setCategoryFilter(value: WizardCategoryMappingFilter): void {
    this._categoryFilterSubject.next(value);
  }

  public toggleBulkEdit(): void {
    this._bulkStateSubject.next(!this._bulkStateSubject.value);
  }

  public addInBulk(): void {
    this._addInBulkSubject.next();
  }

  public removeInBulk(): void {
    this._removeInBulkSubject.next();
  }

  public clearSelectedMapping(): void {
    this._clearSelectedMappingSubject.next();
  }

  public get allFilterOption(): WizardCategoryMappingFilter {
    return 'all';
  }

  public get notCategorizedFilterOption(): WizardCategoryMappingFilter {
    return 'not_categorized';
  }

  public setTitleConfig(config: Partial<WizardCategoryMappingTitleConfig>): void {
    this.titleConfig = { ...this.titleConfig, ...config };
  }
}

export type WizardCategoryMappingFilter = 'all' | 'not_categorized';
export interface WizardCategoryMappingTitleConfig {
  customCategoriesTitle: string;
  customCategoriesTooltip: string;
  originalCategoriesTitle: string;
  originalCategoriesTooltip: string;
}
