<form #settingsPriceForm="ngForm" fxLayout="column" fxLayoutGap="5px">
  <h2>{{ 'PIM.SETTINGS2.DIMENSIONS' | translate }}</h2>
  <mat-form-field class="currency-form-field">
    <mat-select placeholder="{{ 'PIM.SETTINGS2.UNIT' | translate }}" name="dimUnit" [(ngModel)]="data.dimUnit" required>
      <mat-option *ngFor="let c of data.dimensions | keyvalue" [value]="c.key">{{ c.key + ' ' + c.value }}</mat-option>
    </mat-select>
    <mat-error>{{ 'PIM.SETTINGS2.YOU_HAVE' | translate }}</mat-error>
  </mat-form-field>
  <button mat-button class="syncee-blue-button" fxFlexAlign="end" (click)="submit()">
    {{ 'PIM.SETTINGS2.SAVE' | translate }}
  </button>
</form>
