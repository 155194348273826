<div class="products-container">
  <div class="content">
    <div *appHasPermissions="[SCOPES.SEARCH_TYPE_EDIT]" [style.padding-left.px]="10">
      <app-search-product-type-selector
        [loading]="areProductsLoading"
        [value]="searchProductType$ | async"
        (valueChange)="searchProductType$.next($event)"
      ></app-search-product-type-selector>
    </div>
    <app-skeleton-product-card-list
      *ngIf="areProductsLoading && _pagination.from === 0 && !hasError"
      [productCardWidth]="productCardWidth"
      [numberOfProducts]="pagination?.size"
      [needsFillers]="true"
    ></app-skeleton-product-card-list>

    <ng-container *ngIf="!hasError; else error">
      <app-product-card-list
        [products]="products"
        [numberOfProducts]="productNumbers"
        [areProductsLoading]="areProductsLoading"
        [selectable]="selectable"
        [ecomCurrency]="ecomCurrency"
        [hasAds]="true"
        [adsPositions]="adsPositions"
        [isFreePlan]="isFreePlan"
        [needsFillers]="true"
        [from]="_pagination.from"
        [productCardWidth]="productCardWidth"
        [generateMicrodata]="true"
      ></app-product-card-list>
    </ng-container>

    <ng-container *ngIf="!areProductsLoading && products?.length === 0">
      <ng-container *ngTemplateOutlet="noresult"></ng-container>
    </ng-container>

    <app-simple-paginator-with-arrows
      *ngIf="showPagination"
      [hasFullBorder]="true"
      [size]="pagination.size"
      [allItem]="productNumbers"
      [currentlyShownItem]="products.length"
      [page]="page"
      (next)="handleNextPage()"
      (previous)="handlePrevPage()"
    ></app-simple-paginator-with-arrows>

    <app-paginator-load-more
      *ngIf="infinite"
      (loadMoreClicked)="handleInfiniteLoadMore()"
      [fetchedNumber]="products.length"
      [totalNumber]="productNumbers"
      [loading]="areProductsLoading"
    ></app-paginator-load-more>
  </div>
</div>

<ng-template #noresult>
  <app-no-search-results class="w-100p"></app-no-search-results>
  <app-product-no-result-list [productCardWidth]="productCardWidth"></app-product-no-result-list>
</ng-template>

<ng-template #error>
  <div class="pt-100">
    <app-error-message></app-error-message>
  </div>
</ng-template>
