import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SingleAlertWithIconComponent } from '../single-alert-with-icon/single-alert-with-icon.component';
import { GeneralButton } from '../../../vo/general-button/general-button';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  standalone: true,
  imports: [TranslateModule, SingleAlertWithIconComponent],
})
export class ErrorMessageComponent implements OnInit {
  @Input() titleKey: string;
  @Input() titleTooltipKey: string;
  @Input() descriptionKey: string;
  @Input() descriptionTooltipKey: string;
  @Input() button: GeneralButton;

  constructor() {}

  ngOnInit(): void {}
}
