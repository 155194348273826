<div fxLayout="column" fxLayoutGap="20px" *ngIf="!loading; else skeleton">
  <app-products-list-slider-box
    [title]="'PRODUCT_PAGE.SIMILAR_PRODUCTS' | translate"
    [productlist]="products"
    [generateMicrodata]="generateMicrodata"
  ></app-products-list-slider-box>
</div>

<ng-template #skeleton>
  <app-skeleton-products-list-slider-box [hasSeeAll]="false"></app-skeleton-products-list-slider-box>
</ng-template>
