<ng-container *ngIf="isDialog | async; else menuTemplate">
  <div (click)="openDialog()" fxLayoutAlign="center center">
    <ng-container [ngTemplateOutlet]="trigger"></ng-container>
  </div>
</ng-container>

<ng-template #menuTemplate>
  <div [matMenuTriggerFor]="menu" fxLayoutAlign="center center">
    <ng-container [ngTemplateOutlet]="trigger"></ng-container>
  </div>
  <mat-menu #menu="matMenu" class="profile-menu">
    <ng-container *ngIf="nameToShow">
      <div class="title syncee-grey-300-border-bottom text-truncate">
        {{ 'PROFILE_MENU.HI' | translate }}, {{ nameToShow }}
      </div>
    </ng-container>
    <ng-container *ngFor="let nav of navigation">
      <button
        mat-menu-item
        *ngIf="nav.showToRoles.includes(role)"
        (click)="handleCenterClicked(nav.rolesWithAccess, nav.link, nav.forRole)"
      >
        {{ nav.title | translate }}
      </button>
    </ng-container>
    <div class="syncee-grey-300-border-top logout">
      <button mat-menu-item (click)="logout()">{{ 'PROFILE_MENU.LOGOUT' | translate }}</button>
    </div>
    <div *isAdminLoggedInToUser class="syncee-grey-300-border-top">
      <button mat-menu-item (click)="logoutFromUser('ADMIN')">{{ 'PROFILE_MENU.LOGOUT_FROM_USER' | translate }}</button>
    </div>
    <div *isLoggedInByTeamMember class="syncee-grey-300-border-top">
      <button mat-menu-item (click)="logoutFromUser('TEAM_MEMBER')">
        {{ 'PROFILE_MENU.LOGOUT_FROM_TEAM_OWNER' | translate }}
      </button>
    </div>
  </mat-menu>
</ng-template>

<ng-template #trigger>
  <app-avatar type="text" [diameter]="avatarDiameter | async" [style.margin-right]="(avatarMargin | async) + 'px'">
    {{ avatarContent | async }}
  </app-avatar>
</ng-template>
