import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

/*
 * This component is used to display alert messages.
 *
 * It can be used as a standalone component or as a base for other alert components.
 *
 * It provide basic, helper css classes: background, color-light, color-dark
 */
@Component({
  selector: 'app-alert-base',
  standalone: true,
  imports: [CommonModule, FlexModule, MatIconModule],
  templateUrl: './alert-base.component.html',
  styleUrls: ['./alert-base.component.scss'],
})
export class AlertBaseComponent implements OnInit {
  @Input() type: AlertBaseType = 'default';
  @Input() layout: Layout;
  @Input() matIcon?: string;
  @Input() matIconColor?: string;
  @Input() matIconClass?: string;
  @Input() actionRef?: TemplateRef<any>;
  @Input() hideIcon = false;

  constructor() {}

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon(): void {
    if (!this.matIcon) {
      this.matIcon = this.getDefaultIcon();
    }
    if (!this.matIconClass) {
      this.matIconClass = this.getDefaultIconClass();
    }
  }

  private getDefaultIcon(): string {
    switch (this.type) {
      case 'default':
        return 'info';
      case 'danger':
        return 'warning';
      case 'warning':
        return 'error_outline';
      case 'info':
        return 'info';
      case 'success':
        return 'check_circle';
      default:
        return null;
    }
  }

  private getDefaultIconClass(): string {
    switch (this.type) {
      case 'info':
        return 'icon-filled';
      default:
        return null;
    }
  }
}

export type AlertBaseType = 'warning' | 'info' | 'danger' | 'success' | 'default';
export type Layout = 'row' | 'column';
