import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from '../../../../utils/Constants';
import { ReplaySubject } from 'rxjs';
import { FileUploadDownloadInputVo } from '../vo/file-upload-download-input-vo';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-preview-downloaded-file',
  templateUrl: './preview-downloaded-file.component.html',
  styleUrls: ['./preview-downloaded-file.component.scss'],
})
export class PreviewDownloadedFileComponent implements OnInit {
  private _inputSync = new ReplaySubject<FileUploadDownloadInputVo>(1);

  @Input() set inputs(value: FileUploadDownloadInputVo) {
    this._inputSync.next(value);
  }

  remotePath: string;

  @Output() selectedTypeCallBack = new EventEmitter<string>();
  @Output() initFTPUpload = new EventEmitter<boolean>();

  selectedFileExtension = 'Auto';

  fileExtensions = Constants.UPLOAD_FILE_EXTENSIONS;
  disableFileTypeSelector = false;

  constructor() {}

  ngOnInit(): void {
    this._inputSync.pipe(takeWhile(() => !this.remotePath)).subscribe((res: FileUploadDownloadInputVo) => {
      this.remotePath = res.remotePath;
      this.selectedFileExtension = res.selectedType;
      this.disableFileTypeSelector = res.isUpdate;
    });
  }

  selectFileTypeChanged(event): void {
    this.selectedTypeCallBack.emit(event);
  }

  openFTPUpload(): void {
    this.initFTPUpload.emit(true);
  }
}
