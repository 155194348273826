import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { flattenCategoriesSelector } from 'app/store/category/category.selector';
import { AddFilterWithRedirectAction } from 'app/store/product-search/product-search.action';
import { filterSelector } from 'app/store/product-search/product-search.selector';
import { CategoryVo } from 'app/vo/category-vo';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FilterSelectorGtmService } from '../../marketplace-shared-components/service/filter-selector-gtm-service';

@Component({
  selector: 'app-category-sidebar',
  templateUrl: './category-sidebar.component.html',
  styleUrls: ['./category-sidebar.component.scss'],
})
export class CategorySidebarComponent implements OnInit, OnDestroy {
  @Input() sendGtmFilter = false;
  selectedCategory: CategoryVo;
  topLevelParentCategory: CategoryVo;
  searchFilter$: Observable<number>;
  flattenCategories: CategoryVo[];
  selectedCategoryPath: number[];
  unsubscribeAll: Subject<void> = new Subject();

  constructor(private store: Store<AppState>, private gtmFilterService: FilterSelectorGtmService) {
    this.setFilterObservable();
  }

  ngOnInit(): void {
    this.setFlattenCategories();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleCategoryChange(category: CategoryVo): void {
    this.store.dispatch(
      new AddFilterWithRedirectAction({
        filter: {
          category: category.id,
        },
        needRedirect: false,
      })
    );
    if (this.sendGtmFilter) {
      this.gtmFilterService.setFilter('category', category);
    }
  }

  getCategoryById(selectedCategoryId: number): CategoryVo {
    return this.flattenCategories.find((category) => category.id === selectedCategoryId);
  }

  private createFilterChangedSubscription(): void {
    this.searchFilter$.pipe(takeUntil(this.unsubscribeAll)).subscribe((categoryId) => {
      if (categoryId === null || categoryId === undefined) {
        this.setMainCategory(1);
      } else if (categoryId === 1) {
        this.setMainCategory(categoryId);
      } else {
        this.setTopLevelCategory(categoryId);
      }
    });
  }

  private setMainCategory(categoryId: number): void {
    this.topLevelParentCategory = this.getCategoryById(categoryId);
    this.selectedCategory = this.topLevelParentCategory;
    this.selectedCategoryPath = [];
  }

  private setTopLevelCategory(categoryId: number): void {
    this.selectedCategoryPath = [];
    this.setTopLevelParentCategory(categoryId);
    this.selectedCategoryPath = this.selectedCategoryPath.reverse();
    this.setSelectedCategory(categoryId);
  }

  private setFlattenCategories(): void {
    this.store
      .select(flattenCategoriesSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((flattenCategories) => {
        if (flattenCategories.length > 0) {
          this.flattenCategories = flattenCategories;
          this.createFilterChangedSubscription();
        }
      });
  }

  private setTopLevelParentCategory(selectedCategoryId: number): void {
    this.topLevelParentCategory = this.getTopLevelParent(this.getCategoryById(selectedCategoryId));
  }

  private setSelectedCategory(selectedCategoryId: number): void {
    this.selectedCategory = this.getCategoryById(selectedCategoryId);
  }

  private getTopLevelParent(targetCategory: CategoryVo): CategoryVo {
    if (targetCategory.parent === 1) {
      this.selectedCategoryPath.push(targetCategory.id);
      return targetCategory;
    } else {
      this.selectedCategoryPath.push(targetCategory.id);
    }

    const parentCategory = this.getParentCategory(this.flattenCategories, targetCategory);
    return this.getTopLevelParent(parentCategory);
  }

  private getParentCategory(categories: CategoryVo[], targetCategory: CategoryVo): CategoryVo {
    return categories.find((category) => category.id === targetCategory.parent);
  }

  private setFilterObservable(): void {
    this.searchFilter$ = this.store.select(filterSelector).pipe(map((state) => state.category));
  }
}
