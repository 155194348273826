import { Injectable } from '@angular/core';
import { CategoryMappingData } from '../model/category-mapping-data';
import { CategorySelectorDialogService } from '../../category-selector-dialog/category-selector-dialog.service';
import { CategorySelectorDialogComponentData } from '../../category-selector-dialog/category-selector-dialog.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { uniqBy } from 'lodash';
import { RemoveMappingData } from '../model/remove-mapping-data';

@Injectable()
export class WizardCategoryMappingActionService {
  constructor(private categoryDialogService: CategorySelectorDialogService) {}

  addMapping(
    selectedCategory: CategoryMappingData,
    allCategories: CategoryMappingData[],
    dialogData: CategorySelectorDialogComponentData
  ): Observable<CategoryMappingData[]> {
    return this.categoryDialogService.open(dialogData).pipe(
      map((result) => {
        if (!!result) {
          return allCategories.map((entry) => {
            if (entry.id === selectedCategory.id) {
              return { ...entry, mappedCategories: result };
            } else {
              return entry;
            }
          });
        } else {
          return null;
        }
      })
    );
  }

  addMappingInBulk(
    selectedCategories: CategoryMappingData[],
    allCategories: CategoryMappingData[],
    dialogData: CategorySelectorDialogComponentData
  ): Observable<CategoryMappingData[]> {
    return this.categoryDialogService.open(dialogData).pipe(
      map((result) => {
        if (!!result) {
          const selectedIds = selectedCategories.map((cat) => cat.id);
          return allCategories.map((item) => {
            if (selectedIds.includes(item.id)) {
              return { ...item, mappedCategories: uniqBy([...item.mappedCategories, ...result], 'id') };
            } else {
              return item;
            }
          });
        } else {
          return null;
        }
      })
    );
  }

  removeCategory(data: RemoveMappingData, allCategories: CategoryMappingData[]): CategoryMappingData[] {
    return allCategories.map((entry) => {
      if (entry.id === data.mapFromCategoryId) {
        return {
          ...entry,
          mappedCategories: entry.mappedCategories.filter((mapped) => mapped.id !== data.mappedCategoryId),
        };
      } else {
        return entry;
      }
    });
  }

  removeMappingInBulk(
    selectedCategories: CategoryMappingData[],
    allCategories: CategoryMappingData[],
    dialogData: CategorySelectorDialogComponentData
  ): Observable<CategoryMappingData[]> {
    return this.categoryDialogService.open(dialogData).pipe(
      map((result) => {
        if (!!result) {
          const selectedIds = selectedCategories.map((item) => item.id);
          const resultIds = result.map((item) => item.id);
          return allCategories.map((entry) => {
            if (selectedIds.includes(entry.id)) {
              return {
                ...entry,
                mappedCategories: entry.mappedCategories.filter((item) => !resultIds.includes(item.id)),
              };
            } else {
              return entry;
            }
          });
        } else {
          return null;
        }
      })
    );
  }

  clearSelectedMappings(selectedIds: string[], allCategories: CategoryMappingData[]): CategoryMappingData[] {
    return allCategories.map((entry) => {
      if (selectedIds.includes(entry.id)) {
        return { ...entry, mappedCategories: [] };
      } else {
        return entry;
      }
    });
  }
}
