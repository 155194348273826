import { NgModule } from '@angular/core';
import { MobileCenterComponent } from './mobile-center.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FuseNavigationModule } from '../../../../@fuse/components';
import { RoleTranslationPipe } from '../../../shared/pipes/role-translation.pipe';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexModule } from '@angular/flex-layout';
import { FilterRolesDirective } from '../../../shared/directives/filter-roles.directive';
import { TranslateModule } from '@ngx-translate/core';
import { OverflowDirective } from 'app/shared/directives/overflow.directive';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule,
    FuseNavigationModule,
    RoleTranslationPipe,
    FuseDirectivesModule,
    FlexModule,
    TranslateModule,
    FilterRolesDirective,
    OverflowDirective,
  ],
  declarations: [MobileCenterComponent],
  exports: [MobileCenterComponent],
})
export class MobileCenterModule {}
