<mat-card
  class="w-100p p-40 orders-chart-container"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign="center center"
  fxLayoutAlign.lt-lg="center start"
  fxLayoutGap="40px"
>
  <div class="w-100p" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="40px" fxFlex="1 1 auto">
    <div class="w-100p row-gap-sm" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="space-between center">
      <h4 class="m-0">{{ 'DASHBOARD.CHART_STATISTIC_TYPES.SALES_REPORT' | translate }}</h4>
      <div id="legend-container" class="p-0" [hidden]="!anyOrdersReceived"></div>
    </div>
    <div class="orders-canvas">
      <canvas
        *ngIf="!!barChartData && !!barChartOptions"
        baseChart
        class="w-100p"
        [data]="anyOrdersReceived ? barChartData : defaultBarChartData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="'bar'"
      >
      </canvas>
    </div>
  </div>
  <div class="chart-filter" fxFlex="1 0 150px">
    <mat-radio-group
      (change)="handleStatisticTypeChange($event)"
      [value]="statisticType"
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutGap="20px"
    >
      <mat-radio-button [checked]="statisticType === 'PRODUCTS_SOLD'" [value]="'PRODUCTS_SOLD'"
        >{{ 'DASHBOARD.CHART_STATISTIC_TYPES.PRODUCTS_SOLD' | translate }}
      </mat-radio-button>
      <mat-radio-button [checked]="statisticType === 'PROFIT'" [value]="'PROFIT'"
        >{{ 'DASHBOARD.CHART_STATISTIC_TYPES.PROFIT' | translate }}
      </mat-radio-button>
      <mat-radio-button [checked]="statisticType === 'REVENUE'" [value]="'REVENUE'"
        >{{ 'DASHBOARD.CHART_STATISTIC_TYPES.REVENUE' | translate }}
      </mat-radio-button>
      <mat-radio-button [checked]="statisticType === 'ORDERS'" [value]="'ORDERS'"
        >{{ 'DASHBOARD.CHART_STATISTIC_TYPES.ORDERS' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <app-blurred-overlay *ngIf="!anyOrdersReceived" [blurValue]="'0'">
    <app-single-alert-with-icon
      icon="inbox"
      titleKey="DASHBOARD_NEW.NO_ORDERS_YET.TITLE"
      [button]="{
        type: 'inner_redirect',
        textKey: 'DASHBOARD_NEW.NO_ORDERS_YET.BUTTON',
        buttonClass: 'syncee-blue-button',
        url: navigateToMarketplace,
        queryParams: {
          category: '1',
          searchType: 'PRODUCTS'
        }
      }"
    >
    </app-single-alert-with-icon>
  </app-blurred-overlay>
</mat-card>
