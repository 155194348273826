<div id="main-container" *ngIf="isVisible">
  <!--Previous-->
  <button mat-fab (click)="previous()" [disabled]="!hasPreviousItem()" class="prev">
    <mat-icon class="centered">arrow_back</mat-icon>
  </button>

  <!--Image container-->
  <div id="image-container" fxLayoutAlign="center center">
    <img [src]="imageUrls[currentIndex] | productImageFullCdn" />
    <img hidden *ngIf="hasNextItem()" [src]="imageUrls[currentIndex + 1] | productImageFullCdn" />
  </div>

  <!--Next-->
  <button mat-fab (click)="next()" [disabled]="!hasNextItem()" class="next">
    <mat-icon class="centered">arrow_forward</mat-icon>
  </button>
</div>
