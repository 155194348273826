import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PLATFORM } from '../../utils/platforms/platform-config';

@Component({
  selector: 'app-connect-your-store',
  templateUrl: './connect-your-store.component.html',
  styleUrls: ['./connect-your-store.component.scss'],
})
export class ConnectYourStoreComponent {
  @Input() role: number;
  @Input() public variant: 'small' | 'default' = 'default';
  @Input() public columns = 2;
  public ecoms = PLATFORM;

  public platforms: ConnectStorePlatform[] = [
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPIFY.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPIFY.DESCRIPTION',
      image: 'assets/images/platforms/shopify-logo.jpg',
      imageSmall: 'assets/images/logos/shopify.png',
      link: 'https://www.shopify.com/?ref=syncee',
      ecomType: 'shopify',
      needDialogForInstall: false,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WIX.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WIX.DESCRIPTION',
      image: 'assets/images/platforms/wix-logo.jpg',
      imageSmall: 'assets/images/logos/wix.png',
      link: 'https://www.wix.com/ecommerce/website',
      ecomType: 'wix',
      needDialogForInstall: false,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.BIGCOMMERCE.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.BIGCOMMERCE.DESCRIPTION',
      image: 'assets/images/platforms/bigcommerce-logo.jpg',
      imageSmall: 'assets/images/logos/bigcommerce.png',
      link: 'https://www.bigcommerce.com/dm/syncee/',
      ecomType: 'bigcommerce',
      needDialogForInstall: false,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WOOCOMMERCE.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WOOCOMMERCE.DESCRIPTION',
      image: 'assets/images/platforms/woocommerce-logo.jpg',
      imageSmall: 'assets/images/logos/woocommerce.png',
      link: 'https://woocommerce.com/',
      ecomType: 'woocommerce',
      needDialogForInstall: true,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SQUARESPACE.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SQUARESPACE.DESCRIPTION',
      image: 'assets/images/platforms/squarespace-logo.jpg',
      imageSmall: 'assets/images/logos/squarespace.png',
      link: 'https://www.squarespace.com/',
      ecomType: 'squarespace',
      needDialogForInstall: true,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ECWID.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ECWID.DESCRIPTION',
      image: 'assets/images/platforms/ecwid-logo.jpg',
      imageSmall: 'assets/images/logos/ecwid.png',
      link: 'http://open.ecwid.com/66MDMB',
      ecomType: 'ecwid',
      needDialogForInstall: false,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.JUMPSELLER.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.JUMPSELLER.DESCRIPTION',
      image: 'assets/images/platforms/jumpseller-logo.jpg',
      imageSmall: 'assets/images/logos/jumpseller.png',
      link: 'https://jumpseller.com?ref=syncee-affiliate',
      ecomType: 'jumpseller',
      needDialogForInstall: true,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPRENTER.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPRENTER.DESCRIPTION',
      image: 'assets/images/platforms/shoprenter-logo.jpg',
      imageSmall: 'assets/images/logos/shoprenter.png',
      link: 'https://www.shoprenter.hu/',
      ecomType: 'shoprenter',
      needDialogForInstall: true,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.KMO_SHOPS.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.KMO_SHOPS.DESCRIPTION',
      image: 'assets/images/platforms/kmoshops-logo.jpg',
      imageSmall: 'assets/images/logos/kmoshops.png',
      link: 'https://www.kmoshops.be/',
      ecomType: 'kmoshops',
      needDialogForInstall: false,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.UNAS.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.UNAS.DESCRIPTION',
      image: 'assets/images/platforms/unas-logo.jpg',
      imageSmall: 'assets/images/logos/unas.png',
      link: 'https://unas.hu/',
      ecomType: 'unas',
      needDialogForInstall: false,
    },
    {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.EKM.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.EKM.DESCRIPTION',
      image: 'assets/images/platforms/ekm-logo.jpg',
      imageSmall: 'assets/images/logos/ekm.png',
      link: 'https://www.ekm.com/offers/partner-signup.asp?tap_a=17618-24221b&tap_s=2538854-1691a0',
      ecomType: 'ekm',
      needDialogForInstall: false,
    },
    // {
    //   title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ZYRO.TITLE',
    //   description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ZYRO.DESCRIPTION',
    //   image: 'assets/images/platforms/zyro-logo.jpg',
    //   imageSmall: 'assets/images/logos/zyro.png',
    //   link: 'https://tracking.zyro.com/SH9L',
    //   ecomType: 'zyro',
    //   needDialogForInstall: false
    // },
    // {
    //   title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPLAZZA.TITLE',
    //   description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPLAZZA.DESCRIPTION',
    //   image: 'assets/images/platforms/shoplazza-logo.jpg',
    //   link: 'https://www.shoplazza.com/pages/affiliate-register?ref=1061ß',
    //   ecomType: 'shoplazza',
    //   needDialogForInstall: true
    // },
  ];

  constructor(public translateService: TranslateService) {}

  hasInstallerForRole(role: number, ecomType: string): boolean {
    const currentEcom = this.ecoms.filter((ecom) => ecom.key === ecomType)[0];
    return role === 1 ? currentEcom.options.hasSupplierApp : currentEcom.options.hasRetailerApp;
  }
}

export interface ConnectStorePlatform {
  title: string;
  description: string;
  image: string;
  imageSmall: string;
  link: string;
  ecomType: string;
  needDialogForInstall: boolean;
}
