export const TRANSLATION_LANGUAGES: TranslationLanguagesVO[] = [
  {
    language: 'BG',
    name: 'Bulgarian',
  },
  {
    language: 'CS',
    name: 'Czech',
  },
  {
    language: 'DA',
    name: 'Danish',
  },
  {
    language: 'DE',
    name: 'German',
  },
  {
    language: 'EL',
    name: 'Greek',
  },
  {
    language: 'EN',
    name: 'English',
  },
  {
    language: 'ES',
    name: 'Spanish',
  },
  {
    language: 'ET',
    name: 'Estonian',
  },
  {
    language: 'FI',
    name: 'Finnish',
  },
  {
    language: 'FR',
    name: 'French',
  },
  {
    language: 'HU',
    name: 'Hungarian',
  },
  {
    language: 'IT',
    name: 'Italian',
  },
  {
    language: 'JA',
    name: 'Japanese',
  },
  {
    language: 'LT',
    name: 'Lithuanian',
  },
  {
    language: 'LV',
    name: 'Latvian',
  },
  {
    language: 'NL',
    name: 'Dutch',
  },
  {
    language: 'PL',
    name: 'Polish',
  },
  {
    language: 'PT',
    name: 'Portuguese',
  },
  {
    language: 'RO',
    name: 'Romanian',
  },
  {
    language: 'RU',
    name: 'Russian',
  },
  {
    language: 'SK',
    name: 'Slovak',
  },
  {
    language: 'SL',
    name: 'Slovenian',
  },
  {
    language: 'SV',
    name: 'Swedish',
  },
  {
    language: 'ZH',
    name: 'Chinese',
  },
];

export interface TranslationLanguagesVO {
  language: string;
  name: string;
}
