import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountriesManagerService } from '../../../../service/countries-manager/countries-manager.service';
import { EXTRA_SHIPPING_FILTERS } from '../../../../utils/Constants';
import {
  FilterSelectorOption,
  FilterSelectorOptionGroup,
} from '../../filter-selector-base/filter-selector-base.component';
import { isEmpty } from 'lodash';
import { CountryNode } from '../../../../utils/Countries';

@Component({
  selector: 'app-ships-to-filter',
  templateUrl: './ships-to-filter.component.html',
  styleUrls: ['./ships-to-filter.component.scss'],
})
export class ShipsToFilterComponent implements OnInit, OnChanges {
  @Input() multiple = false;
  @Input() hasClearAll = false;
  @Input() value: string | string[];
  @Input() overlayOpen: boolean;
  @Output() valueChange = new EventEmitter<string | string[]>();
  @Output() shipsToChange = new EventEmitter<CountryNode | CountryNode[]>();
  @Output() clearAll = new EventEmitter<void>();
  noOptionsAvailable = false;
  countryList = this.countriesManagerService.getOnlyCountries();
  options: FilterSelectorOptionGroup<string, string>[] = [];

  countryOptions: FilterSelectorOptionGroup<string, string>;
  continentOptions: FilterSelectorOptionGroup<string, string>;

  filteredOptions: FilterSelectorOptionGroup<string, string>[] = [];
  hasSeeMore = true;
  private _searchTerm: string;
  private extraShippingFilters = EXTRA_SHIPPING_FILTERS;

  constructor(private countriesManagerService: CountriesManagerService, private translateService: TranslateService) {
    this.continentOptions = {
      optionGroupTitle: 'CONTINENT',
      options: this.mapContinentListToOptions(),
    };
    this.countryOptions = {
      optionGroupTitle: 'COUNTRY',
      options: this.mapCountryListToOptions(),
    };
  }

  ngOnInit(): void {
    this.setInitialOptions();
  }

  public ngOnChanges({ overlayOpen }: { overlayOpen?: SimpleChange }): void {
    if (!overlayOpen?.currentValue && !overlayOpen.isFirstChange()) {
      this.reset();
    }
  }

  private reset(): void {
    this.handleSearchChange('');
    this.setInitialOptions();
    this.hasSeeMore = true;
  }

  handleShipsToChange(value: string | string[]): void {
    this.valueChange.emit(value);
    this.emitCountryNodes(value);
  }

  private emitCountryNodes(selectedValue: string | string[]): void {
    const allOptions: CountryNode[] = [...this.countryList, ...EXTRA_SHIPPING_FILTERS];
    const getMatchingOptions = (): CountryNode | CountryNode[] => {
      if (Array.isArray(selectedValue)) {
        return allOptions.filter((option) => selectedValue.some((value) => value === option.name));
      } else {
        return allOptions.find((option) => option.name === selectedValue);
      }
    };
    this.shipsToChange.emit(getMatchingOptions());
  }

  onSeeMore(): void {
    this.options = [this.continentOptions, this.countryOptions];
    this.filterOptions();
  }
  onSeeLess(): void {
    this.setInitialOptions();
  }

  private setHasMore(): void {
    if (!this.searchTerm) {
      this.hasSeeMore = this.options.length > 4;
    } else {
      this.hasSeeMore = this.filteredOptions.length > 4;
    }
  }

  private mapCountryListToOptions(): FilterSelectorOption<string, string>[] {
    return this.countryList.map((country) => ({
      key: country.name,
      value: this.translateService.instant(`COUNTRIES.${country.code}`),
    }));
  }

  private mapContinentListToOptions(): FilterSelectorOption<string, string>[] {
    return this.extraShippingFilters.map((filter) => ({
      key: filter.name,
      value: this.translateService.instant(`CONTINENTS.${filter.name.toUpperCase()}`),
    }));
  }

  private filterOptions(): void {
    if (!this.searchTerm) {
      this.filteredOptions = [...this.options];
    } else {
      const filteredOptions: FilterSelectorOptionGroup<string, string>[] = [];
      this.options.forEach((optionGroup) => {
        filteredOptions.push({
          ...optionGroup,
          options: optionGroup.options.filter(
            (option) => option.value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
          ),
        });
      });
      this.filteredOptions = filteredOptions;
    }
    this.noOptionsAvailable = isEmpty(this.filteredOptions);
  }

  handleSearchChange(newValue: string): void {
    this.searchTerm = newValue;
    this.onSeeMore();
    this.setHasMore();
  }

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  private setInitialOptions(): void {
    this.options = [this.continentOptions];
    this.filterOptions();
  }

  onClearAll(): void {
    this.clearAll.emit();
  }
}
