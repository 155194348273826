import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Utils } from 'app/utils/utils';

@Pipe({
  name: 'categoryNameByLanguage',
  standalone: true,
})
export class GetCategoryByLanguage implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(categoryId: number, languageCode = 'en'): Observable<string> {
    return !Utils.isNullOrUndefined(categoryId)
      ? this.store
          .select((appState) => appState.categories.flattenCategories)
          .pipe(
            filter((data) => !!data && data.length > 0),
            map((categories) => categories.find((category) => category.id === categoryId)),
            map(
              (matchingCategory) =>
                matchingCategory.categoryName.find((categoryName) => categoryName.languageCode === languageCode)
                  ?.name ??
                matchingCategory.categoryName.find((categoryName) => (categoryName.languageCode = 'en')).name
            )
          )
      : of('Products');
  }
}
