<div class="outer" [style.height.px]="ltMd && !!mobileHeight ? mobileHeight : height">
  <div class="header-1" [ngClass]="wrapperClasses">
    <div class="inner-container centered-text" [ngClass]="innerClasses">
      <div [ngClass]="ltMd ? 'h6 pb-12' : 'typography-overline-2 pb-16'">{{ upperText }}</div>
      <h1 class="m-0" [ngClass]="ltMd ? 'h2 pb-16' : 'typography-headline-3 pb-32'" [innerHTML]="title"></h1>
      <div [ngClass]="ltMd ? 'typography-h3-subtitle pb-28' : 'typography-h1-subtitle pb-40'">{{ description }}</div>
      <ng-content></ng-content>
    </div>
  </div>
  <img
    [ngSrc]="ltMd && !!mobileBackgroundImage ? mobileBackgroundImage.src : backgroundImage.src"
    [alt]="ltMd && !!mobileBackgroundImage ? mobileBackgroundImage.alt : backgroundImage.alt"
    [height]="ltMd && !!mobileHeight ? mobileHeight : height"
    [width]="1920"
  />
</div>
