<div fxLayout="column">
  <div class="notification-list-container" *ngIf="!isLoading; else loading" fxFlex="1 0 0">
    <ng-container *ngIf="notifications.length > 0; else noNotification"></ng-container>
    <ng-container *ngFor="let notification of notifications">
      <ng-container *ngIf="notification.content.length > 0">
        <div class="notification-list-header">
          <span>{{ notification.name }}</span>
        </div>
        <div class="notification-list">
          <div
            fxLayoutAlign="start center"
            class="notification-list-item-container syncee-grey-200-border-bottom"
            *ngFor="let item of notification.content"
          >
            <app-notification-item
              [item]="item"
              [actualRole]="actualRole"
              [isAdmin]="isAdmin"
              (changeStatus)="changeStatus(notification.name, $event)"
              (deleteNotification)="deleteNotification(notification.name, $event)"
              class="w-100p"
            ></app-notification-item>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div
      fxLayoutAlign="center center"
      class="load-more"
      (click)="loadMore()"
      *ngIf="fromPage + itemsPerPage < totalItems"
    >
      <button mat-flat-button color="primary" *ngIf="!loadMoreLoading; else loading">
        {{ 'NOTIFICATION_PANEL.LOAD_MORE' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  <div fxLayout="column" fxLayoutAlign="center center" class="py-20 w-100p">
    <mat-spinner [diameter]="32"></mat-spinner>
  </div>
</ng-template>

<ng-template #noNotification>
  <div fxLayout="column" fxLayoutAlign="center center" class="py-20">
    <div>{{ 'NOTIFICATION_PANEL.THERE' | translate }}</div>
  </div>
</ng-template>
