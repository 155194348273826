import { NgModule } from '@angular/core';
import { TagSelectorComponent } from './tag-selector.component';
import { TagSelectorItemComponent } from './components/tag-selector-item/tag-selector-item.component';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TagSelectorItemCheckboxComponent } from './components/tag-selector-item-checkbox/tag-selector-item-checkbox.component';

@NgModule({
  imports: [CommonModule, MatCheckboxModule],
  declarations: [TagSelectorComponent, TagSelectorItemComponent, TagSelectorItemCheckboxComponent],
  exports: [TagSelectorComponent],
})
export class TagSelectorModule {}
