import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-image-selector-product-page-arrow-button',
  standalone: true,
  imports: [CommonModule, FlexModule, MatIconModule],
  templateUrl: './image-selector-product-page-arrow-button.component.html',
  styleUrls: ['./image-selector-product-page-arrow-button.component.scss'],
})
export class ImageSelectorProductPageArrowButtonComponent implements OnInit {
  @Input() iconName = 'navigate_next';

  constructor() {}

  ngOnInit(): void {}
}
