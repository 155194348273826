<div class="slide-toggle-with-text" (click)="checkedChange.emit(!this.checked)" [class.checked]="checked">
  <div
    class="bar typography-body-2"
    [style.background-color]="checked ? trueBackground : falseBackground"
    [style.color]="checked ? trueTextColor : falseTextColor"
  >
    {{ (checked ? trueText : falseText) | translate }}
    <div
      class="thumb"
      [@isChecked]="checked"
      mat-ripple
      [style.border-color]="checked ? trueBackground : falseBackground"
    ></div>
  </div>
</div>
