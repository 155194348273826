import { Injectable } from '@angular/core';
import { SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { AnswerDto } from '../../vo/question-answer/answer-dto';
import { ReportUserDto } from '../../vo/question-answer/report-user-dto';

@Injectable({
  providedIn: 'root',
})
export class QuestionAnswerService {
  private baseUrl = '/User/Report';
  private microserviceName = 'userService';
  constructor(private springRestService: SpringRestService) {}

  getAnswersForQuestion(questionCode: string): Observable<AnswerDto[]> {
    const url = `${this.baseUrl}/Answer`;

    return this.springRestService.get(this.microserviceName, url, { questionCode }, true);
  }

  postAnswerForQuestion(reportUserDto: ReportUserDto): Observable<void> {
    return this.springRestService.post(this.microserviceName, this.baseUrl, reportUserDto, undefined, true);
  }
}
