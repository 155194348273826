const prefix = 'supplier/';
const settings = 'settings';

export type ProfileNavigation = (typeof ProfileNavigation)[keyof typeof ProfileNavigation];
export const ProfileNavigation = {
  DEFAULT: `${prefix}${settings}`,
  SETTINGS: `${prefix}${settings}/profile`,
  NOTIFICATION_SETTINGS: `${prefix}${settings}/notification-settings`,
  ORDER_SETTINGS: `${prefix}${settings}/order-settings`,
  TEAM: `${prefix}${settings}/team`,
} as const;
