<div [hidden]="hideFileUploadContainer">
  <app-loading-screen
    *ngIf="saveFile || (taskWizardSampleService.isLoading$ | async)"
    [message]="'TASKWIZARD.UPLOAD_LOADING' | translate"
    [loading]="true"
    [needStatusCheck]="false"
  ></app-loading-screen>
  <mat-accordion #accordion="matAccordion">
    <mat-expansion-panel
      [expanded]="expanded"
      (opened)="expanded = true"
      (closed)="expanded = false"
      [ngStyle]="{ overflow: expanded ? 'unset' : 'hidden' }"
    >
      <mat-expansion-panel-header>
        <div class="typography-body-3">
          {{ 'TASKWIZARD.ADDFILES.TABS.SOURCE' | translate }}
        </div>
      </mat-expansion-panel-header>
      <div class="pb-20">{{ 'TASKWIZARD.ADDFILES.TABS.ADD_OR_MANAGE_PRODUCTS' | translate }}</div>
      <mat-card class="p-24" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
        <div>
          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="center start"
            fxLayoutAlign.lt-md="center stretch"
            fxLayoutGap="40px"
            fxFlex="1 0 0"
            class="m-w-0"
          >
            <div fxFlex="1 0 0" class="m-w-0" [hidden]="hideFileUploadContainer">
              <ng-template #fileupload_container></ng-template>
            </div>
            <div fxFlex="1 0 0" class="m-w-0" [hidden]="isSettingsHidden()">
              <div fxLayout="column" fxLayoutAlign="start">
                <div class="h5 mb-20">
                  {{ 'TASKWIZARD.ADDFILES.TABS.SETTINGS' | translate }}
                </div>
                <ng-template #prodnodetree_container></ng-template>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="hideActionPanel">
          <div fxLayoutAlign="start start">
            <div
              adminComment
              [adminCommentTaskId]="currentTaskID"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'fileSettings'"
              [adminCommentComments]="comments"
            ></div>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="h5">{{ 'TASKWIZARD.SCHEDULE.SCHEDULER' | translate }}</div>
          <div>
            {{ 'TASKWIZARD.SCHEDULE.SCHEDULER_DESCRIPTION' | translate }}
          </div>
          <app-schedule #scheduleComponent [sourceType]="conType" [taskId]="currentTaskID"></app-schedule>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px">
          <button mat-button (click)="deleteTabFile()" [disabled]="disableFileDeleteBtn">
            {{ 'TASKWIZARD.ADDFILES.TABS.DELETE' | translate }}
          </button>
          <button
            fxFlexAlign="end"
            mat-button
            class="syncee-blue-button"
            (click)="saveFileSettings()"
            [disabled]="disableFileSettingsSaveBtn"
          >
            {{ 'TASKWIZARD.ADDFILES.TABS.SAVE' | translate }}
          </button>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</div>
