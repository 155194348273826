import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FaqsComponent } from '../faqs/faqs.component';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { FaqItem } from '../../../vo/faq/faq-item';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';

@Component({
  selector: 'app-faq-columns',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, TranslateModule, FaqsComponent, NgxJsonLdModule, SectionTitleComponent],
  templateUrl: './faq-columns.component.html',
  styleUrls: ['./faq-columns.component.scss'],
})
export class FaqColumnsComponent implements OnInit {
  @Input() titleKey: string;
  @Input() questionsLeft: FaqItem[];
  @Input() questionsRight: FaqItem[];
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  @Input() translateTitle = true;
  @Input() translateValue = true;
  allQuestions: FaqItem[];

  jsonLD: SafeHtml;
  ltMd: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(private screenManagerService: ScreenManagerService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.allQuestions = this.questionsLeft.concat(...this.questionsRight);
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll), this.screenManagerService.stateMatchesOperator())
      .subscribe((matched) => {
        this.ltMd = matched;
      });
  }
}
