import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubscriptionsState } from './subscription.reducer';

const getSubscriptionsState = createFeatureSelector<SubscriptionsState>('subscriptions');

export const subscriptionsSelector = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => state && state.currentSubscriptions
);

export const isLoadingSelector = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => state && state.isLoading
);

export const isFreePlanSelector = createSelector(getSubscriptionsState, (state: SubscriptionsState) => {
  if (!state || !state.currentSubscriptions || !state.currentSubscriptions.subscriptions) {
    return null;
  } else {
    return (
      state.currentSubscriptions.subscriptions.rmp.planId === 120 &&
      state.currentSubscriptions.subscriptions.df.planId === 100
    );
  }
});

export const hasAnyPlanSelector = createSelector(getSubscriptionsState, (state: SubscriptionsState) => {
  if (!!state && !!state.currentSubscriptions && !!state.currentSubscriptions.subscriptions) {
    return (
      state.currentSubscriptions.subscriptions.rmp.planId !== 120 ||
      state.currentSubscriptions.subscriptions.df.planId !== 100
    );
  }
});

export const hasRmpPlanSelector = createSelector(getSubscriptionsState, (state: SubscriptionsState) => {
  if (!!state && !!state.currentSubscriptions && !!state.currentSubscriptions.subscriptions) {
    return state.currentSubscriptions.subscriptions.rmp.planId !== 120;
  }
});

export const hasDfPlanSelector = createSelector(getSubscriptionsState, (state: SubscriptionsState) => {
  if (!!state && !!state.currentSubscriptions && !!state.currentSubscriptions.subscriptions) {
    return state.currentSubscriptions.subscriptions.df.planId !== 100;
  }
});
