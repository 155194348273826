import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepDataModel, StepStateIconRecord } from './model/step-data.model';
import { STEP_STATE_ICON_CONFIG } from './config/step-state-icon.config';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sub-steps-stepper',
  templateUrl: './sub-steps-stepper.component.html',
  styleUrls: ['./sub-steps-stepper.component.scss'],
})
export class SubStepsStepperComponent implements OnInit {
  @Input() steps: StepDataModel[];
  @Input() iconConfig: StepStateIconRecord = STEP_STATE_ICON_CONFIG;
  @Input() defaultStep: number;

  @Output() stepChange = new EventEmitter<number>();

  navigateSteps = new Subject<'next' | 'prev'>();
  completeStep = new Subject<{ stepIndex: number; complete: boolean }>();

  constructor() {}

  ngOnInit(): void {
    this.subscribeToStepChange();
    this.subscribeToStepCompletion();
    this.initDefaultStep();
  }

  initDefaultStep(): void {
    if (this.defaultStep) {
      this.steps = this.steps.map((step, index) => {
        if (index === this.defaultStep) {
          return { ...step, state: 'current' };
        } else {
          if (index < this.defaultStep) {
            return { ...step, state: 'default', isDone: true };
          } else {
            return { ...step, state: 'default' };
          }
        }
      });
    }
  }

  subscribeToStepChange(): void {
    this.navigateSteps.subscribe((value) => {
      this.handleStepNavigation(value);
    });
  }

  handleStepNavigation(direction: 'next' | 'prev'): void {
    if (direction === 'next') {
      this.handleNextStep();
    } else {
      this.handlePrevStep();
    }
  }

  handleStepClicked(clickedStep: StepDataModel): void {
    if (clickedStep.isDone) {
      const stepIndex = this.steps.findIndex((step) => step.label === clickedStep.label);
      this.stepChange.emit(stepIndex);
      this.steps = this.steps.map((step) => {
        if (step.label === clickedStep.label) {
          return { ...step, state: 'current' };
        } else {
          return { ...step, state: 'default' };
        }
      });
    }
  }

  private handleNextStep(): void {
    const currentStepIndex = this.steps.findIndex((step) => step.state === 'current');
    const nextStepIndex = currentStepIndex + 1;
    if (currentStepIndex === this.steps.length - 1) {
      return;
    }
    this.steps[currentStepIndex].state = 'default';
    this.steps[nextStepIndex].state = 'current';
    this.stepChange.emit(nextStepIndex);
  }

  private handlePrevStep(): void {
    const currentStepIndex = this.steps.findIndex((step) => step.state === 'current');
    const prevStepIndex = currentStepIndex - 1;
    if (currentStepIndex === 0) {
      return;
    }
    this.steps[currentStepIndex].state = 'default';
    this.steps[prevStepIndex].state = 'current';
    this.stepChange.emit(prevStepIndex);
  }

  private subscribeToStepCompletion(): void {
    this.completeStep.subscribe((value) => {
      const { stepIndex, complete } = value;
      this.validateStep(stepIndex, complete);
    });
  }

  private validateStep(stepIndex: number, complete: boolean): void {
    this.steps[stepIndex].isDone = complete;
  }
}
