<ng-container *ngIf="hasSessionData">
  <ng-container *ngIf="!isLoading; else skeleton">
    <ng-container *ngIf="!!productList && productList.length > 0">
      <div class="content content-row-padding-lt-xl content-row-max-width">
        <app-products-list-slider-box
          [productlist]="productList"
          [title]="'EXPLORE_PRODUCTS.RECOMMENDED_PRODUCTS' | translate"
          [description]="'EXPLORE_PRODUCTS.BASED_ON_SEARCH' | translate"
          [ecomCurrency]="ecomCurrency"
          [isProductUsed]="isProductUsed"
          [generateMicrodata]="true"
        ></app-products-list-slider-box>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <div class="content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-products-list-slider-box></app-skeleton-products-list-slider-box>
  </div>
</ng-template>
