import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function aiChatMarkedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.text = (text: string) => `<div>${text}</div>`;

  renderer.heading = (text: string) => `<b>${text}</b>`;

  renderer.link = (text: string) => `<div style="line-break: anywhere">${text}</div>`;

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };
}
