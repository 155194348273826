<div
  class="notification top-notification-bar background"
  [ngClass]="data.type"
  [@fadeAnimation]="{ value: animationState, params: { fadeIn: 300, fadeOut: 300 } }"
  (@fadeAnimation.done)="onFadeFinished($event)"
>
  <div class="msg-container">
    <mat-icon class="type-icon color-dark" [class.icon-filled]="['information', 'error'].includes(data.type)">
      <ng-container [ngSwitch]="data.type">
        <ng-container *ngSwitchCase="'success'">check_circle</ng-container>
        <ng-container *ngSwitchCase="'warning'">error_outline</ng-container>
        <ng-container *ngSwitchCase="'error'">warning</ng-container>
        <ng-container *ngSwitchCase="'information'">info</ng-container>
      </ng-container>
    </mat-icon>
    <div class="color-light">
      <ng-container *ngIf="data.message; else templateRef">
        {{ data.message }}
      </ng-container>
    </div>
    <ng-template #templateRef>
      <ng-container *ngTemplateOutlet="data.template; context: data.templateContext"></ng-container>
    </ng-template>
  </div>
  <mat-icon class="close-button color-light" (click)="setClosingAnimState()">highlight_off</mat-icon>
</div>
