import { Injectable } from '@angular/core';
import { RetailerCatalogSettingsVO } from '../../main/marketplace/retailer-catalogs/retailer-catalog-details/retailer-catalog-settings/abstarct-retailer-settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { Utils } from 'app/utils/utils';
@Injectable({
  providedIn: 'root',
})
export class MappingSettingsService {
  public static fieldsToUpdateShopify = [
    // {id: 'shopify_handle', name: 'Handle', checked: false},
    // {id: 'shopify_product_type', name: 'Product type', checked: false},
    // {id: 'shopify_title', name: 'Title', checked: false},
    // {id: 'shopify_variant_sku', name: 'Variant SKU', checked: false},
    // {id: 'shopify_descr', name: 'Description', checked: false},
    // {id: 'shopify_metafields', name: 'Metafields', checked: false},
    // {id: 'shopify_option_value1', name: 'Option value1', checked: false},
    // {id: 'shopify_tags', name: 'Tags', checked: false},
    // {id: 'shopify_metafields_global_desc', name: 'Meta Description (website seo)', checked: false},
    // {id: 'shopify_option_value2', name: 'Option value 2', checked: false},
    // {id: 'shopify_vendor', name: 'Vendor', checked: false},
    // {id: 'shopify_option_value3', name: 'Option value 3', checked: false},
    { id: 'variants.cost', name: 'Cost Per Item', checked: true },
    { id: 'variants.price', name: 'Price', checked: true },
    // {id: 'variants.compare_at_price', name: 'Compare at price', checked: false},
    // {id: 'inventory_management.cost', name: 'Cost Per Item', checked: false},
    { id: 'inventory_management.available', name: 'Inventory Quantity', checked: true },
    // {id: 'collections', name: 'Collections', checked: false},
    // {id: 'shopify_inv_qty_adjustment', name: 'Inventory Qty increase or decrease', checked: true},
    // {id: 'shopify_barcode', name: 'Barcode', checked: false},
    // {id: 'shopify_weight', name: 'Weight', checked: false},
    // {id: 'shopify_option_name', name: 'Option name', checked: false},
    // {id: 'shopify_metafields_global_title_tag', name: 'Page title (website seo)', checked: false},
    //
    { id: 'published_at', name: 'Published', checked: true },
    // {id: 'taxable', name: 'Charge taxes on this product', checked: true},
    // {id: 'inventory_policy', name: 'Backorder', checked: true},
    // {id: 'requires_shipping', name: 'This product requires shipping', checked: true},
    // {id: 'fulfillment_service', name: 'Fulfillment Service', checked: true},
    // {id: 'weight_unit', name: 'Weight unit', checked: true},
    // {id: 'published_scope', name: 'Point of Sale visibility', checked: false}
  ];

  public static FIELDS_TO_UPDATE_DF_SHOPIFY = [
    { id: 'handle', name: 'Handle', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.HANDLE' },
    { id: 'product_type', name: 'Product type', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PRODUCT_TYPE' },
    { id: 'title', name: 'Title', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.TITLE' },
    { id: 'variants.sku', name: 'Variant SKU', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.VARIANT_SKU' },
    { id: 'body_html', name: 'Description', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.DESCRIPTION' },
    // {name: 'metafields', display: 'Metafields'},
    { id: 'options.name', name: 'Option name', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_NAME' },
    {
      id: 'variants.option1',
      name: 'Option value1',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_1',
    },
    {
      id: 'variants.option2',
      name: 'Option value2',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_2',
    },
    {
      id: 'variants.option3',
      name: 'Option value3',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_3',
    },
    { id: 'tags', name: 'Tags', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.TAGS' },
    { id: 'vendor', name: 'Vendor', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.VENDOR' },
    { id: 'variants.price', name: 'Price', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PRICE' },
    {
      id: 'variants.compare_at_price',
      name: 'Compare at price',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COMPARE_PRICE',
    },
    { id: 'variants.cost', name: 'Cost Per Item', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COST_PER_ITEM' },
    {
      id: 'inventory_management.available',
      name: 'Inventory Quantity',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.INV_QTY',
    },
    { id: 'variants.barcode', name: 'Barcode', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.BARCODE' },
    { id: 'variants.weight', name: 'Weight', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.WEIGHT' },
    //  {name: '', display: 'Option name'},
    {
      id: 'metafields_global_description_tag',
      name: 'Meta Description (website seo)',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.META_DESC',
    },
    {
      id: 'metafields_global_title_tag',
      name: 'Page Title (website seo)',
      tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PAGE_TITLE',
    },
    { id: 'collections', name: 'Collections', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COLLECTIONS' },
  ];
  public static fieldsToUpdateShoprenter = [
    { id: 'status', name: 'Status', checked: true },
    { id: 'variants.price', name: 'Price', checked: true },
    // {id: 'variants.compare_at_price', name: 'Compare at price', checked: false},
    // {id: 'inventory_management.cost', name: 'Cost', checked: false},
    { id: 'inventory_management.available', name: 'Stock', checked: true },
  ];

  public static fieldsToUpdateJumpseller = [
    { id: 'status', name: 'Status', checked: true },
    { id: 'variants.price', name: 'Price', checked: true },
    { id: 'inventory_management.available', name: 'Stock', checked: true },
  ];

  public static fieldsToUpdateEcwid = [
    { id: 'enabled', name: 'Status', checked: true },
    { id: 'variants.price', name: 'Price', checked: true },
    { id: 'inventory_management.available', name: 'Stock', checked: true },
  ];

  public static fieldsToUpdateSquarespace = [
    { id: 'isVisible', name: 'Status', checked: true },
    { id: 'variants.price', name: 'Price', checked: true },
    { id: 'inventory_management.available', name: 'Stock', checked: true },
  ];

  public static fieldsToUpdateBigcommerce = [
    { id: 'availability', name: 'Status', checked: true },
    { id: 'variants.price', name: 'Price', checked: true },
    { id: 'inventory_management.available', name: 'Stock', checked: true },
  ];

  public static fieldsToUpdateWix = [
    { id: 'visible', name: 'Visible', checked: true },
    { id: 'variants.variant.priceData.price', name: 'Price', checked: true },
    { id: 'variants.variant.qty', name: 'Inventory Quantity', checked: true },
  ];

  public static fieldsToUpdateWoocommerce = [
    { id: 'status', name: 'Status', checked: true },
    { id: 'variations.regular_price', name: 'Regular Price', checked: true },
    { id: 'variations.stock_quantity', name: 'Stock Quantity', checked: true },
  ];

  public static fieldsToUpdateEkm = [
    { id: 'live', name: 'Live', checked: true },
    { id: 'price', name: 'Price', checked: true },
    { id: 'total_product_stock', name: 'Total Quantity', checked: true },
    { id: 'number_in_stock', name: 'Variant Quantity', checked: true },
  ];

  public static fieldsToUpdateShoplazza = [
    // {id: 'variants.cost', name: 'Cost Per Item', checked: true},
    { id: 'variants.price', name: 'Price', checked: true },
    { id: 'variants.inventory_quantity', name: 'Inventory Quantity', checked: true },
    { id: 'published', name: 'Published', checked: true },
  ];

  public static fieldsToUpdateV3Task = [
    { id: 'shopify_handle', name: 'Handle', checked: false },
    { id: 'shopify_product_type', name: 'Product type', checked: false },
    { id: 'shopify_title', name: 'Title', checked: false },
    { id: 'shopify_variant_sku', name: 'Variant SKU', checked: false },
    { id: 'shopify_descr', name: 'Description', checked: false },
    { id: 'shopify_metafields', name: 'Metafields', checked: false },
    { id: 'shopify_option_value1', name: 'Option value1', checked: false },
    { id: 'shopify_tags', name: 'Tags', checked: false },
    { id: 'shopify_metafields_global_desc', name: 'Meta Description (website seo)', checked: false },
    { id: 'shopify_option_value2', name: 'Option value 2', checked: false },
    { id: 'shopify_vendor', name: 'Vendor', checked: false },
    { id: 'shopify_option_value3', name: 'Option value 3', checked: false },
    { id: 'price', name: 'Price', checked: true },
    { id: 'compare_at_price', name: 'Compare at price', checked: false },
    { id: 'cost', name: 'Cost Per Item', checked: false },
    { id: 'available', name: 'Inventory Quantity', checked: true },
    { id: 'shopify_inv_qty_adjustment', name: 'Inventory Qty increase or decrease', checked: true },
    { id: 'shopify_barcode', name: 'Barcode', checked: false },
    { id: 'shopify_weight', name: 'Weight', checked: false },
    { id: 'shopify_option_name', name: 'Option name', checked: false },
    { id: 'shopify_metafields_global_title_tag', name: 'Page title (website seo)', checked: false },

    // {id: 'published', name: 'Published', checked: true},
    // {id: 'taxable', name: 'Charge taxes on this product', checked: true},
    // {id: 'inventory_policy', name: 'Backorder', checked: true},
    // {id: 'requires_shipping', name: 'This product requires shipping', checked: true},
    // {id: 'fulfillment_service', name: 'Fulfillment Service', checked: true},
    // {id: 'weight_unit', name: 'Weight unit', checked: true},
    // {id: 'published_scope', name: 'Point of Sale visibility', checked: false}
  ];

  public trueOrFalseOptions = [
    { name: 'True', value: true },
    { name: 'False', value: false },
  ];

  public yesOrNoOptions = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  public fieldsNotToUpdate = [
    { id: 'published', name: 'Published', checked: false },
    { id: 'taxable', name: 'Charge taxes on this product', checked: false },
    { id: 'inventory_policy', name: 'Backorder', checked: false },
    { id: 'requires_shipping', name: 'This product requires shipping', checked: false },
    { id: 'fulfillment_service', name: 'Fulfillment Service', checked: false },
    { id: 'weight_unit', name: 'Weight unit', checked: false },
    { id: 'published_scope', name: 'Point of Sale visibility', checked: true },
  ];

  constructor(private restService: RestService) {}

  public saveRetailerCatalogSettings(settingsVO: RetailerCatalogSettingsVO): Observable<any> {
    return this.restService.post('RetailerCatalogService/addSettings', settingsVO).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : null;
      })
    );
  }

  public getRetailerCatalogSettings(taskId: number): Observable<any> {
    return this.restService.get(`TaskService/getTaskSettings?taskId=${taskId}`).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : null;
      })
    );
  }

  public getFulfillmentServices(catalogId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getFulfillmentServices?catalogId=${catalogId}`).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : null;
      })
    );
  }

  public getFulfillmentServicesByTaskId(taskId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getFulfillmentServicesByTaskId?taskId=${taskId}`).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : null;
      })
    );
  }

  public getLocations(taskId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getLocations?catalogId=${taskId}`).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }
}
