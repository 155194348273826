<div fxLayout="column" fxLayoutGap="20px" fxLayoutGap.lt-md="24px" class="h-100-p">
  <div fxFlex="1 0 0">
    <ng-container *ngIf="!isOnMobile; else mobile">
      <app-custom-table
        [fetcher]="getActivityLogs.bind(this)"
        [displayedColumns]="displayedColumns"
        [isServerSidePaginate]="false"
        [forceReload]="reloadSubject"
        [noResultComponent]="noResults"
        class="activity-log-table"
        [enableFilters]="true"
        [showSearchbar]="false"
        [enableFilterDatePicker]="true"
        [filterArray]="tableFilterArray"
        [activeFilters]="activeFilters"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        (dateFromChange)="handleDateFromChange($event)"
        (dateToChange)="handleDateToChange($event)"
        (filterChange)="handleFilterChange($event)"
        (clearAllClicked)="handleClearAllFilters()"
      >
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef>{{ 'ACTIVITY_LOG.ACTIVITY' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.message }}
            <a [routerLink]="element?.path" [queryParams]="element?.params">
              {{ element.linkText }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{ 'ACTIVITY_LOG.TYPE' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ 'ACTIVITY_LOG.DATE' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.date | customDate }}</td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let item; columns: displayedColumns"></mat-row>
      </app-custom-table>
    </ng-container>
  </div>
</div>

<ng-template #mobile>
  <app-activity-log-mobile
    [fetcher]="getActivityLogs.bind(this)"
    [reFetchSubject]="reloadSubject"
  ></app-activity-log-mobile>
</ng-template>

<ng-template #noResults>
  <div class="centered-text pt-16" fxLayoutGap="column" fxLayoutAlign="center center" fxFlex="1 0 0">
    <app-single-alert-with-icon icon="list_alt" [titleKey]="'ACTIVITY_LOG.NO_DATA'"></app-single-alert-with-icon>
  </div>
</ng-template>
