import { Directive, HostListener, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { AuthorizationEcomService } from '../../service/authorization-ecom/authorization-ecom.service';
import { share, shareReplay } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[authenticaton]',
  standalone: true,
})
export class AuthenticationDirective {
  @Input() dialogType: 'login' | 'register' = 'register';
  @Input() roleType!: AuthDialogType;

  @HostListener('click', ['$event']) onClick($event): void {
    this.subscribeToAuthenticationChange();
  }

  constructor(
    private authorizationService: AuthorizationEcomService,
    private authManagerService: AuthenticationManagerService
  ) {}

  private subscribeToAuthenticationChange(): void {
    this.authorizationService
      .isGuest()
      .pipe(untilDestroyed(this), shareReplay(1))
      .subscribe(() => {
        this.openDialog();
      });
  }

  private openDialog(): void {
    switch (this.dialogType) {
      case 'login':
        this.authManagerService.emitOpenLoginDialog(this.roleType);
        break;
      default:
        this.authManagerService.emitOpenRegistrationDialog(this.roleType);
        break;
    }
  }
}
