<app-dialog-multi-wrapper
  [title]="'MY_SUPPLIERS.DIALOGS.SUPPLIER_REPLY.TITLE' | translate"
  icon="chat_bubble_outline"
  [titleContentDistance]="28"
  [contentButtonsDistance]="28"
  [iconFilled]="false"
  [iconWithCircle]="true"
  buttonsAlignment="end"
>
  <div class="supplier-message-wrapper" fxLayout="row" fxLayoutAlign="center center">"{{ data }}"</div>
</app-dialog-multi-wrapper>
