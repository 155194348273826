import { GettingStartedStepDto } from '../../main/getting-started-new/model/getting-started-step-dto';
import { ActionsUnion, GettingStartedActionTypes } from './getting-started.actions';

export interface GettingStartedState {
  completedSteps: GettingStartedStepDto[];
  allSteps: GettingStartedStepDto[];
}

const initialState: GettingStartedState = {
  completedSteps: null,
  allSteps: null,
};

export function gettingStartedReducer(state = initialState, action: ActionsUnion): GettingStartedState {
  switch (action.type) {
    case GettingStartedActionTypes.GETTING_STARTED_COMPLETED_STEPS_SUCCESS:
      return { ...state, completedSteps: action.payload };
    case GettingStartedActionTypes.GETTING_STARTED_ALL_STEPS_SUCCESS:
      return { ...state, allSteps: action.payload };
    case GettingStartedActionTypes.GETTING_STARTED_ALL_STEPS_START:
      return { ...state, completedSteps: undefined };
    default:
      return state;
  }
}
