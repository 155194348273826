<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="28px"
  class="outer-container"
  [ngClass]="wrapperClass"
  *ngIf="!!translations"
>
  <div
    class="inner-container w-100p"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="0"
    fxLayoutGap.lt-md="28px"
    [ngClass]="innerWrapperClass"
  >
    <app-section-title [title]="titleKey | translate : titlei18nPayload"></app-section-title>
    <div fxLayout="row" fxLayoutAlign="center center" class="slider-container w-100p">
      <div class="slider-container" fxLayoutAlign="center center">
        <ngx-slick-carousel
          fxFlex="1 0 0"
          class="review-list-items"
          [@animateStagger]="{ value: '50' }"
          [config]="config"
        >
          <div
            ngxSlickItem
            *ngFor="let review of reviewCardsContent.reviews; let i = index; let last = last"
            class="slick-item"
          >
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="28px" class="review-card">
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
                <img [src]="review.reviewerLogo.src" [alt]="review.reviewerLogo.alt" class="reviewer" />
                <h4 [ngClass]="'h4'" class="reviewer-name">
                  {{ review.reviewerName }}
                </h4>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
                  <mat-icon class="s-28 star-icon" *ngFor="let _ of [].constructor(review.stars)"> star </mat-icon>
                </div>
              </div>
              <div class="text-center typography-body-3">{{ translations[review.key].REVIEW }}</div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>
