import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { maxBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToolbarCallToActionService {
  contents$ = new BehaviorSubject<ToolbarCallToActionContent[]>([]);

  constructor() {}

  getHighestPriorityContent(): ToolbarCallToActionContent {
    return maxBy(this.contents$.getValue(), (content) => content.priority);
  }

  setActionContent(contents: ToolbarCallToActionContent[]): void {
    this.contents$.next(contents);
  }

  addActionContent(...contents: ToolbarCallToActionContent[]): void {
    this.contents$.next([...this.contents$.getValue(), ...contents]);
  }

  removeContent(type: CallToContentType): void {
    this.contents$.next([...this.contents$.getValue().filter((content) => content.type !== type)]);
  }

  removeContentByTypeAndPosition(type: CallToContentType, position: CallToActionPosition): void {
    this.contents$.next([
      ...this.contents$.getValue().filter((content) => !(content.type === type && content.position === position)),
    ]);
  }

  contents(position: CallToActionPosition): Observable<ToolbarCallToActionContent[]> {
    return this.contents$.asObservable().pipe(map((contents) => contents.filter((item) => item.position === position)));
  }

  setPriorityByTypeAndPosition(type: CallToContentType, position: CallToActionPosition, priorityLevel: number): void {
    this.contents$.next([
      ...this.contents$.getValue().map((content) => {
        if (content.type === type && content.position === position) {
          return { ...content, priority: priorityLevel };
        } else {
          return content;
        }
      }),
    ]);
  }

  getHighestPriorityContentsWithPosition(position: CallToActionPosition): Observable<ToolbarCallToActionContent[]> {
    return this.contents(position).pipe(
      map((contents) => {
        let higherPrior = null;
        contents.forEach((elem) => {
          higherPrior = higherPrior == null || elem.priority > higherPrior ? elem.priority : higherPrior;
        });
        return contents.filter((item) => item.priority === higherPrior);
      })
    );
  }

  clearContent(): void {
    this.contents$.next([]);
  }
}

export interface ToolbarCallToActionContent {
  type: CallToContentType;
  title: string;
  priority: number;
  theme: CallToActionTheme;
  action: CallToAction;
  position: CallToActionPosition;
}

export interface CallToAction {
  type: CallToActionType;
  callback?: () => void;
  url?: string;
  queryParams?: Params;
}

export type CallToContentType = 'newHere' | 'newHereLogin' | 'verifyEmail' | 'earnCredit' | 'setupGuide';
export type CallToActionType = 'inner-navigation' | 'external-navigation' | 'callback' | 'non-click';
export type CallToActionTheme = 'warning' | 'info' | 'danger' | 'success';
export type CallToActionPosition = 'top' | 'below-header';
