<div layout="row">
  <div class="ftp-holder">
    <form novalidate class="mat-card mat-elevation-z4 p-24" fxLayout="row" fxLayoutAlign="">
      <mat-form-field>
        <mat-select
          placeholder="Connection type"
          (selectionChange)="changedFtpConnection($event)"
          style="min-width: 200px"
        >
          <mat-option *ngFor="let connectionType of connectionTypes" [value]="connectionType.type">
            {{ connectionType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div [style.visibility]="isFtp ? 'visible' : 'hidden'">
      <h1>{{ 'TASKWIZARD.ADDFILES.FTP.FTP' | translate }}</h1>
      <form #ftpConnectionForm="ngForm" novalidate class="simple-form" class="mat-card mat-elevation-z4 p-24">
        <div fxLayout="column" fxLayout.gt-lg="row wrap" fxLayoutAlign="center" fxLayoutGap="35px">
          <!--Host-->
          <mat-form-field>
            <input
              id="ftpHost"
              name="host"
              matInput
              placeholder="Host"
              #ftpHost="ngModel"
              [(ngModel)]="ftpCredentials.host"
              required
              class="user-input"
            />
            <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.HOST' | translate }} </mat-error>
          </mat-form-field>
          <!--Port-->
          <mat-form-field>
            <input
              id="ftpPort"
              name="port"
              matInput
              [placeholder]="'TASKWIZARD.ADDFILES.FTP.HOST_PLACEHOLDER' | translate"
              #ftpPort="ngModel"
              [(ngModel)]="ftpCredentials.port"
              required
              class="user-input"
            />
            <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.PORT' | translate }}</mat-error>
          </mat-form-field>
          <!--UserName Input-->
          <mat-form-field>
            <input
              id="userName"
              name="name"
              matInput
              [placeholder]="'TASKWIZARD.ADDFILES.FTP.USERNAME_PLACEHOLDER' | translate"
              #username="ngModel"
              [(ngModel)]="ftpCredentials.username"
              required
              class="user-input"
            />
            <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.USERNAME' | translate }}</mat-error>
          </mat-form-field>
          <!--Password Input-->
          <mat-form-field>
            <input
              id="userPassword"
              name="password"
              matInput
              type="password"
              autocomplete="new-password"
              [placeholder]="'TASKWIZARD.ADDFILES.FTP.PASSWORD_PLACEHOLDER' | translate"
              [(ngModel)]="ftpCredentials.password"
              required
            />
            <mat-error>
              {{ 'TASKWIZARD.ADDFILES.FTP.PASSWORD' | translate }}
            </mat-error>
          </mat-form-field>
          <!--Root-->
          <mat-form-field>
            <input
              id="ftpRoot"
              name="root"
              matInput
              [placeholder]="'TASKWIZARD.ADDFILES.FTP.ROOT_PLACEHOLDER' | translate"
              #ftpRoot="ngModel"
              [(ngModel)]="ftpCredentials.root"
              class="user-input"
            />
            <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.ROOT' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-lg="row wrap" fxLayoutAlign="center" fxLayoutGap="35px">
          <section>
            <mat-checkbox
              id="ftpPassive"
              name="passive"
              matCheckbox
              #passive="ngModel"
              [(ngModel)]="ftpCredentials.passive"
              class="user-input"
            >
              {{ 'TASKWIZARD.ADDFILES.FTP.PASSIVE' | translate }}
            </mat-checkbox>
            <mat-checkbox
              id="ftpSsl"
              name="ssl"
              matCheckbox
              [checked]="ftpCredentials.isSsl"
              #ssl="ngModel"
              [(ngModel)]="ftpCredentials.isSsl"
              style="margin-left: 5px"
              class="user-input"
            >
              SSL
            </mat-checkbox>
          </section>
          <button mat-button color="accent" (click)="connectFtp()" [disabled]="isConnectButtonPressed">Connect</button>
        </div>
      </form>
    </div>
    <div class="ftp-manager" [style.visibility]="hideFtpManager ? 'hidden' : 'visible'">
      <h1>{{ 'TASKWIZARD.ADDFILES.FTP.FTP_MANAGER' | translate }}</h1>
      <div class="mat-card mat-elevation-z4 p-24">
        <app-breadcrumb-navigation
          [navItems]="breadCrumb"
          (navItemChange)="breadCrumbCalback($event)"
        ></app-breadcrumb-navigation>
        <hr />
        <mat-grid-list cols="2" rowHeight="50px">
          <mat-grid-tile
            class="text-inside-grid"
            fxLayoutAlign="start center"
            *ngFor="let file of fileList"
            (dblclick)="rowDoubleClick(file)"
            (click)="rowSingleClick(file)"
            [ngClass]="{ selected: file == selected }"
            matRipple
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div class="ftp-align-left">
              <mat-icon mat-list-icon>{{ file.isDirectory ? 'folder' : 'insert_drive_file' }}</mat-icon>
              {{ file.name }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <button mat-button [disabled]="disableDownloadButton" color="primary" (click)="download(selected)">
          {{ 'TASKWIZARD.ADDFILES.FTP.DOWNLOAD' | translate }}
        </button>
        <div [style.visibility]="isFileDownloadSuccessful ? 'visible' : 'hidden'">
          <h4>{{ 'TASKWIZARD.ADDFILES.FTP.SETTINGS' | translate }}</h4>
          <mat-form-field>
            <mat-select
              id="fileNodeTree"
              name="fileNodeTreeSelect"
              placeholder="NONE"
              (selectionChange)="selectrionChange($event.value)"
            >
              <mat-option *ngFor="let node of nodeTree" [value]="node.path">
                <span class="level{{ node.level }}">{{ node.key }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="ftp-manager" [style.visibility]="hideFtpManager ? 'hidden' : 'visible'">
      <h1>{{ 'TASKWIZARD.ADDFILES.FTP.FTP_MANAGER' | translate }}</h1>
      <div class="mat-card mat-elevation-z4 p-24">
        <app-breadcrumb-navigation
          [navItems]="breadCrumb"
          (navItemChange)="breadCrumbCalback($event)"
        ></app-breadcrumb-navigation>
        <hr />
        <mat-grid-list cols="2" rowHeight="50px">
          <mat-grid-tile
            class="text-inside-grid"
            fxLayoutAlign="start center"
            *ngFor="let file of fileList"
            (dblclick)="rowDoubleClick(file)"
            (click)="rowSingleClick(file)"
            [ngClass]="{ selected: file == selected }"
            matRipple
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <div class="ftp-align-left">
              <mat-icon mat-list-icon>{{ file.isDirectory ? 'folder' : 'insert_drive_file' }}</mat-icon>
              {{ file.name }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <button mat-button [disabled]="disableDownloadButton" color="primary" (click)="download(selected)">
          {{ 'TASKWIZARD.ADDFILES.FTP.DOWNLOAD' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
