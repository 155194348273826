import { ConstantChip, VariableChip } from '../../main/droppable-input/chip';
import { FieldStructure } from '../../main/filter/filter-item/filter-item.component';
import { FilterBox } from '../../main/filter/filter-box';
import { Utils } from 'app/utils/utils';

export class TaskwizardUpdateResponse {
  private _files: TaskFile[];
  private _task: Task;
  private _mapping: Mapping;
  private _scheduling: ScheduleTimeVO;
  private _taskFilters: any;
  private _variantFilters: any;
  private _rcatalogId: number;
  private _comments: any;
  private _ecomId: number;
  private _isTaskRunning: boolean;

  constructor() {}

  public isMapping(): boolean {
    return !Utils.isNullOrUndefined(this.mapping);
  }

  public isScheduling(): boolean {
    return !Utils.isNullOrUndefined(this.scheduling);
  }

  public isTaskFilters(): boolean {
    return !!this.taskFilters && !!this.taskFilters.filters && this.taskFilters.filters.length > 0;
  }

  public isTaskVariantFilters(): boolean {
    return !!this.taskFilters && !!this.taskFilters.variantFilters && this.taskFilters.variantFilters.length > 0;
  }

  get taskFilters(): any {
    return this._taskFilters;
  }

  set taskFilters(value: any) {
    this._taskFilters = value;
  }

  get variantFilters(): any {
    return this._variantFilters;
  }

  set variantFilters(value: any) {
    this._variantFilters = value;
  }

  get scheduling(): ScheduleTimeVO {
    return this._scheduling;
  }

  set scheduling(value: ScheduleTimeVO) {
    this._scheduling = value;
  }

  get mapping(): Mapping {
    return this._mapping;
  }

  set mapping(value: Mapping) {
    this._mapping = value;
  }

  get files(): TaskFile[] {
    return this._files;
  }

  set files(value: TaskFile[]) {
    this._files = value;
  }

  get task(): Task {
    return this._task;
  }

  set task(value: Task) {
    this._task = value;
  }

  get rcatalogId(): number {
    return this._rcatalogId;
  }

  set rcatalogId(value: number) {
    this._rcatalogId = value;
  }

  get comments(): any {
    return this._comments;
  }

  set comments(value: any) {
    this._comments = value;
  }

  get ecomId(): number {
    return this._ecomId;
  }

  set ecomId(value: number) {
    this._ecomId = value;
  }

  get isTaskRunning(): boolean {
    return this._isTaskRunning;
  }

  set isTaskRunning(value: boolean) {
    this._isTaskRunning = value;
  }
}

export class TaskFile {
  private _fileId: number;
  private _taskId: number;
  private _extension: string;
  private _remotepath: string;
  private _settings: any;
  private _nodeTree: string;
  private _date: string;

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }

  constructor() {}

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get extension(): string {
    return this._extension;
  }

  set extension(value: string) {
    this._extension = value;
  }

  get remotepath(): string {
    return this._remotepath;
  }

  set remotepath(value: string) {
    this._remotepath = value;
  }

  get settings(): any {
    return this._settings;
  }

  set settings(value: any) {
    this._settings = value;
  }

  get nodeTree(): string {
    return this._nodeTree;
  }

  set nodeTree(value: string) {
    this._nodeTree = value;
  }
}

export class Task {
  private _userId;
  private _taskId;
  private _name;
  private _sourceType;
  private _pid;
  private _date;
  private _isDownloadRunning;

  constructor() {}

  get userId(): number {
    return this._userId;
  }

  set userId(value) {
    this._userId = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value) {
    this._taskId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get sourceType(): string {
    return this._sourceType;
  }

  set sourceType(value) {
    this._sourceType = value;
  }

  get pid(): number {
    return this._pid;
  }

  set pid(value) {
    this._pid = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value) {
    this._date = value;
  }

  get isDownloadRunning(): boolean {
    return this._isDownloadRunning;
  }

  set isDownloadRunning(value) {
    this._isDownloadRunning = value;
  }
}

export interface ProductImagesChips {
  path: (ConstantChip | VariableChip)[];
  alt_text: (ConstantChip | VariableChip)[];
}

export interface ProductAttributes {
  key: (ConstantChip | VariableChip)[];
  value: (ConstantChip | VariableChip)[];
}

export class ProductIds {
  id: string;
  value: (ConstantChip | VariableChip)[];
}

export interface MapInputData {
  chips: (ConstantChip | VariableChip)[];
  operations: any;
  settings: any;
}

export interface ProdAttributes {
  name: MapInputData;
  value: MapInputData;
}

export interface ProdIds {
  id: number;
  value: MapInputData;
  placeholder: string;
}

export interface ProdImages {
  url: MapInputData;
  value: MapInputData;
}

export interface Options {
  name: MapInputData;
  value: MapInputData;
}

export interface MappingTemplateSettings {
  variantsOption: boolean;
}

export class Mapping {
  settings: MappingTemplateSettings = { variantsOption: false };
  productIds: ProdIds[] = [];
  attributes: ProdAttributes[] = [];
  tags: MapInputData[] = [];
  collections: MapInputData[] = [];
  images: ProdImages[] = [];
  variantImages: ProdImages[] = [];
  quantities: MapInputData[] = [];
  quantitiesIncDec: MapInputData[] = [];
  dimSettings: any[] = [];
  isWrongData = false;
  singleFields: any = {
    TITLE: <MapInputData>{ chips: [], settings: [], operations: [] },
    DESCRIPTION: <MapInputData>{ chips: [], settings: [], operations: [] },
    BRAND: <MapInputData>{ chips: [], settings: [], operations: [] },
    CATEGORY: <MapInputData>{ chips: [], settings: [], operations: [] },
    CO: <MapInputData>{ chips: [], settings: [], operations: [] },
    WARRANTY: <MapInputData>{ chips: [], settings: [], operations: [] },
    PRICE: <MapInputData>{ chips: [], settings: [], operations: [] },
    ORIGINAL_PRICE: <MapInputData>{ chips: [], settings: [], operations: [] },
    DISCOUNTED_PRICE: <MapInputData>{ chips: [], settings: [], operations: [] },
    QTY: <MapInputData>{ chips: [], settings: [], operations: [] },
    QTY_INC_DEC: <MapInputData>{ chips: [], settings: [], operations: [] },
    SKU: <MapInputData>{ chips: [], settings: [], operations: [] },
    UPC: <MapInputData>{ chips: [], settings: [], operations: [] },
    PART_NUMBER: <MapInputData>{ chips: [], settings: [], operations: [] },
    WIDTH: <MapInputData>{ chips: [], settings: [], operations: [] },
    HEIGHT: <MapInputData>{ chips: [], settings: [], operations: [] },
    LENGTH: <MapInputData>{ chips: [], settings: [], operations: [] },
    WEIGHT: <MapInputData>{ chips: [], settings: [], operations: [] },
    V_IMAGES: <MapInputData>{ chips: [], settings: [], operations: [] },
    V_IMAGES_ALTS: <MapInputData>{ chips: [], settings: [], operations: [] },
    VARIANT_ID: <MapInputData>{ chips: [], settings: [], operations: [] },

    SHOPIFY_PUBLISHED: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_OPTION_NAME_1: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_OPTION_VALUE_1: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_OPTION_NAME_2: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_OPTION_VALUE_2: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_OPTION_NAME_3: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_OPTION_VALUE_3: <MapInputData>{ chips: [], settings: [], operations: [] },

    // V3 FIELDS
    SHOPIFY_HANDLE: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_META_DESC: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_PAGE_TITLE: <MapInputData>{ chips: [], settings: [], operations: [] },
    COST_PER_ITEM: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_PRODUCT_CATEGORY: <MapInputData>{ chips: [], settings: [], operations: [] },
    PRODUCT_TYPE: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_VARIANT_IMAGES: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_VARIANT_IMAGES_ALT: <MapInputData>{ chips: [], settings: [], operations: [] },
    SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG: <MapInputData>{ chips: [], settings: [], operations: [] },

    URL: <MapInputData>{ chips: [], settings: [], operations: [] },
    SEO_PAGE_TITLE: <MapInputData>{ chips: [], settings: [], operations: [] },
    SEO_META_DESCRIPTION: <MapInputData>{ chips: [], settings: [], operations: [] },
    FILTERABLE_PRODUCT_DATA: <MapInputData>{ chips: [], settings: [], operations: [] },
    FILTERABLE_VARIANT_DATA: <MapInputData>{ chips: [], settings: [], operations: [] },
  };

  // private readonly advanced_fields = ['PART_NUMBER', 'MODEL_NUMBER', 'UOM', 'C_SKU', 'CC_CATEGORY',
  //     'CC_CLASS', 'CC_CLASS_ITEM', 'CC_ALI', 'C_SKU_UOM', 'C_ISSUE_UOM', 'CON_UOM', 'ORDER_UOM', 'C_UNSPSC'];

  emptyMapInputDataObj(): MapInputData {
    return <MapInputData>{ chips: [], settings: [], operations: [] };
  }

  initEmptyMapping(): void {
    this.addNewTag();
    this.addNewCollection();
    this.addNewProdImage();
    this.addNewVariantImage();
    this.addNewProductAttribute();
    this.addNewQty();
    this.addNewQtyIncDec();
  }

  isPunchOut(): boolean {
    return this.singleFields.PUNCH_OUT;
  }

  // isAdvancedFields(): boolean {
  //     let isExistAdvancedField = false;
  //     for (let advancedFKey of this.advanced_fields) {
  //         if (!Utils.isNullOrUndefined(this.singleFields[advancedFKey])
  //             && this.singleFields[advancedFKey].chips.length > 0) {
  //             isExistAdvancedField = true;
  //         }
  //     }
  //     return isExistAdvancedField;
  // }

  addNewQtyIncDec(): void {
    this.quantitiesIncDec.push(this.emptyMapInputDataObj());
  }

  removeQtyIncDec(index): void {
    this.quantitiesIncDec.splice(index, 1);
  }

  addNewQty(): void {
    this.quantities.push(this.emptyMapInputDataObj());
  }

  removeQty(index): void {
    this.quantities.splice(index, 1);
  }

  addNewTag(): void {
    this.tags.push(this.emptyMapInputDataObj());
  }

  addNewCollection(): void {
    this.collections.push(this.emptyMapInputDataObj());
  }

  addNewVariantImage(): void {
    this.variantImages.push(<ProdImages>{ url: this.emptyMapInputDataObj(), value: this.emptyMapInputDataObj() });
  }

  addNewProdImage(): void {
    this.images.push(<ProdImages>{ url: this.emptyMapInputDataObj(), value: this.emptyMapInputDataObj() });
  }

  addNewProductAttribute(): void {
    this.attributes.push(<ProdAttributes>{ name: this.emptyMapInputDataObj(), value: this.emptyMapInputDataObj() });
  }

  removeTag(index: number): void {
    this.tags.splice(index, 1);
  }

  removeCollection(index: number): void {
    this.collections.splice(index, 1);
  }

  removeVariantImage(index: number): void {
    this.variantImages.splice(index, 1);
  }

  removeProdImage(index: number): void {
    this.images.splice(index, 1);
  }

  removeProdAttr(index: number): void {
    this.attributes.splice(index, 1);
  }

  removeProdIdObj(prodIdObj: ProdIds): void {
    this.productIds.splice(this.productIds.indexOf(prodIdObj), 1);
  }
}

export class ScheduleTimeVO {
  constructor() {}

  taskId: number;
  type: string;
  dates: string[] = [];
}

export class TaskFilters {
  operatorBoxes: string[];
  fieldsStructure: FieldStructure[];
  filterBoxes: FilterBox[];
}
