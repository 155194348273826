import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterStructure } from '../../../../vo/filter/filter-structure';
import { AutocompleteSuggestion } from '../../../retailer-import-list/tab-items/retailer-import-list-products/model/autocomplete-suggestion';
import { NeedAutocomplete } from '../../../retailer-import-list/tab-items/retailer-import-list-products/model/need-autocomplete';
import { FilterGroupConditionEnum } from '../../model/filter-group-condition';
import { FilterItem } from '../../model/filter-item';
import { FilterItemError } from '../../model/helper/filter-item-error';

@Component({
  selector: 'app-filter-item-list',
  templateUrl: './filter-item-list.component.html',
  styleUrls: ['./filter-item-list.component.scss'],
})
export class FilterItemListComponent implements OnInit {
  @Input() filterItems: FilterItem[];
  @Input() condition: FilterGroupConditionEnum;
  @Input() filterStructures: FilterStructure[];
  @Input() autocompleteSuggestion: BehaviorSubject<AutocompleteSuggestion>;
  @Input() hasNoMoreAutocomplete: Subject<boolean>;
  @Input() filterItemErrors: FilterItemError[];
  @Input() locked = false;
  @Input() everyItemLocked = false;
  @Input() useOffset = false;
  @Output() conditionChange = new EventEmitter<FilterGroupConditionEnum>();
  @Output() addItemClick = new EventEmitter<void>();
  @Output() deleteItemClicked = new EventEmitter<string>();
  @Output() needAutoComplete = new EventEmitter<NeedAutocomplete>();
  @Output() needMoreAutocomplete = new EventEmitter<NeedAutocomplete>();
  @Output() filterItemChange = new EventEmitter<FilterItem>();
  @Output() filterItemsErrorRevalidate = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  getFilterItemError(id: string): FilterItemError {
    return this.filterItemErrors.find((error) => error.id === id);
  }
}
