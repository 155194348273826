import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { DEFAULT_RATING_ITEMS } from './default-rating-items';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-rating-with-icons',
  standalone: true,
  imports: [CommonModule, FlexModule, MatIconModule, SvgIconComponent, ExtendedModule, TranslateModule],
  templateUrl: './rating-with-icons.component.html',
  styleUrls: ['./rating-with-icons.component.scss'],
})
export class RatingWithIconsComponent implements OnInit {
  @Input() ratingItems = DEFAULT_RATING_ITEMS;
  @Input() selectedRating: number;
  @Output() selectedChange = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {}

  handleSelection(value: number): void {
    this.selectedRating = value;
    this.selectedChange.emit(value);
  }
}
