import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[translationCallback]',
  standalone: true,
})
export class TranslationCallbackDirective {
  @Input() callbackClasses: string[];
  @Output() onClassClick = new EventEmitter<string>();

  @HostListener('click', ['$event']) onClick(event): void {
    const classes = this.callbackClasses?.filter((item) => event.target.classList.contains(item));
    if (classes?.length) {
      classes.forEach((item) => this.onClassClick.emit(item));
    }
  }

  constructor() {}
}
