import { VariantVO } from '../../vo/search-product-vo';

export class ProductPricingUtils {
  static getCostPrice(variant: VariantVO): number {
    return !!variant.DISCOUNTED_PRICE ? variant.DISCOUNTED_PRICE : variant.PRICE;
  }

  static getRetailPrice(variant: VariantVO, legacyPossibleMargin: number): number {
    if (!!variant.RETAIL_PRICE) {
      return variant.RETAIL_PRICE;
    }
    return (ProductPricingUtils.getCostPrice(variant) * (legacyPossibleMargin + 100)) / 100;
  }

  static getCalculatedProfit(variant: VariantVO, legacyPossibleMargin = 0): number {
    return ProductPricingUtils.calculateProfit(
      ProductPricingUtils.getCostPrice(variant),
      ProductPricingUtils.getRetailPrice(variant, legacyPossibleMargin)
    );
  }

  static getCalculatedMargin(variant: VariantVO, legacyPossibleMargin = 0): number {
    return ProductPricingUtils.calculateMargin(
      ProductPricingUtils.getCostPrice(variant),
      ProductPricingUtils.getRetailPrice(variant, legacyPossibleMargin)
    );
  }

  static calculatePrices = (variant: VariantVO, legacyPossibleMargin = 0): ProductPrices => {
    if (!variant) {
      return undefined;
    }
    const costPrice = ProductPricingUtils.getCostPrice(variant);
    const retailPrice = ProductPricingUtils.getRetailPrice(variant, legacyPossibleMargin);
    return {
      costPrice,
      retailPrice,
      calculatedMargin: ProductPricingUtils.calculateMargin(costPrice, retailPrice),
      calculatedProfit: ProductPricingUtils.calculateProfit(costPrice, retailPrice),
    };
  };

  private static calculateMargin(costPrice: number, retailPrice: number): number {
    return (retailPrice / costPrice - 1) * 100;
  }

  private static calculateProfit(costPrice: number, retailPrice: number): number {
    return retailPrice - costPrice;
  }
}

export interface ProductPrices {
  costPrice: number;
  retailPrice: number;
  calculatedMargin: number;
  calculatedProfit: number;
}
