import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChatService } from 'app/service/chat/chat.service';
import { Observable, of, throwError } from 'rxjs';
import { ChatMinimizationService } from '../../../service/chat-minimization/chat-minimization.service';
import { Utils } from 'app/utils/utils';
import { ChannelItem, ChannelUserItem, ChatSupplierProfile, ChatUser } from '../../../service/chat/model/chat-models';
import { ROLE_TYPE, RolesEnum } from '../../../vo/roles/roles';
import { ChatHandlerService } from './service/chat-handler.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

export interface ChatOptions {
  hideChannelSide: boolean;
  hideDirectMessageSide: boolean;
  innerChat: boolean;
}
@UntilDestroy()
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [ChatHandlerService],
})
export class ChatComponent implements OnInit {
  @Input() options: ChatOptions = { hideChannelSide: false, hideDirectMessageSide: false, innerChat: false };
  @Input() recipient: ChatUser;
  @Input() currUser: ChatUser;
  @Input() isMobile = false;
  @Input() currChannelUser: ChannelUserItem;
  @Input() role: ROLE_TYPE;

  channelsLoading = true;
  channelError = false;
  searchTermObs: Observable<string>;
  showActiveChatPanel = true;
  channels: ChannelItem[];
  selectedChannel: ChannelItem;
  expanded = false;
  searchTerm: string;
  ocProfileDetailsStatus = true;
  userProfileDetails: Observable<ChatSupplierProfile>;
  createChannelTryCount = {};
  hasLoadMore: Observable<boolean>;
  private from = 0;
  private size = 20;

  constructor(
    private chatService: ChatService,
    private changeDet: ChangeDetectorRef,
    public chatHandler: ChatHandlerService,
    private chatMinimizationService: ChatMinimizationService
  ) {
    this.isMobile = this.chatService.isMobile();
    this.ocProfileDetailsStatus = !this.isMobile;
    this.hasLoadMore = this.chatHandler.hasLoadMore;
    this.searchTermObs = chatHandler.searchTerm;
  }

  ngOnInit(): void {
    if (!this.isMobile) {
      this.expanded = true;
    }
    this.handleSearchTermAndUnseenFilterChange();
    this.initProfileDetailsOCHandler();
  }

  handleSearchTermAndUnseenFilterChange(): void {
    this.chatHandler.filterChanged.pipe(untilDestroyed(this)).subscribe(([searchTerm, unseen]) => {
      this.init(searchTerm, unseen);
    });
  }

  init(searchTerm?: string, unseen?: boolean): void {
    this.channelsLoading = true;
    this.getChannels(0, this.size, searchTerm, unseen).subscribe({
      next: (channels) => {
        this.channels = channels;
        if (
          !!this.channels.length &&
          !this.isMobile &&
          !this.options.hideChannelSide &&
          !!this.showActiveChatPanel &&
          !this.selectedChannel
        ) {
          this.channelClickHandler(this.channels[0]);
        }

        this.channelsLoading = false;
        this.channelError = false;
        if (this.recipient && this.selectedChannel?.sUserId !== this.recipient.id) {
          this.setDefaultChannel(this.recipient);
        }
      },
      error: () => {
        this.channelError = true;
        this.channelsLoading = false;
      },
    });
  }

  initProfileDetailsOCHandler(): void {
    this.chatService
      .ocProfileDetailsEvent()
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (status === null) {
          return;
        }
        this.ocProfileDetailsStatus = status;
      });
  }

  initProfileDetails(): void {
    if (this.options.innerChat) {
      return;
    }

    const cUserItem: ChannelUserItem = this.selectedChannel.recipients.filter((u) => u.sUserId !== this.currUser.id)[0];
    if (this.options.hideDirectMessageSide) {
      this.userProfileDetails = of(<ChatSupplierProfile>{
        companyName: cUserItem.name,
        logo: cUserItem.avatar,
        contactEmail: cUserItem.email,
      });
    } else {
      this.userProfileDetails = this.chatService.getSupplierProfileDetails(cUserItem.sUserId);
    }
  }

  getChannels(page: number, size: number, searchTerm?: string, unseen?: boolean): Observable<ChannelItem[]> {
    return this.chatHandler.getChannels(this.currUser.id, page, size, searchTerm, unseen).pipe(untilDestroyed(this));
  }

  setDefaultChannel(recipient: ChatUser): void {
    if (this.selectedChannel && recipient.id === this.selectedChannel.sUserId) {
      return;
    }
    this.selectedChannel = this.channels.find((c) => !!c.recipients.find((r) => r.sUserId == recipient.id));
    const channelAsObs = of(this.selectedChannel);
    channelAsObs
      .pipe(
        switchMap((channel) =>
          !!channel
            ? channelAsObs
            : this.chatService
                .getChannelById(recipient.id, this.currUser.id)
                .pipe(catchError((err) => (err.errorCode == 404 ? of(null) : throwError(err))))
        )
      )
      .subscribe((channel) => {
        if (!!channel) {
          this.selectedChannel = channel;
          this.setCurrChannelUser();
          this.initProfileDetails();
        } else {
          this.createChannelTryCount[recipient.id] = true;
          this.chatHandler.createChannel(recipient, this.currUser).subscribe(() => {
            this.init();
          });
        }
      });
  }

  channelClickHandler(channelItem: ChannelItem): void {
    this.currChannelUser = null;
    this.changeDet.detectChanges();
    this.selectedChannel = channelItem;
    this.setCurrChannelUser();
    this.initProfileDetails();
    this.chatMinimizationService.addMinimizedChat({
      currentUser: {
        id: this.currUser.id,
        name: this.currUser.name,
        email: this.currUser.email,
        avatar: this.currUser.avatar,
      },
      lastMessageTime: null,
      recipientUser: {
        id: this.selectedChannel.sUserId,
        name: this.selectedChannel.name,
        email: this.selectedChannel.email,
        avatar: this.selectedChannel.avatar,
      },
    });
  }

  closeSearchPanel(): void {
    this.showActiveChatPanel = true;
  }

  setCurrChannelUser(): void {
    this.currChannelUser = this.selectedChannel.recipients.find((r) => r.sUserId == this.currUser.id);
  }

  search(value: string): void {
    this.searchTerm = value;
    this.chatHandler.setSearchTerm(value);
  }

  handleSearchFocus(): void {
    if (this.role === RolesEnum.RETAILER) {
      this.showActiveChatPanel = false;
    }
  }

  switchBackToActiveChatsPanel(): void {
    if (this.role === RolesEnum.RETAILER) {
      this.chatHandler.searchTerm.pipe(take(1)).subscribe((searchTerm) => {
        if (!!searchTerm) {
          this.channelsLoading = true;
          this.chatHandler.setSearchTerm('');
        }
        this.showActiveChatPanel = true;
      });
    }
  }
  // onCreateChannel(user: ChatUser): void {
  //   this.chatHandler.createChannel(user, this.currUser).subscribe(() => {
  //     this.init();
  //     this.recipient = user;
  //     this.setDefaultChannel(user);
  //     this.closeSearchPanel();
  //   });
  // }

  handleLoadMore(): void {
    this.chatHandler.filterChanged
      .pipe(
        take(1),
        switchMap(([searchTerm, unseen]) =>
          this.getChannels(++this.from, this.size, searchTerm, unseen).pipe(
            untilDestroyed(this),
            catchError(() => of(null))
          )
        ),
        tap((items) => {
          if (!!items) {
            this.channels = this.channels.concat(items);
          }
        })
      )
      .subscribe();
  }

  resetChannels(): void {
    this.getChannels(0, this.size).subscribe((channels) => {
      this.channels = channels.filter((channel) => !Utils.isNullOrUndefined(channel));
    });
  }
  recipientClickHandler(user: ChatUser): void {
    this.recipient = user;
    this.setDefaultChannel(user);
    this.channelsLoading = true;
    this.chatHandler.setSearchTerm('');
    this.closeSearchPanel();
  }
}
