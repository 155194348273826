import { ElementRef, Injectable } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _contentHeight: ReplaySubject<number> = new ReplaySubject<number>(1);
  private _toolbarHeight: ReplaySubject<number> = new ReplaySubject<number>(1);
  private _stickyHeaderVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  contentHeight = this._contentHeight.asObservable();
  toolbarHeight = this._toolbarHeight.asObservable();
  stickyHeaderVisible = this._stickyHeaderVisible.asObservable();
  contentHeightWithoutPadding = this.contentHeight.pipe(map((value) => value - 48));

  constructor() {}

  initContent(ref: ElementRef<HTMLElement>): void {
    this.observeElementHeight(ref, this._contentHeight);
  }

  initToolbar(ref: ElementRef<HTMLElement>): void {
    this.observeElementHeight(ref, this._toolbarHeight);
  }

  setStickyHeaderVisible(visible: boolean): void {
    this._stickyHeaderVisible.next(visible);
  }

  private observeElementHeight(ref: ElementRef<HTMLElement>, replaySubject: ReplaySubject<number>): void {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0].contentRect;
      replaySubject.next(entry.height);
    });

    observer.observe(ref.nativeElement);
  }
}
