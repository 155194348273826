<div class="mobile-main-menu-center" fxLayout="column">
  <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" class="p-20 header">
    <div fxLayoutAlign="center center" (click)="backClicked()">
      <button mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <a class="back-text">{{ 'MOBILE_CENTER.BACK_TO_PROFILE' | translate }}</a>
    </div>
    <button mat-icon-button (click)="closeClicked()" class="close-button"><mat-icon>close</mat-icon></button>
  </div>
  <div fxFlex="1 0 0" class="px-20 content" overflowDirective>
    <h3 class="px-20 pt-20 m-0">{{ role | roleTranslation }} Center</h3>
    <fuse-navigation layout="vertical" (itemClicked)="itemClicked()"></fuse-navigation>
  </div>
</div>
