<ng-container *ngIf="!loading; else skeleton">
  <div class="category-card-list" *ngIf="!!widgets">
    <app-category-card-item
      *ngFor="let widget of widgets"
      [widget]="widget"
      [cardHeight]="cardHeight"
    ></app-category-card-item>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="category-card-list">
    <app-skeleton-category-card-item
      [cardHeight]="cardHeight"
      *ngFor="let _ of [].constructor(MAX_WIDGETS)"
    ></app-skeleton-category-card-item>
  </div>
</ng-template>
