import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'app/utils/utils';
import { TaskwizardService } from '../../../../service/taskwizard/taskwizard.service';
import { ProductImageViewerComponent } from '../../../../shared/components/product-image-viewer/product-image-viewer.component';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements OnInit {
  @ViewChild('imageViewer', { static: false }) imageViewer: ProductImageViewerComponent;

  selectedSample: any;
  selectedImage: any;
  showFull = false;
  currentIndex: number;

  dataSourceVariants = [];
  displayedVColumns = [
    'price',
    'qty',
    'sku',
    'discounted_price',
    'original_price',
    'shopify_option_value_1',
    'shopify_option_value_2',
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public samples: any, public taskWizardService: TaskwizardService) {}

  ngOnInit(): void {
    if (!Utils.isNullOrUndefined(this.samples) && this.samples.length > 0) {
      this.selectedSample = this.samples[0];
      this.currentIndex = 0;
      this.selectSample(this.currentIndex);
    }
  }

  public openProductPictureViewer(currentIndex: number): void {
    this.imageViewer.open(currentIndex, this.selectedSample.IMAGES, false);
  }

  public changeImage(event, value): void {
    this.selectedImage = value;
  }

  public showMoreDescription(): void {
    this.showFull = true;
  }

  paginateBackBtn(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.selectSample(this.currentIndex);
    }
  }

  paginateFowardBtn(): void {
    if (this.currentIndex < this.samples.length - 1) {
      this.currentIndex++;
      this.selectSample(this.currentIndex);
    }
  }

  selectSample(index): void {
    this.selectedSample = this.samples[this.currentIndex];
    this.dataSourceVariants = this.selectedSample.VARIANTS;

    if (Utils.isNullOrUndefined(this.selectedSample.IMAGES)) {
      this.selectedSample.IMAGES = [];
    }
    for (const variant of this.selectedSample.VARIANTS) {
      if (!Utils.isNullOrUndefined(variant.V_IMAGES)) {
        this.selectedSample.IMAGES.push(...variant.V_IMAGES);
      }
    }
    this.selectedSample.IMAGES = this.selectedSample.IMAGES.filter((v, i, a) => a.indexOf(v) === i);

    if (!Utils.isNullOrUndefined(this.selectedSample.IMAGES) && this.selectedSample.IMAGES.length > 0) {
      this.selectedImage = this.selectedSample.IMAGES[0];
    }
  }
}
