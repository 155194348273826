import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RcatalogsState } from './rcatalogs.reducer';

const getRcCatalogState = createFeatureSelector<RcatalogsState>('rcatalogs');

export const rcatalogsSelector = createSelector(
  getRcCatalogState,
  (state: RcatalogsState) => !!state && !!state.catalogs && state.catalogs
);
export const nonAlibabaCatalogsSelector = createSelector(
  rcatalogsSelector,
  (catalogs) => !!catalogs && catalogs.filter((catalog) => !catalog.alibabaCatalog)
);

export const marketplaceCatalogsSelector = createSelector(
  nonAlibabaCatalogsSelector,
  (catalogs) => !!catalogs && catalogs.filter((catalog) => !catalog.filter.some((filter) => filter.key === 'TASK_ID'))
);
export const selectedCatalogSelector = createSelector(getRcCatalogState, (state) => !!state && state.selectedCatalog);

export const recentlyAddedProductsSelector = createSelector(getRcCatalogState, (state) => state?.recentlyAddedProducts);
