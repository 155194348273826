import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { SubscriptionCancelService } from '../../../../service/subscription-cancel/subscription-cancel.service';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PlanType } from '../../cancel-dialog.component';

@Component({
  selector: 'app-cancel-dialog-reason',
  templateUrl: './cancel-dialog-reason.component.html',
  styleUrls: ['./cancel-dialog-reason.component.scss'],
})
export class CancelDialogReasonComponent implements OnInit {
  @Input() selectedEcom: EcomVO;
  @Input() selectedPlanType: PlanType;
  @Input() nextClicked: Subject<void>;
  @Output() closeDialogWithReason: EventEmitter<{ reasons: string[]; comment: string }> = new EventEmitter();
  @Output() goToNextStep: EventEmitter<ReasonToCancelSubscription> = new EventEmitter();
  public reasons: string[] = [];
  public form: UntypedFormGroup;
  public isLoading = true;
  public COMMENT_ID = 'COMMENT';
  private MAX_SELECTABLE_OPTIONS = 3;
  public isCommentOptional = true;

  constructor(private subscriptionCancelService: SubscriptionCancelService) {}

  ngOnInit(): void {
    this.subscribeToNextClicked();
    this.getReasons().subscribe((reasons) => {
      this.reasons = reasons;
      this.buildForm(reasons);
      // this.subscribeToFormValueChanges();
      this.subscribeToOtherSelected();
      this.isLoading = false;
    });
  }

  private getReasons(): Observable<string[]> {
    return this.subscriptionCancelService.getReasons().pipe(map((reasons) => reasons.map((reason) => reason.reason)));
  }

  private getFormControlsFields(reasons: string[]): any {
    const formGroupFields = {};
    reasons.forEach((reason) => {
      formGroupFields[reason] = new UntypedFormControl({ value: false, disabled: false });
    });
    return formGroupFields;
  }

  private buildForm(reasons: string[]): void {
    const formGroupFields = this.getFormControlsFields(reasons);
    this.form = new UntypedFormGroup(
      { ...formGroupFields, [this.COMMENT_ID]: new UntypedFormControl(null) },
      { validators: atLeast1CheckedValidator }
    );
  }

  private subscribeToOtherSelected(): void {
    this.form.get('OTHER').valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls[this.COMMENT_ID].setValidators(Validators.required);
        this.isCommentOptional = false;
      } else {
        this.form.controls[this.COMMENT_ID].clearValidators();
        this.isCommentOptional = true;
      }
      this.form.controls[this.COMMENT_ID].updateValueAndValidity();
      this.form.controls[this.COMMENT_ID].markAsTouched();
    });
  }

  private subscribeToNextClicked(): void {
    this.nextClicked.subscribe((value) => {
      this.form.markAsTouched();
      if (this.form.valid) {
        this.goToNextStep.emit(this.mapFormValues());
      }
    });
  }

  private mapFormValues(): ReasonToCancelSubscription {
    const formValues = this.form.value;
    const selectedReasons: string[] = [];
    this.reasons.forEach((reason) => {
      if (typeof formValues[reason] === 'boolean' && formValues[reason]) {
        selectedReasons.push(reason);
      }
    });
    return {
      comment: this.form.get(this.COMMENT_ID).value,
      reasons: selectedReasons,
    };
  }

  private subscribeToFormValueChanges(): void {
    this.form.valueChanges.subscribe((value) => {
      const notCheckedOptions = this.reasons.filter((reason) => !value[reason]);
      if (this.reasons.length - notCheckedOptions.length >= this.MAX_SELECTABLE_OPTIONS) {
        notCheckedOptions.forEach((option) => {
          this.form.controls[option].disable();
        });
      } else {
        this.reasons.forEach((reason) => {
          this.form.controls[reason].enable();
        });
      }
    });
  }

  get optionsToDisable(): string[] {
    const notCheckedOptions = this.reasons.filter((reason) => !this.form.value[reason]);
    if (this.reasons.length - notCheckedOptions.length >= this.MAX_SELECTABLE_OPTIONS) {
      return notCheckedOptions;
    } else {
      return [];
    }
  }
}

export interface ReasonToCancelSubscription {
  reasons: string[];
  comment: string;
}

const atLeast1CheckedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const hasChecked = Object.keys(control.value).some(
    (key) => typeof control.value[key] === 'boolean' && control.value[key]
  );
  return hasChecked ? null : { atLeast1Checked: { value: control.value } };
};
