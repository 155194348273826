<div
  class="review-badges"
  [class.grid-container]="orientation === 'grid'"
  [ngStyle]="{ 'grid-template-columns': 'repeat(' + gridColumnNumber + ', 1fr)' }"
  [style.row-gap]="orientation === 'horizontal' ? '20px' : undefined"
  [style.column-gap]="orientation === 'horizontal' ? '1%' : undefined"
  [fxLayout]="orientation === 'horizontal' ? 'row wrap' : orientation === 'vertical' ? 'column' : undefined"
  [fxLayoutAlign]="orientation === 'horizontal' ? 'space-between center' : undefined"
  [fxLayoutGap]="orientation === 'vertical' ? '20px' : '0px'"
>
  <div
    *ngFor="let badge of badges"
    class="review-badge"
    (click)="redirectAndSaveSnippet(badge)"
    [fxFlex]="orientation === 'horizontal' ? '1 0 32%' : undefined"
    [fxFlex.lt-lg]="orientation === 'horizontal' ? '0 0 48%' : undefined"
    [fxFlex.lt-sm]="orientation === 'horizontal' ? '0 0 100%' : undefined"
  >
    <ng-container *ngIf="variant === 'primary'">
      <img src="{{ badge.imgUrl }}" [alt]="badge.ecomType + 'icon'" height="76px" />
    </ng-container>
    <ng-container
      *ngIf="variant === 'secondary'"
      [ngTemplateOutlet]="secondVariantItem"
      [ngTemplateOutletContext]="{ ecomType: badge.ecomType, url: badge.imgUrl }"
    >
    </ng-container>
  </div>
</div>

<ng-template #secondVariantItem let-ecomType="ecomType" let-url="url">
  <div class="p-12 w-100p secondary-variant-item-container">
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
      <img
        class="secondary-variant-item-container"
        [style.width]="'52px'"
        [style.height]="'52px'"
        [src]="url"
        [alt]="ecomType + 'icon'"
      />
      <div>
        <div
          [innerHTML]="
            'REVIEW_BADGE.LEAVE_US_REVIEW'
              | translate
                : {
                    platformName: ecomType
                  }
          "
        >
          <!--          Leave us a review <br />-->
          <!--          on <b class="text-capitalize">{{ ecomType }}</b>-->
        </div>
      </div>
    </div>
  </div>
</ng-template>
