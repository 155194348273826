import { DatafeedManagerNavigation } from './datafeed-manager.navigation';
import { AnalyticsNavigation } from './analytics.navigation';
import { ProfileNavigation } from './profile.navigation';
import { BillingNavigation } from './billing.navigation';
import { MarketplaceNavigation } from './marketplace.navigation';

export type RetailerNavigation = (typeof RetailerNavigation)[keyof typeof RetailerNavigation];
export const RetailerNavigation = {
  DASHBOARD: 'dashboard',
  MARKETPLACE: MarketplaceNavigation,
  DATAFEED_MANAGER: DatafeedManagerNavigation,
  MESSAGES: 'messages',
  ORDERS: 'orders',
  ANALYTICS: AnalyticsNavigation,
  SETTINGS: ProfileNavigation,
  BILLING: BillingNavigation,
  ORDER_STATISTICS: 'retailer-orders-statistic',
  IMPORT_LIST: 'import-list',
  FAVORITES: 'favorites',
  FOLLOWED_SUPPLIERS: 'followed-suppliers',
  MY_SUPPLIERS: 'my_suppliers',
  APPS: 'retailer-profile/apps',
} as const;
