import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { SKIP_FUSE_UPDATE } from '@fuse/services/config.service';
import { ImageWithAlt } from '../../../vo/image-with-alt';

@Component({
  selector: 'app-cta-row',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, RouterModule],
  templateUrl: './cta-row.component.html',
  styleUrls: ['./cta-row.component.scss'],
})
export class CtaRowComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() redirectURL: string;
  @Input() image: ImageWithAlt;
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  @Input() imagePosition: 'left' | 'right' = 'left';

  readonly skipFuseUpdate = SKIP_FUSE_UPDATE;

  constructor() {}

  ngOnInit(): void {}
}
