import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { getCurrentUserSelector } from 'app/store/user/user.selector';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home-featured-filter-cards',
  templateUrl: './home-featured-filter-cards.component.html',
  styleUrls: ['./home-featured-filter-cards.component.scss'],
})
export class HomeFeaturedFilterCardsComponent implements OnInit, OnDestroy {
  firstName$: Observable<string>;

  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.initFirstNameSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initFirstNameSubscription(): void {
    this.firstName$ = this.store.select(getCurrentUserSelector).pipe(
      takeUntil(this.unsubscribe$),
      filter((data) => !!data),
      map((data) => data.firstName || data.userName.split(' ')?.[0])
    );
  }
}
