export class FtpDialogVO {
  private _taskId: number;
  private _fileId: number;
  private _isUpdate: boolean;

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get isUpdate(): boolean {
    return this._isUpdate;
  }

  set isUpdate(value: boolean) {
    this._isUpdate = value;
  }

  constructor(taskId, fileId, isUpdate) {
    this._taskId = taskId;
    this._fileId = fileId;
    this._isUpdate = isUpdate;
  }
}
