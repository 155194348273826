import { PreferencesActionsUnion, PreferencesActionTypes } from './preferences.action';
import { Preference } from '../../vo/Preferences/preference';
import { PreferenceType } from '../../vo/Preferences/preference-type';

export interface PreferencesState {
  preferences: Preference[];
  preferenceTypes: PreferenceType[];
}

const initialState: PreferencesState = {
  preferences: null,
  preferenceTypes: null,
};

export function preferencesReducer(state = initialState, action: PreferencesActionsUnion): PreferencesState {
  switch (action.type) {
    case PreferencesActionTypes.GET_PREFERENCES_SUCCESS:
      return { ...state, preferences: action.payload };
    case PreferencesActionTypes.SAVE_PREFERENCES_SUCCESS:
      return { ...state, preferences: [...state.preferences, ...action.payload] };
    case PreferencesActionTypes.DELETE_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: state.preferences.filter((preference) => !action.payload.includes(preference.id)),
      };
    case PreferencesActionTypes.GET_PREFERENCE_TYPES_SUCCESS:
      return { ...state, preferenceTypes: action.payload };
    default:
      return state;
  }
}
