<div class="chat-active-panel-wrapper" [class.chat-active-panel-wrapper-fullheight]="options.hideDirectMessageSide">
  <ng-container *ngIf="!channelsLoading; else loadingSpinner">
    <ng-container *ngIf="!channelsError; else errorMessage">
      <ng-container *ngIf="!!channels && channels.length > 1">
        <div class="chat-topic-label">{{ 'CHAT.ACTIVE_CHATS' | translate }}</div>
      </ng-container>
      <mat-divider></mat-divider>
      <div class="channel-list-wrapper">
        <div overflowDirective *ngIf="!!channels" fxLayout="column" fxFlex="1 0 0">
          <ng-container *ngIf="!!channels.length; else noMessages">
            <ng-container *ngFor="let channel of channels">
              <app-chat-channel-item
                [channel]="channel"
                [selected]="selectedChannel && selectedChannel.id === channel.id"
                (selectChannel)="channelClickHandler($event)"
              ></app-chat-channel-item>
            </ng-container>
            <ng-container *ngIf="hasLoadMore">
              <div fxLayout="row" class="py-12" fxLayoutAlign="center start">
                <button (click)="loadMoreClicked.emit()" fxFlex="0 0 80%" mat-button class="mat-flat-white-button">
                  {{ 'CHAT.LOAD_MORE' | translate }}
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingSpinner>
  <div fxFlex="1 0 0" class="centered">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>

<ng-template #noMessages>
  <div class="no-messages">
    <div>
      {{ hasFiltered ? ('CHAT.NO_MESSAGES_FILTERED' | translate) : ('CHAT.NO_MESSAGES' | translate) }}
    </div>
  </div>
</ng-template>

<ng-template #errorMessage>
  <div class="no-messages">{{ 'CHAT.ERROR_MESSAGE' | translate }}</div>
</ng-template>
