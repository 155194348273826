export function splitUrl(url: string): SplitUrl {
  const [path, params] = url ? url?.split('?') : ['', ''];
  const queryParams = !!params ? Object.fromEntries(new URLSearchParams(params)) : undefined;
  return {
    path,
    queryParams,
  };
}

export interface SplitUrl {
  path: string;
  queryParams: { [key: string]: string };
}
