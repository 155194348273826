import { Observable, pairwise } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Utils } from '../utils';
import { isEqual } from 'lodash';

export function valueChanged<T>(): (source: Observable<T>) => Observable<T> {
  return (source) =>
    source.pipe(
      pairwise(),
      filter((values) => !Utils.isNullOrUndefined(values[0]) && !Utils.isNullOrUndefined(values[1])),
      filter((values) => !isEqual(values[0], values[1])),
      map((values) => values[1])
    );
}
