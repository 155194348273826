import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {
  ShowMessageBubbleDialogComponent,
  ShowMessageBubbleDialogComponentData,
} from '../show-message-bubble-dialog/show-message-bubble-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-show-message-bubble-dialog-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './show-message-bubble-dialog-button.component.html',
  styleUrls: ['./show-message-bubble-dialog-button.component.scss'],
})
export class ShowMessageBubbleDialogButtonComponent implements OnInit {
  @Input() message?: string;
  @Input() titleTextKey = 'SHOW_MESSAGE_BUBBLE_DIALOG_BUTTON.TITLE';
  @Input() descriptionTextKey: string;
  @Input() buttonTextKey = 'SHOW_MESSAGE_BUBBLE_DIALOG_BUTTON.BUTTON_TEXT';
  @Input() buttonNoMessageTextKey = 'SHOW_MESSAGE_BUBBLE_DIALOG_BUTTON.BUTTON_NO_MESSAGE_TEXT';

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openMessageDialog(): void {
    this.dialog.open<ShowMessageBubbleDialogComponent, ShowMessageBubbleDialogComponentData>(
      ShowMessageBubbleDialogComponent,
      {
        data: {
          message: this.message,
          descriptionKey: this.descriptionTextKey,
          titleKey: this.titleTextKey,
        },
        autoFocus: false,
      }
    );
  }
}
