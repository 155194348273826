import { TemplateRef } from '@angular/core';

export class NotificationData {
  type: NotificationType;
  message?: string;
  template?: TemplateRef<any>;
  templateContext?: {};

  constructor(type: NotificationType, message: string) {
    this.type = type;
    this.message = message;
  }
}

export type NotificationType = 'success' | 'warning' | 'error' | 'information';
