import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GettingStartedState } from './getting-started.reducer';
import { isEmpty } from 'lodash';
import { GettingStartedStepType } from '../../main/getting-started-new/model/getting-started-step-type';
import { GettingStartedStepDto } from '../../main/getting-started-new/model/getting-started-step-dto';
import { retailerEcomsSelector } from '../ecom/ecom.selector';
import { RolesEnum } from '../../vo/roles/roles';

const getGettingStartedState = createFeatureSelector<GettingStartedState>('gettingStarted');

export const gettingStartedUncompletedStepsSelector = createSelector(
  getGettingStartedState,
  retailerEcomsSelector,
  (state, retailerEcoms, role: RolesEnum = RolesEnum.RETAILER) => {
    if (!!state && !!state.completedSteps && !!state.allSteps) {
      const completedStepKeys = state.completedSteps.map((step) => step.step);
      const unCompletedStepsByRole = state.allSteps.filter(
        (step) => !completedStepKeys.includes(step.step) && step.appType === role
      );
      if (role === RolesEnum.RETAILER && isEmpty(retailerEcoms)) {
        return unCompletedStepsByRole.filter((step) => step.step !== 'FINISHING_UP');
      }
      return unCompletedStepsByRole;
    }
  }
);

export const gettingStartedAllStepsSelector = createSelector(
  getGettingStartedState,
  retailerEcomsSelector,
  (state, retailerEcoms, role: RolesEnum = RolesEnum.RETAILER) => {
    if (!!state && !!state.allSteps && state.allSteps) {
      const allStepsByRole = state.allSteps.filter((step) => step.appType === role);
      if (role === RolesEnum.RETAILER && isEmpty(retailerEcoms)) {
        return allStepsByRole.filter((step) => step.step !== 'FINISHING_UP');
      }
      return allStepsByRole;
    }
  }
);

export const gettingStartedCurrentStepSelector = createSelector(
  getGettingStartedState,
  (state, currentStepKey: GettingStartedStepType): GettingStartedStepDto => {
    if (!!state && !!state.allSteps) {
      return state.allSteps.find((step) => step.step === currentStepKey);
    }
  }
);

export const gettingStartedCompletedStepsSelector = createSelector(
  getGettingStartedState,
  (state, role: RolesEnum = RolesEnum.RETAILER) => {
    if (!!state && !!state.completedSteps && state.completedSteps) {
      return state.completedSteps.filter((step) => step.appType === role);
    }
  }
);
