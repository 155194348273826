<ng-container *ngIf="isAdmin">
  <mat-icon
    (click)="changeVisible()"
    class="cursor-pointer comment-icon s-20"
    [class.syncee-primary-700-fg]="!!myComment"
  >
    sticky_note_2
  </mat-icon>
  <textarea
    #textbox
    [(ngModel)]="myComment"
    class="border-radius-16 p-16 comment-box"
    [hidden]="isHidden"
    (keypress)="saveComment($event, textbox.value)"
  ></textarea>
</ng-container>
