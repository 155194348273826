import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { RestService } from '../../service/rest/rest.service';
import { CSV_SETTINGS } from '../../utils/Constants';
import { EcomService } from 'app/service/ecom/ecom.service';
import { Router } from '@angular/router';
import { Utils } from 'app/utils/utils';

export const EXPORT_TYPES = ['CSV', 'JSON'];

@Component({
  selector: 'app-add-task-modal',
  templateUrl: './add-task-modal.component.html',
  styleUrls: ['./add-task-modal.component.scss'],
})
export class AddTaskModalComponent implements OnInit {
  form: UntypedFormGroup;
  name: string;
  eTypeDisable = false;

  domains: any[];
  exportTypes = EXPORT_TYPES;
  templates;
  hasNotValidEcom = false;
  starterPlanIsntFull = true;
  isLoading = false;
  hasEcwid = false;
  hasBigcommerce = false;
  AvailableEcomTypesForImportDatafeed = ['shopify', 'shoprenter', 'jumpseller'];
  AvailableEcomTypesForExportDatafeed = ['shopify'];
  AvailableEcomTypesForVUDatafeed = ['shopify'];

  characterEncodings: any[] = [{ code: 'utf8', name: 'UTF-8' }];
  delimiters: any[] = CSV_SETTINGS.delimiters.filter((value) => {
    return value.code !== 'auto';
  });

  enclosures: any[] = CSV_SETTINGS.enclosures.filter((value) => {
    return value.code !== 'auto';
  });

  endoflines: any[] = CSV_SETTINGS.endoflines.filter((value) => {
    return value.code !== 'auto';
  });

  exportValidator = this.conditionalValidator(() => this.isExportTask(), Validators.required);

  exportNullVaidator = this.conditionalValidator(() => this.isExportTask(), Validators.nullValidator);

  exportFileTypeValidator = this.conditionalValidator(() => this.fileTypeCondition(), Validators.required);

  fileTypeCondition(): boolean {
    return this.isExportTask() && !this.eTypeDisable;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private restService: RestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTaskModalComponent>,
    private ecomService: EcomService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadDomains();
    if (this.isExportTask()) {
      this.loadTemplates();
    }

    if (this.form.get('domain')) {
      this.form.get('domain').valueChanges.subscribe((value) => {
        this.isLoading = true;
        this.ecomService.getDomainByEcomId(value, 2).subscribe((domain) => {
          if (domain.subscriptions.df.planId === 100 && !domain.subscriptions.df.hasCustomPlan) {
            const tasksNumber = this.data.allTasks.filter(
              (item) => item.ecomId == this.form.get('domain').value
            ).length;
            this.starterPlanIsntFull = tasksNumber < 10;
          } else {
            this.starterPlanIsntFull = true;
          }
          this.isLoading = false;
        });
      });
    }
  }

  private loadTemplates(): void {
    this.restService.get('ExportTaskService/getTemplates').subscribe((response) => {
      this.templates = response.getData();
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      fileType: ['', this.exportFileTypeValidator],
      csvSettings: this.fb.group({
        delimiter: ',',
        enclosure: '"',
        eol: 'CRLF',
        encoding: 'utf8',
      }),
      template: ['0', this.exportNullVaidator],
      domain: ['', this.conditionalValidator(() => this.isDomainWithEcomIDVisible(), Validators.required)],
    });
  }

  private conditionalValidator(condition: () => boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    };
  }

  private loadDomains(): void {
    if (this.isDomainVisible() || this.isDomainWithEcomIDVisible()) {
      this.ecomService.getOnlyDomains().subscribe((response) => {
        this.domains = response;
        const totalDomainNumber = response.length;
        // OLD_TODO: amint lesz VU és export nem csak Shopify-ra, vissza kell tenni ezeket a domaineket!!!!!
        switch (true) {
          case this.isExportTask():
            this.domains = this.domains.filter(
              (elem) => this.AvailableEcomTypesForExportDatafeed.indexOf(elem.ecomType.toLowerCase()) > -1
            );
            if (totalDomainNumber > this.domains.length) {
              this.hasNotValidEcom = true;
            }
            break;
          case this.isVUTask():
            this.domains = this.domains.filter(
              (elem) => this.AvailableEcomTypesForVUDatafeed.indexOf(elem.ecomType.toLowerCase()) > -1
            );
            if (totalDomainNumber > this.domains.length) {
              this.hasNotValidEcom = true;
            }
            break;
          default:
            this.domains = this.domains.filter(
              (elem) => this.AvailableEcomTypesForImportDatafeed.indexOf(elem.ecomType.toLowerCase()) > -1
            );
            if (totalDomainNumber > this.domains.length) {
              this.hasNotValidEcom = true;
            }
        }
      });
    }
  }

  public isDomainVisible(): boolean {
    return !Utils.isNullOrUndefined(this.data) && this.data.domain;
  }

  public isDomainWithEcomIDVisible(): boolean {
    return !Utils.isNullOrUndefined(this.data) && this.data.ecomId;
  }

  public onSubmit(): void {
    const values = this.form.value;
    if (!this.isExportTask()) {
      delete values['csvSettings'];
      delete values['template'];
    } else {
      if (values['template'] === '0') {
        values['template'] = null;
      }
    }
    if (!Utils.isNullOrUndefined(this.domains)) {
      const ecom = this.domains.find((dom) => {
        return dom.id === values.domain;
      });
      values['ecomType'] = ecom.ecomType;
    }

    this.dialogRef.close(values);
  }

  public close(): void {
    this.dialogRef.close();
  }

  isExportTask(): boolean {
    return !Utils.isNullOrUndefined(this.data) && this.data.isExportTask;
  }

  isVUTask(): boolean {
    return !Utils.isNullOrUndefined(this.data) && this.data.isVUTask;
  }

  get isCSVFileType(): boolean {
    return this.form.get('fileType').value === 'CSV';
  }

  templateSelected(event: MatSelectChange): void {
    if (event.value !== null && event.value !== '0') {
      this.eTypeDisable = true;
      this.form.get('fileType').reset();
    } else {
      this.eTypeDisable = false;
    }
  }

  public navTo(URL): void {
    this.router.navigate([URL]).then(() => this.dialogRef.close());
  }
}
