import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-dashboard-box',
  templateUrl: './skeleton-dashboard-box.component.html',
  styleUrls: ['./skeleton-dashboard-box.component.scss'],
})
export class SkeletonDashboardBoxComponent implements OnInit {
  @Input() title: string;
  @Input() width = 100;
  @Input() withCard = true;

  constructor() {}

  ngOnInit(): void {}
}
