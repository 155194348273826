import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-marketing-card',
  templateUrl: './marketing-card.component.html',
  styleUrls: ['./marketing-card.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule, MatCardModule, MatButtonModule],
})
export class MarketingCardComponent {
  @Input() text: string;
  @Input() buttonText: string;
  @Output() buttonClick = new EventEmitter<void>();

  handleClick(): void {
    this.buttonClick.emit();
  }
}
