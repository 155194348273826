<app-alert-base [actionRef]="!!alertButton && actionRef" [type]="type">
  <div fxFlex="1 0 0" fxLayoutAlign="center start" fxLayout="column" class="color-light">
    <div [innerHTML]="message" [routerLink]="alertButton.link" [queryParams]="alertButton.queryParams"></div>
    <div *ngIf="readMoreLink" [innerHTML]="readMoreLink"></div>
  </div>
</app-alert-base>

<ng-template #actionRef>
  <div fxLayoutAlign="center center">
    <button mat-button class="syncee-{{ type }}-button" (click)="handleClick()">
      {{ alertButton.textKey | translate }}
    </button>
  </div>
</ng-template>
