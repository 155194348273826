import { Component, Inject, OnInit } from '@angular/core';
import { MarketplaceFilter } from '../../../../../vo/search-product-vo';
import { AddFilterWithRedirectAction } from '../../../../../store/product-search/product-search.action';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-product-search-with-autocomplete-mobile-dialog',
  templateUrl: './product-search-with-autocomplete-mobile-dialog.component.html',
  styleUrls: ['./product-search-with-autocomplete-mobile-dialog.component.scss'],
})
export class ProductSearchWithAutocompleteMobileDialogComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ProductSearchWithAutocompleteMobileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ProductSearchWithAutocompleteMobileDialogData
  ) {}

  ngOnInit(): void {}

  handleSearch(filter: MarketplaceFilter): void {
    this.router
      .navigate([
        `explore-products/search/${
          !Utils.isNullOrUndefined(filter) && !Utils.isNullOrUndefined(filter.category) ? filter.category : 1
        }`,
      ])
      .then(() => {
        this.store.dispatch(new AddFilterWithRedirectAction({ filter: filter, needRedirect: this.data.needRedirect }));
        this.dialogRef.close();
      });
  }

  handleCloseClicked(): void {
    this.dialogRef.close();
  }
}

export interface ProductSearchWithAutocompleteMobileDialogData {
  needRedirect: boolean;
}
