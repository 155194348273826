import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { switchMapWith } from 'app/utils/operator/switch-map-with';
import { SupplierTaxSettings } from 'app/vo/supplier/supplier-tax-settings';
import { forkJoin, iif, Observable, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataListingDialogService } from '../../shared/components/dialogs/data-listing-dialog/data-listing-dialog.service';
import { CountryNameToCodePipe } from '../../shared/pipes/legacy/country-name-to-code.pipe';
import { currencies } from '../../utils/Currencies';
import { SupplierTask } from '../../vo/supplier/supplier-task';
import { SupplierWithBadgesAndAutoOrder } from '../../vo/supplier/supplier-with-badges-and-auto-order';
import { SupplierOrderLimit } from '../manage-orders/syncee-orders.service';
import { MinimumOrderAmountDisplayService } from '../minimum-order-amount-display/minimum-order-amount-display.service';
import { SupplierGatewayService } from '../suppliers/supplier-gateway.service';
import { SupplierTaskService } from '../suppliers/supplier-task.service';
import { CategoryNamesByIdsPipe } from '../../shared/pipes/category-names-by-ids.pipe';
import { Utils } from '../../utils/utils';

/*
    Provide the following services, pipes:

    - MinimumOrderAmountDisplayService,
    - OrderAndCatalogInformationDialogService,
    - CountryNameToCodePipe
    - CurrencyTransformPipe,
    - CategoryNamesByIdsPipe,
    - CurrencyExchangeToPipe,
    - CurrencyExchangeToSelectedPipe,
    - CurrencyTransformToSelectedPipe

    Copy this:

    MinimumOrderAmountDisplayService,
    OrderAndCatalogInformationDialogService,
    CountryNameToCodePipe
    CurrencyTransformPipe,
    CategoryNamesByIdsPipe,
    CurrencyExchangeToPipe,
    CurrencyExchangeToSelectedPipe,
    CurrencyTransformToSelectedPipe
 */
@Injectable()
export class OrderAndCatalogInformationDialogService {
  private env = environment;

  constructor(
    private minimumOrderAmountDisplayService: MinimumOrderAmountDisplayService,
    private categoryNamesByIdsPipe: CategoryNamesByIdsPipe,
    private countryNameToCodePipe: CountryNameToCodePipe,
    private translateService: TranslateService,
    private supplierGatewayService: SupplierGatewayService,
    private supplierTaskService: SupplierTaskService,
    private dataListingDialogService: DataListingDialogService
  ) {}

  open(
    catalogId: number,
    supplierUserId: number,
    catalog?: SupplierTask,
    supplier?: SupplierWithBadgesAndAutoOrder,
    supplierOrderLimit?: SupplierOrderLimit[]
  ): Observable<void> {
    return iif(
      () => Utils.isNullOrUndefined(supplier),
      this.supplierGatewayService.getSupplierWithAutoOrder(supplierUserId),
      of(supplier)
    ).pipe(
      switchMapWith((supplierData: SupplierWithBadgesAndAutoOrder) =>
        this.supplierGatewayService.getSupplierTaxSettings(supplierData.userId)
      ),
      switchMap(([supplierData, taxSettings]: [SupplierWithBadgesAndAutoOrder, SupplierTaxSettings]) =>
        this.openDialog(
          supplierData,
          !!catalog ? of(catalog) : this.supplierTaskService.getCatalogById(catalogId),
          this.getSupplierOrderLimit(supplierUserId, supplierData.paymentCurrency, supplierOrderLimit),
          taxSettings
        )
      )
    );
  }

  private openDialog(
    supplier: SupplierWithBadgesAndAutoOrder,
    catalog$: Observable<SupplierTask>,
    supplierOrderLimitText$: Observable<string>,
    taxSettings: SupplierTaxSettings
  ): Observable<void> {
    return forkJoin({ catalog: catalog$, supplierOrderLimitText: supplierOrderLimitText$ }).pipe(
      take(1),
      switchMapWith(({ catalog }) =>
        this.categoryNamesByIdsPipe.transform(
          !!catalog.settings.mainCategories?.length ? catalog.settings.mainCategories : [1]
        )
      ),
      switchMap(([{ catalog, supplierOrderLimitText }, categories]) =>
        this.dataListingDialogService.open(
          this.getTranslation('STOREFRONT.DETAILS.INFOS_LINKS.ORDER_AND_CATALOG'),
          [
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.CATALOG_NAME',
              value: catalog.name,
            },
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.PAYMENT_CURRENCY.LABEL',
              value: `${supplier.paymentCurrency} - ${
                Object.values(currencies.find((currency) => currency[supplier.paymentCurrency]))[0]
              } `,
            },
            ...(!!supplierOrderLimitText
              ? [
                  {
                    key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.SUPPLIER_ORDER_LIMIT.LABEL',
                    value: supplierOrderLimitText,
                  },
                ]
              : []),
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.WAREHOUSE_LOCATION',
              value: this.getTranslation(
                'COUNTRIES.' + this.countryNameToCodePipe.transform(supplier.mainWarehouseLocation)
              ),
            },
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.CATEGORIES',
              value: categories,
            },
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.RESELLING_INFORMATION',
              value: this.getTranslation('APPROVAL_NEEDED_FOR_SUPPLIER_TASK.LONG.' + catalog.settings.approveNeeded),
            },
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.LANGUAGE',
              value: this.getTranslation('GENERAL.LANGUAGES.' + catalog.settings.language.toUpperCase()),
            },
            {
              key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.ORDER_INFORMATION.LABEL',
              value: `${
                !!supplier.autoOrder
                  ? this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.ORDER_INFORMATION.AUTO_ORDER')
                  : this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.ORDER_INFORMATION.NON_AUTO_ORDER', {
                      supplierURL: supplier.website,
                    })
              } ${
                !!this.env.hasCart &&
                supplier.autoOrder &&
                this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.ORDER_INFORMATION.WHOLESALE')
              }`,
            },
            ...(!!catalog.settings.approveDescription
              ? [
                  {
                    key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.SPECIAL_REQUIREMENT.LABEL',
                    value: catalog.settings.approveDescription,
                  },
                ]
              : []),
            ...(!!supplier.contactEmail
              ? [
                  {
                    key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.CONTACT.LABEL',
                    value: this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.CONTACT.VALUE'),
                  },
                ]
              : []),
            ...(!!taxSettings
              ? [
                  {
                    key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.TAX_SETTINGS.PRODUCT_TAX_LABEL',
                    value: taxSettings.productHasTax
                      ? this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.TAX_SETTINGS.YES')
                      : this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.TAX_SETTINGS.NO'),
                  },
                ]
              : []),
            ...(!!taxSettings
              ? [
                  {
                    key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.TAX_SETTINGS.SHIPPING_TAX_LABEL',
                    value: taxSettings.shippingHasTax
                      ? this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.TAX_SETTINGS.YES')
                      : this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.TAX_SETTINGS.NO'),
                  },
                ]
              : []),
          ],
          this.getTranslation('STOREFRONT.DIALOG.BACK_TO'),
          { translateValue: false, translateBackTo: false }
        )
      )
    );
  }

  private getSupplierOrderLimit(
    userId: number,
    paymentCurrency: string,
    supplierOrderLimit?: SupplierOrderLimit[]
  ): Observable<string> {
    return (
      !!supplierOrderLimit ? of(supplierOrderLimit) : this.supplierGatewayService.getSupplierOrderLimit([userId])
    ).pipe(
      switchMap((orderLimit) =>
        this.minimumOrderAmountDisplayService.getMinimumOrderAmountDisplay(orderLimit, paymentCurrency)
      ),
      take(1)
    );
  }

  private getTranslation(key: string, interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams);
  }
}
