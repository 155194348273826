<div class="user-item" [ngClass]="{ selected: selected }" (click)="handleUserSelection()">
  <img
    #avatarImage
    *ngIf="user.avatar && !useFallbackAvatar"
    [src]="user.avatar"
    (error)="useFallbackAvatar = true"
    [alt]="user.name"
  />
  <app-avatar type="text" class="chat-user-avatar" *ngIf="!user.avatar || useFallbackAvatar" [diameter]="40">{{
    user.name.substring(0, 2)
  }}</app-avatar>
  <div>
    <div class="user-name">{{ user.name }}</div>
  </div>
</div>
