<div>
  <img
    class="authentication-syncee-logo"
    [style.top]="textPosition === 'top' ? '90px' : '170px'"
    src="assets/images/logos/white-syncee-logo.svg"
  />
  <img [src]="coverImgPath" />
  <div
    class="authentication-syncee-banner-text"
    [style.top]="textPosition === 'top' ? '170px' : '250px'"
    fxLayout="column"
    fxLayoutGap="29px"
  >
    <div *ngFor="let text of textList">
      <ng-container [ngTemplateOutlet]="bannertextline" [ngTemplateOutletContext]="{ text }"> </ng-container>
    </div>
  </div>
</div>
<ng-template #bannertextline let-text="text">
  <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <mat-icon>done</mat-icon>
    <div>{{ text | translate }}</div>
  </div>
</ng-template>
