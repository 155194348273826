import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  SupplierTaxSettingsService,
  TaxRate,
} from '../../../../service/supplier-tax-settings/supplier-tax-settings.service';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-supplier-tax-settings-dialog',
  templateUrl: './add-supplier-tax-settings-dialog.component.html',
  styleUrls: ['./add-supplier-tax-settings-dialog.component.scss'],
  imports: [
    CommonModule,
    MatInputModule,
    TranslateModule,
    FlexModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  standalone: true,
})
export class AddSupplierTaxSettingsDialogComponent implements OnInit {
  private _formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  private _settingsForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddSupplierTaxSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supplierTaxSettingsService: SupplierTaxSettingsService
  ) {}

  ngOnInit(): void {
    this.initSettingsForm();
  }

  private initSettingsForm(): void {
    this.settingsForm = this.formBuilder.group({
      country: ['', [Validators.required]],
      taxRate: [0, this.supplierTaxSettingsService.validateTaxRate()],
    });
  }

  public saveSettings(): void {
    if (this.settingsForm.valid) {
      const country = this.settingsForm.get('country').value;

      this.dialogRef.close(
        new TaxRate(country.code, country.name, parseInt(this.settingsForm.get('taxRate').value, 10))
      );
    }
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  get formBuilder(): UntypedFormBuilder {
    return this._formBuilder;
  }

  set formBuilder(value: UntypedFormBuilder) {
    this._formBuilder = value;
  }

  get settingsForm(): UntypedFormGroup {
    return this._settingsForm;
  }

  set settingsForm(value: UntypedFormGroup) {
    this._settingsForm = value;
  }
}
