<h1>{{ data.name }}</h1>
<h2>{{ 'TASKS.HISTORY.TITLE' | translate }}:</h2>
<div>
  <app-progress-bar-chart
    [items]="data.progressbar"
    [isError]="data.isError"
    [errorMessage]="data.errorMessage"
    [legend]="'normal'"
    [upperLimit]="data.sum"
  ></app-progress-bar-chart>
</div>
<h2>{{ 'TASKS.DETAILS' | translate }}:</h2>
<div>
  <div class="d-flex flex-row width-150">
    <mat-icon class="status">sync</mat-icon>
    <label class="flex-centered-text">{{ data.status }}</label>
  </div>
  <div class="d-flex flex-row w-100">
    <mat-icon class="products-sum">import_export</mat-icon>
    <label class="flex-centered-text">{{ data.resultCount ? data.resultCount : 0 }} </label>
  </div>
</div>
<div class="button-holder">
  <button mat-button (click)="close()" class="syncee-blue-button">{{ 'TASKS.CLOSE' | translate }}</button>
</div>
