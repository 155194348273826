import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryVo } from '../../../../../vo/category-vo';
import {
  MarketplaceFilter,
  MarketplaceFilterSort,
  ProductSearchSortField,
  ProductSearchSortOrder,
} from '../../../../../vo/search-product-vo';
import { MarketplaceView } from '../../../../../main/marketplace/explore-products/marketplace-view-selector/marketplace-view-selector.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { SetSearchType } from '../../../../../store/product-search/product-search.action';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { filterSelector, searchTypeSelector } from '../../../../../store/product-search/product-search.selector';

@Component({
  selector: 'app-category-selector-popup-recommended',
  templateUrl: './category-selector-popup-recommended.component.html',
  styleUrls: ['./category-selector-popup-recommended.component.scss'],
})
export class CategorySelectorPopupRecommendedComponent implements OnInit {
  @Input() category: CategoryVo;
  @Input() selectedFilterCategoryId: number;
  @Input() filterSort: MarketplaceFilterSort;
  @Output() filterSelected = new EventEmitter<MarketplaceFilter>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  handleAllInClick(): void {
    if (this.selectedFilterCategoryId !== this.category.id || !!this.filterSort) {
      this.filterSelected.emit({
        category: this.category.id,
        sortField: null,
        sortOrder: null,
      });
      // this.store.dispatch(new SetSearchType({ value: 'PRODUCTS' }));
    }
  }
  handleNewProductsClick(): void {
    this.handleNewClickRedirection('PRODUCTS');
  }
  handleNewSuppliersClick(): void {
    this.handleNewClickRedirection('SUPPLIERS');
  }

  private handleNewClickRedirection(searchType: MarketplaceView): void {
    combineLatest([this.store.select(filterSelector), this.store.select(searchTypeSelector)])
      .pipe(take(1))
      .subscribe(([filter, filterSearchType]) => {
        if (
          !!filter &&
          filter.sortField === ProductSearchSortField.CREATED &&
          filter.sortOrder === ProductSearchSortOrder.DESC &&
          filter.category === this.category.id &&
          filterSearchType === searchType
        ) {
          return;
        } else {
          this.filterSelected.emit({
            category: this.category.id,
            sortField: ProductSearchSortField.CREATED,
            sortOrder: ProductSearchSortOrder.DESC,
          });
          this.store.dispatch(new SetSearchType({ value: searchType }));
        }
      });
  }
}
