<ng-container *ngIf="selectedEcom">
  <ng-container *ngIf="ecoms | async as ecomList">
    <ng-container *ngIf="ecomList.length > 1">
      <div class="ecom-selector-navbar-container">
        <mat-form-field class="ecom-selector select-without-label input-without-padding" appearance="fill">
          <mat-select
            [ngModel]="selectedEcom.id"
            (ngModelChange)="setSelectedEcom($event)"
            disableOptionCentering
            panelClass="mat-select-display-change"
          >
            <mat-select-trigger>
              <div class="trigger item">
                <img
                  [src]="getEcomLogo(selectedEcom)"
                  [alt]="selectedEcom.ecomType"
                  class="ecom-selector-navbar-icon"
                />
                <div>{{ selectedEcom.domain }}</div>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let ecom of ecomList" [value]="ecom.id">
              <div class="ecom-selector-navbar-item">
                <img [src]="getEcomLogo(ecom)" [alt]="ecom.ecomType" class="ecom-selector-navbar-icon" />
                <div>{{ ecom.domain }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
