import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ChannelItem } from '../../../../service/chat/model/chat-models';

@Component({
  selector: 'app-chat-channel-item',
  templateUrl: './chat-channel-item.component.html',
  styleUrls: ['./chat-channel-item.component.scss'],
})
export class ChatChannelItemComponent implements OnInit {
  @Input() channel: ChannelItem;
  @Input() selected = false;
  @Output() selectChannel = new EventEmitter<ChannelItem>();
  useFallbackAvatar: boolean;

  constructor() {}

  ngOnInit(): void {}

  handleChannelSelection(): void {
    this.selectChannel.emit(this.channel);
  }
}
