import { Component, ViewChild } from '@angular/core';
import { CategoryPreferencesFormComponent } from '../category-preferences-form/category-preferences-form';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CategoryPreferencesFormDialogService } from './service/category-preferences-form-dialog.service';
import { CommonModule } from '@angular/common';
import { DialogMultiWrapperComponent } from '../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { CustomDialogWrapperComponent } from '../dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { CategoryPreferencesForm } from '../category-preferences-form/model/category-preferences-form';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-category-preferences-form-dialog',
  templateUrl: './category-preferences-form-dialog.component.html',
  styleUrls: ['./category-preferences-form-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CategoryPreferencesFormComponent,
    MatDialogModule,
    DialogMultiWrapperComponent,
    CustomDialogWrapperComponent,
    MatButtonModule,
    FlexModule,
    ConditionLoadingDirective,
    TranslateModule,
  ],
  providers: [CategoryPreferencesFormDialogService],
})
export class CategoryPreferencesFormDialogComponent {
  @ViewChild(CategoryPreferencesFormComponent) public form: CategoryPreferencesFormComponent;

  constructor(
    private ref: MatDialogRef<CategoryPreferencesFormDialogComponent>,
    public categoryPreferencesFormDialogService: CategoryPreferencesFormDialogService
  ) {}

  protected handleSubmit(formContent: CategoryPreferencesForm): void {
    this.ref.close(formContent);
  }
}
