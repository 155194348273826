import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { UniversalBadgeComponent } from 'app/shared/components/universal-badge/universal-badge.component';
import { ClickOutsideDirective } from 'app/shared/directives/click-outside.directive';
import { CurrencyExchangeToSelectedPipe } from 'app/shared/pipes/currency-exchange-to-selected.pipe';
import { CurrencyTransformToSelectedPipe } from 'app/shared/pipes/currency-transform-to-selected.pipe';
import { GetPricePipe } from 'app/shared/pipes/get-price.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AiChatOpenButtonComponent } from '../../../main/ai-chat/shared/components/ai-chat-open-button/ai-chat-open-button.component';
import { AvatarComponent } from '../../../shared/components/avatar/avatar.component';
import { CustomDividerComponent } from '../../../shared/components/custom-divider/custom-divider.component';
import { CustomDialogWrapperComponent } from '../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { EcomSelectorDropdownComponent } from '../../../shared/components/ecom-selector-dropdown/ecom-selector-dropdown.component';
import { EcomSelectorComponent } from '../../../shared/components/ecom-selector/ecom-selector.component';
import { InputPlaceholderSliderComponent } from '../../../shared/components/input-placeholder-slider/input-placeholder-slider.component';
import { OverlayResponsiveWrapperComponent } from '../../../shared/components/overlay-responsive-wrapper/overlay-responsive-wrapper.component';
import { SkeletonModule } from '../../../shared/components/skeleton/skeleton.module';
import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';
import { SynceeLogoComponent } from '../../../shared/components/syncee-logo/syncee-logo.component';
import { UsageErrorComponent } from '../../../shared/components/usage-error/usage-error.component';
import { BadgeDirective } from '../../../shared/directives/badge.directive';
import { FilterRolesDirective } from '../../../shared/directives/filter-roles.directive';
import { IsAdminLoggedInToUserDirective } from '../../../shared/directives/is-admin-logged-in-to-user.directive';
import { IsLoggedInByTeamMemberDirective } from '../../../shared/directives/is-logged-in-by-team-member.directive';
import { OnlyGuestDirective } from '../../../shared/directives/only-guest.directive';
import { OnlyLoggedInDirective } from '../../../shared/directives/only-logged-in.directive';
import { ProductImageErrorHandlerDirective } from '../../../shared/directives/product-image-error-handler.directive';
import { RoleOpenRegistrationDirective } from '../../../shared/directives/role-open-registration.directive';
import { ShowOnBreakpointDirective } from '../../../shared/directives/show-on-breakpoint.directive';
import { IsStarterPlanPipe } from '../../../shared/pipes/is-starter-plan.pipe';
import { ProductImageThumbnailCdnPipe } from '../../../shared/pipes/product-image-resizer-cdn.pipe';
import { SanitizeHtmlPipe } from '../../../shared/pipes/sanitize-html.pipe';
import { SupplierLogoFromUseridPipe } from '../../../shared/pipes/supplier-logo-from-userid.pipe';
import { CategorySelectorModule } from '../category-selector/category-selector.module';
import { MobileCenterModule } from '../mobile-center/mobile-center.module';
import { CartIconComponent } from './components/cart-icon/cart-icon.component';
import { CatalogListIconComponent } from './components/catalog-list-icon/catalog-list-icon.component';
import { CatalogOverlayListWithSelectorComponent } from './components/catalog-overlay/catalog-overlay-list-with-selector/catalog-overlay-list-with-selector.component';
import { CatalogOverlayComponent } from './components/catalog-overlay/catalog-overlay.component';
import { SubscriptionDetailsComponent } from './components/catalog-overlay/subscription-details/subscription-details.component';
import { ExtraIconsComponent } from './components/extra-icons/extra-icons.component';
import { FavoriteIconComponent } from './components/favorite-icon/favorite-icon.component';
import { GuestActionsComponent } from './components/guest-actions/guest-actions.component';
import { NotificationsModule } from './components/notifications/notifications.module';
import { ProductSearchWithAutocompleteMobileDialogComponent } from './components/product-search-with-autocomplete-mobile-dialog/product-search-with-autocomplete-mobile-dialog.component';
import { ProductSearchWithAutocompleteMobileIndicatorComponent } from './components/product-search-with-autocomplete-mobile-indicator/product-search-with-autocomplete-mobile-indicator.component';
import { ProductSearchWithAutocompleteContentComponent } from './components/product-search-with-autocomplete/product-search-with-autocomplete-content/product-search-with-autocomplete-content.component';
import { ProductSearchWithAutocompleteComponent } from './components/product-search-with-autocomplete/product-search-with-autocomplete.component';
import { ProfileIconComponent } from './components/profile-icon/profile-icon.component';
import { ProfileMobileDialogComponent } from './components/profile-mobile-dialog/profile-mobile-dialog.component';
import { SetupGuideButtonComponent } from './components/setup-guide-button/setup-guide-button.component';
import { ToolbarActionBarComponent } from './components/toolbar-action-bar/toolbar-action-bar.component';
import { ToolbarCallToActionComponent } from './components/toolbar-call-to-action/toolbar-call-to-action.component';
import { UpperRowItemComponent } from './components/upper-row/upper-row-item/upper-row-item.component';
import { UpperRowComponent } from './components/upper-row/upper-row.component';
import { DesktopToolbarComponent } from './sizes/desktop-toolbar/desktop-toolbar.component';
import { MobileToolbarComponent } from './sizes/mobile-toolbar/mobile-toolbar.component';

@NgModule({
  declarations: [
    ToolbarComponent,
    ToolbarActionBarComponent,
    ProductSearchWithAutocompleteComponent,
    ProductSearchWithAutocompleteContentComponent,
    ProfileIconComponent,
    ExtraIconsComponent,
    CartIconComponent,
    FavoriteIconComponent,
    SetupGuideButtonComponent,
    MobileToolbarComponent,
    DesktopToolbarComponent,
    GuestActionsComponent,
    CatalogListIconComponent,
    CatalogOverlayComponent,
    CatalogOverlayListWithSelectorComponent,
    UpperRowComponent,
    UpperRowItemComponent,
    ProfileMobileDialogComponent,
    ProductSearchWithAutocompleteMobileIndicatorComponent,
    ProductSearchWithAutocompleteMobileDialogComponent,
    ToolbarCallToActionComponent,
    SubscriptionDetailsComponent,
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    SynceeLogoComponent,
    MatFormFieldModule,
    MatInputModule,
    EcomSelectorComponent,
    OverlayModule,
    NotificationsModule,
    BadgeDirective,
    MatCardModule,
    CategorySelectorModule,
    AvatarComponent,
    FilterRolesDirective,
    OnlyLoggedInDirective,
    OnlyGuestDirective,
    ShowOnBreakpointDirective,
    EcomSelectorDropdownComponent,
    MatSelectModule,
    SvgIconComponent,
    CustomDialogWrapperComponent,
    OverlayResponsiveWrapperComponent,
    MobileCenterModule,
    FuseSidebarModule,
    IsAdminLoggedInToUserDirective,
    SupplierLogoFromUseridPipe,
    CustomDividerComponent,
    UsageErrorComponent,
    IsStarterPlanPipe,
    AiChatOpenButtonComponent,
    IsLoggedInByTeamMemberDirective,
    IsAdminLoggedInToUserDirective,
    SlickCarouselModule,
    SanitizeHtmlPipe,
    SkeletonModule,
    ProductImageThumbnailCdnPipe,
    ProductImageErrorHandlerDirective,
    InputPlaceholderSliderComponent,
    TranslateModule,
    FlexModule,
    ExtendedModule,
    UniversalBadgeComponent,
    LoadingSpinnerComponent,
    ClickOutsideDirective,
    RoleOpenRegistrationDirective,
    GetPricePipe,
    CurrencyExchangeToSelectedPipe,
    CurrencyTransformToSelectedPipe,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
