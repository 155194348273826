<div class="upload-supplier-image-container" [ngClass]="variant">
  <app-drag-and-drop-file
    class="drag-and-drop"
    *conditionLoading="!isLoading"
    [title]="title"
    [tooltip]="tooltip"
    [files]="fileArray"
    [alreadyUploadedFiles]="!!savedImageUrl ? [savedImageUrl] : null"
    (alreadyUploadedFilesChange)="savedImageUrl = $event[0]"
    [allowedExtensions]="allowedExtensions"
    [previewVariant]="previewVariant"
    [hasError]="changedEmitted && !isValid"
    (filesChange)="file = $event[0]; handleChange()"
    (savedImagesClosed)="alreadyClosed = true; handleChange()"
    [lineHeight]="variant === 'for-full-width' ? 120 : 110"
    [maxWidth]="maxWidth"
    [maxHeight]="maxHeight"
    [maxFileSize]="maxFileSize"
    [templateType]="templateType"
    [allowedMIMETypes]="allowedMimeTypes"
  ></app-drag-and-drop-file>
  <ng-container *showOnBreakpoint="'lt-md'; variant: 'hide'">
    <div class="helper" *ngIf="!!helperImageSrc">
      <div class="helper-image-container"><img [src]="helperImageSrc" [alt]="helperText" /></div>
      <div class="helper-text-container typography-body-2 syncee-grey-500-fg">{{ helperText }}</div>
    </div>
  </ng-container>
</div>
