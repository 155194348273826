import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskIsSyncedColor',
})
export class TaskIsSyncedColorPipe implements PipeTransform {
  transform(value: string): string {
    return value === 'Not Run Yet' ? 'var(--app-syncee-grey-500)' : 'var(--app-light-green-700)';
  }
}
