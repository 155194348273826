<ng-container *ngIf="selectedCategoryTree | async as categoryTree">
  <app-mobile-main-menu-inner-sidebar
    [key]="key"
    [previouslyOpenedSidebars]="previouslyOpenedSidebars"
    [close]="sidebarCategoriesClose"
    (openedChanged)="openChanged($event)"
    [customBack]="customBackText | async"
    (customBackClicked)="back(categoryTree)"
  >
    <div class="mobile-main-menu-categories">
      <div fxLayoutAlign="space-between center" fxLayoutGap="12px">
        <div class="h3 title text-elipsis">
          {{ categoryTree.id !== 1 ? categoryTree.name : ('MOBILE_MAIN_MENU.ALL_CATEGORIES' | translate) }}
        </div>
        <a class="text-nowrap" (click)="viewAll(categoryTree)">{{ 'MOBILE_MAIN_MENU.VIEW_ALL' | translate }}</a>
      </div>
      <div *ngFor="let node of categoryTree.children" class="item text-elipsis" (click)="selectCategory(node)">
        {{ node.id | categoryNameById | async }}
      </div>
    </div>
  </app-mobile-main-menu-inner-sidebar>
</ng-container>
