import { Component, Inject, OnInit } from '@angular/core';
import { DialogMultiWrapperComponent } from '../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-email-verification-link-sent-dialog',
  templateUrl: './email-verification-link-sent-dialog.component.html',
  styleUrls: ['./email-verification-link-sent-dialog.component.scss'],
  standalone: true,
  imports: [DialogMultiWrapperComponent, FlexModule, TranslateModule, NgIf],
})
export class EmailVerificationLinkSentDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmailVerificationLinkSentDialogData,
    private dialogRef: MatDialogRef<EmailVerificationLinkSentDialogComponent>
  ) {}

  ngOnInit(): void {}

  handleOkClick(): void {
    this.dialogRef.close();
  }
}

export interface EmailVerificationLinkSentDialogData {
  email: string;
}
