import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { concatMap, takeUntil, tap } from 'rxjs/operators';
import { FavoritesGatewayService } from '../../../service/favorites/favorites-gateway.service';
import { AppState } from '../../../app.state';
import { FullMarketplaceFilterVO, MarketplaceFilter, MarketplaceFilterPagination } from '../../../vo/search-product-vo';
import { SpringPage } from '../../../vo/pagination/spring-page';
import { isEmpty } from 'lodash';
import { FavoritesPageService } from '../service/favorites-page.service';
import { Actions, ofType } from '@ngrx/effects';
import {
  FavoritesActionTypes,
  RemoveFromFollowedSupplierIdsSuccessAction,
} from '../../../store/favorites/favorites.actions';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { favorites_page_navigation } from '../../../navigation/inner-navigation';

@Component({
  selector: 'app-followed-suppliers-page',
  templateUrl: './followed-suppliers.component.html',
  styleUrls: ['./followed-suppliers.component.scss'],
})
export class FollowedSuppliersComponent implements OnInit, OnDestroy {
  hasError = false;
  followedSupplierIds: number[];
  noFollowedSupplier = false;
  total: number;
  fetchedIds = 0;
  currentPage = 0;
  pagination: MarketplaceFilterPagination = {
    from: 0,
    size: 10,
  };
  areResultsLoading = true;
  noResultsForSearch = false;
  prevFilter: MarketplaceFilter;
  private unsubscribeAll = new Subject<void>();
  constructor(
    private favoritesService: FavoritesGatewayService,
    private store: Store<AppState>,
    private actions$: Actions,
    private fuseConfigService: FuseConfigService,
    private favoritePageService: FavoritesPageService
  ) {
    this.favoritePageService.clearFilter();
    this.initTheme();
  }

  ngOnInit(): void {
    this.subscribeToProductSearchStateChange();
    this.subscribeToRemove();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: {
        submenu: {
          hidden: false,
          group: {
            title: 'FAVORITES.INNER_MENU_TITLE',
            type: 'tab',
            items: favorites_page_navigation,
          },
        },
      },
    };
  }

  private searchFollowedSupplierIds(filter: MarketplaceFilter, isNewSearch: boolean): void {
    if (isNewSearch) {
      this.pagination.from = 0;
      this.fetchedIds = 0;
    }
    this.areResultsLoading = true;
    this.favoritesService
      .searchFollowedSuppliersIds(this.favoritePageService.userId, filter, this.pagination)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(
        (value) => {
          this.handleSupplierIdSearchResponse(value, isNewSearch);
          this.areResultsLoading = false;
          this.hasError = false;
        },
        () => {
          this.areResultsLoading = false;
          this.hasError = true;
        }
      );
  }

  private handleSupplierIdSearchResponse(response: SpringPage<number>, isNewSearch: boolean): void {
    if (isNewSearch || !this.followedSupplierIds) {
      this.followedSupplierIds = response.content;
    } else {
      this.followedSupplierIds = this.followedSupplierIds.concat(response.content);
    }
    this.total = response.totalElements;
    this.fetchedIds += response.content.length;
    this.hasError = false;
    this.handleNewSearch(response, isNewSearch);
  }

  private handleNewSearch(response: SpringPage<number>, isNewSearch: boolean): void {
    switch (true) {
      case isNewSearch && isEmpty(response.content):
        this.noResultsForSearch = true;
        break;
      case isNewSearch && !isEmpty(response.content):
        this.noResultsForSearch = false;
        break;
      case !isNewSearch && isEmpty(response.content):
        this.noFollowedSupplier = true;
        break;
      case !isNewSearch && !isEmpty(response.content):
        this.noFollowedSupplier = false;
        break;
      default:
        this.noResultsForSearch = false;
        this.noFollowedSupplier = false;
    }
  }

  private subscribeToProductSearchStateChange(): void {
    this.favoritePageService.filterStore$
      .pipe(
        concatMap((filter, index) => {
          return this.handleFilterStoreResponse(filter, index);
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((filter) => {
        this.prevFilter = filter;
      });
  }

  private subscribeToRemove(): void {
    this.actions$
      .pipe(ofType(FavoritesActionTypes.REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_SUCCESS), takeUntil(this.unsubscribeAll))
      .subscribe((action: RemoveFromFollowedSupplierIdsSuccessAction) => {
        this.followedSupplierIds = this.followedSupplierIds.filter(
          (supplierId) => !action.payload.includes(supplierId)
        );
        this.total -= action.payload.length;
        this.handleTotalChangeOnRemoval();
      });
  }

  private handleTotalChangeOnRemoval(): void {
    if (this.total === 0) {
      this.noFollowedSupplier = true;
    } else {
      this.noFollowedSupplier = false;
    }
  }

  private setPagination(from: number, size?: number): void {
    this.currentPage = Math.floor(from / this.pagination.size);
    this.pagination = { from, size: size ?? 20 };
  }

  public handleLoadMore(): void {
    this.setPagination(this.pagination.from + this.pagination.size, this.pagination.size);
    this.searchFollowedSupplierIds(this.prevFilter, false);
  }

  private handleFilterStoreResponse(
    filter: Partial<FullMarketplaceFilterVO>,
    emitIndex: number
  ): Observable<Partial<FullMarketplaceFilterVO>> {
    if (emitIndex === 0) {
      return of(filter).pipe(
        tap(() => {
          this.searchFollowedSupplierIds(filter, false);
        })
      );
    } else {
      return of(filter).pipe(
        tap(() => {
          this.searchFollowedSupplierIds(filter, true);
        })
      );
    }
  }
}
