<div class="filter-selector-container">
  <ng-container *ngIf="hasSearch">
    <div fxFlex="0 0 auto" class="search">
      <ng-container [ngTemplateOutlet]="searchInput"></ng-container>
    </div>
  </ng-container>
  <div class="options" #optionsContainer (scroll)="throttledOnScrollLoadData()">
    <ng-container *ngFor="let option of options">
      <div
        (click)="handleOptionSelected(option.key)"
        class="option"
        fxLayoutAlign="space-between center"
        [class.selected]="selectedOptions.includes(option.key)"
      >
        <div fxLayoutAlign="center center" fxLayoutGap="16px">
          <mat-checkbox
            *ngIf="multiple"
            [checked]="selectedOptions.includes(option.key)"
            [disableRipple]="true"
            (click)="$event.preventDefault()"
          >
          </mat-checkbox>
          <div *ngIf="option.image">
            <div [style.height]="option.image.height" [style.width]="option.image.width" class="image-container">
              <img [src]="option.image.src" />
            </div>
          </div>
          <div>
            {{ $any(option.value) | translate }}
          </div>
        </div>
        <div *ngIf="option.badges && option.badges.length > 0" fxLayoutAlign="center center" fxLayoutGap="4px">
          <div
            *ngFor="let badge of option.badges"
            [style.background-color]="badge.backgroundColor"
            [style.color]="badge.textColor"
            class="badge"
          >
            {{ badge.text }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #searchInput>
  <mat-form-field class="w-100p" [appearance]="'fill'">
    <mat-label>{{ searchLabel | translate }}</mat-label>
    <input matInput type="text" [ngModel]="searchTerm" (ngModelChange)="handleSearchChange($event)" />
    <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="handleClearClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</ng-template>
