import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ImageTemplatesService,
  TemplateImagesResponse,
  TemplatesPagination,
  TemplateType,
} from 'app/service/image-templates/image-templates.service';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { clone, throttle } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, finalize, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-image-scroll-area',
  templateUrl: './image-scroll-area.component.html',
  styleUrls: ['./image-scroll-area.component.scss'],
})
export class ImageScrollAreaComponent implements OnInit, OnDestroy {
  @Input() templateType: TemplateType;
  @Input() selected: Blob;

  @Output() setSelected: EventEmitter<Blob> = new EventEmitter<Blob>();

  loading: boolean;
  images: Blob[] = [];
  hasError = false;

  private pagination: BehaviorSubject<TemplatesPagination> = new BehaviorSubject({ from: 1, size: 10 });
  private _unsubscribeAll: Subject<void>;

  constructor(private imageTemplateService: ImageTemplatesService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getData(): void {
    this.pagination
      .pipe(
        tap(() => (this.loading = true)),
        takeUntil(this._unsubscribeAll),
        omitNullOrUndefined(),
        distinctUntilChanged(),
        switchMap((pagination) => this.imageTemplateService.getTemplateImages(this.templateType, pagination)),
        finalize(() => (this.loading = false))
      )
      .subscribe((templates) => this.setImages(templates));
  }

  private setImages(values: TemplateImagesResponse): void {
    this.hasError = values.hasError;
    const newArray = clone(this.images);
    newArray.push(...values.blobs);
    this.images = newArray;
    this.loading = false;
  }

  handleIncrementPagination(): void {
    throttle(
      () =>
        this.pagination.next({
          from: this.pagination.value.from + this.pagination.value.size,
          size: this.pagination.value.size,
        }),
      500
    )();
  }
}
