import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { NoEcomComponent } from '../no-ecom/no-ecom.component';

@Component({
  selector: 'app-no-ecom-card',
  templateUrl: './no-ecom-card.component.html',
  styleUrls: ['./no-ecom-card.component.scss'],
  imports: [MatCardModule, FlexModule, MatButtonModule, RouterLink, NgIf, NoEcomComponent],
  standalone: true,
})
export class NoEcomCardComponent {
  @Input()
  titleKey = 'COMMON_ALERTS.NO_ECOM.TITLE';
  @Input()
  subTitleKey: string;
  constructor() {}
}
