const prefix = 'retailer/';
const productUpload = 'import-tasks';
const productExport = 'export-tasks';
const variantsUpdate = 'variants-update-tasks';
const myCatalogs = 'import-catalogs';
const wizard = 'wizard';

export type DatafeedManagerNavigation = (typeof DatafeedManagerNavigation)[keyof typeof DatafeedManagerNavigation];
export const DatafeedManagerNavigation = {
  MY_CATALOGS: myCatalogs,
  PRODUCT_UPLOAD: `${prefix}${productUpload}`,
  PRODUCT_UPLOAD_WIZARD_RETAILER_TASK: `${prefix}${productUpload}/retailer-task-wizard`,
  PRODUCT_UPLOAD_WIZARD_SHOPIFY: `${prefix}${productUpload}/${wizard}/shopify`,
  PRODUCT_UPLOAD_WIZARD_JUMPSELLER: `${prefix}${productUpload}/jumpseller-df-task-wizard`,
  PRODUCT_UPLOAD_WIZARD_SHOPRENTER: `${prefix}${productUpload}/shoprenter-df-task-wizard`,
  PRODUCT_UPLOAD_WIZARD_ECWID: `${prefix}${productUpload}/ecwid-df-task-wizard`,
  PRODUCT_EXPORT: `${prefix}${productExport}`,
  PRODUCT_EXPORT_WIZARD: `${prefix}${productExport}/retailer-export-task-wizard`,
  VARIANTS_UPDATE: `${prefix}${variantsUpdate}`,
  VARIANTS_UPDATE_WIZARD: `${prefix}${variantsUpdate}/retailer-variants-update-task-wizard`,
  VARIANTS_UPDATE_WIZARD_SHOPIFY: `${prefix}${variantsUpdate}/${wizard}/shopify`,
  VARIANTS_UPDATE_WIZARD_JUMPSELLER: `${prefix}jumpseller-vu-task-wizard`,
} as const;
