import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogMultiWrapperComponent } from '../../../../../shared/components/dialog-multi-wrapper/dialog-multi-wrapper.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-supplier-reply-dialog',
  standalone: true,
  imports: [CommonModule, DialogMultiWrapperComponent, FlexModule, TranslateModule],
  templateUrl: './supplier-reply-dialog.component.html',
  styleUrls: ['./supplier-reply-dialog.component.scss'],
})
export class SupplierReplyDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<SupplierReplyDialogComponent>
  ) {
    this.dialogRef.addPanelClass('custom-modal-container');
  }

  ngOnInit(): void {}
}
