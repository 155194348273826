import { Injectable } from '@angular/core';
import { BreakpointState } from '@angular/cdk/layout';
import { BreakPoint, ScreenManagerService } from '../screen-manager/screen-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PaginationSizeService {
  constructor(private screenManagerService: ScreenManagerService) {}

  getSizeToPaginate(state: BreakpointState, maxProductToShow: number, productCardWidth): number {
    const cardSize = Number(this.handleScreenChange(state, productCardWidth).replace('%', ''));
    if (cardSize) {
      const numberPerRow = Math.round(100 / cardSize);
      const numberToRemove = maxProductToShow % numberPerRow;
      return maxProductToShow - numberToRemove;
    } else {
      return maxProductToShow;
    }
  }

  private handleScreenChange(state: BreakpointState, productCardWidth): string {
    if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.xs)]) {
      return productCardWidth.xs;
    } else if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.sm)]) {
      return productCardWidth.xs;
    } else if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.md)]) {
      return productCardWidth.sm;
    } else if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.lg)]) {
      return productCardWidth.md;
    } else if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.xl)]) {
      return productCardWidth.lg;
    } else {
      return productCardWidth.xl;
    }
  }
}
