import { Component, Inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  CurrencyWrapper,
  EvalWrapper,
  FieldOperation,
  HPCCWrapper,
  ReplacerWrapper,
  SplitterWrapper,
  WeightWrapper,
} from '../../../droppable-input/chip';
import {
  CFieldOperation,
  FieldSettingsComponent,
  OperationsWithSettings,
  ReplaceRefWrapper,
} from '../../../field-settings/field-settings.component';
import { Replacer } from '../../../field-settings/replacer/replacer';
import { ReplacerRef } from '../../../field-settings/replacer/replacer-ref';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SettingsDialogData } from '../../../droppable-input/droppable-input.component';
import { FieldStructure } from '../../../filter/filter-item/filter-item.component';
import { MapInputData } from '../../../../service/taskwizard/taskwizard-update-response';
import { FieldsMakerComponent } from './fields-maker.component';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';

export const SettingsConsts = {
  DIRECTION_FLAT: 'flat',
  DIRECTION_RAW: 'raw',

  DIRECTION_NO_REPETITIVE: false,
  DIRECTION_REPETITIVE: true,
};

export interface ProductField {
  name: string;
  displayName: string;
}

export class PrefixWrapper {
  prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }
}

export class SufixWrapper {
  sufix: string;

  constructor(sufix: string) {
    this.sufix = sufix;
  }
}

export class MFieldSettings {
  fieldDirection: string;
  fieldRepetitive: boolean;
}

export interface Group {
  name: string;
  isArray: boolean;
}

export interface Field {
  group: Group;
  name: string;
}

export interface MappingField {
  fields: Field[];
  keyName: string;
  originalName: string;
  displayName: string;
  customDisplayName: string;
  settings: MFieldSettings;
  operations: MFieldOperation[];
  additions?: any;
}

export type MFieldOperation = ReplaceRefWrapper | EvalWrapper | SufixWrapper | PrefixWrapper | ReplacerWrapper;

@Component({
  selector: 'app-field-selector',
  templateUrl: './field-selector.component.html',
  styleUrls: ['./field-selector.component.scss'],
})
export class FieldSelectorComponent implements OnInit {
  @ViewChild('fieldsMaker', { static: true }) viewfieldMaker: FieldsMakerComponent;

  REPLACER_REF = 'replacerRef';
  REPLACER = 'replacer';
  EVAL = 'eval';
  SPLITTER = 'splitter';
  PREFIX = 'Prefix';
  SUFIX = 'Sufix';

  operationFields: MFieldOperation[] = null;
  settingsField: MFieldSettings = null;

  selectedFields: any[];

  settingsConsts = SettingsConsts;

  @Input() fieldsConfig: any[] = [];

  mappingFieldConfig: any[];
  mappingField = <MappingField>{ settings: <MFieldSettings>{} };

  originDirection;
  canBeFlat = false;

  fieldChips: MapInputData = <MapInputData>{ chips: [], settings: [], operations: [] };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<FieldSelectorComponent>,
    private translate: TranslateService
  ) {
    this.settingsField = new MFieldSettings();
    this.operationFields = [];
    this.mappingFieldConfig = data.mappingFieldConfig;
  }

  ngOnInit(): void {
    this.init(this.data.mappingField);
  }

  init(selectedField: MappingField): void {
    if (Utils.isNullOrUndefined(selectedField)) {
      this.selectedFields = [];
      return;
    }

    this.originDirection = selectedField.settings.fieldDirection;
    this.initSelectedFields(selectedField);

    this.mappingField = selectedField;
    this.mappingField.operations.forEach((opField) => {
      if (!Utils.isNullOrUndefined(opField['replacer'])) {
        const replaceRefWrapper = new ReplaceRefWrapper();
        replaceRefWrapper.replacers = (opField as ReplacerWrapper).replacer.map((r) => new ReplacerRef(r));
        this.operationFields.push(replaceRefWrapper);
      }

      if (!Utils.isNullOrUndefined(opField['eval'])) {
        this.operationFields.push(new EvalWrapper(opField['eval']));
      }

      if (!Utils.isNullOrUndefined(opField['prefix'])) {
        this.operationFields.push(new PrefixWrapper(opField['prefix']));
      }

      if (!Utils.isNullOrUndefined(opField['sufix'])) {
        this.operationFields.push(new SufixWrapper(opField['sufix']));
      }
    });
  }

  initSelectedFields(selectedField: MappingField): void {
    const tempSelectedFields = [];
    selectedField.fields.forEach((field) => {
      if (Utils.isNullOrUndefined(field.group)) {
        tempSelectedFields.push({ group: field.group, field: { name: field.name, displayName: field.name } });
        return;
      }
      this.mappingFieldConfig.forEach((groupField) => {
        if (field.group.name !== groupField.name) {
          return;
        }
        groupField.fields.forEach((fieldConfig) => {
          if (fieldConfig.name === field.name) {
            tempSelectedFields.push({ group: field.group, field: fieldConfig });
          }
        });
      });
    });
    this.checkIfCanBeFlat(selectedField.fields);
    this.selectedFields = tempSelectedFields;
  }

  drop(event: CdkDragDrop<ViewContainerRef[]>): void {
    moveItemInArray(this.operationFields, event.previousIndex, event.currentIndex);
  }

  removeOperation(opField: MFieldOperation): void {
    const index = this.operationFields.indexOf(opField);
    if (index > -1) {
      this.operationFields.splice(index, 1);
    }
  }

  checkOperationFieldIsEmpty(opField: MFieldOperation): boolean {
    switch (true) {
      case opField instanceof ReplacerWrapper:
        if ((opField as ReplacerWrapper).replacer.length < 1) {
          return true;
        }
        break;
      case opField instanceof EvalWrapper:
        if (!(opField as EvalWrapper).eval) {
          return true;
        }
        break;
    }
  }

  private getReplacerSettings(replaceRefW: ReplaceRefWrapper): Replacer[] {
    return replaceRefW.replacers.map((r: ReplacerRef) => r.getSettings()).filter((val) => val.replace || val.value);
  }

  public removeReplacer(replaceRefW: ReplaceRefWrapper, id: number): void {
    const replacerRef = replaceRefW.replacers.find((r) => r.id === id);
    if (replacerRef) {
      replaceRefW.replacers.splice(replaceRefW.replacers.indexOf(replacerRef), 1);
    }
  }

  public addNewReplacer(replaceRefW: ReplaceRefWrapper): void {
    replaceRefW.replacers.push(new ReplacerRef());
  }

  addReplacer(): void {
    const replaceRefWrapper = new ReplaceRefWrapper();
    this.addNewReplacer(replaceRefWrapper);
    this.operationFields.push(replaceRefWrapper);
  }

  addOperation(): void {
    this.operationFields.push(new EvalWrapper(''));
  }

  getOperationFieldType(operation): string {
    switch (true) {
      case operation instanceof ReplaceRefWrapper:
        return this.REPLACER_REF;
      case operation instanceof ReplacerWrapper:
        return this.REPLACER;
      case operation instanceof EvalWrapper:
        return this.EVAL;
      case operation instanceof SplitterWrapper:
        return this.SPLITTER;
      case operation instanceof PrefixWrapper:
        return this.PREFIX;
      case operation instanceof SufixWrapper:
        return this.SUFIX;
      default:
        return '';
    }
  }

  submit(): void {
    const operations = [];
    this.operationFields.forEach((opField) => {
      if (opField instanceof ReplaceRefWrapper) {
        const finalOpField = new ReplacerWrapper(this.getReplacerSettings(opField));
        if (!this.checkOperationFieldIsEmpty(finalOpField)) {
          operations.push(finalOpField);
        }
      }
      if (opField instanceof EvalWrapper) {
        if (!this.checkOperationFieldIsEmpty(opField)) {
          operations.push(opField);
        }
      }
      if (opField instanceof PrefixWrapper || opField instanceof SufixWrapper) {
        operations.push(opField);
      }
    });

    if (Utils.isNullOrUndefined(this.mappingField.displayName)) {
      this.mappingField.displayName = this.mappingField.originalName;
    }

    this.mappingField.operations = operations;
    this.dialogRef.close(this.mappingField);
  }

  addPrefix(): void {
    this.operationFields.push(new PrefixWrapper(''));
  }

  addSufix(): void {
    this.operationFields.push(new SufixWrapper(''));
  }

  directionChanged($event: MatButtonToggleChange): void {
    if ($event.value === this.settingsConsts.DIRECTION_RAW) {
      this.settingsField.fieldRepetitive = this.settingsConsts.DIRECTION_NO_REPETITIVE;
    } else {
      this.settingsField.fieldRepetitive = null;
    }
  }

  isCustomDisplayTitle(): boolean {
    return (
      !Utils.isNullOrUndefined(this.originDirection) &&
      this.mappingField.settings.fieldDirection === this.settingsConsts.DIRECTION_FLAT &&
      this.originDirection === this.settingsConsts.DIRECTION_FLAT
    );
  }

  fieldsChanged(fields: any[]): void {
    this.mappingField.fields = [];

    fields.forEach((field) => {
      this.mappingField.fields.push(<Field>{ name: field.field.name, group: field.group });
    });

    this.checkIfCanBeFlat(fields);
  }

  checkIfCanBeFlat(fields: any[]): void {
    this.canBeFlat = false;
    const groups = {};
    fields.forEach((field) => {
      const group = field.group;
      if (!Utils.isNullOrUndefined(group)) {
        groups[group.name] = group.isArray;
      } else {
        groups['null_group'] = 'null_group';
      }
    });
    const groupNames = Object.keys(groups);
    if (groupNames.length === 1 && groups[groupNames[0]] === true && groups[groupNames[0]] !== 'null_group') {
      this.canBeFlat = true;
    } else if (this.mappingField.settings.fieldDirection === SettingsConsts.DIRECTION_FLAT) {
      this.mappingField.settings.fieldDirection = null;
    }
  }
}
