import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  AddToFavoriteIdsStartAction,
  AddToFavoriteIdsSuccessAction,
  AddToFollowedSupplierIdsStartAction,
  AddToFollowedSupplierIdsSuccessAction,
  FavoritesActionTypes,
  GetFavoriteProductsIdsStartAction,
  GetFavoriteProductsIdsSuccessAction,
  GetFollowedSupplierIdsSuccessAction,
  RemoveAllFavoriteIdsStartAction,
  RemoveAllFavoriteIdsSuccessAction,
  RemoveAllFollowedSupplierIdsStartAction,
  RemoveAllFollowedSupplierIdsSuccessAction,
  RemoveFromFavoriteIdsStartAction,
  RemoveFromFavoriteIdsSuccessAction,
  RemoveFromFollowedSupplierIdsStartAction,
  RemoveFromFollowedSupplierIdsSuccessAction,
} from './favorites.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FavoritesGatewayService } from '../../service/favorites/favorites-gateway.service';

@Injectable()
export class FavoriteProductsEffects {
  constructor(private actions$: Actions, private favoriteProductService: FavoritesGatewayService) {}

  GetFavoriteProductIdsStart: Observable<GetFavoriteProductsIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.GET_FAVORITE_PRODUCT_IDS_START),
      switchMap((getAction: GetFavoriteProductsIdsStartAction) =>
        this.favoriteProductService.getFavoritesIdList(getAction.payload).pipe(catchError(() => of([])))
      ),
      map((favoriteIds: string[]) => {
        return new GetFavoriteProductsIdsSuccessAction(favoriteIds ?? []);
      })
    )
  );

  AddToFavoriteProductIdsStart: Observable<AddToFavoriteIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.ADD_TO_FAVORITE_PRODUCT_IDS_START),
      switchMap((addAction: AddToFavoriteIdsStartAction) =>
        this.favoriteProductService
          .postFavorites(addAction.payload.userId, addAction.payload.productIds)
          .pipe(catchError(() => of([])))
      ),
      map((favoriteIds: string[]) => {
        return new AddToFavoriteIdsSuccessAction(favoriteIds);
      })
    )
  );

  RemoveFromFavoriteIdsStart: Observable<RemoveFromFavoriteIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.REMOVE_FROM_FAVORITE_PRODUCT_IDS_START),
      switchMap((removeAction: RemoveFromFavoriteIdsStartAction) => {
        return this.favoriteProductService
          .deleteFavorites(removeAction.payload.userId, removeAction.payload.productIds)
          .pipe(
            catchError(() => of([])),
            map(() => removeAction.payload.productIds)
          );
      }),
      map((value) => {
        return new RemoveFromFavoriteIdsSuccessAction(value);
      })
    )
  );

  RemoveAllFavoriteIdsStart: Observable<RemoveAllFavoriteIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.REMOVE_ALL_FAVORITE_PRODUCT_IDS_START),
      switchMap((removeAction: RemoveAllFavoriteIdsStartAction) =>
        this.favoriteProductService.deleteAllFavorites(removeAction.payload).pipe(catchError(() => of(null)))
      ),
      map(() => {
        return new RemoveAllFavoriteIdsSuccessAction();
      })
    )
  );

  //////////////////////////////////////////////////////////////////////////////////////////

  GetFollowedSupplierIdsStart: Observable<GetFollowedSupplierIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.GET_FOLLOWED_SUPPLIER_IDS_START),
      switchMap((getAction: GetFavoriteProductsIdsStartAction) =>
        this.favoriteProductService.getFollowedSuppliersIds(getAction.payload).pipe(catchError(() => of([])))
      ),
      map((supplierIds: number[]) => {
        return new GetFollowedSupplierIdsSuccessAction(supplierIds ?? []);
      })
    )
  );

  AddToFollowedSupplierIdsStart: Observable<AddToFollowedSupplierIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.ADD_TO_FOLLOWED_SUPPLIER_IDS_START),
      switchMap((addAction: AddToFollowedSupplierIdsStartAction) =>
        this.favoriteProductService
          .postFollowedSupplierIds(addAction.payload.userId, addAction.payload.supplierIds)
          .pipe(catchError(() => of([])))
      ),
      map((supplierIds: number[]) => {
        return new AddToFollowedSupplierIdsSuccessAction(supplierIds);
      })
    )
  );

  RemoveFromFollowedSupplierIdsStart: Observable<RemoveFromFollowedSupplierIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_START),
      switchMap((removeAction: RemoveFromFollowedSupplierIdsStartAction) => {
        return this.favoriteProductService
          .deleteFollowedSupplierIds(removeAction.payload.userId, removeAction.payload.supplierIds)
          .pipe(
            catchError(() => of([])),
            map(() => removeAction.payload.supplierIds)
          );
      }),
      map((supplierIds) => {
        return new RemoveFromFollowedSupplierIdsSuccessAction(supplierIds);
      })
    )
  );

  RemoveAllFollowedSupplierIdsStart: Observable<RemoveAllFollowedSupplierIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActionTypes.REMOVE_ALL_FOLLOWED_SUPPLIER_IDS_START),
      switchMap((removeAction: RemoveAllFollowedSupplierIdsStartAction) =>
        this.favoriteProductService.deleteAllFollowedSupplierIds(removeAction.payload).pipe(catchError(() => of(null)))
      ),
      map(() => {
        return new RemoveAllFollowedSupplierIdsSuccessAction();
      })
    )
  );
}
