<app-custom-dialog-wrapper [actionBarRef]="actionBarRef" [headerLeftRef]="headerLeftRef" [hideCloseBtn]="true">
  <div class="prod-sample-dialog-content" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="8px">
      <button mat-button class="syncee-white-button" [disabled]="(index | async) === 0" (click)="prevProduct()">
        {{ 'TASKWIZARD.ADD_FILES.PREVIOUS_EXAMPLE' | translate }}
      </button>
      <button
        mat-button
        class="syncee-white-button"
        [disabled]="!taskWizardSampleService.hasNextItem"
        (click)="nextProduct()"
      >
        {{ 'TASKWIZARD.ADD_FILES.NEXT_EXAMPLE' | translate }}
      </button>
    </div>
    <div class="list" fxFlex="1 0 0">
      <mat-radio-group
        *conditionLoading="!!productSample"
        class="p-20"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="20px"
        [(ngModel)]="selectedField"
        (ngModelChange)="error = false"
      >
        <ng-container *ngFor="let sample of productSample; let last = last">
          <mat-radio-button [value]="sample.name">
            <div class="h5 name">{{ sample.name }}</div>
            <div class="value">{{ sample.value }}</div>
          </mat-radio-button>
          <app-custom-divider *ngIf="!last"></app-custom-divider>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
</app-custom-dialog-wrapper>
<ng-template #actionBarRef>
  <div class="w-100p p-32">
    <mat-error *ngIf="error">{{ 'TASKWIZARD.ADD_FILES.SELECT_FIELD' | translate }}</mat-error>
    <div fxLayoutAlign="end center" fxLayoutGap="8px">
      <button mat-flat-button [matDialogClose]="null">{{ 'TASKWIZARD.ADD_FILES.CLOSE' | translate }}</button>
      <button mat-flat-button color="primary" (click)="onSelect()">
        {{ 'TASKWIZARD.ADD_FILES.SELECT' | translate }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #headerLeftRef>
  <div class="h4" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div>{{ 'TASKWIZARD.ADD_FILES.SELECT_FIELD' | translate }}</div>
  </div>
</ng-template>
