import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[lazyLoadImg]',
  standalone: true,
})
export class LazyloadProductImageDirective {
  @HostBinding('style.opacity')
  opacity = '0';

  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }

    nativeElement.setAttribute('onload', 'this.className+=" animateLazyLoadImage"');
  }
}
