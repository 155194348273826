<div class="sort-filter-container">
  <div class="sort-by">{{ 'FILTER_COMMON.TITLES.SORT_BY' | translate }}</div>
  <mat-radio-group [value]="value" (change)="handleValueChange($event.value)">
    <div class="sort-options">
      <mat-radio-button
        *ngFor="let option of options"
        [value]="option.filter"
        [checked]="compareWith(option.filter, value)"
        >{{ option.label | translate }}</mat-radio-button
      >
    </div>
  </mat-radio-group>
</div>
