import { Component, Input, OnInit } from '@angular/core';
import { productCardStatusConfigs } from '../../product-card.config';
import { ProductCatalogVo } from '../../../../vo/search-product-vo';

@Component({
  selector: 'app-product-card-action-in-catalog',
  templateUrl: './product-card-action-in-catalog.component.html',
  styleUrls: ['./product-card-action-in-catalog.component.scss'],
})
export class ProductCardActionInCatalogComponent implements OnInit {
  @Input() catalogs: ProductCatalogVo[];

  readonly config = productCardStatusConfigs;

  public catalogName: string;

  constructor() {}

  ngOnInit(): void {
    this.getFirstCatalogName();
  }

  private getFirstCatalogName(): void {
    this.catalogName = this.catalogs?.[0]?.CATALOG_NAME;
  }
}
