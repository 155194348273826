<div style="overflow: auto">
  <!-- (searchTermChange)="searchTermChange.emit($event)" -->
  <app-custom-table
    [dataSource]="mySuppliersDataSource"
    [fetcherLogic]="false"
    [displayedColumns]="displayedColumns"
    [overflowScroll]="true"
    [enableFilters]="true"
    [showFilterButton]="true"
    [size]="pageService.pagination.size"
    [allItemsCount]="allItemsCount"
    [noResultComponent]="noResultComponent"
    [page]="pageService.page$ | async"
    [isLoading]="pageService.loading$ | async"
    (nextClicked)="nextClicked.emit()"
    (previousClicked)="previousClicked.emit()"
    (sizeChanged)="sizeChanged.emit($event)"
    (searchTermChange)="searchTermChange.emit($event)"
    [filterArray]="tableFilterArray"
    [activeFilters]="activeFilters"
    (filterChange)="handleFilterChange($event)"
    (clearAllClicked)="clearFilter()"
    [viewArray]="tableViewArray"
    (viewChange)="handleViewChange($event)"
  >
    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'MY_SUPPLIERS.TABLE_TITLES.SUPPLIER' | translate }}
      </th>

      <td mat-cell *matCellDef="let retailerToCatalog">
        <ng-container *ngIf="retailerToCatalog.catalogId | getSupplierFromCacheByCatalogId | async as supplier">
          <div style="width: 100%" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
            <ng-container *ngIf="retailerToCatalog.catalogId | getTaskFromCacheById | async as supplierTask">
              <div
                class="text-truncate clickable-underlined cursor-pointer"
                (click)="openCatalogInformationDialog(supplier, retailerToCatalog, supplierTask.name)"
              >
                {{ supplier.companyName }}
              </div>
            </ng-container>
            <app-badge-custom
              *ngIf="!supplier.autoOrder"
              style="font-weight: 600"
              [text]="'RETAILER_MANAGE_ORDERS.BADGE.NON_AUTO_ORDER' | translate"
              [textColor]="'NON_AUTO_ORDER' | statusTextColor"
              [bgColor]="'NON_AUTO_ORDER' | statusColor"
              fxFlex="1 0 auto"
              badgeSize="sm"
              [matTooltip]="'MY_SUPPLIERS.TOOLTIPS.NON_AUTO_ORDER' | translate"
              matTooltipPosition="above"
            ></app-badge-custom>
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="catalog">
      <th mat-header-cell *matHeaderCellDef>{{ 'MY_SUPPLIERS.CATALOG' | translate }}</th>

      <td mat-cell *matCellDef="let retailerToCatalog">
        <ng-container *ngIf="retailerToCatalog.catalogId | getTaskFromCacheById | async as catalog">
          {{ catalog.name }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>{{ 'MY_SUPPLIERS.TABLE_TITLES.MESSAGE' | translate }}</th>

      <td mat-cell *matCellDef="let retailerToCatalog">
        <ng-container *ngIf="retailerToCatalog.catalogId | getSupplierFromCacheByCatalogId | async as supplier">
          <app-contact-user
            [recipientUserRole]="RolesEnum.SUPPLIER"
            [recipientUserID]="supplier.userId.toString()"
            [recipientUserName]="supplier.companyName"
            [recipientUserContactEmail]="supplier.contactEmail"
          ></app-contact-user>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="products">
      <th mat-header-cell *matHeaderCellDef>{{ 'MY_SUPPLIERS.TABLE_TITLES.PRODUCTS' | translate }}</th>

      <td mat-cell *matCellDef="let retailerToCatalog">
        <ng-container *ngIf="!(pageService.metaDataLoading$ | async); else skeleton">
          <ng-container
            *ngIf="
              retailerToCatalog.catalogId | getManagedProductNumberFromCacheByCatalogId | async as managedProductNumber
            "
          >
            {{
              (managedProductNumber.count === 1
                ? 'MY_SUPPLIERS.TABLE_CONTENT.MANAGED_PRODUCTS'
                : 'MY_SUPPLIERS.TABLE_CONTENT.MANAGED_PRODUCTS_MULTIPLE'
              )
                | translate
                  : {
                      productCount: managedProductNumber.count
                    }
            }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="orders">
      <th mat-header-cell *matHeaderCellDef>{{ 'MY_SUPPLIERS.TABLE_TITLES.ORDERS' | translate }}</th>

      <td mat-cell *matCellDef="let retailerToCatalog">
        <ng-container *ngIf="!(pageService.metaDataLoading$ | async); else skeleton">
          <ng-container
            *ngIf="
              retailerToCatalog.catalogId | getOrderInfoFromCacheByCatalogId | async as wholesalePriceAndOrderNumber
            "
          >
            <app-orders-info
              [total]="wholesalePriceAndOrderNumber.wholesalePriceSum"
              [numberOfOrders]="wholesalePriceAndOrderNumber.lineItemCount"
              [currency]="'USD'"
            ></app-orders-info>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'MY_SUPPLIERS.TABLE_TITLES.STATUS' | translate }}</th>

      <td mat-cell *matCellDef="let retailerToCatalog">
        <app-catalog-approve-status-badge
          [approveStatus]="retailerToCatalog.approved"
        ></app-catalog-approve-status-badge>
      </td>
    </ng-container>
  </app-custom-table>
</div>

<ng-template #upsellBadge>
  <app-need-subscription-badge></app-need-subscription-badge>
</ng-template>

<ng-template #skeleton>
  <div [style.margin-inline]="'6px'">
    <app-skeleton-text [lines]="1"></app-skeleton-text>
  </div>
</ng-template>
