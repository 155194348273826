import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateType } from 'app/service/image-templates/image-templates.service';

@Component({
  selector: 'app-image-template-selector',
  templateUrl: './image-template-selector.component.html',
  styleUrls: ['./image-template-selector.component.scss'],
})
export class ImageTemplateSelectorComponent implements OnInit {
  selected: Blob;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageTemplateSelectorData,
    private dialogRef: MatDialogRef<ImageTemplateSelectorComponent, ImageTemplateSelectorResponse>
  ) {}

  ngOnInit(): void {}

  handleSetSelected(selection: Blob): void {
    this.selected = selection;
  }

  closeDialog(): void {
    this.dialogRef.close({ image: this.selected });
  }
}

export interface ImageTemplateSelectorData {
  templateType: TemplateType;
}

export interface ImageTemplateSelectorResponse {
  image: Blob;
}
