import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatService } from 'app/service/chat/chat.service';
import { take } from 'rxjs/operators';
import { ChatMinimizationService } from '../../../../../service/chat-minimization/chat-minimization.service';
import { ChannelItem } from '../../../../../service/chat/model/chat-models';
import { NavigationService } from '../../../../../service/navigation/navigation.service';
import { SupplierGatewayService } from '../../../../../service/suppliers/supplier-gateway.service';
import { SupplierWithBadgesDto } from '../../../../../vo/supplier/supplier-with-badges-dto';
@UntilDestroy()
@Component({
  selector: 'app-chat-body-header',
  templateUrl: './chat-body-header.component.html',
  styleUrls: ['./chat-body-header.component.scss'],
})
export class ChatBodyHeaderComponent implements OnInit {
  @Input() currentUserId: string;
  @Input() channel: ChannelItem;
  @Input() isInnerChat = false;
  @Input() isMobile: boolean;
  @Input() channelSideHidden: boolean;
  @Output() backClick = new EventEmitter<void>();

  useFallbackAvatar: boolean;

  supplierData: SupplierWithBadgesDto;

  seoUrl: string;

  constructor(
    private chatService: ChatService,
    private dialog: MatDialog,
    private chatMinimizationService: ChatMinimizationService,
    private supplierGatewayService: SupplierGatewayService,
    private navigationService: NavigationService
  ) {}

  handleBackClick(): void {
    this.backClick.emit();
  }

  closeChatAppDialog(): void {
    this.chatService.closeChatAppDialog(!this.channelSideHidden);
  }
  ngOnInit(): void {
    this.getSupplierData();
  }

  getSupplierData(): void {
    this.supplierGatewayService
      .getSingleSupplier(this.channel.sUserId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((supplierData) => {
        this.supplierData = supplierData;
      });
  }
  navigateToStoreFront(): void {
    if (!!this.supplierData) {
      this.navigationService.navigateToStorefront(this.supplierData.userId, this.supplierData.handle, true);
    }
  }

  handleMinimizeClick(): void {
    const currentUser = this.channel.recipients.find((user) => user.id === this.currentUserId);
    const recipientUser = this.channel.recipients.find((user) => user.id !== this.currentUserId);
    this.chatMinimizationService.addMinimizedChat({
      currentUser: {
        id: currentUser.sUserId,
        name: currentUser.name,
        email: currentUser.email,
        avatar: currentUser.avatar,
      },
      recipientUser: {
        avatar: recipientUser.avatar,
        email: recipientUser.email,
        name: recipientUser.name,
        id: recipientUser.sUserId,
      },
      lastMessageTime: null,
    });
    this.dialog.closeAll();
  }
}
