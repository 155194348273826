import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierReturnAndRefundPolicyDto } from 'app/vo/return-and-refund-policy';
import { CustomDividerComponent } from '../../custom-divider/custom-divider.component';
import { CustomDialogWrapperComponent } from '../custom-dialog-wrapper/custom-dialog-wrapper.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-return-and-refund-policy-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    TranslateModule,
    CustomDividerComponent,
    CustomDialogWrapperComponent,
    MatIconModule,
    FlexModule,
  ],
  templateUrl: './return-and-refund-policy-dialog.component.html',
  styleUrls: ['./return-and-refund-policy-dialog.component.scss'],
})
export class ReturnAndRefundPolicyDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public policyData: ReturnAndRefundPolicyDialogData) {}

  ngOnInit(): void {}
}

export interface ReturnAndRefundPolicyDialogData {
  supplierPolicyData: Partial<SupplierReturnAndRefundPolicyDto>;
}
