import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy, isEqual, orderBy, uniq, uniqBy } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { AppState } from '../../../../app.state';
import { CountryMapperService } from '../../../../service/country/country-mapper.service';
import { CountryNameToCodePipe } from '../../../../shared/pipes/legacy/country-name-to-code.pipe';
import { shipsToPreferenceSelector } from '../../../../store/preferences/preferences.selector';
import { locationByIpSelector } from '../../../../store/user/user.selector';
import { SearchProductVO } from '../../../../vo/search-product-vo';
import { Shipping, ShippingWithLegacyRates } from '../../../../vo/shipping/shipping';

@Component({
  selector: 'app-product-details-supplier-shipping',
  templateUrl: './product-details-supplier-shipping.component.html',
  styleUrls: ['./product-details-supplier-shipping.component.scss'],
  animations: [fuseAnimations],
})
export class ProductDetailsSupplierShippingComponent implements OnInit, OnDestroy {
  @Input() product: SearchProductVO;
  @Input() ecomCurrency: string;

  countriesToShow: ProductCardCountry[] = [];
  isOpen = false;
  hasPreferredCountry: boolean;

  private preferredCountries: string[] = [];
  private warehouseLocationCountryCode: string;
  private unsubscribeAll: Subject<void>;
  private readonly predefinedFeaturedCountries = ['US', 'GB', 'CA', 'AU', 'DE', 'ES', 'FR'];

  constructor(
    private store: Store<AppState>,
    private countryNameToCodePipe: CountryNameToCodePipe,
    private countryMapperService: CountryMapperService,
    public el: ElementRef
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.warehouseLocationCountryCode = this.countryNameToCodePipe.transform(this.product.SETTINGS.warehouseLocation);
    this.subscribeToPreferencesBootstrap();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToPreferencesBootstrap(): void {
    combineLatest([
      this.store.select(shipsToPreferenceSelector).pipe(tap((countries) => this.savePreferredCountries(countries))),
      this.store.select(locationByIpSelector),
    ])
      .pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        takeUntil(this.unsubscribeAll),
        map(([preferredCountries, ipLocation]) =>
          !!preferredCountries?.length ? this.preferredCountries : this.predefinedFeaturedCountries.concat(ipLocation)
        )
      )
      .subscribe((countries) => {
        this.handlePreferenceStored(countries);
      });
  }

  private savePreferredCountries(countries: string[]): void {
    this.preferredCountries = countries?.length
      ? countries.map((country) => this.countryNameToCodePipe.transform(country))
      : [];
  }

  private handlePreferenceStored(preferredCountries: string[]): void {
    this.calcShippingQuickViewCountries(uniq(preferredCountries.concat(this.warehouseLocationCountryCode)));
    this.hasPreferredCountry = this.countriesToShow.some((country) => this.preferredCountries.includes(country.code));
    if (!this.countriesToShow?.length) {
      this.calcShippingQuickViewCountries(
        this.predefinedFeaturedCountries.concat(
          this.product?.SHIPPING?.flatMap((el) => el.locations)?.filter((location) => !location.includes('-'))
        )
      );
    }
  }

  private calcShippingQuickViewCountries(preferredCountries: string[]): void {
    const countries: ProductCardCountry[] = [];
    const shippingDetails = !!this.product.SHIPPING?.length
      ? (this.product.SHIPPING as ShippingWithLegacyRates[])
      : this.countryMapperService.mapShippingDetailsToShipping(this.product.SUPPLIER.shippingDetails);
    shippingDetails.forEach((zone) => {
      const preferredFromZone = zone.locations.filter((country) => preferredCountries.includes(country.split('-')[0]));
      countries.push(...this.getCountriesFromZone(preferredFromZone, zone));
    });
    const statesHandled = this.handleStates(countries);
    const needToSortByWarehouse =
      !this.preferredCountries?.length && !this.preferredCountries.includes(this.warehouseLocationCountryCode);
    const iteratees: (keyof ProductCardCountry)[] = [
      ...(needToSortByWarehouse ? ['mainWarehouse' as keyof ProductCardCountry] : []),
      'minShippingDays',
      'minCost',
    ];
    const orders: ('asc' | 'desc')[] = [...(needToSortByWarehouse ? ['desc' as 'desc'] : []), 'asc', 'asc'];
    this.countriesToShow = orderBy(statesHandled, iteratees, orders).slice(0, 4);
  }

  private getCountriesFromZone(countryNames: string[], zone: Shipping): ProductCardCountry[] {
    const costs = this.getCosts(zone);
    return countryNames.map((country) => ({
      minCost: costs.minCost,
      maxCost: costs.maxCost,
      maxShippingDays: zone.maxShippingDays,
      minShippingDays: zone.minShippingDays,
      code: country,
      mainWarehouse: this.warehouseLocationCountryCode === country,
    }));
  }

  private handleStates(countries: ProductCardCountry[]): ProductCardCountry[] {
    const states = countries
      .map((country) => {
        const [countryCode, provinceCode] = country.code.split('-');
        return { ...country, code: countryCode, provinceCode };
      })
      .filter((country) => !!country.provinceCode);
    const statesDictionary = groupBy(states, (country) => country.code);
    const countriesFromStates = Object.entries(statesDictionary).map(([, value]) => this.getDataOfStates(value));
    const countryKeysFromState = Object.keys(statesDictionary);
    return uniqBy(
      countries
        .filter((country) => !countryKeysFromState.includes(country.code))
        .filter((country) => country.code.indexOf('-') === -1)
        .concat(...countriesFromStates),
      'code'
    );
  }

  private getDataOfStates(states: ProductCardCountry[]): ProductCardCountry {
    const minShippingDays = this.reduceByProp('min', states, 'minShippingDays');
    const maxShippingDays = this.reduceByProp('max', states, 'maxShippingDays');
    const minCost = this.reduceByProp('min', states, 'minCost');
    const maxCost = this.reduceByProp('max', states, 'maxCost');
    return {
      code: states[0].code,
      minCost,
      maxCost,
      maxShippingDays,
      minShippingDays,
      mainWarehouse: this.warehouseLocationCountryCode === states[0].code,
    };
  }

  private reduceByProp(
    type: 'min' | 'max',
    array: ProductCardCountry[],
    key: keyof Pick<ProductCardCountry, 'maxCost' | 'minCost' | 'maxShippingDays' | 'minShippingDays'>
  ): number {
    return type === 'min'
      ? array.reduce((previousValue, currentValue) =>
          previousValue[key] ?? 0 < (currentValue[key] ?? 0) ? previousValue : currentValue
        )[key] ?? 0
      : array.reduce((previousValue, currentValue) =>
          previousValue[key] ?? 0 > (currentValue[key] ?? 0) ? previousValue : currentValue
        )[key] ?? 0;
  }

  private getCosts(zone: Shipping): ShippingPrice {
    return {
      minCost: zone.shippingCost,
      maxCost: zone.shippingCost,
    };
  }
}

interface ShippingPrice {
  minCost: number;
  maxCost: number;
}

export interface ProductCardCountry {
  code: string;
  minCost: number;
  maxCost: number;
  minShippingDays: number;
  maxShippingDays: number;
  mainWarehouse: boolean;
}
