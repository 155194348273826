<div class="cancel-dialog-subscribe-container">
  <div
    class="typography-body-1"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-sm="start center"
    fxLayout.lt-sm="column"
    fxLayout="row"
  >
    <div class="step-title">{{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.SUBSCRIPTION.TITLE' | translate }}</div>
    <div
      (click)="handleMoreLinkClicked()"
      [innerHTML]="'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.SUBSCRIPTION.MORE_ABOUT' | translate"
    ></div>
  </div>
  <div class="annual-changer">
    <div fxLayoutAlign="center center" fxLayoutGap="16px" fxLayout.lt-sm="column" fxLayout="row">
      <div fxLayoutAlign="center center">
        {{ 'PLAN_UPSELL.MONTHLY' | translate }}
        <mat-slide-toggle class="slider" [(ngModel)]="isAnnual"></mat-slide-toggle>
        {{ 'PLAN_UPSELL.ANNUAL' | translate }}
      </div>
      <app-information-chip
        [text]="'PLAN_UPSELL.GET_UP' | translate"
        [class]="'blue-chip typography-button bigger-padding'"
      ></app-information-chip>
      <!--      <mat-chip *ngIf="selectedPlanType === 'rmp'" class="syncee-blue-chip" (click)="handleAnnualBadgeClicked()">-->
      <!--        {{ 'PLAN_UPSELL.GET_UP' | translate }}-->
      <!--      </mat-chip>-->
    </div>
  </div>
  <div fxLayoutGap="24px" fxLayout="row" fxLayout.lt-md="column">
    <ng-container *ngFor="let plan of selectedPlanType === 'rmp' ? plansToMarketplaceShow : plansToDataFeedShow">
      <div fxFlex="1 0 0" *ngIf="!isAnnual || (plan.hasAnnualPlan && isAnnual)">
        <app-plan-upsell-box
          [isCurrent]="
            (selectedEcom && selectedEcom.subscriptions && selectedEcom.subscriptions.rmp.planId === plan.id) ||
            selectedEcom.subscriptions.df.planId === plan.id
          "
          [isTrialValidForEcom]="false"
          [plan]="plan"
          [isAnnual]="isAnnual"
          (buttonClicked)="buttonClicked($event)"
          [buttonText]="
            (isAnnual
              ? 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.SUBSCRIPTION.ACCEPT_OFFER'
              : 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.SUBSCRIPTION.SWTICH_PLAN'
            ) | translate
          "
          class="plans-wrapper"
        ></app-plan-upsell-box>
      </div>
    </ng-container>
  </div>
</div>
