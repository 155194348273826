import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SynceeReviewService } from '../../service/syncee-review.service';
import {
  ChipListSelectorComponent,
  ChipListSelectorItem,
} from '../../../../shared/components/chip-list-selector/chip-list-selector.component';
import { SynceeReviewAnswer } from '../../model/syncee-review-answer';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SaveReviewReplyVo } from '../../model/save-review-reply.vo';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConditionLoadingDirective } from '../../../../shared/directives/condition-loading.directive';
import { FlexModule } from '@angular/flex-layout';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CustomDialogWrapperComponent } from '../../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-select-feedback',
  templateUrl: './select-feedback.component.html',
  styleUrls: ['./select-feedback.component.scss'],
  standalone: true,
  imports: [
    ConditionLoadingDirective,
    FlexModule,
    TranslateModule,
    ChipListSelectorComponent,
    NgIf,
    MatInputModule,
    FormsModule,
    CustomDialogWrapperComponent,
  ],
})
export class SelectFeedbackComponent implements OnInit, OnDestroy {
  @Input() rating: number;
  @Output() feedbackChange = new EventEmitter<SaveReviewReplyVo>();
  loading = false;
  chipListItems: ChipListSelectorItem[];
  selectedItems: ChipListSelectorItem[] = [];
  isCustomSelected = false;
  customAnswer: string;
  questionId: number;
  questionKey: string;

  answersBaseTranslateKey = 'SYNCEE_REVIEW_DIALOG.QUESTIONS.';
  private readonly unSubscribeAll: Subject<void>;
  constructor(private synceeReviewService: SynceeReviewService, private translateService: TranslateService) {
    this.unSubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getQuestionAndAnswers();
  }

  ngOnDestroy(): void {
    this.unSubscribeAll.next();
    this.unSubscribeAll.complete();
  }

  handleSelectionChange(newSelection: ChipListSelectorItem[]): void {
    this.selectedItems = newSelection;
    this.isCustomSelected = newSelection.some((item) => item.id === 'custom');
    if (!this.isCustomSelected) {
      this.customAnswer = null;
    }
    this.feedbackChange.emit(this.mapDataToReplyVo());
  }

  handleCustomAnswerChange(answer: string): void {
    this.feedbackChange.emit(this.mapDataToReplyVo());
  }

  private getQuestionAndAnswers(): void {
    this.loading = true;
    this.synceeReviewService
      .getQuestionsByScore(this.rating)
      .pipe(
        takeUntil(this.unSubscribeAll),
        tap((questions) => {
          this.questionId = questions[0].id;
          this.questionKey = questions[0].translateKey;
          this.mapAnswersToChipListItems(questions[0].translateKey, questions[0].answers, true);
        })
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  private mapAnswersToChipListItems(questionKey: string, answers: SynceeReviewAnswer[], hasCustom = true): void {
    this.chipListItems = answers.map((answer) => ({
      id: answer.id,
      title: this.getAnswerTranslation(questionKey, answer.answer),
    }));
    if (hasCustom) {
      this.chipListItems.push({ id: 'custom', title: this.getAnswerTranslation(questionKey, 'COMMENT') });
    }
  }

  private getAnswerTranslation(questionKey: string, answer: string): string {
    return this.translateService.instant(this.answersBaseTranslateKey + questionKey + '.ANSWERS.' + answer);
  }

  private mapDataToReplyVo(): SaveReviewReplyVo {
    return {
      customAnswer: this.customAnswer,
      answersIdList: this.getAnswerIdLIst(),
      score: this.rating,
      questionId: this.questionId,
    };
  }

  private getAnswerIdLIst(): number[] {
    return this.selectedItems.filter((item) => typeof item.id === 'number').map((item) => Number(item.id));
  }
}
