import { Injectable } from '@angular/core';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { map, filter, zip, BehaviorSubject, combineLatest } from 'rxjs';
import { SearchWidgetService } from 'app/service/search-widget/search-widget.service';
import { Store } from '@ngrx/store';
import { categorySelector } from 'app/store/category/category.selector';
import {
  categoryPreferenceSelector,
  typesIdsByNameSelector,
  userPreferencesSelector,
} from 'app/store/preferences/preferences.selector';
import { cloneDeep, isEqual, shuffle } from 'lodash';
import { WidgetType } from 'app/vo/widget';
import type { AppState } from '../../../../app.state';
import type { CategoryVo } from 'app/vo/category-vo';
import type { Widget } from 'app/vo/widget';
import type { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, tap } from 'rxjs/operators';
import { omitNullOrUndefinedArray } from '../../../../utils/operator/omit-null-or-undefined-array';
import { ShippingPreferencesService } from '../../../../service/preference/shipping-preferences.service';

@UntilDestroy()
@Injectable()
export class PreferredCategoryWidgetsService {
  public static MAX_WIDGETS = 5;

  public widgets$: Observable<Widget[]>;

  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private store: Store<AppState>,
    private searchWidgetService: SearchWidgetService,
    private shippingPreferencesService: ShippingPreferencesService
  ) {
    this.widgets$ = this.initWidgetsByPreferenceObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  private initWidgetsByPreferenceObservable(): Observable<Widget[]> {
    return combineLatest([
      this.store.select(categoryPreferenceSelector),
      this.shippingPreferencesService.hasPreferences$,
    ]).pipe(
      omitNullOrUndefinedArray(),
      untilDestroyed(this),
      tap(() => this._loading$.next(true)),
      filter(([_, hasShippingPreferences]: [string[], boolean]) => isEqual(hasShippingPreferences, true)),
      switchMap(() =>
        zip([
          this.getDisplayedCategoryIDs().pipe(omitNullOrUndefined()),
          this.searchWidgetService.getWidgets(WidgetType.CATEGORY_CARD).pipe(filter(Boolean)),
        ])
      ),
      map(([displayedCategoryIDs, allWidgets]) => {
        // keeping only those widgets which actually are preferred by the user
        const widgets: Widget[] = [];

        displayedCategoryIDs.forEach((categoryID: number): void => {
          const widget = allWidgets.find(
            (_widget: Widget): boolean =>
              this.searchWidgetService.mapWidgetFilterDataToMarketplaceFilter(_widget.filterData).category ===
              categoryID
          );

          if (!widget) {
            return;
          }

          widgets.push(widget);
        });

        return widgets;
      }),
      tap(() => this._loading$.next(false))
    );
  }

  private getDisplayedCategoryIDs(): Observable<number[]> {
    return zip([
      this.getPreferredFirstLevelCategories().pipe(omitNullOrUndefined()),
      this.store.select(categorySelector).pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([firstLevelPreferredCategories, allProductsCategory]) => {
        let displayedCategories: CategoryVo[];

        switch (firstLevelPreferredCategories.length) {
          case 0:
            displayedCategories = this.handleZeroPreferredCategoriesCase(allProductsCategory);
            break;
          case 1:
            displayedCategories = this.handleOnePreferredCategoryCase(firstLevelPreferredCategories[0]);
            break;
          default:
            displayedCategories = this.handleMultiplePreferredCategoriesCase(firstLevelPreferredCategories);
            break;
        }

        return displayedCategories.map((preferredCategory): number => preferredCategory.id);
      })
    );
  }

  private handleZeroPreferredCategoriesCase(allProductsCategory: CategoryVo): CategoryVo[] {
    return allProductsCategory.children.slice(0, PreferredCategoryWidgetsService.MAX_WIDGETS);
  }

  private handleOnePreferredCategoryCase(preferredCategory: CategoryVo): CategoryVo[] {
    return [preferredCategory, ...preferredCategory.children.slice(0, PreferredCategoryWidgetsService.MAX_WIDGETS - 1)];
  }

  private handleMultiplePreferredCategoriesCase(firstLevelPreferredCategories: CategoryVo[]): CategoryVo[] {
    // if the user has more than the max number of displayable categories, we return with the first 5 categories
    if (firstLevelPreferredCategories.length > PreferredCategoryWidgetsService.MAX_WIDGETS) {
      return firstLevelPreferredCategories.slice(0, PreferredCategoryWidgetsService.MAX_WIDGETS);
    }

    const preferedCategories: CategoryVo[] = [...firstLevelPreferredCategories];

    const numberOfRandomCategories = PreferredCategoryWidgetsService.MAX_WIDGETS - firstLevelPreferredCategories.length;

    /* we are storing those second level categories which haven't been added
    to the preferedCategories list in order ot prevent duplicates */
    const secondLevelCategories: { [key: number]: CategoryVo[] } = firstLevelPreferredCategories.reduce(
      (dict, category, i) => {
        dict[i] = shuffle(cloneDeep(category.children));
        return dict;
      },
      {}
    );

    let index = 0;

    let count = 0;

    while (count !== numberOfRandomCategories) {
      if (secondLevelCategories[index].length === 0) {
        continue;
      }

      const secondLevelCategory: CategoryVo = secondLevelCategories[index].pop();

      preferedCategories.push(secondLevelCategory);

      index = index === firstLevelPreferredCategories.length - 1 ? 0 : index + 1;

      count += 1;
    }

    return preferedCategories;
  }

  private getPreferredFirstLevelCategories(): Observable<CategoryVo[]> {
    return zip([
      this.getPreferredCategoryIDs().pipe(omitNullOrUndefined()),
      this.store.select(categorySelector).pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([preferredCategoryIDs, allProductsCategory]) => {
        return allProductsCategory.children.filter((category: CategoryVo): boolean => {
          return preferredCategoryIDs.includes(category.id);
        });
      })
    );
  }

  private getPreferredCategoryIDs(): Observable<number[]> {
    return zip([
      this.store.select(userPreferencesSelector).pipe(omitNullOrUndefined()),
      this.getSynceeCategoryPreferenceTypeID().pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([userPreferences, categoryPreferenceTypeID]) => {
        return userPreferences
          .filter((userPreference) => userPreference.preferenceTypeId === categoryPreferenceTypeID)
          .map((userPreference): number => +userPreference.preferenceValue);
      })
    );
  }

  private getSynceeCategoryPreferenceTypeID(): Observable<number> {
    return this.store.select(typesIdsByNameSelector).pipe(
      omitNullOrUndefined(),
      map((typesIDsByName): number => {
        return typesIDsByName?.SYNCEE_CATEGORY;
      })
    );
  }
}
