<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let item of menus">
    <button
      mat-menu-item
      *ngIf="!(item.id === 'source_settings' && task.sourceType !== 'UPLOAD')"
      [disabled]="item.id === 'delete' && task.isStillRunning"
      (click)="menuItemClicked.emit({ id: item.id, task: task })"
    >
      <mat-icon>{{ item.icon }}</mat-icon>
      <span>{{
        (task.isActive === '1' && item.id === 'active_inactive' ? 'TASKS.MENU_ITEMS_LABELS.SET_INACTIVE' : item.label)
          | translate
      }}</span>
    </button>
  </ng-container>
</mat-menu>
