import { Injectable } from '@angular/core';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { MySuppliersRestService } from './my-suppliers-rest.service';
import { MySuppliersCacheService } from './my-suppliers-cache.service';

import { BehaviorSubject, forkJoin, Observable, of, Subject, combineLatest, EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import type { Paginator } from 'app/main/manage-orders-new/model/vo/request/paginator';
import type { PageEvent } from '@angular/material/paginator';
import type { SupplierTask } from 'app/vo/supplier/supplier-task';
import type { EcomVO } from 'app/service/ecom/ecom.service';
import type { RetailerToCatalog } from '../model/retailer-to-catalog';
import { ManagedProductNumber } from '../model/managed-product-number';
import { WholesalePriceAndOrderNumber } from '../model/wholesale-price-and-order-number';
import { SpringPage } from '../../../../vo/pagination/spring-page';
import { SupplierWithBadgesAndAutoOrder } from '../../../../vo/supplier/supplier-with-badges-and-auto-order';
import { MySuppliersSlice } from '../model/cache-slice';
import { MySuppliersFilterService } from './my-suppliers-filter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApproveStatusParamEnum } from '../model/approve-status-param';
import { isEmpty } from 'lodash';

@UntilDestroy()
@Injectable()
export class MySuppliersPageService {
  public selectedEcom: Observable<EcomVO>;

  public isAdmin: Observable<boolean>;

  public pagination: Pick<Paginator, 'size' | 'from'> = {
    size: 10,
    from: 0,
  };

  public page$ = new BehaviorSubject<number>(0);

  private noResults$ = new BehaviorSubject<boolean>(false);

  private hasError$ = new BehaviorSubject<boolean>(false);

  public ltMd$ = this.screenManager.observeBreakpoint(BreakPoint.md).pipe(this.screenManager.stateMatchesOperator());

  public ids$ = new Subject<number[]>();

  public loading$ = new BehaviorSubject<boolean>(true);

  public metaDataLoading$ = new BehaviorSubject<boolean>(true);

  public content$ = new BehaviorSubject<RetailerToCatalog[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(
    private screenManager: ScreenManagerService,
    private mySuppliersCacheService: MySuppliersCacheService,
    private mySuppliersRestService: MySuppliersRestService,
    private mySuppliersFilterService: MySuppliersFilterService
  ) {
    this.initPageSubscription();
    this.initIDsSubscription();
  }

  public get noResults(): Observable<boolean> {
    return this.noResults$.asObservable();
  }

  public get hasError(): Observable<boolean> {
    return this.hasError$.asObservable();
  }

  public setPage(event: PageEvent): void {
    if (event.pageSize !== this.pagination.size) {
      this.pagination.size = event.pageSize;
    }

    if (event.pageIndex !== this.page$.getValue()) {
      this.pagination.from = this.pagination.size * event.pageIndex;
    }

    this.page$.next(event.pageIndex);
  }

  public next(): void {
    this.pagination.from += this.pagination.size;
    this.page$.next(this.page$.getValue() + 1);
  }

  public previous(): void {
    this.pagination.from -= this.pagination.size;
    this.page$.next(this.page$.getValue() - 1);
  }

  public setPageSize(event: number): void {
    if (event !== this.pagination.size) {
      this.pagination.size = event;
    }

    this.page$.next(0);
  }

  private initPageSubscription(): void {
    combineLatest([
      this.page$.pipe(),
      this.mySuppliersFilterService.approvedStatusFilter,
      this.mySuppliersFilterService.searchTerm,
    ])
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap(([page, approved, searchTerm]) => this.getAndHandleRetailerToCatalogPage(page, approved, searchTerm)),
        map((response) => response.content.map((catalog) => catalog.catalogId)),
        tap((catalogIDs) => this.ids$.next(catalogIDs)),
        this.mySuppliersCacheService.getUncachedEntityIDsOperator(MySuppliersSlice.CATALOGS),
        switchMap((taskIDs) => this.getAndCacheSupplierTasks(taskIDs)),
        map((tasks) => tasks.map((task) => task.userId)),
        this.mySuppliersCacheService.getUncachedEntityIDsOperator(MySuppliersSlice.SUPPLIERS),
        switchMap((userIDs) => this.getAndCacheSuppliers(userIDs)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (): void => {
          this.loading$.next(false);
        },
      });
  }

  private getAndHandleRetailerToCatalogPage(
    page: number,
    approved?: ApproveStatusParamEnum,
    supplierCompanyName?: string
  ): Observable<SpringPage<RetailerToCatalog>> {
    return this.mySuppliersRestService
      .getRetailerToCatalogFilteredPageable(page, this.pagination.size, approved, supplierCompanyName)
      .pipe(
        tap((response) => {
          this.totalCount$.next(response.totalElements);
          this.content$.next(response.content);
          this.hasError$.next(false);
          if (isEmpty(response.content)) {
            this.handleEmptyCatalogSearchResponse(supplierCompanyName, approved);
          }
        }),
        catchError(() => {
          this.hasError$.next(true);
          this.loading$.next(false);
          return EMPTY;
        })
      );
  }

  private handleEmptyCatalogSearchResponse(searchTerm: string, approvedFilter: ApproveStatusParamEnum): void {
    if (!!searchTerm || !!approvedFilter) {
      this.noResults$.next(true);
    } else {
      this.noResults$.next(false);
    }
  }

  private getAndCacheSupplierTasks(catalogIDds: number[]): Observable<SupplierTask[]> {
    if (!catalogIDds.length) {
      return of([]);
    }
    return this.mySuppliersRestService.getCatalogsByCatalogIDs(catalogIDds).pipe(
      tap((catalogs) => {
        this.mySuppliersCacheService.cacheCatalogsOneByOne(catalogs);
      })
    );
  }

  private getAndCacheSuppliers(supplierIDs: number[]): Observable<SupplierWithBadgesAndAutoOrder[]> {
    if (!supplierIDs.length) {
      return of([]);
    }
    return this.mySuppliersRestService
      .getSuppliersByUserIDs(supplierIDs)
      .pipe(tap((suppliers) => this.mySuppliersCacheService.cacheSuppliersOneByOne(suppliers)));
  }

  private initIDsSubscription(): void {
    this.ids$
      .pipe(
        tap(() => this.metaDataLoading$.next(true)),
        switchMap((ids) => this.getCatalogMetaDataByIDs(ids)),
        untilDestroyed(this)
      )
      .subscribe(({ managedProductNumbers, wholesalePricesAndOrderNumbers }): void => {
        if (!!managedProductNumbers.length) {
          this.mySuppliersCacheService.cacheManagedProductNumbersOneByOne(managedProductNumbers);
        }
        if (!!wholesalePricesAndOrderNumbers.length) {
          this.mySuppliersCacheService.cacheWholesalePricesAndOrderNumbersOneByOne(wholesalePricesAndOrderNumbers);
        }
        this.metaDataLoading$.next(false);
      });
  }

  private getCatalogMetaDataByIDs(catalogIDs: number[]): Observable<{
    managedProductNumbers: ManagedProductNumber[];
    wholesalePricesAndOrderNumbers: WholesalePriceAndOrderNumber[];
  }> {
    return forkJoin({
      idsForManagedProductNumbers: this.mySuppliersCacheService.getUncachedEntityIDs(
        MySuppliersSlice.MANAGED_PRODUCT_NUMBERS,
        catalogIDs
      ),
      idsForPricesAndOrderNumbers: this.mySuppliersCacheService.getUncachedEntityIDs(
        MySuppliersSlice.WHOLESALE_PRICES_AND_ORDER_NUMBERS,
        catalogIDs
      ),
    }).pipe(
      switchMap(({ idsForManagedProductNumbers, idsForPricesAndOrderNumbers }) =>
        forkJoin({
          managedProductNumbers: !idsForManagedProductNumbers.length
            ? of([])
            : this.mySuppliersRestService
                .getManagedProductNumberByCatalogIDs(idsForManagedProductNumbers)
                .pipe(
                  map((productCountItems) =>
                    this.handleCatalogsWithoutProductNumberResponseValue(idsForManagedProductNumbers, productCountItems)
                  )
                ),
          wholesalePricesAndOrderNumbers: !idsForPricesAndOrderNumbers.length
            ? of([])
            : this.mySuppliersRestService
                .getWholesalePriceAndOrderNumberByCatalogIDs(idsForPricesAndOrderNumbers)
                .pipe(
                  map((orderInfoItems) =>
                    this.handleCatalogsWithoutOrderInfoResponseValue(idsForPricesAndOrderNumbers, orderInfoItems)
                  )
                ),
        })
      )
    );
  }

  private handleCatalogsWithoutOrderInfoResponseValue(
    catalogIDs: number[],
    items: WholesalePriceAndOrderNumber[]
  ): WholesalePriceAndOrderNumber[] {
    const idsWithoutResponseValue = catalogIDs.filter((id) => !items.some((item) => item.catalogId === id));
    return items.concat(
      ...idsWithoutResponseValue.map(
        (id) => ({ catalogId: id, wholesalePriceSum: 0, lineItemCount: 0 } as WholesalePriceAndOrderNumber)
      )
    );
  }

  private handleCatalogsWithoutProductNumberResponseValue(
    catalogIDs: number[],
    items: ManagedProductNumber[]
  ): ManagedProductNumber[] {
    const idsWithoutResponseValue = catalogIDs.filter((id) => !items.some((item) => item.catalogId === id));
    return items.concat(...idsWithoutResponseValue.map((id) => ({ catalogId: id, count: 0 } as ManagedProductNumber)));
  }
}
