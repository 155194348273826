import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs/operators';
import { ProductSearchService } from '../product-search/product-search.service';

@Injectable({
  providedIn: 'root',
})
export class TrendingSearchService {
  private _trendingTermsCache$: Observable<string[]>;
  private _forceRefresh$ = new BehaviorSubject<void>(null);

  constructor(private productSearchService: ProductSearchService) {
    this.setupTrendingTermsCache();
  }

  private setupTrendingTermsCache(): void {
    this._trendingTermsCache$ = this._forceRefresh$.pipe(
      switchMap(() => this.productSearchService.getTrendingSearch()),
      shareReplay(1)
    );
  }

  getTrendingTerms(): Observable<string[]> {
    return this._trendingTermsCache$.pipe(distinctUntilChanged());
  }

  refreshTrendingSearch(): void {
    this._forceRefresh$.next();
  }
}
