import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Action, AuthorizationEcomService } from '../../service/authorization-ecom/authorization-ecom.service';
import { CatalogService, ImportListCatalogRespDto, ImportListItem } from 'app/service/catalog/catalog.service';
import { MarketplaceEcomService } from 'app/service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { CatalogFormVO } from 'app/vo/catalog-form-vo';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RetailerUpdateCatalogDialogComponent } from '../marketplace/retailer-catalogs/retailer-update-catalog-dialog.component';
import { DialogWithAnInputComponent } from '../../shared/components/dialogs/dialog-with-an-input/dialog-with-an-input.component';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseConfig } from '../../../@fuse/types';
import { Subset } from '../../utils/subset';
import { CatalogDetails } from './model/catalog-details';
import { RetailerCatalogType } from '../../vo/retailer-catalog/retailer-catalog-type';
import { DeleteModalVO } from '../../shared/components/dialogs/delete-modal/delete-modal-vo';
import { DeleteModalComponent } from '../../shared/components/dialogs/delete-modal/delete-modal.component';
import { SetupGuideService } from '../setup-guide/service/setup-guide.service';
import { SnippetEnum } from '../setup-guide/enums/snippet-enums';
import { Utils } from 'app/utils/utils';
import { ConfirmationDialogsService } from '../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { ImportListDto } from './model/import-list.dto';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { DeleteCatalogAction } from '../../store/rcatalogs/rcatalogs.action';

@Injectable({
  providedIn: 'root',
})
export class RetailerImportListService {
  private mutator = new Subject<void>();

  public mutator$: Observable<void> = this.mutator.asObservable();

  constructor(
    private marketplaceEcomService: MarketplaceEcomService,
    private authorizationService: AuthorizationEcomService,
    private dialog: MatDialog,
    private catalogService: CatalogService,
    private _translateService: TranslateService,
    private fuseConfigService: FuseConfigService,
    private setupGuideService: SetupGuideService,
    private confirmationDialogService: ConfirmationDialogsService,
    private store: Store<AppState>
  ) {}

  sync(item: ImportListItem, update?: boolean): Observable<boolean> {
    return combineLatest([
      this.marketplaceEcomService.getDomainByEcomId(item.catalog.ecom_id),
      this.authorizationService.isAdmin(),
    ]).pipe(
      switchMap(([ecom, isAdmin]) => {
        this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_SYNC_CATALOG);

        if (this.authorizationService.hasPermission(Action.SYNC_CATALOG_MARKETPLACE, ecom, isAdmin)) {
          if (update) {
            return this.updateProducts(item);
          } else {
            return this.uploadProducts(item);
          }
        } else {
          this.authorizationService.openSubscribeDialog('PLAN_UPSELL.SPECIAL_DESCRIPTION.SYNC');
        }
        return of(false);
      })
    );
  }

  private uploadProducts(item: ImportListItem): Observable<boolean> {
    item.isRunning = true;
    return this.catalogService.runRetailerCatalog(item.catalog.id).pipe(map(() => true));
  }

  updateProducts(item: ImportListItem): Observable<boolean> {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 1050) {
      dialogConfig.width = '90vw';
      dialogConfig.height = '90%';
      dialogConfig.maxWidth = '90vw';
    } else {
      dialogConfig.width = '60%';
    }

    dialogConfig.data = {
      catalogId: item.catalog.id,
      ecomType: item.catalog.ecom_type,
      isRCatalog: true,
    };
    dialogConfig.panelClass = 'custom-modal-container';
    const dialogRef = this.dialog.open(RetailerUpdateCatalogDialogComponent, dialogConfig);
    return dialogRef.afterClosed().pipe(
      switchMap((updateFields) => {
        if (Utils.isNullOrUndefined(updateFields) || updateFields.length < 1) {
          return of(false);
        }
        return this.catalogService.runRetailerCatalog(item.catalog.id, updateFields).pipe(map(() => true));
      })
    );
  }

  statusChanged(catalog: ImportListCatalogRespDto): Observable<boolean> {
    // don't ask...
    const newStatus = catalog.is_active == 1 ? 0 : 1;
    return this.catalogService.setStatus(catalog.id, newStatus);
  }

  renameCatalog(catalog: ImportListCatalogRespDto): Observable<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = catalog;
    dialogConfig.width = '500px';
    dialogConfig.panelClass = 'custom-modal-container';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogWithAnInputComponent, dialogConfig);

    dialogRef.componentInstance.text = this._translateService.instant('RETAILER_CATALOGS.RENAME_MODAL.TEXT_CATALOG');
    dialogRef.componentInstance.placeholder = this._translateService.instant(
      'RETAILER_CATALOGS.RENAME_MODAL.PLACEHOLDER'
    );
    dialogRef.componentInstance.btnCancel = this._translateService.instant('RETAILER_CATALOGS.RENAME_MODAL.CANCEL');
    dialogRef.componentInstance.btnOk = this._translateService.instant('RETAILER_CATALOGS.RENAME_MODAL.SAVE');

    return dialogRef.afterClosed().pipe(
      switchMap((newName) => {
        const catalogWitName = new CatalogFormVO();
        catalogWitName.id = catalog.id;
        catalogWitName.name = newName;
        catalogWitName.alibabaCatalog = 0;
        return this.catalogService.updateRCatalog('RetailerCatalogService/update', catalogWitName).pipe(map(() => {}));
      })
    );
  }

  deleteCatalog(catalogId: number): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.data = new DeleteModalVO(
      this._translateService.instant('IMPORT_LIST.RETAILER_IMPORT_LIST.DELETE_MODAL_CONTENT.TITLE'),
      this._translateService.instant('IMPORT_LIST.RETAILER_IMPORT_LIST.DELETE_MODAL_CONTENT.MESSAGE')
    );
    dialogConfig.panelClass = 'custom-modal-container';
    const dialogRef = this.dialog.open(DeleteModalComponent, dialogConfig);
    return dialogRef.afterClosed().pipe(
      switchMap((result) => {
        if (result) {
          return this.catalogService.deleteRetailerCatalog(catalogId).pipe(
            tap(() => this.store.dispatch(new DeleteCatalogAction(catalogId))),
            map(() => true)
          );
        }
        return of(false);
      })
    );
  }

  setTheme(config: Subset<FuseConfig>): void {
    this.fuseConfigService.config = config;
  }

  getCatalogType(catalogDetails: CatalogDetails): RetailerCatalogType {
    return this.catalogService.getRetailerCatalogType(catalogDetails);
  }

  public setDefaultImportList(ecomId: number, importListId: number): Observable<ImportListDto> {
    return this._translateService.stream('IMPORT_LIST.RETAILER_IMPORT_LIST.SET_AS_DEFAULT_MODAL_CONTENT').pipe(
      omitNullOrUndefined(),
      switchMap((translation) =>
        this.confirmationDialogService.confirm(translation['TITLE'], translation['MESSAGE']).pipe(
          filter((ok) => ok),
          switchMap(
            (): Observable<ImportListDto> =>
              this.catalogService.patchImportList(ecomId, importListId, { isDefault: true })
          )
        )
      )
    );
  }

  public mutateImportListItems(): void {
    this.mutator.next();
  }
}
