import { Component, OnDestroy, OnInit } from '@angular/core';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { Observable, Subject } from 'rxjs';
import { getCurrentUserIdSelector } from '../../../../../store/user/user.selector';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { SupplierGatewayService } from '../../../../../service/suppliers/supplier-gateway.service';
import { SupplierWithBadgesDto } from '../../../../../vo/supplier/supplier-with-badges-dto';
import { BreakPoint, ScreenManagerService } from '../../../../../service/screen-manager/screen-manager.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  supplier$: Observable<SupplierWithBadgesDto>;
  ltSm: boolean;
  private unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private store: Store<AppState>,
    private supplierGatewayService: SupplierGatewayService,
    private screenManagerService: ScreenManagerService
  ) {
    super(gettingStartedStepBaseService);
  }

  ngOnInit(): void {
    this.screenManagerService
      .observeIfBreakpointMatches(BreakPoint.sm)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((matches) => (this.ltSm = matches));

    this.supplier$ = this.store.select(getCurrentUserIdSelector).pipe(
      filter((userId) => !!userId),
      switchMap((userId) => this.supplierGatewayService.getSuppliers([userId])),
      map((suppliers) => suppliers[0])
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
