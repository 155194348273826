import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { ConnectionStateService } from './connection-state.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RestResponse } from '../rest/rest-response';
import { Utils } from '../../utils/utils';
import { FilterBox } from '../../main/filter/filter-box';
import { FilterItem } from '../../main/filter/filter-item/filter-item.component';
import {
  ExportTaskwizardUpdateResponse,
  Task,
  ScheduleTimeVO,
  TaskFile,
  TaskFilters,
  ExportMapping,
} from './export-taskwizard-update-response';
import { TaskWatcherService } from '../task/task-watcher.service';
import { isObject, isString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ExportTaskwizardUpdateService {
  public static EXISTING_TASK_DATA_URL = 'ExportTaskService/getTaskById';
  public static STORE_FILTER_DATA_URL = '';

  private _isUpdate = false;
  private _data: ExportTaskwizardUpdateResponse = null;
  private _storeFilterData = [];
  private _storeMappingData = [];
  private _rawData = null;
  private _filterIsInited = false;
  private _restDataSubject = new BehaviorSubject(false);
  private _taskFinishedSub: Subscription;
  private _isFilterSaved = false;

  public getRestDataFinished(): Observable<any> {
    return this._restDataSubject.asObservable();
  }

  get data(): ExportTaskwizardUpdateResponse {
    return this._data;
  }

  set data(value: ExportTaskwizardUpdateResponse) {
    this._data = value;
  }

  get storeFilterData(): any[] {
    return this._storeFilterData;
  }

  set storeFilterData(value: any[]) {
    this._storeFilterData = value;
  }

  set isUpdate(value: boolean) {
    this._isUpdate = value;
  }

  get isUpdate(): boolean {
    return this._isUpdate;
  }

  get filterIsInited(): boolean {
    return this._filterIsInited;
  }

  set filterIsInited(value: boolean) {
    this._filterIsInited = value;
  }

  get isFilterSaved(): boolean {
    return this._isFilterSaved;
  }

  public reset(): void {
    this.data = null;
    this.filterIsInited = false;
    this.isUpdate = false;
  }

  constructor(
    private restService: RestService,
    private ftpConnState: ConnectionStateService,
    private taskWatcher: TaskWatcherService
  ) {}

  init(taskId: number): Observable<ExportTaskwizardUpdateResponse> {
    const params = new HttpParams().set('taskId', taskId.toString());

    // OLD_TODO: export init
    return this.restService.get(ExportTaskwizardUpdateService.EXISTING_TASK_DATA_URL, params).pipe(
      map((res: RestResponse) => {
        const result = res.getFirstData();
        this._rawData = result;
        this.filterIsInited = false;

        const response = new ExportTaskwizardUpdateResponse();
        const taskResult = result.task;
        const task = new Task();
        task.taskId = taskResult.id;
        task.userId = taskResult.userId;
        task.ecomId = taskResult.ecomId;
        task.settings = taskResult.settings;

        task.name = taskResult.name;
        task.destination = taskResult.destination;
        task.pid = taskResult.pid;
        task.date = taskResult.date;
        task.rounding = taskResult.rounding;
        response.task = task;

        response.ecom = result.ecom;

        this.data = response;

        if (!Utils.isNullOrUndefined(result.files)) {
          const files = new TaskFile();
          const filesResult = result.files;

          files.settings = filesResult.settings;
          files.fileId = filesResult.fileId;
          files.taskId = filesResult.id;
          files.extension = filesResult.extension;
          files.destination = filesResult.destination;
          files.remotepath = filesResult.remotepath;
          files.nodeTree = filesResult.nodeTree;
          files.date = filesResult.date;
          response.files = files;
        } else {
          const files = new TaskFile();

          files.settings = [];
          files.fileId = null;
          files.taskId = null;
          files.extension = '';
          files.destination = '';
          files.remotepath = '';
          files.nodeTree = '[]';
          files.date = '';
          response.files = files;
        }

        if (!Utils.isNullOrUndefined(result.mapping)) {
          const mapping = new ExportMapping();

          for (const mapItem of result.mapping) {
            mapping.addNewMapItem(mapItem);
          }

          response.mapping = mapping;
        }

        if (!Utils.isNullOrUndefined(result.schedulers)) {
          const resultSchedulers = result.schedulers;
          response.scheduling = this.initScheduling(resultSchedulers);
        } else {
          response.scheduling = null;
        }

        this.isUpdate = true;

        this.initStoreDownloadEvent(task.taskId, task.ecomId);
        return response;
      })
    );
  }

  initStoreDownloadEvent(taskId, ecomId): void {
    this._taskFinishedSub = this.taskWatcher.getTaskRunFinishedObservable().subscribe((value) => {
      this.initFilter(taskId, ecomId).subscribe((value1) => {
        this._restDataSubject.next(true);
      });
    });

    this.taskWatcher.startETaskStoreDownloadWatcher();
    this.taskWatcher.addRunnedTaskToWatcher(taskId);
  }

  initScheduling(schedule): ScheduleTimeVO {
    const schedulers = new ScheduleTimeVO();
    schedulers.type = schedule.type;
    schedulers.dates = schedule.dates;
    this.data.scheduling = schedulers;
    return schedulers;
  }

  unsubscribeWatchers(): void {
    if (!Utils.isNullOrUndefined(this._taskFinishedSub)) {
      this._taskFinishedSub.unsubscribe();
    }
    this.taskWatcher.stopWatcher();
    this._restDataSubject.next(null);
  }

  initFilter(taskId, ecomId): Observable<any> {
    this._filterIsInited = false;
    return this.getFilterItems(ecomId, taskId).pipe(
      map((filter) => {
        const respTaskFilters = !Utils.isNullOrUndefined(this._rawData) ? this._rawData.taskFilters.filter : null;
        const filterFieldStructures = filter.getData();

        const taskFilters = new TaskFilters();

        taskFilters.fieldsStructure = filterFieldStructures;
        taskFilters.filterBoxes = [];
        taskFilters.generalFilter = [];
        taskFilters.operatorBoxes = [];
        taskFilters.filter = respTaskFilters;
        this._isFilterSaved = false;
        const generalFilterLabels = ['published_at', 'hasPicture', 'IMAGES'];
        // Todo: remove filterBoxes if app-filter removed
        if (respTaskFilters) {
          this._isFilterSaved = true;
          respTaskFilters.forEach((respFilterBox) => {
            let filterBox = null;
            switch (true) {
              case isString(respFilterBox):
                // taskFilters.operatorBoxes = respFilterBox;
                taskFilters.operatorBoxes.push(respFilterBox);
                break;
              case Array.isArray(respFilterBox):
                filterBox = new FilterBox();
                filterBox.filterItems = [];
                respFilterBox.forEach((filterItem) => {
                  switch (true) {
                    case isString(filterItem):
                      filterBox.operator = filterItem;
                      break;
                    case isObject(filterItem):
                      filterBox.filterItems.push(this.mapTaskFilterItem(filterItem, taskFilters.fieldsStructure));
                      break;
                  }
                });
                break;
              case isObject(respFilterBox):
                filterBox = new FilterBox();
                filterBox.filterItems = [this.mapTaskFilterItem(respFilterBox, taskFilters.fieldsStructure)];
            }
            if (!Utils.isNullOrUndefined(filterBox)) {
              taskFilters.filterBoxes.push(filterBox);
            }
          });
        }
        if (Utils.isNullOrUndefined(this.data)) {
          this.data = new ExportTaskwizardUpdateResponse();
        }
        this.data.taskFilters = taskFilters;
        this._filterIsInited = true;
        return true;
      })
    );
  }

  initStoreFields(ecomId: number, taskId: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('ecomId', ecomId.toString());
    httpParams = httpParams.set('taskId', taskId.toString());

    return this.restService.get('ExportTaskService/getSamples', httpParams).pipe(
      map((res: RestResponse) => {
        return res;
      })
    );
  }

  getExportWorker(domain: string): Observable<any> {
    return this.restService.get(`ExportTaskService/testWorker?domain=${domain}`).pipe(
      map((rest: RestResponse) => {
        return rest;
      })
    );
  }

  mapTaskFilterItem(obj, structure): FilterItem {
    const res = structure.find((fieldStructure) => {
      return fieldStructure.key === obj.key;
    });
    if (Utils.isNullOrUndefined(res)) {
      return <FilterItem>{ field: '', condition: obj.condition, values: obj.values, fieldStructures: structure };
    }
    const fieldName = res.key;
    return <FilterItem>{ field: fieldName, condition: obj.condition, values: obj.values, fieldStructures: structure };
  }

  getFilterItems(ecomId: number, taskId: number): Observable<any> {
    return this.restService
      .get(`ExportTaskService/getVendorsAndProductTypesForFiltering?ecomId=${ecomId}&taskId=${taskId}`)
      .pipe(
        map((rest: RestResponse) => {
          return rest;
        })
      );
  }
}
