import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { AuthenticationManagerService } from '../../main/authentication/authentication-manager.service';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';

@Injectable({
  providedIn: 'root',
})
export class UpsellService {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authenticationManagerService: AuthenticationManagerService
  ) {}

  verifyAccount(callback: () => void): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(take(1))
      .subscribe((loggedIn) =>
        loggedIn ? callback() : this.authenticationManagerService.emitOpenRegistrationDialog()
      );
  }

  upsellAction(): void {
    this.verifyAccount(() => this.navigateToCheckout());
  }

  getUpsellActionTextKey(): Observable<string> {
    return this.store
      .select(isAuthenticatedSelector)
      .pipe(
        map((loggedIn) => (loggedIn ? 'UPSELL.UPSELL_ACTION.BUTTON.REGISTERED' : 'UPSELL.UPSELL_ACTION.BUTTON.GUEST'))
      );
  }

  private navigateToCheckout(): void {
    this.router.navigate(['retailer', 'billing', 'checkout'], { queryParams: { upgrade: true } });
  }
}
