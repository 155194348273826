import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CountryNode } from '../../../utils/Countries';

@Component({
  selector: 'app-multiple-country-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './multiple-country-selector.component.html',
  styleUrls: ['./multiple-country-selector.component.scss'],
})
export class MultipleCountrySelectorComponent implements OnInit {
  @Input() placeholder: string;
  @Input() searchPlaceholder: string;
  @Input() error?: string;
  @Input() selectedCountries: CountryNode[];
  @Output() selectedCountriesChange = new EventEmitter<CountryNode[]>();
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formControl = new FormControl('');
  filteredCountries: Observable<CountryNode[]>;
  hasFocus = false;
  private readonly allCountries: Observable<CountryNode[]>;

  constructor(private countriesManagerService: CountriesManagerService) {
    this.allCountries = this.getCountriesOnLanguageChange();
    this.filteredCountries = this.formControl.valueChanges.pipe(
      startWith(''),
      withLatestFrom(this.allCountries),
      map(([value, countries]) => this.filterCountries(value, countries)),
      map((countries) => this.filterSelectedCountries(countries))
    );
  }

  ngOnInit(): void {}

  private getCountriesOnLanguageChange(): Observable<CountryNode[]> {
    return this.countriesManagerService.getCountriesWithTranslatedNames();
  }

  private filterCountries(value: string, allCountires: CountryNode[]): CountryNode[] {
    const filterValue = value.toLowerCase();
    return allCountires.filter((country) => country.name.toLowerCase().includes(filterValue));
  }

  private filterSelectedCountries(countries: CountryNode[]): CountryNode[] {
    return countries.filter((country) => !this.selectedCountries?.some((c) => country.code === c.code));
  }

  remove(country: CountryNode): void {
    const newSelected = this.selectedCountries.filter((item) => item.code !== country.code);
    this.selectedCountries = newSelected;
    this.selectedCountriesChange.emit(newSelected);
    this.formControl.setValue('');
    this.itemInput.nativeElement.value = '';
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const matchingCountryNode = this.countriesManagerService
      .getOnlyCountries()
      .find((node) => node.code === event.option.value);
    const newSelected = this.selectedCountries.concat(matchingCountryNode);
    this.selectedCountries = newSelected;
    this.selectedCountriesChange.emit(newSelected);
    this.formControl.setValue('');
    this.itemInput.nativeElement.value = '';
  }

  handleFocusIn(): void {
    this.hasFocus = true;
    this.formControl.setValue('');
  }

  get inputPlaceholder(): string {
    return this.hasFocus ? this.searchPlaceholder ?? this.placeholder : this.placeholder;
  }
}
