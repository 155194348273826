import { Action } from '@ngrx/store';
import { Preference } from '../../vo/Preferences/preference';
import { PreferenceType } from '../../vo/Preferences/preference-type';

export enum PreferencesActionTypes {
  GET_PREFERENCES_START = '[Preferences] Get Preferences Start',
  GET_PREFERENCES_SUCCESS = '[Preferences] Get Preferences Success',
  SAVE_PREFERENCES_START = '[Preferences] Save Preferences Start',
  SAVE_PREFERENCES_SUCCESS = '[Preferences] Save Preferences Success',
  DELETE_PREFERENCES_START = '[Preferences] Delete Preferences Start',
  DELETE_PREFERENCES_SUCCESS = '[Preferences] Delete Preferences Success',
  GET_PREFERENCE_TYPES_START = '[Preferences] Get Preference Types Start',
  GET_PREFERENCE_TYPES_SUCCESS = '[Preferences] Get Preference Types Success',
}

export class GetPreferencesStartAction implements Action {
  readonly type = PreferencesActionTypes.GET_PREFERENCES_START;

  constructor(public payload: number) {}
}

export class GetPreferencesSuccessAction implements Action {
  readonly type = PreferencesActionTypes.GET_PREFERENCES_SUCCESS;
  constructor(public payload: Preference[]) {}
}

export class SavePreferencesStartAction implements Action {
  readonly type = PreferencesActionTypes.SAVE_PREFERENCES_START;
  constructor(public payload: Preference[]) {}
}

export class SavePreferencesSuccessAction implements Action {
  readonly type = PreferencesActionTypes.SAVE_PREFERENCES_SUCCESS;
  constructor(public payload: Preference[]) {}
}

export class DeletePreferencesStartAction implements Action {
  readonly type = PreferencesActionTypes.DELETE_PREFERENCES_START;
  constructor(public payload: number[]) {}
}

export class DeletePreferencesSuccessAction implements Action {
  readonly type = PreferencesActionTypes.DELETE_PREFERENCES_SUCCESS;
  constructor(public payload: number[]) {}
}

export class GetPreferenceTypesStartAction implements Action {
  readonly type = PreferencesActionTypes.GET_PREFERENCE_TYPES_START;

  constructor() {}
}

export class GetPreferenceTypesSuccessAction implements Action {
  readonly type = PreferencesActionTypes.GET_PREFERENCE_TYPES_SUCCESS;
  constructor(public payload: PreferenceType[]) {}
}

export type PreferencesActionsUnion =
  | GetPreferencesSuccessAction
  | GetPreferencesStartAction
  | SavePreferencesStartAction
  | SavePreferencesSuccessAction
  | DeletePreferencesStartAction
  | DeletePreferencesSuccessAction
  | GetPreferenceTypesStartAction
  | GetPreferenceTypesSuccessAction;
