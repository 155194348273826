<div
  class="supplier-simple-item-container"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
  (click)="handleItemClicked()"
>
  <div fxFlex="0 0 auto" class="supplier-simple-image-container">
    <img src="{{ imageUrl }}" />
  </div>
  <div fxFlex="1 0 0" class="supplier-simple-info">
    <div class="supplier-simple-name">{{ name }}</div>
  </div>
</div>
