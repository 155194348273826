import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrencyState } from './currency.reducer';

const getCurrencyState = createFeatureSelector<CurrencyState>('currency');

export const selectedCurrenciesSelector = createSelector(
  getCurrencyState,
  (state: CurrencyState) => state.selectedCurrencies
);

export const retailerCurrencySelector = createSelector(
  getCurrencyState,
  (state: CurrencyState) => state.retailerCurrencies
);

export const selectedCurrencySelector = createSelector(
  getCurrencyState,
  (state: CurrencyState) => state.selectedCurrency
);
