<app-dialog-multi-wrapper
  [type]="'info'"
  [title]="data.titleKey | translate"
  [icon]="'chat_bubble_outline'"
  [iconFilled]="false"
  [iconWithCircle]="true"
  [hideConfirmButton]="true"
  [buttonsAlignment]="'end'"
>
  <div fxLayout="column" fxLayoutGap="28px">
    <div *ngIf="!!data.descriptionKey" class="text-center">
      {{ data.descriptionKey | translate }}
    </div>
    <div class="retailer-message-wrapper">"{{ data.message }}"</div>
  </div>
</app-dialog-multi-wrapper>
