import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const noop = (): any => undefined;
export let isDebugMode = !environment.production;

@Injectable()
export class ConsoleLoggerService implements LoggerService {
  get fine(): any {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get info(): any {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn(): any {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error(): any {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }
}
