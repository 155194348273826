import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PreferencesState } from './preferences.reducer';
import { PreferenceTypeRecord } from '../../service/preference/preference.store.service';
import { fromPairs, isEmpty } from 'lodash';
import { PreferenceNameEnum } from '../../vo/Preferences/preference-name.enum';
import { Utils } from '../../utils/utils';
import { PreferenceType } from '../../vo/Preferences/preference-type';
import { Preference } from '../../vo/Preferences/preference';

const getPreferenceState = createFeatureSelector<PreferencesState>('preferences');

export const userPreferencesSelector = createSelector(getPreferenceState, (state) => {
  if (!!state && !!state.preferences) {
    return state.preferences;
  }
});

export const preferenceTypesSelector = createSelector(getPreferenceState, (state) => {
  if (!!state && state.preferenceTypes) {
    return state.preferenceTypes;
  }
});

export const typesIdsByNameSelector = createSelector(getPreferenceState, (state): PreferenceTypeRecord => {
  if (!!state && !!state.preferenceTypes) {
    const nameIdPairs = state.preferenceTypes.map((type) => [type.name, type.id]);
    return fromPairs(nameIdPairs) as PreferenceTypeRecord;
  }
});

export const userLocationPreferenceSelector = createSelector(
  getPreferenceState,
  (state) =>
    !!state &&
    !!state.preferenceTypes &&
    !!state.preferences &&
    state.preferences.find(
      (preference) =>
        preference.preferenceTypeId ===
        state.preferenceTypes.find((type) => type.name === PreferenceNameEnum.MY_LOCATION)?.id
    )?.preferenceValue
);

const getPreferencesByPreferenceName = (preferenceName: PreferenceNameEnum, state: PreferencesState): string[] => {
  switch (true) {
    case Utils.isNullOrUndefined(state):
    case Utils.isNullOrUndefined(state.preferenceTypes):
    case Utils.isNullOrUndefined(state.preferences):
      return null;
    case isEmpty(state.preferenceTypes):
    case isEmpty(state.preferences):
      return [];
    default: {
      const preferenceType = state.preferenceTypes?.find(
        (item: PreferenceType): boolean => item.name === preferenceName
      );

      if (Utils.isNullOrUndefined(preferenceType)) {
        return [];
      }

      return state.preferences
        .filter((preference: Preference): boolean => preference.preferenceTypeId === preferenceType.id)
        .map((preference: Preference) => preference.preferenceValue);
    }
  }
};

export const shipsToPreferenceSelector = createSelector(getPreferenceState, (state) => {
  return getPreferencesByPreferenceName(PreferenceNameEnum.SHIPS_TO_LOCATION, state);
});

export const shipsFromPreferenceSelector = createSelector(getPreferenceState, (state) => {
  return getPreferencesByPreferenceName(PreferenceNameEnum.SHIPS_FROM_LOCATION, state);
});

export const categoryPreferenceSelector = createSelector(getPreferenceState, (state) => {
  return getPreferencesByPreferenceName(PreferenceNameEnum.SYNCEE_CATEGORY, state);
});
