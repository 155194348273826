const prefix = 'supplier/';
const storefrontSettings = 'storefront-settings';

export type StorefrontSettingsNavigation =
  (typeof StorefrontSettingsNavigation)[keyof typeof StorefrontSettingsNavigation];
export const StorefrontSettingsNavigation = {
  DEFAULT: `${prefix}${storefrontSettings}`,
  BRANDING: `${prefix}${storefrontSettings}/branding`,
  SHIPPING: `${prefix}${storefrontSettings}/shipping`,
  INFORMATION_FOR_RETAILERS: `${prefix}${storefrontSettings}/information-for-retailers`,
  RETURN_AND_REFUND: `${prefix}${storefrontSettings}/return-and-refund`,
  COMPANY_BILLING: `${prefix}${storefrontSettings}/company-billing`,
  PAYMENTS: `${prefix}${storefrontSettings}/payments`,
} as const;
