<div>
  <form #form="ngForm">
    <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
      <div fxFlex="1 0 0">
        <mat-checkbox name="isFirstRowHeader" [(ngModel)]="isFirstRowHeader">{{
          'TASKWIZARD.ADDFILES.FILE_SETTINGS.HAS_HEADER' | translate
        }}</mat-checkbox>
      </div>
      <mat-form-field fxFlex="1 0 0" appearance="fill" class="input-without-padding">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.START_ROW_PLACEHOLDER' | translate }}</mat-label>
        <input matInput name="startRowNum" [(ngModel)]="startRowNum" required />
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.START_ROW' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 0" appearance="fill" class="input-without-padding">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.DELIMETER' | translate }}</mat-label>
        <mat-select name="selDelimiter" [(ngModel)]="selDelimiter" required>
          <mat-option *ngFor="let del of delimiters" [value]="del.code">{{ del.name }} </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.DELIMETER_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 0" appearance="fill" class="input-without-padding">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.ENCLOSURE_PLACEHOLDER' | translate }}</mat-label>
        <mat-select name="selEnclosure" [(ngModel)]="selEnclosure" required>
          <mat-option *ngFor="let enclosure of enclosures" [value]="enclosure.code">{{ enclosure.name }} </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.ENCLOSURE' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 0" appearance="fill" class="input-without-padding">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.END_OF_LINE' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.END_OF_LINE' | translate }}"
          name="selEndOfLine"
          [(ngModel)]="selEndOfLine"
          required
        >
          <mat-option *ngFor="let endofline of endoflines" [value]="endofline.code">{{ endofline.name }} </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.END_OF_LINE_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 auto" appearance="fill" class="input-without-padding">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.CHARACTER_PLACEHOLDER' | translate }}</mat-label>
        <mat-select #encoding="ngModel" id="encodingSelect" name="encodingSelect" [(ngModel)]="encodingSelect" required>
          <mat-option *ngFor="let encoding of characterEncodings" [value]="encoding.name">
            {{ encoding.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.SELECTING' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
