<div class="search-panel-container">
  <div class="search-tab-container">
    <h5 class="selector-tab" (click)="tabClick('chats')" [class.active-tab]="activeTab === 'chats'">
      {{ 'CHAT.CHATS' | translate }}
    </h5>
    <h5 class="selector-tab" (click)="tabClick('suppliers')" [class.active-tab]="activeTab === 'suppliers'">
      {{ 'CHAT.SUPPLIERS' | translate }}
    </h5>
  </div>
  <ng-container *ngIf="activeTab === 'suppliers' && (notSearchedYet || lastSearchTerm === '')">
    <div fxFlex="1 0 0" class="user-search-label centered">{{ 'CHAT.SEARCH_PANEL.SEARCH_LABEL' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="activeTab === 'suppliers'; else channelItems">
    <ng-container *ngIf="!suppliersLoading; else loadingSpinner">
      <ng-container *ngIf="!!users.length; else noResults">
        <div class="user-list-wrapper">
          <div overflowDirective fxLayout="column" fxFlex="1 0 0">
            <ng-container>
              <ng-container *ngFor="let user of users">
                <app-chat-user-item
                  [user]="user"
                  [selected]="!!selectedUser && selectedUser.id === user.id"
                  (selectUser)="supplierClickHandler($event)"
                ></app-chat-user-item>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #channelItems>
  <ng-container *ngIf="!channelsLoading; else loadingSpinner">
    <ng-container *ngIf="channels && !!channels.length; else noMessages">
      <div class="user-list-wrapper">
        <div overflowDirective fxLayout="column" fxFlex="1 0 0">
          <ng-container *ngFor="let channel of channels">
            <app-chat-channel-item
              [channel]="channel"
              [selected]="selectedChannel.id === channel.id"
              (selectChannel)="channelClicked.emit(channel)"
            ></app-chat-channel-item>
          </ng-container>
          <ng-container *ngIf="hasLoadMore">
            <div fxLayout="row" class="py-12" fxLayoutAlign="center start">
              <button (click)="loadMoreClicked.emit()" fxFlex="0 0 80%" mat-button class="mat-flat-white-button">
                {{ 'CHAT.LOAD_MORE' | translate }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loadingSpinner>
  <div fxFlex="1 0 0" class="chat-spinner-wrapper centered">
    <mat-spinner [diameter]="48"> </mat-spinner>
  </div>
</ng-template>

<ng-template #noResults>
  <ng-container *ngIf="!!lastSearchTerm.length">
    <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="8px" class="centered no-results">
      <div class="h4">{{ 'CHAT.SEARCH_PANEL.NO_RESULTS' | translate }}</div>
      <div>
        {{ 'CHAT.SEARCH_PANEL.TRY_ANOTHER_WAY' | translate }}
        <a href="#" (click)="navigateToFindSuppliers()">{{ 'CHAT.SEARCH_PANEL.NEW_SUPPLIERS' | translate }}</a>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noMessages>
  <ng-container *ngIf="!lastSearchTerm; else noResults">
    <div fxFlex="1 0 0" class="user-search-label centered">
      {{ 'CHAT.NO_MESSAGES' | translate }}
    </div>
  </ng-container>
</ng-template>
