<div id="onboard" [class.elevated]="elevated" *ngIf="!!show && !!onboardService.selectedOnboard">
  <ng-container>
    <button mat-button class="syncee-blue-button onboard-button custom-elevation-z1" (click)="openDialog()">
      <mat-icon class="s-20">flag</mat-icon>
      {{
        !!(isRetailer | async)
          ? ('ONBOARD.ONBOARD_BUTTON_LABEL.EARN_CREDIT' | translate : { amount })
          : ('ONBOARD.ONBOARD_BUTTON_LABEL.SETUP_GUIDE' | translate)
      }}
    </button>
  </ng-container>
</div>
