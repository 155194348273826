import { Injectable } from '@angular/core';
import { Dimensions } from '../../utils/Constants';

@Injectable({
  providedIn: 'root',
})
export class DimSettingsService {
  dimensions = [];
  selectedDimensionCode: any;

  constructor() {
    this.dimensions = Object.keys(Dimensions).map((key) => {
      return { code: key, name: Dimensions[key] };
    });
  }

  getDimUnits(): any[] {
    return this.dimensions;
  }

  setDimensionCode(dim: any): void {
    this.selectedDimensionCode = dim;
  }

  getSeletectedDimCode(): any {
    return this.selectedDimensionCode;
  }
}
