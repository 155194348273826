<div class="multiline-container">
  <form #mlForm="ngForm" fxLayout="column" fxLayoutAlign="start">
    <!--    <div class="border-title">-->
    <!--      {{ title }}-->
    <!--    </div>-->
    <mat-list #matListChip class="chip-multiline-container">
      <mat-list-item *ngFor="let chipItem of chipRows; let i = index">
        <app-droppable-input
          id="{{ title }}_{{ i }}"
          [title]="title"
          [index]="i"
          [multiline]="true"
          (newRow)="newRow(i)"
          (removeRow)="removeRow(i)"
          (change)="changedMultiLineInput()"
          [chips]="chipItem.chips"
          [settings]="chipItem.settings"
          [operations]="chipItem.operations"
        ></app-droppable-input>
      </mat-list-item>
    </mat-list>
    <button mat-icon-button (click)="onInputSettingsClick()" fxFlexAlign="end" type="button">
      <mat-icon
        class="settings s-20"
        matRipple
        [ngStyle]="{ color: hasSettings ? 'var(--app-syncee-primary-700)' : 'var(--app-syncee-grey-500)' }"
        style="user-select: none; cursor: pointer"
        >settings
      </mat-icon>
    </button>
  </form>
</div>
