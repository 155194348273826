<ng-container *ngIf="!!snippetToShow">
  <ng-container *ngIf="snippetToShow | async as marketplaceSnippetAsync">
    <div fxLayout="column" fxLayoutGap="32px">
      <ng-container [ngSwitch]="isRetailer | async">
        <div *ngSwitchCase="true">
          {{ 'SETUP_GUIDE.RETAILER_USE_QUICK_GUIDE' | translate }}
        </div>

        <div *ngSwitchCase="false">
          {{ 'SETUP_GUIDE.SUPPLIER_USE_QUICK_GUIDE' | translate }}
        </div>
      </ng-container>

      <app-setup-guide-indicator
        [maxItems]="marketplaceSnippetAsync.snippet.steps.length"
        [completedItems]="marketplaceSnippetAsync.completedSteps.length"
      ></app-setup-guide-indicator>

      <app-setup-guide-item-list [snippet]="marketplaceSnippetAsync"></app-setup-guide-item-list>

      <app-setup-guide-bottom-bar
        [snippet]="marketplaceSnippetAsync"
        (dismissed)="handleDismiss()"
      ></app-setup-guide-bottom-bar>
    </div>
  </ng-container>
</ng-container>
