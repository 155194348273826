import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../main/notification/notification.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { DialogMultiWrapperComponent } from '../../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { DialogMultiType } from '../../../../vo/dialog-multi';

@Component({
  selector: 'app-dialog-with-an-input',
  templateUrl: './dialog-with-an-input.component.html',
  styleUrls: ['./dialog-with-an-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatDialogModule, MatInputModule, FormsModule, DialogMultiWrapperComponent],
})
export class DialogWithAnInputComponent implements OnInit {
  text = 'Enter some text';
  inputValue: string;
  placeholder = 'Type something...';
  btnCancel = 'Cancel';
  btnOk = 'Save';
  response: string;
  public dialogType: DialogMultiType = 'info';

  constructor(
    public dialogRef: MatDialogRef<DialogWithAnInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWithAnInputData,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.initDialog();
  }
  initDialog(): void {
    if (this.data) {
      const { text, btnOk, btnCancel, placeholder, response } = this.data;
      this.text = text ?? this.text;
      this.placeholder = placeholder ?? this.placeholder;
      this.btnOk = btnOk ?? this.btnOk;
      this.btnCancel = btnCancel ?? this.btnCancel;
      this.response = response ?? this.response;
    }
  }

  onSubmit(): void {
    if (this.response.length > 0) {
      this.dialogRef.close(this.response);
    } else {
      this.notificationService.warning('Fill the name field!');
    }
  }
}

export interface DialogWithAnInputData {
  text?: string;
  placeholder?: string;
  btnCancel?: string;
  btnOk?: string;
  response?: string;
}
