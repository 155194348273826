<div fxLayoutAlign="center center">
  <ng-container [ngSwitch]="variant" *conditionLoading="supplierPublicInformationService.initialized">
    <ng-container *ngSwitchCase="'DEFAULT'">
      <app-supplier-public-information-default
        class="w-100p"
        [hasSaveButton]="hasSaveButton"
      ></app-supplier-public-information-default>
    </ng-container>
    <ng-container *ngSwitchCase="'WIDE_SCREEN'">
      <app-supplier-public-information-wide-screen
        [hasSaveButton]="hasSaveButton"
        fxFlex="1 0 0"
        class="w-100p"
      ></app-supplier-public-information-wide-screen>
    </ng-container>
  </ng-container>
</div>
