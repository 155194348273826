import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeaturedProductService {
  constructor(private restService: RestService) {}

  public getFeaturedProductBlocks(): Observable<any> {
    return this.restService.get('ProductFeaturedService/getFeaturedProductBlocks').pipe(map((res) => res.getData()));
  }

  public getFeaturedProducts(ecomType: string): Observable<any> {
    return this.restService
      .get('ProductFeaturedService/getFeaturedProducts?ecomType=' + ecomType)
      .pipe(map((res) => res.getData()));
  }

  public editFeaturedProductBlock(body: FeaturedProductBlock): Observable<any> {
    return this.restService
      .put('ProductFeaturedService/editFeaturedProductBlock', body)
      .pipe(map((res) => res.getData()));
  }

  public saveFeaturedProductBlock(body: FeaturedProductBlock): Observable<any> {
    return this.restService
      .post(`ProductFeaturedService/saveFeaturedProductBlock`, body)
      .pipe(map((res) => res.getData()));
  }
}

export interface FeaturedProductBlock {
  id?: number;
  title: string;
  description: string;
  order: number;
}
