import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { getUserRoleSelector } from 'app/store/user/user.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { RolesEnum } from 'app/vo/roles/roles';
import { switchMap } from 'rxjs';
import { ONBOARD_EARN_CREDIT_AMOUNT } from '../../../onboard/constant';
import { OnboardService } from '../../../onboard/onboard.service';
import { ToolbarCallToActionService } from './toolbar-call-to-action.service';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ToolbarCallToActionContentService {
  constructor(
    private translateService: TranslateService,
    private onboardService: OnboardService,
    private toolbarCallToActionService: ToolbarCallToActionService,
    private store: Store<AppState>
  ) {}

  public addEarnCreditCallToAction(): void {
    this.translateService
      .get('ONBOARD.EARN_CREDIT_ALERT.CALL_TO_ACTION', { amount: ONBOARD_EARN_CREDIT_AMOUNT })
      .pipe(untilDestroyed(this))
      .subscribe((translation) => {
        this.toolbarCallToActionService.addActionContent({
          type: 'earnCredit',
          title: translation,
          theme: 'info',
          position: 'top',
          priority: 1,
          action: {
            type: 'callback',
            callback: () => this.onboardService.openOnboardDialog(),
          },
        });
      });
  }

  public addSetupGuideCallToAction(): void {
    this.store
      .select(getUserRoleSelector)
      .pipe(
        omitNullOrUndefined(),
        switchMap((role) =>
          this.translateService
            .stream(
              role === RolesEnum.RETAILER ? 'ONBOARD.SETUP_GUIDE_CTA.RETAILER' : 'ONBOARD.SETUP_GUIDE_CTA.SUPPLIER'
            )
            .pipe(omitNullOrUndefined())
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (title) => {
          this.toolbarCallToActionService.addActionContent({
            type: 'setupGuide',
            title: title,
            theme: 'warning',
            position: 'below-header',
            priority: 1,
            action: {
              type: 'callback',
              callback: () => this.onboardService.openOnboardDialog(),
            },
          });
        },
      });
  }
}
