import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import { ParsedAuthData } from './authentication.service';

export class AuthenticationStorage {
  public static readonly STORAGE_KEY_AUTH_DATA = 'auth_data';
  public static readonly STORAGE_KEY_USER_AUTH_DATA = 'user_auth_data';

  private static _cacheAuthData: ParsedAuthData = null;
  private static _cacheLoggedUserAuthData: ParsedAuthData = null;

  private static _cacheRawAuthData: string = null;
  private static _cacheRawLoggedUserAuthData: string = null;

  constructor() {}

  public static storeData(data: ParsedAuthData): void {
    this.clearAuthDataCache();
    localStorage.setItem(AuthenticationStorage.STORAGE_KEY_AUTH_DATA, this.encodeData(data));
  }

  public static storeLoggedUserData(data: ParsedAuthData): void {
    this.clearLoggedUserAuthDataCache();
    localStorage.setItem(AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA, this.encodeData(data));
  }

  public static getAuthData(): ParsedAuthData {
    if (
      !this._cacheAuthData ||
      this._cacheRawAuthData !== localStorage.getItem(AuthenticationStorage.STORAGE_KEY_AUTH_DATA)
    ) {
      this._cacheAuthData = this.decodeData(AuthenticationStorage.STORAGE_KEY_AUTH_DATA);
      this._cacheRawAuthData = localStorage.getItem(AuthenticationStorage.STORAGE_KEY_AUTH_DATA);
    }

    return this._cacheAuthData;
  }

  public static getLoggedUserAuthData(): ParsedAuthData {
    if (
      !this._cacheLoggedUserAuthData ||
      this._cacheRawLoggedUserAuthData !== localStorage.getItem(AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA)
    ) {
      this._cacheLoggedUserAuthData = this.decodeData(AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA);
      this._cacheRawLoggedUserAuthData = localStorage.getItem(AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA);
    }

    return this._cacheLoggedUserAuthData;
  }

  private static encodeData(data: ParsedAuthData): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), environment.keyTokens).toString();
  }

  private static decodeData(key: AuthStoreDataKey): ParsedAuthData {
    return localStorage.getItem(key)
      ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem(key), environment.keyTokens).toString(CryptoJS.enc.Utf8))
      : null;
  }

  public static clear(): void {
    this.clearCache();
    localStorage.removeItem(AuthenticationStorage.STORAGE_KEY_AUTH_DATA);
    localStorage.removeItem(AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA);
  }

  public static clearLoggedUser(): void {
    this.clearLoggedUserAuthDataCache();
    localStorage.removeItem(AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA);
  }

  public static clearCache(): void {
    this.clearAuthDataCache();
    this.clearLoggedUserAuthDataCache();
  }

  public static clearAuthDataCache(): void {
    this._cacheAuthData = null;
    this._cacheRawAuthData = null;
  }

  public static clearLoggedUserAuthDataCache(): void {
    this._cacheLoggedUserAuthData = null;
    this._cacheRawLoggedUserAuthData = null;
  }
}

type AuthStoreDataKey =
  | typeof AuthenticationStorage.STORAGE_KEY_AUTH_DATA
  | typeof AuthenticationStorage.STORAGE_KEY_USER_AUTH_DATA;
