<ng-container *ngIf="snippet | async as snippetAsync">
  <ng-container *ngIf="(role$ | async) === RolesEnum.RETAILER && !(allStepsCompleted | async)">
    <app-info-strip [content]="'ONBOARD.EARN_CREDIT_ALERT.DIALOG' | translate : { amount }"></app-info-strip>
  </ng-container>
  <app-custom-dialog-wrapper [hideCloseBtnDesktop]="true">
    <div class="onboard-dialog" fxLayout="column">
      <div class="content" overflowDirective fxFlex="0 0 auto" fxFlex.lt-md="1 0 0">
        <div fxLayout="column" fxLayoutGap="32px">
          <h1 class="mt-0 mb-8">{{ 'ONBOARD_DIALOG.TITLE' | translate }}</h1>
          <div>{{ 'ONBOARD_DIALOG.SUBTITLE' | translate }}</div>
          <app-setup-guide-indicator
            [completedItems]="snippetAsync.completedSteps.length"
            [maxItems]="snippetAsync.snippet.steps.length"
          ></app-setup-guide-indicator>
          <app-setup-guide-item-list
            [snippet]="snippetAsync"
            (handleCloseDialog)="onDismissed()"
          ></app-setup-guide-item-list>
        </div>
      </div>
      <app-setup-guide-bottom-bar
        fxFlex="0 0 auto"
        (dismissed)="onDismissed()"
        [snippet]="snippetAsync"
        class="w-100p action-bar"
      ></app-setup-guide-bottom-bar></div
  ></app-custom-dialog-wrapper>
</ng-container>
