import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag-selector-checkbox',
  templateUrl: './tag-selector-item-checkbox.component.html',
  styleUrls: ['./tag-selector-item-checkbox.component.scss'],
})
export class TagSelectorItemCheckboxComponent {
  @Input() checked = false;
}
