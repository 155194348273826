<div class="w-100p h-100-p" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
  <app-sub-steps-stepper
    #stepperComponent
    [steps]="steps"
    [defaultStep]="currentStepIndex"
    (stepChange)="handleSubStepChange($event)"
  ></app-sub-steps-stepper>
  <div
    fxFlex="1 0 0"
    class="w-100p personalization-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
  >
    <ng-container>
      <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
        <app-step-title-description
          class="mw-660"
          [title]="stepTitles[currentStepIndex].title | translate"
          [description]="stepTitles[currentStepIndex].description | translate"
        ></app-step-title-description>
        <ng-container [ngSwitch]="currentStepIndex">
          <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
            <ng-container *ngSwitchCase="0">
              <app-supplier-payment-account-billing
                class="w-100p"
                #billingInfoForm
                [style.max-width]="contentMaxWidth"
                [paymentAccount]="paymentAccount"
                *conditionLoading="!paymentAccountLoading"
              ></app-supplier-payment-account-billing>
            </ng-container>
            <ng-container *ngSwitchCase="1">
              <div [style.max-width]="contentMaxWidth" class="w-100p">
                <div class="w-100p" fxLayout="column" fxLayoutGap="32px" *ngIf="!paymentAccountLoading">
                  <mat-card fxFlex="1 0 0" class="p-24">
                    <app-supplier-currency-selector
                      (currencyChange)="currencyOnChange($event)"
                    ></app-supplier-currency-selector>
                  </mat-card>

                  <div fxFlex="1 0 0">
                    <app-connect-payment-provider
                      flexDirection="row"
                      [paymentAccount]="paymentAccount"
                      (connectStatusChanged)="handleConnectStatusChanged($event)"
                    ></app-connect-payment-provider>
                    <app-alert-multi
                      *ngIf="paymentIsMissing"
                      [type]="'danger'"
                      [messages]="['Please connect a payment provider']"
                    >
                    </app-alert-multi>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <app-step-navigation-buttons (prevClick)="prevClicked()" (nextClick)="nextClicked()"> </app-step-navigation-buttons>
  </div>
</div>
