<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px" class="m-24">
  <div class="icon-container syncee-primary-50-bg" fxLayoutAlign="center center">
    <mat-icon class="syncee-primary-700-fg">error_outline</mat-icon>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
    <div class="h5">{{ 'AI_CHAT.LIMIT_REACHED.TITLE' | translate }}</div>
    <div class="typography-body-2">{{ 'AI_CHAT.LIMIT_REACHED.DESCRIPTION' | translate }}</div>
  </div>
  <div>
    <button mat-flat-button color="primary" (click)="upsellService.upsellAction()">
      {{ 'AI_CHAT.LIMIT_REACHED.BUTTON' | translate }}
    </button>
  </div>
</div>
