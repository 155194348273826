import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  LanguageActionTypes,
  UserLanguageChangeStartAction,
  UserLanguageChangeSuccessAction,
  GuestLanguageChangeSuccessAction,
  GuestLanguageChangeStartAction,
  LanguageChangeStart,
} from './language.actions';
import { map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { NotificationService } from '../../main/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { getSelectedLanguageCode } from './language.selector';
import { LanguageService } from 'app/service/language/language.service';

@Injectable()
export class LanguageEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private _languageService: LanguageService
  ) {}

  StartLanguageChangeEffect: Observable<UserLanguageChangeStartAction | GuestLanguageChangeStartAction> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageActionTypes.LANGUAGE_CHANGE_START),
        switchMap((action: LanguageChangeStart) =>
          this.store.select(getSelectedLanguageCode).pipe(
            take(1),
            switchMap((storeLanguage) => this.dispatchIfLanguageChanged(storeLanguage, action))
          )
        )
      )
  );

  SetSelectedLanguageForUserEffect: Observable<UserLanguageChangeSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActionTypes.USER_LANGUAGE_CHANGE_START),
      map((action: UserLanguageChangeStartAction) => new UserLanguageChangeSuccessAction(action.payload))
    )
  );

  ChangeSelectedLanguageForGuestEffect: Observable<GuestLanguageChangeSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActionTypes.GUEST_LANGUAGE_CHANGE_START),
      map((action: GuestLanguageChangeStartAction) => new GuestLanguageChangeSuccessAction(action.payload))
    )
  );

  SetSelectedLanguageForGuestEffect: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageActionTypes.GUEST_LANGUAGE_CHANGE_SUCCESS, LanguageActionTypes.USER_LANGUAGE_CHANGE_SUCCESS),
        map((action: GuestLanguageChangeSuccessAction) => {
          this._languageService.cachePrevAppLanguageFromStorage();
          this.updateLocalStorageLanguage(action.payload.countryCode);
          if (action.payload.withNotification) {
            this.changeTranslationWithNotification(action.payload.countryCode);
          } else {
            this.translateService.use(action.payload.countryCode);
          }
        })
      ),
    { dispatch: false }
  );

  private dispatchIfLanguageChanged(
    storeLanguage: string,
    action: LanguageChangeStart
  ): Observable<UserLanguageChangeStartAction | GuestLanguageChangeStartAction> {
    if (storeLanguage !== action.payload.countryCode) {
      return this.store.select(isAuthenticatedSelector).pipe(
        take(1),
        map((isAuthenticated) => {
          if (isAuthenticated) {
            return new UserLanguageChangeStartAction(action.payload);
          } else {
            return new GuestLanguageChangeStartAction(action.payload);
          }
        })
      );
    }
    return of();
  }

  private updateLocalStorageLanguage(languageCode: string): void {
    LanguageService.setLanguageToStorage(languageCode);
  }

  private changeTranslationWithNotification(languageCode: string): void {
    this.translateService
      .use(languageCode)
      .subscribe(() => this.notificationService.success('Language change successful!'));
  }
}
