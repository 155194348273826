import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformCardComponent } from './platform-card/platform-card.component';
import { PlatformListComponent } from './platform-list/platform-list.component';
import { PlatformTypeToNamePipe } from '../../../shared/pipes/platform-type-to-name.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PlatformDialogComponent } from './platform-dialog/platform-dialog.component';
import { PlatformLogoComponent } from '../../../shared/components/platform-logo/platform-logo.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HelpBoxComponent } from '../../../shared/components/help-box/help-box.component';
import { FlexModule } from '@angular/flex-layout';
import { PlatformBadgeDirective } from '../../../shared/directives/platform-badge.directive';
import { PlatformDialogActionsComponent } from './platform-dialog/platform-dialog-actions/platform-dialog-actions.component';
import { SynceeAppDialogActionsComponent } from './platform-dialog/syncee-app-dialog-actions/syncee-app-dialog-actions.component';
import { RouterLink } from '@angular/router';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CustomDividerComponent } from '../../../shared/components/custom-divider/custom-divider.component';
import { PlatformListLogoComponent } from './platform-list-logo/platform-list-logo.component';
import { CardWithSmallIconComponent } from '../../../shared/components/card-with-small-icon/card-with-small-icon.component';

@NgModule({
  declarations: [
    PlatformCardComponent,
    PlatformListComponent,
    PlatformDialogComponent,
    PlatformDialogActionsComponent,
    SynceeAppDialogActionsComponent,
    PlatformListLogoComponent,
  ],
  imports: [
    CommonModule,
    PlatformTypeToNamePipe,
    TranslateModule,
    PlatformLogoComponent,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    HelpBoxComponent,
    FlexModule,
    PlatformBadgeDirective,
    RouterLink,
    FusePipesModule,
    SlickCarouselModule,
    CustomDividerComponent,
    CardWithSmallIconComponent,
  ],
  exports: [PlatformListComponent, PlatformCardComponent],
})
export class PlatformListModule {}
