import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Params, RouterModule } from '@angular/router';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-page-back-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule, FlexModule],
  templateUrl: './page-back-button.component.html',
  styleUrls: ['./page-back-button.component.scss'],
})
export class PageBackButtonComponent implements OnInit {
  @Input() title: string;
  @Input() url: string;
  @Input() queryParams: Params;

  constructor() {}

  ngOnInit(): void {}
}
