<div class="product-card-action-container" *ngIf="status !== 'hidden'">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'in-catalog'">
      <app-product-card-action-in-catalog [catalogs]="product.CATALOG"></app-product-card-action-in-catalog>
    </ng-container>
    <ng-container *ngSwitchCase="'in-store'">
      <app-product-card-action-in-store></app-product-card-action-in-store>
    </ng-container>
    <ng-container *ngSwitchCase="'selected'">
      <app-product-card-action-selectable
        [selected]="selected"
        (selectedChange)="selectedChange.emit($event)"
      ></app-product-card-action-selectable>
    </ng-container>
    <ng-container *ngSwitchCase="'not-selected'">
      <app-product-card-action-selectable
        [selected]="selected"
        (selectedChange)="selectedChange.emit($event)"
      ></app-product-card-action-selectable>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-product-card-action-default [product]="product"></app-product-card-action-default>
    </ng-container>
  </ng-container>
</div>
