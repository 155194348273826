<div
  class="syncee-avatar"
  matRipple
  [class.non-pointer]="nonPointer"
  [matRippleDisabled]="noRipple"
  [class]="type"
  [style.height.px]="diameter"
  [style.width.px]="diameter"
  [style.border-radius.px]="diameter / 2"
  [style.background-color]="backgroundColor"
  [style.color]="color"
  [style.border]="borderWidth && borderWidth + 'px solid ' + borderColor"
>
  <span [ngClass]="textClasses"><ng-content></ng-content></span>
</div>
