<div *conditionLoading="isLoaded">
  <ng-container *ngIf="!errorMessage; else error">
    <div class="thank-you-page-wrapper">
      <app-single-alert-with-icon
        class="pb-72 pt-128"
        icon="check"
        descriptionKey="COMMON_ALERTS.NEED_HELP"
        [titleKey]="
          hasFreeTrial
            ? 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.FREE_TRIAL_ACTIVE'
            : 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.PLAN_CHANGED_SUCCESS'
        "
        [button]="{
          type: 'inner_redirect',
          textKey: 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.GO_TO_BUTTON',
          buttonClass: 'syncee-blue-button',
          url: '/' + navigateToMarketplace,
          queryParams: {
            category: '1',
            searchType: 'SUPPLIERS'
          }
        }"
      ></app-single-alert-with-icon>
      <div class="current-sub-container">
        <div class="current-sub-table">
          <div class="h3 pb-20">{{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.SUMMARY' | translate }}</div>
          <table>
            <thead>
              <tr class="border-bottom">
                <td>{{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.TABLE_LABELS.SOLUTION' | translate }}</td>
                <!-- Product -->
                <td>{{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.TABLE_LABELS.PLAN' | translate }}</td>
                <!-- PLAN -->
                <td>{{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.TABLE_LABELS.CYCLE' | translate }}</td>
                <!-- Cycle -->
                <td>
                  {{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.TABLE_LABELS.LIMIT' | translate }}
                </td>
                <!-- USAGE -->
                <td>
                  <span>{{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.TABLE_LABELS.PRICE' | translate }}</span>
                </td>
                <!-- Price -->
              </tr>
            </thead>
            <tbody>
              <!-- PRODUCTS -->
              <ng-container *ngFor="let product of synceeProducts">
                <tr *ngIf="product == 'rmp' || !isOnlyMarketplace">
                  <!-- Product -->
                  <td>
                    {{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.SOLUTION_NAME.' + product.toUpperCase() | translate }}
                  </td>

                  <!-- PLAN -->
                  <td>
                    {{ ecom.subscriptions[product].planName }}
                  </td>

                  <!-- Cycle -->
                  <td>
                    {{ ecom.cycle }}
                  </td>

                  <!-- USAGE -->
                  <td>
                    {{ ecom.subscriptions[product].maxProductsNumber }}
                  </td>

                  <!-- Price -->
                  <td>
                    <div *ngIf="ecom.subscriptions[product].coupon">
                      <div class="price-with-coupon">
                        <div>
                          {{
                            calculatePrice(
                              ecom.subscriptions[product].coupon.type,
                              ecom.subscriptions[product].coupon.amount,
                              ecom.subscriptions[product]?.price
                            )
                              | currencyTransform : 'USD'
                              | async
                          }}
                        </div>
                        <div class="typography-body-2 coupon-typo">
                          {{ 'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.COUPON_CODE' | translate }}:
                          {{ ecom.subscriptions[product].coupon.name }}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!ecom.subscriptions[product].coupon">
                      <span> {{ ecom.subscriptions[product].price | currencyTransform : 'USD' | async }} </span>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #error>
  {{ errorMessage }}
</ng-template>
