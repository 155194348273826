import { orderBy } from 'lodash';
import { ShippingRateDto } from '../vo/shipping-zones/shipping-rate.dto';

export function shippingRateSort(shippingRates: ShippingRateDto[]): ShippingRateDto[] {
  if (shippingRates && shippingRates.length > 1) {
    return orderBy(
      shippingRates,
      [
        'condition.type',
        function (rate): string {
          return rate.condition ? '' : null;
        },
        'condition.minValue',
        'cost',
      ],
      ['asc', 'desc', 'asc', 'asc']
    );
  }

  return shippingRates;
}
