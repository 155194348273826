import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterStructure } from '../../../../vo/filter/filter-structure';
import { AutocompleteSuggestion } from '../../../retailer-import-list/tab-items/retailer-import-list-products/model/autocomplete-suggestion';
import { NeedAutocomplete } from '../../../retailer-import-list/tab-items/retailer-import-list-products/model/need-autocomplete';
import { FilterGroup } from '../../model/filter-group';
import { FilterGroupConditionEnum } from '../../model/filter-group-condition';
import { FilterItem } from '../../model/filter-item';
import { FilterItemChange } from '../../model/helper/filter-item-change';
import { FilterItemConditionChange } from '../../model/helper/filter-item-condition-change';
import { FilterItemError } from '../../model/helper/filter-item-error';
import { GroupConditionChange } from '../../model/helper/group-condition-change';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnInit, OnChanges {
  @Input() depth = 0;
  @Input() group: FilterGroup;
  @Input() filterStructures: FilterStructure[];
  @Input() autocompleteSuggestion: BehaviorSubject<AutocompleteSuggestion>;
  @Input() hasNoMoreAutocomplete: Subject<boolean>;
  @Input() rightActionBar: TemplateRef<any>;
  @Input() filterItemErrors: FilterItemError[];
  @Input() locked = false;
  @Input() useOffset = false;
  @Output() newItemAdd = new EventEmitter<string>();
  @Output() newGroupAdd = new EventEmitter<string>();
  @Output() deleteItemClicked = new EventEmitter<string>();
  @Output() deleteGroupClicked = new EventEmitter<string>();
  @Output() groupConditionChange = new EventEmitter<GroupConditionChange>();
  @Output() filterItemConditionChange = new EventEmitter<FilterItemConditionChange>();
  @Output() needAutoComplete = new EventEmitter<NeedAutocomplete>();
  @Output() needMoreAutocomplete = new EventEmitter<NeedAutocomplete>();
  @Output() filterItemChange = new EventEmitter<FilterItemChange>();
  @Output() filterItemsErrorRevalidate = new EventEmitter<boolean>();

  everyItemLocked: boolean;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(_: SimpleChanges): void {
    this.everyItemLocked = this.getEveryItemLocked();
  }

  handleAddNewItem(groupId?: string): void {
    this.newItemAdd.emit(groupId ?? this.group.id);
  }

  handleAddNewGroup(groupId?: string): void {
    this.newGroupAdd.emit(groupId ?? this.group.id);
  }

  handleGroupConditionChange(groupId: string, newValue: boolean, index: number): void {
    this.groupConditionChange.emit({
      groupId,
      newValue: newValue ? FilterGroupConditionEnum.OR : FilterGroupConditionEnum.AND,
      index,
    });
  }

  handleFilterItemConditionChange(groupId: string, newValue: FilterGroupConditionEnum): void {
    this.filterItemConditionChange.emit({ groupId, newValue });
  }

  handleFilterITemChange(event: FilterItem): void {
    this.filterItemChange.emit({ filterItem: event, groupId: this.group.id });
  }

  private getEveryItemLocked(): boolean {
    return this.group.filterItems.every((i) => i.locked);
  }
}
