import { Injectable } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { CountryNode, newCountries } from '../utils/Countries';
@Injectable({
  providedIn: 'root',
})
export class CountryCodeNameMapperService {
  constructor() {}

  mapCountryNameToCode(name: string): string {
    let country: CountryNode;
    Object.keys(newCountries).forEach((continent) => {
      const foundCountry = newCountries[continent].find((item) => item.name === name);
      if (!Utils.isNullOrUndefined(foundCountry)) {
        country = foundCountry;
      }
    });
    return country.code;
  }

  mapCountryCodeToName(code: string): string {
    let country;
    Object.keys(newCountries).every((continent) => {
      country = newCountries[continent].find((item) => item.code === code);
      return Utils.isNullOrUndefined(country);
    });
    return country.name;
  }
}
