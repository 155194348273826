<ng-container *ngIf="!item.hidden">

  <div class="nav-link group-link" [ngClass]="item.classes" *ngIf="!item.hideTitle">
    <mat-icon class="nav-link-icon" *ngIf="item.icon && !item.icon_type">{{item.icon}}</mat-icon>
    <img class="nav-link-icon" src="assets/icons/{{item.icon_type}}/{{item.icon}}.{{item.icon_type}}"
         *ngIf="item.icon && item.icon_type" />
    <span class="nav-link-title h6" [translate]="item.translate">{{ item.title }}</span>
  </div>

  <div class="group-items">
    <ng-container *ngFor="let item of item.children">
      <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item" [hasArrow]="hasArrow" (itemClicked)="itemClicked.emit()"></fuse-nav-vertical-group>
      <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                     [item]="item"
                                     [hasArrow]="hasArrow"
                                     (itemClicked)="itemClicked.emit()"></fuse-nav-vertical-collapsable>
      <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item" (itemClicked)="itemClicked.emit()"></fuse-nav-vertical-item>
    </ng-container>
  </div>

</ng-container>
