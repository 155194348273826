<div [ngClass]="wrapperClass">
  <div [ngClass]="innerClass">
    <app-section-title [title]="title" [description]="description"></app-section-title>
    <div class="cards">
      <ng-container *ngFor="let card of cards">
        <div class="card">
          <div class="content">
            <img
              [ngSrc]="card.image.src"
              [alt]="card.image.alt"
              [height]="card.image.height"
              [width]="card.image.width"
            />
            <div>
              <h6 class="subtitle m-0">{{ card.subtitleKey | translate | uppercase }}</h6>
              <h3 class="title m-0">{{ card.titleKey | translate }}</h3>
              <div class="syncee-grey-600-fg" *ngIf="card.descriptionKey">{{ card.descriptionKey | translate }}</div>
            </div>
          </div>
          <app-general-button [config]="card.button"></app-general-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
