import { Directive, HostListener } from '@angular/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';

/**
 * Opens the registration dialog.
 *
 * Add 'trigger-registration-type' id to the corresponding item.
 *
 * @example
 * <a id="trigger-registration-retailer">Sign up</a>
 *
 * */
@Directive({
  selector: '[registrationOpen]',
  standalone: true,
})
export class RegistrationOpenDirective {
  private readonly RETAILER_REGISTRATION_ID = 'trigger-registration-retailer';
  private readonly SUPPLIER_REGISTRATION_ID = 'trigger-registration-supplier';
  @HostListener('click', ['$event']) onClick(event): void {
    if (event.target.id === this.RETAILER_REGISTRATION_ID) {
      this.openRegistration(AuthDialogType.RETAILER);
    }
    if (event.target.id === this.SUPPLIER_REGISTRATION_ID) {
      this.openRegistration(AuthDialogType.SUPPLIER);
    }
  }

  constructor(private authManagerService: AuthenticationManagerService) {}

  private openRegistration(type: AuthDialogType): void {
    this.authManagerService.emitOpenRegistrationDialog(type);
  }
}
