import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ImageTemplateSelectorComponent,
  ImageTemplateSelectorData,
  ImageTemplateSelectorResponse,
} from 'app/main/image-template-selector/image-template-selector.component';
import { transformToCdnDownloadUrl } from 'app/utils/transform-to-cdn-download';
import { isNil } from 'lodash';
import { catchError, forkJoin, map, Observable, of, Subject, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageTemplatesService {
  selectedTemplate: Subject<Blob> = new Subject<Blob>();

  private http: HttpClient;

  constructor(private dialog: MatDialog, private handler: HttpBackend) {
    this.http = new HttpClient(this.handler);
  }

  getCdnImage(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(
      switchMap((response) => {
        if (!response.ok) {
          return throwError(() => new Error('cdn image request error'));
        }
        return of(response.body);
      }),
      catchError((error) => throwError(() => new Error(error.message)))
    );
  }

  getTemplateImages(templateType: TemplateType, pagination: TemplatesPagination): Observable<TemplateImagesResponse> {
    const urls = [];

    for (let i = pagination.from; i < pagination.from + pagination.size; i++) {
      urls.push(transformToCdnDownloadUrl(`assets/images/${templateType}/${i}.png`));
    }

    const imageRequests = urls.map((url) => this.getCdnImage(url).pipe(catchError(() => of(null))));

    return forkJoin(imageRequests).pipe(
      map((results) => {
        const blobs = results.filter((result): result is Blob => !isNil(result));
        const hasError = results.some((result) => isNil(result));
        return { blobs, hasError };
      })
    );
  }

  openTemplateDialog(templateType: TemplateType): void {
    const dialogRef = this.dialog.open<
      ImageTemplateSelectorComponent,
      ImageTemplateSelectorData,
      ImageTemplateSelectorResponse
    >(ImageTemplateSelectorComponent, {
      panelClass: 'custom-modal-full-width-lt-md',
      width: '768px',
      autoFocus: false,
      data: {
        templateType: templateType,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!!res?.image) {
        this.selectedTemplate.next(res.image);
      }
    });
  }
}

export enum TemplateType {
  NONE = 'none',
  COVER = 'storefront-cover-templates',
}

export interface TemplatesPagination {
  from: number;
  size: number;
}

export interface TemplateImagesResponse {
  blobs: Blob[];
  hasError: boolean;
}
