<div fxLayout="column" class="dialog-container">
  <div class="dialog-header">
    <div class="title">
      <div class="h3">{{ 'RETAILER_UPDATE_CATALOG.WHAT_FIELDS' | translate }}</div>
      <div>{{ 'RETAILER_UPDATE_CATALOG.SYNCEE_UPDATES' | translate }}</div>
      <div class="notify">{{ 'RETAILER_UPDATE_CATALOG.PLEASE_SELECT' | translate }}</div>
    </div>
    <button class="close-btn" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="fields-container">
      <div>
        <ng-container *ngFor="let field of fieldsUpdate">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            *ngIf="!isRCatUpdtae || skipOnRCatalogUpdate.indexOf(field.id) == -1"
          >
            <mat-checkbox
              [disabled]="field.disabled"
              [(ngModel)]="field.checked"
              (ngModelChange)="onFieldUpdateChanged(field)"
              [ngModelOptions]="{ standalone: true }"
            >
              {{ field.name }}
            </mat-checkbox>
            <mat-icon
              *ngIf="field.tooltip"
              class="help-icon icon-filled"
              matTooltip="{{ field.tooltip | translate }}"
              [matTooltipPosition]="'above'"
              >info
            </mat-icon>
          </div>
        </ng-container>
      </div>
      <div>
        <div *ngFor="let field of optionFieldsUpdate" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <mat-checkbox
            [disabled]="field.disabled"
            [(ngModel)]="field.checked"
            (change)="onOptionChanged(field, $event)"
            [ngModelOptions]="{ standalone: true }"
          >
            {{ field.name }}
          </mat-checkbox>
          <mat-icon
            *ngIf="field.tooltip"
            class="help-icon icon-filled"
            matTooltip="{{ field.tooltip | translate }}"
            [matTooltipPosition]="'above'"
            >info
          </mat-icon>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="syncee-blue-button" (click)="submit()">
      {{ 'RETAILER_UPDATE_CATALOG.UPDATE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
