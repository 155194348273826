<form
  class="w-100p"
  fxLayout="row"
  fxLayoutAlign="center center"
  [formGroup]="feedbackFormGroup"
  *conditionLoading="feedbacks$ | async as feedbacks; hasError: hasError"
>
  <div class="w-100p" fxLayout="column" fxLayoutGap="20px">
    <ng-container *ngFor="let feedback of feedbacks">
      <ng-container [ngSwitch]="feedback.feedback.type">
        <div fxLayout="column" fxLayoutGap="12px">
          <h5>{{ 'USER_FEEDBACK.' + feedback.feedback.question + '.TITLE' | translate }}</h5>
          <ng-container *ngSwitchCase="'select'">
            <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding-except-error">
              <mat-select
                [formControlName]="feedback.feedback.question"
                *ngIf="feedback.options[0].key !== 'CUSTOM'; else customAnswerSelect"
                [value]="selectedAnswers[feedback.feedback.id].feedbackId"
                (selectionChange)="handleSelectionChange($event.value, feedback)"
                [placeholder]="
                  'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.' + feedback.feedback.question + '_SELECT' | translate
                "
              >
                <mat-option *ngFor="let option of feedback.options" [value]="option.id">
                  {{ 'USER_FEEDBACK.' + feedback.feedback.question + '.OPTIONS.' + option.key | translate }}
                </mat-option>
              </mat-select>
              <ng-template #customAnswerSelect>
                <mat-select
                  [formControlName]="feedback.feedback.question"
                  [value]="+selectedAnswers[feedback.feedback.id].message"
                  (selectionChange)="handleCustomAnswerChange(feedback.options[0], $event.value)"
                  [placeholder]="
                    'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.' + feedback.feedback.question + '_SELECT' | translate
                  "
                >
                  <mat-option *ngFor="let option of customOptions[feedback.feedback.question]" [value]="option.id">
                    {{ option.value }}
                  </mat-option>
                </mat-select>
              </ng-template>
              <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <ng-container
              *ngIf="getFeedbackById(selectedAnswers[feedback.feedback.id].feedbackId, feedback.options) as option"
            >
              <mat-form-field
                class="w-100p select-without-label input-without-padding-except-error"
                appearance="fill"
                *ngIf="option.key === 'OTHER'"
              >
                <input
                  matInput
                  placeholder="Start writing..."
                  [formControlName]="feedback.feedback.question + '_OTHER_INPUT'"
                  [value]="selectedAnswers[feedback.feedback.id].message || ''"
                  (input)="handleCustomAnswerChange(option, $event.target['value'])"
                />
                <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
              </mat-form-field>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'input'">
            <mat-form-field class="w-100p select-without-label input-without-padding-except-error" appearance="fill">
              <input
                matInput
                [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.START_WRITING' | translate"
                [formControlName]="feedback.feedback.question"
                [value]="selectedAnswers[feedback.feedback.id].message || ''"
                (input)="handleCustomAnswerChange(feedback.options[0], $event.target['value'])"
              />
              <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
