import { Component, Input, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, NgClass],
})
export class CustomSpinnerComponent implements OnInit {
  @Input() diameter = 25;
  @Input() isWhite = false;
  @Input() spinnerColor: SpinnerColor = 'default';

  constructor() {}

  ngOnInit(): void {}
}

export type SpinnerColor = 'default' | 'white' | 'black';
