import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { profile_menu_navigation } from '../../../../../navigation/inner-navigation';
import { Router } from '@angular/router';
import { AppState } from 'app/app.state';
import { Store } from '@ngrx/store';
import { getCurrentUserSelector, hasRolesSelector } from 'app/store/user/user.selector';
import {
  LogoutAction,
  UserToUserLoginType,
  UserToUserLogoutStart,
} from 'app/store/authentication/authentication.actions';
import { AvatarService } from '../../../../../service/avatar/avatar.service';
import { User } from 'app/service/user/user';
import { SetActualRole } from '../../../../../store/user/user.actions';
import { BreakPoint, ScreenManagerService } from '../../../../../service/screen-manager/screen-manager.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfileMobileDialogComponent } from '../profile-mobile-dialog/profile-mobile-dialog.component';
import { RoleDialogService } from '../../../../../service/guards/role-dialog.service';
import { ROLE_TYPE, RolesEnum } from '../../../../../vo/roles/roles';
import { SCOPES } from '../../../../../service/permission/scopes';
import {
  getScopesSelector,
  isAuthenticatedSelector,
} from '../../../../../store/authentication/authentication.selector';
import { includes } from 'lodash';
import {
  CENTER_REWRITE_RETAILER,
  CENTER_REWRITE_SUPPLIER,
} from '../../../../../main/change-role/change-role-dialog.config';

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss'],
})
export class ProfileIconComponent implements OnInit {
  nameToShow: string;
  role: ROLE_TYPE;
  readonly avatarDiameter: Observable<number>;
  readonly avatarMargin: Observable<number>;
  readonly navigation = profile_menu_navigation;
  readonly isDialog: Observable<boolean>;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private avatarService: AvatarService,
    private screenManagerService: ScreenManagerService,
    private roleDialogService: RoleDialogService,
    private dialog: MatDialog
  ) {
    this.isDialog = this.screenManagerService.observeBreakpoint(BreakPoint.md).pipe(map((state) => state.matches));
    this.avatarDiameter = this.isDialog.pipe(map((match) => (match ? 32 : 40)));
    this.avatarMargin = this.isDialog.pipe(map((match) => (match ? 8 : 0)));
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    combineLatest([this.getRole(), this.getIsAdminObs()]).subscribe(([role, isAdmin]) => {
      this.role = isAdmin ? RolesEnum.ADMIN : role;
    });
    this.getNameToShow().subscribe((name) => (this.nameToShow = name));
  }

  getPermissionsObs(): Observable<SCOPES[]> {
    return this.store.select(getScopesSelector(false));
  }

  private getIsAdminObs(): Observable<boolean> {
    return this.getPermissionsObs().pipe(map((permissions) => includes(permissions, SCOPES.ADMIN)));
  }

  private getRole(): Observable<ROLE_TYPE> {
    return this.getUser().pipe(map((user) => user.role as ROLE_TYPE));
  }

  private getUser(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(filter((user) => !!user));
  }

  private getNameToShow(): Observable<string> {
    return this.getUser().pipe(map((user) => user.firstName ?? user.userName));
  }

  // private handleRedirectToLogin(type: number): void {
  //   this.authenticationManagerService.emitOpenRegistrationDialog(
  //     type === USER_ROLES.AGENT ? AuthDialogType.RETAILER : AuthDialogType.SUPPLIER
  //   );
  // }

  get avatarContent(): Observable<string> {
    return this.getUser().pipe(map((user: User) => this.avatarService.generateContentFromUser(user)));
  }

  logout(): void {
    this.store.dispatch(new LogoutAction());
  }

  logoutFromUser(loggedInBy: UserToUserLoginType): void {
    this.store.dispatch(new UserToUserLogoutStart(loggedInBy));
  }

  handleCenterClicked(types: number[], link: string, forRole: number): void {
    this.store
      .select(hasRolesSelector, forRole)
      .pipe(take(1))
      .subscribe((hasRole) => {
        if (hasRole) {
          this.store.dispatch(new SetActualRole(forRole));
          this.router.navigate([link]);
        } else {
          this.roleDialogService.handleCenterNavigationAttempt(
            forRole,
            forRole === RolesEnum.SUPPLIER ? CENTER_REWRITE_SUPPLIER : CENTER_REWRITE_RETAILER
          );
        }
      });
  }

  openDialog(): MatDialogRef<any> {
    return this.dialog.open(ProfileMobileDialogComponent, {
      autoFocus: false,
      panelClass: 'custom-modal-full-width-lt-md',
    });
  }
}
