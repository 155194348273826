import { EcomVO } from 'app/service/ecom/ecom.service';
import { EcomActionTypes, USER_ECOMS_ACTIONS_ALL, UserEcomsSuccessPayload } from './ecom.actions';

export interface EcomsState {
  supplierEcoms: EcomVO[];
  retailerEcoms: EcomVO[];
  selectedRetailerEcom: EcomVO;
  selectedSupplierEcom: EcomVO;
}

const initialState = {
  retailerEcoms: [],
  supplierEcoms: [],
  selectedRetailerEcom: undefined,
  selectedSupplierEcom: undefined,
};

export function ecomsReducer(state = initialState, action: USER_ECOMS_ACTIONS_ALL): EcomsState {
  switch (action.type) {
    case EcomActionTypes.REMOVE_SELECTED_ECOM: {
      return {
        ...state,
        selectedSupplierEcom: initialState.selectedSupplierEcom,
        selectedRetailerEcom: initialState.selectedRetailerEcom,
      };
    }
    case EcomActionTypes.FETCH_RETAILER_ECOMS_SUCCESS:
      return handleRetailerEcomFetchSuccess(state, action.payload);
    case EcomActionTypes.FETCH_SUPPLIER_ECOMS_SUCCESS:
      return handleSupplierEcomFetchSuccess(state, action.payload);
    case EcomActionTypes.CHANGE_SELECTED_SUPPLIER_ECOM_SUCCESS: {
      return { ...state, selectedSupplierEcom: action.payload.selectedEcom };
    }
    case EcomActionTypes.CHANGE_SELECTED_RETAILER_ECOM_SUCCESS: {
      return { ...state, selectedRetailerEcom: action.payload.selectedEcom };
    }
    default: {
      return state;
    }
  }
}

function handleRetailerEcomFetchSuccess(state: EcomsState, payload: UserEcomsSuccessPayload): EcomsState {
  return {
    ...state,
    retailerEcoms: payload.ecoms,
    selectedRetailerEcom: payload.selectedEcom ?? null,
  };
}

function handleSupplierEcomFetchSuccess(state: EcomsState, payload: UserEcomsSuccessPayload): EcomsState {
  return {
    ...state,
    supplierEcoms: payload.ecoms,
    selectedSupplierEcom: payload.selectedEcom ?? null,
  };
}
