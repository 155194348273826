import { FaqItem } from '../../vo/faq/faq-item';
import { FaqSection } from '../../vo/faq/faq-section';

class FaqSectionTranslationMapper {
  static map(translation: { [key: string]: object | string }): FaqSection {
    return {
      id: translation['ID'] as string,
      titleKey: translation['TITLE'] as string,
      items: {
        left: this.mapItem(translation['ITEMS']['LEFT']),
        right: this.mapItem(translation['ITEMS']['RIGHT']),
      },
    };
  }

  private static mapItem(items: { [key: string]: { TITLE: string; VALUE: string } }): FaqItem[] {
    return Object.values(items).map((item) => ({ title: item.TITLE, text: item.VALUE }));
  }
}

export default FaqSectionTranslationMapper;
