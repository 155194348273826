<!--<div class="settings-field">-->
<!--<div>-->
<!--<p>Type</p>-->
<!--</div>-->

<!--<mat-form-field appearance="fill">-->
<!--<mat-select value="insert" [(ngModel)]="updateType">-->
<!--<mat-option value="insert">Insert (upload)</mat-option>-->
<!--<mat-option value="update">Update</mat-option>-->
<!--<mat-option value="upsert">Upsert (upload and update)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<div *ngIf="settingsForm" [formGroup]="settingsForm">
  <mat-card class="retailer-mapping-section">
    <mat-card-header>
      <app-task-comment
        [taskId]="taskId"
        [taskType]="'import'"
        [step]="'settings'"
        [mappingField]="'settings'"
        [comments]="comments"
      >
      </app-task-comment>
      <h1>Basic mapping settings</h1>
    </mat-card-header>

    <mat-card-content formGroupName="settings">
      <!--Published-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>Published</p>-->
      <!--<p>If published, the products will be visible in the store.</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-slide-toggle [checked]="true" formControlName="published"></mat-slide-toggle>-->
      <!--&lt;!&ndash;<mat-form-field appearance="fill">&ndash;&gt;-->
      <!--&lt;!&ndash;<mat-select [value]="true" formControlName="published">&ndash;&gt;-->
      <!--&lt;!&ndash;<mat-option *ngFor="let option of mappingSettingsService?.trueOrFalseOptions"&ndash;&gt;-->
      <!--&lt;!&ndash;[value]="option.value">&ndash;&gt;-->
      <!--&lt;!&ndash;{{option.name}}&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-option>&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-select>&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
      <!--</div>-->
      <!--</div>-->

      <!--Charge taxes on this product-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.CHARGE_TAXES' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select [value]="'dont_modify'" formControlName="taxes">
              <mat-option [value]="'dont_modify'">
                {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_MODIFY' | translate }}
              </mat-option>
              <mat-option [value]="true"> true </mat-option>
              <mat-option [value]="false"> false </mat-option>
            </mat-select>
          </mat-form-field>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select [value]="true" formControlName="taxes">-->
          <!--<mat-option *ngFor="let option of mappingSettingsService?.trueOrFalseOptions"-->
          <!--[value]="option.value">-->
          <!--{{option.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--Inventory management-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.INVENTORY_MANAGEMENT' | translate }}</p>
          <p [innerHTML]="'RETAILER_JUMPSELLER_VU_TASKWIZARD.INVENTORY_MANAGEMENT_DESCRIPTION' | translate"></p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="dont_modify" formControlName="inventory">
              <mat-option value="dont_modify">{{
                'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_MODIFY' | translate
              }}</mat-option>
              <mat-option value="dont">{{
                'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_TRACK_INVENTORY' | translate
              }}</mat-option>
              <mat-option value="track">{{
                'RETAILER_JUMPSELLER_VU_TASKWIZARD.SHOPIFY_TRACKS' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Backorder-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.BACKORDER' | translate }}</p>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.ALLOW_CUSTOMERS' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select [value]="'dont_modify'" formControlName="backorder">
              <mat-option [value]="'dont_modify'">
                {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_MODIFY' | translate }}
              </mat-option>
              <mat-option [value]="true"> true </mat-option>
              <mat-option [value]="false"> false </mat-option>
            </mat-select>
          </mat-form-field>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select value="supplierDefault" formControlName="backorder">-->
          <!--<mat-option value="supplierDefault">Supplier default</mat-option>-->
          <!--<mat-option value="allow">Allow</mat-option>-->
          <!--<mat-option value="notAllow">Not allow</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--This product requires shipping-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.REQUIRES_SHIPPING' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select [value]="'dont_modify'" formControlName="shipping">
              <mat-option [value]="'dont_modify'">
                {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_MODIFY' | translate }}
              </mat-option>
              <mat-option [value]="true"> true </mat-option>
              <mat-option [value]="false"> false </mat-option>
            </mat-select>
          </mat-form-field>
          <!--<mat-form-field appearance="fill">-->
          <!--<mat-select [value]="true" formControlName="shipping">-->
          <!--<mat-option *ngFor="let option of mappingSettingsService?.yesOrNoOptions"-->
          <!--[value]="option.value">-->
          <!--{{option.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </div>

      <!--Fulfillment Service-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.FULFILLMENT_SERVICE' | translate }}</p>
          <p [innerHTML]="'RETAILER_JUMPSELLER_VU_TASKWIZARD.NOTE_MANUAL' | translate"></p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="manual" formControlName="fulfillment">
              <mat-option value="dont_modify">
                {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_MODIFY' | translate }}
              </mat-option>
              <mat-option value="manual">{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.MANUAL' | translate }}</mat-option>
              <mat-option *ngFor="let option of fulfillmentServices" [value]="option.handle">
                {{ option.service_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Weight unit-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.WEIGHT_UNIT' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="kg" formControlName="weightUnit">
              <mat-option value="dont_modify">
                {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_MODIFY' | translate }}
              </mat-option>
              <mat-option value="kg">kg</mat-option>
              <mat-option value="oz">oz</mat-option>
              <mat-option value="lb">lb</mat-option>
              <mat-option value="g">g</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Point of Sale visibility-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>Point of Sale visibility</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select value="global" formControlName="pointOfSale">-->
      <!--<mat-option value="global">The products are published to Point of Sale and the Online Store-->
      <!--</mat-option>-->
      <!--<mat-option value="onlineStore">The products are not published to Point of Sale, JUST to the-->
      <!--Online Store-->
      <!--</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->
    </mat-card-content>
  </mat-card>

  <mat-card class="retailer-mapping-section">
    <mat-card-header>
      <h1>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.UPDATE_OPTIONS' | translate }}</h1>
    </mat-card-header>

    <!--    <mat-card-content formGroupName="updateOptions">-->
    <!--    <div class="settings-field">-->
    <!--    <div>-->
    <!--    <p>-->
    <!--    Upload new products-->
    <!--    </p>-->
    <!--    <p>-->
    <!--    If you use an entire supplier catalog Syncee will upload new products when the supplier adds new products to the catalog.-->
    <!--    </p>-->
    <!--    </div>-->
    <!--    <div>-->
    <!--    <mat-slide-toggle [checked]="true" formControlName="uploadNewProducts">-->

    <!--    </mat-slide-toggle>-->
    <!--    </div>-->
    <!--    </div>-->
    <!--    </mat-card-content>-->

    <mat-card-content formGroupName="updateOptions">
      <!--Update type-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>Update type</p>-->

      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select value="insert" formControlName="updateType">-->
      <!--<mat-option value="UPLOAD_DF_MODE">Insert (upload)</mat-option>-->
      <!--<mat-option value="UPDATE_DF_MODE">Update</mat-option>-->
      <!--<mat-option value="CRUD_DF_MODE">Upsert (upload and update)</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->

      <!--Product not in the feed-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>What shall we do with the product which is not in the feed?</p>-->
      <!--<p>This setting only modifies the products that were uploaded to the store using this file, but they-->
      <!--got-->
      <!--deleted from the file since then.</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select value="qty0hidden" formControlName="productNotInFeed">-->
      <!--<mat-option value="dont">Do not touch</mat-option>-->
      <!--<mat-option value="qty0">Set the quantity to 0</mat-option>-->
      <!--<mat-option value="qty0hidden">Hide the product</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->

      <!--&lt;!&ndash;0 quantity feed&ndash;&gt;-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>What shall we do with the product which quantity is 0 in the feed?</p>-->
      <!--<p>This setting only modifies the products that were uploaded to the store using this file, but-->
      <!--their-->
      <!--quantity changed to 0 since then.</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select value="qty0" formControlName="productQuantity0">-->
      <!--<mat-option value="qty0">Set the quantity to 0</mat-option>-->
      <!--<mat-option value="qty0hidden">Hide the product</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->

      <!--&lt;!&ndash;Compare&ndash;&gt;-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>How can Syncee recognize the products which are already in your store?</p>-->
      <!--<p>If you already have products in your store uploaded from this file, and you would like to modify them now, select how Syncee can recognize them in your store to avoid duplicates.</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select value="dont" formControlName="compare">-->
      <!--<mat-option value="dont">Do not compare</mat-option>-->
      <!--<mat-option value="sku">By SKU</mat-option>-->
      <!--<mat-option value="title">By product title</mat-option>-->
      <!--<mat-option value="handle">By handle</mat-option>-->
      <!--<mat-option value="barcode">By barcode</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->

      <!--Variant compare-->
      <div class="settings-field">
        <div>
          <p>
            {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.HOW_CAN_SYNCEE' | translate }}
          </p>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DEFAULT_RECOGNIZE' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="sku" formControlName="variantCompare">
              <mat-option value="sku">By Variant SKU</mat-option>
              <mat-option value="barcode">By Variant Barcode</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Collision method-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.WHAT_SYNCEE_SHOULD_DO' | translate }}</p>
        </div>
        <div>
          <mat-form-field appearance="fill">
            <mat-select formControlName="collisionMethod">
              <mat-option value="updateFirst">{{
                'RETAILER_JUMPSELLER_VU_TASKWIZARD.UPDATE_FIRST' | translate
              }}</mat-option>
              <mat-option value="updateAll">{{
                'RETAILER_JUMPSELLER_VU_TASKWIZARD.UPDATE_ALL' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Rename images-->
      <!--<div class="settings-field">-->
      <!--<div>-->
      <!--<p>Would you like Syncee to rename the images?</p>-->
      <!--<p>If you select YES, Syncee will rename the images with a date and a code. Using this Syncee will try to upload the images multiple times even if Shopify's server doesn't accept it for the first time. If you select NO, Syncee won't try to upload them more than once, but it is better for SEO.</p>-->
      <!--</div>-->

      <!--<div>-->
      <!--<mat-form-field appearance="fill">-->
      <!--<mat-select value="yes" formControlName="renameImages">-->
      <!--<mat-option value="yes">Yes</mat-option>-->
      <!--<mat-option value="no">No</mat-option>-->
      <!--</mat-select>-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <!--</div>-->

      <!--Multi-location inventory changes-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.HOW_QUANTITY_CHANGES' | translate }}</p>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.QUANTITY_CHANGES_DESCRIPTION' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="dont" formControlName="multiLocationInventoryChanges">
              <mat-option value="dont">{{
                'RETAILER_JUMPSELLER_VU_TASKWIZARD.DO_NOT_RELOCATE' | translate
              }}</mat-option>
              <mat-option value="relocate">{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.RELOCATE' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Fields to update-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.WHAT_FIELDS' | translate }}</p>
          <p>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.SYNCEE_UPDATES' | translate }}</p>
        </div>

        <app-checkbox-selection-list
          (checkBoxesChange)="updateChanges()"
          [(checkBoxes)]="fieldsToUpdate"
        ></app-checkbox-selection-list>
      </div>

      <!--Fields not to update-->
      <!--<div id="fields-not-to-update-container" class="settings-field">-->
      <!--<div>-->

      <!--</div>-->

      <!--<app-checkbox-selection-list (checkBoxesChange)="updateChanges()"-->
      <!--[(checkBoxes)]="optionFieldsUpdate"></app-checkbox-selection-list>-->
      <!--</div>-->
    </mat-card-content>
  </mat-card>

  <!--Save button-->
  <!--<div id="retailer-catalog-settings-save" class="centered" *ngIf="showSaveButton">
      <button mat-button color="primary">Save</button>
  </div>-->
</div>
