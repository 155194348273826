interface PriceRule {
  rule: string;
  label: string;
  translateKey?: string;
}

export const PRICE_RULES = [
  {
    rule: 'none',
    label: 'None',
    translateKey: 'COMMON_WORDS.NONE_UC',
  },
  {
    rule: '.00',
    label: 'xxx.00',
  },
  {
    rule: '.95',
    label: 'xxx.95',
  },
  {
    rule: '.99',
    label: 'xxx.99',
  },
  {
    rule: 'xx9.0',
    label: 'xx9.00',
  },
  {
    rule: '990',
    label: 'xx90.00',
  },
  {
    rule: '5.00',
    label: 'xx5.00',
  },
  {
    rule: '0.00',
    label: 'xx0.00',
  },
] as PriceRule[];
