<div class="subscription-plan-box-container" *ngIf="!isLoading && description && dataToShow">
  <div class="subscription-plan-box-title">
    <div class="plan-name">
      <span class="highlight">{{ dataToShow.title }}</span>
      {{ dataToShow.type === 'rmp' ? 'Marketplace' : 'DataFeed' }} Plan
    </div>
    <div class="upgrade-button"></div>
  </div>
  <div class="subscription-plan-box-content">
    <div class="subscription-plan-box-description" [innerHTML]="description | translate"></div>
    <div class="subscription-plan-box-list">
      <div class="subscription-plan-box-list-item" *ngFor="let listItem of list">
        <ng-container [ngSwitch]="listItem.type">
          <ng-container
            *ngSwitchCase="'advantage'"
            [ngTemplateOutlet]="advantage"
            [ngTemplateOutletContext]="{ text: listItem.text }"
          ></ng-container>
          <ng-container
            *ngSwitchCase="'disadvantage'"
            [ngTemplateOutlet]="disadvantage"
            [ngTemplateOutletContext]="{ text: listItem.text }"
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #advantage let-text="text">
  <div fxLayoutGap="8px" fxLayoutAlign="start center">
    <div class="icon-container done-container"><mat-icon>done</mat-icon></div>
    <div>{{ text | translate }}</div>
  </div>
</ng-template>

<ng-template #disadvantage let-text="text">
  <div fxLayoutGap="8px" fxLayoutAlign="start center">
    <div class="icon-container close-container"><mat-icon>close</mat-icon></div>
    <div>{{ text | translate }}</div>
  </div>
</ng-template>
