import { Injectable } from '@angular/core';
import { User } from '../user/user';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  constructor() {}

  generateContentFromUser(user: User): string {
    const firstName = !!user.firstName ? user.firstName.trim() : null;
    if (!!firstName) {
      return firstName.substring(0, 2);
    }
    const split = user.userName.trim().split(' ');
    if (split.length > 1) {
      return split[0].charAt(0).concat(split[1].charAt(0));
    } else {
      return (user.userName ?? user.email).substring(0, 2);
    }
  }
}
