export class TabFileManagerOutputVo {
  private _tabIndex;
  private _tabName;

  get tabIndex(): number {
    return this._tabIndex;
  }

  set tabIndex(value) {
    this._tabIndex = value;
  }

  get tabName(): string {
    return this._tabName;
  }

  set tabName(value) {
    this._tabName = value;
  }
}
