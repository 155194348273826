import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-favorite-no-results-for-search',
  templateUrl: './favorite-no-results-for-search.component.html',
  styleUrls: ['./favorite-no-results-for-search.component.scss'],
})
export class FavoriteNoResultsForSearchComponent implements OnInit {
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}
}
