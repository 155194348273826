import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { NotificationService } from '../../../main/notification/notification.service';
import { SpringPageable } from '../../../vo/pagination/spring-pageable';
import { RequestError } from '../request-error';
import { RequestOptions } from '../rest.service';
import { StatusCodesWithoutErrorNotification } from '../utils/status-codes-without-error-notification';

@Injectable({
  providedIn: 'root',
})
export class SpringRestService {
  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  public static fetchBaseUrl(microserviceName: string, url: string): string {
    return env.microservices[microserviceName].baseUrl + url;
  }

  public static fetchSocketUrl(microserviceName: string, url: string): string {
    return env.microservices[microserviceName].websocket + url;
  }

  public static fetchGatewayUrl(microserviceName: string, url: string): string {
    return `${env.microservices.gatewayUrl}/${env.microservices[microserviceName].serviceName}${url}`;
  }

  private getUrl(microserviceName: string, url: string, gateway: boolean): string {
    return gateway
      ? SpringRestService.fetchGatewayUrl(microserviceName, url)
      : SpringRestService.fetchBaseUrl(microserviceName, url);
  }

  public get(
    microserviceName: string | MicroserviceNames,
    url: string,
    params?: HttpParams | any,
    gateway?: boolean,
    headers?: HttpHeaders,
    skipNotification?: boolean
  ): Observable<any> {
    return this.http
      .get(this.getUrl(microserviceName, url, gateway), { params: params, headers })
      .pipe(catchError((err) => this.handleError(err, skipNotification)));
  }

  public getPage(
    microserviceName: string | MicroserviceNames,
    url: string,
    pageable?: SpringPageable,
    params?: HttpParams | any,
    gateway?: boolean,
    headers?: HttpHeaders,
    skipNotification?: boolean
  ): Observable<any> {
    return this.http
      .get(this.getUrl(microserviceName, url, gateway), {
        params: !!pageable ? Object.assign(pageable, params) : params,
        headers,
      })
      .pipe(catchError((err) => this.handleError(err, skipNotification)));
  }

  public post(
    microserviceName: string | MicroserviceNames,
    url: string,
    body: any,
    options: RequestOptions = {},
    gateway?: boolean,
    skipNotification?: boolean
  ): Observable<any> {
    return this.http
      .post(this.getUrl(microserviceName, url, gateway), body, options)
      .pipe(catchError((err) => this.handleError(err, skipNotification)));
  }

  public put(
    microserviceName: string | MicroserviceNames,
    url: string,
    body: any,
    options: RequestOptions = {},
    gateway?: boolean,
    skipNotification?: boolean
  ): Observable<any> {
    return this.http
      .put(this.getUrl(microserviceName, url, gateway), body, options)
      .pipe(catchError((err) => this.handleError(err, skipNotification)));
  }

  public patch(
    microserviceName: string | MicroserviceNames,
    url: string,
    body: any,
    options: RequestOptions = {},
    gateway?: boolean,
    skipNotification?: boolean
  ): Observable<any> {
    return this.http
      .patch(this.getUrl(microserviceName, url, gateway), body, options)
      .pipe(catchError((err) => this.handleError(err, skipNotification)));
  }

  public delete(
    microserviceName: string,
    url: string,
    params?: HttpParams | any,
    gateway?: boolean,
    body?: any,
    skipNotification?: boolean
  ): Observable<any> {
    return this.http
      .delete(this.getUrl(microserviceName, url, gateway), { params: params, body: body })
      .pipe(catchError((err) => this.handleError(err, skipNotification)));
  }

  private handleError(err: HttpErrorResponse, skipNotification = false): never {
    if (
      !Utils.isNullOrUndefined(err) &&
      !StatusCodesWithoutErrorNotification.includes(err.status) &&
      err.error &&
      (err.error.message || err.error.error) &&
      !skipNotification
    ) {
      this.notificationService.error(err.error.message || err.error.error);
    }

    throw RequestError.create(err);
  }
}

export enum MicroserviceNames {
  USER = 'userService',
  PRODUCT_SEARCH = 'productSearch',
  ORDER = 'orderService',
  NOTIFICATION = 'notificationService',
  TASK = 'taskService',
  AUTH = 'authService',
  PAYMENT = 'paymentService',
}
