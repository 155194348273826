import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BillingInformationVariant } from './model/billing-information-variant';
import { BillingInformationService } from './service/billing-information.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
  providers: [BillingInformationService],
})
export class BillingInformationComponent implements OnInit, OnChanges {
  @Input() variant: BillingInformationVariant = 'DEFAULT';
  @Input() isCpfIncluded = false;

  constructor(private billingInformationService: BillingInformationService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCpfIncluded) {
      this.billingInformationService.euCountryCodes = this.isCpfIncluded;
    }
  }

  get form(): FormGroup {
    return this.billingInformationService.billingInfoForm;
  }
}
