import { Pipe, PipeTransform } from '@angular/core';
import { NavigationService } from '../../service/navigation/navigation.service';

@Pipe({
  name: 'createSupplierFullUrl',
  standalone: true,
})
export class CreateSupplierFullUrlPipe implements PipeTransform {
  constructor(private navigationService: NavigationService) {}

  transform(supplierUserId: number, handle: string): string {
    return `https://${location.hostname}${this.navigationService.getStorefrontPath(supplierUserId, handle)}`;
  }
}
