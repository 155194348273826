<div fxLayout="column" fxLayoutAlign="center start" class="w-100p" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutAlign="start start" class="logo-container" fxLayoutGap="12px">
    <app-syncee-logo [width]="'160px'" [height]="'33px'"></app-syncee-logo>
    <div class="typography-body-1">
      {{ 'FOOTER.SLOGAN' | translate }}
    </div>
    <a href="https://apps.shopify.com/syncee-1" target="_blank">
      <img
        [src]="'images/partner-assets/Shopify-App-Store-Badge-Final-Black.svg' | getAssetFromStorage"
        alt="Find it on the Shopify App Store"
        [width]="160"
    /></a>
  </div>
  <mat-divider class="w-100p"></mat-divider>
  <div class="w-100p">
    <mat-accordion class="w-100p accordion" multi>
      <mat-expansion-panel *ngFor="let group of footerStructure">
        <mat-expansion-panel-header class="custom-panel-header">
          <h5 class="group-title m-0">{{ 'FOOTER.GROUP_TITLES.' + group.name | translate }}</h5>
        </mat-expansion-panel-header>
        <app-footer-group-items [group]="group" [gap]="'28px'"></app-footer-group-items>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
