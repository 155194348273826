import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { NotificationService } from '../../main/notification/notification.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { hasRolesSelector } from '../../store/user/user.selector';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SetActualRole } from '../../store/user/user.actions';
import { RolesEnum } from '../../vo/roles/roles';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad {
  private isAdmin$: Observable<boolean>;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.setIsAdmin();
  }

  canLoad(): Observable<boolean> {
    return this.isAdmin$;
  }

  private setIsAdmin(): void {
    this.isAdmin$ = this.store.select(hasRolesSelector, RolesEnum.ADMIN).pipe(
      tap((isAdmin) => {
        if (!isAdmin) {
          this.notificationService.error('Permission denied!');
          this.router.navigate(['/']);
        } else {
          this.store.dispatch(new SetActualRole(RolesEnum.ADMIN));
        }
      })
    );
  }
}
