<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="40px"
  class="stepflow-row-container"
  [ngClass]="wrapperClass"
>
  <div fxLayout="column" fxLayoutAlign="center center">
    <app-section-title
      [title]="titleKey | translate"
      [description]="descriptionKey | translate"
      [hasBottomPadding]="false"
    >
    </app-section-title>
  </div>
  <div
    class="inner-container"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="center center"
    fxLayoutGap="24px"
    [ngClass]="innerWrapperClass"
  >
    <ng-container *ngFor="let step of steps; let last = last">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" ngClass.lt-md="mb-0">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="20px"
          fxLayoutGap.lt-md="0"
          class="inner-card"
        >
          <app-double-border-icon
            iconColor="var(--app-syncee-primary-700)"
            [iconBackgroundColor]="colorPaletteService.getColorVariable('--app-syncee-primary-50')"
            [icon]="step.icon"
          ></app-double-border-icon>
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" class="step-text-container">
            <h4 class="h4 step-title-text text-center mt-0" [class.pt-24]="ltMd">{{ step.titleKey | translate }}</h4>
            <div class="typography-body-1 text-center">{{ step.descriptionKey | translate }}</div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!last">
        <mat-icon *ngIf="!ltMd; else southArrow" class="s-24 arrow-icon syncee-grey-200-fg">east</mat-icon>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #southArrow>
  <mat-icon class="s-24 arrow-icon">south</mat-icon>
</ng-template>
