<div
  class="product-details-supplier-shipping-overlay-container custom-elevation-z1"
  fxLayout="column"
  fxLayoutGap="20px"
>
  <div fxLayout="column" fxLayoutGap="4px">
    <h5 class="mt-0">{{ 'PRODUCT_DETAILS.SHIPPING_DETAILS' | translate }}</h5>
    <div class="typography-body-2" fxLayoutAlign="start center" fxLayoutGap="4px">
      <div>{{ 'PRODUCT_DETAILS.SHIPS_FROM' | translate }}</div>
      <div>{{ warehouseLocation }}</div>
      <img
        class="country-flag"
        [src]="warehouseLocation | countryNameToCodePipe | countryFlag"
        [alt]="warehouseLocation"
      />
    </div>
    <ng-container *ngIf="!isAutoOrder">
      <div class="typography-body-small-2">
        {{ 'PRODUCT_DETAILS.SHIPPING_MANAGED_INDIVIDUALLY' | translate }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="hasPreferredCountry; else noPreferredCountry">
    <div fxLayoutAlign="space-between center" class="typography-subtitle-small countries-title syncee-grey-200-border">
      <div>{{ 'PRODUCT_DETAILS.DESTINATION' | translate }}</div>
      <div>{{ 'PRODUCT_DETAILS.SHIPPING_TIME' | translate }}</div>
    </div>
    <div
      *ngFor="let country of countriesToShow"
      fxLayoutAlign="space-between center"
      class="typography-body-2 country-item syncee-grey-200-border"
    >
      <div fxLayoutAlign="center center" fxLayoutGap="4px">
        <img class="country-flag" [src]="country.code | countryFlag" [alt]="country.code" />
        <div>{{ country.code | countryCodeToName }}</div>
      </div>
      <div>
        {{ country.minShippingDays }} - {{ country.maxShippingDays }} {{ 'PRODUCT_DETAILS.BUSINESS_DAYS' | translate }}
      </div>
    </div>
  </div>
  <div class="typography-body-small-2">
    {{ 'PRODUCT_DETAILS.FOR_MORE_INFORMATION' | translate }}
    <span (click)="handleClick()" class="underline">{{ 'PRODUCT_DETAILS.CLICK_HERE' | translate }}</span>
  </div>
</div>

<ng-template #noPreferredCountry>
  <div
    routerLink="/retailer/settings/search-settings"
    class="typography-body-small-2 syncee-primary-50-bg p-8 no-preferred"
    [innerHTML]="'PRODUCT_DETAILS.NO_PREFERRED_COUNTRIES' | translate"
  ></div>
</ng-template>
