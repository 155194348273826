import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierCardComponent } from './supplier-card.component';
import { SupplierSliderBoxComponent } from './supplier-slider-box/supplier-slider-box.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SupplierIconComponent } from '../../../shared/components/supplier-icon/supplier-icon.component';
import { GetResourceFromStoragePipe } from '../../../shared/pipes/get-resource-from-storage';
import { CategoryNamesByIdsPipe } from '../../../shared/pipes/category-names-by-ids.pipe';
import { RouterLink } from '@angular/router';
import { FollowSupplierButtonComponent } from '../../../shared/components/follow-supplier-button/follow-supplier-button.component';
import { CountryFlagPipe } from '../../../shared/pipes/country_flag.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SupplierBrandImagePipe } from '../../../shared/pipes/supplier-brand-image.pipe';
import { SkeletonModule } from '../../../shared/components/skeleton/skeleton.module';
import { SliderArrowComponent } from '../../../shared/components/slider-arrow/slider-arrow.component';
import { CountryNameToCodePipe } from 'app/shared/pipes/legacy/country-name-to-code.pipe';
import { LazyloadProductImageDirective } from 'app/shared/directives/lazyload-product-image.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SupplierCardComponent, SupplierSliderBoxComponent],
  exports: [SupplierCardComponent, SupplierSliderBoxComponent],
  imports: [
    CommonModule,
    SlickCarouselModule,
    MatIconModule,
    FlexLayoutModule,
    SupplierIconComponent,
    GetResourceFromStoragePipe,
    CategoryNamesByIdsPipe,
    RouterLink,
    FollowSupplierButtonComponent,
    CountryFlagPipe,
    MatProgressSpinnerModule,
    SupplierBrandImagePipe,
    SkeletonModule,
    SliderArrowComponent,
    CountryNameToCodePipe,
    LazyloadProductImageDirective,
    TranslateModule,
  ],
})
export class SupplierCardModule {}
