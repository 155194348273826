import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AvtarWithIconThemedComponent } from '../avtar-with-icon-themed/avtar-with-icon-themed.component';
import { CommonAlertThemeType } from '../../../vo/theme-config/common-alert-theme-type';

@Component({
  selector: 'app-entry-component-dialog-wrapper',
  templateUrl: './entry-component-dialog-wrapper.component.html',
  styleUrls: ['./entry-component-dialog-wrapper.component.scss'],
  standalone: true,
  imports: [FlexModule, MatButtonModule, MatIconModule, MatDialogModule, AvtarWithIconThemedComponent],
})
export class EntryComponentDialogWrapperComponent implements OnInit {
  @Input() icon: string;
  @Input() themeType: CommonAlertThemeType;
  @Input() title: string;
  @Input() description: string;

  constructor() {}

  ngOnInit(): void {}
}
