import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { Field } from './field-selector.component';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-fields-maker',
  templateUrl: './fields-maker.component.html',
  styleUrls: ['./fields-maker.component.scss'],
})
export class FieldsMakerComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER];
  fruitCtrl = new UntypedFormControl();
  filteredAutoFields: any[];

  @Input() mappingFieldConfig: any[] = [];
  @Input() selectedFields: any[] = [];
  @Output() fieldsChanged = new EventEmitter<any[]>();

  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger;

  @ViewChild('fruitInput', { static: true }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor() {}

  ngOnInit(): void {
    this.initAutoFilteredValues();
    this.fruitCtrl.valueChanges
      .pipe(
        startWith(null),
        map((field: string | null) => {
          return !Utils.isNullOrUndefined(field) ? this._filter(field) : this.filteredAutoFields.slice();
        })
      )
      .subscribe((value) => {
        this.filteredAutoFields = value;
      });
  }

  add(event: MatChipInputEvent): void {
    setTimeout(() => {
      const input = event.input;
      const value = this.fruitInput.nativeElement.value;

      // Add our fruit
      if (value || '') {
        this.selectedFields.push({ group: null, field: { name: value, displayName: value, canBeFlat: false } });
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.fruitCtrl.setValue(null);
      this.fieldsChanged.emit(this.selectedFields);
    }, 500);
  }

  remove(field: any): void {
    if (!Utils.isNullOrUndefined(field.group) && field.group.isArray) {
      this.filteredAutoFields = this.mappingFieldConfig.slice();
    }

    const index = this.selectedFields.indexOf(field);

    if (index >= 0) {
      this.selectedFields.splice(index, 1);
    }

    this.fieldsChanged.emit(this.selectedFields);
  }

  initAutoFilteredValues(): void {
    const arrayGroupFields = this.selectedFields.find((field) => {
      return field.group.isArray === true;
    });

    if (!Utils.isNullOrUndefined(arrayGroupFields)) {
      this.filteredAutoFields = this.mappingFieldConfig
        .filter((mFieldConfig) => {
          return mFieldConfig.isArray === false || mFieldConfig.name === arrayGroupFields.group.name;
        })
        .slice();
    } else {
      this.filteredAutoFields = this.mappingFieldConfig.slice();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedFields.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.fieldsChanged.emit(this.selectedFields);
  }

  private _filter(value: any): any[] {
    const filteredFieldConfig = [];
    if (typeof value === 'object') {
      if (value.group.isArray) {
        return this.mappingFieldConfig
          .filter((mFieldConfig) => {
            return mFieldConfig.isArray === false || mFieldConfig.name === value.group.name;
          })
          .slice();
      }
      return this.mappingFieldConfig.slice();
    }

    const filterValue = value.toLowerCase();
    this.mappingFieldConfig.forEach((group) => {
      const fields = group.fields.filter((field) => field.displayName.toLowerCase().indexOf(filterValue) === 0);
      if (fields.length > 0) {
        const newGroup = Object.assign({}, group);
        newGroup.fields = fields;
        filteredFieldConfig.push(newGroup);
      }
    });

    return filteredFieldConfig;
  }

  getSelectedFields(): any[] {
    return this.selectedFields;
  }

  openAutoComplete(): void {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }
}
