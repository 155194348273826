import { Injectable } from '@angular/core';

import type { ChangeRoleDialogConfig } from '../model/change-role-dialog-config-type';

import { RETAILER_CONFIG_LIST, SUPPLIER_CONFIG_LIST } from '../change-role-dialog.config';

import { ChangeRoleService } from './change-role.service';

import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { hasEcomSelector } from '../../../store/ecom/ecom.selector';

import { map, take } from 'rxjs/operators';

import { RolesEnum } from '../../../vo/roles/roles';
import { Step } from '../model/step';
import { ChangeRoleDialogDataStepTextRewrite } from '../model/change-role-dialog-data';

@Injectable()
export class StepperService {
  private _configList = [];

  private _currentStepIndex = 0;

  constructor(private store: Store<AppState>, private changeRoleService: ChangeRoleService) {}

  public initStepper(role: RolesEnum, textRewrite: ChangeRoleDialogDataStepTextRewrite[] = []): void {
    this.store
      .select(hasEcomSelector, role === RolesEnum.RETAILER ? RolesEnum.SUPPLIER : RolesEnum.RETAILER)
      .pipe(
        take(1),
        map((hasEcom: boolean) => {
          switch (role) {
            case RolesEnum.RETAILER:
              return hasEcom ? this.createConfigListForUserWithEcom(RETAILER_CONFIG_LIST) : RETAILER_CONFIG_LIST;
            case RolesEnum.SUPPLIER:
              return hasEcom ? this.createConfigListForUserWithEcom(SUPPLIER_CONFIG_LIST) : SUPPLIER_CONFIG_LIST;
            default:
              return [];
          }
        }),
        map((list) => {
          return list.map((item) => {
            const found = textRewrite.find((rewrite) => rewrite.key === item.step);
            return !!found ? { ...item, payload: { ...item.payload, ...found } } : item;
          });
        })
      )
      .subscribe((list) => (this._configList = list));
  }

  private createConfigListForUserWithEcom(configList: ChangeRoleDialogConfig[]): ChangeRoleDialogConfig[] {
    // we set the keepRole property to true, because it has ecom
    this.changeRoleService.keepRole = true;
    // we filter out the KEEP_ROLE step
    return configList.filter((config) => config.step !== Step.KEEP_ROLE);
  }

  public back(): void {
    if (!this.canGoBack) {
      return;
    }

    this._currentStepIndex -= 1;
  }

  public forward(): void {
    if (this._configList.length - 1 === this._currentStepIndex) {
      return;
    }

    this._currentStepIndex += 1;
  }

  get canGoBack(): boolean {
    return this._currentStepIndex !== 0;
  }

  get config(): ChangeRoleDialogConfig {
    return this._configList[this._currentStepIndex];
  }

  public get hasBanner(): boolean {
    return this._configList[this._currentStepIndex].step === Step.ENTER;
  }
}
