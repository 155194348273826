import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-image',
  templateUrl: './skeleton-image.component.html',
  styleUrls: ['./skeleton-image.component.scss'],
})
export class SkeletonImageComponent {
  @Input() public width = '100%';

  @Input() public height = '100%';

  @Input() public borderRadius = '0px';
}
