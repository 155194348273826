import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { SetUpAccountFormComponent } from '../../../../../shared/components/set-up-account-form/set-up-account-form.component';
import { SetUpAccountFormValue } from '../../../../../shared/components/set-up-account-form/model/set-up-account-form-value';
import { combineLatest, Observable, Subject } from 'rxjs';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { SubStepsStepperComponent } from '../../../components/sub-steps-stepper/sub-steps-stepper.component';
import {
  CustomOptionDto,
  UserFeedbackComponent,
} from '../../../../../shared/components/user-feedback/user-feedback.component';
import { UserFeedbackService } from '../../../../../service/user-feedback/user-feedback.service';
import { SetUpAccountFormService } from '../../../../../shared/components/set-up-account-form/service/set-up-account-form.service';
import { SupplierDetailsService } from '../../../../../service/suppliers/supplier-details.service';
import { SupplierGatewayService } from '../../../../../service/suppliers/supplier-gateway.service';
import { StepDataModel } from '../../../components/sub-steps-stepper/model/step-data.model';
import { PersonalizationStepTitle } from '../../retailer/personalization/personalization.component';
import { RolesEnum } from '../../../../../vo/roles/roles';
import { GETTING_STARTED_CONTENT_MAX_WIDTH } from '../../../../../utils/getting-started/getting-started-style-settings';

@Component({
  selector: 'app-general-information-supplier',
  templateUrl: './general-information-supplier.component.html',
  styleUrls: ['./general-information-supplier.component.scss'],
  providers: [SetUpAccountFormService],
})
export class GeneralInformationSupplierComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  @ViewChild('setUpAccountFormComponent') setUpForm: SetUpAccountFormComponent;
  @ViewChild('stepperComponent') stepper: SubStepsStepperComponent;
  @ViewChild('userFeedbackComponent') feedback: UserFeedbackComponent;

  value$: Observable<SetUpAccountFormValue>;
  isLoading = false;
  hasOwnInventory: boolean = null;
  hasOwnInventoryChange: boolean;
  currentStepIndex = 0;
  stepTitles = GENERAL_INFORMATION_SUPPLIER_STEP_TITLES;
  bestDescribesCustomSource$: Observable<Record<string, CustomOptionDto[]>>;
  contentMaxWidth = GETTING_STARTED_CONTENT_MAX_WIDTH;

  private unsubscribeAll: Subject<void>;

  steps: StepDataModel[] = [
    {
      optional: false,
      state: 'current',
      label: 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.STEPPER_LABELS.CONTACT_DETAILS',
      isDone: false,
    },
    {
      optional: false,
      state: 'default',
      label: 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.STEPPER_LABELS.BUSINESS_DETAILS',
      isDone: false,
    },
  ];
  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private userFeedbackService: UserFeedbackService,
    private setUpAccountFormService: SetUpAccountFormService,
    private supplierDetailsService: SupplierDetailsService,
    private supplierGatewayService: SupplierGatewayService
  ) {
    super(gettingStartedStepBaseService);
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    if (this.isCompleted) {
      this.steps.map((step) => (step.isDone = true));
    }
    this.currentStepIndex = this.initCurrentSubStep(this.steps);
    this.value$ = this.setUpAccountFormService.getSetUpAccountFormValueSupplier();
    this.bestDescribesCustomSource$ = this.userFeedbackService.bestDescribesCustomSource$;
    this.supplierGatewayService
      .getCurrentUserSupplier()
      .subscribe((supplier) => (this.hasOwnInventory = supplier.ownInventory));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleSubStepChange(index: number): void {
    this.currentStepIndex = index;
    this.setCurrentSubStep(index);
  }

  nextClicked(): void {
    switch (this.currentStepIndex) {
      case 0: {
        this.handleFirstPageNext();
        break;
      }
      case 1: {
        this.handleSecondPageNext();
        break;
      }
    }
  }

  private handleFirstPageNext(): void {
    if (this.setUpForm.isValid && !this.setUpForm.form.dirty) {
      this.completeCurrentStep();
      this.incrementCurrentSubStep(this.currentStepIndex);
      this.stepper.navigateSteps.next('next');
      return;
    }
    if (this.setUpForm.isValid) {
      this.setUpForm.saveSupplierDetails().subscribe(() => {
        this.completeCurrentStep();
        this.incrementCurrentSubStep(this.currentStepIndex);
        this.stepper.navigateSteps.next('next');
      });
    } else {
      this.setUpForm.form.markAllAsTouched();
    }
  }

  private handleSecondPageNext(): void {
    this.feedback.feedbackFormGroup.updateValueAndValidity();
    if (this.feedback.feedbackFormGroup.valid && !this.feedback.feedbackFormGroup.dirty && !this.ownInventoryChanged) {
      this.emptyCurrentSubStep();
      this.completeCurrentStep();
      this.goToNextStep();
      return;
    }
    if (this.feedback.feedbackFormGroup.invalid) {
      this.feedback.feedbackFormGroup.markAllAsTouched();
      return;
    }

    this.saveFeedbackAndOwnInventory();
  }

  private saveFeedbackAndOwnInventory(): void {
    const saveObservables = [
      ...(this.ownInventoryChanged ? [this.saveSupplierHasOwnInventory()] : []),
      ...(this.feedback.feedbackFormGroup.valid && this.feedback.feedbackFormGroup.dirty
        ? [this.feedback.saveFeedbacks()]
        : []),
    ];

    if (saveObservables.length) {
      combineLatest(saveObservables).subscribe(() => {
        this.completeCurrentStep();
        this.goToNextStep();
      });
    }
  }

  private completeCurrentStep(): void {
    this.stepper.completeStep.next({ stepIndex: this.currentStepIndex, complete: true });
  }

  prevClicked(): void {
    this.decrementCurrentSubStep(this.currentStepIndex);
    this.stepper.navigateSteps.next('prev');
  }

  handleOwnPhysicalInventoryChange(value: boolean): void {
    this.hasOwnInventoryChange = value;
  }

  private saveSupplierHasOwnInventory(): Observable<void> {
    if (this.ownInventoryChanged) {
      return this.supplierDetailsService.saveCurrentUserSupplier(
        { ownInventory: this.hasOwnInventoryChange ? '1' : '0' },
        true
      );
    }
  }

  get ownInventoryChanged(): boolean {
    return this.hasOwnInventoryChange !== this.hasOwnInventory;
  }

  get rolesEnum(): typeof RolesEnum {
    return RolesEnum;
  }
}

const GENERAL_INFORMATION_SUPPLIER_STEP_TITLES: PersonalizationStepTitle[] = [
  {
    title: 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.CONTACT_DETAILS.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.CONTACT_DETAILS.SUBTITLE',
  },
  {
    title: 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.BUSINESS_DETAILS.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.BUSINESS_DETAILS.SUBTITLE',
  },
];
