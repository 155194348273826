<div class="authentication-form-content">
  <img class="authentication-syncee-logo-mobile" src="assets/images/logos/syncee-logo.svg" />
  <div fxLayout="column" fxLayoutGap="4px">
    <h2 class="mt-0">{{ 'AUTHENTICATION.LOGIN.SUPPLIER_LOGIN_CONTENT.TITLE' | translate }}</h2>
    <div class="text-center typography-body-1">
      {{ 'AUTHENTICATION.LOGIN.SUPPLIER_LOGIN_CONTENT.SUBTITLE' | translate }}
    </div>
  </div>
  <div class="w-100p" fxLayout="column" fxLayoutGap="32px">
    <app-login-form [role]="supplierRole"></app-login-form>
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.NEW_TO_SYNCEE' | translate }}
      <a href="#" (click)="navigateToSupplierRegister($event)">{{ 'AUTHENTICATION.SIGN_UP' | translate }}</a>
    </div>
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.LOGIN.SUPPLIER_LOGIN_CONTENT.ARE_YOU_A_RETAILER' | translate }}
      <a href="#" (click)="navigate($event)">{{
        'AUTHENTICATION.LOGIN.SUPPLIER_LOGIN_CONTENT.LOGIN_AS_A_RETAILER' | translate
      }}</a>
    </div>
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.WANT_TO_BE_RETAILER' | translate }}
      <a href="#" (click)="navigateToRetailerRegister()"> {{ 'AUTHENTICATION.APPLY_RETAILER' | translate }}</a>
    </div>
  </div>
</div>
