<div class="product-details-container" fxLayout="column" fxLayoutGap="12px">
  <app-marketplace-badges class="badges" [badges]="product.BADGES"></app-marketplace-badges>
  <div fxLayout="column" fxLayoutGap="4px">
    <div class="supplier" fxLayoutAlign="start center" fxLayoutGap="4px">
      <div fxFlex="0 1 auto" class="supplier-text">
        {{ 'COMMON_WORDS.BY_LC' | translate }}
        <u (click)="navigateToStorefront()" target="_blank">
          <span class="highlight">{{ product.SUPPLIER.companyName }}</span>
        </u>
      </div>
      <img
        fxFlex="0 0 auto"
        class="country-flag"
        [alt]="product.SETTINGS?.warehouseLocation"
        [src]="product.SETTINGS?.warehouseLocation | countryNameToCodePipe | countryFlag"
      />
    </div>
    <a class="title" [href]="productPath" target="_blank">
      {{ product.TITLE }}
    </a>
  </div>
  <app-product-prices
    *onlyLoggedIn
    [product]="product"
    [ecomCurrency]="ecomCurrency"
    [catalogStatus]="catalogStatus"
  ></app-product-prices>
  <app-product-prices-guest (click)="openRegistration()" *onlyGuest></app-product-prices-guest>
  <app-product-details-supplier-shipping
    *onlyLoggedIn
    [product]="product"
    [ecomCurrency]="ecomCurrency"
  ></app-product-details-supplier-shipping>
</div>
