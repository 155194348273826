import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteModalVO } from '../../../shared/components/dialogs/delete-modal/delete-modal-vo';
import { NotificationService } from '../../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogsService } from 'app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';

export interface UpdateFields {
  name: string;
  id: string;
  checked?: boolean;
  tooltip?: string;
  disabled?: boolean;
}

export const SHOPIFY_VARIANTS_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'variants.sku', name: 'Variant SKU' },
  { id: 'variants.option1', name: 'Option value1' },
  { id: 'variants.option2', name: 'Option value2' },
  { id: 'variants.option3', name: 'Option value3' },
  { id: 'variants.price', name: 'Price' },
  { id: 'variants.compare_at_price', name: 'Compare at price' },
  { id: 'variants.cost', name: 'Cost Per Item' },
  { id: 'inventory_management.available', name: 'Inventory Quantity' },
  { id: 'variants.barcode', name: 'Barcode' },
  { id: 'variants.weight', name: 'Weight' },
];

export const SHOPIFY_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'handle', name: 'Handle', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.HANDLE' },
  { id: 'product_type', name: 'Product type', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PRODUCT_TYPE' },
  { id: 'title', name: 'Title', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.TITLE' },
  { id: 'variants.sku', name: 'Variant SKU', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.VARIANT_SKU' },
  { id: 'body_html', name: 'Description', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.DESCRIPTION' },
  // {name: 'metafields', display: 'Metafields'},
  { id: 'options.name', name: 'Option name', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_NAME' },
  { id: 'variants.option1', name: 'Option value1', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_1' },
  { id: 'variants.option2', name: 'Option value2', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_2' },
  { id: 'variants.option3', name: 'Option value3', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_3' },
  { id: 'tags', name: 'Tags', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.TAGS' },
  { id: 'vendor', name: 'Vendor', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.VENDOR' },
  { id: 'variants.price', name: 'Price', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PRICE' },
  {
    id: 'variants.compare_at_price',
    name: 'Compare at price',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COMPARE_PRICE',
  },
  { id: 'variants.cost', name: 'Cost Per Item', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COST_PER_ITEM' },
  {
    id: 'inventory_management.available',
    name: 'Inventory Quantity',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.INV_QTY',
  },
  { id: 'variants.barcode', name: 'Barcode', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.BARCODE' },
  { id: 'variants.weight', name: 'Weight', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.WEIGHT' },
  //  {name: '', display: 'Option name'},
  {
    id: 'metafields_global_description_tag',
    name: 'Meta Description (website seo)',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.META_DESC',
  },
  {
    id: 'metafields_global_title_tag',
    name: 'Page Title (website seo)',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PAGE_TITLE',
  },
  { id: 'collections', name: 'Collections', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COLLECTIONS' },
  {
    id: 'image_recreate',
    name: 'Images Recreate',
    tooltip: 'RETAILER_UPDATE_CATALOG.REUPLOAD_IMAGE_CONF_DIALOG_MESSAGE',
  },
];

export const SHOPIFY_UPDATE_OPTIONS: UpdateFields[] = [
  {
    id: 'published_scope',
    name: 'Point of Sale visibility',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.POINT_OF_VISIBILITY',
  },
  { id: 'variants.inventory_policy', name: 'Backorder', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.BACKORDER' },
  {
    id: 'variants.requires_shipping',
    name: 'This product requires shipping',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.REQUIRED_SHIPPING',
  },
  {
    id: 'variants.fulfillment_service',
    name: 'Fulfillment Service',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.FULLFILMENT_SERVICE',
  },
  { id: 'variants.weight_unit', name: 'Weight unit', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.WEIGHT_UNIT' },
  {
    id: 'variants.taxable',
    name: 'Charge taxes on this product',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.CHARGE_TAX',
  },
  { id: 'published_at', name: 'Published', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PUBLISHED' },
];

export const JUMPSELLER_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'title', name: 'Name' },
  { id: 'variants.sku', name: 'SKU' },
  { id: 'variants.price', name: 'Price' },
  { id: 'inventory_management.available', name: 'Stock' },
  { id: 'collections', name: 'Categories' },
  { id: 'body_html', name: 'Description' },
  { id: 'brand', name: 'Brand' },
  { id: 'variants.barcode', name: 'Barcode' },
  { id: 'metafields_global_title_tag', name: 'Page Title' },
  { id: 'metafields_global_description_tag', name: 'Meta Description' },
  { id: 'shopify_handle', name: 'URL' },
  { id: 'variants.weight', name: 'Weight' },
  { id: 'options.name', name: 'Option name' },
  { id: 'variants.option1', name: 'Option value1' },
  { id: 'variants.option2', name: 'Option value2' },
  { id: 'variants.option3', name: 'Option value3' },
  { id: 'images', name: 'Images' },
];

export const JUMPSELLER_UPDATE_OPTIONS: UpdateFields[] = [{ id: 'status', name: 'Status' }];

export const SHOPRENTER_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'title', name: 'Product Name' },
  { id: 'variants.sku', name: 'SKU' },
  { id: 'body_html', name: 'Description' },
  // {name: 'metafields', display: 'Metafields'},
  { id: 'variants.option1', name: 'Option value1' },
  { id: 'variants.option2', name: 'Option value2' },
  // {id: 'variants.option3', name: 'Option value3'},
  { id: 'tags', name: 'Tags' },
  { id: 'vendor', name: 'Manufacturer' },
  { id: 'variants.price', name: 'Price' },
  { id: 'inventory_management.cost', name: 'Cost' },
  { id: 'inventory_management.available', name: 'Stock Quantity' },
  { id: 'variants.barcode', name: 'GTIN' },
  { id: 'variants.weight', name: 'Weight' },
  { id: 'variants.length', name: 'Length' },
  { id: 'variants.width', name: 'Width' },
  { id: 'variants.height', name: 'Height' },
  { id: 'metafields_global_description_tag', name: 'Meta Description (website seo)' },
  { id: 'metafields_global_title_tag', name: 'Page Title (website seo)' },
  { id: 'volumeUnit', name: 'Volume Unit' },
  { id: 'weightUnit', name: 'Weight Unit' },
  { id: 'attributesName', name: 'Attributes Name' },
  { id: 'attributesValue', name: 'Attributes Value' },
  // {id: 'images', name: 'Images'}
];

export const SHOPRENTER_UPDATE_OPTIONS: UpdateFields[] = [
  { id: 'variants.inventory_policy', name: 'No Stock Status' },
  // {id: 'requires_shipping', name: 'This product requires shipping'},
  { id: 'published_at', name: 'Status' },
  { id: 'collections', name: 'Category' },
  { id: 'languages', name: 'Languages' },
  { id: 'taxable', name: 'Taxes' },
  { id: 'freeShipping', name: 'Free Shipping' },
  { id: 'orderable', name: 'Orderable' },
  { id: 'noStockStatus', name: 'No Stock Status' },
  { id: 'inStockStatus', name: 'In Stock Status' },
  { id: 'stockSettings', name: 'Stock Settings' },
  { id: 'stock1Status', name: 'Stock 1 status' },
  { id: 'stock2Status', name: 'Stock 2 status' },
  { id: 'stock3Status', name: 'Stock 3 status' },
  { id: 'stock4Status', name: 'Stock 4 status' },
];

export const ECWID_UPDATE_FIELDS: UpdateFields[] = [
  // {id: 'handle', name: 'Handle'},
  { id: 'title', name: 'Title' },
  { id: 'sku', name: 'Variant SKU' }, // product, és variant is
  // {id: 'variants.sku', name: 'Variant SKU'},
  { id: 'body_html', name: 'Description' },
  // {id: 'attributes', name: 'Attributes'}, //Ha nem lehet külön külön frissíteni a type-ot,
  // brand-et, upc-t akkor egybe marad Attributes-ként

  { id: 'attributes.BRAND', name: 'Brand' },
  { id: 'attributes.UPC', name: 'Upc' },
  { id: 'attributes.type', name: 'Product Type' },

  // {id: 'barcode', name: 'Barcode'},
  // {name: 'metafields', display: 'Metafields'},
  { id: 'variants.options', name: 'Options' },
  // {id: 'images', name: 'Images'},
  // {id: 'variants.option1', name: 'Option value1'},
  // {id: 'variants.option2', name: 'Option value2'},
  // {id: 'variants.option3', name: 'Option value3'},
  // {id: 'tags', name: 'Tags'},
  // {id: 'vendor', name: 'Vendor'},
  { id: 'variants.price', name: 'Price' },
  { id: 'categoryIds', name: 'Categories' },
  // {id: 'variants.compare_at_price', name: 'Compare at price'},
  // {id: 'variants.cost', name: 'Cost Per Item'},
  { id: 'inventory_management.available', name: 'Inventory Quantity' },
  // {id: 'variants.barcode', name: 'Barcode'},
  { id: 'weight', name: 'Weight' }, // product, és variant is
  // {id: 'variants.weight', name: 'Variant Weight'},
  // {id: 'ProductDimensions', name: 'Product Dimensions'},
  //  {name: '', display: 'Option name'},
  { id: 'metafields_global_description_tag', name: 'Meta Description (website seo)' },
  { id: 'metafields_global_title_tag', name: 'Page Title (website seo)' },
  // {id: 'collections', name: 'Collections'}
];

export const ECWID_UPDATE_OPTIONS: UpdateFields[] = [
  // {id: 'published_scope', name: 'Point of Sale visibility'},
  // {id: 'variants.inventory_policy', name: 'Backorder'},
  // {id: 'isShippingRequired', name: 'This product requires shipping'},
  // {id: 'variants.fulfillment_service', name: 'Fulfillment Service'},
  { id: 'enabled', name: 'Status' },
  // {id: 'weight_unit', name: 'Weight unit'},
  // {id: 'variants.taxable', name: 'Charge taxes on this product'}
];

export const SQUARESPACE_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'title', name: 'Title' },
  { id: 'variants.sku', name: 'Variant SKU' },
  { id: 'body_html', name: 'Description' },
  // {id: 'images', name: 'Images'},
  { id: 'tags', name: 'Tags' },
  { id: 'variants.price', name: 'Price' },
  { id: 'inventory_management.available', name: 'Inventory Stock' },
  // {id: 'productPage', name: 'Product Page'}
];

export const SQUARESPACE_UPDATE_OPTIONS: UpdateFields[] = [
  { id: 'isVisible', name: 'Published' },
  { id: 'variants.shippingMeasurements', name: 'Dimensions' },
];

export const BIGCOMMERCE_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'name', name: 'Name' },
  { id: 'variants.sku', name: 'Product SKU' },
  { id: 'description', name: 'Description' },

  { id: 'brand_name', name: 'Brand' },
  { id: 'upc', name: 'Upc' },

  { id: 'variants.option_values', name: 'Options' },
  { id: 'images', name: 'Images' },
  { id: 'variants.price', name: 'Price' },
  { id: 'categories', name: 'Categories' },
  { id: 'variants.inventory_level', name: 'Inventory Level' },
  // {id: 'inventory_tracking', name: 'Inventory Tracking'},
  { id: 'variants.barcode', name: 'Barcode' },
  { id: 'variants.weight', name: 'Weight' },
  // {id: 'depth', name: 'Depth'},
  // {id: 'width', name: 'Width'},
  // {id: 'height', name: 'Height'},
  { id: 'meta_description', name: 'Meta Description (website seo)' },
  { id: 'page_title', name: 'Page Title (website seo)' },
];

export const BIGCOMMERCE_UPDATE_OPTIONS: UpdateFields[] = [
  { id: 'is_visible', name: 'Is Visible' },
  { id: 'availability', name: 'Availability' },
];

export const WIX_UPDATE_FIELDS: UpdateFields[] = [
  // {id: 'handle', name: 'Handle'},
  { id: 'name', name: 'Name' },
  { id: 'variants.variant.sku', name: 'Variant SKU' }, // product, és variant is
  { id: 'description', name: 'Description' },

  { id: 'product_type', name: 'Product Type' },
  { id: 'brand', name: 'Brand' },

  { id: 'variants.variant.priceData.price', name: 'Price' },
  { id: 'collections', name: 'Collections' },
  { id: 'variants.variant.qty', name: 'Inventory Quantity' },
  { id: 'variants.variant.weight', name: 'Weight' },
  { id: 'variants.variant.sku', name: 'SKU' },
];

export const WIX_UPDATE_OPTIONS: UpdateFields[] = [{ id: 'visible', name: 'Is Visible' }];

export const WOOCOMMERCE_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'name', name: 'Name' },
  // {id: 'variants.sku', name: 'Variant SKU'},
  { id: 'description', name: 'Description' },
  // {id: 'short_description', name: 'Short Description'},
  { id: 'tags', name: 'Tags' },
  { id: 'categories', name: 'Categories' },
  { id: 'attributes', name: 'Attributes' },

  { id: 'variations.regular_price', name: 'Regular Price' },
  { id: 'variations.stock_quantity', name: 'Stock Quantity' },
  { id: 'variations.weight', name: 'Weight' },
  { id: 'images', name: 'Images' },
  { id: 'sku', name: 'SKU' },
];

export const WOOCOMMERCE_UPDATE_OPTIONS: UpdateFields[] = [{ id: 'status', name: 'Status' }];

export const EKM_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'live', name: 'Live' },
  { id: 'media', name: 'Images' },
  { id: 'name', name: 'Name' },
  { id: 'description', name: 'Description' },
  { id: 'short_description', name: 'Short Description' },
  // {id: 'images', name: 'Images'},
  { id: 'brand', name: 'Brand' },
  { id: 'category_id', name: 'Category' },
  { id: 'total_product_stock', name: 'Total Inventory Stock' },
  { id: 'number_in_stock', name: 'Variant Inventory Stock' },
  { id: 'price', name: 'Price' },
  { id: 'product_code', name: 'Product Code (SKU)' },
  { id: 'product_weight', name: 'Product Weight' },
];

export const EKM_UPDATE_OPTIONS: UpdateFields[] = [
  // {id: 'isVisible', name: 'Published'},
  // {id: 'variants.shippingMeasurements', name: 'Dimensions'},
];

export const SHOPLAZZA_UPDATE_FIELDS: UpdateFields[] = [
  { id: 'handle', name: 'Handle', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.HANDLE' },
  // {id: 'product_type', name: 'Product type', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PRODUCT_TYPE'},
  { id: 'title', name: 'Title', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.TITLE' },
  { id: 'variants.sku', name: 'Variant SKU', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.VARIANT_SKU' },
  { id: 'variants.barcode', name: 'Variant Barcode', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.BARCODE' },
  { id: 'description', name: 'Description', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.DESCRIPTION' },
  { id: 'brief', name: 'Brief', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.DESCRIPTION' },
  // {name: 'metafields', display: 'Metafields'},
  { id: 'options.name', name: 'Option name', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_NAME' },
  { id: 'variants.option1', name: 'Option value1', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_1' },
  { id: 'variants.option2', name: 'Option value2', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_2' },
  { id: 'variants.option3', name: 'Option value3', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.OPTION_VALUE_3' },
  { id: 'tags', name: 'Tags', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.TAGS' },
  { id: 'vendor', name: 'Vendor', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.VENDOR' },
  { id: 'variants.price', name: 'Price', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.PRICE' },
  // {id: 'variants.compare_at_price', name: 'Compare at price', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COMPARE_PRICE'},
  // {id: 'variants.cost', name: 'Cost Per Item', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COST_PER_ITEM'},
  {
    id: 'variants.inventory_quantity',
    name: 'Inventory Quantity',
    tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.INV_QTY',
  },
  { id: 'variants.weight', name: 'Weight', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.WEIGHT' },
  //  {name: '', display: 'Option name'},
  { id: 'collections', name: 'Collections', tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.COLLECTIONS' },
];

export const SHOPLAZZA_UPDATE_OPTIONS: UpdateFields[] = [
  // {id: 'isVisible', name: 'Published'},
  // {id: 'variants.shippingMeasurements', name: 'Dimensions'},
];

@Component({
  selector: 'app-retailer-update-catalog-dialog',
  templateUrl: './retailer-update-catalog-dialog.component.html',
  styleUrls: ['./retailer-update-catalog-dialog.component.scss'],
})
export class RetailerUpdateCatalogDialogComponent implements OnInit {
  // OLD_TODO: ha valami oknál fogva mégis tölteni fogjuk a compare-t és a cost-ot az rcatalog-oknál, akkor ezt ki kell venni majd innen
  isRCatUpdtae = false;
  skipOnRCatalogUpdate = ['inventory_management.cost', 'variants.compare_at_price'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RetailerUpdateCatalogDialogComponent>,
    private notificationService: NotificationService,
    private confDialogService: ConfirmationDialogsService,
    private translate: TranslateService
  ) {}

  fieldsUpdate: UpdateFields[];
  optionFieldsUpdate: UpdateFields[];

  ngOnInit(): void {
    this.isRCatUpdtae = this.data.isRCatalog;
    this.initByEcomType();
  }

  submit(): void {
    let updatableFields = [];
    if (this.data.ecomType === 'shopify') {
      const isForceFieldsChecked = this.optionFieldsUpdate.find((field) =>
        ['published_force', 'unpublished_force'].includes(field.id)
      );
      if (isForceFieldsChecked) {
        this.optionFieldsUpdate = this.optionFieldsUpdate.filter((field) => field.id !== 'published_at');
      }
    }

    this.fieldsUpdate.forEach((value) => {
      if (value.checked) {
        updatableFields.push(value.id);
      }
    });
    this.optionFieldsUpdate.forEach((value) => {
      if (value.checked) {
        updatableFields.push(value.id);
      }
    });

    const imageRecreateField = this.fieldsUpdate.find((field) => field.id === 'image_recreate' && field.checked);

    if (imageRecreateField) {
      updatableFields = ['image_recreate'];

      this.confDialogService
        .confirm(
          this.translate.instant('PIM_EDITOR.CONF_DIALOG.TITLE'),
          this.translate.instant('RETAILER_UPDATE_CATALOG.REUPLOAD_IMAGE_CONF_DIALOG_MESSAGE')
        )
        .subscribe((resp) => {
          if (resp) {
            this.dialogRef.close(updatableFields);
          }
        });

      return;
    }

    if (updatableFields.length < 1) {
      this.notificationService.warning(this.translate.instant('RETAILER_UPDATE_CATALOG.SELECT_ERROR'));
      return;
    }
    this.dialogRef.close(updatableFields);
  }

  initByEcomType(): void {
    switch (this.data.ecomType) {
      case 'shopify':
        this.fieldsUpdate = JSON.parse(JSON.stringify(SHOPIFY_UPDATE_FIELDS)); // deep copy
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(SHOPIFY_UPDATE_OPTIONS));
        break;
      case 'jumpseller':
        this.fieldsUpdate = JSON.parse(JSON.stringify(JUMPSELLER_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(JUMPSELLER_UPDATE_OPTIONS));
        break;
      case 'shoprenter':
        this.fieldsUpdate = JSON.parse(JSON.stringify(SHOPRENTER_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(SHOPRENTER_UPDATE_OPTIONS));
        break;
      case 'ecwid':
        this.fieldsUpdate = JSON.parse(JSON.stringify(ECWID_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(ECWID_UPDATE_OPTIONS));
        break;
      case 'squarespace':
        this.fieldsUpdate = JSON.parse(JSON.stringify(SQUARESPACE_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(SQUARESPACE_UPDATE_OPTIONS));
        break;
      case 'bigcommerce':
        this.fieldsUpdate = JSON.parse(JSON.stringify(BIGCOMMERCE_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(BIGCOMMERCE_UPDATE_OPTIONS));
        break;
      case 'wix':
        this.fieldsUpdate = JSON.parse(JSON.stringify(WIX_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(WIX_UPDATE_OPTIONS));
        break;
      case 'woocommerce':
        this.fieldsUpdate = JSON.parse(JSON.stringify(WOOCOMMERCE_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(WOOCOMMERCE_UPDATE_OPTIONS));
        break;
      case 'ekm':
        this.fieldsUpdate = JSON.parse(JSON.stringify(EKM_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(EKM_UPDATE_OPTIONS));
        break;
      case 'shoplazza':
        this.fieldsUpdate = JSON.parse(JSON.stringify(SHOPLAZZA_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(SHOPLAZZA_UPDATE_OPTIONS));
        break;
      default:
        this.fieldsUpdate = JSON.parse(JSON.stringify(SHOPIFY_UPDATE_FIELDS));
        this.optionFieldsUpdate = JSON.parse(JSON.stringify(SHOPIFY_UPDATE_OPTIONS));
        break;
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  onFieldUpdateChanged(field): void {
    if (field.id !== 'image_recreate') {
      return;
    }

    if (field.checked) {
      this.disableAllFields(this.fieldsUpdate, true, ['image_recreate']);
      this.disableAllFields(this.optionFieldsUpdate, true);
    } else {
      this.disableAllFields(this.fieldsUpdate, false);
      this.disableAllFields(this.optionFieldsUpdate, false);
    }
  }

  disableAllFields(fields: UpdateFields[], disable = true, exceptions = []): void {
    fields.forEach((field) => (field.disabled = !exceptions.includes(field.id) ? disable : field.disabled));
  }

  onOptionChanged(field, event: MatCheckboxChange): void {
    if (field.id === 'published_force') {
      const pfField = this.optionFieldsUpdate.find((f) => f.id === 'unpublished_force');
      pfField['checked'] = false;
    }
    if (field.id === 'unpublished_force') {
      const pfField = this.optionFieldsUpdate.find((f) => f.id === 'published_force');
      pfField['checked'] = false;
    }
    if (field.id === 'published_at' && this.data.ecomType === 'shopify') {
      if (field.checked === true) {
        this.optionFieldsUpdate.push({
          id: 'published_force',
          name: 'Force publish',
          tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.FORCE_PUBLISHED',
        });
        this.optionFieldsUpdate.push({
          id: 'unpublished_force',
          name: 'Force unpublish',
          tooltip: 'RETAILER_UPDATE_CATALOG.FIELDS_TOOLTIPS.FORCE_UNPUBLISHED',
        });
      } else {
        this.optionFieldsUpdate = this.optionFieldsUpdate.filter(
          (f) => f.id !== 'published_force' && f.id !== 'unpublished_force'
        );
      }
    }
  }
}
