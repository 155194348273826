import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { iif, Observable } from 'rxjs';
import { Social } from '../../vo/social/social';
import { mergeMap, switchMap } from 'rxjs/operators';
import { SocialPlatform, SocialPlatformType } from '../../vo/social/social-platform';
import { SocialPlatformToUser } from '../../vo/social/social-platform-to-user';

@Injectable({
  providedIn: 'root',
})
export class UserSocialService {
  constructor(private springRestService: SpringRestService) {}

  saveSocialPlatforms(userId: number, platforms: SocialPlatformToUser[]): Observable<void> {
    return this.getSocial(userId).pipe(
      switchMap((socials) =>
        iif(
          () => !!socials && socials.length > 0,
          this.deleteSocialPlatforms(
            userId,
            socials.map((s) => s.platform)
          ).pipe(mergeMap(() => this.postSocialPlatforms(userId, platforms))),
          this.postSocialPlatforms(userId, platforms)
        )
      )
    );
  }

  postSocialPlatforms(userId: number, platforms: SocialPlatformToUser[]): Observable<void> {
    return this.springRestService.post(MicroserviceNames.USER, `/User/${userId}/Social`, platforms, undefined, true);
  }

  deleteSocialPlatforms(userId: number, platforms: SocialPlatformType[]): Observable<void> {
    return this.springRestService.delete(
      MicroserviceNames.USER,
      `/User/${userId}/Social`,
      { platforms: platforms.join(',') },
      true
    );
  }

  getSocialPlatforms(): Observable<SocialPlatform[]> {
    return this.springRestService.get(MicroserviceNames.USER, `/User/Social/Platforms`, undefined, true);
  }

  getSocial(userId: number): Observable<Social[]> {
    return this.springRestService.get(MicroserviceNames.USER, `/User/${userId}/Social`, undefined, true);
  }
}
