import {
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AdminCommentComponent } from '../components/admin-comment/admin-comment.component';
import { UserService } from '../../service/user/user.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, take } from 'rxjs/operators';
import { includes } from 'lodash';
import { SCOPES } from '../../service/permission/scopes';
import { PermissionService } from '../../service/permission/permission.service';
import { getIsAdminScopeSelector, getScopesSelector } from '../../store/authentication/authentication.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Directive({
  selector: '[adminComment]',
  standalone: true,
})
export class AdminCommentDirective implements OnChanges {
  @Input() adminCommentTaskId: number;
  @Input() adminCommentStep: string;
  @Input() adminCommentComments: any;
  @Input() adminCommentTaskType: string;
  @Input() adminCommentMappingField: string;

  @Input() adminCommentWrapperClasses = ['pt-8'];
  @Input() adminCommentMainWrapperClasses = ['admin-comment-wrapper-main'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef,
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private permissionService: PermissionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.permissionService
      .getHasAdminScope()
      .pipe(take(1))
      .subscribe((adminScope) => {
        if (adminScope) {
          this.insertComponent();
        }
      });
  }

  private insertComponent(): void {
    const parent = this.el.nativeElement.parentNode;
    const adminComponent = this.createAdminCommentComponent();
    adminComponent.location.nativeElement.classList.add(this.adminCommentWrapperClasses);
    const wrapper = this.createWrapper(this.adminCommentMainWrapperClasses);
    this.wrap(parent, wrapper, this.el.nativeElement);
    wrapper.insertBefore(adminComponent.location.nativeElement, wrapper.firstChild);
  }

  private wrap(parent: ParentNode, wrapper: HTMLDivElement, element: HTMLElement): void {
    this.renderer.insertBefore(parent, wrapper, element);
    this.renderer.removeChild(parent, element);
    this.renderer.appendChild(wrapper, element);
  }

  private addInputs(component: ComponentRef<AdminCommentComponent>): void {
    component.instance.taskId = this.adminCommentTaskId;
    component.instance.step = this.adminCommentStep;
    component.instance.comments = this.adminCommentComments;
    component.instance.taskType = this.adminCommentTaskType;
    component.instance.mappingField = this.adminCommentMappingField;
  }

  private createAdminCommentComponent(): ComponentRef<AdminCommentComponent> {
    const adminComponent = this.viewContainer.createComponent(AdminCommentComponent);
    this.addInputs(adminComponent);
    return adminComponent;
  }

  private createWrapper(classes: string[]): HTMLDivElement {
    const wrapper = this.document.createElement('div');
    wrapper.classList.add(...classes);
    return wrapper;
  }
}
