import { Component, OnInit } from '@angular/core';
import { FilterSelectorOption } from '../../../filter-selector/filter-selector.component';
import { Observable } from 'rxjs';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { select, Store } from '@ngrx/store';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { take } from 'rxjs/operators';
import { AppState } from '../../../../../../app.state';
import { languages } from '../../../../../../utils/Languages';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-language-filter',
  templateUrl: './product-language-filter.component.html',
  styleUrls: ['./product-language-filter.component.scss'],
})
export class ProductLanguageFilterComponent implements OnInit {
  private _selected: string;
  private allLanguages = languages;

  options: FilterSelectorOption<string, string>[] = [];
  productSearchStore$: Observable<ProductSearchState>;

  constructor(private store: Store<AppState>, private translateService: TranslateService) {
    this.productSearchStore$ = store.pipe(select((state) => state.productSearch));
  }

  ngOnInit(): void {
    const productLanguages = this.getLanguages();
    this.options = this.mapLanguagesToOptions(
      this.allLanguages.filter((lang) => productLanguages.includes(lang.id.toLocaleUpperCase()))
    );
  }

  private mapLanguagesToOptions(langs: { id: string; name: string }[]): FilterSelectorOption<string, string>[] {
    return langs.map((lang) => ({
      value: this.translateService.instant(`GENERAL.LANGUAGES.${lang.id.toUpperCase()}`),
      key: lang.id,
    }));
  }

  private getLanguages(): string[] {
    let array: string[] = [];
    this.productSearchStore$
      .pipe(
        take(1),
        select((state) => state.advancedFiltersData),
        select((state) => state.languages)
      )
      .subscribe((productLanguages) => {
        array = productLanguages;
      });
    return array;
  }

  valueChange(value: string): void {
    this.store.dispatch(
      new SetTemporaryFilter({
        value: {
          lang: value,
        },
      })
    );
  }
}
