import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EmailVerificationLinkSentDialogComponent,
  EmailVerificationLinkSentDialogData,
} from './email-verification-link-sent-dialog.component';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationLinkSentDialogService {
  constructor(private dialog: MatDialog) {}

  open(email?: string): Observable<void> {
    return this.dialog
      .open<EmailVerificationLinkSentDialogComponent, EmailVerificationLinkSentDialogData>(
        EmailVerificationLinkSentDialogComponent,
        { data: { email }, autoFocus: false, restoreFocus: false }
      )
      .afterClosed()
      .pipe(take(1));
  }
}
