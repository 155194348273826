import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import type { SupplierTask } from '../../../../vo/supplier/supplier-task';
import type { MySuppliersCache } from '../model/my-suppliers-cache';
import { MySuppliersSlice } from '../model/cache-slice';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import type { SupplierWithBadgesAndAutoOrder } from '../../../../vo/supplier/supplier-with-badges-and-auto-order';
import type { ManagedProductNumber } from '../model/managed-product-number';
import type { WholesalePriceAndOrderNumber } from '../model/wholesale-price-and-order-number';

@Injectable()
export class MySuppliersHelperService {
  constructor() {}

  public getCatalogFromCache(
    catalogID: number,
    cacheObservable: Observable<MySuppliersCache>
  ): Observable<SupplierTask> {
    return cacheObservable.pipe(
      map((cache) => cache[MySuppliersSlice.CATALOGS][catalogID]),
      omitNullOrUndefined()
    );
  }

  public getSupplierFromCache(
    userID: number,
    cacheObservable: Observable<MySuppliersCache>
  ): Observable<SupplierWithBadgesAndAutoOrder> {
    return cacheObservable.pipe(
      map((cache: MySuppliersCache) => cache[MySuppliersSlice.SUPPLIERS][userID]),
      omitNullOrUndefined()
    );
  }

  public getManagedProductNumberFromCache(
    catalogID: number,
    cacheObservable: Observable<MySuppliersCache>
  ): Observable<ManagedProductNumber> {
    return cacheObservable.pipe(
      map((cache: MySuppliersCache) => cache[MySuppliersSlice.MANAGED_PRODUCT_NUMBERS][catalogID])
    );
  }

  public getWholesalePriceAndOrderNumberFromCache(
    catalogID: number,
    cacheObservable: Observable<MySuppliersCache>
  ): Observable<WholesalePriceAndOrderNumber> {
    return cacheObservable.pipe(
      map((cache: MySuppliersCache) => cache[MySuppliersSlice.WHOLESALE_PRICES_AND_ORDER_NUMBERS][catalogID]),
      omitNullOrUndefined()
    );
  }
}
