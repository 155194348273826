import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SupplierWithBadgesDto } from '../../../vo/supplier/supplier-with-badges-dto';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { Subject, map, takeUntil } from 'rxjs';
import { ReviewAverage } from '../../../vo/review/review-average';
import { ReviewHelperService } from '../../review/service/review-helper.service';
import { UserReviewService } from '../../../service/review/user-review.service';

@Component({
  selector: 'app-more-from-supplier',
  templateUrl: './more-from-supplier.component.html',
  styleUrls: ['./more-from-supplier.component.scss'],
})
export class MoreFromSupplierComponent implements OnInit, OnDestroy, OnChanges {
  @Input() supplier?: SupplierWithBadgesDto;
  @Input() selectedEcom: EcomVO;
  @Input() ecomCurrency = 'USD';
  @Input() supplierSeoUrl: string;

  private unsubscribe$ = new Subject<void>();
  average: number;
  count: number;
  reviewAverage: ReviewAverage[];
  products = [];
  productsToRender;
  isMobile = false;
  isLg$ = this.screenManager.observeBreakpoint(BreakPoint.lg).pipe(
    takeUntil(this.unsubscribe$),
    map(({ matches }) => matches)
  );

  constructor(
    private productSearch: ProductSearchService,
    private screenManager: ScreenManagerService,
    private reviewHelperService: ReviewHelperService,
    private userReviewService: UserReviewService
  ) {}

  ngOnInit(): void {
    this.initSupplierProducts(this.supplier);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.supplier?.isFirstChange() || changes.supplier?.previousValue === changes.supplier?.currentValue) {
      return;
    }

    this.initSupplierProducts(changes.supplier.currentValue);
    this.getSupplierReviewInfo();
  }

  getProducts(supplierID: string): void {
    this.productSearch
      .searchProducts(this.selectedEcom, { supplier: supplierID }, { from: 0, size: 4 })
      .subscribe((data) => {
        this.products = data.result;
        this.initScreenSizeChangeSubscription();
        this.setProductsToInitialBreakPoint();
      });
  }

  private initSupplierProducts(supplier?: SupplierWithBadgesDto): void {
    if (!supplier) {
      return;
    }

    this.getProducts(supplier.userId.toString());
  }

  private initScreenSizeChangeSubscription(): void {
    this.isLg$.subscribe((value) => {
      if (value) {
        this.productsToRender = [...this.products.slice(0, 3)];
      } else {
        this.productsToRender = [...this.products];
      }
    });
  }

  private setProductsToInitialBreakPoint(): void {
    switch (true) {
      case this.checkBreakpoint(BreakPoint.lg):
        this.productsToRender = [...this.products.slice(0, 3)];
        break;
      case this.checkBreakpoint(BreakPoint.xl):
        this.productsToRender = [...this.products];
        break;
      default:
        this.productsToRender = [...this.products];
    }
  }

  private checkBreakpoint(breakpoint: BreakPoint): boolean {
    return this.screenManager.checkBreakpoint(breakpoint);
  }

  private getSupplierReviewInfo(): void {
    if (!this.supplier) {
      return;
    }
    this.userReviewService.getSupplierAverageReviews([Number(this.supplier.userId)]).subscribe((value) => {
      value = value ?? [];
      if (!value?.length) {
        return;
      }
      this.reviewAverage = value;
      this.getReviewAverage(value);
      this.count = this.reviewHelperService.getCountSum(value);
    });
  }

  private getReviewAverage(averages: ReviewAverage[]): void {
    this.average = this.reviewHelperService.getWeightedAverage(
      averages.map((review) => ({ count: review.reviewCount, value: review.ratingAverage }))
    );
  }
}
