<div class="product-search-with-autocomplete-mobile-indicator">
  <app-input-placeholder-slider
    class="placeholder"
    color="var(--app-syncee-grey-700)"
    [backgroundColor]="backgroundColor"
    [options]="productSearchWithAutocompletePlaceholderConfig"
  ></app-input-placeholder-slider>
  <mat-form-field
    class="form-field select-without-label"
    appearance="fill"
    [floatLabel]="'never'"
    (click)="openDialog()"
  >
    <input autocomplete="off" matInput type="text" readonly />
    <span matPrefix><mat-icon class="s-20">search</mat-icon></span>
  </mat-form-field>
</div>
