<div class="summary-container">
  <div *ngIf="enableComponent" fxLayoutAlign="start start">
    <div fxFlex="50">
      <app-destination
        #destComponent
        [currentTaskId]="taskId"
        [destination]="destination"
        [url]="exportUrl"
        (destinationChanged)="handleDestinationChange($event)"
      ></app-destination>
    </div>

    <div fxFlex="50">
      <div class="h4 mb-12">{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.SCHEDULE' | translate }}</div>
      <app-schedule #scheduleComp [taskId]="taskId" [sourceType]="'export'" [isExport]="true"></app-schedule>
    </div>
  </div>

  <!--<h3>Summary</h3>-->
  <div *ngIf="!isLoading; else loading">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="h3 m-16">{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.TASK_SUMMARY' | translate }}</div>
      <div fxLayout="column" fxLayoutGap="12px">
        <div>
          <strong>{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.FILE_TYPE' | translate }}</strong> {{ fileType }}
          <span *ngIf="fileType === 'CSV'"
            >( {{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.DELIMETER' | translate }}
            <strong>{{ csvSettings.delimiter }}</strong> ,
            {{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.ENCLOSURE' | translate }}
            <strong>{{ csvSettings.enclosure }}</strong> , {{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.END' | translate }}
            <strong>{{ csvSettings.eol }}</strong
            >)</span
          >
        </div>
        <div>
          <strong>{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.STORE_NAME' | translate }} </strong> {{ storeName }}
        </div>
        <!--<h3 *ngIf="sourceType"><strong>Files source type: </strong>{{sourceType}}</h3>-->
        <div>
          <strong>{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.PRODUCT' | translate }} </strong>{{ productNumber }}
        </div>
        <div>
          <strong>{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.PRODUCT2' | translate }} </strong>{{ filteredNumber }}
        </div>
        <div *ngIf="scheduleType">
          <strong>{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.TASK_WILL' | translate }} {{ scheduleType }}:</strong>
        </div>
        <mat-form-field appearance="fill" class="mt-20">
          <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.EMAIL' | translate }}</mat-label>
          <mat-select [(value)]="selectedNotification" disableOptionCentering panelClass="mat-select-display-change">
            <mat-option value="dont">{{ 'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.DONT_SEND' | translate }}</mat-option>
            <mat-option value="email">{{
              'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.SEND_EMAIL_WHEN' | translate
            }}</mat-option>
            <mat-option value="report">{{
              'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.SEND_EMAIL_AND' | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!--                <mat-list *ngIf="scheduleTimes && scheduleTimes.length > 0" class="summary-list" role="list" fxFlexAlign="center">-->
        <!--                    <mat-list-item role="listitem" *ngFor="let scheduleTime of scheduleTimes">-->
        <!--                        - {{scheduleTime}}</mat-list-item>-->
        <!--                </mat-list>-->
      </div>
    </div>
  </div>
  <ng-template #loading>
    <div fxLayout="column" fxLayoutAlign="start center">
      <app-loading-spinner [message]="'RETAILER_EXPORT_TASK_WIZARD.SUMMARY.PLEASE' | translate"></app-loading-spinner>
    </div>
  </ng-template>
</div>
