import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskIsSyncedBackground',
})
export class TaskIsSyncedBackgroundPipe implements PipeTransform {
  transform(value: string): string {
    return value === 'Not Run Yet' ? 'var(--app-syncee-grey-200)' : 'var(--app-light-green-100)';
  }
}
