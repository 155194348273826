<div
  fxLayout="column"
  fxLayoutAlign="stretch center"
  class="w-100p footer-container"
  fxLayoutGap="80px"
  fxLayoutGap.lt-md="16px"
>
  <div
    *ngIf="!isMd; else mdFooter"
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutAlign="space-between start"
    fxLayoutGap="100px"
    fxLayoutGap.lt-lg="32px"
    class="w-100p nav-container row-gap-lg"
  >
    <div fxLayout="column" fxLayoutAlign="start start" class="logo-container" fxLayoutGap="20px">
      <app-syncee-logo [width]="'116px'" [height]="'24px'"></app-syncee-logo>
      <div class="typography-body-1 slogan">
        {{ 'FOOTER.SLOGAN' | translate }}
      </div>
      <a href="https://apps.shopify.com/syncee-1" target="_blank">
        <img
          [src]="'images/partner-assets/Shopify-App-Store-Badge-Final-Black.svg' | getAssetFromStorage"
          alt="Find it on the Shopify App Store"
          [width]="160"
        />
      </a>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="80px"
      fxLayoutGap.lt-lg="32px"
      class="row-gap-lg groups-container-width"
    >
      <div
        *ngFor="let group of footerStructure"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="4px"
        fxFlex="1 0 0"
      >
        <h5 class="group-title">
          {{ 'FOOTER.GROUP_TITLES.' + group.name | translate }}
        </h5>
        <app-footer-group-items [group]="group" [gap]="'12px'"></app-footer-group-items>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isMd">
    <ng-container *ngTemplateOutlet="review"></ng-container>
  </ng-container>
  <div class="divider-container">
    <mat-divider class="w-100p"></mat-divider>
  </div>
  <div class="w-100p" fxLayout="column" fxLayoutAlign="center center">
    <div
      class="social-group flex-wrap row-gap-lg w-100p"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="space-between start"
    >
      <ng-container *ngIf="!isMd">
        <ng-container *ngTemplateOutlet="review"></ng-container>
      </ng-container>
      <app-partners [gap]="isMd ? 12 : 4" layoutAlign="start start"></app-partners>
      <ng-container *ngIf="isMd">
        <ng-container *ngTemplateOutlet="social"></ng-container>
      </ng-container>
    </div>
    <div class="divider-container">
      <mat-divider class="w-100p"></mat-divider>
    </div>
    <div
      class="social-group w-100p flex-wrap-reverse row-gap-lg"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-sm="space-between flex-end"
    >
      <div *ngIf="!isMd" fxLayout="row" fxLayoutGap="40px">
        <app-language-selector></app-language-selector>
        <ng-container *ngTemplateOutlet="policies"></ng-container>
      </div>
      <div *ngIf="isMd" fxLayout="column" fxLayoutGap="16px">
        <ng-container *ngTemplateOutlet="policies"></ng-container>
        <app-language-selector fxFlex="0 0 auto" fxFlexAlign="start"></app-language-selector>
      </div>
      <ng-container *ngIf="!isMd">
        <ng-container *ngTemplateOutlet="social"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #mdFooter>
  <app-md-groups class="w-100p" [footerStructure]="footerStructure"></app-md-groups>
</ng-template>

<ng-template #review>
  <app-review-images></app-review-images>
</ng-template>

<ng-template #social>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
    <app-footer-item *ngFor="let socialPlatform of socialPlatforms" [item]="socialPlatform"></app-footer-item>
  </div>
</ng-template>

<ng-template #policies>
  <app-footer-item [item]="allRightsReserved"></app-footer-item>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" class="flex-wrap row-gap-lg">
    <app-footer-item *ngFor="let gdpr of gdprs" [item]="gdpr"></app-footer-item>
  </div>
</ng-template>
