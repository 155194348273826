<div fxLayout="row" fxLayoutGap="20px">
  <mat-form-field
    fxFlex="0 0 82px"
    class="select-without-label input-without-padding country-code-select"
    appearance="fill"
  >
    <mat-select #phoneCodeSelector [(ngModel)]="selectedPhoneCode" (ngModelChange)="handleCountrySelect($event)">
      <mat-select-trigger fxLayout="row" fxLayoutAlign="center">
        <img
          [style.height]="'12px'"
          [src]="selectedPhoneCode.countryCode | countryFlag"
          [alt]="selectedPhoneCode.country + ' flag'"
        />
      </mat-select-trigger>
      <mat-option>
        <app-custom-mat-select-search
          [parentSelect]="phoneCodeSelector"
          (searchTermChange)="initSearch($event)"
          [numberOfResults]="filteredPhoneCodes.length"
        ></app-custom-mat-select-search>
      </mat-option>
      <ng-container *ngFor="let phoneCodeEntry of filteredPhoneCodes">
        <mat-option [value]="phoneCodeEntry">
          <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
            <img
              [style.height]="'12px'"
              [src]="phoneCodeEntry.countryCode | countryFlag"
              [alt]="phoneCodeEntry.country + ' flag'"
            />
            <div>{{ 'COUNTRIES.' + phoneCodeEntry.countryCode | translate }}</div>
            <div class="list-phone-code">{{ phoneCodeEntry.phoneCode }}</div>
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <div fxFlex="1 0 0" class="min-w-0">
    <mat-form-field
      class="w-100p input-without-padding-except-error select-without-label phone-number-input"
      [class.underline-hidden]="hideUnderline"
      [class.mat-form-field-invalid]="!!error"
      appearance="fill"
    >
      <input
        [(ngModel)]="phoneNumber.phoneNumber"
        (ngModelChange)="handlePhoneNumberChange()"
        [placeholder]="required ? placeholder + ' *' : placeholder"
        [class.custom-input-error]="!!hasError"
        [required]="required"
        matInput
        type="tel"
        inputmode="tel"
      />
      <div [style.padding-right]="'4px'" matPrefix>{{ phoneNumber.countryCode }}</div>
      <mat-error *ngIf="!!error">{{ error }}</mat-error>
    </mat-form-field>
  </div>
</div>
