import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { SingleAlertWithIconComponent } from '../single-alert-with-icon/single-alert-with-icon.component';
import { GeneralButton } from '../../../vo/general-button/general-button';

@Component({
  selector: 'app-no-search-results',
  templateUrl: './no-search-results.component.html',
  styleUrls: ['./no-search-results.component.scss'],
  imports: [FlexModule, TranslateModule, SingleAlertWithIconComponent],
  standalone: true,
})
export class NoSearchResultsComponent implements OnInit {
  @Input() titleKey = 'COMMON_ALERTS.NO_RESULT.TITLE';
  @Input() titleTooltipKey;
  @Input() descriptionKey = 'COMMON_ALERTS.NO_RESULT.DESCRIPTION';
  @Input() descriptionTooltipKey;
  @Input() paddingTop = 120;
  @Input() paddingBottom = 200;
  @Input() icon = 'search';
  @Input() svgIcon?: string;
  @Input() button: GeneralButton;

  constructor() {}

  ngOnInit(): void {}
}
