import { Component, Input, forwardRef } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { TagOption } from './model/tag-option';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TagSelectorComponent),
    },
  ],
})
export class TagSelectorComponent<T> implements ControlValueAccessor {
  @Input() value: T[] = [];

  @Input() label: string = null;

  @Input() options: TagOption<T>[] = [];

  touched = false;

  disabled = false;

  onChange: any = () => {};

  onTouched: any = () => {};

  protected handleClick(item: TagOption<T>): void {
    const copy = cloneDeep(this.value);

    const index = copy.findIndex((value: T) => isEqual(item.value, value));

    if (index === -1) {
      copy.push(item.value);
    } else {
      copy.splice(index, 1);
    }

    this.markAsTouched();
    this.onInputChange(copy);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(_value: any): void {
    this.value = _value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange(value: T[]): void {
    this.value = value;
    this.onChange(value);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
