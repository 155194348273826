import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Utils } from 'app/utils/utils';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { CustomDialogWrapperComponent } from '../dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { SubscriptionUpsellComponent } from '../subscription-upsell/subscription-upsell.component';

@Component({
  selector: 'app-upsell-dialog',
  templateUrl: './upsell-dialog.component.html',
  styleUrls: ['./upsell-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, CustomDialogWrapperComponent, SubscriptionUpsellComponent],
})
export class UpsellDialogComponent implements OnInit {
  descTransKey = 'PLAN_UPSELL.DESCRIPTION';
  selectedEcom: EcomVO = null;

  constructor(
    public dialogRef: MatDialogRef<UpsellDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UpsellDialogData
  ) {}

  ngOnInit(): void {
    if (!Utils.isNullOrUndefined(this.data) && !Utils.isNullOrUndefined(this.data.descriptionTranslationKey)) {
      this.descTransKey = this.data.descriptionTranslationKey;
    }
    if (!Utils.isNullOrUndefined(this.data) && !Utils.isNullOrUndefined(this.data.selectedEcom)) {
      this.selectedEcom = this.data.selectedEcom;
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
    this.dialogRef.close(true);
  }
}

export interface UpsellDialogData {
  descriptionTranslationKey?: string;
  selectedEcom?: EcomVO;
  variant?: 'datafeed' | 'marketplace';
  disableClose?: boolean;
}
