export const productSearchWithAutocompletePlaceholderConfig: string[] = [
  'All natural products',
  'Eco-friendly home decor',
  'Office chair for back support',
  'Home devices',
  'Sustainable sneaker brands',
  'Yoga mat with good grip',
  'Sustainable fashion',
  'Eco-friendly baby clothing',
  'White skate shoes',
  'Gift for coffee lovers',
  'Organic baby food',
  'High-efficiency washing machines',
  'Ergonomic office chair',
  'Mechanical keyboards',
  'High protein vegan snack',
  'Pet toys',
  'Organic skincare products',
  'Compact cameras for travel',
  'Gluten-free pasta brands',
  'Smart TVs with voice control',
  'Vegan leather handbags',
  'Vintage style dresses',
  'Smart home devices',
  'Solar-powered outdoor lights',
  'Waterproof jacket for hiking',
  'Plant-based protein powders',
  'Affordable 4K monitors',
  'All-natural baby products',
  'Cold weather camping gear',
  'Eco-friendly cleaning products',
  'Indoor air purifiers for allergies',
  'Biodegradable kitchen utensils',
  'Organic cotton bedding sets',
  'Electric cars with longest range',
  'Bedroom decor',
  'Fair-trade coffee beans',
  'Pet-friendly indoor plants',
  'Hoodies',
  'Gentle cleanser',
  "I'm having a backyard bbq",
  'Running shoes',
  'Indoor plants',
];
