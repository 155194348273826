import { Observable } from 'rxjs';

export class ConvertImagesToPng {
  static convert(files: File[]): Observable<File[]> {
    return new Observable<File[]>((observer) => {
      const convertedFiles: File[] = [];
      let completedConversions = 0;

      files.forEach((file, index) => {
        if (file.type.startsWith('image/') && file.type !== 'image/png') {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              canvas.toBlob((blob) => {
                const convertedFile = new File([blob], file.name.replace(/\.[^/.]+$/, '.png'), {
                  type: 'image/png',
                  lastModified: new Date().getTime(),
                });
                convertedFiles[index] = this.normalizeFileExtension(convertedFile);
                completedConversions++;
                if (completedConversions === files.length) {
                  observer.next(convertedFiles);
                  observer.complete();
                }
              }, 'image/png');
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          convertedFiles[index] = this.normalizeFileExtension(file);
          completedConversions++;
          if (completedConversions === files.length) {
            observer.next(convertedFiles);
            observer.complete();
          }
        }
      });
    });
  }

  static normalizeFileExtension(file: File): File {
    const extension = file.name.split('.').pop().toLowerCase();
    const baseName = file.name.substring(0, file.name.lastIndexOf('.'));
    const newFileName = `${baseName}.${extension}`;

    if (newFileName !== file.name) {
      return new File([file], newFileName, { type: file.type, lastModified: file.lastModified });
    }
    return file;
  }
}
