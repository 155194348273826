<mat-card class="countries-table-card">
  <table mat-table [dataSource]="mappedRates" class="countries-table syncee-grey-200-border">
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef class="pl-12">
        <h5 class="h5" [style.margin]="'0'">
          {{ 'SHIPPING_DETAILS.LOCATION' | translate }}
        </h5>
      </th>
      <td mat-cell *matCellDef="let rates">
        <div [class.nested-element]="rates.isNestedElement">
          {{ rates?.location }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="condition">
      <th mat-header-cell *matHeaderCellDef class="centered-text">
        <h5 class="h5" [style.margin]="'0'">
          {{ 'SHIPPING_DETAILS.CONDITION' | translate }}
        </h5>
      </th>
      <td mat-cell *matCellDef="let rates" class="centered-text">
        <ng-container *ngIf="!rates?.hasNested || rates?.isNestedElement">
          <ng-container [ngSwitch]="true">
            <ng-container
              *ngSwitchCase="
                !rates.condition || (rates.condition.minValue === null && rates.condition.maxValue === null)
              "
            >
              <!-- {{ 0 | currencyTransform : currency | async }} {{ 'SHIPPING_DETAILS.AND_UP' | translate }} -->
            </ng-container>

            <ng-container *ngSwitchCase="rates.condition && rates.condition.type === shippingRateType.PRICE">
              {{
                rates.condition.minValue
                  | currencyExchangeTo : supplierCurrency : currency
                  | async
                  | currencyTransform : currency
                  | async
              }}
              {{
                rates.condition.maxValue
                  ? ' - ' +
                    (rates.condition.maxValue
                      | currencyExchangeTo : supplierCurrency : currency
                      | async
                      | currencyTransform : currency
                      | async)
                  : ('SHIPPING_DETAILS.AND_UP' | translate)
              }}
            </ng-container>

            <ng-container *ngSwitchCase="rates.condition && rates.condition.type === shippingRateType.WEIGHT">
              {{ rates.condition.minValue | convertToUserWeightUnit | async }}
              {{
                rates.condition.maxValue
                  ? ' - ' + (rates.condition.maxValue | convertToUserWeightUnit | async)
                  : ('SHIPPING_DETAILS.AND_UP' | translate)
              }}
            </ng-container>

            <ng-container *ngSwitchDefault>
              {{ rates.condition?.minValue + ' - ' + rates.condition?.maxValue }}
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="incrementalFee">
      <th mat-header-cell *matHeaderCellDef class="centered-text">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
          <h5 class="h5" [style.margin]="'0'">
            {{ 'SHIPPING_DETAILS.INCREMENTAL_FEE' | translate }}
          </h5>
          <mat-icon class="cursor-pointer s-20" [matTooltip]="'SHIPPING_DETAILS.INCREMENTAL_FEE_TOOLTIP' | translate">
            info</mat-icon
          >
        </div>
      </th>
      <td mat-cell *matCellDef="let rates" class="centered-text">
        <ng-container *ngIf="!rates?.hasNested || rates?.isNestedElement">
          {{
            rates?.additionalCost
              ? (rates?.additionalCost
                | currencyExchangeTo : supplierCurrency : currency
                | async
                | currencyTransform : currency
                | async)
              : (0 | currencyTransform : currency | async)
          }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="shippingTime">
      <th mat-header-cell *matHeaderCellDef class="centered-text">
        <h5 class="h5" [style.margin]="'0'">
          {{ 'SHIPPING_DETAILS.SHIPPING_TIME' | translate }}
        </h5>
      </th>
      <td mat-cell *matCellDef="let rates" class="centered-text">
        <ng-container *ngIf="!rates?.hasNested || rates?.isNestedElement">
          {{
            rates?.minShippingDays || rates?.mayShippingDays
              ? ('SHIPPING_DETAILS.DAYS'
                | translate : { minDays: rates.minShippingDays, maxDays: rates.maxShippingDays })
              : null
          }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="shippingCost">
      <th mat-header-cell *matHeaderCellDef class="right-text">
        <h5 class="h5" [style.margin]="'0'">
          {{ 'SHIPPING_DETAILS.SHIPPING_COST' | translate }}
        </h5>
      </th>
      <td mat-cell *matCellDef="let rates" class="right-text">
        <ng-container *ngIf="!rates?.hasNested || rates?.isNestedElement">
          {{
            rates?.shippingCost
              ? (rates?.shippingCost
                | currencyExchangeTo : supplierCurrency : currency
                | async
                | currencyTransform : currency
                | async)
              : ('SHIPPING_DETAILS.FREE' | translate)
          }}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.multiple-rates]="row.hasNested || row.isNestedElement"
    ></tr>
  </table>
</mat-card>
