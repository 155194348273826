import { Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { ProductdetailsbuilderComponent } from '../../taskwizard/addfiles/mapping/productdetailsbuilder/productdetailsbuilder.component';
import { RestService } from '../../../service/rest/rest.service';
import { RecentlyDroppedItemNotifier } from '../../drag-ndrop-zone/recently-dropped-item-notifier';
import { NotificationService } from '../../notification/notification.service';
import { TaskwizardUpdateService } from '../../../service/taskwizard/taskwizard-update.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { UserService } from '../../../service/user/user.service';
import { StepperService } from '../../../service/taskwizard/stepper.service';
import { DroppableInputComponent } from '../../droppable-input/droppable-input.component';
import { DimensionFieldsComponent } from '../../taskwizard/addfiles/mapping/productdetailsbuilder/dimension-fields/dimension-fields.component';
import { PermissionService } from '../../../service/permission/permission.service';

@Component({
  selector: 'app-jumpseller-mapping-builder',
  templateUrl: './jumpseller-mapping-builder.component.html',
  styleUrls: ['./jumpseller-mapping-builder.component.scss'],
})
export class JumpsellerMappingBuilderComponent extends ProductdetailsbuilderComponent {
  @ViewChild('dimensionComponent', { static: true }) private dimensionComponent: DimensionFieldsComponent;

  constructor(
    protected restService: RestService,
    protected droppedItemNotifier: RecentlyDroppedItemNotifier,
    protected notification: NotificationService,
    protected taskWizardUpdateService: TaskwizardUpdateService,
    protected dialog: MatDialog,
    protected taskWizardService: TaskwizardService,
    protected userService: UserService,
    protected stepperService: StepperService,
    protected permissionService: PermissionService
  ) {
    super(
      restService,
      droppedItemNotifier,
      notification,
      taskWizardUpdateService,
      dialog,
      taskWizardService,
      userService,
      stepperService,
      permissionService
    );
  }

  getCustomNormalComponents(): QueryList<DroppableInputComponent> {
    return this.dimensionComponent.getDimensionsInputComponents();
  }
}
