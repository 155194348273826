import { Component, Input, OnInit } from '@angular/core';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'inner-content',
  templateUrl: './inner-content.component.html',
  styleUrls: ['./inner-content.component.scss'],
})
export class InnerContentComponent implements OnInit {
  @Input() verticalPadding = 24;

  constructor(private _fuseSidebarService: FuseSidebarService) {}

  isOnMobile;

  ngOnInit(): void {
    this.isOnMobile = window.screen.width <= 800;
  }

  toggleSidebar(): void {
    this._fuseSidebarService.getSidebar('inner-menu-sidebar').toggleOpen();
  }
}
