import {animate, state, style, transition, trigger} from '@angular/animations';
import {timing_default} from './animation-timings';

export const FadeInOutAnimation = trigger('fadeInOut', [
    state('void', style({
        visibility: 'hidden',
        opacity: 0
    })),
    state('*', style({
        visibility: 'visible',
        opacity: 1
    })),
    transition('* => void', animate(`${timing_default} ease-out`)),
    transition('void => *', animate(`${timing_default} ease-in`))
]);
