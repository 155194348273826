import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PlanCompareDialogComponent } from './plan-compare-dialog.component';
import { PlanVO } from '../subscription.component';

@Injectable({
  providedIn: 'root',
})
export class PlanCompareDialogService {
  constructor(private dialog: MatDialog) {}

  public openDialog(
    currentPlan: PlanVO,
    selectedPlan: PlanVO,
    isUpgrade: boolean,
    productType: string,
    isNextPlan: boolean
  ): Observable<any> {
    let dialogRef: MatDialogRef<PlanCompareDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = true;
    if (window.screen.width < 750) {
      config.width = '100vw';
      config.maxWidth = '100vw';
    } else {
      config.width = '52vw';
    }
    // config.panelClass = 'custom-modal-container';

    dialogRef = this.dialog.open(PlanCompareDialogComponent, config);

    dialogRef.componentInstance.currentPlan = currentPlan;
    dialogRef.componentInstance.selectedPlan = selectedPlan;
    dialogRef.componentInstance.isUpgrade = isUpgrade;
    dialogRef.componentInstance.productType = productType;
    dialogRef.componentInstance.isNextPlan = isNextPlan;

    return dialogRef.afterClosed();
  }
}
