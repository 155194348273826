import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { productCardStatusConfigs } from '../../product-card.config';

@Component({
  selector: 'app-product-card-action-selectable',
  templateUrl: './product-card-action-selectable.component.html',
  styleUrls: ['./product-card-action-selectable.component.scss'],
})
export class ProductCardActionSelectableComponent implements OnInit {
  @Input() selected = false;
  @Output() selectedChange = new EventEmitter<boolean>();

  readonly config = productCardStatusConfigs;

  constructor() {}

  ngOnInit(): void {}
}
