import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class FileProcessService {
  constructor(private restService: RestService) {}

  getAndSetNodeTree(fileId: number, catalogId: number, force = false): Observable<unknown> {
    return this.restService.post('FileProcessService/getAndSetNodeTree', { fileId, force, taskId: catalogId });
  }
}
