export class SettingsInputVo {
  private _fileType: string;

  get fileType(): string {
    return this._fileType;
  }

  set fileType(value: string) {
    this._fileType = value;
  }
}
