import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, sortBy } from 'lodash';
import { CountryShippingInfo } from '../vo/country-shipping-info';
import { shippingRateSort } from '../../../../utils/shipping-rate-sort';

@Pipe({
  name: 'shippingDetailsSort',
  standalone: true,
})
export class ShippingDetailsSortPipe implements PipeTransform {
  constructor() {}

  transform(shippingInfos: CountryShippingInfo[]): CountryShippingInfo[] {
    return shippingInfos.map((shipping) => {
      if (shipping.shippingRate && shipping.shippingRate.length > 1) {
        return {
          ...shipping,
          shippingRate: shippingRateSort(shipping.shippingRate),
        };
      }

      return shipping;
    });
  }
}
