import { Component, Input, OnInit } from '@angular/core';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { RestService } from '../../../../service/rest/rest.service';
import { UserService } from '../../../../service/user/user.service';
import { PlanType } from '../../cancel-dialog.component';
import { Utils } from '../../../../utils/utils';
import { PlanInfo, CycleType } from '../../model/vo/plan-info-vo';
import { rcatalogsSelector } from '../../../../store/rcatalogs/rcatalogs.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';

@Component({
  selector: 'app-cancel-dialog-plan-info',
  templateUrl: './cancel-dialog-plan-info.component.html',
  styleUrls: ['./cancel-dialog-plan-info.component.scss'],
})
export class CancelDialogPlanInfoComponent implements OnInit {
  @Input() selectedEcom: EcomVO;
  @Input() selectedPlanType: PlanType;
  public planToShow: PlanInfo;
  public userName: string;

  public lostFeatures: string[] = [];

  constructor(private restService: RestService, private userService: UserService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.select(rcatalogsSelector).subscribe((catalogs) => {
      if (catalogs) {
        this.userName = this.userService.getCachedUser().userName;
        const planToSelect = this.selectedEcom.subscriptions[this.selectedPlanType];
        this.lostFeatures = this.getLostFeatures(planToSelect, this.selectedPlanType);
        this.planToShow = this.mapPlanWithoutInterfaceFromEcomVoToPlanInfo(
          planToSelect,
          this.selectedPlanType,
          this.selectedEcom.cycle as CycleType,
          catalogs.filter((elem) => elem.ecomId == this.selectedEcom.ecomId && elem.deleted == null).length
        );
      }
    });
  }

  formatNumber(amount: number): string {
    return Utils.formatMoney(amount);
  }

  private mapPlanWithoutInterfaceFromEcomVoToPlanInfo(
    plan,
    planType: PlanType,
    cycle: CycleType,
    usedCatalogNumber: number
  ): PlanInfo {
    return {
      planName: plan.planName.replace('marketplace plan', '').replace('datafeed plan', ''),
      type: planType,
      period: cycle,
      isCustom: plan.hasCustomPlan,
      price: cycle === 'monthly' ? plan.productMonthlyPrice : plan.productYearlyPrice,
      productLimit: plan.maxProductsNumber,
      managedProducts: plan.usedProductNumber,
      catalogLimit: planType === 'rmp' ? plan.catalogNum : undefined,
      catalogCreated: planType === 'rmp' ? usedCatalogNumber : undefined,
    };
  }

  private getLostFeatures(plan, planType: PlanType): string[] {
    if (planType === 'rmp') {
      return this.getRmpLostFeatures(plan);
    } else {
      return this.getDfLostFeatures(plan);
    }
  }

  private getRmpLostFeatures(plan): string[] {
    const list: string[] = [];
    if (plan.canSync) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.UPLOAD');
    }
    if (plan.automatedProductUploads) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.UPDATES');
    }
    if (plan.automatedOrderDownload) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.ORDER_SYNC');
    }
    if (plan.catalogNum !== 2) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.MORE_CATALOG');
    }
    if (plan.contactAccess) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.CONTACT');
    }
    if (plan.accessChat) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.CHAT');
    }
    if (plan.vipSupplierAccess && plan.premiumSupplierAccess) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.PREMIUM');
    }
    if (plan.pim && plan.variantRenaming) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.MARKETPLACE.PIM');
    }
    return list;
  }

  private getDfLostFeatures(plan): string[] {
    const list: string[] = [];
    if (plan.canSync) {
      list.push('BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.DATAFEED.UPLOAD');
    }
    list.push(
      ...[
        'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.DATAFEED.UPDATES',
        'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.DATAFEED.BULK',
        'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.DATAFEED.EXPORT',
        'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.DATAFEED.VARIANTS',
      ]
    );
    return list;
  }
}
