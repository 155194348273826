import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-orders-info',
  templateUrl: './orders-info.component.html',
})
export class OrdersInfoComponent {
  @Input() total: number;

  @Input() numberOfOrders: number;

  @Input() currency = 'USD';
}
