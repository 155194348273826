<div class="alert-base background" [ngClass]="type">
  <div fxLayoutAlign="start center" fxLayoutAlign.lt-md="start stretch" fxLayout.lt-lg="column" fxLayoutGap="16px">
    <div
      fxFlex="1 1 auto"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start start"
      fxLayout="{{ layout ? layout : 'row' }}"
    >
      <div
        *ngIf="matIcon && !hideIcon"
        class="alert-base-icon alert-dark"
        [style.color]="matIconColor ? matIconColor : null"
        fxFlex="0 0 auto"
        fxLayoutAlign="center center"
      >
        <mat-icon class="s-24" [ngClass]="matIconClass">{{ matIcon }}</mat-icon>
      </div>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!!actionRef">
      <ng-template [ngTemplateOutlet]="actionRef"></ng-template>
    </ng-container>
  </div>
</div>
