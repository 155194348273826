import { Component } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-cancel-dialog-stepper',
  templateUrl: './cancel-dialog-stepper.component.html',
  styleUrls: ['./cancel-dialog-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CancelDialogStepperComponent }],
})
export class CancelDialogStepperComponent extends CdkStepper {
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
