import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectSearchComponent, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-custom-mat-select-search',
  standalone: true,
  imports: [CommonModule, NgxMatSelectSearchModule, FormsModule, TranslateModule],
  templateUrl: './custom-mat-select-search.component.html',
  styleUrls: ['./custom-mat-select-search.component.scss'],
})
export class CustomMatSelectSearchComponent implements OnInit {
  @ViewChild('matSelectSearchComponent') public searchBox: MatSelectSearchComponent;
  @Input() placeholderLabel = 'EXPLORE_PRODUCTS.TOP_SEARCH.COUNTRY_FILTER_SEARCH';
  @Input() noEntriesFoundLabel = 'EXPLORE_PRODUCTS.TOP_SEARCH.COUNTRY_FILTER_NO_MATCH';
  @Input() numberOfResults: number;
  @Input() parentSelect: MatSelect;
  @Output() searchTermChange = new EventEmitter<string>();
  searchTerm: string;
  overlayPane: Element;
  constructor() {}

  ngOnInit(): void {
    this.overlayPane = document.getElementsByClassName('cdk-overlay-pane')[0];
    this.parentSelect.panelClass = 'custom-select-search-panel';
    this.parentSelect.disableOptionCentering = true;
  }

  handleSearchTermChange(value: string): void {
    this.searchTermChange.emit(value);
  }
}
