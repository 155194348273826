import { Injectable } from '@angular/core';
import {
  CategorySelectorDialogComponent,
  CategorySelectorDialogComponentData,
} from './category-selector-dialog.component';
import { Observable } from 'rxjs';
import { CategoryData } from '../wizard-category-mapping/model/category-mapping-data';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class CategorySelectorDialogService {
  constructor(private dialog: MatDialog) {}
  public open(data: CategorySelectorDialogComponentData): Observable<CategoryData[]> {
    const dialog = this.dialog.open<
      CategorySelectorDialogComponent,
      CategorySelectorDialogComponentData,
      CategoryData[]
    >(CategorySelectorDialogComponent, {
      data,
      panelClass: 'custom-modal-full-width-lt-md',
      autoFocus: false,
      restoreFocus: false,
      width: '100%',
      maxWidth: '520px',
    });

    return dialog.afterClosed();
  }
}
