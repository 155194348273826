import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { Observable } from 'rxjs';
import { SetupGuideService } from '../../../../../main/setup-guide/service/setup-guide.service';

@Component({
  selector: 'app-setup-guide-button',
  templateUrl: './setup-guide-button.component.html',
  styleUrls: ['./setup-guide-button.component.scss'],
})
export class SetupGuideButtonComponent implements OnInit {
  allCompleted: Observable<boolean>;

  constructor(private store: Store<AppState>, private setupGuideService: SetupGuideService) {
    this.allCompleted = this.getAllCompleted();
  }

  ngOnInit(): void {}

  private getAllCompleted(): Observable<boolean> {
    return this.setupGuideService.getAllStepsCompleted();
  }
}
