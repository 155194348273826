import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpringRestService } from '../../../service/rest/microservices/spring-rest.service';
import { QuestionByScore } from '../model/question-by-score';
import { SavePlatformChoiceVo } from '../model/save-platform-choice.vo';
import { SaveReviewReplyVo } from '../model/save-review-reply.vo';
import { ReviewReply } from '../model/review-reply';

@Injectable({ providedIn: 'root' })
export class SynceeReviewService {
  private microserviceName = 'userService';

  constructor(private springRestService: SpringRestService) {}

  getReviewReplies(): Observable<ReviewReply> {
    return this.springRestService.get(this.microserviceName, `/Review`, {}, true);
  }

  getQuestionsByScore(score: number): Observable<QuestionByScore[]> {
    return this.springRestService.get(this.microserviceName, `/Review/Questions/${score}`, {}, true);
  }

  savePlatformChoice(platformChoice: SavePlatformChoiceVo): Observable<void> {
    return this.springRestService.post(this.microserviceName, '/Review/Platform', platformChoice, {}, true);
  }

  saveReviewScore(score: number): Observable<any> {
    return this.springRestService.post(this.microserviceName, `/Review?score=${score}`, undefined, {}, true);
  }

  saveReviewReply(reply: SaveReviewReplyVo): Observable<any> {
    return this.springRestService.post(this.microserviceName, `/Review/reply`, reply, {}, true);
  }
}
