import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CustomTableDataResponse } from '../../../../shared/components/custom-table/custom-table.component';
import { ActivityLogTableItem } from '../../../../service/activity-log/activity-log.service';
import { isEmpty } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-activity-log-mobile',
  templateUrl: './activity-log-mobile.component.html',
  styleUrls: ['./activity-log-mobile.component.scss'],
})
export class ActivityLogMobileComponent implements OnInit, OnDestroy {
  @Input() fetcher: () => Observable<CustomTableDataResponse<ActivityLogTableItem>>;
  @Input() reFetchSubject: Subject<void>;
  isLoading = true;
  hasError = false;
  fetchedItems: ActivityLogTableItem[];
  displayedItems: ActivityLogTableItem[];
  pageSizeOptions = [5, 10, 15, 20];

  private unsubScribeAll: Subject<void>;

  pagination = {
    page: 0,
    total: 0,
    count: 0,
    size: 10,
  };

  constructor() {
    this.unsubScribeAll = new Subject();
  }

  ngOnInit(): void {
    this.fetchItems();
    this.subscribeToReFetch();
  }

  ngOnDestroy(): void {
    this.unsubScribeAll.next();
    this.unsubScribeAll.complete();
  }

  fetchItems(): void {
    this.isLoading = true;
    this.fetcher()
      .pipe(takeUntil(this.unsubScribeAll))
      .subscribe(
        (value) => {
          this.handleFetcherResponse(value);

          this.setResponseState('success');
        },
        () => {
          this.setResponseState('error');
        }
      );
  }

  private handleFetcherResponse(response: CustomTableDataResponse<ActivityLogTableItem>): void {
    this.pagination.page = 0;
    this.fetchedItems = response.data;
    this.pagination.total = response.data.length;
    this.setDisplayedItems();
  }

  public handlePageChange(event: PageEvent): void {
    this.pagination.page = event.pageIndex;
    this.pagination.size = event.pageSize;
    this.setDisplayedItems();
  }

  private setDisplayedItems(): void {
    if (isEmpty(this.fetchedItems)) {
      this.displayedItems = [];
      return;
    }
    const start = this.pagination.size * this.pagination.page;
    const end = start + this.pagination.size;
    this.displayedItems = this.fetchedItems.slice(start, end);
  }

  private setResponseState(result: 'success' | 'error'): void {
    if (result === 'success') {
      this.hasError = false;
    } else {
      this.hasError = true;
    }
    this.isLoading = false;
  }

  subscribeToReFetch(): void {
    this.reFetchSubject.subscribe(() => {
      this.fetchItems();
    });
  }
}
