import { Injectable } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { chain, compact, countBy, flatMap, groupBy, maxBy, pickBy, sortBy, uniq } from 'lodash';
import { from, Observable, of, throwError } from 'rxjs';
import { MarketplaceFilter } from '../../vo/search-product-vo';
import { SearchData } from './model/search-data';
import { SearchSessionData } from './model/search-session-data';

@Injectable({
  providedIn: 'root',
})
export class SearchSessionMapperService {
  constructor() {}

  mapMarketplaceFilterToSearchSessionData(filter: MarketplaceFilter): SearchSessionData {
    return {
      categoryId: !Utils.isNullOrUndefined(filter.category) ? +filter.category : 1,
      searchTerm: !!filter.search && filter.search !== '' ? filter.search : undefined,
      approveNeeded: !filter.approveType || filter.approveType === 'any' ? undefined : filter.approveType === 'true',
      premium: !filter.premium || filter.premium === 'any' ? undefined : filter.premium === 'true',
      autoOrder: !filter.autoOrder || filter.autoOrder === 'any' ? undefined : filter.autoOrder === 'true',
      productLanguage: !filter.lang || filter.lang === 'any' ? undefined : filter.lang,
      shipsTo: !!filter.shipsTo ? (Array.isArray(filter.shipsTo) ? filter.shipsTo[0] : filter.shipsTo) : undefined,
      shipsFrom: !!filter.shipsFrom
        ? Array.isArray(filter.shipsFrom)
          ? filter.shipsFrom[0]
          : filter.shipsFrom
        : undefined,
    };
  }

  mapSearchSessionDataToMarketplaceFilter(searchSessionData: SearchSessionData): MarketplaceFilter {
    return {
      category: !Utils.isNullOrUndefined(searchSessionData?.categoryId) ? +searchSessionData.categoryId : 1,
      search: !!searchSessionData ? searchSessionData.searchTerm : null,
      approveType: !!searchSessionData?.approveNeeded ? (searchSessionData.approveNeeded ? 'true' : 'false') : 'any',
      premium: !!searchSessionData?.premium ? (searchSessionData.premium ? 'true' : 'false') : null,
      autoOrder: !!searchSessionData?.autoOrder ? (searchSessionData.autoOrder ? 'true' : 'false') : 'any',
      lang: !!searchSessionData?.productLanguage ? searchSessionData.productLanguage : 'any',
      shipsTo: !!searchSessionData?.shipsTo ? searchSessionData.shipsTo : null,
      shipsFrom: !!searchSessionData?.shipsFrom ? searchSessionData.shipsFrom : null,
    };
  }

  private checkSimilarity(a: string, b: string): boolean {
    return Utils.stringSimilarity(a, b) <= 1;
  }

  getSearchTerms(data: SearchData[]): Observable<string[]> {
    if (!data || data?.length === 0) {
      return of(null);
    }

    const searchTermArray = compact(
      flatMap(data, (searchData) => searchData?.search_datas?.map((obj) => obj?.searchTerm))
    );
    const searchTermCounts = countBy(searchTermArray, (term) => {
      return searchTermArray?.find((key) => this.checkSimilarity(term, key)) || term;
    });

    const filteredSearchTermCounts = pickBy(searchTermCounts, (count) => count >= 2);

    const searchTerms = compact(uniq(sortBy(Object.keys(filteredSearchTermCounts), (term) => -searchTermCounts[term])));

    if (!searchTerms) {
      return throwError(() => of(null));
    }

    return of(searchTerms.slice(0, 10));
  }

  getMarketplaceFilter(data: SearchData[]): Observable<MarketplaceFilter> {
    if (!data || data?.length === 0) {
      return of(null);
    }

    const searchTermArray = compact(flatMap(data, (session) => session?.search_datas?.map((obj) => obj?.searchTerm)));
    const searchTermGroups = groupBy(searchTermArray, (term) => {
      return searchTermArray?.find((key) => this.checkSimilarity(term, key)) || term;
    });

    const mostFrequentGroup = maxBy(Object.keys(searchTermGroups), (group) => searchTermGroups[group]?.length);
    const mostFrequentObject = chain(data)
      .map((session) =>
        session.search_datas.find((obj) => searchTermGroups[mostFrequentGroup]?.includes(obj.searchTerm))
      )
      .find()
      .value();

    if (!mostFrequentObject) {
      return from([this.getLatestFilter(data)]);
    }

    const marketplaceFilter = this.mapSearchSessionDataToMarketplaceFilter(mostFrequentObject);

    return from([marketplaceFilter]);
  }

  getLatestSearch(data: SearchData[]): Observable<MarketplaceFilter> {
    if (!data || data?.length === 0) {
      return of(null);
    }

    const latestSearch = data.flatMap((session) => session.search_datas)?.find((obj) => obj.searchTerm);

    if (!latestSearch) {
      return throwError(() => null);
    }

    const marketplaceFilter = this.mapSearchSessionDataToMarketplaceFilter(latestSearch);

    return from([marketplaceFilter]);
  }

  getLatestFilter(data: SearchData[]): MarketplaceFilter {
    if (!data || data?.length === 0) {
      return null;
    }

    const latestFilter = data.flatMap((session) => session.search_datas)[0];

    const marketplaceFilter = this.mapSearchSessionDataToMarketplaceFilter(latestFilter);

    return marketplaceFilter;
  }
}
