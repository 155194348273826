import { environment } from '../../../environments/environment';
import { ReviewCardsContent } from '../../shared/components/reviews-slider/reviews-slider.component';

export const SYNCEE_STATIC_REVIEWS_RETAILER: ReviewCardsContent = {
  translationPrefix: 'SYNCEE_STATIC_REVIEWS.RETAILER',
  reviews: [
    {
      key: 'SPEKTRUM',
      stars: 5,
      reviewerName: 'SPEKTRUM',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/spektrum.webp`,
        alt: 'Spektrum logo',
      },
    },
    {
      key: 'KENIKE',
      stars: 5,
      reviewerName: 'Kenike',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/kenike.webp`,
        alt: 'Kenike logo',
      },
    },
    {
      key: 'SUMAK',
      stars: 5,
      reviewerName: 'Sumak Australia',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/sumak_australia.webp`,
        alt: 'Sumak Australia logo',
      },
    },
    {
      key: 'WHERE_THE_HEART_IS_STORE',
      stars: 5,
      reviewerName: 'Where the Heart Is Store',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/where_the_heart_is_store.webp`,
        alt: 'Where the Heart Is Store logo',
      },
    },
    {
      key: 'BUY_ME_FUR',
      stars: 5,
      reviewerName: 'Buy Me Fur',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/buy_me_fur.webp`,
        alt: 'Buy Me Fur logo',
      },
    },
    {
      key: 'LOW_COUNTRY_FAIR_TRADE',
      stars: 5,
      reviewerName: 'Low Country Fair Trade',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/low_country_fair_trade.webp`,
        alt: 'Low Country Fair Trade logo',
      },
    },
    {
      key: 'THIS_NICHE',
      stars: 5,
      reviewerName: 'ThisNiche®',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/this_niche.webp`,
        alt: 'ThisNiche logo',
      },
    },
    {
      key: 'SIMPLY_SCENTAMENTAL',
      stars: 5,
      reviewerName: 'Simply ScentaMental LLC',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/simply_scentamental_llc.webp`,
        alt: 'Simply ScentaMental LLC logo',
      },
    },
    {
      key: 'TOYS_4_MAUI',
      stars: 5,
      reviewerName: 'Toys 4 Maui',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/toys_4_maui.webp`,
        alt: 'Toys 4 Maui logo',
      },
    },
  ],
};

export const SYNCEE_STATIC_REVIEWS_SUPPLIER: ReviewCardsContent = {
  translationPrefix: 'SYNCEE_STATIC_REVIEWS.SUPPLIER',
  reviews: [
    {
      key: 'IGONATURAL',
      stars: 5,
      reviewerName: 'iGoNatural',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/igonatural.webp`,
        alt: 'iGoNatural logo',
      },
    },
    {
      key: 'SOUL_CHARMS',
      stars: 5,
      reviewerName: 'Soul Charms',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/soul_charms.webp`,
        alt: 'Soul Charms logo',
      },
    },
    {
      key: 'JASON_CARL_MORGAN',
      stars: 5,
      reviewerName: 'JasonCarlMorgan',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/jason_carl_morgan.webp`,
        alt: 'JasonCarlMorgan logo',
      },
    },
    {
      key: 'ESCAPE_TO_PARADISE',
      stars: 5,
      reviewerName: 'Escape to Paradise',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/escape_to_paradise.webp`,
        alt: 'Escape to Paradise logo',
      },
    },
    {
      key: 'SERNES',
      stars: 5,
      reviewerName: 'SERNES',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/sernes.webp`,
        alt: 'SERNES logo',
      },
    },
    {
      key: 'DELUXXIE',
      stars: 5,
      reviewerName: 'Deluxxie',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/deluxxie.webp`,
        alt: 'Deluxxie logo',
      },
    },
    {
      key: 'BETTY_DESIGN',
      stars: 5,
      reviewerName: 'Betty Design',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/betty_design.webp`,
        alt: 'Betty Design logo',
      },
    },
    {
      key: 'CONCORDIA_STYLE_BOUTIQUE',
      stars: 5,
      reviewerName: 'Concordia Style Boutique',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/concordia_style_boutique.webp`,
        alt: 'Concordia Style Boutique logo',
      },
    },
    {
      key: 'ROSALIE_AND_DONELL',
      stars: 5,
      reviewerName: 'Rosalie & Donell',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/rosalie_donell.webp`,
        alt: 'Rosalie & Donell logo',
      },
    },
  ],
};

export const SYNCEE_STATIC_REVIEWS_SHOPIFY: ReviewCardsContent = {
  translationPrefix: 'SYNCEE_STATIC_REVIEWS.SHOPIFY',
  reviews: [
    {
      key: 'LOW_COUNTRY_FAIR_TRADE',
      stars: 5,
      reviewerName: 'Low Country Fair Trade',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/shopify-review-logo-01.webp`,
        alt: 'Low Country Fair Trade logo',
      },
    },
    {
      key: 'KENIKE',
      stars: 5,
      reviewerName: 'Kenike',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/shopify-review-logo-02.webp`,
        alt: 'Kenike logo',
      },
    },
    {
      key: 'MEIN_MONTESSORI',
      stars: 5,
      reviewerName: 'Mein Montessori',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/shopify-review-logo-03.webp`,
        alt: 'Mein Montessori logo',
      },
    },
    {
      key: 'BUY_ME_FUR',
      stars: 5,
      reviewerName: 'Buy Me Fur',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/buy_me_fur.webp`,
        alt: 'Buy Me Fur logo',
      },
    },
    {
      key: 'SNEASSY',
      stars: 5,
      reviewerName: 'Sneassy',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/sneassy.webp`,
        alt: 'Sneassy logo',
      },
    },
    {
      key: 'PARFUMFRANCE',
      stars: 5,
      reviewerName: 'Parfurmfrance.com',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/parfumfrance.webp`,
        alt: 'Parfurmfrance.com logo',
      },
    },
    {
      key: 'WHERE_THE_HEART_IS_STORE',
      stars: 5,
      reviewerName: 'Where the Heart Is Store',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/where_the_heart_is_store.webp`,
        alt: 'Where the Heart Is Store logo',
      },
    },
    {
      key: 'POSHE',
      stars: 5,
      reviewerName: 'POSHÉ',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/poshe.webp`,
        alt: 'POSHÉ logo',
      },
    },
    {
      key: 'MAGENTA_MUSE',
      stars: 5,
      reviewerName: 'Magenta Muse',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/magenta_muse.webp`,
        alt: 'Magenta Muse logo',
      },
    },
  ],
};

export const SYNCEE_STATIC_REVIEWS_SHOPIFY_PLUS: ReviewCardsContent = {
  translationPrefix: 'SYNCEE_STATIC_REVIEWS.SHOPIFY_PLUS',
  reviews: [
    {
      key: 'CONCILIA_KAMBARAMI',
      stars: 5,
      reviewerName: 'Concilia Kambarami',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/shopifyplus-review-logo-01.webp`,
        alt: 'Concilia Kambarami logo',
      },
    },
    {
      key: 'LOW_COUNTRY_FAIR_TRADE',
      stars: 5,
      reviewerName: 'Low Country Fair Trade',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/shopifyplus-review-logo-02.webp`,
        alt: 'Low Country Fair Trade logo',
      },
    },
    {
      key: 'ZALERMO_360',
      stars: 5,
      reviewerName: 'Zalermo 360',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/shopifyplus-review-logo-03.webp`,
        alt: 'Zalermo 360 logo',
      },
    },
    {
      key: 'LAUREL_LANE_CO',
      stars: 5,
      reviewerName: 'Laurel Lane & Co.',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/laurel_lane.webp`,
        alt: 'Laurel Lane & Co. logo',
      },
    },
    {
      key: 'HALL_OF_FRAMES',
      stars: 5,
      reviewerName: 'Hall of Frames',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/hall_of_frames.webp`,
        alt: 'Hall of Frames logo',
      },
    },
    {
      key: 'SIMPLY_SCENTAMENTAL',
      stars: 5,
      reviewerName: 'Simply ScentaMental LLC',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/simply_scentamental_llc.webp`,
        alt: 'Simply ScentaMental LLC logo',
      },
    },
    {
      key: 'SUMAK',
      stars: 5,
      reviewerName: 'Sumak Australia',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/sumak_australia.webp`,
        alt: 'Sumak Australia logo',
      },
    },
    {
      key: 'VICTORIOUS_BABY_CLOTHES',
      stars: 5,
      reviewerName: 'VictoriousBabyClothes',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/victorious_baby_clothes.webp`,
        alt: 'VictoriousBabyClothes logo',
      },
    },
    {
      key: 'WHERE_THE_HEART_IS_STORE',
      stars: 5,
      reviewerName: 'Where the Heart Is Store',
      reviewerLogo: {
        src: `${environment.assetsUrl}/images/static-reviews/where_the_heart_is_store.webp`,
        alt: 'Where the Heart Is Store logo',
      },
    },
  ],
};
