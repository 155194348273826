import { Component } from '@angular/core';
import { SupplierTypes } from '../../../../../../utils/Constants';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { AppState } from '../../../../../../app.state';

@Component({
  selector: 'app-supplier-type-filter',
  templateUrl: './supplier-type-filter.component.html',
  styleUrls: ['./supplier-type-filter.component.scss'],
})
export class SupplierTypeFilterComponent {
  public productSearchStore$: Observable<ProductSearchState>;
  public approveType = [
    { label: 'ANY', id: 'any' },
    { label: 'APPROVE_NEEDED', id: 'true' },
    { label: 'FREE_TO_USE', id: 'false' },
  ];
  public premiumType = [
    { label: 'ANY', id: 'any' },
    { label: 'YES', id: 'true' },
    { label: 'NO', id: 'false' },
  ];

  constructor(private store: Store<AppState>) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
  }

  handleApproveChange(value: string): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { approveType: value } }));
  }

  handlePremiumChange(value: string): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { premium: value } }));
  }
}
