import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerComponent } from 'app/shared/components/image-viewer/image-viewer.component';
import { ChatService } from 'app/service/chat/chat.service';
import { ChatMessage } from '../../../../../service/chat/model/chat-models';

export interface ChatItemOption {
  background: string;

  color: string;
}

@Component({
  selector: 'app-chat-message-item',
  templateUrl: './chat-message-item.component.html',
  styleUrls: ['./chat-message-item.component.scss'],
})
export class ChatMessageItemComponent implements OnInit {
  @Input() direction: 'chat-side-left' | 'chat-side-right' = 'chat-side-right';

  @Input() options: ChatItemOption;
  @Input() message: ChatMessage;

  containsOnlyEmoji = false;

  constructor(private modalService: MatDialog, private chatService: ChatService) {}

  ngOnInit(): void {
    this.containsOnlyEmoji = this.chatService.isOnlyEmoji(this.message.message.replace(/\s/g, ''));
  }

  openImageViewer(): void {
    this.modalService
      .open(ImageViewerComponent, { data: { imageUrls: this.message.media.map((v) => v.value) } })
      .afterClosed()
      .subscribe(() => {});
  }
}
