<div class="chat-body-input-container">
  <input
    type="file"
    style="display: none"
    class="file-input"
    accept="image/x-png,image/gif,image/jpeg"
    (change)="onFileSelected($event)"
    #fileUpload
  />
  <button mat-icon-button (click)="fileUpload.click()">
    <mat-icon>image</mat-icon>
  </button>
  <div *ngIf="mediaInput && mediaInput.filePath" class="file-holder">
    <img [src]="mediaInput.filePath" alt="'Your chosen image'" />
    <button *ngIf="isMobile" class="btn-close mat-button" mat-icon-button>
      <mat-icon (click)="resetMedia()" *ngIf="isMobile">close</mat-icon>
    </button>
    <button *ngIf="!isMobile" class="btn-close" mat-icon-button>
      <mat-icon (click)="resetMedia()" *ngIf="!isMobile">delete_outline</mat-icon>
    </button>
  </div>
  <div fxFlex="1 0 0" class="message-input-wrapper">
    <mat-form-field style="width: 100%" class="form-field select-without-label" appearance="fill">
      <textarea
        style="width: 100%"
        matInput
        [placeholder]="'CHAT.PLACEHOLDER' | translate"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        [(ngModel)]="input"
        (keyup.enter)="send()"
      ></textarea>
      <span matSuffix>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="s-20">emoji_emotions</mat-icon>
        </button>
      </span>
    </mat-form-field>
    <mat-menu #menu="matMenu" class="emoji-menu">
      <emoji-mart
        [isNative]="true"
        set="apple"
        [sheetSize]="20"
        [useButton]="true"
        [hideObsolete]="true"
        [emojiSize]="20"
        [darkMode]="false"
        [showPreview]="false"
        color="#286df8"
        emoji=""
        [enableSearch]="false"
        [perLine]="8"
        (emojiClick)="handleSelection($event)"
      >
      </emoji-mart>
    </mat-menu>
  </div>
  <button (click)="send()" mat-icon-button>
    <mat-icon>send</mat-icon>
  </button>
</div>
