import { Injectable } from '@angular/core';
import { UpperRowItemWithChildren } from './models/upper-row-item-with-children';
import { FuseNavigation, FuseNavigationItemType } from '../../../../../../@fuse/types';
import { UpperRowItemType } from './models/upper-row-item-type';

@Injectable({
  providedIn: 'root',
})
export class UpperRowService {
  constructor() {}

  mapUpperRowItemToFuseNavigationItem(item: UpperRowItemWithChildren): FuseNavigation {
    return {
      id: `menu_upper_row_${item.title}`,
      title: item.title,
      translate: item.title,
      type: this.mapRowItemTypeToFuseNavigationType(item.type),
      url: item.link,
      externalUrl: item.type === 'external-link',
      openInNewTab: item.type === 'external-link',
      children: item.children && item.children.map((child) => this.mapUpperRowItemToFuseNavigationItem(child)),
    };
  }

  mapRowItemTypeToFuseNavigationType(type: UpperRowItemType): FuseNavigationItemType {
    switch (type) {
      case 'external-link':
      case 'inner-link':
        return 'item';
      case 'menu':
        return 'collapsable';
      default:
        return 'item';
    }
  }
}
