import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { EcomInterface } from './ecom-interface';
import { EcomService } from '../../../../service/ecom/ecom.service';
import { UserService } from '../../../../service/user/user.service';

@Component({
  selector: 'app-shopify',
  templateUrl: './shopify.component.html',
  styleUrls: ['./shopify.component.scss'],
})
export class ShopifyComponent implements OnInit, EcomInterface {
  @ViewChild('shopifyFileForm', { static: true }) shopifyForm: NgForm;
  // @ViewChild('ng') apiKeyModel: NgModel;
  // @ViewChild('apiKey') apiKeyModel: NgModel;
  // @ViewChild('password') passwordModel: NgModel;
  @Input() set inputs(input: any) {
    this.init(input);
  }

  type: string;
  ecomId: any;
  domains: any[];

  constructor(private ecomService: EcomService, private userService: UserService) {}

  ngOnInit(): void {}

  init(input): void {
    const role = this.userService.getActualRole();
    this.ecomService.getSourceSelectableDomains(input.taskId, role).subscribe((data) => {
      this.type = input.type;
      this.domains = data.filter((item) => item.ecomType === this.type);
      this.ecomId = parseInt(input.ecomId, 10) || 0;
      if (!this.ecomId && this.domains.length > 0) {
        this.ecomId = this.domains[0].id;
      }
    });
  }

  public save(): any {
    for (const key of Object.keys(this.shopifyForm.controls)) {
      this.shopifyForm.controls[key].markAsTouched();
    }
    if (!this.shopifyForm.valid) {
      return null;
    }

    const dom = this.domains.find((d) => d.id === this.ecomId);
    return { ecomId: this.ecomId, domain: dom.domain, ecomType: dom.ecomType };
  }
}
