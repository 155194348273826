import { NgModule } from '@angular/core';
import { CategorySelectorComponent } from './category-selector.component';
import { CategorySelectorPopupContentComponent } from './category-selector-popup-content/category-selector-popup-content.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierSimpleItemComponent } from '../../../shared/components/supplier-simple-item/supplier-simple-item.component';
import { CategoryImageFromId } from '../../../shared/pipes/category-image-from-id';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { SupplierLogoFromUseridPipe } from '../../../shared/pipes/supplier-logo-from-userid.pipe';
import { CategorySelectorPopupRecommendedComponent } from './category-selector-popup-content/category-selector-popup-recommended/category-selector-popup-recommended.component';
import { CategoryNameByIdPipe } from '../../../shared/pipes/category-name-by-id.pipe';
import { SupplierSimpleItemSkeletonComponent } from '../../../shared/components/supplier-simple-item/supplier-simple-item-skeleton.component';

@NgModule({
  declarations: [
    CategorySelectorComponent,
    CategorySelectorPopupContentComponent,
    CategorySelectorPopupRecommendedComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    FlexModule,
    TranslateModule,
    MatIconModule,
    SupplierSimpleItemComponent,
    CategoryImageFromId,
    MatDividerModule,
    SupplierLogoFromUseridPipe,
    CategoryNameByIdPipe,
    SupplierSimpleItemSkeletonComponent,
  ],
  exports: [CategorySelectorComponent],
})
export class CategorySelectorModule {}
