import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SanitizeHtmlPipe } from 'app/shared/pipes/sanitize-html.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { GeneralButtonComponent } from '../general-button/general-button.component';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-syncee-cta-two-columns',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    SanitizeHtmlPipe,
    GeneralButtonComponent,
    CustomDividerComponent,
  ],
  templateUrl: './syncee-cta-two-columns.component.html',
  styleUrls: ['./syncee-cta-two-columns.component.scss'],
})
export class SynceeCtaTwoColumnsComponent implements OnInit {
  @Input() title: string;
  @Input() descriptionInnerHTML: string;
  @Input() descriptionTemplateRef: TemplateRef<any>;
  @Input() subtitleLeft: string;
  @Input() subtitleRight: string;
  @Input() descriptionLeft: string;
  @Input() descriptionRight: string;
  @Input() buttonLeft: GeneralButton;
  @Input() buttonRight: GeneralButton;
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }
}
