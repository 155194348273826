import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuestionAnswerDialogComponent } from '../dialogs/question-answer-dialog/question-answer-dialog.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-storefront-report-supplier',
  templateUrl: './report-supplier.component.html',
  styleUrls: ['./report-supplier.component.scss'],
  standalone: true,
  imports: [FlexModule, TranslateModule, MatIconModule, SkeletonModule, CommonModule],
})
export class ReportSupplierComponent {
  @Input()
  reportedUserId: number;

  constructor(private dialog: MatDialog) {}

  public openReportDialog(): void {
    this.dialog.open(QuestionAnswerDialogComponent, {
      width: '544px',
      maxWidth: '544px',
      panelClass: 'custom-modal-full-width-lt-md',
      data: {
        questionCode: 'REPORT_SUPPLIER',
        reportedUserId: this.reportedUserId,
      },
    });
  }
}
