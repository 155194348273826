import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { SearchTerm } from '../model/variables/marketplace.variables';

export class SearchGtmAction implements GtmAction {
  event = GtmEvent.search;
  constructor(public payload: SearchGtmActionPayload) {}
}

// tslint:disable-next-line:no-empty-interface
export interface SearchGtmActionPayload extends SearchTerm {}
