<ng-container *ngIf="!!productLists && !!preferredCategories && preferredCategories.length > 0">
  <ng-container *ngIf="productLists.length > 0; else skeletons">
    <ng-container *ngIf="!!productLists[0]">
      <div
        class="content-row-padding-lt-xl content-row-max-width"
        *ngIf="productLists[0].productList as firstRowProducts; else skeleton"
      >
        <app-products-list-slider-box
          [productlist]="firstRowProducts"
          [title]="
            'EXPLORE_PRODUCTS.SPECIALS'
              | translate : { category: productLists[0].categoryId | categoryNameById | async }
          "
          [description]="'EXPLORE_PRODUCTS.BASED_ON_PREFERENCES' | translate"
          [ecomCurrency]="ecomCurrency"
          [isProductUsed]="productLists[0].isProductUsed"
          [filter]="{ category: productLists[0].categoryId }"
          [generateMicrodata]="true"
        ></app-products-list-slider-box>
      </div>
    </ng-container>

    <ng-content select="[first]"> </ng-content>

    <ng-container *ngIf="!!productLists[1]">
      <div
        class="content-row-padding-lt-xl content-row-max-width"
        *ngIf="productLists[1].productList as secondRowProducts; else skeleton"
      >
        <app-products-list-slider-box
          [productlist]="secondRowProducts"
          [title]="
            'EXPLORE_PRODUCTS.EXCLUSIVE'
              | translate : { category: productLists[1].categoryId | categoryNameById | async }
          "
          [description]="'EXPLORE_PRODUCTS.BASED_ON_PREFERENCES' | translate"
          [ecomCurrency]="ecomCurrency"
          [isProductUsed]="productLists[1].isProductUsed"
          [filter]="{ category: productLists[1].categoryId }"
          [generateMicrodata]="true"
        ></app-products-list-slider-box>
      </div>
    </ng-container>

    <ng-content select="[second]"> </ng-content>

    <ng-container *ngIf="!!productLists[2]">
      <div
        class="content-row-padding-lt-xl content-row-max-width"
        *ngIf="productLists[2].productList as thirdRowProducts; else skeleton"
      >
        <app-products-list-slider-box
          [productlist]="thirdRowProducts"
          [title]="
            'EXPLORE_PRODUCTS.QUALITY_PICKS'
              | translate : { category: productLists[2].categoryId | categoryNameById | async }
          "
          [description]="'EXPLORE_PRODUCTS.BASED_ON_PREFERENCES' | translate"
          [ecomCurrency]="ecomCurrency"
          [isProductUsed]="productLists[2].isProductUsed"
          [filter]="{ category: productLists[2].categoryId }"
          [generateMicrodata]="true"
        ></app-products-list-slider-box>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <div class="content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-products-list-slider-box></app-skeleton-products-list-slider-box>
  </div>
</ng-template>

<ng-template #skeletons>
  <ng-container *ngTemplateOutlet="skeleton"></ng-container>
  <ng-content select="[first]"> </ng-content>
  <ng-container *ngTemplateOutlet="skeleton"></ng-container>
  <ng-content select="[second]"> </ng-content>
  <ng-container *ngTemplateOutlet="skeleton"></ng-container>
</ng-template>
