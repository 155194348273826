import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { CleanedPageUrl, PageTitle } from '../model/variables/general.variables';

export class VirtualPageViewGtmAction implements GtmAction {
  event = GtmEvent.virtual_page_view;

  constructor(public payload: VirtualPageViewGtmActionPayload) {}
}

export interface VirtualPageViewGtmActionPayload extends CleanedPageUrl, PageTitle {}
