<ng-container *ngIf="supplier$ | async as supplier">
  <div
    class="w-100p"
    [style.max-width]="'500px'"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
    fxFlexAlign="center"
  >
    <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
      <app-supplier-icon
        [size]="'56px'"
        [supplierUserId]="supplier.userId"
        [supplierCompanyName]="supplier.companyName"
      ></app-supplier-icon>
      <div [ngClass.lt-md]="'typography-button'" [ngClass.gt-sm]="'typography-body-large-2'">
        {{ supplier.companyName }}
      </div>
    </div>
    <app-step-title-description
      [title]="'GETTING_STARTED.STEP_PAGE.SUMMARY.TITLE' | translate"
      [description]="'GETTING_STARTED.STEP_PAGE.SUMMARY.SUBTITLE' | translate"
    ></app-step-title-description>
    <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
      <app-step-navigation-buttons
        [ngClass.lt-md]="'w-100p'"
        [layout]="'column-reverse'"
        [prevButtonClass]="ltSm ? 'underline w-100p' : 'underline'"
        [nextButtonClass]="ltSm ? 'w-100p' : ''"
        (nextClick)="goToNextStep()"
        (prevClick)="goToPreviousStep()"
        [nextButtonLabel]="'GETTING_STARTED.STEP_PAGE.SUMMARY.CONTINUE_TO_SYNCEE' | translate"
      ></app-step-navigation-buttons>
    </div>
  </div>
</ng-container>
