import { Action } from '@ngrx/store';
import { User, UserSettings } from 'app/service/user/user';
import { PatchUserSettings } from '../../service/user/user-gateway.service';

export enum UserActionTypes {
  INIT_USER = '[User] Init User',
  UPDATE_USER_START = '[User] Update User Start',
  UPDATE_USER_SUCCESS = '[User] Update User Success',
  USER_DETAILS = '[User] User Details',
  USER_DETAILS_SUCCESS = '[User] User Details Success',
  USER_DETAILS_CHAIN_TO_ECOM_FETCH = '[User] User Details Success Chain To Ecom Fetch',
  USER_DETAILS_FORCE_CHAIN_TO_ECOM_FETCH = '[User] User Details Force Success Chain To Ecom Fetch',
  USER_DETAILS_CHAIN_TO_ECOM_FETCH_SUCCESS = '[User] User Details Chain To Ecom Fetch Success',
  SET_ACTUAL_ROLE = '[User] Set Actual Role',
  GET_LOCATION_BY_IP = '[User] Get location by ip start',
  UPDATE_USER_SETTINGS_START = '[User] Update user settings start',
  UPDATE_USER_SETTINGS_SUCCESS = '[User] Update user settings success',
}

export class UserDetails implements Action {
  readonly type = UserActionTypes.USER_DETAILS;
  constructor() {}
}

export class UserDetailsSuccess implements Action {
  readonly type = UserActionTypes.USER_DETAILS_SUCCESS;
  constructor(public payload: User) {}
}

export class UserDetailsChainedToEcomFetch implements Action {
  readonly type = UserActionTypes.USER_DETAILS_CHAIN_TO_ECOM_FETCH;
  constructor() {}
}

export class UserDetailsForceChainedToEcomFetch implements Action {
  readonly type = UserActionTypes.USER_DETAILS_FORCE_CHAIN_TO_ECOM_FETCH;
  constructor() {}
}

export class UserDetailsChainedToEcomFetchSuccess implements Action {
  readonly type = UserActionTypes.USER_DETAILS_CHAIN_TO_ECOM_FETCH_SUCCESS;
  constructor(public payload: User) {}
}

export class InitUserEffetct implements Action {
  readonly type = UserActionTypes.INIT_USER;
}

export class SetActualRole implements Action {
  readonly type = UserActionTypes.SET_ACTUAL_ROLE;
  constructor(public payload: number) {}
}

export class UpdateUserStart implements Action {
  readonly type = UserActionTypes.UPDATE_USER_START;
  constructor(public payload: UpdateUserStartPayload) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UPDATE_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class GetLocationByIp implements Action {
  readonly type = UserActionTypes.GET_LOCATION_BY_IP;
  constructor(public payload: string) {}
}

export class UpdateUserSettingsStartAction implements Action {
  readonly type = UserActionTypes.UPDATE_USER_SETTINGS_START;
  constructor(public payload: PatchUserSettings) {}
}

export class UpdateUserSettingsSuccessAction implements Action {
  readonly type = UserActionTypes.UPDATE_USER_SETTINGS_SUCCESS;
  constructor(public payload: UserSettings) {}
}

export type USER_ACTIONS_ALL =
  | UserDetails
  | UserDetailsSuccess
  | UserDetailsChainedToEcomFetch
  | UserDetailsChainedToEcomFetchSuccess
  | SetActualRole
  | UpdateUserStart
  | UpdateUserSuccess
  | GetLocationByIp
  | UpdateUserSettingsStartAction
  | UpdateUserSettingsSuccessAction;

export interface UpdateUserStartPayload {
  user: Partial<User>;
  options?: UpdateUserStartOptions;
}
export interface UpdateUserStartOptions {
  showNotification: boolean;
  notificationMessageKey: string;
}
