import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
})
export class BreadcrumbNavigationComponent implements OnInit {
  @Output() navItemChange = new EventEmitter<string>();
  @Input() navItems: BreadcrumbNavigationItem[] = [];
  @Input() activeItemKey: string;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  clickHandler(item: BreadcrumbNavigationItem): void {
    if (item.disabled === true) {
      return;
    }
    this.navItemChange.emit(item.id);
    if (item.callback) {
      item.callback(item.id);
    }
    if (item.url) {
      this._router.navigate([item.url]);
    }
  }
}

export interface BreadcrumbNavigationItem {
  id: string;
  key: string;
  url?: string;
  disabled?: boolean;
  queryParams?: { [key: string]: any };
  callback?: (key: string) => void;
}
