import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { forkJoin, Observable, of } from 'rxjs';
import { EcomFeatureUsage } from './model/ecom/ecom-feature-usage';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EcomUsageService {
  constructor(private springRestService: SpringRestService) {}

  getEcomFeatureUsage(...ecomId: number[]): Observable<EcomFeatureUsage[]> {
    const getObs = (id: number) =>
      this.springRestService
        .get(MicroserviceNames.USER, `/v1/Ecom/${id}/Feature/Usage`, undefined, true)
        .pipe(catchError(() => of([])));
    return forkJoin(ecomId.map((id) => getObs(id)));
  }
}
