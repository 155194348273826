import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatRadioModule } from '@angular/material/radio';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Utils } from 'app/utils/utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { AddFilter } from '../../../store/product-search/product-search.action';
import {
  MarketplaceFilter,
  MarketplaceFilterSort,
  ProductSearchSortField,
  ProductSearchSortOrder,
} from '../../../vo/search-product-vo';
@Component({
  selector: 'app-product-search-sort-selector',
  templateUrl: './product-search-sort-selector.component.html',
  styleUrls: ['./product-search-sort-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FlexModule, ExtendedModule, MatRadioModule],
})
export class ProductSearchSortSelectorComponent implements OnInit, OnDestroy {
  @Input() isSupplierSearch = false;

  private static INIT_VALUE: MarketplaceFilterSort = {
    sortField: null,
    sortOrder: null,
  };
  value: MarketplaceFilterSort = ProductSearchSortSelectorComponent.INIT_VALUE;
  options: Options[] = [];

  private baseOptions: Options[] = [
    {
      filter: { sortField: null, sortOrder: null },
      label: 'PRODUCT_LIST_UPPER_BAR.SORT.MOST_RELEVANT',
    },
    {
      filter: { sortField: ProductSearchSortField.CREATED, sortOrder: ProductSearchSortOrder.DESC },
      label: 'PRODUCT_LIST_UPPER_BAR.SORT.NEW_PRODUCTS',
    },
  ];

  private authOnlyOptions: Options[] = [
    {
      filter: { sortField: ProductSearchSortField.VARIANTS_PRICE, sortOrder: ProductSearchSortOrder.ASC },
      label: 'PRODUCT_LIST_UPPER_BAR.SORT.PRICE_LOW_TO_HIGH',
    },
    {
      filter: { sortField: ProductSearchSortField.VARIANTS_PRICE, sortOrder: ProductSearchSortOrder.DESC },
      label: 'PRODUCT_LIST_UPPER_BAR.SORT.PRICE_HIGH_TO_LOW',
    },
  ];

  private filter$: Observable<MarketplaceFilter>;
  private unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>) {
    this.unsubscribeAll = new Subject<void>();
    this.filter$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.filter)
    );
  }

  ngOnInit(): void {
    this.hideOptionsForGuests();
    this.subscribeToFilterChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private hideOptionsForGuests(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(omitNullOrUndefined(), takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (isAuthenticated) => {
          if (isAuthenticated && !this.isSupplierSearch) {
            this.options = [...this.baseOptions, ...this.authOnlyOptions];
          } else {
            this.options = this.baseOptions;
          }
        },
      });
  }

  handleValueChange(filter: MarketplaceFilterSort): void {
    this.store.dispatch(new AddFilter({ value: { ...filter } }));
  }

  compareWith(option: MarketplaceFilterSort, value: MarketplaceFilterSort): boolean {
    return option.sortOrder === value.sortOrder && option.sortField === value.sortField;
  }

  private subscribeToFilterChange(): void {
    this.filter$.pipe(takeUntil(this.unsubscribeAll)).subscribe((filter) => {
      if (!Utils.isNullOrUndefined(filter)) {
        this.value = {
          sortOrder: Utils.isNullOrUndefined(filter.sortOrder) ? null : filter.sortOrder,
          sortField: Utils.isNullOrUndefined(filter.sortField) ? null : filter.sortField,
        };
      } else {
        this.setValueToInit();
      }
    });
  }

  private setValueToInit(): void {
    this.value = ProductSearchSortSelectorComponent.INIT_VALUE;
  }
}

interface Options {
  filter: MarketplaceFilterSort;
  label: string;
}
