<div class="p-12 pt-0">
  <mat-card>
    <mat-card-header>
      <mat-card-title> {{ 'TASKS.HISTORY.TIMELINE' | translate }} </mat-card-title>
    </mat-card-header>
    <mat-card-content #card class="pb-16" overflowDirective [scrollLeft]="card.scrollWidth">
      <div fxLayout="row" class="times-container">
        <div
          class="time-cell"
          *ngFor="let time of dates"
          [style.left.px]="taskNameRef?.nativeElement.offsetWidth - hourCellRef?.nativeElement.offsetWidth / 2"
        >
          <div class="text-center">
            {{ time[0] }}
          </div>
          <div class="text-center">
            {{ time[1] }}
          </div>
        </div>
      </div>
      <div>
        <div
          class="now"
          [style.min-width.px]="
            nowCellRef?.nativeElement.offsetWidth +
            overLayRef?.nativeElement.offsetWidth +
            taskNameRef?.nativeElement.offsetWidth
          "
        >
          Now {{ now.toLocaleString().split(',')[1] }}
        </div>
      </div>

      <div class="timeline-row" *ngIf="!isOnMobile">
        <div
          class="timeline-container"
          *ngFor="let task of taskHistories; let i = index"
          [class.darkerBackground]="i % 2 === 0"
        >
          <div #taskName class="task-name" [ngClass]="i % 2 === 0 ? 'darkerBackground' : 'white'">
            <div class="bold-text" [matTooltip]="task['taskId']">
              {{ task['taskName'] }}
            </div>
          </div>

          <div
            #overLay
            class="overlay-row"
            fxLayoutAlign="start center"
            fxLayout="row"
            [style.left.px]="taskName.offsetWidth"
          >
            <div class="timeline-bar center">
              <div
                class="history-bar"
                *ngFor="let history of task.histories"
                [style.min-width.px]="history.barLength"
                [style.max-width.px]="history.barLength"
                [style.left.px]="history.startPos"
              >
                <div
                  class="updated"
                  *ngIf="history['h_resultUpdated'] !== 0"
                  [matTooltip]="'Updated: ' + history['h_resultUpdated']"
                  [style.min-width.%]="(history['h_resultUpdated'] / history['sum']) * 100"
                ></div>

                <div
                  class="new"
                  *ngIf="history['h_resultNew'] !== 0"
                  [matTooltip]="'New: ' + history['h_resultNew']"
                  [style.min-width.%]="(history['h_resultNew'] / history['sum']) * 100"
                ></div>

                <div
                  class="deleted"
                  *ngIf="history['h_resultDeleted'] !== 0"
                  [matTooltip]="'Deleted: ' + history['h_resultDeleted']"
                  [style.min-width.%]="(history['h_resultDeleted'] / history['sum']) * 100"
                ></div>

                <div
                  class="not-modified"
                  *ngIf="history['h_resultNotModified'] !== 0"
                  [matTooltip]="'Not Modified: ' + history['h_resultNotModified']"
                  [style.min-width.%]="(history['h_resultNotModified'] / history['sum']) * 100"
                ></div>
              </div>
            </div>

            <div #hourCell class="hour-cell" *ngFor="let hour of numberOfHours"></div>
            <div
              #nowCell
              class="now"
              [class.darkerBackground]="i % 2 === 0"
              [style.min-width.px]="now.getMinutes() * minuteLength"
            ></div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
