import { Injectable } from '@angular/core';
import { User } from '../user/user';

@Injectable({
  providedIn: 'root',
})
export class CachedUserService {
  getCachedUser(): User {
    return JSON.parse(localStorage.getItem('currentUser')) as User;
  }

  getActualRole(): number {
    return Number(localStorage.getItem('actualRole'));
  }

  getRawAuthToken(): string {
    return localStorage.getItem('access_token');
  }

  getUserDetails(): User {
    return JSON.parse(localStorage.getItem('userDetails')) as User;
  }
}
