import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'categoryImageFromId',
  standalone: true,
})
export class CategoryImageFromId implements PipeTransform {
  transform(value: any, ...args): any {
    return `${environment.storageUrl}/o/category-images%2F${value}.jpg?alt=media`;
  }
}
