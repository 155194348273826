import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, Observable, zip } from 'rxjs';
import {
  CurrencyActionTypes,
  FetchSelectedCurrenciesStart,
  FetchSelectedCurrenciesSuccess,
  FetchSelectedCurrenciesWithoutChainStart,
  FetchSelectedCurrenciesWithoutChainSuccess,
  NoEcomSetSelectedCurrencyStartAction,
  NoEcomSetSelectedCurrencySuccessAction,
  SelectedEcomSetSelectedCurrencyStartAction,
  SelectedEcomSetSelectedCurrencySuccessAction,
  SetSelectedCurrencyStartAction,
} from './currency.action';
import { map, switchMap, take } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { selectedRetailerEcomSelector, selectedSupplierEcomSelector } from '../ecom/ecom.selector';
import { CurrencyExchange, CurrencyService } from '../../service/currency-service/currency.service';
import {
  EcomActionTypes,
  SelectedEcomCurrencyUpdateStart,
  SelectedEcomCurrencyUpdateSuccess,
  UserEcomChainToSubscriptionChangedSuccess,
} from '../ecom/ecom.actions';
import { EcomVO } from '../../service/ecom/ecom.service';
import { selectedCurrencySelector } from './currency.selector';
import { NotificationService } from '../../main/notification/notification.service';
import { isNil, omitBy } from 'lodash';
import { RolesEnum } from '../../vo/roles/roles';

@Injectable()
export class CurrencyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private currencyService: CurrencyService,
    private notificationService: NotificationService
  ) {}

  SetCurrencyWithoutChain: Observable<FetchSelectedCurrenciesWithoutChainSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_WITHOUT_CHAIN_START),
      switchMap((action: FetchSelectedCurrenciesWithoutChainStart) =>
        this.currencyService.getCurrenciesExhangesByCurrency(action.payload.selectedCurrency).pipe(
          map(
            (currencies: CurrencyExchange[]) =>
              new FetchSelectedCurrenciesWithoutChainSuccess({
                currencies,
                selectedCurrency: action.payload.selectedCurrency,
              })
          )
        )
      )
    )
  );

  SetCurrency: Observable<FetchSelectedCurrenciesSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActionTypes.SET_SELECTED_CURRENCY_START),
      switchMap((action: FetchSelectedCurrenciesStart) =>
        this.currencyService
          .getCurrenciesExhangesByCurrency(action.payload.selectedCurrency)
          .pipe(
            map(
              (currencies: CurrencyExchange[]) =>
                new FetchSelectedCurrenciesSuccess({ currencies, selectedCurrency: action.payload.selectedCurrency })
            )
          )
      )
    )
  );

  FetchSelectedCurrenciesSuccess: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_SUCCESS),
      switchMap((action: SetSelectedCurrencyStartAction) => {
        return this.store.select(selectedCurrencySelector).pipe(
          take(1),
          switchMap((selectedCurrency) => this.dispatchIfCurrencyChanged(selectedCurrency, action))
        );
      })
    )
  );

  SetSelectedEcomCurrency: Observable<SelectedEcomCurrencyUpdateStart> = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActionTypes.SELECTED_ECOM_SET_SELECTED_CURRENCY_START),
      mergeMap((aciton: SelectedEcomSetSelectedCurrencyStartAction) => this.updateSelectedEcomsCurrency(aciton))
    )
  );

  SetNoEcomCurrency: Observable<NoEcomSetSelectedCurrencySuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActionTypes.NO_ECOM_SET_SELECTED_CURRENCY_START),
      map((aciton: NoEcomSetSelectedCurrencyStartAction) => new NoEcomSetSelectedCurrencySuccessAction(aciton.payload))
    )
  );

  SetToLocalStorage: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CurrencyActionTypes.NO_ECOM_SET_SELECTED_CURRENCY_SUCCESS,
          CurrencyActionTypes.SELECTED_ECOM_SET_SELECTED_CURRENCY_SUCCESS
        ),
        map((action: SelectedEcomSetSelectedCurrencySuccessAction) => {
          this.setCurrencyToLocalStorage(action.payload.selectedCurrency);
          if (action.payload.withNotification) {
            this.notificationService.success('Currency change successful!');
          }
        })
      ),
    { dispatch: false }
  );

  UpdateCurrencyOnSelectedEcomChange: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EcomActionTypes.USER_ECOM_CHAIN_TO_SUBSCRIPTION_CHANGED_SUCCESS,
        EcomActionTypes.USER_ECOMS_CHAIN_TO_SUBSCRIPTION_FETCH_SUCCESS
      ),
      map((action: UserEcomChainToSubscriptionChangedSuccess) =>
        this.dispatchInitialCurrency(action.payload.selectedEcom)
      )
    )
  );

  SelectedEcomUpdateSuccess: Observable<SelectedEcomSetSelectedCurrencySuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.SELECTED_ECOM_CURRENCY_UPDATE_SUCCESS),
      map(
        (action: SelectedEcomCurrencyUpdateSuccess) =>
          new SelectedEcomSetSelectedCurrencySuccessAction({
            selectedCurrency: CurrencyService.getCurrency(action.payload.selectedEcom),
            withNotification: true,
          })
      )
    )
  );

  private dispatchIfCurrencyChanged(
    selectedCurrency: string,
    aciton: SetSelectedCurrencyStartAction
  ): Observable<Action> {
    return zip([
      this.store.select(selectedRetailerEcomSelector).pipe(take(1)),
      this.store.select(selectedSupplierEcomSelector).pipe(take(1)),
    ]).pipe(
      take(1),
      map(([retailer, supplier]) => ({ retailer, supplier })),
      switchMap((ecomsFromStore) => {
        const actions: Action[] = [];
        if (Object.keys(omitBy(ecomsFromStore, isNil)).length) {
          if (ecomsFromStore.retailer) {
            actions.push(
              new SelectedEcomSetSelectedCurrencyStartAction({
                ...aciton.payload,
                ecom: ecomsFromStore.retailer,
                role: RolesEnum.RETAILER,
              })
            );
          }
          if (ecomsFromStore.supplier) {
            actions.push(
              new SelectedEcomSetSelectedCurrencyStartAction({
                ...aciton.payload,
                ecom: ecomsFromStore.supplier,
                role: RolesEnum.SUPPLIER,
              })
            );
          }
          return actions;
        } else {
          return [new NoEcomSetSelectedCurrencyStartAction(aciton.payload)] as Action[];
        }
      })
    );
  }

  private setCurrencyToLocalStorage(currency: string): void {
    localStorage.setItem('preferredCurrency', currency);
  }

  private updateSelectedEcomsCurrency(
    aciton: SelectedEcomSetSelectedCurrencyStartAction
  ): Observable<SelectedEcomCurrencyUpdateStart> {
    return this.currencyService.saveDefaultCurrency(aciton.payload.ecom.id, aciton.payload.selectedCurrency).pipe(
      map(
        () =>
          new SelectedEcomCurrencyUpdateStart({
            ecom: aciton.payload.ecom,
            currency: aciton.payload.selectedCurrency,
            role: aciton.payload.role,
          })
      )
    );
  }

  private dispatchInitialCurrency(selectedEcom: EcomVO): Action {
    if (selectedEcom) {
      return new SelectedEcomSetSelectedCurrencySuccessAction({
        selectedCurrency: CurrencyService.getCurrency(selectedEcom),
        withNotification: false,
      });
    } else {
      return new NoEcomSetSelectedCurrencyStartAction({
        selectedCurrency: CurrencyService.getCurrency(selectedEcom),
        withNotification: false,
      });
    }
  }
}
