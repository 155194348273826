import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FieldStructure, FilterItem, FilterItemComponent } from './filter-item/filter-item.component';

import {
  Constants,
  FILTER_VALUES_OFFSETS,
  MAXIMUM_FIELD_CONDITION_PAIR,
  MAXIMUM_FIELD_NUMBER,
  MAXIMUM_FIELDS_PER_TYPE,
  USER_ROLES,
} from '../../utils/Constants';
import { TaskFilterService } from '../../service/taskwizard/task-filter.service';
import { NotificationService } from '../notification/notification.service';
import { TaskFilters } from '../../service/taskwizard/taskwizard-update-response';
import { TaskwizardUpdateService } from '../../service/taskwizard/taskwizard-update.service';
import { FilterBox } from './filter-box';
import { StepBase } from '../taskwizard/step-base';
import { Observable, Subject, Subscription } from 'rxjs';
import { CatalogService } from '../../service/catalog/catalog.service';
import { LoadingScreenService } from '../../service/loading-screen/loading-screen.service';
import { ExportTaskwizardUpdateService } from '../../service/taskwizard/export-taskwizard-update.service';
import { Utils } from '../../utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { MarketplaceEcomService } from '../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../service/user/user.service';
import { TaskStepLockService } from '../../service/task/task-step-lock.service';
import { isObject } from 'lodash';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, StepBase, OnDestroy {
  @ViewChildren('filterItems') private filterItems: QueryList<FilterItemComponent>;

  @Input() taskId: number;
  @Input() taskIdForSuggestion?: number;
  @Input() usedComponent: string;
  @Input() catalogId: number;
  @Input() hasDefaultFilter = false;
  @Input() ecomId;
  @Input() hasVariantFilter = false;
  @Input() disableFilter = false;

  @Output() filterForSearch = new EventEmitter();

  filterBoxes: FilterBox[] = [];
  variantFilterBoxes: FilterBox[] = [];
  allConditions: Operator[] = [
    { key: 'and', display: 'all conditions' },
    { key: 'or', display: 'any conditions' },
  ];
  @Input() comments: any;

  fieldsStructure: FieldStructure[] = [];
  variantfieldsStructure: FieldStructure[] = [];

  // condOperatorBetweenBoxes = Constants.OPERATOR_AND;
  condOperatorBetweenBoxes: string[] = [];
  condOperatorBetweenVariantBoxes: string[] = [];
  customFilter = [];
  restDataObserverEvent: Subscription;
  applyFilterVisible: boolean;
  message = '';
  filterLoaded = false;
  variantFilterLoaded = false;
  subs: Subscription = new Subscription();

  private _unsubscribeAll: Subject<void>;

  hasError = false;
  public errorType: FilterErrorTypeVO = {
    maxFieldNumberError: false,
    maxFieldConditionPairError: false,
    maxFieldsPerTypeError: false,
    maxValuesError: false,
  };
  public isAdmin: boolean;
  public stepLocked = false;

  constructor(
    private taskwUpdateService: TaskwizardUpdateService,
    private notification: NotificationService,
    private taskFilterService: TaskFilterService,
    private marketplaceEcomService: MarketplaceEcomService,
    private loadScreenService: LoadingScreenService,
    private etwUpdateService: ExportTaskwizardUpdateService,
    private catalogService: CatalogService,
    private translate: TranslateService,
    private stepLockService: TaskStepLockService,
    private _userService: UserService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.initComponent();
  }

  private initStepLock(): void {
    this.stepLockService.getTaskStepsForTask(this.taskId).subscribe((lockedSteps) => {
      if (lockedSteps.includes('FILTER')) {
        this.stepLocked = true;
      }
    });
  }

  initComponent(): void {
    this.isAdmin = this._userService.getCachedUser().role === USER_ROLES.GLOBAL_ADMIN;
    this.filterLoaded = false;
    this.variantFilterLoaded = false;
    this.applyFilterVisible = this.usedComponent === 'retailer-catalog' || this.usedComponent === 'explore-products';
    switch (this.usedComponent) {
      case 'retailer-catalog':
        this.subs.add(
          this.catalogService.getRetailerCatalogById(this.catalogId).subscribe((res) => {
            this.subs.add(
              this.taskFilterService
                .getFiltersStructureForRetailer(
                  res.filter.filter((filter) => filter.key === 'TASK_ID')[0].values[0],
                  this.catalogId,
                  this.ecomId ? this.ecomId : this.marketplaceEcomService.selectedEcom.ecomId
                )
                .subscribe((fieldsStructure) => {
                  this.fieldsStructure = fieldsStructure;

                  let setFilter = res.filter;
                  if (
                    res.filter.length > 2 &&
                    res.filter[res.filter.length - 1].hasOwnProperty('key') &&
                    res.filter[res.filter.length - 1].key === 'TASK_ID'
                  ) {
                    setFilter = res.filter.slice(0, res.filter.length - 2);
                  }

                  this.initToUpdate(
                    this.taskwUpdateService.setFilterForRetailerCatalog(
                      Array.isArray(setFilter) &&
                        (setFilter.length > 1 || (setFilter[0].key !== 'TASK_ID' && Array.isArray(setFilter[0])))
                        ? setFilter[0]
                        : setFilter,
                      fieldsStructure
                    )
                  );
                })
            );
          })
        );
        this.message = 'FILTER.FILTER_DESCR';
        break;
      case 'explore-products': // Find suppliers catalog's products listing filter
        this.subs.add(
          this.taskFilterService
            .getFiltersStructureForRetailer(
              this.taskId,
              this.catalogId,
              this.ecomId ? this.ecomId : this.marketplaceEcomService.selectedEcom.ecomId
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((fieldsStructure) => {
              this.fieldsStructure = fieldsStructure;
              this.filterLoaded = true;
            })
        );
        this.message = 'FILTER.FILTER_DESCR';
        break;
      case 'export-task':
        if (this.etwUpdateService.filterIsInited) {
          this.fieldsStructure = this.etwUpdateService.data.taskFilters.fieldsStructure;
          this.initToUpdate(this.etwUpdateService.data.taskFilters);

          if (this.hasDefaultFilter && !this.etwUpdateService.isFilterSaved) {
            this.initDefaultFieldStructure();
          }
        } else {
          this.subs.add(
            this.etwUpdateService.getRestDataFinished().subscribe((res) => {
              if (res !== true) {
                return;
              }
              this.fieldsStructure = this.etwUpdateService.data.taskFilters.fieldsStructure;
              this.initToUpdate(this.etwUpdateService.data.taskFilters);
              if (this.hasDefaultFilter && !this.etwUpdateService.isFilterSaved) {
                this.initDefaultFieldStructure();
              }
            })
          );
        }
        this.message = 'FILTER.FILTER_DESCR_EXPORT';
        break;
      default:
        this.initStepLock();
        this.subs.add(
          this.taskFilterService.getFieldStructures(this.taskId).subscribe((fieldsStructure) => {
            this.fieldsStructure = fieldsStructure;
            if (this.taskwUpdateService.isUpdate && this.taskwUpdateService.data.isTaskFilters()) {
              this.taskwUpdateService.data.taskFilters.filterBoxes.map((filterItem) => {
                if (isObject(filterItem)) {
                  filterItem.filterItems.map((item) => (item.fieldStructures = this.fieldsStructure));
                }
              });
              this.initToUpdate(this.taskwUpdateService.data.taskFilters);
            }
            if (this.hasDefaultFilter && !this.taskwUpdateService.data.isTaskFilters()) {
              this.initDefaultFieldStructure();
            }
            this.filterLoaded = true;
          })
        );

        if (this.hasVariantFilter) {
          this.subs.add(
            this.taskFilterService.getVariantFieldStructures(this.taskId).subscribe((fieldsStructure) => {
              this.variantfieldsStructure = fieldsStructure;
              if (this.taskwUpdateService.isUpdate && this.taskwUpdateService.data.isTaskVariantFilters()) {
                this.taskwUpdateService.data.variantFilters.filterBoxes.map((filterItem) => {
                  if (isObject(filterItem)) {
                    filterItem.filterItems.map((item) => (item.fieldStructures = this.variantfieldsStructure));
                  }
                });
                this.initToUpdateVariantsFilter(this.taskwUpdateService.data.variantFilters);
              }
              this.variantFilterLoaded = true;
            })
          );
        }
    }
  }

  filterChange(_?: unknown): void {
    if (this.usedComponent === 'retailer-catalog') {
      const fields = this.getFields();
      this.getFieldsError(fields);
    }
  }

  private getFieldsError(fields: FilterItemNumberVO[]): void {
    this.hasError = false;
    this.errorType = {
      maxFieldsPerTypeError: false,
      maxFieldConditionPairError: false,
      maxFieldNumberError: false,
      maxValuesError: false,
    };
    const countFields = {};
    const countFieldCondPairs = {};
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < fields.length; i++) {
      countFields[fields[i].field] = 1 + (countFields[fields[i].field] || 0);
      const keyForCountFieldCondPairs = fields[i].field + fields[i].condition;
      countFieldCondPairs[keyForCountFieldCondPairs] = 1 + (countFieldCondPairs[keyForCountFieldCondPairs] || 0);

      if (countFields[fields[i].field] > MAXIMUM_FIELDS_PER_TYPE) {
        this.errorType.maxFieldsPerTypeError = true;
        this.hasError = true;
      }

      if (countFieldCondPairs[keyForCountFieldCondPairs] > MAXIMUM_FIELD_CONDITION_PAIR) {
        this.errorType.maxFieldConditionPairError = true;
        this.hasError = true;
      }

      if (Object.keys(countFields).length > MAXIMUM_FIELD_NUMBER) {
        this.errorType.maxFieldNumberError = true;
        this.hasError = true;
      }

      if (fields[i].numberOfValues > this.getMaxValues(fields[i])) {
        this.errorType.maxValuesError = true;
        this.hasError = true;
      }
    }
  }

  private getMaxValues(field): number {
    const fieldType = this.fieldsStructure.find((item) => item.key === field.field);
    const fieldLimits = FILTER_VALUES_OFFSETS.find((item) => item.field === field.field);
    if (Utils.isNullOrUndefined(fieldLimits)) {
      return fieldType.valuesLimit;
    } else {
      const cond = fieldLimits.conditions.find((condition) => condition.key === field.condition);
      return fieldType.valuesLimit + (Utils.isNullOrUndefined(cond) ? 0 : cond.offset);
    }
  }

  private getFields(): any {
    const fields: FilterItemNumberVO[] = [];
    this.filterBoxes.forEach((box) => {
      box.filterItems.forEach((filterItem) => {
        if (filterItem.field !== '' && filterItem.condition !== '') {
          fields.push({
            field: filterItem.field,
            condition: filterItem.condition,
            numberOfValues: filterItem.values.length,
          });
        }
      });
    });
    return fields;
  }

  getErrorMessage(): string[] {
    const errorMessages: string[] = [];
    if (this.errorType.maxFieldsPerTypeError) {
      errorMessages.push(this.translate.instant('FILTER.EXCEEDED'));
    }
    if (this.errorType.maxFieldConditionPairError) {
      errorMessages.push(
        this.translate.instant('FILTER.FIELD_CONDITION_PAIR_PRE') +
          MAXIMUM_FIELD_CONDITION_PAIR +
          this.translate.instant('FILTER.FIELD_CONDITION_PAIR_AFTER')
      );
    }
    if (this.errorType.maxFieldNumberError) {
      errorMessages.push(
        this.translate.instant('FILTER.FIELD_NUMBER_PRE') +
          MAXIMUM_FIELD_NUMBER +
          this.translate.instant('FILTER.FIELD_NUMBER_AFTER')
      );
    }
    if (this.errorType.maxValuesError) {
      errorMessages.push(this.translate.instant('FILTER.MAX_VALUES'));
    }
    return errorMessages;
  }

  initDefaultFieldStructure(): void {
    const defaultFilterBoxPublished = new FilterBox();
    defaultFilterBoxPublished.operator = 'and';
    defaultFilterBoxPublished.filterItems = [
      {
        condition: 'exists',
        field: this.usedComponent === 'export-task' ? 'published_at' : 'SHOPIFY_PUBLISHED',
        fieldStructures: this.fieldsStructure,
        values: [],
      },
    ];

    this.condOperatorBetweenBoxes.push('and');

    const defaultFilterBoxImages = new FilterBox();

    defaultFilterBoxImages.operator = 'or';
    defaultFilterBoxImages.filterItems = [
      {
        condition: 'exists',
        field: this.usedComponent === 'export-task' ? 'images' : 'IMAGES',
        fieldStructures: this.fieldsStructure,
        values: [],
      },
    ];

    if (this.usedComponent !== 'export-task') {
      defaultFilterBoxImages.filterItems.push({
        condition: 'exists',
        field: 'VARIANTS.V_IMAGES',
        fieldStructures: this.fieldsStructure,
        values: [],
      });
    }

    this.filterBoxes = [defaultFilterBoxPublished, defaultFilterBoxImages];
  }

  reInit(): void {
    this.ngOnInit();
  }

  initToUpdate(taskFilters: TaskFilters): void {
    this.filterBoxes = taskFilters.filterBoxes;
    // this.fieldsStructure = taskFilters.fieldsStructure;
    this.condOperatorBetweenBoxes = Utils.isNullOrUndefined(taskFilters.operatorBoxes) ? [] : taskFilters.operatorBoxes;
    this.filterLoaded = true;
  }

  initToUpdateVariantsFilter(taskFilters: TaskFilters): void {
    this.variantFilterBoxes = taskFilters.filterBoxes;
    // this.fieldsStructure = taskFilters.fieldsStructure;
    this.condOperatorBetweenVariantBoxes = Utils.isNullOrUndefined(taskFilters.operatorBoxes)
      ? []
      : taskFilters.operatorBoxes;
    this.variantFilterLoaded = true;
  }

  addNewItem(filterBox: FilterBox): void {
    filterBox.filterItems.push({
      fieldStructures: this.fieldsStructure,
      field: '',
      condition: '',
      values: [],
    });
  }

  addVariantNewItem(filterBox: FilterBox): void {
    filterBox.filterItems.push({
      fieldStructures: this.variantfieldsStructure,
      field: '',
      condition: '',
      values: [],
    });
  }

  deleteFilterItem(filterBox: FilterBox, filterItem: FilterItem): void {
    filterBox.filterItems.splice(filterBox.filterItems.indexOf(filterItem), 1);
    this.filterChange();
  }

  deleteFilterBox(filterBox: FilterBox): void {
    this.filterBoxes.splice(this.filterBoxes.indexOf(filterBox), 1);
    this.filterChange();
  }

  deleteVariantFilterBox(filterBox: FilterBox): void {
    this.variantFilterBoxes.splice(this.filterBoxes.indexOf(filterBox), 1);
  }

  addNewGroup(): void {
    // if (Utils.isNullOrUndefined(this.condOperatorBetweenBoxes)) {
    //     this.condOperatorBetweenBoxes = 'or';
    // }
    const filterBox = new FilterBox();
    this.filterBoxes.push(filterBox);
    this.condOperatorBetweenBoxes.push('and');
  }

  addNewVariantGroup(): void {
    // if (Utils.isNullOrUndefined(this.condOperatorBetweenBoxes)) {
    //     this.condOperatorBetweenBoxes = 'or';
    // }
    const filterBox = new FilterBox();
    this.variantFilterBoxes.push(filterBox);
    this.condOperatorBetweenVariantBoxes.push('and');
  }

  boxesOperatorClick(index): void {
    if (this.condOperatorBetweenBoxes[index] === Constants.OPERATOR_AND) {
      this.condOperatorBetweenBoxes[index] = Constants.OPERATOR_OR;
    } else {
      this.condOperatorBetweenBoxes[index] = Constants.OPERATOR_AND;
    }
  }

  variantBoxesOperatorClick(index): void {
    if (this.condOperatorBetweenVariantBoxes[index] === Constants.OPERATOR_AND) {
      this.condOperatorBetweenVariantBoxes[index] = Constants.OPERATOR_OR;
    } else {
      this.condOperatorBetweenVariantBoxes[index] = Constants.OPERATOR_AND;
    }
  }

  isStepValid(): boolean {
    return true;
  }

  saveStep(): Observable<any> {
    let valid = true;
    this.filterItems.forEach((filterItem) => {
      if (!filterItem.isValid()) {
        valid = false;
      }
    });

    if (!valid) {
      this.notification.warning(this.translate.instant('FILTER.FILTER_ERROR'));
      return;
    }

    const filters = [];
    const variantFilters = [];
    this.filterBoxes.forEach((filterBox, indexBox) => {
      const boxFilters = [];
      filterBox.filterItems.forEach((filterItem, indexItem) => {
        const fieldKey = filterItem.fieldStructures.find((fieldStructure) => {
          if (!Utils.isNullOrUndefined(fieldStructure.key)) {
            return fieldStructure.key === filterItem.field;
          }

          return false;
        }).key;
        boxFilters.push({
          key: fieldKey,
          condition: filterItem.condition,
          values: filterItem.values.map((item) => item.trim()),
        });
        if (indexItem !== filterBox.filterItems.length - 1) {
          boxFilters.push(filterBox.operator);
        }
      });

      if (
        typeof boxFilters[boxFilters.length - 1] === 'string' ||
        boxFilters[boxFilters.length - 1] instanceof String
      ) {
        boxFilters.pop();
      }

      if (boxFilters.length < 1) {
        return;
      }
      if (boxFilters.length === 1) {
        filters.push(boxFilters[0]);
      } else {
        filters.push(boxFilters);
      }
      if (indexBox !== this.filterBoxes.length - 1) {
        filters.push(this.condOperatorBetweenBoxes[indexBox]);
      }
    });

    this.variantFilterBoxes.forEach((filterBox, indexBox) => {
      const boxFilters = [];
      filterBox.filterItems.forEach((filterItem, indexItem) => {
        const fieldKey = filterItem.fieldStructures.find((fieldStructure) => {
          if (!Utils.isNullOrUndefined(fieldStructure.key)) {
            return fieldStructure.key === filterItem.field;
          }

          return false;
        }).key;
        boxFilters.push({
          key: fieldKey,
          condition: filterItem.condition,
          values: filterItem.values.map((item) => item.trim()),
        });
        if (indexItem !== filterBox.filterItems.length - 1) {
          boxFilters.push(filterBox.operator);
        }
      });

      if (
        typeof boxFilters[boxFilters.length - 1] === 'string' ||
        boxFilters[boxFilters.length - 1] instanceof String
      ) {
        boxFilters.pop();
      }

      if (boxFilters.length < 1) {
        return;
      }
      if (boxFilters.length === 1) {
        variantFilters.push(boxFilters[0]);
      } else {
        variantFilters.push(boxFilters);
      }
      if (indexBox !== this.variantFilterBoxes.length - 1) {
        variantFilters.push(this.condOperatorBetweenVariantBoxes[indexBox]);
      }
    });

    if (typeof filters[filters.length - 1] === 'string' || filters[filters.length - 1] instanceof String) {
      filters.pop();
    }

    if (
      typeof variantFilters[variantFilters.length - 1] === 'string' ||
      variantFilters[variantFilters.length - 1] instanceof String
    ) {
      variantFilters.pop();
    }

    // if (this.hasDefaultFilter && this.customFilter.length > 0) {
    //     if (filters.length > 0) {
    //         filters.push('and');
    //     }
    //     filters.push(this.customFilter);
    // }

    if (this.usedComponent === 'productUpload') {
      // if (filters.length !== 0) {
      //     filters.push(Constants.OPERATOR_AND);
      // }
      // filters.push({
      //     key: 'TASK_ID',
      //     condition: 'equals',
      //     values: [this.taskId]
      // });
      this.catalogService
        .saveRetailerCatalog(this.catalogId, [
          {
            key: 'TASK_ID',
            condition: 'equals',
            values: [this.taskId],
          },
        ])
        .subscribe();

      return this.taskFilterService.saveFilters(this.taskId, JSON.stringify(filters), JSON.stringify(variantFilters));
    } else {
      if (this.usedComponent === 'export-task') {
        return this.taskFilterService.saveExportFilters(this.taskId, filters);
      } else {
        if (this.usedComponent !== 'taskwizard') {
          this.filterForSearch.emit(filters);
          return null;
        } else {
          return this.taskFilterService.saveFilters(
            this.taskId,
            JSON.stringify(filters),
            JSON.stringify(variantFilters)
          );
        }
      }
    }
  }

  applyFilter(): void {
    this.saveStep();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this.loadScreenService.stopLoading();
    if (!Utils.isNullOrUndefined(this.subs)) {
      this.subs.unsubscribe();
    }
  }
}

export interface FilterErrorTypeVO {
  maxFieldsPerTypeError: boolean;
  maxFieldConditionPairError: boolean;
  maxFieldNumberError: boolean;
  maxValuesError: boolean;
}

export interface FilterItemNumberVO {
  field: string;
  condition: string;
  numberOfValues: number;
}

export interface Operator {
  key: string;
  display: string;
}
