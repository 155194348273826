<div class="filter-group" fxLayoutAlign="center center">
  <div fxFlex="1 0 0" [class.border]="depth !== 0">
    <div
      *ngIf="everyItemLocked && group.filterItems.length > 0"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      class="mb-8"
      [style.color]="'var(--app-syncee-grey-600)'"
    >
      <mat-icon class="s-20">lock</mat-icon>
      <div>{{ 'FILTER.LOCKED' | translate }}</div>
    </div>
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="group.filterItems.length > 0 && group.children.length === 0">
        <app-filter-item-list
          [filterStructures]="filterStructures"
          [filterItems]="group.filterItems"
          (addItemClick)="handleAddNewItem()"
          (deleteItemClicked)="deleteItemClicked.emit($event)"
          [condition]="group.conditions.length > 0 ? group.conditions[0] : null"
          (conditionChange)="handleFilterItemConditionChange(group.id, $event)"
          (needAutoComplete)="needAutoComplete.emit($event)"
          [autocompleteSuggestion]="autocompleteSuggestion"
          (needMoreAutocomplete)="needMoreAutocomplete.emit($event)"
          [hasNoMoreAutocomplete]="hasNoMoreAutocomplete"
          (filterItemChange)="handleFilterITemChange($event)"
          [filterItemErrors]="filterItemErrors"
          (filterItemsErrorRevalidate)="filterItemsErrorRevalidate.emit()"
          [locked]="locked"
          [everyItemLocked]="everyItemLocked"
          [useOffset]="useOffset"
        ></app-filter-item-list>
      </ng-container>
      <ng-container *ngSwitchCase="group.filterItems.length === 0 && group.children.length > 0">
        <div
          class="w-100p"
          fxLayout="column"
          fxLayoutGap="16px"
          fxLayoutAlign="center start"
          fxLayoutAlign.lt-md="center center"
        >
          <ng-container *ngFor="let child of group.children; let last = last; let index = index">
            <app-filter-group
              class="w-100p"
              [group]="child"
              (newGroupAdd)="handleAddNewGroup(child.id)"
              (newItemAdd)="handleAddNewItem(child.id)"
              (deleteItemClicked)="deleteItemClicked.emit($event)"
              (deleteGroupClicked)="deleteGroupClicked.emit($event)"
              (filterItemConditionChange)="handleFilterItemConditionChange(child.id, $event.newValue)"
              [depth]="depth + 1"
              [filterStructures]="filterStructures"
              (needAutoComplete)="needAutoComplete.emit($event)"
              [autocompleteSuggestion]="autocompleteSuggestion"
              (needMoreAutocomplete)="needMoreAutocomplete.emit($event)"
              [hasNoMoreAutocomplete]="hasNoMoreAutocomplete"
              (filterItemChange)="filterItemChange.emit($event)"
              [filterItemErrors]="filterItemErrors"
              (filterItemsErrorRevalidate)="filterItemsErrorRevalidate.emit()"
              [locked]="locked"
              [useOffset]="useOffset"
            ></app-filter-group>
            <ng-container *ngIf="!last">
              <app-slide-toggle-with-text
                class="ml-20"
                [checked]="group.conditions[index] === 'OR'"
                (checkedChange)="handleGroupConditionChange(group.id, $event, index)"
                [trueText]="'OR'"
                [falseText]="'AND'"
              ></app-slide-toggle-with-text>
            </ng-container>
          </ng-container>
        </div>
        <div fxLayoutAlign="space-between center" class="mt-28" *ngIf="!locked">
          <button
            mat-button
            class="syncee-light-blue-button"
            (click)="handleAddNewGroup(group.id)"
            [buttonWithIcon]="'add'"
          >
            {{ 'FILTER.ADD_GROUP' | translate }}
          </button>
          <ng-container *ngIf="depth === 0 && !!rightActionBar" [ngTemplateOutlet]="rightActionBar"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!locked">
        <ng-container *ngSwitchCase="group.filterItems.length === 0 && group.children.length === 0">
          <app-filter-empty-group (addClick)="handleAddNewGroup()"></app-filter-empty-group>
          <div fxLayoutAlign="end center" class="mt-28">
            <ng-container *ngIf="depth === 0 && !!rightActionBar" [ngTemplateOutlet]="rightActionBar"></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <button
    mat-icon-button
    class="syncee-light-blue-button ml-24 delete-group"
    (click)="deleteGroupClicked.emit(group.id)"
    *ngIf="depth !== 0 && !locked && !everyItemLocked"
  >
    <mat-icon class="s-20">delete</mat-icon>
  </button>
</div>
