<div
  fxLayout="row"
  fxLayoutAlign="center center"
  class="report-container cursor-pointer"
  (click)="openReportDialog()"
  *ngIf="reportedUserId; else skeleton"
>
  <mat-icon>flag</mat-icon>
  <u>{{ 'REPORT_SUPPLIER.TITLE' | translate }}</u>
</div>

<ng-template #skeleton>
  <div class="skeleton-container">
    <app-skeleton-text [lines]="1"></app-skeleton-text>
  </div>
</ng-template>
