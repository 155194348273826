import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MappingInputVo } from '../../taskwizard/addfiles/vo/mapping-vo/mapping-input-vo';
import { DroppableInputComponent } from '../../droppable-input/droppable-input.component';
import { Mapping, MappingTemplateSettings, ProdIds } from '../../../service/taskwizard/taskwizard-update-response';
import { Observable, Subscription } from 'rxjs';
import { RestService } from '../../../service/rest/rest.service';
import { RecentlyDroppedItemNotifier } from '../../drag-ndrop-zone/recently-dropped-item-notifier';
import { NotificationService } from '../../notification/notification.service';
import { TaskwizardUpdateService } from '../../../service/taskwizard/taskwizard-update.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { StepperService } from '../../../service/taskwizard/stepper.service';
import { RestResponse } from '../../../service/rest/rest-response';
import { StepBase } from '../../taskwizard/step-base';
import {
  GroupInputFields,
  ProductdetailsbuilderComponent,
} from '../../taskwizard/addfiles/mapping/productdetailsbuilder/productdetailsbuilder.component';
import { UserService } from '../../../service/user/user.service';
import { MfieldQuantitySettingsService } from '../../../service/taskwizard/mfield-quantity-settings.service';
import { ProdSampleInputVo } from 'app/main/taskwizard/addfiles/vo/prod-sample-input-vo';
import { PermissionService } from '../../../service/permission/permission.service';

@Component({
  selector: 'app-shopify-product-details-builder',
  templateUrl: './shopify-product-details-builder.component.html',
  styleUrls: ['./shopify-product-details-builder.component.scss'],
})
export class ShopifyProductDetailsBuilderComponent extends ProductdetailsbuilderComponent {
  @ViewChildren('quantities') private inputsQuantitiesProcess: QueryList<DroppableInputComponent>;

  constructor(
    protected restService: RestService,
    protected droppedItemNotifier: RecentlyDroppedItemNotifier,
    protected notification: NotificationService,
    protected taskWizardUpdateService: TaskwizardUpdateService,
    protected dialog: MatDialog,
    protected taskWizardService: TaskwizardService,
    public qtyLocationService: MfieldQuantitySettingsService,
    protected userService: UserService,
    protected stepperService: StepperService,
    protected permissionService: PermissionService
  ) {
    super(
      restService,
      droppedItemNotifier,
      notification,
      taskWizardUpdateService,
      dialog,
      taskWizardService,
      userService,
      stepperService,
      permissionService
    );
  }

  protected getCustomGroupComponents(): GroupInputFields[] {
    return [{ key: 'QTIES', inputs: this.inputsQuantitiesProcess }];
  }
}
