import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Review } from '../../../vo/review/review';
import { LeaveUserReviewConfig } from './config/leave-user-review.config';
import { sortBy } from 'lodash';
import { LeaveUserReviewService } from '../service/leave-user-review.service';
import { NotificationService } from 'app/main/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-leave-user-review-dialog',
  templateUrl: './leave-user-review-dialog.component.html',
  styleUrls: ['./leave-user-review-dialog.component.scss'],
})
export class LeaveUserReviewDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LeaveUserReviewDialogComponent>,
    private leaveUserReviewService: LeaveUserReviewService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: LeaveUserReviewDialogData
  ) {
    this.dialogRef.addPanelClass('custom-modal-full-width-lt-md');
  }
  allStepsDone = false;
  reviewSteps: LeaveUserReviewConfig[];
  messageStep: LeaveUserReviewConfig = {
    type: 'REVIEW_MESSAGE',
    hasRating: false,
    orderIndex: null,
  };
  currentReviewStepIndex = 0;
  reviewMessage = '';
  ngOnInit(): void {
    this.reviewSteps = [...sortBy(this.data.reviewItems, 'orderIndex'), this.messageStep];
    this.mapExistingReviewsToItems();
  }

  private mapExistingReviewsToItems(): void {
    if (this.data.review) {
      this.reviewMessage = this.data.review.review;
      this.data.reviewItems.forEach((item) => {
        const matchingExistingReview = this.data.review.reviews.find((review) => review.reviewType === item.type);
        if (!!matchingExistingReview) {
          item.rating = matchingExistingReview.rate;
        }
      });
    }
  }

  handleNext(): void {
    if (this.currentReviewStepIndex === this.lastStepIndex) {
      this.handleSubmit();
      return;
    }
    this.currentReviewStepIndex += 1;
  }

  handleRatingChange(rating: number): void {
    this.reviewSteps[this.currentReviewStepIndex].rating = rating;
  }

  handleSubmit(): void {
    this.leaveUserReviewService.handleReviewSubmit(this.mapDataToReview(), !!this.data.review).subscribe((value) => {
      if (value) {
        this.allStepsDone = true;
      }
    });
  }

  public handleDeleteReview(): void {
    this.leaveUserReviewService.deleteReview(this.data.supplierUserId).subscribe((): void => {
      this.notificationService.success(
        this.translateService.instant('USER_REVIEW_DIALOG.SUCCESSFULLY_DELETED_MESSAGE')
      );
      this.dialogRef.close();
    });
  }

  get lastStepIndex(): number {
    return this.reviewSteps.length - 1;
  }

  private mapDataToReview(): Partial<Review> {
    return {
      review: this.reviewMessage,
      toUserId: this.data.supplierUserId,
      fromUserId: this.data.currentUserId,
      reviews: this.leaveUserReviewService.getReviewRates(this.reviewSteps),
    };
  }
}

export interface LeaveUserReviewDialogData {
  currentUserId: number;
  supplierUserId: number;
  reviewItems: LeaveUserReviewConfig[];
  review?: Review;
}
