import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeCustomComponent } from '../badge-custom/badge-custom.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-catalog-approve-status-badge',
  templateUrl: './catalog-approve-status-badge.component.html',
  styleUrls: ['./catalog-approve-status-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, BadgeCustomComponent, TranslateModule],
})
export class CatalogApproveStatusBadgeComponent {
  @Input() approveStatus: string;

  constructor() {}
}
