import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { ONBOARD_EARN_CREDIT_AMOUNT } from '../../../layout/components/onboard/constant';
import { OnboardItem } from '../../../layout/components/onboard/onboard.service';
import { ToolbarCallToActionService } from '../../../layout/components/toolbar/components/toolbar-call-to-action/toolbar-call-to-action.service';
import { isAuthenticatedSelector } from '../../../store/authentication/authentication.selector';
import { AddCompletedSnippetToUserAction } from '../../../store/snippet/snippet.actions';
import {
  snippetCompletedStepsSelector,
  snippetIsAllCompletedSelector,
  snippetTreeSelector,
} from '../../../store/snippet/snippet.selector';
import { getUserRolesSelector } from '../../../store/user/user.selector';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { RolesEnum } from '../../../vo/roles/roles';
import { SetupGuideSnippet } from '../model/setup-guide-snippet';
import { SnippetToUser } from '../model/snippet-to-user';
import { uniqBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SetupGuideService {
  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private toolbarCallToActionService: ToolbarCallToActionService
  ) {}

  getSetupGuideSnippetObservable(key: string): Observable<SetupGuideSnippet> {
    return combineLatest([this.getSnippet(key), this.store.select(snippetCompletedStepsSelector)]).pipe(
      filter(([snippet, completedStepIds]) => !!snippet && !!completedStepIds),
      map(([snippet, completedStepIds]) => this.mapToSetupGuideSnippet(snippet, completedStepIds))
    );
  }

  getRoleForSetupGuideSnippet(): Observable<RolesEnum> {
    return this.store.select(getUserRolesSelector).pipe(
      omitNullOrUndefined(),
      map((roles) => (roles.includes(RolesEnum.SUPPLIER) ? RolesEnum.SUPPLIER : RolesEnum.RETAILER)),
      distinctUntilChanged()
    );
  }

  private getSnippet(key: string): Observable<OnboardItem> {
    return this.store.select(snippetTreeSelector).pipe(
      filter((items) => !!items),
      this.getSnippetByKeyOperator(key)
    );
  }

  private mapToSetupGuideSnippet(snippet: OnboardItem, completedStepIds: SnippetToUser[]): SetupGuideSnippet {
    const snippetIds = snippet.steps.map((step) => step.id);

    const completedSteps = uniqBy(
      completedStepIds.filter((snippetToUser) => snippetIds.includes(snippetToUser.snippetsId)),
      (snippetToUser) => snippetToUser.snippetsId
    );

    return { snippet, completedSteps };
  }

  private getSnippetByKeyOperator(key: string): (source: Observable<OnboardItem[]>) => Observable<OnboardItem> {
    return (source: Observable<OnboardItem[]>): Observable<OnboardItem> =>
      source.pipe(map((items) => items.find((item) => item.type === key)));
  }

  setCompletedStep(snippetId: number): void {
    this.store.dispatch(
      new AddCompletedSnippetToUserAction({
        snippetsId: snippetId,
        skipped: false,
      })
    );
  }

  public getAllStepsCompleted(): Observable<boolean> {
    return this.getRoleForSetupGuideSnippet().pipe(
      switchMap((role) => this.store.select(snippetIsAllCompletedSelector, role)),
      omitNullOrUndefined(),
      distinctUntilChanged()
    );
  }

  public observeIfStepIsCompleted(stepId: number): Observable<boolean> {
    return this.store.select(snippetCompletedStepsSelector).pipe(
      omitNullOrUndefined(),
      map((completedSteps) => completedSteps.some((step) => step.snippetsId === stepId))
    );
  }

  observeIfNeedsEarnMoreCallToAction(): Observable<boolean> {
    return combineLatest([
      this.store.select(isAuthenticatedSelector).pipe(omitNullOrUndefined()),
      this.getAllStepsCompleted().pipe(omitNullOrUndefined()),
      this.getRoleForSetupGuideSnippet().pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([isAuthenticated, allCompleted, role]) => {
        return isAuthenticated && !allCompleted && role === RolesEnum.RETAILER;
      })
    );
  }

  observeIfNeedsSetupGuideCta(): Observable<boolean> {
    return combineLatest([
      this.store.select(isAuthenticatedSelector).pipe(omitNullOrUndefined()),
      this.getAllStepsCompleted().pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([isAuthenticated, allCompleted]) => {
        return isAuthenticated && !allCompleted;
      })
    );
  }

  addEarnMoreCallToActionOnSetupGuidePage(): void {
    this.toolbarCallToActionService.removeContentByTypeAndPosition('earnCredit', 'top');
    this.toolbarCallToActionService.removeContentByTypeAndPosition('setupGuide', 'below-header');
    this.translateService
      .get('ONBOARD.EARN_CREDIT_ALERT.CALL_TO_ACTION', { amount: ONBOARD_EARN_CREDIT_AMOUNT })
      .pipe(take(1))
      .subscribe((translation) => {
        this.toolbarCallToActionService.addActionContent({
          type: 'earnCredit',
          title: translation,
          theme: 'info',
          position: 'below-header',
          priority: 10,
          action: null,
        });
      });
  }
}
