<div class="help-container">
  <app-support-team [items]="items"></app-support-team>

  <div class="have-a-question">
    <h4 class="margin-zero">{{ 'HELP_BOX.HAVE_A_QUESTION' | translate }}</h4>
    <div class="typography-body-1">{{ 'HELP_BOX.HERE_TO_HELP' | translate }}</div>
  </div>

  <div class="get-in-touch cursor-pointer">
    <mat-icon>chat_bubble_outline</mat-icon>
    <div (click)="intercom.show()">{{ 'HELP_BOX.GET_IN_TOUCH' | translate }}</div>
  </div>
</div>
