import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExtendedModule, FlexModule } from '@angular/flex-layout';

import { SkeletonTextComponent } from './skeleton-text/skeleton-text.component';
import { SkeletonImageComponent } from './skeleton-image/skeleton-image.component';
import { SkeletonProductCardComponent } from './skeleton-product-card/skeleton-product-card.component';
import { SkeletonProductsListSliderBoxComponent } from './skeleton-products-list-slider-box/skeleton-products-list-slider-box.component';
import { SkeletonDisplayTextComponent } from './skeleton-display-text/skeleton-display-text.component';
import { SkeletonSupplierCard } from './skeleton-supplier-card/skeleton-supplier-card.component';
import { SkeletonSupplierSliderBoxComponent } from './skeleton-supplier-slider-box/skeleton-supplier-slider-box.component';
import { SkeletonProductCardListComponent } from './skeleton-product-list/skeleton-product-card-list.component';
import { SkeletonSupplierCardListComponent } from './skeleton-supplier-card-list/skeleton-supplier-card-list.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SkeletonTextComponent,
    SkeletonImageComponent,
    SkeletonProductCardComponent,
    SkeletonProductsListSliderBoxComponent,
    SkeletonDisplayTextComponent,
    SkeletonSupplierCard,
    SkeletonSupplierSliderBoxComponent,
    SkeletonProductCardListComponent,
    SkeletonSupplierCardListComponent,
  ],
  imports: [CommonModule, FlexModule, MatIconModule, SlickCarouselModule, ExtendedModule],
  exports: [
    SkeletonTextComponent,
    SkeletonImageComponent,
    SkeletonProductCardComponent,
    SkeletonProductsListSliderBoxComponent,
    SkeletonDisplayTextComponent,
    SkeletonSupplierCard,
    SkeletonSupplierSliderBoxComponent,
    SkeletonProductCardListComponent,
    SkeletonSupplierCardListComponent,
  ],
})
export class SkeletonModule {}
