// TODO: Refactor this component in the future when there is sufficient time
import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../../service/billing/billing.service';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../service/user/user.service';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BillingInfoVO } from '../../../vo/billing-vo';
// declare var createNummuspayToken: any;
declare var initBraintree: any;
import * as braintree from 'braintree-web';
import { Utils } from '../../../utils/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../main/notification/notification.service';
import { LowerCasePipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SubscribeData } from '../../../main/billing-new/subscription/model/card-dialog-data';
import { BrainTreeCardInfo } from '../../../main/billing-new/subscription/model/brain-tree-card-info';

@Component({
  selector: 'app-payment-card-dialog',
  templateUrl: './payment-card-dialog.component.html',
  styleUrls: ['./payment-card-dialog.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    MatDialogModule,
    ReactiveFormsModule,
    FlexModule,
    MatInputModule,
    TranslateModule,
    NgIf,
    LowerCasePipe,
    MatButtonModule,
    NgSwitchCase,
  ],
})
export class PaymentCardDialogComponent implements OnInit {
  title: string;
  message: string;
  subscription: any;
  btnOkText: string;
  btnCancelText: string;
  type: string;

  env = environment;

  ///// BRAINTREE /////
  hostedFieldsInstance: braintree.HostedFields;
  cardholdersName: string;
  cardHolderError = false;
  valid = false;
  formClass = '';
  cardImageClass = '';

  ///// NUMMUSPAY /////
  loading = false;
  billingInfo: BillingInfoVO;
  cardForm;

  constructor(
    private billingService: BillingService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<PaymentCardDialogComponent>,
    private notificationService: NotificationService,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    switch (this.type) {
      case 'braintree':
        this.createBraintreeUI();
        break;
      case 'nummuspay':
        this.initNummuspay();
        break;
    }
  }

  ///// NUMMUSPAY /////

  initNummuspay(): void {
    this.billingService.getBillingInformation().subscribe((rest) => {
      this.billingInfo = rest;
    });
    this.cardForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      number: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      cvv: ['', Validators.required],
    });
  }

  send(): void {
    this.loading = true;
    const cardInfo = this.cardForm.getRawValue();

    if (this.cardForm.valid) {
      const currentUser = this.subscription.userDetails;

      const data = {
        subdomain: 'syncee',
        email: currentUser.email,
        firstName: cardInfo.firstName,
        lastName: cardInfo.lastName,
        billingAddress: this.billingInfo.address1 + ' ' + this.billingInfo.address2 + ', ' + this.billingInfo.city,
        zip: this.billingInfo.zip.toString(),
        number: cardInfo.number.toString(),
        month: cardInfo.month.toString(),
        year: cardInfo.year.toString(),
        cvv: cardInfo.cvv.toString(),
        company: this.billingInfo.companyName.toString(),
        vatNumber: this.billingInfo.euVat.toString(),
        vatCountryID: this.billingInfo.country,
      };
    } else {
      this.loading = false;
    }
  }

  ///// BRAINTREE /////

  createBraintreeUI(): void {
    this.billingService.getBrainTreeClientToken().subscribe((rest) => {
      console.log(this.subscription);
      console.log(rest);
      if (!Utils.isNullOrUndefined(rest.clientToken) && !Utils.isNullOrUndefined(rest.billingData)) {
        const threeDSecureParameters = {
          amount: this.subscription.subscribeData.amount > 0 ? this.subscription.subscribeData.amount : 1,
          email: this.subscription.userDetails.email,
          billingAddress: {
            givenName: rest.billingData.firstName.replace(/[^\x00-\x7F]/g, ''),
            surname: rest.billingData.lastName.replace(/[^\x00-\x7F]/g, ''),
            // phoneNumber: '8101234567',
            streetAddress: rest.billingData.address1,
            extendedAddress: rest.billingData.address2,
            locality: rest.billingData.city,
            // region: 'CA',
            postalCode: rest.billingData.zip,
            countryCodeAlpha2: rest.billingData.country,
          },
        };

        initBraintree(rest.clientToken, threeDSecureParameters)
          .then((payload) => {
            const subscriptionData = {
              cardInfo: payload,
              cycle: this.subscription.cycle,
              subscribeData: this.subscription.subscribeData,
              ecomId: this.subscription.ecomId,
            };
            this.dialogRef.close(subscriptionData);
          })
          .catch((value) => {
            this.userService
              .postError({
                code: value.code,
                message: value,
                requestParams: {
                  clientToken: rest.clientToken,
                  threeDSecureParameters: threeDSecureParameters,
                },
                userMessage: this._translateService.instant('ERROR.ERROR_BRAINTREE'),
              })
              .subscribe();
            console.log(value);
          });
      } else {
        this.notificationService.error(this._translateService.instant('ERROR.ERROR_BRAINTREE'));
      }
    });
  }

  // Tokenize the collected details so that they can be sent to your server
  tokenizeUserDetails(): void {
    if (!Utils.isNullOrUndefinedOrLengthZero(this.cardholdersName) && this.valid) {
      this.hostedFieldsInstance
        .tokenize({ cardholderName: this.cardholdersName })
        .then((payload) => {
          const subscriptionData = {
            cardInfo: payload,
            cycle: this.subscription.cycle,
            subscribeData: this.subscription.subscribeData,
            ecomId: this.subscription.ecomId,
          };
          this.dialogRef.close(subscriptionData);
        })
        .catch((error) => {
          console.log(error);
          this.notificationService.error(error);
          // perform custom validation here or log errors
        });
    } else {
      if (!this.valid) {
        this.notificationService.error(
          this._translateService.instant('BILLING.SUBSCRIPTION.BRAINTREE_CARD_INFORMATION.FILL_ALL_ERROR')
        );
      } else {
        this.notificationService.error(
          this._translateService.instant('BILLING.SUBSCRIPTION.BRAINTREE_CARD_INFORMATION.NAME_ERROR')
        );
      }

      this.cardHolderError = true;
    }
  }

  nameChanged(event): void {
    this.cardHolderError = event.length < 1;
  }

  asd(): boolean {
    return !this.valid;
  }

  ///// OTHER FUNCTIONS /////

  tryParseJSON(jsonString): boolean {
    try {
      JSON.parse(jsonString);
    } catch (e) {
      return false;
    }
    return true;
  }
}

export interface PaymentCardDialogResponse {
  cardInfo: BrainTreeCardInfo;
  cycle: string;
  subscribeData: SubscribeData;
  ecomId: number;
}
