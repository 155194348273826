import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AlertMultiComponent } from '../../alert-multi/alert-multi.component';
import { CustomDialogWrapperComponent } from '../../dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-supplier-currency-selector-confirm-dialog',
  templateUrl: './supplier-currency-selector-confirm-dialog.component.html',
  styleUrls: ['./supplier-currency-selector-confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CustomDialogWrapperComponent,
    AlertMultiComponent,
    TranslateModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
})
export class SupplierCurrencySelectorConfirmDialogComponent implements OnInit {
  checkedRisk = false;

  constructor(
    private _matDialogRef: MatDialogRef<SupplierCurrencySelectorConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SupplierCurrencySelectorConfirmDialogData
  ) {}

  ngOnInit(): void {}

  confirm(): void {
    this._matDialogRef.close(true);
  }
}

export interface SupplierCurrencySelectorConfirmDialogData {
  currency: string;
}
