import { BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { Utils } from 'app/utils/utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadcrumbItem, FuseConfig } from '../../../../@fuse/types';
import { AppState } from '../../../app.state';
import { BootstrapService } from '../../../service/bootstrap/bootstrap.service';
import { CategoryService } from '../../../service/product-search/category.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import {
  ShippingDetailsDialogComponent,
  ShippingDetailsDialogData,
} from '../../../shared/components/dialogs/shipping-details-dialog/shipping-details-dialog.component';
import { SupplierOrderInfoData } from '../../../shared/components/supplier-order-info/supplier-order-info.component';
import { Subset } from '../../../utils/subset';
import { CategoryVo } from '../../../vo/category-vo';
import { SearchProductVO } from '../../../vo/search-product-vo';
import { ShippingType } from '../../../vo/supplier/shipping-type';
import { ProductAccordionData } from '../product-page-details-accordion/product-page-details-accordion.component';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsPageService implements OnDestroy, OnInit {
  public categoryTree: CategoryVo;

  private readonly _unsubscribeAll: Subject<void>;
  constructor(
    private dialog: MatDialog,
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private bootstrapService: BootstrapService,

    private screenManager: ScreenManagerService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {}

  public setBreadcrumbs(categoryTree: CategoryVo, categoryId: number, productName: string): Subset<FuseConfig> {
    return {
      layout: {
        breadcrumb: {
          path: this.getBreadcrumbPath(categoryTree, categoryId, productName),
        },
      },
    };
  }

  private getBreadcrumbPath(categoryTree: CategoryVo, categoryId: number, productName: string): BreadcrumbItem[] {
    if (Utils.isNullOrUndefined(categoryId)) {
      return [
        { translationKey: 'NAV.HOME', url: '/', shouldTranslate: true },
        {
          translationKey: 'NAV.SEARCH_PRODUCTS',
          url: MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH,
          queryParams: { category: 1 },
          shouldTranslate: false,
        },
        { translationKey: productName, url: null, shouldTranslate: false },
      ];
    } else {
      return [
        { translationKey: 'NAV.HOME', url: '/', shouldTranslate: true },
        {
          translationKey: 'NAV.SEARCH_PRODUCTS',
          url: MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH,
          queryParams: { category: 1 },
          shouldTranslate: true,
        },
        {
          translationKey: this.getCategoryName(categoryTree, categoryId),
          url: `/${MARKETPLACE_NAVIGATION.COLLECTIONS}/${categoryId}/${this.getCategoryHandle(
            categoryTree,
            categoryId
          )}`,
          queryParams: { category: categoryId },
          shouldTranslate: false,
        },
        { translationKey: productName, url: null, shouldTranslate: false },
      ];
    }
  }

  private getCategoryHandle(categoryTree: CategoryVo, categoryId: number): string {
    return this.categoryService.getHandleForCategory(this.categoryService.searchInCategories(categoryTree, categoryId));
  }

  private getCategoryName(categoryTree: CategoryVo, categoryId: number): string {
    const matchingCategory = this.categoryService.searchInCategories(categoryTree, categoryId);
    return this.categoryService.getNameForCategory(matchingCategory);
  }

  public subscribeToCategories(): Observable<CategoryVo> {
    return this.store.pipe(
      select((state) => state.categories),
      select((state) => state.categories),
      takeUntil(this._unsubscribeAll)
    );
  }

  private getFinalCategory(category: string): string {
    if (category.includes('>')) {
      const categoryStringArray = category.split('>');
      return categoryStringArray[categoryStringArray.length - 1].trim();
    } else {
      return category.trim();
    }
  }

  public getDetailsForAccordion(
    product: SearchProductVO,
    ecomCurrency: string,
    optionNameFields: [string, any][]
  ): ProductAccordionData {
    return {
      ecomCurrency: ecomCurrency,
      approval: product.SETTINGS.approveNeeded,
      brand: product.BRAND,
      category: this.getFinalCategory(product.CATEGORY ?? ''),
      language: product.SETTINGS.language,
      tags: product.TAGS?.join(),
      optionNameFields: optionNameFields,
      variants: product.VARIANTS,
    };
  }

  public getOrderInfoDataForAccordion(product: SearchProductVO, hasAccessToContacts: boolean): SupplierOrderInfoData {
    return {
      userId: product.USER_ID,
      contactEmail: product.SUPPLIER?.contactEmail,
      approveDescription: product.SETTINGS.approveDescription,
      supplierWebsite: product.SUPPLIER?.website,
      approveNeeded: product.SETTINGS.approveNeeded,
      isAutomated: product.SUPPLIER?.isAutomated,
      hasAccessToContacts: hasAccessToContacts,
      supplierCurrency: product.SUPPLIER.paymentCurrency,
    };
  }

  public getOptionNameFields(product: SearchProductVO): [string, any][] {
    return Object.entries(product).filter(([key, _]) => key.includes('SHOPIFY_OPTION_NAME') && !!product[key]);
  }

  public openShippingDialog(product: SearchProductVO): void {
    this.dialog.open<ShippingDetailsDialogComponent, ShippingDetailsDialogData>(ShippingDetailsDialogComponent, {
      data: {
        shippingType: product.SETTINGS.shipping_type as ShippingType,
        isWorldWide: product.SUPPLIER.worldwideShipping,
        userId: Number(product.USER_ID),
        mainWarehouseLocation: product.SETTINGS.warehouseLocation,
        supplierCurrency: product.SUPPLIER.paymentCurrency,
        taskId: product.TASK_ID,
      },
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'custom-modal-full-width-lt-md',
    });
  }

  subscribeToBreakpointObserver(): Observable<BreakpointState> {
    return this.screenManager.observeBreakpoint(BreakPoint.md).pipe(takeUntil(this._unsubscribeAll));
  }
}
