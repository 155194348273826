<div class="py-4">
  <mat-chip-list [selectable]="false">
    <mat-chip
      *ngFor="let item of items"
      (click)="itemClicked(item)"
      [selectable]="false"
      [selected]="selectedItemsIds.includes(item.id)"
      [ngClass]="selectedItemsIds.includes(item.id) ? 'light-blue-chip' : 'white-chip'"
    >
      <div class="content">
        <mat-icon *ngIf="item.icon?.type === 'material-icons'">{{ item.icon.icon }}</mat-icon>
        <img
          *ngIf="item.icon?.type === 'url'"
          [src]="item.icon.icon | sanitizeResourceUrl"
          [style.width]="iconSize + 'px'"
          [style.height]="iconSize + 'px'"
        />
        <div>{{ item.title | translate }}</div>
      </div>
    </mat-chip>
  </mat-chip-list>
</div>
