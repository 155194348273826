<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <button mat-button class="syncee-white-button" (click)="syncClicked.emit()">Sync</button>
    <app-tasks-menu [menus]="menus" [task]="task" (menuItemClicked)="menuItemClicked.emit($event)"></app-tasks-menu>
  </div>
  <div>
    <a class="h4" (click)="clickRow.emit(task)">{{ task.name }}</a>
    <div class="typography-body-2">{{ (ecom | async)?.domain }}</div>
  </div>
  <div>
    <div class="typography-body-3">{{ task.sourceType }}</div>
    <div>
      {{ 'TASKS.MOBILE_VIEW.PRODUCTS' | translate }}: <span>{{ task.resultCount ?? 0 }}</span>
    </div>
  </div>
  <div>
    <div *ngIf="!!task.date">{{ 'TASKS.MOBILE_VIEW.LAST_UPDATE' | translate }}: {{ task.date | customDate }}</div>
    <div *ngIf="!!task.nextTimeRun">
      {{ 'TASKS.MOBILE_VIEW.NEXT_UPDATE' | translate }}: {{ task.nextTimeRun | customDate }}
    </div>
  </div>
  <app-task-badges [task]="task"></app-task-badges>
  <app-history-indicator [data]="task.progressbar" [variant]="'expendable'"></app-history-indicator>
</div>
