import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StorageLoaderService } from './storage-loader-service';
import { FuseSplashScreenService } from '../../@fuse/services/splash-screen.service';
import { MissingHandler } from './missing-handler';
// import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: StorageLoaderService,
        deps: [HttpClient, FuseSplashScreenService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingHandler,
      },
      // compiler: {
      //   provide: TranslateCompiler,
      //   useClass: TranslateMessageFormatCompiler,
      // },
    }),
  ],
  exports: [TranslateModule],
})
export class StorageTranslateModule {}
