<div [ngClass]="wrapperClass" *ngIf="!isLoading; else skeleton">
  <app-products-list-slider-box
    [productlist]="productList"
    [title]="title"
    [description]="'EXPLORE_PRODUCTS.BASED_ON_PREFERENCES' | translate"
    [ecomCurrency]="ecomCurrency | async"
    [isProductUsed]="isProductUsed"
    [filter]="mapToMarketplaceFilter(widgetFilter)"
    [generateMicrodata]="true"
  ></app-products-list-slider-box>
</div>

<ng-template #skeleton>
  <div [ngClass]="wrapperClass">
    <app-skeleton-products-list-slider-box></app-skeleton-products-list-slider-box>
  </div>
</ng-template>
