import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[marketplaceBadge]',
  standalone: true,
})
export class MarketplaceBadgeDirective implements OnChanges {
  @Input() name: string;
  colors: BadgeColor;
  @HostBinding('style.background-color') get backgroundColor(): string {
    return this.colors.background;
  }
  @HostBinding('style.color') get getColor(): string {
    return this.colors.foreground;
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.colors = this.getBadgeColors();
  }

  private getBadgeColors(): BadgeColor {
    switch (this.name) {
      case 'APPROVAL_NEEDED': {
        return { background: 'var(--app-syncee-primary-50)', foreground: 'var(--app-syncee-primary-700)' };
      }
      case 'NON_AUTO_ORDER': {
        return { background: 'var(--app-grey-200)', foreground: 'var(--app-grey-600)' };
      }
      case 'PREMIUM': {
        return { background: 'var(--app-amber-100)', foreground: 'var(--app-amber-600)' };
      }
      case 'BESTSELLER': {
        return { background: 'var(--app-neon-yellow-200)', foreground: 'var(--app-neon-yellow-800)' };
      }
      default: {
        return { background: 'var(--app-syncee-primary-50)', foreground: 'var(--app-syncee-primary-700)' };
      }
    }
  }
}

export interface BadgeColor {
  background: string;
  foreground: string;
}
