import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import hu from 'dayjs/locale/hu';
import it from 'dayjs/locale/it';
import pt from 'dayjs/locale/pt';
import zh from 'dayjs/locale/zh-cn';
import RelativeTime from 'dayjs/plugin/relativeTime';

const DAYJS_LANGUAGES = {
    de: de,
    en: en,
    es: es,
    fr: fr,
    pt: pt,
    zh: zh,
    hu: hu,
    it: it,
};

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {};
    
    transform(value: any): string {
        dayjs.extend(RelativeTime);
        const dateToUse = new Date(value).getTime() < new Date().getTime() ? dayjs(value) : dayjs();
        return dateToUse.locale(DAYJS_LANGUAGES[this.translateService.currentLang as keyof typeof DAYJS_LANGUAGES]).fromNow();
    }
}
