import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-guide-review-content-image',
  templateUrl: './setup-guide-review-content-image.component.html',
  styleUrls: ['./setup-guide-review-content-image.component.scss'],
})
export class SetupGuideReviewContentImageComponent implements OnInit {
  public backgroundImageUrl = 'assets/images/backgrounds/setup-guide-review-background.jpg';
  constructor() {}

  ngOnInit(): void {}

  protected readonly Array = Array;
}
