import { Component, OnInit } from '@angular/core';
import { BillingInformationService } from '../../service/billing-information.service';

@Component({
  selector: 'app-billing-information-wide-screen',
  templateUrl: './billing-information-wide-screen.component.html',
  styleUrls: ['./billing-information-wide-screen.component.scss'],
})
export class BillingInformationWideScreenComponent implements OnInit {
  constructor(public billingInformationService: BillingInformationService) {}

  ngOnInit(): void {}
}
