<!--<div style="height: 30px; width: 200px;">{{path}}</div>-->
<div fxLayoutAlign="start center" fxLayoutGap="16px">
  <mat-icon class="s-20 syncee-grey-500-fg">drag_indicator</mat-icon>
  <div class="m-w-0">
    <div class="h5 pb-4 truncate">
      {{ path }}
    </div>
    <div class="typography-body-1 truncate">{{ value }}</div>
  </div>
</div>
