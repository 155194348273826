import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-display-text',
  templateUrl: './skeleton-display-text.component.html',
  styleUrls: ['./skeleton-display-text.component.scss'],
})
export class SkeletonDisplayTextComponent {
  @Input() public size: 'small' | 'medium' | 'large' | 'extraLarge' = 'medium';

  @Input() public maxWidth = '120px';
}
