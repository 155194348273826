import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Observable } from 'rxjs';
import { getSelectedLanguageCode } from '../../store/language/language.selector';
import { languages } from 'app/store/language/language.reducer';
import { LanguageOption } from 'app/store/language/language.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public static readonly fallbackLanguage: string = 'en';

  private _cacheAppInitLanguageFromStorage: string = LanguageService.fallbackLanguage;

  constructor(private store: Store<AppState>, private _translateService: TranslateService) {
    this.observeLanguageChange().subscribe((language) => {
      this._translateService.use(language);
    });
  }

  public getLocalId(): string {
    let language = navigator.language;

    if (language !== 'en-US' && language !== 'hu') {
      language = 'en-US';
    }

    return language;
  }

  observeLanguageChange(): Observable<string> {
    return this.store.select(getSelectedLanguageCode);
  }

  public static getLanguageFromStorage(): string {
    const langStorage: string | null = localStorage.getItem('selectedLanguage');
    if (langStorage === null) {
      return this.fallbackLanguage;
    }

    return languages.find((language) => language.code === langStorage).code;
  }

  getCachedAppInitLanguage(): string {
    return this._cacheAppInitLanguageFromStorage;
  }

  cachePrevAppLanguageFromStorage(): void {
    this._cacheAppInitLanguageFromStorage = LanguageService.getLanguageFromStorage();
  }

  public static setLanguageToStorage(language: string): void {
    localStorage.setItem('selectedLanguage', language);
  }
}
