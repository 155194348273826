import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  RetailerDashboardAnalyticsService,
  TopSuppliersDto,
} from '../../../service/analytics/retailer-dashboard-analytics.service';
import { SpringPageable } from '../../../vo/pagination/spring-pageable';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Subject } from 'rxjs';
import { getSelectedEcomByRole } from '../../../store/ecom/ecom.selector';
import { take, takeUntil, tap } from 'rxjs/operators';
import { CurrencyService } from '../../../service/currency-service/currency.service';
import { RolesEnum } from 'app/vo/roles/roles';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';

@Component({
  selector: 'app-top-suppliers',
  templateUrl: './top-suppliers.component.html',
  styleUrls: ['./top-suppliers.component.scss'],
})
export class TopSuppliersComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  dataSource: MatTableDataSource<TopSuppliersDto>;
  columns = ['supplier-image', 'supplier', 'orders', 'products-sold', 'profit', 'revenue', 'average-profit-margin'];
  pageable: SpringPageable = { page: 0, size: 5, sort: 'profit,desc' };
  protected ecomCurrency: string;

  private unsubscribeAll = new Subject<void>();

  protected selectedEcomCurrency: string;

  constructor(
    private retailerDashboardAnalyticsService: RetailerDashboardAnalyticsService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.getCurrencyFromEcom();
    this.setDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.setDataSource();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private getCurrencyFromEcom(): void {
    this.store
      .select(getSelectedEcomByRole(RolesEnum.RETAILER))
      .pipe(
        takeUntil(this.unsubscribeAll),
        omitNullOrUndefined(),
        tap((ecom: EcomVO) => (this.selectedEcomCurrency = ecom?.options?.currency))
      )
      .subscribe((selectedEcom: EcomVO): void => {
        this.ecomCurrency = CurrencyService.getCurrency(selectedEcom);
      });
  }

  setDataSource(): void {
    this.retailerDashboardAnalyticsService
      .getTopSuppliers(this.dateFrom, this.dateTo, this.pageable)
      .pipe(take(1))
      .subscribe((topSuppliers) => {
        this.dataSource = new MatTableDataSource<TopSuppliersDto>(topSuppliers);
      });
  }
}
