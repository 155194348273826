import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { AuthenticationService, ParsedAuthData } from '../../authentication/authentication.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { translationUrl } from '../../../../environments/environment';
import { AppState } from 'app/app.state';
import { Store } from '@ngrx/store';
import { LogoutAction } from 'app/store/authentication/authentication.actions';
import { AuthenticationStorage } from 'app/service/authentication/authentication-storage';
import { isAuthenticatedSelector } from '../../../store/authentication/authentication.selector';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _cacheAuthData: ParsedAuthData;

  // readonly beforeExpireTime = 60 * 60 - 5;
  readonly beforeExpireTime = 200;

  constructor(private authService: AuthenticationService, private store: Store<AppState>) {
    this.cacheAuthData();
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (req.url.match(new RegExp(`${translationUrl}*`))) {
      return req;
    }
    return !!token ? req.clone({ setHeaders: { Authorization: 'Bearer ' + token } }) : req;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.cacheAuthData();

    request = this.addToken(request, this._cacheAuthData?.accessToken);

    if (!this.isTokenRequest(request.url) && this.isAccessTokenExpired()) {
      return this.handleRefreshToken(request, next);
    }

    return next.handle(request).pipe(
      catchError((error: Error) => {
        if (!(error instanceof HttpErrorResponse)) {
          return throwError(error);
        }
        if (this.isTokenRequest(request.url)) {
          return throwError(error);
        }
        switch (error.status) {
          case 401:
            if (!this.isAccessTokenExpired()) {
              this.authService.logoutWithLoginDialog();
              return throwError(error);
            }
            return this.handle401Error(request, next);
          default:
            return throwError(error);
        }
      })
    );
  }

  private isAccessTokenExpired(): boolean {
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    return this.hasAuthData() && currentTimestamp + this.beforeExpireTime > this._cacheAuthData?.expiresIn;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleRefreshToken(request, next);
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._isRefreshing) {
      return this.handleWaitingRefreshTokenRequests(request, next);
    }

    this.setRefreshingProgress();

    if (!this._cacheAuthData?.refreshToken) {
      this.authService.logoutWithLoginDialog();
      return throwError('Refresh token not found');
    }

    this.cacheAuthData(false);
    return this.getRefreshTokenObs(this._cacheAuthData?.refreshToken).pipe(
      take(1),
      switchMap(() => {
        this.cacheAuthData();
        this.releaseRefreshingProgress();
        return this.handleWaitingRefreshTokenRequests(request, next);
      }),
      catchError((error) => {
        this.releaseRefreshingProgress();
        switch (error.errorCode) {
          case '401':
            this.store.dispatch(new LogoutAction());
            return throwError(error);
          default:
            return throwError(error);
        }
      })
    );
  }

  private setRefreshingProgress(): void {
    this._isRefreshing = true;
    this._refreshTokenSubject.next(null);
  }

  private releaseRefreshingProgress(): void {
    this._isRefreshing = false;
    this._refreshTokenSubject.next(this._cacheAuthData?.accessToken);
  }

  private handleWaitingRefreshTokenRequests(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((accessToken) => {
        return next.handle(this.addToken(request, accessToken));
      })
    );
  }

  private cacheAuthData(fromMemory = true): void {
    if (!fromMemory) {
      AuthenticationStorage.clearCache();
    }
    this._cacheAuthData = this.getAuthData();
  }

  private hasAuthData(): boolean {
    return !!this._cacheAuthData;
  }

  private getAuthData(): ParsedAuthData {
    return this.authService.isLoggedUserByAdmin()
      ? AuthenticationStorage.getLoggedUserAuthData()
      : AuthenticationStorage.getAuthData();
  }

  private getRefreshTokenObs(refreshToken: string): Observable<boolean> {
    return this.authService.isLoggedUserByAdmin()
      ? this.authService.refreshLoggedUserToken(refreshToken)
      : this.authService.refreshToken(refreshToken);
  }

  private isTokenRequest(url: string): boolean {
    return !!url.match(new RegExp(`/token*`));
  }
}
