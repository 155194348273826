<div class="slider-container">
  <button *ngIf="prevAvailable" (click)="slidePrev()" class="navigation-buttons left-arrow">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <ng-container *ngIf="productSearchStore$ | async as store">
    <app-slider [hasButtons]="true">
      <ng-container>
        <button
          mat-icon-button
          class="filter-button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          (click)="isSortOpen = !isSortOpen"
        >
          <mat-icon>import_export</mat-icon>
        </button>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOffsetY]="2"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isSortOpen"
          [cdkConnectedOverlayHasBackdrop]="true"
          cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
          (backdropClick)="isSortOpen = false"
        >
          <mat-card>
            <app-product-search-sort-selector [isSupplierSearch]="isSupplierSearch"></app-product-search-sort-selector>
          </mat-card>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!hideFilterDialog">
        <button (click)="openFilterDialog()" mat-icon-button class="filter-button" *ngIf="!hideFilterDialog">
          <mat-icon>tune</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('shipsTo')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.SHIPS_TO' | translate"
          #shipsToWrapper
          [filterValue]="store?.filter?.shipsTo"
        >
          <app-ships-to-filter
            [hasClearAll]="true"
            [value]="shipsToWrapper.filterValue"
            [overlayOpen]="shipsToWrapper.isOverlayOpen"
            (valueChange)="handleChange({ shipsTo: $event })"
            (clearAll)="handleClearFilter(['shipsTo'])"
            [multiple]="true"
            (shipsToChange)="setGtmFilterValue('shipsTo', $event)"
          ></app-ships-to-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('supplier')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.SUPPLIER' | translate"
          #supplierWrapper
          [filterValue]="store?.filter?.supplier"
        >
          <app-supplier-filter
            [hasClearAll]="true"
            [value]="supplierWrapper.filterValue"
            [idFilterList]="supplierIdFilterList"
            [overlayOpen]="supplierWrapper.isOverlayOpen"
            (valueChange)="handleChange({ supplier: $event })"
            (supplierChange)="setGtmFilterValue('supplier', $event)"
            (clearAll)="handleClearFilter(['supplier'])"
            [multiple]="true"
          ></app-supplier-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('minShippingTime') && !hiddenFilters.includes('maxShippingTime')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.SHIPPING_TIME' | translate"
          #shippingTimeWrapper
          [filterValue]="{
            minShippingTime: store?.filter?.minShippingTime,
            maxShippingTime: store?.filter?.maxShippingTime
          }"
        >
          <app-shipping-time-filter
            [hasClearAll]="true"
            [value]="shippingTimeWrapper.filterValue"
            (valueChange)="
              handleChange({ minShippingTime: $event.minShippingTime, maxShippingTime: $event.maxShippingTime })
            "
            (clearAll)="handleClearFilter(['minShippingTime', 'maxShippingTime'])"
          ></app-shipping-time-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('minPrice') && !hiddenFilters.includes('maxPrice')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.PRICE' | translate"
          #priceWrapper
          [filterValue]="{
            minPrice: store?.filter?.minPrice,
            maxPrice: store?.filter?.maxPrice
          }"
        >
          <app-price-filter
            *ngIf="priceWrapper.isOverlayOpen"
            [hasClearAll]="true"
            [value]="priceWrapper.filterValue"
            (valueChange)="
              handleChange({ minPrice: $event.minPrice, maxPrice: $event.maxPrice });
              setGtmFilterValue('minPrice', $event.minPrice);
              setGtmFilterValue('maxPrice', $event.maxPrice)
            "
            (clearAll)="handleClearFilter(['minPrice', 'maxPrice'])"
          ></app-price-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('lang')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.PRODUCT_LANGUAGE' | translate"
          #languageWrapper
          [filterValue]="store?.filter?.lang"
        >
          <app-product-language-filter
            [hasClearAll]="true"
            [value]="languageWrapper.filterValue"
            [overlayOpen]="languageWrapper.isOverlayOpen"
            (valueChange)="handleChange({ lang: $any($event) })"
            (languageChange)="setGtmFilterValue('lang', $event)"
            (clearAll)="handleClearFilter(['lang'])"
          ></app-product-language-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('premium')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.PREMIUM' | translate"
          #premiumWrapper
          [filterValue]="store?.filter?.premium"
        >
          <app-premium-supplier-filter
            [hasClearAll]="true"
            [value]="premiumWrapper.filterValue"
            (valueChange)="handleChange({ premium: $event }); setGtmFilterValue('premium', $event)"
            (clearAll)="handleClearFilter(['premium'])"
          ></app-premium-supplier-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('shipsFrom')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.SHIPS_FROM' | translate"
          #shipsFromWrapper
          [filterValue]="store?.filter?.shipsFrom"
        >
          <app-ships-from-filter
            [hasClearAll]="true"
            [overlayOpen]="shipsFromWrapper.isOverlayOpen"
            [value]="shipsFromWrapper.filterValue"
            (valueChange)="handleChange({ shipsFrom: $event })"
            (clearAll)="handleClearFilter(['shipsFrom'])"
            [multiple]="true"
            (shipsFromChange)="setGtmFilterValue('shipsFrom', $event)"
          ></app-ships-from-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('shippingType')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.SHIPPING_TYPE' | translate"
          #shippingTypeWrapper
          [filterValue]="store?.filter?.shippingType"
        >
          <app-shipping-type-filter
            [hasClearAll]="true"
            [value]="shippingTypeWrapper.filterValue"
            (valueChange)="handleChange({ shippingType: $event }); setGtmFilterValue('shippingType', $event)"
            (clearAll)="handleClearFilter(['shippingType'])"
          ></app-shipping-type-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <!-- <ng-container *ngIf="!hiddenFilters.includes('autoOrder')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.AUTO_ORDER' | translate"
          #autoOrderWrapper
          [filterValue]="store?.filter?.autoOrder"
        >
          <app-auto-order-filter
            [hasClearAll]="true"
            [value]="autoOrderWrapper.filterValue"
            (valueChange)="handleChange({ autoOrder: $event }); setGtmFilterValue('autoOrder', $event)"
            (clearAll)="handleClearFilter(['autoOrder'])"
          ></app-auto-order-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container> -->
      <ng-container *ngIf="!hiddenFilters.includes('approveType')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.APPROVAL' | translate"
          #approvalWrapper
          [filterValue]="store?.filter?.approveType"
        >
          <app-approval-filter
            [hasClearAll]="true"
            [value]="approvalWrapper.filterValue"
            (valueChange)="handleChange({ approveType: $event }); setGtmFilterValue('approveType', $event)"
            (clearAll)="handleClearFilter(['approveType'])"
          ></app-approval-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('stock')">
        <app-filter-wrapper-chip-with-overlay
          [title]="'FILTER_COMMON.TITLES.STOCK_STATUS' | translate"
          #stockStatusWrapper
          [filterValue]="store?.filter?.stock"
        >
          <app-stock-status-filter
            [hasClearAll]="true"
            [value]="stockStatusWrapper.filterValue"
            (valueChange)="handleChange({ stock: $event }); setGtmFilterValue('stock', $event)"
            (clearAll)="handleClearFilter(['stock'])"
          ></app-stock-status-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
      <ng-container *ngIf="!hiddenFilters.includes('sku')">
        <app-filter-wrapper-chip-with-overlay [title]="'SKU'" #skuWrapper [filterValue]="store?.filter?.sku">
          <app-sku-filter
            [value]="skuWrapper.filterValue"
            (valueChange)="handleChange({ sku: $event }); setGtmFilterValue('sku', $event)"
          ></app-sku-filter>
        </app-filter-wrapper-chip-with-overlay>
      </ng-container>
    </app-slider>
  </ng-container>
  <button *ngIf="nextAvailable" (click)="slideNext()" class="navigation-buttons right-arrow">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>
