import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from 'app/layout/components/footer/footer.component';
import { OnlyGuestDirective } from 'app/shared/directives/only-guest.directive';
import { LanguageAndCurrencySelectorComponent } from '../../../shared/components/language-and-currency-selector/language-and-currency-selector.component';
import { PartnersComponent } from '../../../shared/components/partners/partners.component';
import { ReviewImagesComponent } from '../../../shared/components/review-images/review-images.component';
import { SynceeLogoComponent } from '../../../shared/components/syncee-logo/syncee-logo.component';
import { GetAssetFromStoragePipe } from '../../../shared/pipes/get-asset-from-storage';
import { FooterGroupItemsComponent } from './footer-group-items/footer-group-items.component';
import { FooterItemComponent } from './footer-item/footer-item.component';
import { MdGroupsComponent } from './md-groups/md-groups.component';

@NgModule({
  declarations: [FooterComponent, FooterGroupItemsComponent, MdGroupsComponent, FooterItemComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    FuseSharedModule,
    MatChipsModule,
    SynceeLogoComponent,
    MatListModule,
    TranslateModule,
    LanguageAndCurrencySelectorComponent,
    MatExpansionModule,
    OnlyGuestDirective,
    PartnersComponent,
    GetAssetFromStoragePipe,
    ReviewImagesComponent,
  ],
  exports: [FooterComponent, FooterItemComponent],
})
export class FooterModule {}
