import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

import { RolesEnum } from '../../vo/roles/roles';
import { Utils } from '../../utils/utils';

const getUserState = createFeatureSelector<UserState>('users');

export const getUserRoleSelector = createSelector(getUserState, (state: UserState) => state.actualRole);
export const getUserRolesSelector = createSelector(getUserState, (state: UserState) => state.roles);

export const hasRolesSelector = createSelector(getUserState, (state: UserState, role: RolesEnum) =>
  state?.roles?.includes(role)
);

export const hasRoleSelector = (role: RolesEnum) =>
  createSelector(getUserState, (state: UserState): boolean => {
    if (Utils.isNullOrUndefinedOrLengthZero(state?.roles)) {
      return undefined;
    }

    return state.roles.includes(role);
  });

export const isEmailVerified = createSelector(getUserState, (state) => state?.currentUser?.verified);

export const getCurrentUserSelector = createSelector(getUserState, (state: UserState) => state.currentUser);
export const getCurrentUserIdSelector = createSelector(
  getUserState,
  (state: UserState) => !!state && state.currentUser && state.currentUser.id
);

export const locationByIpSelector = createSelector(getUserState, (state) => state?.locationByIp);

export const getCurrentUserEmailSelector = createSelector(getUserState, (state: UserState) => state.currentUser?.email);

export const getCurrentUserSettingsSelector = createSelector(
  getUserState,
  (state: UserState) => state.currentUser?.settings
);

export const getCurrentUserSettingsWeightUnitSelector = createSelector(
  getUserState,
  (state: UserState) => state.currentUser?.settings?.weightUnit
);
