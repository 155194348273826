import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-chip',
  templateUrl: './information-chip.component.html',
  styleUrls: ['./information-chip.component.scss'],
  standalone: true,
})
export class InformationChipComponent implements OnInit {
  @Input() text: string;
  @Input() class: string;

  constructor() {}

  ngOnInit(): void {}
}
