<div class="setup-guide-item-list">
  <div class="accordion-container">
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let step of snippet.snippet.steps; let i = index"
        (opened)="openedAccordionId = step.id"
        [expanded]="openedAccordionId === step.id"
        [disabled]="hideUncompletedSteps && uncompletedStepIds.includes(step.id) && uncompletedStepIds[0] !== step.id"
      >
        <mat-expansion-panel-header>
          <div fxLayoutAlign="center center" fxLayoutGap="24px">
            <div class="step-index" *ngIf="uncompletedStepIds.includes(step.id); else readyStep">
              {{ i + 1 }}
            </div>
            <div>{{ step.title.en }}</div>
          </div>
        </mat-expansion-panel-header>
        <ng-container [ngSwitch]="specialContents[step.id]?.type">
          <ng-container *ngSwitchCase="'review'">
            <div class="w-100p">
              <app-setup-guide-review-content
                [isCompleted]="step.id | isStepCompleted : snippet.completedSteps"
              ></app-setup-guide-review-content>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <app-setup-guide-item-list-content
              [button]="step.buttonText"
              [buttonLink]="step.continueUrl"
              [description]="step.description"
              [videoUrl]="step.videoUrl"
              (handleClose)="step.continueUrl && handleCloseDialog ? handleCloseDialog.emit() : null"
            ></app-setup-guide-item-list-content>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #readyStep>
  <div class="ready-step" fxLayoutAlign="center center"><mat-icon class="s-20">check</mat-icon></div>
</ng-template>
