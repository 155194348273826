import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { ChartConfiguration, Plugin } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import {
  RetailerDashboardAnalyticsService,
  TimelineDataSetDto,
  TimelineRangeDto,
} from '../../../service/analytics/retailer-dashboard-analytics.service';
import { ColorPaletteService } from '../../../service/color-palette/color-palette.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { htmlLegendPlugin } from './bar-legend-plugin';
import { TranslateService } from '@ngx-translate/core';
import { getTranslationFromStream } from '../../../utils/operator/get-translation-from-stream';

@Component({
  selector: 'app-orders-chart',
  templateUrl: './orders-chart.component.html',
  styleUrls: ['./orders-chart.component.scss'],
})
export class OrdersChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  private unsubscribeAll: Subject<void> = new Subject<void>();

  public readonly navigateToMarketplace = MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH;

  barChartPlugins: Plugin[] = [htmlLegendPlugin];
  statisticType: StatisticType = 'PRODUCTS_SOLD';
  dataSets: TimelineDataSetDto[];
  anyOrdersReceived = false;

  defaultBarChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: this.colorPaletteService.getColorVariable('--app-grey-100'),
        barThickness: 'flex',
        borderRadius: 8,
      },
      {
        data: [],
        backgroundColor: this.colorPaletteService.getColorVariable('--app-grey-100'),
        barThickness: 'flex',
        borderRadius: 8,
      },
    ],
  };

  barChartData: ChartConfiguration<'bar'>['data'];

  barChartOptions: ChartConfiguration<'bar'>['options'];

  constructor(
    private retailerDashboardAnalyticsService: RetailerDashboardAnalyticsService,
    private colorPaletteService: ColorPaletteService,
    private screenManagerService: ScreenManagerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.observableToTranslate()
      .pipe(take(1))
      .subscribe(() => {
        this.setDefaultBarchartDataValues();
        this.getDailyAnalytics();
        this.createLgSubscription();
        this.createSmSubscription();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.getDailyAnalytics();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getDailyAnalytics(): void {
    this.retailerDashboardAnalyticsService
      .getDailyOrderAnalytics(this.dateFrom, this.dateTo)
      .pipe(take(1))
      .subscribe((timeLineDataSets) => {
        this.dataSets = timeLineDataSets;
        this.setAnyOrdersReceived(timeLineDataSets);
        this.setBarChartData();
        this.setLabels();
        this.chart.chart.update();
        this.chart.chart.resize();
      });
  }

  handleStatisticTypeChange(event: MatRadioChange): void {
    this.statisticType = event.value;
    this.setBarChartData();
    this.setYAxisTitle();
    this.chart.render();
    this.chart.chart.resize();
  }

  private observableToTranslate(): Observable<void> {
    return this.translateService.stream('DASHBOARD').pipe(
      getTranslationFromStream(),
      tap((t) => {
        this.initBarChartOptions(t as object);
        this.initBarChartData(t as object);
      }),
      map(() => {})
    );
  }

  private setAnyOrdersReceived(timeLineDataSets: TimelineDataSetDto[]): void {
    this.anyOrdersReceived = timeLineDataSets.some((dataSet) => dataSet.rangeDtos.some((range) => range.orders > 0));
  }

  private setDefaultBarchartDataValues(): void {
    const base = 5;
    const exponent = 2;
    const numOfValues = 10;
    const middle = numOfValues / 2;
    const multiplier = 2;

    for (let i = 0; i < numOfValues; i++) {
      const closenessToMiddle = (middle - Math.abs(middle - i)) / 2;
      const value = base + Math.floor(Math.pow(exponent, i) / 10) + closenessToMiddle * multiplier;

      this.defaultBarChartData.datasets[0].data.push(value);
    }
  }

  private setLabels(): void {
    const dateRangeLabels = this.getDateRangeLabels();
    this.barChartData.labels = dateRangeLabels;
    this.defaultBarChartData.labels = dateRangeLabels;
  }

  private createLgSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.lg)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((event) => {
        this.barChartOptions.scales.x.ticks.display = !event.matches;

        this.barChartOptions = { ...this.barChartOptions };

        if (this.chart) {
          this.chart.chart.update();
          this.chart.chart.resize();
        }
      });
  }

  private createSmSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.sm)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((event) => {
        this.barChartOptions.scales.x.title.display = !event.matches;
        this.barChartOptions.scales.y.title.display = !event.matches;
        this.barChartOptions.scales.y.ticks.display = !event.matches;

        this.barChartOptions = { ...this.barChartOptions };

        if (this.chart) {
          this.chart.chart.update();
          this.chart.chart.resize();
        }
      });
  }

  private setBarChartData(): void {
    this.barChartData.datasets[0].data = this.getMarketplaceValues();
    this.barChartData.datasets[1].data = this.getAlibabaValues();
    // this.barChartData = {...this.barChartData};
  }

  private getDateRangeLabels(): string[] {
    return this.dataSets
      ? this.dataSets[0].rangeDtos.map((range) => new Date(Date.parse(range.from)).toLocaleDateString())
      : [];
  }

  private getAlibabaValues(): number[] {
    return this.dataSets
      .find((datasetDto) => datasetDto.type === 'ALIBABA')
      .rangeDtos.map((rangeDto) => this.getValueByStatisticType(rangeDto));
  }

  private getMarketplaceValues(): number[] {
    return this.dataSets
      .find((datasetDto) => datasetDto.type === 'MARKETPLACE')
      .rangeDtos.map((rangeDto) => this.getValueByStatisticType(rangeDto));
  }

  private getValueByStatisticType(rangeDto: TimelineRangeDto): number {
    switch (this.statisticType) {
      case 'ORDERS':
        return rangeDto.orders;
      case 'PRODUCTS_SOLD':
        return rangeDto.productsSold;
      case 'PROFIT':
        return rangeDto.profit;
      case 'REVENUE':
        return rangeDto.revenue;
    }
  }

  private setYAxisTitle(): void {
    this.barChartOptions.scales.y.title.text = this.translateService.instant(
      `DASHBOARD.CHART_STATISTIC_TYPES.${this.statisticType}`
    );
  }

  private initBarChartOptions(translation: object): void {
    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: false,
        },
        // @ts-ignore
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: 'legend-container',
        },
      },
      scales: {
        x: {
          title: {
            text: translation['TIMELINE'],
            display: true,
            color: this.colorPaletteService.getColorVariable('--app-black-500'),
            font: {
              size: 14,
              family: 'Inter',
              weight: 'bold',
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            display: true,
            autoSkip: true,
            maxTicksLimit: 5,
          },
        },
        y: {
          // labels: ,
          title: {
            text: translation['CHART_STATISTIC_TYPES']['PRODUCTS_SOLD'],
            display: true,
            color: this.colorPaletteService.getColorVariable('--app-black-500'),
            font: {
              size: 14,
              family: 'Inter',
              weight: 'bold',
            },
          },
          ticks: {
            display: true,
          },
        },
      },
    };
  }

  private initBarChartData(translation: object): void {
    this.barChartData = {
      labels: [],
      datasets: [
        {
          label: translation['MARKETPLACE_ORDERS'],
          data: [],
          backgroundColor: this.colorPaletteService.getColorVariable('--app-syncee-primary-50'),
          barThickness: 'flex',
          borderRadius: 8,
        },
        {
          label: translation['ALIBABA_ORDERS'],
          data: [],
          backgroundColor: this.colorPaletteService.getColorVariable('--app-orange-100'),
          barThickness: 'flex',
          borderRadius: 8,
        },
      ],
    };
  }
}

export type StatisticType = 'PRODUCTS_SOLD' | 'PROFIT' | 'REVENUE' | 'ORDERS' | 'RETAILERS';
