import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorPaletteService {
  constructor() {}

  getColorVariable(variable: string): string {
    return getComputedStyle(document.body).getPropertyValue(variable);
  }

  public getPalette(palette: string, desc = false): Map<string, string> {
    const styles = getComputedStyle(document.body);

    const map = new Map<string, string>();
    colorValues.forEach((colorValue) => {
      const key = `--app-${palette}-${colorValue}`;
      return map.set(key, styles.getPropertyValue(key));
    });

    return desc ? new Map([...map.entries()].sort((a, b) => this.getColorStrength(a) - this.getColorStrength(b))) : map;
  }

  private getColorStrength(a: [string, string]): number {
    return parseInt(a[0].substring(a[0].lastIndexOf('-')), 10);
  }
}

export const colorValues = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];
