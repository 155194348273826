import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentAccount, PaymentService, StripeConnect } from '../../../service/payment/payment.service';
import { BillingMapperService } from '../../../service/mappers/billing-mapper.service';
import { BillingInformationComponent } from '../../../main/billing-information/billing-information.component';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { BillingInformationModule } from '../../../main/billing-information/billing-information.module';
import { BillingInformationVariant } from '../../../main/billing-information/model/billing-information-variant';
import { NotificationService } from '../../../main/notification/notification.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { defaultIfEmpty, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { STRIPE_VALID_COUNTRY_CODES } from '../../../utils/Constants';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogsService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { AppState } from 'app/app.state';
import { Store } from '@ngrx/store';
import { getScopesSelector } from 'app/store/authentication/authentication.selector';
import { SCOPES } from 'app/service/permission/scopes';

@Component({
  selector: 'app-supplier-payment-account-billing',
  standalone: true,
  imports: [CommonModule, ConditionLoadingDirective, BillingInformationModule],
  templateUrl: './supplier-payment-account-billing.component.html',
  styleUrls: ['./supplier-payment-account-billing.component.scss'],
})
export class SupplierPaymentAccountBillingComponent implements OnInit, AfterViewInit {
  @ViewChild('billingInfoForm') billingInfoForm: BillingInformationComponent;
  @Input() variant: BillingInformationVariant = 'DEFAULT';
  @Input() paymentAccount: PaymentAccount;
  isBillingLoading = true;
  private paymentAccountId: number;
  private paymentAddressId: number;
  private stripeValidCountries = STRIPE_VALID_COUNTRY_CODES;

  constructor(
    private store: Store<AppState>,
    private paymentService: PaymentService,
    private billingMapperService: BillingMapperService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private confirmationDialogsService: ConfirmationDialogsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.paymentAccount) {
      this.subscribeToBillingAddress();
    } else {
      this.handlePaymentAccount(this.paymentAccount);
    }
  }

  private countryValidation(): Observable<boolean> {
    const countryFormControl = this.billingInfoForm.form.get('country');
    return this.paymentService
      .getStripeConnect()
      .pipe(
        switchMap((stripeConnect) =>
          !!stripeConnect
            ? this.handleStripeConnect(countryFormControl, stripeConnect)
            : this.save().pipe(map(() => true))
        )
      );
  }

  private handleStripeConnect(countryFormControl: AbstractControl, stripeConnect: StripeConnect): Observable<boolean> {
    return !this.stripeValidCountries.includes(countryFormControl.value)
      ? this.openConfirmationDialog().pipe(
          filter((confirmed) => confirmed),
          switchMap(() => this.handleStripeAccountSave(stripeConnect)),
          map(() => true),
          defaultIfEmpty(false)
        )
      : this.save().pipe(map(() => true));
  }

  private handleStripeAccountSave(stripeConnect: StripeConnect): Observable<void> {
    return this.paymentService
      .getDeletedStripeConnects()
      .pipe(
        switchMap((stripeConnects) =>
          stripeConnects.map((connect) => connect.id).includes(stripeConnect.id)
            ? this.save()
            : this.paymentService.disconnectStripeAccount(stripeConnect.id).pipe(switchMap(() => this.save()))
        )
      );
  }

  private openConfirmationDialog(): Observable<boolean> {
    return this.confirmationDialogsService.confirm(
      this.translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE'),
      this.translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE_MESSAGE'),
      this.translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE_YES'),
      this.translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.STRIPE_NOT_AVAILABLE_NO')
    );
  }

  private subscribeToBillingAddress(): void {
    this.isBillingLoading = true;
    this.paymentService.getPaymentAccount().subscribe((data) => this.handlePaymentAccount(data));
  }

  private handlePaymentAccount(paymentAccount: PaymentAccount): void {
    if (!!paymentAccount) {
      this.paymentAccountId = paymentAccount.id;
      this.paymentAddressId = paymentAccount.paymentAddress?.id;
      this.billingInfoForm.form.patchValue(this.billingMapperService.mapPaymentAccountToBillingInfoVO(paymentAccount));
    }
    this.isBillingLoading = false;
  }

  private save(): Observable<void> {
    return this.store.select(getScopesSelector(false)).pipe(
      take(1),
      switchMap((scopes) =>
        this.paymentService.savePaymentAccount(
          this.billingMapperService.mapBillingInfoVOToPaymentAccount(
            this.billingInfoForm.form.getRawValue(),
            this.paymentAccountId,
            this.paymentAddressId,
            !!scopes.find((scope) => scope === SCOPES.PAYMENT_SANDBOX)
          )
        )
      ),
      tap(() => this.notificationService.success('Successfully saved!')),
      switchMap(() => of(void 0))
    );
  }

  saveClicked(): Observable<boolean> {
    if (this.billingInfoForm.form.valid) {
      return this.countryValidation();
    } else {
      this.billingInfoForm.form.markAllAsTouched();
      return of(false);
    }
  }

  get form(): FormGroup {
    return this.billingInfoForm.form;
  }
}
