<button
  mat-flat-button
  class="filter-dropdown-btn"
  [ngClass]="{ 'active-filter': !!dateFrom || !!dateTo }"
  datePickerDropdown
  [dateFrom]="dateFrom"
  [dateTo]="dateTo"
  (dateFromChange)="dateFromChange.emit($event)"
  (dateToChange)="dateToChange.emit($event)"
>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
    <span>
      {{ translateLabel() | translate : { from: dateFrom?.toLocaleDateString(), to: dateTo?.toLocaleDateString() } }}
    </span>
    <mat-icon class="s-20"> keyboard_arrow_down </mat-icon>
  </div>
</button>
