import { Pipe, PipeTransform } from '@angular/core';
import { SnippetToUser } from '../model/snippet-to-user';

@Pipe({
  name: 'isStepCompleted',
})
export class IsStepCompletedPipe implements PipeTransform {
  transform(stepId: number, completedSteps: SnippetToUser[]): boolean {
    const completedStepIds = completedSteps
      .filter((step) => step.snippetsId && !step.skipped)
      .map((step) => step.snippetsId);
    return completedStepIds.includes(stepId);
  }
}
