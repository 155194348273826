import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { PreferenceStoreService } from '../../../service/preference/preference.store.service';
import { SearchWidgetService } from '../../../service/search-widget/search-widget.service';
import { SupplierGatewayService } from '../../../service/suppliers/supplier-gateway.service';
import { newCountries } from '../../../utils/Countries';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { Widget, WidgetType } from '../../../vo/widget';
import { BrandsSliderComponent } from '../brands-slider/brands-slider.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

@UntilDestroy()
@Component({
  selector: 'app-brands-slider-by-location',
  standalone: true,
  imports: [CommonModule, BrandsSliderComponent, SkeletonModule],
  templateUrl: './brands-slider-by-location.component.html',
  styleUrls: ['./brands-slider-by-location.component.scss'],
})
export class BrandsSliderByLocationComponent implements OnInit {
  @Input() title: string;
  @Input() wrapperClass: string;
  @Input() innerClass: string;
  isLoading = true;
  widgets: Widget[];
  private readonly FEATURED_TYPES: WidgetTypeToCountries[] = [
    {
      widgetType: WidgetType.PREMIUM_BRANDS_FEATURED_LIST_1,
      countryCodes: ['HU'],
    },
    {
      widgetType: WidgetType.PREMIUM_BRANDS_FEATURED_LIST_2,
      countryCodes: ['AU'],
    },
    {
      widgetType: WidgetType.PREMIUM_BRANDS_FEATURED_LIST_3,
      countryCodes: newCountries.Europe.map((country) => country.code),
    },
  ];

  constructor(
    private supplierGatewayService: SupplierGatewayService,
    private navigationService: NavigationService,
    private router: Router,
    private searchWidgetService: SearchWidgetService,
    private preferenceStoreService: PreferenceStoreService
  ) {}

  ngOnInit(): void {
    this.getWidgets();
  }

  handleBrandClicked(brand: Widget): void {
    this.getSupplierName(Number(brand.filterData.find((x) => x.fieldName === 'supplier').fieldValue))
      .pipe(
        take(1),
        tap((seoURL) => this.router.navigateByUrl(seoURL))
      )
      .subscribe();
  }

  private getSupplierName(supplierID: number): Observable<string> {
    return this.supplierGatewayService.getSuppliers([supplierID]).pipe(
      untilDestroyed(this),
      map((supplier) => this.navigationService.getStorefrontPath(supplier[0].userId, supplier[0].handle))
    );
  }

  private getWidgets(): void {
    this.isLoading = true;
    this.getWidgetType()
      .pipe(
        switchMap((type) => this.searchWidgetService.getWidgets(type, true)),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((widgets) => (this.widgets = widgets));
  }

  private getWidgetType(): Observable<WidgetType> {
    return this.preferenceStoreService.getPreferredLocation().pipe(
      omitNullOrUndefined(),
      take(1),
      map(
        (location) =>
          this.FEATURED_TYPES.find((type) => type.countryCodes.includes(location.code))?.widgetType ??
          WidgetType.PREMIUM_BRANDS
      )
    );
  }

  protected readonly Array = Array;
}

interface WidgetTypeToCountries {
  widgetType: WidgetType;
  countryCodes: string[];
}
