import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { getCurrentUserIdSelector, getCurrentUserSelector, getUserRolesSelector } from 'app/store/user/user.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { RolesEnum } from 'app/vo/roles/roles';
import { uniq } from 'lodash';
import { BehaviorSubject, iif, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { User } from '../user/user';
import { SearchData } from './model/search-data';
import { SearchSession } from './model/search-session';
import { SearchSessionCacheService } from './search-session-cache.service';
import { SearchSessionRestService } from './search-session-rest.service';

@Injectable({
  providedIn: 'root',
})
export class SearchSessionService {
  private _session = new BehaviorSubject<SearchSession>(null);
  private _searchData = new BehaviorSubject<SearchData[]>(null);
  private _productClicks = new BehaviorSubject<string[]>(null);
  private _isAdmin = new BehaviorSubject<boolean>(false);
  session$ = this._session.asObservable();
  searchData$ = this._searchData.asObservable();
  productClicks$ = this._productClicks.asObservable().pipe(omitNullOrUndefined());
  createNewSession$ = this.getSessionObs().pipe(
    tap((session) => this.saveSession(session)),
    map(() => {}),
    share()
  );
  fetchSearchData$: Subject<void> = new Subject<void>();

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private searchSessionRestService: SearchSessionRestService,
    private searchSessionCacheService: SearchSessionCacheService,
    private store: Store<AppState>
  ) {
    this.getSearchData();
    this.initByUser();
  }

  initSession(): void {
    const cachedSession = this.searchSessionCacheService.getSession();

    this.getHasAdminRole()
      .pipe(
        filter((isAdmin) => !isAdmin),
        switchMap(() =>
          this.getCachedUser().pipe(
            map((user) => (!!user && !!cachedSession ? user.id === cachedSession.userId : !!cachedSession))
          )
        ),
        switchMap((useCache) =>
          (useCache ? of(cachedSession) : this.getSessionObs()).pipe(
            tap((session) => this.saveSession(session)),
            map(() => {})
          )
        )
      )
      .subscribe();
  }

  createNewGuestSession(): void {
    this.searchSessionRestService
      .createGuestSession()
      .pipe(
        take(1),
        tap((session) => this.saveSession(session))
      )
      .subscribe();
  }

  patchGuestToUserSession(): void {
    this.getHasAdminRole()
      .pipe(
        filter((isAdmin) => !isAdmin),
        switchMap(() => this.getCachedUser())
      )
      .subscribe((user) => {
        if (!!this.searchSessionCacheService.getSession()) {
          this.patchSession(user.id);
        } else {
          this.createNewUserSession(user.id);
        }
      });
  }

  isEventValid(): boolean {
    return !!this._session?.value?.id && !this._isAdmin?.value;
  }

  addProductClickManually(id: string): void {
    this._productClicks.next(uniq([...(this._productClicks?.value ?? []), id]));
  }

  private patchSession(userId: number): void {
    this.searchSessionRestService
      .patchUserSession(this._session?.value?.id, userId)
      .pipe(
        tap((session) => this.saveSession(session)),
        map(() => {})
      )
      .subscribe();
  }

  private createNewUserSession(userId: number): void {
    this.searchSessionRestService
      .createUserSession(userId)
      .pipe(
        tap((session) => this.saveSession(session)),
        map(() => {})
      )
      .subscribe();
  }

  private saveSession(session: SearchSession): void {
    this._session.next(session);
    this.searchSessionCacheService.saveSession(session);
  }

  private getCachedUser(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(take(1));
  }

  private getHasAdminRole(): Observable<boolean> {
    return this.store.select(isAuthenticatedSelector).pipe(
      take(1),
      switchMap((isAuth) =>
        iif(
          () => isAuth,
          this.store.select(getUserRolesSelector).pipe(
            omitNullOrUndefined(),
            filter((roles) => !!roles && roles.length > 0),
            map((roles) => roles.includes(RolesEnum.ADMIN)),
            take(1)
          ),
          of(false)
        )
      ),
      tap((isAdmin) => this._isAdmin.next(isAdmin))
    );
  }

  private getSessionObs(): Observable<SearchSession> {
    return this.getCachedUser().pipe(
      take(1),
      tap((data) => console.log(data)),
      switchMap((user) =>
        iif(
          () => !!user,
          this.searchSessionRestService.createUserSession(user?.id),
          this.searchSessionRestService.createGuestSession()
        )
      )
    );
  }

  private subscribeToFetchSubject(): void {
    this.fetchSearchData$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => this.getSearchData());
  }

  private getSearchData(): void {
    this.store
      .select(getCurrentUserIdSelector)
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap(() => this._searchData.next([])),
        filter((userId) => !!userId),
        switchMap((userId) =>
          this.searchSessionRestService.getSearchData(userId).pipe(
            map((page) => page.content),
            catchError(() => of(null))
          )
        )
      )
      .subscribe((searchData) => this._searchData.next(searchData));
  }

  private getProductClicks(): void {
    this.store
      .select(getCurrentUserIdSelector)
      .pipe(
        takeUntil(this._unsubscribeAll),
        omitNullOrUndefined(),
        take(1),
        switchMap((userId) =>
          this.searchSessionRestService.getProductClicks(userId, false, { page: 0, size: 20, sort: 'id,desc' }).pipe(
            map((page) => page.content),
            catchError(() => of([]))
          )
        ),
        map((sessions) =>
          sessions.flatMap((session) => session.productClicks.map((productClick) => productClick.productId))
        ),
        map((ids) => uniq(ids))
      )
      .subscribe((ids) => this._productClicks.next(ids));
  }

  private initByUser(): void {
    this.store
      .select(getCurrentUserIdSelector)
      .pipe(omitNullOrUndefined())
      .subscribe(() => {
        this.getProductClicks();
      });
  }

  get session(): SearchSession {
    return this._session.value;
  }

  get searchData(): SearchData[] {
    return this._searchData.value;
  }
}
