import { Injectable } from '@angular/core';
import { GeneralButton } from 'app/vo/general-button/general-button';

@Injectable({
  providedIn: 'root',
})
export class GeneralButtonService {
  constructor() {}

  onClick(config: GeneralButton): void {
    switch (config.type) {
      case 'callback': {
        config.callback();
        break;
      }
    }
  }
}
