<div class="ecom-selector-container">
  <div class="selected-ecom ecom-item" fxLayoutAlign="start center" (click)="handleSelectedEcomClicked()">
    <img [src]="selectedEcomLogo" class="ecom-icon" [alt]="selectedEcom.ecomType" />
    <div class="domain">{{ selectedEcom.domain }}</div>
    <mat-icon *ngIf="ecomList.length > 1" class="arrow" [class.transformed-arrow]="collapseState === 'expanded'"
      >keyboard_arrow_right</mat-icon
    >
  </div>
  <div @expandCollapse *ngIf="collapseState === 'expanded'" class="ecom-list">
    <div *ngFor="let ecom of filteredEcomList">
      <ng-container [ngTemplateOutlet]="ecomItem" [ngTemplateOutletContext]="{ ecom: ecom }"></ng-container>
    </div>
  </div>
</div>
<ng-template #ecomItem let-ecom="ecom">
  <div (click)="selectedEcomIdChange.emit(ecom.id)" fxLayoutAlign="start center" class="ecom-item">
    <img [src]="ecom.logo" class="ecom-icon" [alt]="ecom.ecomType" />
    <div class="domain">{{ ecom.domain }}</div>
  </div>
</ng-template>
