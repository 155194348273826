import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { newCountries } from '../../../utils/Countries';

@Pipe({ name: 'countryCodeToName', standalone: true })
export class CountryCodeToNamePipe implements PipeTransform {
  constructor() {}
  transform(code: string): any {
    let country;
    Object.keys(newCountries).every((continent) => {
      country = newCountries[continent].find((item) => item.code === code);
      return Utils.isNullOrUndefined(country);
    });
    return country.name;
  }
}
