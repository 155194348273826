import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestResponse } from '../rest/rest-response';
import { RestService } from '../rest/rest.service';
import { NotificationService } from '../../main/notification/notification.service';
import { FieldStructure } from '../../main/filter/filter-item/filter-item.component';
import { Utils } from '../../utils/utils';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class TaskFilterService {
  public static SAVE_FILTERS_URL = 'TaskFiltersService/addTaskFilters';
  public static SAVE_EXPORT_FILTERS_URL = 'ExportTaskService/saveFilter';
  public static GET_FILTERS_STRUCTURE = 'TaskFiltersService/getFiltersStructureFW';
  public static GET_FILTERS_STRUCTURE_FOR_RETAILER = 'TaskFiltersService/getFiltersStructureForRetailer';
  public static GET_VARIANTS_FILTERS_STRUCTURE = 'TaskFiltersService/getVariantFiltersStructureFW';

  constructor(private restService: RestService, private notificationService: NotificationService) {}

  getFieldStructures(taskId: number): Observable<FieldStructure[]> {
    const params = new HttpParams().set('taskId', taskId.toString());
    return this.restService.get(TaskFilterService.GET_FILTERS_STRUCTURE, params).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          return Utils.mapTaskFiltersFieldStructure(res.getData());
        } else {
          this.notificationService.error('Error occurred during get filter fields structure');
          return null;
        }
      })
    );
  }

  getFiltersStructureForRetailer(taskId: number, catalogId: number, ecomId: number): Observable<FieldStructure[]> {
    const params = new HttpParams().set('taskId', taskId.toString()).set('ecomId', ecomId.toString());
    if (!Utils.isNullOrUndefined(catalogId)) {
      params.set('catalogId', catalogId.toString());
    }

    return this.restService.get(TaskFilterService.GET_FILTERS_STRUCTURE_FOR_RETAILER, params).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          return Utils.mapTaskFiltersFieldStructure(res.getData());
        } else {
          this.notificationService.error('Error occurred during get filter fields structure');
          return null;
        }
      })
    );
  }

  saveFilters(taskId: number, filter: string, variantFilter: string): Observable<RestResponse> {
    const filterParams = {
      taskId: taskId,
      filter: filter,
      variantFilter: variantFilter,
    };

    return this.restService.post(TaskFilterService.SAVE_FILTERS_URL, filterParams);
  }

  saveExportFilters(taskId: number, filter: any[]): Observable<RestResponse> {
    const filterParams = {
      taskId: taskId,
      filter: filter,
    };

    return this.restService.post(TaskFilterService.SAVE_EXPORT_FILTERS_URL, filterParams);
  }

  getVariantFieldStructures(taskId: number): Observable<FieldStructure[]> {
    const params = new HttpParams().set('taskId', taskId.toString());
    return this.restService.get(TaskFilterService.GET_VARIANTS_FILTERS_STRUCTURE, params).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          return Utils.mapTaskFiltersFieldStructure(res.getData());
        } else {
          this.notificationService.error('Error occurred during get filter fields structure');
          return null;
        }
      })
    );
  }
}
