import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameTruncate',
  standalone: true,
})
export class FileNameTruncatePipe implements PipeTransform {
  transform(value: string, nameLength = 20): string {
    const splitArray = value.split('.');
    const extension = splitArray.pop();
    const name = splitArray.join('.');
    return name.length >= nameLength ? name.slice(0, nameLength) + '(...).' + extension : value;
  }
}
