import { AfterViewInit, Component, ElementRef, HostBinding, Input, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-slider-item',
  standalone: true,
  imports: [CommonModule, ObserversModule],
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss'],
})
export class SliderItemComponent implements OnInit, AfterViewInit {
  @Input() staticItem = false;
  @HostBinding('style.min-width') minWidth = '0px';
  minWidthChanged: BehaviorSubject<number>;

  constructor(public el: ElementRef<HTMLElement>, private zone: NgZone) {
    this.minWidthChanged = new BehaviorSubject<number>(0);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.setMinWidth(this.el.nativeElement.offsetWidth);
    if (this.staticItem) {
      this.onContentChange();
    }
  }

  onContentChange(): void {
    this.minWidth = `0px`;
    this.zone.run(() => setTimeout(() => this.setMinWidth(this.el.nativeElement.offsetWidth), 0));
  }

  private setMinWidth(value: number): void {
    this.minWidth = `${value}px`;
    this.minWidthChanged.next(value);
  }
}
