<form class="form-wrapper" [formGroup]="form" #ngForm="ngForm">
  <mat-form-field class="input-without-padding-except-error" [appearance]="'fill'">
    <mat-label>{{ 'AUTHENTICATION.EMAIL' | translate }}</mat-label>
    <input id="email" name="email" matInput type="text" formControlName="email" placeholder="Email" required />
    <mat-error *ngIf="email.errors?.['required']">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    <mat-error *ngIf="email.errors?.['email']">{{ 'FORM_VALIDATORS.EMAIL' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field class="input-without-padding-except-error" [appearance]="'fill'">
    <mat-label>{{ 'AUTHENTICATION.PASSWORD' | translate }}</mat-label>
    <input
      id="password"
      name="password"
      type="password"
      matInput
      placeholder="{{ 'AUTHENTICATION.PASSWORD' | translate }}"
      formControlName="password"
      required
    />
    <mat-error *ngIf="password.errors && !password.errors?.required">
      {{ 'AUTHENTICATION.PW_ERROR' | translate }}
    </mat-error>
    <mat-error *ngIf="password.errors?.required">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field class="input-without-padding-except-error" [appearance]="'fill'">
    <mat-label>{{ 'AUTHENTICATION.CONFIRM_PASSWORD' | translate }}</mat-label>
    <input
      id="passwordConfirm"
      name="passwordConfirm"
      type="password"
      matInput
      placeholder="{{ 'AUTHENTICATION.CONFIRM_PASSWORD' | translate }}"
      formControlName="passwordConfirm"
      required
    />
    <mat-error *ngIf="passwordConfirm.errors?.required">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    <mat-error *ngIf="passwordConfirm.errors?.passwordsNotMatching">{{
      'AUTHENTICATION.PASSWORD_MUST_MATCH' | translate
    }}</mat-error>
  </mat-form-field>

  <app-login-google-button
    [googleBtnId]="role === RETAILER ? 'retailerRegisterBtn' : 'supplierRegisterBtn'"
    [googleBtnId]="'registerBtnId'"
    (success)="successSociaLoginHandle('google')"
    [role]="role"
  ></app-login-google-button>

  <div class="custom-form-field">
    <mat-checkbox formControlName="checkedTerms">
      {{ 'AUTHENTICATION.READ_T_AND_C' | translate }}
      <a href="https://www.syncee.com/terms-of-use/" target="_blank">
        {{ 'AUTHENTICATION.T_AND_C' | translate }}</a
      ></mat-checkbox
    >
    <mat-error *ngIf="ngForm.submitted && checkedTerms.errors?.required">{{
      'AUTHENTICATION.T_AND_C_ERROR' | translate
    }}</mat-error>
  </div>

  <button mat-flat-button color="primary" [disabled]="!submitEnabled" (click)="submitHandle()">
    {{ 'AUTHENTICATION.CREATE_ACCOUNT' | translate }}
  </button>
</form>
