import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierCatalog } from 'app/vo/supplier-catalog/supplier-catalog';
import { ApprovalStatus } from '../../vo/approval-status';
import { TasksState } from './tasks.reducer';

const getSupplierCatalogsState = createFeatureSelector<TasksState>('tasks');

export const getAllTasks = createSelector(getSupplierCatalogsState, (state) => state?.tasks);

export const getTaskById = (id: number) =>
  createSelector(getSupplierCatalogsState, (state) => state.tasks.find((task) => +task.id === +id) as SupplierCatalog);

export const getSupplierCatalogs = createSelector(
  getAllTasks,
  (tasks) => !!tasks && tasks.filter((task) => task.taskType === 'public' || task.taskType === 'manual')
);

export const getSupplierManualCatalogs = createSelector(
  getAllTasks,
  (tasks) => !!tasks && tasks.find((task) => task.taskType === 'manual')
);

export const areAllCatalogsRejected = createSelector(
  getSupplierCatalogs,
  (catalogs) => !!catalogs && catalogs.every((catalog) => catalog.approved === ApprovalStatus.REJECTED)
);

export const hasApprovedTasksSelector = createSelector(getAllTasks, (tasks) =>
  !tasks?.length ? undefined : tasks.some((task) => task.approved === ApprovalStatus.APPROVED && task.isActive)
);
