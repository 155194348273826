<ng-container *ngIf="!loading; else skeleton">
  <div class="image-viewer-container">
    <ng-container *ngIf="productImages?.length > 1">
      <div class="thumbnail-wrapper">
        <app-custom-thumbnail
          #thumbnailComponent
          class="small-image-viewer"
          [images]="productImages"
          [isMobile]="isMobile"
          [config]="thumbnailConfig"
          (indexChange)="handleThumbnailIndexChange($event)"
          [altForAllImages]="productImageAlt"
        ></app-custom-thumbnail>
      </div>
    </ng-container>

    <div class="image-viewer-wrapper">
      <app-product-image-selector
        #imageSelectorComponent
        class="main-image-viewer"
        (indexChange)="handleImageViewerIndexChange($event)"
        [images]="productImages"
        [altForImages]="productImageAlt"
      >
        <app-image-selector-product-page-arrow-button
          leftButton
          iconName="arrow_back_ios"
        ></app-image-selector-product-page-arrow-button>
        <app-image-selector-product-page-arrow-button
          rightButton
          iconName="arrow_forward_ios"
        ></app-image-selector-product-page-arrow-button>
      </app-product-image-selector>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="image-viewer-container">
    <div class="thumbnail-wrapper">
      <div [ngStyle]="{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }">
        <ng-container *ngFor="let _ of [].constructor(3)">
          <app-skeleton-image class="skeleton-image"></app-skeleton-image>
        </ng-container>
      </div>
    </div>

    <app-skeleton-image borderRadius="4px" style="flex: 1"></app-skeleton-image>
  </div>
</ng-template>
