import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { SubStepsStepperComponent } from '../../../components/sub-steps-stepper/sub-steps-stepper.component';
import { Subject } from 'rxjs';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { SupplierPublicInformationComponent } from '../../../../supplier-public-information/supplier-public-information.component';
import { StorefrontUploadImagesComponent } from '../../../../../shared/components/storefront-upload-images/storefront-upload-images.component';
import { StepDataModel } from '../../../components/sub-steps-stepper/model/step-data.model';
import { PersonalizationStepTitle } from '../../retailer/personalization/personalization.component';
import { SnippetEnum } from '../../../../setup-guide/enums/snippet-enums';
import { SetupGuideService } from '../../../../setup-guide/service/setup-guide.service';
import { GETTING_STARTED_CONTENT_MAX_WIDTH } from '../../../../../utils/getting-started/getting-started-style-settings';

@Component({
  selector: 'app-company-presentation',
  templateUrl: './company-presentation.component.html',
  styleUrls: ['./company-presentation.component.scss'],
})
export class CompanyPresentationComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  @ViewChild('stepperComponent') stepper: SubStepsStepperComponent;
  @ViewChild('supplierDetails') supplierDetails: SupplierPublicInformationComponent;
  @ViewChild('storefrontUploadImages') storefrontUploadImages: StorefrontUploadImagesComponent;
  isLoading = false;
  currentStepIndex = 0;
  stepTitles = COMPANY_PRESENTATION_STEP_TITLES;
  contentMaxWidth = GETTING_STARTED_CONTENT_MAX_WIDTH;
  steps: StepDataModel[] = [
    {
      optional: false,
      state: 'current',
      label: 'GETTING_STARTED.STEP_PAGE.COMPANY_PRESENTATION.STEPPER_LABELS.COMPANY_BRANDING',
      isDone: false,
    },
    {
      optional: false,
      state: 'default',
      label: 'GETTING_STARTED.STEP_PAGE.COMPANY_PRESENTATION.STEPPER_LABELS.INFORMATION_FOR_RETAILERS',
      isDone: false,
    },
  ];
  private unsubscribeAll: Subject<void>;
  uploadedImagesMissing = false;

  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private setupGuideService: SetupGuideService
  ) {
    super(gettingStartedStepBaseService);
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    if (this.isCompleted) {
      this.steps.map((step) => (step.isDone = true));
    }

    this.currentStepIndex = this.initCurrentSubStep(this.steps);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleSubStepChange(index: number): void {
    this.currentStepIndex = index;
    this.setCurrentSubStep(index);
  }

  nextClicked(): void {
    switch (this.currentStepIndex) {
      case 0: {
        if (this.storefrontUploadImages.isValid) {
          this.storefrontUploadImages.saveImages().subscribe(() => {
            this.completeCurrentStep();
            this.incrementCurrentSubStep(this.currentStepIndex);
            this.stepper.navigateSteps.next('next');
          });
        } else {
          this.uploadedImagesMissing = true;
        }
        break;
      }
      case 1: {
        if (!this.supplierDetails.form.dirty && this.supplierDetails.form.valid) {
          this.completeCurrentStep();
          this.emptyCurrentSubStep();
          this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_SETUP_STOREFRONT);
          this.goToNextStep();
          break;
        }
        if (this.supplierDetails.form.valid) {
          this.supplierDetails.save().subscribe(() => {
            this.completeCurrentStep();
            this.emptyCurrentSubStep();
            this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_SETUP_STOREFRONT);
            this.goToNextStep();
          });
        } else {
          this.supplierDetails.form.markAllAsTouched();
        }
      }
    }
  }

  private completeCurrentStep(): void {
    this.stepper.completeStep.next({ stepIndex: this.currentStepIndex, complete: true });
  }

  prevClicked(): void {
    if (this.currentStepIndex === 0) {
      this.emptyCurrentSubStep();
      this.goToPreviousStep();
    } else {
      this.decrementCurrentSubStep(this.currentStepIndex);
      this.stepper.navigateSteps.next('prev');
    }
  }
}

const COMPANY_PRESENTATION_STEP_TITLES: PersonalizationStepTitle[] = [
  {
    title: 'GETTING_STARTED.STEP_PAGE.COMPANY_PRESENTATION.COMPANY_BRANDING.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.COMPANY_PRESENTATION.COMPANY_BRANDING.SUBTITLE',
  },
  {
    title: 'GETTING_STARTED.STEP_PAGE.COMPANY_PRESENTATION.INFORMATION_FOR_RETAILERS.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.COMPANY_PRESENTATION.INFORMATION_FOR_RETAILERS.SUBTITLE',
  },
];
