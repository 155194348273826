import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SearchProductVO, ImageKeys } from '../../vo/search-product-vo';
import { uniq } from 'lodash';

@Pipe({
  name: 'productImageList',
  standalone: true,
})
export class ProductImageListPipe implements PipeTransform {
  imageKeys = ImageKeys;

  transform(product: SearchProductVO, ...args): string[] {
    const imageKey = this.imageKeys.imageKey;
    const variantImageKey = this.imageKeys.vImageKey;
    const imageList: string[] = [];
    const productImages = product[imageKey];
    if (!!productImages) {
      imageList.push(...productImages);
    }
    product.VARIANTS.forEach((variant) => {
      const variantImageField = variant[variantImageKey];
      if (!variantImageField) {
        return;
      }
      if (!Array.isArray(variantImageField)) {
        imageList.push(variantImageField);
      } else {
        imageList.push(...variantImageField);
      }
    });
    return uniq(imageList);
    // return imageList.filter((v, i, a) => {
    //    return  a.indexOf(v) === i
    // });
  }
}
