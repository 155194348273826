import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CustomDialogWrapperComponent } from '../custom-dialog-wrapper/custom-dialog-wrapper.component';
import { TranslateConditionallyPipe } from '../../../pipes/translate-conditionally.pipe';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageBackButtonComponent } from '../../page-back-button/page-back-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { DataListingComponent } from '../../data-listing/data-listing.component';

@Component({
  selector: 'app-data-listing-dialog',
  templateUrl: './data-listing-dialog.component.html',
  styleUrls: ['./data-listing-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CustomDialogWrapperComponent,
    TranslateConditionallyPipe,
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    PageBackButtonComponent,
    TranslateModule,
    DataListingComponent,
  ],
})
export class DataListingDialogComponent implements OnInit, OnDestroy {
  ltMd: boolean;
  config: DataListingDialogConfig;
  private readonly unsubscribeAll: Subject<void>;
  private readonly defaultConfig: DataListingDialogConfig = {
    keyWidth: '280px',
    translateBackTo: true,
    translateKey: true,
    translateTitle: true,
    translateValue: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataListingDialogData,
    private screenManagerService: ScreenManagerService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.config = this.getConfig();
    this.getLtMd().subscribe((matches) => (this.ltMd = matches));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private getConfig(): DataListingDialogConfig {
    return {
      ...this.defaultConfig,
      ...(!!this.data?.config && this.data.config),
    };
  }

  private getLtMd(): Observable<boolean> {
    return this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), takeUntil(this.unsubscribeAll));
  }
}

export interface DataListingDialogData {
  title: string;
  items: DataListingDialogItem[];
  backTo?: string;
  config?: Partial<DataListingDialogConfig>;
}

export interface DataListingDialogItem {
  key: string;
  value: string;
}

export interface DataListingDialogConfig {
  translateTitle: boolean;
  translateKey: boolean;
  translateValue: boolean;
  translateBackTo: boolean;
  keyWidth: string;
}
