import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { FlatTreeControl } from '@angular/cdk/tree';

@Component({
  selector: 'app-category-tree-selector',
  templateUrl: './category-tree-selector.component.html',
  styleUrls: ['./category-tree-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatTreeModule,
    MatIconModule,
  ],
})
export class CategoryTreeSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  synceeCategories: CategoryNode[] = [];
  selectedCategoryName: string;
  isRemove = false;
  isCollectionList = false;
  removeItems: string[] = [];
  treeControl: FlatTreeControl<FlatNode, FlatNode>;
  treeFlattener: MatTreeFlattener<CategoryNode, FlatNode, FlatNode>;
  dataSource: MatTreeFlatDataSource<CategoryNode, FlatNode, FlatNode>;
  itemsSelection = new SelectionModel<FlatNode>(true, []);

  private transformer: (node: CategoryNode, level: number) => FlatNode;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CategoryTreeSelectorData,
    public dialogRef: MatDialogRef<CategoryTreeSelectorComponent>
  ) {
    this.transformer = (node: CategoryNode, level: number) => {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.name,
        path: node.path,
        level: level,
        id: node.id,
        parent: node.parent,
      };
    };
    this.treeControl = new FlatTreeControl<FlatNode>(
      (node) => node.level,
      (node) => node.expandable
    );
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.isRemove = this.data.isRemove;
    this.isCollectionList = this.data.isCollectionList || false;

    if (!this.isCollectionList) {
      if (this.isRemove) {
        const _removeItems: any[] = this.data.bulkRemoveItems.flatMap((elem: any) => elem.breadcrumbs);
        this.removeItems = [...new Set(_removeItems)];
      } else {
        this.synceeCategories = this.data.categories;
        this.selectedCategoryName = this.data.selectedCategory;
      }
    } else {
      if (this.isRemove) {
        const _removeItems: any[] = this.data.bulkRemoveItems.flatMap((elem: any) => elem.breadcrumbs);
        this.removeItems = [...new Set(_removeItems)];
      } else {
        this.synceeCategories = this.data.categories.map((elem) => ({
          ...elem,
          name: elem.title,
          path: elem.title,
        }));
        this.selectedCategoryName = this.data.selectedCategory;
      }
    }

    this.dataSource.data = this.synceeCategories;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.data.preSelectedCategories) {
      this.data.preSelectedCategories.forEach((path) => {
        const node = this.findNodeByPath(path);
        if (node) {
          this.itemsSelection.select(node);
          this.expandAncestors(node);
        }
        if (!this.isCollectionList) {
          const parent = this.data.all.find((category) => category.path === path).parent;
          this.expandAncestors(parent);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  filterTree(filterText: string): void {
    this.dataSource.data = this.filterNodes(this.synceeCategories, filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  private filterNodes(nodes: CategoryNode[], filterText: string): CategoryNode[] {
    return nodes.reduce<CategoryNode[]>((acc, node) => {
      const children = node.children ? this.filterNodes(node.children, filterText) : [];
      if (this.matchFilter(node, filterText) || (children && children.length > 0)) {
        acc.push({ ...node, children });
      }
      return acc;
    }, []);
  }

  private matchFilter(node: CategoryNode, filterText: string): boolean {
    return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) => this.itemsSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.itemsSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  itemSelectionToggle(node: FlatNode): void {
    this.itemsSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.itemsSelection.isSelected(node)
      ? this.itemsSelection.select(...descendants)
      : this.itemsSelection.deselect(...descendants);
  }

  checkboxChanged(event: any, node: FlatNode): void {
    if (event.checked) {
      if (this.data.ecomType === 'squarespace') {
        this.itemsSelection.clear();
      }
      this.itemsSelection.select(node);
    } else {
      this.itemsSelection.deselect(node);
    }
    this.expandAncestors(node);
  }

  bulkRemoveSelection(event, item): void {
    if (event.checked) {
      if (!this.itemsSelection.isSelected(item)) {
        this.itemsSelection.select(item);
      }
    } else {
      if (this.itemsSelection.isSelected(item)) {
        this.itemsSelection.deselect(item);
      }
    }
  }

  save(): void {
    this.dialogRef.close(this.itemsSelection.selected.map((node) => node.path));
  }

  private expandAncestors(node: FlatNode): void {
    let current = this.getParentNode(node);
    while (current) {
      this.treeControl.expand(current);
      current = this.getParentNode(current);
    }
  }

  private getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  private findNodeByPath(path: string): FlatNode | undefined {
    return this.treeControl.dataNodes.find((node) => node.path === path);
  }
}

interface CategoryNode {
  id: string;
  name: string;
  path: string;
  parent?: string;
  children?: CategoryNode[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  path: string;
  level: number;
  id: string;
  parent?: string;
}

interface CategoryTreeSelectorData {
  isCollectionList: boolean;
  selectedCategory: string;
  categories: any[];
  all: any[];
  preSelectedCategories: any;
  ecomType: string;
  isRemove: boolean;
  bulkRemoveItems: any[];
}
