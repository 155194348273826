import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getUserRoleSelector } from '../../../store/user/user.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { ROLE_TYPE } from '../../../vo/roles/roles';

@Component({
  selector: 'app-mobile-center',
  templateUrl: './mobile-center.component.html',
  styleUrls: ['./mobile-center.component.scss'],
})
export class MobileCenterComponent implements OnInit, OnDestroy {
  @Input() previouslyOpenedSidebars: string[] = [];
  @Output() close = new EventEmitter<void>();
  role: ROLE_TYPE;
  private unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>, private fuseSidebarService: FuseSidebarService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getRole()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((role) => (this.role = role));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  backClicked(): void {
    this.closeSidebar(false);
  }

  itemClicked(): void {
    this.closeSidebar(true);
  }

  closeClicked(): void {
    this.closeSidebar(true);
  }

  private closeSidebar(closeAll: boolean): void {
    this.fuseSidebarService.getSidebar('mobile-center').close();
    if (closeAll) {
      this.close.emit();
      this.previouslyOpenedSidebars.forEach((key) => this.fuseSidebarService.getSidebar(key).close());
    }
  }

  private getRole(): Observable<ROLE_TYPE> {
    return this.store.select(getUserRoleSelector);
  }
}
