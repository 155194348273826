<mat-form-field class="example-chip-list input-without-padding-except-error" appearance="fill">
  <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.FIELD_MAPPING.SELECT_FIELD' | translate }}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let field of selectedFields"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(field)"
      class="grey-chip"
    >
      {{ field.field.displayName }}
      <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
    </mat-chip>
    <input
      #fruitInput
      [formControl]="fruitCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (click)="openAutoComplete()"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <ng-container *ngFor="let fConfig of filteredAutoFields">
      <mat-optgroup [label]="fConfig.name">
        <mat-option
          *ngFor="let field of fConfig.fields"
          [value]="{ group: { name: fConfig.name, isArray: fConfig.isArray }, field: field }"
        >
          {{ field.displayName }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <!--<mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">-->
    <!--{{fruit}}-->
    <!--</mat-option>-->
  </mat-autocomplete>
</mat-form-field>
