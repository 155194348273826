import { MarketplaceFilter } from '../../../../vo/search-product-vo';

export interface AIChatMessage {
  type: AIChatMessageType;
  source: AIChatMessageSource;
  payload: Partial<AIChatMessagePayload>;
  needToRequest: boolean;
  raw?: any[];
  hide?: boolean;
}

export interface AIChatMessagePayload {
  simpleMessage: string;
  filter: MarketplaceFilter;
  searchTerms: string[];
}

export enum AIChatMessageType {
  SIMPLE_CHAT = 'simple_chat',
  FILTER = 'filter',
  SEARCH_TERM_SUGGESTION = 'search_term_suggestion',
}

export enum AIChatMessageSource {
  RESPONSE = 'response',
  COMMAND = 'command',
}
