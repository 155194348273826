import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../../../service/rest/microservices/spring-rest.service';
import { Observable, switchMap } from 'rxjs';
import { AiChatDto } from '../model/dto/ai-chat-dto';
import { AiChatRequestMessage } from '../model/dto/ai-chat-request-message';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';
import { take } from 'rxjs/operators';
import { AiChatRequest } from '../model/dto/ai-chat-request';

@Injectable({
  providedIn: 'root',
})
export class AiChatRestService {
  constructor(private springRestService: SpringRestService, private store: Store<AppState>) {}

  send(messages: AiChatRequestMessage[]): Observable<AiChatDto[]> {
    return this.store.select(selectedRetailerEcomSelector).pipe(
      take(1),
      switchMap((ecom) =>
        this.springRestService.post(
          MicroserviceNames.USER,
          '/v2/AIChat',
          { messages, ecomId: ecom?.id || null } as AiChatRequest,
          undefined,
          true
        )
      )
    );
  }
}
