import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/types';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app/app.state';
import { AddFilterWithRedirectAction } from '../../../../../app/store/product-search/product-search.action';
import { MarketplaceFilter } from '../../../../../app/vo/search-product-vo';
import { FuseConfigService } from '../../../../services/config.service';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    @Output() itemClicked = new EventEmitter<void>();

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     */

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private store: Store<AppState>,
    private fuseConfigService: FuseConfigService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation item
    merge(
      this._fuseNavigationService.onNavigationItemAdded,
      this._fuseNavigationService.onNavigationItemUpdated,
      this._fuseNavigationService.onNavigationItemRemoved
    ).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  handleFilter(): void {
    this.itemClicked.emit();
    this.store.dispatch(new AddFilterWithRedirectAction({
      filter: this.item.queryParams as MarketplaceFilter,
      // needRedirect: this.fuseConfigService.getConfigValue().layout.categorySelector.redirect
      needRedirect: true
    }));
  }

  handleFunctionItem(): void {
    this.item.function();
    this.itemClicked.emit();
  }

}
