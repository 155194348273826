<app-avatar
  type="icon"
  [diameter]="52"
  [noRipple]="true"
  [backgroundColor]="themeConfig[themeType].bgColor"
  [color]="themeConfig[themeType].color"
  [nonPointer]="true"
>
  <mat-icon class="s-28">{{ icon }}</mat-icon>
</app-avatar>
