import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-task-details-mobilepopup',
  templateUrl: './task-details-mobilepopup.component.html',
  styleUrls: ['./task-details-mobilepopup.component.scss'],
})
export class TaskDetailsMobilepopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<TaskDetailsMobilepopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }
}
