<mat-accordion
  fxLayout="column"
  fxLayoutGap="8px"
  displayMode="flat"
  *ngIf="productDetails && orderInfoData; else skeleton"
>
  <div class="product-accordion-panel">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          ><h4>{{ 'PRODUCT_PAGE.DESCRIPTION' | translate }}</h4></mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        [innerHTML]="
          description
            ? (description | overwriteHrefs | overwriteHTags | sanitizeHTML)
            : ('PRODUCT_PAGE.NO_DESCRIPTION' | translate)
        "
      ></div>
    </mat-expansion-panel>
    <mat-divider *onlyLoggedIn></mat-divider>
  </div>
  <ng-container *onlyLoggedIn>
    <div class="product-accordion-panel">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            ><h4>{{ 'PRODUCT_PAGE.PRODUCT_DETAILS' | translate }}</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-accordion-product-details
          [details]="productDetails"
          [orderInfoData]="orderInfoData"
        ></app-accordion-product-details>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </div>
    <div class="product-accordion-panel">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            ><h4>{{ 'PRODUCT_PAGE.ORDER_INFORMATION' | translate }}</h4></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div>
          <app-supplier-order-info [data]="orderInfoData"></app-supplier-order-info>
          <ng-container *hasPermission="CONTACTS_READ; data: { ecom: null }">
            <a (click)="openOrderAndCatalogInformation()" class="order-info">
              {{ 'STOREFRONT.DETAILS.INFOS_LINKS.ORDER_AND_CATALOG' | translate }}
            </a>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </div>
  </ng-container>
</mat-accordion>

<ng-template #skeleton>
  <div fxLayout="column" fxLayoutGap="24px" style="margin-bottom: 24px">
    <app-skeleton-display-text></app-skeleton-display-text>

    <app-skeleton-display-text></app-skeleton-display-text>

    <app-skeleton-display-text></app-skeleton-display-text>
  </div>
</ng-template>
