import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Choice, TableFilterItem } from '../../filter-factory/filter-factory.component';
import { fuseAnimations } from '@fuse/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeCustomComponent } from '../../badge-custom/badge-custom.component';
import { StatusColorPipe } from 'app/shared/pipes/status-color.pipe';
import { StatusTextColorPipe } from 'app/shared/pipes/status-text-color.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { isEqual } from 'lodash';
import { SearchbarCustomComponent } from '../../searchbar-custom/searchbar-custom.component';
import { distinctUntilChanged, finalize, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-index-table-filter-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    ScrollingModule,
    TranslateModule,
    BadgeCustomComponent,
    StatusColorPipe,
    StatusTextColorPipe,
    MatRadioModule,
    SearchbarCustomComponent,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './index-table-filter-dropdown.component.html',
  styleUrls: ['./index-table-filter-dropdown.component.scss'],
  animations: [fuseAnimations],
})
export class IndexTableFilterDropdownComponent<T> implements OnInit, OnDestroy {
  @Input() choices: Choice<T>[];
  @Input() radio = false;
  @Input() hasSearch = false;
  @Input() selectedChoice: T;
  @Input() fetcher: (from: number, size: number, searchTerm: string) => Observable<T[]>;
  @Input() size = 10;
  @Input() choiceLabelProperty: string;
  @Output() selectedChoiceChanged: EventEmitter<Omit<TableFilterItem, 'type'>> = new EventEmitter<
    Omit<TableFilterItem, 'type'>
  >();

  from = 0;
  searchTerm$: Subject<string>;
  hasMore: boolean;
  isLoading: boolean;

  private unsubscribeAll: Subject<void>;
  private _searchTerm: string;

  constructor() {
    this.searchTerm$ = new Subject<string>();
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    if (!!this.fetcher) {
      this.loadInitialChoices();
      this.subscribeToSearchTermChange();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  isEqual(object: any, other: any): boolean {
    return isEqual(object, other);
  }

  handleLoadMore(): void {
    if (this.hasMore) {
      this.isLoading = true;
      this.from += this.size;
      this.fetchChoices(this.from, this.size, this._searchTerm, false);
    }
  }

  private mapValuesToChoices(values: T[]): Choice<T>[] {
    return values.map((value) => {
      return {
        value: value,
        label: !!this.choiceLabelProperty ? value[this.choiceLabelProperty] : value,
        translate: false,
      };
    });
  }

  private loadInitialChoices(): void {
    this.isLoading = true;
    this.fetchChoices(0, this.size, null, true);
  }

  private subscribeToSearchTermChange(): void {
    this.searchTerm$.pipe(takeUntil(this.unsubscribeAll), distinctUntilChanged(isEqual)).subscribe((searchTerm) => {
      this._searchTerm = searchTerm;
      this.fetchChoices(this.from, this.size, searchTerm, true);
    });
  }

  private fetchChoices(from: number, size: number, searchTerm: string, newSearch: boolean): void {
    this.fetcher(from, size, searchTerm)
      .pipe(
        take(1),
        tap((values) => {
          this.hasMore = values?.length === size;
        }),
        map((values) => this.mapValuesToChoices(values)),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((choices) => {
        if (newSearch) {
          this.choices = choices;
        } else {
          this.choices = this.choices.concat(choices);
        }
      });
  }
}
