import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FilterModule } from '../../filter/filter.module';
import { RetailerUpdateCatalogDialogComponent } from './retailer-update-catalog-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AlertMultiComponent } from '../../../shared/components/alert-multi/alert-multi.component';
import { DialogWithAnInputComponent } from '../../../shared/components/dialogs/dialog-with-an-input/dialog-with-an-input.component';
import { AlertWithButtonComponent } from '../../../shared/components/alert-with-button/alert-with-button.component';
import {
  ProductImageFullCdnPipe,
  ProductImageThumbnailCdnPipe,
} from '../../../shared/pipes/product-image-resizer-cdn.pipe';
import { ProductImageErrorHandlerDirective } from '../../../shared/directives/product-image-error-handler.directive';
import { SearchbarCustomComponent } from 'app/shared/components/searchbar-custom/searchbar-custom.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { TablePaginatorComponent } from 'app/shared/components/table-paginator/table-paginator.component';
import { VariantImgSelectorPopupComponent } from './retailer-catalog-details/pim-editor/variant-img-selector-popup/variant-img-selector-popup.component';
import { RetailerVariantsRenameComponent } from './retailer-catalog-details/retailer-variants-rename/retailer-variants-rename.component';
import { SubscriptionUpsellComponent } from 'app/shared/components/subscription-upsell/subscription-upsell.component';

@NgModule({
  declarations: [
    RetailerUpdateCatalogDialogComponent,
    VariantImgSelectorPopupComponent,
    RetailerVariantsRenameComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FuseSharedModule,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatStepperModule,
    MatTabsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatGridListModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    FilterModule,
    MatDialogModule,
    MatChipsModule,
    MatRadioModule,
    CKEditorModule,
    AlertMultiComponent,
    DialogWithAnInputComponent,
    AlertWithButtonComponent,
    ProductImageFullCdnPipe,
    ProductImageThumbnailCdnPipe,
    ProductImageErrorHandlerDirective,
    SearchbarCustomComponent,
    TranslateModule,
    MatTooltipModule,
    LoadingSpinnerComponent,
    TablePaginatorComponent,
    SubscriptionUpsellComponent,
  ],
  exports: [],
})
export class RetailerCatalogsModule {}
