import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { take } from 'rxjs';
import { RetailerDashboardAnalyticsService } from '../../../service/analytics/retailer-dashboard-analytics.service';
import { ColorPaletteService } from '../../../service/color-palette/color-palette.service';
import { emptyPlugin, htmlPieLegend } from './pie-legend-plugin';

@Component({
  selector: 'app-products-pie-chart',
  templateUrl: './products-pie-chart.component.html',
  styleUrls: ['./products-pie-chart.component.scss'],
})
export class ProductsPieChartComponent implements OnInit, OnChanges {
  private readonly ALL_PRODUCTS_CATEGORY_ID = 1;

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;

  empty = true;
  doughnutChartPlugins = [htmlPieLegend, emptyPlugin];
  doughnutChartLabels: string[];
  doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'];
  doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    // responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1,

    plugins: {
      legend: {
        display: false,
      },
      // @ts-ignore
      htmlPieLegend: {
        // ID of the container to put the legend in
        containerID: 'pie-legend-container',
      },
      emptyPie: {
        color: this.colorPaletteService.getColorVariable('--app-grey-100'),
        width: 50,
        radiusDecrease: 25,
      },
    },
  };
  readonly navigateToMarketplace = MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH;

  constructor(
    private colorPaletteService: ColorPaletteService,
    private retailerDashboardAnalyticsService: RetailerDashboardAnalyticsService
  ) {}

  ngOnInit(): void {
    this.getPalette();
    this.setChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.setChart();
    }
  }

  private getPalette(): string[] {
    const bluePalette: string[] = Array.from(this.colorPaletteService.getPalette('syncee-primary', true).values());
    const greyPalette: string[] = Array.from(this.colorPaletteService.getPalette('grey').values());
    return bluePalette.concat(greyPalette);
  }

  private setChart(): void {
    this.retailerDashboardAnalyticsService
      .getCategorySellingAnalytics(this.dateFrom, this.dateTo)
      .pipe(take(1))
      .subscribe((categoryAnalytics) => {
        this.empty = !categoryAnalytics.length;
        this.doughnutChartLabels = categoryAnalytics.map((analytic) =>
          analytic.categoryId !== this.ALL_PRODUCTS_CATEGORY_ID ? analytic.categoryName : 'N/A'
        );
        this.doughnutChartDatasets = [
          {
            data: categoryAnalytics.length ? categoryAnalytics.map((analitic) => analitic.quantity) : [],
            label: 'Top selling categories',
            backgroundColor: categoryAnalytics.length
              ? this.getPalette()
              : this.colorPaletteService.getColorVariable('--app-syncee-grey-200'),
          },
        ];
        this.chart.chart.update();
      });
  }
}
