import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DataListingDialogComponent,
  DataListingDialogConfig,
  DataListingDialogData,
  DataListingDialogItem,
} from './data-listing-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataListingDialogService {
  constructor(private dialog: MatDialog) {}

  open(
    title: string,
    items: DataListingDialogItem[],
    backTo?: string,
    config?: Partial<DataListingDialogConfig>
  ): Observable<void> {
    return this.dialog
      .open<DataListingDialogComponent, DataListingDialogData>(DataListingDialogComponent, {
        data: { title, items, config, backTo },
        autoFocus: false,
        panelClass: 'custom-modal-full-width-lt-md',
      })
      .afterClosed()
      .pipe(map(() => {}));
  }
}
