import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LeaveUserReviewConfig } from '../leave-user-review-dialog/config/leave-user-review.config';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../notification/notification.service';
import { combineLatest, Observable, of } from 'rxjs';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import {
  LeaveUserReviewDialogComponent,
  LeaveUserReviewDialogData,
} from '../leave-user-review-dialog/leave-user-review-dialog.component';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Review, ReviewRate } from '../../../vo/review/review';
import { AppState } from '../../../app.state';
import { ReviewType } from '../../../vo/review/review-type';
import { UserReviewService } from '../../../service/review/user-review.service';
import { RatingItem } from '../../../shared/components/rating-with-icons/rating-item';
import { DEFAULT_RATING_ITEMS } from '../../../shared/components/rating-with-icons/default-rating-items';

@Injectable({
  providedIn: 'root',
})
export class LeaveUserReviewService {
  constructor(
    private dialog: MatDialog,
    private userReviewService: UserReviewService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private reviewService: UserReviewService
  ) {}

  autoOpen(supplierId: number): Observable<boolean> {
    return combineLatest([this.getUserId(), this.userReviewService.getSupplierReviews(supplierId)]).pipe(
      take(1),
      switchMap(([userId, result]) => {
        if (!!result && !!result.length && result.some((review) => review.fromUserId === userId)) {
          return of(false);
        } else {
          return this.open(supplierId);
        }
      })
    );
  }

  open(supplierUserId: number, existingReview: Review = null): Observable<boolean> {
    return combineLatest([this.getUserId(), this.getReviewTypes()]).pipe(
      switchMap(([userId, reviews]) => {
        return this.handleDialogOpen(userId, supplierUserId, reviews, existingReview);
      })
    );
  }

  getReviewRates(reviewSteps: LeaveUserReviewConfig[]): ReviewRate[] {
    const reviewRates: ReviewRate[] = [];
    reviewSteps.forEach((stepItem) => {
      if (stepItem.hasRating && !!stepItem.rating) {
        reviewRates.push({ rate: stepItem.rating, reviewType: stepItem.type });
      }
    });
    return reviewRates;
  }

  public deleteReview(supplierID: number): Observable<void> {
    return this.reviewService.delete(supplierID);
  }

  private getUserId(): Observable<number> {
    return this.store.select(getCurrentUserIdSelector);
  }

  private handleDialogOpen(
    userId: number,
    supplierUserId: number,
    reviewItems: LeaveUserReviewConfig[],
    existingReview: Review = null
  ): Observable<boolean> {
    const dialogRef = this.dialog.open<LeaveUserReviewDialogComponent, LeaveUserReviewDialogData, boolean>(
      LeaveUserReviewDialogComponent,
      {
        data: {
          currentUserId: userId,
          supplierUserId,
          reviewItems,
          review: existingReview,
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );
    return dialogRef.afterClosed();
  }

  private getReviewTypes(): Observable<LeaveUserReviewConfig[]> {
    return this.userReviewService.getUserReviewTypes().pipe(
      map((value) => {
        return this.mapTypesToReviewSteps(value);
      })
    );
  }

  handleReviewSubmit(reviewData: Partial<Review>, hasExistingReview: boolean): Observable<boolean> {
    if (hasExistingReview) {
      return this.handleUpdateExistingReview(reviewData);
    } else {
      return this.handleAddNewReview(reviewData);
    }
  }

  handleAddNewReview(reviewData: Partial<Review>): Observable<boolean> {
    return this.handlePostAndPutReviewRequests(this.userReviewService.postUserReview(reviewData));
  }

  handleUpdateExistingReview(reviewData: Partial<Review>): Observable<boolean> {
    return this.handlePostAndPutReviewRequests(this.userReviewService.putUserReview(reviewData));
  }

  private handlePostAndPutReviewRequests(request: Observable<void>): Observable<boolean> {
    return request.pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        this.notificationService.error('Sorry, an error occurred during the request.');
        return of(false);
      })
    );
  }

  private mapTypesToReviewSteps(typeList: ReviewType[]): LeaveUserReviewConfig[] {
    return typeList.map((reviewType) => ({
      orderIndex: reviewType.orderIndex,
      type: reviewType.type,
      rating: null,
      hasRating: true,
      ratingItems: this.getRatingItems(reviewType.type),
    }));
  }

  getRatingItems(reviewType: string): RatingItem[] {
    const getLabelKeyByIndex = (index: number): string => {
      switch (index) {
        case 0:
          return 'ONE';
        case 1:
          return 'TWO';
        case 2:
          return 'THREE';
        case 3:
          return 'FOUR';
        case 4:
          return 'FIVE';
      }
    };
    return DEFAULT_RATING_ITEMS.map((item, index) => ({
      ...item,
      label: `USER_REVIEW_DIALOG.${reviewType}.OPTIONS.${getLabelKeyByIndex(index)}`,
    }));
  }
}
