import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FullMarketplaceFilterVO } from '../../../../vo/search-product-vo';

@Component({
  selector: 'app-shipping-time-filter',
  templateUrl: './shipping-time-filter.component.html',
  styleUrls: ['./shipping-time-filter.component.scss'],
})
export class ShippingTimeFilterComponent implements OnInit {
  @Input() value: ShippingTimeFilterValue;
  @Input() hasClearAll = false;
  @Output() valueChange = new EventEmitter<ShippingTimeFilterValue>();

  @Output() clearAll = new EventEmitter<void>();

  options: ShippingTimeFilterOption[] = [
    { min: null || undefined, max: 4, label: 'FILTER_COMMON.SELECTOR.VALUES.SHIPPING_TIME.FOUR_DAYS_MAX' },
    { min: null || undefined, max: 7, label: 'FILTER_COMMON.SELECTOR.VALUES.SHIPPING_TIME.SEVEN_DAYS_MAX' },
    { min: null || undefined, max: 14, label: 'FILTER_COMMON.SELECTOR.VALUES.SHIPPING_TIME.FOURTEEN_DAYS_MAX' },
    { min: 15, max: null || undefined, label: 'FILTER_COMMON.SELECTOR.VALUES.SHIPPING_TIME.FIFTEEN_DAYS_MIN' },
  ];

  constructor() {}

  ngOnInit(): void {}

  handleChange(value: ShippingTimeFilterValue): void {
    this.valueChange.emit(value);
  }

  handleClearAllClicked(): void {
    this.clearAll.emit();
  }
}

export interface ShippingTimeFilterValue extends Pick<FullMarketplaceFilterVO, 'minShippingTime' | 'maxShippingTime'> {}

export interface ShippingTimeFilterOption {
  min: number;
  max: number;

  label: string;
}
