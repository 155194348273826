import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToolbarActionbarContent, ToolbarExtraIcon } from '../../../../../../@fuse/types';
import { BreakPoint, ScreenManagerService } from '../../../../../service/screen-manager/screen-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { RolesEnum } from '../../../../../vo/roles/roles';

@Component({
  selector: 'app-toolbar-action-bar',
  templateUrl: './toolbar-action-bar.component.html',
  styleUrls: ['./toolbar-action-bar.component.scss'],
})
export class ToolbarActionBarComponent implements OnInit, OnDestroy {
  @Input() extraIcons: ToolbarExtraIcon[] = [];
  @Input() role: number;
  @Input() content: ToolbarActionbarContent;
  private _unsubscribeAll: Subject<void>;
  isMobileSize: boolean;

  constructor(private screenManager: ScreenManagerService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.checkMobileSize();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private checkMobileSize(): void {
    this.screenManager
      .observeBreakpoint(BreakPoint.lg)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((breakPointState) => {
        this.isMobileSize = breakPointState.matches;
      });
  }

  protected readonly RolesEnum = RolesEnum;
}
