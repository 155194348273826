<div class="supplier-list-container" *ngIf="!!suppliers && !!supplierImages; else skeleton">
  <div class="pb-20" *ngIf="title">
    <h3 class="h3">
      {{ title }}
    </h3>
  </div>
  <div class="slider-container" fxLayoutAlign="center center">
    <ng-container *ngIf="!ltMd; else mobile">
      <app-slider-arrow
        *ngIf="swiped"
        variant="previous"
        fxFlex="0 0 auto"
        [style.visibility]="suppliers.length < config.slidesToShow && 'hidden'"
        (click)="slickModal.slickPrev()"
      ></app-slider-arrow>
      <ngx-slick-carousel
        fxFlex="1 0 0"
        #slickModal
        class="supplier-list-items"
        [@animateStagger]="{ value: '50' }"
        [config]="config"
      >
        <ng-container>
          <ng-container *ngFor="let supplier of suppliers">
            <app-supplier-card
              class="supplier-item"
              ngxSlickItem
              [supplier]="supplier"
              [supplierImages]="supplierImages[supplier.userId]"
              [categoryId]="selectedCategoryId"
            ></app-supplier-card>
          </ng-container>
        </ng-container>
      </ngx-slick-carousel>
      <app-slider-arrow
        variant="next"
        fxFlex="0 0 auto"
        [style.visibility]="suppliers.length < config.slidesToShow && 'hidden'"
        (click)="slickModal.slickNext(); swiped = true"
      ></app-slider-arrow>
    </ng-container>
  </div>
</div>

<ng-template #skeleton>
  <div class="content content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-supplier-slider-box></app-skeleton-supplier-slider-box>
  </div>
</ng-template>

<ng-template #mobile>
  <div class="mobile-carousel">
    <app-supplier-card
      *ngFor="let supplier of suppliers"
      class="supplier-item"
      [supplier]="supplier"
      [supplierImages]="supplierImages[supplier.userId]"
      [categoryId]="selectedCategoryId"
    ></app-supplier-card>
  </div>
</ng-template>
