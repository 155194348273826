import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-authentication-dialog-banner',
  templateUrl: './authentication-dialog-banner.component.html',
  styleUrls: ['./authentication-dialog-banner.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatIconModule, TranslateModule],
})
export class AuthenticationDialogBannerComponent implements OnInit {
  @Input() coverImgPath = 'assets/images/backgrounds/retailer-login.png';
  @Input() textList: string[] = [];
  @Input() textPosition: 'top' | 'center' = 'top';

  constructor() {}

  ngOnInit(): void {}
}
