import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CountriesManagerService } from 'app/service/countries-manager/countries-manager.service';
import { Shipping } from 'app/service/order/order.service';
import { CPF_COUNTRY_CODES, EU_COUNTRIES_CODES, STATES } from 'app/utils/Constants';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-shipping-info-form',
  templateUrl: './shipping-info-form.component.html',
  styleUrls: ['./shipping-info-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    LoadingSpinnerComponent,
    MatInputModule,
  ],
})
export class ShippingInfoFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  ltMd: boolean;

  @Input()
  labelled = false;

  @Input() disable = false;

  @Input() set shipping(value: Shipping) {
    this.initForm(value);
  }
  private _unsubscribeAll: Subject<void>;

  hasError = false;

  countryList = this.countriesManagerService.getOnlyCountries();
  euCountryCodeList = EU_COUNTRIES_CODES;
  cpfCountryCodeList = CPF_COUNTRY_CODES;
  usStates = STATES;

  public shippingInfoForm = this.fb.group({
    id: [null],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    countryCode: ['', Validators.required],
    country: [''],
    city: ['', Validators.required],
    zip: ['', Validators.required],
    address1: ['', Validators.required],
    address2: [''],
    company: [''],
    province: [''],
    phone: ['', Validators.required],
  });
  public filteredCountries: Observable<any[]>;
  public countryFilter = new UntypedFormControl();
  private filterInited = false;

  constructor(private fb: UntypedFormBuilder, private countriesManagerService: CountriesManagerService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.filteredCountries = of(this.countryList);
    this.handleDisable();
    this.initProvinceSubscription();
  }

  handleDisable(): void {
    if (this.disable) {
      this.shippingInfoForm.disable();
    }
  }

  initForm(shipping: Shipping): void {
    this.shippingInfoForm.patchValue(shipping);
    this.listenToCountryCodeChanges();
  }

  listenToCountryCodeChanges(): void {
    this.shippingInfoForm.get('countryCode').valueChanges.subscribe((value) => {
      const country = this.countryList.find((countryItem) => countryItem.code === value);
      this.shippingInfoForm.patchValue({
        country: country.name,
        province: value !== 'US' ? null : this.shippingInfoForm.controls['province'].value,
      });
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.countryList.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  public initFilter(): void {
    if (!this.filterInited) {
      this.filteredCountries = this.countryFilter.valueChanges.pipe(map((value) => this._filter(value)));
      this.filterInited = true;
    }
  }

  private initProvinceSubscription(): void {
    this.shippingInfoForm.controls['provinceCode'].valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((provinceCode: string): void => {
        console.log(provinceCode);
        if (provinceCode && provinceCode.length > 0) {
          this.shippingInfoForm.controls['province'].setValue(
            this.countriesManagerService.getProvinceNameByProvinceCode(provinceCode)
          );
        } else {
          this.shippingInfoForm.controls['province'].setValue('');
        }
      });
  }
}
