import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card-corner-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-corner-badge.component.html',
  styleUrls: ['./card-corner-badge.component.scss'],
})
export class CardCornerBadgeComponent implements OnInit {
  @Input() position: CardCornerBadgePosition = 'top-right';
  @Input() text = 'Default';
  @Input() textColor = 'var(--app-white-500)';
  @Input() bgColor = 'var(--app-syncee-primary-700)';

  constructor() {}

  ngOnInit(): void {}
}

export type CardCornerBadgePosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
