<ng-container [ngSwitch]="approveStatus">
  <ng-container *ngSwitchCase="'1'">
    <app-badge-custom
      [badgeSize]="'sm'"
      [text]="'APPROVE_STATUS_BADGE.APPROVED' | translate"
      [bgColor]="'var(--app-syncee-primary-50)'"
      [textColor]="'#286DF8'"
    >
    </app-badge-custom>
  </ng-container>
  <ng-container *ngSwitchCase="'0'">
    <app-badge-custom
      [badgeSize]="'sm'"
      [text]="'APPROVE_STATUS_BADGE.PENDING' | translate"
      [bgColor]="'var(--app-syncee-grey-200)'"
      [textColor]="'var(--app-syncee-grey-500)'"
    >
    </app-badge-custom>
  </ng-container>
  <ng-container *ngSwitchCase="'-1'">
    <app-badge-custom
      [badgeSize]="'sm'"
      [text]="'APPROVE_STATUS_BADGE.REJECTED' | translate"
      [bgColor]="'var(--app-syncee-warn-100)'"
      [textColor]="'var(--app-syncee-warn-400)'"
    >
    </app-badge-custom>
  </ng-container>
</ng-container>
