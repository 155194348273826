import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleAlertWithIconComponent } from '../single-alert-with-icon/single-alert-with-icon.component';
import { RetailerNavigation } from '../../../navigation/navigation-routes/retailer/retailer.navigation';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';

@Component({
  selector: 'app-no-ecom',
  standalone: true,
  imports: [CommonModule, SingleAlertWithIconComponent],
  templateUrl: './no-ecom.component.html',
  styleUrls: ['./no-ecom.component.scss'],
})
export class NoEcomComponent implements OnInit {
  @Input() titleKey = 'COMMON_ALERTS.NO_ECOM.TITLE';
  @Input() subTitleKey: string;
  redirectUrl = RetailerCenterNavigation.SETTINGS.MY_SHOPS;

  constructor() {}

  ngOnInit(): void {}
}
