<app-custom-dialog-wrapper [headerLeftRef]="dialogTitleRef" headerPadding="32px 40px 0">
  <ng-container *ngIf="role">
    <div fxLayout="column" fxLayoutGap="40px" class="content-wrapper">
      <div fxLayout="column" fxLayoutGap="24px">
        <div class="field">
          <div class="field-key" [ngClass]="isMobile ? 'h5' : 'h6'">
            {{ configData[role].nameLabel | translate | capitalise }}
          </div>
          <div class="field-value">{{ name }}</div>
        </div>
        <app-custom-divider></app-custom-divider>
        <ng-container *ngIf="!!taskName">
          <div class="field">
            <div class="field-key" [ngClass]="isMobile ? 'h5' : 'h6'">
              {{ configData[role].taskNameLabel | translate | capitalise }}
            </div>
            <div class="field-value">{{ taskName }}</div>
          </div>
          <app-custom-divider></app-custom-divider>
        </ng-container>
        <div class="field">
          <div class="field-key" [ngClass]="isMobile ? 'h5' : 'h6'">
            {{ 'TASK_INFORMATION_BASE_DIALOG.STATUS' | translate | capitalise }}
          </div>
          <div class="field-value">
            <app-catalog-approve-status-badge [approveStatus]="status"></app-catalog-approve-status-badge>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <ng-container *ngIf="!!rejectedNoteRef" [ngTemplateOutlet]="rejectedNoteRef"> </ng-container>
        <!-- <ng-container *ngIf="!!shippingTypeRef" [ngTemplateOutlet]="shippingTypeRef"> </ng-container> -->
        <div class="field">
          <div class="field-key" [ngClass]="isMobile ? 'h5' : 'h6'">
            {{ 'CATALOG_INFO_DIALOG.MESSAGE_LABEL' | translate | capitalise }}
          </div>
          <div class="field-value">
            <app-contact-user
              [recipientUserRole]="role"
              [recipientUserID]="userId.toString()"
              [recipientUserName]="name"
              [recipientUserContactEmail]="contactEmail"
            ></app-contact-user>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <div class="field">
          <div class="field-key" [ngClass]="isMobile ? 'h5' : 'h6'">
            {{ 'TASK_INFORMATION_BASE_DIALOG.RETAILER_NOTE' | translate | capitalise }}
          </div>
          <div class="field-value">
            <app-show-message-bubble-dialog-button
              descriptionTextKey="RETAILERS_LIST.MESSAGE_DIALOG.TITLE"
              [message]="message"
            ></app-show-message-bubble-dialog-button>
          </div>
        </div>
        <app-custom-divider></app-custom-divider>
        <ng-content></ng-content>
      </div>
      <ng-container [ngTemplateOutlet]="actionBarRef"></ng-container>
    </div>
  </ng-container>
</app-custom-dialog-wrapper>

<ng-template #dialogTitleRef>
  <div [ngClass]="isMobile ? 'h4' : 'h3'">{{ (role ? configData[role].titleLabel : defaultTitle) | translate }}</div>
</ng-template>
