import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BadgeColor } from './marketplace-badge.directive';

@Directive({
  selector: '[platformBadge]',
  standalone: true,
})
export class PlatformBadgeDirective implements OnChanges {
  @Input() name: string;
  colors: BadgeColor;
  @HostBinding('style.background-color') get backgroundColor(): string {
    return this.colors.background;
  }
  @HostBinding('style.color') get getColor(): string {
    return this.colors.foreground;
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.colors = this.getBadgeColors();
  }

  private getBadgeColors(): BadgeColor {
    switch (this.name) {
      case 'PLATFORM': {
        return { background: 'var(--app-syncee-primary-50)', foreground: 'var(--app-syncee-primary-700)' };
      }
      case 'APP': {
        return { background: 'var(--app-syncee-primary-50)', foreground: 'var(--app-syncee-primary-700)' };
      }
      case 'INSTALLED': {
        return { background: 'var(--app-light-green-100)', foreground: 'var(--app-light-green-700)' };
      }
      case 'CONNECTED': {
        return { background: 'var(--app-light-green-100)', foreground: 'var(--app-light-green-700)' };
      }
      default: {
        return { background: 'var(--app-syncee-primary-50)', foreground: 'var(--app-syncee-primary-700)' };
      }
    }
  }
}
