<div
  class="typography-body-1 cursor-pointer"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
  (click)="openLanguageAndCurrencyChangeDialog()"
>
  <div fxLayoutAlign="center center" fxLayoutGap="8px">
    <mat-icon class="selector-icon s-20">language</mat-icon>
    <div>
      {{
        isCurrencyActive ? ('LANGUAGE_AND_CURRENCY_SELECTOR.PLACEHOLDER' | translate) : (currentLanguage$ | async).name
      }}
    </div>
  </div>
  <mat-icon class="selector-icon s-20" [ngClass]="menuOpen ? 'language-selector-open' : 'language-selector-closed'">
    keyboard_arrow_up
  </mat-icon>
</div>
