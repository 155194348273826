import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Constants } from '../../../../utils/Constants';
import { NgForm } from '@angular/forms';
import { FileUploadResponseVo } from '../vo/file-upload-response-vo';
import { SharedUrlUploadInputVo } from '../vo/shared-url-upload-input-vo';
import { UrlUploadVo } from '../vo/url-upload-vo';
import { FileDownloadService } from '../../../../service/taskwizard/file-download.service';
import { Utils } from 'app/utils/utils';
@Component({
  selector: 'app-url-shared',
  templateUrl: './url-shared.component.html',
  styleUrls: ['./url-shared.component.scss'],
})
export class UrlSharedComponent implements OnInit {
  private _inputs;

  @ViewChild('urlDownloadFileForm', { static: true }) urlDownloadForm: NgForm;
  @Output() callBack = new EventEmitter<FileUploadResponseVo>();
  @Output() downloadFileActive = new EventEmitter<boolean>();
  @Output() selectedTypeCallBack = new EventEmitter<string>();

  @Input() set inputs(value: SharedUrlUploadInputVo) {
    this._inputs = value;
    this.init();
  }

  get inputs(): SharedUrlUploadInputVo {
    return this._inputs;
  }

  urlUploadParams: UrlUploadVo;
  disableDownloadBtn = false;

  disableFileTypeSelector = false;
  fileExtensions: any[] = Constants.UPLOAD_FILE_EXTENSIONS;

  constructor(private fileUploadService: FileDownloadService) {}

  ngOnInit(): void {}

  init(): void {
    this.urlUploadParams = new UrlUploadVo();
    this.urlUploadParams.extension = !Utils.isNullOrUndefined(this.inputs.selectedType)
      ? this.inputs.selectedType
      : Constants.EXTENSION_AUTO;
    this.urlUploadParams.remotePath = !Utils.isNullOrUndefined(this.inputs.remotePath) ? this.inputs.remotePath : '';
    this.urlUploadParams.fileId = !Utils.isNullOrUndefined(this.inputs.fileId) ? this.inputs.fileId : null;
    this.urlUploadParams.urlSourceType = !Utils.isNullOrUndefined(this.inputs.urlSourceType)
      ? this.inputs.urlSourceType
      : 'URL_GD';
  }

  typeChanged(event: any): void {
    this.selectedTypeCallBack.emit(event);
  }

  downloadFile(): void {
    if (this.urlDownloadForm.valid) {
      this.urlUploadParams.remotePath = this.urlUploadParams.remotePath.trim();
      this.urlUploadParams.taskId = this.inputs.taskId;
      this.urlUploadParams.fileId = this.inputs.fileId;
      this.disableDownloadBtn = true;
      this.downloadFileActive.emit(true);
      this.fileUploadService.urlDownload(this.inputs.restUrl, this.urlUploadParams).subscribe(
        (resData) => {
          const response = new FileUploadResponseVo();
          response.status = true;
          response.fileId = resData.fileId;
          response.remotePath = resData.remotePath;
          response.detectedType = resData.extension;
          this.urlUploadParams.fileId = resData.fileId;
          this.urlUploadParams.extension = resData.extension;
          this.disableFileTypeSelector = false;
          this.disableDownloadBtn = false;
          this.downloadFileActive.emit(false);
          this.callBack.emit(response);
        },
        (error) => {
          this.disableDownloadBtn = false;
          this.downloadFileActive.emit(false);
        }
      );
    }
  }
}
