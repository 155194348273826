<app-custom-dialog-wrapper [hideCloseBtnTablet]="false" [hideCloseBtnDesktop]="true">
  <app-authentication-layout>
    <app-authentication-dialog-banner [textList]="bannerTextList" banner-side></app-authentication-dialog-banner>
    <app-forgot-password-form
      [type]="data.type"
      *ngIf="data.step === FORGOTTEN_PASSWORD_STEP"
      content-side
    ></app-forgot-password-form>
    <app-reset-password-form
      [type]="data.type"
      *ngIf="data.step === RESET_PASSWORD_STEP"
      content-side
    ></app-reset-password-form>
  </app-authentication-layout>
</app-custom-dialog-wrapper>
