import { Observable } from 'rxjs';
import { map, scan } from 'rxjs/operators';

export function withPreviousValue<T>(): (source: Observable<T>) => Observable<PreviousAndCurrentValue<T>> {
  return (source): Observable<PreviousAndCurrentValue<T>> =>
    source.pipe(
      scan(
        (accumulator: T[], currentValue: T) => {
          const previousValue = accumulator[accumulator.length - 1];
          return [previousValue, currentValue];
        },
        [null]
      ),
      map(([previous, current]) => ({ previous: previous, current: current }))
    );
}

export interface PreviousAndCurrentValue<T> {
  previous: T;
  current: T;
}
