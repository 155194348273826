import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[imageFallback]',
  standalone: true,
})
export class ImageFallbackDirective {
  @Input() imageUrl: string;

  constructor(private ref: ElementRef) {}

  @HostListener('error')
  private changeFallbackOnError(): void {
    const el: HTMLImageElement = <HTMLImageElement>this.ref.nativeElement;
    el.src = this.imageUrl || 'assets/images/placeholder-image.png';
  }
}
