import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ImageFallbackDirective } from 'app/shared/directives/image-fallback.directive';
import { RouterLink } from '@angular/router';
import { NavDirection } from '../custom-thumbnail/custom-thumbnail.component';
import { ProductImageFullCdnPipe } from '../../pipes/product-image-resizer-cdn.pipe';

@Component({
  selector: 'app-product-image-selector',
  standalone: true,
  templateUrl: './product-image-selector.component.html',
  styleUrls: ['./product-image-selector.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    FlexModule,
    SlickCarouselModule,
    ImageFallbackDirective,
    RouterLink,
    NgOptimizedImage,
    ProductImageFullCdnPipe,
  ],
})
export class ProductImageSelectorComponent implements OnInit {
  @ViewChild('slickModal') slick: SlickCarouselComponent;
  @Input() images: string[] = [];
  @Input() altForImages: string;
  @Input() productId: string | number;

  @Input() navFor: string;

  @Output() indexChange = new EventEmitter<number>();
  env = environment;
  prevDisabled = true;
  nextDisabled = false;

  @Input() config: JQuerySlickOptions = {
    infinite: false,
    arrows: false,
    speed: 200,
  };
  isLoading = true;

  constructor() {}

  ngOnInit(): void {}

  handleImageChanged(data): void {
    this.nextDisabled = data.nextSlide === this.images.length - 1;
    this.prevDisabled = data.nextSlide === 0;
    this.indexChange.emit(data.nextSlide);
  }

  public handleNavigation(direction: NavDirection): void {
    if (direction === 'prev') {
      this.slick.slickPrev();
    } else if (direction === 'next') {
      this.slick.slickNext();
    }
  }
}
