import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { GetResourceFromStoragePipe } from '../../../shared/pipes/get-resource-from-storage';
import { FlexModule } from '@angular/flex-layout';
import { MarketplaceBadgeDirective } from '../../../shared/directives/marketplace-badge.directive';
import { MarketplaceBadgesComponent } from '../../../shared/components/marketplace-badges/marketplace-badges.component';
import { MatCardModule } from '@angular/material/card';
import { ReviewComponent } from '../../../shared/components/review/review.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NoSearchResultsComponent } from '../../../shared/components/no-search-results/no-search-results.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CountryNamesPipe } from '../../../shared/pipes/shipping-countries.pipe';
import { ErrorMessageComponent } from '../../../shared/components/error-message/error-message.component';
import { SupplierCardListComponent } from '../../../shared/components/supplier-card-list/supplier-card-list.component';
import { SupplierNoResultListComponent } from './supplier-no-result-list/supplier-no-result-list.component';
import { SkeletonModule } from 'app/shared/components/skeleton/skeleton.module';
import { CurrencyExchangePipe } from 'app/shared/pipes/legacy/currency-exchange.pipe';
import { PaginatorLoadMoreComponent } from 'app/shared/components/paginator-load-more/paginator-load-more.component';
import { LazyloadProductImageDirective } from 'app/shared/directives/lazyload-product-image.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SupplierListComponent, SupplierNoResultListComponent],
  imports: [
    CommonModule,
    GetResourceFromStoragePipe,
    FlexModule,
    MarketplaceBadgeDirective,
    MarketplaceBadgesComponent,
    MatCardModule,
    ReviewComponent,
    MatIconModule,
    MatListModule,
    NoSearchResultsComponent,
    MatProgressSpinnerModule,
    CountryNamesPipe,
    ErrorMessageComponent,
    SupplierCardListComponent,
    SkeletonModule,
    CurrencyExchangePipe,
    PaginatorLoadMoreComponent,
    LazyloadProductImageDirective,
    TranslateModule,
  ],
  exports: [SupplierListComponent],
})
export class SupplierListModule {}
