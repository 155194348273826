import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-categories-by-preference',
  templateUrl: './categories-by-preference.component.html',
  styleUrls: ['./categories-by-preference.component.scss'],
})
export class CategoriesByPreferenceComponent {
  @Input() title: string;

  @Input() titlePosition: 'start' | 'center';

  @Input() description: string;

  @Input() wrapperClass: string;

  @Input() innerWrapperClass: string;

  @Input() background: string;

  constructor() {}
}
