<app-filter-selector-base
  [title]="'SUPPLIER'"
  [searchLabel]="'SUPPLIER'"
  [value]="value"
  [multiple]="true"
  (valueChange)="handleSelectionChange($event)"
  [options]="displayedOptions"
  [searchTerm]="searchTerm"
  (searchTermChange)="handleSearchTermChange($event)"
  (loadMore)="loadMore()"
  [totalCount]="totalNumber"
  [lazyLoad]="true"
  [hasSearch]="true"
  (seeMore)="onSeeMore()"
  (seeLess)="onSeeLess()"
  [hasSeeMore]="hasSeeMore"
  [noOptionsAvailable]="noOptionsAvailable"
  [needsTranslation]="false"
  [hasClearAll]="hasClearAll"
  (clearAll)="onClearAll()"
  [overlayOpen]="overlayOpen"
></app-filter-selector-base>
