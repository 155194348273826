import { StepStateIconRecord } from '../model/step-data.model';

export const STEP_STATE_ICON_CONFIG: StepStateIconRecord = {
  default: {
    stepState: 'default',
    icon: 'radio_button_unchecked',
    filled: false,
  },
  current: {
    stepState: 'current',
    icon: 'radio_button_checked',
    filled: false,
  },
  complete: {
    stepState: 'complete',
    icon: 'check_circle',
    filled: true,
    color: 'var(--app-syncee-primary-700)',
  },
};
