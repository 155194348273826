import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-chat-message-model-avatar',
  templateUrl: './ai-chat-message-model-avatar.component.html',
  styleUrls: ['./ai-chat-message-model-avatar.component.scss'],
})
export class AiChatMessageModelAvatarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
