import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, filter, first, map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class PaypalOnboardService {
  public static GET_SIGN_UP_LINK = 'PaypalOnboardService/onboard';
  public static SAVE_ONBOARD = 'PaypalOnboardService/CreatePayPalConnect';
  public static SUBMIT_ONBOARD = 'PaypalOnboardService/TrackOnboardStatus';
  public static GET_DELETED_PAYPAL_EMAIL = 'SynceePaymentService/getDeletedPaypalConnects';
  public static REACTIVE_PAYPAL_CONNECT = 'SynceePaymentService/reActivatePaypalAccount';
  public static CAPTURE_MOCK = 'PaypalOnboardService/Capture';

  constructor(private restService: RestService) {}

  getSignUpLink(returnUrl: string): Observable<Link> {
    return this.restService.post(PaypalOnboardService.GET_SIGN_UP_LINK, { returnUrl }).pipe(
      map((resp) => {
        return from(resp.getData());
      }),
      concatMap((link) => link),
      filter((link) => link.rel === 'action_url'),
      first()
    );
  }

  getDeletedPaypalEmails(version: 'v1' | 'v2' = 'v2'): Observable<any> {
    return this.restService
      .get(PaypalOnboardService.GET_DELETED_PAYPAL_EMAIL, { version })
      .pipe(map((response) => response.getData()));
  }

  saveOnboard(merchantId: number, merchantIdInPayPal: string): Observable<void> {
    return this.restService
      .post(PaypalOnboardService.SAVE_ONBOARD, { merchantId, merchantIdInPayPal })
      .pipe(map((_) => void 0));
  }

  reActivePaypalConnect(connectId: number): Observable<any> {
    return this.restService
      .post(PaypalOnboardService.REACTIVE_PAYPAL_CONNECT, { connectId })
      .pipe(map((response) => response.getData()));
  }

  capture(orderId: string, mockResponse: string = null): Observable<void> {
    let params = { orderId };
    if (mockResponse) {
      params = { ...{ mockResponse }, ...params };
    }
    return this.restService.post(PaypalOnboardService.CAPTURE_MOCK, params).pipe(map((response) => void 0));
  }

  submit(): Observable<TrackOnboardStatus> {
    // return Observable.of(<TrackOnboardStatus>{ paymentsReceivable: false, primaryEmailConfirmed: true });
    return this.restService.put(PaypalOnboardService.SUBMIT_ONBOARD, {}).pipe(map((resp) => resp.getFirstData()));
  }
}

interface PayPalOnboardSignUpResponse {
  links: Link[];
}

interface Link {
  href: string;
  rel: string;
  method: string;
  description: string;
}

export interface TrackOnboardStatus {
  primaryEmailConfirmed: boolean;
  paymentsReceivable: boolean;
}
