import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  SupplierDashboardAnalyticsService,
  TopRetailerDto,
} from '../../../service/analytics/supplier-dashboard-analytics.service';
import { SpringPageable } from '../../../vo/pagination/spring-pageable';
import { SupplierDashboardService } from '../services/supplier-dashboard.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-top-retailers',
  templateUrl: './top-retailers.component.html',
  styleUrls: ['./top-retailers.component.scss'],
})
export class TopRetailersComponent implements OnInit, OnChanges {
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  dataSource: MatTableDataSource<TopRetailerDto>;
  columns = ['retailer', 'orders', 'products-sold', 'revenue'];
  pageable: SpringPageable = { page: 0, size: 3, sort: 'productsSold,desc' };

  protected paymentCurrency: string;

  constructor(
    private supplierDashboardAnalyticsService: SupplierDashboardAnalyticsService,
    private _supplierDashboardService: SupplierDashboardService
  ) {}

  ngOnInit(): void {
    this.initPaymentCurrency();
    this.setDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.setDataSource();
    }
  }

  private initPaymentCurrency(): void {
    this._supplierDashboardService
      .getSupplierPaymentCurrency()
      .pipe(take(1))
      .subscribe((paymentCurrency: string): void => {
        this.paymentCurrency = paymentCurrency;
      });
  }

  setDataSource(): void {
    this.supplierDashboardAnalyticsService
      .getTopRetailers(this.dateFrom, this.dateTo, this.pageable)
      .subscribe((topRetailers) => {
        this.dataSource = new MatTableDataSource<TopRetailerDto>(topRetailers);
      });
  }
}
