import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { MySuppliersPageService } from '../service/my-suppliers-page.service';
import { NotificationService } from 'app/main/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../../service/navigation/navigation.service';
import { MatTableDataSource } from '@angular/material/table';
import { RolesEnum } from 'app/vo/roles/roles';
import { RetailerToCatalog } from '../model/retailer-to-catalog';
import { MySuppliersCacheService } from '../service/my-suppliers-cache.service';
import { SupplierWithBadgesAndAutoOrder } from 'app/vo/supplier/supplier-with-badges-and-auto-order';
import { MY_SUPPLIERS_TABLE_FILTER, MY_SUPPLIERS_TABLE_VIEWS } from './constant/my-suppliers-table-filter';
import {
  IndexTableFilter,
  TableFilterItem,
  TableViewItem,
} from '../../../../shared/components/filter-factory/filter-factory.component';
import { MySuppliersDialogService } from '../service/my-suppliers-dialog.service';
import { ApproveStatusParamEnum } from '../model/approve-status-param';
import { GetSupplierFromCacheByCatalogIdPipe } from '../pipe/get-supplier-from-cache-by-catalog-id.pipe';
import { GetTaskFromCacheByIdPipe } from '../pipe/get-task-from-cache-by-id.pipe';
import { GetManagedProductNumberFromCacheByCatalogIdPipe } from '../pipe/get-managed-product-number-from-cache-by-catalog-id.pipe';
import { GetOrderInfoFromCacheByCatalogIdPipe } from '../pipe/get-order-info-from-cache-by-catalog-id.pipe';
import { Observable } from 'rxjs';
import { MySuppliersCache } from '../model/my-suppliers-cache';

@Component({
  selector: 'app-my-suppliers-table',
  templateUrl: './my-suppliers-table.component.html',
  styleUrls: ['./my-suppliers-table.component.scss'],
  providers: [
    GetSupplierFromCacheByCatalogIdPipe,
    GetTaskFromCacheByIdPipe,
    GetManagedProductNumberFromCacheByCatalogIdPipe,
    GetOrderInfoFromCacheByCatalogIdPipe,
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    fuseAnimations,
  ],
})
export class MySuppliersTableComponent implements OnChanges {
  @Input() content: RetailerToCatalog[];

  @Input() hasAccessToContacts: boolean;

  @Input() noResultComponent: TemplateRef<HTMLElement>;

  @Input() page = 0;

  @Input() size = 10;

  @Input() allItemsCount = 0;

  @Input() loading: boolean;

  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() approvalFilterChange = new EventEmitter<ApproveStatusParamEnum>();

  @Output() nextClicked = new EventEmitter<void>();

  @Output() previousClicked = new EventEmitter<void>();

  @Output() sizeChanged = new EventEmitter<number>();

  public mySuppliersDataSource: MatTableDataSource<RetailerToCatalog>;

  public displayedColumns: string[] = ['supplier', 'catalog', 'message', 'products', 'orders', 'status'];

  public RolesEnum = RolesEnum;

  public tableFilterArray: IndexTableFilter<ApproveStatusParamEnum>[] = MY_SUPPLIERS_TABLE_FILTER;
  public tableViewArray: TableViewItem[] = MY_SUPPLIERS_TABLE_VIEWS;

  public activeFilters: TableFilterItem[] = [];

  public cache$: Observable<MySuppliersCache>;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    public pageService: MySuppliersPageService,
    public mySuppliersCacheService: MySuppliersCacheService,
    private mySuppliersDialogService: MySuppliersDialogService
  ) {
    this.cache$ = this.mySuppliersCacheService.cache;
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.mySuppliersDataSource = new MatTableDataSource(this.content);
  }

  public handleCatalogUnavailable(): void {
    const message: string = this.translateService.instant('MY_SUPPLIERS.TOASTS.CATALOG_UNAVAILABLE');

    this.notificationService.warning(message);
  }

  public openCatalogInformationDialog(
    supplier: SupplierWithBadgesAndAutoOrder,
    catalog: RetailerToCatalog,
    taskName: string
  ): void {
    this.mySuppliersDialogService.openCatalogInformationDialog({
      retailerToCatalog: catalog,
      supplierInfo: supplier,
      taskName: taskName,
    });
  }

  public handleFilterChange(item: TableFilterItem): void {
    this.activeFilters = [item];
    this.approvalFilterChange.emit(item.value);
  }

  handleViewChange(e: TableViewItem): void {
    this.activeFilters = [];
    e?.filters?.map((filterItem) => {
      this.handleFilterChange(filterItem);
    });
  }

  public clearFilter(): void {
    this.activeFilters = [];
    this.approvalFilterChange.emit(null);
  }
}
