import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Widget } from 'app/vo/widget';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { WidgetTranslatePipe } from '../../pipes/widget-translate.pipe';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';

@Component({
  selector: 'app-brands-slider',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    SlickCarouselModule,
    WidgetTranslatePipe,
    SectionTitleComponent,
  ],
  templateUrl: './brands-slider.component.html',
  styleUrls: ['./brands-slider.component.scss'],
  animations: [fuseAnimations],
})
export class BrandsSliderComponent implements OnInit, OnDestroy {
  @Input() titleKey: string;
  @Input() widgetContent: Widget[];
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  @Output() brandClicked = new EventEmitter<Widget>();
  preventClick: boolean;

  config: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: BreakPoint.lg,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  private _unsubscribeAll: Subject<void>;
  private timeoutId: number | NodeJS.Timeout = undefined;

  constructor(private screenManagerService: ScreenManagerService, private ngZone: NgZone) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  emitBrandClicked(brand: Widget): void {
    if (!this.preventClick) {
      this.brandClicked.emit(brand);
    }
  }

  handleAfterChange(): void {
    clearTimeout(this.timeoutId);
    this.preventClick = false;
  }

  handleBeforeChange(): void {
    clearTimeout(this.timeoutId);
    this.preventClick = true;
    this.timeoutId = setTimeout(() => {
      this.preventClick = false;
    }, 500);
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.sm)
      .pipe(takeUntil(this._unsubscribeAll), this.screenManagerService.stateMatchesOperator())
      .subscribe((matched) => {
        this.ngZone.run(() => {
          this.config.dots = matched;
        });
      });
  }
}
