export type ROLE_TYPE = RolesEnum.SUPPLIER | RolesEnum.RETAILER | RolesEnum.ADMIN;

export type ROLE_TYPE_ADMIN_EXCLUDED = RolesEnum.RETAILER | RolesEnum.SUPPLIER;

export enum RolesEnum {
  SUPPLIER = 1,
  RETAILER = 2,
  ADMIN = 3,
}

export enum RoleTypesEnum {
  SUPPLIER = 'supplier',
  RETAILER = 'retailer',
  ADMIN = 'admin',
}

export const NUM_ROLE_MAP_TO_STR = {
  1: RoleTypesEnum.SUPPLIER,
  2: RoleTypesEnum.RETAILER,
  3: RoleTypesEnum.ADMIN,
};
