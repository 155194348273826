import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { transformToCdnUrl } from 'app/utils/transform-to-cdn-url';
import { Utils } from 'app/utils/utils';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { toNumber } from 'lodash';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  private renderer: Renderer2;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  protected readonly DEFAULT_TITLE = 'Syncee Collective Dropshipping & Wholesale';
  protected readonly DEFAULT_DESCRIPTION =
    "Syncee is a premium dropshipping and wholesale marketplace where online stores from multiple ecommerce platforms connect to sell each other's products.";
  protected readonly BASE_HREF = 'https://syncee.com/';

  addTags(title: string, description: string, imageURL: string, href: string, type: TruncateType): void {
    const _title = this.truncateTitle(title, type);
    const _description = this.truncateDescription(description);

    this.titleService.setTitle(_title);

    this.metaService.addTags([
      { name: 'description', content: _description },
      { property: 'og:title', content: _title },
      { property: 'og:description', content: _description },
      { property: 'og:image', content: imageURL },
      { property: 'og:url', content: href },
    ]);
  }

  updateTags(
    title: string,
    description: string,
    imageURL: string,
    href: string,
    type: TruncateType,
    canonical: string
  ): void {
    const _title = this.truncateTitle(title, type);
    const _description = this.truncateDescription(description);

    this.titleService.setTitle(_title);

    this.metaService.updateTag({ name: 'description', content: _description });
    this.metaService.updateTag({ property: 'og:title', content: _title });
    this.metaService.updateTag({ property: 'og:description', content: _description });
    this.metaService.updateTag({ property: 'og:image', content: imageURL });
    this.metaService.updateTag({ property: 'og:url', content: href });
    const canonicalLink = this.document.querySelector('link[rel="canonical"]');
    this.renderer.setAttribute(canonicalLink, 'href', canonical);
  }

  resetToDefaults(): void {
    this.titleService.setTitle(this.DEFAULT_TITLE);

    this.metaService.updateTag({ name: 'description', content: this.DEFAULT_DESCRIPTION });
    this.metaService.updateTag({ property: 'og:title', content: this.DEFAULT_TITLE });
    this.metaService.updateTag({ property: 'og:description', content: this.DEFAULT_DESCRIPTION });
    this.metaService.updateTag({ property: 'og:image', content: SYNCEE_BANNER_DEFAULT_IMAGE });
    this.metaService.updateTag({ property: 'og:url', content: this.BASE_HREF });
    const canonicalLink = this.document.querySelector('link[rel="canonical"]');
    this.renderer.setAttribute(canonicalLink, 'href', this.BASE_HREF);
  }

  truncateDescription(description: string): string {
    let truncatedDescription = !Utils.isNullOrUndefined(description)
      ? this.normalizeText(description).substring(0, 300)
      : this.DEFAULT_DESCRIPTION;

    truncatedDescription = truncatedDescription.substring(0, truncatedDescription.lastIndexOf(' '));

    return `${truncatedDescription}`;
  }

  truncateTitle(title: string, type: TruncateType): string {
    if (type === TruncateType.PRODUCT) {
      let truncatedTitle = (title as string).replace(/[^\p{L}\p{N}\s]/gu, '');

      while (truncatedTitle.length > 42) {
        truncatedTitle = truncatedTitle.substring(0, truncatedTitle.lastIndexOf(' '));
      }

      return `Dropship ${truncatedTitle} - Syncee`;
    } else if (type === TruncateType.SUPPLIER) {
      let truncatedTitle = (title as string).replace(/[^\p{L}\p{N}\s]/gu, '');

      while (truncatedTitle.length > 38) {
        truncatedTitle = truncatedTitle.substring(0, truncatedTitle.lastIndexOf(' '));
      }

      return `${truncatedTitle} dropshipping - Syncee`;
    } else if (type === TruncateType.SYNCEE) {
      let truncatedTitle = title as string;

      while (truncatedTitle.length > 51) {
        truncatedTitle = truncatedTitle.substring(0, truncatedTitle.lastIndexOf(' '));
      }

      return `${truncatedTitle} - Syncee`;
    }
  }

  calculateProductRRP(product: SearchProductVO): number {
    const calculatedPrice =
      Utils.getPrice(product?.VARIANTS[0]?.PRICE, product?.VARIANTS[0]?.DISCOUNTED_PRICE) *
      ((toNumber(product.SETTINGS?.possibleMargin) + 100) / 100);
    return calculatedPrice;
  }

  normalizeText(value: string): string {
    return !Utils.isNullOrUndefined(value) ? value.replace(/<[^>]*>+/g, '').replace(/^\s*[\r\n]+/gm, '') : '';
  }
}

export enum TruncateType {
  SUPPLIER = 'supplier',
  PRODUCT = 'product',
  SYNCEE = 'syncee',
}

export const SYNCEE_FULL_LOGO_URL = transformToCdnUrl('logos/syncee-logo.svg');
export const SYNCEE_BAG_LOGO_URL = transformToCdnUrl('logos/syncee-bag-logo-with-bg.png');
export const SYNCEE_BANNER_DEFAULT_IMAGE = 'https://syncee.com/assets/images/syncee-banner.png';
