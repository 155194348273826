import { Component, Input } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import type { ProductCardWidths } from '../../product-card-list/product-card-list.component';

@Component({
  selector: 'app-skeleton-supplier-card-list',
  templateUrl: './skeleton-supplier-card-list.component.html',
  styleUrls: ['./skeleton-supplier-card-list.component.scss'],
  animations: [fuseAnimations],
})
export class SkeletonSupplierCardListComponent {
  @Input() numberOfSuppliers: number;

  @Input() needsFillers = false;
}
