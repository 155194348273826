import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';

import { NotificationsListComponent } from 'app/layout/components/toolbar/components/notifications/notifications-list/notifications-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationIconComponent } from './notification-icon/notification-icon.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { NotificationOverlayComponent } from './notification-overlay/notification-overlay.component';
import { NotificationOverlayActionBarComponent } from './notification-overlay/notification-overlay-action-bar/notification-overlay-action-bar.component';
import { NotificationItemComponent } from './notifications-list/notification-item/notification-item.component';
import { BadgeDirective } from '../../../../../shared/directives/badge.directive';
import { OverlayResponsiveWrapperComponent } from '../../../../../shared/components/overlay-responsive-wrapper/overlay-responsive-wrapper.component';
import { OverflowDirective } from 'app/shared/directives/overflow.directive';

@NgModule({
  declarations: [
    NotificationsListComponent,
    NotificationIconComponent,
    NotificationOverlayComponent,
    NotificationOverlayActionBarComponent,
    NotificationItemComponent,
  ],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslateModule,
    FuseSharedModule,
    OverlayModule,
    MatButtonModule,
    BadgeDirective,
    OverlayResponsiveWrapperComponent,
    OverflowDirective,
  ],
  exports: [NotificationsListComponent, NotificationIconComponent],
})
export class NotificationsModule {}
