import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SupplierWithBadgesAndAutoOrder } from '../../../../vo/supplier/supplier-with-badges-and-auto-order';
import { MySuppliersCacheService } from '../service/my-suppliers-cache.service';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
@Pipe({
  name: 'getSupplierFromCacheByCatalogId',
})
export class GetSupplierFromCacheByCatalogIdPipe implements PipeTransform {
  constructor(private cacheService: MySuppliersCacheService) {}
  transform(catalogId: number): Observable<SupplierWithBadgesAndAutoOrder> {
    return this.cacheService
      .getCatalogFromCache(catalogId)
      .pipe(switchMap(({ userId }) => this.cacheService.getSupplierFromCache(userId)));
  }
}
