<!-- if ADD ask for chart skeleton just do it, cuz I wont. -->
<mat-card>
  <div fxLayout="column" fxLayoutGap="12px">
    <h4 class="mt-0 no-wrap">{{ 'DASHBOARD.SALES_OVERVIEW' | translate }}</h4>

    <div fxLayout="column" fxLayoutGap="30px">
      <app-skeleton-text [lines]="4"></app-skeleton-text>
      <app-skeleton-text [lines]="4"></app-skeleton-text>
      <app-skeleton-text [lines]="4"></app-skeleton-text>
      <app-skeleton-text [lines]="4"></app-skeleton-text>
    </div>
  </div>
</mat-card>
