import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { transformToCdnUrl } from '../../utils/transform-to-cdn-url';

@Directive({
  selector: '[productImageErrorHandler]',
  standalone: true,
})
export class ProductImageErrorHandlerDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('productImageErrorHandler') url: string;
  @Input() checkedOriginal = false;
  private readonly FALLBACK_URL = transformToCdnUrl(`placeholder-images/product_card_placeholder.webp`);

  constructor(private el: ElementRef<HTMLImageElement>) {}

  @HostListener('error')
  private onError(): void {
    if (this.checkedOriginal) {
      this.el.nativeElement.src = this.FALLBACK_URL;
    } else {
      this.el.nativeElement.src = this.getOriginalCachedUrl();
      this.checkedOriginal = true;
    }
  }

  private getOriginalCachedUrl(): string {
    return environment.synceeImgURL + '/cdn_image?url=' + encodeURIComponent(this.url);
  }
}
