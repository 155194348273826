import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HasCartService {
  hasCart: boolean;
  env = environment;

  constructor() {
    this.hasCart = this.getHasCart();
  }

  private getHasCart(): boolean {
    return this.env.hasCart;
  }
}
