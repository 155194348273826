import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { AuthorizationEcomService } from 'app/service/authorization-ecom/authorization-ecom.service';
import { RolesEnum } from '../../vo/roles/roles';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { hasRolesSelector } from '../../store/user/user.selector';
import { RoleDialogService } from '../../service/guards/role-dialog.service';

@Directive({
  selector: '[roleOpenRegistration]',
  standalone: true,
})
export class RoleOpenRegistrationDirective implements OnDestroy, AfterViewInit {
  @Input() titleTranslationKey = 'STOREFRONT.GUEST.GENERAL_REGISTRATION_REDIRECT.TITLE';
  @Input() descriptionTranslationKey = 'STOREFRONT.GUEST.GENERAL_REGISTRATION_REDIRECT.DESCRIPTION';
  @Input() role = RolesEnum.RETAILER;
  private unsubscribeAll: Subject<void>;
  private subscription: Subscription;

  constructor(
    private element: ElementRef<HTMLButtonElement>,
    private authManagerService: AuthenticationManagerService,
    private authorizationService: AuthorizationEcomService,
    private store: Store<AppState>,
    private roleDialogService: RoleDialogService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngAfterViewInit(): void {
    this.createSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private openRegistration(): void {
    this.authManagerService.emitOpenRegistrationDialog(
      this.role === RolesEnum.RETAILER ? AuthDialogType.RETAILER : AuthDialogType.SUPPLIER,
      undefined,
      this.titleTranslationKey,
      this.descriptionTranslationKey
    );
  }

  private openRoleChange(): void {
    this.roleDialogService.handleCenterNavigationAttempt(this.role);
  }

  private createSubscription(): void {
    if (!this.subscription) {
      this.subscription = combineLatest([
        fromEvent(this.element.nativeElement, 'click', { capture: true }),
        this.authorizationService.isAuthenticatedUserOrNot(),
        this.store.select(hasRolesSelector, this.role),
      ])
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(([event, isAuth, hasRole]) => {
          if (!isAuth) {
            event.stopPropagation();
            this.openRegistration();
          } else if (!hasRole) {
            event.stopPropagation();
            this.openRoleChange();
          }
        });
    }
  }
}
