import { ChipColor } from '../../../droppable-input/chip';

export class ProdSampleInputVo {
  private _fileName: string;
  private _taskId: number;
  private _extension: string;
  private _fileId: number;
  private _chipColor: ChipColor;
  private _retry: boolean;

  get fileName(): string {
    return this._fileName;
  }

  set fileName(value: string) {
    this._fileName = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get chipColor(): ChipColor {
    return this._chipColor;
  }

  set chipColor(value: ChipColor) {
    this._chipColor = value;
  }

  get retry(): boolean {
    return this._retry;
  }

  set retry(value: boolean) {
    this._retry = value;
  }

  get extension(): string {
    return this._extension;
  }

  set extension(value: string) {
    this._extension = value;
  }
}
