<app-overlay-responsive-wrapper [overlayWidth]="550">
  <button slot="trigger" mat-icon-button cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="isOpen = !isOpen">
    <mat-icon
      [badge]="
        !notificationsService.unseen || notificationsService.unseen === 0
          ? null
          : notificationsService.unseen.toString()
      "
      badgePosition="top-right"
      badgeVariant="without-number"
      >notifications
    </mat-icon>
  </button>
  <ng-container slot="overlay-content">
    <app-notification-overlay (closeOverlay)="isOpen = false"></app-notification-overlay>
  </ng-container>
</app-overlay-responsive-wrapper>
