/* Új onboard event esetén ide kell felvinni az új step event-et, ez jelenik meg a KIOSK oldalon.
 *  Az új evented be kell illeszteni a megfelelő event-hez:
 *  this.onboardService.addCompletedStep(this.addTaskCompletedStep);
 *  A kiosk-on hozzáadott új step ID-ját lementi az adatbázisban
 * */
export const OnBoardEventsHandle = [
  // Search products
  'searchItem',
  'productAdded',
  'openMyCatalog',
  'searchProductsSynced',

  // Find suppliers
  'findSuppliersSearchItem',
  'findSuppliersOpenSupplierProducts',
  'findSuppliersUseSupplier',
  'findSuppliersSynced',

  // Retailer my catalogs
  'openCatalog',
  'catalogSettings',
  'categoryMapping',
  'setPricing',
  'runCatalog',

  // retailer dataFeed
  'addNewTaskRetailerDF',
  'addFileDoMappingRetailerDF',
  'addFilterRetailerDF',
  'addPricingRetailerDF',
  'addCategoriesRetailerDF',
  'addSettingsRetailerDF',
  'addSummaryRetailerDF',
  'dfUploadSynced',

  // retailer export
  'exportAddNewTask',
  'exportMapping',
  'exportFilter',
  'exportPricing',
  'exportSettings',
  'dfExportSynced',

  // retailer VU
  'vuAddNewTask',
  'vuMapping',
  'vuPricing',
  'vuSettings',
  'vuSummary',
  'dfVariantsSynced',

  // Supplier datafeed
  'addNewTaskSupplierDF',
  'addFileDoMappingSupplierDF',
  'addFilterSupplierDF',
  'addCatalogSettingsSupplierDF',
  'addCategoriesSupplierDF',
  'addPricingSupplierDF',
  'addApprovedSupplierCatalogDF',

  // supplier manual
  'addNewManualProductSupplier',
  'addFillYourFirstManualProductDataSupplier',
  'addManualProductsSetttingsSupplier',
  'addApprovedSupplierManualProducts',

  // Alibaba
  'connectedAlibabaAccountAlibaba',
  'goToImportListAlibaba',
  'addProductToImportListAlibaba',
  'addProductToCatalogAlibaba',
  'goToAlibabaCatalogList',
  'runCatalogAlibaba',
];

/* Onboard típusok, a Getting started végén ezek közül fog választani a user,
 * ez mentődik le adatbázisban és
 * ez alapján jelenítjük meg a quick guide-ban a tartalmakat
 * Az új típusok id-ja ne "retailer"-el vagy "supplier"-el kezdődjön, mert akkor a getting started-ba is bele kell tenni
 * */

export const OnBoardTypes = [
  {
    id: 'retailerSearchProducts',
    name: 'ONBOARD.TYPES.RETAILER_SEARCH_PRODUCTS',
    order: 0,
    type: 'retailer_marketplace',
  },
  {
    id: 'retailerFindSuppliers',
    name: 'ONBOARD.TYPES.RETAILER_FIND_SUPPLIERS',
    order: 1,
    type: 'retailer_marketplace',
  },
  {
    id: 'retailerDF',
    name: 'ONBOARD.TYPES.RETAILER_DATAFEED',
    order: 2,
    type: 'retailer_datafeed',
  },
  {
    id: 'catalogListing',
    name: 'ONBOARD.TYPES.CATALOG',
    order: 0,
    type: 'retailer_marketplace',
  },
  {
    id: 'retailerExport',
    name: 'ONBOARD.TYPES.RETAILER_EXPORT',
    order: 4,
    type: 'retailer_datafeed',
  },
  {
    id: 'retailerVariants',
    name: 'ONBOARD.TYPES.RETAILER_VARIANTS_UPDATE',
    order: 3,
    type: 'retailer_datafeed',
  },
  {
    id: 'supplierExportFromStore',
    name: 'ONBOARD.TYPES.SUPPLIER_EXPORT_FROM_STORE',
    order: 0,
    type: 'supplier',
  },
  {
    id: 'supplierDataFeedManager',
    name: 'ONBOARD.TYPES.SUPPLIER_DATAFEED_MANAGER',
    order: 0,
    type: 'supplier',
  },
  {
    id: 'supplierManualProductsAdded',
    name: 'ONBOARD.TYPES.SUPPLIER_MANUAL_PRODUCT',
    order: 0,
    type: 'supplier',
  },
  {
    id: 'retailerAlibaba',
    name: 'Alibaba',
    order: 0,
    type: 'retailer_marketplace',
  },
];
