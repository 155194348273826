import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '../../../main/notification/notification.service';
import { MatIconModule } from '@angular/material/icon';
import { SanitizeResourceUrlPipe } from '../../pipes/sanitize-resource-url.pipe';

@Component({
  selector: 'app-chip-list-selector',
  standalone: true,
  imports: [CommonModule, MatChipsModule, TranslateModule, MatIconModule, SanitizeResourceUrlPipe],
  templateUrl: './chip-list-selector.component.html',
  styleUrls: ['./chip-list-selector.component.scss'],
})
export class ChipListSelectorComponent implements OnInit {
  @Input() items: ChipListSelectorItem[] = [];
  @Input() selectionLimit: number;
  @Input() iconSize = 24;
  @Input() selectedItems: ChipListSelectorItem[] = [];
  @Output() selectedItemsChange = new EventEmitter<ChipListSelectorItem[]>();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {}

  private handleSelectedItemsChange(selectedItem: ChipListSelectorItem): ChipListSelectorItem[] {
    let newSelectedItems = [...this.selectedItems];
    if (newSelectedItems.some((item) => item.id === selectedItem.id)) {
      newSelectedItems = newSelectedItems.filter((item) => item.id !== selectedItem.id);
    } else {
      this.handleAddItemToSelection(newSelectedItems, selectedItem);
    }
    return newSelectedItems;
  }

  private handleAddItemToSelection(selectedItems: ChipListSelectorItem[], selectedItem: ChipListSelectorItem): void {
    if (!this.selectionLimit || selectedItems.length < this.selectionLimit) {
      selectedItems.push(selectedItem);
    } else {
      this.notificationService.warning(`You can select up to ${this.selectionLimit} items`);
    }
  }

  itemClicked(selectedItem: ChipListSelectorItem): void {
    this.selectedItemsChange.emit(this.handleSelectedItemsChange(selectedItem));
  }

  get selectedItemsIds(): (string | number)[] {
    return this.selectedItems.map((item) => item.id);
  }
}

export interface ChipListSelectorItem {
  id: string | number;
  title: string;
  icon?: ChipListSelectorItemIcon;
}

export interface ChipListSelectorItemIcon {
  icon: string;
  type: ChipListSelectorItemIconType;
}

export type ChipListSelectorItemIconType = 'svg' | 'material-icons' | 'url' | 'custom';
