<ng-template #header_left>
  <div class="h3">
    {{ data?.title | translateConditionally : config.translateTitle }}
  </div>
</ng-template>

<app-custom-dialog-wrapper [headerLeftRef]="header_left">
  <div class="data-listing-container" fxLayout="column" fxLayoutAlign="center start">
    <mat-dialog-content>
      <app-data-listing [items]="data?.items" [config]="config"></app-data-listing>
    </mat-dialog-content>
  </div>
</app-custom-dialog-wrapper>
