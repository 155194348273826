import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { RatingWithIconsComponent } from '../../../../shared/components/rating-with-icons/rating-with-icons.component';
import { CustomDialogWrapperComponent } from '../../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-select-rating',
  templateUrl: './select-rating.component.html',
  styleUrls: ['./select-rating.component.scss'],
  standalone: true,
  imports: [FlexModule, TranslateModule, RatingWithIconsComponent, CustomDialogWrapperComponent],
})
export class SelectRatingComponent implements OnInit {
  @Input() selectedRating: number;
  @Output() ratingChanged = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}
}
