import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MARKETPLACE_NAVIGATION } from '../../../navigation/navigation-routes/common/marketplace.navigation';
import {
  SupplierDashboardAnalyticsService,
  SupplierTopSellingProductsDto,
} from '../../../service/analytics/supplier-dashboard-analytics.service';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { SupplierDashboardService } from '../services/supplier-dashboard.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-top-selling-products',
  templateUrl: './supplier-top-selling-products.component.html',
  styleUrls: ['./supplier-top-selling-products.component.scss'],
})
export class SupplierTopSellingProductsComponent implements OnInit, OnChanges {
  @Input()
  title: string;
  @Input()
  sortBy: string;
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  dataSource: MatTableDataSource<SupplierTopSellingProductsDto>;
  columns = ['product-image', 'title', 'products-sold', 'revenue'];

  protected paymentCurrency: string;

  readonly marketplaceNavigation = MARKETPLACE_NAVIGATION;

  constructor(
    private supplierDashboardAnalyticsService: SupplierDashboardAnalyticsService,
    private navigationService: NavigationService,
    private _supplierDashboardService: SupplierDashboardService
  ) {}

  ngOnInit(): void {
    this.initPaymentCurrency();
    this.setDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.setDataSource();
    }
  }

  private initPaymentCurrency(): void {
    this._supplierDashboardService
      .getSupplierPaymentCurrency()
      .pipe(take(1))
      .subscribe((paymentCurrency: string): void => {
        this.paymentCurrency = paymentCurrency;
      });
  }

  setDataSource(): void {
    const pageable = {
      sort: this.sortBy,
      page: 0,
      size: 3,
    };

    this.supplierDashboardAnalyticsService
      .getTopSellingProducts(this.dateFrom, this.dateTo, pageable)
      .subscribe((topSellingProducts) => {
        this.dataSource = new MatTableDataSource(topSellingProducts);
      });
  }

  getProductPath(id: string): string {
    return this.navigationService.getProductPath(id, null);
  }
}
