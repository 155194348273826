import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle1Component } from 'app/layout/components/navbar/vertical/style-1/style-1.component';
import { RouterModule } from '@angular/router';
import { OverflowDirective } from 'app/shared/directives/overflow.directive';

@NgModule({
  declarations: [NavbarVerticalStyle1Component],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    FuseSharedModule,
    FuseNavigationModule,
    RouterModule,
    OverflowDirective,
  ],
  exports: [NavbarVerticalStyle1Component],
})
export class NavbarVerticalStyle1Module {}
