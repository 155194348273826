import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RestService } from '../../../service/rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
  standalone: true,
  imports: [CommonModule, MatPaginatorModule],
})
export class TablePaginatorComponent implements OnInit, AfterViewInit, OnDestroy {
  sortOrder: string;
  sortField: string;

  private _unsubscribeAll = new Subject<void>();

  _sort: MatSort;

  @Input() restUrl: string;
  @Input() queryParams: HttpParams = new HttpParams();
  @Input() totalRows = 0;

  @Input() pageSizeOptions: number[] = [25, 50, 100];

  @Input() set sort(value: any) {
    this._sort = value;
    if (!Utils.isNullOrUndefined(this._sort)) {
      this.subscribeToSortChange();
    }
  }

  @Output() itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  constructor(private restService: RestService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadItems();
  }

  private subscribeToSortChange(): void {
    this._sort.sortChange.pipe(takeUntil(this.unsubscribeAll)).subscribe((sort) => {
      this.sortOrder = sort.direction !== '' ? sort.direction.toUpperCase() : null;
      this.sortField = sort.active;
      this.paginator.pageIndex = 0;
      this.loadItems();
    });
  }

  public loadItems(): void {
    this.restService
      .get(this.restUrl, this.getExtendedQueryParams())
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((response) => {
        this.itemsChange.emit(response.getData());
      });
  }

  public resetPageIndex(): void {
    this.paginator.pageIndex = 0;
  }

  private isSortingActive(): boolean {
    return !Utils.isNullOrUndefined(this.sortField) && !Utils.isNullOrUndefined(this.sortOrder);
  }

  public handlePageEvent(): void {
    this.loadItems();
  }

  private getExtendedQueryParams(): HttpParams {
    this.queryParams = this.queryParams.set('from', `${this.getFrom()}`).set('size', `${this.getSize()}`);
    if (this.isSortingActive()) {
      this.queryParams = this.queryParams.set('order', `${this.sortField}:${this.sortOrder}`);
    }
    return this.queryParams;
  }

  private getFrom(): number {
    return this.paginator.pageIndex * this.paginator.pageSize;
  }

  private getSize(): number {
    return this.paginator.pageSize;
  }

  get pageSize(): number {
    return !Utils.isNullOrUndefined(this.paginator) ? this.paginator.pageSize : 0;
  }

  get unsubscribeAll(): Subject<void> {
    return this._unsubscribeAll;
  }

  public decrementPageIndex(): void {
    this.paginator.pageIndex = this.paginator.pageIndex - 1;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
