import { Injectable } from '@angular/core';
import { FuseNavigationService } from '../../../@fuse/components/navigation/navigation.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { User } from '../user/user';
import { USER_ROLES, USER_ROLES_STRINGS } from '../../utils/Constants';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FuseSidebarService } from '../../../@fuse/components/sidebar/sidebar.service';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { OnboardService } from '../../layout/components/onboard/onboard.service';
import { SupplierSettingsDialogComponent } from '../../main/users/supplier-settings-dialog/supplier-settings-dialog.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { admin_navigation_children } from '../../navigation/navigation';
import { PreferenceSolutionsService } from '../preference-solutions/preference-solutions.service';
import { Utils } from 'app/utils/utils';
// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  navigation: any;
  navigation_general: any;
  navigation_agency: any;
  navigation_global_admin: any;
  navigation_global_admin_as_supplier: any;
  navigation_global_admin_as_agency: any;
  navigation_admin_with_collapse: any;
  navigation_global_admin_as_own_supplier: any;
  navigation_global_admin_as_own_retailer: any;

  // array of all Admin navigation IDs
  allAdminNavs = [];
  constructor(
    private authService: AuthenticationService,
    private fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private marketplaceEcomService: MarketplaceEcomService,
    private onboardService: OnboardService,
    private gtmService: GoogleTagManagerService,
    private preferenceSolutionsService: PreferenceSolutionsService
  ) {
    // filling up the allAdminNavs array with IDs
    admin_navigation_children.forEach((item) => {
      if (item.type === 'item') {
        if (item.id !== 'changerole') {
          this.allAdminNavs.push(item.id);
        }
      } else {
        item.children.forEach((subItem) => {
          this.allAdminNavs.push(subItem.id);
        });
      }
    });
  }

  private _isInitializedApp = false;

  private _supplierPopUpOpen = false;

  logouturl = {
    function: () => {
      // this.socialAuthService.signOut().then((data) => {
      //     console.log( 'signOut');
      // });
      // this.authService.logout();
    },
  };

  get isInitializedApp(): boolean {
    return this._isInitializedApp;
  }

  initAppByAdminSelectedUser(user: User): void {
    this.resetServices();

    switch (user.role) {
      case USER_ROLES.SUPPLIER:
        this.fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_SUPPLIER);
        // this.fuseNavigationService.updateNavigationItem('changetoretailer', {hidden: true});
        this.fuseNavigationService.updateNavigationItem('supplierNavigation', { title: user.userName + ' supplier' });
        // this.updateSupplierSettingsNavItem();
        break;
      case USER_ROLES.AGENT:
        this.fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_AGENCY);
        // this.fuseNavigationService.updateNavigationItem('changetosupplier', {hidden: true});
        this.fuseNavigationService.updateNavigationItem('agentNavigation', { title: user.userName + ' retailer' });
        this.marketplaceEcomService.initDomainList();
        break;
    }

    this.fuseNavigationService.updateNavigationItem('logout', this.logouturl);
    // this.fuseNavigationService.updateNavigationItem('logoutAdmin', this.logouturl);
    this.updateNotificationNavItem();
    this.updateProfileMenuItem(user.userName);
    this.setChangeroleMenuItem(user);
  }

  init(user: User, withNavigate = false): void {
    return;
  }

  private setChangeroleMenuItem(selectedUser?: User): void {
    return;
  }

  updateNotificationNavItem(): void {
    try {
      this.fuseNavigationService.updateNavigationItem('notification', {
        function: () => {
          this._fuseSidebarService.getSidebar('notificationPanel').toggleOpen();
        },
      });
    } catch (ex) {}
  }

  updateSupplierSettingsNavItem(): void {
    let width;
    if (window.screen.width <= 1050) {
      width = '95vw';
    } else {
      width = '80%';
    }
    try {
      this.fuseNavigationService.updateNavigationItem('settings', {
        function: () => {
          this.dialog.open(SupplierSettingsDialogComponent, { height: '80%', width: width, maxWidth: width });
        },
      });
    } catch (e) {}
  }

  updateProfileMenuItem(username): void {
    try {
      this.fuseNavigationService.updateNavigationItem('profile', { title: username });
    } catch (e) {}
  }

  updateKioskMenuItems(user): void {
    // update kiosk menu function
    try {
      // adding the logout function to each Admin menu item
      this.allAdminNavs.forEach((navId) => {
        this.fuseNavigationService.updateNavigationItem(navId, {
          function: () => {
            if (!Utils.isNullOrUndefined(localStorage.getItem('userId'))) {
              // After click, remove the signed in user
              localStorage.removeItem('userId');
              localStorage.removeItem('userDetails');
              this.marketplaceEcomService.remove();

              this.resetServices();

              this.fuseNavigationService.updateNavigationItem('changerole', { hidden: false });
              this.updateProfileMenuItem(user.userName);

              if (!Utils.isNullOrUndefined(localStorage.getItem('actualRole'))) {
                switch (Number(localStorage.getItem('actualRole'))) {
                  case 1:
                    this.fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_OWN_SUPPLIER);
                    break;
                  case 2:
                    this.fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_OWN_RETAILER);
                    this.marketplaceEcomService.isSettedEcomPLan = false;
                    this.marketplaceEcomService.initDomainList();

                    break;
                }
              }

              this.setChangeroleMenuItem();
            }
          },
        });
      });
    } catch (e) {}
  }

  resetServices(): void {
    this.onboardService.initOnboard = false;
    this.onboardService.getCompletedSteps().subscribe();
    this.onboardService.getOnboardingStatus().subscribe((res) => {
      this.onboardService.initOnboard = res;
    });
  }
}
