import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'overwriteHTags',
  standalone: true,
})
export class OverwriteHTagsPipe implements PipeTransform {
  transform(text: string, extraClasses?: string[]): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    // Select all <h> elements
    const hElements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');

    // Loop through each <h> element and replace with <div> and extend class list
    hElements.forEach((element) => {
      element.classList.add(element.localName, ...(extraClasses ? extraClasses : []));
      const newDiv = document.createElement('div');
      newDiv.innerHTML = element.innerHTML;
      // Copy original element's attributes
      for (const attribute of Array.from(element.attributes)) {
        newDiv.setAttribute(attribute.name, attribute.value);
      }
      element.parentNode.replaceChild(newDiv, element);
    });

    // Return modified HTML content
    return doc.body.innerHTML;
  }
}
