<div
  role="group"
  [formGroup]="form"
  class="chip-with-autocomplete-container"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>
  <mat-chip-list #itemChipList formControlName="items">
    <mat-chip
      *ngFor="let item of selectedItems"
      [selectable]="true"
      [removable]="true"
      (removed)="removePlatform(item)"
      class="grey-chip"
    >
      {{ item.name | titlecase }}
      <mat-icon matChipRemove>close</mat-icon>
    </mat-chip>
    <input
      [matAutocomplete]="itemAuto"
      [matChipInputFor]="itemChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      [placeholder]="placeholder"
      #itemInput
      (input)="filterItems()"
    />
  </mat-chip-list>
  <mat-autocomplete #itemAuto="matAutocomplete" (optionSelected)="itemSelected($event)">
    <mat-option *ngFor="let item of selectableItems" [value]="item.id">{{ item.name | titlecase }}</mat-option>
  </mat-autocomplete>
</div>
