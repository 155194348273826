<ng-container [ngSwitch]="element.type">
  <ng-container *ngSwitchCase="'inner-link'">
    <ng-container
      [ngTemplateOutlet]="innerLink"
      [ngTemplateOutletContext]="{ elem: element, typography: 'typography-body-2' }"
    ></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'external-link'">
    <ng-container
      [ngTemplateOutlet]="externalLink"
      [ngTemplateOutletContext]="{ elem: element, typography: 'typography-body-2' }"
    ></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'menu'">
    <ng-container [ngTemplateOutlet]="menu" [ngTemplateOutletContext]="{ elem: element }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #innerLink let-elem="elem" let-typography="typography">
  <button mat-menu-item class="item" [ngClass]="[elem.type, typography]" [routerLink]="elem.link">
    {{ elem.title | translate }}
  </button>
</ng-template>

<ng-template #externalLink let-elem="elem" let-typography="typography">
  <button
    mat-menu-item
    class="item upper-row-link"
    [ngClass]="[elem.type, typography]"
    (click)="redirectExternal(elem.link)"
  >
    {{ elem.title | translate }}
  </button>
</ng-template>

<ng-template #menu let-elem="elem">
  <ng-container *ngIf="elem.children && elem.children.length > 0">
    <div
      class="item"
      [ngClass]="elem.type"
      [class.is-open]="isOpen"
      [matMenuTriggerFor]="menu"
      #menuTrigger="matMenuTrigger"
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
    >
      <!--      (mouseenter)="triggerMouseHover.next(true)"-->
      <!--      (mouseleave)="triggerMouseHover.next(false)"-->
      <!--    >-->
      <div class="typography-body-2">{{ elem.title | translate }}</div>
      <mat-icon class="s-20">keyboard_arrow_down</mat-icon>
    </div>
    <mat-menu #menu="matMenu" class="upper-row-menu">
      <!--      <div (mouseenter)="menuMouseHover.next(true)" (mouseleave)="menuMouseHover.next(false)">-->
      <div>
        <div class="upper-row-menu-item" *ngFor="let menuItem of elem.children">
          <ng-container [ngSwitch]="menuItem.type">
            <ng-container *ngSwitchCase="'inner-link'">
              <ng-container
                [ngTemplateOutlet]="innerLink"
                [ngTemplateOutletContext]="{ elem: menuItem, typography: 'typography-body-1' }"
              ></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'external-link'">
              <ng-container
                [ngTemplateOutlet]="externalLink"
                [ngTemplateOutletContext]="{ elem: menuItem, typography: 'typography-body-1' }"
              ></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-menu>
  </ng-container>
</ng-template>
