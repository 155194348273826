<div class="payment-connect-container" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="50px">
  <div fxFlex="40">
    <form *ngIf="billingForm" class="mt-2" fxLayout="column" [formGroup]="billingForm">
      <h2>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.TITLE' | translate }}</h2>
      <mat-form-field>
        <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.FIRST_NAME' | translate }}</mat-label>
        <input matInput name="firstName" formControlName="firstName" required />
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.LAST_NAME' | translate }}</mat-label>
        <input matInput name="lastName" formControlName="lastName" required />
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.COMPANY_NAME' | translate }}</mat-label>
        <input matInput name="companyName" formControlName="companyName" required />
        <mat-error></mat-error>
      </mat-form-field>
      <div formGroupName="paymentAddress" fxLayout="column">
        <mat-form-field>
          <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.COUNTRY' | translate }}</mat-label>
          <mat-select name="country" formControlName="country" required>
            <mat-option *ngFor="let c of countries" [value]="c.code">
              {{ c.name }}
            </mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.ADDRESS_1' | translate }}</mat-label>
          <input matInput name="address1" formControlName="address1" required />
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.ADDRESS_2' | translate }}</mat-label>
          <input matInput name="address2" formControlName="address2" />
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.CITY' | translate }}</mat-label>
          <input matInput name="city" formControlName="city" required />
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.ZIP' | translate }}</mat-label>
          <input matInput name="zipCode" formControlName="zipCode" required />
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <mat-form-field *ngIf="!hideEUVat">
        <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.EU_VAT' | translate }}</mat-label>
        <input matInput name="euVat" formControlName="euVat" />
        <span matPrefix>{{ country == 'GR' ? 'EL' : country }}</span>
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="!hideTax">
        <mat-label>{{ 'ORDER_SETTINGS.BILLING_INFORMATION.TAX_NUMBER' | translate }}</mat-label>
        <input matInput name="taxNumber" formControlName="taxNumber" />
        <mat-error></mat-error>
      </mat-form-field>
    </form>
    <button [disabled]="!this.billingForm.valid" mat-button class="syncee-blue-button" (click)="submitBilling()">
      {{ 'ORDER_SETTINGS.BILLING_INFORMATION.SAVE_BTN' | translate }}
    </button>
  </div>
  <!--  <div *ngIf="paymentAccount" fxFlex="60">-->
  <div fxFlex="60">
    <h2>{{ 'ORDER_SETTINGS.PAYMENT_CONNECT.TITLE' | translate }}</h2>
    <div>{{ 'ORDER_SETTINGS.PAYMENT_CONNECT.SUBTITLE' | translate }}</div>
    <div class="pt-8">
      <app-alert-multi
        *ngIf="!paymentAccount"
        [type]="'info'"
        [messages]="['ORDER_SETTINGS.PAYMENT_CONNECT.SAVE_BILLING_FIRST' | translate]"
        [matIcon]="'receipt_long'"
      >
      </app-alert-multi>
    </div>
    <div class="mt-16 payment-connects" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="mat-card" *ngIf="isStripeExistsInCountry || !country">
        <app-stripe-connect [redirectURL]="redirectStripeUrl" (stripeConnected)="stripeConnectStatusEvent($event)">
        </app-stripe-connect>
      </div>
      <div class="mat-card mt-40">
        <app-login-with-paypal
          *ngIf="paymentAccount?.paypalConnect?.version === 'v1'"
          [redirectUrl]="redirectPaypalUrl"
          (paypalConnected)="paypalConnectStatusEvent($event)"
        >
        </app-login-with-paypal>
        <app-paypal-onboard
          *ngIf="country && isPaypalExistsInCountry; else notsupportedPaypal"
          #paypalOnboard
          [redirectUrl]="redirectPaypalUrl"
          (paypalConnected)="paypalConnectStatusEvent($event)"
        ></app-paypal-onboard>
        <ng-template #notsupportedPaypal>
          {{ 'ORDER_SETTINGS.PAYPAL_CONNECT_NOT_SUPPORTED' | translate }}
        </ng-template>
        <div class="badge-container">
          <img class="paypal-badge" src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_SbyPP_mc_vs_dc_ae.jpg" />
        </div>
      </div>
      <div *ngIf="isConnectedPayment()" class="mat-card mt-40">
        <app-supplier-tax-settings></app-supplier-tax-settings>
      </div>

      <div class="payment-connect-disabled" *ngIf="!paymentAccount"></div>
    </div>
  </div>
</div>
