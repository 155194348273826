<div
  class="setup-guide-item-list-content"
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-lg="center start"
  fxLayoutGap="40px"
  fxLayoutGap.lt-md="20px"
  fxLayout="column"
  fxLayout.gt-md="row"
>
  <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutGap="8px">
      <div>{{ 'SETUP_GUIDE.REVIEW_STEP.MAIN.MESSAGE_1' | translate }}</div>
    </div>
    <ng-container *showOnBreakpoint="'gt-lg'" [ngTemplateOutlet]="button"></ng-container>
  </div>
  <div fxFlex="1 0 0" fxFlex.gt-lg="0 0 555px">
    <app-setup-guide-review-content-image></app-setup-guide-review-content-image>
  </div>
  <ng-container *showOnBreakpoint="'lg'" [ngTemplateOutlet]="button"></ng-container>
</div>

<ng-template #button>
  <button *ngIf="!review" (click)="handleReviewButtonClick()" fxFlexAlign="start" mat-flat-button color="primary">
    {{ 'SETUP_GUIDE.REVIEW_STEP.BUTTON_TEXT' | translate }}
  </button>
</ng-template>
