import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { SearchProductType } from '../../../vo/search-product-vo';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-product-type-selector',
  standalone: true,
  imports: [CommonModule, MatSelectModule, FormsModule],
  templateUrl: './search-product-type-selector.component.html',
  styleUrls: ['./search-product-type-selector.component.scss'],
})
export class SearchProductTypeSelectorComponent implements OnInit {
  @Input() loading = false;
  @Input() value: SearchProductType;
  @Output() valueChange = new EventEmitter<SearchProductType>();
  searchProductType = SearchProductType;

  constructor() {}

  ngOnInit(): void {}
}
