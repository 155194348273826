import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: {
      velocity: 0.4,
      threshold: 2,
    },
    pan: { direction: 30 },
  };
}
