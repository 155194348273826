import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';

export interface BCreditCard {
  cardType: string;
  expirationMonth: string;
  expirationYear: string;
  last4: string;
}

export interface BPaypalAccount {
  email: string;
}

export interface BraintreeConnect {
  type: string;
  paymentInfo: BCreditCard | BPaypalAccount;
}

export interface PaypalConnect {
  id: number;
  email: string;
  refreshToken: string;
  deleted: string;
  date: string;
  version: 'v1' | 'v2';
  merchantId: string;
  activated: string;
}

export interface StripeConnect {
  id: number;
  accountId: string;
  refresh_token: string;
  email: string;
  expired: Date;
  activated: Date;
  deleted: Date;
  created: Date;
}

export interface PaymentAddress {
  id: number;
  country: string;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
}

export interface BillingData {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  euVat?: string;
  taxNumber?: string;
  paymentAddress: PaymentAddress;
}

export interface PaymentAccount extends BillingData {
  sandbox: boolean;
  paypalConnect: PaypalConnect;
  stripeConnect: StripeConnect;
}

export interface ConnectStatus {
  paypalConnectStatus: boolean;
  stripeConnectStatus: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public static GET_STRIPE_CONNECT_URL = 'SynceePaymentService/getStripeConnect';
  public static GET_PAYPAL_CONNECT_URL = 'SynceePaymentService/getPaypalConnect';
  public static GET_PAYMENT_ACCOUNT_URL = 'SynceePaymentService/getPaymentAccount';
  public static SAVE_PAYMENT_ACCOUNT_URL = 'SynceePaymentService/savePaymentAccount';
  public static CREATE_STRIPE_ACCOUNT_URL = 'SynceePaymentService/createStripeAccount';
  public static COMPLETE_STRIPE_ACCOUNT_URL = 'SynceePaymentService/completeStripeConnect';
  public static DISCONNECT_STRIPE_ACCOUNT_URL = 'SynceePaymentService/disconnectStripeConnect';
  public static GET_DELETE_STRIPE_ACCOUNTS_URL = 'SynceePaymentService/getDeletedStripeConnects';
  public static REACTIVE_STRIPE_ACCOUNT_URL = 'SynceePaymentService/reActivateStripeAccount';
  public static CREATE_STRIPE_ACCOUNT_LINK_URL = 'SynceePaymentService/createStripeAccountLink';
  public static REFRESH_STRIPE_URL = 'SynceePaymentService/stripeRefresh';

  public static GENERATE_BRAINTREE_CLIENT_TOKEN = 'SynceePaymentService/generateBrainTreeClientToken';
  public static CREATE_BRAINTREE_CONNECT = 'SynceePaymentService/createBraintreeConnect';
  public static UPDATE_BRAINTREE_CONNECT = 'SynceePaymentService/updateBraintreeConnect';
  public static GET_BRAINTREE_CONNECT = 'SynceePaymentService/getBraintreeConnect';
  public static DISCONNECT_PAYPAL_ACCOUNT_URL = 'SynceePaymentService/disconnectPaypalAccount';

  constructor(private restService: RestService) {}

  public getPaymentAccount(): Observable<PaymentAccount> {
    return this.restService
      .get(PaymentService.GET_PAYMENT_ACCOUNT_URL)
      .pipe(map((response) => response.getFirstData()));
  }

  public getPaypalConnect(): Observable<PaypalConnect> {
    return this.restService.get(PaymentService.GET_PAYPAL_CONNECT_URL).pipe(map((response) => response.getFirstData()));
  }

  public disconnectPaypalAccount(id): Observable<any> {
    return this.restService
      .post(PaymentService.DISCONNECT_PAYPAL_ACCOUNT_URL, { id })
      .pipe(map((response) => response.getFirstData()));
  }

  public savePaymentAccount(data: Partial<PaymentAccount>): Observable<PaymentAccount> {
    return this.restService
      .post(PaymentService.SAVE_PAYMENT_ACCOUNT_URL, data)
      .pipe(map((response) => response.getFirstData()));
  }

  public getStripeConnect(): Observable<StripeConnect> {
    return this.restService.get(PaymentService.GET_STRIPE_CONNECT_URL).pipe(map((response) => response.getFirstData()));
  }

  public createAccount(returnUrl): Observable<any> {
    const params = { returnUrl };
    return this.restService
      .post(PaymentService.CREATE_STRIPE_ACCOUNT_URL, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public completeAccount(accountId): Observable<any> {
    return this.restService
      .post(PaymentService.COMPLETE_STRIPE_ACCOUNT_URL, { accountId })
      .pipe(map((response) => response.getFirstData()));
  }

  public disconnectStripeAccount(id): Observable<any> {
    return this.restService
      .post(PaymentService.DISCONNECT_STRIPE_ACCOUNT_URL, { id })
      .pipe(map((response) => response.getFirstData()));
  }

  public getDeletedStripeConnects(): Observable<StripeConnect[]> {
    return this.restService
      .get(PaymentService.GET_DELETE_STRIPE_ACCOUNTS_URL)
      .pipe(map((response) => response.getData()));
  }

  public reActiveStripeConnect(connectId): Observable<any> {
    return this.restService
      .post(PaymentService.REACTIVE_STRIPE_ACCOUNT_URL, { connectId })
      .pipe(map((response) => response.getData()));
  }

  public generateBrainTreeClientToken(): Observable<any> {
    return this.restService
      .post(PaymentService.GENERATE_BRAINTREE_CLIENT_TOKEN, null)
      .pipe(map((response) => response.getFirstData()['clientToken']));
  }

  public createBrainTreeConnect(type, nonce): Observable<any> {
    return this.restService
      .post(PaymentService.CREATE_BRAINTREE_CONNECT, { type, nonce })
      .pipe(map((response) => response.getFirstData()));
  }

  public updateBrainTreeConnect(type, nonce): Observable<any> {
    return this.restService
      .post(PaymentService.UPDATE_BRAINTREE_CONNECT, { type, nonce })
      .pipe(map((response) => response.getFirstData()));
  }

  public getBrainTreeConnect(): Observable<any> {
    return this.restService.get(PaymentService.GET_BRAINTREE_CONNECT).pipe(map((response) => response.getFirstData()));
  }

  public createStripeAccountLink(stripeAccId, returnUrl): Observable<any> {
    const params = { stripeAccId, returnUrl };
    return this.restService
      .post(PaymentService.CREATE_STRIPE_ACCOUNT_LINK_URL, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public refreshConnect(accountId): Observable<any> {
    return this.restService
      .post(PaymentService.REFRESH_STRIPE_URL, { accountId })
      .pipe(map((response) => response.getFirstData()));
  }
}
