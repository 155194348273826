import { Injectable } from '@angular/core';
import { PreferenceStoreService } from '../../../../service/preference/preference.store.service';
import { combineLatest, Observable } from 'rxjs';
import { PreferenceNameEnum } from '../../../../vo/Preferences/preference-name.enum';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { firstLevelCategoriesSelector } from '../../../../store/category/category.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { CategoryVo } from '../../../../vo/category-vo';
import { Preference } from '../../../../vo/Preferences/preference';

@UntilDestroy()
@Injectable()
export class CategoryPreferencesFormDialogService {
  public firstLevelCategoryIdsFromPreferences$: Observable<number[]>;

  private categoryPreferences$ = this.preferenceStoreService
    .getPreferencesByPreferenceName(PreferenceNameEnum.SYNCEE_CATEGORY)
    .pipe(
      omitNullOrUndefined(),
      map((preferences: Preference[]) =>
        preferences.map((preference: Preference) => parseInt(preference.preferenceValue, 10))
      )
    );

  private firstLevelCategoryIds$ = this.store.select(firstLevelCategoriesSelector).pipe(
    omitNullOrUndefined(),
    map((categories: CategoryVo[]) => categories.map((category: CategoryVo): number => category.id))
  );

  constructor(private store: Store<AppState>, private preferenceStoreService: PreferenceStoreService) {
    this.initFirstLevelCategoryPreferencesSubscription();
  }

  private initFirstLevelCategoryPreferencesSubscription(): void {
    this.firstLevelCategoryIdsFromPreferences$ = combineLatest([
      this.categoryPreferences$,
      this.firstLevelCategoryIds$,
    ]).pipe(
      untilDestroyed(this),
      map(([preferredCategoryIds, firstLevelCategoryIds]: [number[], number[]]) =>
        preferredCategoryIds.filter((preference: number) => firstLevelCategoryIds.includes(preference))
      )
    );
  }
}
