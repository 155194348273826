<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div *ngIf="saveMappingWarning" class="notification warning">
    <div class="msg-container">
      <mat-icon class="type-icon">warning</mat-icon>
      <ng-container>
        {{ 'RETAILER_TASK_WIZARD.SHOPIFY.YOU_HAVE' | translate }}
      </ng-container>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
    <div class="fuse-card mapping-container" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
      <form #productDetatailsForm="ngForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
        <div class="h2 mb-24">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BASIC' | translate }}</div>
        <div class="product-details">
          <!--ID-->
          <app-task-comment
            [taskId]="taskId"
            [taskType]="'import'"
            [step]="'mapping'"
            [mappingField]="'ID'"
            [comments]="comments"
          >
          </app-task-comment>
          <div *ngIf="mappingData.productIds" id="product-id-container" fxLayout="row" fxLayoutAlign="start">
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="ID_{{ prod.id }}"
              name="productId_{{ prod.id }}"
              [(ngModel)]="prod.value.chips"
              [(operations)]="prod.value.operations"
              *ngFor="let prod of mappingData.productIds"
              fxFlex="{{ 100 / mappingData.productIds.length }}"
              title="ID"
              placeholder="ID"
            ></app-droppable-input>
          </div>

          <!--Title-->
          <app-task-comment
            [taskId]="taskId"
            [taskType]="'import'"
            [step]="'mapping'"
            [mappingField]="'TITLE'"
            [comments]="comments"
          >
          </app-task-comment>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="TITLE"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.TITLE_PLACE' | translate }}"
              name="TITLE"
              [(ngModel)]="mappingData.singleFields.TITLE.chips"
              [(operations)]="mappingData.singleFields.TITLE.operations"
              name="ngModelTest"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.TITLE_PLACE' | translate }}"
            ></app-droppable-input>
          </div>

          <!--Description-->
          <app-task-comment
            [taskId]="taskId"
            [taskType]="'import'"
            [step]="'mapping'"
            [mappingField]="'DESCRIPTION'"
            [comments]="comments"
          >
          </app-task-comment>
          <div id="description-container">
            <app-droppable-multiline-input
              id="DESCRIPTION"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.DESC_PLACE' | translate }}"
              [(chips)]="mappingData.singleFields.DESCRIPTION.chips"
              [(operations)]="mappingData.singleFields.DESCRIPTION.operations"
            ></app-droppable-multiline-input>
          </div>

          <div id="product-images-container" fxLayout="column">
            <div
              id="product-images-info"
              class="product-imgs"
              fxLayout="row"
              fxLayoutAlign="start"
              ngModelGroup="image"
              *ngFor="let img of mappingData.images; last as isLast; let i = index"
            >
              <!--Image URL-->
              <app-task-comment
                [taskId]="taskId"
                [taskType]="'import'"
                [step]="'mapping'"
                [mappingField]="'IMAGES'"
                [comments]="comments"
              >
              </app-task-comment>
              <app-droppable-input
                #imagesURLProcess
                id="IMAGES"
                title="{{ 'RETAILER_TASK_WIZARD.ECWID.IMAGE_URL_PLACE' | translate }}"
                name="IMAGES-{{ i }}"
                [(ngModel)]="img.url.chips"
                [(operations)]="img.url.operations"
                [style.width.%]="95"
                placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.IMAGE_URL_PLACE' | translate }}"
              ></app-droppable-input>

              <div class="icon-holder" fxLayoutAlign="start center">
                <button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeProdImage(i)">
                  <mat-icon aria-label="Delete icon" [style.width.%]="5"> delete </mat-icon>
                </button>
                <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProdImage()">
                  <mat-icon aria-label="new Image icon" [style.width.%]="5"> add_circle_outline </mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="h2 mb-24">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.ORGAN' | translate }}</div>
          <div id="product-categories" fxLayout="column" fxLayoutAlign="start stretch">
            <!--Vendor-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'BRAND'"
              [comments]="comments"
            >
            </app-task-comment>
            <div id="product-brands" fxLayout="row" fxLayoutAlign="start">
              <app-droppable-input
                #normalProcess
                id="BRAND"
                title="{{ 'RETAILER_TASK_WIZARD.ECWID.VENDOR_PLACE' | translate }}"
                name="BRAND"
                [(ngModel)]="mappingData.singleFields.BRAND.chips"
                [(operations)]="mappingData.singleFields.BRAND.operations"
                [style.width.%]="100"
                placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.VENDOR_PLACE' | translate }}"
              ></app-droppable-input>
            </div>

            <!--Collections-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'COLLECTIONS'"
              [comments]="comments"
            >
            </app-task-comment>
            <div id="product-collections" fxLayout="row" fxLayoutAlign="start">
              <app-droppable-input
                #normalProcess
                id="CATEGORY"
                title="{{ 'RETAILER_TASK_WIZARD.ECWID.CATEGORY_PLACE' | translate }}"
                name="CATEGORY"
                [(ngModel)]="mappingData.singleFields.CATEGORY.chips"
                [(operations)]="mappingData.singleFields.CATEGORY.operations"
                [style.width.%]="100"
                placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.CATEGORY_PLACE' | translate }}"
              ></app-droppable-input>
            </div>
          </div>
          <div class="h2 mb-24">Variant Fields</div>

          <div id="product-variants-container" fxLayout="column" fxLayoutAlign="start">
            <div id="product-price-and-stuff-container">
              <div fxLayout="row" fxLayoutAlign="start">
                <!--Price-->
                <app-task-comment
                  [taskId]="taskId"
                  [taskType]="'import'"
                  [step]="'mapping'"
                  [mappingField]="'PRICE'"
                  [comments]="comments"
                >
                </app-task-comment>
                <app-droppable-input
                  #normalProcess
                  [required]="priceFieldIsRequired"
                  id="PRICE"
                  title="{{ 'RETAILER_TASK_WIZARD.ECWID.PRICE_PLACE' | translate }}"
                  name="PRICE"
                  [isPriceSettings]="true"
                  [(ngModel)]="mappingData.singleFields.PRICE.chips"
                  [(settings)]="mappingData.singleFields.PRICE.settings"
                  [(operations)]="mappingData.singleFields.PRICE.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.PRICE_PLACE' | translate }}"
                ></app-droppable-input>

                <app-task-comment
                  [taskId]="taskId"
                  [taskType]="'import'"
                  [step]="'mapping'"
                  [mappingField]="'DISCOUNTED_PRICE'"
                  [comments]="comments"
                >
                </app-task-comment>
                <app-droppable-input
                  #normalProcess
                  id="COST_PER_ITEM'"
                  title="{{ 'RETAILER_TASK_WIZARD.ECWID.COST_PER_ITEM_PLACE' | translate }}"
                  name="COST_PER_ITEM'"
                  [isPriceSettings]="true"
                  [(ngModel)]="mappingData.singleFields.COST_PER_ITEM.chips"
                  [(settings)]="mappingData.singleFields.COST_PER_ITEM.settings"
                  [(operations)]="mappingData.singleFields.COST_PER_ITEM.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.COST_PER_ITEM_PLACE' | translate }}"
                ></app-droppable-input>
              </div>
              <div fxLayout="row" fxLayoutAlign="start">
                <!--Inventory Quantity-->
                <app-task-comment
                  [taskId]="taskId"
                  [taskType]="'import'"
                  [step]="'mapping'"
                  [mappingField]="'UPC'"
                  [comments]="comments"
                >
                </app-task-comment>

                <!--Barcode-->

                <app-droppable-input
                  #normalProcess
                  id="ORIGINAL_PRICE"
                  title="{{ 'RETAILER_TASK_WIZARD.ECWID.COMPARE_AT_PRICE_PLACE' | translate }}"
                  name="ORIGINAL_PRICE"
                  [(ngModel)]="mappingData.singleFields.ORIGINAL_PRICE.chips"
                  [(operations)]="mappingData.singleFields.ORIGINAL_PRICE.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.COMPARE_AT_PRICE_PLACE' | translate }}"
                ></app-droppable-input>

                <app-task-comment
                  [taskId]="taskId"
                  [taskType]="'import'"
                  [step]="'mapping'"
                  [mappingField]="'QTY'"
                  [comments]="comments"
                >
                </app-task-comment>
                <app-droppable-input
                  #normalProcess
                  id="QTY"
                  title="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.QTY_LABEL' | translate }}"
                  name="QTY"
                  [(ngModel)]="mappingData.singleFields.QTY.chips"
                  [(operations)]="mappingData.singleFields.QTY.operations"
                  [style.width.%]="50"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.JUMPSELLER.MAPPING.QTY_LABEL' | translate }}"
                ></app-droppable-input>
              </div>

              <div id="product-basic-fields" fxLayout="row" fxLayoutAlign="start">
                <!--Weight-->
                <app-task-comment
                  [taskId]="taskId"
                  [taskType]="'import'"
                  [step]="'mapping'"
                  [mappingField]="'WEIGHT'"
                  [comments]="comments"
                >
                </app-task-comment>
                <app-droppable-input
                  #normalProcess
                  id="WEIGHT"
                  [style.width.%]="50"
                  title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                  name="WEIGHT"
                  [isWeightSettings]="true"
                  [(ngModel)]="mappingData.singleFields.WEIGHT.chips"
                  [(operations)]="mappingData.singleFields.WEIGHT.operations"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                ></app-droppable-input>

                <!--Variant SKU-->
                <app-task-comment
                  [taskId]="taskId"
                  [taskType]="'import'"
                  [step]="'mapping'"
                  [mappingField]="'SKU'"
                  [comments]="comments"
                >
                </app-task-comment>
                <app-droppable-input
                  #normalProcess
                  id="SKU"
                  title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SKU_PLACE' | translate }}"
                  name="SKU"
                  [style.width.%]="50"
                  [(ngModel)]="mappingData.singleFields.SKU.chips"
                  [(operations)]="mappingData.singleFields.SKU.operations"
                  placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.SKU_PLACE' | translate }}"
                ></app-droppable-input>
              </div>
            </div>
          </div>
        </div>

        <form
          #shopifyOptionsForm="ngForm"
          fxLayout="column"
          class="options-container"
          fxLayoutAlign="start"
          fxLayoutGap="8px"
        >
          <div id="shopify-options-1" fxLayout="row" fxLayoutAlign="start">
            <!--OPTION NAME 1-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'SHOPIFY_OPTION_NAME_1'"
              [comments]="comments"
            >
            </app-task-comment>
            <app-droppable-input
              fxFlex="30"
              #normalProcess
              id="SHOPIFY_OPTION_NAME_1"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
              name="SHOPIFY_OPTION_NAME_1"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_NAME_1_PLACE' | translate }}"
            ></app-droppable-input>

            <!--OPTION VALUE 1-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'SHOPIFY_OPTION_VALUE_1'"
              [comments]="comments"
            >
            </app-task-comment>
            <app-droppable-input
              fxFlex="80"
              #normalProcess
              id="SHOPIFY_OPTION_VALUE_1"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_VALUE_1_PLACE' | translate }}"
              name="SHOPIFY_OPTION_VALUE_1"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_VALUE_1_PLACE' | translate }}"
            ></app-droppable-input>
          </div>
          <div id="shopify-options-2" fxLayout="row" fxLayoutAlign="start">
            <!--OPTION NAME 2-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'SHOPIFY_OPTION_NAME_2'"
              [comments]="comments"
            >
            </app-task-comment>
            <app-droppable-input
              fxFlex="30"
              #normalProcess
              id="SHOPIFY_OPTION_NAME_2"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_NAME_2_PLACE' | translate }}"
              name="SHOPIFY_OPTION_NAME_2"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_NAME_2_PLACE' | translate }}"
            ></app-droppable-input>

            <!--OPTION VALUE 2-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'SHOPIFY_OPTION_VALUE_2'"
              [comments]="comments"
            >
            </app-task-comment>
            <app-droppable-input
              fxFlex="80"
              #normalProcess
              id="SHOPIFY_OPTION_VALUE_2"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_VALUE_2_PLACE' | translate }}"
              name="SHOPIFY_OPTION_VALUE_2"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_VALUE_2_PLACE' | translate }}"
            ></app-droppable-input>
          </div>
          <div id="shopify-options-3" fxLayout="row" fxLayoutAlign="start">
            <!--OPTION NAME 3-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'SHOPIFY_OPTION_NAME_3'"
              [comments]="comments"
            >
            </app-task-comment>
            <app-droppable-input
              fxFlex="30"
              #normalProcess
              id="SHOPIFY_OPTION_NAME_3"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_NAME_3_PLACE' | translate }}"
              name="SHOPIFY_OPTION_NAME_3"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_NAME_3_PLACE' | translate }}"
            ></app-droppable-input>

            <!--OPTION VALUE 3-->
            <app-task-comment
              [taskId]="taskId"
              [taskType]="'import'"
              [step]="'mapping'"
              [mappingField]="'SHOPIFY_OPTION_VALUE_3'"
              [comments]="comments"
            >
            </app-task-comment>
            <app-droppable-input
              fxFlex="80"
              #normalProcess
              id="SHOPIFY_OPTION_VALUE_3"
              title="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_VALUE_3_PLACE' | translate }}"
              name="SHOPIFY_OPTION_VALUE_3"
              [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.chips"
              [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.operations"
              [style.width.%]="30"
              placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.OPTION_VALUE_3_PLACE' | translate }}"
            ></app-droppable-input>
          </div>

          <!--          <app-dimension-fields #dimensionComponent [widthChip]="mappingData.singleFields.WIDTH" [heightChip]="mappingData.singleFields.HEIGHT" [lengthChip]="mappingData.singleFields.LENGTH" [taskId]="taskId" [comments]="comments"></app-dimension-fields>-->
        </form>

        <div class="h2 mb-24">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SEO' | translate }}</div>

        <!--Handle-->
        <app-task-comment
          [taskId]="taskId"
          [taskType]="'import'"
          [step]="'mapping'"
          [mappingField]="'SHOPIFY_HANDLE'"
          [comments]="comments"
        >
        </app-task-comment>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            #normalProcess
            id="SHOPIFY_HANDLE"
            title="{{ 'RETAILER_TASK_WIZARD.ECWID.SHOPIFY_HANDLE' | translate }}"
            name="SHOPIFY_HANDLE"
            [(ngModel)]="mappingData.singleFields.URL.chips"
            [(operations)]="mappingData.singleFields.URL.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.SHOPIFY_HANDLE' | translate }}"
          ></app-droppable-input>
        </div>

        <!--Page title (website seo)-->
        <app-task-comment
          [taskId]="taskId"
          [taskType]="'import'"
          [step]="'mapping'"
          [mappingField]="'SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG'"
          [comments]="comments"
        >
        </app-task-comment>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            #normalProcess
            id="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            title="{{ 'RETAILER_TASK_WIZARD.ECWID.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG' | translate }}"
            name="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            [(ngModel)]="mappingData.singleFields.SEO_PAGE_TITLE.chips"
            [(operations)]="mappingData.singleFields.SEO_PAGE_TITLE.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.ECWID.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG' | translate }}"
          ></app-droppable-input>
        </div>

        <!--Meta Description (website seo)-->
        <app-task-comment
          [taskId]="taskId"
          [taskType]="'import'"
          [step]="'mapping'"
          [mappingField]="'SHOPIFY_META_DESC'"
          [comments]="comments"
        >
        </app-task-comment>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-multiline-input
            id="SHOPIFY_META_DESC"
            title="{{ 'RETAILER_TASK_WIZARD.ECWID.SHOPIFY_META_DESC' | translate }}"
            name="SHOPIFY_META_DESC"
            [(chips)]="mappingData.singleFields.SEO_META_DESCRIPTION.chips"
            [(operations)]="mappingData.singleFields.SEO_META_DESCRIPTION.operations"
          >
          </app-droppable-multiline-input>
        </div>

        <div class="h2 mb-24">{{ 'TASKWIZARD.ADDFILES.MAPPING.FILTERABLE_FIELDS' | translate }}</div>

        <!--Filter product field-->
        <app-task-comment
          [taskId]="taskId"
          [taskType]="'import'"
          [step]="'mapping'"
          [mappingField]="'FILTERABLE_PRODUCT_DATA'"
          [comments]="comments"
        >
        </app-task-comment>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            #normalProcess
            id="FILTERABLE_PRODUCT_DATA"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.FILTERABLE_PRODUCT_DATA' | translate }}"
            name="FILTERABLE_PRODUCT_DATA"
            [isFilterSettings]="true"
            [(chips)]="mappingData.singleFields.FILTERABLE_PRODUCT_DATA.chips"
            [(operations)]="mappingData.singleFields.FILTERABLE_PRODUCT_DATA.operations"
          >
          </app-droppable-input>
        </div>

        <!--Filter variant field-->
        <app-task-comment
          [taskId]="taskId"
          [taskType]="'import'"
          [step]="'mapping'"
          [mappingField]="'FILTERABLE_VARIANT_DATA'"
          [comments]="comments"
        >
        </app-task-comment>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            #normalProcess
            id="FILTERABLE_VARIANT_DATA"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.FILTERABLE_VARIANT_DATA' | translate }}"
            name="FILTERABLE_VARIANT_DATA"
            [isFilterSettings]="true"
            [(chips)]="mappingData.singleFields.FILTERABLE_VARIANT_DATA.chips"
            [(operations)]="mappingData.singleFields.FILTERABLE_VARIANT_DATA.operations"
          >
          </app-droppable-input>
        </div>
      </form>
    </div>
  </div>
</div>
<button mat-button class="syncee-black-flat-button" (click)="takonySave()" *ngIf="isAdmin">
  <mat-icon class="type-icon">visibility_off</mat-icon>{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SAVE' | translate }}
</button>
