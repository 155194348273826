import { BehaviorSubject } from 'rxjs';
import { BasicPagination } from '../vo/pagination/basic-pagination';

export class PaginateWithSubject {
  pagination: BehaviorSubject<BasicPagination>;
  allItemsCount: number;
  constructor(page: number, size: number) {
    this.pagination = new BehaviorSubject({ page: page, size: size });
  }
  paginateNext(): void {
    if (this.allItemsCount / (this.page + 1) <= this.size) {
      return;
    }
    this.pagination.next({
      ...this.pagination.value,
      page: this.page + 1,
    });
  }

  paginatePrev(): void {
    if (this.page === 0) {
      return;
    }
    this.pagination.next({
      ...this.pagination.value,
      page: this.page - 1,
    });
  }

  changePageSize(newSize: number): void {
    this.pagination.next({
      size: newSize,
      page: 0,
    });
  }
  get page(): number {
    return this.pagination.value.page;
  }
  get size(): number {
    return this.pagination.value.size;
  }
  set itemCount(value: number) {
    this.allItemsCount = value;
  }
}
