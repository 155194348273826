import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/main/notification/notification.service';
import { NavigationService } from 'app/service/navigation/navigation.service';
import { SupplierTask } from 'app/vo/supplier/supplier-task';
import { SupplierWithBadgesAndAutoOrder } from 'app/vo/supplier/supplier-with-badges-and-auto-order';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { CatalogApprovalStatus } from '../../model/catalog-approval-status';
import { ManagedProductNumber } from '../../model/managed-product-number';
import { RetailerToCatalog } from '../../model/retailer-to-catalog';
import { WholesalePriceAndOrderNumber } from '../../model/wholesale-price-and-order-number';
import { MySuppliersCacheService } from '../../service/my-suppliers-cache.service';
import { MySuppliersDialogService } from '../../service/my-suppliers-dialog.service';
import { MySuppliersPageService } from '../../service/my-suppliers-page.service';
@UntilDestroy()
@Component({
  selector: 'app-my-suppliers-mobile-item',
  templateUrl: './my-suppliers-mobile-item.component.html',
  styleUrls: ['./my-suppliers-mobile-item.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', opacity: 0, visibility: 'hidden' })),
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MySuppliersMobileItemComponent implements OnInit, OnDestroy {
  @Input() retailerToCatalog: RetailerToCatalog;

  @Input() expanded: boolean;

  @Input() hasAccessToReadContacts: boolean;

  @Input() hasAccessToChat = true;

  @Output() expansionChange = new EventEmitter<boolean>();

  public supplier: SupplierWithBadgesAndAutoOrder;

  public approved: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private mySuppliersDialogService: MySuppliersDialogService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    public pageService: MySuppliersPageService,
    private mySuppliersCacheService: MySuppliersCacheService
  ) {}

  public ngOnInit(): void {
    this.initSupplierSubscription();
    this.approved = this.retailerToCatalog.approved === CatalogApprovalStatus.APPROVED;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initSupplierSubscription(): void {
    this.mySuppliersCacheService
      .getCatalogFromCache(this.retailerToCatalog.catalogId)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((catalog: SupplierTask) => this.mySuppliersCacheService.getSupplierFromCache(catalog.userId))
      )
      .subscribe((supplier: SupplierWithBadgesAndAutoOrder) => {
        this.supplier = supplier;
      });
  }

  handleExpansion(): void {
    if (!this.approved) {
      return;
    }

    this.expanded = !this.expanded;

    this.expansionChange.emit(this.expanded);
  }

  /*

  public showCatalogDetails(): void {
    if (this.supplier.catalogs.length === 1) {
      this.mySuppliersDialogService.openMobileCatalogDetails({
        supplierName: this.supplier.companyName,
        website: this.supplier.website,
        isAutoOrder: this.supplier.isAutoOrder,
        hasAccessToContacts: this.hasAccessToReadContacts,
        catalog: this.supplier.catalogs[0],
      });
    } else {
      this.mySuppliersDialogService.openMobileCatalogDetailsDialog({
        catalogList: this.supplier.catalogs,
        supplierName: this.supplier.companyName,
        website: this.supplier.website,
        isAutoOrder: this.supplier.isAutoOrder,
        hasAccessToReadContacts: this.hasAccessToReadContacts,
      });
    }
  } */

  public showCatalogDetails(): void {
    this.catalog.pipe(take(1), untilDestroyed(this)).subscribe((catalog) => {
      this.mySuppliersDialogService.openCatalogInformationDialog({
        retailerToCatalog: this.retailerToCatalog,
        supplierInfo: this.supplier,
        taskName: catalog.name,
      });
    });
  }

  public handleCatalogUnavailable(): void {
    const message: string = this.translateService.instant('MY_SUPPLIERS.TOASTS.CATALOG_UNAVAILABLE');
    this.notificationService.warning(message);
  }

  public handleOpenStorefront(): void {
    this.navigationService.navigateToStorefront(Number(this.supplier.userId), this.supplier.handle);
  }

  public get catalog(): Observable<SupplierTask> {
    return this.mySuppliersCacheService.getCatalogFromCache(this.retailerToCatalog.catalogId);
  }

  public get managedProductNumber(): Observable<ManagedProductNumber> {
    return this.mySuppliersCacheService.getManagedProductNumberFromCache(this.retailerToCatalog.catalogId);
  }

  public get wholesalePriceAndOrderNumber(): Observable<WholesalePriceAndOrderNumber> {
    return this.mySuppliersCacheService.getWholesalePriceAndOrderNumberFromCache(this.retailerToCatalog.catalogId);
  }
}
