import { Component, Input, OnInit } from '@angular/core';
import { CatalogGatewayService } from 'app/service/catalog/catalog-gateway.service';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { SearchSessionMapperService } from 'app/service/search-session/search-session-mapper.service';
import { SearchSessionService } from 'app/service/search-session/search-session.service';
import { SupplierSearchGatewayService } from 'app/service/supplier-search/supplier-search-gateway.service';
import { SupplierGatewayService } from 'app/service/suppliers/supplier-gateway.service';
import { SupplierTaskService } from 'app/service/suppliers/supplier-task.service';
import { MarketplaceFilter, SearchProductType } from 'app/vo/search-product-vo';
import { SupplierWithBadgesDto } from 'app/vo/supplier/supplier-with-badges-dto';
import { isEqual, shuffle } from 'lodash';
import { catchError, filter, iif, Observable, of, Subject, switchMap, take, takeUntil, tap, throwError } from 'rxjs';
import { SupplierCard } from '../../supplier-card/supplier-card.component';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { ShippingPreferencesService } from '../../../../service/preference/shipping-preferences.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-recommended-suppliers-slider',
  templateUrl: './recommended-suppliers-slider.component.html',
  styleUrls: ['./recommended-suppliers-slider.component.scss'],
})
export class RecommendedSuppliersSliderComponent implements OnInit {
  @Input() selectedEcom: EcomVO;

  hasSessionData: boolean;
  suppliers: SupplierCard[];
  supplierImages: Record<number, string[]>;
  isLoading = true;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private catalogGatewayService: CatalogGatewayService,
    private supplierTaskService: SupplierTaskService,
    private supplierSearchGatewayService: SupplierSearchGatewayService,
    private supplierGatewayService: SupplierGatewayService,
    private searchSessionMapperService: SearchSessionMapperService,
    private searchSessionService: SearchSessionService,
    private navigationEventService: NavigationEventService,
    private shippingPreferencesService: ShippingPreferencesService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getSessionData();
  }

  private getSessionData(): void {
    this.shippingPreferencesService.hasPreferences$
      .pipe(
        untilDestroyed(this),
        omitNullOrUndefined(),
        tap(() => (this.isLoading = true)),
        filter((hasShippingPreferences: boolean) => isEqual(hasShippingPreferences, true)),
        switchMap(() => this.searchSessionService.searchData$),
        tap((data) => {
          this.hasSessionData = data.some((x) => x.search_datas.length > 0);
        }),
        filter((data) => !!data && data.length > 0),
        switchMap((sessionData) => this.searchSessionMapperService.getMarketplaceFilter(sessionData)),
        catchError(() => of(null)),
        switchMap((marketplaceFilter) => this.getSuppliers(marketplaceFilter)),
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((response: SupplierWithBadgesDto[]): void => {
        this.mapSuppliers(response);
        this.isLoading = false;
      });
  }

  private getSuppliers(marketplaceFilter: MarketplaceFilter): Observable<SupplierWithBadgesDto[]> {
    return this.supplierSearchGatewayService
      .getSupplierIdsBySearch(
        this.selectedEcom,
        { ...marketplaceFilter, autoOrder: 'true' },
        {
          from: 0,
          size: 18,
        },
        SearchProductType.FULL_TEXT
      )
      .pipe(
        switchMap((supplierIds) =>
          iif(
            () => !!supplierIds && supplierIds.content?.length > 0,
            of(supplierIds),
            throwError(() => null)
          )
        ),
        tap((supplierIds) => this.getSupplierImages(supplierIds.content)),
        switchMap((supplierIds) => this.supplierGatewayService.getSuppliers(supplierIds.content)),
        takeUntil(this.navigationEventService.onNavigationStart)
      );
  }

  private getSupplierImages(supplierIds: number[]): void {
    this.supplierGatewayService
      .getRandomSupplierProductImages(supplierIds)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe((imageUrls) => {
        this.supplierImages = this.supplierGatewayService.mapRandomSupplierVosToRecord(imageUrls);
      });
  }

  private mapSuppliers(similarSuppliers: SupplierWithBadgesDto[]): void {
    const userIds = similarSuppliers.map((supplier) => supplier.userId);
    this.catalogGatewayService
      .getCatalogCategories(userIds)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe((mainCategories) => {
        this.suppliers = shuffle(
          this.supplierGatewayService.mapSupplierDtoToSupplierCardModel(similarSuppliers, mainCategories)
        );
      });
  }
}
