export const NotificationSettings: NotificationSettingsConfig = {
  1: [
    {
      id: 'products',
      translateKey: 'NOTIFICATION_SETTINGS.PRODUCTS',
      email: true,
      web: true,
    },
    {
      id: 'retailers',
      translateKey: 'NOTIFICATION_SETTINGS.RETAILERS',
      email: true,
      web: true,
    },
    {
      id: 'orders',
      translateKey: 'NOTIFICATION_SETTINGS.ORDERS',
      email: true,
      web: true,
    },
    {
      id: 'review',
      translateKey: 'NOTIFICATION_SETTINGS.REVIEW',
      email: true,
      web: true,
    },
  ],
  2: [
    {
      id: 'marketplace',
      translateKey: 'NOTIFICATION_SETTINGS.MARKETPLACE',
      email: true,
      web: true,
    },
    {
      id: 'datafeed_manager',
      translateKey: 'NOTIFICATION_SETTINGS.DATAFEED_MANAGER',
      email: true,
      web: true,
    },
    {
      id: 'orders',
      translateKey: 'NOTIFICATION_SETTINGS.ORDERS',
      email: true,
      web: true,
    },
    {
      id: 'subscriptions',
      translateKey: 'NOTIFICATION_SETTINGS.SUBSCRIPTIONS',
      email: true,
      web: true,
    },
    {
      id: 'review',
      translateKey: 'NOTIFICATION_SETTINGS.REVIEW',
      email: true,
      web: true,
    },
  ],
  3: [
    {
      id: 'retailer',
      translateKey: 'NOTIFICATION_SETTINGS.RETAILER',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'supplier',
      translateKey: 'NOTIFICATION_SETTINGS.SUPPLIER',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'order',
      translateKey: 'NOTIFICATION_SETTINGS.ORDER',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'subscriptions',
      translateKey: 'NOTIFICATION_SETTINGS.SUBSCRIPTIONS',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'review',
      translateKey: 'NOTIFICATION_SETTINGS.REVIEW',
      email: true,
      web: true,
    },
    {
      id: 'retailersOfSupplier',
      translateKey: 'NOTIFICATION_SETTINGS.RETAILERS_OF_SUPPLIER',
      email: true,
      web: true,
    },
    {
      id: 'taskErrorDatafeed',
      translateKey: 'NOTIFICATION_SETTINGS.TASK_ERROR_DATAFEED',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'taskErrorPublic',
      translateKey: 'NOTIFICATION_SETTINGS.TASK_ERROR_PUBLIC',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'variantsLimit',
      translateKey: 'NOTIFICATION_SETTINGS.VARIANTS_LIMIT',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'getAllError',
      translateKey: 'NOTIFICATION_SETTINGS.GET_ALL_ERROR',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'supplier_charge_success_admin',
      translateKey: 'NOTIFICATION_SETTINGS.SUPPLIER_CHARGE_SUCCESS_ADMIN',
      email: true,
      web: true,
      slack: true,
    },
    {
      id: 'supplier_charge_failed_admin',
      translateKey: 'NOTIFICATION_SETTINGS.SUPPLIER_CHARGE_FAILED_ADMIN',
      email: true,
      web: true,
      slack: true,
    },
  ],
};

export interface NotificationSettingsOptions {
  id: string;
  translateKey: string;
  email: boolean;
  web: boolean;
  slack?: boolean;
}

export interface NotificationSettingsConfig {
  [key: number]: NotificationSettingsOptions[];
}
