<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
  <div class="typography-body-large-1">
    {{ 'IMPORT_LIST.PRICING.' + (isDatafeed ? 'DATAFEED' : 'NON_DATAFEED') + '_DESCRIPTION' | translate }}
  </div>

  <mat-form-field class="input-without-padding" fxFlex="0 0 250px" appearance="fill">
    <mat-label>{{ 'IMPORT_LIST.PRICING_TYPE_SETTINGS.LABEL' | translate }}</mat-label>
    <mat-select [(ngModel)]="selected" (ngModelChange)="onChange($event)" [disabled]="locked">
      <mat-option [value]="OPTION_RETAIL_PRICE">{{
        'IMPORT_LIST.PRICING_TYPE_SETTINGS.OPTIONS.PRICE' | translate
      }}</mat-option>
      <mat-option [value]="OPTION_PRICE">{{
        'IMPORT_LIST.PRICING_TYPE_SETTINGS.OPTIONS.RETAIL_PRICE' | translate
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
