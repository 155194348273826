import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-inc-dec-line',
  templateUrl: './inc-dec-line.component.html',
  styleUrls: ['./inc-dec-line.component.scss'],
})
export class IncDecLineComponent implements OnInit {
  @Input()
  percentage: number;

  constructor() {}

  ngOnInit(): void {}
}
