import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MySuppliersComponent } from './my-suppliers/my-suppliers.component';
import { MySuppliersTableComponent } from './my-suppliers-table/my-suppliers-table.component';
import { ConditionLoadingDirective } from '../../../shared/directives/condition-loading.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { SearchbarCustomComponent } from '../../../shared/components/searchbar-custom/searchbar-custom.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MySuppliersCatalogsTableComponent } from './my-suppliers-table/my-suppliers-catalogs-table/my-suppliers-catalogs-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { SimplePaginatorWithArrowsComponent } from '../../../shared/components/paginations/simple-paginator-with-arrows/simple-paginator-with-arrows.component';
import { ErrorMessageComponent } from '../../../shared/components/error-message/error-message.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MySuppliersMobileComponent } from './my-suppliers-mobile/my-suppliers-mobile.component';
import { MySuppliersMobileItemComponent } from './my-suppliers-mobile/my-suppliers-mobile-item/my-suppliers-mobile-item.component';
import { MatDividerModule } from '@angular/material/divider';
import { MySuppliersMobileCatalogListComponent } from './my-suppliers-mobile/my-suppliers-mobile-catalog-list/my-suppliers-mobile-catalog-list.component';
import { CustomDialogWrapperComponent } from '../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { MySuppliersDialogService } from './service/my-suppliers-dialog.service';
import { RouterLink } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MySuppliersMobileCatalogDetailsComponent } from './my-suppliers-mobile/my-suppliers-mobile-catalog-details/my-suppliers-mobile-catalog-details.component';
import { SanitizeUrlPipe } from '../../../shared/pipes/sanitize-url.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SanitizeHtmlPipe } from '../../../shared/pipes/sanitize-html.pipe';
import { BadgeCustomComponent } from '../../../shared/components/badge-custom/badge-custom.component';
import { StatusTextColorPipe } from '../../../shared/pipes/status-text-color.pipe';
import { StatusColorPipe } from '../../../shared/pipes/status-color.pipe';
import { GetUserReviewPipe } from './pipe/get-user-review.pipe';
import { NoSearchResultsComponent } from '../../../shared/components/no-search-results/no-search-results.component';
import { OpenLinkWrapperComponent } from 'app/shared/components/open-link-wrapper/open-link-wrapper.component';
import { ContactSupplierButtonComponent } from '../../../shared/components/contact-supplier-button/contact-supplier-button.component';
import { CustomTableComponent } from 'app/shared/components/custom-table/custom-table.component';
import { CatalogApproveStatusBadgeComponent } from 'app/shared/components/catalog-approve-status-badge/catalog-approve-status-badge.component';
import { MySuppliersRetailersModule } from 'app/shared/components/my-suppliers-retailers/my-suppliers-retailers.module';
import { MySuppliersRestService } from './service/my-suppliers-rest.service';
import { SkeletonModule } from '../../../shared/components/skeleton/skeleton.module';
import { GetSupplierFromCacheByCatalogIdPipe } from './pipe/get-supplier-from-cache-by-catalog-id.pipe';
import { GetTaskFromCacheByIdPipe } from './pipe/get-task-from-cache-by-id.pipe';
import { GetManagedProductNumberFromCacheByCatalogIdPipe } from './pipe/get-managed-product-number-from-cache-by-catalog-id.pipe';
import { GetOrderInfoFromCacheByCatalogIdPipe } from './pipe/get-order-info-from-cache-by-catalog-id.pipe';
import { MySuppliersRoutingModule } from './my-suppliers.routing.module';
import { NeedSubscriptionBadgeComponent } from 'app/shared/components/need-subscription-badge/need-subscription-badge.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    MySuppliersComponent,
    MySuppliersTableComponent,
    MySuppliersCatalogsTableComponent,
    MySuppliersMobileComponent,
    MySuppliersMobileItemComponent,
    MySuppliersMobileCatalogListComponent,
    MySuppliersMobileCatalogDetailsComponent,
    GetSupplierFromCacheByCatalogIdPipe,
    GetTaskFromCacheByIdPipe,
    GetManagedProductNumberFromCacheByCatalogIdPipe,
    GetOrderInfoFromCacheByCatalogIdPipe,
  ],
  providers: [MySuppliersDialogService, MySuppliersRestService],
  imports: [
    CommonModule,
    ConditionLoadingDirective,
    MatProgressSpinnerModule,
    FlexModule,
    SearchbarCustomComponent,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    TranslateModule,
    SimplePaginatorWithArrowsComponent,
    ErrorMessageComponent,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatDividerModule,
    CustomDialogWrapperComponent,
    RouterLink,
    MatDialogModule,
    SanitizeUrlPipe,
    ExtendedModule,
    CustomDatePipe,
    SanitizeHtmlPipe,
    BadgeCustomComponent,
    StatusTextColorPipe,
    StatusColorPipe,
    GetUserReviewPipe,
    NoSearchResultsComponent,
    OpenLinkWrapperComponent,
    ContactSupplierButtonComponent,
    CustomTableComponent,
    CatalogApproveStatusBadgeComponent,
    MySuppliersRetailersModule,
    SkeletonModule,
    MySuppliersRoutingModule,
    NeedSubscriptionBadgeComponent,
    MatTooltipModule,
  ],
})
export class MySuppliersNewModule {}
