<div
  class="category-selector-popup-container"
  fxLayoutAlign="center stretch"
  [fxLayout]="isOnMobile ? 'column' : 'row'"
  fxLayoutGap="12px"
>
  <div fxFlex="4 0 0" fxLayout="column">
    <ng-container *ngIf="!!selectedInnerCategory">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="cursor-pointer pl-8 pt-8 pb-4">
        <mat-icon class="s-12">arrow_back_ios</mat-icon>
        <a (click)="handleBackToButtonClicked()">
          {{ 'CATEGORIES_SELECTOR.BACK_TO' | translate : { name: parentCategory.id | categoryNameById | async } }}
        </a>
      </div>
    </ng-container>
    <div fxLayout="row">
      <div fxFlex="1 0 0">
        <app-category-selector-popup-recommended
          [category]="!!selectedInnerCategory ? selectedInnerCategory : mainCategory"
          [filterSort]="selectedSort"
          [selectedFilterCategoryId]="selectedFilterCategoryId"
          (filterSelected)="filterSelected.emit($event)"
        ></app-category-selector-popup-recommended>
      </div>
      <div fxFlex="3 0 0" class="column">
        <ng-container *ngIf="selectedInnerCategory; else levelOneTitle">
          <ng-container [ngTemplateOutlet]="innerTitle"></ng-container>
        </ng-container>
        <div class="submenus">
          <ng-container
            *ngFor="let category of selectedInnerCategory ? selectedInnerCategory.children : mainCategory.children"
          >
            <div class="submenu" (click)="handleCategoryClicked(category)">
              {{ category.id | categoryNameById | async }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <mat-divider [vertical]="!isOnMobile"></mat-divider>
  <div fxFlex="1 0 0" class="column">
    <div class="title">{{ 'CATEGORIES_SELECTOR.TOP_SUPPLIERS' | translate }}</div>
    <div fxLayout="column" fxLayoutGap="4px">
      <ng-container *ngIf="!loading; else supplierSkeletons">
        <ng-container *ngFor="let supplier of topSuppliersForCategory">
          <app-supplier-simple-item
            class="px-16"
            [name]="supplier.companyName"
            [imageUrl]="supplier.userId | supplierLogoFromUserid"
            (filterClick)="handleSupplierClicked(supplier)"
          >
          </app-supplier-simple-item>
        </ng-container>
      </ng-container>
      <ng-template #supplierSkeletons>
        <div *ngFor="let _ of [].constructor(4)" class="px-16">
          <app-supplier-simple-item-skeleton></app-supplier-simple-item-skeleton>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!isOnMobile" fxFlex="1 0 0">
    <div
      class="image-container"
      [style.background-image]="'url(' + (mainCategory.id | categoryImageFromId) + ')'"
    ></div>
  </div>
</div>
<ng-template #levelOneTitle>
  <div class="title">{{ mainCategory.id | categoryNameById | async }}</div>
</ng-template>
<ng-template #innerTitle>
  <div class="w-100p">
    <div class="title w-100p" fxLayoutAlign="space-between center" fxLayoutGap="4px">
      <div>{{ selectedInnerCategory.id | categoryNameById | async }}</div>
    </div>
  </div>
</ng-template>
