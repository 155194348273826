import { Component, Input } from '@angular/core';

import type { RetailerToCatalog } from '../model/retailer-to-catalog';

@Component({
  selector: 'app-my-suppliers-mobile',
  templateUrl: './my-suppliers-mobile.component.html',
  styleUrls: ['./my-suppliers-mobile.component.scss'],
})
export class MySuppliersMobileComponent {
  @Input() content: RetailerToCatalog[];

  @Input() hasAccessToChat: boolean;

  @Input() hasAccessToReadContacts: boolean;

  public expandedItem: number = null;

  public handleItemExpansion(supplierId: number, isExpanded: boolean): void {
    this.expandedItem = !!isExpanded ? supplierId : null;
  }
}
