<div class="ai-chat-header" fxLayoutAlign="space-between center">
  <div class="typography-body-3">Syncee Search AI</div>
  <div fxLayoutAlign="center center">
    <button mat-icon-button (click)="changeLayout()" *ngIf="layoutChangeIcon | async as icon">
      <mat-icon class="s-20">{{ icon }}</mat-icon>
    </button>
    <button mat-icon-button (click)="aiChatService.closeChat()">
      <mat-icon class="s-20">close</mat-icon>
    </button>
  </div>
</div>
