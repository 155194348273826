<ng-container *ngIf="widgets | async as widgetFilters; else spinner">
  <ng-container *ngFor="let widgetFilter of widgetFilters">
    <app-products-row-widget
      [title]="widgetFilter.titles | widgetTranslatePipe : 'title'"
      [widgetFilter]="widgetFilter.filterData"
      [wrapperClass]="'content-row-padding-lt-xl content-row-max-width'"
    ></app-products-row-widget>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <div class="content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-products-list-slider-box></app-skeleton-products-list-slider-box>
  </div>
</ng-template>
