import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { CatalogApproveStatus } from 'app/service/catalog-sidebar/catalog-sidebar.service';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { SearchProductVO } from '../../../vo/search-product-vo';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  @Input() product: SearchProductVO;
  @Input() ecomCurrency: string;
  @Input() catalogStatus: CatalogApproveStatus;
  productPath: string;

  constructor(private navigationService: NavigationService, private authManagerService: AuthenticationManagerService) {}

  ngOnInit(): void {
    this.productPath = this.getProductPath();
  }

  private getProductPath(): string {
    return this.navigationService.getProductPath(this.product.ID, this.product.TITLE);
  }

  navigateToStorefront(): void {
    this.navigationService.navigateToStorefront(this.product.USER_ID, this.product.SUPPLIER?.handle);
  }

  openRegistration(): void {
    this.authManagerService.emitOpenRegistrationDialog(
      AuthDialogType.RETAILER,
      undefined,
      'PRODUCT_CARD_ACTION_DEFAULT.REGISTRATION_REDIRECT.TITLE',
      'PRODUCT_CARD_ACTION_DEFAULT.REGISTRATION_REDIRECT.DESCRIPTION'
    );
  }
}
