import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { SupplierTask } from '../../vo/supplier/supplier-task';
import { SearchProductVO } from '../../vo/search-product-vo';
import { BasicPagination } from '../../vo/pagination/basic-pagination';
import { SpringPage } from '../../vo/pagination/spring-page';

@Injectable({
  providedIn: 'root',
})
export class SupplierTaskService {
  readonly userService = 'userService';

  constructor(private springRestService: SpringRestService) {}

  getCatalogBySeoUrl(seoUrl: string): Observable<SupplierTask> {
    return this.springRestService.get(this.userService, '/Supplier/Catalog/FindBySeoUrl', { seoUrl }, true);
  }

  getCatalogById(id: number): Observable<SupplierTask> {
    return this.springRestService.get(this.userService, `/Supplier/Catalog/${id}`, undefined, true);
  }

  getSeoUrlsByUserIds(userIds: number[]): Observable<SeoUrlsBySupplierDto[]> {
    return this.springRestService.get(this.userService, '/Supplier/Catalog/SeoUrl', { userId: userIds }, true);
  }

  getAllApprovedCatalogsByUserIds(...userIds: number[]): Observable<SupplierTask[]> {
    return this.springRestService.get(this.userService, '/Supplier/Catalog/Approved', { userIds }, true);
  }

  getCategoryMappingByIds(catalogIds: number[]): Observable<TaskCategoryMappingDto[]> {
    return this.springRestService.get(this.userService, '/Supplier/Catalog/Mapping', { catalogIds }, true);
  }

  getPreviewProductList(
    taskId: number,
    pagination?: BasicPagination,
    skipNotification?: boolean
  ): Observable<SpringPage<SearchProductVO>> {
    return this.springRestService.get(
      MicroserviceNames.TASK,
      `/api/v1/task/${taskId}/product-preview-paginated`,
      { ...pagination },
      true,
      undefined,
      skipNotification
    );
  }
}

export interface SeoUrlsBySupplierDto {
  userId: number;
  seoUrls: string[];
}

export interface TaskCategoryMappingDto {
  catalogId: number;
  categoryId: number;
  productCategory: string;
}
