<form
  id="URLUploadCard"
  #urlDownloadFileForm="ngForm"
  novalidate
  class="simple-form"
  fxLayout="column"
  fxLayoutGap="20px"
  fxLayoutAlign="center stretch"
>
  <div class="h5">{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.SOURCE' | translate }}</div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
    <!--URL-->
    <mat-form-field id="urlStringInput" fxFlex="1 0 0" appearance="fill" class="input-without-padding-except-error">
      <mat-label>{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.SOURCE' | translate }}</mat-label>
      <input
        id="urlString"
        name="url"
        matInput
        placeholder="{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.URL_PLACEHOLDER' | translate }}"
        #urlString="ngModel"
        [(ngModel)]="urlUploadParams.remotePath"
        required
        class="user-input"
      />
      <mat-error>{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.URL_REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field id="fileTypeSelect" appearance="fill" class="input-without-padding">
      <mat-label>{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.FILE_TYPE_PLACEHOLDER' | translate }}</mat-label>
      <mat-select
        [disabled]="disableFileTypeSelector"
        [(ngModel)]="urlUploadParams.extension"
        name="type"
        (valueChange)="typeChanged($event)"
      >
        <mat-option *ngFor="let fileExt of fileExtensions" [value]="fileExt.type">
          {{ fileExt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div id="downloadButton" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
    <button
      [disabled]="urlDownloadFileForm.invalid || disableDownloadBtn"
      mat-button
      class="syncee-blue-button"
      (click)="downloadFile()"
    >
      {{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.UPLOAD' | translate }}
    </button>
  </div>
</form>
