import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskFilterComponent } from './task-filter.component';
import { FilterNewModule } from '../filter-new/filter-new.module';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ConditionLoadingDirective } from '../../shared/directives/condition-loading.directive';
import { TaskWizardStepLockedWarningComponent } from '../../shared/components/task-wizard-step-locked-warning/task-wizard-step-locked-warning.component';

@NgModule({
  declarations: [TaskFilterComponent],
  exports: [TaskFilterComponent],
  imports: [
    CommonModule,
    FilterNewModule,
    FlexModule,
    TranslateModule,
    ConditionLoadingDirective,
    TaskWizardStepLockedWarningComponent,
  ],
})
export class TaskFilterModule {}
