<div class="price-filter-container">
  <div fxLayoutAlign="space-between center">
    <div class="font-weight-700">{{ 'FILTER_COMMON.TITLES.PRICE' | translate }}</div>
    <div *ngIf="hasClearAll && (value.minPrice || value.maxPrice)" (click)="handleClearAllClicked()" class="clear-all">
      {{ 'FILTER_COMMON.SELECTOR.CONTROLS.CLEAR_ALL' | translate }}
    </div>
  </div>
  <mat-radio-group class="price-options-container" (change)="onValueChange($event.value)">
    <div class="predefined-prices">
      <mat-radio-button
        #preDefined
        *ngFor="let priceSet of predefinedPrices"
        [value]="{ minPrice: priceSet.min, maxPrice: priceSet.max }"
        [disableRipple]="true"
        (click)="handlePredefinedSelect()"
      >
        {{
          priceSet.template
            | translate
              : {
                  min: priceSet.calculatedMin | number : '1.0-2',
                  max: priceSet.calculatedMax | number : '1.0-2',
                  ecomCurrency: ecomCurrency
                }
        }}</mat-radio-button
      >
    </div>
    <mat-radio-button
      #userDefined
      [value]="{ minPrice: userDefinedPrices.min, maxPrice: userDefinedPrices.max }"
      [disableRipple]="true"
      [checked]="!!userDefinedPrices.min || !!userDefinedPrices.max"
    >
      <div class="user-defined-prices">
        <div>{{ 'FILTER_COMMON.SELECTOR.VALUES.PRICE_FILTER.FROM' | translate }}</div>
        <ng-container [ngTemplateOutlet]="fromPrice"></ng-container>
        <div>{{ ecomCurrency | currencyTransformToSymbol }}</div>
        <div>{{ 'FILTER_COMMON.SELECTOR.VALUES.PRICE_FILTER.TO' | translate }}</div>
        <ng-container [ngTemplateOutlet]="toPrice"></ng-container>
        <div>{{ ecomCurrency | currencyTransformToSymbol }}</div>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>

<ng-template #fromPrice>
  <mat-form-field appearance="outline" fxFlex="1 0 0" class="price-mat-form-field without-padding">
    <input
      matInput
      type="number"
      [min]="1"
      [value]="userDefinedPrices.min?.toString()"
      (input)="debouncedMinChange($event)"
      (change)="handleInputChangeEvent($event)"
    />
  </mat-form-field>
</ng-template>
<ng-template #toPrice>
  <mat-form-field appearance="outline" fxFlex="1 0 0" class="price-mat-form-field without-padding">
    <input
      matInput
      type="number"
      [min]="1"
      [value]="userDefinedPrices.max?.toString()"
      (input)="debouncedMaxChange($event)"
      (change)="handleInputChangeEvent($event)"
    />
  </mat-form-field>
</ng-template>
