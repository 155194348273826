<div class="carousel-container">
  <button class="arrow-btn left" (click)="scroll(-300)" *ngIf="hasButtons">
    <mat-icon class="s-16">{{ 'arrow_back_ios' }}</mat-icon>
  </button>
  <div #carousel class="carousel" horizontalScroll [style.padding]="hasButtons ? '0 40px' : 0">
    <ng-content></ng-content>
  </div>
  <button class="arrow-btn right" (click)="scroll(300)" *ngIf="hasButtons">
    <mat-icon class="s-16">{{ 'arrow_forward_ios' }}</mat-icon>
  </button>
</div>
