import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { TeamMember } from '../../vo/team-member/team-member';
import { RegisterTeamMemberVo } from '../../vo/team-member/register-team-member-vo';
import { LoginVO } from '../../vo/authentication/login-vo';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private readonly microServiceName: MicroserviceNames = MicroserviceNames.AUTH;
  constructor(private springRestService: SpringRestService) {}

  getTeamMembers(): Observable<TeamMember[]> {
    return this.springRestService.get(this.microServiceName, '/team-members', undefined, true);
  }

  getTeams(): Observable<TeamMember[]> {
    return this.springRestService.get(this.microServiceName, '/team-member/teams', undefined, true);
  }
  addTeamMember(email: string): Observable<HttpResponse<void>> {
    return this.springRestService.post(
      this.microServiceName,
      '/team-member/add',
      { email },
      { observe: 'response' as 'body', reportProgress: true },
      true
    );
  }

  registerTeamMember(data: RegisterTeamMemberVo): Observable<void> {
    return this.springRestService.post(this.microServiceName, '/team-member/register', data, undefined, true);
  }

  removeTeamMember(teamMemberId: number): Observable<void> {
    return this.springRestService.delete(this.microServiceName, `/team-member/remove/${teamMemberId}`, undefined, true);
  }

  loginToTeamMember(email: string): Observable<LoginVO> {
    return this.springRestService.post(this.microServiceName, `/team-member-login`, { email }, undefined, true);
  }
}
