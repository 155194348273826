import { Injectable } from '@angular/core';
import { CategoryMappingService, MappedCategory } from 'app/service/category-mapping/category-mapping.service';
import { PricingRange, PricingRow, PricingService } from 'app/service/pricing/pricing.service';
import { ImportListCollection } from 'app/shared/components/import-list-price-settings/model/import-list-collection';
import { ImportListPrice } from 'app/shared/components/import-list-price-settings/model/import-list-price';
import { ImportListPriceItem } from 'app/shared/components/import-list-price-settings/model/import-list-price-item';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RetailerImportListPricingService {
  constructor(private categoryMappingService: CategoryMappingService, private pricingService: PricingService) {}

  public getRounding(catalogId: number): Observable<string> {
    return this.pricingService.getRoundingByCatalogId(catalogId, 'RETAILER').pipe(
      map((rounding) => {
        return rounding.rounding;
      })
    );
  }

  public saveRounding(catalogId: number, roundingRule: string): Observable<void> {
    return this.pricingService.updateRounding({ id: catalogId, rounding: roundingRule }, 'RETAILER');
  }

  public savePricing(catalogId: number, importListPrice: ImportListPrice[]): Observable<void> {
    const pricing = importListPrice.reduce((acc, price) => {
      const pricingRange = price.priceItemList.reduce((acc2, priceItem) => {
        acc2[priceItem.from] = {
          perc: priceItem.percAmountOperator * priceItem.percentage,
          fix: priceItem.fixAmountOperator * priceItem.fixAmount,
        };
        return acc2;
      }, {});
      acc[price.collectionId] = pricingRange;
      return acc;
    }, {});
    return this.pricingService.updatePricing({ catalog_id: catalogId, pricing }, 'RETAILER');
  }

  public getCategories(catalogId: number): Observable<MappedCategory[]> {
    return this.categoryMappingService.getMappedCategories(catalogId);
  }

  public getPricing(catalogId: number): Observable<ImportListPrice[]> {
    return this.pricingService.getRetailerPricing(catalogId).pipe(
      map((pricing) => {
        const mappedPricing: ImportListPrice[] = Object.keys(pricing).map((key) => {
          const mappedPricingRows: ImportListPriceItem[] = [];
          const pricingRules = Object.keys(pricing[key]);
          pricingRules.forEach((rangeKey, rangeIndex) => {
            const pricingRange: PricingRange = pricing[key][rangeKey];
            const mappedPricingRow: ImportListPriceItem = {
              from: parseInt(rangeKey, 10),
              to: pricingRules[rangeIndex + 1] ? parseInt(pricingRules[rangeIndex + 1], 10) : null,
              percAmountOperator: pricingRange.perc >= 0 ? 1 : -1,
              percentage: Math.abs(pricingRange.perc),
              fixAmountOperator: pricingRange.fix >= 0 ? 1 : -1,
              fixAmount: Math.abs(pricingRange.fix),
            };

            mappedPricingRows.push(mappedPricingRow);
          });

          return {
            collectionId: key,
            priceItemList: mappedPricingRows,
          };
        });
        return mappedPricing;
      })
    );
  }

  fetchPricingRelatedData(catalogId: number): Observable<[ImportListCollection[], ImportListPrice[]]> {
    return forkJoin([this.getCategories(catalogId), this.getPricing(catalogId)]).pipe(
      map(([categories, pricing]) => {
        const importListCollections = categories.map((category) => {
          return {
            id: category.id,
            title: category.title,
            hasPriceSettings: pricing.some((price) => price.collectionId === category.id),
          } as ImportListCollection;
        });
        return [importListCollections, pricing] as [ImportListCollection[], ImportListPrice[]];
      })
    );
  }
}
