import { Injectable } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { StepWrapperComponent } from '../../main/step-wrapper/step-wrapper.component';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  public currentStepIndex = 0;
  public stepWrappers: StepWrapperComponent[] = [];
  private stepper: MatStepper;
  private steps: MatStep[];

  constructor() {}

  public setStepWrappers(stepWrappers: any): void {
    this.stepWrappers = stepWrappers;
  }

  public setStepper(stepper: MatStepper): void {
    this.stepper = stepper;
    this.subscribeToStepChange();
    this.setIndex();
  }

  public setMatSteps(steps: MatStep[]): void {
    this.steps = steps;
  }

  public next(): void {
    this.currentStepIndex = ++this.currentStepIndex;
    if (!Utils.isNullOrUndefined(this.stepper.selected)) {
      this.stepper.selected.completed = true;
    }
    this.setIndex();
  }

  public previous(): void {
    this.currentStepIndex = --this.currentStepIndex;
    this.setIndex();
  }

  public setIndex(): void {
    this.stepper.selectedIndex = this.currentStepIndex;
  }

  public setCurrentStep(index: number): void {
    this.currentStepIndex = index;
  }

  public isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  public isLastStep(): boolean {
    return this.currentStepIndex === this.stepWrappers.length - 1;
  }

  public reset(): void {
    this.setCurrentStep(0);
  }

  private subscribeToStepChange(): void {
    this.stepper.selectionChange.subscribe((event) => {
      this.currentStepIndex = event.selectedIndex;
      if (!Utils.isNullOrUndefined(this.stepWrappers[this.currentStepIndex])) {
        this.stepWrappers[this.currentStepIndex].reInitIfNeeded();
      }
    });
  }

  initStepperSteps(value: boolean): void {
    this.stepper.selectedIndex = 0;
    this.stepper.steps.forEach((step) => {
      step.completed = value;
    });
  }

  isStepCompletedState(index: number): 'done' | 'number' {
    return !!this.steps && !!this.steps[index] && this.steps[index].completed ? 'done' : 'number';
  }
}
