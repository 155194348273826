import { Action } from '@ngrx/store';

export enum LanguageActionTypes {
  LANGUAGE_CHANGE_START = '[Language] Language change start',
  USER_LANGUAGE_CHANGE_START = '[Language] User selected language change start',
  USER_LANGUAGE_CHANGE_SUCCESS = '[Language] User selected language change success',
  GUEST_LANGUAGE_CHANGE_START = '[Language] Guest selected language change start',
  GUEST_LANGUAGE_CHANGE_SUCCESS = '[Language] Guest selected language change success',
}

export interface LanguageChangePayload {
  countryCode: string;
  withNotification: boolean;
}

export class LanguageChangeStart implements Action {
  readonly type = LanguageActionTypes.LANGUAGE_CHANGE_START;
  constructor(public payload: LanguageChangePayload) {}
}

export class UserLanguageChangeStartAction implements Action {
  readonly type = LanguageActionTypes.USER_LANGUAGE_CHANGE_START;
  constructor(public payload: LanguageChangePayload) {}
}

export class UserLanguageChangeSuccessAction implements Action {
  readonly type = LanguageActionTypes.USER_LANGUAGE_CHANGE_SUCCESS;
  constructor(public payload: LanguageChangePayload) {}
}

export class GuestLanguageChangeStartAction implements Action {
  readonly type = LanguageActionTypes.GUEST_LANGUAGE_CHANGE_START;
  constructor(public payload: LanguageChangePayload) {}
}

export class GuestLanguageChangeSuccessAction implements Action {
  readonly type = LanguageActionTypes.GUEST_LANGUAGE_CHANGE_SUCCESS;
  constructor(public payload: LanguageChangePayload) {}
}

export type LANGUAGE_ACTIONS_ALL =
  | LanguageChangeStart
  | UserLanguageChangeStartAction
  | UserLanguageChangeSuccessAction
  | GuestLanguageChangeStartAction
  | GuestLanguageChangeSuccessAction;

export interface LanguageOption {
  code: string;
  name: string;
}
