<div class="search-bar-custom-container" [ngClass]="{ levi: isLevi }">
  <mat-form-field fxFlex="1 0 0" class="form-field select-without-label" appearance="fill" [floatLabel]="'never'">
    <input
      autocomplete="off"
      matInput
      type="text"
      [disabled]="disabled"
      [(ngModel)]="searchTerm"
      (ngModelChange)="debouncedSearchTermChange($event)"
      [placeholder]="placeholder"
    />
    <span matPrefix
      ><mat-icon [class]="'s-' + iconSize">{{ prefixIcon }}</mat-icon></span
    >
    <span
      matSuffix
      class="clear-button"
      *ngIf="hasClearButton && !!searchTerm && searchTerm.length > 0"
      (click)="handleClearClick()"
      ><mat-icon [class]="'s-' + iconSize">close</mat-icon></span
    >
  </mat-form-field>
</div>
