import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabNavComponent } from 'app/shared/components/tab-nav/tab-nav.component';
import { MyRetailersComponent } from './my-retailers.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MyRetailersRoutingModule } from './my-retailers-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [MyRetailersComponent],
  imports: [
    CommonModule,
    MyRetailersRoutingModule,
    MatButtonModule,
    TabNavComponent,
    RouterModule,
    TranslateModule,
    MatCheckboxModule,
  ],
})
export class MyRetailersModule {}
