import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { SupplierOrderLimit } from 'app/service/manage-orders/syncee-orders.service';
import { PaginateResponse } from 'app/service/rest/PaginateResponse';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { Utils } from 'app/utils/utils';
import { SearchProductVO, VariantVO } from 'app/vo/search-product-vo';
import { SupplierWithBadgesDto } from 'app/vo/supplier/supplier-with-badges-dto';
import { flatten } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatAll, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { HasCartService } from '../../../service/has-cart/has-cart.service';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { MicroserviceNames, SpringRestService } from '../../../service/rest/microservices/spring-rest.service';
import {
  OrderCountBySupplier,
  PAYMENT_STATUS,
  SupplierGatewayService,
} from '../../../service/suppliers/supplier-gateway.service';
import { CartItem } from '../models/CartItem';
import { CartItemDto } from '../models/CartItemDto';
import { CartLineItem } from '../models/CartLineItem';
import { CartLineItemDetails } from '../models/CartLineItemDetails';
import { CartRequestDto } from '../models/CartRequestDto';
import { SupplierWithPrice } from '../models/SupplierWithPrice';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public static ADD_TO_CART = '/CartItem';
  public static DELETE_CART_LINE_ITEMS = '/CartItem/delete';
  public static UPDATE_CART = '/CartItem';
  public static GET_CART_ITEMS = '/CartItem';
  public static GET_CART_ITEMS_COUNT = '/CartItem/CartLineItemCount';
  public static PATCH_CART_ITEM_QTY = '/CartItem/{cartLineItemId}';
  public static GET_SHIPPING_COST = '/ShippingCost/Calculate';

  private _cachedData: CartItem[] = [];
  private _page$ = new BehaviorSubject<number>(0);
  private _pageSize = 10;
  private _data$: Observable<CartItem[]>;
  private _refreshCachedData = new BehaviorSubject<void>(null);
  private _refreshCachedDataObs$ = new Observable<void>();
  private _cartLineItemsCount = new BehaviorSubject<number>(null);
  private _supplierOrderLimits$ = new BehaviorSubject<SupplierOrderLimit[]>(null);
  private _ordersCountBySupplier$ = new BehaviorSubject<OrderCountBySupplier[]>(null);

  private _openedSupplierCartItemsStatusSub = new BehaviorSubject<number[]>([]);
  private _openedSupplierCartItemsStatusObs = this._openedSupplierCartItemsStatusSub.asObservable();

  private readonly cacheTime = 1000; // 5 minutes

  constructor(
    private restService: SpringRestService,
    private supplierGService: SupplierGatewayService,
    private productSearchService: ProductSearchService,
    private _store: Store<AppState>,
    private hasCartService: HasCartService
  ) {
    if (!this.hasCartService.hasCart) {
      return;
    }
    this._refreshCachedDataObs$ = this._refreshCachedData.asObservable();
    this._store.select(isAuthenticatedSelector).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.getCartLineItemsCountFetch();
      } else {
        this.clearCartData();
      }
    });
  }

  deleteCartLineItemsAPI(cartLineItemIds: number[]): Observable<void> {
    return this.restService.post(
      MicroserviceNames.ORDER,
      CartService.DELETE_CART_LINE_ITEMS,
      cartLineItemIds,
      {},
      true
    );
  }

  patchCartLineItemQty(cartLineItemId: number, quantity: number): Observable<void> {
    return this.restService.patch(
      MicroserviceNames.ORDER,
      Utils.buildRestApiURL(CartService.PATCH_CART_ITEM_QTY, { cartLineItemId }),
      quantity,
      {},
      true
    );
  }

  addToCart(cartRequestDto: CartRequestDto): Observable<void> {
    return this.restService.post(MicroserviceNames.ORDER, CartService.ADD_TO_CART, cartRequestDto, {}, true).pipe(
      tap(() => {
        this.resetCacheData();
      })
    );
  }

  getCartItemsFetch(page, size = this._pageSize): Observable<PaginateResponse<CartItemDto>> {
    return this.restService.getPage(MicroserviceNames.ORDER, CartService.GET_CART_ITEMS, { page, size }, {}, true);
  }

  private getCartLineItemsCountFetch(): void {
    this.restService
      .get(MicroserviceNames.ORDER, CartService.GET_CART_ITEMS_COUNT, {}, true)
      .pipe(take(1))
      .subscribe((response) => {
        this._cartLineItemsCount.next(parseInt(response, 10));
      });
  }

  buildCartRequestDto(supplierId: number, variantId: string, qty: number, langCode: string): CartRequestDto {
    return <CartRequestDto>{
      supplierId: supplierId,
      variantId: variantId,
      quantity: qty,
      languageCode: langCode,
    };
  }

  getCartItems(): Observable<CartItem[]> {
    if (this._data$) {
      return this._data$;
    }
    this._data$ = this._page$.pipe(
      switchMap((page) =>
        this.getCartItemsFetch(page).pipe(
          map((response) => {
            return response.content as CartItemDto[];
          })
        )
      ),
      switchMap((cartItemDtos: CartItemDto[]) => {
        if (Utils.isNullOrUndefinedOrLengthZero(cartItemDtos)) {
          return of([]);
        }
        return forkJoin([this.fetchSupplierData(cartItemDtos), this.fetchProductsData(cartItemDtos)]).pipe(
          tap(([supplierData]) => {
            const supplierUserIds = supplierData.map((supplierItem) => supplierItem.userId);
            this._initSupplierOrderLimits(supplierUserIds);
            this._initOrdersCountBySupplier(supplierUserIds, PAYMENT_STATUS.SUCCESSFUL);
          }),
          map(([suppliersData, products]) => {
            return this.handleAdditionalCartData(cartItemDtos, suppliersData, products);
          })
        );
      }),
      catchError((err) => []),
      map((data: CartItem[]) => this._cachedData.concat(data)),
      tap((data) => {
        this._cachedData = data;
      }),
      switchMap(() => {
        return this._refreshCachedDataObs$;
      }),
      map(() => this._cachedData),
      shareReplay(1)
    );
    return this._data$;
  }

  private _initOrdersCountBySupplier(userIds: number[], status: PAYMENT_STATUS): void {
    this._supplierOrderLimits$
      .pipe(
        switchMap(() => this.supplierGService.getOrdersCountBySupplierIds(userIds, status)),
        take(1)
      )
      .subscribe((count) => {
        this._ordersCountBySupplier$.next(count);
      });
  }

  private _initSupplierOrderLimits(userIds: number[]): void {
    this._supplierOrderLimits$
      .pipe(
        switchMap(() => this.supplierGService.getSupplierOrderLimit(userIds)),
        take(1)
      )
      .subscribe((limits) => {
        this._supplierOrderLimits$.next(limits);
      });
  }

  getSupplierOrderLimitObs$(userId: number): Observable<SupplierOrderLimit[]> {
    return this._supplierOrderLimits$.asObservable().pipe(
      map((limits: SupplierOrderLimit[]) => {
        if (limits === null) {
          return null;
        }
        return limits?.filter((limit) => limit.userId === userId);
      })
    );
  }

  getOrdersCountBySupplierIdObs$(userId: number): Observable<OrderCountBySupplier> {
    return this._ordersCountBySupplier$.asObservable().pipe(
      map((counts: OrderCountBySupplier[]) => {
        return counts?.find((count) => count.supplierUserId === userId);
      })
    );
  }

  private handleAdditionalCartData(
    cartItemDtos: CartItemDto[],
    suppliersData: SupplierWithBadgesDto[],
    products: SearchProductVO[]
  ): CartItem[] {
    const cartItemsFinal: CartItem[] = [];
    cartItemDtos.forEach((cartItem: CartItemDto) => {
      let cartItemFinal: CartItem = null;
      const supplierData = suppliersData.find((sData) => sData.userId === cartItem.supplierId);
      cartItemFinal = { supplierId: cartItem.supplierId, supplierData, cartLineItems: cartItem.cartItems };
      cartItemFinal.cartLineItems.forEach((cartLineItem: CartLineItem) => {
        cartLineItem.details = this.getCartLineItemDetailsByVariantID(products, cartLineItem.variantId);
      });
      cartItemsFinal.push(cartItemFinal);
    });
    return cartItemsFinal;
  }

  private fetchSupplierData(cartItemDtos: CartItemDto[]): Observable<SupplierWithBadgesDto[]> {
    if (cartItemDtos.length < 1) {
      return of([]);
    }
    const supplierUserIds = cartItemDtos.map((cartItemDto) => cartItemDto.supplierId);
    return this.supplierGService.getSuppliers(supplierUserIds);
  }

  private fetchProductsData(cartItemDtos: CartItemDto[]): Observable<SearchProductVO[]> {
    const acc = {} as { [key: number]: string[] };
    cartItemDtos.forEach((cartItemDto) => {
      if (!acc[cartItemDto.supplierId]) {
        acc[cartItemDto.supplierId] = [];
      }

      const variantIds = cartItemDto.cartItems.map((cItem) => cItem.variantId);

      acc[cartItemDto.supplierId].push(...variantIds);
    });

    const obs = Object.keys(acc).map((supplierId) => {
      return this.productSearchService.getProductsByVariantIdsAndSupplierUserId(
        acc[supplierId],
        parseInt(supplierId, 10)
      );
    });

    return forkJoin(obs).pipe(
      map((resp) => {
        return flatten(resp);
      })
    );
  }

  private getCartLineItemDetailsByVariantID(products: SearchProductVO[], variantId: string): CartLineItemDetails {
    return products.reduce((variant: CartLineItemDetails, product: SearchProductVO) => {
      const tempVariant: VariantVO = product.VARIANTS.find((prodVariant) => prodVariant.VARIANT_ID == variantId);
      if (tempVariant) {
        const variantTitle = Utils.buildVariantTitle(
          tempVariant.SHOPIFY_OPTION_VALUE_1,
          tempVariant.SHOPIFY_OPTION_VALUE_2,
          tempVariant.SHOPIFY_OPTION_VALUE_3
        );
        return {
          id: product.ID,
          imageSrc: tempVariant.V_IMAGES?.length > 0 ? tempVariant.V_IMAGES[0] : product.IMAGES[0],
          title: product.TITLE,
          sku: tempVariant.SKU,
          price: tempVariant.PRICE,
          discountedPrice: tempVariant.DISCOUNTED_PRICE,
          originalPrice: tempVariant.ORIGINAL_PRICE,
          variantTitle,
          taskId: Utils.explodeSynceeID(product.ID).taskID,
          qty: tempVariant.QTY,
        } as CartLineItemDetails;
      }
      return variant;
    }, null);
  }

  getCartSuppliersWithPrice(): Observable<SupplierWithPrice[]> {
    return this.getCartItems().pipe(
      map((cartItems) =>
        cartItems.map((cartItem) => {
          return {
            shippingCost: cartItem.shippingCost,
            supplierData: cartItem.supplierData,
            cartLineItemsIds: cartItem.cartLineItems.map((cartLineItem) => cartLineItem.id),
            sumPrice: cartItem.cartLineItems
              .map((cartLineItem) => cartLineItem.quantity * (cartLineItem.details?.price || 0))
              .reduce((sum, price) => sum + price, 0),
          };
        })
      )
    );
  }

  nextPage(page?: number): void {
    if (page === undefined) {
      const sub = this._page$.pipe(take(1)).subscribe((pageNum) => this._page$.next(pageNum + 1));
      sub.unsubscribe();
    } else {
      this._page$.next(page);
    }
  }

  getCartLineItemsCount(): Observable<number> {
    return this._cartLineItemsCount.asObservable();
  }

  updateCacheData(): void {
    this.getCartLineItemsCountFetch();
    this._refreshCachedData.next();
  }

  resetCacheData(): void {
    this._cachedData = [];
    this.getCartLineItemsCountFetch();
    this._page$.next(0);
  }

  clearCartData(): void {
    this._cachedData = [];
    this._data$ = null;
  }

  deleteCartLineItems(cartLineItemIds: number[]): Observable<void> {
    return this.deleteCartLineItemsAPI(cartLineItemIds).pipe(
      tap(() => {
        this.resetCacheData();
      })
    );
  }

  updateCartLineItemQty(cartLineItemId: number, qty: number): Observable<void> {
    return this.patchCartLineItemQty(cartLineItemId, qty).pipe(
      tap(() => {
        const cartLineItem = this.getCartLineItem(cartLineItemId);
        cartLineItem.quantity = qty;
        this.updateCacheData();
      })
    );
  }

  getCartLineItem(cartLineItemId: number): CartLineItem {
    let cartLineItem: CartLineItem = null;
    this._cachedData.forEach((cartItem: CartItem) => {
      const lineItem = cartItem.cartLineItems.find((clItem: CartLineItem) => clItem.id === cartLineItemId);
      if (lineItem) {
        cartLineItem = lineItem;
      }
    });
    return cartLineItem;
  }

  getOpenedSupplierCartItemsStatusObs(): Observable<number[]> {
    return this._openedSupplierCartItemsStatusObs;
  }

  removeOpenedSupplierCartItemsStatus(supplierId: number): void {
    this.getOpenedSupplierCartItemsStatusObs()
      .pipe(take(1))
      .subscribe((ids) => {
        this._openedSupplierCartItemsStatusSub.next(ids.filter((id) => id !== supplierId));
      });
  }

  addOpenedSupplierCartItemsStatus(supplierId: number): void {
    this.getOpenedSupplierCartItemsStatusObs()
      .pipe(take(1))
      .subscribe((ids) => {
        this._openedSupplierCartItemsStatusSub.next([...ids, supplierId]);
      });
  }

  getCartItemLineItemsTotalPrice(cartItem: CartItem): number {
    return cartItem.cartLineItems
      .map((cartLineItem) => cartLineItem.quantity * (cartLineItem.details?.price || 0))
      .reduce((sum, price) => sum + price, 0);
  }
}
