import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TaskwizardComponent } from './taskwizard.component';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { TaskwizardUpdateService } from '../../service/taskwizard/taskwizard-update.service';
import { AddfilesModule } from './addfiles/addfiles.module';
import { AlertMultiComponent } from '../../shared/components/alert-multi/alert-multi.component';
import { ComponentHeaderComponent } from '../../shared/components/component-header/component-header.component';
import { CustomPanelComponent } from '../../shared/components/custom-panel/custom-panel.component';
import { ImportListPriceSettingsModule } from '../../shared/components/import-list-price-settings/import-list-price-settings.module';
import { SingleAlertWithIconComponent } from '../../shared/components/single-alert-with-icon/single-alert-with-icon.component';
import { AdminCommentDirective } from '../../shared/directives/admin-comment.directive';
import { DraggableModule } from '../draggable/draggable.module';
import { EcwidDfTaskWizardComponent } from '../ecwid-df-task-wizard/ecwid-df-task-wizard.component';
import { EcwidMappingSettingsComponent } from '../ecwid-df-task-wizard/ecwid-mapping-settings/ecwid-mapping-settings.component';
import { FilterModule } from '../filter/filter.module';
import { JumpsellerDfTaskWizardComponent } from '../jumpseller-df-task-wizard/jumpseller-df-task-wizard.component';
import { JumpsellerSettingsComponent } from '../jumpseller-df-task-wizard/jumpseller-settings/jumpseller-settings.component';
import { DestinationComponent } from '../retailer-export-task-wizard/destination/destination.component';
import { RetailerExportConnectFtpComponent } from '../retailer-export-task-wizard/destination/retailer-export-connect-ftp/retailer-export-connect-ftp.component';
import { CustomFieldSettingsComponent } from '../retailer-export-task-wizard/field-mapping/custom-field/custom-field-settings/custom-field-settings.component';
import { CustomFieldComponent } from '../retailer-export-task-wizard/field-mapping/custom-field/custom-field.component';
import { FieldMappingComponent } from '../retailer-export-task-wizard/field-mapping/field-mapping.component';
import { NewFieldMappingModule } from '../retailer-export-task-wizard/new-field-mapping/new-field-mapping.module';
import { RetailerExportTaskWizardComponent } from '../retailer-export-task-wizard/retailer-export-task-wizard.component';
import { SettingAndSummaryComponent } from '../retailer-export-task-wizard/settings-and-summary/setting-and-summary.component';
import { RetailerJumpsellerVuSettingsComponent } from '../retailer-jumpseller-vu-task-wizard/retailer-jumpseller-vu-settings/retailer-jumpseller-vu-settings.component';
import { RetailerJumpsellerVuSummaryComponent } from '../retailer-jumpseller-vu-task-wizard/retailer-jumpseller-vu-summary/retailer-jumpseller-vu-summary.component';
import { RetailerJumpsellerVuTaskWizardComponent } from '../retailer-jumpseller-vu-task-wizard/retailer-jumpseller-vu-task-wizard.component';
import { RetailerTaskWizardSettingsComponent } from '../retailer-task-wizard/retailer-task-wizard-settings/retailer-task-wizard-settings.component';
import { RetailerTaskWizardComponent } from '../retailer-task-wizard/retailer-task-wizard.component';
import { RetailerVariantsUpdateSettingsComponent } from '../retailer-variants-update-task-wizard/retailer-variants-update-settings/retailer-variants-update-settings.component';
import { RetailerVariantsUpdateSummaryComponent } from '../retailer-variants-update-task-wizard/retailer-variants-update-summary/retailer-variants-update-summary.component';
import { RetailerVariantsUpdateTaskWizardComponent } from '../retailer-variants-update-task-wizard/retailer-variants-update-task-wizard.component';
import { ShoprenterDfTaskWizardComponent } from '../shoprenter-df-task-wizard/shoprenter-df-task-wizard.component';
import { ShoprenterSettingsComponent } from '../shoprenter-df-task-wizard/shoprenter-settings/shoprenter-settings.component';
import { StepWrapperComponent } from '../step-wrapper/step-wrapper.component';
import { TaskFilterModule } from '../task-filter/task-filter.module';
import { StoreConnectedComponent } from './store-connected/store-connected.component';
import { SummaryComponent } from './summary/summary.component';
import { SupplierTaskPricingTypeSettingsComponent } from './supplier-task-pricing/supplier-task-pricing-type-settings/supplier-task-pricing-type-settings.component';
import { SupplierTaskPricingComponent } from './supplier-task-pricing/supplier-task-pricing/supplier-task-pricing.component';
import { CatalogEditorComponent } from 'app/shared/components/catalog-editor/catalog-editor.component';
import { RetailerCatalogsModule } from '../marketplace/retailer-catalogs/retailer-catalogs.module';
import { TaskCommentComponent } from 'app/shared/components/task-comment/task-comment.component';
import { CheckboxSelectionListComponent } from 'app/shared/components/checkbox-selection-list/checkbox-selection-list.component';
import { BreadcrumbNavigationComponent } from 'app/shared/components/breadcrumb-navigation/breadcrumb-navigation.component';
import { RetailerCategoryMappingComponent } from 'app/shared/components/retailer-category-mapping/retailer-category-mapping.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingScreenComponent } from 'app/shared/components/loading-screen/loading-screen.component';
import { NewCategoryMappingComponent } from 'app/shared/components/new-category-mapping/new-category-mapping.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CopyToClipboardComponent } from 'app/shared/components/copy-to-clipboard/copy-to-clipboard.component';
import { RetailerPricingComponent } from 'app/shared/components/retailer-pricing/retailer-pricing.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { TaskWizardStepLockedWarningComponent } from '../../shared/components/task-wizard-step-locked-warning/task-wizard-step-locked-warning.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTabsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatListModule,
    MatCardModule,
    FuseSharedModule,
    AddfilesModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    FilterModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    DraggableModule,
    MatMenuModule,
    NewFieldMappingModule,
    TaskFilterModule,
    SingleAlertWithIconComponent,
    AlertMultiComponent,
    CustomPanelComponent,
    AdminCommentDirective,
    ImportListPriceSettingsModule,
    ComponentHeaderComponent,
    CatalogEditorComponent,
    RetailerCatalogsModule,
    TaskCommentComponent,
    CheckboxSelectionListComponent,
    BreadcrumbNavigationComponent,
    RetailerCategoryMappingComponent,
    TranslateModule,
    LoadingScreenComponent,
    NewCategoryMappingComponent,
    MatExpansionModule,
    CopyToClipboardComponent,
    MatIconModule,
    RetailerPricingComponent,
    LoadingSpinnerComponent,
    MatTooltipModule,
    MatRippleModule,
    MatSortModule,
    TaskWizardStepLockedWarningComponent,
  ],
  exports: [SummaryComponent],
  declarations: [
    TaskwizardComponent,
    SummaryComponent,
    StepWrapperComponent,
    RetailerTaskWizardComponent,
    RetailerExportTaskWizardComponent,
    RetailerTaskWizardSettingsComponent,
    DestinationComponent,
    FieldMappingComponent,
    CustomFieldComponent,
    CustomFieldSettingsComponent,
    RetailerExportConnectFtpComponent,
    RetailerVariantsUpdateTaskWizardComponent,
    RetailerVariantsUpdateSettingsComponent,
    SettingAndSummaryComponent,
    RetailerVariantsUpdateSummaryComponent,
    JumpsellerDfTaskWizardComponent,
    JumpsellerSettingsComponent,
    ShoprenterDfTaskWizardComponent,
    ShoprenterSettingsComponent,
    RetailerJumpsellerVuTaskWizardComponent,
    RetailerJumpsellerVuSettingsComponent,
    RetailerJumpsellerVuSummaryComponent,
    EcwidDfTaskWizardComponent,
    EcwidMappingSettingsComponent,
    StoreConnectedComponent,
    SupplierTaskPricingComponent,
    SupplierTaskPricingTypeSettingsComponent,
  ],
  providers: [TaskwizardUpdateService],
})
export class TaskwizardModule {}
