import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnippetEnum } from 'app/main/setup-guide/enums/snippet-enums';
import { backoff } from 'app/utils/operator/backoff';
import { fetchAllPages } from 'app/utils/operator/fetch-all-pages';
import { CatalogSettingsVO } from 'app/vo/catalog-settings-vo';
import { SupplierCatalogProductCounts } from 'app/vo/supplier/supplier-catalog-product-counts';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { NotificationService } from '../../main/notification/notification.service';
import { SetupGuideService } from '../../main/setup-guide/service/setup-guide.service';
import { GetTasksStartAction } from '../../store/tasks/tasks.actions';
import { CatalogHistory } from '../../vo/catalog-history/catalog-history';
import { NextRun } from '../../vo/next-run/next-run';
import { SpringPage } from '../../vo/pagination/spring-page';
import { SupplierCatalog } from '../../vo/supplier-catalog/supplier-catalog';
import { SupplierCatalogUpdate } from '../../vo/supplier-catalog/supplier-catalog-update';
import { TaskSampleResponse } from '../../vo/task/task-sample';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { RequestError } from '../rest/request-error';
import { AddTaskUrlEnum } from './model/add-task-url.enum';
import { CreateTaskResponse } from './model/create-task-response';
import { TaskEntityType } from './model/task-entity-type';
import { TaskService } from './task.service';

@Injectable({ providedIn: 'root' })
export class SupplierCatalogService {
  constructor(
    private springRestService: SpringRestService,
    private notificationService: NotificationService,
    private taskService: TaskService,
    private store$: Store<AppState>,
    private actions$: Actions,
    private router$: Router,
    private setupGuideService: SetupGuideService
  ) {}

  getAllCatalogs(): Observable<SupplierCatalog[]> {
    const SIZE = 100;

    return this.getCatalogs(0, SIZE).pipe(
      fetchAllPages((from, size) => this.getCatalogs(from, size), SIZE, { paginationType: 'from' })
    );
  }

  createNewCatalog(name: string, taskType: string, sourceType: string): Observable<void> {
    return this.taskService.addTask(AddTaskUrlEnum.ADD_TASK, { name, taskType, sourceType }).pipe(
      map((response) => response.getFirstData()),
      tap((data) => {
        this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_IMPORT_PRODUCTS);
        this.store$.dispatch(new GetTasksStartAction());
        this.navigateOnCatalogCreate(data.taskId);
      }),
      map(() => {})
    );
  }

  createNewPlatformCatalog(name: string, ecomId: number): Observable<void> {
    return this.taskService.createDefaultSupplierTask(ecomId, name).pipe(
      tap((data) => {
        this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_IMPORT_PRODUCTS);
        this.store$.dispatch(new GetTasksStartAction());
        this.navigateOnCatalogCreate(data.catalogId);
      }),
      map(() => {})
    );
  }

  navigateOnCatalogCreate(taskId: number): void {
    const params = {
      task_id: taskId,
      isUpdate: true,
    };
    this.router$.navigate(['supplier/catalog/wizard'], { queryParams: params });
  }

  getCatalogs(page = 0, size = 10): Observable<SpringPage<SupplierCatalog>> {
    return this.springRestService
      .get(MicroserviceNames.TASK, `/api/v1/catalog/get-all`, { page, size }, true)
      .pipe(catchError(() => of(null)));
  }

  getCatalogHistoryItems(catalogIds: number[], entityType: TaskEntityType): Observable<CatalogHistory[]> {
    return this.springRestService
      .get(MicroserviceNames.TASK, `/api/v1/latest-history`, { entityIDs: catalogIds, entityType }, true)
      .pipe(catchError(() => of([])));
  }

  getNextRunTimesByIds(catalogIds: number[]): Observable<NextRun[]> {
    return this.springRestService
      .get(MicroserviceNames.TASK, `/api/v1/catalog/next-run`, { taskIds: catalogIds }, true)
      .pipe(catchError(() => of([])));
  }

  patchCatalog(catalogId: number, data: SupplierCatalogUpdate): Observable<void> {
    return this.springRestService.patch(MicroserviceNames.TASK, `/api/v1/catalog/${catalogId}`, data, {}, true).pipe(
      catchError((err: RequestError) => {
        this.notificationService.error(err.errorMessage);
        return EMPTY;
      })
    );
  }

  getSampleProducts(fileId: number, page: number, size = 10): Observable<TaskSampleResponse> {
    return this.springRestService.get(MicroserviceNames.TASK, '/api/v1/sample-products', { fileId, page, size }, true);
  }

  getSampleProductsRetry(fileId: number, page: number, size = 10, retryCount = 5): Observable<TaskSampleResponse> {
    return this.springRestService
      .get(MicroserviceNames.TASK, '/api/v1/sample-products', { fileId, page, size }, true)
      .pipe(backoff(5000, 80000, retryCount));
  }

  getCatalogById(catalogId: number): Observable<CatalogSettingsVO> {
    return this.springRestService.get(MicroserviceNames.TASK, `/api/v1/catalog-settings/${catalogId}`, undefined, true);
  }

  getCatalogProductCount(values: number[], key: string = 'TASK_ID'): Observable<SupplierCatalogProductCounts> {
    return this.springRestService.get(
      MicroserviceNames.PRODUCT_SEARCH,
      `/v1/Product/Count`,
      {
        key: key,
        value: values.join(','),
      },
      true
    );
  }
}
