import { HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
import { debounce } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../../../app.state';
import {
  ExploreProductsService,
  ProductSearchTerm,
  ProductSearchTermHelpers,
} from '../../../../../../service/marketplace/explore-products/explore-products.service';
import { NavigationService } from '../../../../../../service/navigation/navigation.service';
import { CategoryService } from '../../../../../../service/product-search/category.service';
import { ProductSearchService } from '../../../../../../service/product-search/product-search.service';
import { SupplierSearchGatewayService } from '../../../../../../service/supplier-search/supplier-search-gateway.service';
import { isAuthenticatedSelector } from '../../../../../../store/authentication/authentication.selector';
import { CategoryState } from '../../../../../../store/category/category.reducer';
import { ProductSearchHelper } from '../../../../../../store/product-search/product-search.reducer';
import { CategoryVo } from '../../../../../../vo/category-vo';
import { MarketplaceFilter } from '../../../../../../vo/search-product-vo';
import { SupplierDto } from '../../../../../../vo/supplier/supplier-dto';

@Component({
  selector: 'app-product-search-with-autocomplete-content',
  templateUrl: './product-search-with-autocomplete-content.component.html',
  styleUrls: ['./product-search-with-autocomplete-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductSearchWithAutocompleteContentComponent implements OnChanges, OnInit, OnDestroy {
  @Input() searchTerm: string;
  @Input() trendingTerms: string[] = [];
  @Input() keyboardArrowPressed: Subject<string>;
  @Output() suggestionSelected = new EventEmitter<MarketplaceFilter>();
  @Output() close = new EventEmitter<void>();
  needTrendingSuggestion = true;
  termsHistory: string[] = [];
  autocompleteTerms: ProductSearchTerm[] = [];
  autocompleteLocations: ProductSearchTerm[] = [];
  autocompleteCategoriesFromApi: ProductSearchTerm[] = [];
  selectedIndexViaKeyboard: number = null;
  autocompleteSuppliers: SupplierDto[] = [];
  autocompleteCategories: CategoryVo[] = [];
  private _unsubscribeAll: Subject<void>;
  private categoryStore$: Observable<CategoryState>;
  private readonly currentLang: string;

  private handleInputChangeDebounced = debounce(this.handleInputChange, 300);

  constructor(
    private productSearchService: ProductSearchService,
    private exploreProductsService: ExploreProductsService,
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private supplierSearchGatewayService: SupplierSearchGatewayService,
    private navigationService: NavigationService,
    private router: Router
  ) {
    this.currentLang = this.translateService.currentLang;
    this._unsubscribeAll = new Subject();
    this.categoryStore$ = this.store.pipe(select((state) => state.categories));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchTerm.currentValue !== changes.searchTerm.previousValue) {
      if (changes.searchTerm.firstChange) {
        this.handleInputChange(changes.searchTerm.currentValue);
      } else {
        this.handleInputChangeDebounced(changes.searchTerm.currentValue);
      }
    }
  }

  ngOnInit(): void {
    this.needTrendingSuggestion = !(!Utils.isNullOrUndefined(this.searchTerm) && this.searchTerm.length !== 0);
    this.termsHistory = this.getTermsCookie();
    this.keyboardArrowPressed.pipe(takeUntil(this._unsubscribeAll)).subscribe((key) => {
      this.onKeydown(key);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private handleInputChange(newValue: string): void {
    this.selectedIndexViaKeyboard = null;
    if (Utils.isNullOrUndefined(newValue) || newValue === '') {
      this.termsHistory = this.getTermsCookie();
      this.needTrendingSuggestion = true;
    } else {
      this.getAutoCompleteTerms(newValue);
      this.fetchSuppliers(newValue);
      this.searchInCategories(newValue);
      this.categoryService.searchInCategoriesByTerm([], newValue, this.currentLang);
      this.needTrendingSuggestion = false;
    }
  }

  private getAutoCompleteTerms(term: string): void {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('search', term);
    httpParams = httpParams.set('size', '5');
    httpParams = httpParams.set('from', '0');
    this.exploreProductsService
      .getAutoCompleteItems(httpParams)
      .subscribe((res: { categories: CategoryVo[]; locations: string[]; titles: string[] }) => {
        if (res.titles.length > 0) {
          this.autocompleteTerms = res.titles.map((title) => ({
            label: title.toLowerCase().replace(term.toLowerCase(), `<b>${term.toLowerCase()}</b>`),
            filter: {
              search: title,
            },
          }));
          this.autocompleteCategoriesFromApi = res.categories.map((category) => ({
            label:
              '"' +
              res.titles[0].toLowerCase().replace(term.toLowerCase(), `<b>${term.toLowerCase()}</b>`) +
              '" ' +
              this.translateService.instant('TOOLBAR.PRODUCT_SEARCH.IN') +
              ' <span class="highlight-content">' +
              category.name +
              '</span>',
            filter: {
              search: res.titles[0],
              category: Number(category.id),
            },
            helpers: {
              category,
            },
          }));
          this.autocompleteLocations = res.locations.map((location) => ({
            label:
              '"' +
              res.titles[0].toLowerCase().replace(term.toLowerCase(), `<b>${term.toLowerCase()}</b>`) +
              '" ' +
              this.translateService.instant('TOOLBAR.PRODUCT_SEARCH.IN') +
              ' <span class="highlight-content">' +
              location +
              '</span>',
            filter: {
              search: res.titles[0],
              shipsFrom: location,
            },
          }));
        }
      });
  }

  handleTermClicked(term: string): void {
    this.suggestionSelected.emit({
      search: term,
    });
  }

  handleCategoryClicked(category: CategoryVo): void {
    // this.store.dispatch(new SetHelperAction({ category: category }));
    this.suggestionSelected.emit({
      category: Number(category.id),
    });
  }

  handleAutocompleteTermClicked(filters: MarketplaceFilter, category?: CategoryVo): void {
    this.dispatchHelpers(filters, { category: category });
    this.suggestionSelected.emit(filters);
  }

  getCategoryName(category: CategoryVo): string {
    return this.categoryService.getNameForCategory(category, this.currentLang);
  }

  private getTermsCookie(): string[] {
    return this.productSearchService.getTermCookies();
  }

  private onKeydown(key: string): void {
    const maxIndex = this.needTrendingSuggestion
      ? this.trendingTerms.length + this.termsHistory.length - 1
      : this.autocompleteTerms.length +
        this.autocompleteCategoriesFromApi.length +
        this.autocompleteLocations.length +
        this.autocompleteSuppliers.length +
        this.autocompleteCategories.length -
        1;
    if (key === 'ArrowUp') {
      event.preventDefault();
      if (Utils.isNullOrUndefined(this.selectedIndexViaKeyboard)) {
        this.selectedIndexViaKeyboard = maxIndex;
      } else {
        if (this.selectedIndexViaKeyboard === 0) {
          this.selectedIndexViaKeyboard = null;
        } else {
          this.selectedIndexViaKeyboard--;
        }
      }
    } else if (key === 'ArrowDown') {
      event.preventDefault();
      if (Utils.isNullOrUndefined(this.selectedIndexViaKeyboard)) {
        this.selectedIndexViaKeyboard = 0;
      } else {
        if (this.selectedIndexViaKeyboard === maxIndex) {
          this.selectedIndexViaKeyboard = null;
        } else {
          this.selectedIndexViaKeyboard++;
        }
      }
    } else if (key === 'Enter') {
      this.handleEnterPressed();
    }
  }

  private handleEnterPressed(): void {
    if (Utils.isNullOrUndefined(this.selectedIndexViaKeyboard)) {
      this.suggestionSelected.emit({
        search: this.searchTerm,
      });
    } else {
      if (!this.needTrendingSuggestion || this.selectedIndexViaKeyboard > this.termsHistory.length) {
        document.getElementById(`term_${this.selectedIndexViaKeyboard}`).click();
      } else {
        (
          document
            .getElementById(`term_${this.selectedIndexViaKeyboard}`)
            .getElementsByClassName('history-term')[0] as HTMLElement
        ).click();
      }
    }
  }

  private fetchSuppliers(searchTerm: string): void {
    this.supplierSearchGatewayService.searchSupplierForAutocomplete(searchTerm).subscribe((suppliers) => {
      this.autocompleteSuppliers = suppliers.content;
    });
  }

  private searchInCategories(searchTerm: string): void {
    const foundCategories = this.categoryService.searchInCategoriesByTerm(
      this.getFlattenCategoriesFromStore(),
      searchTerm,
      this.currentLang
    );
    this.autocompleteCategories = foundCategories.slice(0, 3);
  }

  handleSupplierClicked(supplier: SupplierDto): void {
    this.navigationService.navigateToStorefront(supplier.userId, supplier.handle);
    this.close.emit();
  }

  private getFlattenCategoriesFromStore(): CategoryVo[] {
    let categories: CategoryVo[];
    this.categoryStore$
      .pipe(
        take(1),
        select((state) => state.flattenCategories)
      )
      .subscribe((cats) => {
        categories = cats;
      });
    return categories;
  }

  private dispatchHelpers(filters: MarketplaceFilter, forHelpers?: Partial<ProductSearchTermHelpers>): void {
    const data: Partial<ProductSearchHelper> = {};
    switch (true) {
      case !Utils.isNullOrUndefined(filters) && !Utils.isNullOrUndefined(filters.category): {
        data.category = forHelpers.category;
        break;
      }
      case !Utils.isNullOrUndefined(filters) && !Utils.isNullOrUndefined(filters.supplier): {
        data.supplier = { id: forHelpers.supplier.userId, name: forHelpers.supplier.companyName };
        break;
      }
    }
    // this.store.dispatch(new SetHelperAction(data));
  }

  deleteHistoryTerm(term: string): void {
    setTimeout(() => {
      this.productSearchService.deleteTermFromCookies(term);
      this.termsHistory = this.getTermsCookie();
    }, 0);
  }

  handleReferralClick(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        take(1),
        tap((isAuth) => this.router.navigate(isAuth ? ['referrals', 'my-referrals'] : ['supplier-referral']))
      )
      .subscribe(() => this.close.emit());
  }
}
