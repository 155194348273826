import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
@Injectable({
  providedIn: 'root',
})
export class MfieldQuantitySettingsService {
  public static GET_SHOPIFY_LOCATIONS = 'ShopDetailsService/getLocationsByTaskId';

  private data = {};
  private occupiedLocations: any = {};
  locations: any[] = null;
  private prevTaskId = null;
  isGetLocationsInProgress = false;

  constructor(private restService: RestService) {}

  created(key): void {}

  getLocationsByTaskId(taskId: number, force = false): Observable<any[]> {
    if (this.prevTaskId !== taskId || Utils.isNullOrUndefined(this.locations) || force) {
      this.prevTaskId = taskId;
      const httpParams = new HttpParams().set('taskId', taskId.toString());
      this.isGetLocationsInProgress = true;
      return this.restService.get(MfieldQuantitySettingsService.GET_SHOPIFY_LOCATIONS, httpParams).pipe(
        switchMap((res) => {
          this.locations = res.getData();
          this.isGetLocationsInProgress = false;
          return this.locations;
        })
      );
    } else {
      return of(this.locations || []);
    }
  }

  isLocationFree(fieldName, locationId): boolean {
    if (Utils.isNullOrUndefined(this.occupiedLocations[fieldName])) {
      return true;
    }
    return !this.occupiedLocations[fieldName].includes(locationId);
  }

  occupeLocation(fieldName, locationId): void {
    if (Utils.isNullOrUndefined(this.occupiedLocations[fieldName])) {
      this.occupiedLocations[fieldName] = [];
    }
    if (this.isLocationFree(fieldName, locationId)) {
      this.occupiedLocations[fieldName].push(locationId);
    }
  }

  removeLocation(fieldName, locationId): void {
    if (Utils.isNullOrUndefined(this.occupiedLocations[fieldName])) {
      return;
    }
    const index = this.occupiedLocations[fieldName].indexOf(locationId);
    if (index > -1) {
      this.occupiedLocations[fieldName].splice(index, 1);
    }
  }

  isFreeLocation(fieldName): boolean {
    const existFreeLocation = () => {
      return (this.locations || []).filter((loc) => this.isLocationFree(fieldName, loc.id)).length > 0;
    };
    return Utils.isNullOrUndefined(this.locations) || existFreeLocation();
  }

  getLocationsNum(): number {
    return (this.locations || []).length;
  }
}
