import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatService } from 'app/service/chat/chat.service';
import { SortFactory } from '../chat-utils/SortFactory';
import { ChatOptions } from '../chat.component';
import { ChannelItem } from '../../../../service/chat/model/chat-models';

@Component({
  selector: 'app-chat-active-panel',
  templateUrl: './chat-active-panel.component.html',
  styleUrls: ['./chat-active-panel.component.scss'],
})
export class ChatActivePanelComponent implements OnInit {
  @Input() channelsLoading: boolean;
  @Input() channelsError: boolean;
  @Input() hasLoadMore: boolean;
  @Input() hasFiltered: boolean;
  @Input() selectedChannel: ChannelItem;
  @Input() options: ChatOptions = { hideChannelSide: false, hideDirectMessageSide: false, innerChat: false };
  @Input() channels: ChannelItem[];
  @Output() channelClicked = new EventEmitter<ChannelItem>();
  @Output() loadMoreClicked = new EventEmitter<void>();

  @Input() sort: string = SortFactory.UNREAD_MESSAGES_KEY;

  expandedPanel = true;

  constructor(private chatService: ChatService) {}

  ngOnInit(): void {}

  channelClickHandler(channel: ChannelItem): void {
    this.selectedChannel = channel;
    this.channelClicked.emit(channel);
    if (this.chatService.isMobile()) {
      this.expandedPanel = false;
    }
  }
}
