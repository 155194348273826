<div>
  <div
    class="filter-selector-chip"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="isOverlayOpen = !isOverlayOpen"
  >
    <div>{{ title }}</div>
    <ng-container [ngTemplateOutlet]="numberDisplay"></ng-container>
    <mat-icon [class]="isOverlayOpen ? 'arrow-icon-rotate' : 'arrow-icon-base'">keyboard_arrow_down</mat-icon>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOffsetY]="2"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayPositions]="positions"
    (backdropClick)="isOverlayOpen = false"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  >
    <mat-card class="filter-container custom-elevation-z1">
      <ng-content></ng-content>
    </mat-card>
  </ng-template>
</div>

<ng-template #numberDisplay>
  <div *ngIf="numberOfSelected > 0; else zeroSelected" class="number-display">{{ numberOfSelected }}</div>
  <ng-template #zeroSelected>
    <div class="zero-selected"></div>
  </ng-template>
</ng-template>
