import { USER_ROLES } from '../utils/Constants';
import { RetailerCenterNavigation } from './navigation-routes/retailer/retailer-center.navigation';
import { RetailerNavigation } from './navigation-routes/retailer/retailer.navigation';
import { SupplierCenterNavigation } from './navigation-routes/supplier/supplier-center.navigation';
import { SupplierNavigation } from './navigation-routes/supplier/supplier.navigation';

export const kiosk_navigation = [
  {
    name: 'Users',
    translate: 'INNER_NAV.KIOSK.USERS',
    url: 'admin/kiosk/users',
  },
  {
    name: 'Tasks',
    translate: 'INNER_NAV.KIOSK.TASKS',
    url: 'admin/kiosk/tasks',
  },
  {
    name: 'Export Tasks',
    translate: 'INNER_NAV.KIOSK.EXPORT_TASKS',
    url: 'admin/kiosk/export-tasks',
  },
  {
    name: 'Catalogs',
    translate: 'INNER_NAV.KIOSK.CATALOGS',
    url: 'admin/kiosk/catalogs',
  },
  {
    name: 'Ecoms',
    translate: 'INNER_NAV.KIOSK.ECOMS',
    url: 'admin/kiosk/ecoms',
  },
  // { //NO NO SENOR
  //     name: 'Retailer catalogs',
  //     translate: 'INNER_NAV.KIOSK.RETAILER_CATALOGS',
  //     url: 'kiosk/retailer-catalog-list-kiosk'
  // }
];

export const supplier_kiosk_navigation = [
  {
    name: 'All supplier',
    translate: 'INNER_NAV.SUPPLIER_KIOSK.ALL_SUPPLIER',
    url: 'admin/supplier-kiosk/all-supplier',
  },
  {
    name: 'Pending catalogs',
    translate: 'INNER_NAV.SUPPLIER_KIOSK.PENDING_CATALOGS',
    url: 'admin/supplier-kiosk/pending-catalogs',
  },
  {
    name: 'Supplier reviews',
    translate: 'INNER_NAV.SUPPLIER_KIOSK.SUPPLIER_REVIEWS',
    url: 'admin/supplier-kiosk/supplier-reviews',
  },
  {
    name: 'Supplier Charge Settings',
    translate: 'INNER_NAV.SUPPLIER_KIOSK.SUPPLIER_CHARGE_SETTINGS',
    url: 'admin/supplier-kiosk/supplier-charge',
  },
  {
    name: 'Supplier Automate Orders',
    translate: 'INNER_NAV.SUPPLIER_KIOSK.SUPPLIER_AUTOMATE_ORDERS',
    url: 'admin/supplier-kiosk/supplier-automate-orders',
  },
];

export const billing_navigation = [
  { name: 'Plans', translate: 'INNER_NAV.BILLING.PLANS', url: 'billing/plans' },
  {
    name: 'Subscriptions',
    translate: 'INNER_NAV.BILLING.SUBSCRIPTIONS',
    url: RetailerNavigation.BILLING.SUBSCRIPTIONS,
  },
  {
    name: 'Invoices',
    translate: 'INNER_NAV.BILLING.INVOICES',
    url: RetailerNavigation.BILLING.INVOICES,
  },
];

export const alibaba_navigation = [
  {
    name: 'Alibaba search',
    translate: 'INNER_NAV.ALIBABA.SEARCH_PRODUCTS',
    url: RetailerCenterNavigation.ALIBABA.ALIBABA_CONNECTION,
  },
  {
    name: 'Alibaba import list',
    translate: 'INNER_NAV.ALIBABA.IMPORT_LIST',
    url: RetailerCenterNavigation.ALIBABA.ALIBABA_PRODUCTS,
  },
];

export const suppliers_my_listing_navigation = [
  {
    name: 'Company details',
    translate: 'INNER_NAV.SUPPLIER_MY_LISTING.COMPANY_DETAILS',
    url: SupplierNavigation.MY_LISTING.COMPANY_DETAILS,
  },
  {
    name: 'Shipping details',
    translate: 'INNER_NAV.SUPPLIER_MY_LISTING.SHIPPING_DETAILS',
    url: SupplierNavigation.MY_LISTING.SHIPPING_DETAILS,
  },
  {
    name: 'Nemzetközi szállítás',
    url: SupplierNavigation.MY_LISTING.INTERNATIONAL_SHIPPING,
    hidden: getLanguage() !== 'hu',
  },
];

export const retailer_profile_navigation = [
  {
    name: 'Settings',
    translate: 'INNER_NAV.RETAILER_PROFILE.SETTINGS',
    url: 'retailer-profile/settings',
  },
  {
    name: 'My Shops',
    translate: 'INNER_NAV.RETAILER_PROFILE.MY_SHOPS',
    url: 'retailer-profile/my-shops',
  },
  {
    name: 'Order settings',
    translate: 'INNER_NAV.RETAILER_PROFILE.ORDER_SETTINGS',
    url: 'retailer-profile/order-settings',
  },
  {
    name: 'Search settings',
    translate: 'INNER_NAV.RETAILER_PROFILE.SEARCH_SETTINGS',
    url: 'retailer-profile/search-settings',
  },
  {
    name: 'Notification settings',
    translate: 'INNER_NAV.RETAILER_PROFILE.NOTIFICATION_SETTINGS',
    url: 'retailer-profile/notification-settings',
  },
  {
    name: 'Team',
    translate: 'INNER_NAV.RETAILER_PROFILE.TEAM',
    url: 'retailer-profile/team',
  },
  {
    name: 'Activity log',
    translate: 'INNER_NAV.RETAILER_PROFILE.ACTIVITY_LOG',
    url: 'retailer-profile/activity-log',
  },
];

export const supplier_profile_navigation = [
  {
    name: 'Settings',
    translate: 'INNER_NAV.SUPPLIER_PROFILE.SETTINGS',
    url: 'supplier-profile/settings',
  },
  {
    name: 'Notification settings',
    translate: 'INNER_NAV.SUPPLIER_PROFILE.NOTIFICATION_SETTINGS',
    url: 'supplier-profile/notification-settings',
  },
  {
    name: 'Order settings',
    translate: 'INNER_NAV.SUPPLIER_PROFILE.ORDER_SETTINGS',
    url: 'supplier-profile/order-settings',
  },
  {
    name: 'Invoices',
    translate: 'INNER_NAV.SUPPLIER_PROFILE.INVOICES',
    url: 'supplier-profile/invoices',
  },
];

export const manager_orders_navigation = [
  {
    name: 'All orders',
    translate: 'All orders',
    url: 'admin/orders-manager/all-orders',
  },
  {
    name: 'Unsuccessful Orders',
    translate: 'Unsuccessful Orders',
    url: 'admin/orders-manager/unsuccessful-orders',
  },
  {
    name: 'Paid Orders',
    translate: 'Paid Orders',
    url: 'admin/orders-manager/paid-orders',
  },
  {
    name: 'Expired Orders',
    translate: 'Expired Orders',
    url: 'admin/orders-manager/expired-order-payments',
  },
];

export const kiosk_alibaba_navigation = [
  {
    name: 'Products  management',
    translate: 'Products Management',
    url: 'admin/kiosk-alibaba/kiosk-alibaba-products',
  },
];

export const kiosk_marketplace_settings_navigation = [
  {
    name: 'Featured Suppliers Block',
    translate: 'Featured Suppliers Block',
    url: 'admin/marketplace-settings/featured-suppliers',
  },
  {
    name: 'New Suppliers Block',
    translate: 'New Suppliers Block',
    url: 'admin/marketplace-settings/new-suppliers',
  },
  {
    name: 'Recommendation Settings',
    translate: 'Recommendation Settings',
    url: 'admin/marketplace-settings/recommendation-settings',
  },
  {
    name: 'Best Sellers Block',
    translate: 'Best Sellers Block',
    url: 'admin/marketplace-settings/hot-products',
  },
  {
    name: 'Premium Suppliers Block',
    translate: 'Premium Suppliers Block',
    url: 'admin/marketplace-settings/premium-suppliers',
  },
];

export const profile_menu_navigation = [
  {
    title: 'PROFILE_MENU.RETAILER_CENTER',
    link: RetailerCenterNavigation.DASHBOARD,
    showToRoles: [USER_ROLES.AGENT, USER_ROLES.SUPPLIER, USER_ROLES.GLOBAL_ADMIN],
    rolesWithAccess: [USER_ROLES.AGENT, USER_ROLES.GLOBAL_ADMIN],
    forRole: USER_ROLES.AGENT,
  },
  {
    title: 'PROFILE_MENU.SUPPLIER_CENTER',
    link: SupplierCenterNavigation.DASHBOARD,
    showToRoles: [USER_ROLES.AGENT, USER_ROLES.SUPPLIER, USER_ROLES.GLOBAL_ADMIN],
    rolesWithAccess: [USER_ROLES.SUPPLIER, USER_ROLES.GLOBAL_ADMIN],
    forRole: USER_ROLES.SUPPLIER,
  },
  {
    title: 'PROFILE_MENU.ADMIN_CENTER',
    link: 'admin/kiosk',
    showToRoles: [USER_ROLES.GLOBAL_ADMIN],
    rolesWithAccess: [USER_ROLES.AGENT, USER_ROLES.GLOBAL_ADMIN],
    forRole: USER_ROLES.GLOBAL_ADMIN,
  },
];

export const favorites_page_navigation = [
  {
    name: 'Products',
    translate: 'FAVORITES.FAVORITE_PRODUCTS.MENU_TITLE',
    url: RetailerCenterNavigation.FAVORITES.DEFAULT,
  },
  {
    name: 'Followed Suppliers',
    translate: 'FAVORITES.FOLLOWED_SUPPLIERS.MENU_TITLE',
    url: RetailerCenterNavigation.FAVORITES.FOLLOWED_SUPPLIERS,
  },
];

export const referrals_page_retailer_navigation = [
  {
    name: 'Statistics',
    translate: 'Statistics',
    url: 'referrals/statistics',
  },
  {
    name: 'Refer Retailer',
    translate: 'Refer a retailer',
    url: 'referrals/refer-retailer',
  },
  {
    name: 'Refer Supplier',
    translate: 'Refer a Supplier',
    url: 'referrals/refer-supplier',
  },
];
export const referrals_page_supplier_navigation = [{}];

function getLanguage(): string {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  return user && user.language ? user.language : '';
}
