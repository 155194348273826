<div
  class="w-100p toolbar-container syncee-grey-300-border-bottom"
  [class.with-bottom-padding]="config.hasBottomPadding"
  [class.no-inline-padding]="config.noInlinePadding"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
>
  <div class="content" [class.content-container]="config.needsContentContainer" [ngClass]="config.containerVariant">
    <div fxLayoutAlign="space-between center">
      <div fxFlex="0 0 50%" fxLayoutAlign="center center" fxLayoutGap="28px">
        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayoutGap="14px">
          <app-syncee-logo [width]="'136px'"></app-syncee-logo>
        </div>
        <div fxFlex="1 0 0" class="search-bar mr-12" fxLayoutAlign="center center">
          <app-product-search-with-autocomplete
            *ngIf="!config.content.hideSearchbar"
            class="w-100p"
            (filtersSelected)="handleFilterSelected($event)"
          ></app-product-search-with-autocomplete>
        </div>
        <ng-container *ngIf="!config.content.hideAiChatButton">
          <app-ai-chat-open-button></app-ai-chat-open-button>
        </ng-container>
      </div>
      <app-toolbar-action-bar
        fxFlex="0 0 auto"
        [role]="role"
        [extraIcons]="extraIcons"
        [content]="config.content.actionbar"
        *onlyLoggedIn
      ></app-toolbar-action-bar>
      <app-guest-actions *onlyGuest></app-guest-actions>
    </div>
    <ng-container *ngIf="needCategorySelector">
      <div class="category-selector">
        <app-category-selector [needRedirect]="needRedirect" class="w-100p"></app-category-selector>
      </div>
    </ng-container>
  </div>
</div>
