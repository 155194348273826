import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-filter-wrapper-chip-with-overlay',
  templateUrl: './filter-wrapper-chip-with-overlay.component.html',
  styleUrls: ['./filter-wrapper-chip-with-overlay.component.scss'],
})
export class FilterWrapperChipWithOverlayComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() filterValue: any;
  numberOfSelected = 0;
  isOverlayOpen = false;

  public positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 2,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 2,
    },
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 2,
    },
  ];

  constructor(private overlay: Overlay) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getNumberOfSelected();
  }

  getNumberOfSelected(): void {
    const filterValue = this.filterValue;
    if (Utils.isNullOrUndefined(filterValue)) {
      this.numberOfSelected = 0;
      return;
    }
    if (Array.isArray(filterValue)) {
      this.numberOfSelected = filterValue.length;
    } else if (typeof filterValue === 'object') {
      const objectValues = Object.values(filterValue).filter((value) => !Utils.isNullOrUndefined(value));
      this.numberOfSelected = objectValues.length;
    } else {
      this.numberOfSelected = 1;
    }
  }
}
