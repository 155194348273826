import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getTranslationFromStream } from 'app/utils/operator/get-translation-from-stream';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { ToolbarCallToActionContentService } from '../../../layout/components/toolbar/components/toolbar-call-to-action/toolbar-call-to-action-content.service';
import { ToolbarCallToActionService } from '../../../layout/components/toolbar/components/toolbar-call-to-action/toolbar-call-to-action.service';
import { RolesEnum } from '../../../vo/roles/roles';
import { KEY_RETAILER, KEY_SUPPLIER } from '../config/snippet-keys';
import { SetupGuideSnippet } from '../model/setup-guide-snippet';
import { SetupGuideService } from '../service/setup-guide.service';

@Component({
  selector: 'app-setup-guide-page',
  templateUrl: './setup-guide-page.component.html',
  styleUrls: ['./setup-guide-page.component.scss'],
})
export class SetupGuidePageComponent implements OnInit, OnDestroy {
  snippetToShow: Observable<SetupGuideSnippet>;
  reInitAccordion: Subject<void>;
  isRetailer: Observable<boolean>;
  private unsubscribeAll: Subject<void>;

  constructor(
    private fuseConfigService: FuseConfigService,
    private setupGuideService: SetupGuideService,
    private router: Router,
    private route: ActivatedRoute,
    private toolbarCallToActionService: ToolbarCallToActionService,
    private toolbarCallToActionContentService: ToolbarCallToActionContentService,
    private translateService: TranslateService
  ) {
    this.initTheme();
    this.unsubscribeAll = new Subject();
    this.reInitAccordion = new Subject();
    this.isRetailer = this.getIsRetailer();
  }

  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.initSnippetToShow();
    this.handleEarCreditCallToActionOnComponentInit();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.handleEarnCreditCallToActionOnComponentDestroy();
  }

  private handleEarnCreditCallToActionOnComponentDestroy(): void {
    this.toolbarCallToActionService.removeContentByTypeAndPosition('earnCredit', 'below-header');
    this.observeIfNeedsEarnMoreCallToAction()
      .pipe(take(1))
      .subscribe((response) => {
        if (!!response) {
          this.toolbarCallToActionContentService.addEarnCreditCallToAction();
          this.toolbarCallToActionContentService.addSetupGuideCallToAction();
        }
      });
  }

  private initSnippetToShow(): void {
    this.snippetToShow = this.getRoleFromQueryParams().pipe(
      switchMap((role) => this.getRoleFallback(role)),
      switchMap((role) =>
        this.setupGuideService.getSetupGuideSnippetObservable(role === RolesEnum.SUPPLIER ? KEY_SUPPLIER : KEY_RETAILER)
      )
    );
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((event) => event instanceof RoutesRecognized),
        filter((event: RoutesRecognized) => event.url.includes('/dashboard'))
      )
      .subscribe(() => {
        if (this.router.url.includes('/setup-guide')) {
          this.initTheme();
        }
      });
  }

  private getIsRetailer(): Observable<boolean> {
    return this.setupGuideService.getRoleForSetupGuideSnippet().pipe(
      takeUntil(this.unsubscribeAll),
      map((role) => role === RolesEnum.RETAILER)
    );
  }

  private getRoleFromQueryParams(): Observable<RolesEnum> {
    return this.route.queryParams.pipe(
      takeUntil(this.unsubscribeAll),
      tap(() => this.initTheme()),
      map((value) => {
        return !!value && !!value.role ? Number(value.role) : null;
      })
    );
  }

  private getRoleFallback(role: RolesEnum): Observable<RolesEnum> {
    return !!role ? of(role) : this.setupGuideService.getRoleForSetupGuideSnippet();
  }

  private initTheme(): void {
    this.translateService
      .stream('SETUP_GUIDE.TITLE')
      .pipe(getTranslationFromStream('SETUP_GUIDE.TITLE'))
      .subscribe({
        next: (translation) => {
          this.fuseConfigService.config = {
            layout: { title: translation as string, contentContainer: 'container-240' },
          };
        },
      });
  }

  private observeIfNeedsEarnMoreCallToAction(): Observable<boolean> {
    return this.setupGuideService
      .observeIfNeedsEarnMoreCallToAction()
      .pipe(takeUntil(this.unsubscribeAll), distinctUntilChanged());
  }

  handleEarCreditCallToActionOnComponentInit(): void {
    this.observeIfNeedsEarnMoreCallToAction().subscribe((needsCallToAction) => {
      if (needsCallToAction) {
        this.setupGuideService.addEarnMoreCallToActionOnSetupGuidePage();
      } else {
        this.toolbarCallToActionService.removeContent('earnCredit');
      }
    });
  }

  handleDismiss(): void {
    this.router.navigate(['retailer/dashboard']);
  }
}
