import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingStateDirective } from 'app/shared/directives/button-loading-state.directive';

@Component({
  selector: 'app-paginator-load-more',
  styleUrls: ['./paginator-load-more.component.scss'],
  templateUrl: './paginator-load-more.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatButtonModule,
    MatProgressBarModule,
    ButtonLoadingStateDirective,
  ],
})
export class PaginatorLoadMoreComponent implements OnChanges {
  @Output() loadMoreClicked = new EventEmitter<void>();

  @Input() totalNumber: number;

  @Input() fetchedNumber: number;

  @Input() onlyButton = true;

  @Input() loading = false;

  progressBarPercentage = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.onlyButton) {
      if (changes.fetchedNumber && changes.fetchedNumber.currentValue !== 0 && this.totalNumber) {
        this.progressBarPercentage = Math.ceil((changes.fetchedNumber.currentValue / this.totalNumber) * 100);
      }
    }
  }

  handleLoadMoreClicked(): void {
    if (this.totalNumber > this.fetchedNumber) {
      this.loadMoreClicked.emit();
    }
  }
}
