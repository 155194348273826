<div id="planCompare">
  <h2 mat-dialog-title fxLayoutAlign="space-between">
    <ng-container *ngIf="selectedPlan">
      {{
        isUpgrade
          ? ('BILLING.SUBSCRIPTION.COMPARE.FIRST_MESSAGE.UPGRADE' | translate)
          : ('BILLING.SUBSCRIPTION.COMPARE.FIRST_MESSAGE.DOWNGRADE' | translate)
      }}
      {{ 'BILLING.SUBSCRIPTION.COMPARE.FIRST_MESSAGE.TO' | translate }} {{ selectedPlan.productName }}
      {{ 'BILLING.SUBSCRIPTION.COMPARE.FIRST_MESSAGE.PLAN' | translate }}
    </ng-container>
    <ng-container *ngIf="!selectedPlan">
      {{ currentPlan.productName }} {{ 'BILLING.SUBSCRIPTION.COMPARE.PLAN_INFO_LABEL' | translate }}
    </ng-container>
    <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
  </h2>
  <mat-dialog-content>
    <div fxLayoutAlign="start start">
      <table>
        <thead>
          <tr>
            <td></td>
            <td>{{ 'BILLING.SUBSCRIPTION.COMPARE.CURRENT_PLAN_LABEL' | translate }}</td>
            <td *ngIf="selectedPlan">
              {{
                isNextPlan
                  ? ('BILLING.SUBSCRIPTION.COMPARE.NEXT_PLAN' | translate)
                  : ('BILLING.SUBSCRIPTION.COMPARE.SELECTED_PLAN' | translate)
              }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.NAME' | translate }}
            </td>
            <td>
              {{ currentPlan.productName }}
            </td>
            <td *ngIf="selectedPlan">
              {{ selectedPlan.productName }}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.TRIAL' | translate }}
            </td>
            <td>
              {{
                currentPlan.productId === 120 || currentPlan.productId === 100
                  ? ('BILLING.SUBSCRIPTION.COMPARE.FOREVER' | translate)
                  : ('BILLING.SUBSCRIPTION.COMPARE.TRIAL_DAYS' | translate : { days: currentPlan.planData.trialDays })
              }}
            </td>
            <td *ngIf="selectedPlan">
              {{
                selectedPlan.productId === 120 || selectedPlan.productId === 100
                  ? ('BILLING.SUBSCRIPTION.COMPARE.FOREVER' | translate)
                  : ('BILLING.SUBSCRIPTION.COMPARE.TRIAL_DAYS' | translate : { days: selectedPlan.planData.trialDays })
              }}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.PRICE' | translate }}
            </td>
            <td>{{ currentPlan.productMonthlyPrice }} USD</td>
            <td *ngIf="selectedPlan">{{ selectedPlan.productMonthlyPrice }} USD</td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.YEARLY_PRICE' | translate }}
            </td>
            <td>{{ currentPlan.productYearlyPrice }} USD</td>
            <td *ngIf="selectedPlan">{{ selectedPlan.productYearlyPrice }} USD</td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.MANAGED_PRODUCTS_NUMBER' | translate }}
            </td>
            <td>
              {{ currentPlan.planData.managedProductsLimit }}
              {{
                currentPlan.planData.managedProductsLimit === 0
                  ? ('BILLING.SUBSCRIPTION.COMPARE.MANAGED_PRODUCTS_NUMBER_STARTER' | translate)
                  : ''
              }}
            </td>
            <td *ngIf="selectedPlan">
              {{ selectedPlan.planData.managedProductsLimit }}
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.TRANSACTION_FEE' | translate }}
            </td>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.NO_TRANSACTION_FEE' | translate }}
            </td>
            <td *ngIf="selectedPlan">
              {{ 'BILLING.SUBSCRIPTION.COMPARE.NO_TRANSACTION_FEE' | translate }}
            </td>
          </tr>
          <tr *ngIf="productType == 'df'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.MANAGED_VARIANTS_PER_DAY' | translate }}
            </td>
            <td>
              {{ currentPlan.planData.variantsPerDay }}
            </td>
            <td *ngIf="selectedPlan">
              {{ selectedPlan.planData.variantsPerDay }}
            </td>
          </tr>
          <tr *ngIf="productType == 'df'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.MANUAL_PROCESS_PER_DAY' | translate }}
            </td>
            <td>
              {{ currentPlan.planData.manualProcessPerDay }}
            </td>
            <td *ngIf="selectedPlan">
              {{ selectedPlan.planData.manualProcessPerDay }}
            </td>
          </tr>
          <tr *ngIf="productType == 'df'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED_RUN_TASK_DAY' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.automatedRunPerDay; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.automatedRunPerDay; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'df'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED_IMPORT_VARIANTS' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <!--<tr>-->
          <!--<td>-->
          <!--Trial days-->
          <!--</td>-->
          <!--<td>-->
          <!--{{currentPlan.planData.trialDays}}-->
          <!--</td>-->
          <!--<td>-->
          <!--{{selectedPlan.planData.trialDays}}-->
          <!--</td>-->
          <!--</tr>-->
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.CATALOGS_TO_CREATE' | translate }}
            </td>
            <td>
              <div fxLayoutAlign="end center">
                <span *ngIf="currentPlan.planData.catalogNum === 0">{{
                  'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED' | translate
                }}</span>
                <ng-container *ngIf="currentPlan.planData.catalogNum > 0">
                  <span>{{ currentPlan.planData.catalogNum }}</span>
                  <ng-container *ngIf="currentPlan.planData.catalogNum === 2">
                    <mat-icon
                      [matTooltip]="'BILLING.SUBSCRIPTION.COMPARE.TOOLTIP_FOR_CATALOG_LIMIT_FREE' | translate"
                      class="color-black"
                      >info</mat-icon
                    >
                  </ng-container>
                  <ng-container *ngIf="currentPlan.planData.catalogNum !== 2">
                    <mat-icon
                      [matTooltip]="
                        'BILLING.SUBSCRIPTION.COMPARE.TOOLTIP_FOR_CATALOG_LIMIT_WITH_SUBSCRIPTION' | translate
                      "
                      class="color-black"
                      >info</mat-icon
                    >
                  </ng-container>
                </ng-container>
              </div>
            </td>
            <td *ngIf="selectedPlan">
              <div fxLayoutAlign="end center">
                <span *ngIf="selectedPlan.planData.catalogNum === 0">{{
                  'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED' | translate
                }}</span>
                <ng-container *ngIf="selectedPlan.planData.catalogNum > 0">
                  <span>{{ selectedPlan.planData.catalogNum }}</span>
                  <ng-container *ngIf="selectedPlan.planData.catalogNum === 2">
                    <mat-icon
                      [matTooltip]="'BILLING.SUBSCRIPTION.COMPARE.TOOLTIP_FOR_CATALOG_LIMIT_FREE' | translate"
                      class="color-black"
                      >info</mat-icon
                    >
                  </ng-container>
                  <ng-container *ngIf="selectedPlan.planData.catalogNum !== 2">
                    <mat-icon
                      [matTooltip]="
                        'BILLING.SUBSCRIPTION.COMPARE.TOOLTIP_FOR_CATALOG_LIMIT_WITH_SUBSCRIPTION' | translate
                      "
                      class="color-black"
                      >info</mat-icon
                    >
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.ACCESS_TO_PRODUCTS' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.ALIBABA_PRODUCTS' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.ACCESS_TO_PRE_APPROVAL' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.approveSupplierAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.approveSupplierAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.ACCESS_TO_PREMIUM' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.premiumSupplierAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.premiumSupplierAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.ACCESS_TO_VIP' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.vipSupplierAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.vipSupplierAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.PRODUCT_UPLOAD_TO_STORE' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.canSync; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.canSync; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.AUTOMATED_PRODUCT_UPDATES' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.autoProcessPerDay !== 0; else noFeature">
                {{ currentPlan.planData.autoProcessPerDay }}{{ 'BILLING.SUBSCRIPTION.COMPARE.PER_DAY' | translate }}
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.autoProcessPerDay !== 0; else noFeature">
                {{ selectedPlan.planData.autoProcessPerDay }}{{ 'BILLING.SUBSCRIPTION.COMPARE.PER_DAY' | translate }}
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.MANUAL_PRODUCT_UPDATES' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.manualProcessPerDay !== 0; else noFeature">
                {{ currentPlan.planData.manualProcessPerDay }}{{ 'BILLING.SUBSCRIPTION.COMPARE.PER_DAY' | translate }}
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.manualProcessPerDay !== 0; else noFeature">
                {{ selectedPlan.planData.manualProcessPerDay }}{{ 'BILLING.SUBSCRIPTION.COMPARE.PER_DAY' | translate }}
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.AUTOMATED_ORDER_SYNC' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.automatedOrderDownload; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.automatedOrderDownload; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <!--          <tr *ngIf="productType == 'rmp'">-->
          <!--            <td>-->
          <!--              {{ 'BILLING.SUBSCRIPTION.COMPARE.AUTOMATED_PRODUCT_UPLOADS' | translate}}-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <ng-container *ngIf="currentPlan.planData.automatedProductUploads; else noFeature">-->
          <!--                <mat-icon>check</mat-icon>-->
          <!--              </ng-container>-->
          <!--            </td>-->
          <!--            <td *ngIf="selectedPlan">-->
          <!--              <ng-container *ngIf="selectedPlan.planData.automatedProductUploads; else noFeature">-->
          <!--                <mat-icon>check</mat-icon>-->
          <!--              </ng-container>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr *ngIf="productType == 'rmp'">-->
          <!--            <td>-->
          <!--              {{ 'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED_MANUAL_PRODUCT_UPDATE' | translate}}-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <mat-icon>check</mat-icon>-->
          <!--            </td>-->
          <!--            <td *ngIf="selectedPlan">-->
          <!--              <mat-icon>check</mat-icon>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr *ngIf="productType == 'rmp'">-->
          <!--            <td>-->
          <!--              {{ 'BILLING.SUBSCRIPTION.COMPARE.AUTOMATED_ORDER_DOWNLOAD' | translate}}-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <ng-container *ngIf="currentPlan.planData.automatedOrderDownload; else noFeature">-->
          <!--                <mat-icon>check</mat-icon>-->
          <!--              </ng-container>-->
          <!--            </td>-->
          <!--            <td *ngIf="selectedPlan">-->
          <!--              <ng-container *ngIf="selectedPlan.planData.automatedOrderDownload; else noFeature">-->
          <!--                <mat-icon>check</mat-icon>-->
          <!--              </ng-container>-->
          <!--            </td>-->
          <!--          </tr>-->
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.MANUAL_ORDER_DOWNLOAD' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.manualDownloadOrder; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.manualDownloadOrder; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED_SUPPLIER' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.UNLIMITED_ORDERS' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.unlimitedOrders; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.unlimitedOrders; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.DYNAMIC_PRICING_SETTINGS' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.TIERED_PRICING_MODEL' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.CURRENCY_CONVERT' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.SALES_REPORT' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.salesReports; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.salesReports; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.CHAT_WITH_SUPPLIERS' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.accessChat; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.accessChat; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.ACCESS_DETAILS' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.contactAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.contactAccess; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.PRODUCT_EDITOR' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.pim; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.pim; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="productType == 'rmp'">
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.VARIANT_RENAMING' | translate }}
            </td>
            <td>
              <ng-container *ngIf="currentPlan.planData.variantRenaming; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
            <td *ngIf="selectedPlan">
              <ng-container *ngIf="selectedPlan.planData.variantRenaming; else noFeature">
                <mat-icon>check</mat-icon>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.STAFF_ACCOUNTS' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'BILLING.SUBSCRIPTION.COMPARE.FREE_EMAIL_SUPPORT' | translate }}
            </td>
            <td>
              <mat-icon>check</mat-icon>
            </td>
            <td *ngIf="selectedPlan">
              <mat-icon>check</mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button class="syncee-blue-button" mat-dialog-close *ngIf="!isNextPlan">
      {{ 'BILLING.SUBSCRIPTION.COMPARE.USE_THIS_PLAN_LABEL' | translate }}
    </button>
    <button
      mat-button
      class="syncee-blue-button"
      (click)="closeDialogWithNextPlan()"
      *ngIf="isNextPlan && selectedPlan"
    >
      {{ 'BILLING.SUBSCRIPTION.COMPARE.USE_THE_NEXT_PLAN_LABEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
<ng-template #noFeature>
  <mat-icon class="color-red">close</mat-icon>
</ng-template>
