<!--<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">-->
<!--  <div class="logo">-->
<!--    <div class="logo-icon" [routerLink]="'/quick-guide'"></div>-->
<!--  </div>-->

<!--  <div class="buttons">-->
<!--    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>-->
<!--      <mat-icon class="secondary-text">menu</mat-icon>-->
<!--    </button>-->

<!--    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>-->
<!--      <mat-icon class="secondary-text">arrow_back</mat-icon>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->

<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" overflowDirective>
  <div class="navbar-content">
    <fuse-navigation layout="vertical"></fuse-navigation>
  </div>
</div>
