<app-custom-dialog-wrapper [headerLeftRef]="backToMySuppliers">
  <div class="mobile-catalog-details-wrapper" fxLayout="column" fxLayoutGap="40px">
    <div class="catalog-name-wrapper" fxLayout="column" fxLayoutGap="8px">
      <h3>{{ data.catalog.catalog.name }}</h3>
      <h5>Catalog of {{ data.supplierName }}</h5>
    </div>
    <div class="catalog-data-wrapper" fxLayout="column" fxLayoutGap="24px">
      <div fxLayout="column" fxLayoutGap="8px">
        <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.PERMISSION' | translate }}</h5>
        <ng-container [ngSwitch]="data.catalog.approved">
          <div *ngSwitchCase="'1'">{{ 'MY_SUPPLIERS.TOOLTIPS.REQUEST_APPROVED' | translate }}</div>
          <div *ngSwitchCase="'0'">{{ 'MY_SUPPLIERS.TOOLTIPS.REQUEST_PENDING' | translate }}</div>
          <div *ngSwitchCase="'-1'">
            {{ 'MY_SUPPLIERS.TOOLTIPS.REQUEST_DENIED' | translate }}
          </div>
          <div *ngSwitchDefault></div>
        </ng-container>
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.DATE_ADDED' | translate }}</h5>
        <div>
          {{ data.catalog.addedDate | customDate }}
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.SHIPPING_TYPE' | translate }}</h5>
        <ng-container [ngSwitch]="data.catalog.catalog.settings.shipping_type">
          <div *ngSwitchCase="'both'">
            <span class="pr-8">{{ 'MARKETPLACE.MY_SUPPLIER.WHOLESAILING' | translate }}</span>
            <mat-slide-toggle
              [checked]="data.catalog.dropship"
              (change)="toggleChanged($event)"
              class="mat-slide-fixed-color"
            ></mat-slide-toggle>
            <span class="pl-8">{{ 'MARKETPLACE.MY_SUPPLIER.DROPPSHIPPING' | translate }}</span>
          </div>
          <div *ngSwitchDefault>
            {{ data.catalog.catalog.settings.shipping_type }}
          </div>
        </ng-container>
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.ORDER_INFORMATION' | translate }}</h5>
        <ng-container [ngSwitch]="data.catalog.catalog.adminApprovedStatus">
          <div *ngSwitchCase="true" class="clickable-underlined">
            <ng-container [ngSwitch]="data.isAutoOrder">
              <div *ngSwitchCase="true" fxLayout="column" fxLayoutGap="8px">
                <div>
                  {{ 'MY_SUPPLIERS.DIALOGS.ORDER_INFO.INFO.AUTO_ORDER' | translate }}
                </div>
                <div [innerHTML]="'MY_SUPPLIERS.DIALOGS.ORDER_INFO.HELP_CENTER_LINK' | translate"></div>
              </div>
              <div *ngSwitchCase="false">
                <ng-container *ngIf="!!data.hasAccessToContacts; else needSubscriptionBadge">
                  <div
                    [innerHTML]="
                      'MY_SUPPLIERS.DIALOGS.ORDER_INFO.INFO.NON_AUTO_ORDER' | translate : { website: data.website }
                    "
                  ></div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div *ngSwitchCase="false" class="disabled-texts">
            {{ 'MARKETPLACE.MY_SUPPLIER.DISABLED_CATALOG' | translate }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</app-custom-dialog-wrapper>

<ng-template #backToMySuppliers>
  <div (click)="closeAndBackToMySuppliers()" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
    <mat-icon class="s-20">keyboard_arrow_left</mat-icon>
    <a>{{ 'MY_SUPPLIERS.ACTIONS.BACK_TO_MY_SUPPLIERS' | translate }}</a>
  </div>
</ng-template>

<ng-template #needSubscriptionBadge>
  <app-need-subscription-badge></app-need-subscription-badge>
</ng-template>
