import { Action } from '@ngrx/store';
import { SupplierCatalog } from '../../vo/supplier-catalog/supplier-catalog';
import { SupplierCatalogUpdate } from '../../vo/supplier-catalog/supplier-catalog-update';
import { PatchTaskDto } from '../../service/task/task.service';
import { CreateTaskData } from '../../service/task/model/create-task-data';

export enum TaskActionType {
  GET_TASKS_START = '[Task] Get Tasks Start',
  GET_TASKS_SUCCESS = '[Task] Get Tasks Success',
  CREATE_TASK_START = '[Task] Create Task Start',
  CREATE_TASK_SUCCESS = '[Task] Create Task Success',
  UPDATE_TASK_START = '[Task] Update Task Start',
  UPDATE_TASK_SUCCESS = '[Task] Update Task Success',
  DELETE_TASK_START = '[Task] Delete Task Start',
  DELETE_TASK_SUCCESS = '[Task] Delete Task Success',
}

export class GetTasksStartAction implements Action {
  readonly type: TaskActionType = TaskActionType.GET_TASKS_START;
}

export class GetTasksSuccessAction implements Action {
  readonly type: TaskActionType = TaskActionType.GET_TASKS_SUCCESS;
  constructor(public payload: SupplierCatalog[]) {}
}

export class CreateTaskStartAction implements Action {
  readonly type: TaskActionType = TaskActionType.CREATE_TASK_START;
  constructor(public payload: CreateTaskData) {}
}

export class CreateTaskSuccessAction implements Action {
  readonly type: TaskActionType = TaskActionType.CREATE_TASK_SUCCESS;
}

export class UpdateTaskStartAction implements Action {
  readonly type: TaskActionType = TaskActionType.UPDATE_TASK_START;
  constructor(public payload: UpdateTaskPayload) {}
}

export class UpdateTaskSuccessAction implements Action {
  readonly type: TaskActionType = TaskActionType.UPDATE_TASK_SUCCESS;
}

export class DeleteTaskStartAction implements Action {
  readonly type: TaskActionType = TaskActionType.DELETE_TASK_START;
  public constructor(public payload: number) {}
}

export class DeleteTaskSuccessAction implements Action {
  readonly type: TaskActionType = TaskActionType.DELETE_TASK_SUCCESS;
}

export type TasksAction =
  | GetTasksStartAction
  | GetTasksSuccessAction
  | CreateTaskStartAction
  | CreateTaskSuccessAction
  | UpdateTaskStartAction
  | UpdateTaskSuccessAction
  | DeleteTaskStartAction
  | DeleteTaskSuccessAction;

export interface UpdateTaskPayload {
  updateType: TaskUpdateType;
  taskId: number;
  data: SupplierCatalogUpdate & PatchTaskDto;
}

export type TaskUpdateType = 'task' | 'catalog';
