<div class="platform-actions w-100p">
  <div
    class="platform-actions-inner w-100p"
    fxLayout="column"
    fxLayout.sm="row-reverse"
    fxLayout.lt-sm="column"
    fxLayoutAlign="center start"
    fxLayoutGap="20px"
  >
    <button *ngIf="!isInstalled" mat-button class="syncee-blue-button w-100p" (click)="installSynceeApp()">
      {{ 'GETTING_STARTED.ADD_YOUR_STORE.INSTALL' | translate }}
    </button>
    <button
      *ngIf="isInstalled"
      [routerLink]="platform.url"
      mat-dialog-close=""
      mat-button
      class="syncee-light-blue-button w-100p"
    >
      <mat-icon class="s-20">open_in_new</mat-icon>
      {{ 'GETTING_STARTED.ADD_YOUR_STORE.OPEN_APP' | translate }}
    </button>
    <app-help-box></app-help-box>
    <div *ngIf="isInstalled" class="w-100p" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
        <div class="installed-check">
          <mat-icon class="s-10 installed-tick">check</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="center start">
          <div class="typography-body-1">{{ 'GETTING_STARTED.ADD_YOUR_STORE.INSTALLED' | translate }}</div>
          <div class="typography-overline syncee-grey-400-text">
            {{ (installedApp$ | async).date | dateAgo }}
          </div>
        </div>
      </div>
      <button (click)="uninstallSynceeApp()" mat-button class="syncee-light-red-button w-100p">
        {{ 'GETTING_STARTED.ADD_YOUR_STORE.UNINSTALL' | translate }}
      </button>
    </div>
  </div>
</div>
