<div class="preview-container" *ngIf="selectedSample">
  <div class="samples-paginate" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-icon-button [disabled]="currentIndex < 1" (click)="paginateBackBtn()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button mat-icon-button [disabled]="currentIndex >= samples.length - 1" (click)="paginateFowardBtn()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="images-container">
      <div class="images">
        <div class="main-image-container">
          <div class="main-image">
            <img src="{{ selectedImage }}" (click)="openProductPictureViewer(0)" />
          </div>
        </div>
        <div class="small-images-container">
          <ul class="small-images">
            <li class="small-image" *ngFor="let image of selectedSample.IMAGES">
              <img
                src="{{ image }}"
                (click)="changeImage($event, image)"
                [class.selected-image]="image == selectedImage"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="details-container">
      <h1 mat-dialog-title>{{ selectedSample.TITLE }}</h1>
      <!--<div class="supplier">by <span>{{selectedSample.SUPPLIER?.companyName}}</span></div>-->

      <div class="general-info">
        <div class="category">
          {{ 'EXPLORE_PRODUCTS.PRODUCT_DIALOG.PRODUCT_CATEGORY' | translate }} : {{ selectedSample.CATEGORY }}
        </div>
        <div class="brand">{{ 'EXPLORE_PRODUCTS.PRODUCT_DIALOG.BRAND' | translate }} : {{ selectedSample.BRAND }}</div>
        <div>{{ 'TASKWIZARD.ADDFILES.MODALS.VARIANTS' | translate }}</div>
        <mat-table
          [dataSource]="dataSourceVariants"
          id="variants-table"
          class="table-margin"
          matSort
          matSortActive="userName"
          matSortDirection="asc"
          matSortDisableClear
        >
          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-header class="id-column">{{
              'TASKWIZARD.ADDFILES.MODALS.PRICE' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.PRICE }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="qty">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              'TASKWIZARD.ADDFILES.MODALS.QTY' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.QTY }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="sku">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              'TASKWIZARD.ADDFILES.MODALS.SKU' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.SKU }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="discounted_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              'TASKWIZARD.ADDFILES.MODALS.DISCOUNTED' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.DISCOUNTED_PRICE }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="original_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              'TASKWIZARD.ADDFILES.MODALS.PRICE' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.ORIGINAL_PRICE }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="shopify_option_value_1">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              'TASKWIZARD.ADDFILES.MODALS.SHOPIFY_OPTION_VALUE_1' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.SHOPIFY_OPTION_VALUE_1 }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="shopify_option_value_2">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              'TASKWIZARD.ADDFILES.MODALS.SHOPIFY_OPTION_VALUE_2' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let variant">{{ variant.SHOPIFY_OPTION_VALUE_2 }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedVColumns"></mat-header-row>
          <mat-row matRipple *matRowDef="let variant; columns: displayedVColumns"> </mat-row>
        </mat-table>
      </div>

      <div *ngIf="!showFull" class="description">
        {{ 'EXPLORE_PRODUCTS.PRODUCT_DIALOG.PRODUCT_DESCRIPTION' | translate }}:
        <div [innerHTML]="selectedSample.DESCRIPTION"></div>
        <span class="desc-gradient"></span>
      </div>
      <div class="show-more" (click)="showMoreDescription()" *ngIf="!showFull">
        {{ 'EXPLORE_PRODUCTS.PRODUCT_DIALOG.SHOW_FULL_DESCRIPTION' | translate }}
        <div class="arrow">
          <span></span>
          <!--<span></span>-->
          <!--<span></span>-->
        </div>
      </div>
      <div class="full-description" *ngIf="showFull">
        {{ 'EXPLORE_PRODUCTS.PRODUCT_DIALOG.PRODUCT_DESCRIPTION' | translate }}:
        <div [innerHTML]="selectedSample.DESCRIPTION"></div>
      </div>
      <br />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'EXPLORE_PRODUCTS.PRODUCT_DIALOG.CLOSE' | translate }}</button>
  </mat-dialog-actions>

  <app-product-image-viewer #imageViewer></app-product-image-viewer>
</div>
