import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { RoleTypesEnum } from '../../vo/roles/roles';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private springRestService: SpringRestService) {}

  addRole(data: RoleData): Observable<null> {
    return this.springRestService.post(MicroserviceNames.AUTH, `/add-role`, data, {}, true);
  }

  removeRole(data: RoleData): Observable<null> {
    return this.springRestService.post(MicroserviceNames.AUTH, `/remove-role`, data, {}, true);
  }
}

interface RoleData {
  roles: RoleTypesEnum[];
}
