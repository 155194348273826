<div
  *ngIf="percentage > 0; else decline"
  class="inc-dec-line incline"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="4px"
>
  <mat-icon class="s-20">arrow_upward</mat-icon>
  <div>
    {{ percentage | percent : '1.0-2' }}
  </div>
</div>

<ng-template #decline>
  <div class="inc-dec-line decline" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
    <mat-icon class="s-20">arrow_downward</mat-icon>
    <div>
      {{ percentage | percent : '1.0-2' }}
    </div>
  </div>
</ng-template>
