<ng-container *ngIf="!isLoading">
  <ng-container
    *ngIf="
      product.SETTINGS?.showPrices ||
        (!product.SETTINGS?.showPrices && catalogStatus?.approved == '1') ||
        !product.SETTINGS?.approveNeeded;
      else needApproveToPrice
    "
  >
    <div class="prices" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
      <div
        class="price"
        fxLayout="column"
        fxLayout.lt-sm="row"
        fxLayoutAlign="center start"
        fxLayoutAlign.lt-sm="space-between center"
      >
        <div class="title">{{ 'PRODUCT_DETAILS.PRODUCT_COST' | translate }}</div>
        <div class="amount">
          {{
            prices.costPrice
              | currencyExchangeTo : product.SUPPLIER?.paymentCurrency : ecomCurrency
              | async
              | currencyTransform : ecomCurrency
              | async
          }}
        </div>
      </div>
      <div
        class="rrp"
        fxLayout="column"
        fxLayout.lt-sm="row"
        fxLayoutAlign="center start"
        fxLayoutAlign.lt-sm="space-between center"
      >
        <div class="title">{{ 'PRODUCT_DETAILS.RETAILER_PRICE' | translate }}</div>
        <div class="amount">
          {{
            prices.retailPrice
              | currencyExchangeTo : product.SUPPLIER?.paymentCurrency : ecomCurrency
              | async
              | currencyTransform : ecomCurrency
              | async
          }}
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #needApproveToPrice>
  <div>
    {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.APPROVE_NEEDED_LABEL' | translate }}
  </div>
</ng-template>
