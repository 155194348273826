import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { FuseConfigService } from '../../../../../../@fuse/services/config.service';
import { MARKETPLACE_NAVIGATION } from '../../../../../navigation/navigation-routes/common/marketplace.navigation';
import { AiChatService } from '../../../services/ai-chat.service';

@Component({
  selector: 'app-ai-chat-open-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './ai-chat-open-button.component.html',
  styleUrls: ['./ai-chat-open-button.component.scss'],
})
export class AiChatOpenButtonComponent implements OnInit {
  constructor(
    private fuseConfigService: FuseConfigService,
    public aiChatService: AiChatService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  aiChatClicked(): void {
    const hasAiChat = !this.fuseConfigService.getConfigValue().layout.aiChat.hidden;
    if (hasAiChat) {
      this.aiChatService.toggleChat();
    } else {
      this.router
        .navigate([MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH], {
          skipLocationChange: true,
        })
        .then(() => {
          this.aiChatService.showChat();
        });
    }
  }
}
