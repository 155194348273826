import { Pipe, PipeTransform } from '@angular/core';
import { FulfillmentStatusEnum } from '../../main/manage-orders-new/model/enums/fulfillment-status-enum';
import { PaymentStatusEnum } from '../../main/manage-orders-new/model/enums/payment-status-enum';
import { StatusBadgeColorsEnum } from '../../main/manage-orders-new/model/enums/status-badge-colors';
import { AutoOrderStatusEnum } from '../../main/manage-orders-new/model/enums/auto-order-status-enum';

@Pipe({
  name: 'statusColor',
  standalone: true,
})
export class StatusColorPipe implements PipeTransform {
  transform(value: FulfillmentStatusEnum | PaymentStatusEnum | AutoOrderStatusEnum | string, args?: any): string {
    return StatusBadgeColorsEnum[value];
  }
}
