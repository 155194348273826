<div class="typography-subtitle-1 pt-32 pb-8">
  {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_LIST.POPULAR_PRODUCTS' | translate }}
</div>
<app-product-card-list
  [products]="products"
  [numberOfProducts]="products.length"
  [areProductsLoading]="isLoading"
  [selectable]="selectable"
  [ecomCurrency]="currency | async"
  [hasAds]="false"
  [isFreePlan]="(isFreePlan | async) ?? true"
  [needsFillers]="true"
  [from]="0"
  [productCardWidth]="productCardWidth"
  [generateMicrodata]="true"
></app-product-card-list>
