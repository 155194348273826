<div class="container" [ngStyle]="{ gap: screenService.small ? '28px' : '32px' }">
  <div class="content-container">
    <div class="text-container">
      <div class="h3 text-center syncee-grey-800-fg">{{ config.payload['title'] | translate }}</div>
      <div [ngClass]="descriptionStyle">
        {{ config.payload['description'] | translate }}
      </div>
    </div>

    <app-tile-select
      gridGap="20px"
      backgroundColor="var(--app-custom-syncee-silver)"
      [defaultValue]="changeRoleService.keepRole"
      [options]="options"
      [columns]="screenService.small ? '1' : '2'"
      [rowHeight]="screenService.small ? undefined : '280px'"
      [columnWidth]="screenService.small ? undefined : '280px'"
      [borderRadius]="screenService.small ? '8px' : '20px'"
      (onChange)="handleChange($event)"
    ></app-tile-select>
  </div>

  <button mat-button class="syncee-blue-button" (click)="handleNext()">
    {{ config.payload['next'] | translate }}
  </button>
</div>
