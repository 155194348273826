import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { RestService } from '../../../service/rest/rest.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';

export class ProductViewDataSource<T> implements DataSource<T> {
  private countSubject = new BehaviorSubject<number>(0);
  public totalRows = this.countSubject.asObservable();

  private baseTableSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private httpParams = new HttpParams();

  private customId: number = null;
  private paramString: string = null;

  private _paginator: MatPaginator | null;

  constructor(private restUrl: string, private restService: RestService, customId: number, paramString: string) {
    this.customId = customId;
    this.paramString = paramString;
  }

  connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
    return this.baseTableSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.baseTableSubject.complete();
  }

  get paginator(): MatPaginator | null {
    return this._paginator;
  }

  set paginator(value: MatPaginator | null) {
    this._paginator = value;

    if (!Utils.isNullOrUndefined(this.paginator)) {
      this.setPaginatorTotalRowsListener();
      this.setPaginatorPageChangeListener();
    }
  }

  setPaginatorTotalRowsListener(): void {
    this.totalRows
      .pipe(
        tap((count) => {
          this.paginator.length = count as number;
        })
      )
      .subscribe();
  }

  setPaginatorPageChangeListener(): void {
    this.paginator.page
      .pipe(
        tap(() => {
          this.loadValues();
        })
      )
      .subscribe();
  }

  loadValues(offset?: number, pageSize?: number): any {
    this.loadingSubject.next(true);

    if (this.paginator) {
      offset = this._paginator.pageSize * this._paginator.pageIndex;
      pageSize = this.paginator.pageSize;
    }

    this.fetchData(offset, pageSize, this.customId.toString(), this.paramString)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((value: DataSourceResponseForEllastic<T>) => {
        this.baseTableSubject.next(value.data);
        this.countSubject.next(value.totalRows);
      });
  }

  fetchData(
    offset: number,
    pageSize: number,
    taskId: string,
    parameterName?: string
  ): Observable<DataSourceResponseForEllastic<T>> {
    let paramName = 'TASK_ID';

    if (!Utils.isNullOrUndefined(parameterName)) {
      paramName = parameterName;
    }

    const queryString =
      '{' + '"' + paramName + '":' + taskId + ',"size":' + pageSize.toString() + ',"from":' + offset.toString() + '}';

    console.log(queryString);

    this.httpParams = this.httpParams
      .set('query', queryString)
      .set('order', 'LAST_MODIFIED:DESC')
      .set('taskId', taskId);

    return this.restService
      .getWithClass(this.restUrl, this.httpParams)
      .pipe(
        map(
          (res) =>
            new DataSourceResponseForEllastic<T>(res.getFirstData()['totalRows'], res.getFirstData()['products'] as T[])
        )
      );
  }
}

export class DataSourceResponseForEllastic<T> {
  totalRows: number;
  data: T[];

  constructor(totalRows: number, data: T[]) {
    this.totalRows = totalRows;
    this.data = data;
  }
}
