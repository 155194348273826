import { RestService } from '../rest/rest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestResponse } from '../rest/rest-response';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TaskStepLockService {
  public REST_URL = 'TaskStepLockService/';

  constructor(private restService: RestService) {}

  public getTaskSteps(): Observable<any[]> {
    return this.restService.get(this.REST_URL + 'getSteps').pipe(map((res) => res.getData()));
  }

  public getTaskStepsForTask(taskId: number): Observable<any[]> {
    return this.restService.get(this.REST_URL + 'getLockedStepsForTask?taskId=' + taskId).pipe(
      map((res: RestResponse) => {
        return res.getFirstData()['lockedSteps'];
      })
    );
  }

  public unlockTaskStep(data: RestStepVo): Observable<any> {
    return this.restService.post(this.REST_URL + 'deleteStepsFromTask', data).pipe(map((res) => res.getData()));
  }

  public lockTaskStep(data: RestStepVo): Observable<any> {
    return this.restService.post(this.REST_URL + 'addStepsToTask', data).pipe(map((res) => res.getData()));
  }
}

interface RestStepVo {
  taskId: number;
  steps: Steps;
}

export type Steps = 'FILTER' | 'PRICING';
