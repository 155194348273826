import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoriteProductsComponent } from './favorite-products/favorite-products.component';
import { NoProductsAddedComponent } from './no-products-added/no-products-added.component';
import { FilterSelectorRowComponent } from '../../shared/components/filter-selector-row/filter-selector-row.component';
import { ProductCardListComponent } from '../../shared/components/product-card-list/product-card-list.component';
import { SimplePaginatorWithArrowsComponent } from '../../shared/components/paginations/simple-paginator-with-arrows/simple-paginator-with-arrows.component';
import { ErrorMessageComponent } from '../../shared/components/error-message/error-message.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { FavoritesPageFilterComponent } from './favorites-page-filter/favorites-page-filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FollowedSuppliersComponent } from './followed-suppliers/followed-suppliers.component';
import { SupplierCardListComponent } from '../../shared/components/supplier-card-list/supplier-card-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NoFollowedSuppliersComponent } from './no-followed-suppliers/no-followed-suppliers.component';

import { NoSearchResultsComponent } from '../../shared/components/no-search-results/no-search-results.component';
import { FavoriteNoResultsForSearchComponent } from './favorite-no-results-for-search/favorite-no-results-for-search.component';
import { PaginatorLoadMoreComponent } from 'app/shared/components/paginator-load-more/paginator-load-more.component';

@NgModule({
  declarations: [
    FavoriteProductsComponent,
    NoProductsAddedComponent,
    FavoriteNoResultsForSearchComponent,
    FavoritesPageFilterComponent,
    FollowedSuppliersComponent,
    NoFollowedSuppliersComponent,
  ],
  imports: [
    CommonModule,
    FilterSelectorRowComponent,
    ProductCardListComponent,
    SimplePaginatorWithArrowsComponent,
    ErrorMessageComponent,
    FlexModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    TranslateModule,
    SupplierCardListComponent,
    MatProgressSpinnerModule,
    ExtendedModule,
    NoSearchResultsComponent,
    PaginatorLoadMoreComponent,
  ],
  exports: [FavoriteProductsComponent, NoProductsAddedComponent, FollowedSuppliersComponent],
})
export class FavoritesPageModule {}
