<div class="ftp-holder">
  <mat-expansion-panel [expanded]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'TASKWIZARD.ADDFILES.FTP.FTP' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #ftpConnectionForm="ngForm" novalidate class="simple-form">
      <div fxLayout="column" fxLayout.gt-lg="row wrap" fxLayoutAlign.gt-lg="start center">
        <!--Host-->
        <mat-form-field>
          <input
            id="ftpHost"
            name="host"
            matInput
            placeholder="{{ 'TASKWIZARD.ADDFILES.FTP.HOST_PLACEHOLDER' | translate }}"
            #ftpHost="ngModel"
            [disabled]="disableNotCredentialsInput"
            [(ngModel)]="ftpCredentials.host"
            required
            class="user-input"
          />
          <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.HOST' | translate }}</mat-error>
        </mat-form-field>
        <!--Port-->
        <mat-form-field>
          <input
            id="ftpPort"
            name="port"
            matInput
            placeholder="{{ 'TASKWIZARD.ADDFILES.FTP.PORT_PLACEHOLDER' | translate }}"
            #ftpPort="ngModel"
            [disabled]="disableNotCredentialsInput"
            [(ngModel)]="ftpCredentials.port"
            required
            class="user-input"
          />
          <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.PORT' | translate }}</mat-error>
        </mat-form-field>
        <!--UserName Input-->
        <mat-form-field>
          <input
            id="userName"
            name="name"
            matInput
            placeholder="{{ 'TASKWIZARD.ADDFILES.FTP.USERNAME_PLACEHOLDER' | translate }}"
            #username="ngModel"
            [(ngModel)]="ftpCredentials.username"
            required
            class="user-input"
          />
          <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.USERNAME' | translate }}</mat-error>
        </mat-form-field>
        <!--Password Input-->
        <mat-form-field>
          <input
            id="userPassword"
            name="password"
            matInput
            type="password"
            autocomplete="new-password"
            placeholder="{{ 'TASKWIZARD.ADDFILES.FTP.PASSWORD_PLACEHOLDER' | translate }}"
            [(ngModel)]="ftpCredentials.password"
            required
          />
          <mat-error>
            {{ 'TASKWIZARD.ADDFILES.FTP.PASSWORD' | translate }}
          </mat-error>
        </mat-form-field>
        <!--Root-->
        <mat-form-field>
          <input
            id="ftpRoot"
            name="root"
            matInput
            placeholder="{{ 'TASKWIZARD.ADDFILES.FTP.ROOT_PLACEHOLDER' | translate }}"
            #ftpRoot="ngModel"
            [disabled]="disableNotCredentialsInput"
            [(ngModel)]="ftpCredentials.root"
            class="user-input"
          />
          <mat-error>{{ 'TASKWIZARD.ADDFILES.FTP.ROOT' | translate }}</mat-error>
        </mat-form-field>
        <div class="action-panel">
          <mat-checkbox
            id="ftpPassive"
            name="passive"
            matCheckbox
            #passive="ngModel"
            [(ngModel)]="ftpCredentials.passive"
            class="user-input"
          >
            {{ 'TASKWIZARD.ADDFILES.FTP.PASSIVE' | translate }}
          </mat-checkbox>

          <mat-checkbox
            id="ftpSsl"
            name="ssl"
            matCheckbox
            [checked]="ftpCredentials.isSsl"
            #ssl="ngModel"
            [(ngModel)]="ftpCredentials.isSsl"
            style="margin-left: 5px"
            class="user-input"
          >
            {{ 'TASKWIZARD.ADDFILES.FTP.SSL' | translate }}
          </mat-checkbox>

          <button
            style="margin-left: 10px"
            mat-button
            color="accent"
            (click)="connectFtp()"
            [disabled]="isConnectButtonPressed"
          >
            {{ 'TASKWIZARD.ADDFILES.FTP.CONNECT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>

  <div [hidden]="hideFtpManager">
    <h1>
      {{ 'TASKWIZARD.ADDFILES.FTP.FTP_MANAGER' | translate }}
    </h1>
    <div class="mat-card mat-elevation-z4 p-24">
      <app-breadcrumb-navigation
        [navItems]="breadCrumb"
        (navItemChange)="breadCrumbCalback($event)"
      ></app-breadcrumb-navigation>
      <hr />
      <mat-grid-list cols="2" rowHeight="50px" class="ftp-file-list">
        <mat-grid-tile
          class="text-inside-grid"
          fxLayoutAlign="start center"
          *ngFor="let file of fileList"
          (dblclick)="rowDoubleClick(file)"
          (click)="rowSingleClick(file)"
          [ngClass]="{ selected: file == selected }"
          matRipple
          [@animate]="{ value: '*', params: { y: '100%' } }"
        >
          <div class="ftp-align-left">
            <mat-icon mat-list-icon>{{ file.isDirectory ? 'folder' : 'insert_drive_file' }}</mat-icon>
            {{ file.name }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px" class="modal-action-panel">
    <div>
      <button class="mat-button" style="margin-right: 5px" (click)="close()">
        {{ 'TASKWIZARD.ADDFILES.FTP.CLOSE' | translate }}
      </button>
      <button class="mat-button mat-accent" [disabled]="disableDownloadButton" (click)="download(selected)">
        {{ 'TASKWIZARD.ADDFILES.FTP.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
