import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-plan-upsell-box-header',
  standalone: true,
  imports: [CommonModule, FlexModule],
  templateUrl: './plan-upsell-box-header.component.html',
  styleUrls: ['./plan-upsell-box-header.component.scss'],
})
export class PlanUpsellBoxHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;

  constructor() {}

  ngOnInit(): void {}
}
