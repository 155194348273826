<div class="notification-overlay-container" fxLayout="column">
  <div class="action-bar-container syncee-grey-200-border-bottom" fxFlex="0 0 auto">
    <app-notification-overlay-action-bar
      (settingsClicked)="navigateToSettings()"
      (readAllClicked)="handleMarkAllAsSeenClicked()"
    ></app-notification-overlay-action-bar>
  </div>
  <div class="list-container" overflowDirective>
    <app-notifications-list [markAllAsSeenClicked]="markAllAsSeenClicked"></app-notifications-list>
  </div>
</div>
