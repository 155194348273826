<div #addFilesContainer class="add-files-container">
  <ng-container *ngIf="taskName">
    <div class="source-changer-title">
      <h1>{{ 'TASKS.FILE_REPLACEMENT.TITLE' | translate }} "{{ taskName }}"</h1>
      <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
    <div>
      {{ 'TASKS.FILE_REPLACEMENT.DESCRIPTION' | translate }}
    </div>
  </ng-container>
  <mat-tab-group
    class="addFileMatGroup"
    [selectedIndex]="selectedTab"
    (selectedTabChange)="initProductSample($event.index)"
  >
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>
        <div class="file-mat-tab-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>{{ tab.label }}</span>
        </div>
      </ng-template>
      <app-source-settings-tab
        #fileManagerTabs
        *ngIf="tab.inputs"
        (sourceChanged)="onSourceChanged($event)"
        [data]="tab.inputs"
      >
      </app-source-settings-tab>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="tabs.length > 0" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="closeDialog()" mat-stroked-button>Cancel</button>
    <button
      [class.disabled-btn]="disableFinishBtn"
      [disabled]="disableFinishBtn"
      (click)="finish()"
      class="syncee-finish-btn"
      mat-button
    >
      {{ 'TASKS.FILE_REPLACEMENT.DONE' | translate }}
      Done
    </button>
  </div>
</div>
