import { Component, Input, OnInit } from '@angular/core';
import { RestService } from '../../../service/rest/rest.service';
import { USER_ROLES } from '../../../utils/Constants';
import { UserService } from '../../../service/user/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationSettings, NotificationSettingsOptions } from './notification-settings';
import { Observable } from 'rxjs';
import { CustomTableComponent, CustomTableDataResponse } from '../custom-table/custom-table.component';
import { catchError, map, tap } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatCheckboxModule,
    CustomTableComponent,
    MatTableModule,
    LoadingSpinnerComponent,
    FormsModule,
  ],
})
export class NotificationSettingsComponent implements OnInit {
  @Input() role = 3;
  displayedColumns: string[];

  isLoading = true;
  hasError = false;

  columnDefinitions = [
    { def: 'name', showForNotAdmin: true },
    { def: 'email', showForNotAdmin: true },
    { def: 'web', showForNotAdmin: true },
    { def: 'slack', showForNotAdmin: false },
  ];

  dataByRole = NotificationSettings;
  selectedLanguage = 'en';

  constructor(
    private restService: RestService,
    public _userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = Utils.isNullOrUndefined(this.translate.currentLang) ? 'en' : this.translate.currentLang;
    this.isLoading = false;
  }

  getDisplayedColumns(): string[] {
    const isAdmin = this.role === USER_ROLES.GLOBAL_ADMIN;
    return this.columnDefinitions.filter((cd) => isAdmin || cd.showForNotAdmin).map((cd) => cd.def);
  }

  getSettings(): Observable<CustomTableDataResponse<NotificationSettingsOptions>> {
    this.isLoading = true;
    return this.restService.get(`NotificationService/getTurnedOffNotifications?role=${this.role}`).pipe(
      tap(() => (this.isLoading = false)),
      catchError(() => {
        this.hasError = true;
        return [];
      }),
      map((response) => response.getData()),
      map((data) => {
        if (data.length > 0) {
          data.forEach((elem) => {
            this.dataByRole[this.role].map((element) => {
              if (element.id === elem.t_type) {
                element[elem.toff_type] = false;
              }
            });
          });
          return this.mapNotificationSettingsOptionsToTable(this.dataByRole[this.role]);
        } else {
          return this.mapNotificationSettingsOptionsToTable(this.dataByRole[this.role]);
        }
      })
    );
  }

  private mapNotificationSettingsOptionsToTable(
    notificationSettingsOptions: NotificationSettingsOptions[]
  ): CustomTableDataResponse<NotificationSettingsOptions> {
    return {
      data: notificationSettingsOptions,
      size: notificationSettingsOptions.length,
      page: 0,
      allItemsCount: notificationSettingsOptions.length,
    };
  }

  changeStatus(value, id, notiType): void {
    if (value.checked) {
      this.restService
        .post(`NotificationService/turnOnNotifications`, {
          type: id,
          notiType: notiType,
          role: this.role,
        })
        .subscribe(() => {});
    } else {
      this.restService
        .post(`NotificationService/turnOffNotifications`, {
          type: id,
          notiType: notiType,
          role: this.role,
        })
        .subscribe(() => {});
    }
  }
}
