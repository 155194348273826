import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-settings-dialog',
  templateUrl: './supplier-settings-dialog.component.html',
  styleUrls: ['./supplier-settings-dialog.component.scss'],
})
export class SupplierSettingsDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SupplierSettingsDialogComponent>) {}

  ngOnInit(): void {}

  handleSettingsChange(event): void {
    if (event) {
      this.dialogRef.close();
    }
  }
}
