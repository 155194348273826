import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-syncee-logo',
  templateUrl: './syncee-logo.component.html',
  styleUrls: ['./syncee-logo.component.scss'],
  standalone: true,
  imports: [RouterLink, NgStyle],
})
export class SynceeLogoComponent implements OnInit, OnChanges {
  @Input() width = '135px';
  @Input() height = '28px';
  @Input() color: LogoColor = 'default';
  @Input() redirectUrl = '/';

  @HostBinding('style.width') get w(): string {
    return this.width ? this.width : '';
  }

  @HostBinding('style.height') get h(): string {
    return this.height ? this.height : '';
  }

  logoUrl: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.logoUrl = this.getLogoUrl();
  }

  private getLogoUrl(): string {
    switch (this.color) {
      case 'white':
        return '/assets/images/logos/syncee-logo-white.svg';
      default:
        return '/assets/images/logos/syncee-logo.svg';
    }
  }
}

export type LogoColor = 'default' | 'white';
