import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-plan-upsell-box-feature-list',
  templateUrl: './plan-upsell-box-feature-list.component.html',
  styleUrls: ['./plan-upsell-box-feature-list.component.scss'],
  standalone: true,
  imports: [FlexModule, MatIconModule, NgForOf, TranslateModule, NgIf],
})
export class PlanUpsellBoxFeatureListComponent implements OnInit {
  @Input() listTitle: string;
  @Input() features: string[];
  constructor() {}

  ngOnInit(): void {}
}
