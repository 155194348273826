import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthDialogType } from '../../helpers/authentication-dialog-type';
import { FORGOTTEN_CONTENT_STEPS } from '../helpers/login-content-steps';

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
})
export class ForgotPasswordDialogComponent implements OnInit {
  public readonly FORGOTTEN_PASSWORD_STEP = FORGOTTEN_CONTENT_STEPS.FORGOTTEN_PASSWORD_STEP;
  public readonly RESET_PASSWORD_STEP = FORGOTTEN_CONTENT_STEPS.RESET_PASSWORD_STEP;
  public readonly bannerTextList: string[] = [
    'AUTHENTICATION.BANNER.RESET_PASSWORD.FIRST',
    'AUTHENTICATION.BANNER.RESET_PASSWORD.SECOND',
    'AUTHENTICATION.BANNER.RESET_PASSWORD.THIRD',
  ];

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordDialogData
  ) {}

  ngOnInit(): void {}
}

export interface ForgotPasswordDialogData {
  step: FORGOTTEN_CONTENT_STEPS;
  type: AuthDialogType;
}
