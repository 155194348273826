import { Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { StepperService } from '../../service/stepper.service';
import { ChangeRoleScreenService } from '../../service/change-role-screen.service';
import { ChangeRoleService } from '../../service/change-role.service';

import type { ChangeRoleDialogConfig } from '../../model/change-role-dialog-config-type';
import type { TileSelectOption } from '../../../../shared/components/tile-select/model/tile-select-option';
import type { EcomTypeEnum } from '../../../../vo/enums/ecom-type-enum';
import type { ChangeRoleDialogResponse } from '../../model/change-role-dialog-response';

import { ChangeRoleDialogComponent } from '../../change-role-dialog.component';
import { ConfirmationDialogsService } from 'app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ecom',
  templateUrl: './ecom.component.html',
  styleUrls: ['./ecom.component.scss'],
})
export class EcomComponent implements OnInit {
  public options: TileSelectOption<EcomTypeEnum>[];

  public config: ChangeRoleDialogConfig;

  constructor(
    public changeRoleService: ChangeRoleService,
    public screenService: ChangeRoleScreenService,
    public dialog: MatDialog,
    private stepperService: StepperService,
    private matDialogRef: MatDialogRef<ChangeRoleDialogComponent, ChangeRoleDialogResponse>,
    private confirmDialogService: ConfirmationDialogsService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.config = this.stepperService.config;

    this.options = this.changeRoleService.getPlatformOptions();
  }

  public handleSubmit(withAppInstall = true): void {
    if (withAppInstall && !this.changeRoleService.ecomType) {
      return;
    }

    this.matDialogRef.close({
      keepRole: this.changeRoleService.keepRole,
      role: this.changeRoleService.role,
      ecomType: this.changeRoleService.ecomType,
    });
  }

  public handleChange(value?: EcomTypeEnum): void {
    this.changeRoleService.ecomType = value;
  }

  openConfirmDialog(withAppInstall: boolean): void {
    this.confirmDialogService
      .confirm(
        this.translateService.instant('ROLES_ADD.CONFIRM_DIALOG.TITLE'),
        this.translateService.instant('ROLES_ADD.CONFIRM_DIALOG.CONTENT'),
        this.translateService.instant('ROLES_ADD.CONFIRM_DIALOG.CONFIRM'),
        this.translateService.instant('ROLES_ADD.CONFIRM_DIALOG.CANCEL')
      )
      .subscribe((accepted) => {
        if (accepted) {
          this.handleSubmit(withAppInstall);
        }
      });
  }

  get descriptionStyle(): object {
    return {
      'text-center syncee-grey-800-fg': true,
      'typography-body-3': this.screenService.small,
      'typography-body-1': !this.screenService.small,
    };
  }
}
