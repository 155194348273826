import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-supplier',
  templateUrl: './registration-supplier.component.html',
  styleUrls: ['./registration-supplier.component.scss'],
})
export class RegistrationSupplierComponent implements OnInit {
  @Input() titleKey: string;
  @Input() descriptionKey: string;
  // TODO: change supplier cover image if needed
  public readonly bannerCoverImage = 'assets/images/backgrounds/supplier-register.webp';
  public readonly bannerTextList: string[] = [
    'AUTHENTICATION.BANNER.REGISTRATION.SUPPLIER.FIRST',
    'AUTHENTICATION.BANNER.REGISTRATION.SUPPLIER.SECOND',
    'AUTHENTICATION.BANNER.REGISTRATION.SUPPLIER.THIRD',
  ];
  constructor() {}

  ngOnInit(): void {}
}
