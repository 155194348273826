import { Injectable } from '@angular/core';
import { AppState } from '../../app.state';
import { Store } from '@ngrx/store';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';
import { Observable, of, switchMap } from 'rxjs';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { map, take } from 'rxjs/operators';
import { hasRolesSelector } from '../../store/user/user.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { AuthenticationManagerService } from '../../main/authentication/authentication-manager.service';
import { AuthDialogType } from '../../main/authentication/helpers/authentication-dialog-type';
import { Router } from '@angular/router';
import { RoleDialogService } from '../guards/role-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class StaticCtaService {
  constructor(
    private store: Store<AppState>,
    private authenticationManagerService: AuthenticationManagerService,
    private roleDialogService: RoleDialogService,
    private router: Router
  ) {}

  startRetailer(route?: string): void {
    this.getAction(RolesEnum.RETAILER).subscribe((action) => this.handleAction(RolesEnum.RETAILER, action, route));
  }

  startSupplier(route?: string): void {
    this.getAction(RolesEnum.SUPPLIER).subscribe((action) => this.handleAction(RolesEnum.SUPPLIER, action, route));
  }

  private handleAction(forRole: RolesEnum, action: StaticCtaAction, route: string): void {
    switch (action) {
      case StaticCtaAction.OPEN_CROSS_REGISTER:
        this.roleDialogService.handleCenterNavigationAttempt(forRole);
        break;
      case StaticCtaAction.OPEN_REGISTER:
        this.authenticationManagerService.emitOpenRegistrationDialog(
          forRole === RolesEnum.RETAILER ? AuthDialogType.RETAILER : AuthDialogType.SUPPLIER
        );
        break;
      case StaticCtaAction.REDIRECT_TO_CENTER:
        this.router.navigate(
          !!route ? [route] : [...(forRole === RolesEnum.RETAILER ? ['retailer'] : ['supplier']), 'dashboard']
        );
        break;
    }
  }

  private getAction(forRole: RolesEnum): Observable<StaticCtaAction> {
    return this.store.select(isAuthenticatedSelector).pipe(
      omitNullOrUndefined(),
      take(1),
      switchMap((authenticated) =>
        authenticated
          ? this.store.select(hasRolesSelector, forRole).pipe(
              take(1),
              map((hasRole) => (hasRole ? StaticCtaAction.REDIRECT_TO_CENTER : StaticCtaAction.OPEN_CROSS_REGISTER))
            )
          : of(StaticCtaAction.OPEN_REGISTER)
      )
    );
  }
}

enum StaticCtaAction {
  'OPEN_REGISTER',
  'REDIRECT_TO_CENTER',
  'OPEN_CROSS_REGISTER',
}
