import { ActionsUnion, ActionTypes, AddFilterWithRedirectAction } from './product-search.action';
import { MarketplaceFilter, MarketplaceFilterPagination } from '../../vo/search-product-vo';
import { CategoryVo } from '../../vo/category-vo';
import { ActionReducer } from '@ngrx/store';
import { AppState } from '../../app.state';
import { MarketplaceView } from '../../main/marketplace/explore-products/marketplace-view-selector/marketplace-view-selector.component';
import { Utils } from 'app/utils/utils';

export interface ProductSearchState {
  temporaryFilter: MarketplaceFilter;
  filter: MarketplaceFilter;
  helpers: Partial<ProductSearchHelper>;
  advancedFiltersData: AdvancedFiltersData;
  paginator: MarketplaceFilterPagination;
  searchType: MarketplaceView;
}

export interface ProductSearchHelper {
  category: CategoryVo;
  supplier: ProductSearchHelperSupplier;
}

interface ProductSearchHelperSupplier {
  name: string;
  id: number;
}

export interface AdvancedFiltersData {
  languages: string[];
}

const initialState: ProductSearchState = {
  temporaryFilter: null,
  paginator: null,
  helpers: null,
  advancedFiltersData: null,
  filter: null,
  searchType: 'PRODUCTS',
};

export function productSearchReducer(state = initialState, action: ActionsUnion): ProductSearchState {
  switch (action.type) {
    case ActionTypes.setTemporaryFilter: {
      return {
        ...state,
        temporaryFilter: {
          ...state.temporaryFilter,
          ...action.payload.value,
        },
      };
    }
    case ActionTypes.clearTemporaryFilter: {
      return {
        ...state,
        temporaryFilter: null,
      };
    }
    case ActionTypes.addFilter: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload.value,
        },
        paginator: {
          ...state.paginator,
          from: 0,
        },
      };
    }
    case ActionTypes.setFilter: {
      return {
        ...state,
        filter: action.payload.filter,
        paginator: {
          ...state.paginator,
          from: 0,
        },
      };
    }
    case ActionTypes.clearFilter: {
      return {
        ...state,
        filter: initialState.filter,
      };
    }
    case ActionTypes.setHelper: {
      return {
        ...state,
        helpers: {
          ...state.helpers,
          ...action.payload,
        },
      };
    }
    case ActionTypes.setAdvancedLanguages: {
      return {
        ...state,
        advancedFiltersData: {
          ...state.advancedFiltersData,
          languages: action.payload,
        },
      };
    }
    case ActionTypes.setPaginator: {
      return {
        ...state,
        paginator: {
          ...state.paginator,
          ...action.payload,
        },
      };
    }
    case ActionTypes.increasePaginator: {
      const currentFrom =
        Utils.isNullOrUndefined(state.paginator) || Utils.isNullOrUndefined(state.paginator.from)
          ? 0
          : state.paginator.from;
      return {
        ...state,
        paginator: {
          ...state.paginator,
          from: currentFrom + state.paginator.size,
        },
      };
    }
    case ActionTypes.decreasePaginator: {
      const currentFrom =
        Utils.isNullOrUndefined(state.paginator) || Utils.isNullOrUndefined(state.paginator.from)
          ? 0
          : state.paginator.from;
      return {
        ...state,
        paginator: {
          ...state.paginator,
          from: currentFrom - state.paginator.size,
        },
      };
    }
    case ActionTypes.clearPaginator: {
      return {
        ...state,
        paginator: initialState.paginator,
      };
    }
    case ActionTypes.addFilterWithRedirect: {
      return {
        ...state,
        filter: action.payload.filter,
      };
    }
    case ActionTypes.setSearchType: {
      return {
        ...state,
        searchType: action.payload.value,
      };
    }
    default: {
      return state;
    }
  }
}

export function productSearchMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: AddFilterWithRedirectAction): AppState {
    switch (action.type) {
      case ActionTypes.addFilterWithRedirect: {
        return reducer(
          state,
          action.payload.needRedirect
            ? action
            : new AddFilterWithRedirectAction({
                ...action.payload,
                filter: { ...state.productSearch.filter, ...action.payload.filter },
              })
        );
      }
      default: {
        return reducer(state, action);
      }
    }
  };
}
