import { Injectable } from '@angular/core';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceSolutionsService {
  constructor(private restService: RestService) {}

  public getNavigationUrlFromSolution(type: SolutionType): string {
    return SOLUTION_URLS.find((solution) => solution.type === type).url;
  }

  public deleteSolutionFromUser(data: RestSolutionVo): Observable<any> {
    return this.restService
      .post('PreferenceSolutionsService/deleteSolutionFromUser', data)
      .pipe(map((res) => res.getData()));
  }

  public addSolutionToUser(data: RestSolutionVo): Observable<any> {
    return this.restService
      .post('PreferenceSolutionsService/addSolutionToUser', data)
      .pipe(map((res) => res.getData()));
  }

  public getSolutionsForUser(appType: number, uId: number): Observable<SolutionVo[]> {
    return this.restService
      .get(`PreferenceSolutionsService/getSolutionsForUser?appType=${appType}&uId=${uId}`)
      .pipe(map((res) => res.getData()));
  }

  public getAllSolutions(appType: number): Observable<SolutionVo[]> {
    return this.restService
      .get(`PreferenceSolutionsService/getSolutions?appType=${appType}`)
      .pipe(map((res) => res.getData()));
  }
}

export const SOLUTION_URLS = [
  { type: 'SEARCH_PRODUCTS', url: MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH },
  { type: 'FIND_SUPPLIER', url: '/suppliers' },
  { type: 'ALIBABA', url: RetailerCenterNavigation.ALIBABA.ALIBABA_CONNECTION },
  { type: 'DF_UPLOAD', url: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD },
  { type: 'DF_VARIANTS', url: RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE },
  { type: 'DF_EXPORT', url: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_EXPORT },
];

interface RestSolutionVo {
  uId: number;
  appType: number;
  solutions: SolutionType[];
}

export type SolutionType = 'FIND_SUPPLIER' | 'SEARCH_PRODUCTS' | 'ALIBABA' | 'DF_UPLOAD' | 'DF_VARIANTS' | 'DF_EXPORT';

export interface SolutionVo {
  solution: SolutionType;
  id: number;
  appType: number;
}
