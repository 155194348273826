import { BootstrapActionTypes, USER_DATA_BOOTSTRAP_ALL_TYPES } from './bootstrap.actions';

export interface BootstrapState {
  userDataBootstrapped: boolean;
}

const initialState: BootstrapState = {
  userDataBootstrapped: false,
};

export function bootstrapReducer(state = initialState, action: USER_DATA_BOOTSTRAP_ALL_TYPES): BootstrapState {
  switch (action.type) {
    case BootstrapActionTypes.USER_DATA_BOOTSTRAP_SUCCESS: {
      return {
        ...state,
        userDataBootstrapped: true,
      };
    }
    default: {
      return state;
    }
  }
}
