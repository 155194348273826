import { Action } from '@ngrx/store';
import { OnboardItem } from '../../layout/components/onboard/onboard.service';
import { SnippetToUser } from '../../main/setup-guide/model/snippet-to-user';
import { CreateSnippetToUserData } from '../../main/setup-guide/model/create-snippet-to-user-data';

export enum SnippetActionTypes {
  SNIPPET_CHAIN_START = '[Snippet] Snippet Chain Start',
  GET_SNIPPET_COMPLETED_STEPS_START = '[Snippet] Get Completed Steps Start',
  GET_SNIPPET_COMPLETED_STEPS_SUCCESS = '[Snippet] Get Completed Steps Success',
  GET_SNIPPET_TREE_START = '[Snippet] Get Snippet Tree Start',
  GET_SNIPPET_TREE_SUCCESS = '[Snippet] Get Snippet Tree Success',
  GET_SNIPPET_USER_STATUS_START = '[Snippet] Get Snippet User Status Start',
  GET_SNIPPET_USER_STATUS_SUCCESS = '[Snippet] Get Snippet User Status Success',
  ADD_COMPLETED_SNIPPET_TO_USER = '[Snippet] Add Completed Snippet To User',
  ADD_COMPLETED_SNIPPETS_TO_USER = '[Snippet] Add Completed Snippets To User',
}

export class SnippetChainStart implements Action {
  readonly type = SnippetActionTypes.SNIPPET_CHAIN_START;
}

export class GetSnippetCompletedStepsStartAction implements Action {
  readonly type = SnippetActionTypes.GET_SNIPPET_COMPLETED_STEPS_START;
}

export class GetSnippetCompletedStepsSuccessAction implements Action {
  readonly type = SnippetActionTypes.GET_SNIPPET_COMPLETED_STEPS_SUCCESS;

  constructor(public payload: SnippetToUser[]) {}
}

export class GetSnippetTreeStartAction implements Action {
  readonly type = SnippetActionTypes.GET_SNIPPET_TREE_START;
}

export class GetSnippetTreeSuccessAction implements Action {
  readonly type = SnippetActionTypes.GET_SNIPPET_TREE_SUCCESS;

  constructor(public payload: OnboardItem[]) {}
}

export class AddCompletedSnippetToUserAction implements Action {
  readonly type = SnippetActionTypes.ADD_COMPLETED_SNIPPET_TO_USER;

  constructor(public payload: CreateSnippetToUserData) {}
}

export class AddCompletedSnippetsToUserAction implements Action {
  readonly type = SnippetActionTypes.ADD_COMPLETED_SNIPPETS_TO_USER;

  constructor(public payload: CreateSnippetToUserData[]) {}
}

export class GetSnippetUserStatusStart implements Action {
  readonly type = SnippetActionTypes.GET_SNIPPET_USER_STATUS_START;
}

export class GetSnippetUserStatusSuccess implements Action {
  readonly type = SnippetActionTypes.GET_SNIPPET_USER_STATUS_SUCCESS;

  constructor(public payload: boolean) {}
}

export type ActionsUnion =
  | SnippetChainStart
  | GetSnippetCompletedStepsStartAction
  | GetSnippetCompletedStepsSuccessAction
  | GetSnippetTreeStartAction
  | GetSnippetTreeSuccessAction
  | AddCompletedSnippetToUserAction
  | AddCompletedSnippetsToUserAction
  | GetSnippetUserStatusStart
  | GetSnippetUserStatusSuccess;
