import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-settings-modal',
  templateUrl: './choose-settings-modal.component.html',
  styleUrls: ['./choose-settings-modal.component.scss'],
})
export class ChooseSettingsModalComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ChooseSettingsModalComponent>) {}

  ngOnInit(): void {}

  keep(): void {
    this.dialogRef.close(true);
  }

  change(): void {
    this.dialogRef.close(false);
  }
}
