<div fxLayout="column" fxLayoutGap="16px">
  <section>
    <div class="content-title">
      {{ 'APPROVAL_DIALOG.RESELLER_SETUP_INFO.TITLE' | translate }}
    </div>
    <ng-container *ngIf="data.approveNeeded; else noApprovalNeeded">
      <div
        *ngIf="data.isAutomated; else notAutomated"
        [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.CONTENT_HAS_ORDER_HAS_APPROVE' | translate"
      ></div>
      <ng-template #notAutomated>
        <div>
          <div [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.CONTENT_NO_ORDER_HAS_APPROVE' | translate"></div>
          <div>
            <a *hasPermission="CONTACTS_READ; data: { ecom: null }" [href]="data.supplierWebsite" target="_blank">{{
              data.supplierWebsite
            }}</a>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #noApprovalNeeded>
      <div
        *ngIf="data.isAutomated; else notAutomated"
        [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.CONTENT_HAS_ORDER_NO_APPROVE' | translate"
      ></div>
      <ng-template #notAutomated>
        <div [innerHTML]="'APPROVAL_DIALOG.RESELLER_SETUP_INFO.CONTENT_NO_ORDER_NO_APPROVE' | translate"></div>
        <div>
          <a *hasPermission="CONTACTS_READ; data: { ecom: null }" [href]="data.supplierWebsite" target="_blank">{{
            data.supplierWebsite
          }}</a>
        </div>
      </ng-template>
    </ng-template>
  </section>
  <section>
    <div [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.TITLE' | translate" class="content-title"></div>
    <div
      *ngIf="data.isAutomated; else notAutomated"
      [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.CONTENT_HAS_ORDER' | translate"
    ></div>
    <ng-template #notAutomated>
      <div>
        <div [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.CONTENT_NO_ORDER' | translate"></div>
        <div>
          <a *hasPermission="CONTACTS_READ; data: { ecom: null }" [href]="data.supplierWebsite" target="_blank">{{
            data.supplierWebsite
          }}</a>
        </div>
        <div [innerHTML]="'APPROVAL_DIALOG.ORDER_INFO.CONTENT_SHIPPING_TAX_INFO' | translate"></div>
      </div>
    </ng-template>
  </section>
  <section>
    <div [innerHTML]="'APPROVAL_DIALOG.CONTACT.TITLE' | translate" class="content-title"></div>
    <div
      *hasPermission="CONTACTS_READ; data: { ecom: null }"
      [innerHTML]="'APPROVAL_DIALOG.CONTACT.CONTENT' | translate : { email: data.contactEmail }"
    ></div>
  </section>
  <section *ngIf="data.approveDescription && data.approveDescription.length > 0">
    <div
      [innerHTML]="
        !data.approveNeeded
          ? ('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.ACCEPT_MESSAGE_TITLE' | translate)
          : ('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.CONFIRM_WARNING.MESSAGE2' | translate)
      "
      class="content-title"
    ></div>
    <div [innerHTML]="data.approveDescription" class="content-holder"></div>
  </section>
</div>
