import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { IndexTableFilterDropdownComponent } from '../index-table-filter-dropdown/index-table-filter-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { FromToDatePickerComponent } from '../../from-to-date-picker/from-to-date-picker.component';
import { CustomDividerComponent } from '../../custom-divider/custom-divider.component';
import { MatRadioModule } from '@angular/material/radio';
import { DatePickerDropdownComponent } from '../../date-picker-dropdown/date-picker-dropdown.component';
import { DatePickerDropdownDirective } from 'app/shared/directives/date-picker-dropdown.directive';

@Component({
  selector: 'app-index-table-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
    IndexTableFilterDropdownComponent,
    TranslateModule,
    FromToDatePickerComponent,
    CustomDividerComponent,
    MatRadioModule,
    DatePickerDropdownComponent,
    DatePickerDropdownDirective,
  ],
  templateUrl: './index-table-date-picker.component.html',
  styleUrls: ['./index-table-date-picker.component.scss'],
})
export class IndexTableDatePickerComponent implements OnInit {
  @Input() dateFrom: Date;
  @Input() dateTo: Date;

  @Output() dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() {}

  ngOnInit(): void {}

  translateLabel(): string {
    if (!!this.dateFrom && !!this.dateTo) {
      return 'INDEX_FILTER.ACTIVE_LABELS.DATE_FULL';
    } else if (!!this.dateFrom && !this.dateTo) {
      return 'INDEX_FILTER.ACTIVE_LABELS.DATE_START';
    } else if (!this.dateFrom && !!this.dateTo) {
      return 'INDEX_FILTER.ACTIVE_LABELS.DATE_END';
    } else {
      return 'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.DATE';
    }
  }
}
