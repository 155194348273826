import { Component } from '@angular/core';

import { StepperService } from '../../service/stepper.service';

@Component({
  selector: 'app-change-role-dialog-wrapper',
  templateUrl: './change-role-dialog-wrapper.component.html',
  styleUrls: ['./change-role-dialog-wrapper.component.scss'],
})
export class ChangeRoleDialogWrapperComponent {
  constructor(public stepperService: StepperService) {}
}
