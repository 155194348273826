<app-single-country-selector
  [placeholder]="placeholder"
  [label]="label"
  [countryCode]="value"
  (valueChange)="valueChange($event)"
  [hasError]="errorState"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  [required]="required"
></app-single-country-selector>
