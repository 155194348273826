<div
  fxLayoutAlign="space-between center"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="0"
  fxLayoutGap.lt-md="20px"
>
  <div>
    <div (click)="onDismiss()" class="dismiss" *ngIf="!(allStepCompleted | async) && hasDismiss">
      {{ 'SETUP_GUIDE.BOTTOM_BAR.DISMISS' | translate }}
    </div>
  </div>
  <div intercomOpen [innerHTML]="'COMMON_ALERTS.ANY_QUESTIONS' | translate | sanitizeHTML"></div>
</div>
