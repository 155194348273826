import { Injectable } from '@angular/core';
import { ContinentDto } from 'app/vo/countries-location/continent-dto';
import { CountryLocationDto } from 'app/vo/countries-location/country-location-dto';
import { Observable } from 'rxjs';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';

@Injectable({
  providedIn: 'root',
})
export class CountriesLocationsService {
  constructor(private springRestService: SpringRestService) {}

  getLocations(): Observable<CountryLocationDto[]> {
    return this.springRestService.get(MicroserviceNames.ORDER, `/v1/CountriesLocations`, undefined, true);
  }

  getContinents(): Observable<ContinentDto[]> {
    return this.springRestService.get(MicroserviceNames.ORDER, `/v1/Continent`, undefined, true);
  }
}
