<app-custom-dialog-wrapper [hideCloseBtn]="true" [actionBarRef]="actionBarRef">
  <div class="pb-24" fxLayout="column" fxLayoutGap="28px">
    <mat-card
      *ngIf="isSettings() && !isFilterSettings"
      fxLayout="column"
      fxLayoutGap="20px"
      fxLayoutAlign="center stretch"
    >
      <div class="h3" *ngIf="isSettings() && !isFilterSettings">{{ 'FIELD_SETTINGS.FIELD' | translate }}</div>
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="isPriceSettings">
          <ng-container *ngTemplateOutlet="priceSettings"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="isWeightSettings">
          <ng-container *ngTemplateOutlet="weightSettings"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="isDimSettings">
          <ng-container *ngTemplateOutlet="dimSettings"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="isLocationSettings">
          <ng-container *ngTemplateOutlet="locationSettings"></ng-container>
        </ng-container>
      </ng-container>
      <mat-slide-toggle
        *ngIf="!isChipSettings && !isFilterSettings"
        name="fillTheEmpty"
        [(ngModel)]="fillTheEmptyWrapper.fillTheEmpty"
        labelPosition="before"
        >{{ 'FIELD_SETTINGS.FILL_THE_EMPTY' | translate }}</mat-slide-toggle
      >

      <ng-template #locationSettings>
        <div *ngIf="locations">
          <mat-form-field class="input-without-padding-except-error w-100p" appearance="fill">
            <mat-label>{{ 'FIELD_SETTINGS.LOCATIONS' | translate }}</mat-label>
            <mat-select name="locations" [(ngModel)]="locationWrapper.location" [ngModelOptions]="{ standalone: true }">
              <mat-option *ngFor="let loc of locations" [value]="loc.id">
                {{ loc.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>

      <ng-template #priceSettings>
        <form #settingsPriceForm="ngForm">
          <mat-form-field class="input-without-padding-except-error w-100p" appearance="fill">
            <mat-label>{{ 'FIELD_SETTINGS.CURRENCY_PLACE' | translate }}</mat-label>
            <mat-select #currency name="currency" [(ngModel)]="currencyWrapper.currency" required>
              <mat-option *ngFor="let c of currencies" [value]="c.code">{{ c.code + ' ' + c.name }}</mat-option>
            </mat-select>
            <mat-error> {{ 'FIELD_SETTINGS.YOU_HAVE' | translate }}</mat-error>
          </mat-form-field>
        </form>
      </ng-template>
      <ng-template #weightSettings>
        <form #weightSettingsForm="ngForm">
          <mat-form-field class="input-without-padding-except-error w-100p" appearance="fill">
            <mat-label>{{ 'FIELD_SETTINGS.WEIGHT_PLACE' | translate }}</mat-label>
            <mat-select #weightUnit name="weightUnit" [(ngModel)]="weightWrapper.weightUnit" required>
              <mat-option *ngFor="let wu of weightUnits" [value]="wu.code">{{ wu.name }} ({{ wu.code }}) </mat-option>
            </mat-select>
            <mat-error> {{ 'FIELD_SETTINGS.YOU_HAVE2' | translate }}</mat-error>
          </mat-form-field>
        </form>
      </ng-template>
      <ng-template #dimSettings>
        <form #dimForm="ngForm">
          <mat-form-field class="input-without-padding-except-error w-100p" appearance="fill">
            <mat-label>{{ 'FIELD_SETTINGS.DIMENSION_PLACE' | translate }}</mat-label>
            <mat-select name="weightUnit" [(ngModel)]="dimWrapper.dimUnit" required>
              <mat-option *ngFor="let dim of dimensions" [value]="dim.code">{{ dim.name }}</mat-option>
            </mat-select>
            <mat-error> {{ 'FIELD_SETTINGS.YOU_HAVE3' | translate }}</mat-error>
          </mat-form-field>
        </form>
      </ng-template>
    </mat-card>
    <mat-card>
      <div class="h3 mb-20">{{ 'FIELD_SETTINGS.FIELD2' | translate }}</div>
      <form [hidden]="!operationFields || operationFields.length === 0">
        <div
          fxLayout="column"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          fxLayoutGap="20px"
          class="operations-list"
        >
          <mat-card
            *ngFor="let opField of operationFields; let i = index"
            cdkDragData="{{ opField }}"
            cdkDrag
            cdkDragLockAxis="y"
            class="operations-list-item"
          >
            <div class="operation-container" fxLayoutAlign="center center" fxLayoutGap="20px">
              <mat-icon class="cursor-grab syncee-grey-500-fg" cdkDragHandle>drag_indicator</mat-icon>
              <div fxLayout="column" fxLayoutGap="12px" fxFlex="1 0 0">
                <ng-container *ngIf="getOperationFieldType(opField) === REPLACER_REF">
                  <div fxLayoutAlign="space-between center">
                    <div fxLayoutAlign="center center" fxLayoutGap="8px">
                      <button mat-icon-button>
                        <mat-icon (click)="addNewReplacer($any(opField))"> add_circle </mat-icon>
                      </button>
                      <div class="h5">{{ 'FIELD_SETTINGS.REPLACERS' | translate }}</div>
                    </div>
                    <div fxLayoutAlign="center center" fxLayoutGap="8px">
                      <button mat-icon-button (click)="removeOperation(opField)">
                        <mat-icon class="s-20">close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <app-replacer
                    *ngFor="let rep of $any(opField).replacers"
                    [ref]="rep"
                    (remove)="removeReplacer($any(opField), rep.id)"
                  ></app-replacer>
                </ng-container>
                <ng-container *ngIf="getOperationFieldType(opField) === EVAL">
                  <div fxLayoutAlign="space-between center">
                    <div fxLayoutAlign="center center" fxLayoutGap="8px">
                      <div class="h5">{{ 'FIELD_SETTINGS.OPERATIONS' | translate }}</div>
                    </div>
                    <div fxLayoutAlign="center center" fxLayoutGap="8px">
                      <button mat-icon-button (click)="removeOperation(opField)">
                        <mat-icon class="s-20">close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <mat-form-field class="select-without-label" appearance="fill">
                    <input
                      matInput
                      name="eval-{{ i }}"
                      [(ngModel)]="$any(opField).eval"
                      (mousedown)="$event.stopPropagation()"
                    />
                    <mat-hint> {{ 'FIELD_SETTINGS.YOU' | translate }}</mat-hint>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="getOperationFieldType(opField) === SPLITTER">
                  <div fxLayoutAlign="space-between center">
                    <div fxLayoutAlign="center center" fxLayoutGap="8px">
                      <div class="h5">{{ 'FIELD_SETTINGS.SPLIT' | translate }}</div>
                    </div>
                    <div fxLayoutAlign="center center" fxLayoutGap="8px">
                      <button mat-icon-button (click)="removeOperation(opField)">
                        <mat-icon class="s-20">close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <mat-form-field class="select-without-label input-without-padding" appearance="fill">
                    <input
                      matInput
                      name="split-{{ i }}"
                      [(ngModel)]="$any(opField).splitter"
                      (mousedown)="$event.stopPropagation()"
                    />
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </mat-card>
        </div>
      </form>
      <div class="mt-20" fxLayout="row" fxLayoutGap="8px" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">
        <button mat-button class="syncee-light-blue-button" (click)="addReplacer()" buttonWithIcon="add">
          {{ 'FIELD_SETTINGS.REPLACER' | translate }}
        </button>
        <button mat-button class="syncee-light-blue-button" (click)="addOperation()" buttonWithIcon="add">
          {{ 'FIELD_SETTINGS.OPERATION' | translate }}
        </button>
        <button mat-button class="syncee-light-blue-button" (click)="addSplit()" buttonWithIcon="add">
          {{ 'FIELD_SETTINGS.SPLIT2' | translate }}
        </button>
      </div>
    </mat-card>
  </div>
</app-custom-dialog-wrapper>
<ng-template #actionBarRef>
  <div fxLayoutAlign="center center" fxLayoutGap="8px">
    <button mat-button [matDialogClose]="null" [disabled]="isCancelBtnDisabled()">
      {{ 'FIELD_SETTINGS.CANCEL' | translate }}
    </button>
    <button [disabled]="isActionBtnDisabled()" mat-flat-button color="primary" (click)="close()">
      {{ 'FIELD_SETTINGS.SAVE' | translate }}
    </button>
  </div>
</ng-template>
