import { Injectable } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CategoryVo } from '../../vo/category-vo';
import { RestService } from '../rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryMappingService {
  private _alreadyMappedCategories: string[] = [];

  private _shopCategories: any[] = [];

  constructor(private _restService: RestService) {}

  public getMappedCategories(catalogId: number): Observable<MappedCategory[]> {
    return forkJoin([this.getRetailerCategoryMappingData(catalogId), this.getRetailerCategoryMapping(catalogId)]).pipe(
      map((response) => {
        const shopCategories = response[0].shopCategories;
        const alreadyMappedCategories = [];
        if (response[1]) {
          response[1].forEach((element) => {
            const handle = Object.keys(element)[0];
            element[handle].forEach((el) => {
              if (!alreadyMappedCategories.includes(el)) {
                alreadyMappedCategories.push(el);
              }
            });
          });
        }

        const categories = shopCategories
          .filter((category) => category.id !== '' && alreadyMappedCategories.includes(category.id))
          .map((shopCategory) => ({ ...shopCategory, id: shopCategory.id.toString() }));
        return categories;
      }),
      catchError((error) => {
        console.log(error);
        return [];
      })
    );
  }

  public getCategoryMapping(taskId: number): Observable<any> {
    return this._restService.get(`CatalogToCategoryService/getCategories?catalogId=${taskId}`).pipe(
      map((response) => {
        console.log('responseCat', response);

        return this.convertMappingResponse(response.getFirstData()['categoryId']);
      })
    );
  }

  public getCategoryNameAndIcon(rest): Observable<any> {
    return this._restService.get('CatalogToCategoryService/getCategoryNameAndIcon', rest).pipe(
      map((res) => {
        return res.getData();
      })
    );
  }

  private convertMappingResponse(response: any): any {
    const mappingValues = [];
    let tempObject = {};
    if (!Utils.isNullOrUndefined(response)) {
      Object.keys(response).forEach((key) => {
        tempObject[key] = response[key];
        mappingValues.push(tempObject);
        tempObject = {};
      });
    }
    return mappingValues;
  }

  public getAllCategories(): Observable<CategoryVo[]> {
    return this._restService.get('CategoryService/getAllCategory').pipe(map((response) => response.getData()));
  }

  public uploadCategoryIcon(data): Observable<any> {
    return this._restService.post('CategoryService/uploadCategoryIcon', data).pipe(
      map((res) => {
        return res.getData();
      })
    );
  }

  public getProductCategories(taskId: number): Observable<string[]> {
    return this._restService
      .get(`ElasticsSearch/getCategoriesByTaskId?taskId=${taskId}`)
      .pipe(map((response) => response.getData()));
  }

  public getProductByCategory(rest): Observable<any> {
    return this._restService
      .get(`CatalogToCategoryService/getExampleProducts`, rest)
      .pipe(map((response) => response.getData()));
  }

  public saveCategoryMapping(catalog_id: number, categoryMappingVO: any): Observable<any> {
    return this._restService.post(
      `CatalogToCategoryService/addSubCategories?catalog_id=${catalog_id}`,
      categoryMappingVO
    );
  }

  public getRetailerCategoryMappingData(catalogId: number): Observable<AllCategories> {
    return this._restService
      .get(`RetailerCatalogService/getCategoryMappingData?catalogId=${catalogId}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getRetailerCategoryMapping(taskId: number): Observable<MappedCategoryResp[]> {
    return this._restService.get(`RetailerCatalogService/getCategoryMapping?catalogId=${taskId}`).pipe(
      map((response) => {
        console.log('responseCat', response);

        return this.convertMappingResponse(response.getFirstData());
      })
    );
  }

  public saveRetailerCategoryMapping(categoryMappingVO: any): Observable<any> {
    return this._restService.post(`RetailerCatalogService/setCategoryMapping`, categoryMappingVO).pipe(
      map((response) => {
        console.log('responseCat', response);

        return response.getFirstData();
      })
    );
  }

  findParentOfCategory(flatCategories: CategoryVo[], categoryId: number): CategoryVo {
    if (categoryId === 1) {
      return null;
    }

    let currentCategoryId: number = categoryId;
    let parentCategory: CategoryVo;

    function findParent(catId: number): void {
      const _currentCategory = flatCategories?.find((category) => category.id === catId);
      if (_currentCategory.parent === 1) {
        parentCategory = _currentCategory;
        return;
      } else {
        const _parent = flatCategories?.find((parent) => parent.id === _currentCategory?.parent);
        parentCategory = _parent;
        currentCategoryId = parentCategory.id;
        return;
      }
    }

    while (parentCategory?.parent !== 1) {
      findParent(currentCategoryId);
    }

    return parentCategory;
  }

  get shopCategories(): any {
    return this._shopCategories;
  }

  set shopCategories(value: any) {
    this._shopCategories = value;
  }

  get alreadyMappedCategories(): string[] {
    return this._alreadyMappedCategories;
  }

  set alreadyMappedCategories(value: string[]) {
    this._alreadyMappedCategories = value;
  }
}

export interface MappedCategory {
  id: string;
  handle?: string;
  title: string;
  updated_at: string;
  body_html?: any;
  published_at: string;
  sort_order: string;
  template_suffix?: any;
  published_scope: string;
  admin_graphql_api_id: string;
}

export interface AllCategories {
  title: string;
  shopCategories: ShopCategory[];
  productCategories: ProductCategory[];
}

export interface ProductCategory {
  id: string;
  name: string;
}

export interface ShopCategory {
  id: string;
  handle: string;
  title: string;
  updated_at: string;
  body_html?: any;
  published_at: string;
  sort_order: string;
  template_suffix?: any;
  published_scope: string;
  admin_graphql_api_id: string;
}

export interface MappedCategoryResp {
  [key: string]: string[];
}
