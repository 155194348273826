import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [CommonModule, MatRippleModule],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() nonPointer = false;
  @Input() type: 'image' | 'text' | 'icon';
  @Input() textClasses = 'typography-button';
  @Input() noRipple = false;
  @Input() diameter = 40;
  @Input() backgroundColor = 'var(--app-syncee-primary-50)';
  @Input() color = 'var(--app-syncee-primary-700)';
  @Input() borderWidth = 0;
  @Input() borderColor = 'var(--app-syncee-grey-200)';

  constructor() {}

  ngOnInit(): void {}
}
