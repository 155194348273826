import { SourceType } from '../enums/source-type.enum';

export const DATAFEED_SOURCE_TYPES: SourceType[] = [
  SourceType.FTP,
  SourceType.URL,
  SourceType.UPLOAD,
  SourceType.URL_GDOCS,
  SourceType.URL_GD,
  SourceType.URL_DROPBOX,
];
