<div *ngIf="sourceType && sourceType !== FILE_UPLOAD_SOURCE_TYPE" fxLayout="column">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.lt-md="center stretch"
    fxLayoutGap="40px"
    fxLayoutGap.lt-md="20px"
  >
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-md="center stretch"
      [style.gap.px]="20"
    >
      <mat-form-field fxFlex="1 0 0" appearance="fill" class="select-without-label input-without-padding">
        <mat-select [(ngModel)]="selectedSchedule">
          <mat-option *ngFor="let schedule of schedules" value="{{ schedule.value }}">
            {{ schedule.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div fxFlex="1 0 0" [hidden]="selectedSchedule !== SCHEDULER_CONSTANTS.DAILY">
        <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding">
          <mat-chip-list #chipListDaily>
            <mat-chip
              *ngFor="let schedule of scheduleHolders['daily'].selectedSchedules"
              [selectable]="scheduleHolders['daily'].selectable"
              [removable]="scheduleHolders['daily'].removable"
              (removed)="scheduleHolders['daily'].remove(schedule, dailyTrigger)"
              class="grey-chip"
            >
              {{ schedule }}
              <mat-icon matChipRemove *ngIf="scheduleHolders['daily'].removable">close </mat-icon>
            </mat-chip>
            <input
              placeholder="{{ 'TASKWIZARD.SCHEDULE.SELECT_TIMES' | translate }}"
              #dailyScheduleInput
              #dailyTrigger="matAutocompleteTrigger"
              [formControl]="scheduleHolders['daily'].scheduleCtrl"
              [matAutocomplete]="autoDaily"
              [matChipInputFor]="chipListDaily"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="scheduleHolders['daily'].addOnBlur"
              (matChipInputTokenEnd)="scheduleHolders['daily'].add($event)"
              (focus)="openAutoCompletePanel(dailyTrigger)"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoDaily="matAutocomplete"
            (optionSelected)="scheduleHolders['daily'].selected($event, dailyTrigger)"
          >
            <mat-option
              *ngFor="let filteredSchedule of scheduleHolders['daily'].filteredSchedules | async"
              [value]="filteredSchedule"
            >
              {{ filteredSchedule }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div fxFlex="1 0 0" [hidden]="selectedSchedule !== SCHEDULER_CONSTANTS.WEEKLY">
        <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding">
          <mat-chip-list #chipListWeekly>
            <mat-chip
              *ngFor="let schedule of scheduleHolders['weekly'].selectedSchedules"
              [selectable]="scheduleHolders['weekly'].selectable"
              [removable]="scheduleHolders['weekly'].removable"
              (removed)="scheduleHolders['weekly'].remove(schedule, weeklyTrigger)"
              class="grey-chip"
            >
              {{ schedule }}
              <mat-icon matChipRemove *ngIf="scheduleHolders['weekly'].removable">close </mat-icon>
            </mat-chip>
            <input
              placeholder="{{ 'TASKWIZARD.SCHEDULE.SELECT_DAYS' | translate }}"
              #weeklyScheduleInput
              #weeklyTrigger="matAutocompleteTrigger"
              [formControl]="scheduleHolders['weekly'].scheduleCtrl"
              [matAutocomplete]="autoWeekly"
              [matChipInputFor]="chipListWeekly"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="scheduleHolders['weekly'].addOnBlur"
              (matChipInputTokenEnd)="scheduleHolders['weekly'].add($event)"
              (focus)="openAutoCompletePanel(weeklyTrigger)"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoWeekly="matAutocomplete"
            (optionSelected)="scheduleHolders['weekly'].selected($event, weeklyTrigger)"
          >
            <mat-option
              *ngFor="let filteredSchedule of scheduleHolders['weekly'].filteredSchedules | async"
              [value]="filteredSchedule"
            >
              {{ filteredSchedule }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div fxFlex="1 0 0" [hidden]="selectedSchedule !== SCHEDULER_CONSTANTS.MONTHLY">
        <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding">
          <mat-chip-list #chipListMonthly>
            <mat-chip
              *ngFor="let schedule of scheduleHolders['monthly'].selectedSchedules"
              [selectable]="scheduleHolders['monthly'].selectable"
              [removable]="scheduleHolders['monthly'].removable"
              (removed)="scheduleHolders['monthly'].remove(schedule, monthlyTrigger)"
              class="grey-chip"
            >
              {{ schedule }}
              <mat-icon matChipRemove *ngIf="scheduleHolders['monthly'].removable">close </mat-icon>
            </mat-chip>
            <input
              placeholder="{{ 'TASKWIZARD.SCHEDULE.SELECT_DAYS' | translate }}"
              #monthlyScheduleInput
              #monthlyTrigger="matAutocompleteTrigger"
              [formControl]="scheduleHolders['monthly'].scheduleCtrl"
              [matAutocomplete]="autoMonthly"
              [matChipInputFor]="chipListMonthly"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="scheduleHolders['monthly'].addOnBlur"
              (matChipInputTokenEnd)="scheduleHolders['monthly'].add($event)"
              (focus)="openAutoCompletePanel(monthlyTrigger)"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoMonthly="matAutocomplete"
            (optionSelected)="scheduleHolders['monthly'].selected($event, monthlyTrigger)"
          >
            <mat-option
              *ngFor="let filteredSchedule of scheduleHolders['monthly'].filteredSchedules | async"
              [value]="filteredSchedule"
            >
              {{ filteredSchedule }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div fxFlex="1 0 0" *ngIf="selectedSchedule === SCHEDULER_CONSTANTS.AUTO_RUN && autoRunDate">
        <div class="next-update-chip">
          <span class="h5">{{ 'TASKWIZARD.SCHEDULE.NEXT_RUN' | translate }}</span> {{ autoRunDate | customDate }}
        </div>
      </div>
      <div fxFlex="1 0 0" *ngIf="selectedSchedule === SCHEDULER_CONSTANTS.MANUALLY"></div>
    </div>
  </div>
</div>
<div *ngIf="sourceType && sourceType === FILE_UPLOAD_SOURCE_TYPE">
  <app-alert-multi type="info" [messages]="['TASKWIZARD.SCHEDULE.UNABLE' | translate]"></app-alert-multi>
</div>
