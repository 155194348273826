<app-loading-screen
  *ngIf="isUpdate || loadingService.isForceLoad"
  [message]="'TASKWIZARD.PLEASE' | translate"
></app-loading-screen>
<div class="taskwizard-container">
  <h2 class="task-name">{{ taskName }}</h2>

  <mat-horizontal-stepper labelPosition="bottom" #taskStepper>
    <mat-step #mappingStep [state]="stepperService.isStepCompletedState(0)">
      <ng-template matStepLabel>{{ 'TASKWIZARD.MAPPING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #addFilesWrapper>
          <app-addfiles
            #stepComponent
            [currentTaskId]="taskId"
            (stateChanged)="wizardStateChanged()"
            (sourceTypeCallBack)="setSourceType($event)"
            [comments]="comments"
          ></app-addfiles>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Scheduler-->
    <!--<mat-step #scheduleStep>-->
    <!--<ng-template matStepLabel>Scheduler</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #scheduleWrapper>-->
    <!--<app-schedule *ngIf="scheduleWrapper.isStepActive()" #stepComponent [sourceType]="sourceType" [taskId]="taskId"></app-schedule>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Filter-->
    <mat-step #filterStep [state]="stepperService.isStepCompletedState(1)">
      <ng-template matStepLabel>{{ 'TASKWIZARD.FILTER' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #filterWrapper>
          <app-task-filter
            *ngIf="stepper.selected === filterStep"
            #stepComponent
            [taskId]="taskId"
            [filterStructures]="taskFilterService.getFieldStructures(taskId)"
            [taskFilters]="twUpdateService.data.taskFilters.filters"
            [canUseTaskFilters]="twUpdateService.isUpdate"
            [useDefaultFilter]="!twUpdateService.data.isTaskFilters()"
            [defaultFieldNames]="{
              publishedField: twUpdateService.data.task.sourceType + '_PUBLISHED',
              imagesField: 'IMAGES',
              variantImageField: 'VARIANTS.V_IMAGES'
            }"
            [canUseDefaultFilter]="hasDefaultFilter()"
            [hasVariantFilter]="true"
            [variantFilterStructures]="taskFilterService.getVariantFieldStructures(taskId)"
            [variantHasTaskFilters]="twUpdateService.data.isTaskVariantFilters()"
            [variantTaskFilters]="twUpdateService.data.taskFilters.variantFilters"
            [filterSave]="saveSupplierTask.bind(this)"
          ></app-task-filter>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Catalog editor-->
    <mat-step #catalogEditorStep [state]="stepperService.isStepCompletedState(2)">
      <ng-template matStepLabel>{{ 'TASKWIZARD.CATALOG' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #catalogEditorWrapper>
          <app-catalog-editor
            #stepComponent
            [catalogId]="taskId"
            [isNewCatalog]="!isUpdate"
            *ngIf="stepper.selected === catalogEditorStep && catalogEditorWrapper.isStepActive()"
            [comments]="comments"
          ></app-catalog-editor>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Category mapping-->
    <mat-step #categoryMappingStep [state]="stepperService.isStepCompletedState(3)">
      <ng-template matStepLabel>{{ 'TASKWIZARD.CATEGORIES' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #categoryMappingWrapper>
          <app-category-mapping
            *ngIf="categoryMappingWrapper.isStepActive() && stepper.selected === categoryMappingStep"
            #stepComponent
            [taskId]="taskId"
            [comments]="comments"
          ></app-category-mapping>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Pricing editor-->
    <mat-step #pricingEditorStep [state]="stepperService.isStepCompletedState(4)">
      <ng-template matStepLabel>{{ 'TASKWIZARD.PRICING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <div style="overflow: auto">
          <app-step-wrapper #pricingWrapper>
            <app-supplier-task-pricing
              #stepComponent
              *ngIf="pricingWrapper.isStepActive() && stepper.selected === pricingEditorStep"
              [catalogId]="taskId"
              [isNewCatalog]="!isUpdate"
              [comments]="comments"
              [sourceType]="sourceType"
            ></app-supplier-task-pricing>
            <!--            <app-supplier-pricing-->
            <!--              *ngIf="pricingWrapper.isStepActive() && stepper.selected === pricingEditorStep"-->
            <!--              #stepComponent-->
            <!--              [catalogId]="taskId"-->
            <!--              [isNewCatalog]="!isUpdate"-->
            <!--              [comments]="comments"-->
            <!--            ></app-supplier-pricing>-->
          </app-step-wrapper>
        </div>
      </div>
    </mat-step>
    <ng-template matStepperIcon="number">
      <mat-icon>radio_button_unchecked</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="done">
      <mat-icon>radio_button_checked</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="edit">
      <mat-icon>radio_button_checked</mat-icon>
    </ng-template>
  </mat-horizontal-stepper>
</div>
