import { ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { LayoutPortalPosition } from './layout-portal-position';

@Injectable({
  providedIn: 'root',
})
export class LayoutPortalService {
  private _portals = initPortals;

  constructor() {}

  registerComponentPortal(position: LayoutPortalPosition, component: ComponentType<unknown>): void {
    this._portals[position] = new ComponentPortal(component);
  }

  registerTemplatePortal(
    position: LayoutPortalPosition,
    template: TemplateRef<unknown>,
    viewContainerRef: ViewContainerRef
  ): void {
    this._portals[position] = new TemplatePortal(template, viewContainerRef);
  }

  registerDOMPortal(position: LayoutPortalPosition, element: ElementRef<HTMLElement> | HTMLElement): void {
    this._portals[position] = new DomPortal(element);
  }

  unregisterPortal(position: LayoutPortalPosition): void {
    this._portals[position] = null;
  }

  get portals(): Portals {
    return this._portals;
  }
}

const initPortals: Portals = {
  LAYOUT_TOP_RIGHT_FLOATING: null,
};

type Portals = {
  [K in LayoutPortalPosition]: Portal<unknown> | null;
};
