<div fxLayoutAlign="center center" class="selector-container border-color">
  <div
    fxFlex="1 0 0"
    fxLayoutAlign="center center"
    class="option border-color"
    [class.selected]="marketplaceView === 'PRODUCTS'"
    (click)="handleOptionClicked('PRODUCTS')"
  >
    {{ 'PRODUCT_LIST.PRODUCTS' | translate }}
  </div>
  <div
    fxFlex="1 0 0"
    fxLayoutAlign="center center"
    class="option border-color"
    [class.selected]="marketplaceView === 'SUPPLIERS'"
    (click)="handleOptionClicked('SUPPLIERS')"
  >
    {{ 'PRODUCT_LIST.SUPPLIERS' | translate }}
  </div>
</div>
