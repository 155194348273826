import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardCategoryMappingComponent } from './wizard-category-mapping.component';
import { WizardCategoryMappingHeaderComponent } from './component/wizard-category-mapping-header/wizard-category-mapping-header.component';
import { WizardMappingHeaderLeftControlsComponent } from './component/wizard-category-mapping-header/wizard-mapping-header-left-controls/wizard-mapping-header-left-controls.component';
import { WizardMappingHeaderRightControlsComponent } from './component/wizard-category-mapping-header/wizard-mapping-header-right-controls/wizard-mapping-header-right-controls.component';
import { WizardCategoryMappingListComponent } from './component/wizard-category-mapping-list/wizard-category-mapping-list.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchbarCustomComponent } from '../searchbar-custom/searchbar-custom.component';
import { WizardCategoryMappingBulkActionsComponent } from './component/wizard-category-mapping-header/wizard-category-mapping-bulk-actions/wizard-category-mapping-bulk-actions.component';
import { ActionsButtonComponent } from '../actions-button/actions-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { MatDividerModule } from '@angular/material/divider';
import { SimplePaginatorWithArrowsComponent } from '../paginations/simple-paginator-with-arrows/simple-paginator-with-arrows.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    WizardCategoryMappingComponent,
    WizardCategoryMappingHeaderComponent,
    WizardMappingHeaderLeftControlsComponent,
    WizardMappingHeaderRightControlsComponent,
    WizardCategoryMappingListComponent,
    WizardCategoryMappingBulkActionsComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    SearchbarCustomComponent,
    ActionsButtonComponent,
    MatMenuModule,
    ExtendedModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    CustomDividerComponent,
    MatDividerModule,
    SimplePaginatorWithArrowsComponent,
    MatChipsModule,
    MatCheckboxModule,
    ConditionLoadingDirective,
    TranslateModule,
    MatTooltipModule,
  ],
  exports: [WizardCategoryMappingComponent],
})
export class WizardCategoryMappingModule {}
