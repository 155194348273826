<div class="p-20" fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start start">
  <div class="centered-text" fxLayout="column" fxLayoutGap="12px">
    <h3>{{ data.title ?? 'Select categories' }}</h3>
    <div *ngIf="data.instruction">{{ data.instruction }}</div>
  </div>
  <ng-container *ngIf="variant === 'default'; else synceeCategoryTree">
    <div class="w-100p" fxLayout="column" fxLayoutGap="12px">
      <app-searchbar-custom *ngIf="data.hasSearchBar"></app-searchbar-custom>
      <div *ngIf="!!data.categories?.length" class="category-list w-100p" fxLayout="column" fxLayoutGap="8px">
        <ng-container *ngFor="let category of data.categories">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <div class="pb-8">
              <mat-checkbox
                [checked]="isSelected(category)"
                [disableRipple]="true"
                (change)="handleCategorySelect(category, $event)"
                >{{ category.name }}</mat-checkbox
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlexAlign="center">
    <button mat-button class="syncee-white-button" [matDialogClose]="null">
      {{ 'CATEGORY_SELECTOR_DIALOG.CLOSE' | translate }}
    </button>
    <button mat-flat-button color="primary" [matDialogClose]="selectedCategories">
      {{ 'CATEGORY_SELECTOR_DIALOG.SELECT' | translate }}
    </button>
  </div>
</div>

<ng-template #synceeCategoryTree>
  <div class="w-100p">
    <app-mat-tree-categories
      [value]="selectedCategoryIds"
      (valueChange)="synceeCategoryTreeValueChange($event)"
      [inputPlaceholder]="'CATEGORY_SELECTOR_DIALOG.SEARCH_PLACEHOLDER' | translate"
    ></app-mat-tree-categories>
  </div>
</ng-template>
