import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SetupGuideSnippet } from '../model/setup-guide-snippet';
import { HIDE_UNCOMPLETED_STEPS } from '../config/hide-uncompleted-steps';
import { SETUP_GUIDE_SPECIAL_CONTENTS } from '../config/setup-guide-special-contens';

@Component({
  selector: 'app-setup-guide-item-list',
  templateUrl: './setup-guide-item-list.component.html',
  styleUrls: ['./setup-guide-item-list.component.scss'],
})
export class SetupGuideItemListComponent implements OnInit, OnChanges {
  @Input() snippet: SetupGuideSnippet;
  @Output() handleCloseDialog = new EventEmitter<void>();

  openedAccordionId: number;
  uncompletedStepIds: number[] = [];
  hideUncompletedSteps: boolean;
  specialContents = SETUP_GUIDE_SPECIAL_CONTENTS;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initAccordion();
    this.initCompletedStepIds();
    this.checkHideUncompletedSteps();
  }

  private checkHideUncompletedSteps(): void {
    this.hideUncompletedSteps = HIDE_UNCOMPLETED_STEPS.includes(this.snippet.snippet.type);
  }

  private initAccordion(): void {
    this.openedAccordionId = this.snippet.snippet.steps.find(
      (step) =>
        !this.snippet.completedSteps.some(
          (completedStep) => completedStep.snippetsId === step.id && !completedStep.skipped
        )
    ).id;
    console.log();
  }

  private initCompletedStepIds(): void {
    const completedStepIds = this.snippet.completedSteps
      .filter((step) => step.snippetsId)
      .map((step) => step.snippetsId);
    this.uncompletedStepIds = this.snippet.snippet.steps
      .filter((step) => !completedStepIds.includes(step.id))
      .map((step) => step.id);
  }
}
