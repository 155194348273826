<app-dialog-multi-wrapper
  icon="info"
  [title]="'BILLING.SUBSCRIPTION.COUPON.ACTIVATE_COUPON_CODE' | translate"
  [hideCloseButton]="false"
  [hideConfirmButton]="false"
  [buttonsAlignment]="'center'"
  [confirmButtonText]="'BILLING.SUBSCRIPTION.COUPON.DIALOG_ACTIVATION' | translate"
  (confirmClick)="activate()"
>
  <form #form="ngForm">
    <div class="fields-container">
      <div class="typography-body-1 centered-text">
        {{ 'BILLING.SUBSCRIPTION.COUPON.DIALOG_DESCRIPTION' | translate }}
      </div>
      <div class="input-wrapper">
        <mat-form-field appearance="outline" class="w-100p mt-12">
          <mat-label>{{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_CODE' | translate }}</mat-label>
          <input
            matInput
            name="coupon"
            [(ngModel)]="coupon"
            [placeholder]="'BILLING.SUBSCRIPTION.COUPON.COUPON_CODE' | translate"
            required
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</app-dialog-multi-wrapper>
