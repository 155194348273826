import { IChannelSort } from './IChannelSort';
import { ChannelItem } from '../../../../service/chat/model/chat-models';

export class ChannelUnreadMessSort implements IChannelSort {
  sort(list: ChannelItem[]): ChannelItem[] {
    if (!list || list.length === 0) {
      return list;
    }
    return list.sort((a: ChannelItem, b: ChannelItem) => {
      return b.unseenMessageCount - a.unseenMessageCount;
    });
  }
}
