<app-filter-selector-base
  [title]="'PRODUCT_LANGUAGE'"
  [options]="options"
  [hasSearch]="true"
  [searchLabel]="'LANGUAGE'"
  [searchTerm]="searchTerm"
  [multiple]="multiple"
  (searchTermChange)="handleSearch($event)"
  [value]="value"
  (valueChange)="onValueChange($event)"
  [hasSeeMore]="hasSeeMore"
  (seeMore)="onSeeMore()"
  (seeLess)="onSeeLess()"
  [hasClearAll]="hasClearAll"
  (clearAll)="onClearAll()"
  [overlayOpen]="overlayOpen"
>
</app-filter-selector-base>
