import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  private readonly loginPath = '/login';

  constructor(private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams.token) {
      return true;
    }
    this._router.navigate([this.loginPath]);
    return false;
  }
}
