export enum EcomTypeEnum {
  SHOPIFY = 'shopify',
  WIX = 'wix',
  WOOCOMMERCE = 'woocommerce',
  BIGCOMMERCE = 'bigcommerce',
  ECWID = 'ecwid',
  KMOSHOPS = 'kmoshops',
  JUMPSELLER = 'jumpseller',
  SHOPRENTER = 'shoprenter',
  SQUARESPACE = 'squarespace',
  UNSAS = 'unas',
  EKM = 'ekm',
  ZYRO = 'zyro',
  SHOPLAZZA = 'shoplazza',
}
