import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IntercomOpenDirective } from 'app/shared/directives/intercom-open.directive';
import { Intercom } from 'ng-intercom';
import { Observable } from 'rxjs';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { SanitizeHtmlPipe } from '../../pipes/sanitize-html.pipe';
import { GeneralButtonComponent } from '../general-button/general-button.component';

@Component({
  selector: 'app-single-alert-with-icon',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    SanitizeHtmlPipe,
    MatButtonModule,
    RouterLink,
    GeneralButtonComponent,
    IntercomOpenDirective,
  ],
  templateUrl: './single-alert-with-icon.component.html',
  styleUrls: ['./single-alert-with-icon.component.scss'],
})
export class SingleAlertWithIconComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() titleKey: string;
  @Input() titleI18nPayload: SingleAlertWithIconInterpolateParams;
  @Input() titleTooltipKey: string;
  @Input() descriptionKey: string;
  @Input() descriptionI18nPayload: SingleAlertWithIconInterpolateParams;
  @Input() descriptionTooltipKey: string;
  @Input() actionBarRef: TemplateRef<any>;
  @Input() button: GeneralButton;
  @Input() type: SingleAlertWithIconType = 'info';
  @Input() icon: string;
  @Input() svgIcon?: string;
  iconToShow: string;
  title$: Observable<string>;
  description$: Observable<string>;
  ltMd: boolean;
  private eventListenerAdded = false;
  private readonly INTERCOM_ID = '#intercom';

  constructor(
    private elementRef: ElementRef,
    private intercom: Intercom,
    private translateService: TranslateService,
    private screenManagerService: ScreenManagerService
  ) {}

  ngOnInit(): void {
    this.subscribeToScreenSize();
  }

  ngAfterViewChecked(): void {
    if (!this.eventListenerAdded && this.elementRef.nativeElement.querySelector(this.INTERCOM_ID)) {
      this.elementRef.nativeElement
        .querySelector(this.INTERCOM_ID)
        .addEventListener('click', this.openIntercom.bind(this));
      this.eventListenerAdded = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.iconToShow = this.getDefaultValue(this.icon, 'icon');
    this.title$ = this.getTranslateObservable(this.titleKey, 'title', this.titleI18nPayload);
    this.description$ = this.getTranslateObservable(this.descriptionKey, 'description', this.descriptionI18nPayload);
  }

  private subscribeToScreenSize(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator())
      .subscribe((ltMd) => (this.ltMd = ltMd));
  }

  private openIntercom(): void {
    this.intercom.show();
  }

  private getTranslateObservable(
    value: string,
    fallbackKey: string,
    interpolateParams?: SingleAlertWithIconInterpolateParams
  ): Observable<string> {
    const defaultValue = this.getDefaultValue(value, fallbackKey);
    return !!defaultValue ? this.translateService.stream(defaultValue, interpolateParams) : null;
  }

  private getDefaultValue(value: string, fallbackKey: string): string {
    if (value !== undefined) {
      return value;
    }
    const defaultValue = DEFAULTS[this.type][fallbackKey];
    return !!defaultValue ? DEFAULTS[this.type][fallbackKey] : null;
  }
}

export type SingleAlertWithIconType = 'danger' | 'info' | 'warning' | 'success';

type SingleAlertWithIconDefault = {
  [key in SingleAlertWithIconType]: {
    title?: string;
    description?: string;
    icon?: string;
  };
};

const DEFAULTS: SingleAlertWithIconDefault = {
  danger: {
    icon: 'error_outline',
    title: 'COMMON_ALERTS.ERROR_STATE.TITLE',
    description: 'COMMON_ALERTS.ERROR_STATE.DESCRIPTION',
  },
  info: {
    icon: 'info',
  },
  success: {
    icon: 'check_circle',
  },
  warning: {
    icon: 'error_outline',
  },
};

export interface SingleAlertWithIconInterpolateParams {
  [key: string]: string;
}
