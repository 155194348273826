import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaskVO } from '../../main/tasks/tasks.component';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { RestResponse } from '../rest/rest-response';
import { RestService } from '../rest/rest.service';
import { AddTaskUrlEnum } from './model/add-task-url.enum';
import { CreateTaskData } from './model/create-task-data';
import { CreateTaskUrlEnum } from './model/create-task-url.enum';
import { GetTaskUrlEnum } from './model/get-task-url.enum';
import { RunTaskUrlEnum } from './model/run-task-url.enum';
import { TaskType, URL_BY_TASK_TYPE } from './model/task-type';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public static GET_IMPORT_TASKS_URL = GetTaskUrlEnum.GET_IMPORT_TASKS_URL;
  public static GET_EXPORT_TASKS_URL = GetTaskUrlEnum.GET_EXPORT_TASKS_URL;
  public static GET_DF_IMPORT_TASKS_URL = GetTaskUrlEnum.GET_DF_IMPORT_TASKS_URL;
  public static GET_VARIANTS_UPDATE_TASKS_URL = GetTaskUrlEnum.GET_VARIANTS_UPDATE_TASKS_URL;
  public static GET_TASK_SOURCE_SETTINGS = GetTaskUrlEnum.GET_TASK_SOURCE_SETTINGS;
  public static GET_SUPPLIER_TASK = GetTaskUrlEnum.GET_SUPPLIER_TASK;

  public static RUN_IMPORT_TASK = RunTaskUrlEnum.RUN_IMPORT_TASK;
  public static RUN_EXPORT_TASK = RunTaskUrlEnum.RUN_EXPORT_TASK;
  public static RUN_VU_TASK = RunTaskUrlEnum.RUN_VU_TASK;

  public static ADD_TASK = AddTaskUrlEnum.ADD_TASK;
  public static ADD_DF_TASK = AddTaskUrlEnum.ADD_DF_TASK;
  public static ADD_EXPORT_TASK = AddTaskUrlEnum.ADD_EXPORT_TASK;
  public static ADD_VU_TASK = AddTaskUrlEnum.ADD_VU_TASK;

  public static CREATE_DEFAULT_SUPPLIER_TASK = CreateTaskUrlEnum.CREATE_DEFAULT_SUPPLIER_TASK;

  constructor(private restService: RestService, private springRestService: SpringRestService, private router: Router) {}

  getSupplierTask(): Observable<any> {
    return this.restService.get(TaskService.GET_SUPPLIER_TASK, null).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  createDefaultSupplierTask(ecomId: number, name: string): Observable<any> {
    return this.restService.post(TaskService.CREATE_DEFAULT_SUPPLIER_TASK, { ecomId, name }).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  getTaskSourceSettings(taskId): Observable<any> {
    const httpParams = new HttpParams().set('taskId', taskId.toString());
    return this.restService.get(TaskService.GET_TASK_SOURCE_SETTINGS, httpParams).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  getUserTasks(url): Observable<TaskVO[]> {
    return this.restService.get(url, null).pipe(
      map((res) => {
        return res.getData();
      })
    );
  }

  runTask(url, taskId, updateFields): Observable<any> {
    const params = {
      taskId: taskId,
    };
    if (!!updateFields) {
      params['updateFields'] = updateFields;
    }

    return this.restService.post(url, params).pipe(
      map((value) => {
        return value.getData();
      })
    );
  }

  addTask(url: string, params: Partial<CreateTaskData>): Observable<any> {
    return this.restService.post(url, params).pipe(
      map((value) => {
        return value;
      })
    );
  }
  getTaskFile(taskId: number, ecomId: number, fileId?: number): Observable<RestResponse<{ taskId: number }>> {
    return this.restService.post('TaskFileEcomDownloadService/downloadFile', { taskId: taskId, ecomId: ecomId });
  }

  updateTask(url, taskVo): Observable<any> {
    return this.restService.post(url, taskVo).pipe(
      map((value) => {
        return value;
      })
    );
  }

  isTaskRunning(taskId): Observable<any> {
    const param = { taskIds: taskId };
    return this.restService.post(`TaskService/isTaskRunning`, param).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }

  deleteTask(taskId: number): Observable<RestResponse<void>> {
    return this.restService.post(`TaskService/delete`, { taskId: taskId });
  }

  setTasksActiveState(taskId: number, isActive: boolean): Observable<void> {
    const param: { taskId: number; isActive: boolean } = {
      taskId,
      isActive,
    };
    return this.restService.post('TaskService/setActive', param).pipe(map(() => {}));
  }

  patchTask(taskId: number, data: PatchTaskDto): Observable<void> {
    return this.springRestService.patch(MicroserviceNames.TASK, `/api/v1/task/${taskId}`, data, {}, true);
  }

  patchCatalog(taskId: number, data: Partial<PatchCatalogDto>): Observable<void> {
    return this.springRestService.patch(MicroserviceNames.TASK, `/api/v1/catalog/${taskId}`, data, {}, true);
  }

  redirectByTaskType(taskType: TaskType, params: Record<string, string>): void {
    const path = URL_BY_TASK_TYPE[taskType];
    this.router.navigate([path], { queryParams: params });
  }
}

export interface PatchTaskDto {
  isActive?: boolean;
  name?: string;
}

export interface PatchCatalogSettings {
  possibleMargin: string;
  language: string;
  shipping_type: string;
  approveNeeded: boolean;
  approveDescription: string;
  needResponse: boolean;
  showPrices: boolean;
  sendEmail: string;
  warehouseLocation: string;
  mainCategories: string[];
  feedSettings: FeedSettings;
  translate_source: string;
  translate_target: string;
}

export interface FeedSettings {
  backorder: boolean;
  shipping: boolean;
}

export interface PatchCatalogDto {
  active: boolean;
  approved: number;
  description: string;
  isDefault: boolean;
  isHidden: boolean;
  name: string;
  rounding: string;
  seoUrl: string;
  settings: string;
  tags: string | string[];
  visibility: boolean;
}
