<div
  class="category-card-item"
  [ngStyle]="{ height: cardHeight + 'px' }"
  (click)="handleClick()"
  [style.background-image]="'url(' + widget.image + ')'"
>
  <h4
    class="card-title"
    [ngClass]="'typography-body-large-3'"
    [ngClass.lt-lg]="'typography-body-large-2'"
    [ngClass.lt-md]="'h4'"
  >
    {{ filter.category | categoryNameById | async }}
  </h4>
</div>
