<div fxLayout="column wrap" fxLayoutAlign="center center" class="pb-20">
  <div class="info-block">
    {{ 'ADD_NEW_STORE.SHOPIFY.DESCRIPTION' | translate }}
  </div>
  <form novalidate (ngSubmit)="installShopify()" [formGroup]="shopify">
    <div class="input-field">
      <mat-form-field appearance="fill">
        <mat-label>example.myshopify.com</mat-label>
        <input matInput placeholder="Domain" formControlName="domain" required />
        <mat-hint>{{ 'ADD_NEW_STORE.SHOPIFY.ADD' | translate }}</mat-hint>
        <mat-error *ngIf="domain.errors?.required && domain.touched">
          {{ 'ADD_NEW_STORE.SHOPIFY.ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="domain.errors?.pattern && domain.touched">
          {{ 'ADD_NEW_STORE.SHOPIFY.VALID_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="install-button-mobile">
      <button mat-button class="syncee-blue-button" type="submit" [disabled]="shopify.invalid">
        <span> {{ 'ADD_NEW_STORE.SHOPIFY.INSTALL' | translate }}</span>
      </button>
    </div>
  </form>
</div>
