import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { RandomImageVo, SupplierGatewayService } from '../../../service/suppliers/supplier-gateway.service';
import { transformToCdnUrl } from '../../../utils/transform-to-cdn-url';

@UntilDestroy()
@Component({
  selector: 'app-supplier-card',
  templateUrl: './supplier-card.component.html',
  styleUrls: ['./supplier-card.component.scss'],
})
export class SupplierCardComponent implements OnInit {
  @Input() supplier: SupplierCard;
  @Input() supplierImages: string[];
  @Input() categoryId: number;
  productImageUrls: string[];
  noImageUrl = transformToCdnUrl(`placeholder-images/product_card_placeholder.webp`);

  constructor(private suppliersService: SupplierGatewayService, private navigationService: NavigationService) {}

  ngOnInit(): void {}

  handleImageError(): void {
    if (!!this.supplierImages) {
      this.productImageUrls = this.supplierImages;
    } else {
      this.suppliersService
        .getRandomSupplierProductImages([this.supplier.userId], this.categoryId)
        .pipe(untilDestroyed(this), take(1))
        .subscribe({
          next: (imageVo) => {
            this.setProductImages(imageVo[0]);
          },
          error: () => {
            this.productImageUrls = [this.noImageUrl];
          },
        });
    }
  }

  handleFallbackImageError(): void {
    this.productImageUrls = [this.noImageUrl];
  }

  navigateToStorefront(): void {
    this.navigationService.navigateToStorefront(this.supplier.userId, this.supplier.handle, true);
  }

  private setProductImages(randomImageVo: RandomImageVo): void {
    if (!!randomImageVo.images.length) {
      this.productImageUrls = randomImageVo.images;
    } else {
      this.productImageUrls = [this.noImageUrl];
    }
  }
}

export interface SupplierCard {
  userId: number;
  companyName: string;
  mainCategoryIds: number[];
  mainWarehouseCountry: string;
  handle: string;
}
