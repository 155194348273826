import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDialogWrapperComponent } from 'app/shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { NoSearchResultsComponent } from 'app/shared/components/no-search-results/no-search-results.component';
import { SkeletonModule } from 'app/shared/components/skeleton/skeleton.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomSpinnerComponent } from '../../shared/components/custom-spinner/custom-spinner.component';
import { ImageScrollAreaComponent } from './image-scroll-area/image-scroll-area.component';
import { ImageSelectorImageComponent } from './image-selector-image/image-selector-image.component';
import { ImageTemplateSelectorComponent } from './image-template-selector.component';

@NgModule({
  declarations: [ImageTemplateSelectorComponent, ImageScrollAreaComponent, ImageSelectorImageComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    NoSearchResultsComponent,
    FlexModule,
    ExtendedModule,
    MatButtonModule,
    CustomSpinnerComponent,
    TranslateModule,
    CustomDialogWrapperComponent,
    InfiniteScrollModule,
    MatRadioModule,
    SkeletonModule,
  ],
})
export class ImageTemplateSelectorModule {}
