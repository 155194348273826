import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target): void {
    if (!this.eRef.nativeElement.contains(target)) {
      this.clickOutside.emit();
    }
  }
}
