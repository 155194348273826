import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from '../../../../utils/Constants';
import { AuthenticationService } from '../../../../service/authentication/authentication.service';
import { RestService } from '../../../../service/rest/rest.service';
import { HttpHeaders } from '@angular/common/http';
import { FileUploadResponseVo } from '../vo/file-upload-response-vo';
import { RequestError } from '../../../../service/rest/request-error';
import { ReplaySubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../../../utils/utils';
import { FileUploadDownloadInputVo } from '../vo/file-upload-download-input-vo';
import { NotificationService } from '../../../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-url-upload',
  templateUrl: './url-upload.component.html',
  styleUrls: ['./url-upload.component.scss'],
})
export class UrlUploadComponent implements OnInit {
  private _inputSync: ReplaySubject<FileUploadDownloadInputVo> = new ReplaySubject<FileUploadDownloadInputVo>(1);

  @Input() set inputs(value: FileUploadDownloadInputVo) {
    this._inputSync.next(value);
  }

  @ViewChild('urlDownloadFileForm', { static: true }) urlDownloadForm: NgForm;
  @Output() callBack = new EventEmitter<FileUploadResponseVo>();
  @Output() downloadFileActive = new EventEmitter<boolean>();
  @Output() selectedTypeCallBack = new EventEmitter<string>();

  urlCredentials: FileDownloadVO = new FileDownloadVO();

  fileExtensions: any[] = Constants.UPLOAD_FILE_EXTENSIONS;

  // userName = 'fnetdatafeed';
  // password = 'datafeed';
  // remotePath = 'https://www.fragrancenet.com/wsdata/datafeed2.txt';

  userName = '';
  password = '';
  remotePath = '';

  disableFileTypeSelector = false;

  constructor(
    private auth: AuthenticationService,
    private notService: NotificationService,
    private translateService: TranslateService,
    private restService: RestService
  ) {}

  ngOnInit(): void {
    this._inputSync.pipe(takeWhile(() => !this.urlCredentials.taskId)).subscribe((res: FileUploadDownloadInputVo) => {
      this.urlCredentials.taskId = res.taskId;
      this.urlCredentials.extension = !Utils.isNullOrUndefined(res.selectedType)
        ? res.selectedType
        : Constants.EXTENSION_AUTO;

      if (!Utils.isNullOrUndefined(res.remotePath)) {
        const urlPieces = Utils.getCredentialsFromURL(res.remotePath);
        this.userName = urlPieces.username;
        this.password = urlPieces.password;
        this.remotePath = urlPieces.url;
      }

      this.urlCredentials.fileId = !Utils.isNullOrUndefined(res.fileId) ? res.fileId : null;
      this.disableFileTypeSelector = res.isUpdate;
    });
  }

  downloadFile(): void {
    if (this.urlDownloadForm.valid) {
      this.remotePath = this.remotePath.trim();
      this.userName = Utils.isNullOrUndefined(this.userName) ? null : this.userName.trim();
      this.password = Utils.isNullOrUndefined(this.password) ? null : this.password.trim();

      this.urlCredentials.remotePath = this.getConcatedRemotePath();
      console.log(this.urlCredentials);
      const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
      this.downloadFileActive.emit(true);
      this.restService.post('TaskFileDownloadService/downloadFile', this.urlCredentials, requestOptions).subscribe(
        (res) => {
          console.log(res);
          const resData = res.getFirstData();
          if (!Utils.isNullOrUndefined(resData.error)) {
            this.updateState(resData);
            this.notService.error(resData.error);
            this.downloadFileActive.emit(false);
            return;
          }
          const response = new FileUploadResponseVo();
          response.status = true;
          response.fileId = resData.pmsFile.fileId;
          response.remotePath = resData.pmsFile.remotePath;
          response.detectedType = resData.detectedType;
          this.updateState(resData);
          this.disableFileTypeSelector = false;
          this.downloadFileActive.emit(false);
          this.callBack.emit(response);
        },
        (error: RequestError) => {
          this.downloadFileActive.emit(false);
          console.log(error);
        }
      );
    }
  }

  updateState(respData): void {
    this.urlCredentials.fileId = respData.pmsFile.fileId;
    if (!Utils.isNullOrUndefined(respData.detectedType)) {
      this.urlCredentials.extension = respData.detectedType;
    }
  }

  getConcatedRemotePath(): string {
    if (!this.userName && !this.password) {
      return this.remotePath;
    }
    const splittedUrl = this.remotePath.split('//');
    if (splittedUrl.length !== 0) {
      return splittedUrl[0] + '//' + this.userName + ':' + this.password + '@' + splittedUrl[1];
    }
    return this.userName + ':' + this.password + '@' + this.remotePath;
  }

  typeChanged(event): void {
    this.selectedTypeCallBack.emit(event);
  }
}

export class FileDownloadVO {
  taskId: number;
  fileId: number;
  extension: string;
  remotePath: string;
}
