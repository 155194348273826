import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SynceeReviewDialogComponent } from '../syncee-review-dialog/dialog/syncee-review-dialog.component';
import { AppState } from '../../../app.state';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { SynceeReviewService } from './syncee-review.service';
import { catchError, switchMap } from 'rxjs/operators';
import { SaveReviewReplyVo } from '../model/save-review-reply.vo';
import { NotificationService } from '../../notification/notification.service';

@Injectable({ providedIn: 'root' })
export class SynceeReviewDialogService {
  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private synceeReviewService: SynceeReviewService,
    private notificationService: NotificationService
  ) {}

  public open(): Observable<boolean> {
    return this.dialog
      .open<SynceeReviewDialogComponent>(SynceeReviewDialogComponent, {
        autoFocus: false,
        restoreFocus: false,
      })
      .afterClosed();
  }

  getUserId(): Observable<number> {
    return this.store.select(getCurrentUserIdSelector);
  }

  savePlatform(platform: string, rating: number): Observable<void> {
    return this.getUserId().pipe(
      catchError(() => {
        return this.handleError();
      }),
      switchMap((id) => {
        return this.synceeReviewService.savePlatformChoice({ userId: id, platform: platform });
      }),
      switchMap(() => this.synceeReviewService.saveReviewScore(rating))
    );
  }

  saveFeedback(feedback: SaveReviewReplyVo, rating: number): Observable<void> {
    return this.synceeReviewService.saveReviewReply(feedback).pipe(
      catchError(() => {
        return this.handleError();
      }),
      switchMap(() => this.synceeReviewService.saveReviewScore(rating))
    );
  }

  handleError(): Observable<null> {
    this.notificationService.error('Sorry, an error occurred during the request.');
    return of(null);
  }
}
