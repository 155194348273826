import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SnippetToUser } from '../model/snippet-to-user';
import { MicroserviceNames, SpringRestService } from '../../../service/rest/microservices/spring-rest.service';
import { OnboardItem } from '../../../layout/components/onboard/onboard.service';
import { map } from 'rxjs/operators';
import { CreateSnippetToUserData } from '../model/create-snippet-to-user-data';

@Injectable({
  providedIn: 'root',
})
export class SetupGuideGatewayService {
  private microserviceName = 'userService';

  constructor(private springRestService: SpringRestService) {}

  getSnippetToUser(userId: number): Observable<SnippetToUser[]> {
    return this.springRestService.get(this.microserviceName, `/SnippetToUser/${userId}`, null, true);
  }

  getSnippetToUserForAdmin(userId: number): Observable<SnippetToUser[]> {
    return this.springRestService.get(this.microserviceName, `/SnippetToUser/Admin/${userId}`, null, true);
  }

  addSnippetToUser(data: CreateSnippetToUserData): Observable<void> {
    return this.addSnippetsToUser([data]);
  }

  addSnippetsToUser(data: CreateSnippetToUserData[]): Observable<void> {
    return this.springRestService.post(this.microserviceName, '/SnippetToUser/Create', data, {}, true);
  }

  getSnippetTree(version = 2): Observable<OnboardItem[]> {
    // hotfix
    return this.springRestService.get(MicroserviceNames.USER, `/Snippets/Tree`, { version }, true).pipe(
      map((snippets: OnboardItem[]) =>
        snippets.map((snippet) => ({
          ...snippet,
          steps: snippet.steps.map((step) => ({
            ...step,
            buttonText: JSON.parse(step.buttonText as unknown as string),
          })),
        }))
      )
    );
  }

  deleteSnippetAdmin(userId: number, snippetIds: number[]): Observable<void> {
    return this.springRestService.delete(
      MicroserviceNames.USER,
      `/SnippetToUser`,
      {
        userId,
        snippetIds: snippetIds.join(','),
      },
      true
    );
  }

  addSnippetsAdmin(userId: number, snippetsIds: number[]): Observable<void> {
    return this.springRestService.post(
      MicroserviceNames.USER,
      `/SnippetToUser/AddToUser`,
      {
        userId,
        dtos: snippetsIds.map((snippetsId: number) => ({
          snippetsId,
          skipped: false,
        })),
      },
      {},
      true
    );
  }
}
