import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Utils } from 'app/utils/utils';
import { ReplaySubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { RestService } from '../../../../service/rest/rest.service';
import { Constants } from '../../../../utils/Constants';
import { FileNodeTreeVO } from '../../../ftp/ftp.component';
import { ProductNodeTreeVO } from '../vo/product-node-tree-vo';
import { SettingsOutputVo } from '../vo/settings-output.vo';
import { SettingsInterface } from './settings-interface';

@Component({
  selector: 'app-productnodetree',
  templateUrl: './productnodetree.component.html',
  styleUrls: ['./productnodetree.component.scss'],
})
export class ProductnodetreeComponent implements OnInit, SettingsInterface {
  @Input() readonly = false;
  private _inputSync: ReplaySubject<ProductNodeTreeVO> = new ReplaySubject<ProductNodeTreeVO>(1);

  @Input() set inputs(value: ProductNodeTreeVO) {
    this._inputSync.next(value);
  }

  @ViewChild('settingsForm', { static: true }) settingsForm: NgForm;
  @ViewChild('fileNodeTree', { static: false }) fileNodeTree: NgModel;

  file: FileNodeTreeVO;

  taskId: number;
  fileId: number;
  fileType: string;
  isRunning = true;

  public nodeTree: any[];
  fileNodeTreeSelect: string[];
  characterEncodings = [
    { id: 1, name: 'UTF-8' },
    { id: 2, name: 'ANSI' },
    { id: 3, name: 'ASCII' },
  ];
  encodingSelect: string;

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this._inputSync.pipe(takeWhile(() => !this.taskId)).subscribe((res: ProductNodeTreeVO) => {
      console.log(res);
      this.taskId = res.taskId;
      this.fileId = res.fileId;
      this.fileType = res.fileType;
      this.encodingSelect = Utils.isNullOrUndefined(res.settings) ? 'UTF-8' : res.settings['encoding'];

      if (!Utils.isNullOrUndefined(res.selectedNode)) {
        this.fileNodeTreeSelect = res.selectedNode;
      }

      if (!Utils.isNullOrUndefined(res.nodeTree)) {
        this.nodeTree = res.nodeTree;
      } else {
        this.getProductNodeTree();
      }
    });
  }

  compareObjects(matValue: string[], selectedValue: string[]): boolean {
    if (matValue.length !== selectedValue.length) {
      return false;
    }
    for (let i = 0; i < matValue.length; i++) {
      if (matValue[i] !== selectedValue[i]) {
        return false;
      }
    }
    return true;
  }

  getProductNodeTree(force = false): void {
    const fileNodeVO = {
      fileId: this.fileId,
      taskId: this.taskId,
      force: force,
    };

    this.isRunning = true;

    this.restService.post('FileProcessService/getAndSetNodeTreeFW', fileNodeVO).subscribe(
      (res) => {
        this.isRunning = false;
        this.nodeTree = res.getData()?.[0]?.nodeTree;
      },
      (error) => console.log(error.errorMessage)
    );
  }

  getSettings(): SettingsOutputVo {
    this.fileNodeTree.control.markAsTouched();
    if (this.settingsForm.valid) {
      return new SettingsOutputVo(this.fileType, { container: this.fileNodeTreeSelect, encoding: this.encodingSelect });
    } else {
      return null;
    }
  }

  getSupportedFileTypes(): string[] {
    return [Constants.EXTENSION_JSON, Constants.EXTENSION_XML];
  }

  refresh(fileType: string): void {
    if (!Utils.isNullOrUndefined(fileType)) {
      this.fileType = fileType;
    }
    this.getProductNodeTree(true);
  }

  update(): void {
    this.getProductNodeTree(true);
  }
}
