<mat-card class="p-0">
  <app-custom-pagination
    [size]="size"
    [page]="page"
    [fetcherLogic]="false"
    [dataSource]="dataSource"
    [count]="allCount"
    (sizeChanged)="sizeChanged.emit($event)"
    (nextClicked)="nextClicked.emit()"
    (previousClicked)="previousClicked.emit()"
  >
    <ng-template let-rowData="rowData" let-index="index" #row>
      <div class="p-20">
        <app-tasks-mobile-list-item
          [role]="role"
          [task]="rowData"
          [menus]="menus"
          (syncClicked)="syncClicked.emit(rowData)"
          (menuItemClicked)="menuItemClicked.emit($event)"
          (clickRow)="clickRow.emit($event)"
        ></app-tasks-mobile-list-item>
      </div>
      <app-custom-divider></app-custom-divider>
    </ng-template>
  </app-custom-pagination>
</mat-card>
