<div
  class="mobile-toolbar"
  fxLayout="column"
  [fxLayoutGap]="!config.content.hideSearchbar || !config.content.hideAiChatButton ? '20px' : '0px'"
>
  <div fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center">
      <button mat-icon-button class="categories-button">
        <mat-icon (click)="openMobileMainMenu()">menu</mat-icon>
      </button>
      <app-syncee-logo [width]="'97px'" [height]="'20px'"></app-syncee-logo>
    </div>
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
      <app-toolbar-action-bar
        [content]="config.content.actionbar"
        [extraIcons]="extraIcons"
        [role]="role"
        *onlyLoggedIn
      ></app-toolbar-action-bar>
      <ng-container *onlyGuest>
        <button
          mat-flat-button
          color="primary"
          [style.padding]="'0 16px !important'"
          (click)="emitRegistrationDialog()"
        >
          {{ 'TOOLBAR.SIGN_UP' | translate }}
        </button>
        <button mat-icon-button (click)="emitLoginDialog()"><mat-icon>person</mat-icon></button>
        <button mat-icon-button (click)="emitLoginDialog()">
          <mat-icon svgIcon="cart_custom" [badge]="" [badgeVariant]="'with-number'" [badgeOffsetX]="4"></mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!config.content.hideSearchbar || !config.content.hideAiChatButton">
    <div fxLayout="center center">
      <ng-container *ngIf="!config.content.hideSearchbar">
        <app-product-search-with-autocomplete-mobile-indicator
          fxFlex="1 0 0"
          class="searchbar m-w-0"
          [needRedirect]="needRedirect"
        ></app-product-search-with-autocomplete-mobile-indicator>
      </ng-container>
      <ng-container *ngIf="!config.content.hideAiChatButton">
        <app-ai-chat-open-button></app-ai-chat-open-button>
      </ng-container>
    </div>
  </ng-container>
</div>
