<ng-container *ngIf="!isLoadingAll; else loading"
  ><ng-container *ngIf="!showAll; else allRatesView">
    <div class="shipping-details-preferred w-100-p" fxLayout="column" fxLayoutGap="24px">
      <ng-container *ngIf="(isAutoOrder | async) === false && (rates | isEmpty)">
        <div class="typography-body-1 pb-16">
          {{ 'PRODUCT_DETAILS.SHIPPING_MANAGED_INDIVIDUALLY' | translate }}
        </div>
      </ng-container>

      <ng-container *ngIf="profiles && profiles.length > 1">
        <mat-tab-group
          class="tab-simple-pagination tab-label-min-width-unset"
          [selectedIndex]="selectedIndex"
          (selectedIndexChange)="handleTabChange($event)"
        >
          <ng-container *ngFor="let profile of profiles">
            <mat-tab>
              <ng-template mat-tab-label>
                {{ profile.name }}
              </ng-template>
              <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="content"></ng-container>
              </ng-template>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>

      <ng-container *ngIf="profiles && profiles.length === 1">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ng-container>

      <div class="w-100-p" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="syncee-white-button" (click)="showAll = true">
          {{ 'SHIPPING_DETAILS.SEE_ALL_RATES' | translate }}
        </button>
      </div>
    </div>
  </ng-container></ng-container
>

<ng-template #content>
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="!(rates | isEmpty); else noPreferred">
      <div class="pt-24">
        <app-shipping-details-preferred-table
          class="w-100-p"
          [rates]="rates"
          [preferredLocations]="preferredLocations"
          [supplierCurrency]="supplierCurrency"
        ></app-shipping-details-preferred-table>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noPreferred>
  <div class="pt-24">
    <app-alert-multi type="info" [messages]="['PRODUCT_DETAILS.NO_PREFERRED_COUNTRIES' | translate]"></app-alert-multi>
  </div>
</ng-template>

<ng-template #allRatesView>
  <app-shipping-details-new-full
    [userId]="userId"
    [isWorldwide]="isWorldwide"
    [supplierCurrency]="supplierCurrency"
    [taskId]="taskId"
    [isAutoOrder]="isAutoOrder"
  ></app-shipping-details-new-full>
</ng-template>

<ng-template #loading>
  <div class="w-100-p p-16">
    <app-loading-spinner [centered]="true" [size]="50"></app-loading-spinner>
  </div>
</ng-template>
