import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToolbarExtraIcon } from '../../../../../../@fuse/types';
import { ExtraIconsService } from './extra-icons.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-extra-icons',
  templateUrl: './extra-icons.component.html',
  styleUrls: ['./extra-icons.component.scss'],
})
export class ExtraIconsComponent implements OnInit, OnDestroy {
  @Input() toolbarExtraIcons: ToolbarExtraIcon[];
  extraOverlayOpenStatuses: boolean[];
  private unsubscribeAll: Subject<void>;

  constructor(private extraIconsService: ExtraIconsService) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.genOverlayInitStatuses();
    this.subscribeToOverlayClose();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private genOverlayInitStatuses(): void {
    this.extraOverlayOpenStatuses = new Array(this.toolbarExtraIcons.length).fill(false);
  }

  private subscribeToOverlayClose(): void {
    this.extraIconsService.closeOverlay.pipe(takeUntil(this.unsubscribeAll)).subscribe((id) => {
      this.extraOverlayOpenStatuses[this.toolbarExtraIcons.findIndex((icon) => icon.id === id)] = false;
    });
  }
}
