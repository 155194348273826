import { Component, OnInit } from '@angular/core';

import { StepperService } from '../../service/stepper.service';
import { ChangeRoleScreenService } from '../../service/change-role-screen.service';

import type { ChangeRoleDialogConfig } from '../../model/change-role-dialog-config-type';

@Component({
  selector: 'app-enter',
  templateUrl: './enter.component.html',
  styleUrls: ['./enter.component.scss'],
})
export class EnterComponent implements OnInit {
  public config: ChangeRoleDialogConfig;

  constructor(public stepperService: StepperService, public screenService: ChangeRoleScreenService) {}

  public ngOnInit(): void {
    this.config = this.stepperService.config;
  }

  get descriptionStyle(): object {
    return {
      'text-center syncee-grey-800-fg': true,
      'typography-body-3': this.screenService.small,
      'typography-body-1': !this.screenService.small,
    };
  }
}
