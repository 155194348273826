import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chat-search-bar',
  templateUrl: './chat-search-bar.component.html',
  styleUrls: ['./chat-search-bar.component.scss'],
})
export class ChatSearchBarComponent implements OnInit {
  @Output() search = new EventEmitter<string>();
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();
  @Input() searchTerm: string;
  isFocused: boolean;
  constructor() {}

  ngOnInit(): void {}

  handleSearch(): void {
    this.search.emit(this.searchTerm);
  }

  handleClear(): void {
    this.searchTerm = '';
    this.handleSearch();
  }

  handleFocus(): void {
    this.focus.emit();
    this.isFocused = true;
  }

  handleBlur(): void {
    this.blur.emit();
    this.isFocused = false;
  }
}
