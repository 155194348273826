import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrencyService } from 'app/service/currency-service/currency.service';
import { ChartDataset } from 'chart.js';
import * as moment from 'moment';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { AppState } from '../../../app.state';
import { RetailerDashboardAnalyticsService } from '../../../service/analytics/retailer-dashboard-analytics.service';
import { ColorPaletteService } from '../../../service/color-palette/color-palette.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { getSelectedEcomByRole } from '../../../store/ecom/ecom.selector';
import { RolesEnum } from '../../../vo/roles/roles';
import { OverviewRecord } from '../overview/overview.component';
import { TranslateService } from '@ngx-translate/core';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './retailer-dashboard.component.html',
  styleUrls: ['./retailer-dashboard.component.scss'],
})
export class RetailerDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('topRightTemplate') topRightTemplate: TemplateRef<any>;

  public dateFrom: Date;

  public dateTo: Date;

  public selectedEcom: EcomVO;

  public overViewRecords: OverviewRecord[];

  public currency$: Observable<string>;

  public datasets: ChartDataset<'bar', (number | [number, number])[]>[];

  private DAYS_BEFORE = 30;

  private unsubscribeAll: Subject<void>;

  protected selectedEcomCurrency: string;

  constructor(
    private fuseConfigService: FuseConfigService,
    private retailerDashboardAnalyticsService: RetailerDashboardAnalyticsService,
    private colorPaletteService: ColorPaletteService,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    this.unsubscribeAll = new Subject<void>();
    this.setCurrency();
  }

  private setDateFrom(): void {
    this.dateFrom = moment().startOf('day').subtract(this.DAYS_BEFORE, 'days').toDate();
  }

  ngOnInit(): void {
    this.subscribeToEcom();
    this.initDatasets();
  }

  ngAfterViewInit(): void {
    this.initTheme();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private initDatasets(): void {
    this.translateService
      .stream('DASHBOARD.RETAILER.DASHBOARD.DATASETS')
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((translation) => {
        this.datasets = [
          {
            label: translation['MARKETPLACE_ORDERS'],
            data: [],
            backgroundColor: this.colorPaletteService.getColorVariable('--app-syncee-primary-50'),
            barThickness: 'flex',
            borderRadius: 8,
          },
          {
            label: translation['ALIBABA_ORDERS'],
            data: [],
            backgroundColor: this.colorPaletteService.getColorVariable('--app-orange-100'),
            barThickness: 'flex',
            borderRadius: 8,
          },
        ];
      });
  }

  private setCurrency(): void {
    this.currency$ = this.store
      .select(getSelectedEcomByRole(RolesEnum.RETAILER))
      .pipe(map((selectedEcom) => CurrencyService.getCurrency(selectedEcom)));
  }

  private subscribeToEcom(): void {
    this.store
      .select(getSelectedEcomByRole(RolesEnum.RETAILER))
      .pipe(
        takeUntil(this.unsubscribeAll),
        omitNullOrUndefined(),
        tap((ecom: EcomVO) => (this.selectedEcomCurrency = ecom?.options?.currency))
      )
      .subscribe((selectedEcom) => {
        this.selectedEcom = selectedEcom;
        this.setDateFrom();
        this.setOverviewAnalytics();
      });
  }

  private initTheme(): void {
    this.translateService
      .stream('DASHBOARD.RETAILER.DASHBOARD.TITLE')
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((title): void => {
        this.fuseConfigService.config = {
          layout: {
            title,
            contentContainer: 'full-width',
          },
          templates: {
            topRightContent: this.topRightTemplate,
          },
        };
      });
  }

  private setOverviewAnalytics(): void {
    combineLatest([
      this.retailerDashboardAnalyticsService.getOrderOverviewAnalytics().pipe(take(1)),
      this.translateService
        .stream('DASHBOARD.RETAILER.DASHBOARD.OVERVIEW_ANALYTICS')
        .pipe(untilDestroyed(this), omitNullOrUndefined()),
    ]).subscribe(([overViewAnalytics, translation]): void => {
      this.overViewRecords = [
        {
          title: translation['TOTAL_REVENUE']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.totalRevenue || 0,
          tooltip: translation['TOTAL_REVENUE']['TOOLTIP'],
        },
        {
          title: translation['TOTAL_PROFIT']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.totalProfit || 0,
          tooltip: translation['TOTAL_PROFIT']['TOOLTIP'],
        },
        {
          title: translation['AVERAGE_REVENUE']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.averageRevenue || 0,
          tooltip: translation['AVERAGE_REVENUE']['TOOLTIP'],
        },
        {
          title: translation['AVERAGE_PROFIT']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.averageProfit || 0,
          tooltip: translation['AVERAGE_PROFIT']['TOOLTIP'],
        },
        {
          title: translation['MAX_PROFIT_ON_ORDER']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.maxProfitOnOrder || 0,
          tooltip: translation['MAX_PROFIT_ON_ORDER']['TOOLTIP'],
        },
        {
          title: translation['AVERAGE_PROFIT_MARGIN']['TITLE'],
          info: '',
          unitOfMeasurement: 'PERCENT',
          value: overViewAnalytics.totalProfit / overViewAnalytics.totalRevenue || 0,
          tooltip: translation['AVERAGE_PROFIT_MARGIN']['TOOLTIP'],
        },
      ];
    });
  }

  protected readonly RolesEnum = RolesEnum;
}
