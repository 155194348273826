<div #toolbarContainer>
  <ng-container *ngIf="!(isOnMobile | async); else mobile">
    <ng-container *ngIf="toolbarConfig | async as config">
      <ng-container *ngIf="!config.content.hideTopCallToAction">
        <app-toolbar-call-to-action position="top"></app-toolbar-call-to-action>
      </ng-container>
      <ng-container *ngIf="!config.content.hideUpperRow">
        <app-upper-row></app-upper-row>
      </ng-container>
      <app-desktop-toolbar
        [role]="role"
        [needCategorySelector]="needCategorySelector"
        [needRedirect]="needRedirect"
        [extraIcons]="config.extraIcons"
        [hasSearchBar]="!config.content.hideSearchbar"
        [hasAiChat]="hasAiChat"
        [config]="config"
      ></app-desktop-toolbar>
      <ng-container *ngIf="!config.content.hideTopCallToAction">
        <app-toolbar-call-to-action position="below-header"></app-toolbar-call-to-action>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #mobile>
  <app-mobile-toolbar [config]="toolbarConfig | async"></app-mobile-toolbar>
</ng-template>
