import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
  standalone: true,
  imports: [MatCheckboxModule, TranslateModule],
})
export class AcceptTermsComponent {
  @Input() checked: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  emitValue(value: boolean): void {
    this.checked = value;
    this.checkedChange.emit(this.checked);
  }
}
