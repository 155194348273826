import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import { SCOPES } from 'app/service/permission/scopes';
import { LoginVO } from 'app/vo/authentication/login-vo';
import { User } from '../../service/user/user';
import { RoleTypesEnum, ROLE_TYPE } from '../../vo/roles/roles';

export enum AuthActionTypes {
  BASIC_LOGIN = '[Auth] Basic Login',
  BASIC_LOGIN_SUCCESS = '[Auth] Login Success',
  SOCIAL_REGISTER_START = '[Auth] Social Register Start',
  SOCIAL_LOGIN_START = '[Auth] Social Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  SIGNUP = '[Auth] Signup',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',
  LOGOUT_SUCCESS_DONE = '[Auth] Logout Success Done',
  USER_TO_USER_LOGIN_START = '[Auth] User to user login start',
  USER_TO_USER_AUTH_SUCCESS = '[Auth] User to user authentication success',
  USER_TO_USER_LOGIN_SUCCESS = '[Auth] User to user login success',
  USER_TO_USER_LOGOUT_START = '[Auth] User to user logout start',
  USER_TO_USER_LOGOUT_SUCCESS = '[Auth] User to user logout success',
  REFRESH_AUTH_TOKENS = '[Auth] Refresh auth tokens',
  REFRESH_AUTH_TOKENS_SUCCESS = '[Auth] Refresh auth tokens success',
  REFRESH_CROSSLOGGED_USER_AUTH_TOKENS = '[Auth] Refresh logged user by admins auth tokens',
  REFRESH_CROSSLOGGED_USER_AUTH_TOKENS_SUCCESS = '[Auth] Refresh logged user by admins auth tokens success',
  GOOGLE_LOGIN_START = '[Auth] Google Social Login Success',
  FACEBOOK_LOGIN_START = '[Auth] Facebook Social Login Success',
}

export interface BasicLoginPayload {
  email: string;
  password: string;
  role?: ROLE_TYPE;
}

export interface SocialAuthPayload {
  userName: string;
  email: string;
  password: string;
  role?: ROLE_TYPE;
  loginType: string;
}

export interface GoogleAuthPayload {
  jwt: string;
  role: RoleTypesEnum;
}

export interface FacebookAuthPayload {
  code: string;
  role: RoleTypesEnum;
}

export interface ErrorPayload {
  error: any;
}

export interface LoginSuccessPayload extends LoginVO {
  scopes: SCOPES[];
}

export interface UserToUserNavigatePayload {
  url: string;
  queryParams?: any;
}

export interface UserToUserLoginPayload {
  userId: number;
  email: string;
  loginType: UserToUserLoginType;
  navigateTo?: UserToUserNavigatePayload;
}

export type UserToUserLoginType = 'ADMIN' | 'TEAM_MEMBER';

export interface UserToUserAuthPayload extends LoginVO, UserToUserLoginPayload {
  scopes: SCOPES[];
}

export interface UserToUserLoginSuccessPayload {
  user: User;
  scopes: SCOPES[];
  loginType: UserToUserLoginType;
  navigateTo?: UserToUserNavigatePayload;
}

export interface LogoutPayload {
  url?: string;
  queryParams?: Params;
  state?: { [key: string]: any };
}

export class BasicLogIn implements Action {
  readonly type = AuthActionTypes.BASIC_LOGIN;
  constructor(public payload: BasicLoginPayload) {}
}

export class SocialLogIn implements Action {
  readonly type = AuthActionTypes.SOCIAL_LOGIN_START;
  constructor(public payload: SocialAuthPayload) {}
}

export class SocialRegister implements Action {
  readonly type = AuthActionTypes.SOCIAL_REGISTER_START;
  constructor(public payload: SocialAuthPayload) {}
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.BASIC_LOGIN_SUCCESS;
  constructor(public payload: LoginSuccessPayload) {}
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: ErrorPayload) {}
}

export class SignUp implements Action {
  readonly type = AuthActionTypes.SIGNUP;
  constructor() {}
}

export class LogoutAction implements Action {
  readonly type = AuthActionTypes.LOGOUT;
  constructor(public payload?: LogoutPayload) {}
}

export class LogoutActionSuccess implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS;
  constructor(public payload?: LogoutPayload) {}
}

export class LogoutActionSuccessDone implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS_DONE;
  constructor() {}
}

export class UserToUserLoginStart implements Action {
  readonly type = AuthActionTypes.USER_TO_USER_LOGIN_START;
  constructor(public payload: UserToUserLoginPayload) {}
}

export class UserToUserAuthSuccess implements Action {
  readonly type = AuthActionTypes.USER_TO_USER_AUTH_SUCCESS;
  constructor(public payload: UserToUserAuthPayload) {}
}

export class UserToUserLoginSuccess implements Action {
  readonly type = AuthActionTypes.USER_TO_USER_LOGIN_SUCCESS;
  constructor(public payload: UserToUserLoginSuccessPayload) {}
}

export class UserToUserLogoutStart implements Action {
  readonly type = AuthActionTypes.USER_TO_USER_LOGOUT_START;
  constructor(public payload: UserToUserLoginType) {}
}

export class UserToUserLogoutSuccess implements Action {
  readonly type = AuthActionTypes.USER_TO_USER_LOGOUT_SUCCESS;
  constructor(public payload: UserToUserLoginType) {}
}

export class RefreshAuthTokens implements Action {
  readonly type = AuthActionTypes.REFRESH_AUTH_TOKENS;
  constructor(public payload: LoginSuccessPayload) {}
}

export class RefreshCrossLoggedUserAuthTokens implements Action {
  readonly type = AuthActionTypes.REFRESH_CROSSLOGGED_USER_AUTH_TOKENS;
  constructor(public payload: LoginSuccessPayload) {}
}

export class SocialGoogleLogIn implements Action {
  readonly type = AuthActionTypes.GOOGLE_LOGIN_START;
  constructor(public payload: GoogleAuthPayload) {}
}

export class SocialFacebookLogIn implements Action {
  readonly type = AuthActionTypes.FACEBOOK_LOGIN_START;
  constructor(public payload: FacebookAuthPayload) {}
}

export type AuthenticationActions =
  | BasicLogIn
  | LogInSuccess
  | LogInFailure
  | SignUp
  | LogoutAction
  | LogoutActionSuccess
  | RefreshAuthTokens
  | RefreshCrossLoggedUserAuthTokens
  | UserToUserLoginStart
  | UserToUserLoginSuccess
  | UserToUserAuthSuccess
  | UserToUserLogoutStart
  | UserToUserLogoutSuccess;
