import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { Skipped, StepId } from '../model/variables/general.variables';
import { PlanId } from '../model/variables/subscription.variables';

export class GsStepRetailerAction implements GtmAction {
  event = GtmEvent.gs_step_retailer;

  constructor(public payload: GsStepRetailerActionPayload) {}
}

export interface GsStepRetailerActionPayload extends StepId, Skipped, PlanId {}
