import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { StepBase } from '../../taskwizard/step-base';
import { Observable, of } from 'rxjs';
import { RetailerExportConnectFtpComponent } from './retailer-export-connect-ftp/retailer-export-connect-ftp.component';
import { ExportTaskwizardService } from '../../../service/taskwizard/export-taskwizard.service';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
})
export class DestinationComponent implements OnInit, StepBase {
  form: UntypedFormGroup;
  ftpSettings: UntypedFormGroup;
  @Input() url = '';
  @Input() currentTaskId: number;
  @Input() destination = 'URL';
  @Output() destinationChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('retailerExportConnectFtpComponent', { static: false })
  connectFtpComponent: RetailerExportConnectFtpComponent;

  constructor(private formBuilder: UntypedFormBuilder, private exportTaskwizardService: ExportTaskwizardService) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      destination: [this.destination, Validators.required],
    });

    this.ftpSettings = this.formBuilder.group({
      host: ['', Validators.required],
      port: [''],
      root: [''],
      username: ['', Validators.required],
      password: ['', Validators.required],
      passiveMode: [true],
      ssl: [''],
    });
  }

  handleDestinationChange(): void {
    this.destinationChanged.emit(this.form.value.destination);
  }

  public isFormInvalid(): boolean {
    return !this.form.valid;
  }

  isStepValid(): boolean {
    return this.destination === 'FTP'
      ? !this.isFormInvalid() && this.connectFtpComponent.isFTPFormValid()
      : !this.isFormInvalid();
  }

  saveStep(): Observable<any> {
    let ftpConnParams = null;
    if (this.destination === 'FTP') {
      ftpConnParams = this.connectFtpComponent.getFtpCredentials();
    }
    return this.exportTaskwizardService.saveDestination(this.currentTaskId, this.destination, ftpConnParams);
  }

  public connectToFTP(): void {
    console.log('this.ftpSettings.value', this.ftpSettings.value);
  }
}
