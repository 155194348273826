import { Component } from '@angular/core';
import { WizardCategoryHeaderService } from '../../../service/wizard-category-header.service';
import { WizardCategoryMappingListService } from '../../../service/wizard-category-mapping-list.service';

@Component({
  selector: 'app-wizard-category-mapping-bulk-actions',
  templateUrl: './wizard-category-mapping-bulk-actions.component.html',
  styleUrls: ['./wizard-category-mapping-bulk-actions.component.scss'],
})
export class WizardCategoryMappingBulkActionsComponent {
  constructor(
    public headerService: WizardCategoryHeaderService,
    public listService: WizardCategoryMappingListService
  ) {}

  public handleSelectButtonClick(): void {
    if (this.listService.checkedItems.length < 1) {
      this.listService.checkAllItems();
      return;
    }

    this.listService.clearSelection();
  }
}
