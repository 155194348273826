import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../../user/user';
import { USER_ROLES } from '../../../utils/Constants';
import { Utils } from 'app/utils/utils';

@Injectable()
export class UseridInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = null;
    switch (req.method) {
      case 'POST':
        request = req.clone({ body: this.addUserIdToParamToPost(req.body) });
        break;
      case 'GET':
        request = req.clone({ params: this.addUserIdToParamToGet(req.params) });
        break;
      case 'DELETE':
        request = req.clone({ body: this.addUserIdToParamToPost(req.body) });
        break;
      case 'PUT':
        request = req.clone({ body: this.addUserIdToParamToPost(req.body) });
        break;
      case 'PATCH':
        request = req.clone({ body: this.addUserIdToParamToPost(req.body) });
        break;
    }
    return next.handle(request);
  }

  private addUserIdToParamToGet(params?: HttpParams): HttpParams | null {
    const userInAdminSession = this.userSelectedWithAdmin();
    if (!Utils.isNullOrUndefined(userInAdminSession)) {
      if (Utils.isNullOrUndefined(params)) {
        params = new HttpParams();
      }
      params = params.set('userId', userInAdminSession);
    }

    return params;
  }

  private addUserIdToParamToPost(body: any): any {
    const userInAdminSession = this.userSelectedWithAdmin();
    if (!Utils.isNullOrUndefined(userInAdminSession)) {
      if (Utils.isNullOrUndefined(body)) {
        body = '';
      }
      if (typeof body === 'string') {
        if (body.length !== 0) {
          body += '&';
        }
        body += 'id=' + userInAdminSession;
      } else {
        if (body instanceof FormData) {
          body.append('userId', userInAdminSession);
        } else {
          body.userId = userInAdminSession;
        }
      }
    }
    console.log(body);
    return body;
  }

  private userSelectedWithAdmin(): string {
    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    if (!Utils.isNullOrUndefined(user)) {
      if (user.role === USER_ROLES.GLOBAL_ADMIN) {
        return localStorage.getItem('userId');
      }
    }
    return null;
  }
}
