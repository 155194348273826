<form [formGroup]="setUpAccountFormGroup" fxLayout="column" class="w-100p" fxLayoutGap="20px">
  <div
    *ngIf="!fieldsToHide?.includes(inputNames.FIRST_NAME)"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
  >
    <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
      {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.FIRST_NAME' | translate }}
    </div>
    <mat-form-field
      fxFlex="1 0 0"
      appearance="fill"
      class="w-100p input-without-padding-except-error"
      [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
    >
      <mat-label *ngIf="!labelled || ltMd">{{
        'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.FIRST_NAME' | translate
      }}</mat-label>
      <input
        matInput
        [formControlName]="inputNames.FIRST_NAME"
        placeholder="{{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.FIRST_NAME' | translate }}"
        type="text"
      />
      <mat-error *ngIf="!!firstName.errors">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div
    *ngIf="!fieldsToHide?.includes(inputNames.LAST_NAME)"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="stretch"
  >
    <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
      {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.LAST_NAME' | translate }}
    </div>
    <mat-form-field
      fxFlex="1 0 0"
      appearance="fill"
      class="w-100p input-without-padding-except-error"
      [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
    >
      <mat-label *ngIf="!labelled || ltMd">{{
        'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.LAST_NAME' | translate
      }}</mat-label>
      <input
        matInput
        [formControlName]="inputNames.LAST_NAME"
        placeholder="{{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.LAST_NAME' | translate }}"
        type="text"
      />
      <mat-error *ngIf="!!lastName.errors">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <ng-container *ngIf="isSupplier">
    <div *ngIf="isBothRole" class="account-details-header" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="50">
        <div class="h4">
          {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.SUPPLIER_DETAILS' | translate }}
        </div>
      </div>
    </div>
    <div
      *ngIf="!fieldsToHide?.includes(inputNames.CONTACT_EMAIL)"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="stretch"
    >
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.CONTACT_EMAIL' | translate }}
      </div>
      <mat-form-field
        fxFlex="1 0 0"
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
      >
        <mat-label *ngIf="!labelled || ltMd">
          {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.CONTACT_EMAIL' | translate }}
        </mat-label>
        <input matInput [formControlName]="inputNames.CONTACT_EMAIL" type="text" />
        <mat-error *ngIf="!!contactEmail && (contactEmail.errors?.required || contactEmail.errors?.email)">{{
          contactEmail.errors?.email ? ('FORM_VALIDATORS.EMAIL' | translate) : ('FORM_VALIDATORS.REQUIRED' | translate)
        }}</mat-error>
      </mat-form-field>
    </div>
    <div
      *ngIf="!fieldsToHide?.includes(inputNames.SUPPLIER_COUNTRY)"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="stretch"
    >
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate }}
      </div>
      <mat-form-field
        fxFlex="1 0 0"
        class="input-without-padding-except-error custom-form-field-wrapper"
        appearance="standard"
      >
        <ng-container *ngIf="!labelled || ltMd; else unlabelledSupplierCountry">
          <app-single-country-selector-form-field
            [formControlName]="inputNames.SUPPLIER_COUNTRY"
            [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate"
            [label]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate"
          ></app-single-country-selector-form-field>
        </ng-container>
        <ng-template #unlabelledSupplierCountry>
          <app-single-country-selector-form-field
            [formControlName]="inputNames.SUPPLIER_COUNTRY"
            [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate"
          ></app-single-country-selector-form-field>
        </ng-template>
        <mat-error *ngIf="!!supplierCountry && !!supplierCountry.errors">{{
          'FORM_VALIDATORS.REQUIRED' | translate
        }}</mat-error>
      </mat-form-field>
    </div>
    <div
      *ngIf="!fieldsToHide?.includes(inputNames.SUPPLIER_PHONE_NUMBER)"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="stretch"
    >
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.PHONE_NUMBER' | translate }}
      </div>
      <mat-form-field
        fxFlex="1 0 0"
        class="input-without-padding-except-error custom-form-field-wrapper phone-form-field-wrapper"
        appearance="standard"
      >
        <app-phone-input-form-field
          [formControlName]="inputNames.SUPPLIER_PHONE_NUMBER"
          [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.PHONE_NUMBER' | translate"
        ></app-phone-input-form-field>
        <mat-error *ngIf="!!supplierPhoneNumber.errors">{{
          supplierPhoneNumber.errors?.noNumber ? ('FORM_VALIDATORS.REQUIRED' | translate) : 'Error'
        }}</mat-error>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="isRetailer">
    <div *ngIf="isBothRole" class="account-details-header" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="50">
        <div class="h4">
          {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.RETAILER_DETAILS' | translate }}
        </div>
      </div>
    </div>
    <div
      *ngIf="!fieldsToHide?.includes(inputNames.RETAILER_COUNTRY)"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="stretch"
    >
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate }}
      </div>
      <mat-form-field
        fxFlex="1 0 0"
        class="input-without-padding-except-error custom-form-field-wrapper"
        appearance="standard"
      >
        <ng-container *ngIf="!labelled || ltMd; else unlabelledRetailerCountry">
          <app-single-country-selector-form-field
            [formControlName]="inputNames.RETAILER_COUNTRY"
            [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate"
            [label]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate"
          ></app-single-country-selector-form-field>
        </ng-container>
        <ng-template #unlabelledRetailerCountry>
          <app-single-country-selector-form-field
            [formControlName]="inputNames.RETAILER_COUNTRY"
            [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.COUNTRY' | translate"
          ></app-single-country-selector-form-field>
        </ng-template>
        <mat-error *ngIf="!!retailerCountry.errors">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div
      *ngIf="!fieldsToHide?.includes(inputNames.RETAILER_PHONE_NUMBER)"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="stretch"
    >
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.PHONE_NUMBER' | translate }}
      </div>
      <mat-form-field
        fxFlex="1 0 0"
        class="input-without-padding-except-error custom-form-field-wrapper phone-form-field-wrapper"
        appearance="standard"
      >
        <app-phone-input-form-field
          [formControlName]="inputNames.RETAILER_PHONE_NUMBER"
          [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.PHONE_NUMBER' | translate"
        ></app-phone-input-form-field>
        <mat-error *ngIf="!!retailerPhoneNumber && !!retailerPhoneNumber.errors">{{
          retailerPhoneNumber.errors?.noNumber ? ('FORM_VALIDATORS.REQUIRED' | translate) : 'Error'
        }}</mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
