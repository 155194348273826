import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EcomsState } from './ecom.reducer';
import { isEmpty } from 'lodash';
import { RolesEnum } from '../../vo/roles/roles';

const getEcomsState = createFeatureSelector<EcomsState>('ecoms');
export const selectedRetailerEcomSelector = createSelector(
  getEcomsState,
  (state) => !!state && state.selectedRetailerEcom
);

export const selectedSupplierEcomSelector = createSelector(
  getEcomsState,
  (state) => !!state && state.selectedSupplierEcom
);
export const supplierEcomsSelector = createSelector(
  getEcomsState,
  (state: EcomsState) => !!state && state.supplierEcoms
);
export const retailerEcomsSelector = createSelector(
  getEcomsState,
  (state: EcomsState) => !!state && state.retailerEcoms
);

export const getEcomsByRole = (role: RolesEnum) =>
  createSelector(getEcomsState, (state: EcomsState) => {
    if (!!state) {
      return role === RolesEnum.RETAILER ? state.retailerEcoms : state.supplierEcoms;
    }
  });

export const getSelectedEcomByRole = (role: RolesEnum) =>
  createSelector(getEcomsState, (state: EcomsState) => {
    if (!!state) {
      return role === RolesEnum.RETAILER ? state.selectedRetailerEcom : state.selectedSupplierEcom;
    }
  });

export const hasEcomSelector = createSelector(getEcomsState, (state: EcomsState, role: RolesEnum) => {
  if (!!state) {
    if (role === RolesEnum.RETAILER) {
      return !!state.retailerEcoms && !isEmpty(state.retailerEcoms);
    } else {
      return !!state.supplierEcoms && !isEmpty(state.supplierEcoms);
    }
  }
  return undefined;
});
