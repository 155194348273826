import { Component, OnInit } from '@angular/core';
import { AiChatService } from '../../services/ai-chat.service';
import { AiChatLayout } from '../../model/layout/ai-chat-layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ai-chat-header',
  templateUrl: './ai-chat-header.component.html',
  styleUrls: ['./ai-chat-header.component.scss'],
})
export class AiChatHeaderComponent implements OnInit {
  aiChatLayoutEnum = AiChatLayout;
  layoutChangeIcon: Observable<string>;

  constructor(public aiChatService: AiChatService) {}

  ngOnInit(): void {
    this.layoutChangeIcon = this.getLayoutChangerIcon();
  }

  changeLayout(): void {
    switch (this.aiChatService.layout) {
      case AiChatLayout.SIDEBAR:
        this.aiChatService.changeLayout(AiChatLayout.POPOVER);
        break;
      case AiChatLayout.POPOVER:
        this.aiChatService.changeLayout(AiChatLayout.SIDEBAR);
        break;
    }
  }

  private getLayoutChangerIcon(): Observable<string> {
    return this.aiChatService.layout$.pipe(
      map((layout) => {
        switch (layout) {
          case AiChatLayout.SIDEBAR:
            return 'close_fullscreen';
          case AiChatLayout.POPOVER:
            return 'view_sidebar';
          default:
            return null;
        }
      })
    );
  }
}
