import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { IsProductUsedResponse } from 'app/service/marketplace/explore-products/is-product-used';
import { MetaTagsService, SYNCEE_BAG_LOGO_URL } from 'app/service/meta-tags/meta-tags.service';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { SCOPES } from 'app/service/permission/scopes';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { Utils } from 'app/utils/utils';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { AppState } from '../../../app.state';
import { CatalogSidebarService } from '../../../service/catalog-sidebar/catalog-sidebar.service';
import { FeaturedProductService } from '../../../service/featured-product/featured-product.service';
import { SuppliersService } from '../../../service/suppliers/suppliers.service';
import { selectedCurrencySelector } from '../../../store/currency/currency.selector';
import { ClearFilter } from '../../../store/product-search/product-search.action';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { RolesEnum } from '../../../vo/roles/roles';
import { MarketplaceFilter, SearchProductVO } from '../../../vo/search-product-vo';
import { SupplierBlock } from '../supplier-card/supplier-slider-box/supplier-slider-box.component';
import { PreferenceDialogService } from '../../../service/preference/preference-dialog.service';
import { isEqual } from 'lodash';
import { ShippingPreferencesService } from '../../../service/preference/shipping-preferences.service';
import { RICH_TEXT_AUDIENCE_TYPES } from 'app/utils/RichTextConstants';

@Component({
  selector: 'app-explore-products',
  templateUrl: './explore-products.component.html',
  styleUrls: ['./explore-products.component.scss'],
  animations: [fuseAnimations],
})
export class ExploreProductsComponent implements OnInit, OnDestroy {
  products = [];
  featuredProducts = [];
  featuredProductsLoaded = false;
  adminRecProducts = [];
  ecomCurrency = 'USD';
  recentlyViewedProd: SearchProductVO;
  isProductUsed: IsProductUsedResponse;
  featuredSuppliersBlock: SupplierBlock;
  newSuppliersBlock: SupplierBlock;
  newSuppliersLoaded = false;
  trendingSuppliersBlock: SupplierBlock;
  premiumSuppliersBlock: SupplierBlock;
  recentlyAddedProducts = 0;
  selectedEcom: EcomVO;
  organisationJsonLD: SafeHtml;
  searchboxJsonLD: SafeHtml;
  maxShippingFilter: MarketplaceFilter = { maxShippingTime: 4, autoOrder: 'true' };
  public isAuthenticated$: Observable<boolean>;
  scopes = SCOPES;

  private _unsubscribeAll: Subject<void>;

  public filterByCountryItems = [
    {
      code: 'US',
      name: 'EXPLORE_PRODUCTS.REGIONS.US',
      value: 'United States',
    },
    {
      code: 'CA',
      name: 'EXPLORE_PRODUCTS.REGIONS.CA',
      value: 'Canada',
    },
    {
      code: 'AU',
      name: 'EXPLORE_PRODUCTS.REGIONS.AU',
      value: 'Australia',
    },
    {
      code: 'GB',
      name: 'EXPLORE_PRODUCTS.REGIONS.GB',
      value: 'United Kingdom',
    },
    {
      code: 'IT',
      name: 'EXPLORE_PRODUCTS.REGIONS.IT',
      value: 'Italy',
    },
    {
      code: 'FR',
      name: 'EXPLORE_PRODUCTS.REGIONS.FR',
      value: 'France',
    },
    {
      code: 'ES',
      name: 'EXPLORE_PRODUCTS.REGIONS.ES',
      value: 'Spain',
    },
    {
      code: 'HU',
      name: 'EXPLORE_PRODUCTS.REGIONS.HU',
      value: 'Hungary',
    },
  ];

  constructor(
    public catalogSidebarService: CatalogSidebarService,
    public dialog: MatDialog,
    private fuseConfigService: FuseConfigService,
    private supplierService: SuppliersService,
    private featuredProductService: FeaturedProductService,
    private store: Store<AppState>,
    private metaTagsService: MetaTagsService,
    private navigationEventService: NavigationEventService,
    private shippingPreferencesService: ShippingPreferencesService,
    private preferenceDialogService: PreferenceDialogService
  ) {
    this._unsubscribeAll = new Subject();
    this.initFilter();
    this.initTheme();
    this.addJsonLD();
    this.preferenceDialogService.checkPreferences();
  }

  ngOnInit(): void {
    this.getFeaturedSuppliers();
    this.getCurrency();
    this.initByAuthenticatedUser();
    this.initGuest();
    this.addMetaTags();
    this.initIsAuthenticatedSubscription();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        contentContainer: 'full-width-without-padding',
        toolbar: { position: 'sticky-on-scroll' },
        footer: { topDivider: false },
      },
    };
    this.store
      .select(isAuthenticatedSelector)
      .pipe(omitNullOrUndefined(), takeUntil(this._unsubscribeAll))
      .subscribe((loggedIn) => {
        this.fuseConfigService.config = {
          layout: {
            footer: { topDivider: loggedIn },
          },
        };
      });
  }

  private initIsAuthenticatedSubscription(): void {
    this.isAuthenticated$ = this.store
      .select(isAuthenticatedSelector)
      .pipe(filter((value) => !Utils.isNullOrUndefined(value)));
  }

  private initByAuthenticatedUser(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        filter((authenticated) => authenticated),
        switchMap(() =>
          this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER)).pipe(filter((ecom) => ecom !== undefined))
        ),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((selectedEcom) => {
        this.selectedEcom = selectedEcom;
        this.catalogSidebarService.getRetailerToCatalogList();
      });
  }

  private getCurrency(): void {
    this.store.select(selectedCurrencySelector).subscribe((currency) => {
      this.ecomCurrency = currency;
    });
  }

  private initFilter(): void {
    this.store.dispatch(new ClearFilter());
  }

  getHotProducts(selectedEcom: EcomVO): void {
    this.shippingPreferencesService.hasPreferences$
      .pipe(
        omitNullOrUndefined(),
        filter((hasShippingPreferences: boolean) => isEqual(hasShippingPreferences, true)),
        switchMap(() =>
          this.featuredProductService.getFeaturedProducts(selectedEcom ? selectedEcom.ecomType : 'undefined')
        ),
        takeUntil(this.navigationEventService.onNavigationStart)
      )
      .subscribe((res): void => {
        this.featuredProducts = [];
        res.forEach((block) => {
          const productBlock = [];
          if (block.featuredProducts.length > 0) {
            block.featuredProducts.forEach((product) => {
              productBlock.push(product.product);
            });
            this.featuredProducts.push({
              title: block.title,
              products: productBlock,
            });
          }
        });
        this.featuredProductsLoaded = true;
      });
  }

  getFeaturedSuppliers(): void {
    this.shippingPreferencesService.hasPreferences$
      .pipe(
        omitNullOrUndefined(),
        filter((hasShippingPreferences: boolean) => isEqual(hasShippingPreferences, true)),
        switchMap(() => this.supplierService.getFeaturedSuppliers()),
        takeUntil(this.navigationEventService.onNavigationStart)
      )
      .subscribe((res): void => {
        this.featuredSuppliersBlock = res.find((block) => block.id === 1); // featured supplier block
        const allTrendingSupp = res.find((block) => block.id === 1); // trending suppliers block
        const allNewSupp = res.find((block) => block.id === 2); // new suppliers block
        this.premiumSuppliersBlock = res.find((block) => block.id === 3); // premium suppliers block
        this.newSuppliersBlock = allNewSupp;
        this.trendingSuppliersBlock = allTrendingSupp;
      });
  }

  public catalogStatusCheck(catalogID: number): any {
    return this.catalogSidebarService.catalogStatusCheck(catalogID);
  }

  private initGuest(): void {
    if (!this.selectedEcom) {
      this.store
        .select(isAuthenticatedSelector)
        .pipe(
          filter((authenticated) => !authenticated),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe((res) => {
          if (!res) {
            this.getHotProducts(undefined);
          }
        });
    }
  }

  // SEO tags
  private addMetaTags(): void {
    this.metaTagsService.resetToDefaults();
  }

  // SEO tags
  private addJsonLD(): void {
    this.organisationJsonLD = {
      '@context': 'https://schema.org/',
      '@type': 'OnlineBusiness',
      name: 'Syncee',
      alternateName: 'syncee',
      legalName: 'Syncee',
      description: `Syncee is a premium dropshipping and wholesale marketplace where online stores from multiple ecommerce platforms connect to sell each other's products. Retailers sell dropshipping or wholesale, focusing on sales while suppliers handle fulfillment and shipping. Syncee helps businesses build partnerships locally and globally and reach new audiences.`,
      logo: SYNCEE_BAG_LOGO_URL,
      url: 'https://syncee.com/',
      sameAs: [
        'https://www.facebook.com/synceeapp/',
        'https://www.instagram.com/syncee_co/',
        'https://www.youtube.com/@syncee_official',
        'https://www.tiktok.com/@syncee_official',
        'https://www.linkedin.com/company/syncee',
        'https://x.com/syncee_co',
      ],
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'hello@syncee.com',
      },
      email: 'hello@syncee.com',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Furedi Street 98.',
        addressLocality: 'Debrecen',
        postalCode: '4032',
        addressCountry: 'HU',
      },
      numberOfEmployees: 25,
      foundingDate: 2015,
    };

    this.searchboxJsonLD = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Syncee - Global Dropshipping & Wholesale',
      url: `https://${location.hostname}`,
      audience: {
        '@type': 'Audience',
        audienceType: RICH_TEXT_AUDIENCE_TYPES,
      },
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `https://${location.hostname}/${MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH}?category=1&search={search_term_string}&searchType=PRODUCTS`,
        },
        'query-input': 'required name=search_term_string',
      },
    };
  }
}
