<div *ngIf="settingsForm" [formGroup]="settingsForm">
  <div class="retailer-mapping-section">
    <h1>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.UPDATE_OPTIONS' | translate }}</h1>

    <div formGroupName="updateOptions" class="settings-group">
      <!--Product not in the feed-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.WHAT_SHALL' | translate }}</p>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.THIS_SETTING' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="delete" formControlName="productNotInFeed">
              <mat-option value="qty0hidden">{{
                'RETAILER_CATALOG_SETTING.SHOPRENTER.HIDE_THE_PRODUCT' | translate
              }}</mat-option>
              <!--              <mat-option value="delete">{{'RETAILER_CATALOG_SETTING.SHOPRENTER.DELETE' | translate}}</mat-option>-->
              <mat-option value="qty0">{{
                'RETAILER_CATALOG_SETTING.SHOPRENTER.SET_THE_QUANTITY' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--0 quantity feed-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.WHAT_SHALL2' | translate }}</p>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.THIS_SETTING2' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="qty0" formControlName="productQuantity0">
              <mat-option value="qty0">{{
                'RETAILER_CATALOG_SETTING.SHOPRENTER.SET_THE_QUANTITY' | translate
              }}</mat-option>
              <mat-option value="qty0hidden">{{
                'RETAILER_CATALOG_SETTING.SHOPRENTER.HIDE_THE_PRODUCT' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- 0/delete qty variant -->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.WHAT_SHALL_4' | translate }}</p>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-select value="qty0" formControlName="variantQtyUpload">
              <mat-option value="dont">{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.NOT_UPLOAD' | translate }}</mat-option>
              <mat-option value="qty0">{{
                'RETAILER_CATALOG_SETTING.SHOPRENTER.UPLOAD_WITH_ZERO_QTY' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Fields to update-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.WHAT_FIELD' | translate }}</p>
          <p>{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.SYNCEE_UPDATES' | translate }}</p>
        </div>

        <app-checkbox-selection-list
          (checkBoxesChange)="updateChanges()"
          [(checkBoxes)]="fieldsToUpdate"
        ></app-checkbox-selection-list>
      </div>

      <!--Send email-->
      <div class="settings-field">
        <div>
          <p>{{ 'RETAILER_CATALOG_SETTING.JUMPSELLER.EMAIL_NOTI' | translate }}</p>
        </div>
        <mat-form-field appearance="fill" class="mt-20">
          <mat-label>{{ 'RETAILER_CATALOG_SETTING.JUMPSELLER.EMAIL_NOTI' | translate }}</mat-label>
          <mat-select formControlName="sendEmail" disableOptionCentering panelClass="mat-select-display-change">
            <mat-option value="dont">{{ 'RETAILER_CATALOG_SETTING.SHOPRENTER.DONT_SEND' | translate }}</mat-option>
            <mat-option value="email">{{
              'RETAILER_CATALOG_SETTING.SHOPRENTER.SEND_EMAIL_WHEN' | translate
            }}</mat-option>
            <mat-option value="report">{{
              'RETAILER_CATALOG_SETTING.SHOPRENTER.SEND_EMAIL_AND' | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--Fields not to update-->
      <!--<div id="fields-not-to-update-container" class="settings-field">
          <div>
              <p>Which fields you don't want to update?</p>
              <p>Select which fields you do not want to update when you are updating your products with this
                  file.</p>
          </div>

          <app-checkbox-selection-list formControlName="fieldsNotToUpdate"
                                       [items]="fieldsNotToUpdate"></app-checkbox-selection-list>
      </div>-->
    </div>
  </div>

  <!--  <div class="retailer-mapping-section">-->
  <!--    <h1>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.BASIC_MAPPING_SETTINGS' | translate}}</h1>-->

  <!--    <div formGroupName="settings" class="settings-group">-->

  <!--      &lt;!&ndash;Currencies&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['currencies']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.CURRENCY' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['currencies'][0].id" formControlName="currency">-->
  <!--              <mat-option *ngFor="let option of shopData['currencies']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Default quantity value&ndash;&gt;-->
  <!--      <div class="settings-field">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.DEFAULT_QTY_VALUE' | translate}}: </p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <input id="defaultQtyValue" matInput formControlName="defaultQtyValue">-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Status&ndash;&gt;-->
  <!--      <div class="settings-field">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.STATUS' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="1" formControlName="published">-->
  <!--              <mat-option [value]="1">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.ENABLED' | translate}}-->
  <!--              </mat-option>-->
  <!--              <mat-option [value]="0">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.DISABLED' | translate}}-->
  <!--              </mat-option>-->
  <!--              <mat-option [value]="2">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.RAN_OUT' | translate}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Languages&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['languages']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.LANGUAGES' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['languages'][0].id" formControlName="languages">-->
  <!--              <mat-option *ngFor="let option of shopData['languages']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.code}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Taxes&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['taxes']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.TAXES' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['taxes'][0].id" formControlName="taxes">-->
  <!--              <mat-option *ngFor="let option of shopData['taxes']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Free shipping&ndash;&gt;-->
  <!--      <div class="settings-field">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.FREE_SHIPPING' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="false" formControlName="freeShipping">-->
  <!--              <mat-option [value]="true">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.FREE_SHIPPING' | translate}}-->
  <!--              </mat-option>-->
  <!--              <mat-option [value]="false">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.SHIPPING_IS' | translate}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Orderable&ndash;&gt;-->
  <!--      <div class="settings-field">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.ORDERABLE' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="1" formControlName="orderable">-->
  <!--              <mat-option [value]="1">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.YES' | translate}}-->
  <!--              </mat-option>-->
  <!--              <mat-option [value]="0">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.NO' | translate}}-->
  <!--              </mat-option>-->
  <!--              <mat-option [value]="3">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.OFFER_REQUEST' | translate}}-->
  <!--              </mat-option>-->
  <!--              <mat-option [value]="4">-->
  <!--                {{'RETAILER_CATALOG_SETTING.SHOPRENTER.ORDER' | translate}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Weight&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['weights']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.WEIGHT' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['weights'][0].id" formControlName="weights">-->
  <!--              <mat-option *ngFor="let option of shopData['weights']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Length&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['lengths']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.LENGTH' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['lengths'][0].id" formControlName="lengths">-->
  <!--              <mat-option *ngFor="let option of shopData['lengths']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;No Stock Status&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['lengths']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.NO_STOCK_STATUS' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['stockStatuses'][0].id" formControlName="noStockStatus">-->
  <!--              <mat-option *ngFor="let option of shopData['stockStatuses']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.stockStatusDescriptions.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;      &lt;!&ndash;Subtract Stock&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      <div class="settings-field" *ngIf="shopData['lengths']">&ndash;&gt;-->
  <!--      &lt;!&ndash;        <div>&ndash;&gt;-->
  <!--      &lt;!&ndash;          <p>Subtract Stock</p>&ndash;&gt;-->
  <!--      &lt;!&ndash;          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;          &lt;!&ndash;to&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;        </div>&ndash;&gt;-->

  <!--      &lt;!&ndash;        <div>&ndash;&gt;-->
  <!--      &lt;!&ndash;          <mat-form-field appearance="fill">&ndash;&gt;-->
  <!--      &lt;!&ndash;            <mat-select [value]="false" formControlName="subtractStock">&ndash;&gt;-->
  <!--      &lt;!&ndash;              <mat-option [value]="true">&ndash;&gt;-->
  <!--      &lt;!&ndash;                Yes&ndash;&gt;-->
  <!--      &lt;!&ndash;              </mat-option>&ndash;&gt;-->
  <!--      &lt;!&ndash;              <mat-option [value]="false">&ndash;&gt;-->
  <!--      &lt;!&ndash;                No&ndash;&gt;-->
  <!--      &lt;!&ndash;              </mat-option>&ndash;&gt;-->
  <!--      &lt;!&ndash;            </mat-select>&ndash;&gt;-->
  <!--      &lt;!&ndash;          </mat-form-field>&ndash;&gt;-->
  <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;      </div>&ndash;&gt;-->

  <!--      &lt;!&ndash;In Stock Status&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="shopData['stockStatuses']">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.IN_STOCK_STATUS' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['stockStatuses'][0].id" formControlName="inStockStatus">-->
  <!--              <mat-option *ngFor="let option of shopData['stockStatuses']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.stockStatusDescriptions.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Stock Settings&ndash;&gt;-->
  <!--      <div class="settings-field" *ngIf="stocks">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.STOCK_SETTINGS' | translate}}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="stocks[0].id" formControlName="stockSettings">-->
  <!--              <mat-option *ngFor="let option of stocks"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash;Stocks&ndash;&gt;-->
  <!--      <div class="settings-field" *ngFor="let item of stocks">-->
  <!--        <div>-->
  <!--          <p>{{'RETAILER_CATALOG_SETTING.SHOPRENTER.STOCK' | translate: {number: item.id} }}</p>-->
  <!--          &lt;!&ndash;<p>Please note that if you modify this setting from "Manual" to any other value, you won't be able&ndash;&gt;-->
  <!--          &lt;!&ndash;to&ndash;&gt;-->
  <!--          &lt;!&ndash;modify the inventory locations.</p>&ndash;&gt;-->
  <!--        </div>-->

  <!--        <div>-->
  <!--          <mat-form-field appearance="fill">-->
  <!--            <mat-select [value]="shopData['stockStatuses'][0].id" [formControlName]="'stock' + (item.id)">-->
  <!--              <mat-option *ngFor="let option of shopData['stockStatuses']"-->
  <!--                          [value]="option.id">-->
  <!--                {{option.stockStatusDescriptions.name}}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->
</div>
