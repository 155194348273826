<div fxLayout="column" fxLayoutGap="20px" *ngIf="isInEurope || isInAustralia || isInUS || isCanada">
  <div class="typography-body-large-2 mb-40">{{ 'SUPPLIER_TAX_SETTINGS.TITLE' | translate }}</div>
  <div fxLayout="column" fxLayoutAlign="start space-between" *ngIf="isReady; else loading">
    <form class="tax-form" fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="settingsForm">
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="selection-gap" fxLayoutAlign="start center">
          <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="5px">
            <div>
              <b>{{ 'SUPPLIER_TAX_SETTINGS.PRODUCT_TAX' | translate }}</b>
            </div>
            <div>{{ 'SUPPLIER_TAX_SETTINGS.PRODUCT_TAX_DESC' | translate }}</div>
          </div>

          <div fxFlex="1 0 0">
            <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding-except-error">
              <mat-select
                placeholder="{{ 'SUPPLIER_TAX_SETTINGS.PLACEHOLDER.SELECT_OPTION' | translate }}"
                formControlName="productHasTax"
                required
              >
                <mat-option *ngFor="let option of boolOptions" [value]="option.value">
                  {{ option.text | translate }}
                </mat-option>
              </mat-select>
              <mat-error> {{ 'SUPPLIER_TAX_SETTINGS.FORM_ERROR.REQUIRED' | translate }} </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="selection-gap" *ngIf="showAddTaxToOrder">
          <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="5px">
            <div>
              <b>
                {{ 'SUPPLIER_TAX_SETTINGS.ORDER_TAX' | translate }}
              </b>
            </div>
            <div>{{ 'SUPPLIER_TAX_SETTINGS.ORDER_TAX_DESC' | translate }}</div>
          </div>

          <div fxFlex="1 0 0">
            <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding-except-error">
              <mat-select
                placeholder="{{ 'SUPPLIER_TAX_SETTINGS.PLACEHOLDER.SELECT_OPTION' | translate }}"
                formControlName="addTaxToOrder"
              >
                <mat-option *ngFor="let option of boolOptions" [value]="option.value">
                  {{ option.text | translate }}
                </mat-option>
              </mat-select>
              <mat-error> {{ 'SUPPLIER_TAX_SETTINGS.FORM_ERROR.REQUIRED' | translate }} </mat-error>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="showTaxTable">
          <div fxLayout="row" class="selection-gap" fxLayoutAlign="start center">
            <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="5px">
              <div>
                <b>
                  {{ 'SUPPLIER_TAX_SETTINGS.SHIPPING_TAX' | translate }}
                </b>
              </div>
              <div>{{ 'SUPPLIER_TAX_SETTINGS.SHIPPING_TAX_DESC' | translate }}</div>
            </div>

            <div fxFlex="1 0 0">
              <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding-except-error">
                <mat-select
                  placeholder="{{ 'SUPPLIER_TAX_SETTINGS.PLACEHOLDER.SELECT_OPTION' | translate }}"
                  formControlName="shippingHasTax"
                >
                  <mat-option *ngFor="let option of boolOptions" [value]="option.value">
                    {{ option.text | translate }}
                  </mat-option>
                </mat-select>
                <mat-error> {{ 'SUPPLIER_TAX_SETTINGS.FORM_ERROR.REQUIRED' | translate }} </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showTaxTable">
          <div fxLayout="row" class="selection-gap" fxLayoutAlign="start center">
            <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="5px">
              <div>
                <b>
                  {{ 'SUPPLIER_TAX_SETTINGS.SHIPPING_STORE_TAX' | translate }}
                </b>
              </div>
              <div>{{ 'SUPPLIER_TAX_SETTINGS.SHIPPING_STORE_TAX_DESC' | translate }}</div>
            </div>

            <div fxFlex="1 0 0">
              <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding-except-error">
                <mat-select
                  placeholder="{{ 'SUPPLIER_TAX_SETTINGS.PLACEHOLDER.SELECT_OPTION' | translate }}"
                  formControlName="shippingHasTaxInStoreOrder"
                >
                  <mat-option *ngFor="let option of boolOptions" [value]="option.value">
                    {{ option.text | translate }}
                  </mat-option>
                </mat-select>
                <mat-error> {{ 'SUPPLIER_TAX_SETTINGS.FORM_ERROR.REQUIRED' | translate }} </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showTaxTable && (isInEurope || isInAustralia || isCanada)">
          <div fxLayout="row" class="selection-gap" fxLayoutAlign="start center">
            <div fxFlex="1 0 0" class="h5">
              {{ 'SUPPLIER_TAX_SETTINGS.DEFAULT_LOCATION' | translate }} {{ defaultLocation }}
            </div>
            <div fxFlex="1 0 0">
              <mat-form-field
                class="w-100p select-without-label input-without-padding-except-error input-with-auto-field"
                appearance="fill"
              >
                <input matInput formControlName="defaultLocationTaxRate" />
                <span matSuffix>%&nbsp;</span>
              </mat-form-field>
            </div>
          </div>
          <div class="pt-8 syncee-grey-500-fg" *ngIf="isInEurope">
            {{ 'SUPPLIER_TAX_SETTINGS.INFO' | translate }}
          </div>
        </ng-container>
      </div>
    </form>

    <ng-container *ngIf="!isInUS; else USSettings">
      <ng-container *ngIf="showTaxTable && !isInAustralia && !isCanada">
        <app-custom-table
          *ngIf="taxSettings.length !== 0"
          [fetcherLogic]="false"
          [isPaginate]="false"
          [dataSource]="dataSource"
          [displayedColumns]="displayedColumns"
          class="pt-32"
        >
          <ng-container matColumnDef="CountryName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUPPLIER_TAX_SETTINGS.TAX_TABLE_HEADERS.COUNTRY_NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let item">{{ item.countryName }}</td>
          </ng-container>

          <ng-container matColumnDef="TaxRate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUPPLIER_TAX_SETTINGS.TAX_TABLE_HEADERS.TAX_RATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let item">{{ item.taxRate }} %</td>
          </ng-container>

          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item; let i = index">
              <div fxLayoutAlign="end center">
                <button mat-icon-button (click)="deleteTaxRate(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
        </app-custom-table>
      </ng-container>

      <div fxLayoutAlign="space-between center" class="pt-20">
        <div>
          <button
            *ngIf="!isInUS && showTaxTable && !isInAustralia"
            mat-button
            class="syncee-light-blue-button"
            (click)="addTaxRate()"
          >
            {{ 'SUPPLIER_TAX_SETTINGS.ADD_TAX_RATE' | translate }}
          </button>
        </div>
        <button
          mat-button
          class="syncee-blue-button"
          [disabled]="settingsForm.invalid && showTaxTable"
          (click)="save()"
        >
          {{ 'SUPPLIER_TAX_SETTINGS.SAVE' | translate }}
        </button>
      </div>
    </ng-container>
  </div>

  <ng-template #loading>
    <!--    OLD_todo-->
    <!--    <app-loading-spinner [centered]="true"></app-loading-spinner>-->
  </ng-template>
  <ng-template #USSettings>
    <div fxLayout="row" fxLayoutAlign="start center" class="selection-gap" *ngIf="showTaxTable">
      <div fxFlex="1 0 0" class="location-settings">
        <div class="settings-column">
          <div class="typography-body-large-2 py-40">{{ 'SUPPLIER_TAX_SETTINGS.AVALARA_LABEL' | translate }}</div>
          <div class="us-settings" fxLayout="column">
            <form fxLayout="column" fxLayoutAlign="start space-between" [formGroup]="USSettingsForm">
              <mat-form-field appearance="fill">
                <mat-label>Avalara Account ID</mat-label>
                <input matInput formControlName="avalaraAccountId" />
              </mat-form-field>
              <span class="pb-4">{{ 'SUPPLIER_TAX_SETTINGS.AVALARA_LICENCE_KEY' | translate }}</span>
              <mat-form-field appearance="fill">
                <mat-label>Avalara Licence Key</mat-label>
                <input matInput formControlName="avalaraLicenceKey" />
              </mat-form-field>
              <span class="pb-4">{{ 'SUPPLIER_TAX_SETTINGS.AVALARA_COMPANY_CODE' | translate }}</span>
              <mat-form-field appearance="fill">
                <mat-label>Avalara Company Code</mat-label>
                <input matInput formControlName="avalaraCompanyCode" />
              </mat-form-field>
            </form>
          </div>
        </div>
        <div class="settings-column">
          <div class="typography-body-large-2 py-40">{{ 'SUPPLIER_TAX_SETTINGS.LOCATION_LABEL' | translate }}</div>
          <div class="us-settings" fxLayout="column">
            <form
              fxLayout="column"
              fxLayoutAlign="start space-between"
              [formGroup]="USSettingsForm"
              *ngIf="showTaxTable"
            >
              <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                <mat-label>{{ 'RETAILER_ORDER_SETTINGS.CITY' | translate }}</mat-label>
                <input matInput formControlName="city" required />
                <mat-error>
                  {{ 'RETAILER_ORDER_SETTINGS.CITY_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <!-- Address 1 -->
              <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                <mat-label>{{ 'RETAILER_ORDER_SETTINGS.ADDRESS1' | translate }}</mat-label>
                <input matInput formControlName="address1" required />
                <mat-error>
                  {{ 'RETAILER_ORDER_SETTINGS.ADDRESS1_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <!-- Address 2 -->
              <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                <mat-label>{{ 'RETAILER_ORDER_SETTINGS.ADDRESS2' | translate }}</mat-label>
                <input matInput formControlName="address2" />
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                <mat-label>{{ 'RETAILER_ORDER_SETTINGS.ZIP' | translate }}</mat-label>
                <input matInput formControlName="zip" required />
                <mat-error>
                  {{ 'RETAILER_ORDER_SETTINGS.ZIP_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                <mat-label>{{ 'RETAILER_ORDER_SETTINGS.STATE' | translate }}</mat-label>
                <mat-select formControlName="province" required>
                  <mat-option *ngFor="let state of usStates" [value]="state">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'RETAILER_ORDER_SETTINGS.STATE_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </form>
            <span>{{ 'SUPPLIER_TAX_SETTINGS.AVALARA_ADDRESS' | translate }}</span>
          </div>
        </div>
      </div>
      <div fxFlex="1 0 0"></div>
    </div>
    <div class="mt-20" fxLayoutAlign="center center">
      <button
        mat-button
        class="syncee-blue-button"
        [disabled]="USSettingsForm.invalid && showTaxTable"
        (click)="saveUSSettings()"
      >
        {{ 'SUPPLIER_TAX_SETTINGS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>
</div>
