import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import {
  InstallSynceeAppsStartAction,
  SynceeAppsActionTypes,
  UninstallSynceeAppsStartAction,
} from '../../../../../store/syncee-apps/syncee-apps.actions';
import { PlatformCardData } from '../../platform-card/platform-card.component';
import { getInstalledSynceeAppsSelector } from '../../../../../store/syncee-apps/syncee-apps.selector';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { InstalledSynceeAppDto } from '../../../../../service/user-apps/syncee-apps.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PlatformDialogComponent } from '../platform-dialog.component';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-syncee-app-dialog-actions',
  templateUrl: './syncee-app-dialog-actions.component.html',
  styleUrls: ['./syncee-app-dialog-actions.component.scss'],
})
export class SynceeAppDialogActionsComponent implements OnInit, OnDestroy {
  @Input()
  platform: PlatformCardData;
  @Input()
  isInstalled: boolean;
  installedApp$: Observable<InstalledSynceeAppDto>;
  private unsubscribeAll: Subject<void>;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<PlatformDialogComponent>,
    private readonly actions$: Actions
  ) {
    this.installedApp$ = this.store
      .select(getInstalledSynceeAppsSelector)
      .pipe(
        map((installedAppsAndGroups) =>
          installedAppsAndGroups.installedApps.find((app) => app.type === this.platform.type)
        )
      );
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        takeUntil(this.unsubscribeAll),
        ofType(SynceeAppsActionTypes.INSTALL_SYNCEE_APPS_SUCCESS, SynceeAppsActionTypes.UNINSTALL_SYNCEE_APPS_SUCCESS)
      )
      .subscribe(() => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  uninstallSynceeApp(): void {
    this.store.dispatch(new UninstallSynceeAppsStartAction([this.platform.id]));
  }

  installSynceeApp(): void {
    this.store.dispatch(new InstallSynceeAppsStartAction([this.platform.id]));
  }
}
