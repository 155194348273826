import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { CatalogId, CatalogType } from '../model/variables/catalog.variables';

export class CatalogCreateAction implements GtmAction {
  event = GtmEvent.catalog_create;

  constructor(public payload: CatalogCreateActionPayload) {}
}

export interface CatalogCreateActionPayload extends CatalogType, CatalogId {}
