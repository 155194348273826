import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TileSelectItemStyle } from '../model/tile-select-item-style';

import type { TileSelectOption } from '../model/tile-select-option';

@Component({
  selector: 'app-tile-select-item',
  templateUrl: './tile-select-item.component.html',
  styleUrls: ['./tile-select-item.component.scss'],
  imports: [CommonModule, NgOptimizedImage],
  standalone: true,
})
export class TileSelectItemComponent<T> {
  @Input()
  active: boolean;

  @Input()
  option: TileSelectOption<T>;

  @Input()
  style?: TileSelectItemStyle;

  public get containerStyle(): { [key: string]: any } {
    return {
      'background-color': this.active ? this.style.activeBackgroundColor : this.style.backgroundColor,
      'border-color': this.active ? this.style.activeBorderColor : this.style.borderColor,
      'border-radius': this.style.borderRadius,
    };
  }
}
