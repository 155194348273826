import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'roleTranslation',
  standalone: true,
})
export class RoleTranslationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number): unknown {
    return this.translateService.instant(`ROLES.${this.getKey(value)}`);
  }

  private getKey(role: number): string {
    switch (role) {
      case 1:
        return 'SUPPLIER';
      case 2:
        return 'RETAILER';
      case 3:
        return 'GLOBAL_ADMIN';
    }
  }
}
