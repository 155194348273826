import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SKIP_FUSE_UPDATE } from '@fuse/services/config.service';
import { ImageWithAlt } from '../../../vo/image-with-alt';

@Component({
  selector: 'app-banner-cta',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, RouterModule, NgOptimizedImage],
  templateUrl: './banner-cta.component.html',
  styleUrls: ['./banner-cta.component.scss'],
})
export class BannerCtaComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() buttonRedirectURL: string;
  @Input() linkDescription: string;
  @Input() linkTitle: string;
  @Input() linkRedirectURL: string;
  @Input() image: ImageWithAlt;
  @Input() bgImageURL: string;
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;

  readonly skipFuseUpdate = SKIP_FUSE_UPDATE;

  constructor(private router: Router) {}

  navigateToRegistration(): void {
    this.router.navigate(['/registration/retailer', { state: { skipFuseConfigUpdate: true } }]);
  }

  ngOnInit(): void {}
}
