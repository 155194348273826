import { Action } from '@ngrx/store';

export enum ActionTypes {
  setValue = 'setValue',
  increment = 'increment',
  decrement = 'decrement',
}

export class SetValue implements Action {
  readonly type = ActionTypes.setValue;

  constructor(public payload: { value: number }) {}
}

export class Add implements Action {
  readonly type = ActionTypes.increment;
}

export class Remove implements Action {
  readonly type = ActionTypes.decrement;
}

export type ActionsUnion = SetValue | Add | Remove;
