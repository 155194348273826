<div class="cancel-dialog-plan-info-container" *ngIf="planToShow; else spinner">
  <div class="typography-body-1 step-title">
    {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.TITLE' | translate : { name: userName } }}
  </div>
  <div class="info-boxes">
    <mat-card class="info-box">
      <h5 class="info-box-title title-divider">
        {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.YOU_LL_LOSE.TITLE' | translate }}
      </h5>
      <div class="info-box-content" fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="10px">
        <div fxLayoutGap="8px" fxLayoutAlign="start center" *ngFor="let lostFeature of lostFeatures">
          <app-list-markers [type]="'close'"></app-list-markers>
          <div class="typography-body-1">{{ lostFeature | translate }}</div>
        </div>
      </div>
    </mat-card>
    <mat-card class="info-box">
      <div class="info-box-title">
        {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.TITLE' | translate }}
      </div>
      <mat-card class="info-box-content">
        <div class="plan-name">
          <span class="typography-overline">
            {{
              planToShow.type === 'rmp'
                ? ('BILLING.SUBSCRIPTION.PLANS.TYPE.MARKETPLACE' | translate)
                : ('BILLING.SUBSCRIPTION.PLANS.TYPE.DATAFEED' | translate)
            }}
          </span>
          <h3>{{ planToShow.planName }}</h3>
        </div>
        <div class="plan-price-wrapper">
          <h4 class="cycle-type">
            {{
              (planToShow.period === 'monthly'
                ? 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.MONTHLY'
                : 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.ANNUAL'
              ) | translate
            }}
          </h4>
          <h3>
            ${{ planToShow.price }}
            {{
              (planToShow.period === 'monthly'
                ? 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.PER_MONTH'
                : 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.PER_YEAR'
              ) | translate
            }}
          </h3>
        </div>
        <div class="plan-details-wrapper typography-body-1">
          <div>
            <span>{{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.PRODUCT_LIMIT' | translate }}:</span>
            {{ formatNumber(planToShow.productLimit) }}
          </div>
          <div>
            <span
              >{{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.MANAGED_PRODUCTS' | translate }}:</span
            >
            {{ formatNumber(planToShow.managedProducts) }}
          </div>
          <div *ngIf="planToShow.catalogLimit">
            <span>{{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.CATALOG_LIMIT' | translate }}:</span>
            {{ formatNumber(planToShow.catalogLimit) }}
          </div>
          <div *ngIf="planToShow.catalogCreated">
            <span>{{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.STEPS.INFO.PLAN_DETAILS.CATALOG_CREATED' | translate }}:</span>
            {{ formatNumber(planToShow.catalogCreated) }}
          </div>
        </div>
      </mat-card>
    </mat-card>
  </div>
</div>
<ng-template #spinner>
  <div fxLayoutAlign="center center">
    <mat-spinner diameter="60"></mat-spinner>
  </div>
</ng-template>
