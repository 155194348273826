import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[scrollToTop]',
  standalone: true,
})
export class ScrollToTopDirective implements AfterViewInit, OnDestroy {
  private unsubscribeAll = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute, private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (!this.route.snapshot.fragment) {
          setTimeout(() => {
            this.scrollToTop();
          }, 0);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private scrollToTop(): void {
    this.elementRef.nativeElement['scrollTop'] = 0;
  }
}
