import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Action } from 'app/service/authorization-ecom/authorization-ecom.service';
import { GuestOpenRegistrationDirective } from 'app/shared/directives/guest-open-registration.directive';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { AuthDialogType } from '../../../main/authentication/helpers/authentication-dialog-type';
import { ChatService } from '../../../service/chat/chat.service';
import { ChatUser } from '../../../service/chat/model/chat-models';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { SuppliersService } from '../../../service/suppliers/suppliers.service';
import { AuthenticationDirective } from '../../directives/authentication.directive';
import { HasPermissionButtonDirective } from '../../directives/has-permission-button.directive';

@Component({
  selector: 'app-contact-supplier-button',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    AuthenticationDirective,
    MatButtonModule,
    HasPermissionButtonDirective,
    TranslateModule,
    GuestOpenRegistrationDirective,
  ],
  templateUrl: './contact-supplier-button.component.html',
  styleUrls: ['./contact-supplier-button.component.scss'],
})
export class ContactSupplierButtonComponent implements OnInit {
  @Input() supplierUserId: number;
  @Input() withoutIcon = false;
  @Input() buttonType = '';
  @Input() buttonStyles = '';
  @Input() selectedEcom: EcomVO;
  CHAT_ACCESS = Action.CHAT_ACCESS;
  isAuthenticated: boolean;
  private readonly unsubscribeAll = new Subject<void>();

  constructor(
    private chatService: ChatService,
    private suppliersService: SuppliersService,
    private marketplaceEcomService: MarketplaceEcomService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (!this.selectedEcom) {
      this.getEcom();
    }
  }

  private getEcom(): void {
    this.marketplaceEcomService
      .getEcomWithSubscriptionFromStore()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((ecom) => {
        this.selectedEcom = ecom;
      });
  }

  public contactSupplierDialogOpen(): void {
    forkJoin([
      this.chatService.getCurrUser(),
      this.suppliersService.getSupplierChatDetailsByUser(this.supplierUserId),
    ]).subscribe(([user, details]) => {
      const recipientUser: ChatUser = {
        id: parseInt(details.userId, 10),
        name: details.companyName,
        email: details.userEmail,
        avatar: details.logo,
      };
      this.chatService.openChatDialog(recipientUser, user).subscribe();
    });
  }

  protected readonly AuthDialogType = AuthDialogType;
}
