<ng-container *ngIf="contents$ | async as contents">
  <ng-container *ngIf="!!contents.length">
    <ngx-slick-carousel [@animateStagger]="{ value: '50' }" [config]="config">
      <ng-container>
        <ng-container *ngFor="let content of contents">
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            [ngClass]="content.theme"
            ngxSlickItem
            class="toolbar-call-to-action cursor-pointer background"
            (click)="handleActionClick(content.action)"
          >
            <div class="text-color" [innerHTML]="content.title | sanitizeHTML"></div>
          </div>
        </ng-container>
      </ng-container>
    </ngx-slick-carousel>
  </ng-container>
</ng-container>
