import { Directive } from '@angular/core';

@Directive({
  selector: '[overflowDirective]',
  standalone: true,
  host: {
    '[style.overflow]': '"auto"',
  },
})
export class OverflowDirective {
  constructor() {}
}
