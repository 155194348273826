import { Component, OnInit } from '@angular/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { HasCartService } from '../../../../../service/has-cart/has-cart.service';

@Component({
  selector: 'app-guest-actions',
  templateUrl: './guest-actions.component.html',
  styleUrls: ['./guest-actions.component.scss'],
})
export class GuestActionsComponent implements OnInit {
  constructor(private authManagerService: AuthenticationManagerService, public hasCartService: HasCartService) {}

  ngOnInit(): void {}

  emitLoginDialog(): void {
    this.authManagerService.emitOpenLoginDialog();
  }

  emitRegistrationDialog(): void {
    this.authManagerService.emitOpenRegistrationDialog();
  }
}
