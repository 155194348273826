<app-custom-dialog-wrapper [headerLeftRef]="headerLeftRef" [actionBarRef]="actionBarRef">
  <div class="px-20">
    <app-tile-select
      *ngIf="!!options"
      [options]="options"
      [columns]="'5'"
      rowHeight="85px"
      gridGap="12px"
      (onChange)="selectedEcom = $event"
      borderRadius="7px"
    ></app-tile-select>
  </div>
</app-custom-dialog-wrapper>

<ng-template #headerLeftRef>
  <div class="h3 syncee-grey-800-fg text-center">
    {{ 'NEED_STORE_DIALOG.DESCRIPTION' | translate }}
  </div>
</ng-template>

<ng-template #actionBarRef>
  <div fxLayoutAlign="center center" class="px-20 pt-20">
    <button mat-flat-button color="primary" (click)="handleContinue()">
      {{ 'NEED_STORE_DIALOG.CONTINUE' | translate }}
    </button>
  </div>
</ng-template>
