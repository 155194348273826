<div
  *ngIf="!!reviews && reviews.length > 0"
  [fxLayoutGap]="gap + 'px'"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap.lt-sm="4px"
  class="reviews"
>
  <a *ngFor="let review of reviews" [href]="review.link" target="_blank">
    <img
      [ngSrc]="review.src"
      [alt]="review.alt"
      [width]="width"
      [height]="height"
      [style.width.px]="width"
      [style.height.px]="height"
    />
  </a>
</div>
