import { Component, Input, OnInit } from '@angular/core';
import { PlatformCardData } from '../platform-card/platform-card.component';

@Component({
  selector: 'app-platform-list-logo',
  templateUrl: './platform-list-logo.component.html',
  styleUrls: ['./platform-list-logo.component.scss'],
})
export class PlatformListLogoComponent implements OnInit {
  @Input()
  logoType: PlatformLogoType;
  @Input()
  platform: PlatformCardData;
  @Input()
  size: string;

  constructor() {}

  ngOnInit(): void {}
}

export type PlatformLogoType = 'FULL' | 'ICON';
