import { RolesEnum, ROLE_TYPE } from 'app/vo/roles/roles';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function getHighestPriorityRole(
  ignoreAdmin = false
): (source: Observable<ROLE_TYPE[]>) => Observable<ROLE_TYPE> {
  return (source: Observable<ROLE_TYPE[]>): Observable<ROLE_TYPE> =>
    source.pipe(
      filter((roles) => !!roles && roles.length > 0),
      map((roles) => {
        if (!ignoreAdmin && roles.includes(RolesEnum.ADMIN)) {
          return RolesEnum.ADMIN;
        } else if (roles.includes(RolesEnum.SUPPLIER)) {
          return RolesEnum.SUPPLIER;
        } else {
          return RolesEnum.RETAILER;
        }
      })
    );
}
