import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, of, skip, switchMap } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CategoryService } from '../product-search/category.service';
import { SetCategoriesAction, SetFlattenCategoriesAction } from '../../store/category/category.action';
import { AppState } from '../../app.state';
import { ProductSearchService } from '../product-search/product-search.service';
import { SetAdvancedLanguagesAction } from '../../store/product-search/product-search.action';
import { CategoryVo } from '../../vo/category-vo';
import { ChatService } from '../chat/chat.service';
import { Currencies, CurrencyService } from '../currency-service/currency.service';
import { SetCurrenciesAction } from '../../store/currency/currency.action';
import { Utils } from 'app/utils/utils';
import { AuthorizationEcomService } from '../authorization-ecom/authorization-ecom.service';
import { GettingStartedStepsService } from '../../main/getting-started-new/service/getting-started-steps.service';
import { gettingStartedUncompletedStepsSelector } from '../../store/getting-started/getting-started.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { PreferenceDialogService } from '../preference/preference-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class BootstrapService {
  categoriesStored = new BehaviorSubject<boolean>(false);
  languagesStored = new BehaviorSubject<boolean>(false);
  private loggedInFetched = false;

  constructor(
    private store: Store<AppState>,
    private categoryService: CategoryService,
    private productSearchService: ProductSearchService,
    private chatService: ChatService,
    private currencyService: CurrencyService,
    private authorizationEcomService: AuthorizationEcomService,
    private gettingStartedStepsService: GettingStartedStepsService
  ) {}

  init(): void {
    forkJoin(
      this.categoryService.getCategories([1], true).pipe(catchError(() => of(null))),
      this.productSearchService.getLanguagesForFilters().pipe(catchError(() => of(null))),
      this.currencyService.getRetailerCurrencies().pipe(catchError(() => of({ USD: 1 })))
    ).subscribe(([categories, languages, currencies]) => {
      this.handleCategory(categories);
      this.handleLanguages(languages);
      this.handleCurrencies(currencies);
    });
  }

  initAfterUserLoggedIn(): void {
    if (!this.loggedInFetched) {
      this.loggedInFetched = true;
      this.initChat();
    }
  }

  handleUserLogout(): void {
    this.loggedInFetched = false;
  }

  ADDBootstrap(): void {
    this.authorizationEcomService
      .subscribeDialogObservable()
      .pipe(
        switchMap(() =>
          this.store.select(gettingStartedUncompletedStepsSelector, RolesEnum.RETAILER).pipe(
            take(1),
            switchMap((steps) =>
              this.gettingStartedStepsService.addStepsToUser(steps.map((step) => ({ skip: false, stepId: step.id })))
            )
          )
        )
      )
      .subscribe();
  }

  private handleCategory(categoriesArray: CategoryVo[]): void {
    if (!Utils.isNullOrUndefined(categoriesArray)) {
      const categories = categoriesArray[0];
      this.store.dispatch(new SetCategoriesAction(categories));
      this.store.dispatch(new SetFlattenCategoriesAction(this.categoryService.flatCategories(categories)));
    }
    this.categoriesStored.next(true);
  }

  private handleLanguages(languages: string[]): void {
    this.store.dispatch(new SetAdvancedLanguagesAction(languages));
    this.languagesStored.next(true);
  }

  private handleCurrencies(currencies: Currencies): void {
    this.store.dispatch(new SetCurrenciesAction(currencies));
  }

  private initChat(): void {
    this.chatService.initUnseenMessagePolling().subscribe(() => {});
  }
}
