<mat-card *ngIf="withCard; else withoutcard">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-card>

<ng-template #withoutcard>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
  <div fxLayout="column" fxLayoutGap="12px">
    <h4 class="mt-0 no-wrap">
      {{ title }}
    </h4>
    <div [ngClass]="'w-' + width + '-p'">
      <app-skeleton-text [lines]="4"></app-skeleton-text>
    </div>
  </div>
</ng-template>
