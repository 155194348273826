import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FileUploadResponseVo } from '../../../taskwizard/addfiles/vo/file-upload-response-vo';
import { Constants } from '../../../../utils/Constants';
import { AuthenticationService } from '../../../../service/authentication/authentication.service';
import { RestService } from '../../../../service/rest/rest.service';
import { takeWhile } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { FileChangeVo } from '../file-change-vo';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../notification/notification.service';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-change-file',
  templateUrl: './change-file.component.html',
  styleUrls: ['./change-file.component.scss'],
})
export class ChangeFileComponent implements OnInit {
  private _inputSync: ReplaySubject<FileChangeVo> = new ReplaySubject<FileChangeVo>(1);

  @Input() set inputs(value: FileChangeVo) {
    this._inputSync.next(value);
  }

  @Output() callBack = new EventEmitter<FileUploadResponseVo>();
  @Output() downloadFileActive = new EventEmitter<boolean>();
  @Output() selectedTypeCallBack = new EventEmitter<string>();

  currentTaskId: number;

  postUrl = 'TaskFileUploadService/uploadFile';
  files: File[] = [];

  isFileSelected = false;
  selectedFile: File;
  selectedFileConvertedSize: string;

  isUploadInProgress = false;

  validComboDrag;

  items = [];

  fileExtensions = Constants.UPLOAD_FILE_EXTENSIONS;

  selectedFileExtension: string = Constants.EXTENSION_AUTO;

  isParamsPassed = false;
  selectedFileName: string;
  fileId: number;
  disableFileTypeSelector = false;

  disableUploadBtn = false;

  constructor(
    private auth: AuthenticationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private restService: RestService
  ) {}

  ngOnInit(): void {
    this._inputSync.pipe(takeWhile(() => !this.isParamsPassed)).subscribe((res: FileChangeVo) => {
      this.postUrl = res.uploadUrl;
      this.currentTaskId = res.taskId;

      if (!Utils.isNullOrUndefined(res.selectedType)) {
        const isTypeExist = Constants.UPLOAD_FILE_EXTENSIONS.some(function (obj): boolean {
          return obj.type === res.selectedType;
        });
        if (isTypeExist) {
          this.selectedFileExtension = res.selectedType;
        }
      }
      if (!Utils.isNullOrUndefined(res.remotePath)) {
        this.selectedFileName = res.remotePath;
      }
      if (!Utils.isNullOrUndefined(res.fileId)) {
        this.fileId = res.fileId;
      }
      this.disableFileTypeSelector = res.isUpdate;
      this.isParamsPassed = true;
    });
  }

  setFileToUpload(files: File[]): void {
    if (typeof files !== 'undefined' && files != null && files.length != null && files.length > 0) {
      this.isFileSelected = true;
      this.selectedFile = files[0];
      this.selectedFileName = this.selectedFile.name;
      this.selectedFileConvertedSize = this.bytesToSize(this.selectedFile.size);
      this.files = [];
    }
  }

  bytesToSize(bytes): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024,
      dm = 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFiles(file: File): void {
    this.disableUploadBtn = true;
    this.isUploadInProgress = true;
    const formDatas = new FormData();
    formDatas.append('taskId', this.currentTaskId + '');
    formDatas.append('fileToUpload', file, file.name);
    if (this.selectedFileExtension !== 'auto') {
      formDatas.append('extension', this.selectedFileExtension);
    }
    if (!Utils.isNullOrUndefined(this.fileId)) {
      formDatas.append('fileId', this.fileId.toString());
    }
    const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
    this.downloadFileActive.emit(true);
    this.restService.post(this.postUrl, formDatas, requestOptions).subscribe(
      (res) => {
        this.notificationService.success(
          this.translateService.instant('TASKWIZARD.ADDFILES.FILE_UPLOAD.FILE_CHANGED_SUCCESS')
        );
        const resData = res.getFirstData();
        const response = new FileUploadResponseVo();
        response.status = true;
        response.taskId = resData.pmsFile.taskId;
        response.fileId = resData.pmsFile.fileId;
        response.remotePath = resData.pmsFile.remotePath;
        response.detectedType = resData.detectedType;
        this.fileId = resData.pmsFile.fileId;
        this.selectedFileExtension = resData.detectedType;
        this.isUploadInProgress = false;
        this.disableFileTypeSelector = false;
        this.disableUploadBtn = false;
        this.downloadFileActive.emit(false);
        this.callBack.emit(response);
      },
      (error) => {
        console.log(error);
        this.isUploadInProgress = false;
        this.disableUploadBtn = false;
        this.downloadFileActive.emit(false);
      }
    );
  }

  selectFileTypeChanged(event): void {
    this.selectedTypeCallBack.emit(event);
  }
}
