<app-stepper-dialog-header
  [title]="'BILLING.SUBSCRIPTION.CANCEL_DIALOG.TITLE'"
  [maxStepNumber]="3"
  [currentStepIndex]="stepperIndex"
></app-stepper-dialog-header>
<mat-dialog-content>
  <app-cancel-dialog-stepper [selectedIndex]="stepperIndex">
    <cdk-step
      ><app-cancel-dialog-plan-info
        [selectedEcom]="data.selectedEcom"
        [selectedPlanType]="selectedPlanType"
      ></app-cancel-dialog-plan-info
    ></cdk-step>
    <cdk-step
      ><app-cancel-dialog-subscribe
        [selectedEcom]="data.selectedEcom"
        [selectedPlanType]="selectedPlanType"
        (closeDialogWithoutCancel)="closeWithoutCancel()"
        (closeDialogSetPlan)="closeWithSetAnotherPlan($event.isAnnual, $event.planId)"
      ></app-cancel-dialog-subscribe
    ></cdk-step>
    <cdk-step
      ><app-cancel-dialog-reason
        [selectedEcom]="data.selectedEcom"
        [selectedPlanType]="selectedPlanType"
        [nextClicked]="nextClicked"
        (goToNextStep)="handleReasonStep($event)"
      ></app-cancel-dialog-reason
    ></cdk-step>
  </app-cancel-dialog-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  <div
    class="w-100p"
    fxLayoutAlign="space-between center"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutGap="0"
    fxLayoutGap.lt-sm="4px"
  >
    <div fxLayoutAlign="center center" fxLayoutGap="16px">
      <button mat-stroked-button (click)="closeWithoutCancel()" class="close-button">
        {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.CLOSE' | translate }}
      </button>
      <div fxLayoutAlign="center center" fxLayoutGap="8px" fxLayout.lt-sm="column">
        <div class="typography-body-1 questions-details">
          {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.ANY_QUESTION' | translate }}
        </div>
        <button mat-stroked-button class="contact-button" (click)="handleContactUsClicked()">
          {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.CONTACT' | translate }}
        </button>
      </div>
    </div>
    <div fxFlex="0 0 auto" fxFlex.lt-sm="1 0 0" fxLayoutAlign="center center" fxLayoutGap="4px" class="nav-buttons">
      <button mat-stroked-button *ngIf="!isFirstStep" (click)="goToPrevStep()" class="close-button">
        {{ 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.PREVIOUS' | translate }}
      </button>
      <button mat-button class="syncee-blue-button" (click)="handleNextStepClick()">
        {{ steps[stepperIndex].nextButtonText | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
