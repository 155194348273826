import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { SearchProductVO } from 'app/vo/search-product-vo';

@Pipe({
  name: 'getPrice',
  standalone: true,
  pure: true,
})
export class GetPricePipe implements PipeTransform {
  transform(product: SearchProductVO): number {
    return Utils.getPrice(product.VARIANTS[0]?.PRICE, product.VARIANTS[0]?.DISCOUNTED_PRICE);
  }
}
