import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnboardItem } from '../onboard.service';
import { Observable, switchMap } from 'rxjs';
import { SetupGuideSnippet } from '../../../../main/setup-guide/model/setup-guide-snippet';
import { SetupGuideService } from '../../../../main/setup-guide/service/setup-guide.service';
import { ONBOARD_EARN_CREDIT_AMOUNT } from '../constant';
import { ROLE_TYPE, RolesEnum } from '../../../../vo/roles/roles';
import { share } from 'rxjs/operators';
import { KEY_RETAILER, KEY_SUPPLIER } from '../../../../main/setup-guide/config/snippet-keys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-onboard-dialog',
  templateUrl: './onboard-dialog.component.html',
  styleUrls: ['./onboard-dialog.component.scss'],
})
export class OnboardDialogComponent implements OnInit {
  snippet: Observable<SetupGuideSnippet>;
  allStepsCompleted: Observable<boolean>;
  role$: Observable<ROLE_TYPE>;
  protected readonly amount = ONBOARD_EARN_CREDIT_AMOUNT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OnboardDialogData,
    private setupGuideService: SetupGuideService,
    private dialogRef: MatDialogRef<OnboardDialogComponent>
  ) {
    this.role$ = this.setupGuideService.getRoleForSetupGuideSnippet().pipe(share());
    this.allStepsCompleted = this.setupGuideService.getAllStepsCompleted();
  }

  ngOnInit(): void {
    this.getSnippet();
  }

  getSnippet(): void {
    this.snippet = this.role$.pipe(
      untilDestroyed(this),
      switchMap((role) =>
        this.setupGuideService.getSetupGuideSnippetObservable(role === RolesEnum.SUPPLIER ? KEY_SUPPLIER : KEY_RETAILER)
      )
    );
  }

  onDismissed(): void {
    this.dialogRef.close();
  }

  protected readonly ONBOARD_EARN_CREDIT_AMOUNT = ONBOARD_EARN_CREDIT_AMOUNT;
  protected readonly RolesEnum = RolesEnum;
}

export interface OnboardDialogData {
  selectedOnboard: OnboardItem;
}
