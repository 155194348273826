import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { OrdersInfoComponent } from './orders-info/orders-info.component';
import { ContactUserComponent } from './contact-user/contact-user.component';

import { CurrencyTransformPipe } from '../../pipes/currency-transform-pipe.pipe';
import { HasPermissionButtonDirective } from '../../directives/has-permission-button.directive';
import { ButtonWithIconDirective } from '../../directives/button-with-icon.directive';

@NgModule({
  declarations: [OrdersInfoComponent, ContactUserComponent],
  exports: [OrdersInfoComponent, ContactUserComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    CurrencyTransformPipe,
    MatIconModule,
    MatButtonModule,
    HasPermissionButtonDirective,
    ButtonWithIconDirective,
  ],
})
export class MySuppliersRetailersModule {}
