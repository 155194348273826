<div class="autocomplete-container">
  <ng-container *ngIf="!needTrendingSuggestion; else trendingSuggestion">
    <div class="terms-list">
      <div
        *ngFor="let term of autocompleteCategoriesFromApi; let i = index"
        class="term"
        (click)="handleAutocompleteTermClicked(term.filter, term.helpers.category)"
        [class.selected]="
          (selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) && selectedIndexViaKeyboard === i
        "
        [id]="'term_' + i"
      >
        <div [innerHTML]="term.label"></div>
      </div>
      <div
        *ngFor="let term of autocompleteLocations; let i = index"
        class="term"
        (click)="handleAutocompleteTermClicked(term.filter)"
        [class.selected]="
          (selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) &&
          selectedIndexViaKeyboard === i + autocompleteCategoriesFromApi.length
        "
        [id]="'term_' + (i + autocompleteCategoriesFromApi.length)"
      >
        <div [innerHTML]="term.label"></div>
      </div>
      <div
        *ngFor="let term of autocompleteTerms; let i = index"
        class="term"
        (click)="handleAutocompleteTermClicked(term.filter)"
        [class.selected]="
          (selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) &&
          selectedIndexViaKeyboard === i + autocompleteLocations.length + autocompleteCategoriesFromApi.length
        "
        [id]="'term_' + (i + autocompleteLocations.length + autocompleteCategoriesFromApi.length)"
      >
        <div [innerHTML]="term.label"></div>
      </div>
    </div>
    <div class="terms-list" *ngIf="autocompleteSuppliers && autocompleteSuppliers.length">
      <div class="list-title">{{ 'TOOLBAR.PRODUCT_SEARCH.SUPPLIERS' | translate }}</div>
      <div *ngFor="let supplier of autocompleteSuppliers; let i = index">
        <div
          class="supplier term"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          (click)="handleSupplierClicked(supplier)"
          [class.selected]="
            (selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) &&
            selectedIndexViaKeyboard ===
              i + autocompleteLocations.length + autocompleteCategoriesFromApi.length + autocompleteTerms.length
          "
          [id]="
            'term_' +
            (i + autocompleteLocations.length + autocompleteCategoriesFromApi.length + autocompleteTerms.length)
          "
        >
          <div class="image-container">
            <img [src]="supplier.userId | supplierLogoFromUserid" />
          </div>
          <div>{{ supplier.companyName }}</div>
        </div>
      </div>
    </div>
    <div class="terms-list" *ngIf="autocompleteCategories && autocompleteCategories.length">
      <div class="list-title">{{ 'TOOLBAR.PRODUCT_SEARCH.CATEGORIES' | translate }}</div>
      <div *ngFor="let category of autocompleteCategories; let i = index">
        <div
          class="supplier term"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          (click)="handleCategoryClicked(category)"
          [class.selected]="
            (selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) &&
            selectedIndexViaKeyboard ===
              i +
                autocompleteLocations.length +
                autocompleteCategoriesFromApi.length +
                autocompleteTerms.length +
                autocompleteSuppliers.length
          "
          [id]="
            'term_' +
            (i +
              autocompleteLocations.length +
              autocompleteCategoriesFromApi.length +
              autocompleteTerms.length +
              autocompleteSuppliers.length)
          "
        >
          <div>{{ getCategoryName(category) }}</div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-custom-divider></app-custom-divider>
  <ng-container [ngTemplateOutlet]="refer"></ng-container>
</div>

<ng-template #refer>
  <div class="refer" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon class="s-20 syncee-primary-700-fg">handshake</mat-icon>
    <div class="typography-subtitle-small" (click)="handleReferralClick()">
      {{ 'TOOLBAR.PRODUCT_SEARCH.REFER' | translate : { amount: 50 } }}
    </div>
  </div>
</ng-template>

<ng-template #trendingSuggestion>
  <div class="terms-list" *ngIf="termsHistory && termsHistory.length > 0">
    <div class="list-title">{{ 'TOOLBAR.PRODUCT_SEARCH.SEARCH_HISTORY' | translate }}</div>
    <div
      *ngFor="let term of termsHistory; let i = index"
      class="term"
      [class.selected]="(selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) && selectedIndexViaKeyboard === i"
      [id]="'term_' + i"
      fxLayoutAlign="center center"
    >
      <div fxFlex="1 0 0" class="history-term" (click)="handleTermClicked(term)">
        {{ term }}
      </div>
      <mat-icon class="s-20 syncee-grey-400-fg" (click)="deleteHistoryTerm(term)">close</mat-icon>
    </div>
  </div>
  <div class="terms-list">
    <div class="list-title">{{ 'TOOLBAR.PRODUCT_SEARCH.TRENDING_SEARCHES' | translate }}</div>
    <div
      *ngFor="let term of trendingTerms; let i = index"
      class="term"
      (click)="handleTermClicked(term)"
      [class.selected]="
        (selectedIndexViaKeyboard || selectedIndexViaKeyboard === 0) &&
        selectedIndexViaKeyboard === i + termsHistory.length
      "
      [id]="'term_' + (i + termsHistory.length)"
    >
      {{ term }}
    </div>
  </div>
</ng-template>
