import { Injectable } from '@angular/core';
import { FilterGroup } from '../model/filter-group';
import { v4 as uuidv4 } from 'uuid';
import { FilterGroupConditionEnum } from '../model/filter-group-condition';
import { GroupConditionChange } from '../model/helper/group-condition-change';
import { FilterItemConditionChange } from '../model/helper/filter-item-condition-change';
import { FilterItemChange } from '../model/helper/filter-item-change';
import { FilterItem } from '../model/filter-item';

@Injectable()
export class FilterBaseModifiersService {
  createNewGroup(): FilterGroup {
    return {
      conditions: [],
      children: [
        {
          id: uuidv4().toString(),
          filterItems: [
            { id: uuidv4().toString(), fieldStructures: [], field: null, values: [], condition: null, locked: false },
          ],
          children: [],
          conditions: [],
        },
      ],
      id: uuidv4().toString(),
      filterItems: [],
    };
  }

  createNewEmptyGroup(): FilterGroup {
    return {
      conditions: [],
      children: [],
      id: uuidv4().toString(),
      filterItems: [],
    };
  }

  addNewItemToTree(
    newNode: FilterGroup,
    matchingGroupId: string,
    defaultValues?: FilterItem,
    addCondition = true
  ): FilterGroup {
    if (newNode.id === matchingGroupId) {
      newNode.filterItems = [
        ...newNode.filterItems,
        defaultValues ?? {
          field: null,
          id: uuidv4().toString(),
          values: [],
          fieldStructures: null,
          condition: null,
          locked: false,
        },
      ];
      if (addCondition) {
        newNode.conditions = [
          ...newNode.conditions,
          newNode.conditions.length > 0
            ? newNode.conditions[newNode.conditions.length - 1]
            : FilterGroupConditionEnum.AND,
        ];
      }
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.addNewItemToTree(child, matchingGroupId, defaultValues, addCondition);
      });
    }
    return newNode;
  }

  addNewGroupToTree(newNode: FilterGroup, matchingGroupId: string, defaultId?: string): FilterGroup {
    if (newNode.id === matchingGroupId) {
      if (newNode.children.length > 1) {
        newNode.conditions = [...newNode.conditions, FilterGroupConditionEnum.AND];
      }
      newNode.children = [
        ...newNode.children,
        {
          children: [],
          id: defaultId ?? uuidv4().toString(),
          filterItems: [
            { id: uuidv4().toString(), fieldStructures: [], field: null, values: [], condition: null, locked: false },
          ],
          conditions: [],
        },
      ];
    }
    if (!!newNode.children && newNode.children.length > 0 && newNode.id !== matchingGroupId) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.addNewGroupToTree(child, matchingGroupId);
      });
    }
    return newNode;
  }

  addNewGroupToTreeWithoutItem(
    newNode: FilterGroup,
    matchingGroupId: string,
    defaultId?: string,
    addCondition = true
  ): FilterGroup {
    if (newNode.id === matchingGroupId) {
      if (newNode.children.length > 1 && addCondition) {
        newNode.conditions = [...newNode.conditions, FilterGroupConditionEnum.AND];
      }
      newNode.children = [
        ...newNode.children,
        {
          children: [],
          id: defaultId ?? uuidv4().toString(),
          filterItems: [],
          conditions: [],
        },
      ];
    }
    if (!!newNode.children && newNode.children.length > 0 && newNode.id !== matchingGroupId) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.addNewGroupToTreeWithoutItem(child, matchingGroupId, defaultId, addCondition);
      });
    }
    return newNode;
  }

  deleteItemFromTree(newNode: FilterGroup, matchingItemId: string): FilterGroup {
    if (newNode.filterItems.some((item) => item.id === matchingItemId)) {
      const indexesToDelete = newNode.filterItems
        .map((item, index) => (item.id === matchingItemId ? index : null))
        .filter((index) => !!index || index === 0);
      newNode.filterItems = newNode.filterItems.filter((item, index) => !indexesToDelete.includes(index));
      if (newNode.filterItems.length > 1) {
        const newIndexesToDelete = indexesToDelete.includes(newNode.filterItems.length - 1)
          ? [...indexesToDelete, newNode.filterItems.length - 1]
          : indexesToDelete;
        newNode.conditions = newNode.conditions.filter((condition, index) => !newIndexesToDelete.includes(index));
      } else {
        newNode.conditions = [];
      }
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.deleteItemFromTree(child, matchingItemId);
      });
    }
    newNode.children = newNode.children.filter((child) => child.filterItems.length !== 0);
    return newNode;
  }

  deleteGroupFromTree(newNode: FilterGroup, matchingGroupId: string): FilterGroup {
    if (newNode.children.some((item) => item.id === matchingGroupId)) {
      const indexesToDelete = newNode.children
        .map((item, index) => (item.id === matchingGroupId ? index : null))
        .filter((index) => !!index || index === 0);
      newNode.children = newNode.children.filter((item, index) => !indexesToDelete.includes(index));
      if (newNode.children.length > 1) {
        const newIndexesToDelete = indexesToDelete.includes(newNode.filterItems.length - 1)
          ? [...indexesToDelete, newNode.filterItems.length - 1]
          : indexesToDelete;
        newNode.conditions = newNode.conditions.filter((condition, index) => !newIndexesToDelete.includes(index));
      } else {
        newNode.conditions = [];
      }
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.deleteGroupFromTree(child, matchingGroupId);
      });
    }
    return newNode;
  }

  changeGroupCondition(newNode: FilterGroup, event: GroupConditionChange): FilterGroup {
    if (newNode.id === event.groupId) {
      newNode.conditions[event.index] = event.newValue;
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.changeGroupCondition(child, event);
      });
    }
    return newNode;
  }

  filterItemsAllCondition(newNode: FilterGroup, event: FilterItemConditionChange): FilterGroup {
    if (newNode.id === event.groupId) {
      newNode.conditions = newNode.conditions.map(() => event.newValue);
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.filterItemsAllCondition(child, event);
      });
    }
    return newNode;
  }

  editFilterItem(newNode: FilterGroup, event: FilterItemChange): FilterGroup {
    if (newNode.id === event.groupId) {
      newNode.filterItems = newNode.filterItems.map((item) =>
        item.id === event.filterItem.id ? event.filterItem : item
      );
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.editFilterItem(child, event);
      });
    }
    return newNode;
  }

  addConditionToGroup(newNode: FilterGroup, groupId: string, newCondition: FilterGroupConditionEnum): FilterGroup {
    if (newNode.id === groupId) {
      newNode.conditions.push(newCondition);
    }
    if (!!newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child, index) => {
        newNode.children[index] = this.addConditionToGroup(child, groupId, newCondition);
      });
    }
    return newNode;
  }
}
