<div class="faqs">
  <h2 class="faq-title" *ngIf="title">{{ title }}</h2>
  <mat-accordion class="faq-accordion">
    <mat-expansion-panel
      *ngFor="let faq of faqs; let i = index"
      hideToggle
      (opened)="openedPanel = i"
      (closed)="openedPanel = -1"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-icon class="s-20" *ngIf="openedPanel !== i">add</mat-icon>
            <mat-icon class="s-20" *ngIf="openedPanel === i">remove</mat-icon>
            <h5>{{ faq.title | translateConditionally : translateTitle }}</h5>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        registrationOpen
        class="faq-content typography-body-1"
        [innerHTML]="faq.text | translateConditionally : translateValue | sanitizeHTML"
      ></div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
