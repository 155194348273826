<div class="my-suppliers-mobile-container" fxLayout="column">
  <ng-container *ngFor="let retailerToCatalog of content; let isLast = last">
    <app-my-suppliers-mobile-item
      [hasAccessToChat]="hasAccessToChat"
      [hasAccessToReadContacts]="hasAccessToReadContacts"
      [retailerToCatalog]="retailerToCatalog"
      [expanded]="expandedItem === retailerToCatalog.catalogId"
      (expansionChange)="handleItemExpansion(retailerToCatalog.catalogId, $event)"
    ></app-my-suppliers-mobile-item>
    <mat-divider *ngIf="!isLast"></mat-divider>
  </ng-container>
</div>
