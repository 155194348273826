import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupplierHeadquartersService {
  private readonly url = '/Supplier/Headquarters';

  constructor(private springRestService: SpringRestService) {}

  get(): Observable<SupplierHeadquarterDto> {
    return this.springRestService.get(MicroserviceNames.USER, this.url, undefined, true);
  }

  save(countryCode: string): Observable<void> {
    return this.springRestService.post(MicroserviceNames.USER, this.url, countryCode, undefined, true);
  }

  update(countryCode: string): Observable<void> {
    return this.springRestService.put(MicroserviceNames.USER, this.url, countryCode, undefined, true);
  }
}

export interface SupplierHeadquarterDto {
  userId: number;
  countryCode: string;
}
