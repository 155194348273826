<div *ngIf="!!description || !!title" class="header" [class.no-bottom-padding]="!hasBottomPadding">
  <div *ngIf="!!title" class="title-row">
    <h3 *ngIf="!!title" class="m-0" [class.centered-text]="position === 'center'">{{ title }}</h3>
    <div *ngIf="!!hasAll && !ltMd" class="typography-button text-nowrap cursor-pointer" (click)="allClicked.emit()">
      {{ 'EXPLORE_PRODUCTS.SEE_ALL' | translate }}
    </div>
  </div>
  <div *ngIf="!!description" class="typography-h3-subtitle" [class.centered-text]="position === 'center'">
    {{ description }}
  </div>
</div>
