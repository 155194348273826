import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sku-filter',
  templateUrl: './sku-filter.component.html',
  styleUrls: ['./sku-filter.component.scss'],
})
export class SkuFilterComponent implements OnInit {
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
