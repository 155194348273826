<div class="px-20 pt-8 pb-20">
  <mat-form-field appearance="fill" class="w-100p select-without-label input-without-padding chat-search">
    <input
      matInput
      [(ngModel)]="message"
      (keyup.enter)="sendMessage()"
      [disabled]="limitReached"
      [placeholder]="limitReached && 'Limit reached'"
    />
    <div matSuffix class="search-icon">
      <mat-spinner *ngIf="aiChatMessagesService.waiting" class="spinner" [diameter]="32"></mat-spinner>
      <button *ngIf="!limitReached" mat-icon-button matSuffix (click)="sendMessage()">
        <mat-icon class="s-12">arrow_upward</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
