<div [ngClass]="wrapperClass">
  <div [ngClass]="innerClass" class="inner-container">
    <div [style.order]="position === 'right' ? 2 : 1">
      <app-information-chip
        *ngIf="!!chipText"
        [text]="chipText"
        class="typography-button blue-chip chip-item"
      ></app-information-chip>
      <h2 *ngIf="title" class="m-0 pb-16" [class.h3]="ltMd">{{ title }}</h2>
      <div *ngIf="!!description" class="typography-h2-subtitle" [class.typography-h3-subtitle]="ltMd">
        {{ description }}
      </div>
      <ng-content select="[actions]"></ng-content>
      <div *ngIf="!!descriptionSmall" class="typography-body-2 pt-16">{{ descriptionSmall }}</div>
    </div>
    <div [style.order]="position === 'right' ? 1 : 2"><ng-content select="[template]"></ng-content></div>
  </div>
</div>
