<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
  class="pagination-container"
  *ngIf="totalNumber > fetchedNumber"
>
  <ng-container *ngIf="!onlyButton">
    <div [innerHTML]="'PAGINATOR_LOAD_MORE.TEXT' | translate : { fetched: fetchedNumber, total: totalNumber }"></div>
    <div class="progress-bar-container">
      <mat-progress-bar mode="determinate" [value]="progressBarPercentage"></mat-progress-bar>
    </div>
  </ng-container>

  <button
    mat-button
    class="load-more-button syncee-white-button"
    (click)="handleLoadMoreClicked()"
    [buttonLoadingState]="loading"
    [disabled]="loading"
    spinnerColor="black"
  >
    {{ 'PAGINATOR_LOAD_MORE.LOAD_MORE' | translate }}
  </button>
</div>
