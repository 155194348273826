import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { flatMap, isEmpty, throttle } from 'lodash';

@Component({
  selector: 'app-filter-selector-base',
  templateUrl: './filter-selector-base.component.html',
  styleUrls: ['./filter-selector-base.component.scss'],
})
export class FilterSelectorBaseComponent<K, T> implements OnInit, OnChanges {
  @ViewChild('optionsContainer') optionsContainer: ElementRef<HTMLElement>;

  @Input() hasClearAll = false;
  @Input() overlayOpen: boolean;
  @Input() needsTranslation = true;
  @Input() hasTopOptions = false;
  @Input() noOptionsAvailable = false;
  @Input() title: string;
  @Input() options: FilterSelectorOptionGroup<K, T>[];
  @Input() multiple = false;
  @Input() lazyLoad = false;
  @Output() loadMore = new EventEmitter<void>();
  @Input() totalCount = 0;
  @Input() hasSearch = false;
  @Input() hasSeeMore = false;
  @Input() searchLabel = 'SEARCH';
  @Input() searchTerm: string;
  @Output() clearAll = new EventEmitter<void>();
  @Output() searchTermChange = new EventEmitter<string>();
  @Input() value: K | K[];
  @Output() valueChange = new EventEmitter<K | K[]>();
  @Output() loadInitial = new EventEmitter<void>();
  @Output() seeMore = new EventEmitter<void>();
  @Output() seeLess = new EventEmitter<void>();

  allOptionsVisible = false;

  throttledOnScrollLoadData: () => void;
  private prevScrollTop = 0;

  constructor() {
    this.throttledOnScrollLoadData = throttle(this.onScrollLoadData, 300, { leading: true });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.overlayOpen?.isFirstChange() && changes.overlayOpen?.currentValue === false) {
      this.allOptionsVisible = false;
    }
  }

  ngOnInit(): void {
    if (this.multiple && !!this.value && !Array.isArray(this.value)) {
      this.value = [this.value];
    }
  }

  private generateNewArrayAfterSelection(selected: K, checked: boolean): K[] {
    if (Array.isArray(this.value)) {
      if (checked === false) {
        const newValue = this.value.filter((value) => value !== selected);
        if (isEmpty(newValue)) {
          return null;
        } else {
          return newValue;
        }
      } else if (checked === true) {
        return [...this.value, selected];
      }
    } else {
      return [selected];
    }
  }

  handleSingleOptionSelection(value: K): void {
    this.valueChange.emit(value);
  }

  handleMultipleOptionSelection(value: K, checked: boolean): void {
    this.valueChange.emit(this.generateNewArrayAfterSelection(value, checked));
  }

  onScrollLoadData(): void {
    if (this.lazyLoad) {
      const nativeElement = this.optionsContainer.nativeElement;
      if (nativeElement.clientHeight + Math.round(nativeElement.scrollTop) > nativeElement.scrollHeight - 280) {
        if (nativeElement.scrollTop >= this.prevScrollTop) {
          if (this.allOptions.length < this.totalCount) {
            this.loadMore.emit();
          }
        }
        this.prevScrollTop = nativeElement.scrollTop;
      }
    }
  }

  onSeeMore(): void {
    this.allOptionsVisible = true;
    this.seeMore.emit();
  }

  onSeeLess(): void {
    this.allOptionsVisible = false;
    this.seeLess.emit();
  }

  handleSearchChange(newValue: string): void {
    this.searchTermChange.emit(newValue);
    this.allOptionsVisible = true;
  }

  handleClearClicked(): void {
    this.handleSearchChange(null);
  }

  handleClearAllClicked(): void {
    this.clearAll.emit();
  }

  get allOptions(): FilterSelectorOption<K, T>[] {
    return flatMap(this.options.map((option) => option.options));
  }

  get selectedOptions(): K[] {
    return Array.isArray(this.value) ? this.value : [this.value];
  }
}

/**
 * Used for filter-selector.component.ts
 *
 * Generic types:
 * - 'K' - key of option
 * - 'T' - value of option (e.g.: for label)
 */
export interface FilterSelectorOption<K, T> {
  key: K;
  value: T;
  badges?: FilterSelectorOptionBadge[];
  image?: FilterSelectorImage;
}

export interface FilterSelectorOptionBadge {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export interface FilterSelectorImage {
  src: string;
  width: string;
  height: string;
}

export interface FilterSelectorOptionGroup<K, T> {
  optionGroupTitle?: string;
  options: FilterSelectorOption<K, T>[];
}
