<div class="container" (click)="handleClick($event)">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="before-icon" *ngIf="!!beforeIcon">
    <ng-container *ngTemplateOutlet="beforeIcon"></ng-container>
  </div>
  <mat-icon class="s-20 hover-icon">
    {{ icon }}
  </mat-icon>
</div>
