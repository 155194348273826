import { Injectable } from '@angular/core';

import { RolesEnum } from '../../../vo/roles/roles';

import { PLATFORM } from '../../../utils/platforms/platform-config';

import type { TileSelectOption } from '../../../shared/components/tile-select/model/tile-select-option';

import { EcomTypeEnum } from '../../../vo/enums/ecom-type-enum';

@Injectable()
export class ChangeRoleService {
  public _role: RolesEnum;

  private _keepRole: boolean;

  private _ecomType: EcomTypeEnum = null;

  public PLATFORM_CONFIG_LIST = PLATFORM;

  public getPlatformOptions(): TileSelectOption<EcomTypeEnum>[] {
    switch (this._role) {
      case RolesEnum.RETAILER:
        return this.PLATFORM_CONFIG_LIST.filter(({ options }) => {
          return options.hasRetailerApp;
        }).map(({ key, name, transparentIconSrc }) => {
          return {
            value: key,
            title: name,
            src: transparentIconSrc,
          };
        });
      case RolesEnum.SUPPLIER:
        return this.PLATFORM_CONFIG_LIST.filter(({ options }) => {
          return options.hasSupplierApp;
        }).map(({ key, name, transparentIconSrc }) => {
          return {
            value: key,
            title: name,
            src: transparentIconSrc,
          };
        });
      default:
        return [];
    }
  }

  get role(): RolesEnum {
    return this._role;
  }

  set role(role: RolesEnum) {
    this._role = role;
  }

  get keepRole(): boolean {
    return this._keepRole;
  }

  set keepRole(keepAccount: boolean) {
    this._keepRole = keepAccount;
  }

  get ecomType(): EcomTypeEnum {
    return this._ecomType;
  }

  set ecomType(ecomType: EcomTypeEnum) {
    this._ecomType = ecomType;
  }
}
