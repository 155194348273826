<app-loading-screen [message]="'Please wait until we process your products!'"></app-loading-screen>
<mat-card class="mat-card m-20 taskwizard-container">
  <h2 class="task-name">{{ taskName }}</h2>

  <mat-horizontal-stepper [linear]="true" #taskStepper>
    <!--Mapping-->
    <mat-step #mappingStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.MAPPING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #addFilesWrapper>
          <app-addfiles
            #stepComponent
            role="DF_ECWID"
            [currentTaskId]="taskId"
            (sourceTypeCallBack)="setSourceType($event)"
            (stateChanged)="wizardStateChanged()"
            [comments]="comments"
          ></app-addfiles>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Filter-->
    <mat-step #filterStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.FILTER' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #filterWrapper>
          <app-filter
            #stepComponent
            [taskId]="taskId"
            [usedComponent]="'productUpload'"
            [catalogId]="rcatalogId"
            [comments]="comments"
            [hasDefaultFilter]="hasDefaultFilter()"
          ></app-filter>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Categories-->
    <mat-step #categoryMappingStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.CATEGORIES' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #categoryMappingWrapper>
          <app-retailer-category-mapping
            #stepComponent
            [ecomType]="'ecwid'"
            [rcatalogId]="rcatalogId"
            [comments]="comments"
            [taskId]="taskId"
          ></app-retailer-category-mapping>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Pricing-->
    <mat-step #pricingEditorStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.PRICING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <div style="overflow: auto">
          <app-step-wrapper #pricingWrapper>
            <app-retailer-pricing #stepComponent [rcatalog_id]="rcatalogId" [task_id]="taskId"></app-retailer-pricing>
          </app-step-wrapper>
        </div>
      </div>
    </mat-step>

    <!--Settings-->
    <mat-step #scheduleStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.SETTINGS' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #scheduleWrapper>
          <app-ecwid-mapping-settings
            #stepComponent
            [taskId]="taskId"
            [rcatalogId]="rcatalogId"
            (voChanged)="handleVoChanged($event)"
            [retailerSettingsVO]="retailerVO"
            [comments]="comments"
          >
          </app-ecwid-mapping-settings>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Summary-->
    <mat-step #summaryStep>
      <ng-template matStepLabel>{{ 'RETAILER_TASK_WIZARD.SUMMARY' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #summaryWrapper nextButtonLabel="Finish" backNavigationUrl="/retailer/import-tasks">
          <app-summary
            #stepComponent
            [taskId]="taskId"
            [rcatalogId]="rcatalogId"
            [retailerSettingsVO]="retailerVO"
            [comments]="comments"
          ></app-summary>
        </app-step-wrapper>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-card>
