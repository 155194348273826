import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { RegistrationSupplierReqModel } from 'app/service/authentication/authentication.service';
import { RoleTypesEnum } from 'app/vo/roles/roles';
import { AuthenticationService } from 'app/service/authentication/authentication.service';
import { GtmManagerService } from '../../../../../service/google-tag-manager/gtm-manager.service';
import { SignUpGtmAction } from '../../../../../service/google-tag-manager/actions/sign_up';

@Component({
  selector: 'app-registration-supplier-content',
  templateUrl: './registration-supplier-content.component.html',
  styleUrls: ['./registration-supplier-content.component.scss'],
})
export class RegistrationSupplierContentComponent implements OnInit {
  supplierRole = RoleTypesEnum.SUPPLIER;
  @Input() titleKey = 'AUTHENTICATION.REGISTRATION.SUPPLIER_REGISTRATION_CONTENT.TITLE';
  @Input() descriptionKey = 'AUTHENTICATION.REGISTRATION.SUPPLIER_REGISTRATION_CONTENT.SUBTITLE';
  passwordForm: FormGroup;

  public step = 0;

  constructor(
    private authService: AuthenticationService,
    private authManagerService: AuthenticationManagerService,
    private gtmService: GtmManagerService
  ) {}

  ngOnInit(): void {}

  submitHandle(): void {
    if (!this.passwordForm.valid) {
      return;
    }
    this.authService.register(this.buildRequestData()).subscribe(() => {
      this.authManagerService.emitOpenLoginDialog(AuthDialogType.RETAILER);
      this.gtmService.pushTag(new SignUpGtmAction({ authentication_method: 'form', app_type: 'supplier' }));
    });
  }

  buildRequestData(): RegistrationSupplierReqModel {
    return {
      email: this.passwordForm.get('email').value,
      password: this.passwordForm.get('password').value,
      role: RoleTypesEnum.SUPPLIER,
    };
  }

  navigateToRetailerRegister(event): void {
    event.preventDefault();
    this.authManagerService.emitOpenRegistrationDialog(AuthDialogType.RETAILER);
  }

  navigateToSupplierLogIn(event): void {
    event.preventDefault();
    this.authManagerService.emitOpenLoginDialog(AuthDialogType.SUPPLIER);
  }
}
