import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyTransformToSymbolPipe } from 'app/shared/pipes/currency-transform-to-symbol.pipe';
import { CountryNameToCodePipe } from 'app/shared/pipes/legacy/country-name-to-code.pipe';
import { TruncatePipe } from 'app/shared/pipes/truncate.pipe';
import { SearchbarCustomComponent } from '../../shared/components/searchbar-custom/searchbar-custom.component';
import { ApprovalFilterComponent } from './filter-items/approval-filter/approval-filter.component';
import { AutoOrderFilterComponent } from './filter-items/auto-order-filter/auto-order-filter.component';
import { CategoriesFilterComponent } from './filter-items/categories-filter/categories-filter.component';
import { PremiumSupplierFilterComponent } from './filter-items/premium-supplier-filter/premium-supplier-filter.component';
import { PriceFilterComponent } from './filter-items/price-filter/price-filter.component';
import { ProductLanguageFilterComponent } from './filter-items/product-language-filter/product-language-filter.component';
import { ShippingTimeFilterComponent } from './filter-items/shipping-time-filter/shipping-time-filter.component';
import { ShippingTypeFilterComponent } from './filter-items/shipping-type-filter/shipping-type-filter.component';
import { ShipsFromFilterComponent } from './filter-items/ships-from-filter/ships-from-filter.component';
import { ShipsToFilterComponent } from './filter-items/ships-to-filter/ships-to-filter.component';
import { SkuFilterComponent } from './filter-items/sku-filter/sku-filter.component';
import { StockStatusFilterComponent } from './filter-items/stock-status-filter/stock-status-filter.component';
import { SupplierFilterComponent } from './filter-items/supplier-filter/supplier-filter.component';
import { FilterSelectorBaseComponent } from './filter-selector-base/filter-selector-base.component';
import { FilterSelectorDialogComponent } from './filter-selector-dialog/filter-selector-dialog.component';
import { FilterWrapperChipWithOverlayComponent } from './filter-wrapper-chip-with-overlay/filter-wrapper-chip-with-overlay.component';

@NgModule({
  declarations: [
    FilterSelectorDialogComponent,
    FilterSelectorBaseComponent,
    AutoOrderFilterComponent,
    SupplierFilterComponent,
    CategoriesFilterComponent,
    ProductLanguageFilterComponent,
    PriceFilterComponent,
    ShippingTypeFilterComponent,
    ShipsFromFilterComponent,
    ShipsToFilterComponent,
    StockStatusFilterComponent,
    ApprovalFilterComponent,
    PremiumSupplierFilterComponent,
    FilterWrapperChipWithOverlayComponent,
    ShippingTimeFilterComponent,
    SkuFilterComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    MatRadioModule,
    FlexModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatChipsModule,
    OverlayModule,
    MatCardModule,
    SearchbarCustomComponent,
    TruncatePipe,
    CurrencyTransformToSymbolPipe,
  ],
  exports: [
    FilterSelectorDialogComponent,
    FilterSelectorBaseComponent,
    AutoOrderFilterComponent,
    SupplierFilterComponent,
    CategoriesFilterComponent,
    ProductLanguageFilterComponent,
    PriceFilterComponent,
    ShippingTypeFilterComponent,
    ShipsFromFilterComponent,
    ShipsToFilterComponent,
    StockStatusFilterComponent,
    FilterWrapperChipWithOverlayComponent,
    ApprovalFilterComponent,
    PremiumSupplierFilterComponent,
    ShippingTimeFilterComponent,
    SkuFilterComponent,
  ],
  providers: [CountryNameToCodePipe],
})
export class FilterCommonModule {}
