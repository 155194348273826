import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { AuthenticationMethod } from '../model/variables/general.variables';

export class LoginGtmAction implements GtmAction {
  event = GtmEvent.login;

  constructor(public payload: LoginGtmActionPayload) {}
}

// tslint:disable-next-line:no-empty-interface
export interface LoginGtmActionPayload extends AuthenticationMethod {}
