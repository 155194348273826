import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { FilterSelectorOption } from '../../../filter-selector/filter-selector.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../app.state';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';

@Component({
  selector: 'app-auto-order-filter',
  templateUrl: './auto-order-filter.component.html',
  styleUrls: ['./auto-order-filter.component.scss'],
})
export class AutoOrderFilterComponent {
  public productSearchStore$: Observable<ProductSearchState>;
  public options: FilterSelectorOption<string, string>[] = [
    { key: 'any', value: 'FILTER_SIDEBAR.SELECTOR.AUTO_ORDER.ALL' },
    { key: 'true', value: 'FILTER_SIDEBAR.SELECTOR.AUTO_ORDER.ONLY' },
  ];

  constructor(private store: Store<AppState>) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
  }

  handleChange(value: string): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { autoOrder: value } }));
  }
}
