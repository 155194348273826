import { ActionsUnion, ActionTypes } from './category.action';
import { TopSuppliersForCategory } from '../../service/product-search/category.service';
import { CategoryVo } from '../../vo/category-vo';

export interface CategoryState {
  categories: CategoryVo;
  flattenCategories: CategoryVo[];
  topSuppliers: TopSuppliersForCategory;
}

const initialState: CategoryState = {
  categories: null,
  flattenCategories: [],
  topSuppliers: {},
};

export function categoriesReducer(state = initialState, action: ActionsUnion): CategoryState {
  switch (action.type) {
    case ActionTypes.setCategories: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case ActionTypes.setFlattenCategories: {
      return {
        ...state,
        flattenCategories: action.payload,
      };
    }
    case ActionTypes.addTopSuppliersToCategory: {
      return {
        ...state,
        topSuppliers: {
          ...state.topSuppliers,
          [action.payload.categoryId]: action.payload.suppliers,
        },
      };
    }
    default: {
      return state;
    }
  }
}
