<div fxLayout="column" fxLayoutGap="20px">
  <ng-container *ngIf="!!listTitle">
    <h5>{{ listTitle }}</h5>
  </ng-container>
  <ul class="feature-list w-100p">
    <ng-container *ngFor="let feature of features">
      <li fxLayoutAlign="start center" fxLayoutGap="20px">
        <mat-icon class="s-20">check</mat-icon>
        <div>{{ feature | translate }}</div>
      </li>
    </ng-container>
  </ul>
</div>
