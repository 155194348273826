import { getPlatformByType } from '../platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';

export const INTEGRATION_CARDS_RETAILER = [
  {
    name: 'shopify',
    logo: getPlatformByType(EcomTypeEnum.SHOPIFY).images.black_logo,
    href: '/integration/shopify',
  },
  {
    name: 'wix',
    logo: getPlatformByType(EcomTypeEnum.WIX).images.black_logo,
    href: '/page/wix',
  },
  {
    name: 'squarespace',
    logo: getPlatformByType(EcomTypeEnum.SQUARESPACE).images.black_logo,
    href: '/page/squarespace',
  },
  {
    name: 'woocommerce',
    logo: getPlatformByType(EcomTypeEnum.WOOCOMMERCE).images.black_logo,
    href: '/page/woocommerce',
  },
  {
    name: 'shopify-plus',
    logo: 'assets/images/integrations/shopify-plus.png',
    href: '/integration/shopify-plus',
  },
  {
    name: 'bigcommerce',
    logo: getPlatformByType(EcomTypeEnum.BIGCOMMERCE).images.black_logo,
    href: '/page/bigcommerce',
  },
  {
    name: 'ecwid-by-lightspeed',
    logo: getPlatformByType(EcomTypeEnum.ECWID).images.black_logo,
    href: '/page/ecwid-by-lightspeed',
  },
  {
    name: 'jumpseller',
    logo: getPlatformByType(EcomTypeEnum.JUMPSELLER).images.black_logo,
    href: '/page/jumpseller',
  },
  {
    name: 'shoprenter',
    logo: getPlatformByType(EcomTypeEnum.SHOPRENTER).images.black_logo,
    href: '/page/shoprenter',
  },
];

export const INTEGRATION_CARDS_SUPPLIER = [
  {
    name: 'shopify',
    logo: getPlatformByType(EcomTypeEnum.SHOPIFY).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'wix',
    logo: getPlatformByType(EcomTypeEnum.WIX).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'squarespace',
    logo: getPlatformByType(EcomTypeEnum.SQUARESPACE).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'woocommerce',
    logo: getPlatformByType(EcomTypeEnum.WOOCOMMERCE).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'bigcommerce',
    logo: getPlatformByType(EcomTypeEnum.BIGCOMMERCE).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'ecwid-by-lightspeed',
    logo: getPlatformByType(EcomTypeEnum.ECWID).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'jumpseller',
    logo: getPlatformByType(EcomTypeEnum.JUMPSELLER).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'shoprenter',
    logo: getPlatformByType(EcomTypeEnum.SHOPRENTER).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
  {
    name: 'unas',
    logo: getPlatformByType(EcomTypeEnum.UNSAS).images.black_logo,
    href: '/registration/supplier',
    target: '_self',
  },
];
