<app-dialog-multi-wrapper
  [icon]="'check'"
  [title]="'BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL_TITLE' | translate"
  [hideCloseButton]="false"
  [hideConfirmButton]="false"
  [buttonsAlignment]="'center'"
  [confirmButtonText]="'BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL_BUTTON' | translate"
  [type]="'info'"
  (confirmClick)="closeDialog()"
>
  <div>
    {{ 'BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL' | translate : { subUntil: (subUntil | customDate) } }}
  </div>
  <div>
    {{ 'BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL_RESUB' | translate }}
  </div>
</app-dialog-multi-wrapper>
