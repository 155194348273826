import { Injectable } from '@angular/core';
import { RolesEnum } from '../../../../vo/roles/roles';
import { MatDialog } from '@angular/material/dialog';
import { NeedStoreDialogComponent, NeedStoreDialogComponentData } from './need-store-dialog.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NeedStoreDialogService {
  constructor(private dialog: MatDialog) {}

  open(role: RolesEnum): Observable<void> {
    return this.dialog
      .open<NeedStoreDialogComponent, NeedStoreDialogComponentData>(NeedStoreDialogComponent, {
        data: { role },
        autoFocus: false,
      })
      .afterClosed();
  }
}
