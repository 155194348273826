import { Input, OnDestroy, OnInit, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MappingSettingsService } from '../../../../../service/mapping-settings-service/mapping-settings.service';
import { CatalogService } from '../../../../../service/catalog/catalog.service';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../../../../../service/user/user.service';
import { Utils } from 'app/utils/utils';

@Directive()
export abstract class AbstractRetailerSettingsDirective implements OnInit, OnDestroy {
  fieldsToUpdate: any[];
  selectedItemIds: any[];
  fulfillmentServices: any[];

  settingsVO: RetailerCatalogSettingsVO;

  allSubscriptions = new Subscription();

  protected shopData = {};

  settingsForm: UntypedFormGroup;

  @Input() taskId: number;
  @Input() showSaveButton = true;

  isAdmin = false;

  protected constructor(
    protected formBuilder: UntypedFormBuilder,
    public mappingSettingsService: MappingSettingsService,
    protected catalogService: CatalogService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.userService.getCachedUser().role === 3;
    this.createForm();
    this.allSubscriptions.add(this.subscribeToIsReady());
  }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe();
  }

  protected subscribeToFormValueChange(): void {
    this.settingsForm.valueChanges.subscribe(() => {
      this.updateChanges();
    });
  }

  protected createForm(): void {
    this.loadFieldsToUpdateOptions();
    this.catalogService.getRetailerCatalogSettings(this.taskId).subscribe((data) => {
      console.log(data);
      this.settingsForm = this.makeFormFromData(data);
      if (data) {
        this.controls['settings'].patchValue(data);
        this.controls['updateOptions'].patchValue(data);
        this.controls['adminSettings'].patchValue(data);
        this.fieldsToUpdate.forEach((item) => {
          if (data.fieldsToUpdate.includes(item.id)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
        this.selectedItemIds = this.fieldsToUpdate.filter((item) => item.checked).map((item) => item.id);
      }
      this.showSaveButton = true;
      setTimeout(() => this.updateChanges(), 0);
      this.subscribeToFormValueChange();
    });
  }

  protected loadFieldsToUpdateOptions(): void {
    this.selectedItemIds = this.fieldsToUpdate.filter((item) => item.checked).map((item) => item.id);
  }

  protected getShopData(): Observable<any> {
    return this.catalogService.getShopData(this.taskId);
  }

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};

  updateChanges(): void {
    this.onChange(this.settingsForm.value);
  }

  writeValue(value: any): void {
    if (!Utils.isNullOrUndefined(value)) {
      this.settingsForm.reset(value);
      this.updateChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getSettingsVO(): RetailerCatalogSettingsVO {
    this.settingsVO = new RetailerCatalogSettingsVO();
    this.settingsVO.id = this.taskId;
    this.settingsVO.settings = {
      ...this.settingsForm.get('settings').value,
      ...this.settingsForm.get('updateOptions').value,
      ...this.settingsForm.get('adminSettings').value,
    };
    this.settingsVO.settings.fieldsToUpdate = this.fieldsToUpdate.filter((item) => item.checked).map((item) => item.id);

    return this.settingsVO;
  }

  saveSettings(): Observable<any> {
    return this.mappingSettingsService.saveRetailerCatalogSettings(this.getSettingsVO());
  }

  subscribeToIsReady(): Subscription {
    return this.isReady().subscribe((isReady) => {
      // if (isReady) {
      //     this.createForm();
      // }
    });
  }

  abstract makeFormFromData(data): UntypedFormGroup;

  abstract isReady(): Observable<boolean>;

  get controls(): any {
    return this.settingsForm.controls;
  }
}

export class RetailerCatalogSettingsVO {
  id: number;
  updateType: string;
  settings: any;
}
