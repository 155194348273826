import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss'],
})
export class NotificationIconComponent implements OnInit {
  isOpen = false;

  constructor(public notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.notificationsService.setTimerForNotificationCheck();
  }
}
