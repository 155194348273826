import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate',
  standalone: true,
})
export class CustomDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  getFormat(hideTime: boolean): string {
    if (hideTime) {
      return 'MMM d, y';
    } else {
      return 'MMM d, y, hh:mm a';
    }
  }
  transform(value: Date | string, hideTime = false): string {
    return this.datePipe.transform(value, this.getFormat(hideTime));
  }
}
