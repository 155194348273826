import { CustomSvgInterface } from './custom-svg-registry.service';

export const CUSTOM_SVG_LIST: CustomSvgInterface[] = [
  {
    iconName: 'extremely_dissatisfied',
    url: '/assets/icons/svg/sentiment-icons/sentiment_extremely_dissatisfied.svg',
  },
  { iconName: 'dissatisfied', url: '/assets/icons/svg/sentiment-icons/sentiment_dissatisfied.svg' },
  { iconName: 'neutral', url: '/assets/icons/svg/sentiment-icons/sentiment_neutral.svg' },
  {
    iconName: 'satisfied',
    url: '/assets/icons/svg/sentiment-icons/sentiment_satisfied.svg',
  },
  {
    iconName: 'very_satisfied',
    url: '/assets/icons/svg/sentiment-icons/sentiment_very_satisfied.svg',
  },
  {
    iconName: 'cart_custom',
    url: '/assets/icons/svg/shopping_basket_without_dot.svg',
  },
  { iconName: 'package_2', url: '/assets/icons/svg/package_2.svg' },
];
