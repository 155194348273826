<app-wizard-category-mapping
  [categoryItems]="categoryMappingItems"
  (showProducts)="openProductList($event.name)"
  (removeInBulk)="removeInBulk($event)"
  (removeSingleMapping)="removeMapping($event)"
  (addSingleMapping)="addCategory($event)"
  (clearSelectedMapping)="clearCategoryMapping($event)"
  (addSelectedMapping)="addInBulk($event)"
  [originalCategoriesTitle]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.ORIGINAL_CATEGORIES_TITLE' | translate"
  [originalCategoriesTooltip]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.ORIGINAL_CATEGORIES_TOOLTIP' | translate"
  [customCategoriesTitle]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.STORE_COLLECTIONS_TITLE' | translate"
  [customCategoriesTooltip]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.STORE_COLLECTIONS_TOOLTIP' | translate"
></app-wizard-category-mapping>
