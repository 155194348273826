<div class="badge-container">
  <ng-container [ngSwitch]="badgeType">
    <div
      class="badge approve cursor-pointer"
      *ngSwitchCase="'approve'"
      matTooltip="{{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.APPROVE_STATUS.APPROVAL_NEEDED_DESC' | translate }}"
      [matTooltipDisabled]="!tooltipShown"
      (click)="goToHelpURL('https://help.syncee.io/en/articles/4142044-what-is-approval-needed-in-the-marketplace')"
    >
      <ng-container [ngSwitch]="approveStatus">
        <ng-container *ngSwitchCase="null">
          {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.APPROVE_STATUS.APPROVAL_NEEDED' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'0'">
          {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.APPROVE_STATUS.APPROVE_PENDING' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'-1'">
          {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.APPROVE_STATUS.APPROVE_REJECTED' | translate }}
        </ng-container>
      </ng-container>
    </div>
    <div
      class="badge auto cursor-default"
      *ngSwitchCase="'auto'"
      matTooltip="{{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.AUTOMATED_ORDER_DESC' | translate }}"
      [matTooltipDisabled]="!tooltipShown"
    >
      {{ 'EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.AUTOMATED_ORDER' | translate }}
    </div>
    <div class="badge beta cursor-default" *ngSwitchCase="'beta'">Beta</div>
  </ng-container>
</div>
