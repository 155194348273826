import { Injectable } from '@angular/core';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogProductsCacheService {
  private _productsCache: Map<string, Observable<SearchProductVO[]>> = new Map();

  constructor(private catalogSidebarService: CatalogSidebarService) {}

  getProductsForCatalog(catalogId: number, limit: number = 5, offset: number = 0): Observable<SearchProductVO[]> {
    const cacheKey = this.getCacheKey(catalogId, limit, offset);

    if (!this._productsCache.has(cacheKey)) {
      this.fetchAndCacheProducts(cacheKey, catalogId, limit, offset);
    }

    return this._productsCache.get(cacheKey);
  }

  refreshProductsForCatalog(catalogId: number, limit: number = 5, offset: number = 0): void {
    const cacheKey = this.getCacheKey(catalogId, limit, offset);
    this._productsCache.delete(cacheKey);
  }

  clearCache(): void {
    this._productsCache.clear();
  }

  private fetchAndCacheProducts(
    cacheKey: string,
    catalogId: number,
    limit: number,
    offset: number
  ): Observable<SearchProductVO[]> {
    const productsOfCatalog$ = this.catalogSidebarService
      .getProductsOfCatalog(catalogId, limit, offset)
      .pipe(shareReplay(1));

    this._productsCache.set(cacheKey, productsOfCatalog$);
    return productsOfCatalog$;
  }

  private getCacheKey(catalogId: number, limit: number, offset: number): string {
    return `${catalogId}-${limit}-${offset}`;
  }
}
