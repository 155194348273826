import { NgModule } from '@angular/core';
import { NewFieldMappingComponent } from './new-field-mapping.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { A11yModule } from '@angular/cdk/a11y';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FieldSelectorComponent } from './field-selector/field-selector.component';
import { DraggableModule } from '../../draggable/draggable.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldsMakerComponent } from './field-selector/fields-maker.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [NewFieldMappingComponent, FieldSelectorComponent, FieldsMakerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    A11yModule,
    CdkTableModule,
    DragDropModule,
    MatSortModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    DraggableModule,
    FormsModule,
    MatButtonToggleModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TranslateModule,
    MatTooltipModule,
    MatRippleModule,
  ],
  exports: [NewFieldMappingComponent, FieldsMakerComponent],
})
export class NewFieldMappingModule {}
