<div class="review-dialog-container" fxLayout="column" fxLayoutGap="12px" fxLayoutAlign="start center">
  <button fxFlexAlign="end" mat-icon-button [matDialogClose]="false">
    <mat-icon>close</mat-icon>
  </button>
  <ng-container *ngIf="!allStepsDone; else allDone">
    <div fxLayout="column">
      <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start center">
        <app-supplier-icon [supplierUserId]="data.supplierUserId" [size]="'92px'"></app-supplier-icon>
        <div class="centered-text" fxLayout="column" fxLayoutGap="12px">
          <h2 class="mt-0">
            {{ 'USER_REVIEW_DIALOG.' + reviewSteps[currentReviewStepIndex].type + '.TITLE' | translate }}
          </h2>
          <div>{{ 'USER_REVIEW_DIALOG.' + reviewSteps[currentReviewStepIndex].type + '.DESCRIPTION' | translate }}</div>
        </div>
        <ng-container *ngIf="reviewSteps[currentReviewStepIndex].hasRating; else reviewMessageBox">
          <app-rating-with-icons
            [selectedRating]="reviewSteps[currentReviewStepIndex].rating"
            [ratingItems]="reviewSteps[currentReviewStepIndex].ratingItems"
            (selectedChange)="handleRatingChange($event)"
          ></app-rating-with-icons>
        </ng-container>
      </div>
    </div>
    <button
      [disabled]="reviewSteps[currentReviewStepIndex].hasRating && !reviewSteps[currentReviewStepIndex].rating"
      mat-flat-button
      class="w-100p mt-32"
      color="primary"
      (click)="handleNext()"
    >
      {{
        (!reviewSteps[currentReviewStepIndex].hasRating ? 'USER_REVIEW_DIALOG.SUBMIT' : 'USER_REVIEW_DIALOG.NEXT')
          | translate
      }}
    </button>

    <button *ngIf="!!data.review" mat-button color="warn" (click)="handleDeleteReview()">
      {{ 'USER_REVIEW_DIALOG.DELETE' | translate }}
    </button>
  </ng-container>
</div>

<ng-template #reviewMessageBox>
  <mat-form-field appearance="fill" class="w-100p">
    <textarea
      matInput
      [placeholder]="'USER_REVIEW_DIALOG.REVIEW_MESSAGE.INPUT_PLACEHOLDER' | translate"
      [(ngModel)]="reviewMessage"
    ></textarea>
  </mat-form-field>
</ng-template>

<ng-template #allDone>
  <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start center">
    <div class="supplier-icon-container">
      <app-supplier-icon [size]="'92px'" [supplierUserId]="data.supplierUserId"></app-supplier-icon>
      <div class="check-icon-container">
        <mat-icon class="s-12 check-icon">check</mat-icon>
      </div>
    </div>
    <div class="centered-text" fxLayout="column" fxLayoutGap="12px">
      <h2 class="mt-0">{{ 'USER_REVIEW_DIALOG.ALL_DONE_TITLE' | translate }}</h2>
      <div>{{ 'USER_REVIEW_DIALOG.ALL_DONE_SUBTITLE' | translate }}</div>
    </div>
    <button [matDialogClose]="true" mat-flat-button color="primary">{{ 'USER_REVIEW_DIALOG.DONE' | translate }}</button>
  </div>
</ng-template>
