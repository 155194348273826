import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoleTypesEnum } from 'app/vo/roles/roles';
import { AuthDialogType } from './helpers/authentication-dialog-type';
import { ForgotPasswordDialogComponent } from './login/forgot-password-dialog/forgot-password-dialog.component';
import { FORGOTTEN_CONTENT_STEPS } from './login/helpers/login-content-steps';

@Injectable({
  providedIn: 'root',
})
export class ForgottenPasswordManagerService {
  constructor(private dialogService: MatDialog) {}

  public openForgottenPasswordStep(
    type: AuthDialogType = AuthDialogType.RETAILER
  ): MatDialogRef<ForgotPasswordDialogComponent> {
    return this.openDialog(FORGOTTEN_CONTENT_STEPS.FORGOTTEN_PASSWORD_STEP, type);
  }

  public openResetPasswordStep(
    type: AuthDialogType = AuthDialogType.RETAILER
  ): MatDialogRef<ForgotPasswordDialogComponent> {
    return this.openDialog(FORGOTTEN_CONTENT_STEPS.RESET_PASSWORD_STEP, type);
  }

  private openDialog(step: FORGOTTEN_CONTENT_STEPS, type: AuthDialogType): MatDialogRef<ForgotPasswordDialogComponent> {
    this.dialogService.closeAll();
    return this.dialogService.open(ForgotPasswordDialogComponent, {
      maxWidth: '900px',
      data: { step, type },
      panelClass: 'custom-modal-full-width-lt-md',
      maxHeight: 'auto',
    });
  }
}
