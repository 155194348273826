import { Component, Input, OnInit } from '@angular/core';
import { AIChatMessage, AIChatMessageSource, AIChatMessageType } from '../../../model/messages/ai-chat-message';

@Component({
  selector: 'app-ai-chat-message-simple',
  templateUrl: './ai-chat-message-simple.component.html',
  styleUrls: ['./ai-chat-message-simple.component.scss'],
})
export class AiChatMessageSimpleComponent implements OnInit {
  @Input() message: AIChatMessage;
  aIChatMessageSource = AIChatMessageSource;
  aiChatMessageType = AIChatMessageType;
  messageMarkdown: string;

  constructor() {}

  ngOnInit(): void {
    this.messageMarkdown = this.message.payload.simpleMessage.replace('\\n\\n', '\\n').replace(/\n(?=\n)/g, '<br>');
  }
}
