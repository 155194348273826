import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export function getTranslationFromStream(key?: string): (source: Observable<string>) => Observable<string | object> {
  return (source: Observable<string>): Observable<string> =>
    source.pipe(
      filter((data) => !!data),
      filter((data) => data !== key),
      take(1)
    );
}
