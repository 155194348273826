import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FtpComponent } from './ftp.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { BreadcrumbNavigationComponent } from 'app/shared/components/breadcrumb-navigation/breadcrumb-navigation.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSelectModule,
    TranslateModule,
    LoadingSpinnerComponent,
    BreadcrumbNavigationComponent,
    FlexModule,
    ExtendedModule,
    MatListModule,
  ],
  declarations: [FtpComponent],
})
export class FtpModule {}
