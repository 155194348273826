<div
  fxLayout="column"
  class="wrapper-container custom-dialog-wrapper"
  [ngClass]="{
    'hide-close-btn-desktop': hideCloseBtnDesktop,
    'hide-close-btn-tablet': hideCloseBtnTablet,
    'hide-close-btn-mobile': hideCloseBtnMobile
  }"
>
  <div fxFlex="1 0 0" fxLayout="column" class="m-h-0">
    <div
      *ngIf="!!headerLeftRef || !hideCloseBtn"
      fxFlex="0 0 auto"
      class="header"
      [class.no-header-left]="!headerLeftRef"
      [style.padding]="headerPadding"
      [fxLayoutAlign]="headerCentered ? 'center center' : 'space-between center'"
      fxLayout="row"
    >
      <div>
        <div *ngIf="!!headerLeftRef" class="left-header-side-wrapper">
          <ng-container *ngTemplateOutlet="headerLeftRef"></ng-container>
        </div>
      </div>
      <div *ngIf="!hideCloseBtn" class="close-button-wrapper" fxLayout="row-reverse">
        <button mat-icon-button [mat-dialog-close]="closeWithRedirect ?? null">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div fxFlex="1 0 0" class="content-container" [class.scrollable]="!!actionBarRef">
      <ng-content></ng-content>
    </div>
  </div>
  <ng-container *ngIf="!!actionBarRef">
    <div fxFlex="0 0 auto">
      <ng-container *ngTemplateOutlet="actionBarRef"></ng-container>
    </div>
  </ng-container>
</div>
