import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { ECOM_SHOPIFY } from '../../utils/Constants';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { SubscriptionDF, SubscriptionRMP } from '../../vo/subscription-vo';
import { getPlatformByType } from '../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';
import { ROLE_TYPE } from '../../vo/roles/roles';

@Injectable({
  providedIn: 'root',
})
export class EcomService {
  constructor(protected restService: RestService, @Inject(DOCUMENT) protected document: any) {}

  public getEcomType(): Observable<any> {
    return this.restService.get('EcomService/getEcomTypes').pipe(map((res) => res.getData()));
  }

  public getDomains(role?: ROLE_TYPE): Observable<EcomVO[]> {
    return this.restService
      .get(`PlanService/getDataForPlan${role ? '?role=' + role : ''}`)
      .pipe(map((response) => response.getData()));
  }

  public getDomainByEcomId(ecomId: number, role?: ROLE_TYPE): Observable<EcomVO> {
    return this.restService
      .get(`PlanService/getDataForPlan?ecomId=${ecomId}${role ? '&role=' + role : ''}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getOnlyDomains(role?: ROLE_TYPE): Observable<any[]> {
    return this.restService
      .get(`UserService/getDomains${role ? '?role=' + role : ''}`)
      .pipe(map((response) => response.getData()));
  }

  public getUserDomains(): Observable<any[]> {
    return this.restService.get(`UserService/getUserDomains`).pipe(map((response) => response.getData()));
  }

  public getSourceSelectableDomains(taskId, appType): Observable<any[]> {
    const params = new HttpParams().set('taskId', taskId.toString()).set('appType', appType);
    return this.restService
      .get(`UserService/getSourceSelectableDomains`, params)
      .pipe(map((response) => response.getData()));
  }

  public getDomainDetails(ecomId: number): Observable<any> {
    return this.restService
      .get(`EcomService/getEcomByDomain?ecomId=${ecomId}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public installShopify(domain: string, role: 1 | 2): void {
    this.restService.post(`EcomService/add`, { domain: domain, ecomType: ECOM_SHOPIFY, role: role }).subscribe(() => {
      if (role === 1) {
        this.document.location.href = `https://installer.syncee.co/shopify_supplier_auth?shop=${domain}`;
      } else {
        this.document.location.href = `https://installer.syncee.co/shopify_auth?shop=${domain}`;
      }
    });
  }

  public installJumpseller(data): Observable<any> {
    return this.restService.get(`EcomService/installJumpseller`, data).pipe(map((response) => response.getFirstData()));
  }

  public installShoprenter(data): Observable<any> {
    return this.restService.get(`EcomService/installShoprenter`, data).pipe(map((response) => response.getFirstData()));
  }

  public getProductLinkByEcomType(ecom, productID): string {
    return getPlatformByType(ecom.ecomType.toLowerCase() as EcomTypeEnum).links.getLinkToProduct({
      productId: productID,
      domain: ecom.domain,
      storeId: ecom.storeId,
    });
  }

  public getEcomSubscriptions(ecomId): Observable<any> {
    return this.restService
      .get(`EcomService/getEcomSubscriptions?ecomId=${ecomId}`)
      .pipe(map((response) => response.getData()));
  }

  public getWixCheckoutUrls(ecomId): Observable<any> {
    return this.restService
      .get(`PlanService/getWixCheckoutUrls?ecomId=${ecomId}`)
      .pipe(map((response) => response.getData()));
  }

  public postWoocommerceCredentials(ecomId: number, appType: number, ckKey: string, ckSecret: string): Observable<any> {
    return this.restService
      .post(`EcomService/updateWoocommerceCredentials`, { ckKey, ckSecret, ecomId, appType })
      .pipe(map((response) => response.getData()));
  }

  public setAsDefault(ecomId: number): Observable<any> {
    const params = {
      ecomId: ecomId,
    };

    return this.restService.post(`EcomService/setDefaultEcom`, params).pipe(map((response) => response.getData()));
  }

  public getWhiteLabelEcomType(ecomType, domain): EcomTypeEnum {
    switch (ecomType) {
      case 'ecwid':
        if (domain.includes('kmoshops.be')) {
          return EcomTypeEnum.KMOSHOPS;
        } else if (domain.includes('shopsettings.com')) {
          return EcomTypeEnum.ZYRO;
        } else {
          return ecomType;
        }
      default:
        return ecomType;
    }
  }

  public getEcomsByPlatformAndAppTypeAndMaybeUserId(platformType: string, appType: 1 | 2): Observable<EcomVO[]> {
    return this.restService
      .get(`EcomService/getEcomsByPlatformAndAppTypeAndMaybeUserId?platform=${platformType}&appType=${appType}`)
      .pipe(
        map((res) => {
          return res.getData();
        })
      );
  }
}

export class EcomVO {
  domain: string;
  ecomId: number;
  id: number;
  storeId: string;
  ecomType: string;
  options: {
    currency: string;
    multilocation: boolean;
    phone: string;
    isTest: boolean;
    paymentProvider: string;
    preferredCurrency: string;
    combinationsAvailable: boolean;
    fromWoo: boolean;
    country: string;
  };
  planId: number;
  planName: string;
  subscribed: string;
  freeTrialNumber: number;
  date: string;
  items: any[];
  maxProductsNumber: string;
  usedProductNumber: string;
  managedProducts: string;
  managedProductsToday: string;
  totalManagedProducts: string;

  paymentProvider: string;
  cycle: string;
  chargeId: string;
  frozen: string;
  uninstalled: string;
  subscriptions: {
    rmp: SubscriptionRMP;
    df: SubscriptionDF;
    // df: {
    //   subId?: any;
    //   planName: string;
    //   planId: number;
    //   price: number;
    //   managedProductsToday: number;
    //   usedProductNumber: number;
    //   maxProductsNumber: number;
    //   nummuspaySubscriptionId: number;
    //   hasCustomPlan: boolean;
    //   isLastChanged?: boolean;
    //   items: {
    //     label: string;
    //     value: number;
    //   }[];
    //   churnDate: string;
    //   automatedRunPerDay: boolean;
    //   accessChat: boolean;
    //   approveSupplierAccess: boolean;
    //   autoSyncNum: number;
    //   canSync: boolean;
    //   catalogNum: number;
    //   contactAccess: boolean;
    //   pim: boolean;
    //   premiumSupplierAccess: boolean;
    //   transactionFee: number;
    //   variantRenaming: boolean;
    //   vipSupplierAccess: boolean;
    //   freeTrialUntil: string;
    //   planStatus: number;
    //   productMonthlyPrice: number;
    //   productYearlyPrice: number;
    // };
    // rmp: {
    //   subId?: any;
    //   planName: string;
    //   planId: number;
    //   price: number;
    //   managedProductsToday: number;
    //   usedProductNumber: number;
    //   maxProductsNumber: number;
    //   nummuspaySubscriptionId: number;
    //   hasCustomPlan: boolean;
    //   isLastChanged?: boolean;
    //   items: {
    //     label: string;
    //     value: number;
    //   }[];
    //   churnDate: string;
    //   automatedRunPerDay: boolean;
    //   accessChat: boolean;
    //   approveSupplierAccess: boolean;
    //   autoSyncNum: number;
    //   canSync: boolean;
    //   catalogNum: number;
    //   contactAccess: boolean;
    //   pim: boolean;
    //   premiumSupplierAccess: boolean;
    //   transactionFee: number;
    //   variantRenaming: boolean;
    //   vipSupplierAccess: boolean;
    //   hasAnnual: boolean;
    //   freeTrialUntil: string;
    //   planStatus: number;
    //   productMonthlyPrice: number;
    //   productYearlyPrice: number;
    // };
    alibaba: {
      planName: string;
      planId: number;
      price: number;
      managedProductsToday: number;
      usedProductNumber: number;
      maxProductsNumber: number;
      nummuspaySubscriptionId: number;
      hasCustomPlan: boolean;
      items: {
        label: string;
        value: number;
      }[];
      churnDate: string;
    };
  };
  orderSyncType: number;
}
