import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-pagination-with-numbers',
  templateUrl: './pagination-with-numbers.component.html',
  styleUrls: ['./pagination-with-numbers.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule, MatIconModule, MatButtonModule],
})
export class PaginationWithNumbersComponent implements OnInit {
  @Input() maxRange = 10;
  @Input() maxNumber = 50;
  @Input() itemPerPage = 50;
  @Output() selectedIndexCallback = new EventEmitter<number>();

  private minRange = 1;
  private _selectedIndex = this.minRange;

  set selectedIndex(value: number) {
    this._selectedIndex = value;
    this.selectedIndexCallback.emit(value);
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  constructor() {}

  ngOnInit(): void {}

  minus(): void {
    if (this.selectedIndex > this.minRange) {
      this.selectedIndex--;
    }
  }

  plus(): void {
    if (this.selectedIndex < this.maxRange) {
      this.selectedIndex++;
    }
  }

  public setPageIndex(number: number): void {
    this.selectedIndex = number;
  }
}
