<div fxLayoutAlign="center center" fxLayoutGap="30px" class="notification-item-container">
  <div fxFlex="0 0 auto">
    <div
      class="noti-icon"
      [ngStyle]="{ 'background-color': item.settings.color, color: item.settings.iconColor ?? 'white' }"
      fxLayoutAlign="center center"
    >
      <mat-icon>{{ item.settings.icon }}</mat-icon>
    </div>
  </div>
  <div fxFlex="1 0 0">
    <div class="notification-title">{{ item.name }}</div>
    <div>{{ item.notification }}</div>
    <div class="notification-date syncee-grey-500-fg" *ngIf="!!item?.date">{{ item.date | date : 'short' }}</div>
  </div>
  <div class="status" fxFlex="0 0 auto" fxLayoutAlign="center center">
    <ng-container *ngIf="!isAdmin || (isAdmin && actualRole === roles.ADMIN)">
      <div class="remove-notification">
        <mat-icon (click)="deleteNotification.emit(item.id)">close</mat-icon>
      </div>
      <button
        matTooltip="{{ item.markAsRead ? 'Mark as unread' : 'Mark as read' }}"
        class="button-status"
        [class.readed]="item.markAsRead"
        (click)="changeStatus.emit(item.id)"
      ></button>
    </ng-container>
  </div>
</div>
