<app-alert-base
  [type]="type"
  [matIcon]="matIcon"
  [matIconColor]="matIconColor"
  [matIconClass]="matIconClass"
  [layout]="layout"
  [actionRef]="contentRef"
  [hideIcon]="hideIcon"
>
  <div class="alert-multi-messages" fxFlex="1 0 0" fxLayoutAlign="center start" fxLayout="column">
    <div class="alert-multi-message text-color" *ngFor="let message of messages" [innerHTML]="message"></div>
    <div class="alert-multi-read-more text-color" *ngIf="readMoreLink" [innerHTML]="readMoreLink"></div>
  </div>
</app-alert-base>

<ng-template #contentRef>
  <ng-content></ng-content>
</ng-template>
