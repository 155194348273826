import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { CountryNode, newCountries } from '../../../utils/Countries';

@Pipe({ name: 'countryNameToCodePipe', standalone: true })
export class CountryNameToCodePipe implements PipeTransform {
  constructor() {}
  transform(name: string): any {
    let country: CountryNode;
    const trimName = name?.trim();
    Object.keys(newCountries).forEach((continent) => {
      const foundCountry = newCountries[continent].find((item) => item.name === trimName);
      if (!Utils.isNullOrUndefined(foundCountry)) {
        country = foundCountry;
      }
    });
    return country.code;
  }
}
