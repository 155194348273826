import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { DoubleBorderIconComponent } from '../double-border-icon/double-border-icon.component';
import { GeneralButtonComponent } from '../general-button/general-button.component';

@Component({
  selector: 'app-feature-cards-row',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    TranslateModule,
    DoubleBorderIconComponent,
    GeneralButtonComponent,
  ],
  templateUrl: './feature-cards-row.component.html',
  styleUrls: ['./feature-cards-row.component.scss'],
})
export class FeatureCardsRowComponent implements OnInit {
  @Input() cardContent: CardContent[];
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;
  @Input() cardPadding = '40px 24px';

  constructor() {}

  ngOnInit(): void {}
}

export interface CardContent {
  key?: string;
  icon: string;
  iconColor: string;
  iconBackgroundColor: string;
  titleKey: string;
  descriptionKey: string;
  button?: GeneralButton;
}
