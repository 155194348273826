<div *ngIf="!loading">
  <h3 *ngIf="error">{{ 'BILLING.SUBSCRIPTION.PAYPAL_PROGRESS.SOMETHING_WRONG' | translate }}</h3>
  <h3 *ngIf="!error">{{ 'BILLING.SUBSCRIPTION.PAYPAL_PROGRESS.SUCCESSFUL' | translate }}</h3>
  <button mat-button color="primary" (click)="navigateToGuide()">
    {{ 'BILLING.SUBSCRIPTION.PAYPAL_PROGRESS.GO_TO' | translate }}
  </button>
</div>
<app-loading-spinner
  message="{{ 'BILLING.SUBSCRIPTION.PAYPAL_PROGRESS.PLEASE_WAIT' | translate }}"
  *ngIf="loading"
  [centered]="true"
></app-loading-spinner>
