import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { ChatService } from 'app/service/chat/chat.service';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { ChannelItem, ChatUser } from '../../../../service/chat/model/chat-models';
import { ChatHandlerService } from '../service/chat-handler.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-search-panel',
  templateUrl: './chat-search-panel.component.html',
  styleUrls: ['./chat-search-panel.component.scss'],
})
export class ChatSearchPanelComponent implements OnInit, AfterViewInit {
  @Input() searchTermObs: Observable<string>;
  @Input() channels: ChannelItem[];
  @Input() channelsLoading: boolean;
  @Input() hasLoadMore: boolean;
  @Input() selectedChannel: ChannelItem;
  @Output() supplierClicked = new EventEmitter<ChatUser>();
  @Output() channelClicked = new EventEmitter<ChannelItem>();
  @Output() loadMoreClicked = new EventEmitter<void>();
  notSearchedYet = true;
  activeTab: 'suppliers' | 'chats' = 'chats';
  lastSearchTerm = '';
  selectedUser: ChatUser;
  users: ChatUser[];
  suppliersLoading = false;

  constructor(
    private chatService: ChatService,
    private router: Router,
    private dialog: MatDialog,
    private handlerService: ChatHandlerService
  ) {}

  ngOnInit(): void {
    this.handlerService.setUnseenFilter(false);
  }

  ngAfterViewInit(): void {
    this.initSearch();
  }

  initSearch(): void {
    this.searchTermObs
      .pipe(
        filter((term) => term !== null && (term.length > 2 || term.length < 1)),
        untilDestroyed(this)
      )
      .subscribe((searchTerm) => {
        this.lastSearchTerm = searchTerm;
        if (this.activeTab === 'suppliers') {
          this.getSuppliers(searchTerm);
        }
      });
  }

  tabClick(clickedTab: 'suppliers' | 'chats'): void {
    if (this.activeTab === clickedTab) {
      return;
    }
    this.resetUsers();
    this.activeTab = clickedTab;
    if (this.activeTab === 'suppliers') {
      this.getSuppliers(this.lastSearchTerm);
    } else {
      return;
    }
  }

  getSuppliers(searchTerm: string): void {
    if (this.lastSearchTerm.length < 3) {
      this.resetUsers();
      return;
    }
    this.notSearchedYet = false;
    this.loadSpinner();
    this.chatService
      .getSuppliersBySearch(searchTerm)
      .pipe(
        tap((users) => {
          this.handleUsers(users);
        }),
        first()
      )
      .subscribe(() => this.unloadSpinner());
  }

  handleUsers(users): void {
    this.users = users;
  }

  supplierClickHandler(user: ChatUser): void {
    this.supplierClicked.emit(user);
    this.selectedUser = user;
  }

  loadSpinner(): void {
    this.suppliersLoading = true;
  }

  unloadSpinner(): void {
    this.suppliersLoading = false;
  }

  resetUsers(): void {
    this.users = [];
  }

  navigateToFindSuppliers(): void {
    this.dialog.closeAll();
    this.router.navigate([MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH], {
      queryParams: { category: 1, searchType: 'SUPPLIERS' },
    });
  }
}
