import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-slider-arrow',
  standalone: true,
  imports: [CommonModule, FlexModule, MatIconModule],
  templateUrl: './slider-arrow.component.html',
  styleUrls: ['./slider-arrow.component.scss'],
})
export class SliderArrowComponent implements OnInit {
  @Input() variant: 'next' | 'previous';

  constructor() {}

  ngOnInit(): void {}
}
