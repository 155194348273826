<div fxLayoutAlign="center center" fxLayoutGap="16px" fxLayoutGap.lt-md="4px">
  <ng-container *showOnBreakpoint="'gt-md'">
    <ng-container *ngIf="!content.hideSetupGuideButton">
      <app-setup-guide-button></app-setup-guide-button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!content.hideProfileButton">
    <app-profile-icon></app-profile-icon>
  </ng-container>
  <ng-container *showOnBreakpoint="'gt-md'">
    <ng-container *ngIf="!content.hideNotificationButton">
      <app-notification-icon></app-notification-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!content.hideFavouritesButton">
    <app-favorite-icon></app-favorite-icon>
  </ng-container>
  <ng-container *ngIf="!content.hideEcomSelector">
    <app-catalog-list-icon *filterRoles="[RolesEnum.RETAILER]"></app-catalog-list-icon>
  </ng-container>
  <ng-container *ngIf="!content.hideCartButton">
    <app-cart-icon></app-cart-icon>
  </ng-container>
  <app-extra-icons *ngIf="extraIcons && extraIcons.length > 0" [toolbarExtraIcons]="extraIcons"></app-extra-icons>
</div>
