import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgesVO } from '../../../vo/search-product-vo';
import { MatChipsModule } from '@angular/material/chips';
import { FlexModule } from '@angular/flex-layout';
import { MarketplaceBadgeDirective } from '../../directives/marketplace-badge.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MarketplaceBadgesPipe } from './marketplace-badges.pipe';
import { uniqBy } from 'lodash';
import { UniqbyPipe } from 'app/shared/pipes/uniqby.pipe';

@Component({
  selector: 'app-marketplace-badges',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    FlexModule,
    MarketplaceBadgeDirective,
    TranslateModule,
    MarketplaceBadgesPipe,
    UniqbyPipe,
  ],
  templateUrl: './marketplace-badges.component.html',
  styleUrls: ['./marketplace-badges.component.scss'],
})
export class MarketplaceBadgesComponent implements OnInit {
  @Input() badges: BadgesVO[];
  @Input() gap = '8px';

  constructor() {}

  ngOnInit(): void {}
}
