import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SupplierSearchGatewayService } from '../../../../service/supplier-search/supplier-search-gateway.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { Observable, Subject } from 'rxjs';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { selectedRetailerEcomSelector } from '../../../../store/ecom/ecom.selector';
import { takeUntil } from 'rxjs/operators';
//  TODO: Check if fetch works when thre's no ecom
@Component({
  selector: 'app-supplier-no-result-list',
  templateUrl: './supplier-no-result-list.component.html',
  styleUrls: ['./supplier-no-result-list.component.scss'],
})
export class SupplierNoResultListComponent implements OnInit, OnDestroy {
  @Input() selectedCategoryId: number;
  selectedEcom: Observable<EcomVO>;
  supplierIds: number[];
  isLoading = true;
  private unsubscribeAll: Subject<void>;

  constructor(private supplierSearchGatewayService: SupplierSearchGatewayService, private store: Store<AppState>) {
    this.unsubscribeAll = new Subject();
    this.selectedEcom = this.store.select(selectedRetailerEcomSelector);
  }

  ngOnInit(): void {
    this.subscribeToSelectedEcom();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToSelectedEcom(): void {
    this.selectedEcom.pipe(takeUntil(this.unsubscribeAll)).subscribe((ecom) => {
      this.isLoading = true;
      this.fetchSupplierIds(ecom);
    });
  }

  private fetchSupplierIds(ecom: EcomVO): void {
    this.supplierSearchGatewayService.getSupplierIdsBySearch(ecom, {}, { from: 0, size: 10 }).subscribe(
      (idsPage) => {
        this.supplierIds = idsPage.content;
      },
      () => {},
      () => (this.isLoading = false)
    );
  }
}
