import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FilterSelectorOption,
  FilterSelectorOptionBadge,
  FilterSelectorOptionGroup,
} from '../../filter-selector-base/filter-selector-base.component';
import { ProductSearchState } from '../../../../store/product-search/product-search.reducer';
import { AppState } from '../../../../app.state';
import { select, Store } from '@ngrx/store';
import { SupplierDataForFilter, SuppliersService } from '../../../../service/suppliers/suppliers.service';
import { debounce, isEmpty } from 'lodash';
import { RestResponseWithPagination } from '../../../../service/rest/rest-response';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { isNullOrUndef } from 'chart.js/helpers';

@Component({
  selector: 'app-supplier-filter',
  templateUrl: './supplier-filter.component.html',
  styleUrls: ['./supplier-filter.component.scss'],
})
export class SupplierFilterComponent implements OnInit, OnChanges {
  @Input() idFilterList: number[];
  @Input() hasClearAll = false;
  @Input() multiple = false;
  @Input() value: string | string[];
  @Input() overlayOpen: boolean;
  @Output() valueChange = new EventEmitter<string | string[]>();
  @Output() supplierChange = new EventEmitter<SelectedSupplier | SelectedSupplier[]>();
  @Output() clearAll = new EventEmitter<void>();

  private static initialSize = 4;
  private static size = 20;
  selectedSupplier: SelectedSupplier[] | SelectedSupplier;
  noOptionsAvailable = false;
  options: FilterSelectorOptionGroup<string, string>[] = [{ options: [] }];
  displayedOptions: FilterSelectorOptionGroup<string, string>[] = [{ options: [] }];
  productSearchStore$: Observable<ProductSearchState>;
  searchTerm: string;
  hasSeeMore = true;
  totalNumber = SupplierFilterComponent.size * 2;
  debouncedLoadNewOptions: (closingOverlay: boolean) => void;
  private from = 0;

  constructor(private store: Store<AppState>, private supplierService: SuppliersService) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
    this.debouncedLoadNewOptions = debounce(this.loadNewOptions, 500);
  }

  ngOnInit(): void {
    this.loadInitialOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.overlayOpen || changes.overlayOpen.currentValue || changes.overlayOpen.isFirstChange()) {
      return;
    }

    this.handleSearchTermChange('', true);
  }

  handleSelectionChange(selected: string | string[]): void {
    this.valueChange.emit(selected);
    if (Array.isArray(selected)) {
      this.handleArraySelection(selected);
    } else {
      this.handleSingleSelection(selected);
    }
    this.supplierChange.emit(this.selectedSupplier);
  }

  private handleSingleSelection(selectedKey: string): void {
    const supplier: FilterSelectorOption<string, string> = this.options[0].options.find(
      (option) => option.key === selectedKey
    );
    this.selectedSupplier = !!supplier ? { id: Number(supplier.key), name: supplier.value } : null;
    if (this.multiple && isNullOrUndef(selectedKey)) {
      this.selectedSupplier = [];
    }
  }

  private handleArraySelection(selectedKeys: string[]): void {
    const selectedSuppliers: SelectedSupplier[] = [];
    this.options[0].options.forEach((option) => {
      if (selectedKeys.includes(option.key)) {
        selectedSuppliers.push({
          id: Number(option.key),
          name: option.value,
        });
      } else {
        return;
      }
    });
    this.selectedSupplier = selectedSuppliers;
  }

  onSeeMore(): void {
    this.displayedOptions[0].options = [...this.options[0].options];
  }

  onSeeLess(): void {
    this.getInitialOptions();
  }

  loadInitialOptions(): void {
    this.fetchSuppliers(SupplierFilterComponent.size, this.searchTerm)
      .pipe(take(1))
      .subscribe((res) => {
        this.handleSupplierResponse(res);
        this.getInitialOptions();
        this.increaseFrom(SupplierFilterComponent.size);
      });
  }

  getInitialOptions(): void {
    this.displayedOptions[0].options = [...this.options[0].options.slice(0, 4)];
  }

  loadMore(closingOverlay?: boolean): void {
    this.fetchSuppliers(SupplierFilterComponent.size, this.searchTerm)
      .pipe(take(1))
      .subscribe((res) => {
        this.handleSupplierResponse(res);
        if (closingOverlay) {
          this.getInitialOptions();
        } else {
          this.displayedOptions[0].options = [...this.options[0].options];
        }
        this.increaseFrom(SupplierFilterComponent.size);
      });
  }

  private handleSupplierResponse(res: RestResponseWithPagination<SupplierDataForFilter>): void {
    const resultOptions = !!this.idFilterList ? this.filterSupplierOptions(res.getData()) : res.getData();
    this.noOptionsAvailable = isEmpty(resultOptions);
    if (this.noOptionsAvailable) {
      return;
    }
    this.totalNumber = res.totalCount;
    this.hasSeeMore = res.totalCount > SupplierFilterComponent.initialSize;
    this.options = [{ options: [...this.options[0].options, ...this.mapSuppliersToOptions(resultOptions)] }];
  }

  handleSearchTermChange(value: string, closingOverlay = false): void {
    this.searchTerm = value;
    this.debouncedLoadNewOptions(closingOverlay);
    this.hasSeeMore = this.displayedOptions[0].options.length > SupplierFilterComponent.initialSize;
  }

  private loadNewOptions(closingOverlay?: boolean): void {
    this.options = [{ options: [] }];
    this.displayedOptions = [{ options: [] }];
    this.from = 0;
    this.loadMore(closingOverlay);
  }

  private fetchSuppliers(
    size: number,
    searchTerm?: string
  ): Observable<RestResponseWithPagination<SupplierDataForFilter>> {
    return this.supplierService.getSupplierDataForFilter(this.from, size, searchTerm);
  }

  private mapSuppliersToOptions(suppliers: SupplierDataForFilter[]): FilterSelectorOption<string, string>[] {
    return suppliers.map((supplier) => {
      const badges: FilterSelectorOptionBadge[] = [];
      if (supplier.isNew) {
        badges.push({ text: 'NEW', textColor: 'white', backgroundColor: 'black' });
      }
      // if (supplier.vip) {
      //   badges.push({ text: 'VIP', textColor: 'white', backgroundColor: '#286ef9' });
      // }
      // if (supplier.premium) {
      //   badges.push({ text: 'PREMIUM', textColor: 'white', backgroundColor: 'black' });
      // }
      return {
        value: supplier.companyName,
        key: supplier.userId.toString(),
        badges,
        image: null,
      };
    });
  }

  private increaseFrom(increaseBy: number): void {
    this.from += increaseBy;
  }

  onClearAll(): void {
    this.clearAll.emit();
  }

  private filterSupplierOptions(suppliers: SupplierDataForFilter[]): SupplierDataForFilter[] {
    return suppliers.filter((supplier) => this.idFilterList.includes(supplier.userId));
  }
}

export interface SelectedSupplier {
  id: number;
  name: string;
}
