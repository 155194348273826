<div class="category-selector-container">
  <div
    class="category-selector"
    #trigger
    (mouseenter)="debouncedHandleMainCategoriesMouseEnter()"
    (mouseleave)="handleMainCategoriesMouseLeave()"
  >
    <ng-container *ngIf="categories">
      <mat-tab-group #tabGroup [(selectedIndex)]="tabSelectedIndex" *ngIf="categories">
        <mat-tab></mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="tab-label" (mouseenter)="onNewProductsHover()" (click)="onNewProductsClick()">
              {{ 'CATEGORIES_SELECTOR.NEW_PRODUCTS' | translate }}
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="tab-label" (mouseenter)="onAllProductsHover()" (click)="onAllProductsClick()">
              {{ 'CATEGORY_SELECTOR.ALL_PRODUCTS' | translate }}
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab *ngFor="let category of categories.children">
          <ng-template mat-tab-label>
            <div
              class="tab-label"
              (mouseenter)="handleCategoryHovered(category.id)"
              (click)="handleMainCategoryClicked(category)"
            >
              {{ category.id | categoryNameById | async }}
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
  <div
    class="submenu-container"
    (mouseenter)="handleSubmenuOverlayMouseEnter()"
    (mouseleave)="handleSubmenuOverlayMouseLeave()"
  >
    <div *ngIf="isSubmenuOverlayOpen && hoveredMainCategory && hoveredMainCategory.children" class="submenu-overlay">
      <app-category-selector-popup-content
        [mainCategory]="hoveredMainCategory"
        (filterSelected)="emitFilterSelected($event)"
      >
      </app-category-selector-popup-content>
    </div>
  </div>
</div>
