<div class="settings-source-wrapper" fxLayout="row" fxLayoutAlign="start start" fxFlex="1 0 auto" fxLayoutGap="15px">
  <div fxFlex="{{ fileUpload_container }}" [hidden]="hideFileUploadContainer">
    <ng-template #source_container></ng-template>
  </div>
  <div class="vl" fxFlexAlign="center" [hidden]="isSettingsHidden()"></div>
  <div fxFlex="1 0 27" [hidden]="isSettingsHidden()">
    <div fxLayout="column" fxLayoutAlign="start">
      <h2>
        {{ 'TASKWIZARD.ADDFILES.TABS.SETTINGS' | translate }}
      </h2>
      <ng-template #settings_container></ng-template>
    </div>
  </div>
</div>
