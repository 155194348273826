import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { CommonModule } from '@angular/common';
import { SingleAlertWithIconComponent } from '../single-alert-with-icon/single-alert-with-icon.component';

@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
  standalone: true,
  imports: [CommonModule, SingleAlertWithIconComponent],
})
export class Error404Component implements OnInit, OnDestroy {
  button: GeneralButton = {
    type: 'inner_redirect',
    textKey: 'PAGE_NOT_FOUND.BUTTON',
    buttonClass: 'syncee-blue-button',
    url: '/',
  };

  constructor(private fuseConfigService: FuseConfigService) {}

  ngOnInit(): void {
    this.initTheme();
  }

  ngOnDestroy(): void {}

  initTheme(): void {
    this.fuseConfigService.config = {
      layout: { navbar: { hidden: true } },
    };
  }
}
