import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupplierGatewayService } from 'app/service/suppliers/supplier-gateway.service';
import { defaultRefundPolicy } from 'app/utils/Constants';
import { Observable, of } from 'rxjs';
import { ReturnAndRefundPolicyService } from '../../../service/user/return-and-refund-policy/return-and-refund-policy.service';
import {
  ReturnAndRefundPolicyDialogComponent,
  ReturnAndRefundPolicyDialogData,
} from '../../../shared/components/dialogs/return-and-refund-policy-dialog/return-and-refund-policy-dialog.component';
import { SupplierReturnAndRefundPolicyDto } from '../../../vo/return-and-refund-policy';

@Component({
  selector: 'app-return-and-refund-policy',
  templateUrl: './return-and-refund-policy.component.html',
  styleUrls: ['./return-and-refund-policy.component.scss'],
})
export class ReturnAndRefundPolicyComponent implements OnInit {
  @Input() supplierId: number;
  policy: Observable<SupplierReturnAndRefundPolicyDto>;

  constructor(
    private returnAndRefundPolicyService: ReturnAndRefundPolicyService,
    private dialog: MatDialog,
    private supplierGateway: SupplierGatewayService
  ) {}

  ngOnInit(): void {
    this.getPolicy();
  }

  private getDefaultPolicy(): void {
    this.supplierGateway.getSingleSupplier(this.supplierId).subscribe((s) => {
      this.policy = of({
        returnAndRefund: defaultRefundPolicy(s.companyName),
        url: '',
        noPolicies: false,
      });
    });
  }

  private getPolicy(): void {
    this.returnAndRefundPolicyService.getByUserId(this.supplierId).subscribe({
      next: (v) => {
        this.policy = of(v);
      },
      error: (e) => {
        this.getDefaultPolicy();
      },
    });
  }

  openPolicyDialog(data: SupplierReturnAndRefundPolicyDto): void {
    this.dialog.open<ReturnAndRefundPolicyDialogComponent, ReturnAndRefundPolicyDialogData>(
      ReturnAndRefundPolicyDialogComponent,
      { data: { supplierPolicyData: data }, autoFocus: false, restoreFocus: false }
    );
  }
}
