<div [ngClass]="wrapperClass">
  <div [ngClass]="innerClass">
    <app-section-title [title]="title" [description]="description"></app-section-title>
    <div
      class="cards"
      [style.grid-template-columns]="'repeat(' + columns + ', 1fr)'"
      [ngClass]="ltMd ? 'pt-12' : 'pt-36'"
    >
      <ng-container *ngFor="let card of items">
        <div class="card">
          <div class="icon-container mb-20" [style.background]="card.iconBackgroundColor">
            <mat-icon class="s-28" [style.color]="card.iconColor">{{ card.icon }}</mat-icon>
          </div>
          <h3 class="mt-0" [ngClass]="ltMd ? 'mb-8' : 'mb-20'" [class.h4]="ltMd">{{ card.title | translate }}</h3>
          <div [ngClass]="ltMd ? 'typography-h4-subtitle' : 'typography-h3-subtitle'">
            {{ card.description | translate }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
