import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { Store } from '@ngrx/store';
import { CurrencyExchangeToPipe } from 'app/shared/pipes/currency-exchange-to.pipe';
import { Utils } from 'app/utils/utils';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { Currencies, CurrencyService } from '../../../../service/currency-service/currency.service';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { retailerCurrencySelector } from '../../../../store/currency/currency.selector';

@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss'],
  providers: [CurrencyExchangeToPipe],
})
export class PriceFilterComponent implements OnInit, OnDestroy {
  @Input() value: PriceFilterValue;
  @Input() hasClearAll = false;
  @Output() valueChange = new EventEmitter<PriceFilterValue>();

  @Output() clearAll = new EventEmitter<void>();

  @ViewChildren('preDefined') preDefined: QueryList<MatRadioButton>;
  @ViewChild('userDefined', { static: false }) userDefined: MatRadioButton;

  private unsubscribeAll: Subject<void>;
  debouncedMaxChange: (event: Event) => void;
  debouncedMinChange: (event: Event) => void;
  ecomCurrency = 'USD';
  predefinedPrices: PriceSet[] = [
    { min: null, max: 5, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.UNDER' },
    { min: 5, max: 10, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.RANGE' },
    { min: 10, max: 20, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.RANGE' },
    { min: 20, max: null, template: 'EXPLORE_PRODUCTS.TOP_SEARCH.PRICE_FILTER.ABOVE' },
  ];

  userDefinedPrices: Partial<PriceSet> = {
    min: null,
    max: null,
  };

  constructor(
    private currencyService: CurrencyService,
    private marketplaceEcomService: MarketplaceEcomService,
    private store: Store<AppState>,
    private currencyExchange: CurrencyExchangeToPipe
  ) {
    this.debouncedMaxChange = debounce(this.handleMaxChange, 350);
    this.debouncedMinChange = debounce(this.handleMinChange, 350);
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.subscribeToEcomChange();
    this.getEcomData();
    this.userDefinedPrices.max = this.exchangeCurrency(this.value.maxPrice, 'USD', this.ecomCurrency);
    this.userDefinedPrices.min = this.exchangeCurrency(this.value.minPrice, 'USD', this.ecomCurrency);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private handleMaxChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.userDefinedPrices.max = Number(target.value);

    this.valueChange.emit({
      minPrice: this.userDefinedPrices.min
        ? this.exchangeCurrency(this.userDefinedPrices.min, this.ecomCurrency, 'USD')
        : undefined,
      maxPrice: this.exchangeCurrency(target.value, this.ecomCurrency, 'USD'),
    });
  }

  private handleMinChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.userDefinedPrices.min = Number(target.value);

    this.valueChange.emit({
      minPrice: this.exchangeCurrency(target.value, this.ecomCurrency, 'USD'),
      maxPrice: this.userDefinedPrices.max
        ? this.exchangeCurrency(this.userDefinedPrices.max, this.ecomCurrency, 'USD')
        : undefined,
    });
  }

  private exchangeCurrency(value: number | string, from: string, to: string): number {
    let result: number;

    this.currencyExchange
      .transform(+value, from, to)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          result = res;
        },
      });

    return result;
  }

  private subscribeToEcomChange(): void {
    this.marketplaceEcomService.onDomainChange.pipe(takeUntil(this.unsubscribeAll)).subscribe((ecom) => {
      this.handleCurrency(this.marketplaceEcomService.getCurrency());
    });
  }

  private subscribeToEcomInit(): void {
    this.marketplaceEcomService.initialized.pipe(takeUntil(this.unsubscribeAll)).subscribe((ecom) => {
      this.handleCurrency(this.marketplaceEcomService.getCurrency());
    });
  }

  private handleCurrency(currency: string): void {
    this.ecomCurrency = currency;
    this.predefinedPrices = this.predefinedPrices.map((priceSet) => ({
      ...priceSet,
      calculatedMax: this.calculatePrice(priceSet.max, currency),
      calculatedMin: this.calculatePrice(priceSet.min, currency),
    }));
  }

  private calculatePrice(value: number, currency: string): number {
    if (Utils.isNullOrUndefined(value)) {
      return undefined;
    } else {
      const currencies = this.getCurrencies();
      return value * (currencies[currency] ? currencies[currency] : 1);
    }
  }

  private getCurrencies(): Currencies {
    let currencies: Currencies;
    this.store
      .select(retailerCurrencySelector)
      .pipe(take(1))
      .subscribe((c) => {
        currencies = c;
      });
    return currencies;
  }

  private getEcomData(): void {
    if (this.marketplaceEcomService.initialized) {
      this.handleCurrency(this.marketplaceEcomService.getCurrency());
    } else {
      this.subscribeToEcomInit();
    }
  }

  onValueChange(priceValue: PriceFilterValue): void {
    this.valueChange.emit(priceValue);
  }

  handlePredefinedSelect(): void {
    this.userDefinedPrices.min = null;
    this.userDefinedPrices.max = null;
  }

  handleInputChangeEvent(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  handleClearAllClicked(): void {
    this.preDefined.forEach((price) => (price.checked = false));
    this.userDefined.checked = false;
    this.userDefinedPrices.min = null;
    this.userDefinedPrices.max = null;
    this.clearAll.emit();
  }
}

interface PriceSet {
  min: number;
  calculatedMin?: number;
  max: number;
  calculatedMax?: number;
  template: string;
}

export interface PriceFilterValue {
  minPrice?: number;
  maxPrice?: number;
}
