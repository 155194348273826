<mat-form-field class="collection-selector" fxFlexAlign.lt-md="end" appearance="fill">
  <mat-label>{{ 'IMPORT_LIST.PRICING.COLLECTION_PLACEHOLDER' | translate }}</mat-label>
  <mat-select [(ngModel)]="selectedCollectionId" (ngModelChange)="collectionChanged()">
    <mat-option [value]="''">{{ 'IMPORT_LIST.PRICING.COLLECTION_GENERAL' | translate }}</mat-option>
    <app-custom-divider></app-custom-divider>
    <mat-optgroup label="{{ 'IMPORT_LIST.PRICING.CREATED_RULES_FOR' | translate }}">
      <mat-option *ngFor="let collection of collectionsWithPriceSettings" [value]="collection.id">
        {{ collection.title }}
      </mat-option>
    </mat-optgroup>
    <mat-optgroup
      label="{{
        collectionsWithPriceSettings.length > 0
          ? ('IMPORT_LIST.PRICING.OTHER_COLLECTIONS' | translate)
          : ('IMPORT_LIST.PRICING.ALL_COLLECTIONS' | translate)
      }}"
    >
      <mat-option *ngFor="let collection of otherCollections" [value]="collection.id">
        {{ collection.title }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
