import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EcomVO } from '../../service/ecom/ecom.service';
import { Intercom } from 'ng-intercom';
import { Subject } from 'rxjs';
import { SubscriptionCancelService } from '../../service/subscription-cancel/subscription-cancel.service';
import { ReasonToCancelSubscription } from './cancel-dialog-stepper/cancel-dialog-reason/cancel-dialog-reason.component';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
})
export class CancelDialogComponent implements OnInit {
  stepperIndex = 0;
  numberOfSteps = 3;
  steps = [
    {
      type: CancelDialogStep.INFO,
      nextButtonText: 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.CONTINUE',
      needValidation: false,
    },
    {
      type: CancelDialogStep.SUBSCRIBE,
      nextButtonText: 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.NO_THANKS',
      needValidation: false,
    },
    {
      type: CancelDialogStep.REASON,
      nextButtonText: 'BILLING.SUBSCRIPTION.CANCEL_DIALOG.ACTION_BAR.CONFIRM',
      needValidation: true,
    },
  ];
  selectedPlanType: PlanType;
  nextClicked: Subject<void> = new Subject();

  private reasons: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CancelDialogData,
    private dialog: MatDialogRef<CancelDialogComponent>,
    private intercom: Intercom,
    private subscriptionCancelService: SubscriptionCancelService
  ) {}

  ngOnInit(): void {
    this.selectedPlanType = this.selectPlanTypeToShow();
  }

  public closeWithoutCancel(): void {
    const data: CancelDialogResult = {
      type: 'withoutCancellation',
    };
    this.dialog.close(data);
  }

  public closeWithCancel(): void {
    const data: CancelDialogResult = {
      type: 'withCancellation',
      reasons: this.reasons,
    };
    this.dialog.close(data);
  }

  public closeWithSetAnotherPlan(isAnnual: boolean, planId: number): void {
    const data: CancelDialogResult = {
      type: 'setPlan',
      cycleToSet: isAnnual ? 1 : 0,
      planIdToSet: planId,
      planTypeToSet: this.selectedPlanType,
    };
    this.dialog.close(data);
  }

  public handleContactUsClicked(): void {
    this.closeWithoutCancel();
    this.intercom.show();
  }

  public goToNextStep(): void {
    if (this.numberOfSteps !== this.stepperIndex + 1) {
      this.stepperIndex += 1;
    } else {
      this.closeWithCancel();
    }
  }

  public handleNextStepClick(): void {
    if (this.steps[this.stepperIndex].needValidation) {
      this.nextClicked.next();
    } else {
      this.goToNextStep();
    }
  }

  public goToPrevStep(): void {
    if (this.stepperIndex !== 0) {
      this.stepperIndex -= 1;
    }
  }

  private selectPlanTypeToShow(): PlanType {
    if (
      this.data.selectedEcom.subscriptions.rmp.planId !== 120 &&
      this.data.selectedEcom.subscriptions.df.planId !== 100
    ) {
      return 'rmp';
    }
    if (this.data.selectedEcom.subscriptions.rmp.planId !== 120) {
      return 'rmp';
    }
    if (this.data.selectedEcom.subscriptions.df.planId !== 100) {
      return 'df';
    }
  }

  public handleReasonStep(value: ReasonToCancelSubscription): void {
    this.reasons = value.reasons;
    this.subscriptionCancelService
      .addReasonsToEcom({
        reasons: value.reasons,
        comment: value.comment,
        ecomId: this.data.selectedEcom.ecomId,
      })
      .subscribe(() => {
        this.goToNextStep();
      });
  }

  get isLastStep(): boolean {
    return this.stepperIndex === this.numberOfSteps - 1;
  }

  get isFirstStep(): boolean {
    return this.stepperIndex === 0;
  }
}

export interface CancelDialogData {
  selectedEcom: EcomVO;
}

export interface CancelDialogResult {
  type: DialogCloseType;
  planIdToSet?: number;
  cycleToSet?: number;
  planTypeToSet?: PlanType;
  reasons?: string[];
}

export type PlanType = 'rmp' | 'df';

export type DialogCloseType = 'withoutCancellation' | 'withCancellation' | 'setPlan';

interface ReasonToCancel {
  reasons: string[];
  comment: string;
}

enum CancelDialogStep {
  INFO,
  SUBSCRIBE,
  REASON,
}
