<app-dialog-multi-wrapper
  type="info"
  [title]="'EMAIL_VERIFICATION.DIALOG.VERIFICATION_LINK_SENT_DIALOG.TITLE' | translate"
  [hideCloseButton]="true"
  [confirmButtonText]="'EMAIL_VERIFICATION.DIALOG.VERIFICATION_LINK_SENT_DIALOG.OK' | translate"
  (confirmClick)="handleOkClick()"
>
  <div class="centered-text" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div>{{ 'EMAIL_VERIFICATION.DIALOG.VERIFICATION_LINK_SENT_DIALOG.TEXT1' | translate }}</div>
    <ng-container *ngIf="!!data.email">
      <div>
        {{ 'EMAIL_VERIFICATION.DIALOG.VERIFICATION_LINK_SENT_DIALOG.TEXT2' | translate : { email: data.email } }}
      </div>
    </ng-container>
  </div>
</app-dialog-multi-wrapper>
