import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AlertBaseType } from '../alert-base/alert-base.component';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-custom-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule, FlexModule],
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent implements OnInit {
  @Input()
  icon: string;
  @Input()
  type: AlertBaseType;
  @Input()
  iconSizeClass = 's-16';
  @Input()
  containerSize = '20px';

  constructor() {}

  ngOnInit(): void {}
}
