import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { Currency, Value } from '../model/variables/general.variables';
import { Items } from '../model/variables/marketplace.variables';

export class ViewItemGtmAction implements GtmAction {
  event = GtmEvent.view_item;

  constructor(public payload?: ViewItemGtmActionPayload) {
    this.payload = { ...payload };
  }
}

export interface ViewItemGtmActionPayload extends Value, Currency, Items {}
