import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { MySuppliersSlice } from '../model/cache-slice';
import type { SupplierTask } from 'app/vo/supplier/supplier-task';
import type { ManagedProductNumber } from '../model/managed-product-number';
import type { WholesalePriceAndOrderNumber } from '../model/wholesale-price-and-order-number';
import type { MySuppliersCache } from '../model/my-suppliers-cache';
import type { SupplierWithBadgesAndAutoOrder } from 'app/vo/supplier/supplier-with-badges-and-auto-order';
import { share, take } from 'rxjs/operators';
import { MySuppliersHelperService } from './my-suppliers-helper.service';

@Injectable()
export class MySuppliersCacheService {
  public static DEFAULT_CACHE_VALUE: MySuppliersCache = {
    [MySuppliersSlice.SUPPLIERS]: {},
    [MySuppliersSlice.CATALOGS]: {},
    [MySuppliersSlice.MANAGED_PRODUCT_NUMBERS]: {},
    [MySuppliersSlice.WHOLESALE_PRICES_AND_ORDER_NUMBERS]: {},
  };

  constructor(private mySuppliersHelperService: MySuppliersHelperService) {}

  private cache$ = new BehaviorSubject<MySuppliersCache>(MySuppliersCacheService.DEFAULT_CACHE_VALUE);

  public get cache(): Observable<MySuppliersCache> {
    return this.cache$.asObservable().pipe(share());
  }

  public setCache(cache: MySuppliersCache): void {
    this.cache$.next(cache);
  }

  public reset(): void {
    this.setCache(MySuppliersCacheService.DEFAULT_CACHE_VALUE);
  }

  public hasCachedValue(slice: MySuppliersSlice, key: number): Observable<boolean> {
    return this.cache$.pipe(map((cache) => !!cache[slice][key]));
  }

  public getUncachedEntityIDs(slice: MySuppliersSlice, ids: number[]): Observable<number[]> {
    return this.cache.pipe(
      take(1),
      map((cache) => ids.filter((id) => !cache[slice].hasOwnProperty(id)))
    );
  }

  public getUncachedEntityIDsOperator(slice: MySuppliersSlice): (source: Observable<number[]>) => Observable<number[]> {
    return (source): Observable<number[]> => source.pipe(switchMap((ids) => this.getUncachedEntityIDs(slice, ids)));
  }

  public cacheCatalogsOneByOne(catalogs: SupplierTask[]): void {
    if (!catalogs.length) {
      return;
    }
    const cache: MySuppliersCache = this.cache$.getValue();
    const sliceName = MySuppliersSlice.CATALOGS;
    this.handleCachedEntityAmountLimitation(cache, sliceName, catalogs.length);
    catalogs.forEach((catalog: SupplierTask): void => {
      cache[sliceName][catalog.id] = catalog;
    });

    this.setCache(cache);
  }

  public getCatalogFromCache(catalogID: number): Observable<SupplierTask> {
    return this.mySuppliersHelperService.getCatalogFromCache(catalogID, this.cache);
  }

  public cacheSuppliersOneByOne(suppliers: SupplierWithBadgesAndAutoOrder[]): void {
    const cache: MySuppliersCache = this.cache$.getValue();
    const sliceName = MySuppliersSlice.SUPPLIERS;
    this.handleCachedEntityAmountLimitation(cache, sliceName, suppliers.length);
    for (const supplier of suppliers) {
      cache[sliceName][supplier.userId] = supplier;
    }

    this.setCache(cache);
  }

  public getSupplierFromCache(userID: number): Observable<SupplierWithBadgesAndAutoOrder> {
    return this.mySuppliersHelperService.getSupplierFromCache(userID, this.cache);
  }

  public cacheManagedProductNumbersOneByOne(managedProductNumbers: ManagedProductNumber[]): void {
    const cache: MySuppliersCache = this.cache$.getValue();
    const sliceName = MySuppliersSlice.MANAGED_PRODUCT_NUMBERS;
    this.handleCachedEntityAmountLimitation(cache, sliceName, managedProductNumbers.length);
    for (const managedProductNumber of managedProductNumbers) {
      cache[sliceName][managedProductNumber.catalogId] = managedProductNumber;
    }

    this.setCache(cache);
  }

  public getManagedProductNumberFromCache(catalogID: number): Observable<ManagedProductNumber> {
    return this.mySuppliersHelperService.getManagedProductNumberFromCache(catalogID, this.cache);
  }

  public cacheWholesalePricesAndOrderNumbersOneByOne(
    wholesalePricesAndOrderNumbers: WholesalePriceAndOrderNumber[]
  ): void {
    const cache: MySuppliersCache = this.cache$.getValue();
    const itemsToCache = wholesalePricesAndOrderNumbers;
    const sliceName = MySuppliersSlice.WHOLESALE_PRICES_AND_ORDER_NUMBERS;
    this.handleCachedEntityAmountLimitation(cache, sliceName, itemsToCache.length);
    for (const wholesalePriceAndOrderNumber of wholesalePricesAndOrderNumbers) {
      cache[MySuppliersSlice.WHOLESALE_PRICES_AND_ORDER_NUMBERS][wholesalePriceAndOrderNumber.catalogId] =
        wholesalePriceAndOrderNumber;
    }

    this.setCache(cache);
  }

  private handleCachedEntityAmountLimitation(
    cache: MySuppliersCache,
    slice: MySuppliersSlice,
    newItemsAmount: number,
    limit = DEFAULT_CACHED_ENTITY_LIMIT
  ): void {
    const cachedEntries = Object.entries(Object.entries(cache[slice]));
    const excessAmount = newItemsAmount + cachedEntries.length - limit;
    if (excessAmount > 0) {
      cachedEntries.splice(0, excessAmount);
      cachedEntries.forEach(([key, value]) => {
        cache[slice][key] = value;
      });
    }
  }

  public getWholesalePriceAndOrderNumberFromCache(catalogID: number): Observable<WholesalePriceAndOrderNumber> {
    return this.mySuppliersHelperService.getWholesalePriceAndOrderNumberFromCache(catalogID, this.cache);
  }
}

export const DEFAULT_CACHED_ENTITY_LIMIT = 300;
