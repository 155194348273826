import { Injectable } from '@angular/core';
import { FilterItemError } from '../model/helper/filter-item-error';
import { FilterGroup } from '../model/filter-group';
import { FilterItemBaseErrorState } from '../model/helper/filter-item-base-error-state';
import { FilterItem } from '../model/filter-item';
import { EXIST_CONDITIONS } from '../../../utils/Constants';

@Injectable({
  providedIn: 'root',
})
export class FilterBaseErrorCheckService {
  constructor() {}

  checkForBaseErrors(originalGroup: FilterGroup): FilterItemError[] {
    const errors: FilterItemError[] = [];
    const mapper = (group: FilterGroup) => {
      group.filterItems.forEach((item) => {
        const errorState = this.getErrorState(item);
        if (this.hasError(errorState)) {
          errors.push({ id: item.id, errors: errorState });
        }
      });
      group.children.forEach((child) => mapper(child));
    };
    mapper(originalGroup);
    return errors;
  }

  private hasError(state: FilterItemBaseErrorState): boolean {
    return state.value.length > 0 || state.field.length > 0 || state.condition.length > 0;
  }

  private getErrorState(item: FilterItem): FilterItemBaseErrorState {
    const errorState: FilterItemBaseErrorState = { value: [], field: [], condition: [] };
    if (!EXIST_CONDITIONS.includes(item.condition) && item.values.length === 0) {
      errorState.value.push('At least one value need to be added');
    }
    if (!item.condition) {
      errorState.condition.push('Please select a condition');
    }
    if (!item.field) {
      errorState.field.push('Please select a field');
    }
    return errorState;
  }
}
