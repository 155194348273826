import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { SupplierCatalogService } from '../../../../service/task/supplier-catalog.service';
import { ProductField } from '../../../../service/taskwizard/taskwizard.service';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { TaskSample } from '../../../../vo/task/task-sample';
import { FileProcessService } from '../../../../service/file-process/file-process.service';

@Injectable()
export class TaskWizardSampleService {
  private last = false;
  private lastIndex = false;
  private allSamples: TaskWizardSample[] = [];
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading$: Observable<boolean> = this._isLoading$.asObservable();
  hasError: boolean;
  productSample: BehaviorSubject<ProductField[]>;

  constructor(private supplierCatalogService: SupplierCatalogService, private fileProcessService: FileProcessService) {
    this.productSample = new BehaviorSubject<ProductField[]>(null);
  }

  private addSample(fileId: number, fetchedSamples: TaskSample[], savedSamples: TaskSample[], index: number): void {
    const otherSamples = !!this.allSamples
      ? this.allSamples.filter((taskSamples) => taskSamples.fileId !== fileId)
      : [];
    let newSamplesForFiles: TaskSample[] = [];
    if (!!savedSamples) {
      newSamplesForFiles = newSamplesForFiles.concat(savedSamples);
    }
    if (!!fetchedSamples) {
      newSamplesForFiles = newSamplesForFiles.concat(fetchedSamples);
    }
    this.checkLastIndex(newSamplesForFiles, index);
    this.allSamples = otherSamples.concat({
      fileId,
      samples: newSamplesForFiles,
      nextPage: this.getPageForFile(fileId) + 1,
    });
  }

  private emitProductSample(fileId: number, index: number): void {
    const sampleForFile = this.allSamples.find((sample) => sample.fileId === fileId);
    const samples = !!sampleForFile ? sampleForFile.samples : [];
    this.checkLastIndex(samples, index);
    this.productSample.next(!!samples[index] ? this.mapTaskSampleToProductFields(samples[index]) : null);
  }

  private checkLastIndex(samples: TaskSample[], index: number): void {
    this.lastIndex = samples.length - 1 === index;
  }

  private fetchProducts(
    catalogId: number,
    fileId: number,
    samples: TaskSample[],
    index: number,
    retry: boolean,
    extension: string
  ): void {
    this.getProductSamples(catalogId, fileId, retry, extension).subscribe((fetchedSamples) => {
      this.addSample(fileId, fetchedSamples, samples, index);
      this.emitProductSample(fileId, index);
    });
  }

  private getProductSamples(
    catalogId: number,
    fileId: number,
    retry: boolean,
    extension: string
  ): Observable<TaskSample[]> {
    const maxRetries = 5;
    let retryCount = 0;

    if (this.last && !retry) {
      this._isLoading$.next(false);
      return of([]);
    }

    this._isLoading$.next(true);

    const page = this.getPageForFile(fileId);

    return (
      page === 0 && retry && extension != 'csv' && extension != 'xls' && extension != 'xlsx'
        ? this.fileProcessService.getAndSetNodeTree(fileId, catalogId)
        : of(null)
    ).pipe(
      catchError(() => {
        this._isLoading$.next(false);
        return throwError(() => new Error());
      }),
      switchMap(() =>
        retry
          ? this.supplierCatalogService.getSampleProductsRetry(fileId, page, 10, maxRetries)
          : this.supplierCatalogService.getSampleProducts(fileId, page)
      ),
      tap(() => retryCount++),
      catchError(() => this.handleError(retry, retryCount, maxRetries)),
      filter((res) => (retryCount <= maxRetries ? !!res : true)),
      tap(() => {
        this._isLoading$.next(false);
      }),
      omitNullOrUndefined(),
      tap((response) => {
        this.last = response?.last ? response.last : true;
        this.hasError = false;
      }),
      map((response) => (response?.content ? response.content : []))
    );
  }

  private getPageForFile(fileId: number): number {
    const sample = this.allSamples.find((item) => item.fileId === fileId);
    return !!sample ? sample.nextPage : 0;
  }

  private handleError(retry: boolean, retryCount: number, maxRetries: number): Observable<null> {
    if (retry) {
      if (retryCount === maxRetries) {
        this.hasError = true;
        this._isLoading$.next(false);
        return of(null);
      }
    } else if (!retry) {
      this.hasError = true;
      this._isLoading$.next(false);
      return of(null);
    }
  }

  private mapTaskSampleToProductFields(taskSample: TaskSample): ProductField[] {
    return Object.keys(taskSample).map((key) => ({ name: key, value: taskSample[key] }));
  }

  updateProductSample(catalogId: number, fileId: number, index: number, extension: string, retry = false): void {
    const offset = 5;
    if (retry) {
      this.allSamples = [];
    }

    const samples = this.allSamples.find((taskSamples) => taskSamples.fileId === fileId)?.samples;
    const needFetch = !samples || samples.length === 0 || samples.length < index + offset || !this._isLoading$.value;
    if (needFetch) {
      if (!!samples && samples.length > 0) {
        this._isLoading$.next(false);
      }
      this.fetchProducts(catalogId, fileId, samples, index, retry, extension);
    }
    this.emitProductSample(fileId, index);
  }

  get hasNextItem(): boolean {
    return !this.lastIndex;
  }
}

export interface TaskWizardSample {
  fileId: number;
  samples: TaskSample[];
  nextPage: number;
}
