<div fxLayout="column" fxLayoutGap="24px" *ngIf="!(ltMd | async); else mobile">
  <div fxLayout="column" fxLayoutGap="12px" class="centered-text">
    <h1>{{ title }}</h1>
    <div class="typography-body-1" [innerHTML]="description"></div>
  </div>
  <div *ngIf="privateDetails" class="private-details" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
    <mat-icon>visibility_off</mat-icon>
    <div>{{ 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.PRIVATE' | translate }}</div>
  </div>
</div>

<ng-template #mobile>
  <div fxLayout="column" fxLayoutGap="24px">
    <div fxLayout="column" fxLayoutGap="12px" class="centered-text">
      <h3>{{ title }}</h3>
      <div class="typography-body-1" [innerHTML]="description"></div>
    </div>
    <div *ngIf="privateDetails" class="private-details" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <mat-icon>visibility_off</mat-icon>
      <div>{{ 'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.PRIVATE' | translate }}</div>
    </div>
  </div>
</ng-template>
