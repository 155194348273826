import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uniq } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Utils } from '../../../utils/utils';

@UntilDestroy()
@Component({
  selector: 'app-mat-tree-custom',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    FlexModule,
  ],
  templateUrl: './mat-tree-custom.component.html',
  styleUrls: ['./mat-tree-custom.component.scss'],
})
export class MatTreeCustomComponent implements OnInit, OnChanges {
  @Input() parentIsSelectable = false;
  @Input() selectParentExclusively = false;
  @Input() selectable = false;
  @Input() inputPlaceholder = 'Search';

  @Input() inputTooltip?: string;
  @Input() data: MatTreeCustomNode[];
  @Input() maxHeight = '300px';
  @Input() value: string[];
  @Output() valueChange = new EventEmitter<string[]>();
  searchTerm: string;
  searchTermUpdate = new BehaviorSubject<string>('');
  private transformer = (node: MatTreeCustomNode, level: number): MatTreeCustomFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      id: node.id,
      name: node.name,
      imgUrl: node.imgUrl,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<MatTreeCustomFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  getLevel = (node: MatTreeCustomFlatNode) => node.level;
  hasChild = (_: number, node: MatTreeCustomFlatNode) => node.expandable;
  filteredValues = new SelectionModel<MatTreeCustomFlatNode>(true, []);

  constructor() {}

  public ngOnInit(): void {
    this.filteredValues.select(...this.treeControl.dataNodes);
    this.initSearchTermSubscription();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataSource.data = this.data;
    }
  }

  private initSearchTermSubscription(): void {
    this.searchTermUpdate
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(),
        map((term: string): MatTreeCustomFlatNode[] => {
          if (Utils.isNullOrUndefinedOrLengthZero(term)) {
            return this.treeControl.dataNodes;
          }

          return this.treeControl.dataNodes.filter(
            (item: MatTreeCustomFlatNode): boolean => item.name.toUpperCase().indexOf(term.toUpperCase()) !== -1
          );
        })
      )
      .subscribe((nodes: MatTreeCustomFlatNode[]): void => {
        this.filteredValues.clear();

        this.filteredValues.select(...nodes);

        this.treeControl.collapseAll();

        nodes.forEach((node: MatTreeCustomFlatNode): void => {
          this.setAllParentsVisible(node);

          if (this.treeControl.dataNodes.length !== nodes.length) {
            this.expandNodeParents(node);
          } else {
            if (this.value?.includes(node.id)) {
              this.expandNodeParents(node);
            }
          }
        });
      });
  }

  private expandNodeParents(node: MatTreeCustomFlatNode): void {
    let parent: MatTreeCustomFlatNode = this.getParentNode(node);

    while (parent) {
      this.treeControl.expand(parent);
      parent = this.getParentNode(parent);
    }
  }

  setVisible(...node: MatTreeCustomFlatNode[]): void {
    this.filteredValues.select(...node);
  }

  setAllParentsVisible(node: MatTreeCustomFlatNode): void {
    let parent: MatTreeCustomFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.setVisible(parent);
      parent = this.getParentNode(parent);
    }
  }

  descendantsAllSelected(node: MatTreeCustomFlatNode): boolean {
    const descendants = this.parentIsSelectable
      ? this.treeControl.getDescendants(node)
      : this.getDescendantsWithoutParents(this.treeControl.getDescendants(node));
    return (
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.isSelected(child);
      })
    );
  }

  descendantsPartiallySelected(node: MatTreeCustomFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: MatTreeCustomFlatNode, event: MatCheckboxChange): void {
    const descendants = this.treeControl.getDescendants(node);
    if (this.parentIsSelectable) {
      this.toggle(node);
      if (this.selectParentExclusively) {
        return;
      }
      this.isSelected(node) ? this.select(...descendants) : this.deselect(...descendants);
    } else {
      event.checked ? this.select(...descendants) : this.deselect(...descendants);
    }

    // descendants.forEach((child) => this.isSelected(child));
    if (!this.selectParentExclusively) {
      this.checkAllParentsSelection(node);
    }
  }

  getDescendantsWithoutParents(descendants: MatTreeCustomFlatNode[]): MatTreeCustomFlatNode[] {
    return descendants.filter((descendant) => !descendant.expandable);
  }

  todoLeafItemSelectionToggle(node: MatTreeCustomFlatNode): void {
    this.toggle(node);
    if (!this.selectParentExclusively) {
      this.checkAllParentsSelection(node);
    }
  }

  checkAllParentsSelection(node: MatTreeCustomFlatNode): void {
    let parent: MatTreeCustomFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: MatTreeCustomFlatNode): void {
    const nodeSelected = this.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.select(node);
    }
  }

  getParentNode(node: MatTreeCustomFlatNode): MatTreeCustomFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  isSelected(node: MatTreeCustomFlatNode): boolean {
    return this.value?.includes(node.id);
  }

  private toggle(node: MatTreeCustomFlatNode): void {
    this.isSelected(node) ? this.deselect(node) : this.select(node);
  }

  private select(...nodes: MatTreeCustomFlatNode[]): void {
    const nodesToAdd = this.parentIsSelectable ? nodes : this.getDescendantsWithoutParents(nodes);
    this.value = uniq([...(this.value ?? []), ...nodesToAdd.map((node) => node.id)]);
    this.valueChange.emit(this.value);
  }

  private deselect(...nodes: MatTreeCustomFlatNode[]): void {
    const ids = nodes.map((node) => node.id);
    this.value = this.value.filter((value) => !ids.includes(value));
    this.valueChange.emit(this.value);
  }
}

export interface MatTreeCustomNode {
  id: string;
  name: string;
  imgUrl?: string;
  children?: MatTreeCustomNode[];
}

interface MatTreeCustomFlatNode {
  expandable: boolean;
  id: string;
  name: string;
  level: number;
  imgUrl?: string;
}
