import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { debounce } from 'lodash';

@Component({
  selector: 'app-searchbar-custom',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatIconModule, FlexModule, FormsModule],
  templateUrl: './searchbar-custom.component.html',
  styleUrls: ['./searchbar-custom.component.scss'],
})
export class SearchbarCustomComponent implements OnInit {
  @Input() disabled = false;
  @Input() isLevi = false;
  @Input() placeholder = 'Search';
  @Input() prefixIcon = 'search';
  @Input() hasClearButton = false;
  @Input() iconSize = 20;

  @Input() debounceTime = 300;

  @Output() searchTermChange = new EventEmitter<string>();

  @Input() searchTerm = '';

  debouncedSearchTermChange: (newValue: string) => void;

  constructor() {
    this.debouncedSearchTermChange = debounce(this.handleSearchTermChange, this.debounceTime);
  }

  ngOnInit(): void {}

  handleSearchTermChange(newValue: string): void {
    this.searchTermChange.emit(newValue);
  }

  handleClearClick(): void {
    if (!this.searchTerm) {
      return;
    }
    this.searchTerm = '';
    this.debouncedSearchTermChange(this.searchTerm);
  }
}
