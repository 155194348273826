import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileList } from '../../../ftp/filelist';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../service/rest/rest.service';
import { AuthenticationService } from '../../../../service/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { Utils } from '../../../../utils/utils';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadResponseVo } from '../vo/file-upload-response-vo';
import { ConnectionStateService } from '../../../../service/taskwizard/connection-state.service';
import { FtpConnectionStateVo } from '../vo/ftp-connection-state-vo';
import { FtpDialogVO } from '../vo/ftp-dialog-vo';
import { FtpUploadResponseVO } from '../vo/ftp-upload-response-vo';
import { BreadcrumbNavigationItem } from 'app/shared/components/breadcrumb-navigation/breadcrumb-navigation.component';
@Component({
  selector: 'app-ftpclient',
  templateUrl: './ftpclient.component.html',
  styleUrls: ['./ftpclient.component.scss'],
  animations: fuseAnimations,
})
export class FtpclientComponent implements OnInit {
  @ViewChild('ftpConnectionForm', { static: true }) ftpConnectionForm: NgForm;
  public ftpCredentials = new FtpConnectionVO();
  public fileList = new Array<FileList>();
  selected: FileList;

  breadCrumb: Array<BreadcrumbNavigationItem> = new Array<BreadcrumbNavigationItem>();
  public upDir: FileList;
  public hideFtpManager = true;
  public disableDownloadButton = true;
  public isFileDownloadSuccessful = false;
  public nodeTree: any[];

  disableNotCredentialsInput = false;

  public isConnectButtonPressed = false;

  isExpanded = false;

  constructor(
    private route: ActivatedRoute,
    private restService: RestService,
    private auth: AuthenticationService,
    private connStateService: ConnectionStateService,
    public dialogRef: MatDialogRef<FtpclientComponent>,
    @Inject(MAT_DIALOG_DATA) private ftpDialogVO: FtpDialogVO
  ) {}

  ngOnInit(): void {
    this.ftpCredentials.connectionType = 'FTP';
    this.ftpCredentials.taskId = this.ftpDialogVO.taskId;
    this.ftpCredentials.fileId = !Utils.isNullOrUndefined(this.ftpDialogVO.fileId) ? this.ftpDialogVO.fileId : null;
    this.upDir = new FileList();
    this.upDir.name = '..';
    this.upDir.isDirectory = true;
    if (this.connStateService.currentState) {
      const connState = this.connStateService.currentState;
      this.disableNotCredentialsInput = true;
      this.ftpCredentials.host = connState.host;
      this.ftpCredentials.port = connState.port;
      this.ftpCredentials.username = connState.username;
      this.ftpCredentials.password = connState.password;
      this.ftpCredentials.root = connState.root;
      this.ftpCredentials.passive = connState.passive;
      this.ftpCredentials.isSsl = connState.isSsl;
      this.connectFtp();
    } else {
      this.isExpanded = true;
      this.ftpCredentials.passive = 'true';
    }
  }

  connectFtp(): void {
    this.isConnectButtonPressed = true;
    if (this.ftpConnectionForm.valid) {
      const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
      this.restService.post('FtpService/connect', this.ftpCredentials, requestOptions).subscribe(
        (res) => {
          this.hideFtpManager = false;
          this.parseFileList(res.getData());
          this.setBreadCrumbPath(this.ftpCredentials.root);
          this.isConnectButtonPressed = false;
        },
        (error) => {
          this.isConnectButtonPressed = false;
          console.log(error);
        }
      );
    }
  }

  refreshConnectionCredentions(): void {
    const ftpConnState = new FtpConnectionStateVo();
    ftpConnState.host = this.ftpCredentials.host;
    ftpConnState.root = this.ftpCredentials.root;
    ftpConnState.username = this.ftpCredentials.username;
    ftpConnState.password = this.ftpCredentials.password;
    ftpConnState.port = this.ftpCredentials.port;
    ftpConnState.passive = this.ftpCredentials.passive;
    ftpConnState.isSsl = this.ftpCredentials.isSsl;
    this.connStateService.currentState = ftpConnState;
  }

  rowSingleClick(item: FileList): void {
    if (!item.isDirectory) {
      this.selected = item;
      this.disableDownloadButton = false;
    } else {
      this.disableDownloadButton = true;
    }
  }

  download(selected: FileList): void {
    this.disableDownloadButton = true;
    const downloadFile = new DownloadFileVO();
    downloadFile.taskId = this.ftpCredentials.taskId;
    downloadFile.fileId = this.ftpCredentials.fileId;
    downloadFile.remotePath = selected.path + '/' + selected.name;
    this.restService.post('FtpService/download', downloadFile).subscribe(
      (res) => {
        this.disableDownloadButton = false;
        if (res.isSuccess()) {
          this.refreshConnectionCredentions();
          console.log(res);
          const resData = res.getFirstData();
          const response = new FileUploadResponseVo();
          response.status = true;
          console.log(resData);
          response.taskId = resData.pmsFile.taskId;
          response.fileId = resData.pmsFile.fileId;
          response.remotePath = resData.pmsFile.remotePath;
          response.detectedType = resData.detectedType;
          this.dialogRef.close(response);
          return;
        } else {
          alert('Error occurred during download file');
        }
      },
      (error) => {
        this.disableDownloadButton = false;
        console.log(error);
      }
    );
  }

  breadCrumbCalback(bc: string): void {
    this.ftpCredentials.root = bc;
    this.restService.post('FtpService/listDir', this.ftpCredentials).subscribe(
      (res) => {
        this.parseFileList(res.getData());
        this.setBreadCrumbPath(this.ftpCredentials.root);
      },
      (error) => console.log(error)
    );
  }

  addBcElement(label: string, url: string): void {
    const bc: BreadcrumbNavigationItem = {
      key: label,
      url: url,
      id: url,
    };

    this.breadCrumb.push(bc);
  }

  rowDoubleClick(item: FileList): void {
    if (item.isDirectory) {
      let path = '';
      if (item.name === '..') {
        if (this.ftpCredentials.root === '/') {
          return;
        }
        const paths = this.ftpCredentials.root.split('/');

        const filtered = paths.filter(function (el): boolean {
          return el !== '';
        });

        for (let i = 0; i < filtered.length; i++) {
          path += paths[i] + '/';
        }
        this.ftpCredentials.root = path;
      } else {
        this.ftpCredentials.root = item.path.replace('//', '/') + '/' + item.name;
      }
      this.restService.post('FtpService/listDir', this.ftpCredentials).subscribe(
        (res) => {
          this.parseFileList(res.getData());
          this.setBreadCrumbPath(this.ftpCredentials.root);
        },
        (error) => console.log(error)
      );
    }
  }

  parseFileList(list: any): void {
    // this.fileList = new Array<FileList>();
    const tempFileList = new Array<FileList>();
    for (const item of list) {
      const splittedItems = item.split('\\#');
      const listItem: FileList = new FileList();
      listItem.isDirectory = splittedItems[0] === 'directory' ? true : false;

      const splittedPath = splittedItems[1].split('//');
      listItem.path = splittedPath[0];
      listItem.name = splittedPath[1];
      tempFileList.push(listItem);
      //    this.fileList.push(listItem);
    }
    this.fileList = Utils.getOrderedFileList(tempFileList);

    this.fileList.unshift(this.upDir);
  }

  setBreadCrumbPath(path: string): void {
    const paths = path.split('/');
    this.breadCrumb = new Array<BreadcrumbNavigationItem>();
    this.addBcElement('HOME', '/');
    let temp;
    for (const item of paths) {
      if (item === '') {
        temp = '/';
        continue;
      }
      temp += item + '/';
      const bc: BreadcrumbNavigationItem = {
        key: item,
        url: temp,
        id: temp,
      };

      this.breadCrumb.push(bc);
    }
  }

  close(): void {
    const response = new FtpUploadResponseVO();
    response.deleteTab = !this.ftpDialogVO.isUpdate;
    response.status = false;
    this.dialogRef.close(response);
  }
}

export class FtpConnectionVO {
  taskId: number;
  fileId: number;
  host: string;
  username: string;
  password: string;
  isSsl: string;
  port = '21';
  timeout: string;
  passive: string;
  root = '/';
  connectionType: string;
}

export class DownloadFileVO {
  taskId: number;
  fileId: number;
  remotePath: string;
}
