import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MetaTagsService } from '../meta-tags/meta-tags.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingMetatagService implements OnInit, OnDestroy {
  private unsubscribeAll: Subject<void>;

  constructor(private router: Router, private metaTagsService: MetaTagsService) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getSkipMetaTagUpdate();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  subscribeToNavigationEnd(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.getSkipMetaTagUpdate();
      });
  }

  private getSkipMetaTagUpdate(): void {
    let currentRoute = this.router.routerState.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    if (!currentRoute.routeConfig.data?.skipMetaTagUpdate) {
      this.metaTagsService.resetToDefaults();
    }
  }
}
