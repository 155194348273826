import { Component, Input, OnInit } from '@angular/core';
import { CatalogApproveStatus } from 'app/service/catalog-sidebar/catalog-sidebar.service';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { ProductPrices, ProductPricingUtils } from '../../../../utils/pricing/product-pricing-utils';

@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss'],
})
export class ProductPricesComponent implements OnInit {
  @Input() product: SearchProductVO;
  @Input() ecomCurrency: string;
  @Input() catalogStatus: CatalogApproveStatus;

  prices: ProductPrices;
  isLoading = true;

  constructor() {}

  ngOnInit(): void {
    this.calculatePrices();
    this.isLoading = false;
  }

  private calculatePrices(): void {
    this.prices = ProductPricingUtils.calculatePrices(
      this.product.VARIANTS[0],
      Number(this.product.SETTINGS.possibleMargin)
    );
  }
}
