import { Injectable } from '@angular/core';
import { CountryNode, newCountries } from '../../utils/Countries';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EXTRA_SHIPPING_FILTERS } from '../../utils/Constants';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class CountriesManagerService {
  constructor(private translate: TranslateService) {}

  public getOnlyCountries(): CountryNode[] {
    return Object.values(newCountries)
      .reduce((a, b) => a.concat(b))
      .sort(function (a, b): any {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .filter((c) => c.code !== 'PR');
  }

  public createShippingInformation(shippingDetails, isWorldwide): any {
    const list = [];
    console.log(shippingDetails);

    Object.keys(newCountries).forEach((elem) => {
      const countriesOfContinent = [];
      newCountries[elem].forEach((country) => {
        if (country.code === 'US') {
          country.children.forEach((state) => {
            countriesOfContinent.push({
              name: state.name + ' (US)',
              code: state.code,
            });
          });
          countriesOfContinent.push({
            name: 'United States',
            code: 'US',
          });
        } else {
          countriesOfContinent.push(country);
        }
      });

      countriesOfContinent.forEach((country) => {
        const countryElem = shippingDetails.find(
          (shipping) =>
            !Utils.isNullOrUndefined(shipping.countries) &&
            (shipping.countries.includes(country.code) || shipping.countries.includes(country.name))
        );
        if (Utils.isNullOrUndefined(countryElem)) {
          return;
        }
        let minCost;
        let maxCost;
        if (countryElem.shippingZoneRates && countryElem.shippingZoneRates.length > 0) {
          const prices = countryElem.shippingZoneRates.map((rate) => rate.price);
          minCost = Math.min(...prices);
          maxCost = Math.max(...prices);
        } else if (!Utils.isNullOrUndefined(countryElem.cost)) {
          minCost = countryElem.cost;
          maxCost = countryElem.cost;
        }
        if (countryElem || isWorldwide) {
          const index = list.findIndex((continent) => continent.name === elem);
          if (index === -1) {
            list.push({
              name: elem,
              minCost: minCost,
              maxCost: maxCost,
              minShippingTime: !Utils.isNullOrUndefined(countryElem) ? countryElem.minShippingTime : null,
              maxShippingTime: !Utils.isNullOrUndefined(countryElem) ? countryElem.maxShippingTime : null,
              countries: [
                {
                  country: country.name,
                  cost: !Utils.isNullOrUndefined(countryElem) ? countryElem.cost : null,
                  additionalCost: !Utils.isNullOrUndefined(countryElem) ? countryElem.additionalCost : null,
                  minShippingTime: !Utils.isNullOrUndefined(countryElem) ? countryElem.minShippingTime : null,
                  maxShippingTime: !Utils.isNullOrUndefined(countryElem) ? countryElem.maxShippingTime : null,
                  shippingZoneRates: !Utils.isNullOrUndefined(countryElem) ? countryElem.shippingZoneRates : null,
                },
              ],
            });
          } else {
            list[index].countries.push({
              country: country.name,
              cost: !Utils.isNullOrUndefined(countryElem) ? countryElem.cost : null,
              additionalCost: !Utils.isNullOrUndefined(countryElem) ? countryElem.additionalCost : null,
              minShippingTime: !Utils.isNullOrUndefined(countryElem) ? countryElem.minShippingTime : null,
              maxShippingTime: !Utils.isNullOrUndefined(countryElem) ? countryElem.maxShippingTime : null,
              shippingZoneRates: !Utils.isNullOrUndefined(countryElem) ? countryElem.shippingZoneRates : null,
            });
            if (!Utils.isNullOrUndefined(countryElem)) {
              list[index].minCost = list[index].minCost
                ? list[index].minCost > minCost
                  ? minCost
                  : list[index].minCost
                : minCost;
              list[index].maxCost = list[index].maxCost
                ? list[index].maxCost < maxCost
                  ? maxCost
                  : list[index].maxCost
                : maxCost;
              list[index].minShippingTime = list[index].minShippingTime
                ? list[index].minShippingTime > countryElem.minShippingTime
                  ? countryElem.minShippingTime
                  : list[index].minShippingTime
                : countryElem.minShippingTime;
              list[index].maxShippingTime = list[index].maxShippingTime
                ? list[index].maxShippingTime < countryElem.maxShippingTime
                  ? countryElem.maxShippingTime
                  : list[index].maxShippingTime
                : countryElem.maxShippingTime;
            }
          }
        }
      });
    });
    return list;
  }

  getCountryNames(codes): string[] {
    const countries = [];
    this.getOnlyCountries().forEach((elem) => {
      if (codes.indexOf(elem.code) > -1) {
        countries.push(elem.name);
      }
    });
    return countries;
  }

  getCountryCodes(countries): string[] {
    const codes = [];
    this.getOnlyCountries().forEach((elem) => {
      if (countries.indexOf(elem.name) > -1) {
        codes.push(elem.code);
      }
    });
    return codes;
  }

  getCountryNameByCode(code): string {
    const countryElem = this.getOnlyCountries().find((country) => country.code === code);
    return Utils.isNullOrUndefined(countryElem) ? code : countryElem.name;
  }

  getCountryCodeByCountryName(name): string {
    const countryElem = this.getOnlyCountries().find((country) => country.name === name);
    return Utils.isNullOrUndefined(countryElem) ? name : countryElem.code;
  }

  getCountryNodeByCountryCode(code: string): CountryNode {
    return this.getOnlyCountries().find((country) => country.code === code);
  }

  getCountryNodeByCountryName(name: string): CountryNode {
    return this.getOnlyCountries().find((country) => country.name === name);
  }

  getCountryOrContinentByName(name: string): CountryNode {
    return [...EXTRA_SHIPPING_FILTERS, ...this.getOnlyCountries()].find(
      (country) => country.name.toLowerCase() === name.toLowerCase()
    );
  }

  getCountryNameByCountryCode(code): string {
    const countryElem = this.getOnlyCountries().find((country) => country.code === code);
    return Utils.isNullOrUndefined(countryElem) ? code : countryElem.name;
  }

  getProvinceNameByProvinceCode(code): string {
    const province = this.getUSStates().find((item) => item.code === 'US-' + code);
    console.log(province);
    return Utils.isNullOrUndefined(province) ? code : province.name;
  }

  getUSStates = () => {
    return newCountries['North America'].find((country) => country.code == 'US').children;
  };

  isInContinentByCode(continent: string, code: string): boolean {
    if (Utils.isNullOrUndefined(newCountries[continent])) {
      return false;
    }

    return !Utils.isNullOrUndefined(newCountries[continent].find((item) => item.code === code));
  }

  getTranslatedCountryNames(): Observable<Record<string, string>> {
    return this.translate.stream('COUNTRIES');
  }

  mapTranslatedNamesToCountryNodes(translatedNames: Record<string, string>): CountryNode[] {
    return this.getOnlyCountries().map((entry) => {
      const matchingTranslatedName = translatedNames[entry.code.toUpperCase()];
      return { ...entry, name: matchingTranslatedName ?? entry.name };
    });
  }

  getCountriesWithTranslatedNames(): Observable<CountryNode[]> {
    return this.getTranslatedCountryNames().pipe(map((tNames) => this.mapTranslatedNamesToCountryNodes(tNames)));
  }

  isInContinentByName(continent: string, name: string): boolean {
    if (Utils.isNullOrUndefined(newCountries[continent])) {
      return false;
    }

    return !Utils.isNullOrUndefined(newCountries[continent].find((item) => item.name === name));
  }
}
