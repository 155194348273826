import { Injectable } from '@angular/core';
import { FtpConnectionStateVo } from '../../main/taskwizard/addfiles/vo/ftp-connection-state-vo';

@Injectable()
export class ConnectionStateService {
  private _currentState: FtpConnectionStateVo = null;

  constructor() {}

  get currentState(): FtpConnectionStateVo {
    return this._currentState;
  }

  set currentState(value: FtpConnectionStateVo) {
    this._currentState = value;
  }
}
