<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">
  <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<ng-container *ngTemplateOutlet="mobileMainMenu"></ng-container>

<div id="main">
  <!-- TOOLBAR: Above -->
  <ng-container *ngIf="toolbarConfig$ | async as toolbarConfig">
    <ng-container *ngIf="toolbarConfig.position === 'top'">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
  </ng-container>
  <!-- / TOOLBAR: Above -->

  <div class="outer-container" #outerContainer scrollToTop>
    <div fxFlex="1 0 0" class="m-w-0" id="layout-outer-container">
      <div id="container-1" class="container">
        <!-- NAVBAR: Left -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
          <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div
          id="container-2"
          class="container"
          [style.padding-top.px]="
            fuseConfig.layout.toolbar.position === 'sticky-on-scroll' ? (layoutService.toolbarHeight | async) : 0
          "
        >
          <ng-container *ngIf="toolbarConfig$ | async as toolbarConfig">
            <ng-container *ngIf="toolbarConfig.position === 'sticky-on-scroll'">
              <div stickyOnScroll class="white-500-bg w-100p">
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
              </div>
            </ng-container>
          </ng-container>

          <div id="container-3" class="container">
            <ng-container *ngIf="!fuseConfig.layout.breadcrumb.hidden">
              <div
                class="breadcrumb-wrapper"
                [style]="breacrumbContainerStyle"
                [ngClass]="fuseConfig.layout.contentContainer"
              >
                <app-breadcrumbs></app-breadcrumbs>
              </div>
            </ng-container>

            <div class="page-layout simple inner-scroll">
              <app-submenu
                *ngIf="!!fuseConfig.layout.submenu.group && !fuseConfig.layout.submenu.hidden"
                [group]="fuseConfig.layout.submenu.group"
              ></app-submenu>
              <inner-content
                class="center content"
                [ngClass]="fuseConfig.layout.contentContainer"
                [class.full-height]="fuseConfig.layout.fullHeight"
                [verticalPadding]="fuseConfig.layout.contentContainer === 'full-width-without-padding' ? 0 : 24"
              >
                <!-- CONTENT -->
                <!--
                *ngIf="true" hack is required here for router-outlet to work
                correctly. Otherwise, it won't register the changes on the
                layout and won't update the view.
                -->
                <ng-container *ngIf="!!fuseConfig.layout.backButton">
                  <div class="pb-40">
                    <app-page-back-button
                      [title]="fuseConfig.layout.backButton.title | translate"
                      [url]="fuseConfig.layout.backButton.url"
                      [queryParams]="fuseConfig.layout.backButton.queryParams"
                    >
                    </app-page-back-button>
                  </div>
                </ng-container>
                <div
                  fxLayout="row"
                  fxLayout.lt-md="column"
                  fxLayoutAlign="center center"
                  fxLayoutAlign.lt-md="center start"
                  fxLayoutAlign.lt-sm="center stretch"
                  fxLayoutGap="8px"
                  fxLayoutGap.lt-md="20px"
                  class="mb-20"
                  *ngIf="!!fuseConfig.layout.title || !!fuseConfig.templates?.topRightContent"
                >
                  <ng-container *ngIf="!!fuseConfig.layout.title">
                    <div class="h3 title" fxFlex="1 0 0">
                      {{
                        fuseConfig.layout.translateTitle
                          ? (fuseConfig.layout.title | translate)
                          : fuseConfig.layout.title
                      }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!!fuseConfig.templates?.topRightContent">
                    <ng-container [ngTemplateOutlet]="fuseConfig.templates?.topRightContent"></ng-container>
                  </ng-container>
                </div>
                <router-outlet *ngIf="true"></router-outlet>
                <!-- / CONTENT -->
              </inner-content>
            </div>

            <ng-container *ngTemplateOutlet="onboard"></ng-container>

            <ng-container *ngIf="layoutPortalService.portals[LayoutPortalPosition.LAYOUT_TOP_RIGHT_FLOATING]">
              <div class="portal layout-top-right-floating" [style.top.px]="topValueOfFloatingTopPortals">
                <ng-template
                  [cdkPortalOutlet]="layoutPortalService.portals[LayoutPortalPosition.LAYOUT_TOP_RIGHT_FLOATING]"
                ></ng-template>
              </div>
            </ng-container>

            <ng-container *ngIf="aiChatService.layout$ | async as layout">
              <ng-container *ngIf="layout === aiChatLayoutEnum.MOBILE || layout === aiChatLayoutEnum.POPOVER">
                <ng-container *ngTemplateOutlet="aiChat"></ng-container>
              </ng-container>
            </ng-container>

            <!-- FOOTER: Below static -->
            <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static'">
              <ng-container *ngTemplateOutlet="footer"></ng-container>
            </ng-container>
            <!-- / FOOTER: Below static -->
          </div>

          <!-- FOOTER: Below fixed -->
          <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-fixed'">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
          </ng-container>
          <!-- / FOOTER: Below fixed -->
        </div>

        <!-- NAVBAR: Right -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
          <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Right -->
      </div>

      <!-- FOOTER: Above -->
      <ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="(aiChatService.layout$ | async) === aiChatLayoutEnum.SIDEBAR">
      <ng-container *ngTemplateOutlet="aiChat"></ng-container>
    </ng-container>
  </div>
  <!-- FOOTER: Above -->
</div>
<!-- / SIDE PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<ng-template #aiChat>
  <app-ai-chat
    fxFlex="0 0 400px"
    class="sticky ai-chat"
    *ngIf="aiChatService.isOpen && !fuseConfig.layout.aiChat.hidden"
    [layout]="fuseConfig.layout.aiChat.layout"
  ></app-ai-chat>
</ng-template>

<!-- TOOLBAR -->
<ng-template #toolbar>
  <ng-container *ngIf="toolbarConfig$ | async as toolbarConfig">
    <toolbar
      *ngIf="toolbarConfig.hidden === false"
      [needRedirect]="toolbarConfig.needRedirect"
      [needCategorySelector]="toolbarConfig.needCategorySelector"
      [ngClass]="toolbarConfig.customClass"
      [hasAiChat]="toolbarConfig.hasAiChat"
    >
    </toolbar>
  </ng-container>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  <app-syncee-cta
    *ngIf="!!fuseConfig.layout.footer.cta"
    [title]="fuseConfig.layout.footer.cta.title"
    [buttonText]="fuseConfig.layout.footer.cta.buttonText"
    [descriptionTemplateRef]="fuseConfig.layout.footer.cta.descriptionTemplateRef"
    [descriptionInnerHTML]="fuseConfig.layout.footer.cta.descriptionInnerHTML"
    [innerWrapperClass]="fuseConfig.layout.footer.cta.innerWrapperClass"
    [queryParams]="fuseConfig.layout.footer.cta.queryParams"
    [redirectURL]="fuseConfig.layout.footer.cta.redirectURL"
    [wrapperClass]="fuseConfig.layout.footer.cta.wrapperClass"
    [callback]="fuseConfig.layout.footer.cta.callback"
  ></app-syncee-cta>
  <ng-container *ngIf="!fuseConfig.layout.footer.cta">
    <app-custom-divider *ngIf="fuseConfig.layout.footer.topDivider"></app-custom-divider>
  </ng-container>
  <footer
    *ngIf="!fuseConfig.layout.footer.hidden"
    [ngClass]="
      fuseConfig.layout.footer.customBackgroundColor === true
        ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background
        : fuseConfig.layout.footer.position
    "
  ></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  <fuse-sidebar
    name="navbar"
    class="syncee-grey-300-border-right navbar"
    *ngIf="!fuseConfig.layout.navbar.hidden"
    [folded]="fuseConfig.layout.navbar.folded"
    [foldedWidth]="65"
    lockedOpen="gt-sm"
  >
    <div class="extra-content mx-16" fxLayout="column" fxLayoutGap="16px">
      <app-ecom-selector-dropdown [roleType]="userRole"></app-ecom-selector-dropdown>
    </div>
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
  <fuse-sidebar
    name="navbar"
    position="right"
    class="navbar-fuse-sidebar"
    [folded]="fuseConfig.layout.navbar.folded"
    lockedOpen="gt-md"
    *ngIf="!fuseConfig.layout.navbar.hidden"
  >
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->

<!-- ONBOARD -->
<ng-template #onboard>
  <app-onboard
    *ngIf="(onboardStatus | async) && !fuseConfig.layout.setupGuideButton.hidden"
    class="onboard"
    [elevated]="fuseConfig.layout.setupGuideButton.elevated"
  ></app-onboard>
</ng-template>
<!-- / ONBOARD -->
<!--MINIMIZED CHAT-->
<ng-container *ngIf="!fuseConfig.layout.minimizedChat?.hidden && minimizedChats && minimizedChats.length && !isMobile">
  <div class="minimized-chat-bar">
    <app-minimized-chat-taskbar [chats]="minimizedChats"></app-minimized-chat-taskbar>
  </div>
</ng-container>
<!--/MINIMIZED CHAT-->
<ng-template #mobileMainMenu>
  <fuse-sidebar [name]="mobileSidebarId" position="left" [fullWidth]="true" [hasBackdrop]="false" class="p-0">
    <app-mobile-main-menu-sidebar
      [needRedirect]="fuseConfig.layout.categorySelector.redirect"
    ></app-mobile-main-menu-sidebar>
  </fuse-sidebar>
</ng-template>
