<div
  class="syncee-grey-200-border simple-paginator-with-arrows"
  [class.has-full-border]="hasFullBorder"
  [class.hide-border]="!hasBorder"
>
  <div class="mr-12" *ngIf="hasSizeSelector" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span>{{ 'SIMPLE_PAGINATOR_WITH_ARROWS.ITEMS_PER_PAGE' | translate }}:</span>
    <mat-form-field class="w-50 page-size-selector">
      <mat-select [value]="size" (selectionChange)="sizeChangeHandler($event)">
        <mat-option *ngFor="let size of sizes" [value]="size">{{ size }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    {{ allItem === 0 ? 0 : page * size + 1 }} - {{ page * size + currentlyShownItem }}
    {{ 'SIMPLE_PAGINATOR_WITH_ARROWS.OF' | translate }} {{ allItem }}
  </div>
  <div class="buttons">
    <button mat-icon-button [disabled]="isPreviousDisabled" (click)="previous.emit()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-icon-button [disabled]="isNextDisabled" (click)="next.emit()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
