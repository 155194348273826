const prefix = 'supplier/';
const catalog = 'catalog';

export type SupplierCatalogNavigation = (typeof SupplierCatalogNavigation)[keyof typeof SupplierCatalogNavigation];
export const SupplierCatalogNavigation = {
  DEFAULT: `${prefix}${catalog}`,
  PRODUCTS: `${prefix}${catalog}/products`,
  CATALOGS: `${prefix}${catalog}/list`,
  CREATE: `${prefix}${catalog}/create`,
  PIM: `${prefix}${catalog}/manual`,
  PIM_ADD: `${prefix}${catalog}/manual/product`,
  TASK: `${prefix}${catalog}/wizard`,
} as const;
