import { Injectable } from '@angular/core';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AddToImportListMenuComponent,
  AddToCatalogMenuComponentData,
} from '../../main/marketplace/explore-products/add-to-import-list-menu/add-to-import-list-menu.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreateEmptyCatalogService {
  constructor(private catalogSidebarService: CatalogSidebarService, private dialog: MatDialog) {}

  public create(): Observable<boolean> {
    return this.dialog
      .open<AddToImportListMenuComponent, AddToCatalogMenuComponentData>(AddToImportListMenuComponent, {
        width: '350px',
        disableClose: true,
        data: {
          createEmptyCatalog: true,
        },
      })
      .afterClosed();
  }
}
