import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HorizontalScrollDirective } from 'app/shared/directives/horizontal-scroll.directive';
import { SliderArrowComponent } from '../slider-arrow/slider-arrow.component';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, HorizontalScrollDirective, SliderArrowComponent, MatIconModule],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() hasButtons = false;

  @ViewChild('carousel') carousel: ElementRef;

  scroll(amount: number): void {
    this.carousel?.nativeElement?.scrollBy({ left: amount, behavior: 'smooth' });
  }
}
