import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogService, ActivityLogTableItem } from '../../../service/activity-log/activity-log.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable, Subject } from 'rxjs';
import { CustomTableDataResponse } from '../../../shared/components/custom-table/custom-table.component';
import { map } from 'rxjs/operators';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { IndexTableFilter, TableFilterItem } from 'app/shared/components/filter-factory/filter-factory.component';

@Component({
  selector: 'app-activity-log-content',
  templateUrl: './activity-log-content.component.html',
  styleUrls: ['./activity-log-content.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD.MM.YYYY',
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD.MM.YYYY',
          monthYearA11yLabel: 'MMM YYYY',
        },
      },
    },
  ],
})
export class ActivityLogContentComponent implements OnInit {
  dateTo: Date;
  dateFrom: Date;
  activityLogs: Partial<ActivityLogTableItem>[];
  reloadSubject = new Subject<void>();
  displayedColumns: string[] = ['message', 'type', 'date'];
  isOnMobile: boolean;

  activeFilters: TableFilterItem[] = [];
  tableFilterArray: IndexTableFilter<string>[];

  @Input() type: string;

  constructor(private activityLogService: ActivityLogService, private screenManager: ScreenManagerService) {
    this.subscribeToViewPortWidthChange();
  }

  ngOnInit(): void {
    this.initDatePickerTimes();
    this.getTypes();
  }

  private initDatePickerTimes(): void {
    const today = new Date();
    const fromDate = new Date();
    fromDate.setMonth(today.getMonth() - 6);
    this.dateTo = today;
    today.setHours(0, 0, 0);
    this.dateFrom = fromDate;
  }

  getActivityLogs(): Observable<CustomTableDataResponse<ActivityLogTableItem>> {
    const from = this.dateFrom ? this.dateFrom : new Date(null);
    const to = this.dateTo ? this.dateTo : new Date();
    return this.activityLogService.getActivityLogs(from.toISOString(), to.toISOString(), this.type).pipe(
      map((data) => {
        return this.mapActivityLogsToCustomTable(this.activityLogService.mapFetchedActivityLogsToTableItems(data));
      })
    );
  }

  updateChanges(): void {
    this.reloadSubject.next();
  }

  private getTypes(): void {
    this.activityLogService.getTypes().subscribe((data) => {
      const types = data.filter((type) => type.value !== '%');
      this.tableFilterArray = [
        {
          type: 'Type',
          labelKey: 'ACTIVITY_LOG.ACTIVITY_TYPE',
          choices: types.map((type) => {
            return {
              value: type.value,
              label: type.name,
            };
          }),
        },
      ];
    });
    this.type = '%';
  }

  private subscribeToViewPortWidthChange(): void {
    this.screenManager.observeIfBreakpointMatches(BreakPoint.md).subscribe((value) => {
      this.isOnMobile = value;
    });
  }

  mapActivityLogsToCustomTable(activityLogs: ActivityLogTableItem[]): CustomTableDataResponse<ActivityLogTableItem> {
    return {
      data: activityLogs,
      size: activityLogs.length,
      page: 0,
      allItemsCount: activityLogs.length,
    };
  }

  handleFilterChange(e: TableFilterItem): void {
    switch (e.type) {
      case 'Type':
        this.type = '%';
        this.activeFilters = this.activeFilters.filter((el) => el.type !== e.type);
        if (e.value) {
          this.type = e.value;
          this.activeFilters.push(e);
        }
        this.updateChanges();
        break;
      case 'Date':
        this.dateFrom = e.value.dateFrom;
        this.dateTo = e.value.dateTo;
        this.activeFilters = this.activeFilters.filter((filter) => filter.type !== e.type);
        if (e.value && (e.value.dateTo || e.value.dateFrom)) {
          this.activeFilters.push(e);
        }
        this.updateChanges();
        break;
      default:
        break;
    }
  }

  handleDateFromChange(e: Date): void {
    this.dateFrom = e;
    this.updateChanges();
  }

  handleDateToChange(e: Date): void {
    this.dateTo = e;
    this.updateChanges();
  }

  handleClearAllFilters(): void {
    this.type = '%';
    this.dateFrom = null;
    this.dateTo = null;
    this.activeFilters = [];
    this.updateChanges();
  }
}
