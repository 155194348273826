<app-overlay-responsive-wrapper [overlayWidth]="400" [openOverlay]="openOverlay">
  <button slot="trigger" mat-icon-button>
    <mat-icon
      [badge]="recentlyAddedProducts === 0 ? null : recentlyAddedProducts.toString()"
      badgePosition="top-right"
      badgeVariant="without-number"
      >storefront</mat-icon
    >
  </button>
  <ng-container slot="overlay-content">
    <app-catalog-overlay (closeOverlay)="openOverlay.next(false)"></app-catalog-overlay>
  </ng-container>
</app-overlay-responsive-wrapper>
