import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { User } from '../../../../../service/user/user';
import { getCurrentUserSelector, hasRolesSelector } from '../../../../../store/user/user.selector';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { profile_menu_navigation } from '../../../../../navigation/inner-navigation';
import { MatDialogRef } from '@angular/material/dialog';
import { SetActualRole } from '../../../../../store/user/user.actions';
import {
  LogoutAction,
  UserToUserLoginType,
  UserToUserLogoutStart,
} from '../../../../../store/authentication/authentication.actions';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import { RoleDialogService } from '../../../../../service/guards/role-dialog.service';
import { ROLE_TYPE, RolesEnum } from '../../../../../vo/roles/roles';
import {
  CENTER_REWRITE_RETAILER,
  CENTER_REWRITE_SUPPLIER,
} from '../../../../../main/change-role/change-role-dialog.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SCOPES } from '../../../../../service/permission/scopes';
import { getScopesSelector } from '../../../../../store/authentication/authentication.selector';
import { includes } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-profile-mobile-content',
  templateUrl: './profile-mobile-dialog.component.html',
  styleUrls: ['./profile-mobile-dialog.component.scss'],
})
export class ProfileMobileDialogComponent implements OnInit {
  username: Observable<string>;
  role: ROLE_TYPE;
  readonly navigation = profile_menu_navigation;

  constructor(
    private dialogRef: MatDialogRef<ProfileMobileDialogComponent>,
    private store: Store<AppState>,
    private fuseSidebarService: FuseSidebarService,
    private roleDialogService: RoleDialogService
  ) {
    this.username = this.getUsername();
  }

  ngOnInit(): void {
    this.initRoleSubscription();
  }

  private initRoleSubscription(): void {
    combineLatest([this.getRole(), this.getIsAdminObs()])
      .pipe(untilDestroyed(this))
      .subscribe(([role, isAdmin]) => {
        this.role = isAdmin ? RolesEnum.ADMIN : role;
      });
  }

  private getRole(): Observable<ROLE_TYPE> {
    return this.getUser().pipe(map((user) => user.role as ROLE_TYPE));
  }

  private getPermissionsObs(): Observable<SCOPES[]> {
    return this.store.select(getScopesSelector(false));
  }

  private getIsAdminObs(): Observable<boolean> {
    return this.getPermissionsObs().pipe(map((permissions) => includes(permissions, SCOPES.ADMIN)));
  }

  private getUser(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(filter((user) => !!user));
  }

  private getUsername(): Observable<string> {
    return this.getUser().pipe(map((user) => user?.userName || null));
  }

  onMenuItemClick(): void {
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  onCenterClick(types: number[], link: string, forRole: number): void {
    this.store
      .select(hasRolesSelector, forRole)
      .pipe(take(1))
      .subscribe((hasRole) => {
        if (hasRole) {
          this.store.dispatch(new SetActualRole(forRole));
          this.openSidebar();
        } else {
          this.roleDialogService.handleCenterNavigationAttempt(
            forRole,
            forRole === RolesEnum.SUPPLIER ? CENTER_REWRITE_SUPPLIER : CENTER_REWRITE_RETAILER
          );
        }
      });
  }

  logout(): void {
    this.store.dispatch(new LogoutAction());
    this.onMenuItemClick();
  }

  openSidebar(): void {
    this.fuseSidebarService.getSidebar('mobile-center').open();
  }

  logoutFromUser(loggedInBy: UserToUserLoginType): void {
    this.store.dispatch(new UserToUserLogoutStart(loggedInBy));
  }
}
