<form [formGroup]="catalogForm" fxLayoutGap="20px" fxLayout="column">
  <!--Catalog name-->
  <div
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.gt-sm="center center"
    fxLayoutGap="0"
    fxLayoutGap.lt-md="8px"
  >
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">{{ 'CATALOG_EDITOR.CATALOG_NAME' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'CATALOG_EDITOR.CATALOG_NAME_DESCR' | translate">info</mat-icon>
    </div>
    <mat-form-field appearance="fill" fxFlex="1 0 0" class="select-without-label input-without-padding-except-error">
      <input matInput [placeholder]="'CATALOG_EDITOR.CATALOG_NAME' | translate" formControlName="name" required />
      <mat-error *ngIf="catalogForm.get('name').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
    </mat-form-field>
  </div>

  <!--Tags-->
  <!-- <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">{{ 'CATALOG_EDITOR.TAGS' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'CATALOG_EDITOR.TAGS_DESCR' | translate">info</mat-icon>
    </div>
    <mat-form-field appearance="fill" fxFlex="1 0 0" class="select-without-label input-without-padding-except-error">
      <mat-chip-list #chipList formControlName="tags">
        <mat-chip
          *ngFor="let tag of tags"
          [selectable]="false"
          [removable]="true"
          (removed)="removeTag(tag)"
          class="grey-chip"
        >
          {{ tag }}
          <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
        <input
          [placeholder]="'CATALOG_EDITOR.TAGS' | translate"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-list>
    </mat-form-field>
  </div> -->

  <!--Description-->
  <!-- <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">{{ 'CATALOG_EDITOR.DESC' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'CATALOG_EDITOR.DESC_CONTENT' | translate">info</mat-icon>
    </div>
    <mat-form-field
      appearance="fill"
      fxFlex="1 0 0"
      class="textarea-content select-without-label input-without-padding-except-error"
    >
      <textarea
        [placeholder]="'CATALOG_EDITOR.DESC' | translate"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        formControlName="description"
        required
      ></textarea>
      <mat-error *ngIf="catalogForm.get('description').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
    </mat-form-field>
  </div> -->

  <!--Recomended price margin-->
  <div
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.gt-sm="center center"
    fxLayoutGap="0"
    fxLayoutGap.lt-md="8px"
    *ngIf="showProfitMargin"
  >
    <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center" class="pr-20">
      <div class="h5">
        {{ 'CATALOG_EDITOR.POSSIBLE' | translate }}
      </div>
      <mat-icon
        class="s-20 icon-filled"
        (mouseenter)="openLayout()"
        (mouseleave)="closeLayout()"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        info
      </mat-icon>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen"
        cdkConnectedOverlayWidth="200px"
        [cdkConnectedOverlayPositions]="positions"
      >
        <div class="price-margin-overlay">
          <div>{{ 'CATALOG_EDITOR.POSSIBLE_DESCR' | translate }}</div>
          <!-- <div class="price-margin-table">
            <div fxLayoutAlign="space-between center">
              <div fxFlex="1 0 0">{{ 'TOOLTIP.RECOMMENDED_PRICE_MARGIN.DISCOUNT' | translate }}</div>
              <div fxFlex="1 0 0"></div>
              <div fxFlex="1 0 0">{{ 'TOOLTIP.RECOMMENDED_PRICE_MARGIN.RPM' | translate }}</div>
            </div>
            <div *ngFor="let row of discountExamples">
              <div fxFlex="1 0 0">{{ row.discount }}</div>
              <div fxFlex="1 0 0"><mat-icon>arrow_right_alt</mat-icon></div>
              <div fxFlex="1 0 0">{{ row.rpm }}</div>
            </div>
          </div> -->
        </div>
      </ng-template>
    </div>
    <mat-form-field
      appearance="fill"
      fxFlex="1 0 0"
      class="textarea-content select-without-label input-without-padding-except-error"
      formGroupName="settings"
    >
      <input
        [placeholder]="'CATALOG_EDITOR.POSSIBLE' | translate"
        matInput
        type="number"
        formControlName="possibleMargin"
        [min]="0"
        [max]="1000"
      />
      <span matSuffix>%</span>
      <mat-error *ngIf="catalogForm.get('settings.possibleMargin').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
      <mat-error *ngIf="catalogForm.get('settings.possibleMargin').hasError('min')">{{
        'FORM_VALIDATORS.MIN_VALUE' | translate : { min: 0 }
      }}</mat-error>
      <mat-error *ngIf="catalogForm.get('settings.possibleMargin').hasError('max')">{{
        'FORM_VALIDATORS.MAX_VALUE' | translate : { max: 1000 }
      }}</mat-error>
    </mat-form-field>
  </div>

  <!--Send Email-->
  <!--   <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
    <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center" class="pr-20">
      <div class="h5">{{ 'MAPPING_SETTINGS.EMAIL_NOTI' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.EMAIL_NOTI_DESCR' | translate">info</mat-icon>
    </div>
    <mat-form-field
      formGroupName="settings"
      appearance="fill"
      fxFlex="1 0 0"
      class="select-without-label input-without-padding-except-error"
    >
      <mat-select
        formControlName="sendEmail"
        [placeholder]="'MAPPING_SETTINGS.EMAIL_NOTI' | translate"
        disableOptionCentering
        panelClass="mat-select-display-change"
      >
        <mat-option value="dont">{{ 'MAPPING_SETTINGS.DONT_SEND' | translate }}</mat-option>
        <mat-option value="email">{{ 'MAPPING_SETTINGS.SEND1' | translate }}</mat-option>
        <mat-option value="report">{{ 'MAPPING_SETTINGS.SEND2' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->

  <!--Language-->
  <div
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.gt-sm="center center"
    fxLayoutGap="0"
    fxLayoutGap.lt-md="8px"
  >
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">{{ 'MAPPING_SETTINGS.LANGUAGE' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.LANGUAGE_DESCR' | translate">info</mat-icon>
    </div>
    <mat-form-field
      formGroupName="settings"
      appearance="fill"
      fxFlex="1 0 0"
      class="select-without-label input-without-padding-except-error"
    >
      <mat-select
        formControlName="language"
        [placeholder]="'MAPPING_SETTINGS.LANGUAGE' | translate"
        disableOptionCentering
        panelClass="mat-select-display-change"
        required
      >
        <mat-option *ngFor="let language of languages" [value]="language.id">
          {{ 'GENERAL.LANGUAGES.' + language.id | uppercase | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="catalogForm.get('settings.language').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
    </mat-form-field>
  </div>

  <!--Shipping type-->
  <!-- <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
    <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center" class="pr-20">
      <div class="h5">{{ 'MAPPING_SETTINGS.SHIPPING_TYPE' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.SHIPPING_TYPE_DESCR' | translate"
        >info</mat-icon
      >
    </div>
    <mat-form-field
      formGroupName="settings"
      appearance="fill"
      fxFlex="1 0 0"
      class="select-without-label input-without-padding-except-error"
    >
      <mat-select
        formControlName="shipping_type"
        disableOptionCentering
        [placeholder]="'MAPPING_SETTINGS.SHIPPING_TYPE' | translate"
        panelClass="mat-select-display-change"
        required
      >
        <mat-option value="dropship">
          {{ 'MAPPING_SETTINGS.DROPSHIPPING' | translate }}
        </mat-option>
        <mat-option value="wholesaling">
          {{ 'MAPPING_SETTINGS.WHOLESALING' | translate }}
        </mat-option>
        <mat-option value="both">
          {{ 'MAPPING_SETTINGS.DROP_AND_WHOLE' | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="catalogForm.get('settings.shipping_type').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
    </mat-form-field>
  </div> -->

  <!--Warehouse Location-->
  <!-- <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">{{ 'MAPPING_SETTINGS.WAREHOUSE' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.WAREHOUSE_DESCR' | translate">info</mat-icon>
    </div>
    <mat-form-field
      formGroupName="settings"
      appearance="fill"
      fxFlex="1 0 0"
      class="select-without-label input-without-padding-except-error"
    >
      <mat-select
        formControlName="warehouseLocation"
        disableOptionCentering
        [placeholder]="'MAPPING_SETTINGS.WAREHOUSE' | translate"
        panelClass="mat-select-display-change"
        required
      >
        <mat-option *ngFor="let warehouseItem of warehouseList" [value]="warehouseItem.name">
          {{ warehouseItem.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="catalogForm.get('settings.warehouseLocation').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
    </mat-form-field>
  </div> -->

  <!--Categories-->
  <!-- <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
    <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center" class="pr-20">
      <div class="h5">{{ 'MAPPING_SETTINGS.MAIN_CATEGORIES' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.MAIN_CATEGORIES_DESCR' | translate"
        >info</mat-icon
      >
    </div>
    <mat-form-field
      appearance="fill"
      fxFlex="1 0 0"
      class="input-without-padding-except-error"
      formGroupName="settings"
    >
      <mat-label> {{ 'MAPPING_SETTINGS.MAIN_CATEGORIES' | translate }}</mat-label>
      <app-chip-with-autocomplete
        required
        formControlName="mainCategories"
        [items]="categories"
        [idProperty]="'id'"
        [nameProperty]="'name'"
        [separatorKeysCodes]="separatorKeysCodes"
      ></app-chip-with-autocomplete>
      <mat-error *ngIf="catalogForm.get('settings.mainCategories').hasError('required')">{{
        'FORM_VALIDATORS.REQUIRED' | translate
      }}</mat-error>
    </mat-form-field>
  </div> -->

  <!--Supplier message-->
  <div
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.gt-sm="center center"
    fxLayoutGap="0"
    fxLayoutGap.lt-md="8px"
  >
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">
        {{ 'MAPPING_SETTINGS.SUPPLIER_MESSAGE' | translate }}
      </div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.SUPPLIER_MESSAGE_DESC' | translate"
        >info</mat-icon
      >
    </div>
    <mat-form-field
      formGroupName="settings"
      appearance="fill"
      fxFlex="1 0 0"
      class="select-without-label input-without-padding-except-error"
    >
      <textarea
        matInput
        [placeholder]="'MAPPING_SETTINGS.SUPPLIER_MESSAGE' | translate"
        formControlName="approveDescription"
        rows="5"
      ></textarea>
      <mat-error *ngIf="catalogForm.get('settings.approveDescription').hasError('required')">
        {{ 'MAPPING_SETTINGS.SUPPLIER_MESSAGE_ERROR_MAXLENGTH' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <ng-container *ngIf="approveNeededShown">
    <ng-container *ngTemplateOutlet="approveNeededOptions"></ng-container>
  </ng-container>

  <!--Backorder-->
  <div
    fxLayout="row"
    fxLayoutAlign.gt-sm="center center"
    fxLayoutGap="0"
    fxLayoutGap.lt-md="8px"
    formGroupName="settings"
  >
    <div
      fxFlex="1 0 0"
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="space-between center"
      class="pr-20"
    >
      <div class="h5">{{ 'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.BACKORDER' | translate }}</div>
      <mat-icon class="s-20 icon-filled" [matTooltip]="'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.ALLOW' | translate"
        >info</mat-icon
      >
    </div>
    <div fxFlex="1 0 0" formGroupName="feedSettings" fxLayoutAlign.lt-md="flex-end center">
      <mat-slide-toggle formControlName="backorder"></mat-slide-toggle>
    </div>
  </div>

  <!--This product requires shipping-->
  <!-- <div fxLayout="row" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px" formGroupName="settings">
    <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center" class="pr-20">
      <div class="h5">{{ 'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.THIS' | translate }}</div>
      <mat-icon
        class="s-20 icon-filled"
        [matTooltip]="'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.REQ_SHIPPING_DESC' | translate"
        >info</mat-icon
      >
    </div>
    <div fxFlex="1 0 0" formGroupName="feedSettings" fxLayoutAlign.lt-md="flex-end center">
      <mat-slide-toggle formControlName="shipping"></mat-slide-toggle>
    </div>
  </div> -->

  <!-- Admin settings  -->
  <div *ngIf="isAdmin$ | async" class="mt-40 p-20 admin-settings">
    <div class="typography-body-large-2 mb-40">{{ 'CATALOG_EDITOR.ADMIN_SETTINGS' | translate }}</div>

    <app-custom-divider [margin]="'32px'"></app-custom-divider>

    <!-- Translation settings -->
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center">
        <div class="h5">{{ 'MAPPING_SETTINGS.TRANSLATION_SETTINGS' | translate }}</div>
        <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.TRANSLATION_SETTINGS_DESCR' | translate"
          >info</mat-icon
        >
      </div>
      <div
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        fxLayoutAlign.gt-sm="space-between center"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="48px"
        fxLayoutGap.lt-md="12px"
      >
        <div>{{ 'MAPPING_SETTINGS.TRANSLATION_FROM' | translate }}</div>
        <mat-form-field
          formGroupName="settings"
          appearance="fill"
          fxFlex="1 0 0"
          class="select-without-label input-without-padding-except-error"
        >
          <mat-select formControlName="translate_source" [placeholder]="'MAPPING_SETTINGS.LANGUAGE' | translate">
            <mat-option *ngFor="let lng of translationLanguages" [value]="lng.language">
              {{ lng.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div>{{ 'MAPPING_SETTINGS.TRANSLATION_TO' | translate }}</div>
        <mat-form-field
          formGroupName="settings"
          appearance="fill"
          fxFlex="1 0 0"
          class="select-without-label input-without-padding-except-error"
        >
          <mat-select formControlName="translate_target" [placeholder]="'MAPPING_SETTINGS.LANGUAGE' | translate">
            <mat-option *ngFor="let lng of translationLanguages" [value]="lng.language">
              {{ lng.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <app-custom-divider [margin]="'32px'"></app-custom-divider>

    <!-- Private catalog -->
    <div>
      <div fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
        <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" class="pr-20">
          <div class="h5">{{ 'MAPPING_SETTINGS.IS_PRIVATE' | translate }}</div>
          <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.IS_PRIVATE_DESC' | translate"
            >info</mat-icon
          >
        </div>
        <div fxFlex="1 0 0" fxLayoutAlign.lt-md="flex-end center">
          <mat-slide-toggle formControlName="isHidden" (change)="privateChanged($event)"></mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="isCodePrivate">
        <div>
          <p>{{ 'MAPPING_SETTINGS.CODE_PRIVATE_LABEL' | translate }}:</p>
        </div>
        <mat-form-field appearance="fill" class="w-100p">
          <mat-label>{{ 'MAPPING_SETTINGS.CODE_PRIVATE' | translate }}</mat-label>
          <input
            matInput
            formControlName="privateCode"
            [placeholder]="'MAPPING_SETTINGS.CODE_PRIVATE' | translate"
            [maxLength]="255"
            required
          />
          <mat-error>
            {{ 'MAPPING_SETTINGS.ERROR_CODE_PRIVATE_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <app-custom-divider [margin]="'32px'"></app-custom-divider>

    <ng-container *ngIf="!approveNeededShown">
      <ng-container *ngTemplateOutlet="approveNeededOptions"></ng-container>

      <app-custom-divider [margin]="'32px'"></app-custom-divider>
    </ng-container>

    <!-- Visible -->
    <div class="mb-20" fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
      <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px">
        <div class="h5">{{ 'CATALOG_EDITOR.VISIBLE' | translate }}</div>
        <mat-icon class="s-20 icon-filled" [matTooltip]="'CATALOG_EDITOR.VISIBLE_DESC' | translate">info</mat-icon>
      </div>
      <div fxFlex="1 0 0" fxLayoutAlign.lt-md="flex-end center">
        <mat-slide-toggle formControlName="visibility"></mat-slide-toggle>
      </div>
    </div>

    <!-- Exclude product from marketplace -->
    <div
      *ngIf="isAdmin$ | async"
      fxLayoutAlign.gt-sm="center center"
      fxLayout.lt-md="column"
      fxLayout.gt-sm="row"
      fxLayoutGap="0"
      fxLayoutGap.lt-md="20px"
    >
      <div fxFlex="1 0 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign.lt-md="space-between center" class="pr-20">
        <div class="h5">{{ 'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.EXCLUDE_PLATFORMS' | translate }}</div>
        <mat-icon
          class="s-20 icon-filled"
          [matTooltip]="'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.EXCLUDE_PLATFORMS_DESC' | translate"
          >info</mat-icon
        >
      </div>
      <div fxFlex="1 0 0">
        <mat-form-field appearance="fill" fxFlex="1 0 0" class="input-without-padding-except-error">
          <mat-label> {{ 'CATALOG_EDITOR.CATALOG_FEED_SETTINGS.EXCLUDE_PLATFORMS_PLACEHOLDER' | translate }}</mat-label>
          <app-chip-with-autocomplete
            formControlName="platformExceptions"
            [items]="platformList"
            [idProperty]="'id'"
            [nameProperty]="'name'"
            [separatorKeysCodes]="separatorKeysCodes"
          ></app-chip-with-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

  <ng-template #approveNeededOptions>
    <!--Approve needed-->
    <div fxLayout="column" fxLayoutGap="20px" class="pt-20 pb-20">
      <div fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
        <div fxFlex="1 0 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="pr-20">
          <div class="h5">{{ 'MAPPING_SETTINGS.APPROVE' | translate }}</div>
          <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.APPROVE_DESC' | translate">info</mat-icon>
        </div>
        <div formGroupName="settings" fxFlex="1 0 0" fxLayoutAlign.lt-md="flex-end center">
          <mat-slide-toggle formControlName="approveNeeded"></mat-slide-toggle>
        </div>
      </div>

      <!--Show prices-->
      <div *ngIf="approveNeeded" class="pt-24 pl-20 left-border" fxLayout="column" fxLayoutGap="20px">
        <div fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
          <div fxFlex="1 0 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="h5">{{ 'MAPPING_SETTINGS.SHOW' | translate }}</div>
            <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.SHOW_DESC' | translate">info</mat-icon>
          </div>
          <div formGroupName="settings" fxFlex="1 0 0" fxLayoutAlign.lt-md="flex-end center">
            <mat-slide-toggle formControlName="showPrices"></mat-slide-toggle>
          </div>
        </div>

        <!-- Message required -->
        <div fxLayoutAlign.gt-sm="center center" fxLayoutGap="0" fxLayoutGap.lt-md="8px">
          <div fxFlex="1 0 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="h5">
              {{ 'MAPPING_SETTINGS.NEED_RESPONSE' | translate }}
            </div>
            <mat-icon class="s-20 icon-filled" [matTooltip]="'MAPPING_SETTINGS.NEED_RESPONSE_HINT' | translate"
              >info</mat-icon
            >
          </div>
          <div formGroupName="settings" fxFlex="1 0 0" fxLayoutAlign.lt-md="flex-end center">
            <mat-slide-toggle formControlName="needResponse"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</form>
