import { AlibabaNavigation } from './alibaba.navigation';
import { BillingNavigation } from './billing.navigation';
import { DatafeedManagerNavigation } from './datafeed-manager.navigation';
import { FavoritesNavigation } from './favorites.navigation';
import { ProfileNavigation } from './profile.navigation';

const prefix = 'retailer/';

export type RetailerCenterNavigation = (typeof RetailerCenterNavigation)[keyof typeof RetailerCenterNavigation];
export const RetailerCenterNavigation = {
  DASHBOARD: `${prefix}dashboard`,
  IMPORT_LIST: `${prefix}import-list`,
  ORDERS: `${prefix}orders`,
  FAVORITES: FavoritesNavigation,
  MY_SUPPLIERS: `${prefix}my-suppliers`,
  MESSAGES: `${prefix}messages`,
  REFERRALS: `${prefix}referrals`,
  SETTINGS: ProfileNavigation,
  BILLING: BillingNavigation,
  APPS: `${prefix}apps`,
  ALIBABA: AlibabaNavigation,
  DATAFEED_MANAGER: DatafeedManagerNavigation,
} as const;
