import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[buttonWithIcon]',
  standalone: true,
})
export class ButtonWithIconDirective implements OnInit {
  @Input() buttonWithIcon: string;
  @Input() iconPosition: IconPosition = 'start';

  constructor(private el: ElementRef<HTMLButtonElement>, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    const iconEl = this.document.createElement('mat-icon');
    const parent = this.el.nativeElement.getElementsByClassName('mat-button-wrapper')[0];
    this.addClass(iconEl);
    this.addStyle(iconEl);
    this.insertIcon(iconEl, parent);
  }

  private insertIcon(iconEl: HTMLElement, parent: Element): void {
    iconEl.textContent = this.buttonWithIcon;
    if (this.iconPosition === 'start') {
      parent.insertBefore(iconEl, parent.firstChild);
    } else {
      parent.appendChild(iconEl);
    }
  }

  private addClass(iconEl: HTMLElement): void {
    this.el.nativeElement.classList.add('button-with-icon', this.iconPosition);
    iconEl.classList.add(...['s-20', 'button-icon']);
  }

  private addStyle(iconEl: HTMLElement): void {
    if (this.iconPosition == 'end') {
      iconEl.style.marginLeft = 'auto';
    }
  }
}

type IconPosition = 'end' | 'start';
