import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[horizontalScroll]',
  standalone: true,
})
export class HorizontalScrollDirective {
  private isTouch = false;
  private touchStartX = 0;
  private scrollStartX = 0;

  constructor(private element: ElementRef) {}

  @HostListener('wheel', ['$event'])
  public onWheel(event: WheelEvent): void {
    if (Math.abs(event.deltaX) < Math.abs(event.deltaY) && !event.ctrlKey) {
      event.preventDefault();
      this.element.nativeElement.scrollLeft += event.deltaY;
    }
  }

  @HostListener('touchstart', ['$event'])
  public onTouchStart(event: TouchEvent): void {
    this.isTouch = true;
    this.touchStartX = event.touches[0].pageX;
    this.scrollStartX = this.element.nativeElement.scrollLeft;
  }

  @HostListener('touchmove', ['$event'])
  public onTouchMove(event: TouchEvent): void {
    if (!this.isTouch) {
      return;
    }

    const touchCurrentX = event.touches[0].pageX;
    const diff = this.touchStartX - touchCurrentX;
    this.element.nativeElement.scrollLeft = this.scrollStartX + diff;
  }

  @HostListener('touchend')
  public onTouchEnd(): void {
    this.isTouch = false;
  }
}
