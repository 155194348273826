<div #addFilesContainer class="add-files-container">
  <div *ngIf="_currentTaskId && tabs">
    <mat-tab-group
      class="addFileMatGroup"
      [selectedIndex]="selectedTab"
      (selectedTabChange)="initProductSample($event.index, true)"
    >
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          <div class="file-mat-tab-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <span>{{ tab.label }}</span>
            <!--            <button-->
            <!--              [disabled]="!tab.fileId"-->
            <!--              mat-icon-button-->
            <!--              class="syncee-blue-button"-->
            <!--              (click)="refreshSettings(tab.fileId)"-->
            <!--            >-->
            <!--              <mat-icon>refresh</mat-icon>-->
            <!--            </button>-->
          </div>
        </ng-template>
        <div class="pt-20">
          <app-filemanagertab
            #fileManagerTabs
            *ngIf="tab.inputs"
            [tabManagerInputs]="tab.inputs"
            (removeTabCallback)="removeTabEvent($event)"
            (setTabNameCallback)="setTabNameCallback($event)"
            (settingsSavedCallback)="setFileIDCallback($event)"
            (saveClicked)="closeMappingAndScrollTop()"
            [comments]="comments"
            [userRole]="userRole"
          ></app-filemanagertab>
        </div>
      </mat-tab>
      <mat-tab *ngIf="showFileAddBtn" [disabled]="true">
        <ng-template mat-tab-label>
          <div
            class="file-mat-tab-title black-500-fg cursor-pointer"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            (click)="addNewFile()"
          >
            <mat-icon class="s-20">add</mat-icon>
            <span>{{ 'TASKWIZARD.ADDFILES.ADD_NEW' | translate }}</span>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px" cdkDropListGroup>
      <mat-accordion #accordion="matAccordion" class="w-100p mt-44" *ngIf="!isEmptyTabs && mappingInputVoArray">
        <mat-expansion-panel
          [expanded]="expanded"
          (opened)="expanded = true"
          (closed)="expanded = false"
          [ngStyle]="{ overflow: expanded ? 'unset' : 'hidden' }"
        >
          <mat-expansion-panel-header>
            <div fxLayoutAlign="center center" fxLayoutGap="12px">
              <div class="typography-body-3">{{ 'TASKWIZARD.ADDFILES.MAPPING_LABEL' | translate }}</div>
              <app-badge-custom
                *ngIf="hasMapping"
                badgeSize="sm"
                borderRadius="4px"
                bgColor="var(--app-syncee-primary-50)"
                textColor="var(--app-syncee-primary-700)"
                [text]="'TASKWIZARD.ADDFILES.COMPLETED' | translate"
              ></app-badge-custom>
            </div>
          </mat-expansion-panel-header>
          <div class="pb-32">
            {{ 'TASKWIZARD.ADDFILES.DRAG_AND_DROP' | translate }}
          </div>
          <div class="relative" fxLayout="row" fxLayoutGap="40px">
            <div fxFlex="7 0 0" fxFlex.lt-lg="6 0 0" fxFlex.lt-md="1 0 0" class="m-w-0">
              <ng-container [ngSwitch]="role">
                <ng-container *ngSwitchCase="'SUPPLIER'">
                  <app-productdetailsbuilder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                    [prodSampleInputVo]="currentProdSampleInputVO"
                  ></app-productdetailsbuilder>
                </ng-container>
                <ng-container *ngSwitchCase="'DF_SHOPIFY'">
                  <app-shopify-product-details-builder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                    [prodSampleInputVo]="currentProdSampleInputVO"
                  ></app-shopify-product-details-builder>
                </ng-container>
                <ng-container *ngSwitchCase="'RETAILER_VARIANTS_UPDATE'">
                  <app-shopify-variants-update-product-details-builder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                    [prodSampleInputVo]="currentProdSampleInputVO"
                  ></app-shopify-variants-update-product-details-builder>
                </ng-container>
                <ng-container *ngSwitchCase="'RETAILER_JUMPSELLER_VARIANTS_UPDATE'">
                  <app-retailer-jumpseller-vu-builder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                  >
                  </app-retailer-jumpseller-vu-builder>
                </ng-container>
                <ng-container *ngSwitchCase="'DF_JUMPSELLER'">
                  <app-jumpseller-mapping-builder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                  ></app-jumpseller-mapping-builder>
                </ng-container>

                <ng-container *ngSwitchCase="'DF_SHOPRENTER'">
                  <app-shoprenter-mapping-builder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                  ></app-shoprenter-mapping-builder>
                </ng-container>

                <ng-container *ngSwitchCase="'DF_ECWID'">
                  <app-ecwid-mapping-builder
                    #productDetailsBuilder
                    [files]="mappingInputVoArray"
                    [taskId]="_currentTaskId"
                    [comments]="comments"
                  ></app-ecwid-mapping-builder>
                </ng-container>
              </ng-container>
            </div>
            <app-prod-sample-list
              fxFlex="3 0 0"
              fxFlex.lt-lg="4 0 0"
              fxFlex.lt-md="1 0 0"
              class="fixed m-w-0"
              *showOnBreakpoint="'lt-md'; variant: 'hide'"
              [prodSampleVo]="currentProdSampleInputVO"
            ></app-prod-sample-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
