<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div *ngIf="saveMappingWarning" class="notification warning">
    <div class="msg-container">
      <mat-icon class="type-icon">warning</mat-icon>
      <ng-container> {{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.UNSAVED_WARNING' | translate }} </ng-container>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
    <div class="fuse-card mapping-container" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
      <form #productDetatailsForm="ngForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
        <div class="h2 mb-24">Basic Fields</div>
        <div class="product-details">
          <!--ID-->
          <div *ngIf="mappingData.productIds" id="product-id-container" fxLayout="row" fxLayoutAlign="start">
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="ID_{{ prod.id }}"
              name="productId_{{ prod.id }}"
              [(ngModel)]="prod.value.chips"
              [(operations)]="prod.value.operations"
              *ngFor="let prod of mappingData.productIds"
              fxFlex="{{ 100 / mappingData.productIds.length }}"
              title="ID"
              placeholder="ID"
            ></app-droppable-input>
          </div>

          <!--&lt;!&ndash;Title&ndash;&gt;-->
          <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
          <!--<app-droppable-input #normalProcess [required]="true"-->
          <!--id="TITLE"-->
          <!--title="Title"-->
          <!--name="TITLE"-->
          <!--[(ngModel)]="mappingData.singleFields.TITLE.chips"-->
          <!--[(operations)]="mappingData.singleFields.TITLE.operations"-->
          <!--name="ngModelTest"-->
          <!--placeholder="Title"></app-droppable-input>-->
          <!--</div>-->

          <!--&lt;!&ndash;Description&ndash;&gt;-->
          <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
          <!--<app-droppable-input #normalProcess-->
          <!--id="DESCRIPTION"-->
          <!--name="DESCRIPTION"-->
          <!--[(ngModel)]="mappingData.singleFields.DESCRIPTION.chips"-->
          <!--[(operations)]="mappingData.singleFields.DESCRIPTION.operations"-->
          <!--title="Description"-->
          <!--[textAreaMode]="true"-->
          <!--placeholder="Description"></app-droppable-input>-->
          <!--</div>-->

          <div id="product-variants-container" fxLayout="column" fxLayoutAlign="start">
            <div id="product-price-and-stuff-container">
              <div fxLayout="row" fxLayoutAlign="start">
                <!--Price-->
                <app-droppable-input
                  #normalProcess
                  id="PRICE"
                  title="Price"
                  name="PRICE"
                  [isPriceSettings]="true"
                  [(ngModel)]="mappingData.singleFields.PRICE.chips"
                  [(settings)]="mappingData.singleFields.PRICE.settings"
                  [(operations)]="mappingData.singleFields.PRICE.operations"
                  [style.width.%]="50"
                  placeholder="Price"
                ></app-droppable-input>

                <!--Weight-->
                <app-droppable-input
                  #normalProcess
                  id="WEIGHT"
                  [style.width.%]="50"
                  title="Weight"
                  name="WEIGHT"
                  [isWeightSettings]="true"
                  [(ngModel)]="mappingData.singleFields.WEIGHT.chips"
                  [(operations)]="mappingData.singleFields.WEIGHT.operations"
                  placeholder="Weight"
                ></app-droppable-input>
              </div>
            </div>
          </div>
        </div>

        <!--<div id="product-images-container" fxLayout="column" fxLayoutAlign="end" fxFlex="1 1 auto">-->
        <!--<div id="product-images-info" class="product-imgs" fxLayout="row" fxLayoutAlign="start"-->
        <!--ngModelGroup="image"-->
        <!--*ngFor="let img of mappingData.images; last as isLast; let i = index">-->

        <!--&lt;!&ndash;Image URL&ndash;&gt;-->
        <!--<app-droppable-input #imagesURLProcess-->
        <!--id="IMAGES"-->
        <!--title="Image URL"-->
        <!--name="IMAGES-{{i}}"-->
        <!--[(ngModel)]="img.url.chips"-->
        <!--[(operations)]="img.url.operations"-->
        <!--[style.width.%]="60"-->
        <!--placeholder="Image URL"></app-droppable-input>-->

        <!--&lt;!&ndash;Image Alt&ndash;&gt;-->
        <!--<app-droppable-input #imagesALTProcess-->
        <!--id="IMAGES_ALTS"-->
        <!--name="IMAGES_ALTS-{{i}}"-->
        <!--[(ngModel)]="img.value.chips"-->
        <!--[(settings)]="img.value.settings"-->
        <!--[(operations)]="img.value.operations"-->
        <!--title="Image Alt"-->
        <!--[style.width.%]="30"-->
        <!--placeholder="Image Alt"></app-droppable-input>-->

        <!--<div class="icon-holder" fxLayoutAlign="start center">-->
        <!--<button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeProdImage(i)">-->
        <!--<mat-icon aria-label="Delete icon" [style.width.%]="5">-->
        <!--delete-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--<button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProdImage()">-->
        <!--<mat-icon aria-label="new Image icon" [style.width.%]="5">-->
        <!--add_circle_outline-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="h2 mb-24">Organization Fields</div>-->
        <!--<div id="product-categories" fxLayout="column" fxLayoutAlign="start stretch">-->

        <!--&lt;!&ndash;Product type&ndash;&gt;-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="PRODUCT_TYPE"-->
        <!--name="PRODUCT_TYPE"-->
        <!--[(ngModel)]="mappingData.singleFields.PRODUCT_TYPE.chips"-->
        <!--[(operations)]="mappingData.singleFields.PRODUCT_TYPE.operations"-->
        <!--title="Product Type"-->
        <!--placeholder="Product Type" ></app-droppable-input>-->

        <!--&lt;!&ndash;Tags&ndash;&gt;-->
        <!--<div id="product-tags" fxLayout="row" fxLayoutAlign="start"-->
        <!--*ngFor="let tag of mappingData.tags; last as isLast; let i = index">-->
        <!--<app-droppable-input #tagsProcess-->
        <!--id="TAGS"-->
        <!--title="Tag"-->
        <!--name="TAGS-{{i}}"-->
        <!--[(ngModel)]="tag.chips"-->
        <!--[(operations)]="tag.operations"-->
        <!--[style.width.%]="90"-->
        <!--placeholder="Tag"></app-droppable-input>-->
        <!--<div class="icon-holder" fxLayoutAlign="start center">-->
        <!--<button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeTag(i)">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--delete-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--<button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewTag()">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--add_circle_outline-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->

        <!--&lt;!&ndash;Vendor&ndash;&gt;-->
        <!--<div id="product-brands" fxLayout="row" fxLayoutAlign="start">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="BRAND"-->
        <!--title="Vendor"-->
        <!--name="BRAND"-->
        <!--[(ngModel)]="mappingData.singleFields.BRAND.chips"-->
        <!--[(operations)]="mappingData.singleFields.BRAND.operations"-->
        <!--[style.width.%]="100"-->
        <!--placeholder="Vendor"></app-droppable-input>-->
        <!--</div>-->

        <!--&lt;!&ndash;Collections&ndash;&gt;-->
        <!--<div id="product-collections" fxLayout="row" fxLayoutAlign="start"-->
        <!--*ngFor="let collection of mappingData.collections; last as isLast; let i = index">-->
        <!--<app-droppable-input #collectionsProcess-->
        <!--id="COLLECTIONS"-->
        <!--title="Collection"-->
        <!--name="COLLECTIONS-{{i}}"-->
        <!--[(ngModel)]="collection.chips"-->
        <!--[(operations)]="collection.operations"-->
        <!--[style.width.%]="90"-->
        <!--placeholder="Collection"></app-droppable-input>-->
        <!--<div class="icon-holder" fxLayoutAlign="start center">-->
        <!--<button mat-icon-button [disabled]="i == 0" (click)="mappingData.removeCollection(i)">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--delete-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--<button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewCollection()">-->
        <!--<mat-icon aria-label="Example icon-button with a heart icon" [style.width.%]="5">-->
        <!--add_circle_outline-->
        <!--</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->

        <!--</div>-->

        <!--                <div class="h2 mb-24">Variant Fields</div>-->

        <!--<div fxLayout="row" fxLayoutAlign="start">-->

        <!--&lt;!&ndash;Image URL&ndash;&gt;-->
        <!--<app-droppable-input #imagesURLProcess-->
        <!--id="V_IMAGES"-->
        <!--title="Image URL"-->
        <!--name="V_IMAGES"-->
        <!--[(ngModel)]="mappingData.singleFields.V_IMAGES.chips"-->
        <!--[(operations)]="mappingData.singleFields.V_IMAGES.operations"-->
        <!--[style.width.%]="50"-->
        <!--placeholder="Image URL"></app-droppable-input>-->

        <!--&lt;!&ndash;Image Alt&ndash;&gt;-->
        <!--<app-droppable-input #imagesALTProcess-->
        <!--id="V_IMAGES_ALTS"-->
        <!--name="V_IMAGES_ALTS"-->
        <!--[(ngModel)]="mappingData.singleFields.V_IMAGES_ALTS.chips"-->
        <!--[(operations)]="mappingData.singleFields.V_IMAGES_ALTS.operations"-->
        <!--title="Image Alt"-->
        <!--[style.width.%]="50"-->
        <!--placeholder="Image Alt"></app-droppable-input>-->

        <!--</div>-->

        <!--Variant SKU-->
        <div fxLayout="row" fxLayoutAlign="start">
          <app-droppable-input
            #normalProcess
            id="SKU"
            title="Variant SKU"
            name="SKU"
            [style.width.%]="50"
            [(ngModel)]="mappingData.singleFields.SKU.chips"
            [(operations)]="mappingData.singleFields.SKU.operations"
            placeholder="Variant SKU"
          ></app-droppable-input>

          <app-droppable-input
            #normalProcess
            id="QTY"
            title="Stock"
            name="QTY"
            [style.width.%]="50"
            [(ngModel)]="mappingData.singleFields.QTY.chips"
            [(operations)]="mappingData.singleFields.QTY.operations"
            placeholder="Stock"
          ></app-droppable-input>
        </div>

        <!--<div class="h2 mb-24">SEO Fields</div>-->

        <!--&lt;!&ndash;Handle&ndash;&gt;-->
        <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="SHOPIFY_HANDLE"-->
        <!--title="Handle"-->
        <!--name="SHOPIFY_HANDLE"-->
        <!--[(ngModel)]="mappingData.singleFields.SHOPIFY_HANDLE.chips"-->
        <!--[(operations)]="mappingData.singleFields.SHOPIFY_HANDLE.operations"-->
        <!--placeholder="Handle"></app-droppable-input>-->
        <!--</div>-->

        <!--&lt;!&ndash;Page title (website seo)&ndash;&gt;-->
        <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"-->
        <!--title="Page title"-->
        <!--name="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"-->
        <!--[(ngModel)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.chips"-->
        <!--[(operations)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.operations"-->
        <!--placeholder="Page title"></app-droppable-input>-->
        <!--</div>-->

        <!--&lt;!&ndash;Meta Description (website seo)&ndash;&gt;-->
        <!--<div fxLayout="column" fxLayoutAlign="start stretch">-->
        <!--<app-droppable-input #normalProcess-->
        <!--id="SHOPIFY_META_DESC"-->
        <!--title="Meta Description"-->
        <!--name="SHOPIFY_META_DESC"-->
        <!--[(ngModel)]="mappingData.singleFields.SHOPIFY_META_DESC.chips"-->
        <!--[(operations)]="mappingData.singleFields.SHOPIFY_META_DESC.operations"-->
        <!--[textAreaMode]="true"-->
        <!--placeholder="Meta Description"></app-droppable-input>-->
        <!--</div>-->
      </form>
    </div>
  </div>
</div>
<button mat-button class="syncee-black-flat-button" (click)="takonySave()" *ngIf="isAdmin">Save</button>
