<ng-container *ngIf="!!group">
  <ng-container [ngSwitch]="group.type">
    <ng-container *ngSwitchCase="'tab'">
      <ng-container *ngTemplateOutlet="tab"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #tab>
  <app-submenu-tab [group]="group"></app-submenu-tab>
</ng-template>
