import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-from-to-date-picker',
  templateUrl: './from-to-date-picker.component.html',
  styleUrls: ['./from-to-date-picker.component.scss'],
  imports: [MatInputModule, MatDatepickerModule, ReactiveFormsModule, TranslateModule, FlexModule],
  standalone: true,
})
export class FromToDatePickerComponent implements OnInit {
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  @Input()
  gap = 8;
  @Input()
  gapUnit: 'px' | 'em' = 'px';
  @Input()
  direction: 'row wrap' | 'column' = 'row wrap';
  @Output()
  dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output()
  dateToChange: EventEmitter<Date> = new EventEmitter<Date>();
  dateFromControl: FormControl;
  dateToControl: FormControl;

  constructor() {}

  ngOnInit(): void {
    this.dateFromControl = new FormControl(this.dateFrom);
    this.dateToControl = new FormControl(this.dateTo);
  }

  handleFromDateChange(event: MatDatepickerInputEvent<any>): void {
    !!event.value ? this.dateFromChange.emit(new Date(event.value)) : this.dateFromChange.emit(null);
  }

  handleToDateChange(event: MatDatepickerInputEvent<any>): void {
    !!event.value ? this.dateToChange.emit(new Date(event.value)) : this.dateToChange.emit(null);
  }
}
