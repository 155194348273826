import { Component, OnInit } from '@angular/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';

@Component({
  selector: 'app-product-page-price-display-guest',
  templateUrl: './product-page-price-display-guest.component.html',
  styleUrls: ['./product-page-price-display-guest.component.scss'],
})
export class ProductPagePriceDisplayGuestComponent implements OnInit {
  constructor(private authManagerService: AuthenticationManagerService) {}

  ngOnInit(): void {}

  emitLoginDialog(event): void {
    event.preventDefault();
    this.authManagerService.emitOpenLoginDialog();
  }

  emitRegistrationDialog(): void {
    this.authManagerService.emitOpenRegistrationDialog();
  }
}
