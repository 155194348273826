<div class="container" [ngStyle]="{ gap: screenService.small ? '20px' : '28px' }">
  <div class="content-container">
    <div class="text-container">
      <div class="h3 syncee-grey-800-fg text-center">{{ config.payload['title'] | translate }}</div>
      <div [ngClass]="descriptionStyle">
        {{ config.payload['description'] | translate }}
      </div>
    </div>
  </div>

  <div class="select-container">
    <app-tile-select
      [defaultValue]="changeRoleService.ecomType"
      [options]="options"
      [columns]="screenService.small ? '2' : '5'"
      rowHeight="85px"
      gridGap="12px"
      (onChange)="handleChange($event)"
      borderRadius="7px"
    ></app-tile-select>
  </div>

  <div class="actions-container">
    <button
      *ngIf="!!this.changeRoleService.ecomType"
      mat-button
      class="syncee-blue-button"
      (click)="openConfirmDialog(true)"
    >
      {{ config.payload['continue'] | translate }}
    </button>

    <app-or-divider class="w-100p"></app-or-divider>

    <div fxLayout="column" fxLayoutAlign="center center">
      <button (click)="openConfirmDialog(false)" mat-button class="syncee-black-flat-button">
        {{ config.payload['close'] | translate }}
      </button>
    </div>
  </div>
</div>
