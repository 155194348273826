import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { CustomTableDataResponse } from '../custom-table/custom-table.component';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { NoSearchResultsComponent } from '../no-search-results/no-search-results.component';
import { Utils } from '../../../utils/utils';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
  imports: [CommonModule, MatPaginatorModule, NoSearchResultsComponent, LoadingSpinnerComponent],
  standalone: true,
})
export class CustomPaginationComponent<T> implements OnInit, OnDestroy {
  @ContentChild('row', { static: false }) rowTemplateRef: TemplateRef<any>;
  @Input() fetcher: (page: number, pageSize: number) => Observable<CustomTableDataResponse<T>>;
  @Input() page = 0;
  @Input() size: number;
  @Input() pageSizeOptions: number[] = [5, 10, 25];
  @Input() isServerSidePaginate = true;
  @Input() forceReload?: Subject<void>;
  @Input() setPageToZero?: Subject<void>;
  @Input() noResultComponent: TemplateRef<HTMLElement>;
  @Input() fetcherLogic = true;
  @Input() dataSource: T[];
  @Input() count = 0;
  @Input() bordered = false;
  @Input() isPaginate = true;
  @Output() nextClicked = new EventEmitter<void>();
  @Output() previousClicked = new EventEmitter<void>();
  @Output() sizeChanged = new EventEmitter<number>();
  staticPaginateSubject = new BehaviorSubject<void>(null);
  isEmpty: boolean;
  isLoading: boolean;
  private unsubscribeAll: Subject<void>;

  constructor() {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.fetch();
    this.subscribeToForceReload();
    this.subscribeToSetPageToZero();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToSetPageToZero(): void {
    if (!!this.setPageToZero) {
      this.setPageToZero.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => (this.page = 0));
    }
  }

  private subscribeToForceReload(): void {
    if (!!this.forceReload) {
      this.forceReload.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.reload());
    }
  }

  private reload(page?: number): void {
    this.page = Utils.isNullOrUndefinedOrLengthZero(page) ? this.page : page;
    this.fetch();
  }

  private handlePaginatonWithoutFetch(event: PageEvent): void {
    event.pageIndex > this.page ? this.nextClicked.emit() : this.previousClicked.emit();
    if (event.pageSize !== this.size) {
      this.sizeChanged.emit(event.pageSize);
    }
  }

  fetch(): void {
    if (!this.fetcher) {
      return;
    }

    this.fetcher(this.page, this.size)
      .pipe(
        switchMap((response) => {
          if (this.isServerSidePaginate) {
            return of(response);
          } else {
            return this.staticPaginateSubject.asObservable().pipe(
              map(() => {
                return this.handleStaticPageChange(response);
              })
            );
          }
        })
      )
      .subscribe((response) => {
        this.isEmpty = response.data.length === 0;
        this.dataSource = response.data;
        this.count = response.allItemsCount;
        this.isLoading = false;
      });
  }

  handlePageEvent(event: PageEvent): void {
    if (this.fetcherLogic) {
      this.page = event.pageIndex;
      this.size = event.pageSize;
      this.fetch();
    } else {
      this.handlePaginatonWithoutFetch(event);
    }
  }

  handleStaticPageChange(response: CustomTableDataResponse<T>): CustomTableDataResponse<T> {
    const start = this.page * this.size;
    const end = start + this.size;
    return {
      page: this.page,
      size: this.size,
      allItemsCount: response.allItemsCount,
      data: response.data.slice(start, end),
    };
  }
}
