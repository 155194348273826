import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FooterMenuItem } from 'app/layout/components/footer/footer.component';
import { socialPlatforms } from 'app/layout/components/footer/footer.config';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { SynceeLogoComponent } from '../syncee-logo/syncee-logo.component';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TranslateModule, SynceeLogoComponent, FooterModule],
})
export class MaintenancePageComponent implements OnInit, OnDestroy {
  readonly currentYear = new Date().getFullYear();
  readonly socialPlatforms = socialPlatforms;
  readonly synceeEmail: FooterMenuItem = {
    type: 'EMAIL',
    value: 'hello@syncee.co',
  };

  ltMd: boolean;

  private unsubscribe: Subject<void>;

  constructor(private fuseConfigService: FuseConfigService, private screenManagerService: ScreenManagerService) {
    this.fuseConfigService.config = {
      layout: {
        contentContainer: 'full-width-without-padding',
        fullHeight: true,
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    this.unsubscribe = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), takeUntil(this.unsubscribe))
      .subscribe((matches) => (this.ltMd = matches));
  }
}
