<div class="product-card-list-container" [@animateStagger]="{ value: '50' }">
  <ng-container>
    <ng-container *ngFor="let _ of [].constructor(numberOfProducts ?? 20); let last = last; let index = index">
      <app-skeleton-product-card
        [ngStyle]="{ width: productCardWidth.xl }"
        [ngStyle.xl]="{ width: productCardWidth.xl }"
        [ngStyle.lg]="{ width: productCardWidth.lg }"
        [ngStyle.md]="{ width: productCardWidth.md }"
        [ngStyle.sm]="{ width: productCardWidth.sm }"
        [ngStyle.xs]="{ width: productCardWidth.xs }"
      ></app-skeleton-product-card>
    </ng-container>

    <ng-container *ngIf="needsFillers">
      <div class="content-fill"></div>
      <div class="content-fill"></div>
      <div class="content-fill"></div>
      <div class="content-fill"></div>
      <div class="content-fill"></div>
    </ng-container>
  </ng-container>
</div>
