import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DatePickerDropdownComponent } from '../components/date-picker-dropdown/date-picker-dropdown.component';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[datePickerDropdown]',
  standalone: true,
})
export class DatePickerDropdownDirective implements OnInit, OnDestroy, OnChanges {
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() position: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
  ];

  @Output() dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange: EventEmitter<Date> = new EventEmitter<Date>();

  overlayRef: OverlayRef;
  closeOverlay$: Subject<void>;
  overlayRefInstance: ComponentRef<DatePickerDropdownComponent>;

  private unsubscribe: Subject<void>;

  constructor(private elementRef: ElementRef, private overlay: Overlay, private injector: Injector) {
    this.unsubscribe = new Subject<void>();
    this.closeOverlay$ = new Subject<void>();
  }

  @HostListener('click')
  openOverlay(): void {
    if (!this.overlayRef) {
      this.createOverlay();
    }
    this.overlayRef.hasAttached() ? this.closeOverlay() : this.attachOverlay();
  }

  ngOnInit(): void {
    this.subscribeToCloseSubject();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.dispose();
    }
  }

  ngOnChanges(): void {
    if (this.overlayRef && this.overlayRef.hasAttached() && this.overlayRefInstance) {
      this.overlayRefInstance.instance.dateFrom = this.dateFrom;
      this.overlayRefInstance.instance.dateTo = this.dateTo;
    }
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(this.position);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
    });
  }

  private attachOverlay(): void {
    const overlayComponent = DatePickerDropdownComponent;
    const portal = new ComponentPortal(overlayComponent, null, this.injector);
    this.overlayRefInstance = this.overlayRef.attach(portal);
    this.subscribeToBackdropClick();

    this.overlayRefInstance.instance.closeOverlay$ = this.closeOverlay$;

    this.overlayRefInstance.instance.dateFrom = this.dateFrom;
    this.overlayRefInstance.instance.dateTo = this.dateTo;

    this.overlayRefInstance.instance.dateFromChange = this.dateFromChange;
    this.overlayRefInstance.instance.dateToChange = this.dateToChange;
  }

  private closeOverlay(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  private subscribeToBackdropClick(): void {
    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.closeOverlay();
      });
  }

  private subscribeToCloseSubject(): void {
    this.closeOverlay$.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.closeOverlay());
  }
}
