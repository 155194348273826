import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from './user';
import { FuseNavigationService } from '../../../@fuse/components/navigation/navigation.service';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { USER_ROLES, USER_ROLES_STRINGS } from '../../utils/Constants';
import { Router } from '@angular/router';
import { NotificationService } from '../../main/notification/notification.service';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { UserToUserNavigatePayload } from '../../store/authentication/authentication.actions';
import { DatafeedManagerNavigation } from '../../navigation/navigation-routes/retailer/datafeed-manager.navigation';
import { ProductListingNavigation } from '../../navigation/navigation-routes/supplier/product-listing.navigation';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { SupplierCenterNavigation } from 'app/navigation/navigation-routes/supplier/supplier-center.navigation';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public static GET_RCATALOG_SUPPLIERS_URL = 'KioskService/getRCatalogSupplierDetails';

  private adminSubject: Subject<User> = new Subject<User>();

  private httpParams = new HttpParams();

  constructor(
    private fuseNavigationService: FuseNavigationService,
    private restService: RestService,
    private router: Router,
    private notiService: NotificationService,
    private _catalogSidebarService: CatalogSidebarService,
    private marketplaceEcomService: MarketplaceEcomService
  ) {}

  adminSubObservable(): any {
    return this.adminSubject.asObservable();
  }

  selectedUserByAdmin(user: User): void {
    this.marketplaceEcomService.adminStopEcomGet();
    this.marketplaceEcomService.isInitialized = false;
    this._catalogSidebarService.clearAll();

    if (!user.retailer || !user.supplier) {
      this.fuseNavigationService.updateNavigationItem('changerole', { hidden: true });
    }
  }

  navigateToUserPageByRole(user: User, navigateTo?: UserToUserNavigatePayload): void {
    // TODO: Revisit this after talking to Gyuszi - by Tibi
    if (user.role === USER_ROLES.AGENT || user.role === USER_ROLES.GLOBAL_ADMIN) {
      this.fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_AGENCY);
      if (navigateTo) {
        this.router.navigate([navigateTo.url], { queryParams: { ...navigateTo.queryParams, userId: user.id } });
      } else {
        this.router.navigate([`/${RetailerCenterNavigation.DASHBOARD}`], { queryParams: { userId: user.id } });
      }
    } else {
      if (user.role === USER_ROLES.SUPPLIER) {
        this.fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_SUPPLIER);
        if (navigateTo) {
          this.router.navigate([navigateTo.url], { queryParams: { ...navigateTo.queryParams, userId: user.id } });
        } else {
          this.router.navigate([`/${SupplierCenterNavigation.DASHBOARD}`], { queryParams: { userId: user.id } });
        }
      } else {
        this.notiService.error("You don't have permission for this operation");
      }
    }
  }

  unsubscribe(): void {
    console.log('leiratkozás');
    this.adminSubject.complete();
  }

  public adminSearch(search?: string, filterBy?: string): Observable<any> {
    let params = this.httpParams;

    if (!Utils.isNullOrUndefined(search)) {
      params = params.set('search', search);
    }
    if (!Utils.isNullOrUndefined(filterBy)) {
      params = params.set('filterBy', filterBy);
    }

    return this.restService.get(`AdminService/globalSearch`, params).pipe(map((response) => response.getFirstData()));
  }

  public getWorkerLogs(taskId: string): Observable<any> {
    const params = this.httpParams.set('taskId', taskId);

    return this.restService.get(`AdminService/getHistoryData`, params).pipe(map((response) => response.getData()));
  }

  public downloadFile(taskId: string, fileId: string, type: string, fullFile: boolean): Observable<any> {
    const params = this.httpParams
      .set('type', type)
      .set('fullFile', fullFile.toString())
      .set('taskId', taskId)
      .set('fileId', fileId);

    return this.restService.get(`AdminService/getFileOfTask`, params).pipe(map((response) => response.getFirstData()));
  }

  public runFeedOnly(taskId: string, updateFields = null): Observable<any> {
    const params = { taskId: taskId };
    if (!Utils.isNullOrUndefined(updateFields)) {
      params['updateFields'] = updateFields;
    }

    return this.restService.post(`AdminService/runFeedOnly`, params);
  }

  public runRCatFeedOnly(catalogId: string, updateFields = null, isPIMSync = null): Observable<any> {
    const params = { catalogId: catalogId };
    if (!Utils.isNullOrUndefined(updateFields)) {
      params['updateFields'] = updateFields;
    }
    if (!Utils.isNullOrUndefined(isPIMSync)) {
      params['isPIMSync'] = true;
    }
    return this.restService.post(`AdminService/runRCatFeedOnly`, params);
  }

  public rCatFilterCleaner(catalogId: string, updateFilter = ''): Observable<any> {
    const params = this.httpParams.set('catalogId', catalogId).set('updateFilter', updateFilter);
    return this.restService.post(`AdminService/rCatFilterCleaner`, params).pipe(map((response) => response.getData()));
  }

  public getFileInfo(taskId: string): Observable<any> {
    const params = this.httpParams.set('taskId', taskId);

    return this.restService.get(`AdminService/getFileInfo`, params).pipe(map((response) => response.getFirstData()));
  }

  public getEcomsForUser(userId: any): Observable<any> {
    const params = this.httpParams.set('userId', userId.toString());

    return this.restService.get(`UserService/getDomains`, params).pipe(map((response) => response.getData()));
  }

  public copyTask(taskId: string, userId: string, ecomId: string): Observable<any> {
    const params = this.httpParams.set('taskId', taskId).set('user', userId).set('ecomId', ecomId);

    return this.restService.post(`AdminService/copyTask`, params).pipe(map((response) => response.getFirstData()));
  }

  public copyRCatalog(catalogId: string, userId: string, ecomId: string): Observable<any> {
    const params = this.httpParams.set('catalogId', catalogId).set('user', userId).set('ecomId', ecomId);

    return this.restService.post(`AdminService/copyRCatalog`, params).pipe(map((response) => response.getFirstData()));
  }

  public getRetailerWorkerLogs(catalogId: string): Observable<any> {
    const params = this.httpParams.set('catalogId', catalogId);

    return this.restService
      .get(`AdminService/getRetailerHistoryData`, params)
      .pipe(map((response) => response.getData()));
  }

  public getRCatalogFileInfo(catalogId: string): Observable<any> {
    const params = this.httpParams.set('catalogId', catalogId);

    return this.restService
      .get(`AdminService/getRCatalogFileInfo`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public downloadRCatalogFile(catalogId: string, fileId: string, fullFile: boolean): Observable<any> {
    const params = this.httpParams
      .set('fullFile', fullFile.toString())
      .set('catalogId', catalogId)
      .set('fileId', fileId);

    return this.restService
      .get(`AdminService/getFilesOfRCatalog`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public getRecurringChargeShopify(ecomId: string): Observable<any> {
    const params = this.httpParams.set('ecomId', ecomId);

    return this.restService
      .get(`AdminService/getRecurringChargeShopify`, params)
      .pipe(map((response) => response.getData()));
  }

  public getRecurringChargeBraintree(ecomId: string): Observable<any> {
    const params = this.httpParams.set('ecomId', ecomId);

    return this.restService
      .get(`AdminService/brainTreeSubscriptionData`, params)
      .pipe(map((response) => response.getData()));
  }

  // old
  public getRunningWorkers(): Observable<any> {
    return this.restService.get(`AdminService/getRunningWorkers`).pipe(map((response) => response.getData()));
  }

  public getRunningProcesses(): Observable<any> {
    return this.restService.get(`AdminService/getRunningProcesses`).pipe(map((response) => response.getData()));
  }

  // old
  public killProcess(pid: string): Observable<any> {
    const params = this.httpParams.set('pid', pid);

    return this.restService.post(`AdminService/killProcess`, params).pipe(map((response) => response.getData()));
  }

  public killProcessById(processId: string): Observable<any> {
    const params = this.httpParams.set('processId', processId);

    return this.restService.post(`AdminService/killProcessById`, params).pipe(map((response) => response.getData()));
  }

  public killProcessesByIds(processIds): Observable<any> {
    return this.restService
      .post(`AdminService/killProcessesByIds`, processIds)
      .pipe(map((response) => response.getData()));
  }

  public getStatisticsForSuppliers(): Observable<any> {
    return this.restService.get(`KioskService/getSupplierStatistics`).pipe(map((response) => response.getData()));
  }

  public getScript(taskId: string, userId: string, scriptName: string): Observable<any> {
    const params = this.httpParams.set('scriptName', scriptName).set('taskId', taskId).set('userId', userId);

    return this.restService.get(`AdminService/getScript`, params).pipe(map((response) => response.getData()));
  }

  public getEcomHistory(userId: string, ecomId: string): Observable<any> {
    const params = this.httpParams.set('user', userId).set('ecomId', ecomId);

    return this.restService.get(`AdminService/getEcomsSessions`, params).pipe(map((response) => response.getData()));
  }

  public deleteProductsById(ids: [], ecomId: string): Observable<any> {
    return this.restService
      .post(`AdminService/deleteProductsById`, { productIds: ids, ecomId: ecomId })
      .pipe(map((response) => response.getData()));
  }

  public deleteProductsByHistoryId(id, ecomId: string): Observable<any> {
    return this.restService
      .post(`AdminService/deleteProductsByHistoryId`, { historyId: id, ecomId: ecomId })
      .pipe(map((response) => response.getData()));
  }

  public getExceptionLog(): Observable<any> {
    return this.restService.get(`AdminService/getExceptionLog`).pipe(map((response) => response.getData()));
  }

  public getIntercomArchivedLog(): Observable<any> {
    return this.restService
      .get(`AdminService/getIntercomToMailerLiteHistory`)
      .pipe(map((response) => response.getData()));
  }

  public searchEcoms(searchString: string): Observable<any> {
    const params = this.httpParams.set('searchString', searchString);

    return this.restService.get(`AdminService/searchEcoms`, params).pipe(map((response) => response.getData()));
  }

  public searchSuppliers(searchString: string): Observable<any> {
    const params = this.httpParams.set('searchString', searchString);

    return this.restService.get(`AdminService/searchSuppliers`, params).pipe(map((response) => response.getData()));
  }

  public getRCatalogSupplierDetails(rcatalogId): Observable<any[]> {
    const params = new HttpParams().set('rcatalogId', rcatalogId.toString());

    return this.restService
      .get(AdminService.GET_RCATALOG_SUPPLIERS_URL, params)
      .pipe(map((response) => response.getData()));
  }

  public sendRequestToEcom(ecomId: number, request: string, requestType: string, requestBody: any): Observable<any> {
    const params = {
      ecomId: ecomId,
      request: request,
      requestType: requestType,
      requestBody: requestBody,
    };

    return this.restService.post(`AdminService/sendRequestToEcom`, params).pipe(map((response) => response.getData()));
  }

  public getExportWorkerLogs(taskId: string): Observable<any> {
    const params = this.httpParams.set('taskId', taskId);

    return this.restService
      .get(`AdminService/getExportHistoryData`, params)
      .pipe(map((response) => response.getData()));
  }

  public getImportLogs(userId, ecomId, catalogId): Observable<any> {
    const params = this.httpParams.set('uId', userId).set('ecomId', ecomId).set('catalogId', catalogId);
    return this.restService
      .get(`AdminService/getLastImportLogs`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public getLastLogsForTask(userId, taskId): Observable<any> {
    const params = this.httpParams.set('uId', userId).set('taskId', taskId);
    return this.restService
      .get(`AdminService/getLastLogsForTask`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public getDestUrlForExportTask(taskId: string, userId: string): Observable<any> {
    const params = this.httpParams.set('taskId', taskId).set('userId', userId);

    return this.restService
      .get(`ExportTaskService/getExportFileURL`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public getWorkerStack(): Observable<any> {
    return this.restService.get(`AdminService/getWorkerStack`).pipe(map((response) => response.getData()));
  }

  public deleteProcess(processId: string): Observable<any> {
    const params = this.httpParams.set('processId', processId);

    return this.restService
      .post(`AdminService/deleteProcessFromWorkerStack`, params)
      .pipe(map((response) => response.getData()));
  }

  public getUnsuccessfulOrders(search: string, sortField: string, sortDirection: string): Observable<any> {
    let params = `?sortField=${sortField}&sortDirection=${sortDirection}`;
    if (!Utils.isNullOrUndefined(search) || search === '') {
      params += `&search=${search}`;
    }

    return this.restService
      .get(`AdminService/getUnsuccessfulOrderWrites${params}`)
      .pipe(map((response) => response.getData()));
  }

  public resendOrder(ecomId: string, transactionId: string): Observable<any> {
    const params = {
      transactionId: transactionId,
      ecomId: ecomId,
    };
    return this.restService
      .post(`AdminService/reSendOrderToSupplier`, params)
      .pipe(map((response) => response.getData()));
  }

  public isExportTaskRunning(taskId: number): Observable<boolean> {
    const params = { taskIds: [taskId] };

    return this.restService
      .post('ExportTaskService/isETaskFileRunning', params)
      .pipe(map((response) => !response.isNotEmpty()));
  }

  public getSubUntilLogs(ecomId: string): Observable<any[]> {
    return this.restService
      .get(`AdminService/getSubscribedUntilLogs?ecomId=${ecomId}`)
      .pipe(map((response) => response.getData()));
  }

  public updatePmsCatalogSeoUrl(taskId, newUrl): Observable<any> {
    const params = {
      id: taskId,
      seoUrl: newUrl,
    };
    return this.restService.post('AdminService/updatePmsCatalogSeoUrl', params);
  }

  public getAppStatus(ecomId): Observable<any> {
    return this.restService
      .get('EcomService/installStatus?ecomId=' + ecomId)
      .pipe(map((response) => response.getFirstData()));
  }

  public setAppUninstalled(ecomId: number, appType: number): Observable<any> {
    const params = {
      ecomId: ecomId,
      appType: appType,
    };
    return this.restService.delete('EcomService/uninstallEcomByAppType', params);
  }

  public changeEmail(oldEmail, newEmail): Observable<any> {
    const params = {
      oldEmail: oldEmail,
      newEmail: newEmail,
    };
    return this.restService.post('AdminService/changeEmails', params).pipe(map((response) => response.getData()));
  }

  public changeTaskRunType(taskId: number, runType: boolean): Observable<any> {
    const params = {
      taskId: taskId,
      runType: runType,
    };

    return this.restService.post('TaskService/updateTaskRunType', params).pipe(map((response) => response.getData()));
  }

  getTaskRunType(taskId: number): Observable<any> {
    return this.restService
      .get('TaskService/getRunType', { taskId: taskId })
      .pipe(map((response) => response.getFirstData()));
  }

  getCatalogById(id: number): Observable<any> {
    return this.restService
      .get(`AdminService/getCatalogsNew?search=${id}&idSearch=true`)
      .pipe(map((res) => res.getFirstData()));
  }

  getTaskById(id: number): Observable<any> {
    return this.restService
      .get(`AdminService/getTasksNew?search=${id}&taskIdSearch=true`)
      .pipe(map((res) => res.getFirstData()));
  }

  public searchPaidOrders(params: PaidOrdersVO): Observable<any> {
    return this.restService.get('AdminService/searchPaidOrders', params).pipe(map((res) => res.getFirstData()));
  }

  public getAdminToUserLoginNavigateTo(
    taskType: string,
    taskId?: number,
    rCatalogId?: number
  ): UserToUserNavigatePayload {
    if (!taskType && rCatalogId) {
      return {
        url: RetailerCenterNavigation.IMPORT_LIST + '/' + rCatalogId + '/products',
      };
    }
    switch (true) {
      case taskType.includes('_df'):
        return {
          url: DatafeedManagerNavigation.PRODUCT_UPLOAD_WIZARD_RETAILER_TASK,
          queryParams: { task_id: taskId, isUpdate: true },
        };
      case taskType.includes('public'):
        return {
          url: ProductListingNavigation.AUTOMATIC_PRODUCT_LISTING_TASK,
          queryParams: { task_id: taskId, isUpdate: true },
        };
      case taskType.includes('manual'):
        return {
          url: SupplierCenterNavigation.CATALOG.PIM,
        };
      case taskType.includes('_uv'):
        return {
          url: DatafeedManagerNavigation.VARIANTS_UPDATE_WIZARD,
          queryParams: { task_id: taskId, isUpdate: true },
        };
    }
  }
}

export interface PaidOrdersVO {
  ecomId?: number;
  supplierId?: number;
  orderId?: string;
  from: number;
  size: number;
  toDate: string;
  fromDate: string;
}
