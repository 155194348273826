<app-custom-dialog-wrapper
  *conditionLoading="
    this.categoryPreferencesFormDialogService.firstLevelCategoryIdsFromPreferences$
      | async as firstLevelCategoryIdsFromPreferences
  "
  headerPadding="0px"
  [headerLeftRef]="title"
  [actionBarRef]="actionBar"
>
  <app-category-preferences-form
    (submit)="handleSubmit($event)"
    [categories]="firstLevelCategoryIdsFromPreferences"
    [hideButton]="true"
  ></app-category-preferences-form>
</app-custom-dialog-wrapper>

<ng-template #title>
  <div class="typography-h1">
    {{ 'CATEGORY_PREFERENCES_FORM_DIALOG.TITLE' | translate }}
  </div>
</ng-template>

<ng-template #actionBar>
  <div fxLayoutAlign="flex-end" class="mt-8">
    <button mat-button class="syncee-blue-button" (click)="form.handleSubmit()">
      {{ 'CATEGORY_PREFERENCES_FORM_DIALOG.SAVE' | translate }}
    </button>
  </div>
</ng-template>
