<div class="card cursor-pointer">
  <ng-content select="[icon]"></ng-content>
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="8px">
      <div [ngClass]="ltMd ? 'typography-subtitle-small' : 'h4'">
        {{ title }}
      </div>
      <ng-content select="[afterTitle]"></ng-content>
    </div>
    <div [ngClass]="ltMd ? 'typography-body-2' : 'typography-body-1'">
      {{ description }}
    </div>
  </div>
</div>
