<app-dialog-multi-wrapper
  [title]="title"
  [hideCloseButton]="!showCancel"
  [hideConfirmButton]="false"
  [buttonsAlignment]="'center'"
  [confirmButtonText]="btnOkText"
  [closeButtonText]="btnCancelText"
  (confirmClick)="close()"
  [type]="dialogType"
>
  <div [innerHTML]="message" class="content-holder pb-8 centered-text"></div>
</app-dialog-multi-wrapper>
