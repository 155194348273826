import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class ProgressBarHandlerInterceptor implements HttpInterceptor {
  private onGoingCalls = 0;

  constructor(private progressBar: FuseProgressBarService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressBar.show();
    this.onGoingCalls++;
    return next.handle(req).pipe(
      finalize(() => {
        if (--this.onGoingCalls === 0) {
          this.progressBar.hide();
        }
      })
    );
  }
}
