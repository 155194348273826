import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../service/rest/rest.service';
import { RecentlyDroppedItemNotifier } from '../../drag-ndrop-zone/recently-dropped-item-notifier';
import { NotificationService } from '../../notification/notification.service';
import { TaskwizardUpdateService } from '../../../service/taskwizard/taskwizard-update.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { UserService } from '../../../service/user/user.service';
import { StepperService } from '../../../service/taskwizard/stepper.service';
import { ProductdetailsbuilderComponent } from '../../taskwizard/addfiles/mapping/productdetailsbuilder/productdetailsbuilder.component';
import { PermissionService } from '../../../service/permission/permission.service';

@Component({
  selector: 'app-retailer-jumpseller-vu-builder',
  templateUrl: './retailer-jumpseller-vu-builder.component.html',
  styleUrls: ['./retailer-jumpseller-vu-builder.component.scss'],
})
export class RetailerJumpsellerVuBuilderComponent extends ProductdetailsbuilderComponent implements OnInit {
  constructor(
    protected restService: RestService,
    protected droppedItemNotifier: RecentlyDroppedItemNotifier,
    protected notification: NotificationService,
    protected taskWizardUpdateService: TaskwizardUpdateService,
    protected dialog: MatDialog,
    protected taskWizardService: TaskwizardService,
    protected userService: UserService,
    protected stepperService: StepperService,
    protected permissionService: PermissionService
  ) {
    super(
      restService,
      droppedItemNotifier,
      notification,
      taskWizardUpdateService,
      dialog,
      taskWizardService,
      userService,
      stepperService,
      permissionService
    );
  }

  init(): void {
    this.isRunTask = false;
  }
}
