import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../rest/rest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public static GET_IP_ADDRESS_URL = 'AdminService/isExceptionMaintenance';

  constructor(private restService: RestService) {}

  isMaintenanceExceptionIps(): Observable<boolean> {
    return this.restService.get(SharedService.GET_IP_ADDRESS_URL).pipe(
      map((resp) => {
        return resp.getFirstData();
      })
    );
  }
}
