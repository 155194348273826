<div
  class="catalog-overlay-container"
  fxLayout="column"
  fxLayoutGap="8px"
  fxLayoutAlign="start stretch"
  fxLayoutAlign.lt-md="space-between stretch"
>
  <ng-container *ngIf="ecoms | async as ecomList; else noEcom">
    <ng-container *ngIf="ecomList.length !== 0; else noEcom">
      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="column" fxLayoutGap="8px" *ngIf="ecomList.length > 1">
          <div fxLayoutAlign="space-between center">
            <div>{{ 'TOOLBAR.MODAL.SELECT_STORE.TITLE' | translate }}</div>

            <mat-icon class="s-20 icon-filled" [matTooltip]="'TOOLBAR.MODAL.SELECT_STORE.TOOLTIP' | translate">
              info
            </mat-icon>
          </div>

          <app-ecom-selector-dropdown></app-ecom-selector-dropdown>

          <app-custom-divider margin="16px"></app-custom-divider>
        </div>

        <!-- <app-subscription-details (onUpgrade)="handleUpgrade()"></app-subscription-details> -->

        <app-catalog-overlay-list-with-selector class="list"></app-catalog-overlay-list-with-selector>
      </div>

      <div fxLayoutAlign="center center" fxLayoutGap="5px" class="action-bar">
        <button
          class="catalog-overlay-action-button"
          fxFlex="1 0 0"
          mat-flat-button
          color="primary"
          [routerLink]="navigateToRetailerImportList"
          (click)="closeOverlay.emit()"
        >
          {{ 'TOOLBAR.MODAL.ACTIONS.VIEW_ALL_LIST' | translate }}
        </button>

        <button
          fxFlex="1 0 0"
          mat-button
          class="syncee-light-blue-button catalog-overlay-action-button"
          (click)="createNewCatalog()"
        >
          <mat-icon>add</mat-icon>
          {{ 'TOOLBAR.MODAL.ACTIONS.CREATE' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noEcom>
  <div fxLayoutAlign="space-between center">
    <div>{{ 'TOOLBAR.MODAL.NO_ECOM.MESSAGE' | translate }}</div>
    <button mat-flat-button color="primary" (click)="onMyStoresClicked()">
      {{ 'TOOLBAR.MODAL.NO_ECOM.MY_STORES' | translate }}
    </button>
  </div>
</ng-template>
