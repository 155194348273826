<div *ngIf="inputs">
  <form #form="ngForm">
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="csvsettings-checkbox-panel" fxLayout="row" fxLayoutAlign="start center">
        <div>
          <mat-checkbox name="isFirstRowHeader" [(ngModel)]="isFirstRowHeader">{{
            'TASKS.EXCEL_SETTINGS.FIRST_ROW_IS_THE_HEADER' | translate
          }}</mat-checkbox>
        </div>
      </div>
      <mat-form-field appearance="fill" class="input-without-padding-except-error">
        <mat-label>{{ 'TASKS.EXCEL_SETTINGS.START_ROW_NUMBER' | translate }}</mat-label>
        <input matInput name="startRowNum" [(ngModel)]="startRowNum" required />
        <mat-error>
          {{ 'TASKS.EXCEL_SETTINGS.ERROR_START_ROW' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="input-without-padding-except-error">
        <mat-label>{{ 'TASKS.EXCEL_SETTINGS.SHEET' | translate }}</mat-label>
        <mat-select name="sheet" [(ngModel)]="selectedSheet" required>
          <mat-option *ngFor="let sheet of sheets" [value]="sheet">{{ sheet }} </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKS.EXCEL_SETTINGS.ERROR_SHEET' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="input-without-padding-except-error">
        <mat-label>{{ 'TASKS.EXCEL_SETTINGS.CHARACTER_ENCODING' | translate }}</mat-label>
        <mat-select #encoding="ngModel" id="encodingSelect" name="encodingSelect" [(ngModel)]="encodingSelect" required>
          <mat-option *ngFor="let encoding of characterEncodings" [value]="encoding.name">
            {{ encoding.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKS.EXCEL_SETTINGS.ERROR_CHARACTER_ENCODING' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
