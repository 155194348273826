import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Utils } from 'app/utils/utils';
@Component({
  selector: 'app-progress-bar-chart',
  templateUrl: './progress-bar-chart.component.html',
  styleUrls: ['./progress-bar-chart.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class ProgressBarChartComponent implements OnChanges, OnInit {
  @Input() upperLimit: number;
  @Input() isError = false;
  @Input() errorMessage = 'Error occurred during process';

  @Input() items: ProgressBarChartItem[] = [];
  @Input() legend: 'normal' | 'tooltip' | 'row' = 'normal';

  isValidPercentage = true;

  constructor() {}
  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.calculatePercentages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.items.firstChange) {
      this.init();
    }
  }

  private calculatePercentages(): void {
    const notNullItems = this.items.filter((item) => {
      return !Utils.isNullOrUndefined(item.value);
    });

    if (notNullItems.length !== this.items.length) {
      this.isError = true;
      this.errorMessage = 'No data yet';
      return;
    }

    this.items.map((item) => {
      const percentage = (Math.abs(item.value) / this.upperLimit) * 100;
      if (isNaN(percentage)) {
        item.percentage = 0;
      } else {
        item.percentage = percentage < 1 && percentage > 0 ? 1 : percentage;
      }
    });
  }

  public getTooltip(item: ProgressBarChartItem): string {
    return `${item.label}: ${item.value}`;
  }
}

export interface ProgressBarChartItem {
  label: string;
  value: number;
  background: string;
  percentage?: number;
}
