import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from 'app/app.module';
import {environment} from 'environments/environment';
import 'hammerjs';
import * as Sentry from "@sentry/angular";

Sentry.init({
    dsn: "https://2bd54bdf6e79c73d6b413f857a21aff3@o4507724573638656.ingest.us.sentry.io/4507781495521280",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/backend\.syncee\.co\/rest/, /^https:\/\/syncee-gateway\.syncee\.co/],
    tracePropagationTargets: [],
    // Session Replay
    // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.0,
    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 0.1,

    enabled: environment.production,
    ignoreErrors: ['404 Not Found'],
});

if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = function (): void {
        };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
