<div class="single-alert-with-icon" [ngClass]="type">
  <div class="icon-container background">
    <mat-icon class="color-dark fill-dark" [ngClass]="ltMd ? 's-26' : 's-40'" [svgIcon]="svgIcon">
      {{ iconToShow }}
    </mat-icon>
  </div>
  <div class="content">
    <div class="title-container" *ngIf="title$ | async as titleToShow">
      <div class="h3 title">
        {{ titleToShow }}
        <span>
          <mat-icon
            *ngIf="!!titleTooltipKey"
            class="s-20 icon-filled"
            [matTooltip]="titleTooltipKey | translate"
            matTooltipPosition="right"
          >
            info
          </mat-icon>
        </span>
      </div>
    </div>
    <div class="description-container" *ngIf="description$ | async as descriptionToShow">
      <div intercomOpen class="description" [innerHTML]="descriptionToShow | sanitizeHTML"></div>
      <mat-icon
        *ngIf="!!descriptionTooltipKey"
        class="s-20 icon-filled"
        [matTooltip]="descriptionTooltipKey | translate"
        matTooltipPosition="right"
      >
        info
      </mat-icon>
    </div>
  </div>
  <ng-container *ngIf="!!actionBarRef" [ngTemplateOutlet]="actionBarRef"></ng-container>
  <ng-container *ngIf="!actionBarRef && !!button">
    <div class="button-container">
      <app-general-button [config]="button"></app-general-button>
    </div>
  </ng-container>
</div>
