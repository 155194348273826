<mat-card
  class="w-100p p-40 orders-chart-container"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign="center center"
  fxLayoutAlign.lt-lg="center start"
  fxLayoutGap="40px"
>
  <div class="w-100p" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="40px" fxFlex="1 1 auto">
    <div class="w-100p row-gap-sm" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="space-between center">
      <div class="h4">{{ 'DASHBOARD.SALES_OVERVIEW' | translate }}</div>
      <mat-icon class="icon-filled s-20" [matTooltip]="'DASHBOARD.SALES_OVERVIEW_TOOLTIP' | translate">info</mat-icon>
    </div>
    <div class="orders-canvas">
      <canvas
        baseChart
        class="w-100p"
        [data]="anyOrdersReceived ? barChartData : defaultBarChartData"
        [options]="barChartOptions"
        [type]="'bar'"
      >
      </canvas>
    </div>
  </div>
  <div class="chart-filter" fxFlex="1 0 150px">
    <mat-radio-group
      (change)="handleStatisticTypeChange($event)"
      [value]="selectedStatisticType"
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutGap="20px"
    >
      <mat-radio-button
        *ngFor="let statisticType of statisticTypes"
        [value]="statisticType"
        [checked]="statisticType === selectedStatisticType"
        >{{ 'DASHBOARD.CHART_STATISTIC_TYPES.' + statisticType | translate }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <app-blurred-overlay *ngIf="!anyOrdersReceived" [blurValue]="'0'">
    <app-single-alert-with-icon icon="trending_up" titleKey="DASHBOARD_NEW.NO_ORDERS_YET.TITLE">
    </app-single-alert-with-icon>
  </app-blurred-overlay>
</mat-card>
