import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingInformationComponent } from './billing-information.component';
import { BillingInformationDefaultComponent } from './variant/billing-information-default/billing-information-default.component';
import { BillingInformationWideScreenComponent } from './variant/billing-information-wide-screen/billing-information-wide-screen.component';
import { ConditionLoadingDirective } from '../../shared/directives/condition-loading.directive';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SingleCountrySelectorFormFieldComponent } from '../../shared/components/single-country-selector-form-field/single-country-selector-form-field.component';
import { IsCpfCountryPipe } from './pipes/is-cpf-country.pipe';

@NgModule({
  declarations: [
    BillingInformationComponent,
    BillingInformationDefaultComponent,
    BillingInformationWideScreenComponent,
    IsCpfCountryPipe,
  ],
  imports: [
    CommonModule,
    ConditionLoadingDirective,
    FlexModule,
    ReactiveFormsModule,
    MatInputModule,
    TranslateModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    SingleCountrySelectorFormFieldComponent,
  ],
  exports: [BillingInformationComponent],
})
export class BillingInformationModule {}
