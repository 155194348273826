import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { SupplierReplyDialogComponent } from '../my-suppliers-dialogs/supplier-reply-dialog/supplier-reply-dialog.component';
import {
  MySuppliersOrderInfoDialogComponent,
  MySuppliersOrderInfoDialogData,
} from '../my-suppliers-dialogs/my-suppliers-order-info-dialog/my-suppliers-order-info-dialog.component';
import { ChatService } from '../../../../service/chat/chat.service';
import { AuthorizationEcomService } from '../../../../service/authorization-ecom/authorization-ecom.service';
import { SuppliersService } from '../../../../service/suppliers/suppliers.service';
import {
  MySuppliersMobileCatalogListComponent,
  MySuppliersMobileCatalogListData,
} from '../my-suppliers-mobile/my-suppliers-mobile-catalog-list/my-suppliers-mobile-catalog-list.component';
import {
  MySuppliersMobileCatalogData,
  MySuppliersMobileCatalogDetailsComponent,
} from '../my-suppliers-mobile/my-suppliers-mobile-catalog-details/my-suppliers-mobile-catalog-details.component';
import { LeaveUserReviewService } from '../../../review/service/leave-user-review.service';
import { Review } from '../../../../vo/review/review';
import { ChatUser } from '../../../../service/chat/model/chat-models';
import {
  MySuppliersCatalogInformationDialogComponent,
  MySuppliersCatalogInformationDialogData,
} from '../../../../shared/components/dialogs/my-suppliers-catalog-information-dialog/my-suppliers-catalog-information-dialog.component';

@Injectable()
export class MySuppliersDialogService {
  private dialogFocusConfig: Pick<MatDialogConfig, 'autoFocus' | 'restoreFocus'> = {
    autoFocus: false,
    restoreFocus: false,
  };
  constructor(
    private dialog: MatDialog,
    private authorizationService: AuthorizationEcomService,
    private supplierService: SuppliersService,
    private chatService: ChatService,
    private userReviewDialogService: LeaveUserReviewService
  ) {}

  public addReview(supplierUserId: string): Observable<boolean> {
    return this.userReviewDialogService.open(Number(supplierUserId));
  }

  public updateReview(supplierUserId: number, review: Review): Observable<boolean> {
    return this.userReviewDialogService.open(supplierUserId, review);
  }

  public showReplyFromSupplier(reply: string): void {
    this.dialog.open<SupplierReplyDialogComponent, string>(SupplierReplyDialogComponent, {
      data: reply,
      ...this.dialogFocusConfig,
    });
  }

  public showOrderInfoDialog(data: MySuppliersOrderInfoDialogData): void {
    this.dialog.open<MySuppliersOrderInfoDialogComponent, MySuppliersOrderInfoDialogData>(
      MySuppliersOrderInfoDialogComponent,
      {
        data: data,
        ...this.dialogFocusConfig,
      }
    );
  }

  public contactSupplierDialogOpen(supplierId: string, hasAccessToChat: boolean): void {
    if (!hasAccessToChat) {
      this.authorizationService.openSubscribeDialog('PLAN_UPSELL.SPECIAL_DESCRIPTION.MESSENGER');
      return;
    }
    forkJoin([this.chatService.getCurrUser(), this.supplierService.getSupplierChatDetailsByUser(supplierId)]).subscribe(
      ([user, details]) => {
        const recipientUser: ChatUser = {
          id: parseInt(details.userId, 10),
          name: details.companyName,
          email: details.userEmail,
          avatar: details.logo,
        };
        this.chatService.openChatDialog(recipientUser, user).subscribe();
      }
    );
  }

  public openCatalogInformationDialog(data: MySuppliersCatalogInformationDialogData): void {
    this.dialog.open<MySuppliersCatalogInformationDialogComponent, MySuppliersCatalogInformationDialogData>(
      MySuppliersCatalogInformationDialogComponent,
      { data, panelClass: 'custom-modal-full-width-lt-md', ...this.dialogFocusConfig }
    );
  }

  public openMobileCatalogDetailsDialog(data: MySuppliersMobileCatalogListData): void {
    this.dialog.open<MySuppliersMobileCatalogListComponent, MySuppliersMobileCatalogListData>(
      MySuppliersMobileCatalogListComponent,
      {
        panelClass: 'custom-modal-full-width-lt-md',
        data: data,
        ...this.dialogFocusConfig,
      }
    );
  }

  public openMobileCatalogDetails(data: MySuppliersMobileCatalogData): void {
    this.dialog.open<MySuppliersMobileCatalogDetailsComponent, MySuppliersMobileCatalogData>(
      MySuppliersMobileCatalogDetailsComponent,
      {
        panelClass: 'custom-modal-full-width-lt-md',
        data: data,
        ...this.dialogFocusConfig,
      }
    );
  }
}
