<div fxLayout="column" fxLayoutAlign="start">
  <div>
    <h2>{{ 'TASKWIZARD.ADD_FILES.CURRENT_FILE_SOURCE' | translate }}</h2>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <mat-form-field class="hover-click" (click)="openFTPUpload()" fxFlex="1 0 auto">
      <input class="hover-click" matInput value="{{ remotePath }}" />
    </mat-form-field>
    <mat-form-field>
      <mat-select
        [disabled]="disableFileTypeSelector"
        placeholder="File type"
        [(ngModel)]="selectedFileExtension"
        name="type"
        (valueChange)="selectFileTypeChanged($event)"
      >
        <mat-option *ngFor="let fileExt of fileExtensions" [value]="fileExt.type">
          {{ fileExt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
