import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/utils/utils';

@Pipe({
  name: 'isNullOrUndefinedOrEmpty',
  standalone: true,
  pure: true,
})
export class IsNullOrUndefinedOrEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    return Utils.isNullOrUndefinedOrLengthZero(value);
  }
}
