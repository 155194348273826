<div fxLayout="column" fxLayoutAlign="start start" [fxLayoutGap]="gap">
  <div class="typography-body-1 no-wrap" *ngFor="let item of group.items" [ngSwitch]="item.type">
    <a *ngSwitchCase="'EXTERNAL_NAV'" target="_blank" [href]="item.value">
      {{ 'FOOTER.ITEMS.' + item.key | translate }}
    </a>
    <a
      *ngSwitchCase="'EXTERNAL_NAV_WITH_LANGUAGE'"
      [href]="
        item.value.split('.com/')[0] +
        '.com/' +
        currentLanguageCode +
        '/' +
        (item.value.split('.com/')[1] ? item.value.split('.com/')[1] : '')
      "
    >
      {{ 'FOOTER.ITEMS.' + item.key | translate }}
    </a>
    <a
      *ngSwitchCase="'WORDPRESS_NAV_WITH_LANGUAGE'"
      [href]="item.wpDomain + '/' + item.wpPageType + '/' + currentLanguageCode + item.value"
    >
      {{ 'FOOTER.ITEMS.' + item.key | translate }}
    </a>
    <a *ngSwitchCase="'EMAIL'" [href]="'mailto:' + item.value" [innerHTML]="'FOOTER.ITEMS.' + item.key | translate"></a>
    <span *ngSwitchCase="'NON_CLICK'">{{ 'FOOTER.ITEMS.' + item.key | translate }}</span>
    <span *ngSwitchCase="'INNER_NAV'" [routerLink]="item.value" class="cursor-pointer">
      {{ 'FOOTER.ITEMS.' + item.key | translate }}
    </span>
    <span *ngSwitchCase="'DIALOG'" (click)="openDialog(item)" class="cursor-pointer">
      {{ 'FOOTER.ITEMS.' + item.key | translate }}
    </span>
  </div>
</div>
