import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CustomSpinnerComponent, SpinnerColor } from '../components/custom-spinner/custom-spinner.component';
/** Use the spinnerColor property to change the spinner's color. You can choose from pre-defined values.  */
@Directive({
  selector: '[buttonLoadingState]',
  standalone: true,
})
export class ButtonLoadingStateDirective implements OnInit, OnChanges {
  @Input() buttonLoadingState: boolean;
  @Input() spinnerColor: SpinnerColor = 'default';

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLButtonElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttonLoadingState !== undefined && changes.buttonLoadingState !== null) {
      if (changes.buttonLoadingState?.currentValue) {
        this.insertSpinner();
      } else {
        this.deleteSpinner();
      }
      this.el.nativeElement.disabled = changes.buttonLoadingState?.currentValue;
    }
  }

  private insertSpinner(): void {
    const parent = this.el.nativeElement.getElementsByClassName('mat-button-wrapper')[0];
    const spinnerRef = this.viewContainer.createComponent(CustomSpinnerComponent);
    spinnerRef.setInput('spinnerColor', this.spinnerColor);
    parent.classList.add('button-with-loading-state');
    spinnerRef.location.nativeElement.classList.add('button-loading-spinner');
    parent.insertBefore(spinnerRef.location.nativeElement, parent.firstChild);
  }

  private deleteSpinner(): void {
    const parent = this.el.nativeElement.getElementsByClassName('mat-button-wrapper')[0];

    if (!parent) {
      return;
    }

    parent.classList.remove('button-with-loading-state');

    const spinner = parent.getElementsByClassName('button-loading-spinner')[0];

    if (!spinner) {
      return;
    }

    parent.removeChild(spinner);
  }
}
