import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SnippetState } from './snippet.reducer';
import { flatMap } from 'lodash';
import { ROLE_TYPE, RolesEnum } from '../../vo/roles/roles';

const getSnippetState = createFeatureSelector<SnippetState>('snippet');

export const snippetSelector = createSelector(getSnippetState, (state) => {
  if (!!state && !!state.completedSteps && !!state.items) {
    return state;
  }
});

export const snippetTreeSelector = createSelector(getSnippetState, (state) => !!state && state.items);

export const snippetCompletedStepsSelector = createSelector(
  getSnippetState,
  (state) => !!state && state.completedSteps
);

export const snippetByRoleSelector = (role: ROLE_TYPE) =>
  createSelector(getSnippetState, (state) =>
    state?.items?.filter((snippet) => snippet.type.includes(role === RolesEnum.SUPPLIER ? 'supplier' : 'retailer'))
  );

export const snippetIsAllCompletedSelector = createSelector(getSnippetState, (state: SnippetState, role: RolesEnum) => {
  if (!state || !state.completedSteps || !state.items) {
    return;
  }
  if (!!state && !!state.completedSteps && !!state.items) {
    const filteredItems = state.items.filter((item) =>
      item.type.includes(role === RolesEnum.SUPPLIER ? 'supplier' : 'retailer')
    );
    const steps = flatMap(filteredItems, (item) => item.steps);
    return !steps.some((step) => !state.completedSteps.some((completedStep) => completedStep.snippetsId === step.id));
  }
  return false;
});

export const snippetOnboardingStatusSelector = createSelector(getSnippetState, (state) => !!state && state.show);
