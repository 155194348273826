import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { ConstantChip, FieldOperation, FieldSetting, VariableChip } from './chip';
import { ProductField } from '../../service/taskwizard/taskwizard.service';
import { RecentlyDroppedItemNotifier } from '../drag-ndrop-zone/recently-dropped-item-notifier';
import { Subscription } from 'rxjs';
import { DroppableInputComponent, SettingsDialogConfig } from './droppable-input.component';
import { DpMultilineInputItemComponent } from './dp-multiline-input-item.component';
import { FieldSettingsComponent, OperationsWithSettings } from '../field-settings/field-settings.component';
import { TaskwizardUpdateService } from '../../service/taskwizard/taskwizard-update.service';
import { Utils } from 'app/utils/utils';

class ChipRow {
  chips: (ConstantChip | VariableChip)[] = [];
  operations: FieldOperation[] = [];
  settings: FieldSetting[] = [];
}

@Component({
  selector: 'app-droppable-multiline-input',
  templateUrl: './droppable-multiline-input.component.html',
  styleUrls: ['./droppable-multiline-input.component.scss'],
})
export class DroppableMultilineInputComponent implements OnInit, AfterViewInit {
  @ViewChildren(DroppableInputComponent) inputs: QueryList<DroppableInputComponent>;

  rawChips: (ConstantChip | VariableChip)[] = [];

  @Input() set settings(value: any[]) {
    this.inputOperationsWithChips.settings = value;
  }

  @Input() set operations(value: any[]) {
    this.inputOperationsWithChips.operations = value;
  }

  @Input() set chips(value: (ConstantChip | VariableChip)[]) {
    this.rawChips = value;
  }

  @Output() settingsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() operationsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() chipsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() readonly id: string;
  @Input() readonly title: string;
  @Input() placeholder: string;

  public inputOperationsWithChips: OperationsWithSettings = { operations: [], settings: [] };
  private lastSub: Subscription;

  chipRows: ChipRow[] = [];

  fileId = 1;

  hasSettings = false;
  isSettings = false;

  productFields: ProductField[] = [
    <ProductField>{ name: 'test', value: 'test' },
    <ProductField>{
      name: 'test 2',
      value: 'test 2',
    },
  ];

  constructor(private settingsDialog: MatDialog, private taskWizardUpdate: TaskwizardUpdateService) {}

  ngOnInit(): void {
    this.initStructure(this.rawChips);
    this.checkSettingsExist();
  }

  ngAfterViewInit(): void {
    this.inputs.changes.subscribe((_) => {
      const convertedChips = this.taskWizardUpdate.mapChips(this.getStructure()[this.id][0].chips);
      this.chipsChange.emit(convertedChips);
    });
  }

  private checkSettingsExist(): void {
    if (
      (this.inputOperationsWithChips.settings.length > 0 && this.isSettings) ||
      this.inputOperationsWithChips.operations.length
    ) {
      this.hasSettings = true;
    }
  }

  createNewEmptyChipItem(): ChipRow {
    const chipRow = new ChipRow();
    chipRow.chips = [];
    chipRow.operations = [];
    chipRow.settings = [];
    return chipRow;
  }

  public initStructure(chips: any[]): any {
    if (Utils.isNullOrUndefined(chips)) {
      return [];
    }
    let chipRow = new ChipRow();
    chips.forEach((chip) => {
      if (chip instanceof ConstantChip && chip.value === '{newLine}') {
        this.chipRows.push(chipRow);
        chipRow = new ChipRow();
      } else {
        chipRow.chips.push(chip);
      }
    });
    this.chipRows.push(chipRow);
  }

  public getStructure(): any {
    const retStructure = { [this.id]: [{ chips: [], ...this.inputOperationsWithChips }] };
    this.inputs.forEach((dpMultiLineItem, index) => {
      if (index > 0 && index < this.inputs.length) {
        retStructure[this.id][0].chips.push('{newLine}');
      }
      retStructure[this.id][0].chips.push(...dpMultiLineItem.getMultilineStructure());
    });

    return retStructure;
  }

  public onInputSettingsClick(): void {
    this.cancelLastDialogSubscription();
    this.lastSub = this.openSettingsWithData(this.inputOperationsWithChips)
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.inputOperationsWithChips.operations = value.operations;
          this.inputOperationsWithChips.settings = value.settings;
          this.settingsChange.emit(this.inputOperationsWithChips.settings);
          this.operationsChange.emit(this.inputOperationsWithChips.operations);
        }
        this.hasSettings = this.anySettingsPresent(
          this.inputOperationsWithChips.operations,
          this.inputOperationsWithChips.settings
        );
      });
  }

  private anySettingsPresent(operations: FieldOperation[], settings: FieldSetting[]): boolean {
    return settings.length > 0 || operations.length > 0;
  }

  private openSettingsWithData(
    data: OperationsWithSettings
  ): MatDialogRef<FieldSettingsComponent, OperationsWithSettings> {
    return this.settingsDialog.open(FieldSettingsComponent, new SettingsDialogConfig(data, this.id));
  }

  private cancelLastDialogSubscription(): void {
    if (this.lastSub) {
      this.lastSub.unsubscribe();
    }
  }

  newRow(listItemIndex: number): void {
    this.chipRows.splice(listItemIndex + 1, 0, this.createNewEmptyChipItem());
  }

  removeRow(listItemIndex: number): void {
    if (this.chipRows.length > 1) {
      this.chipRows.splice(listItemIndex, 1);
    }
  }

  changedMultiLineInput(): void {
    this.chipsChange.emit(this.taskWizardUpdate.mapChips(this.getStructure()[this.id][0].chips));
  }
}
