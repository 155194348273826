const prefix = 'retailer/';
const alibaba = 'alibaba';

export type AlibabaNavigation = (typeof AlibabaNavigation)[keyof typeof AlibabaNavigation];
export const AlibabaNavigation = {
  DEFAULT: `${prefix}${alibaba}`,
  ALIBABA_CONNECTION: `${prefix}${alibaba}/connection`,
  ALIBABA_PRODUCTS: `${prefix}${alibaba}/products`,
  ALIBABA_IMPORT_LIST: `${prefix}${alibaba}/import-list`,
  ALIBABA_MY_CATALOGS: `${prefix}${alibaba}/my-catalogs`,
  ALIBABA_COMING_SOON: `${prefix}${alibaba}/alibaba-coming-soon`,
  ALIBABA_REFRESH_TOKEN: `${prefix}${alibaba}/refresh-token`,
} as const;
