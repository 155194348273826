<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
  <ng-container *ngFor="let step of steps; let isLast = last">
    <div
      [fxFlex]="isLast ? '0 0 auto' : '1 0 0'"
      [style.padding-inline]="'12px'"
      class="cursor-pointer"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      (click)="handleStepClicked(step)"
      [class.color-default]="!step.isDone && step.state === 'default'"
    >
      <mat-icon
        [style.color]="
          step.isDone && step.state !== 'current' ? iconConfig.complete.color : iconConfig[step.state].color
        "
        [class.icon-filled]="step.isDone && step.state !== 'current' ? true : iconConfig[step.state].filled"
        >{{
          step.isDone && step.state !== 'current' ? iconConfig.complete.icon : iconConfig[step.state].icon
        }}</mat-icon
      >
      <div class="typography-body-2 no-wrap">{{ step.label | translate }}</div>
    </div>
    <app-custom-divider fxFlex="1 0 44px" *ngIf="!isLast"></app-custom-divider>
  </ng-container>
</div>
