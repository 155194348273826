import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskIsActiveColor',
})
export class TaskIsActiveColorPipe implements PipeTransform {
  transform(value: string): string {
    return value === '1' ? 'var(--app-syncee-primary-700)' : 'var(--app-red-400)';
  }
}
