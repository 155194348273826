import { ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Currencies, CurrencyExchange } from '../../service/currency-service/currency.service';
import { CurrencyActionsUnion, CurrencyActionTypes } from './currency.action';
import { Action, ActionReducer } from '@ngrx/store';
import { AppState, defaultAppState } from 'app/app.state';
import { stat } from 'fs';

export interface CurrencyState {
  retailerCurrencies: Currencies;
  selectedCurrencies: CurrencyExchange[];
  selectedCurrency: string;
}

const initialState: CurrencyState = {
  retailerCurrencies: null,
  selectedCurrency: null,
  selectedCurrencies: null,
};

export function currencyReducer(state = initialState, action: CurrencyActionsUnion): CurrencyState {
  switch (action.type) {
    case CurrencyActionTypes.SET_CURRENCIES:
      return {
        ...state,
        retailerCurrencies: action.payload,
      };
    case CurrencyActionTypes.SELECTED_ECOM_SET_SELECTED_CURRENCY_SUCCESS:
    case CurrencyActionTypes.NO_ECOM_SET_SELECTED_CURRENCY_SUCCESS:
      return {
        ...state,
        selectedCurrency: action.payload.selectedCurrency,
      };
    case CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_SUCCESS:
    case CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_WITHOUT_CHAIN_SUCCESS:
      return {
        ...state,
        selectedCurrency: action.payload.selectedCurrency,
        selectedCurrencies: action.payload.currencies,
      };
    default:
      return state;
  }
}

export function currencyMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  const preferredCurrency = localStorage.getItem('preferredCurrency') || 'USD';

  return function (state: AppState, action: Action): AppState {
    switch (action.type) {
      case ROOT_EFFECTS_INIT: {
        return reducer(
          {
            ...defaultAppState,
            ...{
              currency: {
                selectedCurrencies: state.currency.selectedCurrencies,
                retailerCurrencies: state.currency.retailerCurrencies,
                selectedCurrency: preferredCurrency,
              },
            },
          },
          action
        );
      }
      default: {
        return reducer(state, action);
      }
    }
  };
}
