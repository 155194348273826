import { ChannelLatestMessSort } from './ChannelLatestMessSort';
import { ChannelUnreadMessSort } from './ChannelUnreadMessSort';
import { IChannelSort } from './IChannelSort';

export class SortFactory {
  public static UNREAD_MESSAGES_KEY = 'unread_messages';
  public static LATEST_MESSAGES_KEY = 'latest_messages';

  public static getInstance(sort): IChannelSort {
    switch (sort) {
      case 'unread_messages':
        return new ChannelUnreadMessSort();
      case 'latest_messages':
        return new ChannelLatestMessSort();
    }
  }
}
