import { BehaviorSubject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { Utils } from 'app/utils/utils';
import { ChatUser } from '../chat/model/chat-models';
export const MINIMIZED_CHATS_LOCAL_STORAGE_KEY = 'minimized_chats';

@Injectable({ providedIn: 'root' })
export class ChatMinimizationService implements OnInit {
  chats: MinimizedChatData[] = [];
  minimizedChatSubject: BehaviorSubject<MinimizedChatData[]>;
  constructor() {
    this.minimizedChatSubject = new BehaviorSubject(this.chats);
    this.initChatsFromLocalStorage();
  }

  ngOnInit(): void {}

  private initChatsFromLocalStorage(): void {
    if (!Utils.isNullOrUndefined(localStorage[MINIMIZED_CHATS_LOCAL_STORAGE_KEY])) {
      this.chats = this.getMinimizedChats();
      this.minimizedChatSubject.next(this.chats);
    }
  }

  private getMinimizedChats(): MinimizedChatData[] {
    return JSON.parse(localStorage.getItem(MINIMIZED_CHATS_LOCAL_STORAGE_KEY));
  }

  public addMinimizedChat(chat: MinimizedChatData): void {
    if (this.chats.some((minimizedChat) => minimizedChat.recipientUser.id === chat.recipientUser.id)) {
      return;
    }
    if (this.chats.length < 3) {
      this.chats.unshift(chat);
    } else {
      this.chats.splice(0, 1, chat);
    }
    this.updateChatSubjectAndStorage();
  }

  public removeMinimizedChat(recipientId: number): void {
    this.chats = this.chats.filter((chat) => chat.recipientUser.id !== recipientId);
    this.updateChatSubjectAndStorage();
  }

  public clearAllMinimizedChats(): void {
    this.chats = [];
    this.updateChatSubjectAndStorage();
  }
  private updateChatSubjectAndStorage(): void {
    localStorage.setItem(MINIMIZED_CHATS_LOCAL_STORAGE_KEY, JSON.stringify(this.chats));
    this.minimizedChatSubject.next(this.chats);
  }
}

export interface MinimizedChatData {
  currentUser: ChatUser;
  recipientUser: ChatUser;
  lastMessageTime: string;
}
