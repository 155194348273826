import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNil, omitBy } from 'lodash';
import { iif, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { getCurrentUserIdSelector } from '../../store/user/user.selector';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { RestService } from '../rest/rest.service';
import { Utils } from '../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SupplierDetailsService {
  constructor(
    private restService: RestService,
    private springRestService: SpringRestService,
    private store: Store<AppState>
  ) {}

  private post(supplierDetails: Partial<SupplierDetails> & { userId: number }): Observable<void> {
    return this.springRestService
      .post(MicroserviceNames.USER, '/Supplier', supplierDetails, undefined, true)
      .pipe(map(() => {}));
  }

  private patch(supplierDetails: SupplierPatchDto, userId: number): Observable<void> {
    return this.springRestService
      .patch(MicroserviceNames.USER, `/Supplier/${userId}`, supplierDetails, undefined, true)
      .pipe(map(() => {}));
  }

  private mapSupplierDetailsToSupplierPatchDto(supplierDetails: Partial<SupplierDetails>): SupplierPatchDto {
    return omitBy(
      {
        ...supplierDetails,
        ...(!Utils.isNullOrUndefined(supplierDetails.ownInventory) && {
          ownInventory: supplierDetails.ownInventory === '1',
        }),
      },
      isNil
    );
  }

  save(supplierDetails: Partial<SupplierDetails> & { userId: number }, exists?: boolean): Observable<void> {
    if (exists !== null && exists !== undefined) {
      return exists
        ? this.patch(this.mapSupplierDetailsToSupplierPatchDto(supplierDetails), supplierDetails.userId)
        : this.post(supplierDetails);
    } else {
      return this.get(supplierDetails.userId).pipe(
        map((details) => !!details && !!details.companyName),
        switchMap((hasData) =>
          iif(
            () => hasData,
            this.patch(this.mapSupplierDetailsToSupplierPatchDto(supplierDetails), supplierDetails.userId),
            this.post(supplierDetails)
          )
        )
      );
    }
  }

  saveCurrentUserSupplier(supplierDetails: Partial<SupplierDetails>, exists?: boolean): Observable<void> {
    return this.store.select(getCurrentUserIdSelector).pipe(
      take(1),
      switchMap((userId) => this.save({ ...supplierDetails, userId }, exists))
    );
  }

  get(userId?: number): Observable<SupplierDetails> {
    return this.springRestService
      .get(MicroserviceNames.USER, `/Supplier`, { userId: userId }, true, undefined, true)
      .pipe(map((response) => response[0]));
  }
}

export interface SupplierDetails {
  userId: number;
  contactEmail: string;
  companyName: string;
  website: string;
  phoneCode: string;
  phoneNumber: string;
  discount: number;
  mainWarehouseLocation: string;
  description: string;
  worldwideShipping: boolean;
  ownInventory: '1' | '0';
  yearOfFoundation: number;
  publicPhoneCode: string;
  publicPhoneNumber: string;
}

export interface SupplierPatchDto {
  contactEmail?: string;
  website?: string;
  description?: string;
  mainWarehouseLocation?: string;
  companyName?: string;
  yearOfFoundation?: number;
  ownInventory?: boolean;
  phoneCode?: string;
  phoneNumber?: string;
  publicPhoneCode?: string;
  publicPhoneNumber?: string;
  discount?: number;
}
