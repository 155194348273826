<mat-card
  class="rate-table-mobile-card"
  [class.content-disabled]="hasAppliedShippingZone ? !isAppliedShippingZone : false"
>
  <ng-container *ngFor="let rate of rates; let last = last">
    <div class="rate-table-mobile-card-item" [class.multiple-rate-wrapper]="rate.hasMultipleRate">
      <div fxLayoutAlign="start center" fxLayout="row" class="rate-table-mobile-location">
        <div><mat-icon> location_on</mat-icon></div>
        <span class="h5">{{ rate.country }}</span>
      </div>
      <ng-container *ngIf="!rate.hasMultipleRate">
        <ng-container
          [ngTemplateOutlet]="rateTemplate"
          [ngTemplateOutletContext]="{ rateDetails: rate }"
        ></ng-container>
      </ng-container>
      <ng-container *ngIf="rate.hasMultipleRate">
        <ng-container *ngFor="let shippingRate of rate.shippingRate; let lastShippingRate = last">
          <ng-container
            [ngTemplateOutlet]="rateTemplate"
            [ngTemplateOutletContext]="{ rateDetails: shippingRate }"
            *ngIf="!!shippingRate"
          ></ng-container>
          <mat-divider *ngIf="!lastShippingRate" class="rate-table-mobile-item-divider"></mat-divider>
        </ng-container>
      </ng-container>
    </div>
    <mat-divider *ngIf="!last" class="rate-table-mobile-item-divider"></mat-divider>
  </ng-container>
</mat-card>

<ng-template #rateTemplate let-rateDetails="rateDetails">
  <div class="rate-table-mobile">
    <div *ngIf="rateDetails.shippingCarrier">
      <div class="rate-table-mobile-title">{{ 'SHIPPING_DETAILS.CARRIER' | translate }}</div>
      <div class="rate-table-mobile-content">
        <ng-container
          *ngIf="!!rateDetails"
          [ngTemplateOutlet]="shippingCarrierTemplate"
          [ngTemplateOutletContext]="{ shippingCarrier: rateDetails.shippingCarrier }"
        ></ng-container>
      </div>
    </div>

    <div>
      <div class="rate-table-mobile-title">{{ 'SHIPPING_DETAILS.CONDITION' | translate }}</div>
      <div class="rate-table-mobile-content">
        <ng-container
          *ngIf="!!rateDetails"
          [ngTemplateOutlet]="conditionTemplate"
          [ngTemplateOutletContext]="{ condition: rateDetails.condition }"
        ></ng-container>
      </div>
    </div>

    <div>
      <div class="rate-table-mobile-title">{{ 'SHIPPING_DETAILS.INCREMENTAL_FEE' | translate }}</div>
      <div class="rate-table-mobile-content">
        <ng-container
          *ngIf="!!rateDetails"
          [ngTemplateOutlet]="additionalCostTemplate"
          [ngTemplateOutletContext]="{ additionalCost: rateDetails.additionalCost }"
        ></ng-container>
      </div>
    </div>

    <div>
      <div class="rate-table-mobile-title">{{ 'SHIPPING_DETAILS.SHIPPING_TIME' | translate }}</div>
      <div class="rate-table-mobile-content">
        <ng-container
          *ngIf="!!rateDetails"
          [ngTemplateOutlet]="shippingTimeTemplate"
          [ngTemplateOutletContext]="{
            minShippingDays: rateDetails.minShippingDays,
            maxShippingDays: rateDetails.maxShippingDays
          }"
        ></ng-container>
      </div>
    </div>

    <div>
      <div class="rate-table-mobile-title">{{ 'SHIPPING_DETAILS.SHIPPING_COST' | translate }}</div>
      <div class="rate-table-mobile-content">
        <ng-container
          *ngIf="!!rateDetails"
          [ngTemplateOutlet]="shippingCostTemplate"
          [ngTemplateOutletContext]="{ shippingCost: rateDetails.shippingCost }"
        ></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #shippingCarrierTemplate let-shippingCarrier="shippingCarrier">
  {{ shippingCarrier.name }}
  {{ shippingCarrier.type ? ' - ' + ('SHIPPING_DETAILS.SHIPPING_TYPE.' + shippingCarrier.type | translate) : null }}
</ng-template>

<ng-template #conditionTemplate let-condition="condition">
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="!condition || (!condition.minValue && !condition.maxValue)">
      {{ 0 | currencyTransform : currency | async }} {{ 'SHIPPING_DETAILS.AND_UP' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="condition && condition.type === shippingRateType.PRICE">
      {{
        condition.minValue
          | currencyExchangeTo : supplierCurrency : currency
          | async
          | currencyTransform : currency
          | async
      }}
      {{
        condition.maxValue
          ? ' - ' +
            (condition.maxValue
              | currencyExchangeTo : supplierCurrency : currency
              | async
              | currencyTransform : currency
              | async)
          : ('SHIPPING_DETAILS.AND_UP' | translate)
      }}
    </ng-container>

    <ng-container *ngSwitchCase="condition && condition.type === shippingRateType.WEIGHT">
      {{ condition.minValue ? condition.minValue / 1000 : 0 }} kg
      {{ condition.maxValue ? ' - ' + (condition.maxValue / 1000 + ' kg') : ('SHIPPING_DETAILS.AND_UP' | translate) }}
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{ condition?.minValue + ' - ' + condition?.maxValue }}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #additionalCostTemplate let-additionalCost="additionalCost">
  {{
    additionalCost
      ? (additionalCost
        | currencyExchangeTo : supplierCurrency : currency
        | async
        | currencyTransform : currency
        | async)
      : (0 | currencyTransform : currency | async)
  }}
</ng-template>

<ng-template #shippingTimeTemplate let-minShippingDays="minShippingDays" let-maxShippingDays="maxShippingDays">
  {{
    minShippingDays || maxShippingDays
      ? ('SHIPPING_DETAILS.DAYS' | translate : { minDays: minShippingDays, maxDays: maxShippingDays })
      : null
  }}
</ng-template>

<ng-template #shippingCostTemplate let-shippingCost="shippingCost">
  {{
    shippingCost
      ? (shippingCost | currencyExchangeTo : supplierCurrency : currency | async | currencyTransform : currency | async)
      : ('SHIPPING_DETAILS.FREE' | translate)
  }}
</ng-template>
