import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import { FilterItemComponent } from './filter-item/filter-item.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { AlertMultiComponent } from '../../shared/components/alert-multi/alert-multi.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingScreenComponent } from 'app/shared/components/loading-screen/loading-screen.component';
import { TaskCommentComponent } from 'app/shared/components/task-comment/task-comment.component';

@NgModule({
  declarations: [FilterComponent, FilterItemComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTabsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatListModule,
    MatCardModule,
    FuseSharedModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    AlertMultiComponent,
    LoadingSpinnerComponent,
    TranslateModule,
    MatTooltipModule,
    LoadingScreenComponent,
    TaskCommentComponent,
  ],
  exports: [FilterComponent, FilterItemComponent],
})
export class FilterModule {}
