<div class="profile-container">
  <div class="user-profile-card">
    <div class="m-12 user-profile-card-cont">
      <div class="h2">
        {{ 'SUPPLIER_DETAILS.GENERAL_INFO' | translate }}
      </div>
      <div class="secondary-text">{{ 'SUPPLIER_DETAILS.ADD_GENERAL_INFO' | translate }}</div>
      <form #updateUserForm="ngForm" novalidate fxLayout="column" class="top-margin" *ngIf="!isLodaing">
        <div fxLayout="row" fxLayoutGap="10px" class="basic-company-container">
          <div fxFlex="30" class="logo-upload-container">
            <app-logo-upload
              [logoLink]="supplierProfileInfo.logoLink"
              (fileChanged)="setLogo($event)"
            ></app-logo-upload>
            <div *ngIf="!supplierLogo" class="required" fxLayoutAlign="start center">
              {{ 'SUPPLIER_DETAILS.LOGO_REQUIRED' | translate }}
            </div>
          </div>
          <div fxFlex="70" fxLayout="column">
            <div class="h2 mt-0">
              {{ 'SUPPLIER_DETAILS.BASIC_COMPANY_INFO' | translate }}
            </div>
            <!--Company Name-->
            <div class="h2">{{ 'SUPPLIER_DETAILS.COMPANY_NAME' | translate }}</div>
            <div class="secondary-text-cont">
              <mat-label class="secondary-text">
                {{ 'SUPPLIER_DETAILS.COMPANY_NAME_CONTENT' | translate }}
              </mat-label>
            </div>
            <mat-form-field appearance="fill" fxFlex="100" class="pr-4 pb-0 company-name-field">
              <mat-label>{{ 'SUPPLIER_DETAILS.COMPANY_NAME' | translate }}</mat-label>
              <input matInput name="companyName" required [(ngModel)]="supplierProfileInfo.companyName" />
              <mat-error>{{ 'SUPPLIER_DETAILS.COMPANY_NAME_REQUIRED' | translate }}</mat-error>
            </mat-form-field>

            <!--Email-->
            <mat-form-field appearance="fill" fxFlex="100" class="pr-4 pb-0">
              <mat-label>{{ 'SUPPLIER_DETAILS.CONTACT_EMAIL' | translate }}</mat-label>
              <input
                matInput
                name="contact_email"
                type="email"
                required
                #emailref="ngModel"
                [(ngModel)]="supplierProfileInfo.contactEmail"
              />
              <mat-error>{{ 'SUPPLIER_DETAILS.EMAIL_REQUIRED' | translate }}</mat-error>
            </mat-form-field>

            <!--Website-->
            <mat-form-field appearance="fill" fxFlex="100" class="pr-4 pb-0">
              <mat-label>{{ 'SUPPLIER_DETAILS.WEBSITE' | translate }}</mat-label>
              <input matInput name="website" required [(ngModel)]="supplierProfileInfo.website" />
              <mat-error>{{ 'SUPPLIER_DETAILS.WEBSITE_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" class="mt-20 supp-desc-cont">
          <div fxFlex="30" class="logo-upload-container">
            <app-logo-upload
              [logoLink]="supplierProfileInfo.coverLink"
              [isCover]="true"
              (fileChanged)="setCover($event)"
            ></app-logo-upload>
          </div>
          <div fxFlex="70" fxLayout="column">
            <!--Description-->
            <div class="h2">{{ 'SUPPLIER_DETAILS.DESC_TITLE' | translate }}</div>
            <div class="secondary-text-cont">
              <mat-label class="secondary-text">
                {{ 'SUPPLIER_DETAILS.DESC_CONTENT' | translate }}
              </mat-label>
            </div>
            <mat-form-field appearance="fill" fxFlex="100" class="pr-4 textarea-content mt-8">
              <mat-label>{{ 'SUPPLIER_DETAILS.DESC' | translate }}</mat-label>
              <textarea matInput name="description" required [(ngModel)]="supplierProfileInfo.description"></textarea>
              <mat-error>{{ 'SUPPLIER_DETAILS.DESC_REQUIRED' | translate }}</mat-error>
            </mat-form-field>

            <!--Warehouse Location-->
            <div class="h2">{{ 'SUPPLIER_DETAILS.MAIN_WAREHOUSE_LOCATION' | translate }}</div>
            <div class="secondary-text-cont">
              <mat-label class="secondary-text">
                {{ 'SUPPLIER_DETAILS.MAIN_WAREHOUSE_LOCATION_CONTENT' | translate }}
              </mat-label>
            </div>
            <mat-form-field appearance="fill" fxFlex="100" class="mt-8">
              <mat-label>{{ 'SUPPLIER_DETAILS.MAIN_WAREHOUSE_LOCATION' | translate }}</mat-label>
              <mat-select
                [(ngModel)]="supplierProfileInfo.mainWarehouseLocation"
                [ngModelOptions]="{ standalone: true }"
                required
              >
                <mat-option class="phone-filter">
                  <ngx-mat-select-search
                    [formControl]="countryFilterCtrl"
                    [placeholderLabel]="'SUPPLIER_DETAILS.SEARCH' | translate"
                    [noEntriesFoundLabel]="'SUPPLIER_DETAILS.SEARCH_NOT_FOUND' | translate"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let warehouseItem of filteredCountries | async" [value]="warehouseItem.name">
                  {{ warehouseItem.name }}
                </mat-option>
              </mat-select>

              <mat-error>{{ 'SUPPLIER_DETAILS.MAIN_WAREHOUSE_LOCATION_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="h2">{{ 'SUPPLIER_DETAILS.PHONE_NUMBER' | translate }}</div>
        <div class="secondary-text-cont">
          <mat-label class="secondary-text">
            {{ 'SUPPLIER_DETAILS.PHONE_NUMBER_CONTENT' | translate }}
          </mat-label>
        </div>
        <div fxLayout="row">
          <!-- Phone -->
          <mat-form-field appearance="fill" fxFlex="30" class="mt-8">
            <mat-label>{{ 'SUPPLIER_DETAILS.PHONE_CODE' | translate }}</mat-label>
            <mat-select [(ngModel)]="supplierProfileInfo.phoneCode" [ngModelOptions]="{ standalone: true }" required>
              <mat-option class="phone-filter">
                <ngx-mat-select-search
                  [formControl]="phoneCodeFilterCtrl"
                  [placeholderLabel]="'SUPPLIER_DETAILS.SEARCH' | translate"
                  [noEntriesFoundLabel]="'SUPPLIER_DETAILS.SEARCH_NOT_FOUND' | translate"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let code of filteredPhoneCodes | async" [value]="code.phoneCode">
                {{ code.phoneCode }} ({{ code.country }})
              </mat-option>
            </mat-select>
            <mat-error>{{ 'SUPPLIER_DETAILS.PHONE_CODE_REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="70" class="mt-8">
            <mat-label>{{ 'SUPPLIER_DETAILS.PHONE_NUMBER' | translate }}</mat-label>
            <input matInput name="phoneNumber" required [(ngModel)]="supplierProfileInfo.phoneNumber" />
            <mat-error>{{ 'SUPPLIER_DETAILS.PHONE_NUMBER_REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="h2">{{ 'SUPPLIER_DETAILS.PUBLIC_PHONE_NUMBER' | translate }}</div>
        <div class="secondary-text-cont">
          <mat-label class="secondary-text">
            {{ 'SUPPLIER_DETAILS.PUBLIC_PHONE_NUMBER_CONTENT' | translate }}
          </mat-label>
        </div>
        <div fxLayout="row">
          <!-- Public Phone -->
          <mat-form-field appearance="fill" fxFlex="30" class="mt-8">
            <mat-label>{{ 'SUPPLIER_DETAILS.PHONE_CODE' | translate }}</mat-label>
            <mat-select [(ngModel)]="supplierProfileInfo.publicPhoneCode" [ngModelOptions]="{ standalone: true }">
              <mat-option class="phone-filter">
                <ngx-mat-select-search
                  [formControl]="publicPhoneCodeFilterCtrl"
                  [placeholderLabel]="'SUPPLIER_DETAILS.SEARCH' | translate"
                  [noEntriesFoundLabel]="'SUPPLIER_DETAILS.SEARCH_NOT_FOUND' | translate"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let code of filteredPublicPhoneCodes | async" [value]="code.phoneCode">
                {{ code.phoneCode }} ({{ code.country }})
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="70" class="mt-8">
            <mat-label>{{ 'SUPPLIER_DETAILS.PHONE_NUMBER' | translate }}</mat-label>
            <input matInput name="publicPhoneNumber" [(ngModel)]="supplierProfileInfo.publicPhoneNumber" />
          </mat-form-field>
        </div>

        <div class="h2">{{ 'SUPPLIER_DETAILS.DISCOUNT' | translate }}</div>
        <div class="secondary-text-cont">
          <mat-label class="secondary-text">
            {{ 'SUPPLIER_DETAILS.DISCOUNT_CONTENT' | translate }}
          </mat-label>
        </div>
        <div>
          <!--Discount-->
          <mat-form-field appearance="fill" fxFlex="100" class="pr-4 pb-0 mt-8">
            <mat-label>{{ 'SUPPLIER_DETAILS.DISCOUNT_LABEL' | translate }}</mat-label>
            <input
              matInput
              name="discount"
              type="number"
              [min]="0"
              required
              [(ngModel)]="supplierProfileInfo.discount"
            />
            <mat-error>{{ 'SUPPLIER_DETAILS.DISCOUNT_ERROR' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="h2">{{ 'SUPPLIER_DETAILS.OWN_INVENTORY_TITLE' | translate }}</div>
        <div class="secondary-text-cont">
          <mat-label class="secondary-text">
            {{ 'SUPPLIER_DETAILS.OWN_INVENTORY_CONTENT' | translate }}
          </mat-label>
        </div>
        <mat-form-field appearance="fill" fxFlex="100" class="mt-8">
          <mat-label>{{ 'SUPPLIER_DETAILS.OWN_INVENTORY' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="supplierProfileInfo.ownInventory"
            name="ownInventory"
            disableOptionCentering
            panelClass="mat-select-display-change"
            required
          >
            <mat-option [value]="true">
              {{ 'SUPPLIER_DETAILS.YES' | translate }}
            </mat-option>
            <mat-option [value]="false">
              {{ 'SUPPLIER_DETAILS.NO' | translate }}
            </mat-option>
          </mat-select>
          <mat-error>{{ 'SUPPLIER_DETAILS.OWN_INVENTORY_REQUIRED' | translate }}</mat-error>
        </mat-form-field>

        <div class="h2">{{ 'SUPPLIER_DETAILS.YEAR_OF_FOUNDATION' | translate }}</div>
        <div class="secondary-text-cont">
          <mat-label class="secondary-text">
            {{ 'SUPPLIER_DETAILS.YEAR_OF_FOUNDATION_DESCR' | translate }}
          </mat-label>
        </div>
        <div>
          <!--Discount-->
          <mat-form-field appearance="fill" fxFlex="100" class="pr-4 pb-0 mt-8">
            <mat-label>{{ 'SUPPLIER_DETAILS.YEAR_OF_FOUNDATION' | translate }}</mat-label>
            <input
              matInput
              name="yearOfFoundation"
              type="number"
              [min]="1"
              [max]="currentYear"
              [pattern]="'[0-9]{4}'"
              required
              [(ngModel)]="supplierProfileInfo.yearOfFoundation"
            />
            <mat-error>{{ 'SUPPLIER_DETAILS.YEAR_OF_FOUNDATION_ERROR' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <!--                <div class="h2">{{'SUPPLIER_DETAILS.SHIPPING_INFO' | translate}}</div>-->
        <!--                <div class="secondary-text-cont">-->
        <!--                    <mat-label class="secondary-text">-->
        <!--                        {{'SUPPLIER_DETAILS.LONG_TEXT' | translate}}-->
        <!--                    </mat-label>-->
        <!--                </div>-->

        <!--Worldwide shipping-->
        <!--                <div fxLayout="column">-->
        <!--                    <mat-checkbox class="top-margin" name="isWorldWideShipping"-->
        <!--                                  [(ngModel)]="supplierProfileInfo.worldwideShipping">-->
        <!--                        {{'SUPPLIER_DETAILS.WORLDWIDE_SHIPPING' | translate}}-->
        <!--                    </mat-checkbox>-->
        <!--                </div>-->
        <!--                <div>-->
        <!--                    <mat-label class="secondary-text">-->
        <!--                        {{'SUPPLIER_DETAILS.WORLDWIDE_SHIPPING_NOTE' | translate}}-->
        <!--                    </mat-label>-->
        <!--                </div>-->

        <!--                <div class="top-margin" fxLayout="column" fxFlex="100">-->
        <!--                    <table *ngIf="supplierProfileInfo.shippingDetailsHandle && supplierProfileInfo.shippingDetailsHandle.length > 0; else noShippingAddedYet" class="shipping-table">-->
        <!--                        <thead>-->
        <!--                            <tr>-->
        <!--                                <th class="shipping-table__countries__header">{{'SUPPLIER_DETAILS.SHIPPING_TABLE.COUNTRIES' | translate}}</th>-->
        <!--                                <th class="shipping-table__days__header">{{'SUPPLIER_DETAILS.SHIPPING_TABLE.SHIPPING_DAYS' | translate}}</th>-->
        <!--                                <th class="shipping-table__cost__header">{{'SUPPLIER_DETAILS.SHIPPING_TABLE.SHIPPING_COST' | translate}}</th>-->
        <!--                                <th class="shipping-table__actions__header"></th>-->
        <!--                                <th></th>-->
        <!--                            </tr>-->
        <!--                        </thead>-->

        <!--                        <tbody>-->
        <!--                        <tr *ngFor="let shipping of supplierProfileInfo.shippingDetailsHandle;let i = index">-->
        <!--                            <td class="shipping-table__countries__content">-->
        <!--                                {{getCountryNames(shipping.countries).join(', ')}}-->
        <!--                            </td>-->
        <!--                            <td class="shipping-table__days__content">-->
        <!--                                {{shipping.minShippingTime}} - {{shipping.maxShippingTime}} {{'SUPPLIER_DETAILS.SHIPPING_TABLE.WORKING_DAYS' | translate}}-->
        <!--                            </td>-->
        <!--                            <td class="shipping-table__cost__content">-->
        <!--                                {{shipping.cost || shipping.cost == 0 ? shipping.cost : '-'}} USD-->
        <!--                                + {{shipping.additionalCost ? shipping.additionalCost : 0}} USD {{'SUPPLIER_DETAILS.SHIPPING_TABLE.ADDITIONAL_COST' | translate}}-->
        <!--                            </td>-->
        <!--                            <td class="shipping-table__actions__content">-->
        <!--                                <ng-container *ngIf="!isOnMobile">-->
        <!--                                    <button mat-button (click)="editShippingGroup(i, shipping)" class="mr-4">{{'SUPPLIER_DETAILS.SHIPPING_TABLE.EDIT' | translate}}</button>-->
        <!--                                    <button mat-button (click)="removeShippingGroup(i)">{{'SUPPLIER_DETAILS.SHIPPING_TABLE.REMOVE' | translate}}</button>-->
        <!--                                </ng-container>-->
        <!--                                <ng-container *ngIf="isOnMobile">-->
        <!--                                    <button mat-mini-fab color="accent" (click)="editShippingGroup(i, shipping)">-->
        <!--                                        <mat-icon>edit</mat-icon>-->
        <!--                                    </button>-->
        <!--                                    <button mat-mini-fab color="warn" (click)="removeShippingGroup(i)">-->
        <!--                                        <mat-icon>delete</mat-icon>-->
        <!--                                    </button>-->
        <!--                                </ng-container>-->

        <!--                            </td>-->
        <!--                        </tr>-->
        <!--                        </tbody>-->
        <!--                    </table>-->
        <!--                </div>-->
        <!--                <div class="pt-8">-->
        <!--                    <button mat-button (click)="addShippingGroup()">{{'SUPPLIER_DETAILS.SHIPPING_TABLE.ADD_GROUP' | translate}}</button>-->
        <!--                </div>-->
        <!--                <div *ngIf="supplierProfileInfo.worldwideShipping && !checkForWorldWideShippingInfo()" class="py-12 warn-500-fg">-->
        <!--                    {{'SUPPLIER_DETAILS.WORLDWIDE_SHIPPING_ERROR_NOTIFICATION' | translate}}-->
        <!--                </div>-->
        <div fxLayoutAlign="end">
          <button
            *ngIf="showSaveButton"
            mat-button
            class="submit-button syncee-blue-button"
            aria-label="SAVE"
            [disabled]="disableSaveButton || !isStepValid()"
            (click)="saveSupplierInfo()"
          >
            {{ 'SUPPLIER_DETAILS.SAVE' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #noShippingAddedYet>
  {{ 'SUPPLIER_DETAILS.SHIPPING_TABLE.NO_SHIPPING_INFO' | translate }}
</ng-template>
