import { Action } from '@ngrx/store';
import { FavoriteProductsIds, FollowedSuppliersIds } from '../../service/favorites/favorites-gateway.service';

export enum FavoritesActionTypes {
  GET_FAVORITE_PRODUCT_IDS_START = '[Favorites] Get favorite products ids START',
  GET_FAVORITE_PRODUCT_IDS_SUCCESS = '[Favorites] Get favorite products ids SUCCESS',
  ADD_TO_FAVORITE_PRODUCT_IDS_START = '[Favorites] Add To Favorites START',
  ADD_TO_FAVORITE_PRODUCT_IDS_SUCCESS = '[Favorites] Add To Favorites SUCCESS',
  REMOVE_FROM_FAVORITE_PRODUCT_IDS_START = '[Favorites] Remove From Favorites START',
  REMOVE_FROM_FAVORITE_PRODUCT_IDS_SUCCESS = '[Favorites] Remove From Favorites SUCCESS',
  REMOVE_ALL_FAVORITE_PRODUCT_IDS_START = '[Favorites] Remove All Favorites START',
  REMOVE_ALL_FAVORITE_PRODUCT_IDS_SUCCESS = '[Favorites] Remove All Favorites SUCCESS',

  GET_FOLLOWED_SUPPLIER_IDS_START = '[Favorites] Get Followed Suppliers Ids START',
  GET_FOLLOWED_SUPPLIER_IDS_SUCCESS = '[Favorites] Get Followed Suppliers Ids SUCCESS',
  ADD_TO_FOLLOWED_SUPPLIER_IDS_START = '[Favorites] Add To Followed Suppliers Ids START',
  ADD_TO_FOLLOWED_SUPPLIER_IDS_SUCCESS = '[Favorites] Add To Followed Suppliers Ids SUCCESS',
  REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_START = '[Favorites] Remove From Followed Suppliers Ids START',
  REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_SUCCESS = '[Favorites] Remove From Followed Suppliers Ids SUCCESS',
  REMOVE_ALL_FOLLOWED_SUPPLIER_IDS_START = '[Favorites] Remove All Followed Suppliers Ids START',
  REMOVE_ALL_FOLLOWED_SUPPLIER_IDS_SUCCESS = '[Favorites] Remove All Followed Suppliers Ids SUCCESS',
}

export class GetFavoriteProductsIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.GET_FAVORITE_PRODUCT_IDS_START;
  constructor(public payload: number) {}
}

export class GetFavoriteProductsIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.GET_FAVORITE_PRODUCT_IDS_SUCCESS;

  constructor(public payload: string[]) {}
}

export class AddToFavoriteIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.ADD_TO_FAVORITE_PRODUCT_IDS_START;
  constructor(public payload: FavoriteProductsIds) {}
}

export class AddToFavoriteIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.ADD_TO_FAVORITE_PRODUCT_IDS_SUCCESS;
  constructor(public payload: string[]) {}
}

export class RemoveFromFavoriteIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_FROM_FAVORITE_PRODUCT_IDS_START;
  constructor(public payload: FavoriteProductsIds) {}
}

export class RemoveFromFavoriteIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_FROM_FAVORITE_PRODUCT_IDS_SUCCESS;
  constructor(public payload: string[]) {}
}

export class RemoveAllFavoriteIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_ALL_FAVORITE_PRODUCT_IDS_START;
  constructor(public payload: number) {}
}

export class RemoveAllFavoriteIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_ALL_FAVORITE_PRODUCT_IDS_SUCCESS;
}

///////////////////////////////////////////////////////////////
export class GetFollowedSupplierIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.GET_FOLLOWED_SUPPLIER_IDS_START;
  constructor(public payload: number) {}
}

export class GetFollowedSupplierIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.GET_FOLLOWED_SUPPLIER_IDS_SUCCESS;

  constructor(public payload: number[]) {}
}

export class AddToFollowedSupplierIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.ADD_TO_FOLLOWED_SUPPLIER_IDS_START;
  constructor(public payload: FollowedSuppliersIds) {}
}

export class AddToFollowedSupplierIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.ADD_TO_FOLLOWED_SUPPLIER_IDS_SUCCESS;
  constructor(public payload: number[]) {}
}

export class RemoveFromFollowedSupplierIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_START;
  constructor(public payload: FollowedSuppliersIds) {}
}

export class RemoveFromFollowedSupplierIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_FROM_FOLLOWED_SUPPLIER_IDS_SUCCESS;
  constructor(public payload: number[]) {}
}

export class RemoveAllFollowedSupplierIdsStartAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_ALL_FOLLOWED_SUPPLIER_IDS_START;
  constructor(public payload: number) {}
}

export class RemoveAllFollowedSupplierIdsSuccessAction implements Action {
  readonly type = FavoritesActionTypes.REMOVE_ALL_FOLLOWED_SUPPLIER_IDS_SUCCESS;
}

export type FavoriteProductsActionUnion =
  | GetFavoriteProductsIdsStartAction
  | GetFavoriteProductsIdsSuccessAction
  | AddToFavoriteIdsStartAction
  | AddToFavoriteIdsSuccessAction
  | RemoveFromFavoriteIdsStartAction
  | RemoveFromFavoriteIdsSuccessAction
  | RemoveAllFavoriteIdsStartAction
  | RemoveAllFavoriteIdsSuccessAction
  | GetFollowedSupplierIdsStartAction
  | GetFollowedSupplierIdsSuccessAction
  | AddToFollowedSupplierIdsStartAction
  | AddToFollowedSupplierIdsSuccessAction
  | RemoveFromFollowedSupplierIdsStartAction
  | RemoveFromFollowedSupplierIdsSuccessAction
  | RemoveAllFollowedSupplierIdsStartAction
  | RemoveAllFollowedSupplierIdsSuccessAction;
