<div class="breadcrumb-container">
  <div class="full-path typography-body-2">
    <ng-container *ngFor="let item of navItems; let last = last">
      <div class="path-element" [class.checkout-nav-activate]="item.id === activeItemKey" (click)="clickHandler(item)">
        {{ item.key | translate }}
      </div>
      <ng-container *ngIf="!last">
        <div>/</div>
      </ng-container>
    </ng-container>
  </div>
</div>
