import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InstalledAppsAndGroups, SynceeAppsService } from '../../service/user-apps/syncee-apps.service';
import {
  GetInstalledSynceeAppsSuccessAction,
  InstallSynceeAppsStartAction,
  InstallSynceeAppsSuccessAction,
  SynceeAppsActionTypes,
  UninstallSynceeAppsStartAction,
  UninstallSynceeAppsSuccessAction,
} from './syncee-apps.actions';

@Injectable()
export class SynceeAppsEffects {
  constructor(private actions$: Actions, private synceeAppsService: SynceeAppsService) {}

  GetInstalledSynceeAppsStart: Observable<GetInstalledSynceeAppsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SynceeAppsActionTypes.GET_INSTALLED_SYNCEE_APPS_START,
        SynceeAppsActionTypes.INSTALL_SYNCEE_APPS_SUCCESS,
        SynceeAppsActionTypes.UNINSTALL_SYNCEE_APPS_SUCCESS
      ),
      switchMap(() => this.synceeAppsService.getAllInstalledByUser().pipe(catchError(() => of(null)))),
      map((installedApps: InstalledAppsAndGroups) => {
        return new GetInstalledSynceeAppsSuccessAction(installedApps);
      })
    )
  );

  InstallSynceeAppsStart: Observable<InstallSynceeAppsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SynceeAppsActionTypes.INSTALL_SYNCEE_APPS_START),
      switchMap((action: InstallSynceeAppsStartAction) => this.synceeAppsService.installByUser(action.payload)),
      map(() => new InstallSynceeAppsSuccessAction())
    )
  );

  UninstallSynceeAppsStart: Observable<UninstallSynceeAppsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SynceeAppsActionTypes.UNINSTALL_SYNCEE_APPS_START),
      switchMap((action: UninstallSynceeAppsStartAction) => this.synceeAppsService.deleteByUser(action.payload)),
      map(() => new UninstallSynceeAppsSuccessAction())
    )
  );
}
