<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="32px"
  fxLayoutAlign.lt-sm="space-between center"
  fxLayoutGap.lt-sm="16px"
>
  <ng-container *ngFor="let rating of ratingItems">
    <div
      class="cursor-pointer rating-item"
      fxLayout="column"
      fxLayoutGap="8px"
      fxLayoutAlign="flex-start center"
      (click)="handleSelection(rating.value)"
    >
      <mat-icon
        *ngIf="!!rating.icon"
        [svgIcon]="rating.icon"
        class="rating-icon"
        [ngClass]="'s-48'"
        ngClass.lt-sm="s-40"
        [class.selected]="selectedRating === rating.value"
      ></mat-icon>
      <div>
        {{ rating.label | translate }}
      </div>
    </div>
  </ng-container>
</div>
