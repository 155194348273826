import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { GetRetailerToCatalogSuccessAction, RetailerToCatalogActionTypes } from './retailer-to-catalog.actions';
import { map, switchMap } from 'rxjs/operators';
import { CatalogSidebarService } from '../../service/catalog-sidebar/catalog-sidebar.service';

@Injectable()
export class RetailerToCatalogEffects {
  constructor(private actions$: Actions, private catalogSidebarService: CatalogSidebarService) {}

  getRetailerToCatalogStart: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RetailerToCatalogActionTypes.GET_RETAILER_TO_CATALOG_START),
      switchMap(() => this.catalogSidebarService.getRetailerToCatalogForRetailer()),
      map((retailerToCatalog) => new GetRetailerToCatalogSuccessAction(retailerToCatalog))
    )
  );
}
