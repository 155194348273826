import { Component, OnInit } from '@angular/core';

import { StepperService } from '../../service/stepper.service';
import { ChangeRoleScreenService } from '../../service/change-role-screen.service';
import { NotificationService } from '../../../notification/notification.service';
import { ChangeRoleService } from '../../service/change-role.service';

import { TranslateService } from '@ngx-translate/core';

import type { ChangeRoleDialogConfig } from '../../model/change-role-dialog-config-type';
import type { TileSelectOption } from '../../../../shared/components/tile-select/model/tile-select-option';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-keep-role',
  templateUrl: './keep-role.component.html',
  styleUrls: ['./keep-role.component.scss'],
})
export class KeepRoleComponent implements OnInit {
  public options: TileSelectOption<boolean>[];

  public config: ChangeRoleDialogConfig;

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    public stepperService: StepperService,
    public screenService: ChangeRoleScreenService,
    public changeRoleService: ChangeRoleService
  ) {}

  public ngOnInit(): void {
    this.config = this.stepperService.config;

    this.initOptions();
  }

  private initOptions(): void {
    this.options = [
      {
        value: true,
        title: this.translateService.instant(this.config.payload['keep_role']),
      },
      {
        value: false,
        title: this.translateService.instant(this.config.payload['remove_role']),
      },
    ];
  }

  public handleChange(value?: boolean): void {
    this.changeRoleService.keepRole = value;
  }

  public handleNext(): void {
    if (Utils.isNullOrUndefined(this.changeRoleService.keepRole)) {
      const message = this.translateService.instant(this.config.payload['description']);
      this.notificationService.warning(message);
      return;
    }

    this.stepperService.forward();
  }

  get descriptionStyle(): object {
    return {
      'text-center syncee-grey-800-fg': true,
      'typography-body-3': this.screenService.small,
      'typography-body-1': !this.screenService.small,
    };
  }
}
