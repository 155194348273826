<app-custom-dialog-wrapper [headerLeftRef]="backToMySuppliers">
  <div class="mobile-catalog-list-wrapper" fxLayout="column" fxLayoutGap="40px">
    <h3>
      {{ 'MY_SUPPLIERS_TABLE_TITLES.CATALOGS_OF' | translate : { supplierName: data.supplierName } }}
    </h3>
    <div fxLayout="column">
      <ng-container *ngFor="let catalog of data.catalogList; let isLast = last">
        <div
          class="catalog-name-wrapper"
          [ngClass]="{ disabled: !catalog.catalog.adminApprovedStatus }"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="catalog-name" fxLayout="row">{{ catalog.catalog.name }}</div>
          <ng-container [ngSwitch]="catalog.catalog.adminApprovedStatus">
            <mat-icon *ngSwitchCase="true" (click)="showCatalogDetails(catalog)" class="s-20"
              >keyboard_arrow_right</mat-icon
            >
            <div *ngSwitchCase="false">{{ 'MY_SUPPLIERS.INFO.CATALOG_NOT_AVAILABLE_MOBILE' | translate }}</div>
          </ng-container>
        </div>

        <mat-divider *ngIf="!isLast"></mat-divider>
      </ng-container>
    </div>
  </div>
</app-custom-dialog-wrapper>

<ng-template #backToMySuppliers>
  <div (click)="closeAndRedirect()" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
    <mat-icon class="s-20">keyboard_arrow_left</mat-icon>
    <a>{{ 'MY_SUPPLIERS.ACTIONS.BACK_TO_MY_SUPPLIERS' | translate }}</a>
  </div>
</ng-template>
