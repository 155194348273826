import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImportListCollection } from '../model/import-list-collection';

@Component({
  selector: 'app-import-list-price-collection-selector',
  templateUrl: './import-list-price-collection-selector.component.html',
  styleUrls: ['./import-list-price-collection-selector.component.scss'],
})
export class ImportListPriceCollectionSelectorComponent implements OnInit {
  @Input() collections: ImportListCollection[];
  @Output() collectionChange = new EventEmitter<string>();

  collectionsWithPriceSettings: ImportListCollection[] = [];
  otherCollections: ImportListCollection[] = [];

  selectedCollectionId = '';

  constructor() {}

  ngOnInit(): void {
    this.collectionsWithPriceSettings = this.collections.filter((collection) => collection.hasPriceSettings);
    this.otherCollections = this.collections.filter((collection) => !collection.hasPriceSettings);
  }

  collectionChanged(): void {
    this.collectionChange.emit(this.selectedCollectionId);
  }
}
