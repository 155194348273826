import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge-label',
  templateUrl: './badge-label.component.html',
  styleUrls: ['./badge-label.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BadgeLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
