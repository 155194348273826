import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountriesLocationsState } from './countries-locations.reducer';

const getCountriesState = createFeatureSelector<CountriesLocationsState>('countriesLocations');

export const getCountriesSelector = createSelector(
  getCountriesState,
  (state: CountriesLocationsState) => state?.countriesLocations
);

export const getContinentsSelector = createSelector(
  getCountriesState,
  (state: CountriesLocationsState) => state?.continents
);
