import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-panel',
  templateUrl: './custom-panel.component.html',
  styleUrls: ['./custom-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CustomPanelComponent implements OnInit {
  @Input()
  public overflowScroll = false;

  constructor() {}

  ngOnInit(): void {}
}
