import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreenManagerService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  checkBreakpoint(breakpoint: BreakPoint): boolean {
    return window.innerWidth < breakpoint;
  }

  observeBreakpoint(breakpoint: BreakPoint): Observable<BreakpointState> {
    return this.breakpointObserver.observe(this.buildQuery(breakpoint));
  }

  observeBreakpoints(breakpoints: BreakPoint[]): Observable<BreakpointState> {
    return this.breakpointObserver.observe(breakpoints.map((breakpoint) => this.buildQuery(breakpoint)));
  }

  stateMatchesOperator(): (source: Observable<BreakpointState>) => Observable<boolean> {
    return (source: Observable<BreakpointState>): Observable<boolean> => source.pipe(map((state) => state.matches));
  }

  buildQuery(breakpoint: BreakPoint): string {
    return `(max-width: ${breakpoint}px)`;
  }

  observeIfBreakpointMatches(breakPoint: BreakPoint): Observable<boolean> {
    return this.observeBreakpoint(breakPoint).pipe(this.stateMatchesOperator());
  }
}

export enum BreakPoint {
  xs = 450,
  sm = 600,
  md = 960,
  lg = 1280,
  xd = 1600,
  xl = 1920,
}
