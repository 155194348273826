import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UpperRowItemWithChildren } from '../models/upper-row-item-with-children';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upper-row-item',
  templateUrl: './upper-row-item.component.html',
  styleUrls: ['./upper-row-item.component.scss'],
})
export class UpperRowItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() element: UpperRowItemWithChildren;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  private unsubscribeAll: Subject<void>;
  isOpen = false;
  menuMouseHover: Subject<boolean>;
  triggerMouseHover: Subject<boolean>;

  constructor() {
    this.unsubscribeAll = new Subject();
    this.menuMouseHover = new BehaviorSubject(false);
    this.triggerMouseHover = new BehaviorSubject(false);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.element.type === 'menu' && !!this.trigger) {
      this.trigger.menuClosed.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => (this.isOpen = false));
      this.trigger.menuOpened.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => (this.isOpen = true));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  redirectExternal(link: string): void {
    window.open(link, '_blank');
  }
}
