import { IChannelSort } from './IChannelSort';
import { ChannelItem } from '../../../../service/chat/model/chat-models';

export class ChannelLatestMessSort implements IChannelSort {
  sort(list: ChannelItem[]): ChannelItem[] {
    if (!list || list.length === 0) {
      return list;
    }
    return list.sort((a: ChannelItem, b: ChannelItem) => {
      const aDate = new Date(a.lastMessageDate);
      const bDate = new Date(b.lastMessageDate);
      return bDate.getTime() - aDate.getTime();
      // return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    });
  }
}
