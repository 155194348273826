import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestResponse } from '../rest/rest-response';
import { RestService } from '../rest/rest.service';
import { NotificationService } from '../../main/notification/notification.service';
import { ScheduleTimeVO } from './taskwizard-update-response';

@Injectable({
  providedIn: 'root',
})
export class SchedueServiceService {
  public static SCHEDULE_SAVE_URL = 'TaskSchedulerService/saveSchedule';
  public static EXPORT_SCHEDULE_SAVE_URL = 'TaskSchedulerService/saveExportSchedule';

  constructor(private restService: RestService, private notificationService: NotificationService) {}

  public saveSchedule(scheduleTimeRequest: ScheduleTimeVO): Observable<RestResponse> {
    return this.restService.post(SchedueServiceService.SCHEDULE_SAVE_URL, scheduleTimeRequest);
  }

  public saveExportSchedule(scheduleTimeRequest: ScheduleTimeVO): Observable<RestResponse> {
    return this.restService.post(SchedueServiceService.EXPORT_SCHEDULE_SAVE_URL, scheduleTimeRequest);
  }
}
