import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-divider',
  templateUrl: './custom-divider.component.html',
  styleUrls: ['./custom-divider.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CustomDividerComponent implements OnInit {
  @Input() margin;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() color = 'var(--app-syncee-grey-200)';

  constructor() {}

  ngOnInit(): void {}
}
