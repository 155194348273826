<div fxLayout="row" fxLayoutAlign="start center">
  <div *ngIf="textBefore" class="pr-4">
    {{ textBefore }}
  </div>
  <div
    class="copy-to-clipboard-container"
    fxLayout="row"
    fxLayoutAlign="start center"
    (click)="copyInput(copyToClipBoardInput)"
    [matTooltip]="toolTipMessage"
    [matTooltipPosition]="toolTipPosition"
    [matTooltipDisabled]="toolTipDisabled"
  >
    <input
      class="copy-to-clipboard-input"
      type="text"
      value="{{ content }}"
      readonly
      #copyToClipBoardInput
      [style.width]="getContentLength() + 2 + 'ch'"
    />
    <mat-icon class="copy-to-clipboard-icon">{{ icon }}</mat-icon>
  </div>
</div>
