<app-custom-dialog-wrapper [headerLeftRef]="title" [actionBarRef]="actions">
  <div fxLayout="column" fxLayoutGap="15px">
    <app-alert-multi
      matIcon="info"
      type="warning"
      [messages]="['SUPPLIER_CURRENCY_SELECTOR.DIALOG.WARNING_CONTENT' | translate : { currency: data.currency }]"
    ></app-alert-multi>
    <mat-checkbox [(ngModel)]="checkedRisk">{{
      'SUPPLIER_CURRENCY_SELECTOR.DIALOG.RISK_CHECKBOX' | translate
    }}</mat-checkbox>
  </div>
</app-custom-dialog-wrapper>
<ng-template #title>
  <h5>{{ 'SUPPLIER_CURRENCY_SELECTOR.DIALOG.TITLE' | translate }}</h5>
</ng-template>
<ng-template #actions>
  <div fxLayout="row" fxLayoutAlign="end">
    <button [disabled]="!checkedRisk" mat-button class="syncee-blue-button" (click)="confirm()">
      {{ 'SUPPLIER_CURRENCY_SELECTOR.DIALOG.SAVE' | translate }}
    </button>
  </div>
</ng-template>
