import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AvatarComponent } from '../avatar/avatar.component';
import { CommonAlertThemeType } from '../../../vo/theme-config/common-alert-theme-type';
import { ThemeColorConfig } from '../../../vo/theme-config/theme-color-config';

@Component({
  selector: 'app-avtar-with-icon-themed',
  standalone: true,
  imports: [CommonModule, MatIconModule, AvatarComponent],
  templateUrl: './avtar-with-icon-themed.component.html',
  styleUrls: ['./avtar-with-icon-themed.component.scss'],
})
export class AvtarWithIconThemedComponent implements OnInit {
  @Input() icon = 'info';
  @Input() themeType: CommonAlertThemeType = 'info';
  themeConfig: ThemeColorConfig = {
    info: {
      color: 'var(--app-syncee-primary-700)',
      bgColor: 'var(--app-syncee-primary-50)',
    },
    warning: { color: 'var(--app-amber-800)', bgColor: 'var(--app-amber-100)' },
    success: { color: 'var(--app-green-700)', bgColor: 'var(--app-light-green-100)' },
    danger: { color: 'var(--app-red-400)', bgColor: 'var(--app-red-100)' },
  };
  constructor() {}

  ngOnInit(): void {}
}
