import { Injectable } from '@angular/core';
import { SYNCEE_PRODUCTS } from '../../utils/Constants';
import { EcomVO } from '../ecom/ecom.service';

// tslint:disable-next-line: ban-types
declare let fbq: Function;
// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class MetricsEventManagerService {
  public synceeProducts = SYNCEE_PRODUCTS;

  constructor() {}

  public sendPurchaseEventToSEOPlatforms(ecom: EcomVO, toFacebook: boolean = true, toGoogle: boolean = false): void {
    const fbqObj = { value: 0, content_type: 'subscription', currency: 'USD', contents: [] };
    const subIds = [];
    this.synceeProducts.forEach((sProd) => {
      if (!ecom.subscriptions[sProd].isLastChanged) {
        return;
      }
      subIds.push(ecom.subscriptions[sProd].subId);
      fbqObj.value += ecom.subscriptions[sProd].price;
      fbqObj.contents.push({ id: ecom.subscriptions[sProd].planName, quantity: 1 });
    });

    if (toFacebook) {
      fbq('track', 'Purchase', fbqObj);
    }

    if (toGoogle) {
      gtag('event', 'conversion', {
        send_to: 'AW-365637940/U9NWCLjh_KQCELTirK4B',
        value: fbqObj.value,
        currency: 'USD',
      });
    }
  }
}
