<div class="authentication-form-content">
  <img class="authentication-syncee-logo-mobile" src="assets/images/logos/syncee-logo.svg" />
  <div class="text-center" fxLayout="column" fxLayoutGap="4px">
    <h2 class="mt-0">{{ 'AUTHENTICATION.RESET_PASSWORD.TITLE' | translate }}</h2>
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.RESET_PASSWORD.SUBTITLE' | translate }}
    </div>
  </div>
  <form [formGroup]="form" class="w-100p" fxLayout="column" fxLayoutGap="32px">
    <mat-form-field class="input-without-padding-except-error" [appearance]="'fill'">
      <mat-label>{{ 'AUTHENTICATION.EMAIL' | translate }}</mat-label>
      <input matInput placeholder="{{ 'AUTHENTICATION.EMAIL' | translate }}" formControlName="email" required />
      <mat-error *ngIf="email.errors?.['required']">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
      <mat-error *ngIf="email.errors?.['email']">{{ 'AUTHENTICATION.INCORRECT_EMAIL_FORMAT' | translate }}</mat-error>
    </mat-form-field>
    <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="submitHandle()">
      {{ 'AUTHENTICATION.FORGOT_PASSWORD.SEND_RESET_LINK' | translate }}
    </button>
  </form>

  <button mat-flat-button (click)="goBackToLogin()">
    {{ 'AUTHENTICATION.FORGOT_PASSWORD.BACK_TO_LOGIN' | translate }}
  </button>
</div>
