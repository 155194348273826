import { Replacer } from '../field-settings/replacer/replacer';
import { Utils } from 'app/utils/utils';
export abstract class Chip {
  constant: boolean;
  hasSettings = false;
}

export class ConstantChip extends Chip {
  readonly constant = true;
  value: string;

  constructor(value: string) {
    super();
    this.value = value;
  }
}

export interface PathPiece {
  position?: number;
  piece: string;
  index?: any;
}

export interface ChipColor {
  color: string;
  backgroundColor: string;
  iconColor: string;
  iconActiveColor: string;
}

export class VariableChip extends Chip {
  readonly constant = false;
  path: string | any[];
  fileId: number;
  operations: FieldOperation[] = [];
  settings: FieldSetting[] = [];
  color: ChipColor;
  pathPieces: PathPiece[];
  altFields?: (ConstantChip | VariableChip)[];

  constructor(path: any[] | string, fileId: number, color: ChipColor) {
    super();
    this.fileId = fileId;
    this.path = path;
    this.color = color;
    this.handlePath(this.path.toString());
  }

  private handlePath(path: string): void {
    this.pathPieces = [];
    let piece = '';
    for (let i = 0; i < path.length; i++) {
      if (path.charAt(i) === '#') {
        let index = '#';
        if (Utils.isNumeric(path.charAt(i + 1))) {
          index = path.charAt(i + 1);
          ++i;
        }
        this.pathPieces.push({ piece: piece, index: index });
        piece = '';
      } else {
        piece += path.charAt(i);
      }
    }
    if (piece) {
      this.pathPieces.push({ piece: piece });
    }
  }

  public regeneratePath(): void {
    let newPath = '';
    this.pathPieces.forEach((piece) => {
      newPath += piece.piece;
      if (!Utils.isNullOrUndefined(piece.index)) {
        newPath += '#';
        if (piece.index !== '#') {
          newPath += piece.index;
        }
      }
    });
    this.path = newPath;
  }
}

export class ReplacerWrapper {
  replacer: Replacer[];

  constructor(replacer: Replacer[]) {
    this.replacer = replacer;
  }
}

export class EvalWrapper {
  eval: string;

  constructor(expr: string) {
    this.eval = expr;
  }
}

export class SplitterWrapper {
  splitter: string;

  constructor(splitter: string) {
    this.splitter = splitter;
  }
}

export class SelectOnlyWrapper {
  selectOnly: string;

  constructor(selectOnly: string) {
    this.selectOnly = selectOnly;
  }
}

export class CurrencyWrapper {
  currency: string;

  constructor(currency: string) {
    this.currency = currency;
  }
}

export class LocationWrapper {
  location: any;

  constructor(location: any = null) {
    this.location = location;
  }
}

export class DimWrapper {
  dimUnit: string;

  constructor(dimUnit: string = '') {
    this.dimUnit = dimUnit;
  }
}

export class WeightWrapper {
  weightUnit: string;

  constructor(weight: string = '') {
    this.weightUnit = weight;
  }
}

export class HPCCWrapper {
  HPCC: boolean;

  constructor(HPCC: boolean) {
    this.HPCC = HPCC;
  }
}

export class FillTheEmptyWrapper {
  fillTheEmpty = false;

  constructor(fillTheEmpty: boolean = false) {
    this.fillTheEmpty = fillTheEmpty;
  }
}

export class RepetitiveRowsWrapper {
  repetitiveRows: string;

  constructor(repetitiveRows: string = '') {
    this.repetitiveRows = repetitiveRows;
  }
}

export class ExportChooseFirstRowWrapper {
  firstRow: string;

  constructor(firstRow: string = '') {
    this.firstRow = firstRow;
  }
}

export type FieldOperation = ReplacerWrapper | EvalWrapper | SplitterWrapper | SelectOnlyWrapper;
export type FieldSetting =
  | CurrencyWrapper
  | HPCCWrapper
  | WeightWrapper
  | RepetitiveRowsWrapper
  | DimWrapper
  | ExportChooseFirstRowWrapper
  | FillTheEmptyWrapper
  | LocationWrapper;
