import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PreferenceService } from 'app/service/preference/preference.service';
import { concatMap, mergeMap, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  DeletePreferencesStartAction,
  DeletePreferencesSuccessAction,
  GetPreferencesStartAction,
  GetPreferencesSuccessAction,
  GetPreferenceTypesSuccessAction,
  PreferencesActionTypes,
  SavePreferencesStartAction,
  SavePreferencesSuccessAction,
} from './preferences.action';
import { Preference } from '../../vo/Preferences/preference';

@Injectable()
export class UserPreferencesEffects {
  constructor(private actions$: Actions, private preferenceService: PreferenceService) {}

  GetUserPreferencesStart: Observable<GetPreferencesSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActionTypes.GET_PREFERENCES_START),
      switchMap((action: GetPreferencesStartAction) =>
        this.preferenceService.getPreference(action.payload).pipe(catchError(() => of(null)))
      ),
      map((preference: Preference[]) => new GetPreferencesSuccessAction(preference))
    )
  );

  SaveUserPreferencesStart: Observable<SavePreferencesSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActionTypes.SAVE_PREFERENCES_START),
      mergeMap((action: SavePreferencesStartAction) => this.preferenceService.postPreferences(action.payload)),
      map((value) => new SavePreferencesSuccessAction(value))
    )
  );

  DeletePreferencesStart: Observable<DeletePreferencesSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActionTypes.DELETE_PREFERENCES_START),
      switchMap((action: DeletePreferencesStartAction) => this.preferenceService.deletePreferences(action.payload)),
      map((deletedIds) => new DeletePreferencesSuccessAction(deletedIds))
    )
  );

  GetPreferenceTypesStart: Observable<GetPreferenceTypesSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActionTypes.GET_PREFERENCE_TYPES_START),
      switchMap(() => this.preferenceService.getPreferenceTypes()),
      map((preferenceTypes) => new GetPreferenceTypesSuccessAction(preferenceTypes))
    )
  );
}
