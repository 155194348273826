import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { PricingVO } from '../../vo/pricing/pricing-vo';
import { PricingMainVO } from '../../vo/pricing/pricing-main-vo';
import { RoundingVO } from '../../vo/pricing/rounding-vo';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ExportPricingService {
  private readonly defaultPricingVO = {
    catalog_id: null,
    pricing: { '': { '0': { perc: '-0', fix: '-0' } } },
  };

  constructor(private restService: RestService) {}

  public getPrefixes(): any[] {
    return [
      { name: '+', value: '' },
      { name: '-', value: '-' },
    ];
  }

  public getPricing(taskId: number): Observable<any> {
    const httpParams: HttpParams = new HttpParams().set('taskId', taskId.toString());
    const restUrl = `PricingService/getExportTaskPricing`;

    return this.restService.get(restUrl, httpParams).pipe(
      map((response) => {
        const parsedResponse = response.getFirstData();

        if (!Utils.isNullOrUndefined(parsedResponse)) {
          parsedResponse.hasPricing = true;
        }

        return !!parsedResponse ? parsedResponse : JSON.parse(JSON.stringify(this.defaultPricingVO));
      })
    );
  }

  public getDefaultPricingVO(): Observable<PricingVO> {
    return new Observable((observer) => {
      observer.next(JSON.parse(JSON.stringify(this.defaultPricingVO)));
    });
  }

  public getDefaultExample(currency = 'USD'): string {
    return `For example a(n) 1.24 ${currency} product's price will be 1.24 ${currency}`;
  }

  public getRoundings(): any {
    return [
      { name: 'None', value: 'none' },
      { name: 'xxx.00', value: '.00' },
      { name: 'xxx.95', value: '.95' },
      { name: 'xxx.99', value: '.99' },
    ];
  }

  public updatePricing(pricingMainVO: PricingMainVO, roundingVO: RoundingVO, taskID: number): Observable<any> {
    const rest = {
      taskId: taskID,
      rounding: roundingVO.rounding,
      pricing: pricingMainVO.pricing,
    };
    console.log(rest);

    const restUrl = `ExportTaskService/updateTaskPricingAndRounding`;
    return this.restService.post(restUrl, rest).pipe(map((response) => response.getData()));
  }

  public updateExportPricing(pricing: PricingVO, rounding: string, taskID: number): Observable<any> {
    const rest = {
      taskId: taskID,
      rounding: rounding,
      pricing: pricing,
    };

    const restUrl = `ExportTaskService/updateTaskPricingAndRounding`;
    return this.restService.post(restUrl, rest).pipe(map((response) => response.getData()));
  }
}
