import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { EcomService, EcomVO } from 'app/service/ecom/ecom.service';
import { EMPTY, iif, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SubscriptionFetchStartAction, SubscriptionFetchStartPayload } from '../subscription/subscription.actions';
import {
  EcomActionTypes,
  RetailerEcomsFetchStart,
  RetailerEcomsFetchSuccess,
  SelectedEcomCurrencyUpdateStart,
  SelectedRetailerEcomChangeSuccess,
  SelectedSupplierEcomChangeStart,
  SelectedSupplierEcomChangeSuccess,
  SupplierEcomsFetchStart,
  SupplierEcomsFetchSuccess,
  UserEcomChainToSubscriptionChangedSuccess,
  UserEcomChangeSuccessPayload,
  UserEcomsChainToSubscriptionFetch,
  UserEcomsSuccessPayload,
} from './ecom.actions';
import {
  retailerEcomsSelector,
  selectedRetailerEcomSelector,
  selectedSupplierEcomSelector,
  supplierEcomsSelector,
} from './ecom.selector';
import { isEmpty } from 'lodash';
import { ROLE_TYPE, RolesEnum } from '../../vo/roles/roles';
import { FetchSelectedCurrenciesWithoutChainStart } from '../currency/currency.action';
import { CurrencyService } from 'app/service/currency-service/currency.service';
import { GetEcomUsageStartAction } from '../usage/usage.actions';

@Injectable()
export class EcomEffects {
  constructor(private actions$: Actions, private ecomService: EcomService, private store: Store<AppState>) {}

  SupplierEcomsFetch: Observable<SupplierEcomsFetchSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.FETCH_SUPPLIER_ECOMS_START),
      switchMap(() => {
        return this._getUserEcomsObsByRole(RolesEnum.SUPPLIER);
      }),
      map((payload: UserEcomsSuccessPayload) => new SupplierEcomsFetchSuccess(payload))
    )
  );

  RetailerEcomsFetch: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.FETCH_RETAILER_ECOMS_START),
      switchMap((action: RetailerEcomsFetchStart) => {
        return iif(() => action.payload.hasRetailerRole, this._getUserEcomsObsByRole(RolesEnum.RETAILER), of([]));
      }),
      switchMap(
        (payload: UserEcomsSuccessPayload) =>
          [
            new RetailerEcomsFetchSuccess(payload),
            new FetchSelectedCurrenciesWithoutChainStart({
              selectedCurrency: CurrencyService.getCurrency(payload.selectedEcom),
              withNotification: true,
            }),
          ] as Action[]
      )
    )
  );

  UserEcomsChainToSubscriptionFetchEffects: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.USER_ECOMS_CHAIN_TO_SUBSCRIPTION_FETCH),
      map((action: UserEcomsChainToSubscriptionFetch) => action.payload),
      switchMap(({ roles }) => {
        const actions: Action[] = [
          new RetailerEcomsFetchStart({ hasRetailerRole: roles.includes(RolesEnum.RETAILER) }),
          roles.includes(RolesEnum.SUPPLIER) && new SupplierEcomsFetchStart(),
        ].filter(Boolean);
        if (!isEmpty(actions)) {
          return actions;
        } else {
          return EMPTY;
        }
      })
    )
  );

  UserEcomsChainToSubscriptionFetchSuccessEffects: Observable<SubscriptionFetchStartAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.FETCH_RETAILER_ECOMS_SUCCESS),
      map((action: RetailerEcomsFetchSuccess) => action.payload),
      filter((payload) => !!payload.selectedEcom),
      map(
        (payload) => ({ ecomId: payload.selectedEcom.id, role: RolesEnum.RETAILER } as SubscriptionFetchStartPayload)
      ),
      map((payload) => new SubscriptionFetchStartAction(payload))
    )
  );

  UserEcomsChainToBootstrapEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.FETCH_RETAILER_ECOMS_SUCCESS),
      map((action: RetailerEcomsFetchSuccess) => action.payload),
      filter((payload) => !!payload.ecoms?.length),
      switchMap((payload) => [new GetEcomUsageStartAction(payload.ecoms.map((ecom) => ecom.id))])
    )
  );

  SelectedSupplierEcomChanged: Observable<SelectedSupplierEcomChangeSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.CHANGE_SELECTED_SUPPLIER_ECOM_START),
      switchMap((action: SelectedSupplierEcomChangeStart) => {
        return this.setSelectedEcomByRole(action.payload.ecomId, RolesEnum.SUPPLIER);
      }),
      map((selectedEcom) => new SelectedSupplierEcomChangeSuccess({ selectedEcom }))
    )
  );

  SelectedRetailerEcomChanged: Observable<SelectedRetailerEcomChangeSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.CHANGE_SELECTED_RETAILER_ECOM_START),
      switchMap((action: SelectedSupplierEcomChangeStart) => {
        return this.setSelectedEcomByRole(action.payload.ecomId, RolesEnum.RETAILER);
      }),
      map((selectedEcom) => new SelectedRetailerEcomChangeSuccess({ selectedEcom }))
    )
  );

  SelectedEcomChangeSuccess: Observable<UserEcomChainToSubscriptionChangedSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EcomActionTypes.CHANGE_SELECTED_RETAILER_ECOM_SUCCESS,
        EcomActionTypes.CHANGE_SELECTED_SUPPLIER_ECOM_SUCCESS
      ),
      map(
        (action: SelectedRetailerEcomChangeSuccess) =>
          new UserEcomChainToSubscriptionChangedSuccess({ selectedEcom: action.payload.selectedEcom })
      )
    )
  );

  UserEcomChainToSubscriptionChangedSuccess: Observable<SubscriptionFetchStartAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.USER_ECOM_CHAIN_TO_SUBSCRIPTION_CHANGED_SUCCESS),
      map((action: UserEcomChainToSubscriptionChangedSuccess) => action.payload.selectedEcom),
      map((selectedEcom) => ({ ecomId: selectedEcom.id, role: RolesEnum.RETAILER } as SubscriptionFetchStartPayload)),
      map((payload: SubscriptionFetchStartPayload) => new SubscriptionFetchStartAction(payload))
    )
  );

  SelectedEcomCurrencyUpdate: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcomActionTypes.SELECTED_ECOM_CURRENCY_UPDATE_START),
      map((action: SelectedEcomCurrencyUpdateStart) => {
        const copySelectedEcom = JSON.parse(JSON.stringify(action.payload.ecom));
        copySelectedEcom.options.preferredCurrency = action.payload.currency;
        this.resetEcomsInLocalStorage(copySelectedEcom, action.payload.role);
        return {
          selectedEcom: copySelectedEcom,
          role: action.payload.role,
        };
      }),
      map((payload: UserEcomChangeSuccessPayload) => {
        return payload.role === RolesEnum.SUPPLIER
          ? new SelectedSupplierEcomChangeSuccess(payload)
          : new SelectedRetailerEcomChangeSuccess(payload);
      })
    )
  );

  // UTILS

  private setSelectedEcomByRole(ecomId: number, role: ROLE_TYPE): Observable<EcomVO> {
    const selector = role === RolesEnum.RETAILER ? retailerEcomsSelector : supplierEcomsSelector;
    return this.store.select(selector).pipe(
      take(1),
      map((ecoms) => {
        return ecoms.find((ecom) => ecom.id === ecomId);
      }),
      tap((newSelectedEcom) => {
        this.setSelectedEcomInLocalStorageByRole(role, newSelectedEcom);
      })
    );
  }

  private _getUserEcomsObsByRole(role: ROLE_TYPE): Observable<UserEcomsSuccessPayload> {
    return this.ecomService.getOnlyDomains(role).pipe(
      tap((ecoms) => {
        this.setEcomsInLocalStorageByRole(role, ecoms);
      }),
      switchMap((ecoms) =>
        this.getSelectedEcomSelectorByRole(role).pipe(
          take(1),
          map((selectedEcom) => {
            if (ecoms.length < 1) {
              return null;
            }
            if (selectedEcom === undefined) {
              selectedEcom = this.getSelectedEcomFromLocalStorageByRole(role)
                ? this.getSelectedEcomFromLocalStorageByRole(role)
                : ecoms[0] || null;
            }
            return selectedEcom;
          }),
          tap((selectedEcom) => {
            if (!!selectedEcom) {
              this.setSelectedEcomInLocalStorageByRole(role, selectedEcom);
            }
          }),
          map((selectedEcom) => {
            return { ecoms, selectedEcom } as UserEcomsSuccessPayload;
          })
        )
      )
    );
  }

  private getSelectedEcomSelectorByRole(role: ROLE_TYPE): Observable<EcomVO> {
    return this.store.select(role === RolesEnum.SUPPLIER ? selectedSupplierEcomSelector : selectedRetailerEcomSelector);
  }

  private setEcomsInLocalStorageByRole(role: ROLE_TYPE, ecoms: EcomVO[]): void {
    const storageKey = role === RolesEnum.SUPPLIER ? 'supplierEcoms' : 'retailerEcoms';
    localStorage.setItem(storageKey, JSON.stringify(ecoms));
  }

  private setSelectedEcomInLocalStorageByRole(role: ROLE_TYPE, selectedEcom: EcomVO): void {
    const storageKey = role === RolesEnum.SUPPLIER ? 'selectedSupplierEcom' : 'selectedRetailerEcom';
    localStorage.setItem(storageKey, JSON.stringify(selectedEcom));
    localStorage.setItem(storageKey + 'Id', JSON.stringify(selectedEcom.id));
  }

  private getSelectedEcomFromLocalStorageByRole(role: ROLE_TYPE): EcomVO | null {
    const storageKey = role === RolesEnum.SUPPLIER ? 'selectedSupplierEcom' : 'selectedRetailerEcom';
    return localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : null;
  }

  private resetEcomsInLocalStorage(selectedEcom: EcomVO, role: RolesEnum): void {
    this.setSelectedEcomInLocalStorageByRole(role, selectedEcom);
    const changedEcoms = JSON.parse(
      localStorage.getItem(role === RolesEnum.RETAILER ? 'retailerEcoms' : 'supplierEcoms')
    ).map((ecom: EcomVO) => {
      if (ecom.id === selectedEcom.id) {
        return selectedEcom;
      }
      return ecom;
    });

    this.setEcomsInLocalStorageByRole(role, changedEcoms);
  }
}
