import { Component, OnInit, Inject } from '@angular/core';

import { FadeInOutAnimation } from '../../../styles/animations/fade-in-out.animation';

import { StepperService } from './service/stepper.service';
import { ChangeRoleService } from './service/change-role.service';
import { ChangeRoleScreenService } from './service/change-role-screen.service';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import type { ChangeRoleDialogData } from './model/change-role-dialog-data';

import { Step } from './model/step';

@Component({
  selector: 'app-change-role-dialog',
  templateUrl: './change-role-dialog.component.html',
  styleUrls: ['./change-role-dialog.component.scss'],
  animations: [FadeInOutAnimation],
  providers: [StepperService, ChangeRoleService, ChangeRoleScreenService],
})
export class ChangeRoleDialogComponent implements OnInit {
  constructor(
    public stepperService: StepperService,
    private changeRoleService: ChangeRoleService,
    @Inject(MAT_DIALOG_DATA) public data: ChangeRoleDialogData
  ) {}

  public ngOnInit(): void {
    this.stepperService.initStepper(this.data.role, this.data.textRewrite);

    this.changeRoleService.role = this.data.role;
  }

  public get Step(): typeof Step {
    return Step;
  }
}
