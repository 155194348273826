export class FtpConnectionStateVo {
  private _taskId;
  private _host;
  private _port;
  private _username;
  private _password;
  private _root;
  private _passive;
  private _isSsl;

  get passive(): string {
    return this._passive;
  }

  set passive(value) {
    this._passive = value;
  }

  get isSsl(): string {
    return this._isSsl;
  }

  set isSsl(value) {
    this._isSsl = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value) {
    this._taskId = value;
  }

  get host(): string {
    return this._host;
  }

  set host(value) {
    this._host = value;
  }

  get port(): string {
    return this._port;
  }

  set port(value) {
    this._port = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value) {
    this._username = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value) {
    this._password = value;
  }

  get root(): string {
    return this._root;
  }

  set root(value) {
    this._root = value;
  }
}
