<div class="custom-modal-container">
  <div class="title">
    <span>{{ 'TASKWIZARD.ADDFILES.MODALS.FILE_TYPE' | translate }}</span>
  </div>
  <div class="content" fxLayout="column" fxLayoutAlign="start">
    <div fxFlex="1 0 auto" fxLayout="column">
      <div class="my-h3">{{ 'TASKWIZARD.ADDFILES.MODALS.DO_YOU' | translate }}</div>
      <div class="my-h4">{{ 'TASKWIZARD.ADDFILES.MODALS.IF_NO' | translate }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" class="action-panel">
      <button class="no-btn" mat-button (click)="change()">
        {{ 'TASKWIZARD.ADDFILES.MODALS.NO' | translate }}
      </button>
      <button class="keep-btn" mat-button (click)="keep()" color="accent">
        {{ 'TASKWIZARD.ADDFILES.MODALS.KEEP' | translate }}
      </button>
    </div>
  </div>
</div>
