import { Component, Input, OnInit } from '@angular/core';
import { RetailerCatalogSettingsVO } from '../../marketplace/retailer-catalogs/retailer-catalog-details/retailer-catalog-settings/abstarct-retailer-settings';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { CatalogService } from '../../../service/catalog/catalog.service';
import { Constants, SchedulerConstants } from '../../../utils/Constants';
import { Utils } from '../../../utils/utils';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-retailer-variants-update-summary',
  templateUrl: './retailer-variants-update-summary.component.html',
  styleUrls: ['./retailer-variants-update-summary.component.scss'],
})
export class RetailerVariantsUpdateSummaryComponent implements OnInit {
  @Input() taskId: number;
  @Input() rcatalogId: number;
  @Input() retailerSettingsVO: RetailerCatalogSettingsVO;
  @Input() comments: any;
  selectedNotification = 'dont';

  private readonly SCHEDULE_TYPES = { daily: 'day', weekly: 'week', monthly: 'month', auto: 'day' };
  private readonly WEEKLY_DISPLAYS = ['Sunday', 'Monday', 'TuesDay', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  taskName: string;
  sourceType: string;
  productNumber: string;
  variantsNumber: string;
  filteredNumber: string;
  scheduleType: string;
  scheduleTimes: string[];

  showDetails = false;

  constructor(
    private taskWService: TaskwizardService,
    private catalogService: CatalogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.taskWService.getVUSummaryDetails(this.taskId).subscribe((summDetails) => {
      this.taskName = summDetails.taskName;
      const srcTypeObj = Constants.CONNECTIONTYPES.find((sTypeObj) => {
        return sTypeObj.type === summDetails.sourceType;
      });
      this.sourceType =
        Utils.isNullOrUndefined(srcTypeObj) || summDetails.sourceType === Constants.SHOPIFY_CONNECTION_TYPE
          ? null
          : srcTypeObj.name;
      this.variantsNumber = Utils.isNullOrUndefined(summDetails.variantsCount)
        ? 'still running the process'
        : summDetails.variantsCount.toString();
      if (Utils.isNullOrUndefined(summDetails.schedulers)) {
        this.showDetails = true;
        return;
      }

      this.scheduleType = Utils.isNullOrUndefined(this.SCHEDULE_TYPES[summDetails.schedulers.type])
        ? null
        : this.SCHEDULE_TYPES[summDetails.schedulers.type];
      this.scheduleTimes = [];
      switch (summDetails.schedulers.type) {
        case SchedulerConstants.DAILY:
          for (const dayTime of summDetails.schedulers.dates) {
            this.scheduleTimes.push(
              new Date(dayTime).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })
            );
          }
          break;
        case SchedulerConstants.WEEKLY:
          for (const weekTime of summDetails.schedulers.dates) {
            this.scheduleTimes.push(
              this.translateService.instant(
                'TASKWIZARD.SUMMARY.' + this.WEEKLY_DISPLAYS[new Date(weekTime).getDay()].toUpperCase()
              )
            );
          }
          break;
        case SchedulerConstants.MONTHLY:
          for (const weekTime of summDetails.schedulers.dates) {
            const dayNum = new Date(weekTime).getDate();
            this.scheduleTimes.push(Utils.monthDayNumToString(dayNum));
          }
          break;
      }

      this.showDetails = true;
    });
    if (this.retailerSettingsVO.settings.sendMail) {
      this.selectedNotification = this.retailerSettingsVO.settings.sendMail;
    }
  }

  isStepValid(): boolean {
    return true;
  }

  getRetailerSettingsVO(): RetailerCatalogSettingsVO {
    this.retailerSettingsVO.settings = {
      ...this.retailerSettingsVO.settings,
      ...{ sendMail: this.selectedNotification },
    };

    return this.retailerSettingsVO;
  }

  saveStep(): Observable<any> {
    return this.catalogService.saveRetailerCatalogSettings(this.getRetailerSettingsVO());
  }
}
