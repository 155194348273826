<div fxLayout="column" fxLayoutAlign="center center" class="outer-container" [ngClass]="wrapperClass">
  <app-section-title [title]="titleKey | translate"></app-section-title>
  <div
    class="inner-container"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between stretch"
    fxLayoutAlign.lt-md="center center"
    fxLayoutGap="28px"
    [ngClass]="innerWrapperClass"
  >
    <div fxFlex="1 0 0">
      <app-faqs
        [faqs]="ltMd ? allQuestions : questionsLeft"
        [translateTitle]="translateTitle"
        [translateValue]="translateValue"
      ></app-faqs>
    </div>
    <div fxFlex="1 0 0" *ngIf="!ltMd">
      <app-faqs [faqs]="questionsRight" [translateTitle]="translateTitle" [translateValue]="translateValue"></app-faqs>
    </div>
  </div>
</div>
