export const productCardStatusConfigs: StatusConfigs = {
  'in-catalog': {
    borderColor: 'var(--app-light-green-700)',
    icon: 'check',
    iconStyle: {
      backgroundColor: 'var(--app-light-green-700)',
      color: 'white',
    },
    actionButtonStyle: {
      backgroundColor: 'var(--app-light-green-100)',
      color: 'var(--app-green-700)',
    },
  },
  'in-store': {
    borderColor: 'var(--app-amber-600)',
    icon: 'store',
    iconStyle: {
      backgroundColor: 'var(--app-amber-600)',
      color: 'white',
    },
    actionButtonStyle: {
      backgroundColor: 'var(--app-amber-100)',
      color: 'var(--app-amber-600',
    },
  },
  none: {
    borderColor: 'var(--app-syncee-grey-200)',
    actionButtonStyle: {
      backgroundColor: 'var(--app-syncee-primary-700)',
      color: 'white',
    },
  },
  selected: {
    borderColor: 'var(--app-syncee-primary-700)',
    icon: 'check',
    iconStyle: {
      backgroundColor: 'var(--app-syncee-primary-700)',
      color: 'white',
    },
    actionButtonStyle: {
      backgroundColor: 'var(--app-syncee-primary-50)',
      color: 'var(--app-syncee-primary-700)',
    },
  },
  'not-selected': {
    borderColor: 'var(--app-syncee-grey-200)',
    actionButtonStyle: {
      backgroundColor: 'var(--app-syncee-primary-700)',
      color: 'white',
    },
  },
  hidden: {
    borderColor: 'var(--app-syncee-grey-200)',
  },
};

export type ProductCardStatus = 'none' | 'in-catalog' | 'in-store';
export type ProductCardHiddenStatus = 'selected' | 'not-selected' | 'hidden';
export type ProductCardAllStatus = ProductCardStatus | ProductCardHiddenStatus;

type StatusConfigs = {
  [key in ProductCardAllStatus]: StatusConfig;
};

interface StatusConfig {
  icon?: string;
  borderColor?: string;
  iconStyle?: ButtonStyle;
  actionButtonStyle?: ButtonStyle;
}

interface ButtonStyle {
  backgroundColor: string;
  color: string;
}
