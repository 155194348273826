<app-dialog-multi-wrapper
  icon="info_i"
  [title]="'MY_SUPPLIERS.DIALOGS.ORDER_INFO.TITLE' | translate"
  [hideCloseButton]="!!data.isAutoOrder"
  [hideConfirmButton]="true"
  [buttonsAlignment]="!!data.isAutoOrder ? 'center' : 'end'"
  [customButtons]="!!data.isAutoOrder ? autoOrderButtons : null"
>
  <ng-container *ngIf="!!data.isAutoOrder; else nonAutoOrder">
    <div>
      {{ 'MY_SUPPLIERS.DIALOGS.ORDER_INFO.INFO.AUTO_ORDER' | translate }}
    </div>
  </ng-container>
</app-dialog-multi-wrapper>

<ng-template #nonAutoOrder>
  <ng-container *ngIf="!!data.hasAccessToContacts; else needSubscriptionBadge">
    <div
      [innerHTML]="
        'MY_SUPPLIERS.DIALOGS.ORDER_INFO.INFO.NON_AUTO_ORDER'
          | translate : { website: data.website | sanitizeUrl }
          | sanitizeHTML
      "
      style="text-align: center"
    ></div>
  </ng-container>
</ng-template>

<ng-template #autoOrderButtons>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div
      class="centered-text"
      [innerHTML]="'MY_SUPPLIERS.DIALOGS.ORDER_INFO.HELP_CENTER_LINK' | translate | sanitizeHTML"
    ></div>
    <button mat-flat-button color="primary" [mat-dialog-close]="null">
      {{ 'MY_SUPPLIERS.DIALOGS.CLOSE_BUTTON' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #needSubscriptionBadge>
  <app-need-subscription-badge></app-need-subscription-badge>
</ng-template>
