<ng-container *ngIf="isAdmin">
  <button mat-icon-button (click)="changeVisible()" type="button">
    <mat-icon class="cursor-pointer comment-icon s-20" [class.syncee-primary-700-fg]="!!myComment">
      sticky_note_2
    </mat-icon>
  </button>
  <textarea
    #textbox
    [(ngModel)]="myComment"
    class="comment-box"
    [hidden]="isHidden"
    (keypress)="saveComment($event, textbox.value)"
  ></textarea>
</ng-container>
