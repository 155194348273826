import { Component } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';

@Component({
  selector: 'app-or-divider',
  templateUrl: './or-divider.component.html',
  standalone: true,
  imports: [FlexLayoutModule, CustomDividerComponent],
})
export class OrDividerComponent {
  constructor() {}
}
