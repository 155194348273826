import { Component, Input, OnInit } from '@angular/core';
import { FooterGroup, FooterMenuItem } from '../footer.component';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'app/app.state';
import { Store } from '@ngrx/store';
import { getSelectedLanguageCode } from 'app/store/language/language.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-footer-group-items',
  templateUrl: './footer-group-items.component.html',
  styleUrls: ['./footer-group-items.component.scss'],
})
export class FooterGroupItemsComponent implements OnInit {
  @Input()
  group: FooterGroup;
  @Input()
  gap = '20px';
  currentLanguageCode: string;

  private unsubscribe: Subject<void>;

  constructor(private dialog: MatDialog, private store: Store<AppState>) {
    this.unsubscribe = new Subject<void>();
  }

  ngOnInit(): void {
    this.getLanguageCode();
  }

  openDialog(item: FooterMenuItem): void {
    this.dialog.open(item.component);
  }

  private getLanguageCode(): void {
    this.store
      .select(getSelectedLanguageCode)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.currentLanguageCode = res;
        } else {
          this.currentLanguageCode = 'en';
        }
      });
  }
}
