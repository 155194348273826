<button
  mat-flat-button
  class="filter-dropdown-btn"
  [ngClass]="{ 'active-filter': selectedChoice && selectedChoice.value !== null }"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="dropdownOpen = !dropdownOpen"
>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
    <span>
      {{
        selectedChoice && selectedChoice.value !== null
          ? ('INDEX_FILTER.ACTIVE_LABELS.PREFIX'
            | translate
              : {
                  type: (labelKey | translate),
                  value: findChoice(selectedChoice.value)?.translate
                    ? (selectedChoice.label | translate)
                    : selectedChoice.label
                })
          : (labelKey | translate)
      }}
    </span>
    <mat-icon class="s-20"> keyboard_arrow_down </mat-icon>
  </div>
</button>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  (backdropClick)="dropdownOpen = false"
>
  <app-index-table-filter-dropdown
    [choices]="choices"
    [radio]="radio"
    [hasSearch]="hasSearch"
    [fetcher]="fetcher"
    [size]="size"
    [choiceLabelProperty]="choiceLabelProperty"
    [selectedChoice]="selectedChoice?.value"
    (selectedChoiceChanged)="
      selectedChoiceChanged.emit({ value: $event.value, label: $event.label, type: type }); dropdownOpen = false
    "
  ></app-index-table-filter-dropdown>
</ng-template>
