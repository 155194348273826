<div fxLayout="column" fxLayoutGap="24px" class="w-100p">
  <div fxLayoutAlign="start center" fxLayoutGap="16px" *ngIf="!locked && !everyItemLocked">
    <app-filter-add-item-button (click)="addItemClick.emit()"></app-filter-add-item-button>
    <div *ngIf="filterItems.length > 1">
      <mat-radio-group (change)="conditionChange.emit($event.value)" [value]="condition">
        <mat-radio-button value="AND">{{ 'FILTER.FILTER_ITEM_LIST.ALL_CONDITIONS' | translate }}</mat-radio-button>
        <mat-radio-button value="OR">{{ 'FILTER.FILTER_ITEM_LIST.ANY_CONDITIONS' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="6px">
    <ng-container *ngFor="let item of filterItems">
      <app-filter-item
        [filterItem]="item"
        (deleteClicked)="deleteItemClicked.emit(item.id)"
        [filterStructures]="filterStructures"
        (needAutoComplete)="needAutoComplete.emit($event)"
        [autocompleteSuggestion]="autocompleteSuggestion"
        (needMoreAutocomplete)="needMoreAutocomplete.emit($event)"
        [hasNoMoreAutocomplete]="hasNoMoreAutocomplete"
        (filterItemChange)="filterItemChange.emit($event)"
        [filterItemError]="getFilterItemError(item.id)"
        (filterItemsErrorRevalidate)="filterItemsErrorRevalidate.emit()"
        [locked]="locked || item.locked"
        [useOffset]="useOffset"
      ></app-filter-item>
    </ng-container>
  </div>
</div>
