export const htmlLegendPlugin: any = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options): void {
    const legendContainerDiv = getOrCreateLegendContainer(chart, options.containerID);

    // Remove old legend items
    while (legendContainerDiv.firstChild) {
      legendContainerDiv.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    const styles = getComputedStyle(document.body);

    items.forEach((item, index) => {
      const li = document.createElement('li');

      li.style.display = 'flex';
      li.style.justifyContent = 'center';
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.flexDirection = 'row';
      li.setAttribute('class', 'typography-label');

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      switch (index) {
        case 0: {
          boxSpan.style.background = styles.getPropertyValue('--app-syncee-primary-50');
          boxSpan.style.color = styles.getPropertyValue('--app-syncee-primary-700');
          break;
        }
        case 1: {
          boxSpan.style.background = styles.getPropertyValue('--app-orange-100');
          boxSpan.style.color = styles.getPropertyValue('--app-orange-800');
          break;
        }
      }
      boxSpan.style.display = 'flex';
      boxSpan.style.flexDirection = 'row';
      boxSpan.style.justifyContent = 'center';
      boxSpan.style.alignItems = 'center';
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = 'auto';
      boxSpan.style.padding = '10px';
      boxSpan.style.borderRadius = '4px';
      boxSpan.style.whiteSpace = 'nowrap';
      const text = document.createTextNode(item.text);
      boxSpan.appendChild(text);
      boxSpan.style.textDecoration = item.hidden ? 'line-through' : '';

      li.appendChild(boxSpan);
      legendContainerDiv.appendChild(li);
    });
  },
};

export const getOrCreateLegendContainer = (chart, id) => {
  const legendContainer = document.getElementById(id);

  let listContainer = legendContainer.querySelector('div');

  if (!listContainer) {
    listContainer = document.createElement('div');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};
