<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div *ngIf="saveMappingWarning" class="notification warning">
    <div class="msg-container">
      <mat-icon class="type-icon">warning</mat-icon>
      <ng-container>
        {{ 'RETAILER_TASK_WIZARD.SHOPIFY.YOU_HAVE' | translate }}
      </ng-container>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
    <div class="fuse-card mapping-container" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" class="m-w-0">
      <form
        #productDetatailsForm="ngForm"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayoutGap="32px"
        fxFlex="1 0 auto"
        name="form"
      >
        <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BASIC' | translate }}</div>
        <div class="product-details" class="mb-0">
          <div
            *ngIf="mappingData.productIds"
            id="product-id-container"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-lg="0"
          >
            <div class="mr-0">
              <div
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'ID'"
                [adminCommentComments]="comments"
              ></div>
            </div>
            <app-droppable-input
              #normalProcess
              [required]="true"
              id="ID_{{ prod.id }}"
              name="productId_{{ prod.id }}"
              class="m-w-0"
              [(ngModel)]="prod.value.chips"
              [(operations)]="prod.value.operations"
              *ngFor="let prod of mappingData.productIds"
              fxFlex="{{ 100 / mappingData.productIds.length }}"
              fxFlex.lt-md="1 0 0"
              title="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_ID_PLACEHOLDER' | translate }} - {{ prod.placeholder }}"
              placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_ID_PLACEHOLDER' | translate }} {{
                prod.placeholder
              }}"
              [prodSampleInputVo]="prodSampleInputVo"
            >
            </app-droppable-input>
          </div>
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'TITLE'"
            [adminCommentComments]="comments"
            #normalProcess
            [required]="true"
            id="TITLE"
            title="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_NAME_PLACEHOLDER' | translate }}"
            name="TITLE"
            [(ngModel)]="mappingData.singleFields.TITLE.chips"
            [(operations)]="mappingData.singleFields.TITLE.operations"
            placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.PRODUCT_NAME_PLACEHOLDER' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
          <app-droppable-multiline-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'DESCRIPTION'"
            [adminCommentComments]="comments"
            id="DESCRIPTION"
            title="Description"
            [(chips)]="mappingData.singleFields.DESCRIPTION.chips"
            [(operations)]="mappingData.singleFields.DESCRIPTION.operations"
          >
          </app-droppable-multiline-input>
          <div id="product-images-container" fxLayout="column" fxLayoutAlign="end">
            <div
              id="product-images-info"
              class="product-imgs"
              fxLayout="row"
              fxLayout.lt-lg="column"
              fxLayoutAlign="start"
              fxLayoutGap="20px"
              fxLayoutGap.lt-lg="0"
              ngModelGroup="image"
              *ngFor="let img of mappingData.images; last as isLast; let i = index"
            >
              <div fxFlex="1 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'IMAGES'"
                  [adminCommentComments]="comments"
                  #imagesURLProcess
                  id="IMAGES"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_URL_PLACEHOLDER' | translate }}"
                  name="IMAGES-{{ i }}"
                  [(ngModel)]="img.url.chips"
                  [(operations)]="img.url.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_URL_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div fxFlex="1 0 0" class="m-w-0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <div fxFlex="1 0 0" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'IMAGES_ALTS'"
                    [adminCommentComments]="comments"
                    #imagesALTProcess
                    id="IMAGES_ALTS"
                    name="IMAGES_ALTS-{{ i }}"
                    [(ngModel)]="img.value.chips"
                    [(settings)]="img.value.settings"
                    [(operations)]="img.value.operations"
                    title="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_ALT_PLACEHOLDER' | translate }}"
                    placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.IMAGE_ALT_PLACEHOLDER' | translate }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  >
                  </app-droppable-input>
                </div>
                <div class="icon-holder" fxLayoutAlign="start center">
                  <button mat-icon-button *ngIf="i !== 0" (click)="mappingData.removeProdImage(i)">
                    <mat-icon class="s-20" aria-label="Delete icon"> delete </mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewProdImage()">
                    <mat-icon class="s-20" aria-label="new Image icon"> add_circle_outline </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <app-custom-divider [margin]="'32px'"></app-custom-divider>
          <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.ORGAN' | translate }}</div>
          <div id="product-categories" fxLayout="column" fxLayoutAlign="start stretch">
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'PRODUCT_TYPE'"
              [adminCommentComments]="comments"
              #normalProcess
              id="PRODUCT_TYPE"
              name="PRODUCT_TYPE"
              [(ngModel)]="mappingData.singleFields.PRODUCT_TYPE.chips"
              [(operations)]="mappingData.singleFields.PRODUCT_TYPE.operations"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRODUCT_TYPE_PLACE' | translate }}"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRODUCT_TYPE_PLACE' | translate }}"
              [prodSampleInputVo]="prodSampleInputVo"
            ></app-droppable-input>
            <div
              id="product-tags"
              fxLayout="row"
              fxLayoutAlign="start"
              fxLayoutGap="20px"
              *ngFor="let tag of mappingData.tags; last as isLast; let i = index; trackBy: trackByFn"
            >
              <div fxFlex="1 0 0" class="m-w-0">
                <app-droppable-input
                  adminComment
                  [adminCommentTaskId]="taskId"
                  [adminCommentTaskType]="'import'"
                  [adminCommentStep]="'mapping'"
                  [adminCommentMappingField]="'TAGS'"
                  [adminCommentComments]="comments"
                  #tagsProcess
                  id="TAGS"
                  title="{{ 'TASKWIZARD.ADDFILES.MAPPING.TAG_PLACEHOLDER' | translate }}"
                  name="TAG-{{ i }}"
                  [(ngModel)]="tag.chips"
                  [(operations)]="tag.operations"
                  placeholder="{{ 'TASKWIZARD.ADDFILES.MAPPING.TAG_PLACEHOLDER' | translate }}"
                  [prodSampleInputVo]="prodSampleInputVo"
                >
                </app-droppable-input>
              </div>
              <div class="icon-holder" fxLayoutAlign="start center">
                <button mat-icon-button *ngIf="mappingData.tags.length >= 2" (click)="mappingData.removeTag(i)">
                  <mat-icon class="s-20" aria-label="Delete icon"> delete </mat-icon>
                </button>
                <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewTag()">
                  <mat-icon class="s-20" aria-label="new Image icon"> add_circle_outline </mat-icon>
                </button>
              </div>
            </div>
            <div id="product-brands">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'BRAND'"
                [adminCommentComments]="comments"
                #normalProcess
                id="BRAND"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VENDOR_PLACE' | translate }}"
                name="BRAND"
                [(ngModel)]="mappingData.singleFields.BRAND.chips"
                [(operations)]="mappingData.singleFields.BRAND.operations"
                [style.width.%]="100"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VENDOR_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
            <div id="product-collections">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'COLLECTIONS'"
                [adminCommentComments]="comments"
                #normalProcess
                id="CATEGORY"
                title="Collection"
                name="CATEGORY"
                [(ngModel)]="mappingData.singleFields.CATEGORY.chips"
                [(operations)]="mappingData.singleFields.CATEGORY.operations"
                [style.width.%]="100"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COLLECTION_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
          </div>
          <app-custom-divider [margin]="'32px'"></app-custom-divider>
          <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT' | translate }}</div>
          <div id="product-variants-container" fxLayout="column" fxLayoutAlign="start">
            <div id="product-price-and-stuff-container">
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'PRICE'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    [required]="priceFieldIsRequired"
                    id="PRICE"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRICE_PLACE' | translate }}"
                    name="PRICE"
                    [isAlternativeFields]="isAdmin"
                    [isPriceSettings]="true"
                    [(ngModel)]="mappingData.singleFields.PRICE.chips"
                    [(settings)]="mappingData.singleFields.PRICE.settings"
                    [(operations)]="mappingData.singleFields.PRICE.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PRICE_PLACE' | translate }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  ></app-droppable-input>
                </div>
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'ORIGINAL_PRICE'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="ORIGINAL_PRICE"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COMPARE_PLACE' | translate }}"
                    name="ORIGINAL_PRICE"
                    [isAlternativeFields]="isAdmin"
                    [isPriceSettings]="true"
                    [(ngModel)]="mappingData.singleFields.ORIGINAL_PRICE.chips"
                    [(settings)]="mappingData.singleFields.ORIGINAL_PRICE.settings"
                    [(operations)]="mappingData.singleFields.ORIGINAL_PRICE.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COMPARE_PLACE' | translate }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  ></app-droppable-input>
                </div>
              </div>
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
                <!--Cost Per Item-->
                <!--                                <app-task-comment [taskId]="taskId" [taskType]="'import'" [step]="'mapping'"-->
                <!--                                                  [mappingField]="'DISCOUNTED_PRICE'" [comments]="comments">-->
                <!--                                </app-task-comment>-->
                <!--                                <app-droppable-input #normalProcess-->
                <!--                                                     id="DISCOUNTED_PRICE"-->
                <!--                                                     title="Discount Price"-->
                <!--                                                     name="DISCOUNTED_PRICE"-->
                <!--                                                     [isPriceSettings]="true"-->
                <!--                                                     [(ngModel)]="mappingData.singleFields.DISCOUNTED_PRICE.chips"-->
                <!--                                                     [(settings)]="mappingData.singleFields.DISCOUNTED_PRICE.settings"-->
                <!--                                                     [(operations)]="mappingData.singleFields.DISCOUNTED_PRICE.operations"-->
                <!--                                                     [style.width.%]="50"-->
                <!--                                                     placeholder="{{'RETAILER_TASK_WIZARD.SHOPIFY.DISCOUNT_PLACE' | translate}}"></app-droppable-input>-->
                <div fxFlex="1 0 0" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'COST_PER_ITEM'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="COST_PER_ITEM"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COST_PER_ITEM_PLACE' | translate }}"
                    name="COST_PER_ITEM"
                    [isPriceSettings]="true"
                    [(ngModel)]="mappingData.singleFields.COST_PER_ITEM.chips"
                    [(settings)]="mappingData.singleFields.COST_PER_ITEM.settings"
                    [(operations)]="mappingData.singleFields.COST_PER_ITEM.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.COST_PER_ITEM_PLACE' | translate }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  ></app-droppable-input>
                </div>
                <div fxFlex="1 0 0" fxHide.lt-lg></div>
              </div>
              <div
                id="product-basic-fields"
                fxLayout="row"
                fxLayout.lt-lg="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-lg="0"
              >
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'UPC'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="UPC"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BARCODE_PLACE' | translate }}"
                    name="UPC"
                    [(ngModel)]="mappingData.singleFields.UPC.chips"
                    [(operations)]="mappingData.singleFields.UPC.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.BARCODE_PLACE' | translate }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  ></app-droppable-input>
                </div>
                <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'WEIGHT'"
                    [adminCommentComments]="comments"
                    #normalProcess
                    id="WEIGHT"
                    title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                    name="WEIGHT"
                    [isWeightSettings]="true"
                    [(ngModel)]="mappingData.singleFields.WEIGHT.chips"
                    [(settings)]="mappingData.singleFields.WEIGHT.settings"
                    [(operations)]="mappingData.singleFields.WEIGHT.operations"
                    placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.WEIGHT_PLACE' | translate }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  ></app-droppable-input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0" class="mb-0">
          <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'V_IMAGES'"
              [adminCommentComments]="comments"
              #normalProcess
              id="V_IMAGES"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_IMAGE_URL_PLACE' | translate }}"
              name="V_IMAGES"
              [(ngModel)]="mappingData.singleFields.V_IMAGES.chips"
              [(operations)]="mappingData.singleFields.V_IMAGES.operations"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_IMAGE_URL_PLACE' | translate }}"
              [prodSampleInputVo]="prodSampleInputVo"
            ></app-droppable-input>
          </div>
          <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'V_IMAGES_ALTS'"
              [adminCommentComments]="comments"
              #normalProcess
              id="V_IMAGES_ALTS"
              name="V_IMAGES_ALTS"
              [(ngModel)]="mappingData.singleFields.V_IMAGES_ALTS.chips"
              [(operations)]="mappingData.singleFields.V_IMAGES_ALTS.operations"
              title="Variant Image Alt"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_IMAGE_ALT_PLACE' | translate }}"
              [prodSampleInputVo]="prodSampleInputVo"
            ></app-droppable-input>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0" class="mb-0">
          <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
            <app-droppable-input
              adminComment
              [adminCommentTaskId]="taskId"
              [adminCommentTaskType]="'import'"
              [adminCommentStep]="'mapping'"
              [adminCommentMappingField]="'SKU'"
              [adminCommentComments]="comments"
              #normalProcess
              id="SKU"
              title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_SKU_PLACE' | translate }}"
              name="SKU"
              [(ngModel)]="mappingData.singleFields.SKU.chips"
              [(operations)]="mappingData.singleFields.SKU.operations"
              placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.VARIANT_SKU_PLACE' | translate }}"
              [prodSampleInputVo]="prodSampleInputVo"
            ></app-droppable-input>
          </div>
          <div fxFlex="1 0 0" fxFlex.gt-md="50%" class="m-w-0">
            <div fxLayout="column">
              <div
                *ngFor="let qty of mappingData.quantities; let i = index; last as isLast"
                fxLayout="row"
                flex="1 0 0"
                fxLayoutGap="20px"
              >
                <div fxFlex="1 0 0">
                  <app-droppable-input
                    adminComment
                    [adminCommentTaskId]="taskId"
                    [adminCommentTaskType]="'import'"
                    [adminCommentStep]="'mapping'"
                    [adminCommentMappingField]="'QTY_' + (i + 1)"
                    [adminCommentComments]="comments"
                    #quantities
                    [id]="'QTY'"
                    title="{{
                      mappingData.quantities.length === 1
                        ? ('RETAILER_TASK_WIZARD.SHOPIFY.INVENTORY_QUANTITY_LOCATION_PLACE' | translate)
                        : ('RETAILER_TASK_WIZARD.SHOPIFY.INVENTORY_QUANTITY_LOCATION_PLACE' | translate) + ' ' + (i + 1)
                    }}"
                    name="QTY_{{ i + 1 }}"
                    [taskId]="taskId"
                    [isLocationSettings]="true"
                    [maxValues]="1"
                    [isAlternativeFields]="isAdmin"
                    [(ngModel)]="qty.chips"
                    [(operations)]="qty.operations"
                    [(settings)]="qty.settings"
                    placeholder="{{
                      mappingData.quantities.length === 1
                        ? ('RETAILER_TASK_WIZARD.SHOPIFY.INVENTORY_QUANTITY_LOCATION_PLACE' | translate)
                        : ('RETAILER_TASK_WIZARD.SHOPIFY.INVENTORY_QUANTITY_LOCATION_PLACE' | translate) + ' ' + (i + 1)
                    }}"
                    [prodSampleInputVo]="prodSampleInputVo"
                  ></app-droppable-input>
                </div>
                <div class="icon-holder" fxLayoutAlign="start center">
                  <button mat-icon-button *ngIf="mappingData.quantities.length > 1" (click)="mappingData.removeQty(i)">
                    <mat-icon class="s-20" aria-label="Delete icon"> delete </mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="isLast" (click)="mappingData.addNewQty()">
                    <mat-icon class="s-20" aria-label="new Image icon"> add_circle_outline </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="true">
          <app-droppable-input
            #normalProcess
            id="SHOPIFY_PUBLISHED"
            title="SHOPIFY_PUBLISHED"
            name="SHOPIFY_PUBLISHED"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_PUBLISHED.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_PUBLISHED.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SHOPIFY_PUBLISHED_PLACE' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          ></app-droppable-input>
        </div>

        <form #shopifyOptionsForm="ngForm" fxLayout="column" class="options-container" fxLayoutAlign="start">
          <div id="shopify-options-1" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
            <div fxFlex="3 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_1'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_NAME_1"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
                name="SHOPIFY_OPTION_NAME_1"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_1.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_1_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
            <div fxFlex="7 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_1'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_VALUE_1"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_1_PLACE' | translate }}"
                name="SHOPIFY_OPTION_VALUE_1"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_1.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_1_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
          </div>
          <div id="shopify-options-2" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
            <div fxFlex="3 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_2'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_NAME_2"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_2_PLACE' | translate }}"
                name="SHOPIFY_OPTION_NAME_2"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_2.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_2_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
            <div fxFlex="7 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_2'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_VALUE_2"
                title="OPTION VALUE 2"
                name="SHOPIFY_OPTION_VALUE_2"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_2.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_2_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
          </div>
          <div id="shopify-options-3" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
            <div fxFlex="3 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_NAME_3'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_NAME_3"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_3_PLACE' | translate }}"
                name="SHOPIFY_OPTION_NAME_3"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_NAME_3.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_NAME_3_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
            <div fxFlex="7 0 0" class="m-w-0">
              <app-droppable-input
                adminComment
                [adminCommentTaskId]="taskId"
                [adminCommentTaskType]="'import'"
                [adminCommentStep]="'mapping'"
                [adminCommentMappingField]="'SHOPIFY_OPTION_VALUE_3'"
                [adminCommentComments]="comments"
                #normalProcess
                id="SHOPIFY_OPTION_VALUE_3"
                title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_3_PLACE' | translate }}"
                name="SHOPIFY_OPTION_VALUE_3"
                [(ngModel)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.chips"
                [(operations)]="mappingData.singleFields.SHOPIFY_OPTION_VALUE_3.operations"
                placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.OPTION_VALUE_3_PLACE' | translate }}"
                [prodSampleInputVo]="prodSampleInputVo"
              ></app-droppable-input>
            </div>
          </div>
        </form>
        <app-custom-divider></app-custom-divider>
        <div class="h5 mb-20">{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SEO' | translate }}</div>
        <div class="mb-0" fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_HANDLE'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SHOPIFY_HANDLE"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.HANDLE_PLACE' | translate }}"
            name="SHOPIFY_HANDLE"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_HANDLE.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_HANDLE.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.HANDLE_PLACE' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          ></app-droppable-input>
        </div>
        <div class="mb-0" fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG'"
            [adminCommentComments]="comments"
            #normalProcess
            id="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PAGE_TITLE_PLACE' | translate }}"
            name="SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG"
            [(ngModel)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_METAFIELDS_GLOBAL_TITLE_TAG.operations"
            placeholder="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.PAGE_TITLE_PLACE' | translate }}"
            [prodSampleInputVo]="prodSampleInputVo"
          ></app-droppable-input>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-multiline-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'SHOPIFY_META_DESC'"
            [adminCommentComments]="comments"
            id="SHOPIFY_META_DESC"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.META_DESC_PLACE' | translate }}"
            name="SHOPIFY_META_DESC"
            [(chips)]="mappingData.singleFields.SHOPIFY_META_DESC.chips"
            [(operations)]="mappingData.singleFields.SHOPIFY_META_DESC.operations"
          >
          </app-droppable-multiline-input>
        </div>
        <app-custom-divider></app-custom-divider>
        <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.MAPPING.FILTERABLE_FIELDS' | translate }}</div>
        <div fxLayout="column" fxLayoutAlign="start stretch" class="mb-0">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'FILTERABLE_PRODUCT_DATA'"
            [adminCommentComments]="comments"
            #normalProcess
            id="FILTERABLE_PRODUCT_DATA"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.FILTERABLE_PRODUCT_DATA' | translate }}"
            name="FILTERABLE_PRODUCT_DATA"
            [isFilterSettings]="true"
            [(ngModel)]="mappingData.singleFields.FILTERABLE_PRODUCT_DATA.chips"
            [(operations)]="mappingData.singleFields.FILTERABLE_PRODUCT_DATA.operations"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <app-droppable-input
            adminComment
            [adminCommentTaskId]="taskId"
            [adminCommentTaskType]="'import'"
            [adminCommentStep]="'mapping'"
            [adminCommentMappingField]="'FILTERABLE_VARIANT_DATA'"
            [adminCommentComments]="comments"
            #normalProcess
            id="FILTERABLE_VARIANT_DATA"
            title="{{ 'RETAILER_TASK_WIZARD.SHOPIFY.FILTERABLE_VARIANT_DATA' | translate }}"
            name="FILTERABLE_VARIANT_DATA"
            [isFilterSettings]="true"
            [(ngModel)]="mappingData.singleFields.FILTERABLE_VARIANT_DATA.chips"
            [(operations)]="mappingData.singleFields.FILTERABLE_VARIANT_DATA.operations"
            [prodSampleInputVo]="prodSampleInputVo"
          >
          </app-droppable-input>
        </div>
      </form>
    </div>
  </div>
</div>
<button mat-button class="syncee-black-flat-button mt-16" (click)="takonySave()" *ngIf="isAdmin">
  <mat-icon class="type-icon">visibility_off</mat-icon>{{ 'RETAILER_TASK_WIZARD.SHOPIFY.SAVE' | translate }}
</button>
