import { Component, Input, OnInit } from '@angular/core';
import { SubmenuGroup } from './model/submenu-group';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent implements OnInit {
  @Input() group: SubmenuGroup;

  constructor() {}

  ngOnInit(): void {}
}
