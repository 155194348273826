import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '../../../@fuse/components';
import { AddTaskModalComponent } from './add-task-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductViewComponent } from './product-view/product-view.component';
import { RouterModule } from '@angular/router';
import { SourceChangerDialogComponent } from './source-changer-dialog/source-changer-dialog.component';
import { SourceSettingsTabComponent } from './source-changer-dialog/source-settings-tab/source-settings-tab.component';
import { ChangeFileComponent } from './source-changer-dialog/change-file/change-file.component';
import { ngfModule } from 'angular-file';
import { DraggableModule } from '../draggable/draggable.module';
import { TaskDetailsMobilepopupComponent } from './task-details-mobilepopup/task-details-mobilepopup.component';
import { DialogWithAnInputComponent } from '../../shared/components/dialogs/dialog-with-an-input/dialog-with-an-input.component';
import { CustomTableComponent } from '../../shared/components/custom-table/custom-table.component';
import { HistoryIndicatorComponent } from './history-indicator/history-indicator.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CustomDatePipe } from '../../shared/pipes/custom-date.pipe';
import { BadgeCustomComponent } from '../../shared/components/badge-custom/badge-custom.component';
import { TaskBadgesComponent } from './task-badges/task-badges.component';
import { TaskIsActiveColorPipe } from './pipes/task-is-active-color.pipe';
import { TaskIsSyncedColorPipe } from './pipes/task-is-synced-color.pipe';
import { TaskIsActiveBackgroundPipe } from './pipes/task-is-active-background.pipe';
import { TaskIsSyncedBackgroundPipe } from './pipes/task-is-synced-background.pipe';
import { TasksMobileListComponent } from './tasks-mobile-list/tasks-mobile-list.component';
import { CustomPaginationComponent } from '../../shared/components/custom-pagination/custom-pagination.component';
import { CustomDividerComponent } from '../../shared/components/custom-divider/custom-divider.component';
import { TasksMobileListItemComponent } from './tasks-mobile-list-item/tasks-mobile-list-item.component';
import { TasksMenuComponent } from './tasks-menu/tasks-menu.component';
import { AlertWithButtonComponent } from '../../shared/components/alert-with-button/alert-with-button.component';
import { NoSearchResultsComponent } from 'app/shared/components/no-search-results/no-search-results.component';
import { SupplierTaskProblemAlertComponent } from 'app/shared/components/supplier-task-problem-alert/supplier-task-problem-alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarChartComponent } from 'app/shared/components/progress-bar-chart/progress-bar-chart.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertComponent } from 'app/shared/components/alert/alert.component';
import { HistoryTimelineComponent } from 'app/shared/components/history-timeline/history-timeline.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    MatSortModule,
    MatCardModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatTabsModule,
    ngfModule,
    DraggableModule,
    DialogWithAnInputComponent,
    CustomTableComponent,
    OverlayModule,
    CustomDatePipe,
    BadgeCustomComponent,
    CustomPaginationComponent,
    CustomDividerComponent,
    AlertWithButtonComponent,
    NoSearchResultsComponent,
    SupplierTaskProblemAlertComponent,
    TranslateModule,
    ProgressBarChartComponent,
    MatTooltipModule,
    AlertComponent,
    HistoryTimelineComponent,
    MatDialogModule,
  ],
  declarations: [
    TasksComponent,
    AddTaskModalComponent,
    ProductViewComponent,
    SourceChangerDialogComponent,
    SourceSettingsTabComponent,
    ChangeFileComponent,
    TaskDetailsMobilepopupComponent,
    HistoryIndicatorComponent,
    TaskBadgesComponent,
    TaskIsActiveColorPipe,
    TaskIsActiveBackgroundPipe,
    TaskIsSyncedColorPipe,
    TaskIsSyncedBackgroundPipe,
    TasksMobileListComponent,
    TasksMobileListItemComponent,
    TasksMenuComponent,
  ],
})
export class TasksModule {}
