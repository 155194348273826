import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { IMPORT_LIST_PRICE_OPERATOR_TYPE } from 'app/shared/components/import-list-price-settings/types/operator.type';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { RolesEnum } from 'app/vo/roles/roles';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CatalogService } from '../../../../service/catalog/catalog.service';
import { PricingService } from '../../../../service/pricing/pricing.service';
import { TaskStepLockService } from '../../../../service/task/task-step-lock.service';
import { UserService } from '../../../../service/user/user.service';
import { CommentVO } from '../../../../shared/components/admin-comment/admin-comment.component';
import { USER_ROLES } from '../../../../utils/Constants';
import { StepBase } from '../../step-base';
import { DATAFEED_SOURCE_TYPES } from '../constants/datafeed-source-types';
import {
  RETAIL_DEFAULT_FIX_OPERATOR,
  RETAIL_DEFAULT_PERCENTAGE_OPERATOR,
  RETAIL_FIX_OPERATORS,
  RETAIL_PERCENTAGE_OPERATORS,
  WHOLESALE_DEFAULT_FIX_OPERATOR,
  WHOLESALE_DEFAULT_PERCENTAGE_OPERATOR,
  WHOLESALE_FIX_OPERATORS,
  WHOLESALE_PERCENTAGE_OPERATORS,
} from '../constants/supplier-pricing.constants';
import { PriceType } from '../enums/price-type.enum';
import { SourceType } from '../enums/source-type.enum';
import {
  SupplierCategoriesAndPricesMapped,
  SupplierTaskPriceSettingsService,
} from '../service/supplier-task-price-settings.service';
import { SupplierTaskPricingService } from '../service/supplier-task-pricing.service';
import { PermissionService } from '../../../../service/permission/permission.service';
import { SCOPES } from '../../../../service/permission/scopes';

@Component({
  selector: 'app-supplier-task-pricing',
  templateUrl: './supplier-task-pricing.component.html',
  styleUrls: ['./supplier-task-pricing.component.scss'],
  providers: [SupplierTaskPriceSettingsService, SupplierTaskPricingService],
})
export class SupplierTaskPricingComponent implements OnInit, StepBase, OnDestroy {
  @Input() catalogId: number;
  @Input() userId: number;
  @Input() isNewCatalog: boolean;
  @Input() comments: CommentVO[];
  @Input() sourceType: SourceType;
  isLocked: boolean;
  priceSettingsObservable: Observable<SupplierCategoriesAndPricesMapped>;
  roundingObservable$: Observable<string>;
  ecomCurrency = 'USD';
  private isAdmin$ = new BehaviorSubject<boolean>(false);
  private _unsubscribeAll: Subject<void>;

  public isDatafeed: boolean;

  priceType = PriceType.PRICE;
  savedPriceType = PriceType.PRICE;

  enabledPercentageOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = WHOLESALE_PERCENTAGE_OPERATORS;
  enabledFixOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = WHOLESALE_FIX_OPERATORS;
  defaultPercentageOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = WHOLESALE_DEFAULT_PERCENTAGE_OPERATOR;
  defaultFixOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = WHOLESALE_DEFAULT_FIX_OPERATOR;

  _priceTypeChangedSubject = new BehaviorSubject<void>(null);
  _priceTypeChangedObs$ = this._priceTypeChangedSubject.asObservable();

  constructor(
    private priceSettingsService: SupplierTaskPriceSettingsService,
    public taskPricingService: SupplierTaskPricingService,
    private pricingService: PricingService,
    private catalogService: CatalogService,
    private stepLockService: TaskStepLockService,
    private userService: UserService,
    private store: Store<AppState>,
    private permissionService: PermissionService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getEcomCurrency();
    this.getPriceType();
    this.priceSettingsObservable = this.getPricing();
    this.roundingObservable$ = this.getRounding();
    this.getIfStepIsLocked();
    this.setIsAdmin();
    this.initIsDatafeed();
  }

  getPriceType(): Observable<PriceType> {
    return this.catalogService.getSupplierCatalogById(this.catalogId).pipe(
      takeUntil(this._unsubscribeAll),
      map((catalog) => {
        return catalog.target;
      })
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private initIsDatafeed(): void {
    this.isDatafeed = DATAFEED_SOURCE_TYPES.includes(this.sourceType);
  }

  get isAdmin(): Observable<boolean> {
    return this.isAdmin$.asObservable();
  }

  private setIsAdmin(): void {
    this.isAdmin$.next(this.userService.getCachedUser().role === USER_ROLES.GLOBAL_ADMIN);
  }

  private getPricing(): Observable<SupplierCategoriesAndPricesMapped> {
    return this.priceSettingsService.getDataForPricingComponent(this.catalogId).pipe(
      switchMap((value) =>
        this.getPriceType().pipe(
          tap((type) => {
            if (type) {
              this.priceType = type;
            }
            this.savedPriceType = type;
            this.taskPricingService.handleChangePriceType(this.priceType);
          }),
          tap(() => {
            this.taskPricingService.initPriceSettingsFromData(value.pricing);
          }),
          map(() => value)
        )
      ),
      switchMap((value) => {
        return this._priceTypeChangedObs$.pipe(
          map(() => {
            return this.taskPricingService.setEnableAndDefaultOperators(value, this.priceType !== this.savedPriceType);
          })
        );
      }),
      takeUntil(this._unsubscribeAll)
    );
  }

  private getRounding(): Observable<string> {
    return this.pricingService.getRoundingByCatalogId(this.catalogId).pipe(
      map((response) => response.rounding ?? 'none'),
      tap((value) => this.taskPricingService.initRoundingRuleFromData(value))
    );
  }

  private savePriceSettings(): Observable<void> {
    return this.catalogService.updateCatalogPriceType(this.priceType, this.catalogId).pipe(
      switchMap(() => {
        return this.pricingService.updatePricing({
          catalog_id: this.catalogId,
          pricing: this.taskPricingService.priceSettingsUpdateVo,
        });
      })
    );
  }

  private saveRounding(): Observable<void> {
    return this.pricingService.updateRounding({ rounding: this.taskPricingService.rounding, id: this.catalogId });
  }

  private updatePricing(): Observable<void> {
    return this.savePriceSettings().pipe(concatMap(() => this.saveRounding()));
  }

  saveStep(): Observable<void> {
    if (this.isNewCatalog) {
      this.catalogService.saveNewCatalogNotification().subscribe();
    }

    return this.updatePricing();
  }

  private getIfStepIsLocked(): void {
    this.permissionService
      .hasPermissions(SCOPES.CATALOG_PRICING_LOCKED_EDIT)
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((hasPermission) =>
          hasPermission
            ? of(false)
            : this.stepLockService
                .getTaskStepsForTask(this.catalogId)
                .pipe(map((lockedSteps) => lockedSteps.includes('PRICING')))
        )
      )
      .subscribe((value) => (this.isLocked = value));
  }

  isStepValid(): boolean {
    return !this.taskPricingService.pricing.some((entry) =>
      entry.priceItemList.some((item) => {
        return item.from !== 0 && !item.from;
      })
    );
  }

  private getEcomCurrency(): void {
    this.store
      .select(getSelectedEcomByRole(RolesEnum.SUPPLIER))
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((asd) => console.warn(asd)),
        filter((data) => !!data)
      )
      .subscribe((res) => (this.ecomCurrency = res.options.currency ?? res.options.preferredCurrency ?? 'USD'));
  }

  handleChangePriceType(priceType: PriceType): void {
    this.priceType = priceType;
    this.taskPricingService.handleChangePriceType(priceType);
    this.taskPricingService.emptyPriceSettings();
    this.updatePricing()
      .pipe(tap(() => this._priceTypeChangedSubject.next()))
      .subscribe();
  }
}
