import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { suppliers_my_listing_navigation } from '../../../navigation/inner-navigation';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { RestService } from '../../../service/rest/rest.service';
import { phoneCodes, STATES } from '../../../utils/Constants';
import { Utils } from '../../../utils/utils';
import { NotificationService } from '../../notification/notification.service';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss'],
})
export class SupplierDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showSaveButton = true;
  @Input() userId;
  @Input() isGSStepper = false;
  @Output() closeDialog = new EventEmitter();

  private _unsubscribeAll: Subject<void>;

  readonly currentYear = new Date().getFullYear();

  constructor(
    private notificationService: NotificationService,
    private restService: RestService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private countriesManagerService: CountriesManagerService,
    private fuseConfigService: FuseConfigService
  ) {
    this._unsubscribeAll = new Subject();
  }

  @ViewChild('updateUserForm', { static: false }) updateUserForm: NgForm;
  public supplierProfileInfo: SupplierProfileInfoVO = new SupplierProfileInfoVO();

  disableSaveButton = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  supplierLogo: any;
  supplierCover: any;
  // shippingDetails = [];

  warehouseList = this.countriesManagerService.getOnlyCountries();
  states = STATES;
  phoneCodes = phoneCodes;
  public phoneCodeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public publicPhoneCodeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredPhoneCodes: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredPublicPhoneCodes: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredCountries: ReplaySubject<any> = new ReplaySubject<any>(1);

  isLodaing = true;
  isOnMobile;

  ngOnInit(): void {
    this.getUserDetails();

    this.filteredPhoneCodes.next(this.phoneCodes);
    this.phoneCodeFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.filterForPhone();
    });

    this.filteredPublicPhoneCodes.next(this.phoneCodes);
    this.publicPhoneCodeFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.filterForPublicPhone();
    });

    this.filteredCountries.next(this.warehouseList);
    this.countryFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.filterForCountry();
    });
    this.isOnMobile = window.screen.width <= 1050;
    if (!this.isGSStepper) {
      this.initInnerSidebar();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initInnerSidebar(): void {
    if (this.isOnMobile) {
      this.fuseConfigService.config = {
        layout: {
          submenu: {
            hidden: false,
            group: {
              items: suppliers_my_listing_navigation,
              title: 'NAV.SETTINGS',
              type: 'tab',
            },
          },
        },
      };
    } else {
      this.fuseConfigService.config = {
        layout: {
          submenu: {
            hidden: false,
            group: {
              items: suppliers_my_listing_navigation,
              title: 'NAV.SETTINGS',
              type: 'tab',
            },
          },
          toolbar: {
            hidden: false,
          },
        },
      };
    }
  }

  protected filterForPhone(): void {
    if (!this.phoneCodes) {
      return;
    }
    // get the search keyword
    let search = this.phoneCodeFilterCtrl.value;
    if (!search) {
      this.filteredPhoneCodes.next(this.phoneCodes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredPhoneCodes.next(
      this.phoneCodes.filter(
        (phone) =>
          phone.country.toLowerCase().indexOf(search) > -1 || phone.phoneCode.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filterForPublicPhone(): void {
    if (!this.phoneCodes) {
      return;
    }
    // get the search keyword
    let search = this.publicPhoneCodeFilterCtrl.value;
    if (!search) {
      this.filteredPublicPhoneCodes.next(this.phoneCodes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredPublicPhoneCodes.next(
      this.phoneCodes.filter(
        (phone) =>
          phone.country.toLowerCase().indexOf(search) > -1 || phone.phoneCode.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filterForCountry(): void {
    if (!this.warehouseList) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.warehouseList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredCountries.next(
      this.warehouseList.filter((country) => country.name.toLowerCase().indexOf(search) > -1)
    );
  }

  ngAfterViewInit(): void {}

  saveSupplierInfo(): void {
    if (this.isStepValid()) {
      this.sendSupplierApplication().subscribe(
        (response) => {
          this.closeDialog.emit(true);
        },
        (error) => console.log(error)
      );
    }
  }

  sendSupplierApplication(): Observable<any> {
    return new Observable<any>((observe) => {
      this.disableSaveButton = true;
      this.supplierProfileInfo.supplierLogo = this.supplierLogo;
      this.supplierProfileInfo.coverImage = this.supplierCover;
      this.supplierProfileInfo.ownInventory = this.supplierProfileInfo.ownInventory ? '1' : '0';
      // this.supplierProfileInfo.shippingDetails = JSON.stringify(this.supplierProfileInfo.shippingDetailsHandle);
      const formData = Utils.objectToFormData(this.supplierProfileInfo, new FormData());
      if (this.userId) {
        formData.append('userId', this.userId.toString());
      }

      this.restService.post('SupplierDetailsService/add', formData).subscribe(
        (res) => {
          this.disableSaveButton = false;
          this.supplierProfileInfo.ownInventory = this.supplierProfileInfo.ownInventory === '1';
          this.notificationService.success(this.translate.instant('SUPPLIER_DETAILS.SAVE_SUCCESS'));
          observe.next(true);
          observe.complete();
        },
        (error) => {
          this.disableSaveButton = false;
          this.supplierProfileInfo.ownInventory = this.supplierProfileInfo.ownInventory === '1';
          observe.error(error);
          observe.complete();
          console.log(error);
        }
      );
    });
  }

  getUserDetails(): void {
    this.restService
      .get(`SupplierDetailsService/getSupplierDetails${this.userId ? '?userId=' + this.userId : ''}`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.supplierProfileInfo = res.getFirstData() as SupplierProfileInfoVO;
          const event = new MatCheckboxChange();
          event.checked = this.supplierProfileInfo.worldwideShipping;
          this.isLodaing = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  setLogo(file): void {
    this.supplierLogo = file;
    console.log(this.supplierLogo);
  }

  setCover(file): void {
    this.supplierCover = file;
  }

  // FOR GETTING STARTED PAGE

  isStepValid(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.updateUserForm && !this.updateUserForm.invalid && !Utils.isNullOrUndefined(this.supplierLogo);
  }

  saveStep(): Observable<any> {
    return new Observable<any>((observe) => {
      this.sendSupplierApplication().subscribe(
        (res) => {
          observe.next({ saveStep: true });
          observe.complete();
        },
        (error) => {}
      );
    });
  }

  getCountryNames(codes): string[] {
    const countries = [];
    this.warehouseList.forEach((elem) => {
      if (codes.indexOf(elem.code) > -1) {
        countries.push(elem.name);
      }
      if (elem.code === 'US') {
        elem.children.forEach((item) => {
          if (codes.indexOf(item.code) > -1) {
            countries.push(item.name + ' (US)');
          }
        });
      }
    });
    return countries;
  }
}

export class CountriesSelectObject {
  defaultCountryList: string[] = [];
  countriesCtrl = new UntypedFormControl();
  filteredCountries: Observable<string[]>;
  filteredPopularCountries: Observable<string[]>;
  filteredShipsToCountries: Observable<string[]>;
  matAutocomplete: MatAutocomplete;
  matAutocompleteShipsTo: MatAutocomplete;
  selectedCountries: string[] = [];
  selectedShipsToCountries: string[] = [];
  countriesInput: ElementRef<HTMLInputElement>;
  shipsToCountriesInput: ElementRef<HTMLInputElement>;
  fromDay: number;
  toDay: number;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isValueSelected = true;
  onSelectedCountriesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  popularCountries = ['United States', 'United Kingdom', 'Canada', 'Germany', 'Australia', 'France'];

  constructor() {
    this.filteredCountries = this.countriesCtrl.valueChanges.pipe(
      startWith(null),
      map((country: string | null) => this.filterCountries(country))
    );
    this.filteredShipsToCountries = this.countriesCtrl.valueChanges.pipe(
      startWith(null),
      map((country: string | null) => this.filterShipsToCountries(country))
    );
    this.filteredPopularCountries = this.countriesCtrl.valueChanges.pipe(
      startWith(null),
      map((country: string | null) => this.filterPopularCountries(country))
    );
  }

  private filterCountries(country: string): any {
    return country
      ? this._filter(country)
      : this.defaultCountryList.filter((currentCountry) => !this.selectedCountries.includes(currentCountry)).slice();
  }

  private filterShipsToCountries(country: string): any {
    return country
      ? this._filter(country)
      : this.defaultCountryList
          .filter((currentCountry) => !this.selectedShipsToCountries.includes(currentCountry))
          .slice();
  }

  private filterPopularCountries(country: string): any {
    return country
      ? this._filter(country)
      : this.popularCountries.filter((currentCountry) => !this.selectedCountries.includes(currentCountry)).slice();
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      if (input) {
        input.value = '';
      }
      this.countriesCtrl.setValue(null);
    }
  }

  addShipsTo(event: MatChipInputEvent): void {
    if (!this.matAutocompleteShipsTo.isOpen) {
      const input = event.input;
      if (input) {
        input.value = '';
      }
      this.countriesCtrl.setValue(null);
    }
  }

  private openAutocompletePanel(trigger: MatAutocompleteTrigger): void {
    setTimeout(() => {
      trigger._onChange('');
      trigger.openPanel();
    }, 300);
  }

  remove(country: string, trigger: MatAutocompleteTrigger): void {
    const index = this.selectedCountries.indexOf(country);

    if (index >= 0) {
      this.selectedCountries.splice(index, 1);
    }
    if (this.selectedCountries.length === 0) {
      this.isValueSelected = true;
    }

    this.onSelectedCountriesChange.emit(this.selectedCountries);
    trigger._onChange('');
  }

  removeShipsTo(country: string, trigger: MatAutocompleteTrigger): void {
    const index = this.selectedShipsToCountries.indexOf(country);

    if (index >= 0) {
      this.selectedShipsToCountries.splice(index, 1);
    }
    if (this.selectedShipsToCountries.length === 0) {
      this.isValueSelected = true;
    }

    trigger._onChange('');
  }

  selected(event: MatAutocompleteSelectedEvent, trigger: MatAutocompleteTrigger): void {
    this.isValueSelected = false;
    this.selectedCountries.push(event.option.viewValue);
    this.countriesInput.nativeElement.value = '';
    this.countriesCtrl.setValue(null);
    this.onSelectedCountriesChange.emit(this.selectedCountries);
  }

  selectedShipsTo(event: MatAutocompleteSelectedEvent, trigger: MatAutocompleteTrigger): void {
    this.isValueSelected = false;
    this.selectedShipsToCountries.push(event.option.viewValue);
    this.shipsToCountriesInput.nativeElement.value = '';
    this.countriesCtrl.setValue(null);
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.defaultCountryList.filter((country) => country.toLowerCase().indexOf(filterValue) === 0);
  }
}

export class SupplierProfileInfoVO {
  userId: string;
  contactEmail: string;
  companyName: string;
  website: string;
  phoneCode: string;
  phoneNumber: string;
  discount: number;
  mainWarehouseLocation: string;
  description: string;
  worldwideShipping: boolean;
  ownInventory: boolean | string;
  yearOfFoundation: number;
  supplierLogo: any;
  logoLink: string;
  coverImage: any;
  coverLink: string;
  publicPhoneCode: string;
  publicPhoneNumber: string;
}
