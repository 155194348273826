import { Component, OnInit } from '@angular/core';
import { AiChatMessagesService } from '../../services/ai-chat-messages.service';
import { AiChatLimitationsService } from '../../services/ai-chat-limitations.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-ai-chat-bottom',
  templateUrl: './ai-chat-bottom.component.html',
  styleUrls: ['./ai-chat-bottom.component.scss'],
})
export class AiChatBottomComponent implements OnInit {
  message: string;
  limitReached: boolean;

  constructor(
    public aiChatMessagesService: AiChatMessagesService,
    private aiChatLimitationsService: AiChatLimitationsService
  ) {}

  ngOnInit(): void {
    this.subscribeToLimitReached();
  }

  sendMessage(): void {
    if (!this.aiChatMessagesService.waiting && !!this.message && this.message.length > 0) {
      this.aiChatMessagesService.pushCommand(this.message);
      this.message = null;
    }
  }

  private subscribeToLimitReached(): void {
    this.aiChatLimitationsService.limitReached
      .pipe(untilDestroyed(this))
      .subscribe((reached) => (this.limitReached = reached));
  }
}
