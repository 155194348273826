import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { ngfModule } from 'angular-file';
import { BreadcrumbNavigationComponent } from 'app/shared/components/breadcrumb-navigation/breadcrumb-navigation.component';
import { ErrorMessageComponent } from 'app/shared/components/error-message/error-message.component';
import { LoadingScreenComponent } from 'app/shared/components/loading-screen/loading-screen.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { ProductImageViewerComponent } from 'app/shared/components/product-image-viewer/product-image-viewer.component';
import { TaskCommentComponent } from 'app/shared/components/task-comment/task-comment.component';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { ConnectionStateService } from '../../../service/taskwizard/connection-state.service';
import { FileSettingsService } from '../../../service/taskwizard/file-settings.service';
import { AlertMultiComponent } from '../../../shared/components/alert-multi/alert-multi.component';
import { CustomDividerComponent } from '../../../shared/components/custom-divider/custom-divider.component';
import { CustomDialogWrapperComponent } from '../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { DeleteModalComponent } from '../../../shared/components/dialogs/delete-modal/delete-modal.component';
import { DragAndDropFileComponent } from '../../../shared/components/drag-and-drop-file/drag-and-drop-file.component';
import { SingleAlertWithIconComponent } from '../../../shared/components/single-alert-with-icon/single-alert-with-icon.component';
import { AdminCommentDirective } from '../../../shared/directives/admin-comment.directive';
import { ButtonWithIconDirective } from '../../../shared/directives/button-with-icon.directive';
import { ConditionLoadingDirective } from '../../../shared/directives/condition-loading.directive';
import { ShowOnBreakpointDirective } from '../../../shared/directives/show-on-breakpoint.directive';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { DraggableModule } from '../../draggable/draggable.module';
import { EcwidMappingBuilderComponent } from '../../ecwid-df-task-wizard/ecwid-mapping-builder/ecwid-mapping-builder.component';
import { JumpsellerMappingBuilderComponent } from '../../jumpseller-df-task-wizard/jumpseller-mapping-builder/jumpseller-mapping-builder.component';
import { RetailerJumpsellerVuBuilderComponent } from '../../retailer-jumpseller-vu-task-wizard/retailer-jumpseller-vu-builder/retailer-jumpseller-vu-builder.component';
import { ShopifyProductDetailsBuilderComponent } from '../../retailer-task-wizard/shopfiy-product-details-builder/shopify-product-details-builder.component';
import { ShopifyVariantsUpdateProductDetailsBuilderComponent } from '../../retailer-variants-update-task-wizard/shopify-variants-update-product-details-builder/shopify-variants-update-product-details-builder.component';
import { ShoprenterMappingBuilderComponent } from '../../shoprenter-df-task-wizard/shoprenter-mapping-builder/shoprenter-mapping-builder.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { AddfilesComponent } from './addfiles.component';
import { CsvSettingsComponent } from './file-settings/csv-settings.component';
import { ExcelSettingsComponent } from './file-settings/excel-settings.component';
import { ProductnodetreeComponent } from './file-settings/productnodetree.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FtpclientComponent } from './ftp/ftpclient.component';
import { PreviewDownloadedFileComponent } from './ftp/preview-downloaded-file.component';
import { ProdSampleDialogComponent } from './mapping/prod-sample-dialog/prod-sample-dialog.component';
import { ProdSampleListComponent } from './mapping/prod-sample-list.component';
import { DimensionFieldsComponent } from './mapping/productdetailsbuilder/dimension-fields/dimension-fields.component';
import { ProductdetailsbuilderComponent } from './mapping/productdetailsbuilder/productdetailsbuilder.component';
import { ChooseSettingsModalComponent } from './modals/choose-settings-modal.component';
import { ConnecttypepopupComponent } from './modals/connecttypepopup.component';
import { PreviewModalComponent } from './modals/preview-modal.component';
import { ShopifyValidationDirective } from './shopify/shopify-validation.directive';
import { ShopifyComponent } from './shopify/shopify.component';
import { FilemanagertabComponent } from './tabs/filemanagertab.component';
import { UrlSharedComponent } from './url-upload/url-shared.component';
import { UrlUploadComponent } from './url-upload/url-upload.component';
import { PimModule } from '../../pim/pim.module';
import { BadgeCustomComponent } from '../../../shared/components/badge-custom/badge-custom.component';
import { ConnectStoreComponent } from './connect-store/connect-store.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTabsModule,
    MatGridListModule,
    MatRippleModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    ngfModule,
    TranslateModule,
    MatExpansionModule,
    MatSlideToggleModule,
    DraggableModule,
    MatTableModule,
    MatChipsModule,
    MatAutocompleteModule,
    FuseSharedModule,
    MatProgressSpinnerModule,
    AutoSizeInputModule,
    DeleteModalComponent,
    CustomDatePipe,
    SingleAlertWithIconComponent,
    CustomDividerComponent,
    DragAndDropFileComponent,
    AlertMultiComponent,
    AdminCommentDirective,
    ButtonWithIconDirective,
    ConditionLoadingDirective,
    MatRadioModule,
    CustomDialogWrapperComponent,
    ShowOnBreakpointDirective,
    TaskCommentComponent,
    BreadcrumbNavigationComponent,
    ProductImageViewerComponent,
    LoadingScreenComponent,
    MatSortModule,
    LoadingSpinnerComponent,
    ErrorMessageComponent,
    PimModule,
    BadgeCustomComponent,
  ],
  providers: [FileSettingsService, ConnectionStateService],
  declarations: [
    AddfilesComponent,
    ConnecttypepopupComponent,
    FtpclientComponent,
    FilemanagertabComponent,
    FileUploadComponent,
    ProductnodetreeComponent,
    PreviewDownloadedFileComponent,
    CsvSettingsComponent,
    ExcelSettingsComponent,
    UrlUploadComponent,
    ChooseSettingsModalComponent,
    ProdSampleListComponent,
    ProductdetailsbuilderComponent,
    ShopifyComponent,
    ShopifyValidationDirective,
    UrlSharedComponent,
    ShopifyProductDetailsBuilderComponent,
    PreviewModalComponent,
    ScheduleComponent,
    ShopifyVariantsUpdateProductDetailsBuilderComponent,
    JumpsellerMappingBuilderComponent,
    ShoprenterMappingBuilderComponent,
    DimensionFieldsComponent,
    RetailerJumpsellerVuBuilderComponent,
    EcwidMappingBuilderComponent,
    ProdSampleDialogComponent,
    ConnectStoreComponent,
  ],
  exports: [AddfilesComponent, ShopifyComponent, ScheduleComponent, FileUploadComponent],
})
export class AddfilesModule {}
