import { Action } from '@ngrx/store';
import { Currencies, CurrencyExchange } from '../../service/currency-service/currency.service';
import { EcomVO } from '../../service/ecom/ecom.service';
import { RolesEnum } from '../../vo/roles/roles';

export enum CurrencyActionTypes {
  SET_CURRENCIES = '[Currency] Set currencies (based on USD)',
  SET_SELECTED_CURRENCY_START = '[Currency] Set selected currency start',
  FETCH_SELECTED_CURRENCIES_START = '[Currency] Fetch selected currencies start',
  FETCH_SELECTED_CURRENCIES_WITHOUT_CHAIN_START = '[Currency] Fetch selected currencies without chain start',
  FETCH_SELECTED_CURRENCIES_WITHOUT_CHAIN_SUCCESS = '[Currency] Fetch selected currencies without chain success',
  FETCH_SELECTED_CURRENCIES_SUCCESS = '[Currency] Fetch selected currencies success',
  SELECTED_ECOM_SET_SELECTED_CURRENCY_START = '[Currency] User set selected currency start',
  SELECTED_ECOM_SET_SELECTED_CURRENCY_SUCCESS = '[Currency] User set selected currency success',
  NO_ECOM_SET_SELECTED_CURRENCY_START = '[Currency] No Ecom set selected currency start',
  NO_ECOM_SET_SELECTED_CURRENCY_SUCCESS = '[Currency] No Ecom set selected currency success',
}

export interface FetchedSelectedCurrenciesPayload {
  selectedCurrency: string;
  currencies: CurrencyExchange[];
}

export interface CurrencyChangePayload {
  selectedCurrency: string;
  withNotification: boolean;
}

export class SetCurrenciesAction implements Action {
  readonly type = CurrencyActionTypes.SET_CURRENCIES;
  constructor(public payload: Currencies) {}
}

export class SetSelectedCurrencyStartAction implements Action {
  readonly type = CurrencyActionTypes.SET_SELECTED_CURRENCY_START;

  constructor(public payload: CurrencyChangePayload) {}
}

export class SelectedEcomSetSelectedCurrencyStartAction implements Action {
  readonly type = CurrencyActionTypes.SELECTED_ECOM_SET_SELECTED_CURRENCY_START;

  constructor(public payload: { ecom: EcomVO; role: RolesEnum } & CurrencyChangePayload) {}
}

export class SelectedEcomSetSelectedCurrencySuccessAction implements Action {
  readonly type = CurrencyActionTypes.SELECTED_ECOM_SET_SELECTED_CURRENCY_SUCCESS;

  constructor(public payload: CurrencyChangePayload) {}
}

export class NoEcomSetSelectedCurrencyStartAction implements Action {
  readonly type = CurrencyActionTypes.NO_ECOM_SET_SELECTED_CURRENCY_START;

  constructor(public payload: CurrencyChangePayload) {}
}

export class NoEcomSetSelectedCurrencySuccessAction implements Action {
  readonly type = CurrencyActionTypes.NO_ECOM_SET_SELECTED_CURRENCY_SUCCESS;

  constructor(public payload: CurrencyChangePayload) {}
}

export class FetchSelectedCurrenciesStart implements Action {
  readonly type = CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_START;

  constructor(public payload: CurrencyChangePayload) {}
}

export class FetchSelectedCurrenciesSuccess implements Action {
  readonly type = CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_SUCCESS;

  constructor(public payload: FetchedSelectedCurrenciesPayload) {}
}

export class FetchSelectedCurrenciesWithoutChainStart implements Action {
  readonly type = CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_WITHOUT_CHAIN_START;

  constructor(public payload: CurrencyChangePayload) {}
}

export class FetchSelectedCurrenciesWithoutChainSuccess implements Action {
  readonly type = CurrencyActionTypes.FETCH_SELECTED_CURRENCIES_WITHOUT_CHAIN_SUCCESS;

  constructor(public payload: FetchedSelectedCurrenciesPayload) {}
}

export type CurrencyActionsUnion =
  | SetCurrenciesAction
  | SetSelectedCurrencyStartAction
  | SelectedEcomSetSelectedCurrencyStartAction
  | SelectedEcomSetSelectedCurrencySuccessAction
  | NoEcomSetSelectedCurrencyStartAction
  | NoEcomSetSelectedCurrencySuccessAction
  | FetchSelectedCurrenciesSuccess
  | FetchSelectedCurrenciesWithoutChainStart
  | FetchSelectedCurrenciesWithoutChainSuccess;
