<div class="platform-dialog-container w-100p">
  <div class="platform-dialog-header w-100p">
    <div
      class="platform-header-row"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="24px"
      fxLayoutGap.lt-sm="12px"
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="center start"
    >
      <app-platform-list-logo
        [platform]="data.platformData.platformCardData"
        [size]="'68px'"
        [logoType]="'ICON'"
      ></app-platform-list-logo>
      <div class="platform-title">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px">
          <h4 class="margin-zero">
            {{ data.platformData.platformCardData | platformTypeToName : data.type }}
          </h4>
          <div class="typography-body-1">
            {{ data.platformData.platformCardData.description | translate }}
          </div>
        </div>
        <div class="badges">
          <div class="badge typography-body-small-1" platformBadge [name]="data.type">
            {{ 'BADGES.' + data.type | translate }}
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="platform-content">
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayout.lt-md="column">
      <div class="typography-body-1 platform-description">
        <div fxLayout="column" fxLayoutGap="12px" *ngIf="data.type === 'PLATFORM' && data.platformData.isInstalled">
          <h5 class="margin-zero">
            {{
              'GETTING_STARTED.ADD_YOUR_STORE.PLATFORM_DIALOG.CONNECTED_STORES'
                | translate : { store: data.platformData.platformCardData | platformTypeToName : data.type }
            }}
          </h5>
          <ul class="mb-0">
            <li *ngFor="let ecom of platformDomains | async" class="mb-8">
              <u class="typography-link">{{ ecom.domain }}</u>
            </li>
          </ul>
        </div>
        <app-custom-divider
          *ngIf="data.type === 'PLATFORM' && data.platformData.isInstalled"
          class="w-100p"
        ></app-custom-divider>
        <div [innerHTML]="data.platformData.platformCardData.descriptionLong | translate"></div>
        <a
          *ngIf="!!data.platformData.platformCardData.learnMoreUrlRetailer"
          href="{{ data.platformData.platformCardData.learnMoreUrlRetailer }}"
          target="_blank"
        >
          {{ 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORM_DIALOG.LEARN_MORE' | translate }}
        </a>
      </div>
      <app-custom-divider *ngIf="!(mdBreakpoint | async).matches" orientation="vertical"></app-custom-divider>
      <app-platform-dialog-actions
        *ngIf="data.type === 'PLATFORM'; else appActions"
        [platform]="data.platformData"
        [preSetRole]="data.role"
      ></app-platform-dialog-actions>
    </div>
  </mat-dialog-content>
</div>

<ng-template #appActions>
  <app-syncee-app-dialog-actions
    [platform]="data.platformData.platformCardData"
    [isInstalled]="data.platformData.isInstalled"
  ></app-syncee-app-dialog-actions>
</ng-template>
