<app-task-information-base-dialog
  [role]="role"
  [name]="data.supplierInfo.companyName"
  [userId]="data.supplierInfo.userId"
  [contactEmail]="data.supplierInfo.contactEmail"
  [taskName]="data.taskName"
  [status]="data.retailerToCatalog.approved"
  [rejectedNoteRef]="data.retailerToCatalog.rejectedReason ? rejectedNoteRef : null"
  [shippingTypeRef]="shippingTypeRef"
  [actionBarRef]="actionBar"
>
  <div class="field">
    <div class="field-key" [ngClass]="(isMobile$ | async) ? 'h5' : 'h6'">
      {{ 'MY_SUPPLIERS.CATALOG_INFO_DIALOG.EMAIL_LABEL' | translate }}
    </div>
    <div class="field-value">
      {{ data.supplierInfo.contactEmail }}
    </div>
  </div>
  <app-custom-divider></app-custom-divider>
  <div class="field">
    <div class="field-key" [ngClass]="(isMobile$ | async) ? 'h5' : 'h6'">
      {{ 'MY_SUPPLIERS.CATALOG_INFO_DIALOG.WEBSITE_LABEL' | translate }}
    </div>
    <div class="field-value">
      {{ data.supplierInfo.website }}
    </div>
  </div>
</app-task-information-base-dialog>

<ng-template #actionBar>
  <div class="w-100p" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="redirectToStoreFront()" mat-flat-button class="syncee-white-button">
      {{ 'MY_SUPPLIERS.CATALOG_INFO_DIALOG.SHOW_STOREFRONT' | translate }}
    </button>
    <!-- <button (click)="handleSaveClick()" mat-flat-button color="primary">
      {{ 'MY_SUPPLIERS.CATALOG_INFO_DIALOG.SAVE' | translate }}
    </button> -->
  </div>
</ng-template>

<ng-template #shippingTypeRef>
  <div class="field">
    <div class="field-key" [ngClass]="(isMobile$ | async) ? 'h5' : 'h6'">
      {{ 'MY_SUPPLIERS.CATALOG_INFO_DIALOG.SHIPPING_TYPE_LABEL' | translate }}
    </div>
    <div class="field-value">
      <mat-slide-toggle [(ngModel)]="dropship"></mat-slide-toggle>
    </div>
  </div>
  <app-custom-divider></app-custom-divider>
</ng-template>

<ng-template #rejectedNoteRef>
  <div class="field">
    <div class="field-key" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
      <div [ngClass]="(isMobile$ | async) ? 'h5' : 'h6'">
        {{ 'MY_SUPPLIERS.CATALOG_INFO_DIALOG.REJECTED_NOTE_LABEL' | translate }}
      </div>
      <mat-icon class="s-20" [matTooltip]="'MY_SUPPLIERS.CATALOG_INFO_DIALOG.REJECTED_TOOLTIP' | translate"
        >info</mat-icon
      >
    </div>
    <div class="field-value">{{ data.retailerToCatalog.rejectedReason }}</div>
  </div>
  <app-custom-divider></app-custom-divider>
</ng-template>
