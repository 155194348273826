import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../rest/rest.service';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { PricingVO } from '../../vo/pricing/pricing-vo';
import { PricingMainVO } from '../../vo/pricing/pricing-main-vo';
import { RoundingVO } from '../../vo/pricing/rounding-vo';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private readonly defaultPricingVO = {
    catalog_id: null,
    pricing: { '': { '0': { perc: '0', fix: '0' } } },
  };

  constructor(private restService: RestService) {}

  public getPrefixes(): any[] {
    return [
      { name: '+', value: '' },
      { name: '-', value: '-' },
    ];
  }

  public getCategoriesByCatalogId(taskId: number): Observable<any> {
    return this.restService
      .get(`ElasticsSearch/getCategoriesByTaskId?taskId=${taskId}`)
      .pipe(map((response) => response.getData()));
  }

  public getShopifyCollections(taskId: number): Observable<any> {
    return this.restService
      .get(`RetailerCatalogService/getCategoryMappingData?catalogId=${taskId}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getDefaultPricingVO(): Observable<PricingVO> {
    return new Observable((observer) => {
      observer.next(JSON.parse(JSON.stringify(this.defaultPricingVO)));
    });
  }

  public getRoundings(): PriceRounding[] {
    return [
      { name: 'None', value: 'none' },
      { name: 'xxx.00', value: '.00' },
      { name: 'xxx.95', value: '.95' },
      { name: 'xxx.99', value: '.99' },
      { name: 'xx9.00', value: 'xx9.0' },
      { name: 'xx990.00', value: '990' },
      { name: 'xx5.00', value: '5.00' },
      { name: 'xx0.00', value: '0.00' },
    ];
  }

  public addNewPricing(pricingMainVO: PricingMainVO, role?: 'SUPPLIER' | 'RETAILER'): Observable<any> {
    const restUrl = `PricingService/${this.isRetailer(role) ? 'saveRetailerPricing' : 'saveTaskPricing'}`;
    return this.restService.post(restUrl, pricingMainVO).pipe(map((response) => response.getData()));
  }

  public updatePricing(pricingMainVO: PricingMainVO, role?: 'SUPPLIER' | 'RETAILER'): Observable<any> {
    const restUrl = `PricingService/${this.isRetailer(role) ? 'updateRetailerPricing' : 'updateTaskPricing'}`;
    return this.restService.post(restUrl, pricingMainVO).pipe(map((response) => response.getData()));
  }

  public getPricing(catalogId: number, userId?: number, role?: 'SUPPLIER' | 'RETAILER'): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set('catalog_id', catalogId.toString());

    if (!Utils.isNullOrUndefined(userId) && !isNaN(userId)) {
      httpParams = httpParams.set('user_id', userId.toString());
    }

    const restUrl = `PricingService/${this.isRetailer(role) ? 'getRetailerPricingNew' : 'getTaskPricingNew'}`;

    return this.restService.get(restUrl, httpParams).pipe(
      map((response) => {
        const parsedResponse = response.getFirstData();
        parsedResponse.hasPricing = !Utils.isNullOrUndefined(parsedResponse.pricing);
        return !Utils.isNullOrUndefined(parsedResponse.pricing)
          ? parsedResponse
          : JSON.parse(JSON.stringify(this.defaultPricingVO));
      })
    );
  }

  public getRetailerPricing(catalogId: number): Observable<PricingRow> {
    const restUrl = `PricingService/getRetailerPricingNew`;

    return this.restService.get(restUrl, { catalog_id: catalogId }).pipe(
      map((response) => {
        const parsedResponse = response.getFirstData();

        parsedResponse.hasPricing = !Utils.isNullOrUndefined(parsedResponse.pricing);
        return !Utils.isNullOrUndefined(parsedResponse.pricing)
          ? parsedResponse.pricing
          : JSON.parse(JSON.stringify(this.defaultPricingVO.pricing));
      })
    );
  }

  public getDefaultExample(currency = 'USD'): string {
    return `For example a(n) 1.24 ${currency} product's price will be 1.24 ${currency}`;
  }

  public addNewRounding(roundingVO: RoundingVO, role?: 'SUPPLIER' | 'RETAILER'): Observable<any> {
    const restUrl = `PricingService/${this.isRetailer(role) ? 'saveRetailerRounding' : 'saveRounding'}`;
    return this.restService.post(restUrl, roundingVO).pipe(map((response) => response.getData()));
  }

  public updateRounding(roundingVO: RoundingVO, role?: 'SUPPLIER' | 'RETAILER'): Observable<any> {
    const restUrl = `PricingService/${this.isRetailer(role) ? 'updateRetailerRounding' : 'updateRounding'}`;
    return this.restService.post(restUrl, roundingVO).pipe(map((response) => response.getData()));
  }

  public getRoundingByCatalogId(catalogId: number, role?: 'SUPPLIER' | 'RETAILER'): Observable<RoundingResponse> {
    const restUrl = `PricingService/${this.isRetailer(role) ? 'getRetailerRounding' : 'getRounding'}?id=${catalogId}`;
    return this.restService.get(restUrl).pipe(map((response) => response.getFirstData()));
  }

  public getRoundingForAgency(catalogId: number, userId: number): Observable<any> {
    return this.restService
      .get(`PricingService/getRoundingForUser?id=${catalogId}&user_id=${userId}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public addNewRoundingForAgency(roundingVO: RoundingVO): Observable<any> {
    return this.restService
      .post('PricingService/saveRoundingForUser', roundingVO)
      .pipe(map((response) => response.getData()));
  }

  public updateRoundingForAgency(roundingVO: RoundingVO): Observable<any> {
    return this.restService
      .post('PricingService/updateRoundingForUser', roundingVO)
      .pipe(map((response) => response.getData()));
  }

  private isRetailer(role: string): boolean {
    return role === 'RETAILER';
  }
}

export interface PricingRow {
  [key: string]: PricingRange;
}

export interface PricingRange {
  perc: number;
  fix: number;
}

export interface PriceRounding {
  name: string;
  value: string;
}

export interface RoundingResponse {
  rounding: string;
}

export interface PricingResponse {
  catalog_id: string;
  pricing: Record<string, PricingPerRanges>;
  hasPricing?: boolean;
}

export type PricingPerRanges = Record<string, PricingValues>;

export type PriceSettingsUpdateVo = Record<string, PricingPerRanges>;

export interface PricingValues {
  fix: number;
  perc: number;
}
