import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ProductSearchWithAutocompleteMobileDialogComponent,
  ProductSearchWithAutocompleteMobileDialogData,
} from '../product-search-with-autocomplete-mobile-dialog/product-search-with-autocomplete-mobile-dialog.component';
import { productSearchWithAutocompletePlaceholderConfig } from '../product-search-with-autocomplete/product-search-with-autocomplete-placeholder.config';
import { ColorPaletteService } from '../../../../../service/color-palette/color-palette.service';

@Component({
  selector: 'app-product-search-with-autocomplete-mobile-indicator',
  templateUrl: './product-search-with-autocomplete-mobile-indicator.component.html',
  styleUrls: ['./product-search-with-autocomplete-mobile-indicator.component.scss'],
})
export class ProductSearchWithAutocompleteMobileIndicatorComponent implements OnInit {
  @Input() needRedirect = true;
  backgroundColor: string;
  protected readonly productSearchWithAutocompletePlaceholderConfig = productSearchWithAutocompletePlaceholderConfig;

  constructor(private dialog: MatDialog, private colorPaletteService: ColorPaletteService) {
    this.backgroundColor = this.colorPaletteService.getColorVariable('--app-syncee-grey-100');
  }

  ngOnInit(): void {}

  openDialog(): void {
    this.dialog.open<ProductSearchWithAutocompleteMobileDialogComponent, ProductSearchWithAutocompleteMobileDialogData>(
      ProductSearchWithAutocompleteMobileDialogComponent,
      {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        autoFocus: false,
        data: {
          needRedirect: this.needRedirect,
        },
      }
    );
  }
}
