import { Component } from '@angular/core';

import { BreakPoint } from '../../../../service/screen-manager/screen-manager.service';

@Component({
  selector: 'app-skeleton-supplier-slider-box',
  templateUrl: './skeleton-supplier-slider-box.component.html',
  styleUrls: ['./skeleton-supplier-slider-box.component.scss'],
})
export class SkeletonSupplierSliderBoxComponent {
  public static CONFIG: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: BreakPoint.xl,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: BreakPoint.lg,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    swipe: false,
    draggable: false,
  };

  protected SkeletonSupplierSliderBoxComponent = SkeletonSupplierSliderBoxComponent;
}
