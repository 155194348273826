import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-search-history-chip-list',
  standalone: true,
  imports: [CommonModule, FlexModule, MatChipsModule, MatIconModule, TranslateModule],
  templateUrl: './search-history-chip-list.component.html',
  styleUrls: ['./search-history-chip-list.component.scss'],
})
export class SearchHistoryChipListComponent implements OnInit {
  @Input() searchTerms: string[] = [];
  @Output() chipClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
