import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectYourStoreInstallDialogComponent } from '../connect-your-store-install-dialog/connect-your-store-install-dialog.component';
import { ConnectStorePlatform } from '../connect-your-store.component';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../service/user/user.service';
import { getPlatformByType } from '../../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../../vo/enums/ecom-type-enum';

@Component({
  selector: 'app-connect-your-store-item',
  templateUrl: './connect-your-store-item.component.html',
  styleUrls: ['./connect-your-store-item.component.scss'],
})
export class ConnectYourStoreComponentItemComponent {
  @Input() public data: ConnectStorePlatform;
  @Input() public role: number;
  @Input() public variant: 'small' | 'default' = 'default';

  constructor(@Inject(DOCUMENT) private document: any, private dialog: MatDialog, private userService: UserService) {}

  connectStore(): void {
    if (this.data.needDialogForInstall) {
      this.dialog.open(ConnectYourStoreInstallDialogComponent, {
        width: '52vw',
        data: {
          ecomType: this.data.ecomType,
          role: this.role,
        },
      });
    } else {
      this.redirectToPlatform();
    }
  }

  public redirectToPlatform(): void {
    switch (this.data.ecomType) {
      case 'shopify': {
        this.installShopify();
        break;
      }
      case 'wix': {
        this.installWix();
        break;
      }
      case 'bigcommerce': {
        this.installBigcommerce();
        break;
      }
      case 'ecwid': {
        this.installEcwid();
        break;
      }
      case 'kmoshops': {
        this.installKmoshops();
        break;
      }
      case 'unas': {
        this.installUnas();
        break;
      }
      case 'ekm': {
        this.installEkm();
        break;
      }
      case 'zyro': {
        this.installZyro();
        break;
      }
    }
  }

  inBeta(ecomType): boolean {
    return getPlatformByType(ecomType as EcomTypeEnum).options.beta;
  }

  installEcwid(): void {
    const url = 'https://my.ecwid.com/cp/#apps:view=app&name=';
    this.document.location.href = url + (this.role === 1 ? 'syncee-suppliers' : 'syncee');
  }

  installKmoshops(): void {
    this.document.location.href = 'https://my.kmoshops.be/cp/#app:name=pakket';
  }

  installBigcommerce(): void {
    const url = 'https://login.bigcommerce.com/deep-links/marketplace/apps/';
    this.document.location.href = url + (this.role === 1 ? '23617' : '19571');
  }

  installWix(): void {
    const url = 'https://www.wix.com/app-market/';
    this.document.location.href = url + (this.role === 1 ? 'syncee-for-suppliers' : 'syncee-global-dropshipping');
  }

  installUnas(): void {
    const callbackUrl = `${environment.installerURL}/api/unas_supplier_auth/authUser`;
    this.document.location.href = callbackUrl + '?user_id=' + this.userService.getCachedUser().id;
  }

  installEkm(): void {
    this.document.location.href = `https://api.ekm.net/connect/authorize?client_id=0bbfaa41-f9cc-4d04-b119-7ad651a21740&scope=openid profile tempest.customers.read tempest.orders.read tempest.orders.write tempest.products.read tempest.products.write tempest.categories.read tempest.settings.orderstatuses.read tempest.settings.domains.read tempest.settings.accountdetails.read tempest.settings.tax.read tempest.settings.currency.read offline_access&redirect_uri=https://installer.syncee.co/ekm_auth/callback&prompt=login&response_type=code`;
  }

  installShopify(): void {
    const url = 'https://apps.shopify.com/';
    this.document.location.href = url + (this.role === 1 ? 'syncee-for-suppliers' : 'syncee-1');
  }

  installZyro(): void {
    this.document.location.href = 'https://help.syncee.co/en/articles/6248927-how-to-install-syncee-to-your-zyro-store';
  }
}
