import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm, NgModel } from '@angular/forms';
import { SettingsInterface } from './settings-interface';
import { CsvSettingsInputVo } from '../vo/csv-settings-input-vo';
import { ReplaySubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Constants, CSV_SETTINGS } from '../../../../utils/Constants';
import { SettingsOutputVo } from '../vo/settings-output.vo';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-csv-settings',
  templateUrl: './csv-settings.component.html',
  styleUrls: ['./csv-settings.component.scss'],
})
export class CsvSettingsComponent implements OnInit, SettingsInterface {
  private _inputSync: ReplaySubject<CsvSettingsInputVo> = new ReplaySubject<CsvSettingsInputVo>(1);

  @Input() set inputs(value: CsvSettingsInputVo) {
    this._inputSync.next(value);
  }

  @ViewChild('form', { static: true }) settingsForm: NgForm;
  @ViewChild('encoding', { static: true }) encoding: NgModel;

  taskId: number;
  fileId: number;

  isFirstRowHeader: boolean;
  startRowNum: number;
  selDelimiter: string;
  selEnclosure: string;
  selEndOfLine: string;
  fileType: string;
  encodingSelect: string;
  characterEncodings: any[] = CSV_SETTINGS.characterEncodings;
  delimiters: any[] = CSV_SETTINGS.delimiters;

  enclosures: any[] = CSV_SETTINGS.enclosures;

  endoflines: any[] = CSV_SETTINGS.endoflines;

  private _isParamPassed = false;

  constructor() {}

  ngOnInit(): void {
    this._inputSync.pipe(takeWhile(() => !this._isParamPassed)).subscribe((res: CsvSettingsInputVo) => {
      this.fileType = res.fileType;
      this.isFirstRowHeader = !Utils.isNullOrUndefined(res.firstRowIsHeader) ? res.firstRowIsHeader : true;
      this.selDelimiter = !Utils.isNullOrUndefined(res.delimiter) ? res.delimiter : 'auto';
      this.selEnclosure = !Utils.isNullOrUndefined(res.enclosure) ? res.enclosure : 'auto';
      this.selEndOfLine = !Utils.isNullOrUndefined(res.endofline) ? res.endofline : 'auto';
      this.startRowNum = !Utils.isNullOrUndefined(res.startRowNum) ? res.startRowNum : 1;
      this._isParamPassed = true;
      this.encodingSelect = res.encoding;
    });
  }

  public getSettings(): SettingsOutputVo {
    Utils.markFormGroupTouched(this.settingsForm.control);
    if (!this.settingsForm.valid) {
      return null;
    }
    const settings = {
      firstRowIsHeader: this.isFirstRowHeader,
      delimiter: this.selDelimiter,
      enclosure: this.selEnclosure,
      eol: this.selEndOfLine,
      startRowNum: this.startRowNum,
      encoding: this.encodingSelect,
    };
    return new SettingsOutputVo(this.fileType, settings);
  }

  getSupportedFileTypes(): string[] {
    return [Constants.EXTENSION_CSV];
  }

  refresh(fileType: string): void {}

  update(): void {}
}
