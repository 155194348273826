<!-- TODO: Refactor component when there is sufficient time-->
<div>
  <h2 class="centered-text" mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content>
    <ng-container [ngSwitch]="type.toLowerCase()">
      <ng-container *ngSwitchCase="'nummuspay'">
        <!-- NUMMUSPAY -->
        <h3>{{ message }}</h3>
        <form [formGroup]="cardForm" class="w-100p" fxLayout="column" fxLayoutAlign="start">
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" class="w-100p">
            <mat-form-field appearance="fill" fxFlex="50" class="w-100p">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.FIRST_NAME' | translate }}</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="cardForm.invalid">{{
                'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.ERROR_MESSAGES.FIRST_NAME' | translate
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50" class="w-100p">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.LAST_NAME' | translate }}</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="cardForm.invalid">{{
                'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.ERROR_MESSAGES.LAST_NAME' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100" class="w-100p">
            <mat-form-field appearance="fill" class="w-100p">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.CARD_NUMBER' | translate }}</mat-label>
              <input matInput formControlName="number" type="number" />
              <mat-error *ngIf="cardForm.invalid">{{
                'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.ERROR_MESSAGES.CARD_NUMBER' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" class="w-100p">
            <mat-form-field appearance="fill" fxFlex="33" class="w-100p">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.MONTH' | translate }}</mat-label>
              <input matInput formControlName="month" type="number" [maxLength]="2" />
              <mat-error *ngIf="cardForm.invalid">{{
                'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.ERROR_MESSAGES.MONTH' | translate
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33" class="w-100p">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.YEAR' | translate }}</mat-label>
              <input matInput formControlName="year" type="number" [maxLength]="4" />
              <mat-error *ngIf="cardForm.invalid">Year is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33" class="w-100p">
              <mat-label>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.CVV' | translate }}</mat-label>
              <input matInput formControlName="cvv" type="number" [maxLength]="3" />
              <mat-error *ngIf="cardForm.invalid">{{
                'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.ERROR_MESSAGES.CVV' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </form>
        <div *ngIf="billingInfo">
          <div>
            {{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.BILLED_TO' | translate }}: <br />{{
              billingInfo.firstName
            }}
            {{ billingInfo.lastName }} <br />{{ billingInfo.address1 }} {{ billingInfo.address2 }},
            {{ billingInfo.city }} <br />{{ billingInfo.zip }} <br />{{ billingInfo.country }}
            <ng-container *ngIf="billingInfo.companyName"><br />{{ billingInfo.companyName }}</ng-container>
            <ng-container *ngIf="billingInfo.euVat"
              ><br />{{ billingInfo.country }}-{{ billingInfo.euVat }}</ng-container
            >
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'braintree'">
        <div id="dropin-container"></div>
        <!--                <button id="submit-button">Purchase</button>-->
        <!--                <form id="my-sample-form" class="scale-down" [ngClass]="formClass">-->
        <!--                    <div class="cardinfo-card-number">-->
        <!--                        <label class="cardinfo-label" for="card-number">{{ 'BILLING.SUBSCRIPTION.BRAINTREE_CARD_INFORMATION.CARD_NUMBER' | translate}}</label>-->
        <!--                        <div class='input-wrapper' id="card-number"></div>-->
        <!--                        <div id="card-image" [ngClass]="cardImageClass"></div>-->
        <!--                    </div>-->

        <!--                    <div class="cardinfo-cardholder-name">-->
        <!--                        <label class="cardinfo-label" for="cardholderName">{{ 'BILLING.SUBSCRIPTION.BRAINTREE_CARD_INFORMATION.NAME' | translate}}</label>-->
        <!--                        <div class='input-wrapper' id="cardholderName">-->
        <!--                            <input type="text" class="name-custom-input" [(ngModel)]="cardholdersName" name="cardholderName" (ngModelChange)="nameChanged($event)"/>-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                    <div class="cardinfo-wrapper">-->
        <!--                        <div class="cardinfo-exp-date">-->
        <!--                            <label class="cardinfo-label" for="expiration-date">{{ 'BILLING.SUBSCRIPTION.BRAINTREE_CARD_INFORMATION.DATE' | translate}}</label>-->
        <!--                            <div class='input-wrapper' id="expiration-date"></div>-->
        <!--                        </div>-->

        <!--                        <div class="cardinfo-cvv">-->
        <!--                            <label class="cardinfo-label" for="cvv">{{ 'BILLING.SUBSCRIPTION.BRAINTREE_CARD_INFORMATION.CVV' | translate}}</label>-->
        <!--                            <div class='input-wrapper' id="cvv"></div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </form>-->
        <!--                <div>-->
        <!--                    {{alma}}-->
        <!--                </div>-->
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="space-between center" class="pt-8">
    <div>
      <button mat-button mat-dialog-close>Close</button>
    </div>
    <ng-container [ngSwitch]="type | lowercase">
      <ng-container *ngSwitchCase="'nummuspay'">
        <button
          *ngIf="!loading; else loadingTemp"
          mat-button
          class="syncee-blue-button"
          (click)="send()"
          [disabled]="cardForm.invalid"
        >
          {{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.SAVE' | translate }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'braintree'">
        <button mat-button class="syncee-blue-button" id="submit-button">
          {{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.SAVE' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </mat-dialog-actions>
</div>

<ng-template #loadingTemp>
  <div>{{ 'BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.LOADING' | translate }}</div>
</ng-template>
