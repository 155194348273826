import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileSelectComponent } from '../../tile-select/tile-select.component';
import { TileSelectOption } from '../../tile-select/model/tile-select-option';
import { EcomTypeEnum } from '../../../../vo/enums/ecom-type-enum';
import { RolesEnum } from '../../../../vo/roles/roles';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getPlatformByType, PLATFORM } from '../../../../utils/platforms/platform-config';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDialogWrapperComponent } from '../custom-dialog-wrapper/custom-dialog-wrapper.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import {
  PlatformDialogComponent,
  PlatformDialogData,
} from '../../../../main/users/platform-list/platform-dialog/platform-dialog.component';

@Component({
  selector: 'app-need-store-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TileSelectComponent,
    TranslateModule,
    CustomDialogWrapperComponent,
    MatButtonModule,
    FlexModule,
  ],
  templateUrl: './need-store-dialog.component.html',
  styleUrls: ['./need-store-dialog.component.scss'],
})
export class NeedStoreDialogComponent implements OnInit {
  options: TileSelectOption<EcomTypeEnum>[];
  selectedEcom: EcomTypeEnum;

  private PLATFORM_CONFIG_LIST = PLATFORM;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: NeedStoreDialogComponentData,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<NeedStoreDialogComponent>
  ) {}

  ngOnInit(): void {
    this.options = this.getPlatformOptions();
  }
  public getPlatformOptions(): TileSelectOption<EcomTypeEnum>[] {
    return this.PLATFORM_CONFIG_LIST.filter(({ options }) => {
      return this.data.role === RolesEnum.RETAILER ? options.hasRetailerApp : options.hasSupplierApp;
    }).map(({ key, name, transparentIconSrc }) => {
      return {
        value: key,
        title: name,
        src: transparentIconSrc,
      };
    });
  }

  handleContinue(): void {
    if (this.selectedEcom) {
      this.dialog
        .open<PlatformDialogComponent, PlatformDialogData>(PlatformDialogComponent, {
          width: '1136px',
          maxWidth: '1136px',
          panelClass: ['custom-modal-container', 'custom-modal-full-width-lt-md'],
          autoFocus: false,
          data: {
            platformData: {
              platformCardData: { ...getPlatformByType(this.selectedEcom).install, type: this.selectedEcom },
              isInstalled: false,
            },
            type: 'PLATFORM',
            role: this.data.role,
          },
        })
        .afterClosed()
        .subscribe(() => this.matDialogRef.close());
    } else {
      this.matDialogRef.close();
    }
  }
}

export interface NeedStoreDialogComponentData {
  role: RolesEnum;
}
