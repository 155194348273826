import { Injectable } from '@angular/core';
import { CategoryMappingData, CategoryMappingPageable } from '../model/category-mapping-data';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { WizardCategoryMappingFilter } from './wizard-category-header.service';
import { isEmpty } from 'lodash';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class WizardCategoryMappingListService {
  customCategoriesTitle: string;
  customCategoriesTooltip: string;
  originalCategoriesTitle: string;
  originalCategoriesTooltip: string;
  private _allItems = new BehaviorSubject<CategoryMappingData[]>(undefined);
  private _itemsMatchingFilter = new BehaviorSubject<CategoryMappingData[]>(null);

  constructor() {}

  public get allItems(): Observable<CategoryMappingData[]> {
    return this._allItems.asObservable().pipe(filter((value) => value !== undefined));
  }

  public set setItemsMatchingFilter(items: CategoryMappingData[]) {
    this._itemsMatchingFilter.next(items);
  }

  public keepCheckedValueOnItemsUpdate(updatedMappings: CategoryMappingData[]): CategoryMappingData[] {
    if (!this._allItems.value) {
      return updatedMappings;
    } else {
      return updatedMappings.map((item) => {
        const matchingItem = this._allItems.value.find((entry) => entry.id === item.id);
        return { ...item, checked: matchingItem ? matchingItem.checked : false };
      });
    }
  }

  public setItems(items: CategoryMappingData[]): void {
    this._allItems.next(items);
  }

  public checkAllItems(): void {
    combineLatest([this._allItems, this._itemsMatchingFilter])
      .pipe(
        take(1),
        map(([all, matching]: [CategoryMappingData[], CategoryMappingData[]]) =>
          all.map(
            (item: CategoryMappingData): CategoryMappingData => ({
              ...item,
              checked: matching.findIndex((entry: CategoryMappingData): boolean => entry.id === item.id) !== -1,
            })
          )
        )
      )
      .subscribe((items: CategoryMappingData[]): void => this.setItems(items));
  }

  public clearSelection(): void {
    if (!this._allItems.value) {
      return;
    }
    const mappedItems = this._allItems.value.map((item) => ({ ...item, checked: false }));
    this.setItems(mappedItems);
  }

  public get checkedItems(): CategoryMappingData[] {
    return this._allItems.getValue().filter((item) => item.checked);
  }

  public toggleSingleItemChecked(itemId: string, isChecked: boolean): void {
    const mappedItems = this._allItems
      .getValue()
      .map((item) => (item.id === itemId ? { ...item, checked: isChecked } : item));
    this.setItems(mappedItems);
  }

  public filter(
    categories: CategoryMappingData[],
    mapFromSearchTerm: string,
    mappedSearchTerm: string,
    categoryFilter: WizardCategoryMappingFilter
  ): CategoryMappingData[] {
    let filteredCategories = this.filterByMapFromSearchTerm(categories, mapFromSearchTerm);
    filteredCategories = this.filterByMappedSearchTerm(filteredCategories, mappedSearchTerm);
    filteredCategories = this.filterByCategoryFilter(filteredCategories, categoryFilter);
    return filteredCategories;
  }

  public paginate(filteredCategories: CategoryMappingData[], page: number, size: number): CategoryMappingPageable {
    return {
      allItemsCount: filteredCategories.length,
      data: this.getCategoriesForPage(filteredCategories, page, size),
    };
  }

  private filterByMapFromSearchTerm(categories: CategoryMappingData[], searchTerm: string): CategoryMappingData[] {
    if (!searchTerm) {
      return categories;
    }
    return categories.filter((cat) => cat.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  private filterByMappedSearchTerm(categories: CategoryMappingData[], searchTerm: string): CategoryMappingData[] {
    if (!searchTerm) {
      return categories;
    }
    return categories.filter((cat) =>
      cat.mappedCategories.some((mappedCat) => mappedCat.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }

  private filterByCategoryFilter(
    categories: CategoryMappingData[],
    categoryFilter: WizardCategoryMappingFilter
  ): CategoryMappingData[] {
    if (categoryFilter === 'not_categorized') {
      return categories.filter((category) => isEmpty(category.mappedCategories));
    } else {
      return categories;
    }
  }

  private getCategoriesForPage(categories: CategoryMappingData[], page: number, size: number): CategoryMappingData[] {
    return categories.slice(page * size, (page + 1) * size);
  }
}
