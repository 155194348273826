import { ShippingZoneDto } from './shipping-zone-dto';

export interface ShippingProfileDto {
  id: number;
  userId: number;
  name: string;
  type: ShippingProfileType;
  shippingZones: ShippingZoneDto[];
  tasks: CatalogDto[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export enum ShippingProfileType {
  GENERAL = 'GENERAL',
  CUSTOM = 'CUSTOM',
}

export interface CatalogDto {
  id: number;
  userId: number;
  name: string;
  deleteDate: string;
}
