import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CatalogProductsCacheService } from 'app/service/catalog-products/catalog-products-cache.service';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { AppState } from '../../../../../../app.state';
import { CatalogSidebarService } from '../../../../../../service/catalog-sidebar/catalog-sidebar.service';
import { EcomVO } from '../../../../../../service/ecom/ecom.service';
import { selectedRetailerEcomSelector } from '../../../../../../store/ecom/ecom.selector';
import { SetSelectedCatalogAction } from '../../../../../../store/rcatalogs/rcatalogs.action';
import {
  marketplaceCatalogsSelector,
  recentlyAddedProductsSelector,
  selectedCatalogSelector,
} from '../../../../../../store/rcatalogs/rcatalogs.selector';
import { Catalog } from '../../../../../../vo/catalog';
import { SearchProductVO } from '../../../../../../vo/search-product-vo';
import { CatalogOverlayService } from '../service/catalog-overlay.service';

@Component({
  selector: 'app-catalog-overlay-list-with-selector',
  templateUrl: './catalog-overlay-list-with-selector.component.html',
  styleUrls: ['./catalog-overlay-list-with-selector.component.scss'],
  providers: [CatalogOverlayService],
})
export class CatalogOverlayListWithSelectorComponent implements OnInit, OnDestroy {
  env = environment;
  catalogsForEcom$: Observable<Catalog[]>;
  selectedCatalog$: Observable<Catalog>;
  recentlyAddedProductIds: Observable<string[]>;
  products: SearchProductVO[] = [];
  productLoading = true;
  catalogs: Catalog[] = [];
  private unsubscribeAll: Subject<void>;
  private catalogs$: Observable<Catalog[]>;
  private selectedEcom: Observable<EcomVO>;
  private selectedCatalogId$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  constructor(
    private store: Store<AppState>,
    private catalogSidebarService: CatalogSidebarService,
    private catalogOverlayService: CatalogOverlayService,
    private catalogProductsCacheService: CatalogProductsCacheService
  ) {
    this.recentlyAddedProductIds = this.store.select(recentlyAddedProductsSelector).pipe(
      filter((products) => !!products && !!products.length),
      map((products) => products.map((product) => product.productId))
    );
    this.catalogs$ = this.store.select(marketplaceCatalogsSelector);
    this.selectedCatalog$ = this.store.select(selectedCatalogSelector);
    this.unsubscribeAll = new Subject<void>();
    this.selectedEcom = this.store.select(selectedRetailerEcomSelector).pipe(distinctUntilChanged());
    this.catalogsForEcom$ = this.getCatalogEcoms();
  }

  ngOnInit(): void {
    this.subscribeToSelectedCatalogChange();
    this.subscribeToCatalogs();
    this.subscribeToNewProductAdded();
    this.subscribeToProductRemoved();
    this.catalogOverlayService.subscribeToSupplierIdChange();
    this.getProductForSelectedCatalog();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleCatalogSelectorValueChange(id: string): void {
    this.store.dispatch(new SetSelectedCatalogAction(this.catalogs.find((catalog) => catalog.id === id)));
  }

  private subscribeToCatalogs(): void {
    this.catalogsForEcom$
      .pipe(
        switchMap((value, index) => {
          if (index === 0 && !!localStorage.getItem('selectedImportList')) {
            return of(value);
          } else {
            return of(value).pipe(tap((catalogs) => this.setSelectedCatalogIfEcomChanges(catalogs)));
          }
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((catalogs) => {
        this.catalogs = catalogs ?? [];
      });
  }

  private getCatalogEcoms(): Observable<Catalog[]> {
    return combineLatest([this.selectedEcom, this.catalogs$]).pipe(
      filter(([selectedEcom, catalogs]) => !!selectedEcom && !!catalogs),
      map(([selectedEcom, catalogs]) => {
        return catalogs.filter((catalog) => catalog.ecomId === selectedEcom.id);
      })
    );
  }

  private setSelectedCatalogIfEcomChanges(catalogs: Catalog[]): void {
    this.selectedCatalog$.pipe(take(1)).subscribe((catalog) => {
      if ((catalog === null || catalog === undefined) && catalogs && catalogs.length > 0) {
        this.store.dispatch(new SetSelectedCatalogAction(catalogs[0]));
      } else if (catalog === null || catalog === undefined || catalogs.length < 1) {
        this.store.dispatch(new SetSelectedCatalogAction(null));
      }
    });
  }

  private getProductForSelectedCatalog(): void {
    this.productLoading = true;
    this.selectedCatalogId$
      .pipe(
        omitNullOrUndefined(),
        switchMap((catalogId) =>
          this.catalogProductsCacheService.getProductsForCatalog(catalogId).pipe(omitNullOrUndefined())
        ),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((products) => {
        this.products = products;
        this.productLoading = false;
      });
  }

  private subscribeToSelectedCatalogChange(): void {
    this.selectedCatalog$.pipe(takeUntil(this.unsubscribeAll), distinctUntilChanged()).subscribe((catalog) => {
      if (catalog !== null && catalog !== undefined) {
        this.productLoading = true;
        this.selectedCatalogId$.next(Number(catalog.id));
      }
    });
  }

  private subscribeToNewProductAdded(): void {
    this.catalogSidebarService.newProductAdded.subscribe((value) => {
      this.productLoading = true;
      this.catalogProductsCacheService.refreshProductsForCatalog(Number(value.catalogId));
      this.selectedCatalogId$.next(Number(value.catalogId));
    });
  }

  private subscribeToProductRemoved(): void {
    this.catalogSidebarService.removedFromCatalog.subscribe((value) => {
      this.productLoading = true;
      this.catalogProductsCacheService.refreshProductsForCatalog(Number(value.catalogId));
      this.selectedCatalogId$.next(Number(value.catalogId));
    });
  }
}
