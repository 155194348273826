import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskVoExtended } from '../tasks.component';
import { TaskMenuItemClickedEvent } from '../tasks-menu/tasks-menu.component';

import { ROLE_TYPE } from '../../../vo/roles/roles';

@Component({
  selector: 'app-tasks-mobile-list',
  templateUrl: './tasks-mobile-list.component.html',
  styleUrls: ['./tasks-mobile-list.component.scss'],
})
export class TasksMobileListComponent implements OnInit {
  @Input() role: ROLE_TYPE;
  @Input() dataSource: TaskVoExtended[];
  @Input() page = 0;
  @Input() size: number;
  @Input() allCount: number;
  @Input() menus: any[] = [];
  @Output() nextClicked = new EventEmitter<void>();
  @Output() previousClicked = new EventEmitter<void>();
  @Output() sizeChanged = new EventEmitter<number>();
  @Output() syncClicked = new EventEmitter<TaskVoExtended>();
  @Output() menuItemClicked = new EventEmitter<TaskMenuItemClickedEvent>();
  @Output() clickRow = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
