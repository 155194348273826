import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TrendingSearchService } from 'app/service/trending-search/trending-search.service';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Utils } from 'app/utils/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../../app.state';
import { ColorPaletteService } from '../../../../../service/color-palette/color-palette.service';
import { SearchGtmAction } from '../../../../../service/google-tag-manager/actions/search';
import { GtmManagerService } from '../../../../../service/google-tag-manager/gtm-manager.service';
import { ProductSearchService } from '../../../../../service/product-search/product-search.service';
import { AddFilter } from '../../../../../store/product-search/product-search.action';
import { ProductSearchHelper } from '../../../../../store/product-search/product-search.reducer';
import { filterSelector } from '../../../../../store/product-search/product-search.selector';
import { CategoryVo } from '../../../../../vo/category-vo';
import { MarketplaceFilter } from '../../../../../vo/search-product-vo';
import { productSearchWithAutocompletePlaceholderConfig } from './product-search-with-autocomplete-placeholder.config';

@Component({
  selector: 'app-product-search-with-autocomplete',
  templateUrl: './product-search-with-autocomplete.component.html',
  styleUrls: ['./product-search-with-autocomplete.component.scss'],
})
export class ProductSearchWithAutocompleteComponent implements OnInit, OnDestroy {
  @Input() isMobile = false;

  @Output() filtersSelected = new EventEmitter<MarketplaceFilter>();
  @Output() closeClicked = new EventEmitter<void>();

  searchTerm: string;
  trendingTerms: string[] = [];
  keyPressed = new Subject<string>();
  productSearchFilterStore$: Observable<MarketplaceFilter>;
  productSearchHelperStore$: Observable<Partial<ProductSearchHelper>>;
  selectedCategoryName: string;
  isOpenDebounced: Observable<boolean>;
  isOpen = new BehaviorSubject<boolean>(false);
  backgroundColor: string;
  filterSearchValue: string;

  protected readonly productSearchWithAutocompletePlaceholderConfig = productSearchWithAutocompletePlaceholderConfig;

  private _unsubscribeAll: Subject<void>;
  private gtmSearchTerm: string;

  constructor(
    private productSearchService: ProductSearchService,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private gtmService: GtmManagerService,
    private colorPaletteService: ColorPaletteService,
    private trendingSearchService: TrendingSearchService
  ) {
    this.isOpenDebounced = this.isOpen.asObservable().pipe(debounceTime(100));
    this.productSearchFilterStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.filter)
    );
    this.productSearchHelperStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.helpers)
    );
    this._unsubscribeAll = new Subject();
    this.backgroundColor = this.colorPaletteService.getColorVariable('--app-syncee-grey-100');
  }

  ngOnInit(): void {
    this.selectedCategoryName = this.getSelectedCategoryName(null);
    this.subscribeToProductSearchHelper();
    this.createFilterSearchValueSubscription();
    this.subscribeToTrendingSearch();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private createFilterSearchValueSubscription(): void {
    this.store
      .select(filterSelector)
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((marketplaceFilter: MarketplaceFilter) => marketplaceFilter?.search)
      )
      .subscribe((search: string): void => {
        this.filterSearchValue = search;
      });
  }

  public handleClearSearch(): void {
    this.store.dispatch(
      new AddFilter({
        value: {
          search: undefined,
        },
      })
    );
  }

  handleClick(filters: MarketplaceFilter): void {
    this.emitSearch(filters);
  }

  handleInputKeyDownEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') {
      this.keyPressed.next(event.key);
    } else {
      this.isOpen.next(!this.isMobile);
    }
  }

  private saveTermToCookie(term: string): void {
    this.productSearchService.saveTermToCookies(term);
  }

  private emitSearch(filters: MarketplaceFilter): void {
    if (!Utils.isNullOrUndefined(filters.search)) {
      this.saveTermToCookie(filters.search);
      this.sendGtmSearchTag(filters.search);
    }
    this.isOpen.next(false);
    this.searchTerm = null;
    this.filtersSelected.emit(filters);
  }

  private sendGtmSearchTag(searchTerm: string): void {
    if (!!searchTerm && !!searchTerm.length && this.gtmSearchTerm !== searchTerm) {
      this.gtmService.pushTag(new SearchGtmAction({ search_term: searchTerm }));
      this.gtmSearchTerm = searchTerm;
    }
  }

  private subscribeToTrendingSearch(): void {
    this.trendingSearchService
      .getTrendingTerms()
      .pipe(omitNullOrUndefined(), takeUntil(this._unsubscribeAll))
      .subscribe((terms) => {
        this.trendingTerms = terms;
      });
  }

  private subscribeToProductSearchHelper(): void {
    this.productSearchHelperStore$.pipe(takeUntil(this._unsubscribeAll)).subscribe((helpers) => {
      if (!Utils.isNullOrUndefined(helpers)) {
        this.selectedCategoryName = this.getSelectedCategoryName(helpers.category);
      }
    });
  }

  private getSelectedCategoryName(selectedCategory: CategoryVo): string {
    if (Utils.isNullOrUndefined(selectedCategory) || Utils.isNullOrUndefined(selectedCategory.id)) {
      return this.translateService.instant('SEARCH_BAR.ALL');
    }
    return selectedCategory.name;
  }
}
