import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { SynceeReviewAutoProcessService } from 'app/main/syncee-review/service/syncee-review-auto-process.service';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { Utils } from 'app/utils/utils';
import { isNull } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { filter, interval, map, Subject, Subscription, switchMap, takeUntil, tap, timer } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root',
})
export class ReviewTimerService {
  private readonly THRESHOLD = 30;
  private readonly EXPIRE_AFTER_DAYS = 3;

  private _timer$: Subscription;
  private _timerValue = 0;
  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private _cookieService: CookieService,
    private _synceeReviewService: SynceeReviewAutoProcessService,
    private router: Router
  ) {
    if (this._hasCookie()) {
      return;
    }
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        tap((isAuthenticated) => {
          if (!isAuthenticated) {
            this._releaseTimer();
          }
        }),
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => this.router.events),
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event) => {
        if (this._isOnTrackablePage(event.url)) {
          this.init();
        } else {
          this._releaseTimer();
        }
      });
  }

  init(): void {
    if (this._hasCookie()) {
      return;
    }
    if (!Utils.isNullOrUndefined(this._timer$)) {
      return;
    }
    this._startTimer();
  }

  private _isOnTrackablePage(url: string): boolean {
    return url.startsWith('/collections/') || url.startsWith('/supplier/') || url.startsWith('/product/');
  }

  private _startTimer(): void {
    this._timer$ = timer(0, 1000).subscribe(() => {
      this._timerValue++;
      if (this._timerValue < this.THRESHOLD) {
        return;
      }
      if (this._hasCookie()) {
        this._releaseSubscriptions();
        return;
      }
      this._synceeReviewService.triggerDialogOpen();
      this._releaseSubscriptions();
      this._setReviewDate(new Date());
    });
  }

  private _hasCookie(): boolean {
    return !!this._cookieService.get('review_date');
  }

  private _setReviewDate(date: Date): void {
    const expires = new Date();
    expires.setDate(expires.getDate() + this.EXPIRE_AFTER_DAYS);
    this._cookieService.set('review_date', date.toISOString(), expires, '/');
  }

  private _releaseTimer(): void {
    this._timer$?.unsubscribe();
    this._timer$ = null;
  }

  private _releaseSubscriptions(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._releaseTimer();
  }
}
