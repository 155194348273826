import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { DroppableInputComponent } from '../droppable-input/droppable-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FieldSettingsComponent } from '../field-settings/field-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { ReplacerComponent } from '../field-settings/replacer/replacer.component';
import { FileFieldComponent } from '../file-fields/file-field.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DroppableMultilineInputComponent } from '../droppable-input/droppable-multiline-input.component';
import { DpMultilineInputItemComponent } from '../droppable-input/dp-multiline-input-item.component';
import { AutoSizeInputModule } from 'ngx-autosize-input';

import { CustomDialogWrapperComponent } from '../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { ButtonWithIconDirective } from '../../shared/directives/button-with-icon.directive';
import { ShowOnBreakpointDirective } from '../../shared/directives/show-on-breakpoint.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FuseSharedModule,
    OverlayModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatRippleModule,
    DragDropModule,
    MatListModule,
    MatChipsModule,
    AutoSizeInputModule,
    CustomDialogWrapperComponent,
    ButtonWithIconDirective,
    ShowOnBreakpointDirective,
    TranslateModule,
  ],
  declarations: [
    DroppableInputComponent,
    DroppableMultilineInputComponent,
    FieldSettingsComponent,
    ReplacerComponent,
    FileFieldComponent,
    DpMultilineInputItemComponent,
  ],
  exports: [
    DroppableInputComponent,
    DroppableMultilineInputComponent,
    FieldSettingsComponent,
    ReplacerComponent,
    FileFieldComponent,
    DpMultilineInputItemComponent,
    DragDropModule,
  ],
})
export class DraggableModule {}
