<div class="mobile-main-menu-inner-sidebar" fxLayout="column">
  <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" class="p-20">
    <div fxLayoutAlign="center center" (click)="backClicked()">
      <button mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <a class="back-text">{{ customBack ?? ('MOBILE_MAIN_MENU.BACK_TO_MAIN_MENU' | translate) }}</a>
    </div>
    <button mat-icon-button (click)="closeClicked()" class="close-button"><mat-icon>close</mat-icon></button>
  </div>
  <div fxFlex="1 0 0" class="px-20 content" overflowDirective>
    <ng-content></ng-content>
  </div>
</div>
