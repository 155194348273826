import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { SupplierReturnAndRefundPolicyDto } from '../../../vo/return-and-refund-policy';

@Injectable({
  providedIn: 'root',
})
export class ReturnAndRefundPolicyService {
  constructor(private springRestService: SpringRestService) {}

  get(): Observable<SupplierReturnAndRefundPolicyDto> {
    return this.springRestService.get(MicroserviceNames.USER, `/SupplierReturnAndRefundPolicy`, undefined, true);
  }

  getByUserId(userId: number): Observable<SupplierReturnAndRefundPolicyDto> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      `/SupplierReturnAndRefundPolicy/User/${userId}`,
      undefined,
      true
    );
  }

  post(data: Partial<SupplierReturnAndRefundPolicyDto>): Observable<void> {
    return this.springRestService.post(MicroserviceNames.USER, `/SupplierReturnAndRefundPolicy`, data, undefined, true);
  }

  put(data: Partial<SupplierReturnAndRefundPolicyDto>): Observable<void> {
    return this.springRestService.put(MicroserviceNames.USER, `/SupplierReturnAndRefundPolicy`, data, undefined, true);
  }
}
