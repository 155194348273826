import { AfterViewInit, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { getUserRolesSelector } from '../../store/user/user.selector';

@Directive({
  selector: '[filterRoles]',
  standalone: true,
})
export class FilterRolesDirective implements AfterViewInit, OnDestroy {
  /**
   * Filter for syncee roles. (e.g.: 1, 2, 3)
   *
   * Possible input(s):
   * - filterType: 'hide' | 'only' = 'only'
   */
  @Input() filterRoles: number[];
  @Input() filterType: FilterType = 'only';
  private unsubscribeAll: Subject<void>;
  private hasView = false;
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private store: Store<AppState>
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngAfterViewInit(): void {
    this.subscribeToActualRole();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private handler(roles: number[]): void {
    if (this.filterType === 'only') {
      roles.some((role) => this.filterRoles.includes(role)) ? this.show() : this.hide();
    } else {
      roles.some((role) => this.filterRoles.includes(role)) ? this.hide() : this.show();
    }
  }

  private show(): void {
    if (!this.hasView) {
      this.container.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  private hide(): void {
    if (this.hasView) {
      this.container.clear();
      this.hasView = false;
    }
  }

  private subscribeToActualRole(): void {
    if (!this.subscription) {
      this.subscription = this.store
        .select(getUserRolesSelector)
        .pipe(omitNullOrUndefined(), takeUntil(this.unsubscribeAll))
        .subscribe((roles) => {
          this.handler(roles);
        });
    }
  }
}

type FilterType = 'hide' | 'only';
