import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { PermissionService } from 'app/service/permission/permission.service';
import { SCOPES } from 'app/service/permission/scopes';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap, takeLast } from 'rxjs/operators';
import { EcomActionTypes, UserEcomsFetch } from '../ecom/ecom.actions';
import { UserActionTypes, UserDetails } from '../user/user.actions';
import { getUserRoleSelector } from '../user/user.selector';
import { BootstrapActionTypes, UserDataBootstrapSuccess } from './bootstrap.actions';

@Injectable()
export class BootstrapEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private permissionService: PermissionService
  ) {}

  UserDataBootstrapEffect: Observable<UserDataBootstrapSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(BootstrapActionTypes.USER_DATA_BOOTSTRAP_START),
      switchMap(() => {
        this.store.dispatch(new UserDetails());
        return this.actions$.pipe(ofType(UserActionTypes.USER_DETAILS_SUCCESS), first());
      }),
      switchMap(() => {
        return this.store.select(getUserRoleSelector).pipe(
          filter((value) => value !== undefined),
          first()
        );
      }),
      switchMap((role: 1 | 2) => {
        this.store.dispatch(new UserEcomsFetch({ role }));
        return this.actions$.pipe(ofType(EcomActionTypes.USER_ECOMS_FETCH_SUCCESS), first());
      }),
      map(() => new UserDataBootstrapSuccess())
    )
  );
}
