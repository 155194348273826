<div>
  <div class="title">{{ 'CATEGORIES_SELECTOR.RECOMMENDED' | translate }}</div>
  <div fxLayout="column" class="pl-16">
    <div (click)="handleAllInClick()" class="menu py-8">
      {{ 'CATEGORIES_SELECTOR.ALL_IN' | translate : { categoryName: category.id | categoryNameById | async } }}
    </div>
    <div (click)="handleNewProductsClick()" class="menu py-8">{{ 'CATEGORIES_SELECTOR.NEW_PRODUCTS' | translate }}</div>
    <div (click)="handleNewSuppliersClick()" class="menu py-8">
      {{ 'CATEGORIES_SELECTOR.NEW_SUPPLIERS' | translate }}
    </div>
  </div>
</div>
