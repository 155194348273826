import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../../../environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-paypal-custom-button',
  templateUrl: './paypal-custom-button.component.html',
  styleUrls: ['./paypal-custom-button.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, TranslateModule, MatFormFieldModule],
})
export class PaypalCustomButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  protected readonly environment = environment;
}
