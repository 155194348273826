<div fxLayoutAlign="center center" fxLayoutGap="22px">
  <div class="product-search-input-container" fxFlex="1 0 0">
    <app-input-placeholder-slider
      class="placeholder"
      color="var(--app-syncee-grey-700)"
      [class.hide]="!!searchTerm?.length || !!filterSearchValue?.length"
      [backgroundColor]="backgroundColor"
      [options]="productSearchWithAutocompletePlaceholderConfig"
    ></app-input-placeholder-slider>
    <mat-form-field
      class="form-field select-without-label"
      appearance="fill"
      (click)="isOpen.next(!isMobile)"
      [floatLabel]="'never'"
    >
      <input
        autocomplete="off"
        matInput
        type="text"
        #input
        [ngModel]="filterSearchValue"
        (ngModelChange)="searchTerm = $event"
        (keydown)="handleInputKeyDownEvent($event)"
      />
      <span matPrefix><mat-icon class="s-24">search</mat-icon></span>
      <button mat-icon-button matSuffix *ngIf="!!filterSearchValue" (click)="handleClearSearch()">
        <mat-icon class="s-20 cursor-pointer">close</mat-icon>
      </button>
    </mat-form-field>
    <div
      class="product-search-overlay custom-elevation-z1"
      *ngIf="(isOpenDebounced | async) && !isMobile"
      (clickOutside)="isOpen.next(false)"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
  <div fxFlex="0 0 auto" (click)="closeClicked.emit()" *ngIf="isMobile">
    {{ 'TOOLBAR.PRODUCT_SEARCH.CANCEL' | translate }}
  </div>
</div>
<ng-container *ngIf="isMobile">
  <div class="mobile-wrapper">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>

<ng-template #content>
  <app-product-search-with-autocomplete-content
    [searchTerm]="searchTerm"
    [trendingTerms]="trendingTerms"
    [keyboardArrowPressed]="keyPressed"
    (suggestionSelected)="handleClick($event); input.value = !!$event.search?.length ? $event.search : ''"
    (close)="isOpen.next(false); closeClicked.emit()"
  >
  </app-product-search-with-autocomplete-content>
</ng-template>
