import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-chat-base',
  templateUrl: './ai-chat-base.component.html',
  styleUrls: ['./ai-chat-base.component.scss'],
})
export class AiChatBaseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
