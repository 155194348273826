import { Continent, CountryNode } from '../../../../utils/Countries';
import { ShippingDetailsWithConcatenatedCountries } from './shipping-details-zones.service';
import { ShippingRateDto } from '../../../../vo/shipping-zones/shipping-rate.dto';
import { ShippingRateConditionDto } from '../../../../vo/shipping-zones/shipping-rate-condition-dto';

export class ContinentShippingInfo {
  public name: string;

  public countryShippingInfos: CountryShippingInfo[];

  constructor(continent: Continent, country: CountryNode, shipping: ShippingDetailsWithConcatenatedCountries) {
    this.name = continent;

    const countryInfo = ContinentShippingInfo.createCountryShippingInfo(country, shipping);

    this.countryShippingInfos = [];

    this.countryShippingInfos.push(countryInfo);
  }

  public addCountryShippingInfo(country: CountryNode, shipping: ShippingDetailsWithConcatenatedCountries): void {
    const countryInfo = ContinentShippingInfo.createCountryShippingInfo(country, shipping);

    this.countryShippingInfos.push(countryInfo);
  }

  static createCountryShippingInfo(
    country: CountryNode,
    shipping: ShippingDetailsWithConcatenatedCountries
  ): CountryShippingInfo {
    if (shipping.shippingRates.length == 0) {
      return {
        country: null,
        condition: null,
        additionalCost: null,
        minShippingDays: null,
        maxShippingDays: null,
        shippingCost: null,
        shippingRate: null,
        hasMultipleRate: false,
      };
    }

    if (shipping.shippingRates.length == 1) {
      const shippingRate = shipping.shippingRates[0];

      return {
        country: country.name,
        condition: shippingRate.condition,
        additionalCost: shippingRate.additionalCost,
        minShippingDays: shippingRate.minShippingDays,
        maxShippingDays: shippingRate.maxShippingDays,
        shippingCost: shippingRate.cost,
        shippingRate: null,
        hasMultipleRate: false,
      };
    }

    return {
      country: country.name,
      condition: null,
      additionalCost: null,
      minShippingDays: null,
      maxShippingDays: null,
      shippingCost: null,
      shippingRate: shipping.shippingRates,
      hasMultipleRate: true,
    };
  }
}

interface CountryShippingInfo {
  country: string;
  condition: ShippingRateConditionDto;
  additionalCost: number;
  minShippingDays: number;
  maxShippingDays: number;
  shippingCost: number;
  shippingRate: ShippingRateDto[];
  hasMultipleRate: boolean;
}
