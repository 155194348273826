<div class="breadcrumb-container">
  <div class="full-path typography-body-2">
    <ng-container *ngFor="let pathElement of fuseConfig.layout.breadcrumb.path; let last = last">
      <div [routerLink]="pathElement.url" [queryParams]="pathElement.queryParams" class="path-element">
        {{ (pathElement.translationKey | translate) || pathElement.translationKey }}
      </div>
      <ng-container *ngIf="!last">
        <div>/</div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ngx-json-ld *ngIf="!!jsonLD" [json]="jsonLD"></ngx-json-ld>
