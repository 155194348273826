<div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxLayoutGap="5px">
  <h2 mat-dialog-title>{{ 'TASKWIZARD.ADDFILES.MODALS.CONNECTION' | translate }}</h2>

  <form fxLayout="row" fxLayoutAlign="start" mat-dialog-content [formGroup]="form">
    <mat-form-field fxFlex="100" appearance="fill">
      <mat-select
        placeholder="{{ 'TASKWIZARD.ADDFILES.MODALS.CONNECTION' | translate }}"
        (selectionChange)="selectedFtpConnectionType($event)"
        formControlName="connType"
        style="min-width: 200px"
        required
      >
        <mat-option *ngFor="let connectionType of connectionTypes" [value]="connectionType.type">{{
          connectionType.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
    <div>
      <button class="mat-button" style="margin-right: 5px" (click)="close()">
        {{ 'TASKWIZARD.ADDFILES.MODALS.CLOSE' | translate }}
      </button>
      <button class="mat-button mat-accent" [disabled]="!form.valid" (click)="onSubmit()">
        {{ 'TASKWIZARD.ADDFILES.MODALS.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
