import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { PlatformLogoComponent } from '../platform-logo/platform-logo.component';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';

@Component({
  selector: 'app-card-with-small-icon',
  standalone: true,
  imports: [CommonModule, FlexModule, PlatformLogoComponent],
  templateUrl: './card-with-small-icon.component.html',
  styleUrls: ['./card-with-small-icon.component.scss'],
})
export class CardWithSmallIconComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() @HostBinding('class.selected') selected = false;
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator())
      .subscribe((matched) => (this.ltMd = matched));
  }
}
