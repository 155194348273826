import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { DialogMultiWrapperComponent } from '../../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { DialogMultiType } from '../../../../vo/dialog-multi';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    DialogMultiWrapperComponent,
  ],
})
export class ConfirmationDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public titleAlign?: string;
  public btnOkText?: string;
  public btnCancelText?: string;
  public showCancel = true;
  public isAlibaba = false;
  public ecologyToken: string;

  public isLoading = false;

  public dialogType: DialogMultiType = 'warning';

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  close(): void {
    if (this.isAlibaba) {
      this.goToAlibabaSite();
    } else {
      this.dialogRef.close(true);
    }
  }

  goToAlibabaSite(): void {
    window.open('https://dropshipping.alibaba.com/?ecology_token=' + this.ecologyToken, '_blank');
    this.dialogRef.close();
  }
}
