import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlanVO } from '../subscription.component';

@Component({
  selector: 'app-plan-compare-dialog',
  templateUrl: './plan-compare-dialog.component.html',
  styleUrls: ['./plan-compare-dialog.component.scss'],
})
export class PlanCompareDialogComponent implements OnInit {
  // Dialog inputs
  public currentPlan: PlanVO;
  public selectedPlan: PlanVO;
  public isUpgrade;
  public isNextPlan;
  public productType;

  constructor(public dialogRef: MatDialogRef<PlanCompareDialogComponent>) {}

  ngOnInit(): void {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public closeDialogWithNextPlan(): void {
    this.dialogRef.close(this.selectedPlan);
  }
}
