import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { ChangeRoleDialogWrapperComponent } from './components/change-role-dialog-wrapper/change-role-dialog-wrapper.component';
import { EnterComponent } from './components/enter/enter.component';
import { KeepRoleComponent } from './components/keep-role/keep-role.component';
import { EcomComponent } from './components/ecom/ecom.component';
import { TileSelectComponent } from 'app/shared/components/tile-select/tile-select.component';
import { OrDividerComponent } from 'app/shared/components/or-divider/or-divider.component';
import { TranslateModule } from '@ngx-translate/core';

import { ChangeRoleDialogComponent } from './change-role-dialog.component';

import { NotificationService } from '../notification/notification.service';

@NgModule({
  declarations: [
    ChangeRoleDialogWrapperComponent,
    EnterComponent,
    KeepRoleComponent,
    EcomComponent,
    ChangeRoleDialogComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    TileSelectComponent,
    OrDividerComponent,
    TranslateModule,
  ],
  providers: [NotificationService],
  exports: [ChangeRoleDialogComponent],
})
export class ChangeRoleModule {}
