import { ContinentDto } from 'app/vo/countries-location/continent-dto';
import { CountryLocationDto } from 'app/vo/countries-location/country-location-dto';
import { CountriesLocationActionTypes, COUNTRIES_LOCATIONS_ACTIONS_ALL } from './countries-locations.actions';

export interface CountriesLocationsState {
  continents: ContinentDto[];
  countriesLocations: CountryLocationDto[];
}

const initialState = (): CountriesLocationsState => {
  return {
    continents: null,
    countriesLocations: null,
  };
};

export function countriesLocationsReducer(
  state = initialState(),
  action: COUNTRIES_LOCATIONS_ACTIONS_ALL
): CountriesLocationsState {
  switch (action.type) {
    case CountriesLocationActionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesLocations: action.payload,
      };
    case CountriesLocationActionTypes.GET_CONTINENTS_SUCCESS:
      return {
        ...state,
        continents: action.payload,
      };
    default: {
      return state;
    }
  }
}
