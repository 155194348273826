<div class="w-100p" *ngIf="!isLoading; else loading">
  <div *ngIf="!isEmpty; else noResults" [ngClass]="{ bordered: bordered }">
    <div *ngFor="let rowData of dataSource; let index = index">
      <ng-container
        *ngIf="rowTemplateRef"
        [ngTemplateOutlet]="rowTemplateRef"
        [ngTemplateOutletContext]="{ rowData: rowData, index: index }"
      ></ng-container>
    </div>
  </div>
  <mat-paginator
    *ngIf="isPaginate"
    [pageIndex]="page"
    [pageSize]="size"
    [length]="count"
    [pageSizeOptions]="pageSizeOptions"
    (page)="handlePageEvent($event)"
  >
  </mat-paginator>
</div>

<ng-template #noResults>
  <!--    replace if necessary-->
  <app-no-search-results *ngIf="!noResultComponent"></app-no-search-results>
  <ng-container *ngIf="!!noResultComponent && isEmpty" [ngTemplateOutlet]="noResultComponent"></ng-container>
</ng-template>

<ng-template #loading>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>
