import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'lodash';

@Pipe({
  name: 'capitalise',
  standalone: true,
})
export class CapitalisePipe implements PipeTransform {
  transform(value: string): string {
    return capitalize(value.toLowerCase());
  }
}
