import { Component, Inject, OnInit } from '@angular/core';
import { MySupplierCatalog } from '../../../../../vo/my-suppliers/my-supplier-vo';
import { MySuppliersPageService } from '../../service/my-suppliers-page.service';
import { MySuppliersDialogService } from '../../service/my-suppliers-dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MySuppliersMobileCatalogData } from '../my-suppliers-mobile-catalog-details/my-suppliers-mobile-catalog-details.component';

@Component({
  selector: 'app-my-suppliers-mobile-catalog-list',
  templateUrl: './my-suppliers-mobile-catalog-list.component.html',
  styleUrls: ['./my-suppliers-mobile-catalog-list.component.scss'],
})
export class MySuppliersMobileCatalogListComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MySuppliersMobileCatalogListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MySuppliersMobileCatalogListData,
    private mySuppliersPageService: MySuppliersPageService,
    private mySuppliersDialogService: MySuppliersDialogService,

    private router: Router
  ) {}

  ngOnInit(): void {
    // this.data.catalogList = this.mySuppliersPageService.sortCatalogsByApprovedStatus(this.data.catalogList);
  }

  public closeAndRedirect(): void {
    this.dialogRef.close();
    this.navigateToMySuppliers();
  }

  private navigateToMySuppliers(): void {
    this.router.navigate(['my_suppliers']);
  }

  public showCatalogDetails(catalog: MySupplierCatalog): void {
    const data: MySuppliersMobileCatalogData = {
      catalog: catalog,
      supplierName: this.data.supplierName,
      website: this.data.website,
      isAutoOrder: this.data.isAutoOrder,
      hasAccessToContacts: this.data.hasAccessToReadContacts,
    };
    this.mySuppliersDialogService.openMobileCatalogDetails(data);
  }
}

export interface MySuppliersMobileCatalogListData {
  catalogList: MySupplierCatalog[];
  supplierName: string;
  isAutoOrder: boolean;
  website: string;
  hasAccessToReadContacts: boolean;
}
