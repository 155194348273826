import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-section-title-slider',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './section-title-slider.component.html',
  styleUrls: ['./section-title-slider.component.scss'],
})
export class SectionTitleSliderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() hasBottomPadding = true;
  @Input() position: 'left' | 'center' = 'left';
  @Input() hasAll = false;
  @Output() allClicked = new EventEmitter<void>();

  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }
}
