<mat-card class="connect-your-store-platform-container" [ngClass]="'variant-' + variant">
  <app-universal-badge
    [badgeType]="'beta'"
    *ngIf="inBeta(data.ecomType.toLowerCase())"
    class="beta-badge"
  ></app-universal-badge>
  <div [fxFlex]="'0 0 ' + (variant === 'default' ? 150 : 80) + 'px'" fxLayoutAlign="center center">
    <img src="{{ variant === 'default' ? data.image : data.imageSmall }}" />
  </div>
  <div fxFlex="1 0 0" class="connect-your-store-platform-item-content">
    <div>
      <div class="connect-your-store-platform-item-title">{{ data.title | translate }}</div>
      <div *ngIf="variant === 'default'" class="connect-your-store-platform-item-desc">
        {{ data.description | translate }}
      </div>
    </div>
    <div class="dont-have-store">
      <button mat-button class="syncee-blue-button" (click)="connectStore()" fxFlex="0 0 auto">
        {{
          (variant === 'default'
            ? 'GETTING_STARTED.ADD_YOUR_STORE.CONNECT_STORE'
            : 'GETTING_STARTED.ADD_YOUR_STORE.CONNECT'
          ) | translate
        }}
      </button>
      <div class="connect-your-store-platform-item-link" fxFlex="1 0 0">
        <div>{{ 'GETTING_STARTED.ADD_YOUR_STORE.DONT_HAVE_A_STORE' | translate }}</div>
        <a href="{{ data.link }}">{{ 'GETTING_STARTED.ADD_YOUR_STORE.TRY_IT' | translate }}</a>
      </div>
    </div>
  </div>
</mat-card>
