import { Injectable } from '@angular/core';
import { Constants } from '../../utils/Constants';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from 'app/utils/utils';
import { isArray } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PersonalizationService {
  constructor(private _cookieService: CookieService) {}

  public addProductToCookie(product: any): void {
    const preferenceString = this._cookieService.get('preferences');
    const productHistoryString = this._cookieService.get('productHistory');
    const preferences = preferenceString ? JSON.parse(preferenceString) : {};
    const productHistory = productHistoryString ? JSON.parse(productHistoryString) : [];

    this.addPreference(product, preferences);
    this.addProductHistory(product, productHistory);
  }

  private addProductHistory(product, productHistory): void {
    if (productHistory.indexOf(product.ID) !== -1) {
      return;
    }
    productHistory.unshift(product.ID);
    if (productHistory.length > Constants.PERSONALIZATION.productHistoryLimit) {
      productHistory.pop();
    }

    this._cookieService.set('productHistory', JSON.stringify(productHistory), 365, '/');
  }

  private addPreference(product, preferences): void {
    if (Object.values(preferences).length === 0) {
      Constants.PERSONALIZATION.preferenceFields.forEach((prop) => {
        const productProp = prop.split('.').reduce((a, b) => a[b], product);
        preferences = this.createNewPreference(productProp, preferences, prop);
      });
    } else {
      Constants.PERSONALIZATION.preferenceFields.forEach((prop) => {
        const min = Math.min.apply(
          Math,
          Object.values(preferences[prop]).map((item) => item['modified'])
        );
        const productProp = prop.split('.').reduce((a, b) => a[b], product);

        if (Object.values(preferences[prop]).length < Constants.PERSONALIZATION.preferenceLimit) {
          preferences = this.add(productProp, preferences, prop);
        } else {
          preferences = this.remove(preferences, prop, min);
          preferences = this.add(productProp, preferences, prop);
        }
        const max = Math.max.apply(
          Math,
          Object.values(preferences[prop]).map((item) => item['modified'])
        );
        preferences[prop] = this.decreaseCounts(preferences[prop], min, max);
      });
    }

    this._cookieService.set('preferences', JSON.stringify(preferences), 365, '/');
  }

  private createNewPreference(productProp, preferences, prop): any {
    preferences[prop] = {};
    if (Utils.isNullOrUndefined(productProp)) {
      return preferences;
    }

    if (isArray(productProp)) {
      productProp.forEach((item) => {
        preferences[prop][item] = {
          count: 2,
          modified: new Date().getTime(),
        };
      });
    } else {
      preferences[prop][productProp] = {
        count: 2,
        modified: new Date().getTime(),
      };
    }

    return preferences;
  }

  private add(productProp, preferences, prop): any {
    if (Utils.isNullOrUndefined(productProp)) {
      return preferences;
    }

    if (isArray(productProp)) {
      productProp.forEach((item) => {
        if (Utils.isNullOrUndefined(preferences[prop][item])) {
          preferences[prop][item] = {
            count: 2,
            modified: new Date().getTime(),
          };
        } else {
          preferences[prop][item]['count']++;
          preferences[prop][item]['modified'] = new Date().getTime();
        }
      });
    } else {
      if (Utils.isNullOrUndefined(preferences[prop][productProp])) {
        preferences[prop][productProp] = {
          count: 2,
          modified: new Date().getTime(),
        };
      } else {
        preferences[prop][productProp]['count']++;
        preferences[prop][productProp]['modified'] = new Date().getTime();
      }
    }

    return preferences;
  }

  private remove(preferences, prop, min): any {
    Object.keys(preferences[prop]).forEach((index) => {
      if (preferences[prop][index].modified === min) {
        delete preferences[prop][index];
      }
    });

    return preferences;
  }

  private decreaseCounts(preference, min, max): any {
    const diff = max - min;
    // tslint:disable-next-line: forin
    for (const preferenceKey in preference) {
      const currentDiff = max - preference[preferenceKey]['modified'];
      preference[preferenceKey]['count'] -= currentDiff / diff;
      if (preference[preferenceKey]['count'] <= 0 || Utils.isNullOrUndefined(preference[preferenceKey]['count'])) {
        delete preference[preferenceKey];
      }
    }

    return preference;
  }
}
