<ng-container *ngIf="!isRemove">
  <h1 mat-dialog-title>
    {{ selectedCategoryName ? selectedCategoryName : ('CATEGORY_MAPPING.TREE_DIALOG.BULK_ADD_TITLE' | translate) }}
  </h1>
  <mat-form-field [appearance]="'fill'" class="w-100p">
    <mat-label>{{ 'CATEGORY_MAPPING.TREE_DIALOG.SEARCH_LABEL' | translate }}</mat-label>
    <input matInput id="filter" #filter (keyup)="filterTree(filter.value)" />
  </mat-form-field>
  <mat-dialog-content class="dialog-tree-content">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <mat-checkbox [checked]="itemsSelection.isSelected(node)" (change)="checkboxChanged($event, node)">
          <div class="node-title">
            {{ node.name }}
          </div>
        </mat-checkbox>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <mat-checkbox
          [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="itemSelectionToggle(node)"
        >
          <div class="node-title">
            {{ node.name }}
          </div>
        </mat-checkbox>
        <button mat-icon-button (click)="treeControl.toggle(node)">
          <mat-icon class="s-20">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
      </mat-tree-node>
    </mat-tree>
  </mat-dialog-content>
</ng-container>

<ng-container *ngIf="isRemove">
  <h1 mat-dialog-title>
    {{ 'CATEGORY_MAPPING.TREE_DIALOG.BULK_REMOVE_TITLE' | translate }}
  </h1>
  <h3>
    {{ 'CATEGORY_MAPPING.TREE_DIALOG.BULK_REMOVE_EXPLAIN' | translate }}
  </h3>
  <mat-dialog-content class="dialog-tree-content">
    <div>
      <div *ngFor="let item of removeItems">
        <mat-checkbox (change)="bulkRemoveSelection($event, item)"></mat-checkbox>
        {{ isCollectionList ? item : item.substring(3) }}
      </div>
    </div>
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button class="syncee-black-flat-button mr-8" [mat-dialog-close]="false">
    {{ 'CATEGORY_MAPPING.TREE_DIALOG.CLOSE' | translate }}
  </button>
  <button mat-button class="syncee-black-flat-button mr-8" (click)="save()">
    {{
      isRemove
        ? ('CATEGORY_MAPPING.TREE_DIALOG.REMOVE' | translate)
        : ('CATEGORY_MAPPING.TREE_DIALOG.SELECT' | translate)
    }}
  </button>
</mat-dialog-actions>
