<ng-container *ngIf="!!tree">
  <app-mat-tree-custom
    #customTree
    [inputPlaceholder]="inputPlaceholder"
    [data]="tree"
    [value]="value"
    (valueChange)="valueChange.emit($event)"
    [parentIsSelectable]="true"
    [selectParentExclusively]="true"
    [selectable]="true"
  ></app-mat-tree-custom>
</ng-container>
