<div fxLayout="column" class="h-100-p">
  <div class="type-selector">
    <div class="label">{{ 'FILTER_SIDEBAR.SELECTOR.SHIPS_TO.TITLE' | translate }}</div>
    <mat-radio-group class="radio-group" [(ngModel)]="selectedShipsToType">
      <mat-radio-button [value]="'country'" class="radio-button">
        {{ 'FILTER_SIDEBAR.SELECTOR.SHIPS_TO.COUNTRY' | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="'continent'" class="radio-button">
        {{ 'FILTER_SIDEBAR.SELECTOR.SHIPS_TO.CONTINENT' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <app-filter-selector
    fxFlex="1 0 0"
    [options]="filteredOptions"
    [hasSearch]="true"
    [value]="(productSearchStore$ | async)?.temporaryFilter?.shipsTo || (productSearchStore$ | async)?.filter?.shipsTo"
    (valueChange)="handleShipsToChange($any($event))"
    [searchTerm]="searchTerm"
    (searchTermChange)="handleSearchChange($event)"
  >
  </app-filter-selector>
</div>
