import { Injectable } from '@angular/core';
import { ConsoleLoggerService } from './console-logger.service';

@Injectable({ providedIn: 'root', useClass: ConsoleLoggerService })
export abstract class LoggerService {
  fine: any;
  info: any;
  warn: any;
  error: any;
}
