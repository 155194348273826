import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { AddfilesComponent } from '../taskwizard/addfiles/addfiles.component';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { StepperService } from '../../service/taskwizard/stepper.service';
import { ConnectionStateService } from '../../service/taskwizard/connection-state.service';
import { StepWrapperComponent } from '../step-wrapper/step-wrapper.component';
import { ExportTaskwizardUpdateService } from '../../service/taskwizard/export-taskwizard-update.service';
import { ExportTaskwizardService } from '../../service/taskwizard/export-taskwizard.service';
import { LoadingScreenService } from '../../service/loading-screen/loading-screen.service';
import { EcomService } from '../../service/ecom/ecom.service';
import { Utils } from 'app/utils/utils';
import { TaskFilterService } from '../../service/taskwizard/task-filter.service';
import { Observable } from 'rxjs';
import { FieldStructure } from '../filter/filter-item/filter-item.component';
import { map, tap } from 'rxjs/operators';
import { FilterStructure } from '../../vo/filter/filter-structure';

@Component({
  selector: 'app-retailer-export-task-wizard',
  templateUrl: './retailer-export-task-wizard.component.html',
  styleUrls: ['./retailer-export-task-wizard.component.scss'],
})
export class RetailerExportTaskWizardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('taskStepper', { static: true }) stepper: MatStepper;

  @ViewChild('mappingStep', { static: true }) private mappingMatStep: MatStep;
  @ViewChild('scheduleStep', { static: false }) private scheduleMatStep: MatStep;
  @ViewChild('filterStep', { static: true }) private filterMatStep: MatStep;

  @ViewChild('catalogEditorStep', { static: false }) private catalogEditorStep: MatStep;
  @ViewChild('pricingEditorStep', { static: true }) private pricingEditorStep: MatStep;
  @ViewChild('categoryMappingStep', { static: false }) private categoryMappingStep: MatStep;

  addFilesComponent: AddfilesComponent;

  taskId: number;
  ecomId: number;
  domain: string;
  currency: string;

  taskName: string;
  destination: string;

  enableComponents = false;

  isUpdate: boolean;

  defaultStructure: any;

  fieldStructures: Observable<FilterStructure[]>;

  constructor(
    private route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private cfr: ComponentFactoryResolver,
    private stepperService: StepperService,
    public etwUpdateService: ExportTaskwizardUpdateService,
    private connStateService: ConnectionStateService,
    private _loadingService: LoadingScreenService,
    private exportTaskwizardService: ExportTaskwizardService,
    private ecomService: EcomService,
    private taskFilterService: TaskFilterService
  ) {
    this.getFieldStructures();
  }

  @ViewChildren(MatStep) set matSteps(steps: QueryList<MatStep>) {
    this.stepperService.setMatSteps(steps.toArray());
  }

  @ViewChildren(StepWrapperComponent) set stepWrappers(content: QueryList<StepWrapperComponent>) {
    if (!Utils.isNullOrUndefined(this.stepperService)) {
      this.stepperService.setStepWrappers(content.toArray());
    }
  }

  ngAfterViewInit(): void {
    this.stepperService.setStepper(this.stepper);
    this.route.queryParams.subscribe((params) => {
      this.taskId = params.task_id;
      this.ecomId = params.ecom_id;
      this.openToUpdate();
    });
    this.defaultStructure = this.exportTaskwizardService.getDefaultStructure();

    this.ecomService.getDomainByEcomId(this.ecomId).subscribe((item) => {
      if (!Utils.isNullOrUndefined(item.options.currency)) {
        this.currency = item.options.currency.toUpperCase();
      } else {
        this.currency = 'USD';
      }
    });
  }

  openToUpdate(): void {
    this.exportTaskwizardService.runShopifyProductsExport(this.taskId, this.ecomId).subscribe(() => {
      this.etwUpdateService.init(this.taskId).subscribe(
        (res) => {
          this.taskName = res.task.name;
          this.ecomId = res.task.ecomId;
          this.domain = res.ecom.domain;
          this.enableComponents = true;
          this.stepperService.initStepperSteps(true);
        },
        (error) => console.log(error)
      );
    });
  }

  ngOnDestroy(): void {
    this.stepperService.reset();
    this.etwUpdateService.unsubscribeWatchers();
  }

  getFieldStructures(): Observable<FieldStructure[]> {
    return this.etwUpdateService.getRestDataFinished().pipe(
      map((res) => {
        if (res) {
          return this.etwUpdateService.data.taskFilters.fieldsStructure;
        }
      })
    );
  }

  saveFilter(taskId: number, filters: any, variantFilters?: any): Observable<any> {
    return this.taskFilterService
      .saveExportFilters(taskId, filters)
      .pipe(tap(() => (this.etwUpdateService.data.taskFilters.filter = filters)));
  }

  get loadingService(): LoadingScreenService {
    return this._loadingService;
  }
}
