import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-update-available',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  protected readonly window = window;
}
