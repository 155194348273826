import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PriceType } from 'app/main/taskwizard/supplier-task-pricing/enums/price-type.enum';
import { Utils } from 'app/utils/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogDetails } from '../../main/retailer-import-list/model/catalog-details';
import { CatalogFormVO, CatalogSettingsRequest } from '../../vo/catalog-form-vo';
import { RetailerCatalogType } from '../../vo/retailer-catalog/retailer-catalog-type';
import { RestService } from '../rest/rest.service';
import { ImportListDto, PatchImportListBody } from '../../main/retailer-import-list/model/import-list.dto';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { RetailerCatalogSettingsVO } from '../../shared/components/retailer-catalog-settings/abstract-retailer-settings';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  public static UNLOCK_SUPPLIER_CATALOG = 'CatalogService/activatePrivateCatalog';
  public static IS_ALL_CATALOG_REJECTED = 'CatalogService/isCatalogsRejected';
  public static GET_CATALOGS_BY_TASKSIDS = 'CatalogService/getCatalogsByTaskIds';
  public static GET_ECOM_ACTIVE_CATALOGS = 'RetailerCatalogService/getMarketplaceRetailerCatalogs';
  public static GET_ECOM_ALIBABA_ACTIVE_CATALOGS = 'RetailerCatalogService/getAlibabaRetailerCatalogs';
  public static MOVE_PRODUCTS = 'RetailerCatalogService/moveProducts';
  public static UPDATE_PRICE_TYPE = 'CatalogService/updatePriceType';

  constructor(private restService: RestService, private springRestService: SpringRestService) {}

  public getCatalogsByTaskIds(taskIds): Observable<CatalogModel[]> {
    return this.restService
      .get(CatalogService.GET_CATALOGS_BY_TASKSIDS, { taskIds: taskIds.join(',') })
      .pipe(map((response) => response.getData()));
  }

  public isAllCatalogRejected(): Observable<boolean> {
    return this.restService
      .get(CatalogService.IS_ALL_CATALOG_REJECTED)
      .pipe(map((response) => response.getFirstData().rejected));
  }

  public unlockCatalog(catalogId, privateCode): Observable<boolean> {
    return this.restService
      .post(CatalogService.UNLOCK_SUPPLIER_CATALOG, { catalogId, privateCode })
      .pipe(map(() => true));
  }

  public setStatus(rcatId, status): Observable<boolean> {
    return this.restService
      .post('RetailerCatalogService/setStatus', { rcatId: rcatId, status: status })
      .pipe(map(() => true));
  }

  public moveProdsToOtherCatalog(fromCatalogId, toCatalogId, synceeProductIds): Observable<boolean> {
    const params = { fromCatalogId, toCatalogId, synceeProductIds };
    return this.restService.post(CatalogService.MOVE_PRODUCTS, params).pipe(map(() => true));
  }

  public updateRCatalog(url, task): Observable<any> {
    return this.restService.post(url, task).pipe(map((response) => response.getFirstData()));
  }

  public getGetStartedCatalog(id: number): Observable<any> {
    return this.restService
      .get(`CatalogService/getGetStartedCatalog?id=${id}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getSupplierCatalogById(id: number): Observable<any> {
    return this.restService
      .get(`CatalogService/getCatalogById?id=${id}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getSupplierCatalogs(): Observable<any> {
    return this.restService.get('CatalogService/listMyCatalogs').pipe(map((response) => response.getData()));
  }

  public getEcomActiveCatalogs(ecomId): Observable<any[]> {
    return this.restService
      .get(CatalogService.GET_ECOM_ACTIVE_CATALOGS, { ecomId })
      .pipe(map((response) => response.getData()));
  }

  public addCatalog(catalogVO: CatalogFormVO): Observable<any> {
    return this.restService.post('CatalogService/add', catalogVO).pipe(map((response) => response.getFirstData()));
  }

  public updateCatalogPriceType(type: PriceType, catalogId: number): Observable<void> {
    return this.restService.post(CatalogService.UPDATE_PRICE_TYPE, { type, catalogId }).pipe(map(() => {}));
  }

  public updateCatalog(catalogVO: CatalogSettingsRequest | CatalogFormVO): Observable<any> {
    return this.restService.post('CatalogService/update', catalogVO).pipe(map((response) => response.getFirstData()));
  }

  public deleteCatalog(catalogId: number): Observable<any> {
    return this.restService
      .get(`CatalogService/deleteCatalog?id=${catalogId}`)
      .pipe(map((response) => response.getData()));
  }

  public deleteRetailerCatalog(catalogId: number): Observable<any> {
    return this.restService
      .post('RetailerCatalogService/delete', { catalogId: catalogId })
      .pipe(map((response) => response.getData()));
  }

  public getCatalogTypes(): Observable<any> {
    return this.restService.get('CatalogService/getAllTypes').pipe(map((response) => response.getData()));
  }

  public getRetailerCatalogs(isAlibaba = false): Observable<ImportListItem[]> {
    const restURL = 'RetailerCatalogService/getMyCatalogs' + (isAlibaba ? '?isAlibabaCatalog=true' : '');
    return this.restService.get(restURL).pipe(map((response) => response.getData()));
  }

  public getRetailerCatalogsProductCounts(catalogs: any[], isDatafeed = false): Observable<any> {
    return this.restService
      .post('RetailerCatalogService/getRetailerCatalogsProductCounts', { catalogs: catalogs, datafeed: isDatafeed })
      .pipe(map((response) => response.getData()));
  }

  public getMyDataFeedCatalogs(): Observable<any> {
    return this.restService
      .get('RetailerCatalogService/getMyDataFeedCatalogs')
      .pipe(map((response) => response.getData()));
  }

  public getRetailerCatalogById(id: number): Observable<CatalogDetails> {
    return this.restService.get(`RetailerCatalogService/getCatalogDetails?catalogId=${id}`).pipe(
      this.restService.throwErrorIfNotSuccessOperator(),
      map((response) => response.getFirstData())
    );
  }

  public runRetailerCatalog(catalogId: number, updateFields: string[] = null, isPIMSync = false): Observable<any> {
    const params = { catalogId: catalogId };
    if (!Utils.isNullOrUndefined(updateFields)) {
      params['updateFields'] = updateFields;
    }
    if (isPIMSync) {
      params['isPIMSync'] = true;
    }
    return this.restService
      .post('RetailerCatalogService/runCatalog', params)
      .pipe(map((response) => response.getData()));
  }

  public saveRetailerCatalog(catalogId: number, filter: any[]): Observable<any> {
    return this.restService
      .post(`RetailerCatalogService/saveFilter`, {
        catalogId: catalogId,
        filter: filter,
      })
      .pipe(
        map((response) => {
          return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : response.getData();
        })
      );
  }

  public getRetailerCatalogSettings(catalogId: number): Observable<any> {
    return this.restService
      .get(`RetailerCatalogService/getCatalogSettings?id=${catalogId}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public saveRetailerCatalogSettings(settingsVO: RetailerCatalogSettingsVO): Observable<any> {
    return this.restService.post('RetailerCatalogService/addSettings', settingsVO).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : null;
      })
    );
  }

  public getShopData(catalogId: number, taskId?: any): Observable<any> {
    let params = new HttpParams();
    if (!Utils.isNullOrUndefined(taskId)) {
      params = params.set('taskId', taskId.toString());
    } else {
      params = params.set('catalogId', catalogId.toString());
    }
    return this.restService.get(`ShopDetailsService/getShopData`, params).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData()) ? response.getFirstData() : null;
      })
    );
  }

  public getTaxes(catalogId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getTaxes?catalogId=${catalogId}`).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }

  public saveNewCatalogNotification(): Observable<any> {
    return this.restService.post(`CatalogService/saveNewCatalogNotification`, null).pipe(
      map((response) => {
        return !Utils.isNullOrUndefined(response.getData());
      })
    );
  }

  public getLanguages(catalogId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getLanguages?catalogId=${catalogId}`).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }
  public getStockStatuses(catalogId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getStockStatuses?catalogId=${catalogId}`).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }

  public getVolumeUnits(catalogId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getWeightAndLengthUnits?catalogId=${catalogId}`).pipe(
      map((response) => {
        return response.getFirstData();
      })
    );
  }

  public getCurrencies(catalogId: number): Observable<any> {
    return this.restService.get(`ShopDetailsService/getCurrencies?catalogId=${catalogId}`).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }
  public isCatalogRunning(catalogId): Observable<any> {
    const param = { taskIds: catalogId };
    return this.restService.post(`RetailerCatalogService/isCatalogRunning`, param).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }
  public savePremiumBulk(value: boolean, catalogIds: number[]): Observable<any> {
    const params = {
      isPremium: value,
      catalogIds,
    };
    return this.restService.post('CatalogService/changePremiumCatalog', params).pipe(
      map((resp) => {
        return resp.getData();
      })
    );
  }

  getRetailerCatalogType(catalogDetails: CatalogDetails): RetailerCatalogType {
    return !!catalogDetails.filter && catalogDetails.filter.some((filter) => filter?.key === 'TASK_ID')
      ? RetailerCatalogType.SUPPLIER
      : RetailerCatalogType.SELECTION;
  }

  getRetailerCatalogTypeByFilter(filter: ImportListCatalogFilter[]): RetailerCatalogType {
    return !!filter && filter.some((item) => item?.key === 'TASK_ID')
      ? RetailerCatalogType.SUPPLIER
      : RetailerCatalogType.SELECTION;
  }

  public patchImportList(ecomId: number, importListId: number, body: PatchImportListBody): Observable<ImportListDto> {
    return this.springRestService.patch(
      MicroserviceNames.USER,
      `/Retailer/Catalog/Ecom/${ecomId}/${importListId}`,
      body,
      {},
      true
    );
  }
}

export interface FeedSettings {
  backorder: boolean;
  shipping: boolean;
}

export interface Settings {
  possibleMargin: string;
  language: string;
  shipping_type: string;
  approveNeeded: boolean;
  approveDescription: string;
  needResponse: boolean;
  showPrices: boolean;
  sendEmail: string;
  warehouseLocation: string;
  mainCategories: string[];
  translate_source?: any;
  translate_target?: any;
  feedSettings: FeedSettings;
}

export interface CatalogModel {
  id: string;
  userId: number;
  name: string;
  seoUrl: string;
  type?: any;
  description: string;
  tags: string;
  isHidden: boolean;
  visibility: boolean;
  approved: boolean;
  active: boolean;
  target?: any;
  start: Date;
  end?: any;
  deleted?: any;
  date: Date;
  rounding?: any;
  settings: Settings;
}

export interface ImportListItem {
  isRunning?: boolean;
  lastUpdated?: string;
  products?: number;
  productsInFilter?: number;
  statusCode?: number;
  uploaded?: number;
  type?: string;
  catalogType?: RetailerCatalogType;
  catalog: ImportListCatalogRespDto;
}

export interface ImportListCatalogRespDto {
  id: number;
  user_id: string;
  ecom_id: number;
  task_id?: any;
  alibaba_catalog: string;
  name: string;
  filter: ImportListCatalogFilter[];
  rounding?: any;
  settings: string;
  is_active: number;
  is_default: '1' | '0';
  deleted?: any;
  date: string;
  domain: string;
  ecom_type: string;
}

interface ImportListCatalogFilter {
  key: string;
  condition: string;
  values: string[];
}

export interface CatalogStatus {
  disabled: boolean;
  isRunning: boolean;
  ecomId: number;
}
