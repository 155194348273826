import { E } from '@angular/cdk/keycodes';
import { Directive, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomSpinnerComponent } from 'app/shared/components/custom-spinner/custom-spinner.component';
import { PermissionFallbackComponent } from 'app/shared/components/permission-fallback/permission-fallback.component';
import { Subscription } from 'rxjs';
import { PermissionService } from './permission.service';
import { SCOPES } from './scopes';

@Directive({
  selector: '[appHasPermissions]',
  standalone: true,
})
export class PermissionDirective implements OnDestroy, OnInit {
  private hasView = false;
  private hasFallback = false;
  subscription: Subscription;
  permissions: SCOPES[];

  @Input()
  set appHasPermissions(permissions: SCOPES[]) {
    this.permissions = permissions;
  }

  @Input()
  set appHasPermissionsHasFallback(fallback: boolean) {
    this.hasFallback = fallback;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.subscription = this.permissionService.hasPermissions(...this.permissions).subscribe((has) => {
      if (has) {
        this.showComponent();
      } else {
        this.showFallback();
      }
    });
  }

  showFallback(): void {
    if (!this.hasFallback) {
      return;
    }
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(PermissionFallbackComponent);
    this.hasView = false;
  }

  showComponent(): void {
    if (this.hasView) {
      return;
    }

    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }

  removeComponent(): void {
    if (this.hasFallback) {
      this.showFallback();
      return;
    }
    this.viewContainerRef.clear();
    this.hasView = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
