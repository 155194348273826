import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HistoryComponent } from './history.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [HistoryComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FuseSharedModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
  ],
})
export class HistoryModule {}
