import { Component, Input } from '@angular/core';
import { TagOption } from '../../model/tag-option';

@Component({
  selector: 'app-tag-selector-item',
  templateUrl: './tag-selector-item.component.html',
  styleUrls: ['./tag-selector-item.component.scss'],
})
export class TagSelectorItemComponent<T> {
  @Input() option: TagOption<T>;

  @Input() checked = false;

  @Input() disabled = false;
}
