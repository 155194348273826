import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { locale as en } from '../language-files/i18n/en';

export class MissingHandler extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    const jsonPath = params.key.split('.');

    return this.insertValues(jsonPath, params);
  }

  private insertValues(jsonPath: string[], params: MissingTranslationHandlerParams): string {
    let rawTranslationValue = this.getTranslation(jsonPath, en.data);
    if (!!params.interpolateParams) {
      rawTranslationValue = this.replaceInterpolateVariables(params, rawTranslationValue);
    }

    return rawTranslationValue;
  }

  private replaceInterpolateVariables(params: MissingTranslationHandlerParams, rawTranslationValue: string): string {
    Object.keys(params.interpolateParams).forEach((key) => {
      const interpolateRegex = new RegExp(`\{\{\\s*${key}\\s*}}`, 'g');
      rawTranslationValue = rawTranslationValue.replace(interpolateRegex, params.interpolateParams[key]);
    });

    return rawTranslationValue;
  }

  private getTranslation(params: string[], json: object): string {
    if (params.length === 1) {
      return !!json ? json[params[0]] : '';
    }

    return this.getTranslation(params.slice(1), json[params[0]]);
  }
}
