<form id="URLUploadCard" #urlDownloadFileForm="ngForm" novalidate class="simple-form">
  <div class="h5 mb-20">{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.SOURCE' | translate }}</div>
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="8px">
    <!--URL-->
    <mat-form-field fxFlex="0 1 400px" id="urlStringInput" appearance="fill" class="select-without-label m-w-0">
      <input
        id="urlString"
        name="url"
        matInput
        [placeholder]="'TASKWIZARD.ADDFILES.URL_UPLOAD.URL_PLACEHOLDER' | translate"
        #urlString="ngModel"
        [(ngModel)]="remotePath"
        required
        class="user-input"
      />
      <mat-error>{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.URL_PLACEHOLDER' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="1 0 0" id="fileTypeSelect" appearance="fill" class="select-without-label">
      <mat-select
        [disabled]="disableFileTypeSelector"
        placeholder="File type"
        [(ngModel)]="urlCredentials.extension"
        name="type"
        (valueChange)="typeChanged($event)"
      >
        <mat-option *ngFor="let fileExt of fileExtensions" [value]="fileExt.type">
          {{ fileExt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="8px" class="m-w-0">
    <!--UserName Input-->
    <mat-form-field id="urlUserNameInput" fxFlex="1 0 0" appearance="fill" class="select-without-label m-w-0">
      <input
        id="urlUserName"
        name="name"
        matInput
        placeholder="{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.USERNAME' | translate }}"
        [(ngModel)]="userName"
        class="user-input"
      />
    </mat-form-field>
    <!--Password Input-->
    <mat-form-field id="urlPasswordInput" fxFlex="1 0 0" appearance="fill" class="select-without-label m-w-0">
      <input
        id="urlPassword"
        name="password"
        matInput
        type="password"
        autocomplete="new-password"
        placeholder="{{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.PASSWORD' | translate }}"
        [(ngModel)]="password"
      />
    </mat-form-field>
  </div>
  <div id="downloadButton" fxLayoutAlign="end center">
    <button
      [disabled]="urlDownloadFileForm.invalid"
      mat-button
      class="syncee-light-blue-button"
      (click)="downloadFile()"
    >
      {{ 'TASKWIZARD.ADDFILES.URL_UPLOAD.UPLOAD' | translate }}
    </button>
  </div>
</form>
