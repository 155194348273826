import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-universal-badge',
  templateUrl: './universal-badge.component.html',
  styleUrls: ['./universal-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule],
})
export class UniversalBadgeComponent implements OnInit {
  constructor() {}

  // approve, auto, beta
  @Input() badgeType: 'beta' | 'auto' | 'approve';
  @Input() approveStatus = 'unset';
  @Input() tooltipShown = false;

  ngOnInit(): void {}

  public goToHelpURL(urlString: string): void {
    window.open(urlString, '_blank');
  }
}
