import { Component, Input, OnInit } from '@angular/core';
import { MarketplaceFilter } from '../../../../../vo/search-product-vo';
import { AddFilterWithRedirectAction } from '../../../../../store/product-search/product-search.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { ToolbarConfig, ToolbarExtraIcon } from '../../../../../../@fuse/types';
import { AiChatService } from '../../../../../main/ai-chat/services/ai-chat.service';
import { SCOPES } from '../../../../../service/permission/scopes';

@Component({
  selector: 'app-desktop-toolbar',
  templateUrl: './desktop-toolbar.component.html',
  styleUrls: ['./desktop-toolbar.component.scss'],
})
export class DesktopToolbarComponent implements OnInit {
  @Input() role: number;
  @Input() extraIcons: ToolbarExtraIcon[];
  @Input() needRedirect = true;
  @Input() needCategorySelector = true;
  @Input() hasSearchBar: boolean;
  @Input() hasAiChat: boolean;
  @Input() config: ToolbarConfig;
  scopes = SCOPES;

  constructor(private store: Store<AppState>, public aiChatService: AiChatService) {}

  ngOnInit(): void {}

  handleFilterSelected(filter: MarketplaceFilter): void {
    this.store.dispatch(new AddFilterWithRedirectAction({ filter: filter, needRedirect: this.needRedirect }));
  }
}
