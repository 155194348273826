<div class="m-20" *ngIf="historyElements">
  <!--<mat-card class="fuse-card m-20">-->
  <mat-card-header>
    <mat-card-title fxLayout="row wrap" fxLayoutAlign="start center">
      <!--{{'TASKS.HISTORY.TASK_HISTORY' | translate}}-->Task History
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
      <mat-card class="history-item" *ngFor="let historyItem of historyElements">
        <mat-card-content fxLayout="column" fxLayoutGap="10px">
          <div class="history-date-wrapper">
            <div>{{ historyItem.date | date : 'medium' }}</div>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let item of menus" (click)="menuItemClick(item.label, taskId)">
                <mat-icon>{{ item.icon }}</mat-icon>
                <span>{{ item.label }}</span>
              </button>
            </mat-menu>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div *ngFor="let result of historyItem.results">
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                <div>{{ result.name }}</div>
                <ng-container [ngSwitch]="result.name">
                  <div *ngSwitchCase="'uploaded'">
                    <span [ngStyle]="{ color: 'green' }">{{ result.value }}</span>
                  </div>
                  <div *ngSwitchCase="'updated'">
                    <span [ngStyle]="{ color: 'blue' }">{{ result.value }}</span>
                  </div>
                  <div *ngSwitchCase="'deleted'">
                    <span [ngStyle]="{ color: 'red' }">{{ result.value }}</span>
                  </div>
                  <div *ngSwitchCase="'not modified'">
                    <span [ngStyle]="{ color: 'grey' }">{{ result.value }}</span>
                  </div>
                  <div *ngSwitchDefault>
                    {{ result.value }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
  <!--</mat-card>-->
</div>

<div *ngIf="!historyElements || historyElements.length == 0">
  <!--<h1>{{'TASKS.HISTORY.THIS_TASK' | translate}}</h1>-->This task has no history yet!
</div>
