import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-supplier-simple-item',
  templateUrl: './supplier-simple-item.component.html',
  styleUrls: ['./supplier-simple-item.component.scss'],
  imports: [FlexModule],
  standalone: true,
})
export class SupplierSimpleItemComponent {
  @Input() name: string;
  @Input() imageUrl: string;
  @Output() filterClick: EventEmitter<void> = new EventEmitter<void>();

  handleItemClicked(): void {
    this.filterClick.emit();
  }
}
