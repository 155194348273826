import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, startWith, take } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'app/utils/utils';
import { StepBase } from 'app/main/taskwizard/step-base';
import { CategoryMappingService } from 'app/service/category-mapping/category-mapping.service';
import { ConfirmationDialogsService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { CategoryTreeSelectorComponent } from 'app/shared/components/category-mapping/category-tree-selector/category-tree-selector.component';
import { CategoryMappingProductExampleDialogComponent } from 'app/shared/components/category-mapping/category-mapping-product-example-dialog/category-mapping-product-example-dialog.component';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { SearchbarCustomComponent } from '../searchbar-custom/searchbar-custom.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-retailer-category-mapping',
  templateUrl: './retailer-category-mapping.component.html',
  styleUrls: ['./retailer-category-mapping.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    SearchbarCustomComponent,
    LoadingSpinnerComponent,
  ],
})
export class RetailerCategoryMappingComponent implements AfterViewInit, StepBase, OnDestroy {
  public selectedCategory: string;

  public synceeCategories: any[] = [];
  public productCategories: any[] = [];

  private mappingValues: any = {};

  private subscriptions = new Subscription();

  filterChangeCtrl = new UntypedFormControl();
  filterPCCtrl = new UntypedFormControl();
  filterSCCtrl = new UntypedFormControl();
  filteredPCValues: Observable<any[]>;
  public isBulkEdit = false;
  public showEmptyMapping = false;
  categorySelection = new SelectionModel<any>(true, []);

  public title: string;

  @Input() showSaveButton = true;
  @Input() rcatalogId: number;
  @Input() domain: string;
  @Input() comments: any;
  @Input() taskId: number;
  @Input() ecomType: string;

  constructor(
    private categoryMappingService: CategoryMappingService,
    private _translateService: TranslateService,
    private confirmDialog: ConfirmationDialogsService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.loadProductCategories();
  }

  reInit(): void {
    this.loadProductCategories();
  }

  readEcomTypeFromUrl(): void {
    this.ecomType = this.activatedRoute.snapshot.paramMap.get('ecomType');
  }

  private loadProductCategories(): void {
    this.subscriptions.add(
      this.categoryMappingService.getRetailerCategoryMappingData(this.rcatalogId).subscribe((response) => {
        this.title = response.title;
        this.categoryMappingService.shopCategories = response.shopCategories;

        this.productCategories = response.productCategories.map((category) => new ProductCategory(category));
        this.synceeCategories = response.shopCategories;

        this.setFilteredValuesChange();
        this.loadCategoryMapping();
      })
    );
  }

  private loadCategoryMapping(): void {
    this.categoryMappingService.alreadyMappedCategories = [];
    this.subscriptions.add(
      this.categoryMappingService.getRetailerCategoryMapping(this.rcatalogId).subscribe((response) => {
        if (response) {
          response.forEach((element) => {
            const handle = Object.keys(element)[0];
            element[handle].forEach((el) => {
              this.addBreadcrumb(handle, el);
              if (!this.categoryMappingService.alreadyMappedCategories.includes(el)) {
                this.categoryMappingService.alreadyMappedCategories.push(el);
              }
            });
          });
        }
        this.showSaveButton = true;
      })
    );
  }

  private getMappingVoToSend(): any {
    const mappingValues = JSON.parse(JSON.stringify(this.mappingValues));
    this.mappingValues = {};
    return { catalogId: this.rcatalogId, mapping: mappingValues };
  }

  private addBreadcrumb(category, collectionId): void {
    const breadcrumb = this.synceeCategories.find((elem) => {
      return elem.id == collectionId;
    });
    if (breadcrumb) {
      this.productCategories.map((item) => {
        if (
          (item.name.name.toLocaleLowerCase() === category.toLocaleLowerCase() || item.name.name === category) &&
          !item.breadcrumbs.includes(breadcrumb.title) &&
          typeof breadcrumb.title === 'string'
        ) {
          item.breadcrumbs.push(breadcrumb.title);
        }
        return item;
      });
    }
  }

  removeBreadcrumb(category: string, breadcrumb: string): void {
    this.productCategories.map((item) => {
      if (item.name.name === category) {
        const index = item.breadcrumbs.indexOf(breadcrumb);
        item.breadcrumbs.splice(index, 1);
      }
      return item;
    });
  }

  isStepValid(): boolean {
    return true;
  }

  saveStep(): Observable<any> {
    this.convertPathToCategoryId();

    if (this.ecomType && this.ecomType.toLowerCase() === 'shoprenter') {
      this.checkForShoprenterAllMapped();
    }

    return this.categoryMappingService.saveRetailerCategoryMapping(this.getMappingVoToSend());
  }

  private convertPathToCategoryId(): void {
    this.productCategories.forEach((item) => {
      item.breadcrumbs.forEach((elem) => {
        const id = this.synceeCategories.find((category) => category.title === elem).id;
        if (this.mappingValues[item.name.name]) {
          this.mappingValues[item.name.name].push(id);
        } else {
          this.mappingValues[item.name.name] = [];
          this.mappingValues[item.name.name].push(id);
        }
      });
    });
  }

  private checkForShoprenterAllMapped(): void {
    if (this.productCategories.length !== Object.keys(this.mappingValues).length) {
      this.confirmDialog
        .confirmWithoutCancel(
          this._translateService.instant('RETAILER_CATEGORY_MAPPING.SHOPRENTER_MISSING_CATEGORY_MAPPING_TITLE'),
          Object.keys(this.mappingValues).length === 0
            ? this._translateService.instant(
                'RETAILER_CATEGORY_MAPPING.NO_CATEGORY_MAPPING.' + this.ecomType.toUpperCase()
              )
            : this._translateService.instant(
                'RETAILER_CATEGORY_MAPPING.MISSING_CATEGORY_MAPPING.' + this.ecomType.toUpperCase()
              )
        )
        .subscribe();
    }
  }

  public saveCategoryMapping(): Observable<any> {
    this.convertPathToCategoryId();
    if (
      this.ecomType &&
      (this.ecomType.toLowerCase() === 'shoprenter' || this.ecomType.toLowerCase() === 'bigcommerce')
    ) {
      this.checkForShoprenterAllMapped();
    }
    if (this.isBulkEdit) {
      this.turnOnOffBulk();
    }
    return this.categoryMappingService.saveRetailerCategoryMapping(this.getMappingVoToSend());
  }

  ngOnDestroy(): void {
    if (!Utils.isNullOrUndefined(this.subscriptions)) {
      this.subscriptions.unsubscribe();
    }
  }

  setFilteredValuesChange(): void {
    this.filteredPCValues = this.filterChangeCtrl.valueChanges.pipe(
      startWith(null),
      map((_: string | boolean | null) => {
        if (this.filterPCCtrl.value || this.filterSCCtrl.value || this.showEmptyMapping) {
          return this._filter();
        } else {
          return this.productCategories;
        }
      })
    );
  }

  filterChanged(): void {
    this.filterChangeCtrl.setValue(true);
  }

  private _filter(): string[] {
    const searchValuePC = !Utils.isNullOrUndefined(this.filterPCCtrl.value)
      ? this.filterPCCtrl.value.toLowerCase()
      : null;
    if (this.showEmptyMapping) {
      return this.productCategories.filter(
        (value) =>
          (Utils.isNullOrUndefined(searchValuePC) ||
            value.name.name.toLowerCase().indexOf(searchValuePC.toLowerCase()) > -1) &&
          value.breadcrumbs.length === 0
      );
    } else {
      const searchValueSC = !Utils.isNullOrUndefined(this.filterSCCtrl.value)
        ? this.filterSCCtrl.value.toLowerCase()
        : null;
      return this.productCategories.filter((value) => {
        console.log(value);
        return (
          (Utils.isNullOrUndefined(searchValuePC) ||
            value.name.name.toLowerCase().indexOf(searchValuePC.toLowerCase()) > -1) &&
          (Utils.isNullOrUndefined(searchValueSC) ||
            value.breadcrumbs.join('|').toLowerCase().indexOf(searchValueSC.toLowerCase()) > -1)
        );
      });
    }
  }

  public addCategory(category): void {
    const dialogRef = this.dialog.open(CategoryTreeSelectorComponent, {
      width: '500px',
      data: {
        isCollectionList: true,
        selectedCategory: category.name.name,
        categories: this.synceeCategories,
        all: this.synceeCategories,
        preSelectedCategories: category.breadcrumbs,
        ecomType: this.ecomType.toLowerCase(),
      },
    });

    dialogRef.afterClosed().subscribe((elem) => {
      if (elem) {
        this.productCategories.map((item) => {
          if (
            item.name.name.toLocaleLowerCase() === category.name.name.toLocaleLowerCase() ||
            item.name === category.name.name
          ) {
            item.breadcrumbs = elem;
          }
          return item;
        });
      }
    });
  }

  public openBulkEditMode(): void {
    const dialogRef = this.dialog.open(CategoryTreeSelectorComponent, {
      width: '500px',
      data: {
        isCollectionList: true,
        categories: this.synceeCategories,
      },
    });

    dialogRef.afterClosed().subscribe((elem) => {
      if (elem) {
        this.productCategories.map((item) => {
          if (this.categorySelection.selected.indexOf(item.name) > -1) {
            if (this.ecomType.toLowerCase() === 'squarespace' || this.ecomType.toLowerCase() === 'ekm') {
              item.breadcrumbs = [];
            }
            item.breadcrumbs.push(...elem);
            item.breadcrumbs = item.breadcrumbs.filter(function (value, index, array): boolean {
              return array.indexOf(value) == index;
            });
          }
          return item;
        });
      }
    });
  }

  public openBulkRemoveMode(): void {
    const bulkRemoveItems = this.productCategories.filter(
      (elem) => this.categorySelection.selected.indexOf(elem.name) > -1
    );
    const dialogRef = this.dialog.open(CategoryTreeSelectorComponent, {
      width: '500px',
      data: {
        isCollectionList: true,
        isRemove: true,
        bulkRemoveItems: bulkRemoveItems,
      },
    });

    dialogRef.afterClosed().subscribe((paths) => {
      if (paths) {
        this.productCategories.map((item) => {
          if (this.categorySelection.selected.indexOf(item.name) > -1) {
            item.breadcrumbs = item.breadcrumbs.filter((breadcrumb) => paths.indexOf(breadcrumb) === -1);
          }
          return item;
        });
      }
    });
  }

  public selectAllSelection(): void {
    this.filteredPCValues.pipe(take(1)).subscribe((categories) => {
      categories.forEach((elem) => {
        if (!this.categorySelection.isSelected(elem.name)) {
          this.categorySelection.select(elem.name);
        }
      });
    });
  }

  public clearCategoryMapping(): void {
    this.productCategories.map((item) => {
      if (this.categorySelection.selected.indexOf(item.name) > -1) {
        item.breadcrumbs = [];
      }
      return item;
    });
  }

  public turnOnOffBulk(): void {
    this.isBulkEdit = !this.isBulkEdit;
    this.clearSelection();
  }

  public selectCategory(event, item): void {
    if (event.checked) {
      if (!this.categorySelection.isSelected(item)) {
        this.categorySelection.select(item);
      }
    } else {
      if (this.categorySelection.isSelected(item)) {
        this.categorySelection.deselect(item);
      }
    }
  }

  public openProductList(categoryName): void {
    if (this.taskId) {
      this.dialog.open(CategoryMappingProductExampleDialogComponent, {
        width: '500px',
        data: {
          taskId: this.taskId,
          productCategory: categoryName.name,
          productCategoryId: categoryName.id,
        },
      });
    } else {
      this.dialog.open(CategoryMappingProductExampleDialogComponent, {
        width: '500px',
        data: {
          catalogId: this.rcatalogId,
          productCategory: categoryName.name,
          productCategoryId: categoryName.id,
        },
      });
    }
  }

  public clearSelection(): void {
    this.categorySelection.clear();
  }

  public showEmpty(): void {
    this.showEmptyMapping = true;
    this.filterSCCtrl.reset();
    this.filterChangeCtrl.setValue(true);
  }

  public showAll(): void {
    this.showEmptyMapping = false;
    this.filterChangeCtrl.setValue(true);
  }
}

class ProductCategory {
  name: any;
  breadcrumbs: string[];

  constructor(name: any) {
    this.name = name;
    this.breadcrumbs = [];
  }
}
