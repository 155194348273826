import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { sortBy } from 'lodash';
import { RestService } from '../../../service/rest/rest.service';
import { GettingStartedStepDto } from '../model/getting-started-step-dto';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { GettingStartedCompletedStepsStartAction } from '../../../store/getting-started/getting-started.actions';
import { MicroserviceNames, SpringRestService } from '../../../service/rest/microservices/spring-rest.service';
import { AddStepsToUserData } from '../model/add-steps-to-user-data';
import { GettingStartedStepsUserDto } from '../model/getting-started-steps-user-dto';

@Injectable({
  providedIn: 'root',
})
export class GettingStartedStepsService {
  constructor(
    private restService: RestService,
    private springRestService: SpringRestService,
    private store: Store<AppState>
  ) {}

  getStepsForUser(appType: number, uId: number, version: number = 1): Observable<GettingStartedStepDto[]> {
    return this.restService
      .get('GettingStartedService/getStepsForUser', { appType, uId, version })
      .pipe(map((response) => sortBy(response.getData(), 'index')));
  }

  getAllSteps(appType: number, version: number = 1): Observable<GettingStartedStepDto[]> {
    return this.restService
      .get('GettingStartedService/getSteps', { appType, version })
      .pipe(map((response) => sortBy(response.getData(), 'index')));
  }

  addStepsToUser(data: AddStepsToUserData[]): Observable<void> {
    return this.springRestService.post(MicroserviceNames.USER, '/GettingStarted/AddStepsToUser', data, {}, true).pipe(
      map(() => void 0),
      tap(() => this.store.dispatch(new GettingStartedCompletedStepsStartAction()))
    );
  }

  deleteStepsAdmin(userId: string, stepIds: number[]): Observable<void> {
    return this.springRestService.delete(
      MicroserviceNames.USER,
      '/GettingStarted/StepsForUser',
      {
        userId,
        stepIds,
      },
      true
    );
  }

  addStepsAdmin(userId: string, stepIds: number[]): Observable<void> {
    return this.springRestService.post(
      MicroserviceNames.USER,
      '/GettingStarted/StepsForUser',
      {
        userId,
        dtos: stepIds.map((stepId: number) => ({
          stepId,
          skip: false,
        })),
      },
      {},
      true
    );
  }

  getUsersSteps(userIds: number[], appType: number, version: number): Observable<GettingStartedStepsUserDto[]> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      '/GettingStarted/StepsForUsers',
      {
        userIds,
        appType,
        version,
      },
      true
    );
  }
}
