<div *ngIf="!!items" class="select-button">
  <div
    *ngFor="let item of items"
    class="item"
    (click)="selectedItem !== item.value && selectedItemChange.emit(item.value)"
    [class.selected]="selectedItem === item.value"
  >
    {{ item.label | translateConditionally : translateValue }}
  </div>
</div>
