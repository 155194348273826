import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-products-added',
  templateUrl: './no-products-added.component.html',
  styleUrls: ['./no-products-added.component.scss'],
})
export class NoProductsAddedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
