<ng-content></ng-content>

<div class="general-button-container">
  <button mat-button class="syncee-black-flat-button" (click)="navigateBackToMyTasks()" *ngIf="isFirstStep()">
    {{ 'STEP_WRAPPER.BACK' | translate }}
  </button>
  <button mat-button class="syncee-black-flat-button" (click)="goToPreviousStep()" *ngIf="!isFirstStep()">
    {{ 'STEP_WRAPPER.PREVIOUS' | translate }}
  </button>
  <button
    mat-button
    class="syncee-blue-button"
    (click)="isStepValid() ? saveAndGoToNextStep() : triggerValidation()"
    [disabled]="!isStepValid() && !component?.triggerValidation"
  >
    {{ getNextButtonLabel() }}
  </button>
</div>
