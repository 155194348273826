import { LanguageService } from 'app/service/language/language.service';
import { LANGUAGE_ACTIONS_ALL, LanguageActionTypes, LanguageOption } from './language.actions';

export interface LanguageState {
  languages: LanguageOption[];
  selectedLanguageCode: string;
}

const initialState = (): LanguageState => {
  return {
    languages: languages,
    selectedLanguageCode: LanguageService.getLanguageFromStorage(),
  };
};

export function languageReducer(state = initialState(), action: LANGUAGE_ACTIONS_ALL): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.USER_LANGUAGE_CHANGE_SUCCESS:
    case LanguageActionTypes.GUEST_LANGUAGE_CHANGE_SUCCESS: {
      return {
        ...state,
        selectedLanguageCode: action.payload.countryCode,
      };
    }
    default: {
      return state;
    }
  }
}

export const languages: LanguageOption[] = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'hu',
    name: 'Magyar',
  },
  {
    code: 'de',
    name: 'Deutsche',
  },
  {
    code: 'es',
    name: 'Español',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'it',
    name: 'Italiano',
  },
  {
    code: 'pt',
    name: 'Português',
  },
  {
    code: 'zh',
    name: '中文',
  },
];
