
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    hasArrow = false;

    @Input()
    navigation: any;

    @Output() itemClicked = new EventEmitter<void>();

    isUserAdmin = false;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.isUserAdmin = this.checkIfUserIsAdmin();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        if (!this.navigation) {
            // Load the navigation from the service
            this.navigation = this._fuseNavigationService.getCurrentNavigation();

            // Subscribe to the current navigation changes
            this._fuseNavigationService.onNavigationChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(() => {

                  // Load the navigation
                  this.navigation = this._fuseNavigationService.getCurrentNavigation();

                  // Mark for check
                  this._changeDetectorRef.markForCheck();
              });

            // Subscribe to navigation item
            merge(
              this._fuseNavigationService.onNavigationItemAdded,
              this._fuseNavigationService.onNavigationItemUpdated,
              this._fuseNavigationService.onNavigationItemRemoved
            ).pipe(takeUntil(this._unsubscribeAll))
              .subscribe(() => {

                  // Mark for check
                  this._changeDetectorRef.markForCheck();
              });
        }
    }

    private checkIfUserIsAdmin(): boolean {
        const user =  JSON.parse(localStorage.getItem('currentUser'));
        return user && user.role === 3;
    }
}
