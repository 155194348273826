<div fxLayout="column" class="h-100-p">
  <ng-container *conditionLoading="!isLoading">
    <ng-container *ngIf="!hasError; else errorMessage">
      <ng-container *ngIf="!!fetchedItems.length; else noResults">
        <div fxLayout="column" fxLayoutGap="12px">
          <mat-card class="activity-log-mobile">
            <ng-container *ngFor="let item of displayedItems; let isLast = last">
              <div class="p-20" fxLayout="column" fxLayoutGap="12px">
                <div fxLayout="column" fxLayoutGap="4px">
                  <h5 class="my-0">
                    {{ item.type }}
                  </h5>
                  <div class="typography-body-2">
                    {{ item.date | customDate }}
                  </div>
                </div>
                <div>
                  {{ item.message }}
                  <a [routerLink]="item?.path" [queryParams]="item?.params">
                    {{ item.linkText }}
                  </a>
                </div>
              </div>
              <app-custom-divider *ngIf="!isLast"></app-custom-divider>
            </ng-container>
          </mat-card>
          <mat-paginator
            [pageIndex]="pagination.page"
            [pageSize]="pagination.size"
            [length]="pagination.total"
            [pageSizeOptions]="pageSizeOptions"
            (page)="handlePageChange($event)"
          ></mat-paginator>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #noResults>
  <mat-card class="centered-text" fxLayoutGap="column" fxLayoutAlign="center center" fxFlex="1 0 0">
    <div [style.color]="'var(--app-syncee-grey-500)'">
      There is no activity in the selected period of time for the selected activity type.
    </div>
  </mat-card>
</ng-template>

<ng-template #errorMessage>
  <mat-card fxLayoutGap="column" fxLayoutAlign="center center" fxFlex="1 0 0">
    <app-error-message></app-error-message>
  </mat-card>
</ng-template>
