<div class="my-suppliers-wrapper" fxFlex="1 0 0" fxLayout="column" fxLayoutGap="32px">
  <div
    [fxLayout]="(pageService.ltMd$ | async) ? 'column' : 'row'"
    [fxLayoutGap]="(pageService.ltMd$ | async) ? '24px' : '12px'"
    fxLayoutAlign="space-between"
  ></div>

  <!-- (searchTermChange)="handleSearchChange($event)" -->

  <ng-container *ngIf="!(pageService.hasError | async); else error">
    <ng-container [ngSwitch]="pageService.ltMd$ | async">
      <app-my-suppliers-table
        *ngSwitchCase="false"
        [content]="pageService.content$ | async"
        [size]="pageService.pagination.size"
        [page]="pageService.page$ | async"
        [allItemsCount]="pageService.totalCount$ | async"
        [hasAccessToContacts]="hasAccessToReadContacts"
        (nextClicked)="pageService.next()"
        (previousClicked)="pageService.previous()"
        (sizeChanged)="pageService.setPageSize($event)"
        [noResultComponent]="!!(pageService.noResults | async) ? noResultsComponent : noSuppliersYet"
        [loading]="pageService.loading$ | async"
        (searchTermChange)="handleSearchChange($event)"
        (approvalFilterChange)="handleFilterChange($event)"
      ></app-my-suppliers-table>

      <ng-container *ngSwitchCase="true">
        <div [ngStyle]="{ maxWidth: '400px' }" [ngStyle.lt-md]="{ maxWidth: '100%' }">
          <!-- (searchTermChange)="handleSearchChange($event)" -->

          <app-searchbar-custom
            #searchBar
            [hasClearButton]="!!searchBar.searchTerm.length"
            (searchTermChange)="handleSearchChange($event)"
          ></app-searchbar-custom>
        </div>

        <ng-container *conditionLoading="!(pageService.loading$ | async)">
          <!-- *ngIf="supplierData && !!supplierData.length; else noSearchResults ? noResultsComponent : noSuppliersYet" -->
          <ng-container *ngIf="true">
            <ng-container [ngTemplateOutlet]="mobileView"></ng-container>
          </ng-container>
        </ng-container>

        <mat-paginator
          (page)="pageService.setPage($event)"
          [pageIndex]="pageService.page$ | async"
          [pageSize]="pageService.pagination.size"
          [length]="pageService.totalCount$ | async"
          [pageSizeOptions]="[5, 10, 20]"
        ></mat-paginator>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #noSuppliersYet>
  <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="20px" class="centered">
    <app-no-search-results
      icon="people_alt"
      titleKey="MY_SUPPLIERS.NO_SUPPLIERS_ADDED.TITLE"
      descriptionKey="MY_SUPPLIERS.NO_SUPPLIERS_ADDED.INFO"
      [button]="{
        type: 'inner_redirect',
        textKey: 'MY_SUPPLIERS.NO_SUPPLIERS_ADDED.BUTTON_TEXT',
        buttonClass: 'syncee-blue-button',
        url: MARKETPLACE_URL,
        queryParams: {
          category: '1',
          searchType: 'SUPPLIERS'
        }
      }"
    ></app-no-search-results>
  </div>
</ng-template>

<ng-template #noResultsComponent>
  <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="8px" class="centered centered-text">
    <app-no-search-results icon="people_alt" descriptionKey="MY_SUPPLIERS.NO_RESULTS.INFO"></app-no-search-results>
  </div>
</ng-template>

<ng-template #error>
  <div fxFlex="1 0 0" fxLayout="column" fxLayoutGap="20px" class="centered">
    <app-error-message></app-error-message>
  </div>
</ng-template>

<ng-template #mobileView>
  <app-my-suppliers-mobile
    [content]="pageService.content$ | async"
    [hasAccessToReadContacts]="true"
    [hasAccessToChat]="true"
  ></app-my-suppliers-mobile>
</ng-template>
<ng-template #inviteButton>
  <button (click)="handleInviteButtonClick()" mat-flat-button color="primary">
    {{ 'MY_SUPPLIERS.ACTIONS.INVITE_NEW_SUPPLIER' | translate }}
  </button>
</ng-template>
