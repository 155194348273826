import { Injectable } from '@angular/core';
import { Review, ReviewRate, ReviewRateWeighted } from '../../../vo/review/review';
import { StarRatings } from '../model/helper/star-ratings';
import { chain } from 'lodash';
import { WeightedAverageItem } from '../model/helper/weighted-average-item';
import { ReviewInfoDto } from '../../../vo/review/review-info-dto';

@Injectable({
  providedIn: 'any',
})
export class ReviewHelperService {
  constructor() {}

  calculateRatingForReviewItem(rates: ReviewRate[]): number {
    const ratings = rates.map((rate) => rate.rate);
    return this.getAverage(ratings);
  }

  roundToInteger(number: number): number {
    return Math.round(number);
  }

  countStars(rates: number[]): StarRatings {
    const stars: StarRatings = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    Object.keys(stars).forEach((key) => {
      stars[key] = rates.filter((rate) => rate === Number(key)).length;
    });
    return stars;
  }

  calculateRatings(reviews: Review[]): number[] {
    const ratings: number[] = [];
    reviews.forEach((review) => {
      ratings.push(this.roundToInteger(this.calculateRatingForReviewItem(review.reviews)));
    });
    return ratings;
  }

  // TODO: nem súlyoz
  getRatingsByType(reviews: ReviewRate[]): ReviewRateWeighted[] {
    return chain(reviews)
      .groupBy('reviewType')
      .values()
      .map((value) => ({
        reviewType: value[0].reviewType,
        count: value.length,
        rate: this.getAverage(value.map((val) => val.rate)),
      }))
      .value();
  }

  getAverage(numbers: number[]): number {
    const sum = numbers.reduce((a, b) => a + b, 0);
    const average = sum / numbers.length || 0;
    return this.round(average);
  }

  getWeightedAverage(items: WeightedAverageItem[]): number {
    const allCount = items.reduce((a, b) => a + b.count, 0);
    const average = items.reduce((a, b) => a + b.count * b.value, 0) / allCount;
    return this.round(average);
  }

  private round(value: number): number {
    return Number((Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2));
  }

  getCountSum(reviews: ReviewInfoDto[]): number {
    return Math.max(...reviews.map((item) => item.reviewCount));
  }
}
