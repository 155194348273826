import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirects } from './app.redirects';
import { ActivityLogComponent } from './main/activity-log/activity-log.component';
import { AlibabaRefreshTokenComponent } from './main/alibaba-new/alibaba-refresh-token/alibaba-refresh-token.component';
import { CrossLoginComponent } from './main/authentication/cross-login/cross-login.component';
import { ChatAppContainerComponent } from './main/chat/chat/chat-app-container/chat-app-container.component';
import { RetailerDashboardComponent } from './main/dashboard/retailer-dashboard/retailer-dashboard.component';
import { SupplierDashboardComponent } from './main/dashboard/supplier-dashboard/supplier-dashboard.component';
import { EcwidDfTaskWizardComponent } from './main/ecwid-df-task-wizard/ecwid-df-task-wizard.component';
import { FavoriteProductsComponent } from './main/favorites-page/favorite-products/favorite-products.component';
import { FollowedSuppliersComponent } from './main/favorites-page/followed-suppliers/followed-suppliers.component';
import { FtpComponent } from './main/ftp/ftp.component';
import { JumpsellerDfTaskWizardComponent } from './main/jumpseller-df-task-wizard/jumpseller-df-task-wizard.component';
import { ExploreProductsComponent } from './main/marketplace/explore-products/explore-products.component';
import { RetailerExportTaskWizardComponent } from './main/retailer-export-task-wizard/retailer-export-task-wizard.component';
import { RetailerJumpsellerVuTaskWizardComponent } from './main/retailer-jumpseller-vu-task-wizard/retailer-jumpseller-vu-task-wizard.component';
import { RetailerTaskWizardComponent } from './main/retailer-task-wizard/retailer-task-wizard.component';
import { RetailerVariantsUpdateTaskWizardComponent } from './main/retailer-variants-update-task-wizard/retailer-variants-update-task-wizard.component';
import { SetupGuidePageComponent } from './main/setup-guide/setup-guide-page/setup-guide-page.component';
import { ShoprenterDfTaskWizardComponent } from './main/shoprenter-df-task-wizard/shoprenter-df-task-wizard.component';
import { STATIC_PAGE_ROUTES } from './main/static/static-page.routes';
import { HistoryComponent } from './main/tasks/history/history.component';
import { ProductViewComponent } from './main/tasks/product-view/product-view.component';
import { TasksComponent } from './main/tasks/tasks.component';
import { CsvSettingsComponent } from './main/taskwizard/addfiles/file-settings/csv-settings.component';
import { FileUploadComponent } from './main/taskwizard/addfiles/file-upload/file-upload.component';
import { TaskwizardComponent } from './main/taskwizard/taskwizard.component';
import { ThankYouPageShopifyComponent } from './main/thank-you-page-shopify/thank-you-page-shopify.component';
import { SupplierDetailsComponent } from './main/users/supplier-details/supplier-details.component';
import { CommonNavigation } from './navigation/navigation-routes/common/common.navigation';
import { RetailerCenterNavigation } from './navigation/navigation-routes/retailer/retailer-center.navigation';
import { RetailerNavigation } from './navigation/navigation-routes/retailer/retailer.navigation';
import { SupplierCenterNavigation } from './navigation/navigation-routes/supplier/supplier-center.navigation';
import { SupplierNavigation } from './navigation/navigation-routes/supplier/supplier.navigation';
import { marketplaceRoutes } from './routes/marketplace.routes';
import { AuthenticationGuard } from './service/authentication/authentication.guard';
import { AdminGuard } from './service/guards/admin.guard';
import { AgentGuard } from './service/guards/agent.guard';
import { CartGuard } from './service/guards/cart.guard';
import { MaintenanceGuard } from './service/guards/maintenance.guard';
import { ResetPasswordGuard } from './service/guards/reset-password.guard';
import { SetupGuideGuard } from './service/guards/setup-guide.guard';
import { SupplierGuard } from './service/guards/supplier.guard';
import { AddNewStoreComponent } from './shared/components/add-new-store/add-new-store.component';
import { Error404Component } from './shared/components/error-404/error-404.component';
import { ForgotPasswordEntryComponent } from './shared/components/forgot-password-entry/forgot-password-entry.component';
import { LoginDialogEntryComponent } from './shared/components/login-dialog-entry/login-dialog-entry.component';
import { MaintenancePageComponent } from './shared/components/maintenance-page/maintenance-page.component';
import { PaypalLoginProgressComponent } from './shared/components/paypal/paypal-login-progress/paypal-login-progress.component';
import { RegistrationDialogEntryComponent } from './shared/components/registration-dialog-entry/registration-dialog-entry.component';
import { RolesEnum } from './vo/roles/roles';

export const appRoutes: Routes = [
  {
    path: '',
    component: ExploreProductsComponent,
    data: {
      pageTitle: 'App - Home Page',
      cleanRoute: '',
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
      skipMetaTagUpdate: true,
    },
    children: [
      { path: 'signin', redirectTo: `login/retailer` },
      { path: 'login', redirectTo: `login/retailer` },
      { path: 'registration', redirectTo: `registration/retailer` },
      { path: 'signup', redirectTo: `registration/retailer` },
      { path: 'register', redirectTo: `registration/retailer` },
      {
        path: 'login/:type',
        component: LoginDialogEntryComponent,
      },
      {
        path: 'email-verification/:token',
        loadChildren: () =>
          import('app/main/email-verification/email-verification.module').then((m) => m.EmailVerificationModule),
      },
      {
        path: 'team-member-create',
        loadChildren: () =>
          import('app/main/team-member-registration/team-member-registration.module').then(
            (m) => m.TeamMemberRegistrationModule
          ),
      },
      {
        path: 'registration/:type',
        component: RegistrationDialogEntryComponent,
      },
      {
        path: 'reset-password',
        canActivate: [ResetPasswordGuard],
        component: ForgotPasswordEntryComponent,
      },
    ],
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent,
  },
  {
    path: '',
    canActivate: [MaintenanceGuard, AuthenticationGuard],
    children: [
      ...redirects,
      {
        path: 'cart',
        data: { pageTitle: 'App - Cart' },
        canActivate: [AgentGuard],
        canLoad: [CartGuard],
        loadChildren: () => import('app/main/cart/cart.module').then((m) => m.CartModule),
      },
      // all
      // { path: '', pathMatch: 'full', redirectTo: '', canActivate: [RedirectGuard] },
      { path: 'history', component: HistoryComponent, data: { pageTitle: 'App - History' } },
      {
        path: SupplierNavigation.ACTIVITY_LOG,
        component: ActivityLogComponent,
        data: { pageTitle: 'App - Activity Log' },
      },
      {
        path: 'getting-started',
        loadChildren: () =>
          import('app/main/getting-started-new/getting-started-new.module').then((m) => m.GettingStartedNewModule),
        data: { pageTitle: 'App - Getting Started' },
      },
      {
        path: SupplierCenterNavigation.DASHBOARD,
        component: SupplierDashboardComponent,
        canActivate: [SupplierGuard, SetupGuideGuard],
        data: { role: RolesEnum.SUPPLIER, pageTitle: 'App - Dashboard - Supplier' },
      },
      {
        path: SupplierCenterNavigation.MY_RETAILERS.DEFAULT,
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - My Retailers' },
        loadChildren: () => import('app/main/my-retailers/my-retailers.module').then((m) => m.MyRetailersModule),
      },
      {
        path: 'supplier/settings',
        loadChildren: () =>
          import('./main/account-settings/supplier-account/supplier-account.module').then(
            (m) => m.SupplierAccountModule
          ),
        canActivate: [SupplierGuard],
      },
      {
        path: RetailerCenterNavigation.SETTINGS.DEFAULT,
        loadChildren: () =>
          import('./main/account-settings/retailer-account/retailer-account.module').then(
            (m) => m.RetailerAccountModule
          ),
        canActivate: [AgentGuard],
      },
      {
        path: 'supplier-details',
        component: SupplierDetailsComponent,
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - Supplier Details' },
      },
      {
        path: SupplierCenterNavigation.CATALOG.TASK,
        component: TaskwizardComponent,
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - My Tasks - Task Wizard' },
      },
      {
        path: 'paypal-login-progress',
        component: PaypalLoginProgressComponent,
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - Paypal Login Progress' },
      },

      // alibaba
      {
        path: RetailerCenterNavigation.ALIBABA.DEFAULT,
        canActivate: [AgentGuard],
        loadChildren: () => import('app/main/alibaba-new/alibaba-new.module').then((m) => m.AlibabaNewModule),
      },

      // { path: 'alibaba/orders', component: AlibabaManageOrdersComponent, canActivate: [AgentGuard] },
      {
        path: RetailerCenterNavigation.ALIBABA.ALIBABA_REFRESH_TOKEN,
        component: AlibabaRefreshTokenComponent,
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - Alibaba - Refresh Token',
        },
      },

      // {path: 'suppliers/supplier/:userID', component: SupplierOrCatalogProductsComponent, canActivate: [AgentGuard]},
      {
        path: SupplierCenterNavigation.CATALOG.DEFAULT,
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - Supplier Catalog' },
        loadChildren: () =>
          import('app/main/supplier-catalog/supplier-catalog.module').then((m) => m.SupplierCatalogModule),
      },
      {
        path: RetailerCenterNavigation.ORDERS,
        loadChildren: () =>
          import('./main/manage-orders-new/retailer-manage-orders/retailer-manage-orders.module').then(
            (m) => m.RetailerManageOrdersModule
          ),
        canActivate: [AgentGuard],
      },
      {
        path: SupplierCenterNavigation.ORDERS,
        loadChildren: () =>
          import('./main/manage-orders-new/supplier-manage-orders/supplier-manage-orders.module').then(
            (m) => m.SupplierManageOrdersModule
          ),
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - Orders - Supplier' },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD,
        component: TasksComponent,
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - Datafeed Manager - Product Upload',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_RETAILER_TASK,
        component: RetailerTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          cleanRoute: `${RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD}/wizard`,
          pageTitle: 'App - Datafeed Manager - Product Upload Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_JUMPSELLER,
        component: JumpsellerDfTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          cleanRoute: `${RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD}/wizard`,
          pageTitle: 'App - Datafeed Manager - Product Upload Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_SHOPRENTER,
        component: ShoprenterDfTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          cleanRoute: `${RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD}/wizard`,
          pageTitle: 'App - Datafeed Manager - Product Upload Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_ECWID,
        component: EcwidDfTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          cleanRoute: `${RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD}/wizard`,
          pageTitle: 'App - Datafeed Manager - Product Upload Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_EXPORT,
        component: TasksComponent,
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - Datafeed Manager - Product Export',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_EXPORT_WIZARD,
        component: RetailerExportTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - Datafeed Manager - Product Export Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE,
        component: TasksComponent,
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - Datafeed Manager - Variants Update',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE_WIZARD,
        component: RetailerVariantsUpdateTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          cleanRoute: `${RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE}/wizard`,
          pageTitle: 'App - Datafeed Manager - Variants Update Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE_WIZARD_JUMPSELLER,
        component: RetailerJumpsellerVuTaskWizardComponent,
        canActivate: [AgentGuard],
        data: {
          cleanRoute: `${RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE}/wizard`,
          pageTitle: 'App - Datafeed Manager - Variants Update Wizard',
        },
      },
      {
        path: RetailerCenterNavigation.MY_SUPPLIERS,
        loadChildren: () =>
          import('./main/marketplace/my-suppliers-new/my-suppliers-new.module').then((m) => m.MySuppliersNewModule),
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - My Suppliers',
          cleanRoute: RetailerCenterNavigation.MY_SUPPLIERS,
        },
      },
      {
        path: RetailerCenterNavigation.APPS,
        loadComponent: () => import('./main/users/user-apps/user-apps.component').then((mod) => mod.UserAppsComponent),
        canActivate: [AgentGuard],
        data: {
          pageTitle: 'App - Retailer Apps',
        },
      },
      {
        path: RetailerCenterNavigation.SETTINGS.DEFAULT,
        loadChildren: () =>
          import('./main/account-settings/account-settings.module').then((m) => m.AccountSettingsModule),
        canActivate: [AgentGuard],
      },
      {
        path: RetailerNavigation.BILLING.DEFAULT,
        canActivate: [AgentGuard],
        loadChildren: () => import('app/main/billing-new/billing-new.module').then((m) => m.BillingNewModule),
        data: { pageTitle: 'App - Billing' },
      },
      {
        path: RetailerNavigation.BILLING.THANK_YOU_WIX,
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('app/main/thank-you-page-wix/thank-you-page-wix.module').then((m) => m.ThankYouPageWixModule),
      },
      {
        path: RetailerNavigation.BILLING.THANK_YOU_WOOCOMMERCE,
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('app/main/thank-you-page-woocommerce/thank-you-page-woocommerce.module').then(
            (m) => m.ThankYouPageWoocommerceModule
          ),
      },
      {
        path: RetailerNavigation.BILLING.THANK_YOU,
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('app/main/thank-you-page-shopify/thank-you-page-shopify.module').then(
            (m) => m.ThankYouPageShopifyModule
          ),
      },
      // { path: 'billing/thank-you-page', component: ShopifyPaymentComponent, canActivate: [AgentGuard] },
      { path: 'shopifyPayment', redirectTo: '' },
      // {path: 'shopifyPayment/done', redirectTo: ''},
      { path: 'shopifyPayment/done', component: ThankYouPageShopifyComponent },
      {
        path: RetailerCenterNavigation.DASHBOARD,
        component: RetailerDashboardComponent,
        canActivate: [AgentGuard, SetupGuideGuard],
        data: { role: RolesEnum.RETAILER, pageTitle: 'App - Dashboard - Retailer' },
      },

      // {path: 'catalogs', component: CatalogsComponent, canActivate: [SupplierGuard]},
      { path: 'add-new-store', component: AddNewStoreComponent, canActivate: [SupplierGuard] },

      // other
      { path: 'connecting', component: FtpComponent },
      { path: 'fileupload', component: FileUploadComponent },
      // OLD_TODO: Csv file-settings test
      { path: 'csv-settings', component: CsvSettingsComponent },
      { path: 'product-view', component: ProductViewComponent },

      // ppms-ből maradt
      // {path: 'my-products', component: MyProductsComponent},

      // {path: 'subscription', component: SubscriptionComponent},
      {
        path: SupplierCenterNavigation.MESSAGES,
        component: ChatAppContainerComponent,
        data: { pageTitle: 'App - Messages' },
      },
      { path: `${SupplierCenterNavigation.MESSAGES}/:type`, component: ChatAppContainerComponent },
      {
        path: RetailerCenterNavigation.MESSAGES,
        component: ChatAppContainerComponent,
        data: { pageTitle: 'App - Messages' },
      },
      { path: `${RetailerCenterNavigation.MESSAGES}/:type`, component: ChatAppContainerComponent },
      {
        path: RetailerCenterNavigation.FAVORITES.DEFAULT,
        canActivate: [AgentGuard],
        component: FavoriteProductsComponent,
        data: { pageTitle: 'App - Favorite Products' },
      },
      {
        path: RetailerCenterNavigation.FAVORITES.FOLLOWED_SUPPLIERS,
        canActivate: [AgentGuard],
        component: FollowedSuppliersComponent,
        data: { pageTitle: 'App - Followed Suppliers' },
      },
      {
        path: CommonNavigation.REFERRALS,
        loadChildren: () => import('app/main/referrals/referrals.module').then((m) => m.ReferralsModule),
      },
      {
        path: 'setup-guide',
        component: SetupGuidePageComponent,
        data: { pageTitle: 'App - Setup Guide' },
      },
      {
        path: SupplierCenterNavigation.STOREFRONT_SETTINGS.DEFAULT,
        loadChildren: () =>
          import('app/main/storefront-settings/storefront-settings.module').then((m) => m.StorefrontSettingsModule),
        canActivate: [SupplierGuard],
        data: { pageTitle: 'App - Storefront settings' },
      },
      {
        path: RetailerCenterNavigation.IMPORT_LIST,
        loadChildren: () =>
          import('app/main/retailer-import-list/retailer-import-list.module').then((m) => m.RetailerImportListModule),
        canActivate: [AgentGuard],
        data: { pageTitle: 'App - Retailer Import List' },
      },
      {
        path: 'checkout',
        canActivate: [AgentGuard],
        loadChildren: () => import('app/main/checkout/checkout.module').then((m) => m.CheckoutModule),
      },
    ],
  },

  ...marketplaceRoutes,
  ...STATIC_PAGE_ROUTES,

  // { path: 'login', component: LoginComponent, canActivate: [MaintenanceGuard, RedirectGuard] },
  { path: 'crosslogin', component: CrossLoginComponent, canActivate: [MaintenanceGuard] },
  // { path: 'registration', component: RegistrationComponent, canActivate: [MaintenanceGuard] },
  // {path: 'reset-password', component: ResetPasswordComponent},

  {
    path: 'admin',
    canActivate: [MaintenanceGuard],
    canLoad: [AdminGuard],
    loadChildren: () => import('app/main/kiosk/kiosk.module').then((m) => m.KioskModule),
  },
  {
    path: '**',
    component: Error404Component,
  },
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
  relativeLinkResolution: 'legacy',
  enableTracing: true,
});
