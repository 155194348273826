<div fxLayout="row" fxLayoutGap="4px">
  <ng-container *ngFor="let chat of chats">
    <div
      class="minimized-chat-container cursor-pointer"
      fxLayout="row"
      fxLayoutAlign="space-between  center"
      fxFlex="0 0 180px"
      fxLayoutGap="4px"
      (click)="handleExpandClick(chat)"
    >
      <div
        fxLayout="row"
        fxLayoutGap="8px"
        fxLayoutAlign="flex-start center"
        class="recipient-logo-name"
        fxFlex="1 0 0"
      >
        <img
          #avatarImage
          *ngIf="chat.recipientUser.avatar && !useFallbackAvatar"
          [src]="chat.recipientUser.avatar"
          (error)="useFallbackAvatar = true"
          [alt]="chat.recipientUser.name"
          class="avatar-img"
        />
        <app-avatar
          type="text"
          class="chat-user-avatar"
          textClasses="typography-body-2"
          *ngIf="!chat.recipientUser.avatar || useFallbackAvatar"
          [diameter]="24"
          >{{ chat.recipientUser.name.substring(0, 2) }}</app-avatar
        >
        <div class="text-truncate typography-body-2">{{ chat.recipientUser.name }}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
        <mat-icon class="s-16 cursor-pointer" (click)="handleCloseClick(chat.recipientUser.id)">close</mat-icon>
      </div>
    </div>
  </ng-container>
</div>
