<div class="authentication-form-content">
  <img class="authentication-syncee-logo-mobile" src="assets/images/logos/syncee-logo.svg" />
  <div class="text-center" fxLayout="column" fxLayoutGap="4px">
    <h2 class="mt-0">{{ titleKey | translate }}</h2>
    <div class="typography-body-1">
      {{ descriptionKey | translate }}
    </div>
  </div>
  <div class="w-100p" fxLayout="column" fxLayoutGap="32px">
    <div>
      <app-registration-password-form
        [role]="supplierRole"
        (submitClick)="submitHandle()"
        [(form)]="passwordForm"
      ></app-registration-password-form>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.ALREADY_REGISTERED' | translate }}
      <a href="#" (click)="navigateToSupplierLogIn($event)">{{ 'AUTHENTICATION.LOG_IN' | translate }}</a>
    </div>
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.WANT_TO_BE_RETAILER' | translate }}
      <a href="#" (click)="navigateToRetailerRegister($event)">{{ 'AUTHENTICATION.APPLY_RETAILER' | translate }}</a>
    </div>
  </div>
</div>
