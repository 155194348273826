import { Component } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { SkeletonModule } from '../skeleton/skeleton.module';

@Component({
  selector: 'app-supplier-simple-item-skeleton',
  templateUrl: './supplier-simple-item-skeleton.component.html',
  styleUrls: ['./supplier-simple-item-skeleton.component.scss'],
  imports: [FlexModule, SkeletonModule],
  standalone: true,
})
export class SupplierSimpleItemSkeletonComponent {}
