import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['copy-to-clipboard.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule, FlexModule, ExtendedModule, MatIconModule],
})
export class CopyToClipboardComponent implements OnInit {
  @Input() content: string;
  @Input() toolTipMessage?: string;
  @Input() toolTipPosition: TooltipPosition = 'above' as TooltipPosition;
  @Input() toolTipDisabled = false;
  @Input() textBefore?: string;

  private timeout = null;

  public icon = 'content_copy';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (Utils.isNullOrUndefined(this.toolTipMessage)) {
      this.toolTipMessage = this.translateService.instant('COPY_TO_CLIPBOARD.DEFAULT_TOOLTIP_MESSAGE');
    }
  }

  public copyInput(inputElement): void {
    clearTimeout(this.timeout);

    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);

    this.icon = 'check_circle';
    this.timeout = setTimeout(() => {
      this.icon = 'content_copy';
    }, 2000);
  }

  public getContentLength(): number {
    return this.content.length;
  }
}
