import { SupplierCatalog } from '../../vo/supplier-catalog/supplier-catalog';
import { GetTasksSuccessAction, TaskActionType, TasksAction } from './tasks.actions';

export interface TasksState {
  tasks: SupplierCatalog[];
}

const initialState: TasksState = {
  tasks: null,
};

export function tasksReducer(state = initialState, action: TasksAction): TasksState {
  switch (action.type) {
    case TaskActionType.GET_TASKS_SUCCESS: {
      return setTasks(state, action as GetTasksSuccessAction);
    }
    default: {
      return state;
    }
  }
}

function setTasks(state: TasksState, action: GetTasksSuccessAction): TasksState {
  return {
    ...state,
    tasks: action.payload,
  };
}
