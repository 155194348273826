<app-custom-dialog-wrapper [headerLeftRef]="dialogTitle" [hideCloseBtnDesktop]="true">
  <div class="dialog-content" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="32px">
    <div class="w-100p" fxLayout="column" fxLayoutGap="12px">
      <div fxLayout="column" fxLayoutGap="12px">
        <mat-form-field appearance="fill" class="input-without-padding w-100p">
          <mat-label>{{ 'FOOTER.LANGUAGE_AND_CURRENCY.LANGUAGE' | translate }}</mat-label>
          <mat-select
            (valueChange)="changeLanguage($event)"
            [value]="currentLanguage$ | async"
            disableOptionCentering
            panelClass="mat-select-display-change"
          >
            <mat-option *ngFor="let language of languages$ | async" [value]="language">
              {{ language.name }} - {{ language.code | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="data.isCurrencyActive" fxLayout="column" fxLayoutGap="12px">
        <mat-form-field appearance="fill" class="input-without-padding w-100p">
          <mat-label
            >{{ 'FOOTER.LANGUAGE_AND_CURRENCY.CURRENCY' | translate }}
            <span *ngIf="selectedEcom$ | async">{{
              'FOOTER.LANGUAGE_AND_CURRENCY.CURRENTLY_SELECTED' | translate
            }}</span></mat-label
          >
          <mat-select
            (selectionChange)="changeCurrency($event.value)"
            [value]="currency?.key"
            disableOptionCentering
            panelClass="mat-select-display-change"
          >
            <mat-option *ngIf="selectedEcom$ | async" [value]="'default'"
              >{{ 'MY_SHOPS.STORE_DEFAULT_LABEL' | translate }} ({{
                (selectedEcom$ | async).options?.currency | uppercase
              }})</mat-option
            >
            <mat-option *ngFor="let currency of currencies" [value]="currency.key">
              {{ currency.key }} - {{ currency.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="typography-body-2 px-8">
          {{ 'FOOTER.LANGUAGE_AND_CURRENCY.DESCRIPTION' | translate }}
        </div>
      </div>
    </div>
    <button mat-flat-button color="primary" class="w-100p" (click)="updatePreferences()">
      {{
        data.isCurrencyActive
          ? ('FOOTER.LANGUAGE_AND_CURRENCY.UPDATE_PREFERENCES' | translate)
          : ('FOOTER.LANGUAGE_AND_CURRENCY.UPDATE_LANGUAGE' | translate)
      }}
    </button>
  </div>
</app-custom-dialog-wrapper>

<ng-template #dialogTitle>
  <div class="typography-body-large-3 title-padding">
    {{
      data.isCurrencyActive
        ? ('FOOTER.LANGUAGE_AND_CURRENCY.PREFERENCES' | translate)
        : ('FOOTER.LANGUAGE_AND_CURRENCY.LANGUAGE' | translate)
    }}
  </div>
</ng-template>
