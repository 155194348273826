<div class="setup-guide-review-content-image-container">
  <img class="balloon-image" width="100%" [src]="backgroundImageUrl" alt="balloons" />
  <div class="inner-content" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="28px" fxLayoutGap.lt-sm="20px">
    <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start end">
      <div ngClass="h3" ngClass.lt-sm="h5">
        {{ 'SETUP_GUIDE.REVIEW_STEP.BANNER.HEADING' | translate }}
      </div>
      <div>{{ 'SETUP_GUIDE.REVIEW_STEP.BANNER.MESSAGE_1' | translate }}</div>
    </div>
    <div
      class="white-500-bg feedback-matters-container"
      ngClass="px-40 py-16"
      ngClass.lt-sm="px-20 py-16"
      fxLayout="column"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
    >
      <div class="centered-text">{{ 'SETUP_GUIDE.REVIEW_STEP.BANNER.MESSAGE_2' | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="center start">
        <ng-container *ngFor="let item of [1, 2, 3, 4, 5]">
          <mat-icon class="amber-300-fg" ngClass="s-36" ngClass.lt-sm="s-24">star</mat-icon>
        </ng-container>
      </div>
    </div>
  </div>
</div>
