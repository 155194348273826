import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SplitUrl, splitUrl } from '../../../utils/split-url.util';
import { I18nBackendJson } from '../../../vo/translation/i18n-backend-json';

@Component({
  selector: 'app-setup-guide-item-list-content',
  templateUrl: './setup-guide-item-list-content.component.html',
  styleUrls: ['./setup-guide-item-list-content.component.scss'],
})
export class SetupGuideItemListContentComponent implements OnInit, OnChanges {
  @Input() description: I18nBackendJson;
  @Input() button: I18nBackendJson;
  @Input() buttonLink: string;
  @Input() videoUrl: string;
  @Output() handleClose = new EventEmitter<void>();
  buttonPath: SplitUrl;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (!!this.buttonLink) {
      this.setRouterData();
    }
  }

  private setRouterData(): void {
    this.buttonPath = splitUrl(this.buttonLink);
  }
}
