import { ProductListingNavigation } from './product-listing.navigation';
import { MyListingNavigation } from './my-listing.navigation';
import { MyRetailersNavigation } from './my-retailers.navigation';
import { AnalyticsNavigation } from './analytics.navigation';
import { ProfileNavigation } from './profile.navigation';
import { StorefrontSettingsNavigation } from './storefront-settings.navigation';
const supplierCatalog = 'supplier-catalog';
export type SupplierNavigation = (typeof SupplierNavigation)[keyof typeof SupplierNavigation];
export const SupplierNavigation = {
  DASHBOARD: 'dashboard-overview',
  SUPPLIER_CATALOG: supplierCatalog,
  SUPPLIER_CATALOG_PRODUCTS: supplierCatalog + '/products',
  SUPPLIER_CATALOG_CATALOGS: supplierCatalog + '/catalogs',
  PRODUCT_LISTING: ProductListingNavigation,
  MY_PRODUCTS: 'supplier-my-products',
  MY_LISTING: MyListingNavigation,
  MY_RETAILERS: MyRetailersNavigation,
  ORDERS: 'manage-orders',
  ANALYTICS: AnalyticsNavigation,
  MESSAGES: 'messages',
  PROFILE: ProfileNavigation,
  MY_SHOPS: 'my-shops',
  ACTIVITY_LOG: 'activity_log',
  STOREFRONT_SETTINGS: StorefrontSettingsNavigation,
} as const;
