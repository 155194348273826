import { SettingsInputVo } from './settings-input-vo';

export class ExcelSettingsInputVo extends SettingsInputVo {
  private _taskId: number;
  private _fileId: number;
  private _firstRowIsHeader: boolean;
  private _startRowNum: number;
  private _sheet: string;
  private _encoding: string;

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get firstRowIsHeader(): boolean {
    return this._firstRowIsHeader;
  }

  set firstRowIsHeader(value: boolean) {
    this._firstRowIsHeader = value;
  }

  get startRowNum(): number {
    return this._startRowNum;
  }

  set startRowNum(value: number) {
    this._startRowNum = value;
  }

  get sheet(): string {
    return this._sheet;
  }

  set sheet(value: string) {
    this._sheet = value;
  }

  get encoding(): string {
    return this._encoding;
  }

  set encoding(value: string) {
    this._encoding = value;
  }
}
