<h2 mat-dialog-title>{{ 'BILLING.BILLING_INFO_CHANGE_DIALOG.TITLE' | translate }}</h2>
<mat-dialog-content>
  <div class="pb-20" [innerHTML]="'BILLING.BILLING_INFO_CHANGE_DIALOG.DESCRIPTION' | translate"></div>

  <app-billing-info-form #appBilling></app-billing-info-form>
</mat-dialog-content>
<mat-dialog-actions [align]="'center'">
  <div fxLayout="column" fxLayoutAlign="start center">
    <ng-container *ngIf="!billingSaveInProgress; else buttonProgress">
      <button
        mat-button
        class="submit-button syncee-blue-button"
        aria-label="SAVE"
        [disabled]="appBilling.billingInfoForm.invalid"
        (click)="saveData()"
      >
        {{ 'BILLING_INFO_FORM.SAVE' | translate }}
      </button>
    </ng-container>
  </div>
</mat-dialog-actions>

<ng-template #buttonProgress>
  <app-loading-spinner [size]="40"></app-loading-spinner>
</ng-template>
