import { Component, Inject, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectYourStoreModule } from 'app/main/connect-your-store/connect-your-store.module';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-add-new-store',
  templateUrl: './add-new-store.component.html',
  styleUrls: ['./add-new-store.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [CommonModule, FlexModule, MatDialogModule, TranslateModule, ConnectYourStoreModule],
})
export class AddNewStoreComponent implements OnInit {
  role;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    this.role = this.data.role;
  }
}
