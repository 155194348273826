import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingScreenService {
  private _loading = false;
  loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _isForceLoad = false;

  constructor() {}

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading(forceLoad = false): void {
    this._isForceLoad = forceLoad;
    this.loading = true;
  }

  stopLoading(): void {
    this.isForceLoad = false;
    this.loading = false;
  }

  get isForceLoad(): boolean {
    return this._isForceLoad;
  }

  set isForceLoad(value: boolean) {
    this._isForceLoad = value;
  }
}
