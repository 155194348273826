import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { forkJoin, Observable } from 'rxjs';
import { EcomFeatureUsage } from './model/ecom/ecom-feature-usage';
import { UserFeatureUsage } from './model/user/user-feature-usage';

@Injectable({
  providedIn: 'root',
})
export class UserUsageService {
  constructor(private springRestService: SpringRestService) {}

  getUserFeatureUsage(): Observable<UserFeatureUsage> {
    return this.springRestService.get(MicroserviceNames.USER, `/v1/Feature/Usage`, undefined, true);
  }
}
