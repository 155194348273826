import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PlanCompareDialogComponent } from './subscription/plan-compare-dialog/plan-compare-dialog.component';
import { ShopifyPaymentComponent } from './subscription/shopify-thank-you-page/shopify-payment.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { BillingInformationChangeDialogComponent } from './billing-information-change-dialog/billing-information-change-dialog.component';
import { BillingComponent } from './billing.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { AppRoutes } from '../../app.routes';
import { CouponCodeDialogComponent } from '../../shared/components/dialogs/coupon-code-dialog/coupon-code-dialog.component';
import { FormsModule } from '@angular/forms';
import { SubscriptionPlanBoxComponent } from './subscription/subscription-plan-box/subscription-plan-box.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AcceptTermsComponent } from '../../shared/components/accept-terms/accept-terms.component';
import { CancelDialogModule } from '../cancel-dialog/cancel-dialog.module';
import { BillingInfoFormComponent } from 'app/shared/components/billing-info-form/billing-info-form.component';
import { MarketingCardComponent } from 'app/shared/components/marketing-card/marketing-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { FaqsComponent } from 'app/shared/components/faqs/faqs.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [
    BillingComponent,
    SubscriptionComponent,
    ShopifyPaymentComponent,
    PlanCompareDialogComponent,
    InvoicesComponent,
    BillingInformationChangeDialogComponent,
    SubscriptionPlanBoxComponent,
  ],
  imports: [
    CommonModule,
    AppRoutes,
    // Material
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    MatChipsModule,
    FuseSharedModule,
    CdkStepperModule,
    AcceptTermsComponent,
    CouponCodeDialogComponent,
    CancelDialogModule,
    BillingInfoFormComponent,
    MarketingCardComponent,
    TranslateModule,
    FaqsComponent,
    LoadingSpinnerComponent,
  ],
  exports: [PlanCompareDialogComponent],
  providers: [DatePipe],
})
export class BillingModule {}
