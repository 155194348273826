import { FieldStructure } from '../../main/filter/filter-item/filter-item.component';
import { FilterBox } from '../../main/filter/filter-box';
import { MappingField } from '../../main/retailer-export-task-wizard/new-field-mapping/field-selector/field-selector.component';
import { Utils } from 'app/utils/utils';

export class ExportTaskwizardUpdateResponse {
  private _files: TaskFile;
  private _task: Task;
  private _ecom: any;
  private _mapping: ExportMapping;
  private _scheduling: ScheduleTimeVO;
  private _taskFilters: TaskFilters;
  private _mappingExamples: any[];

  constructor() {}

  public isMapping(): boolean {
    return !Utils.isNullOrUndefined(this.mapping);
  }

  public isScheduling(): boolean {
    return !Utils.isNullOrUndefined(this.scheduling);
  }

  public isTaskFilters(): boolean {
    return !Utils.isNullOrUndefined(this.taskFilters);
  }

  public hasTaskFilter(): boolean {
    return !Utils.isNullOrUndefined(this.taskFilters?.filter);
  }

  get taskFilters(): TaskFilters {
    return this._taskFilters;
  }

  set taskFilters(value: TaskFilters) {
    this._taskFilters = value;
  }

  get scheduling(): ScheduleTimeVO {
    return this._scheduling;
  }

  set scheduling(value: ScheduleTimeVO) {
    this._scheduling = value;
  }

  get mapping(): ExportMapping {
    return this._mapping;
  }

  set mapping(value: ExportMapping) {
    this._mapping = value;
  }

  get files(): TaskFile {
    return this._files;
  }

  set files(value: TaskFile) {
    this._files = value;
  }

  get task(): Task {
    return this._task;
  }

  set task(value: Task) {
    this._task = value;
  }

  get ecom(): any {
    return this._ecom;
  }

  set ecom(value: any) {
    this._ecom = value;
  }

  get mappingExamples(): any[] {
    return this._mappingExamples;
  }

  set mappingExamples(value: any[]) {
    this._mappingExamples = value;
  }
}

export interface TaskFileSettings {
  delimiter: string;
  enclosure: string;
  eol: string;
  structure: string;
}

export class TaskFile {
  private _fileId: number;
  private _taskId: number;
  private _extension: string;
  private _destination: string;
  private _remotepath: string;
  private _settings: TaskFileSettings;
  private _nodeTree: string;
  private _date: string;

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }

  constructor() {}

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get extension(): string {
    return this._extension;
  }

  set extension(value: string) {
    this._extension = value;
  }

  get remotepath(): string {
    return this._remotepath;
  }

  set remotepath(value: string) {
    this._remotepath = value;
  }

  set destination(value: string) {
    this._destination = value;
  }

  get destination(): string {
    return this._destination;
  }

  get settings(): any {
    return this._settings;
  }

  set settings(value: any) {
    this._settings = value;
  }

  get nodeTree(): string {
    return this._nodeTree;
  }

  set nodeTree(value: string) {
    this._nodeTree = value;
  }
}

export class Task {
  private _userId;
  private _ecomId;
  private _taskId;
  private _name;
  private _destination;
  private _pid;
  private _date;
  private _rounding: string;
  private _settings;

  constructor() {}

  get userId(): number {
    return this._userId;
  }

  set userId(value) {
    this._userId = value;
  }

  get settings(): any {
    return this._settings;
  }

  set settings(value) {
    this._settings = value;
  }

  get ecomId(): number {
    return this._ecomId;
  }

  set ecomId(value) {
    this._ecomId = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value) {
    this._taskId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get destination(): string {
    return this._destination;
  }

  set destination(value) {
    this._destination = value;
  }

  get pid(): number {
    return this._pid;
  }

  set pid(value) {
    this._pid = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value) {
    this._date = value;
  }

  get rounding(): string {
    return this._rounding;
  }

  set rounding(value: string) {
    this._rounding = value;
  }
}

export interface MapInputData {
  storeField: string;
  outputName: string;
  inputSettings: MapInputSettingsData;
  order: number;
  type: string;
}

export interface MapInputSettingsData {
  settings: any[];
  operations: any[];
}

export class ExportMapping {
  fieldMappingElements: MappingField[] = [];

  constructor() {}

  addNewMapItem(rawItem: any): void {
    const mappingField = <MappingField>{
      keyName: rawItem.keyName,
      originalName: rawItem.originalName,
      settings: rawItem.settings,
      displayName: rawItem.displayName,
      customDisplayName: rawItem.customDisplayName,
      fields: rawItem.fields,
      additions: rawItem.additions,
      operations: rawItem.operations,
    };
    this.fieldMappingElements.push(mappingField);
  }
}

export class ExportMappingOld {
  fieldMappingElements: MapInputData[] = [];

  constructor() {}

  emptyMapInputDataObj(): MapInputData {
    return <MapInputData>{
      storeField: '',
      outputName: '',
      operations: [],
      inputSettings: {
        settings: [],
        operations: [],
      },
      order: 1,
      type: 'shopify field',
    };
  }

  emptyCustomMapInputDataObj(): MapInputData {
    return <MapInputData>{
      storeField: '',
      outputName: '',
      operations: [],
      inputSettings: {
        settings: [],
        operations: [],
      },
      order: 1,
      type: 'custom field',
    };
  }

  initExportEmptyMapping(): void {
    this.addfieldMappingElement();
  }

  addfieldMappingElement(): void {
    this.fieldMappingElements.push(this.emptyMapInputDataObj());
  }

  addCustomfieldMappingElement(): void {
    this.fieldMappingElements.push(this.emptyCustomMapInputDataObj());
  }

  removeFieldMappingElement(fieldIndex: number): void {
    this.fieldMappingElements = this.fieldMappingElements.filter((field, index) => index !== fieldIndex);
  }

  refreshFieldValue(fieldIndex: number, customField: any): void {
    this.fieldMappingElements[fieldIndex] = customField.value;
  }
}

export class ScheduleTimeVO {
  constructor() {}

  taskId: number;
  type = '';
  dates: string[] = [];
}

export interface TaskFilterData {
  key: string;
  values: any[];
  condition: string;
}

// Todo: remove filterBoxes if app-filter removed
export class TaskFilters {
  operatorBoxes: string[];
  fieldsStructure: FieldStructure[];
  filterBoxes: FilterBox[];
  generalFilter: TaskFilterData[];
  filter: any;
}
