<div id="products" class="page-layout carded fullwidth inner-scroll">
  <div class="fuse-card task-table">
    <mat-card class="flex-item">
      <mat-card-title>{{ 'TASKS.PRODUCT_VIEW.PRODUCTS' | translate }}</mat-card-title>
      <mat-card-content>
        <mat-table
          [dataSource]="dataSource"
          id="users-table"
          class="table-margin"
          matSort
          matSortActive="userName"
          matSortDirection="asc"
          matSortDisableClear
        >
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-header class="id-column">{{
              'TASKS.PRODUCT_VIEW.SYNCEE_ID' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.ID }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef mat-header>{{
              'TASKS.PRODUCT_VIEW.PRODUCT_IMAGE' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              <img
                class="product-image"
                *ngIf="item.IMAGES && item.IMAGES[0]"
                [alt]="item.TITLE"
                [src]="item.IMAGES[0]"
                onError="this.src='assets/images/no_image_avalible.png';"
              />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-header>{{
              'TASKS.PRODUCT_VIEW.PRODUCT_NAME' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.TITLE }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-header>{{
              'TASKS.PRODUCT_VIEW.STATUS' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.STATUS }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row matRipple *matRowDef="let item; columns: displayedColumns"> </mat-row>
        </mat-table>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 30, 50]"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
