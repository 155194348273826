<div class="channel-item" [ngClass]="{ selected: selected }" (click)="handleChannelSelection()">
  <img
    #avatarImage
    class="avatar-image"
    *ngIf="!!channel.avatar && !useFallbackAvatar"
    [src]="channel.avatar"
    (error)="useFallbackAvatar = true"
    [alt]="channel.name"
  />
  <app-avatar type="text" class="chat-user-avatar" *ngIf="!channel.avatar || useFallbackAvatar" [diameter]="40">{{
    channel.name.substring(0, 2)
  }}</app-avatar>
  <div class="channel-info-wrapper">
    <div class="channel-info-name-wrapper">
      <div fxFlex="1 0 0" class="channel-name">{{ channel.name }}</div>
      <div>
        <ng-container *ngIf="channel.lastMessageDate">
          <div class="last-message-date">
            {{ channel.lastMessageDate | dateAgo }}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="last-message-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
      <div fxFlex="1 0 0" class="last-message typography-body-small-2">
        {{ channel.lastMessage }}
      </div>
      <div
        *ngIf="channel.unseenMessageCount"
        class="unread-message-indicator"
        [matTooltip]="channel.unseenMessageCount + ' unread message(s)'"
      ></div>
    </div>
  </div>
</div>
