import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-list-markers',
  templateUrl: './list-markers.component.html',
  styleUrls: ['./list-markers.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule],
})
export class ListMarkersComponent implements OnInit {
  @Input() type: 'done' | 'close';

  constructor() {}

  ngOnInit(): void {}
}
