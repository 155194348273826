import { CommonModule } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  selector: 'app-checkbox-selection-list',
  templateUrl: './checkbox-selection-list.component.html',
  styleUrls: ['./checkbox-selection-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxSelectionListComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule],
})
export class CheckboxSelectionListComponent implements OnInit {
  selectedItemIds: any[] = [];
  _checkBoxes: any[] = [];

  @Output() checkBoxesChange = new EventEmitter<any[]>();

  get checkBoxes(): any[] {
    return this._checkBoxes;
  }

  @Input()
  set checkBoxes(val: any[]) {
    this._checkBoxes = val;
  }
  constructor() {}

  public handleSelectionChange(event: MatCheckboxChange): void {
    this._checkBoxes.find((x) => x.id === event.source.value).checked = event.checked;
    this.checkBoxesChange.emit(this._checkBoxes);
  }

  public getSelectedItemIds(): any[] {
    return this.selectedItemIds;
  }

  ngOnInit(): void {
    this.selectedItemIds = this._checkBoxes.filter((item) => item.checked).map((item) => item.id);
  }
}
