import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Review } from '../../vo/review/review';
import { Observable } from 'rxjs';
import { SpringPageable } from '../../vo/pagination/spring-pageable';
import { SpringPage } from '../../vo/pagination/spring-page';
import { ReviewType } from '../../vo/review/review-type';
import { ReviewAverage } from '../../vo/review/review-average';

@Injectable({
  providedIn: 'root',
})
export class UserReviewService {
  private readonly baseUrl = '/User/Review';

  constructor(private springRestService: SpringRestService) {}

  getSupplierReviews(userId: number): Observable<Review[]> {
    return this.springRestService.get(MicroserviceNames.USER, `/User/Review/${userId}`, undefined, true);
  }

  getUserReviewTypes(): Observable<ReviewType[]> {
    return this.springRestService.get(MicroserviceNames.USER, `/User/Review/Types`, undefined, true);
  }

  postUserReview(review: Partial<Review>): Observable<void> {
    return this.springRestService.post(MicroserviceNames.USER, '/User/Review', review, {}, true);
  }

  putUserReview(review: Partial<Review>): Observable<void> {
    return this.springRestService.put(MicroserviceNames.USER, '/User/Review', review, {}, true);
  }

  getSupplierAverageReviews(userIds: number[]): Observable<ReviewAverage[]> {
    return this.springRestService.get(MicroserviceNames.USER, `/User/Review/Average`, { userIds }, true);
  }

  getSupplierReviewsPage(userId: number, pageable: SpringPageable): Observable<SpringPage<Review>> {
    return this.springRestService.getPage(
      MicroserviceNames.USER,
      `/User/Review/${userId}/paged`,
      pageable,
      undefined,
      true
    );
  }

  getReviewsFromUserToUsers(fromUserId: number, toUserIds: number[]): Observable<Review[]> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      this.baseUrl + `/From/${fromUserId}`,
      {
        toUserId: toUserIds,
      },
      true
    );
  }

  getReviewToUserFromUser(toUserId: number, fromUserId: number): Observable<Review> {
    return this.springRestService.get(MicroserviceNames.USER, this.baseUrl + `/${toUserId}/From/${fromUserId}`);
  }

  update(review: Review): Observable<void> {
    return this.springRestService.put(MicroserviceNames.USER, this.baseUrl, review, undefined, true);
  }

  updateComment(review: Review): Observable<void> {
    return this.springRestService.patch(MicroserviceNames.USER, this.baseUrl, review, undefined, true);
  }

  public delete(supplierID: number): Observable<void> {
    return this.springRestService.delete(MicroserviceNames.USER, `${this.baseUrl}/${supplierID}`, undefined, true);
  }
}
