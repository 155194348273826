import { Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { CountryPhoneInputComponent } from '../country-phone-input/country-phone-input.component';
import { PhoneNumberModel } from '../country-phone-input/phone-number-model';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

/**
 * has to be wrapped with mat-form-field, with appearance set to standard
 *
 you need to apply the following classes on the wrapping mat-form-field: custom-form-field-wrapper, phone-form-field-wrapper,
 input-without-padding-except-error
 */

@Component({
  selector: 'app-phone-input-form-field',
  standalone: true,
  imports: [CommonModule, CountryPhoneInputComponent],
  templateUrl: './phone-input-form-field.component.html',
  styleUrls: ['./phone-input-form-field.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInputFormFieldComponent }],
})
export class PhoneInputFormFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<PhoneNumberModel>, OnDestroy, OnInit, DoCheck
{
  @Input() placeholder = 'Phone number';
  @Input() error?: string;
  onChange: (value) => void;
  onTouched: () => void;

  value: PhoneNumberModel;
  touched = false;
  constructor(public ref: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    if (!!this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.required = this.ngControl.control.hasValidator(Validators.required);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.errorState) {
      this.stateChanges.next();
    }
  }

  stateChanges = new Subject<void>();
  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  disabled: boolean;
  controlType?: string;
  autofilled?: boolean;
  private _required = false;

  userAriaDescribedBy?: string;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = req;
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.ngControl.invalid && this.ngControl.touched;
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.ref.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDisabledState?(isDisabled: boolean): void {}

  writeValue(value: PhoneNumberModel): void {
    this.value = value;
  }

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  valueChange(value: PhoneNumberModel): void {
    this.onChange(value);
    this.stateChanges.next();
  }
}
