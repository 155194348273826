import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MarketplaceFilter } from '../../../vo/search-product-vo';
import {
  FilterSelectorGtmService,
  PossibleFilters,
} from '../../marketplace/marketplace-shared-components/service/filter-selector-gtm-service';
import { FilterActionKey } from '../../../service/google-tag-manager/actions/filter';

@Component({
  selector: 'app-filter-selector-dialog',
  templateUrl: './filter-selector-dialog.component.html',
  styleUrls: ['./filter-selector-dialog.component.scss'],
})
export class FilterSelectorDialogComponent implements OnInit {
  filterSelectorDialogLocalState: MarketplaceFilter = this.data.filter;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterSelectorDialogData,
    private dialogRef: MatDialogRef<FilterSelectorDialogComponent>,
    private filterSelectorGtmService: FilterSelectorGtmService
  ) {
    this.dialogRef.addPanelClass('custom-modal-container');
  }

  ngOnInit(): void {}

  onValueChange(value: MarketplaceFilter): void {
    this.filterSelectorDialogLocalState = { ...this.filterSelectorDialogLocalState, ...value };
    this.newValue = value;
  }
  set newValue(value: MarketplaceFilter) {
    this.data.filterDataChange.next(value);
  }

  setGtmFilterValue(key: FilterActionKey, value: PossibleFilters): void {
    if (this.data.sendFilterToGtm) {
      this.filterSelectorGtmService.setFilter(key, value);
    }
  }
}

export interface FilterSelectorDialogData {
  filter: MarketplaceFilter;
  filterDataChange: Subject<MarketplaceFilter>;
  hiddenFilters?: (keyof MarketplaceFilter)[];
  sendFilterToGtm?: boolean;
}
