export enum SourceType {
  FTP = 'FTP',
  URL = 'URL',
  UPLOAD = 'UPLOAD',
  URL_GDOCS = 'URL_GDOCS',
  URL_GD = 'URL_GD',
  URL_DROPBOX = 'URL_DROPBOX',
  NONE = '',
  SHOPIFY = 'SHOPIFY',
  SHOPRENTER = 'SHOPRENTER',
  JUMPSELLER = 'JUMPSELLER',
  EKM = 'EKM',
  BIGCOMMERCE = 'BIGCOMMERCE',
  ECWID = 'ECWID',
  WIX = 'WIX',
  WOOCOMMERCE = 'WOOCOMMERCE',
  SQUARESPACE = 'SQUARESPACE',
  UNAS = 'UNAS',
  SHOPLAZZA = 'SHOPLAZZA',
}
