import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { InformationChipComponent } from '../information-chip/information-chip.component';
import { ListMarkersComponent } from '../list-markers/list-markers.component';
import { CardCornerBadgeComponent, CardCornerBadgePosition } from '../card-corner-badge/card-corner-badge.component';
import { PlanUpsellBoxHeaderComponent } from './plan-upsell-box-header/plan-upsell-box-header.component';
import { PlanUpsellBoxPricingComponent } from './plan-upsell-box-pricing/plan-upsell-box-pricing.component';
import { PlanUpsellBoxFeatureListComponent } from './plan-upsell-box-feature-list/plan-upsell-box-feature-list.component';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';

@Component({
  selector: 'app-plan-upsell-box',
  templateUrl: './plan-upsell-box.component.html',
  styleUrls: ['./plan-upsell-box.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatChipsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    InformationChipComponent,
    ListMarkersComponent,
    CardCornerBadgeComponent,
    PlanUpsellBoxHeaderComponent,
    PlanUpsellBoxPricingComponent,
    PlanUpsellBoxFeatureListComponent,
    AlertMultiComponent,
  ],
})
export class PlanUpsellBoxComponent {
  @Input() plan: PlanBox;
  @Input() isAnnual: boolean;
  @Input() isCurrent: boolean;
  @Input() isTrialValidForEcom: boolean;
  @Input() buttonText: string;
  @Output() buttonClicked: EventEmitter<{ link: string; id: number }> = new EventEmitter<{
    link: string;
    id: number;
  }>();
  @Input() featured: boolean;
  @Input() hasCornerBadge = false;
  @Input() cornerBadgePosition: CardCornerBadgePosition = 'top-right';
  @Input() cornerBadgeBgColor = 'var(--app-neon-yellow-200)';
  @Input() cornerBadgeTextColor = 'var(--app-neon-yellow-800)';

  @Input() cornerBadgeText: string = null;

  constructor() {}
}

export interface PlanBox {
  id: number;
  name: string;
  description?: string;
  amount: PlanBoxAmount;
  hasAnnualPlan: boolean;
  features: string[];
  link: string;
  badgeText: string;
  trialDays?: number;
  featured: PlanBoxFeatured;
}

export interface PlanBoxFeatured {
  monthly: boolean;
  annual: boolean;
}

export interface PlanBoxAmount {
  monthly: number;
  annual: PlanBoxAmountAnnual;
}

export interface PlanBoxAmountAnnual {
  perMonth: number;
  once: number;
  compareAtPrice: number;
}
