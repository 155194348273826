import { Injectable } from '@angular/core';
import { SynceeReviewDialogService } from './syncee-review-dialog.service';
import { SynceeReviewService } from './syncee-review.service';
import { Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ReviewReply } from '../model/review-reply';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';

@UntilDestroy()
@Injectable()
export class SynceeReviewAutoProcessService {
  private openDialogTrigger: Subject<void> = new Subject<void>();
  private cancellationTrigger: Subject<void> = new Subject<void>();
  constructor(
    private synceeReviewDialogService: SynceeReviewDialogService,
    private synceeReviewService: SynceeReviewService,
    private store: Store<AppState>
  ) {
    this.getSynceeReview().subscribe();
    this.observeDialogTrigger().subscribe();
  }

  private observeDialogTrigger(): Observable<ReviewReply> {
    return this.openDialogTrigger.pipe(
      takeUntil(this.cancelled),
      switchMap(() => this.synceeReviewDialogService.open()),
      tap((value) => {
        if (!value) {
          this.completeReviewDialogSubject();
        }
      }),
      filter((value) => value),
      switchMap(() => this.getSynceeReview())
    );
  }

  private completeReviewDialogSubject(): void {
    this.cancellationTrigger.next();
    this.cancellationTrigger.complete();
  }

  private getSynceeReview(): Observable<ReviewReply> {
    return this.store.select(isAuthenticatedSelector).pipe(
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => this.synceeReviewService.getReviewReplies()),
      untilDestroyed(this),
      tap((value) => {
        if (!!value) {
          this.completeReviewDialogSubject();
        }
      })
    );
  }

  public triggerDialogOpen(): void {
    this.openDialogTrigger.next();
  }

  private get cancelled(): Observable<void> {
    return this.cancellationTrigger.asObservable();
  }
}
