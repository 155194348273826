<div class="platform-actions w-100p">
  <div
    class="platform-actions-inner w-100p"
    fxLayout="column"
    fxLayout.sm="row-reverse"
    fxLayout.lt-sm="column"
    fxLayoutAlign="center start"
    fxLayoutGap="20px"
  >
    <div class="platform-buttons w-100p">
      <button mat-button class="syncee-blue-button w-100p" (click)="connectStore()">
        {{ 'GETTING_STARTED.ADD_YOUR_STORE.CONNECT_STORE' | translate }}
      </button>
      <div class="w-100p try-it">
        <div class="centered-text pb-12">{{ 'GETTING_STARTED.ADD_YOUR_STORE.DONT_HAVE_A_STORE' | translate }}</div>
        <button class="w-100p syncee-black-flat-button" (click)="handleTryItForFreeClick()" mat-button>
          {{ 'GETTING_STARTED.ADD_YOUR_STORE.TRY_IT' | translate }}
        </button>
      </div>
    </div>
    <app-help-box></app-help-box>
  </div>
</div>
