import { Component, Input, OnInit } from '@angular/core';
import { SubmenuGroup } from '../model/submenu-group';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-submenu-tab',
  templateUrl: './submenu-tab.component.html',
  styleUrls: ['./submenu-tab.component.scss'],
})
export class SubmenuTabComponent implements OnInit {
  @Input() group: SubmenuGroup;
  ltMd: Observable<boolean>;

  constructor(private screenManagerService: ScreenManagerService) {
    this.ltMd = this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator());
  }

  ngOnInit(): void {}
}
