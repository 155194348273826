import { Pipe, PipeTransform } from '@angular/core';
import { BadgesVO } from '../../../vo/search-product-vo';

@Pipe({
  name: 'marketplaceBadgesFilter',
  standalone: true,
})
export class MarketplaceBadgesPipe implements PipeTransform {
  transform(badges: BadgesVO[]): BadgesVO[] {
    if (!(badges === null || badges === undefined)) {
      return badges.filter((badge) => badge.BADGE_TYPE === 'V7');
    } else {
      return [];
    }
  }
}
