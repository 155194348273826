import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
  selector: 'app-my-retailers',
  templateUrl: './my-retailers.component.html',
})
export class MyRetailersComponent implements AfterViewInit {
  @ViewChild('topRightTemplate') topRightTemplate: TemplateRef<any>;

  constructor(private fuseConfigService: FuseConfigService) {}

  ngAfterViewInit(): void {
    this.initTheme();
  }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: { title: 'INNER_NAV.SUPPLIER_MY_RETAILERS.TITLE' },
      templates: {
        topRightContent: this.topRightTemplate,
      },
    };
  }

  onNavigationEnd(): void {
    this.initTheme();
  }
}
