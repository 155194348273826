import { Component, Input, OnInit } from '@angular/core';
import { ProductCardCountry } from '../product-details-supplier-shipping.component';
import {
  ShippingDetailsDialogComponent,
  ShippingDetailsDialogData,
} from '../../../../../shared/components/dialogs/shipping-details-dialog/shipping-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ShippingType } from '../../../../../vo/supplier/shipping-type';

@Component({
  selector: 'app-product-details-supplier-shipping-overlay',
  templateUrl: './product-details-supplier-shipping-overlay.component.html',
  styleUrls: ['./product-details-supplier-shipping-overlay.component.scss'],
})
export class ProductDetailsSupplierShippingOverlayComponent implements OnInit {
  @Input() countriesToShow: ProductCardCountry[] = [];
  @Input() warehouseLocation: string;
  @Input() isAutoOrder: boolean;
  @Input() supplierUserId: number;
  @Input() isWorldwideShipping: boolean;
  @Input() shippingType: string;
  @Input() companyName: string;
  @Input() taskId: number;
  @Input() supplierCurrency: string;
  @Input() hasPreferredCountry: boolean;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  handleClick(): void {
    this.dialog.open<ShippingDetailsDialogComponent, ShippingDetailsDialogData>(ShippingDetailsDialogComponent, {
      data: {
        userId: this.supplierUserId,
        isWorldWide: this.isWorldwideShipping,
        shippingType: this.shippingType as ShippingType,
        mainWarehouseLocation: this.warehouseLocation,
        supplierCurrency: this.supplierCurrency,
        taskId: this.taskId,
      },
      autoFocus: false,
      panelClass: 'custom-modal-full-width-lt-md',
    });
  }
}
