<div class="chat-body-header-container">
  <button
    *ngIf="isMobile && !channelSideHidden"
    (click)="handleBackClick()"
    mat-icon-button
    [matTooltip]="'Back to active chats'"
  >
    <mat-icon class="cursor-pointer s-20"> keyboard_arrow_left</mat-icon>
  </button>
  <div class="channel-item">
    <img
      #avatarImage
      *ngIf="channel.avatar && !useFallbackAvatar"
      [src]="channel.avatar"
      (error)="useFallbackAvatar = true"
      [alt]="channel.name"
      (click)="navigateToStoreFront()"
      [style.cursor]="!!seoUrl ? 'pointer' : 'initial'"
      class="cursor-pointer"
    />
    <app-avatar
      type="text"
      class="chat-user-avatar"
      *ngIf="!channel.avatar || useFallbackAvatar"
      [diameter]="50"
      (click)="navigateToStoreFront()"
      >{{ channel.name.substring(0, 2) }}</app-avatar
    >
    <div class="channel-name">{{ channel.name }}</div>
  </div>
  <div class="buttons-container" fxLayout="row" fxLayoutAlign="flex-start center">
    <ng-container *ngIf="channelSideHidden && !isMobile">
      <button mat-icon-button (click)="handleMinimizeClick()">
        <mat-icon class="s-20" [matTooltip]="'Minimize chat'">remove</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isMobile || channelSideHidden">
      <button class="close-button" mat-icon-button [matTooltip]="'Close chat'">
        <mat-icon class="s-20" (click)="closeChatAppDialog()">close</mat-icon>
      </button>
    </ng-container>
  </div>
</div>
