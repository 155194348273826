import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { StepId } from '../model/variables/general.variables';

export class GsStepSupplierAction implements GtmAction {
  event = GtmEvent.gs_step_supplier;

  constructor(public payload: GsStepSupplierActionPayload) {}
}

// tslint:disable-next-line:no-empty-interface
export interface GsStepSupplierActionPayload extends StepId {}
