import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GtmAction } from './gtm-action';
import { googleTagManagerId } from '../../../environments/environment';
import { User } from '../user/user';
import { AppTypeGtm } from './model/app-type-gtm';
import { RolesEnum } from '../../vo/roles/roles';
import { combineLatest, Observable, of } from 'rxjs';
import { SCOPES } from '../permission/scopes';
import { getScopesSelector } from '../../store/authentication/authentication.selector';
import { map, switchMap, take } from 'rxjs/operators';
import { includes } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserSelector } from '../../store/user/user.selector';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';

@Injectable({ providedIn: 'root' })
export class GtmManagerService {
  constructor(private gtmService: GoogleTagManagerService, private store: Store<AppState>) {}

  private invalid(isAdmin: boolean): boolean {
    return isAdmin || googleTagManagerId === undefined || googleTagManagerId.length === 0;
  }

  private flushData(data: object): Promise<void> {
    Object.keys(data).forEach((key) => (data[key] = undefined));
    return new Promise(() => {
      this.gtmService.pushTag({ ...data });
    });
  }

  private getDefaultProps(user: User): DefaultProps {
    return {
      user_id: user?.id,
      app_type: !!user ? (user.role === RolesEnum.SUPPLIER ? 'supplier' : 'retailer') : undefined,
    };
  }

  addGtmToDom(): Observable<boolean> {
    return this.getIsAdminObs().pipe(
      switchMap((isAdmin: boolean): Observable<boolean> => {
        switch (true) {
          case this.invalid(isAdmin):
            return of(false);
          default:
            return fromPromise(this.gtmService.addGtmToDom());
        }
      })
    );
  }

  async pushTag(event: GtmAction): Promise<void> {
    combineLatest([this.getUser(), this.getIsAdminObs()]).subscribe(async ([user, isAdmin]) => {
      if (!this.invalid(isAdmin)) {
        await this.gtmService.pushTag({ event: event.event, ...this.getDefaultProps(user), ...event.payload });
        await this.flushData({ ...event.payload });
      }
    });
    return Promise.resolve();
  }

  private getPermissionsObs(): Observable<SCOPES[]> {
    return this.store.select(getScopesSelector(false)).pipe(omitNullOrUndefined(), take(1));
  }

  private getIsAdminObs(): Observable<boolean> {
    return this.getPermissionsObs().pipe(map((permissions) => includes(permissions, SCOPES.ADMIN)));
  }

  private getUser(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(omitNullOrUndefined(), take(1));
  }
}

interface DefaultProps {
  user_id: number;
  app_type: AppTypeGtm;
}
