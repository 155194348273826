<ng-container *ngIf="!hasError; else error">
  <form [formGroup]="shippingInfoForm" fxLayout="column" fxLayoutGap="20px">
    <!--First name-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}</mat-label>
        <input
          matInput
          formControlName="firstName"
          placeholder="{{ 'BILLING_INFO_FORM.FIRST_NAME' | translate }}"
          required
        />
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'BILLING_INFO_FORM.FIRST_NAME_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--Last name-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}</mat-label>
        <input
          matInput
          formControlName="lastName"
          placeholder="{{ 'BILLING_INFO_FORM.LAST_NAME' | translate }}"
          required
        />
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'BILLING_INFO_FORM.LAST_NAME_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--country-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.COUNTRY' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.COUNTRY' | translate }}</mat-label>
        <mat-select formControlName="countryCode" placeholder="{{ 'BILLING_INFO_FORM.COUNTRY' | translate }}" required>
          <mat-option [disabled]="true">
            <ngx-mat-select-search
              [formControl]="countryFilter"
              [placeholderLabel]="'EXPLORE_PRODUCTS.TOP_SEARCH.COUNTRY_FILTER_SEARCH' | translate"
              [noEntriesFoundLabel]="'EXPLORE_PRODUCTS.TOP_SEARCH.COUNTRY_FILTER_NO_MATCH' | translate"
              (keypress)="initFilter()"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let countryItem of filteredCountries | async" [value]="countryItem.code">
            {{ countryItem.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'BILLING_INFO_FORM.COUNTRY_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--Address1-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}</mat-label>
        <input
          matInput
          formControlName="address1"
          placeholder="{{ 'BILLING_INFO_FORM.ADDRESS1' | translate }}"
          required
        />
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'BILLING_INFO_FORM.ADDRESS1_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--Address2-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}</mat-label>
        <input matInput formControlName="address2" placeholder="{{ 'BILLING_INFO_FORM.ADDRESS2' | translate }}" />
      </mat-form-field>
    </div>

    <!--City-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.CITY' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.CITY' | translate }}</mat-label>
        <input matInput formControlName="city" placeholder="{{ 'BILLING_INFO_FORM.CITY' | translate }}" required />
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'BILLING_INFO_FORM.CITY_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--state-->
    <div
      *ngIf="this.shippingInfoForm.controls['countryCode'].value === 'US'"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="stretch"
    >
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'RETAILER_ORDER_SETTINGS.STATE' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'RETAILER_ORDER_SETTINGS.STATE' | translate }}</mat-label>
        <mat-select formControlName="provinceCode" placeholder="{{ 'RETAILER_ORDER_SETTINGS.STATE' | translate }}">
          <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">
            {{ state.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'RETAILER_ORDER_SETTINGS.STATE_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.ZIP' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'RETAILER_ORDER_SETTINGS.ZIP' | translate }}</mat-label>
        <input matInput formControlName="zip" placeholder="{{ 'RETAILER_ORDER_SETTINGS.ZIP' | translate }}" required />
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'RETAILER_ORDER_SETTINGS.ZIP_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--company name-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}</mat-label>
        <input matInput formControlName="company" placeholder="{{ 'BILLING_INFO_FORM.COMPANY_NAME' | translate }}" />
      </mat-form-field>
    </div>

    <!-- phone-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
      <div *ngIf="labelled && !ltMd" fxFlex="1 0 0" class="h5">
        {{ 'RETAILER_ORDER_SETTINGS.PHONE_NUMBER' | translate }}
      </div>
      <mat-form-field
        appearance="fill"
        class="w-100p input-without-padding-except-error"
        [ngClass]="labelled && !ltMd ? 'select-without-label' : ''"
        fxFlex="1 0 0"
      >
        <mat-label *ngIf="!labelled || ltMd">{{ 'RETAILER_ORDER_SETTINGS.PHONE_NUMBER' | translate }}</mat-label>
        <input
          matInput
          type="phone"
          formControlName="phone"
          placeholder="{{ 'RETAILER_ORDER_SETTINGS.PHONE_NUMBER' | translate }}"
          required
        />
        <mat-error *ngIf="shippingInfoForm.invalid">
          {{ 'RETAILER_ORDER_SETTINGS.PHONE_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</ng-container>

<ng-template #progress>
  <div class="position-relative py-20" fxLayoutAlign="center center">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<ng-template #error>
  <div>{{ 'BILLING_INFO_FORM.SOMETHING_WRONG' | translate }}</div>
</ng-template>
