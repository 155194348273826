import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DimSettings } from '../../../service/pim/pim.service';

@Component({
  selector: 'app-dimensions-settings',
  templateUrl: './dimensions-settings.component.html',
})
export class DimensionsSettingsComponent implements OnInit {
  constructor(
    private dialog: MatDialogRef<DimensionsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DimSettings
  ) {}

  ngOnInit(): void {}

  submit(): void {
    this.dialog.close(this.data);
  }
}
