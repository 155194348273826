export class FileList {
  private _isDirectory: boolean;
  private _path: string;
  private _name: string;

  get isDirectory(): boolean {
    return this._isDirectory;
  }

  set isDirectory(value: boolean) {
    this._isDirectory = value;
  }

  get path(): string {
    return this._path;
  }

  set path(value: string) {
    this._path = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
