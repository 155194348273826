<div class="supplier-simple-item-container" fxLayoutAlign="start center" fxLayoutGap="8px">
  <div fxFlex="0 0 auto" class="supplier-simple-image-container">
    <app-skeleton-image width="58px" height="58px"></app-skeleton-image>
  </div>
  <div fxFlex="1 0 0" class="supplier-simple-info">
    <div class="supplier-simple-name">
      <app-skeleton-image width="110px" height="20px"></app-skeleton-image>
    </div>
  </div>
</div>
