import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, NgModel } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
import { DialogMultiWrapperComponent } from '../../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { DeleteModalVO } from './delete-modal-vo';
import { DeleteValidatorDirective } from './delete-validator.directive';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    DialogMultiWrapperComponent,
    TranslateModule,
    DeleteValidatorDirective,
  ],
})
export class DeleteModalComponent implements OnInit {
  @ViewChild('inputDel', { static: true }) inputDel: NgModel;

  modalTitle: string = this._translateService.instant('SHARED_MODULE.DELETE_MODAL.DEFAULT_TITLE');
  modalSubject: string = this._translateService.instant('SHARED_MODULE.DELETE_MODAL.DEFAULT_MESSAGE');
  modalDeleteClose: string = this._translateService.instant('SHARED_MODULE.DELETE_MODAL.CLOSE');
  modalDeleteConfirm: string = this._translateService.instant('SHARED_MODULE.DELETE_MODAL.DELETE');
  modalDeleteMessage: string;

  _hasTextConfirm: boolean;
  _inputDelete: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private deleteModalVo: DeleteModalVO,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.modalTitle = Utils.isNullOrUndefined(this.deleteModalVo.title) ? this.modalTitle : this.deleteModalVo.title;
    this.modalSubject = Utils.isNullOrUndefined(this.deleteModalVo.message)
      ? this.modalSubject
      : this.deleteModalVo.message;
    this.modalDeleteMessage =
      !Utils.isNullOrUndefined(this.deleteModalVo.deleteMessage) && this.deleteModalVo.deleteMessage;
    this._hasTextConfirm =
      !Utils.isNullOrUndefined(this.deleteModalVo.deleteMessage) &&
      !Utils.isNullOrUndefined(this.deleteModalVo.deleteMessage);
  }

  onSubmit(): void {
    if (
      !Utils.isNullOrUndefined(this.deleteModalVo.deleteMessage) &&
      !Utils.isNullOrUndefined(this.deleteModalVo.deleteString)
    ) {
      if (this._inputDelete === this.deleteModalVo.deleteString) {
        this.dialogRef.close(true);
      }
    } else {
      this.dialogRef.close(true);
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
