import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FileDownloadService } from '../../../service/taskwizard/file-download.service';
import { FileUploadDownloadInputVo } from '../../taskwizard/addfiles/vo/file-upload-download-input-vo';
import { UrlUploadComponent } from '../../taskwizard/addfiles/url-upload/url-upload.component';
import { FileUploadResponseVo } from '../../taskwizard/addfiles/vo/file-upload-response-vo';
import { takeUntil } from 'rxjs/operators';
import { SharedUrlUploadInputVo } from '../../taskwizard/addfiles/vo/shared-url-upload-input-vo';
import { UrlSharedComponent } from '../../taskwizard/addfiles/url-upload/url-shared.component';
import { FileUploadComponent } from '../../taskwizard/addfiles/file-upload/file-upload.component';
import { ShopifyComponent } from '../../taskwizard/addfiles/shopify/shopify.component';
import { PreviewDownloadedFileComponent } from '../../taskwizard/addfiles/ftp/preview-downloaded-file.component';
import { Constants } from '../../../utils/Constants';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FtpDialogVO } from '../../taskwizard/addfiles/vo/ftp-dialog-vo';
import { FtpclientComponent } from '../../taskwizard/addfiles/ftp/ftpclient.component';
import { FtpUploadResponseVO } from '../../taskwizard/addfiles/vo/ftp-upload-response-vo';
import { TabData } from '../../taskwizard/addfiles/addfiles.component';
import { ProdSampleInputVo } from '../../taskwizard/addfiles/vo/prod-sample-input-vo';
import { TaskFile } from '../../../service/taskwizard/taskwizard-update-response';
import { Utils } from '../../../utils/utils';
import { TabFileManagerInputVo } from '../../taskwizard/addfiles/vo/tab-file-manager-input-vo';
import { TaskService } from '../../../service/task/task.service';
import { ChipColor } from '../../droppable-input/chip';

@Component({
  selector: 'app-source-changer-dialog',
  templateUrl: './source-changer-dialog.component.html',
  styleUrls: ['./source-changer-dialog.component.scss'],
})
export class SourceChangerDialogComponent implements OnInit {
  private readonly TAB_TEXT_SHOPIFY_STORE = 'Shopify Store';
  @ViewChild('addFilesContainer', { read: ElementRef, static: true }) addFilesContainer: ElementRef;
  disableFinishBtn = true;

  taskId: number;
  selectedTab = 0;
  conType: string;
  taskName: string;

  tabs: TabData[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private taskService: TaskService,
    private matDialogRef: MatDialogRef<SourceChangerDialogComponent>
  ) {
    this.taskId = data.taskId;
  }

  ngOnInit(): void {
    this.taskService.getTaskSourceSettings(this.taskId).subscribe((resp) => {
      this.taskName = resp.task.name;
      resp.files.forEach((taskFile, index) => {
        taskFile.settings = JSON.parse(taskFile.settings);
        let name = '';
        if (resp.task.sourceType === Constants.SHOPIFY_CONNECTION_TYPE) {
          name = this.TAB_TEXT_SHOPIFY_STORE;
        } else {
          name = this.trimNameToCharLimit(Utils.getFileNameFromPath(taskFile.remotePath), 23);
        }
        const inputVo = new TabFileManagerInputVo();
        inputVo.taskId = this.taskId;
        inputVo.tabIndex = index;
        inputVo.conType = resp.task.sourceType;
        inputVo.taskFile = taskFile;
        this.tabs.push({
          label: name,
          inputs: inputVo,
          fileId: Utils.isEmptyObject(taskFile.settings) ? null : taskFile.fileId,
          chipColor: {
            backgroundColor: Constants.CHIP_BACKGROUND_COLORS[index],
            color: Constants.CHIP_COLORS[index],
            iconColor: Constants.CHIP_ICON_COLORS[index],
            iconActiveColor: Constants.CHIP_ICON_COLORS_ACTIVE[index],
          },
        });
      });
      this.selectedTab = this.tabs.length - 1;
    });
  }

  trimNameToCharLimit(name: string, limit: number): string {
    return name.substring(name.length - limit, name.length);
  }

  initProductSample(index): void {
    this.selectedTab = index;
    if (index >= this.tabs.length || index < 0) {
      return;
    }
    const tabData = this.tabs[index];
    this.setFileTabBorderColor(tabData.chipColor);
  }

  setFileTabBorderColor(color: ChipColor): void {
    this.addFilesContainer.nativeElement.style.cssText = '--tab-ink-bar-color: ' + color.backgroundColor;
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }

  finish(): void {
    this.closeDialog();
  }

  onSourceChanged($event: boolean): void {
    this.disableFinishBtn = false;
  }
}
