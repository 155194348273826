import { Action } from '@ngrx/store';
import { SubscriptionVO } from 'app/vo/subscription-vo';
import { ROLE_TYPE } from '../../vo/roles/roles';

export enum SubscriptionActionTypes {
  SUBSCRIPTION_FETCH_START = '[Subscription] Fetch Subscriptions',
  SUBSCRIPTION_FETCH_SUCCESS = '[Subscription] Fetch Success Subscriptions',
}

export interface SubscriptionFetchStartPayload {
  ecomId: number;
  role: ROLE_TYPE;
}

export interface SubscriptionsFetchSuccessPayload {
  subscriptions: SubscriptionVO;
}

export class SubscriptionFetchStartAction implements Action {
  readonly type = SubscriptionActionTypes.SUBSCRIPTION_FETCH_START;
  constructor(public payload: SubscriptionFetchStartPayload) {}
}

export class SubsciptionFetchSuccessAction implements Action {
  readonly type = SubscriptionActionTypes.SUBSCRIPTION_FETCH_SUCCESS;
  constructor(public payload: SubscriptionsFetchSuccessPayload) {}
}

export type SUBSCRIPTION_ALL_ACTIONS = SubscriptionFetchStartAction | SubsciptionFetchSuccessAction;
