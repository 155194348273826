import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-dashboard-sales-overview',
  templateUrl: './skeleton-dashboard-sales-overview.component.html',
  styleUrls: ['./skeleton-dashboard-sales-overview.component.scss'],
})
export class SkeletonDashboardSalesOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
