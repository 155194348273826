import { Component, OnInit } from '@angular/core';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { map } from 'rxjs';
import type { Widget } from 'app/vo/widget';
import { PreferredCategoryWidgetsService } from '../services/preferred-category-widgets.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { BreakpointState } from '@angular/cdk/layout';

@UntilDestroy()
@Component({
  selector: 'app-category-card-list',
  templateUrl: './category-card-list.component.html',
  styleUrls: ['./category-card-list.component.scss'],
})
export class CategoryCardListComponent implements OnInit {
  public widgets: Widget[];

  public loading: boolean;

  public cardHeight: number;

  protected readonly MAX_WIDGETS = PreferredCategoryWidgetsService.MAX_WIDGETS;

  constructor(
    private screenManagerService: ScreenManagerService,
    private preferredCategoryWidgetsService: PreferredCategoryWidgetsService
  ) {}

  public ngOnInit(): void {
    this.initLtMdSubscription();
    this.initLoadingSubscription();
    this.initWidgetsSubscription();
  }

  private initLoadingSubscription(): void {
    this.preferredCategoryWidgetsService.loading$
      .pipe(omitNullOrUndefined(), untilDestroyed(this))
      .subscribe((loading: boolean): void => {
        this.loading = loading;
      });
  }

  private initWidgetsSubscription(): void {
    this.preferredCategoryWidgetsService.widgets$
      .pipe(omitNullOrUndefined(), untilDestroyed(this))
      .subscribe((widgets: Widget[]): void => {
        this.widgets = widgets;
      });
  }

  private initLtMdSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(
        omitNullOrUndefined(),
        untilDestroyed(this),
        map((observation: BreakpointState) => observation.matches)
      )
      .subscribe((ltMd: boolean): void => {
        this.cardHeight = ltMd ? 120 : 200;
      });
  }
}
