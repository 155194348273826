<div
  fxLayout="row"
  fxLayout.lt-lg="column-reverse"
  fxLayoutAlign.lt-lg="start start"
  fxLayoutAlign="space-between center"
  fxLayoutGap="10px"
>
  <div
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-lg="start start"
    fxLayoutGap="12px"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="typography-body-large-2 no-wrap">{{ headerService.titleConfig.originalCategoriesTitle }}</div>
      <div class="p-8 centered" ngClass.lt-md="py-0" [matTooltip]="headerService.titleConfig.originalCategoriesTooltip">
        <mat-icon class="s-20 icon-filled">info</mat-icon>
      </div>
    </div>
    <div class="category-mapping-searchbar">
      <app-searchbar-custom (searchTermChange)="headerService.setMappedSearchTerm($event)"></app-searchbar-custom>
    </div>
  </div>

  <mat-form-field class="input-without-padding category-mapping-filter" appearance="fill">
    <mat-label>{{ 'CATEGORY_MAPPING_COMPONENT.CATEGORIES' | translate }}</mat-label>
    <mat-select
      [value]="headerService.categoryFilter | async"
      (selectionChange)="headerService.setCategoryFilter($event.value)"
    >
      <mat-option [value]="headerService.allFilterOption">{{
        'CATEGORY_MAPPING_COMPONENT.ALL' | translate
      }}</mat-option>
      <mat-option [value]="headerService.notCategorizedFilterOption">{{
        'CATEGORY_MAPPING_COMPONENT.NOT_CATEGORIZED' | translate
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
