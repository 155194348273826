import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { CustomDialogWrapperComponent } from '../dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overlay-responsive-wrapper',
  templateUrl: './overlay-responsive-wrapper.component.html',
  styleUrls: ['./overlay-responsive-wrapper.component.scss'],
  standalone: true,
  imports: [CommonModule, OverlayModule, CustomDialogWrapperComponent],
})
/*
 * slots:
 * - trigger
 * - overlay-content
 *
 * usage example:
 *
 * <div slot="overlay-content"></div> (or ng-container)
 */
export class OverlayResponsiveWrapperComponent implements OnInit, OnDestroy {
  @Input() overlayWidth!: number;
  @Input() openOverlay: Subject<boolean>;
  isOverlayOpen = false;
  isDialog: boolean;
  positions = [
    new ConnectionPositionPair(
      {
        originX: 'end',
        originY: 'bottom',
      },
      {
        overlayX: 'end',
        overlayY: 'top',
      },
      0,
      8
    ),
  ];
  private readonly unsubscribeAll: Subject<void>;

  constructor(private screenManagerService: ScreenManagerService, private dialog: MatDialog) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((state) => (this.isDialog = state.matches));
    this.subscribeToOpenOverlay();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToOpenOverlay(): void {
    if (!!this.openOverlay) {
      this.openOverlay.pipe(takeUntil(this.unsubscribeAll)).subscribe((open) => (this.isOverlayOpen = open));
    }
  }

  openDialog(ref: TemplateRef<any>): MatDialogRef<any> {
    return this.dialog.open(ref, {
      panelClass: 'custom-modal-full-width-lt-md',
      autoFocus: false,
    });
  }
}
