import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryMappingService } from '../../../../service/category-mapping/category-mapping.service';
import { environment } from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ProductImageThumbnailCdnPipe } from 'app/shared/pipes/product-image-resizer-cdn.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ProductImageErrorHandlerDirective } from 'app/shared/directives/product-image-error-handler.directive';

@Component({
  selector: 'app-category-mapping-product-example-dialog',
  templateUrl: './category-mapping-product-example-dialog.component.html',
  styleUrls: ['./category-mapping-product-example-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatDialogModule,
    ProductImageThumbnailCdnPipe,
    MatButtonModule,
    ProductImageErrorHandlerDirective,
  ],
})
export class CategoryMappingProductExampleDialogComponent implements OnInit {
  products = [];
  categoryName = '';
  env = environment;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private categoryMappingService: CategoryMappingService) {}

  ngOnInit(): void {
    if (this.data.productCategory) {
      if (this.data.taskId) {
        this.categoryName = this.data.productCategory;
        const rest = {
          taskId: this.data.taskId,
          productCategory: this.data.productCategoryId,
        };

        this.categoryMappingService.getProductByCategory(rest).subscribe((elem) => {
          console.log(elem);
          this.products = elem;
        });
      }

      if (this.data.catalogId) {
        this.categoryName = this.data.productCategory;
        const rest = {
          rcatalogId: this.data.catalogId,
          productCategory: this.data.productCategoryId,
        };

        this.categoryMappingService.getProductByCategory(rest).subscribe((elem) => {
          this.products = elem;
        });
      }
    }
  }

  getDefaultImage(): string {
    return 'assets/images/placeholder-image.png';
  }
}
