import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from 'app/service/rest/microservices/spring-rest.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShippingCatalogRestService {
  constructor(private springRestService: SpringRestService) {}

  createCatalogConnection(shippingProfileID: number, catalogIDs: number[]): Observable<void> {
    return this.springRestService
      .post(
        MicroserviceNames.ORDER,
        `/v1/Shipping/Profile/${shippingProfileID}/Tasks`,
        { taskIds: catalogIDs },
        undefined,
        true
      )
      .pipe(map(() => {}));
  }

  deleteCatalogConnection(shippingProfileID: number, catalogIDs: number[]): Observable<void> {
    return this.springRestService
      .delete(
        MicroserviceNames.ORDER,
        `/v1/Shipping/Profile/${shippingProfileID}/Tasks?${catalogIDs.map((c) => `taskId=${c}`).join('&')}`,
        undefined,
        true
      )
      .pipe(map(() => {}));
  }

  getTasksForProfile(shippingProfileID: number[]): Observable<TaskForProfileResp[]> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/Tasks`,
      {
        profileId: shippingProfileID,
      },
      true
    );
  }
}

export interface TaskForProfileResp {
  shippingProfileId: number;
  taskId: number;
}
