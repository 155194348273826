import { Injectable } from '@angular/core';
import { Utils } from '../../utils/utils';
import { switchMap, map, tap } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { CurrencyExchangeToSelectedPipe } from '../../shared/pipes/currency-exchange-to-selected.pipe';
import { CurrencyTransformToSelectedPipe } from '../../shared/pipes/currency-transform-to-selected.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SupplierOrderLimit } from '../manage-orders/syncee-orders.service';

@Injectable()
export class MinimumOrderAmountDisplayService {
  constructor(
    private currencyExchangeToSelectedPipe: CurrencyExchangeToSelectedPipe,
    private currencyTransformToSelectedPipe: CurrencyTransformToSelectedPipe,
    private translateService: TranslateService
  ) {}

  getMinimumOrderAmountDisplay(supplierOrderLimit: SupplierOrderLimit[], paymentCurrency: string): Observable<string> {
    if (!Utils.isNullOrUndefined(supplierOrderLimit) && supplierOrderLimit.length > 0) {
      if (supplierOrderLimit.length == 1) {
        return this.currencyExchangeToSelectedPipe.transform(supplierOrderLimit[0].amount, paymentCurrency).pipe(
          switchMap((convertedAmount) => this.currencyTransformToSelectedPipe.transform(convertedAmount)),
          map((amount) =>
            this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.SUPPLIER_ORDER_LIMIT.MIN_ORDER', {
              amount: amount,
            })
          )
        );
      } else {
        return combineLatest([
          this.currencyExchangeToSelectedPipe.transform(
            supplierOrderLimit.find((elem) => elem.orderCount === -1).amount,
            paymentCurrency
          ),
          this.currencyExchangeToSelectedPipe.transform(
            supplierOrderLimit.find((elem) => elem.orderCount === 1).amount,
            paymentCurrency
          ),
        ]).pipe(
          switchMap(([convertedAmountFirst, convertedAmounReorder]) => {
            return combineLatest([
              this.currencyTransformToSelectedPipe.transform(convertedAmountFirst),
              this.currencyTransformToSelectedPipe.transform(convertedAmounReorder),
            ]);
          }),
          map(([amount_first, amount_reorder]) => {
            return this.getTranslation(
              'STOREFRONT.DIALOG.ORDER_AND_CATALOG.SUPPLIER_ORDER_LIMIT.MIN_ORDER_WITH_FIRST',
              {
                amount_first: amount_first,
                amount_reorder: amount_reorder,
              }
            );
          })
        );
      }
    } else {
      return of(this.getTranslation('STOREFRONT.DIALOG.ORDER_AND_CATALOG.SUPPLIER_ORDER_LIMIT.NO_MINIMUM_ORDER'));
    }
  }

  private getTranslation(key: string, interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams);
  }
}
