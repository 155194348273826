import { ReplacerSettingsSupplier } from './replacer-settings-supplier';
import { Replacer } from './replacer';

export class ReplacerRef {
  private static idGenerator = 0;
  public id: number;
  public initialSettings: Replacer;

  private _ref: ReplacerSettingsSupplier;

  constructor(initialSettings?: Replacer) {
    this.id = ReplacerRef.idGenerator++;
    if (initialSettings) {
      this.initialSettings = initialSettings;
    }
  }

  public setSupplier(supplier: ReplacerSettingsSupplier): void {
    this._ref = supplier;
  }

  public getSettings(): Replacer {
    return this._ref.getReplacerSettings();
  }
}
