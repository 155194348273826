import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImageSeo } from '../../../vo/image-seo';

@Component({
  selector: 'app-video-dialog-thumbnail',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './video-dialog-thumbnail.component.html',
  styleUrls: ['./video-dialog-thumbnail.component.scss'],
})
export class VideoDialogThumbnailComponent implements OnInit {
  @Input() thumbnail: ImageSeo;

  constructor() {}

  ngOnInit(): void {}
}
