import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ImportListPriceItem } from '../model/import-list-price-item';
import { PriceType } from '../../../../main/taskwizard/supplier-task-pricing/enums/price-type.enum';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-import-list-price-example',
  templateUrl: './import-list-price-example.component.html',
  styleUrls: ['./import-list-price-example.component.scss'],
})
export class ImportListPriceExampleComponent implements OnInit {
  @Input() isDatafeed: boolean;
  @Input() currency = 'USD';
  @Input() priceType: PriceType;
  @Input() set priceRule(priceRule: ImportListPriceItem) {
    this.generateRandomeExampleCalculation(priceRule);
  }

  examplePrice: number = undefined;
  calculatedPrice: string = undefined;

  constructor() {}

  ngOnInit(): void {}

  generateRandomeExampleCalculation(priceRule: ImportListPriceItem): void {
    if (!Utils.isNullOrUndefined(priceRule.from)) {
      const from = Number(priceRule.from) || 0;
      const to = Number(priceRule.to) || from + 100;
      this.examplePrice = Math.floor(Math.random() * (to - from + 1)) + from;
      this.calculatedPrice = formatNumber(
        this.examplePrice * ((100 + priceRule.percentage * priceRule.percAmountOperator) / 100) +
          (priceRule.fixAmount || 0) * priceRule.fixAmountOperator,
        'en-US',
        '.1-2'
      );
    }
  }
}
