import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
})
export class NotificationOverlayComponent implements OnInit, OnDestroy {
  @Output() closeOverlay = new EventEmitter<void>();
  markAllAsSeenClicked: Subject<void>;

  constructor(private router: Router) {
    this.markAllAsSeenClicked = new Subject();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.markAllAsSeenClicked.complete();
  }

  navigateToSettings(): void {
    this.router
      .navigate([`/${RetailerCenterNavigation.SETTINGS.NOTIFICATION_SETTINGS}`])
      .then(() => this.closeOverlay.emit());
  }

  handleMarkAllAsSeenClicked(): void {
    this.markAllAsSeenClicked.next();
  }
}
