import { Component, Input, OnInit } from '@angular/core';
import { TaskwizardUpdateService } from '../../service/taskwizard/taskwizard-update.service';
import { TaskStepLockService } from '../../service/task/task-step-lock.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { FilterStructure } from '../../vo/filter/filter-structure';
import { FilterGroup } from '../filter-new/model/filter-group';
import { FilterGroupConditionEnum } from '../filter-new/model/filter-group-condition';
import { FilterMapperService } from '../filter-new/service/filter-mapper.service';
import { v4 as uuidv4 } from 'uuid';
import { FilterBaseErrorCheckService } from '../filter-new/service/filter-base-error-check.service';
import { FilterItemError } from '../filter-new/model/helper/filter-item-error';
import { NotificationService } from '../notification/notification.service';
import { AutocompleteSuggestion } from '../retailer-import-list/tab-items/retailer-import-list-products/model/autocomplete-suggestion';
import { NeedAutocomplete } from '../retailer-import-list/tab-items/retailer-import-list-products/model/need-autocomplete';
import { isEqual } from 'lodash';
import { SUGGESTION_EXCEPTIONS } from '../../utils/Constants';
import { PermissionService } from '../../service/permission/permission.service';
import { SCOPES } from '../../service/permission/scopes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
})
export class TaskFilterComponent implements OnInit {
  @Input() taskId: number;
  @Input() filterStructures: Observable<FilterStructure[]>;
  @Input() variantFilterStructures: Observable<FilterStructure[]>;
  @Input() taskFilters: any;
  @Input() variantTaskFilters: any;
  @Input() useDefaultFilter: boolean;
  @Input() variantHasTaskFilters: boolean;
  @Input() defaultFieldNames: TaskFilterDefaultFieldNames;
  @Input() canUseTaskFilters: boolean;
  @Input() canUseDefaultFilter: boolean;
  @Input() isDefaultFilterLocked = true;
  @Input() hasVariantFilter: boolean;
  @Input() filterSave: (taskId: number, filters: any, variantFilters?: any) => Observable<any>;
  stepLocked = false;
  fieldStructures: FilterStructure[];
  variantFieldStructures: FilterStructure[];
  filterGroup: FilterGroup;
  variantFilterGroup: FilterGroup;
  filterItemErrors: FilterItemError[] = [];
  variantFilterItemErrors: FilterItemError[] = [];
  hasPermissionToEditLockedCatalog: boolean;
  private filterLoaded: boolean;
  private variantFilterLoaded: boolean;

  autocompleteSuggestion: BehaviorSubject<AutocompleteSuggestion>;
  autocompleteFrom = 0;
  lastNeedAutocomplete: NeedAutocomplete;
  hasNoMoreAutocomplete: Subject<boolean>;
  private readonly AUTOCOMPLETE_SIZE = 10;

  constructor(
    private taskwizardUpdateService: TaskwizardUpdateService,
    private stepLockService: TaskStepLockService,
    private filterMapperService: FilterMapperService,
    private filterBaseErrorCheckService: FilterBaseErrorCheckService,
    private notificationService: NotificationService,
    private permissionService: PermissionService
  ) {
    this.autocompleteSuggestion = new BehaviorSubject<AutocompleteSuggestion>({ suggestions: [], forKey: null });
    this.hasNoMoreAutocomplete = new Subject<boolean>();
  }

  ngOnInit(): void {
    this.handlePermissionToEditLockedStep();
    this.filterLoaded = false;
    this.variantFilterLoaded = false;
    this.initStepLock();
    this.filterStructures.subscribe((filterStructures) => (this.fieldStructures = filterStructures));
    if (this.hasVariantFilter) {
      this.variantFilterStructures.subscribe((filterStructures) => (this.variantFieldStructures = filterStructures));
    }
    this.filterGroup = this.useDefaultFilter ? this.getDefaultFilter() : this.mapFilterToFilterGroup();
    this.variantFilterGroup = this.mapVariantFilterToFilterGroup();
  }

  private handlePermissionToEditLockedStep(): void {
    this.permissionService
      .hasPermissions(SCOPES.CATALOG_FILTER_LOCKED_EDIT)
      .pipe(untilDestroyed(this))
      .subscribe((hasPermission) => (this.hasPermissionToEditLockedCatalog = hasPermission));
  }

  private initStepLock(): void {
    this.stepLockService.getTaskStepsForTask(this.taskId).subscribe((lockedSteps) => {
      if (lockedSteps.includes('FILTER')) {
        this.stepLocked = true;
      }
    });
  }

  private mapFilterToFilterGroup(): FilterGroup {
    return this.filterMapperService.mapFilterToFilterGroup([this.taskFilters]);
  }

  private mapVariantFilterToFilterGroup(): FilterGroup {
    return this.hasVariantFilter ? this.filterMapperService.mapFilterToFilterGroup([this.variantTaskFilters]) : null;
  }

  private getDefaultFilter(): FilterGroup {
    return !this.canUseDefaultFilter
      ? this.mapFilterToFilterGroup()
      : {
          id: uuidv4().toString(),
          filterItems: [],
          conditions: [FilterGroupConditionEnum.AND],
          children: [
            {
              id: uuidv4().toString(),
              filterItems: [
                {
                  id: uuidv4().toString(),
                  values: [],
                  fieldStructures: [],
                  field: this.defaultFieldNames.publishedField,
                  condition: 'exists',
                  locked: this.isDefaultFilterLocked,
                },
              ],
              conditions: [],
              children: [],
            },
            {
              id: uuidv4().toString(),
              filterItems: [
                {
                  id: uuidv4().toString(),
                  values: [],
                  field: this.defaultFieldNames.imagesField,
                  condition: 'exists',
                  fieldStructures: [],
                  locked: this.isDefaultFilterLocked,
                },
                ...(!!this.defaultFieldNames.variantImageField
                  ? [
                      {
                        id: uuidv4().toString(),
                        values: [],
                        field: this.defaultFieldNames.variantImageField,
                        condition: 'exists',
                        fieldStructures: [],
                        locked: this.isDefaultFilterLocked,
                      },
                    ]
                  : []),
              ],
              conditions: [FilterGroupConditionEnum.OR],
              children: [],
            },
          ],
        };
  }

  checkAndHandleVariantErrors(): boolean {
    if (this.hasVariantFilter) {
      this.variantFilterItemErrors = this.filterBaseErrorCheckService.checkForBaseErrors(this.variantFilterGroup);
      return this.variantFilterItemErrors.length > 0;
    }
    return false;
  }

  checkAndHandleErrors(): boolean {
    this.filterItemErrors = this.filterBaseErrorCheckService.checkForBaseErrors(this.filterGroup);
    return this.filterItemErrors.length > 0;
  }

  isStepValid(): boolean {
    return true;
  }

  saveStep(): Observable<any> {
    if (this.checkAndHandleErrors() || this.checkAndHandleVariantErrors()) {
      this.notificationService.warning('Please fill all the required fields and conditions!');
      return;
    }
    const filters = this.filterMapperService.mapFilerGroupToFilter(this.filterGroup);
    const variantFilters =
      this.hasVariantFilter && !!this.variantFilterGroup
        ? this.filterMapperService.mapFilerGroupToFilter(this.variantFilterGroup)
        : [];
    return this.filterSave(this.taskId, filters, variantFilters);
  }

  handleNeedAutoComplete(event: NeedAutocomplete): void {
    if (!isEqual(event, this.lastNeedAutocomplete)) {
      this.autocompleteFrom = 0;
      this.autocompleteSuggestion.next({ suggestions: [], forKey: event.structure.key });

      this.fetchAutocomplete(event).subscribe((res) => {
        this.hasNoMoreAutocomplete.next(res.length < this.AUTOCOMPLETE_SIZE);
        this.lastNeedAutocomplete = event;
        this.autocompleteSuggestion.next({ suggestions: res, forKey: event.structure.key });
      });
    }
  }

  handleNeedMoreAutocomplete(event: NeedAutocomplete): void {
    this.autocompleteFrom += this.AUTOCOMPLETE_SIZE;
    this.fetchAutocomplete(event).subscribe((res) => {
      this.hasNoMoreAutocomplete.next(res.length < this.AUTOCOMPLETE_SIZE);
      this.autocompleteSuggestion.next({
        suggestions: [...this.autocompleteSuggestion.value.suggestions, ...res],
        forKey: event.structure.key,
      });
    });
  }

  private fetchAutocomplete(event: NeedAutocomplete): Observable<string[]> {
    if (!SUGGESTION_EXCEPTIONS.includes(event.structure.key)) {
      const upperTerm = event.term?.toUpperCase();
      const arrayToUse = upperTerm?.length
        ? event.structure.autoValues.filter((auto) => auto.toUpperCase().includes(upperTerm))
        : event.structure.autoValues;

      return of(
        arrayToUse.slice(
          this.autocompleteFrom * this.AUTOCOMPLETE_SIZE,
          this.autocompleteFrom * this.AUTOCOMPLETE_SIZE + this.AUTOCOMPLETE_SIZE
        )
      );
    } else {
      return of([]);
    }
  }
}

export interface TaskFilterDefaultFieldNames {
  publishedField: string;
  imagesField: string;
  variantImageField?: string;
}
