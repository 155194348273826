<form class="price-item-wrapper" [formGroup]="form">
  <mat-form-field fxFlex="0 1 auto" appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ 'IMPORT_LIST.PRICING.FROM' | translate }}</mat-label>
    <input matInput formControlName="from" />
    <div matSuffix>{{ currency }}</div>
    <mat-error *ngIf="form.get('from').hasError"> {{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="0 1 auto" appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ isLastRow ? '∞' : ('IMPORT_LIST.PRICING.TO' | translate) }}</mat-label>
    <input matInput formControlName="to" />
    <div matSuffix>{{ currency }}</div>
    <mat-error *ngIf="form.get('to').hasError"> {{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
  </mat-form-field>

  <div class="price-toogle-group" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="price-toogle-group-label">{{ 'IMPORT_LIST.PRICING.CHANGE_PRICE_WITH' | translate }}</div>
    <mat-button-toggle-group
      formControlName="percAmountOperator"
      name="percAmountOperator"
      aria-label="Percentage amount operator"
    >
      <mat-button-toggle *ngIf="enabledPercentageOperators.includes(1)" [value]="1">+</mat-button-toggle>
      <mat-button-toggle *ngIf="enabledPercentageOperators.includes(-1)" [value]="-1">-</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-form-field fxFlex="0 1 auto" appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ 'IMPORT_LIST.PRICING.PERCENTAGE' | translate }}</mat-label>
    <input matInput formControlName="percentage" />
    <div matSuffix>%</div>
    <mat-error *ngIf="form.get('percentage').hasError">
      {{
        form.get('percentage').errors && form.get('percentage').errors['max']
          ? ('FORM_VALIDATORS.MAX_VALUE' | translate : { max: PERCENTAGE_NEGATIVE_MAX_AMOUNT })
          : ('FORM_VALIDATORS.REQUIRED' | translate)
      }}</mat-error
    >
  </mat-form-field>

  <div class="price-toogle-group" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="price-toogle-group-label">{{ 'IMPORT_LIST.PRICING.AND_WITH' | translate }}</div>
    <mat-button-toggle-group
      formControlName="fixAmountOperator"
      name="fixAmountOperator"
      aria-label="Fix amount operator"
    >
      <mat-button-toggle *ngIf="enabledFixOperators.includes(1)" [value]="1">+</mat-button-toggle>
      <mat-button-toggle *ngIf="enabledFixOperators.includes(-1)" [value]="-1">-</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-form-field fxFlex="0 1 auto" appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ 'IMPORT_LIST.PRICING.FIX' | translate }}</mat-label>
    <input matInput formControlName="fixAmount" />
    <div matSuffix>{{ currency }}</div>
    <mat-error *ngIf="form.get('fixAmount').hasError"> {{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
  </mat-form-field>
</form>
