<mat-card class="mat-card m-20 taskwizard-container">
  <h2 class="task-name">{{ taskName }}</h2>

  <mat-horizontal-stepper [linear]="true" #taskStepper>
    <!--Mapping-->
    <mat-step #mappingStep>
      <ng-template matStepLabel>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.MAPPING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #addFilesWrapper>
          <app-addfiles
            #stepComponent
            [role]="'RETAILER_JUMPSELLER_VARIANTS_UPDATE'"
            [currentTaskId]="taskId"
            (sourceTypeCallBack)="setSourceType($event)"
          ></app-addfiles>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Filter-->
    <!--<mat-step #filterStep>-->
    <!--<ng-template matStepLabel>Filter</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #filterWrapper>-->
    <!--<app-filter #stepComponent *ngIf="filterWrapper.isStepActive()" [taskId]="taskId" [usedComponent]="'productUpload'" [catalogId]="rcatalogId"></app-filter>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Categories-->
    <!--<mat-step #categoryMappingStep>-->
    <!--<ng-template matStepLabel>Categories</ng-template>-->
    <!--<div *ngIf="enableComponents">-->
    <!--<app-step-wrapper #categoryMappingWrapper>-->
    <!--<app-retailer-category-mapping *ngIf="categoryMappingWrapper.isStepActive()" #stepComponent-->
    <!--[taskId]="rcatalogId"></app-retailer-category-mapping>-->
    <!--</app-step-wrapper>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Pricing-->
    <mat-step #pricingEditorStep>
      <ng-template matStepLabel>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.PRICING' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <div style="overflow: auto">
          <app-step-wrapper #pricingWrapper>
            <app-retailer-pricing #stepComponent [rcatalog_id]="rcatalogId"></app-retailer-pricing>
          </app-step-wrapper>
        </div>
      </div>
    </mat-step>

    <!--Settings-->
    <mat-step #scheduleStep>
      <ng-template matStepLabel>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.SETTINGS' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #scheduleWrapper>
          <app-retailer-jumpseller-vu-settings
            #stepComponent
            [taskId]="taskId"
            [rcatalogId]="rcatalogId"
            (voChanged)="handleVoChanged($event)"
            [retailerSettingsVO]="retailerVO"
          >
          </app-retailer-jumpseller-vu-settings>
        </app-step-wrapper>
      </div>
    </mat-step>

    <!--Summary-->
    <mat-step #scheduleStep>
      <ng-template matStepLabel>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.SUMMARY' | translate }}</ng-template>
      <div *ngIf="enableComponents">
        <app-step-wrapper #summaryWrapper nextButtonLabel="Finish" backNavigationUrl="/retailer-variants-update-tasks">
          <app-retailer-jumpseller-vu-summary
            #stepComponent
            [taskId]="taskId"
            [rcatalogId]="rcatalogId"
            [retailerSettingsVO]="retailerVO"
          ></app-retailer-jumpseller-vu-summary>
        </app-step-wrapper>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-card>
