import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationData } from './notification-config';
import { NotificationRef } from './notification-ref';
import { notificationAnimations, NotificationAnimationState } from './notification-animation';
import Timer = NodeJS.Timer;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [notificationAnimations.fadeNotification],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public animationState: NotificationAnimationState = 'default';
  public iconType: string;
  private intervalId: Timer;

  constructor(readonly data: NotificationData, readonly ref: NotificationRef) {
    this.iconType = data.type === 'success' ? 'done' : data.type;
  }

  ngOnInit(): void {
    this.intervalId = setTimeout(() => this.setClosingAnimState(), 5000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.intervalId);
  }

  public setClosingAnimState(): void {
    this.animationState = 'closing';
  }

  private close(): void {
    this.ref.close();
  }

  public onFadeFinished(event: any): void {
    const { toState } = event;
    const isFadeOut = (toState as NotificationAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';
    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
