import { Injectable } from '@angular/core';
import { PermissionService } from '../../../service/permission/permission.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SCOPES } from '../../../service/permission/scopes';
import { AiChatLayout } from '../model/layout/ai-chat-layout';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';

@Injectable({
  providedIn: 'root',
})
export class AiChatService {
  private _isOpen = new BehaviorSubject<boolean>(false);
  private _layout = new BehaviorSubject<AiChatLayout>(AiChatLayout.SIDEBAR);
  isOpen$: Observable<boolean> = this._isOpen.asObservable();
  layout$: Observable<AiChatLayout> = this._layout.asObservable();

  constructor(private permissionService: PermissionService, private screenManagerService: ScreenManagerService) {
    this.subscribeToScreenChange();
  }

  showChat(): void {
    this._isOpen.next(true);
  }

  closeChat(): void {
    this._isOpen.next(false);
  }

  toggleChat(): void {
    this._isOpen.next(!this.isOpen);
  }

  changeLayout(layout: AiChatLayout): void {
    this._layout.next(layout);
  }

  hasChatPermission(): Observable<boolean> {
    return this.permissionService.hasPermissions(SCOPES.AI_CHAT);
  }
  get isOpen(): boolean {
    return this._isOpen.value;
  }

  get layout(): AiChatLayout {
    return this._layout.value;
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .subscribe((state) => this.changeLayout(state.matches ? AiChatLayout.MOBILE : AiChatLayout.SIDEBAR));
  }
}
