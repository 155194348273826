import { Injectable, OnDestroy } from '@angular/core';
import { GettingStartedConfig } from '../model/getting-started-config';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { GettingStartedStepsService } from './getting-started-steps.service';
import {
  gettingStartedAllStepsSelector,
  gettingStartedCompletedStepsSelector,
  gettingStartedCurrentStepSelector,
  gettingStartedUncompletedStepsSelector,
} from '../../../store/getting-started/getting-started.selector';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GettingStartedStepDto } from '../model/getting-started-step-dto';
import { RETAILER_GETTING_STARTED_CONFIG, SUPPLIER_GETTING_STARTED_CONFIG } from '../getting-started.config';
import { hasRolesSelector } from '../../../store/user/user.selector';
import { GettingStartedFinishStartAction } from '../../../store/getting-started/getting-started.actions';
import { ROLE_TYPE, RolesEnum } from '../../../vo/roles/roles';
import { GettingStartedStepType } from '../model/getting-started-step-type';
import { GtmManagerService } from '../../../service/google-tag-manager/gtm-manager.service';
import { TutorialBeginGtmAction } from '../../../service/google-tag-manager/actions/tutorial_begin';
import { TutorialCompleteGtmAction } from '../../../service/google-tag-manager/actions/tutorial_complete';
import { GsStepSupplierAction } from '../../../service/google-tag-manager/actions/gs_step_supplier';
import { GsStepRetailerAction } from '../../../service/google-tag-manager/actions/gs_step_retailer';
import { hasEcomSelector } from '../../../store/ecom/ecom.selector';
import { ActivatedRoute } from '@angular/router';
import { SnippetEnum } from '../../setup-guide/enums/snippet-enums';
import { SetupGuideService } from '../../setup-guide/service/setup-guide.service';
import { GsStepToEmbeddedSnippet } from '../constants/gs-step-to-embedded-snippet';

@Injectable()
export class GettingStartedStepperService implements OnDestroy {
  allSteps: GettingStartedConfig[];
  completedSteps: GettingStartedConfig[];
  currentStepChange: Subject<GettingStartedConfig>;
  currentSubStepChange: Subject<number>;
  private _currentSubStep: number;
  private _currentStep: GettingStartedConfig;
  private unsubscribeAll: Subject<void>;
  private hasSupplierRole$: Observable<boolean>;
  constructor(
    private store: Store<AppState>,
    private gettingStartedStepsService: GettingStartedStepsService,
    private gtmManagerService: GtmManagerService,
    private route: ActivatedRoute,
    private setupGuideService: SetupGuideService
  ) {
    this.unsubscribeAll = new Subject<void>();
    this.currentStepChange = new Subject<GettingStartedConfig>();
    this.currentSubStepChange = new Subject<number>();
    this.hasSupplierRole$ = this.store.select(hasRolesSelector, RolesEnum.SUPPLIER);
    this.getAllSteps();
    this.getCompletedSteps();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private getCompletedSteps(): void {
    this.hasSupplierRole$
      .pipe(switchMap((isSupplier) => this.getCompletedStepsConfigByRole(isSupplier)))
      .subscribe((steps) => (this.completedSteps = steps));
  }

  private getAllSteps(): void {
    this.hasSupplierRole$
      .pipe(switchMap((isSupplier) => this.getAllStepsConfigByRole(isSupplier)))
      .subscribe((steps) => (this.allSteps = steps));
  }

  private getCompletedStepsConfigByRole(hasSupplierRole: boolean): Observable<GettingStartedConfig[]> {
    if (hasSupplierRole) {
      return this.getSupplierStepsConfigs(this.store.select(gettingStartedCompletedStepsSelector, RolesEnum.SUPPLIER));
    } else {
      return this.getRetailerStepsConfigs(this.store.select(gettingStartedCompletedStepsSelector));
    }
  }

  private getAllStepsConfigByRole(hasSupplierRole: boolean): Observable<GettingStartedConfig[]> {
    if (hasSupplierRole) {
      return this.getSupplierStepsConfigs(this.store.select(gettingStartedAllStepsSelector, RolesEnum.SUPPLIER));
    } else {
      return this.getRetailerStepsConfigs(this.store.select(gettingStartedAllStepsSelector));
    }
  }

  private getRetailerStepsConfigs(
    gettingStartedSteps: Observable<GettingStartedStepDto[]>
  ): Observable<GettingStartedConfig[]> {
    return gettingStartedSteps.pipe(
      filter((steps) => !!steps),
      map((steps) => this.mapRetailerStepsToConfig(steps)),
      takeUntil(this.unsubscribeAll)
    );
  }

  private getSupplierStepsConfigs(
    gettingStartedSteps: Observable<GettingStartedStepDto[]>
  ): Observable<GettingStartedConfig[]> {
    return gettingStartedSteps.pipe(
      filter((steps) => !!steps),
      map((steps) => this.mapSupplierStepsToConfig(steps)),
      takeUntil(this.unsubscribeAll)
    );
  }

  private mapRetailerStepsToConfig(steps: GettingStartedStepDto[]): GettingStartedConfig[] {
    return steps.map((step) => RETAILER_GETTING_STARTED_CONFIG.find((config) => config.step === step.step));
  }

  private mapSupplierStepsToConfig(steps: GettingStartedStepDto[]): GettingStartedConfig[] {
    return steps.map((step) => SUPPLIER_GETTING_STARTED_CONFIG.find((config) => config.step === step.step));
  }

  private findRetailerStepWithParam(routerStep: string): GettingStartedConfig {
    return RETAILER_GETTING_STARTED_CONFIG.find((config) => config.url === routerStep);
  }

  private findSupplierStepWithParam(routerStep: string): GettingStartedConfig {
    return SUPPLIER_GETTING_STARTED_CONFIG.find((config) => config.url === routerStep);
  }

  currentStepIsCompleted(): boolean {
    return this.completedSteps.some((step) => step.step === this._currentStep.step);
  }

  private getNextStep(): GettingStartedConfig {
    const currentStepIndex = this.currentStepIndex;
    if (currentStepIndex === this.allSteps.length - 1) {
      return null;
    } else {
      return this.allSteps[currentStepIndex + 1];
    }
  }

  private getPreviousStep(): GettingStartedConfig {
    const currentStepIndex = this.currentStepIndex;
    if (currentStepIndex === 0) {
      return null;
    } else {
      return this.allSteps[currentStepIndex - 1];
    }
  }

  private saveCurrentStep(skip: boolean): Observable<void> {
    return combineLatest([
      this.store.select(gettingStartedCurrentStepSelector, this.currentStep.step),
      this.hasSupplierRole$,
    ]).pipe(
      take(1),
      tap(([currentStep]): void => {
        this.saveEmbeddedSnippetStep(currentStep);
      }),
      tap(([currentStep, isSupplier]): void => {
        this.sendStepToGTM(
          currentStep.step,
          skip,
          currentStep.index,
          isSupplier ? RolesEnum.SUPPLIER : RolesEnum.RETAILER
        );
      }),
      switchMap(([currentStep]): Observable<void> => {
        return this.gettingStartedStepsService.addStepsToUser([
          {
            stepId: currentStep.id,
            skip,
          },
        ]);
      })
    );
  }

  private saveEmbeddedSnippetStep(currentStep: GettingStartedStepDto): void {
    if (GsStepToEmbeddedSnippet[currentStep.id]) {
      this.setupGuideService.setCompletedStep(GsStepToEmbeddedSnippet[currentStep.id]);
    }
  }

  private sendStepToGTM(stepKey: GettingStartedStepType, skipped: boolean, index: number, role: ROLE_TYPE): void {
    if (role === RolesEnum.SUPPLIER) {
      this.store
        .select(hasEcomSelector, RolesEnum.SUPPLIER)
        .pipe(take(1))
        .subscribe((hasEcom) => {
          this.gtmManagerService.pushTag(new GsStepSupplierAction({ step_id: stepKey }));
        });
    } else {
      // TODO: implement planId
      this.gtmManagerService.pushTag(
        new GsStepRetailerAction({ step_id: stepKey, skipped: skipped ? 'skipped' : 'not_skipped', plan_id: null })
      );
    }
  }
  private handleGoToNextStep(): void {
    const nextStep = this.getNextStep();
    if (!!nextStep) {
      this.currentStep = nextStep;
    } else {
      this.store.dispatch(new GettingStartedFinishStartAction(this.currentStep.step));
      this.gtmManagerService.pushTag(new TutorialCompleteGtmAction());
    }
  }

  private setCurrentStepToFirstUncompletedStep(role: ROLE_TYPE, steps: GettingStartedStepDto[]): void {
    if (role === RolesEnum.RETAILER) {
      this.currentStep = this.mapRetailerStepsToConfig([steps[0]])[0];
    } else {
      this.currentStep = this.mapSupplierStepsToConfig([steps[0]])[0];
    }
  }

  private sendTutorialBeginToGtm(uncompletedSteps: GettingStartedStepDto[], allSteps: GettingStartedStepDto[]): void {
    if (Math.min(...uncompletedSteps.map((step) => step.index)) === allSteps[0].index) {
      this.gtmManagerService.pushTag(new TutorialBeginGtmAction());
    }
  }

  goToFirstNotCompletedStep(): void {
    this.hasSupplierRole$
      .pipe(
        take(1),
        map((hasSupplierRole) => (hasSupplierRole ? RolesEnum.SUPPLIER : RolesEnum.RETAILER)),
        switchMap((role) =>
          combineLatest([
            this.store.select(gettingStartedUncompletedStepsSelector, role).pipe(
              filter(Boolean),
              map((steps) => steps as GettingStartedStepDto[])
            ),
            this.store.select(gettingStartedAllStepsSelector),
            this.route.paramMap,
            this.route.queryParamMap,
          ]).pipe(
            take(1),
            tap(([steps, allSteps, params, qParams]) => {
              const stepFromParams = params.get('step');
              const subStepFromParams = qParams.get('step');
              this.currentSubStep = subStepFromParams ? Number(subStepFromParams) : null;

              if (stepFromParams) {
                this.checkCurrentStepFromRoute(role, stepFromParams, steps);
              } else {
                this.setCurrentStepToFirstUncompletedStep(role, steps);
              }

              this.sendTutorialBeginToGtm(steps, allSteps);
            })
          )
        )
      )
      .subscribe();
  }

  checkCurrentStepFromRoute(role: ROLE_TYPE, currentStep: string, steps: GettingStartedStepDto[]): void {
    let step: GettingStartedConfig;

    if (role === RolesEnum.RETAILER) {
      step = this.findRetailerStepWithParam(currentStep);
    } else {
      step = this.findSupplierStepWithParam(currentStep);
    }

    if (step && steps.findIndex((gss) => gss.step === step.step) == -1) {
      this.currentStep = step;
    } else {
      this.setCurrentStepToFirstUncompletedStep(role, steps);
    }
  }

  goToNextStep(skip: boolean): void {
    if (!this.currentStepIsCompleted()) {
      this.saveCurrentStep(skip).subscribe(() => this.handleGoToNextStep());
    } else {
      this.handleGoToNextStep();
    }
  }

  goToPreviousStep(): void {
    const previousStep = this.getPreviousStep();
    if (!!previousStep) {
      this.currentStep = previousStep;
    }
  }

  hasNextStep(): boolean {
    return !!this.getNextStep();
  }

  hasPreviousStep(): boolean {
    return !!this.getPreviousStep();
  }

  get currentStepIndex(): number {
    return (
      this.allSteps && this._currentStep && this.allSteps.findIndex((step) => step.step === this._currentStep.step)
    );
  }

  get currentStep(): GettingStartedConfig {
    return this._currentStep;
  }

  set currentStep(value: GettingStartedConfig) {
    this._currentStep = value;
    this.currentStepChange.next(value);
  }

  get currentSubStep(): number {
    return this._currentSubStep;
  }

  set currentSubStep(value: number) {
    this._currentSubStep = value;
    this.currentSubStepChange.next(value);
  }
}
