import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { includes } from 'lodash';
import { SCOPES } from '../../../service/permission/scopes';
import { PermissionService } from '../../../service/permission/permission.service';

@UntilDestroy()
@Component({
  selector: 'app-admin-comment',
  standalone: true,
  imports: [CommonModule, MatIconModule, FormsModule, MatButtonModule],
  templateUrl: './admin-comment.component.html',
  styleUrls: ['./admin-comment.component.scss'],
})
export class AdminCommentComponent implements OnInit {
  public isHidden = true;
  public myComment;
  public isAdmin = false;

  @Input() taskId: number;
  @Input() step: string;
  @Input() comments: any;
  @Input() taskType: string;
  @Input() mappingField: string;

  constructor(private taskWizardService: TaskwizardService, private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.setIsAdmin();
    this.initComment();
  }

  changeVisible(): void {
    this.isHidden = !this.isHidden;
  }

  public saveComment(event, value): void {
    if (event.key === 'Enter') {
      this.taskWizardService
        .saveComment(new CommentVO(this.taskId, this.taskType, this.step, this.mappingField, value))
        .subscribe();
    }
  }

  private initComment(): void {
    if (!!this.comments) {
      const matchingComment = this.comments.find((item) => {
        return (
          item.step === this.step &&
          this.taskType === item.taskType &&
          this.mappingField &&
          item.mappingField &&
          this.mappingField === item.mappingField &&
          +this.taskId === +item.taskId
        );
      });

      if (!!matchingComment) {
        this.myComment = matchingComment.comment;
      }
    }
  }

  // public isAdmin(): boolean {
  //   return JSON.parse(localStorage.getItem('currentUser')).role === 3;
  // }

  private setIsAdmin(): void {
    this.permissionService
      .getHasAdminScope()
      .pipe(untilDestroyed(this))
      .subscribe((adminScope) => {
        this.isAdmin = adminScope;
      });
  }
}

export class CommentVO {
  taskId: number;
  taskType: string;
  step: string;
  mappingField: string;
  comment: string;

  constructor(taskId, taskType, step, mappingField, comment) {
    this.taskId = taskId;
    this.taskType = taskType;
    this.step = step;
    this.mappingField = mappingField;
    this.comment = comment;
  }
}
