import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { PlanBox, PlanUpsellBoxComponent } from '../plan-upsell-box/plan-upsell-box.component';
import { Router } from '@angular/router';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { PLANS_TO_DATAFEED_SHOW, PLANS_TO_MARKETPLACE_SHOW } from '../../../utils/Constants';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { UpsellBoxButtonTextPipe } from '../../pipes/upsell-box-button-text.pipe';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-subscription-upsell',
  templateUrl: './subscription-upsell.component.html',
  styleUrls: ['./subscription-upsell.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatSlideToggleModule,
    MatChipsModule,
    FormsModule,
    MatButtonModule,
    PlanUpsellBoxComponent,
    UpsellBoxButtonTextPipe,
  ],
})
export class SubscriptionUpsellComponent implements OnInit {
  @Output() laterEvent = new EventEmitter<void>();
  @Output() redirectedEvent = new EventEmitter<void>();
  @Input() variant: 'datafeed' | 'marketplace' = 'marketplace';
  @Input() descriptionTranslationKey = 'PLAN_UPSELL.DESCRIPTION';
  @Input() title: string;
  @Input() buttonClass = 'close';
  @Input() selectedEcom: EcomVO = null;
  @Input() hasActionBar = true;

  public plansToMarketplaceShow: PlanBox[] = PLANS_TO_MARKETPLACE_SHOW;
  public plansToDataFeedShow: PlanBox[] = PLANS_TO_DATAFEED_SHOW;
  public isAnnual = false;

  protected trialValid = false;

  constructor(private router: Router, private ngZone: NgZone, private marketplaceEcomService: MarketplaceEcomService) {}

  ngOnInit(): void {
    this.initEcomSubscription();
    this.trialValid = this.isTrialValid();
  }

  buttonClicked(data: { link: string; id: number }): void {
    const queryParams = {
      default: `${this.variant === 'datafeed' ? 'df' : 'rmp'}-${data.id}`,
      subscribe: 'auto',
    };

    if (this.isAnnual) {
      queryParams['cycle'] = 'annual';
    }

    this.ngZone.run(() => {
      this.router.navigate([data.link], { queryParams });
      this.redirectedEvent.emit();
    });
  }

  laterClicked(): void {
    this.laterEvent.emit();
  }

  handleAnnualBadgeClicked(): void {
    this.isAnnual = true;
  }

  private isTrialValid(): boolean {
    return this.marketplaceEcomService.isFreeTrialAvailable(this.selectedEcom);
  }

  private initEcomSubscription(): void {
    this.marketplaceEcomService
      .getEcomWithSubscriptionFromStore()
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((ecom: EcomVO): void => {
        this.selectedEcom = ecom;
      });
  }
}
