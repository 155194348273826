<div class="pt-20" *ngIf="!isLoading; else skeleton">
  <app-brands-slider
    [titleKey]="title"
    [widgetContent]="widgets"
    [wrapperClass]="wrapperClass"
    [innerWrapperClass]="innerClass"
    (brandClicked)="handleBrandClicked($event)"
  ></app-brands-slider>
</div>

<ng-template #skeleton>
  <div [ngClass]="wrapperClass">
    <div [ngClass]="wrapperClass">
      <div class="skeleton">
        <ng-container *ngFor="let _ of [].constructor(10)">
          <app-skeleton-image height="56px" width="170px"></app-skeleton-image>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
