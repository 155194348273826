<div class="filter-selector-container">
  <div *ngIf="title" class="font-weight-700 filter-title">
    <div>{{ 'FILTER_COMMON.TITLES.' + title | translate }}</div>
    <div *ngIf="hasClearAll && value" class="clear-all" (click)="handleClearAllClicked()">
      {{ 'FILTER_COMMON.SELECTOR.CONTROLS.CLEAR_ALL' | translate }}
    </div>
  </div>
  <ng-container *ngIf="hasSearch">
    <div fxFlex="0 0 auto" class="search">
      <ng-container [ngTemplateOutlet]="searchInput"></ng-container>
    </div>
    <div *ngIf="hasSeeMore && hasTopOptions" class="font-weight-700 font-size-12">
      {{ (allOptionsVisible ? 'FILTER_COMMON.SELECTOR.ALL' : 'FILTER_COMMON.SELECTOR.TOP') | translate }}
    </div>
  </ng-container>
  <!--  <div *ngIf="noOptionsAvailable === true">-->
  <!--    {{ 'FILTER_COMMON.SELECTOR.NO_OPTIONS' | translate }}-->
  <!--  </div>-->
  <div #optionsContainer [ngSwitch]="multiple" class="options-container" (scroll)="throttledOnScrollLoadData()">
    <mat-radio-group
      *ngSwitchCase="false"
      class="option-groups"
      [value]="value"
      (change)="handleSingleOptionSelection($event.value)"
    >
      <div *ngFor="let optionGroup of options" class="option-group">
        <div *ngIf="!!allOptionsVisible && !!optionGroup.optionGroupTitle" class="title">
          {{ 'FILTER_COMMON.SELECTOR.GROUP_TITLES.' + optionGroup.optionGroupTitle | translate }}
        </div>
        <div *ngIf="!optionGroup.options.length">
          {{ 'FILTER_COMMON.SELECTOR.NO_OPTIONS' | translate }}
        </div>
        <div class="options">
          <mat-radio-button *ngFor="let option of optionGroup.options" [value]="option.key" [disableRipple]="true">
            <ng-container *ngIf="needsTranslation; else notTranslatedValue">{{
              $any(option.value) | translate | truncate : 25
            }}</ng-container>
            <ng-template #notTranslatedValue>{{ $any(option.value) | truncate : 25 }}</ng-template>
          </mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
    <div *ngSwitchCase="true" class="option-groups">
      <div *ngFor="let optionGroup of options" class="option-group">
        <div *ngIf="!!allOptionsVisible && !!optionGroup.optionGroupTitle" class="title">
          {{ 'FILTER_COMMON.SELECTOR.GROUP_TITLES.' + optionGroup.optionGroupTitle | translate }}
        </div>
        <div *ngIf="!optionGroup.options.length">
          {{ 'FILTER_COMMON.SELECTOR.NO_OPTIONS' | translate }}
        </div>
        <div class="options">
          <mat-checkbox
            *ngFor="let option of optionGroup.options"
            [disableRipple]="true"
            (change)="handleMultipleOptionSelection(option.key, $event.checked)"
            [checked]="selectedOptions.includes(option.key)"
          >
            <ng-container *ngIf="needsTranslation; else notTranslatedValue">
              {{ $any(option.value) | translate | truncate : 25 }}
            </ng-container>
            <ng-template #notTranslatedValue>{{ $any(option.value) | truncate : 25 }}</ng-template>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="hasSeeMore" [ngSwitch]="allOptionsVisible">
    <div *ngSwitchCase="false" class="see-more" (click)="onSeeMore()">
      {{ 'FILTER_COMMON.SELECTOR.CONTROLS.SEE_MORE' | translate }}
    </div>
    <div *ngSwitchCase="true" class="see-more" (click)="onSeeLess()">
      {{ 'FILTER_COMMON.SELECTOR.CONTROLS.SEE_LESS' | translate }}
    </div>
  </ng-container>
</div>

<ng-template #searchInput>
  <app-searchbar-custom
    class="w-100p"
    [placeholder]="'FILTER_COMMON.SELECTOR.SEARCH_LABELS.' + searchLabel | translate"
    [searchTerm]="searchTerm"
    (searchTermChange)="handleSearchChange($event)"
    [hasClearButton]="true"
  >
  </app-searchbar-custom>
</ng-template>
