<mat-card class="numeric-card h-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px">
  <div class="numeric-card-title w-100p" fxLayout="row" fxLayoutAlign="space-between center">
    <h4 class="m-0 no-wrap">
      {{ title }}
    </h4>
    <mat-icon *ngIf="tooltip" class="icon-filled s-20" [matTooltip]="tooltip">info</mat-icon>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
    <h2>
      {{ value | number : '.0-2' }} <span *ngIf="unitOfMeasurement">{{ unitOfMeasurement }}</span>
    </h2>
    <app-inc-dec-line *ngIf="!!percentage" [percentage]="percentage"></app-inc-dec-line>
  </div>
</mat-card>
