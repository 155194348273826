import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentication-layout',
  templateUrl: './authentication-layout.component.html',
  styleUrls: ['./authentication-layout.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AuthenticationLayoutComponent implements OnInit {
  @Input() reverse = false;

  constructor() {}

  ngOnInit(): void {}
}
