import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SCOPES } from '../../../service/permission/scopes';
import { PermissionService } from '../../../service/permission/permission.service';
import { includes } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule, TranslateModule, MatIconModule, MatInputModule, FormsModule],
})
export class TaskCommentComponent implements OnInit {
  public isHidden = true;
  public myComment;
  public isAdmin = false;

  @Input() taskId: number;
  @Input() step: string;
  @Input() comments: any;
  @Input() taskType: string;
  @Input() mappingField: string;

  constructor(private taskWizardService: TaskwizardService, private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.setIsAdmin();
    this.initComment();
  }

  changeVisible(): void {
    this.isHidden = !this.isHidden;
  }

  public saveComment(event, value): void {
    if (event.key === 'Enter') {
      this.taskWizardService
        .saveComment(new CommentVO(this.taskId, this.taskType, this.step, this.mappingField, value))
        .subscribe();
    }
  }

  private initComment(): void {
    if (!Utils.isNullOrUndefined(this.comments)) {
      this.myComment = this.comments.find((item) => {
        return item.step === this.step && item.mappingField === this.mappingField && item.taskType === this.taskType;
      });
    }
    if (this.myComment) {
      this.myComment = this.myComment.comment;
      this.isHidden = false;
    }
  }

  private setIsAdmin(): void {
    this.permissionService
      .getPermissionsObs(true)
      .pipe(
        untilDestroyed(this),
        map((permissions) => includes(permissions, SCOPES.ADMIN))
      )
      .subscribe((adminScope) => {
        this.isAdmin = adminScope;
      });
  }

  // public isAdmin(): boolean {
  //   return JSON.parse(localStorage.getItem('currentUser')).role === 3;
  // }
}

export class CommentVO {
  taskId: number;
  taskType: string;
  step: string;
  mappingField: string;
  comment: string;

  constructor(taskId, taskType, step, mappingField, comment) {
    this.taskId = taskId;
    this.taskType = taskType;
    this.step = step;
    this.mappingField = mappingField;
    this.comment = comment;
  }
}
