import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SubscriptionService } from 'app/main/billing-new/service/subscription.service';
import { SubscriptionVO } from 'app/vo/subscription-vo';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  SubsciptionFetchSuccessAction,
  SubscriptionActionTypes,
  SubscriptionFetchStartAction,
  SubscriptionFetchStartPayload,
} from './subscription.actions';

@Injectable()
export class SubscriptionEffects {
  constructor(private actions$: Actions, private subscriptionService: SubscriptionService) {}

  SubscriptionFetchEffect: Observable<SubsciptionFetchSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActionTypes.SUBSCRIPTION_FETCH_START),
      map((action: SubscriptionFetchStartAction) => action.payload),
      switchMap((payload: SubscriptionFetchStartPayload) => {
        return this.subscriptionService.getSubscriptions(payload.ecomId, payload.role);
      }),
      map((subscriptions: SubscriptionVO) => {
        return new SubsciptionFetchSuccessAction({ subscriptions });
      })
    )
  );
}
