import { FileUploadResponseVo } from './file-upload-response-vo';

export class FtpUploadResponseVO extends FileUploadResponseVo {
  private _deleteTab: boolean;

  get deleteTab(): boolean {
    return this._deleteTab;
  }

  set deleteTab(value: boolean) {
    this._deleteTab = value;
  }
}
