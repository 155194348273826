import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';

@Injectable()
export class ChangeRoleScreenService implements OnDestroy {
  private _unsubscribe = new Subject<void>();

  public small: boolean;

  constructor(private screenManagerService: ScreenManagerService) {
    this.initSubscription();
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private initSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(({ matches }): void => {
        this.small = matches;
      });
  }
}
