import { ArticleCardsRowItem } from '../../shared/components/article-cards-row/article-cards-row.component';
import { environment } from '../../../environments/environment';

export const SELL_ON_SYNCEE_ARTICLE_CARDS: ArticleCardsRowItem[] = [
  {
    id: 1,
    subtitleKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.1.SUBTITLE',
    titleKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.1.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.1.DESCRIPTION',
    image: {
      src: `${environment.assetsUrl}/images/static-pages/sell-on-syncee/article-section-01.webp`,
      alt: 'Full control over your business',
      width: 480,
      height: 328,
    },
    button: {
      type: 'callback', // add callback in component
      textKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.1.BUTTON',
      buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
      style: {
        disableRipple: true,
      },
    },
  },
  {
    id: 2,
    subtitleKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.2.SUBTITLE',
    titleKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.2.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.2.DESCRIPTION',
    image: {
      src: `${environment.assetsUrl}/images/static-pages/sell-on-syncee/article-section-02.webp`,
      alt: 'Full control over your business',
      width: 480,
      height: 328,
    },
    button: {
      type: 'callback', // add callback in component
      textKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.2.BUTTON',
      buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
      style: {
        disableRipple: true,
      },
    },
  },
  {
    id: 3,
    subtitleKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.3.SUBTITLE',
    titleKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.3.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.3.DESCRIPTION',
    image: {
      src: `${environment.assetsUrl}/images/static-pages/sell-on-syncee/article-section-03.webp`,
      alt: 'Full control over your business',
      width: 480,
      height: 328,
    },
    button: {
      type: 'callback', // add callback in component
      textKey: 'SELL_ON_SYNCEE.ARTICLE_CARDS.CARDS.3.BUTTON',
      buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
      style: {
        disableRipple: true,
      },
    },
  },
];

export const HOME_PAGE_GUEST_ARTICLE_CARDS: ArticleCardsRowItem[] = [
  {
    id: 1,
    subtitleKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.1.SUBTITLE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.1.TITLE',
    descriptionKey: null,
    image: {
      src: `${environment.assetsUrl}/images/static-pages/homepage-guest/success-story-01.webp`,
      alt: 'Full control over your business',
      width: 480,
      height: 328,
    },
    button: {
      url: 'https://syncee.com/blog/success-stories/troop-london-achieved-record-breaking-sales-with-syncee/',
      type: 'callback', // add callback in component
      textKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.1.BUTTON',
      buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
      style: {
        disableRipple: true,
      },
    },
  },
  {
    id: 2,
    subtitleKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.2.SUBTITLE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.2.TITLE',
    descriptionKey: null,
    image: {
      src: `${environment.assetsUrl}/images/static-pages/homepage-guest/success-story-02.webp`,
      alt: 'Full control over your business',
      width: 480,
      height: 328,
    },
    button: {
      url: 'https://syncee.com/blog/success-stories/the-art-of-growth-kinks-quirks-journey-to-reshape-online-selling/',
      type: 'callback', // add callback in component
      textKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.2.BUTTON',
      buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
      style: {
        disableRipple: true,
      },
    },
  },
  {
    id: 3,
    subtitleKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.3.SUBTITLE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.3.TITLE',
    descriptionKey: null,
    image: {
      src: `${environment.assetsUrl}/images/static-pages/homepage-guest/success-story-03.webp`,
      alt: 'Full control over your business',
      width: 480,
      height: 328,
    },
    button: {
      url: 'https://syncee.com/blog/success-stories/how-syncee-fueled-itradecenters-explosive-growth/',
      type: 'callback', // add callback in component
      textKey: 'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.CARDS.3.BUTTON',
      buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
      style: {
        disableRipple: true,
      },
    },
  },
];
