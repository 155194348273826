import { Component, Input, OnInit } from '@angular/core';
import { ChatMinimizationService, MinimizedChatData } from '../../service/chat-minimization/chat-minimization.service';
import { SupplierIconComponent } from '../../shared/components/supplier-icon/supplier-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { ChatService } from '../../service/chat/chat.service';
import { AvatarComponent } from 'app/shared/components/avatar/avatar.component';

@Component({
  selector: 'app-minimized-chat-taskbar',
  templateUrl: './minimized-chat-taskbar.component.html',
  styleUrls: ['./minimized-chat-taskbar.component.scss'],
  standalone: true,
  imports: [CommonModule, SupplierIconComponent, MatIconModule, FlexModule, AvatarComponent],
})
export class MinimizedChatTaskbarComponent implements OnInit {
  @Input() chats: MinimizedChatData[];
  useFallbackAvatar: boolean;

  constructor(private chatService: ChatService, private chatMinimizationService: ChatMinimizationService) {}

  ngOnInit(): void {}

  handleExpandClick(chat: MinimizedChatData): void {
    this.chatService.openChatDialog(chat.recipientUser, chat.currentUser);
    this.chatMinimizationService.removeMinimizedChat(chat.recipientUser.id);
  }

  handleCloseClick(recipientId: number): void {
    this.chatMinimizationService.removeMinimizedChat(recipientId);
  }
}
