import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SetupGuideGatewayService } from '../service/setup-guide-gateway.service';
import { SetupGuideSnippet } from '../model/setup-guide-snippet';
import { OnboardItemStep } from '../../../layout/components/onboard/onboard.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddCompletedSnippetsToUserAction } from '../../../store/snippet/snippet.actions';
import { snippetIsAllCompletedSelector } from '../../../store/snippet/snippet.selector';
import { EXCLUDE_DISMISS } from '../config/exclude-dismiss';
import { RolesEnum } from '../../../vo/roles/roles';

@Component({
  selector: 'app-setup-guide-bottom-bar',
  templateUrl: './setup-guide-bottom-bar.component.html',
  styleUrls: ['./setup-guide-bottom-bar.component.scss'],
})
export class SetupGuideBottomBarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() snippet: SetupGuideSnippet;
  @Output() dismissed = new EventEmitter<void>();
  userId: number;
  allStepCompleted: Observable<boolean>;
  hasDismiss: boolean;
  private unsubscribeAll: Subject<void>;

  constructor(private setupGuideGatewayService: SetupGuideGatewayService, private store: Store<AppState>) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.subscribeToUserId();
    this.allStepCompleted = this.store.select(
      snippetIsAllCompletedSelector,
      this.snippet.snippet.type.includes('supplier') ? RolesEnum.SUPPLIER : RolesEnum.RETAILER
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasDismiss = !this.checkIfDismissIsExcluded();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onDismiss(): void {
    const stepsToSkip = this.getNotCompletedSteps();
    this.store.dispatch(
      new AddCompletedSnippetsToUserAction(stepsToSkip.map((step) => ({ snippetsId: step.id, skipped: true })))
    );
    this.dismissed.emit();
  }

  private checkIfDismissIsExcluded(): boolean {
    return EXCLUDE_DISMISS.includes(this.snippet.snippet.type);
  }

  private getNotCompletedSteps(): OnboardItemStep[] {
    const completedStepIds = this.snippet.completedSteps.map((completedStep) => completedStep.snippetsId);
    return this.snippet.snippet.steps.filter((step) => !completedStepIds.includes(step.id));
  }

  private subscribeToUserId(): void {
    this.store
      .select(getCurrentUserIdSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((id) => {
        this.userId = id;
      });
  }
}
