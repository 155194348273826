<!--<app-wizard-category-mapping-->
<!--  [originalCategoriesTitle]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.ORIGINAL_CATEGORIES_TITLE' | translate"-->
<!--  [originalCategoriesTooltip]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.ORIGINAL_CATEGORIES_TOOLTIP' | translate"-->
<!--  [customCategoriesTitle]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.STORE_COLLECTIONS_TITLE' | translate"-->
<!--  [customCategoriesTooltip]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.STORE_COLLECTIONS_TOOLTIP' | translate"-->
<!--&gt;-->
<!--</app-wizard-category-mapping>-->
<div fxLayout="column" fxLayoutGap="20px" class="py-20">
  <div class="bulk-edit__container">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div>
        <button mat-button class="syncee-black-flat-button mr-8" (click)="turnOnOffBulk()">
          {{
            isBulkEdit ? ('CATEGORY_MAPPING.BULK_EDIT_OFF' | translate) : ('CATEGORY_MAPPING.BULK_EDIT_ON' | translate)
          }}
        </button>
      </div>
      <h3 *ngIf="isBulkEdit" class="pl-8">
        {{ 'CATEGORY_MAPPING.SELECTED_NUMBER' | translate }}: {{ categorySelection.selected.length }}
      </h3>
    </div>
    <div class="pt-12">
      <button mat-button class="syncee-black-flat-button mr-8" *ngIf="isBulkEdit" (click)="selectAllSelection()">
        {{ 'CATEGORY_MAPPING.SELECT_ALL' | translate }}
      </button>
      <button
        mat-button
        class="syncee-black-flat-button mr-8"
        *ngIf="isBulkEdit && categorySelection.selected.length > 0"
        (click)="clearSelection()"
      >
        {{ 'CATEGORY_MAPPING.CLEAR' | translate }}
      </button>
      <button
        mat-button
        class="syncee-blue-button mr-8"
        *ngIf="isBulkEdit && categorySelection.selected.length > 0"
        [matMenuTriggerFor]="actionMenu"
      >
        {{ 'CATEGORY_MAPPING.ACTIONS' | translate }}
      </button>
      <mat-menu #actionMenu="matMenu">
        <button mat-menu-item (click)="openBulkEditMode()">
          {{ 'CATEGORY_MAPPING.BULK_ADD' | translate }}
        </button>
        <button mat-menu-item (click)="openBulkRemoveMode()">
          {{ 'CATEGORY_MAPPING.BULK_REMOVE' | translate }}
        </button>
        <button mat-menu-item (click)="clearCategoryMapping()">
          {{ 'CATEGORY_MAPPING.CLEAR_MAPPING' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="category-mapping">
    <div fxLayout="row" class="category-mapping__header">
      <div fxFlex="50">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h5 class="pr-8">
            <ng-container *ngIf="!taskId">
              {{ 'RETAILER_CATEGORY_MAPPING.CATALOG_CATEGORY_TITLE' | translate }}
            </ng-container>
            <ng-container *ngIf="taskId">
              {{ 'RETAILER_CATEGORY_MAPPING.TASK_CATEGORY_TITLE' | translate }}
            </ng-container>
          </h5>
          <app-searchbar-custom
            class="searchbar"
            [searchTerm]="filterPCCtrl.getRawValue()"
            (searchTermChange)="
              filterPCCtrl.setValue($event, { emitEvent: true, emitViewToModelChange: true }); filterChanged()
            "
          ></app-searchbar-custom>
        </div>
      </div>
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <h5 class="pr-8">
            <ng-container [ngSwitch]="ecomType.toLowerCase()">
              <ng-container *ngSwitchCase="'shopify'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.COLLECTION_TITLE' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shoprenter'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.COLLECTION_TITLE' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'squarespace'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.COLLECTION_TITLE' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'RETAILER_CATEGORY_MAPPING.DEFAULT.COLLECTION_TITLE' | translate }}
              </ng-container>
            </ng-container>
          </h5>
          <app-searchbar-custom
            class="searchbar"
            [searchTerm]="filterSCCtrl.getRawValue()"
            (searchTermChange)="
              filterSCCtrl.setValue($event, { emitEvent: true, emitViewToModelChange: true }); filterChanged()
            "
          ></app-searchbar-custom>
          <div id="empty-mapping-button-container">
            <button mat-button class="syncee-black-flat-button mr-8" (click)="showEmpty()" *ngIf="!showEmptyMapping">
              {{ 'CATEGORY_MAPPING.SHOW_EMPTY_MAPPING' | translate }}
            </button>
            <button mat-button class="syncee-black-flat-button mr-8" (click)="showAll()" *ngIf="showEmptyMapping">
              {{ 'CATEGORY_MAPPING.SHOW_ALL_MAPPING' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex="50">
        <div *ngIf="rcatalogId" class="py-4 pr-24">
          <ng-container *ngIf="!taskId">
            <ng-container [ngSwitch]="ecomType.toLowerCase()">
              <ng-container *ngSwitchCase="'shopify'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.CATALOG_CATEGORY_DESCR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shoprenter'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.CATALOG_CATEGORY_DESCR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'squarespace'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.CATALOG_CATEGORY_DESCR' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'RETAILER_CATEGORY_MAPPING.DEFAULT.CATALOG_CATEGORY_DESCR' | translate }}
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="taskId">
            <ng-container [ngSwitch]="ecomType.toLowerCase()">
              <ng-container *ngSwitchCase="'shopify'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.TASK_CATEGORY_DESCR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shoprenter'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.TASK_CATEGORY_DESCR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'squarespace'">
                {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.TASK_CATEGORY_DESCR' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'RETAILER_CATEGORY_MAPPING.DEFAULT.TASK_CATEGORY_DESCR' | translate }}
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div fxFlex="50">
        <div class="py-4">
          <ng-container [ngSwitch]="ecomType.toLowerCase()">
            <ng-container *ngSwitchCase="'shopify'">
              {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.COLLECTION_DESCR' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'shoprenter'">
              {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.COLLECTION_DESCR' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'squarespace'">
              {{ 'RETAILER_CATEGORY_MAPPING.' + ecomType.toUpperCase() + '.COLLECTION_DESCR' | translate }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ 'RETAILER_CATEGORY_MAPPING.DEFAULT.COLLECTION_DESCR' | translate }}
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="category-mapping__content">
      <div *ngFor="let category of filteredPCValues | async" class="category-mapping__item" fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50" class="category-mapping__category">
          <mat-checkbox
            class="pr-8"
            *ngIf="isBulkEdit"
            (change)="selectCategory($event, category.name)"
            [checked]="categorySelection.isSelected(category.name)"
            [value]="category.name"
          ></mat-checkbox>
          <div class="pr-8">
            <div class="category-mapping__category__name">{{ category.name.name }}</div>
            <a
              (click)="openProductList(category.name)"
              matTooltip="{{ 'CATEGORY_MAPPING.SHOW_PRODUCTS_TOOLTIP' | translate }}"
              class="cursor-pointer"
              >{{ 'CATEGORY_MAPPING.SHOW_PRODUCTS' | translate }}</a
            >
          </div>
        </div>
        <div fxFlex="50" fxLayoutAlign="start center">
          <div class="category-mapping__breadcrumbs">
            <div *ngFor="let breadcrumb of category.breadcrumbs">
              <span class="category-mapping__breadcrumbs__name">{{ breadcrumb }}</span>
              <span
                class="remove-breadcrumb"
                (click)="removeBreadcrumb(category.name.name, breadcrumb)"
                *ngIf="!isBulkEdit"
                >{{ 'CATEGORY_MAPPING.REMOVE' | translate }}</span
              >
            </div>
            <mat-icon
              (click)="addCategory(category)"
              matTooltip="{{ 'CATEGORY_MAPPING.EDIT_MAPPING_TOOLTIP' | translate }}"
              class="category-mapping__item__icon"
              *ngIf="!isBulkEdit"
              >add_circle_outline</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #progress>
  <div fxLayoutAlign="center center">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
