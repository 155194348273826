import { SettingsInputVo } from './settings-input-vo';

export class ProductNodeTreeVO extends SettingsInputVo {
  private _taskId: number;
  private _fileId: number;
  private _nodeTree: any[];
  private _settings: any[];
  private _selectedNode: string[];
  private _encoding: string;

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }

  get selectedNode(): string[] {
    return this._selectedNode;
  }

  set selectedNode(value: string[]) {
    this._selectedNode = value;
  }

  get settings(): any[] {
    return this._settings;
  }

  set settings(value: any[]) {
    this._settings = value;
  }

  get nodeTree(): any[] {
    return this._nodeTree;
  }

  set nodeTree(value: any[]) {
    this._nodeTree = value;
  }

  get encoding(): string {
    return this._encoding;
  }

  set encoding(value: string) {
    this._encoding = value;
  }
}
