import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PhoneCodeEntry, phoneCodes } from '../../../utils/Constants';
import { CommonModule } from '@angular/common';
import { CountryFlagPipe } from '../../pipes/country_flag.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomMatSelectSearchComponent } from '../custom-mat-select-search/custom-mat-select-search.component';
import { Utils } from '../../../utils/utils';
import { PhoneNumberModel } from './phone-number-model';
import { AppState } from '../../../app.state';
import { Store } from '@ngrx/store';
import { locationByIpSelector } from '../../../store/user/user.selector';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-country-phone-input',
  templateUrl: './country-phone-input.component.html',
  styleUrls: ['./country-phone-input.component.scss'],
  imports: [
    CommonModule,
    FlexModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    CountryFlagPipe,
    TranslateModule,
    CustomMatSelectSearchComponent,
  ],
  standalone: true,
})
export class CountryPhoneInputComponent implements OnInit {
  @Input() phoneNumber: PhoneNumberModel;
  @Input() placeholder = '';
  @Input() error?: string;
  @Input() hasError?: boolean;
  @Input() required: boolean;
  @Input() hideUnderline = false;
  @Output() phoneNumberChange = new EventEmitter<PhoneNumberModel>();

  private phoneCodes = [...phoneCodes];

  public filteredPhoneCodes: PhoneCodeEntry[] = [...phoneCodes];

  public selectedPhoneCode: PhoneCodeEntry;

  constructor(private translateService: TranslateService, private store: Store<AppState>) {}

  public ngOnInit(): void {
    this.initLanguageChangeSubscription();
  }

  public handleCountrySelect(newValue: { countryCode: string; phoneCode: string }): void {
    this.phoneNumber = {
      ...this.phoneNumber,
      countryCode: newValue.phoneCode,
    };

    this.handlePhoneNumberChange();
  }

  public handlePhoneNumberChange(): void {
    this.phoneNumberChange.emit(this.phoneNumber);
  }

  public initSearch(value: string): void {
    if (Utils.isNullOrUndefinedOrLengthZero(value)) {
      this.filteredPhoneCodes = [...this.phoneCodes];
      return;
    }

    const lowerCaseValue: string = value.toLowerCase();

    this.filteredPhoneCodes = this.phoneCodes.filter((entry: PhoneCodeEntry) => {
      return (
        entry.country.toLowerCase().includes(lowerCaseValue) || entry.phoneCode.toLowerCase().includes(lowerCaseValue)
      );
    });
  }

  private initLanguageChangeSubscription(): void {
    this.translateService
      .stream('COUNTRIES')
      .pipe(untilDestroyed(this))
      .subscribe((translatedNames): void => {
        this.phoneCodes = phoneCodes.map((entry: PhoneCodeEntry) => {
          const matchingTranslatedName = translatedNames[entry.countryCode];

          return {
            ...entry,
            country: matchingTranslatedName ?? entry.country,
          };
        });

        this.filteredPhoneCodes = [...this.phoneCodes];

        this.initPhoneCode();
      });
  }

  private setPhoneInput(): void {
    this.selectedPhoneCode = this.getPhoneCodeFromInput(this.phoneNumber.countryCode) ?? this.filteredPhoneCodes[0];

    this.phoneNumber = {
      ...this.phoneNumber,
      countryCode: this.selectedPhoneCode.phoneCode,
    };
  }

  private getPhoneCodeFromInput(code: string): PhoneCodeEntry {
    return this.phoneCodes.find((entry: PhoneCodeEntry): boolean => {
      return entry.phoneCode === code;
    });
  }

  private getPhoneCodeFromCountryCode(code: string): PhoneCodeEntry {
    return this.phoneCodes.find((entry: PhoneCodeEntry): boolean => {
      return entry.countryCode === code;
    });
  }

  private initPhoneCodeByIp(): void {
    this.store
      .select(locationByIpSelector)
      .pipe(take(1))
      .subscribe((countryCode: string): void => {
        const phoneCodeEntry: PhoneCodeEntry = this.getPhoneCodeFromCountryCode(countryCode);

        if (Utils.isNullOrUndefined(phoneCodeEntry)) {
          return;
        }

        this.phoneNumber = {
          countryCode: phoneCodeEntry.phoneCode,
          phoneNumber: '',
        };
      });
  }

  private initPhoneCode(): void {
    if (Utils.isNullOrUndefined(this.phoneNumber)) {
      this.initPhoneCodeByIp();
    }

    this.setPhoneInput();
  }
}
