<div *ngIf="showDetails">
  <div fxLayout="column" fxLayoutAlign="center center">
    <app-task-comment
      [taskId]="taskId"
      [taskType]="'import'"
      [step]="'summary'"
      [mappingField]="'summary'"
      [comments]="comments"
    >
    </app-task-comment>
    <div class="h2 m-16">{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.TASK_SUMMARY' | translate }}</div>
    <div fxLayout="column">
      <h3>
        <strong>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.TASK_NAME' | translate }}: </strong> {{ taskName }}
      </h3>
      <h3 *ngIf="sourceType"><strong>Files source type: </strong>{{ sourceType }}</h3>
      <h3>
        <strong>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.PRODUCT_NUMER_IN_FILE' | translate }}: </strong
        >{{ productNumber }}
      </h3>
      <h3>
        <strong>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.UPDATED_VARIANTS_NUMBER' | translate }}: </strong
        >{{ filteredNumber }}
      </h3>
      <h3 *ngIf="scheduleType">
        <strong>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.TASK_WILL_RUN_EVERY' | translate }} {{ scheduleType }}:</strong>
      </h3>
      <mat-form-field appearance="fill" class="mt-20">
        <mat-label>{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.EMAIL_NOTIFICATION' | translate }}</mat-label>
        <mat-select [(value)]="selectedNotification" disableOptionCentering panelClass="mat-select-display-change">
          <mat-option value="dont">{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.DONT_SEND' | translate }}</mat-option>
          <mat-option value="email">{{ 'RETAILER_JUMPSELLER_VU_TASKWIZARD.SEND_EMAIL' | translate }}</mat-option>
          <mat-option value="report">{{
            'RETAILER_JUMPSELLER_VU_TASKWIZARD.SEND_EMAIL_AND_REPORT' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-list *ngIf="scheduleTimes" class="summary-list" role="list" fxFlexAlign="center">
        <mat-list-item role="listitem" *ngFor="let scheduleTime of scheduleTimes">- {{ scheduleTime }}</mat-list-item>
      </mat-list>
    </div>
  </div>

  <!--<div class="pt-24" fxLayout="row" fxLayoutAlign="end center">-->
  <!--<button class="mr-8" mat-button matStepperPrevious type="button" color="accent">-->
  <!--Previous-->
  <!--</button>-->
  <!--<button mat-button type="button" color="accent" (click)="finishTask()">-->
  <!--Finish-->
  <!--</button>-->
  <!--</div>-->
</div>
