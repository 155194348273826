import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-info-strip',
  standalone: true,
  imports: [CommonModule, AlertMultiComponent, TranslateModule],
  templateUrl: './info-strip.component.html',
  styleUrls: ['./info-strip.component.scss'],
})
export class InfoStripComponent implements OnInit {
  @Input() content: string;

  constructor() {}

  ngOnInit(): void {}
}
