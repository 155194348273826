import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CommentVO } from '../admin-comment/admin-comment.component';
import { ImportListCollection } from './model/import-list-collection';
import { ImportListPrice } from './model/import-list-price';
import { ImportListPriceItem } from './model/import-list-price-item';
import { IMPORT_LIST_PRICE_OPERATOR_TYPE } from './types/operator.type';
import { PriceType } from '../../../main/taskwizard/supplier-task-pricing/enums/price-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Utils } from '../../../utils/utils';
import { PERCENTAGE_NEGATIVE_MAX_AMOUNT } from '../../../utils/Constants';

@UntilDestroy()
@Component({
  selector: 'app-import-list-pricing',
  templateUrl: './import-list-pricing.component.html',
  styleUrls: ['./import-list-pricing.component.scss'],
})
export class ImportListPricingComponent implements OnInit, OnChanges {
  @Input() catalogId: number;
  @Input() comments: CommentVO[];
  @Input() collections: ImportListCollection[];
  @Input() pricing: ImportListPrice[];
  @Input() hasSaveButton = true;
  @Input() saveClickObs: Observable<void>;
  @Input() ecomCurrency = 'USD';
  @Input() priceType: PriceType;
  @Input() isDatafeed: boolean;
  @Input() locked: boolean;

  @Input() enabledPercentageOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1, -1];
  @Input() enabledFixOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1, -1];

  @Input() defaultPercentageOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = 1;
  @Input() defaultFixOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = 1;

  @Output() submitValue = new EventEmitter<ImportListPrice>();

  protected selectedPriceRule: ImportListPriceItem = null;
  protected selectedCollectionId = '';
  protected saveClick$ = new Subject<void>();

  protected form: FormGroup = null;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
    this.initSelectedPriceRule();
    this.patchForm(this.pricing?.find((p) => p.collectionId === '') ?? null);
    this.initValueChangeSubscription();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.pricing.firstChange) {
      this.patchForm(this.pricing?.find((p) => p.collectionId === '') ?? null);
    }
  }

  get saveClick(): Observable<void> {
    return this.hasSaveButton ? this.saveClick$.asObservable() : this.saveClickObs;
  }

  get priceItemList(): FormArray {
    return this.form.get('priceItemList') as FormArray;
  }

  get isFormInvalid(): boolean {
    return this.priceItemList.controls.some((control: AbstractControl<ImportListPriceItem>) => {
      return (
        (!control.value.from && control.value.from !== 0) ||
        (control.value.percAmountOperator == -1 && control.value.percentage > PERCENTAGE_NEGATIVE_MAX_AMOUNT)
      );
    });
  }

  public handleCollectionChange(collectionId: string): void {
    this.selectedCollectionId = collectionId;
    const pricingRule = this.pricing.find((pricing) => pricing.collectionId === collectionId);
    this.patchForm(pricingRule);
  }

  public patchForm(importListPrice: ImportListPrice): void {
    if (Utils.isNullOrUndefined(importListPrice)) {
      this.resetForm(this.selectedCollectionId);
      this.initSelectedPriceRule();
      return;
    }

    this.selectedPriceRule = importListPrice.priceItemList[0] || null;

    const priceItemList = importListPrice.priceItemList.map(
      (priceItem: ImportListPriceItem) =>
        new FormControl({
          ...priceItem,
          fixAmountOperator: priceItem.fixAmountOperator ?? this.defaultFixOperator,
          percAmountOperator: priceItem.percAmountOperator ?? this.defaultPercentageOperator,
        })
    );

    this.form.setControl('priceItemList', new FormArray(priceItemList));
  }

  public addNewPriceItem(index: number): void {
    const previousToValue = this.priceItemList.controls[index - 1].value.to;
    this.priceItemList.insert(index, new FormControl());
    this.priceItemList.controls[index].patchValue({ from: previousToValue });
    this.priceItemList.controls[index + 1]?.patchValue({ from: null });
  }

  public removePriceListItem(index: number): void {
    const previousToValue = this.priceItemList.controls[index - 1]?.value?.to ?? 0;
    this.priceItemList.removeAt(index);
    this.priceItemList.controls[index]?.patchValue({ from: previousToValue });
  }

  public calculateExampleHandler(index: number): void {
    const pricingItem = this.priceItemList.get([index]);

    if (Utils.isNullOrUndefined(pricingItem)) {
      return;
    }

    this.selectedPriceRule = Object.assign({}, pricingItem.getRawValue());
  }

  public toFieldChanged(index: number, value: number): void {
    if (value === null || this.priceItemList.length <= index + 1) {
      return;
    }

    this.priceItemList.controls[index + 1]?.patchValue({
      ...this.priceItemList.controls[index + 1].value,
      from: value,
    });
  }

  public submit(): void {
    this.saveClick$.next();

    if (this.isFormInvalid) {
      return;
    }

    this.emitValue();
  }

  private initForm(): void {
    this.form = new FormGroup({
      collectionId: new FormControl(this.selectedCollectionId),
      priceItemList: new FormArray([
        new FormControl({
          ...defaultPriceRule,
          percAmountOperator: this.defaultPercentageOperator,
          fixAmountOperator: this.defaultFixOperator,
        }),
      ]),
    });
  }

  private resetForm(collectionId: string): void {
    this.form.get('collectionId').setValue(collectionId);
    this.form.setControl(
      'priceItemList',
      new FormArray([
        new FormControl({
          ...defaultPriceRule,
          percAmountOperator: this.defaultPercentageOperator,
          fixAmountOperator: this.defaultFixOperator,
        }),
      ])
    );
  }

  private initSelectedPriceRule(): void {
    this.selectedPriceRule = defaultPriceRule;
  }

  private initValueChangeSubscription(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.hasSaveButton) {
        this.emitValue();
      }
    });
  }

  private emitValue(): void {
    if (Utils.isNullOrUndefined(this.priceItemList)) {
      return;
    }

    this.submitValue.emit({
      collectionId: this.selectedCollectionId,
      priceItemList: this.priceItemList.getRawValue(),
    });
  }

  protected readonly AbstractControl = AbstractControl;
}

const defaultPriceRule: ImportListPriceItem = {
  from: 0,
  to: null,
  percentage: 0,
  fixAmount: 0,
  percAmountOperator: 1,
  fixAmountOperator: 1,
};
