import { OnboardItem } from '../../layout/components/onboard/onboard.service';
import { ActionsUnion, SnippetActionTypes } from './snippet.actions';
import { SnippetToUser } from '../../main/setup-guide/model/snippet-to-user';

export interface SnippetState {
  items: OnboardItem[];
  completedSteps: SnippetToUser[];
  show: boolean;
}

const initialState: SnippetState = {
  items: null,
  completedSteps: null,
  show: null,
};

export function snippetReducer(state = initialState, action: ActionsUnion): SnippetState {
  switch (action.type) {
    case SnippetActionTypes.GET_SNIPPET_COMPLETED_STEPS_SUCCESS:
      return { ...state, completedSteps: action.payload };
    case SnippetActionTypes.GET_SNIPPET_TREE_SUCCESS:
      return { ...state, items: action.payload };
    case SnippetActionTypes.GET_SNIPPET_USER_STATUS_SUCCESS:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}
