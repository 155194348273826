import { Injectable } from '@angular/core';
import { SpringRestService } from '../rest/microservices/spring-rest.service';
import { ProductSearchResponse, ProductSearchService } from '../product-search/product-search.service';
import { FilterItemsVO, FullMarketplaceFilterVO, MarketplaceFilterPagination } from '../../vo/search-product-vo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpringPage } from '../../vo/pagination/spring-page';
import { Utils } from 'app/utils/utils';

export interface FavoriteProductsIds {
  userId: number;
  productIds: string[];
}

export interface FollowedSuppliersIds {
  userId: number;
  supplierIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesGatewayService {
  private static microserviceName = 'productSearch';

  constructor(private springRestService: SpringRestService, private productSearchService: ProductSearchService) {}

  public searchFavorites(
    userId: number,
    filters: Partial<FullMarketplaceFilterVO>,
    pagination?: MarketplaceFilterPagination
  ): Observable<ProductSearchResponse> {
    const filterItems = new FilterItemsVO({ ...filters, ...(Utils.isNullOrUndefined(pagination) ? {} : pagination) });
    return this.springRestService.post(
      FavoritesGatewayService.microserviceName,
      `/UserFavoriteProducts/Search/${userId}`,
      this.productSearchService.filterItemsToPostQuery(null, filterItems),
      {},
      true
    );
  }

  public getFavoritesIdList(userId: number): Observable<string[]> {
    return this.springRestService
      .get(FavoritesGatewayService.microserviceName, `/UserFavoriteProducts/${userId}`, {}, true)
      .pipe(
        map((value: FavoriteProductsIds) => {
          return value.productIds;
        })
      );
  }

  public postFavorites(userId: number, productIds: string[]): Observable<string[]> {
    return this.springRestService
      .post(FavoritesGatewayService.microserviceName, `/UserFavoriteProducts/${userId}`, productIds, {}, true)
      .pipe(
        map((value: FavoriteProductsIds) => {
          return value.productIds;
        })
      );
  }

  public deleteFavorites(userId: number, productIds: string[]): Observable<void> {
    return this.springRestService.post(
      FavoritesGatewayService.microserviceName,
      `/UserFavoriteProducts/Delete/${userId}`,
      productIds,
      {},
      true
    );
  }

  public deleteAllFavorites(userId: number): Observable<void> {
    return this.springRestService.delete(
      FavoritesGatewayService.microserviceName,
      `/UserFavoriteProducts/All/${userId}`
    );
  }

  public getFollowedSuppliersIds(userId: number): Observable<number[]> {
    return this.springRestService
      .get(FavoritesGatewayService.microserviceName, `/UserFavoriteSuppliers/${userId}`, {}, true)
      .pipe(
        map((response: FollowedSuppliersIds) => {
          return response.supplierIds;
        })
      );
  }

  public searchFollowedSuppliersIds(
    userId: number,
    filters: Partial<FullMarketplaceFilterVO>,
    pagination?: MarketplaceFilterPagination
  ): Observable<SpringPage<number>> {
    const filterItems = new FilterItemsVO({ ...filters, ...(Utils.isNullOrUndefined(pagination) ? {} : pagination) });
    return this.springRestService.post(
      FavoritesGatewayService.microserviceName,
      `/UserFavoriteSuppliers/Search/${userId}`,
      this.productSearchService.filterItemsToPostQuery(null, filterItems),
      {},
      true
    );
  }

  public postFollowedSupplierIds(userId: number, supplierIds: number[]): Observable<number[]> {
    return this.springRestService
      .post(FavoritesGatewayService.microserviceName, `/UserFavoriteSuppliers/${userId}`, supplierIds, {}, true)
      .pipe(
        map((value: FollowedSuppliersIds) => {
          return value.supplierIds;
        })
      );
  }

  public deleteFollowedSupplierIds(userId: number, supplierIds: number[]): Observable<void> {
    return this.springRestService.post(
      FavoritesGatewayService.microserviceName,
      `/UserFavoriteSuppliers/Delete/${userId}`,
      supplierIds,
      {},
      true
    );
  }

  public deleteAllFollowedSupplierIds(userId: number): Observable<void> {
    return this.springRestService.delete(
      FavoritesGatewayService.microserviceName,
      `/UserFavoriteSuppliers/All/${userId}`
    );
  }
}
