import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { NotificationService } from '../../main/notification/notification.service';

@Directive({
  selector: '[dragAndDropFileUpload]',
  standalone: true,
})
export class DragAndDropFileUploadDirective {
  @HostBinding('class.file-over') fileOver: boolean;
  @Input() allowMultiple = false;
  @Input() notify = true;
  @Input() allowedExtensions: string[];
  @Output() errorEvent = new EventEmitter<DragAndDropFileUploadErrorType>();
  @Output() filesDropped = new EventEmitter<File[]>();

  constructor(private notificationService: NotificationService, private translateService: TranslateService) {}

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFileDropped(files);
    }
  }

  private handleFileDropped(files: FileList): void {
    const filesArray = this.processFiles(files);
    if (!this.isValid(filesArray)) {
      return;
    }
    this.filesDropped.emit(filesArray);
  }

  private checkMultiple(files: File[]): boolean {
    if (!this.allowMultiple && files.length > 1) {
      this.notifyWarning('Only one file is allowed');
      this.errorEvent.emit('ALLOW_MULTIPLE_TYPE');
      return false;
    }
    return true;
  }

  private isValid(files: File[]): boolean {
    return this.checkMultiple(files) && this.isValidExtension(files);
  }

  private isValidExtension(files: File[]): boolean {
    if (!this.allowedExtensions) {
      return true;
    }
    const extensions = uniq(files.map((file) => file.name.split('.').pop().toUpperCase()));
    const allowedUpperCase = this.allowedExtensions.map((extension) => extension.toUpperCase());
    if (extensions.every((extension) => allowedUpperCase.includes(extension))) {
      return true;
    } else {
      this.notifyWarning(
        this.translateService.instant('DRAG_AND_DROP_FILE.EXTENSION_WARNING', {
          allowed: this.allowedExtensions.join(', '),
        })
      );
      this.errorEvent.emit('FILE_EXTENSION');
      return false;
    }
  }

  private notifyWarning(text: string): void {
    if (this.notify) {
      this.notificationService.warning(text);
    }
  }

  private processFiles(list: FileList): File[] {
    const files = [];
    for (let i = 0; i < list.length; i++) {
      files.push(list.item(i));
    }
    return files;
  }
}

type DragAndDropFileUploadErrorType = 'ALLOW_MULTIPLE_TYPE' | 'FILE_EXTENSION';
