import { BreakpointState } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { Utils } from 'app/utils/utils';
import { isArray, isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations';
import { AppState } from '../../../app.state';
import { ProductCardModule } from '../../../main/product-card/product-card.module';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { CatalogApproveStatus, CatalogSidebarService } from '../../../service/catalog-sidebar/catalog-sidebar.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { ExploreProductsService } from '../../../service/marketplace/explore-products/explore-products.service';
import { PaginationSizeService } from '../../../service/pagination-size/pagination-size.service';
import { PermissionDirective } from '../../../service/permission/permission.directive';
import { SCOPES } from '../../../service/permission/scopes';
import { ProductSearchResponse, ProductSearchService } from '../../../service/product-search/product-search.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';
import { filterSelector } from '../../../store/product-search/product-search.selector';
import {
  FullMarketplaceFilterVO,
  MarketplaceFilter,
  MarketplaceFilterPagination,
  SearchProductType,
  SearchProductVO,
} from '../../../vo/search-product-vo';
import { SupplierTask } from '../../../vo/supplier/supplier-task';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { ExploreProductsAdvertisementComponent } from '../explore-products-advertisement/explore-products-advertisement.component';
import { NoSearchResultsComponent } from '../no-search-results/no-search-results.component';
import { SimplePaginatorWithArrowsComponent } from '../paginations/simple-paginator-with-arrows/simple-paginator-with-arrows.component';
import { PaginatorLoadMoreComponent } from '../paginator-load-more/paginator-load-more.component';
import { ProductCardListComponent, ProductCardWidths } from '../product-card-list/product-card-list.component';
import { ProductNoResultListComponent } from '../product-no-result-list/product-no-result-list.component';
import { SearchProductTypeSelectorComponent } from '../search-product-type-selector/search-product-type-selector.component';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { ShippingPreferencesService } from '../../../service/preference/shipping-preferences.service';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { isAuthenticatedSelector } from '../../../store/authentication/authentication.selector';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations: [fuseAnimations],
  imports: [
    CommonModule,
    ProductCardModule,
    MatProgressSpinnerModule,
    NoSearchResultsComponent,
    FlexModule,
    ExploreProductsAdvertisementComponent,
    SimplePaginatorWithArrowsComponent,
    ErrorMessageComponent,
    ProductCardListComponent,
    ProductNoResultListComponent,
    SkeletonModule,
    SearchProductTypeSelectorComponent,
    PermissionDirective,
    PaginatorLoadMoreComponent,
    PermissionDirective,
  ],
  standalone: true,
})
export class ProductListComponent implements OnInit, OnDestroy {
  @Input() selectedEcom: EcomVO;
  @Input() ecomCurrency = 'USD';
  @Input() infinite = true;
  @Input() selectable = false;
  @Input() tasks: SupplierTask[];
  @Input() productCardWidth: ProductCardWidths = {
    xs: '50%',
    sm: '33.333%',
    md: '25%',
    lg: '20%',
    xl: '20%',
  };
  @Output() productNumberChanged = new EventEmitter<number>();
  @Output() selectionChange = new EventEmitter<string[]>();
  areProductsLoading = true;
  products: SearchProductVO[] = [];
  productNumbers = 0;
  adsPositions: number[] = [];
  isFreePlan = false;
  hasError = false;
  selected: string[] = [];
  page: number;
  _pagination: MarketplaceFilterPagination = {
    from: 0,
  };
  showPagination: boolean;
  searchProductType$ = new BehaviorSubject<SearchProductType>(SearchProductType.HYBRID);
  scopes = SCOPES;
  private maxProductToShow = 50;
  private productToShow = 50;
  private unsubscribeAll: Subject<void>;
  private productSearchFilterStore$: Observable<Partial<FullMarketplaceFilterVO>>;
  private selectedEcom$: Observable<EcomVO>;
  private previousFilter: MarketplaceFilter;

  constructor(
    private store: Store<AppState>,
    private productSearchService: ProductSearchService,
    private exploreProductsService: ExploreProductsService,
    private catalogSidebarService: CatalogSidebarService,
    private authenticationService: AuthenticationService,
    private screenManagerService: ScreenManagerService,
    private paginationSizeService: PaginationSizeService,
    private navigationEventService: NavigationEventService,
    private shippingPreferencesService: ShippingPreferencesService
  ) {
    this.unsubscribeAll = new Subject();
    this.productSearchFilterStore$ = this.store.select(filterSelector);
    this.selectedEcom$ = this.getSelectedEcomObservable();
  }

  private getSelectedEcomObservable(): Observable<EcomVO> {
    if (this.authenticationService.isUserLoggedIn()) {
      return this.store.select(selectedRetailerEcomSelector).pipe(filter((value) => value !== undefined));
    } else {
      return of(null);
    }
  }

  ngOnInit(): void {
    this.setProductToShowNumber();
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private resetPageSize(): void {
    if (this.isFreePlan) {
      this.pagination = { from: 0, size: this.productToShow - 1 };
    } else {
      this.pagination = { from: 0, size: this.productToShow };
    }

    this.showPagination =
      !this.infinite &&
      !Utils.isNullOrUndefined(this.pagination) &&
      (!Utils.isNullOrUndefined(this.page) || (!Utils.isNullOrUndefined(this.page) && this.page === 0));
  }

  private getIsHidden(filters: MarketplaceFilter): boolean {
    if (Utils.isNullOrUndefined(filters.taskId)) {
      return false;
    } else {
      return this.tasks?.find((task) =>
        isArray(filters.taskId) ? filters.taskId.includes(Number(task.id)) : Number(task.id) === filters.taskId
      )?.isHidden;
    }
  }

  private handleGetProductsSuccess(res: ProductSearchResponse, filters: MarketplaceFilter, isNewSearch: boolean): void {
    this.genRandomPositionForAds(isNewSearch);
    if (isNewSearch) {
      this.handleNewSearch(res.result);
    } else {
      this.handleExistingSearch(res.result);
    }
    this.productNumbers = res.total;
    this.productNumberChanged.emit(res.total);
    this.getUsedProducts(res.result.map((product) => product.ID));
    this.previousFilter = filters;
    this.hasError = false;
  }

  private getProducts(filters: MarketplaceFilter, isNewSearch: boolean): void {
    const isHidden = this.getIsHidden(filters);
    this.areProductsLoading = true;
    this.productSearchService
      .searchProducts(this.selectedEcom, { ...filters }, this.pagination, this.searchProductType$.value, isHidden)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe({
        next: (res) => {
          this.handleGetProductsSuccess(res, filters, isNewSearch);
        },
        error: () => {
          this.hasError = true;
        },
        complete: () => {
          this.areProductsLoading = false;
        },
      });
  }

  private handleNewSearch(products: SearchProductVO[]): void {
    this.products = products;
  }

  private handleExistingSearch(products: SearchProductVO[]): void {
    this.products.push(...products);
  }

  private subscribeToFilterAndEcomChange(): void {
    combineLatest([this.shippingPreferencesService.hasPreferences$, this.store.select(isAuthenticatedSelector)])
      .pipe(
        omitNullOrUndefined(),
        filter(
          ([hasShippingPreferences, isAuthenticated]: [boolean, boolean]) =>
            isEqual(hasShippingPreferences, true) || isEqual(isAuthenticated, false)
        ),
        switchMap(() =>
          combineLatest([
            this.productSearchFilterStore$,
            this.selectedEcom$,
            this.searchProductType$.pipe(filter((type) => !!type)),
          ])
        ),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(([state]): void => {
        this.initProducts(state);
      });
  }

  private initProducts(filters: MarketplaceFilter): void {
    this.resetPageSize();
    this.products = [];
    this.productNumbers = 0;
    this.getProducts(filters, true);
  }

  private getUsedProducts(ids: string[]): void {
    if (!Utils.isNullOrUndefined(this.selectedEcom) && ids?.length > 0) {
      this.exploreProductsService
        .getIsProductUsed(this.selectedEcom.id, ids)
        .pipe(takeUntil(this.navigationEventService.onNavigationStart))
        .subscribe((isProductUsedResp) => {
          this.exploreProductsService.modifyIsProductUsed(this.products, ids, isProductUsedResp);
        });
    }
  }

  private genRandomPositionForAds(isNewSearch: boolean): void {
    if (isNewSearch) {
      this.adsPositions = [this.getRandomIntFromInterval(6, this._pagination.size)];
    } else {
      this.adsPositions.push(
        this.getRandomIntFromInterval(6 + this.products.length, this._pagination.size + this.products.length)
      );
    }
  }

  private getRandomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private checkIfUserIsInFreePlan(): void {
    this.isFreePlan =
      Utils.isNullOrUndefined(this.selectedEcom) ||
      Utils.isNullOrUndefined(this.selectedEcom.subscriptions) ||
      this.selectedEcom.subscriptions.rmp.planId === 120;
  }

  private setProductToShowNumber(): void {
    this.screenChange$()
      .pipe(take(1))
      .subscribe((res) => {
        this.productToShow = this.paginationSizeService.getSizeToPaginate(
          res,
          this.maxProductToShow,
          this.productCardWidth
        );
      });
  }

  private screenChange$(): Observable<BreakpointState> {
    return this.screenManagerService.observeBreakpoints([
      BreakPoint.xs,
      BreakPoint.sm,
      BreakPoint.md,
      BreakPoint.lg,
      BreakPoint.xl,
    ]);
  }

  private init(): void {
    this.checkIfUserIsInFreePlan();
    this.subscribeToFilterAndEcomChange();
  }

  catalogStatusCheck(catalogID): CatalogApproveStatus {
    return this.catalogSidebarService.catalogStatusCheck(catalogID);
  }

  handleInfiniteLoadMore(): void {
    this.pagination = { ...this.pagination, from: this.pagination.from + this.pagination.size };
    this.getProducts(this.previousFilter, false);
  }

  handleNextPage(): void {
    this.pagination = { ...this.pagination, from: this.pagination.from + this.pagination.size };
    this.getProducts(this.previousFilter, true);
  }

  handlePrevPage(): void {
    this.pagination = { ...this.pagination, from: this.pagination.from - this.pagination.size };
    this.getProducts(this.previousFilter, true);
  }

  handleSelectionChange(id: string, selected: boolean): void {
    if (selected) {
      this.selected.push(id);
    } else {
      this.selected = this.selected.filter((item) => item !== id);
    }
    this.selectionChange.emit(this.selected);
  }

  get pagination(): MarketplaceFilterPagination {
    return this._pagination;
  }

  set pagination(value: MarketplaceFilterPagination) {
    this.page = Math.floor(value.from / value.size);
    this._pagination = value;
  }

  protected readonly SCOPES = SCOPES;
}
