import { Component, Input, OnInit } from '@angular/core';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-step-title-description',
  templateUrl: './step-title-description.component.html',
  styleUrls: ['./step-title-description.component.scss'],
})
export class StepTitleDescriptionComponent implements OnInit {
  constructor(private screenManagerService: ScreenManagerService) {}

  @Input() title: string;
  @Input() description: string;
  @Input() privateDetails = false;
  ltMd: Observable<boolean>;

  ngOnInit(): void {
    this.ltMd = this.screenManagerService.observeIfBreakpointMatches(BreakPoint.md);
  }
}
