import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { debounce, throttle } from 'lodash';

@Component({
  selector: 'app-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
})
export class FilterSelectorComponent<K, T> {
  @ViewChild('optionsContainer') optionsContainer: ElementRef<HTMLElement>;
  @Input() options: FilterSelectorOption<K, T>[];
  @Input() multiple = false;
  @Input() lazyLoad = false;
  @Output() loadMore = new EventEmitter<void>();
  @Input() totalCount = 0;
  @Input() hasSearch = false;
  @Input() searchLabel = 'FILTER_SIDEBAR.SELECTOR.SEARCH';
  @Input() searchTerm: string;
  @Output() searchTermChange = new EventEmitter<string>();
  @Input() value: K | K[];
  @Output() valueChange = new EventEmitter<K | K[]>();
  throttledOnScrollLoadData: () => void;
  private prevScrollTop = 0;

  constructor() {
    this.throttledOnScrollLoadData = throttle(this.onScrollLoadData, 250, { leading: true });
  }

  private generateNewArrayAfterSelection(selected: K): K[] {
    if (Array.isArray(this.value)) {
      if (this.value.some((value) => value === selected)) {
        return this.value.filter((value) => value !== selected);
      } else {
        return [...this.value, selected];
      }
    }
  }

  handleOptionSelected(value: K): void {
    if (this.multiple && Array.isArray(this.value)) {
      this.valueChange.emit(this.generateNewArrayAfterSelection(value));
    } else {
      this.valueChange.emit(value);
    }
  }

  onScrollLoadData(): void {
    if (this.lazyLoad) {
      const nativeElement = this.optionsContainer.nativeElement;
      if (nativeElement.clientHeight + Math.round(nativeElement.scrollTop) > nativeElement.scrollHeight - 280) {
        if (nativeElement.scrollTop >= this.prevScrollTop) {
          if (this.options.length < this.totalCount) {
            this.loadMore.emit();
          }
        }
        this.prevScrollTop = nativeElement.scrollTop;
      }
    }
  }

  handleSearchChange(newValue: string): void {
    this.searchTermChange.emit(newValue);
    this.prevScrollTop = 0;
  }

  handleClearClicked(): void {
    this.handleSearchChange(null);
  }

  get selectedOptions(): K[] {
    return Array.isArray(this.value) ? this.value : [this.value];
  }
}

/**
 * Used for filter-selector.component.ts
 *
 * Generic types:
 * - 'K' - key of option
 * - 'T' - value of option (e.g.: for label)
 */
export interface FilterSelectorOption<K, T> {
  key: K;
  value: T;
  badges?: FilterSelectorOptionBadge[];
  image?: FilterSelectorImage;
}

export interface FilterSelectorOptionBadge {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export interface FilterSelectorImage {
  src: string;
  width: string;
  height: string;
}
