const exploreProducts = 'explore-products';
const findSuppliers = 'suppliers';
const alibaba = 'alibaba';
const mySuppliers = 'my_suppliers';

export type MarketplaceNavigation = (typeof MarketplaceNavigation)[keyof typeof MarketplaceNavigation];
export const MarketplaceNavigation = {
  EXPLORE_PRODUCTS: `${exploreProducts}`,
  EXPLORE_PRODUCTS_SEARCH: `${exploreProducts}/search`,
  EXPLORE_PRODUCTS_SUPPLIERS: `${exploreProducts}/suppliers`,
  EXPLORE_PRODUCTS_PRODUCTS: `${exploreProducts}/products`,
  FIND_SUPPLIERS: findSuppliers,
  FIND_SUPPLIERS_CATALOG: `${findSuppliers}/catalog`,
  ALIBABA_SEARCH_PRODUCTS: `${alibaba}/search-products`,
  ALIBABA_PRODUCTS: `${alibaba}/products`,
  ALIBABA_IMPORT_LIST: `${alibaba}/import-list`,
  ALIBABA_MY_CATALOGS: `${alibaba}/my-catalogs`,
  ALIBABA_COMING_SOON: `${alibaba}/alibaba-coming-soon`,
  ALIBABA_REFRESH_TOKEN: `${alibaba}/refresh-token`,
  MY_CATALOGS: 'retailer-catalogs',
  RETAILER_IMPORT_CATALOGS: 'retailer-import-catalogs',
} as const;
