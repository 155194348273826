import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileList } from '../../../ftp/filelist';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../../../service/rest/rest.service';
import { AuthenticationService } from '../../../../service/authentication/authentication.service';
import { ConnectionStateService } from '../../../../service/taskwizard/connection-state.service';
import { HttpHeaders } from '@angular/common/http';
import { Utils } from '../../../../utils/utils';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { FtpConnectionVO } from '../../../taskwizard/addfiles/ftp/ftpclient.component';
import { BreadcrumbNavigationItem } from 'app/shared/components/breadcrumb-navigation/breadcrumb-navigation.component';

@Component({
  selector: 'app-retailer-export-connect-ftp',
  templateUrl: './retailer-export-connect-ftp.component.html',
  styleUrls: ['./retailer-export-connect-ftp.component.scss'],
  animations: fuseAnimations,
})
export class RetailerExportConnectFtpComponent implements OnInit {
  @ViewChild('ftpConnectionForm', { static: true }) ftpConnectionForm: NgForm;
  @Input() taskID: number;
  public ftpCredentials = new FtpConnectionVO();
  public fileList = new Array<FileList>();
  selected: FileList;

  breadCrumb: Array<BreadcrumbNavigationItem> = new Array<BreadcrumbNavigationItem>();
  public upDir: FileList;
  public hideFtpManager = true;
  public disableDownloadButton = true;
  public isFileDownloadSuccessful = false;
  public nodeTree: any[];

  disableNotCredentialsInput = false;

  public isConnectButtonPressed = false;

  isExpanded = false;

  constructor(
    private route: ActivatedRoute,
    private restService: RestService,
    private auth: AuthenticationService,
    private connStateService: ConnectionStateService
  ) {}

  getFtpCredentials(): FtpConnectionVO {
    return this.ftpCredentials;
  }

  ngOnInit(): void {
    this.ftpCredentials.connectionType = 'FTP';

    this.ftpCredentials.taskId = this.taskID;
    this.upDir = new FileList();
    this.upDir.name = '..';
    this.upDir.isDirectory = true;
    if (this.connStateService.currentState) {
      const connState = this.connStateService.currentState;
      this.ftpCredentials.host = connState.host;
      this.ftpCredentials.port = connState.port;
      this.ftpCredentials.username = connState.username;
      this.ftpCredentials.password = connState.password;
      this.ftpCredentials.root = connState.root;
      this.ftpCredentials.passive = connState.passive;
      this.ftpCredentials.isSsl = connState.isSsl;
      this.isExpanded = true;
    } else {
      this.ftpCredentials.passive = 'true';
    }
  }

  connectFtp(): void {
    this.isConnectButtonPressed = true;
    if (this.ftpConnectionForm.valid) {
      const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
      this.restService.post('FtpService/exportTaskConnect', this.ftpCredentials, requestOptions).subscribe(
        (res) => {
          this.hideFtpManager = false;
          this.parseFileList(res.getData());
          this.setBreadCrumbPath(this.ftpCredentials.root);
          this.isConnectButtonPressed = false;
        },
        (error) => {
          this.isConnectButtonPressed = false;
          console.log(error);
        }
      );
    } else {
      this.isConnectButtonPressed = false;
    }
  }

  parseFileList(list: any): void {
    const tempFileList = new Array<FileList>();
    for (const item of list) {
      const splittedItems = item.split('\\#');
      const listItem: FileList = new FileList();
      listItem.isDirectory = splittedItems[0] === 'directory' ? true : false;

      const splittedPath = splittedItems[1].split('//');
      listItem.path = splittedPath[0];
      listItem.name = splittedPath[1];
      tempFileList.push(listItem);
    }
    this.fileList = Utils.getOrderedFileList(tempFileList);

    this.fileList.unshift(this.upDir);
  }

  setBreadCrumbPath(path: string): void {
    const paths = path.split('/');
    this.breadCrumb = new Array<BreadcrumbNavigationItem>();
    this.addBcElement('HOME', '/');
    let temp;
    for (const item of paths) {
      if (item === '') {
        temp = '/';
        continue;
      }
      temp += item + '/';
      const bc: BreadcrumbNavigationItem = {
        key: item,
        url: temp,
        id: temp,
      };

      this.breadCrumb.push(bc);
      this.disableDownloadButton = false;
    }
  }

  addBcElement(label: string, url: string): void {
    const bc: BreadcrumbNavigationItem = {
      key: label,
      url: url,
      id: url,
    };

    this.breadCrumb.push(bc);
  }

  rowSingleClick(item: FileList): void {
    if (item.isDirectory) {
      let path = '';
      if (item.name === '..') {
        if (this.ftpCredentials.root === '/') {
          return;
        }
        const paths = this.ftpCredentials.root.split('/');

        const filtered = paths.filter(function (el): boolean {
          return el !== '';
        });

        for (let i = 0; i < filtered.length; i++) {
          path += paths[i] + '/';
        }
        this.ftpCredentials.root = path;
      } else {
        this.ftpCredentials.root = item.path.replace('//', '/') + '/' + item.name;
      }
      this.restService.post('FtpService/listDir', this.ftpCredentials).subscribe(
        (res) => {
          this.parseFileList(res.getData());
          this.setBreadCrumbPath(this.ftpCredentials.root);
        },
        (error) => console.log(error)
      );
      this.disableDownloadButton = false;
    }
  }

  breadCrumbCalback(bc: string): void {
    this.ftpCredentials.root = bc;
    this.restService.post('FtpService/listDir', this.ftpCredentials).subscribe(
      (res) => {
        this.parseFileList(res.getData());
        this.setBreadCrumbPath(this.ftpCredentials.root);
      },
      (error) => console.log(error)
    );
  }

  public isFTPFormValid(): boolean {
    return this.ftpConnectionForm.valid;
  }

  public getPath(): string {
    return this.ftpCredentials.root;
  }

  saveSelectedPath(): void {
    const requestOptions = { headers: new HttpHeaders({ Authorization: this.auth.getAuthToken() }) };
    this.restService.post('FtpService/exportTaskConnect', this.ftpCredentials, requestOptions).subscribe(
      (res) => {
        console.log(res);
        this.disableDownloadButton = true;
      },
      (error) => {
        console.log(error);
        this.disableDownloadButton = false;
      }
    );
  }

  expandCallback(): void {
    if (Utils.isNullOrUndefined(this.ftpCredentials.host) && this.connStateService.currentState) {
      const connState = this.connStateService.currentState;
      this.ftpCredentials.host = connState.host;
      this.ftpCredentials.port = connState.port;
      this.ftpCredentials.username = connState.username;
      this.ftpCredentials.password = connState.password;
      this.ftpCredentials.root = connState.root;
      this.ftpCredentials.passive = connState.passive;
      this.ftpCredentials.isSsl = connState.isSsl;
    }
  }
}
