import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-marketplace-view-selector',
  templateUrl: './marketplace-view-selector.component.html',
  styleUrls: ['./marketplace-view-selector.component.scss'],
})
export class MarketplaceViewSelectorComponent implements OnInit {
  @Input() marketplaceView: MarketplaceView;
  @Output() marketplaceViewChange = new EventEmitter<MarketplaceView>();

  constructor() {}

  ngOnInit(): void {}

  handleOptionClicked(value: MarketplaceView): void {
    this.marketplaceViewChange.emit(value);
  }
}

export type MarketplaceView = 'PRODUCTS' | 'SUPPLIERS';
