import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { getEcomsByRole } from '../../../store/ecom/ecom.selector';
import { Observable } from 'rxjs';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { filter, map } from 'rxjs/operators';
import { RolesEnum } from '../../../vo/roles/roles';
import { EcomInterface } from '../addfiles/shopify/ecom-interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-store-connected',
  templateUrl: './store-connected.component.html',
  styleUrls: ['./store-connected.component.scss'],
})
export class StoreConnectedComponent implements OnInit, EcomInterface {
  @Input() set inputs(value: StoreConnectedComponentInput) {
    this._inputs = value;
  }

  get inputs(): StoreConnectedComponentInput {
    return this._inputs;
  }

  private _inputs: StoreConnectedComponentInput;
  domain: string;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getEcoms()
      .pipe(
        untilDestroyed(this),
        map((ecoms) => ecoms.find((ecom) => ecom.id === Number(this._inputs.ecomId))),
        map((ecom) => ecom.domain)
      )
      .subscribe((domain) => (this.domain = domain));
  }

  private getEcoms(): Observable<EcomVO[]> {
    return this.store.select(getEcomsByRole(this.inputs.role)).pipe(filter((ecoms) => !!ecoms));
  }

  save(): any {
    return { ecomId: this.inputs.ecomId, domain: this.domain, ecomType: this.inputs.type };
  }
}

export interface StoreConnectedComponentInput {
  ecomId: string;
  type: string;
  role: RolesEnum;
}
