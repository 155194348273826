import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../service/rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from '../tasks.component';
import { Utils } from 'app/utils/utils';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  historyElements: TaskHistoryVO[] = null;
  httpParams = new HttpParams();
  taskId = 0;
  menus: MenuItem[] = [];

  constructor(private restService: RestService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.initMenuItems();
    this.route.queryParams.subscribe((params) => {
      this.taskId = params.taskId;
      this.httpParams = this.httpParams.set('taskId', this.taskId.toString());
      let url = 'TaskService/getHistoryData';
      switch (true) {
        case !Utils.isNullOrUndefined(params.isExportTask) && params.isExportTask === 'true':
          url = 'ExportTaskService/getHistory';
          break;
        case !Utils.isNullOrUndefined(params.isVariantsUTask) && params.isVariantsUTask === 'true':
          url = 'TaskService/getVUHistoryData';
          break;
      }

      this.restService.get(url, this.httpParams).subscribe(
        (res) => {
          this.historyElements = res.getData();
        },
        (error) => {
          this.historyElements = [];
        }
      );
    });
  }

  private initMenuItems(): void {
    const showButton = new MenuItem('Products View', 'edit', (id) => 1);

    this.menus = [showButton];
  }

  menuItemClick(itemLabel: string, taskId): void {
    console.log(itemLabel);
    if (itemLabel === 'Products View') {
      this.router.navigate(['/product-view'], { queryParams: { task_id: taskId } });
    }
  }
}

export interface TaskHistoryVO {
  id: number;
  taskId: number;
  results: TaskHistoryItem[];
  date: string;
}

interface TaskHistoryItem {
  name: string;
  value: number;
}
