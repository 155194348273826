export const PRODUCT_NUMBER = {
  SHORT_NUMBER: 8,
  SHORT_STRING: '8M+',
  FULL_NUMBER: 8000000,
  FULL_STRING: '8,000,000',
};

export const BRAND_NUMBER = {
  FULL_STRING: '12,000+',
  FULL_NUMBER: 12000,
  SHORT_STRING: '12K+',
  SHORT_NUMBER: 12,
};

export const USER_NUMBER = {
  DROPSHIPPER_SHORT_STRING: '200K+',
  DROPSHIPPER_SHORT_NUMBER: 200,
  DROPSHIPPER_FULL_STRING: '200,000+',
  DROPSHIPPER_FULL_NUMBER: 200000,
};

export const PRO_USER_NUMBER = {
  PRO_USER_SHORT_STRING: '200K+',
  PRO_USER_SHORT_NUMBER: 200,
  PRO_USER_FULL_STRING: '200,000+',
  PRO_USER_FULL_NUMBER: 200000,
};

export const STORE_NUMBER = {
  FULL_STRING: '300,000+',
  FULL_NUMBER: 300_000,
  SHORT_STRING: '300K+',
  SHORT_NUMBER: 300,
};

export const COUNTRY_NUMBER = {
  FULL_STRING: '190+',
  FULL_NUMBER: 190,
};

export const EXPERIENCE_NUMBER = {
  FULL_STRING: '20+',
  FULL_NUMBER: 20,
};

export const PLATFORM_NUMBER = {
  FULL_STRING: '11',
  FULL_NUMBER: 11,
};
