import { Directive } from '@angular/core';
import {
  NG_VALIDATORS,
  AbstractControl,
  ValidatorFn,
  Validator,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';

// validation function
function validateJuriNameFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    const isValid = c.value === 'delete';

    if (isValid) {
      return null;
    } else {
      return {
        deleteRequired: {
          valid: false,
        },
      };
    }
  };
}

@Directive({
  selector: '[deleteRequired][ngModel]',
  standalone: true,
  providers: [{ provide: NG_VALIDATORS, useExisting: DeleteValidatorDirective, multi: true }],
})
export class DeleteValidatorDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = validateJuriNameFactory();
  }

  validate(c: UntypedFormControl): ValidationErrors {
    return this.validator(c);
  }
}
