<!--<mat-form-field #formField appearance="fill" [ngClass]="suffix ? 'isSuffix' : ''">-->

<ng-container *ngIf="!multiline; else multilinecontainer">
  <div class="wrapper">
    <div #container class="container" fxLayout="row" fxLayoutAlign="space-between center">
      <ng-container *ngTemplateOutlet="chipscontainer"></ng-container>
      <div class="suffix" matSuffix fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <span *ngIf="suffix">{{ suffix }}</span>
        <button mat-icon-button (click)="onInputSettingsClick()" type="button">
          <mat-icon
            class="s-20"
            [ngStyle]="{
              color: hasSettings ? 'var(--app-syncee-primary-700)' : 'var(--app-syncee-grey-500)'
            }"
            style="user-select: none; cursor: pointer"
            >settings
          </mat-icon>
        </button>
        <button mat-icon-button (click)="addClicked()" type="button" *showOnBreakpoint="'lt-md'">
          <mat-icon
            class="s-20"
            [ngStyle]="{
              color: 'var(--app-syncee-grey-500)'
            }"
            style="user-select: none; cursor: pointer"
            >add
          </mat-icon>
        </button>
        <mat-icon
          class="s-16"
          *ngIf="isAlternativeFields"
          (click)="toggleAlterFields()"
          style="user-select: none; cursor: pointer"
          >keyboard_arrow_down
        </mat-icon>
      </div>
    </div>
    <div
      *ngIf="toggledAlterFields"
      #container
      class="container mt-12"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <mat-icon class="s-16" *ngIf="isAlternativeFields" matRipple style="user-select: none; cursor: pointer"
        >visibility_off
      </mat-icon>
      <ng-container *ngTemplateOutlet="alterchipscontainer"></ng-container>
      <!-- <div class="suffix" matSuffix fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span *ngIf="suffix">{{suffix}}</span>
                <mat-icon class="s-16" matRipple (click)="onInputSettingsClick()"
                          [ngStyle]="{color: hasSettings ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.3)'}"
                          style="user-select: none; cursor: pointer;">settings65
                </mat-icon>
            </div> -->
    </div>
  </div>
</ng-container>

<ng-template #multilinecontainer>
  <div #formField>
    <ng-container *ngTemplateOutlet="chipscontainer"></ng-container>
  </div>
</ng-template>

<ng-template #chipscontainer>
  <div
    class="chip-container-wrapper"
    [class.has-data]="!!values && values?.length > 0"
    [class.focus]="focused"
    [class.dragover]="dragover"
    (click)="mainChipListClicked($event)"
  >
    <mat-label class="chip-label typography-body-1 overflowing-text"
      >{{ label }}<span *ngIf="required"> *</span>
    </mat-label>
    <div
      #chipList
      cdkDropList
      (cdkDropListEntered)="dragover = true"
      (cdkDropListExited)="dragover = false"
      cdkDropListOrientation="horizontal"
      class="chips-container"
      fxLayout="row"
      fxLayoutAlign="start center"
      (cdkDropListDropped)="drop($event, values)"
    >
      <div cdkDrag *ngFor="let c of values; let index = index" fxFlex="0 0 auto">
        <mat-chip
          *cdkDragPreview
          class="custom-chip"
          [ngStyle]="{
            background: $any(c).constant
              ? 'var(--app-neon-yellow-100)'
              : $any(c).color.backgroundColor
              ? $any(c).color.backgroundColor
              : 'var(--app-syncee-grey-100)',
            color: !$any(c).constant && $any(c).color.color ? $any(c).color.color : 'inherit'
          }"
          [removable]="removable"
          [selectable]="false"
          (removed)="remove(values, c)"
          (click)="selectChip()"
        >
          <mat-icon
            class="s-16 icon-settings"
            *ngIf="!$any(c).constant"
            (click)="onChipSettingsClick($any(c), $event)"
            style="user-select: none; cursor: pointer"
            [ngStyle]="{
              color: $any(c).hasSettings ? $any(c).color.iconActiveColor : $any(c).color.iconColor
            }"
          >
            settings
          </mat-icon>
          <div class="drag-and-drop-chip typography-label">{{ $any(c).constant ? $any(c).value : $any(c).path }}</div>
        </mat-chip>
        <div fxLayout="row" class="chip-wrapper" fxLayoutAlign="start center">
          <mat-chip
            class="custom-chip"
            [ngStyle]="{
              background: $any(c).constant
                ? 'var(--app-neon-yellow-100)'
                : $any(c).color.backgroundColor
                ? $any(c).color.backgroundColor
                : 'var(--app-syncee-grey-100)',
              color: !$any(c).constant && $any(c).color.color ? $any(c).color.color : 'inherit'
            }"
            [removable]="removable"
            [selectable]="false"
            (removed)="remove(values, c)"
          >
            <mat-icon
              class="s-16 icon-settings"
              *ngIf="!$any(c).constant"
              (click)="onChipSettingsClick($any(c), $event)"
              style="user-select: none; cursor: pointer"
              [ngStyle]="{
                color: $any(c).hasSettings ? $any(c).color.iconActiveColor : $any(c).color.iconColor
              }"
            >
              settings
            </mat-icon>
            <ng-container *ngIf="$any(c).constant; then constantChip; else variableChip"></ng-container>
            <ng-template #constantChip>
              <span class="typography-label">{{ $any(c).value }}</span>
            </ng-template>
            <ng-template #variableChip>
              <div class="typography-label" fxLayout="row" fxLayoutAlign="start center">
                <ng-container
                  *ngFor="let pathPiece of $any(c).pathPieces; let count = count; let last = last; let index = index"
                >
                  <div>{{ pathPiece.piece }}</div>
                  <select
                    [(ngModel)]="pathPiece.index"
                    class="index_selector"
                    *ngIf="!last"
                    (change)="pathArrayIndexChanged($event, $any(c), pathPiece)"
                  >
                    <option [value]="'#'">#</option>
                    <option *ngFor="let i of [].constructor(10); let index = index" [value]="index">
                      {{ index }}
                    </option>
                  </select>
                </ng-container>
              </div>
            </ng-template>
            <mat-icon matChipRemove class="s-16 icon-delete">close</mat-icon>
          </mat-chip>
          <ng-container *ngTemplateOutlet="inputs; context: { index: index, minWidth: 5 }"></ng-container>
        </div>
      </div>

      <ng-template [ngIf]="values?.length < 1">
        <ng-container *ngTemplateOutlet="inputs; context: { index: 0, minWidth: 5 }"></ng-container>
      </ng-template>
      <ng-template #inputs let-index="index" let-minWidth="minWidth">
        <ng-container *ngIf="!textAreaMode; else textArea">
          <input
            #inputChip
            class="input-box typography-body-1"
            *ngIf="isConstantsAllowed"
            autoSizeInput
            [ngModel]="null"
            (focus)="onInputFocus()"
            (keyup.backspace)="backspace($event)"
            (keyup.enter)="newRowEventHandler($event, index, values)"
            (click)="$event.stopPropagation()"
            [minWidth]="minWidth"
            [attr.data-index]="index"
            (focusout)="onInputFocusOut($event, index, values)"
            [attr.maxlength]="remainingChars"
            [disabled]="maxValues <= values?.length"
            [attr.type]="numberOnly ? 'number' : 'text'"
          />
        </ng-container>
        <ng-template #textArea>
          <textarea
            *ngIf="isConstantsAllowed"
            (focus)="onInputFocus()"
            (focusout)="onInputFocusOut($event, index, values)"
            [attr.maxlength]="remainingChars"
            [disabled]="maxValues <= values?.length"
            [attr.type]="numberOnly ? 'number' : 'text'"
          ></textarea>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #alterchipscontainer>
  <div
    class="chip-container-wrapper"
    [class.has-data]="!!alterValues && alterValues?.length > 0"
    [class.focus]="focused"
    [class.dragover]="dragover"
  >
    <mat-label class="chip-label typography-body-1 overflowing-text">Alter {{ label }} </mat-label>
    <div
      #alterchipList
      cdkDropList
      cdkDropListOrientation="horizontal"
      class="chips-container"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="alterChipListClicked()"
      (cdkDropListDropped)="drop($event, alterValues, true)"
    >
      <div cdkDrag *ngFor="let c of alterValues; let index = index" fxFlex="0 0 auto">
        <mat-chip
          *cdkDragPreview
          class="custom-chip"
          [ngStyle]="{
            background: $any(c).constant
              ? 'var(--app-neon-yellow-100)'
              : $any(c).color.backgroundColor
              ? $any(c).color.backgroundColor
              : 'var(--app-syncee-grey-100)',
            color: !$any(c).constant && $any(c).colorcolor ? $any(c).color.color : 'inherit'
          }"
          [removable]="removable"
          [selectable]="false"
          (removed)="remove(alterValues, c)"
          (click)="selectChip()"
        >
          <mat-icon
            class="s-16 icon-settings"
            *ngIf="!$any(c).constant"
            (click)="onChipSettingsClick($any(c), $event, true)"
            style="user-select: none; cursor: pointer"
            [ngStyle]="{
              color: $any(c).hasSettings ? $any(c).color.iconActiveColor : $any(c).color.iconColor
            }"
          >
            settings
          </mat-icon>
          <div class="drag-and-drop-chip typography-label">{{ $any(c).constant ? $any(c).value : $any(c).path }}</div>
          <mat-icon matChipRemove class="s-16 icon-delete">close</mat-icon>
        </mat-chip>
        <div fxLayout="row" class="chip-wrapper" fxLayoutAlign="start center">
          <mat-chip
            class="custom-chip"
            [ngStyle]="{
              background: $any(c).constant
                ? 'var(--app-neon-yellow-100)'
                : $any(c).color.backgroundColor
                ? $any(c).color.backgroundColor
                : 'var(--app-syncee-grey-100)',
              color: !$any(c).constant && $any(c).color.color ? $any(c).color.color : 'inherit'
            }"
            [removable]="removable"
            [selectable]="false"
            (removed)="remove(alterValues, c)"
          >
            <mat-icon
              class="s-16 icon-settings"
              *ngIf="!c.constant"
              (click)="onChipSettingsClick($any(c), $event, true)"
              style="user-select: none; cursor: pointer"
              [ngStyle]="{
                color: $any(c).hasSettings ? $any(c).color.iconActiveColor : $any(c).color.iconColor
              }"
            >
              settings
            </mat-icon>
            <ng-container *ngIf="$any(c).constant; then constantChip; else variableChip"></ng-container>
            <ng-template #constantChip>
              <span class="typography-label">{{ $any(c).value }}</span>
            </ng-template>
            <ng-template #variableChip>
              <div class="typography-label" fxLayout="row" fxLayoutAlign="start center">
                <ng-container
                  *ngFor="let pathPiece of $any(c).pathPieces; let count = count; let last = last; let index = index"
                >
                  <div>{{ pathPiece.piece }}</div>
                  <select
                    [(ngModel)]="pathPiece.index"
                    class="index_selector"
                    *ngIf="!last"
                    (change)="pathArrayIndexChanged($event, $any(c), pathPiece)"
                  >
                    <option [value]="'#'">#</option>
                    <option *ngFor="let i of [].constructor(10); let index = index" [value]="index">
                      {{ index }}
                    </option>
                  </select>
                </ng-container>
              </div>
              <mat-icon matChipRemove class="s-16 icon-delete">close</mat-icon>
            </ng-template>
            <mat-icon matChipRemove class="s-16 icon-delete">close</mat-icon>
          </mat-chip>
          <ng-container *ngTemplateOutlet="alterInputs; context: { index: index, minWidth: 5 }"> </ng-container>
        </div>
      </div>

      <ng-template [ngIf]="alterValues?.length < 1">
        <ng-container *ngTemplateOutlet="alterInputs; context: { index: 0, minWidth: 5 }"></ng-container>
      </ng-template>
      <ng-template #alterInputs let-index="index" let-minWidth="minWidth">
        <ng-container *ngIf="!textAreaMode; else textArea">
          <input
            #alterInputChip
            class="input-box typography-body-1"
            *ngIf="isConstantsAllowed"
            autoSizeInput
            [ngModel]="null"
            (focus)="onInputFocus()"
            (keyup.backspace)="backspace($event)"
            (keyup.enter)="newRowEventHandler($event, index, null)"
            (click)="$event.stopPropagation()"
            [minWidth]="minWidth"
            [attr.data-index]="index"
            (focusout)="onInputFocusOut($event, index, alterValues)"
            [attr.maxlength]="remainingChars"
            [disabled]="maxValues <= alterValues?.length"
            [attr.type]="numberOnly ? 'number' : 'text'"
          />
        </ng-container>
        <ng-template #textArea>
          <textarea
            *ngIf="isConstantsAllowed"
            (focus)="onInputFocus()"
            (focusout)="onInputFocusOut($event, index, alterValues)"
            [attr.maxlength]="remainingChars"
            [disabled]="maxValues <= alterValues?.length"
            [attr.type]="numberOnly ? 'number' : 'text'"
          ></textarea>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
