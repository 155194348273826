import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { transformToCdnUrl } from 'app/utils/transform-to-cdn-url';
import { Utils } from 'app/utils/utils';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from '../../../../../../app.state';
import { CountriesManagerService } from '../../../../../../service/countries-manager/countries-manager.service';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { EXTRA_SHIPPING_FILTERS } from '../../../../../../utils/Constants';
import { FilterSelectorOption } from '../../../filter-selector/filter-selector.component';

@Component({
  selector: 'app-ships-to-filter',
  templateUrl: './ships-to-filter.component.html',
  styleUrls: ['./ships-to-filter.component.scss'],
})
export class ShipsToFilterComponent implements OnInit {
  productSearchStore$: Observable<ProductSearchState>;
  countryList = this.countriesManagerService.getOnlyCountries();
  options: FilterSelectorOption<string, string>[] = [];
  filteredOptions: FilterSelectorOption<string, string>[] = [];
  private _searchTerm: string;
  private _selectedShipsToType: ShipsToType = 'country';
  private extraShippingFilters = EXTRA_SHIPPING_FILTERS;

  constructor(
    public store: Store<AppState>,
    private countriesManagerService: CountriesManagerService,
    private translateService: TranslateService
  ) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
  }

  ngOnInit(): void {
    this.options = this.mapCountryListToOptions();
    this.filterOptions();
    this.initShipsToType();
  }

  handleShipsToChange(value: string): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { shipsTo: value } }));
  }

  private mapCountryListToOptions(): FilterSelectorOption<string, string>[] {
    return this.countryList.map((country) => ({
      key: country.name,
      value: this.translateService.instant(`COUNTRIES.${country.code}`),
      image: {
        src: transformToCdnUrl(`assets/images/country_flags_png/${country.code.toLowerCase()}.png`),
        width: '20px',
        height: '17px',
      },
    }));
  }

  private mapContinentListToOptions(): FilterSelectorOption<string, string>[] {
    return this.extraShippingFilters.map((filter) => ({
      key: filter.name,
      value: this.translateService.instant(`CONTINENTS.${filter.name.toUpperCase()}`),
      image: {
        src: transformToCdnUrl(`assets/images/country_flags_png/${filter.name.toLowerCase()}.png`),
        width: '20px',
        height: '17px',
      },
    }));
  }

  private handleShipsTypeChange(value: ShipsToType): void {
    this.options = value === 'country' ? this.mapCountryListToOptions() : this.mapContinentListToOptions();
    this.searchTerm = null;
  }

  get selectedShipsToType(): ShipsToType {
    return this._selectedShipsToType;
  }

  set selectedShipsToType(value: ShipsToType) {
    this.handleShipsTypeChange(value);
    this._selectedShipsToType = value;
  }

  private initShipsToType(): void {
    this.productSearchStore$.pipe(take(1)).subscribe((store) => {
      if (!Utils.isNullOrUndefined(store.temporaryFilter) && !Utils.isNullOrUndefined(store.temporaryFilter.shipsTo)) {
        if (this.extraShippingFilters.some((filter) => filter.code === store.temporaryFilter.shipsTo)) {
          this.selectedShipsToType = 'continent';
        }
      }
    });
  }

  private filterOptions(): void {
    if (Utils.isNullOrUndefined(this.searchTerm)) {
      this.filteredOptions = [...this.options];
    } else {
      this.filteredOptions = this.options.filter(
        (option) => option.value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      );
    }
  }

  handleSearchChange(newValue: string): void {
    this.searchTerm = newValue;
  }

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filterOptions();
  }
}

type ShipsToType = 'continent' | 'country';
