import { Injectable } from '@angular/core';
import { combineLatest, filter, forkJoin, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { Preference } from '../../vo/Preferences/preference';
import { getCurrentUserIdSelector, locationByIpSelector } from '../../store/user/user.selector';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { map, startWith, take } from 'rxjs/operators';
import { PreferenceNameEnum } from '../../vo/Preferences/preference-name.enum';
import { PreferenceStoreService, PreferenceTypeRecord } from './preference.store.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { selectedRetailerEcomSelector } from '../../store/ecom/ecom.selector';
import { Utils } from '../../utils/utils';
import { CountriesManagerService } from '../countries-manager/countries-manager.service';
import { CategoryService } from '../product-search/category.service';
import { EcomVO } from '../ecom/ecom.service';
import { MatDialog } from '@angular/material/dialog';
import { CategoryPreferencesFormDialogComponent } from '../../shared/components/category-preferences-form-dialog/category-preferences-form-dialog.component';
import { CategoryPreferencesForm } from '../../shared/components/category-preferences-form/model/category-preferences-form';
import { switchMapWith } from '../../utils/operator/switch-map-with';
import { ShippingPreferencesService } from './shipping-preferences.service';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';
import { gettingStartedUncompletedStepsSelector } from '../../store/getting-started/getting-started.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { Router } from '@angular/router';
import { RetailerNavigation } from '../../navigation/navigation-routes/retailer/retailer.navigation';

@Injectable({ providedIn: 'root' })
export class PreferenceDialogService {
  subscription: Subscription;
  reCheck: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private preferenceStoreService: PreferenceStoreService,
    private countriesManagerService: CountriesManagerService,
    private dialog: MatDialog,
    private categoryService: CategoryService,
    private shippingPreferencesService: ShippingPreferencesService,
    private router: Router
  ) {}

  private ecomCountryCode$ = this.store
    .select(selectedRetailerEcomSelector)
    .pipe(map((ecom: EcomVO) => ecom?.options?.country));

  private locationByIp$ = this.store.select(locationByIpSelector).pipe(omitNullOrUndefined(), take(1));

  private preferenceTypeIds$ = this.preferenceStoreService.getTypeIdsByNames().pipe(omitNullOrUndefined());

  private userId$ = this.store.select(getCurrentUserIdSelector).pipe(omitNullOrUndefined());

  public checkPreferences(): void {
    if (!!this.subscription) {
      this.reCheck.next();
      return;
    }
    this.subscription = this.store
      .select(isAuthenticatedSelector)
      .pipe(
        omitNullOrUndefined(),
        filter((isAuthenticated) => !!isAuthenticated),
        switchMap(() => this.reCheck.pipe(startWith(undefined as void))),
        switchMap(() =>
          this.store
            .select(gettingStartedUncompletedStepsSelector, RolesEnum.RETAILER)
            .pipe(filter((steps) => steps?.length === 0))
        ),
        switchMap(() =>
          this.shippingPreferencesService.hasPreferences$.pipe(
            filter((hasPref) => !hasPref),
            take(1)
          )
        ),
        take(1)
      )
      .subscribe({ next: () => this.setUpUserPreferences() });
  }

  public setUpUserPreferences(): void {
    if (this.router.url.includes(RetailerNavigation.BILLING.CHECKOUT)) {
      return;
    }
    this.setUpLocationPreferences()
      .pipe(
        take(1),
        switchMap(() =>
          this.dialog
            .open(CategoryPreferencesFormDialogComponent, { autoFocus: false })
            .afterClosed()
            .pipe(
              omitNullOrUndefined(),
              take(1),
              map((form: CategoryPreferencesForm) => form.categories)
            )
        ),
        switchMapWith((ids: number[]) => this.categoryService.getChildrenCategoryIds(ids)),
        map(([ids, children]: [number[], number[]]): number[] => [...ids, ...children]),
        switchMap((ids: number[]) => this.createPreference(PreferenceNameEnum.SYNCEE_CATEGORY, ids.map(String))),
        switchMap((preferences: Preference[]) =>
          this.preferenceStoreService.savePreferencesByPreferenceType(PreferenceNameEnum.SYNCEE_CATEGORY, preferences)
        )
      )
      .subscribe();
  }

  private setUpLocationPreferences(): Observable<boolean> {
    return this.getLocationPreference().pipe(
      omitNullOrUndefined(),
      take(1),
      switchMap((location: string) =>
        forkJoin([
          this.createPreference(PreferenceNameEnum.SHIPS_TO_LOCATION, [location]),
          this.createPreference(PreferenceNameEnum.SHIPS_FROM_LOCATION, [location]),
        ])
      ),
      switchMap(([shipsToPreference, shipsFromPreference]: [Preference[], Preference[]]) =>
        forkJoin([
          this.preferenceStoreService
            .savePreferencesByPreferenceType(PreferenceNameEnum.SHIPS_TO_LOCATION, shipsToPreference)
            .pipe(take(1)),
          this.preferenceStoreService
            .savePreferencesByPreferenceType(PreferenceNameEnum.SHIPS_FROM_LOCATION, shipsFromPreference)
            .pipe(take(1)),
        ])
      ),
      map((statuses: boolean[]) => statuses.every((status: boolean): boolean => status === true))
    );
  }

  public getLocationPreference(): Observable<string> {
    return combineLatest([this.ecomCountryCode$, this.locationByIp$]).pipe(
      take(1),
      map(([ecomCountryCode, locationByIp]: [string, string]): string => {
        switch (true) {
          case !Utils.isNullOrUndefinedOrLengthZero(ecomCountryCode):
            return ecomCountryCode;
          case !Utils.isNullOrUndefinedOrLengthZero(locationByIp):
            return locationByIp;
          default:
            return 'US';
        }
      }),
      map((countryCode: string) => this.countriesManagerService.getCountryNameByCountryCode(countryCode))
    );
  }

  public createPreference(preferenceName: PreferenceNameEnum, values: string[]): Observable<Preference[]> {
    return combineLatest([this.preferenceTypeIds$, this.userId$]).pipe(
      omitNullOrUndefined(),
      take(1),
      map(([typeIds, userId]: [PreferenceTypeRecord, number]) =>
        values.map(
          (value: string): Preference => ({
            preferenceTypeId: typeIds[preferenceName],
            preferenceValue: String(value),
            userId: userId,
          })
        )
      )
    );
  }
}
