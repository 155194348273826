import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { EcomVO } from '../ecom/ecom.service';
import { EcomFeatureUsage } from './model/ecom/ecom-feature-usage';
import { UserFeatureUsage } from './model/user/user-feature-usage';
import { selectedRetailerEcomSelector } from '../../store/ecom/ecom.selector';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { ecomUsageSelector, userUsageSelector } from '../../store/usage/usage.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Injectable({ providedIn: 'root' })
export class FeatureUsageService {
  constructor(private store: Store<AppState>) {}

  featureUsage(): (source: Observable<EcomVO>) => Observable<EcomFeatureUsage | UserFeatureUsage> {
    return (source) => source.pipe(switchMap((ecom) => (!!ecom ? this.getEcomUsage() : this.getUserUsage())));
  }

  getEcomUsage(): Observable<EcomFeatureUsage> {
    return this.store.select(selectedRetailerEcomSelector).pipe(
      omitNullOrUndefined(),
      switchMap((ecom: EcomVO) => this.store.select(ecomUsageSelector(ecom.id))),
      omitNullOrUndefined()
    );
  }

  getUserUsage(): Observable<UserFeatureUsage> {
    return this.store.select(userUsageSelector).pipe(omitNullOrUndefined());
  }
}
