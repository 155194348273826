import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { noop } from 'lodash';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { snippetIsAllCompletedSelector } from '../../store/snippet/snippet.selector';
import { getUserRoleSelector } from '../../store/user/user.selector';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';

@Injectable({
  providedIn: 'root',
})
export class SetupGuideGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(getUserRoleSelector).pipe(
      omitNullOrUndefined(),
      switchMap((role) =>
        this.store.select(snippetIsAllCompletedSelector, role).pipe(
          omitNullOrUndefined(),
          tap((result) => {
            !result ? this.router.navigate(['/setup-guide']) : noop();
          })
        )
      )
    );
  }
}
