<div *conditionLoading="isLoaded">
  <div class="page-wrapper">
    <ng-container *ngIf="errorMessage == ''; else error">
      <app-thank-you-page [ecomId]="ecomId" [ecomType]="ecomType"></app-thank-you-page>
      <ng-container *ngIf="sharesale">
        <img
          src="https://www.shareasale.com/sale.cfm?tracking={{ sharesale.orderID }}&amount={{
            sharesale.amount
          }}&merchantID=110755&transtype=sale"
          width="1"
          height="1"
        />
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #error>
  <app-single-alert-with-icon type="warning" [titleKey]="errorMessage"></app-single-alert-with-icon>
</ng-template>
