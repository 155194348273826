import { Component, OnInit } from '@angular/core';
import { AllCatalogWithApprovedStatus, SuppliersService } from '../../../service/suppliers/suppliers.service';
import { User } from '../../../service/user/user';
import { UserService } from '../../../service/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';

@Component({
  selector: 'app-supplier-task-problem-alert',
  templateUrl: './supplier-task-problem-alert.component.html',
  styleUrls: ['./supplier-task-problem-alert.component.scss'],
  standalone: true,
  imports: [CommonModule, AlertMultiComponent],
})
export class SupplierTaskProblemAlertComponent implements OnInit {
  hasPendingTask = false;
  pendingTasks: AllCatalogWithApprovedStatus[] = [];
  user: User;
  role: number;

  constructor(
    private suppliersService: SuppliersService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.role = Number(this.userService.getActualRole());
    if (this.role === 1) {
      this.setPendingCatalogFlag();
    }
  }

  getAlertMessage(): string[] {
    const listElements = this.pendingTasks.map((task) => `<li>${task.name}</li>`).join('');
    return [
      this.translateService.instant('PENDING_TASK.MAIN_MESSAGE'),
      this.translateService.instant('PENDING_TASK.LIST_TITLE'),
      `<ul>${listElements}</ul>`,
    ];
  }

  setPendingCatalogFlag(): void {
    const supplierUser = localStorage.getItem('userId')
      ? this.userService.getLoggedUser()
      : this.userService.getCachedUser();
    if (!supplierUser) {
      return;
    }
    this.suppliersService.getAllCatalogWithApprovedStatus(supplierUser.id).subscribe((res) => {
      this.pendingTasks = res.filter((task) => task.approved === 0);
      this.hasPendingTask = this.pendingTasks.length > 0;
    });
  }
}
