import { Injectable } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../../../../../service/rest/rest.service';
import { User } from '../../../../../service/user/user';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public watcher: Subscription;
  public unseen: number;

  constructor(private restService: RestService) {}

  public getNotifications(params): Observable<any> {
    return this.restService
      .get(`NotificationService/getNotifications`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public markAsRead(params): Observable<any> {
    return this.restService.post(`NotificationService/markAsRead`, params).pipe(map((response) => response.getData()));
  }

  public delete(params): Observable<any> {
    return this.restService.post(`NotificationService/delete`, params).pipe(map((response) => response.getData()));
  }

  public getUnseenNotificationCount(params): Observable<any> {
    return this.restService
      .get(`NotificationService/getUnseenNotificationCount`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public stopWatcher(): void {
    if (!Utils.isNullOrUndefined(this.watcher)) {
      this.watcher.unsubscribe();
      this.watcher = null;
    }
  }

  public setTimerForNotificationCheck(): void {
    if (Utils.isNullOrUndefined(this.watcher)) {
      this.watcher = interval(300000).subscribe((t) => {
        const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
        const isAdmin = !Utils.isNullOrUndefined(currentUser) && currentUser.role === 3;
        const actualRole =
          isAdmin && Utils.isNullOrUndefined(localStorage.getItem('userId')) ? '3' : localStorage.getItem('actualRole');

        const dataToRest = {
          role: actualRole,
        };
        this.getUnseenNotificationCount(dataToRest).subscribe((res) => {
          this.unseen = res.count;
        });
      });
    }
  }

  public clearUnseenNotifications(): void {
    this.unseen = 0;
  }
}
