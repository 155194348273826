<div class="paypal-onboard-container">
  <div *ngIf="connectData && !!connectData.activated" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div class="typography-body-large-2">{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.LWPP_DISCONNECT' | translate }}</div>
    <app-custom-icon [icon]="'check'" [type]="'success'"></app-custom-icon>
  </div>

  <div *ngIf="!connectData || !connectData.activated" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div class="typography-body-large-2">{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.LWPP' | translate }}</div>
    <app-custom-icon [icon]="'close'" [type]="'danger'"></app-custom-icon>
  </div>
  <br />
  <ng-container *ngIf="!inProgress; else loading">
    <ng-container [ngSwitch]="true">
      <div *ngSwitchCase="!!connectData && !!connectData.activated">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>
            <strong>{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.CONNECTED_PAYPAL_ACCOUNT' | translate }}</strong>
            {{ connectData.email }}
          </div>
          <button mat-button color="warn" (click)="disconnect()">
            {{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_BTN' | translate }}
          </button>
        </div>
      </div>
      <div *ngSwitchCase="connectData && !connectData.activated">
        <div
          *ngIf="!onboardStatus?.primaryEmailConfirmed"
          class="mb-8"
          [innerHTML]="'BILLING.SUBSCRIPTION.PAYPAL_PAGES.ACTION_REQUIRED_EMAIL_CONFIRMATION' | translate"
        ></div>
        <div
          *ngIf="!onboardStatus?.paymentsReceivable"
          class="mb-8"
          [innerHTML]="'BILLING.SUBSCRIPTION.PAYPAL_PAGES.ACTION_REQUIRED_RECEIVE_PAYMENT' | translate"
        ></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <button mat-raised-button class="syncee-blue-button" (click)="connectionCheck()">
            {{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.CONNECTION_CHECK' | translate }}
          </button>
          <button mat-raised-button class="try-again-btn" (click)="getNewLink()">
            {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK.AGAIN_BTN' | translate }}
          </button>
        </div>
      </div>
      <div *ngSwitchDefault>
        <app-paypal-custom-button (click)="paypalButtonClickHandle()"></app-paypal-custom-button>
      </div>
      <div
        *ngIf="!connectData && deletedAccounts.length > 0"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="20px"
      >
        <div class="typography-body-1">
          {{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.MESSAGE' | translate }}
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="20px"
          fxLayoutAlign="stretch center"
          fxLayoutAlign.lt-md="center center"
        >
          <mat-form-field id="domain-selector" fxFlex="1 0 0" class="w-100p input-without-padding" appearance="fill">
            <mat-label>{{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.DISCONNECTED_ACCOUNTS' | translate }}</mat-label>
            <mat-select panelClass="mat-select-display-change" [(value)]="selectedOldAccount">
              <mat-option *ngFor="let account of deletedAccounts" [value]="account">
                {{ account.email }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="reconnect()" mat-button class="add-new-store syncee-blue-button">
            {{ 'BILLING.SUBSCRIPTION.STRIPE_PAGES.CONNECTION' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <mat-spinner [diameter]="60"></mat-spinner>
  </ng-template>
</div>
