import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../rest/rest.service';
import { RestResponse } from '../rest/rest-response';
import { map } from 'rxjs/operators';
import { NotificationService } from '../../main/notification/notification.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FileSettingsService {
  public static SAVE_SETTINGS_URL = 'FileProcessService/saveFileSettingsFW';
  public static SAVE_SHOPIFY_SETTINGS_URL = 'TaskFileEcomDownloadService/downloadFile';
  public static GET_DOWNLOADED_DATA_INFO = 'TaskFileEcomDownloadService/getDownloadedDataInfo';
  public static GET_EXCEL_SHEETS_URL = 'FileProcessService/getSheetsFromXLSFile';

  constructor(
    private restService: RestService,
    private notificationService: NotificationService,
    private translation: TranslateService
  ) {}

  getSheetsFromExcel(taskId: number, fileId: number): Observable<any> {
    const fileSettingsParams = new HttpParams().set('fileId', fileId.toString()).set('taskId', taskId.toString());

    return this.restService.get(FileSettingsService.GET_EXCEL_SHEETS_URL, fileSettingsParams).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          return res.getData();
        } else {
          this.notificationService.error('Error occurred at getting excel sheets');
          return false;
        }
      })
    );
  }

  saveSettings(taskId: number, fileId: number, extension: string, settings): Observable<boolean> {
    const fileSettings = {
      fileId: fileId,
      taskId: taskId,
      extension: extension,
      settings: settings,
    };

    return this.restService.post(FileSettingsService.SAVE_SETTINGS_URL, fileSettings).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          this.notificationService.success(this.translation.instant('TASKWIZARD.ADDFILES.FILE_SETTINGS.SAVE_SUCCESS'));
          return true;
        } else {
          this.notificationService.error(this.translation.instant('TASKWIZARD.ADDFILES.FILE_SETTINGS.SAVE_ERROR'));
          return false;
        }
      })
    );
  }

  saveEcomSettings(taskId: number, fileId: number, ecomId: any): Observable<any> {
    const params = {
      taskId: taskId,
      fileId: fileId,
      ecomId: ecomId,
    };

    return this.restService.post(FileSettingsService.SAVE_SHOPIFY_SETTINGS_URL, params).pipe(
      map((res: RestResponse) => {
        this.notificationService.success(
          this.translation.instant('TASKWIZARD.ADDFILES.FILE_SETTINGS.SAVE_ECOM_SETTINGS_SUCCESS')
        );
        return res.getFirstData();
      })
    );
  }

  getDownloadedDataInfo(fileId, ecomId): Observable<any> {
    const params = {
      fileId: fileId,
      ecomId: ecomId,
    };

    return this.restService.get(FileSettingsService.GET_DOWNLOADED_DATA_INFO, params).pipe(
      map((res: RestResponse) => {
        return res.getFirstData();
      })
    );
  }
}
