import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GetEcomUsageStartAction,
  GetEcomUsageSuccessAction,
  GetUserUsageSuccessAction,
  UsageActionType,
} from './usage.actions';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcomUsageService } from '../../service/usage/ecom-usage.service';
import { UserUsageService } from '../../service/usage/user-usage.service';

@Injectable()
export class UsageEffects {
  constructor(
    private actions$: Actions,
    private ecomUsageService: EcomUsageService,
    private userUsageService: UserUsageService
  ) {}

  getEcomUsage = createEffect(() =>
    this.actions$.pipe(
      ofType(UsageActionType.GET_ECOM_USAGE_START),
      switchMap((action: GetEcomUsageStartAction) => this.ecomUsageService.getEcomFeatureUsage(...action.payload)),
      map((usage) => new GetEcomUsageSuccessAction(usage))
    )
  );

  getUserUsage = createEffect(() =>
    this.actions$.pipe(
      ofType(UsageActionType.GET_USER_USAGE_START),
      switchMap(() => this.userUsageService.getUserFeatureUsage()),
      map((usage) => new GetUserUsageSuccessAction(usage))
    )
  );
}
