export const languages: Language[] = [
  { id: 'en', name: 'English' },
  // {id: 'aa', name: 'Afar'},
  // {id: 'ab', name: 'Abkhazian'},
  // {id: 'af', name: 'Afrikaans'},
  // {id: 'am', name: 'Amharic'},
  // {id: 'ar', name: 'Arabic'},
  // {id: 'as', name: 'Assamese'},
  // {id: 'ay', name: 'Aymara'},
  // {id: 'az', name: 'Azerbaijani'},
  // {id: 'ba', name: 'Bashkir'},
  // {id: 'be', name: 'Byelorussian'},
  { id: 'bg', name: 'Bulgarian' },
  // {id: 'bh', name: 'Bihari'},
  // {id: 'bi', name: 'Bislama'},
  // {id: 'bn', name: 'Bengali/Bangla'},
  // {id: 'bo', name: 'Tibetan'},
  // {id: 'br', name: 'Breton'},
  // {id: 'ca', name: 'Catalan'},
  // {id: 'co', name: 'Corsican'},
  { id: 'cs', name: 'Czech' },
  // {id: 'cy', name: 'Welsh'},
  { id: 'da', name: 'Danish' },
  { id: 'de', name: 'German' },
  // {id: 'dz', name: 'Bhutani'},
  { id: 'el', name: 'Greek' },
  // {id: 'eo', name: 'Esperanto'},
  { id: 'es', name: 'Spanish' },
  { id: 'et', name: 'Estonian' },
  // {id: 'eu', name: 'Basque'},
  // {id: 'fa', name: 'Persian'},
  { id: 'fi', name: 'Finnish' },
  // {id: 'fj', name: 'Fiji'},
  // {id: 'fo', name: 'Faeroese'},
  { id: 'fr', name: 'French' },
  // {id: 'fy', name: 'Frisian'},
  // {id: 'ga', name: 'Irish'},
  // {id: 'gd', name: 'Scots/Gaelic'},
  // {id: 'gl', name: 'Galician'},
  // {id: 'gn', name: 'Guarani'},
  // {id: 'gu', name: 'Gujarati'},
  // {id: 'ha', name: 'Hausa'},
  // {id: 'hi', name: 'Hindi'},
  { id: 'hr', name: 'Croatian' },
  { id: 'hu', name: 'Hungarian' },
  // {id: 'hy', name: 'Armenian'},
  // {id: 'ia', name: 'Interlingua'},
  // {id: 'ie', name: 'Interlingue'},
  // {id: 'ik', name: 'Inupiak'},
  // {id: 'in', name: 'Indonesian'},
  // {id: 'is', name: 'Icelandic'},
  { id: 'it', name: 'Italian' },
  // {id: 'iw', name: 'Hebrew'},
  // {id: 'ja', name: 'Japanese'},
  // {id: 'ji', name: 'Yiddish'},
  // {id: 'jw', name: 'Javanese'},
  // {id: 'ka', name: 'Georgian'},
  // {id: 'kk', name: 'Kazakh'},
  // {id: 'kl', name: 'Greenlandic'},
  // {id: 'km', name: 'Cambodian'},
  // {id: 'kn', name: 'Kannada'},
  { id: 'ko', name: 'Korean' },
  // {id: 'ks', name: 'Kashmiri'},
  // {id: 'ku', name: 'Kurdish'},
  // {id: 'ky', name: 'Kirghiz'},
  // {id: 'la', name: 'Latin'},
  // {id: 'ln', name: 'Lingala'},
  // {id: 'lo', name: 'Laothian'},
  { id: 'lt', name: 'Lithuanian' },
  { id: 'lv', name: 'Latvian/Lettish' },
  // {id: 'mg', name: 'Malagasy'},
  // {id: 'mi', name: 'Maori'},
  // {id: 'mk', name: 'Macedonian'},
  // {id: 'ml', name: 'Malayalam'},
  // {id: 'mn', name: 'Mongolian'},
  // {id: 'mo', name: 'Moldavian'},
  // {id: 'mr', name: 'Marathi'},
  // {id: 'ms', name: 'Malay'},
  // {id: 'mt', name: 'Maltese'},
  // {id: 'my', name: 'Burmese'},
  // {id: 'na', name: 'Nauru'},
  // {id: 'ne', name: 'Nepali'},
  { id: 'nl', name: 'Dutch' },
  { id: 'no', name: 'Norwegian' },
  // {id: 'oc', name: 'Occitan'},
  // {id: 'om', name: '(Afan)/Oromoor/Oriya'},
  // {id: 'pa', name: 'Punjabi'},
  { id: 'pl', name: 'Polish' },
  // {id: 'ps', name: 'Pashto/Pushto'},
  { id: 'pt', name: 'Portuguese' },
  // {id: 'qu', name: 'Quechua'},
  // {id: 'rm', name: 'Rhaeto-Romance'},
  // {id: 'rn', name: 'Kirundi'},
  { id: 'ro', name: 'Romanian' },
  { id: 'ru', name: 'Russian' },
  // {id: 'rw', name: 'Kinyarwanda'},
  // {id: 'sa', name: 'Sanskrit'},
  // {id: 'sd', name: 'Sindhi'},
  // {id: 'sg', name: 'Sangro'},
  // {id: 'sh', name: 'Serbo-Croatian'},
  // {id: 'si', name: 'Singhalese'},
  { id: 'sk', name: 'Slovak' },
  { id: 'sl', name: 'Slovenian' },
  // {id: 'sm', name: 'Samoan'},
  // {id: 'sn', name: 'Shona'},
  // {id: 'so', name: 'Somali'},
  // {id: 'sq', name: 'Albanian'},
  // {id: 'sr', name: 'Serbian'},
  // {id: 'ss', name: 'Siswati'},
  // {id: 'st', name: 'Sesotho'},
  // {id: 'su', name: 'Sundanese'},
  { id: 'sv', name: 'Swedish' },
  // {id: 'sw', name: 'Swahili'},
  // {id: 'ta', name: 'Tamil'},
  // {id: 'te', name: 'Tegulu'},
  // {id: 'tg', name: 'Tajik'},
  // {id: 'th', name: 'Thai'},
  // {id: 'ti', name: 'Tigrinya'},
  // {id: 'tk', name: 'Turkmen'},
  // {id: 'tl', name: 'Tagalog'},
  // {id: 'tn', name: 'Setswana'},
  // {id: 'to', name: 'Tonga'},
  { id: 'tr', name: 'Turkish' },
  // {id: 'ts', name: 'Tsonga'},
  // {id: 'tt', name: 'Tatar'},
  // {id: 'tw', name: 'Twi'},
  // {id: 'uk', name: 'Ukrainian'},
  // {id: 'ur', name: 'Urdu'},
  // {id: 'uz', name: 'Uzbek'},
  // {id: 'vi', name: 'Vietnamese'},
  // {id: 'vo', name: 'Volapuk'},
  // {id: 'wo', name: 'Wolof'},
  // {id: 'xh', name: 'Xhosa'},
  // {id: 'yo', name: 'Yoruba'},
  // {id: 'zh', name: 'Chinese'},
  // {id: 'zu', name: 'Zulu'}
];

export interface Language {
  id: string;
  name: string;
}
