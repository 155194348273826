import { Injectable } from '@angular/core';
import { ChannelItem, ChannelUserItem } from './model/chat-models';
import { ChatRoom } from './model/chat-room';

@Injectable({
  providedIn: 'root',
})
export class ChatUtilityService {
  constructor() {}

  public mapChatRooms(rooms: ChatRoom[], sUserId: any): ChannelItem[] {
    return rooms.map((c) => {
      const recipient = c.userAccounts.filter((u) => u.userId !== sUserId)[0];
      const channelName = recipient.name || 'Unknown';
      const recipients = c.userAccounts.map((u) => this.rawUserMapToCUser(u));
      return <ChannelItem>{
        id: c.id,
        name: channelName,
        email: recipient.email,
        lastMessage: c.lastMessage.message,
        lastMessageDate: c.lastMessage.date,
        sUserId: recipient.userId,
        unseenMessageCount: c.unseenMessageCount,
        avatar: recipient.avatar && recipient.avatar.source,
        recipients: recipients,
      };
    });
  }

  private rawUserMapToCUser(rawUser: any): ChannelUserItem {
    return {
      id: rawUser.id,
      sUserId: rawUser.userId,
      name: rawUser.name,
      email: rawUser.email,
      avatar: rawUser.avatar.source,
    };
  }
}
