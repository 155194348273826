export enum SetUpAccountFormInputNames {
  FIRST_NAME = 'firstName',
  CONTACT_EMAIL = 'contactEmail',
  LAST_NAME = 'lastName',
  SUPPLIER_COUNTRY = 'supplierCountry',
  SUPPLIER_PHONE_NUMBER = 'supplierPhoneNumber',
  RETAILER_COUNTRY = 'retailerCountry',
  RETAILER_PHONE_NUMBER = 'retailerPhoneNumber',
  COUNTRY = 'country',
}
