<div class="container" [ngStyle]="containerStyle">
  <img
    *ngIf="!!option.src; else label"
    [src]="option.src"
    width="145px"
    height="85px"
    style="object-fit: cover"
    [alt]="option.title"
  />
</div>

<ng-template #label>
  <div class="typography-body-large-2 text-center p-32 syncee-grey-800-fg">
    {{ option.title }}
  </div>
</ng-template>
