import { Component, Input, OnInit } from '@angular/core';
import { GettingStartedStepBaseService } from '../../service/getting-started-step-base.service';
import { GettingStartedConfig } from '../../model/getting-started-config';
import { StepDataModel } from '../../components/sub-steps-stepper/model/step-data.model';

@Component({
  selector: 'app-getting-started-step-base',
  templateUrl: './getting-started-step-base.component.html',
  styleUrls: ['./getting-started-step-base.component.scss'],
})
export class GettingStartedStepBaseComponent implements OnInit {
  @Input() currentStep: GettingStartedConfig;
  stepTitle: string;
  constructor(private gettingStartedStepBaseService: GettingStartedStepBaseService) {}

  ngOnInit(): void {}

  goToNextStep(skip: boolean = false): void {
    this.gettingStartedStepBaseService.goToNextStep(skip);
  }

  goToPreviousStep(): void {
    this.gettingStartedStepBaseService.goToPreviousStep();
  }

  setCurrentSubStep(stepId: number): void {
    this.gettingStartedStepBaseService.setCurrentSubStep(stepId);
  }

  getCurrentSubStep(): number {
    return this.gettingStartedStepBaseService.getCurrentSubStep();
  }

  incrementCurrentSubStep(index: number): void {
    this.setCurrentSubStep(index + 1);
  }

  decrementCurrentSubStep(index: number): void {
    this.setCurrentSubStep(index - 1);
  }

  emptyCurrentSubStep(): void {
    this.setCurrentSubStep(null);
  }

  initCurrentSubStep(steps: StepDataModel[]): number {
    const initedSubStep = this.getCurrentSubStep();
    if (initedSubStep && initedSubStep <= steps.length) {
      return initedSubStep - 1;
    } else {
      this.setCurrentSubStep(0);
      return 0;
    }
  }

  get hasNextStep(): boolean {
    return this.gettingStartedStepBaseService.hasNextStep();
  }

  get hasPreviousStep(): boolean {
    return this.gettingStartedStepBaseService.hasPreviousStep();
  }

  get isCompleted(): boolean {
    return this.gettingStartedStepBaseService.isCompleted();
  }
}
