<ng-container *ngIf="!item.hidden">

    <!-- item.url -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && !item.marketplaceFilter"
       [routerLink]="['/' + item.url]" [routerLinkActive]="['active']" [queryParams]="item.queryParams"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'" (click)="itemClicked.emit()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'" (click)="itemClicked.emit()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
          (click)="handleFunctionItem()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>


    <!-- item.marketplaceFilter -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.marketplaceFilter && item.queryParams"
          (click)="handleFilter()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && item.function"
       (click)="handleFunctionItem()"
       [routerLink]="['/' + item.url]" [routerLinkActive]="['active']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
       (click)="handleFunctionItem()"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon && !item.icon_type">{{item.icon}}</mat-icon>
        <img class="nav-link-icon" src="assets/icons/{{item.icon_type}}/{{item.icon}}.{{item.icon_type}}" *ngIf="item.icon && item.icon_type"/>
        <span class="nav-link-title" [class.has-suffix-icon]="!!item.suffixIcon">{{item.translate ? (item.translate | translate) : item.title}}</span>
        <a (click)="$event.stopPropagation()" [href]="item.suffixIconUrl" [target]="item.openInNewTab ? '_blank' : '_self'" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon  class="s-20 syncee-grey-300-fg ml-8" *ngIf="!!item.suffixIcon">{{item.suffixIcon}}</mat-icon>
        </a>
        <span class="nav-link-badge" *ngIf="item.badge"
              [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{item.badge.translate ? (item.translate | translate) : item.badge.title}}
        </span>
    </ng-template>

</ng-container>
