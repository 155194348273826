<div class="w-100p">
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-sm="start start"
    fxLayoutGap="8px"
  >
    <h4 class="m-0">{{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS' | translate }}</h4>
    <mat-form-field
      fxFlex="0 0 0"
      fxFlex.lt-sm="1 0 0"
      [ngClass.lt-sm]="'w-100p'"
      appearance="fill"
      class="input-without-padding select-without-label"
    >
      <mat-select (selectionChange)="pageable.sort = $event.value; setDataSource()" [value]="pageable.sort">
        <mat-option [value]="'profit,desc'">{{
          'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.BY_PROFIT' | translate
        }}</mat-option>
        <mat-option [value]="'productsSold,desc'">{{
          'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.BY_PRODUCTS_SOLD' | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="w-100p top-selling-table-container">
    <table mat-table [dataSource]="dataSource" class="w-100p">
      <ng-container matColumnDef="product-image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let product">
          <img class="product-image" src="{{ product.imageUrl }}" alt="product_image" />
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.TITLE' | translate }}
        </th>
        <td class="product-title-col" mat-cell *matCellDef="let product">
          <div fxLayout="column">
            <div class="product-title">{{ product.title }}</div>
            <div class="product-variant-title">{{ product.originalVariantTitle }}</div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="suppiler">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.SUPPLIER' | translate }}
        </th>
        <td mat-cell *matCellDef="let product">{{ product.companyName }}</td>
      </ng-container>
      <ng-container matColumnDef="products-sold">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.PRODUCTS_SOLD' | translate }}
        </th>
        <td mat-cell *matCellDef="let product">{{ product.productsSold }}</td>
      </ng-container>
      <ng-container matColumnDef="profit">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.PROFIT' | translate }}
        </th>
        <td mat-cell *matCellDef="let product">
          {{
            product.profit
              | currencyExchangeTo : selectedEcomCurrency : ecomCurrency
              | async
              | currencyTransform : ecomCurrency
              | async
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="revenue">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.REVENUE' | translate }}
        </th>
        <td mat-cell *matCellDef="let product">
          {{
            product.revenue
              | currencyExchangeTo : selectedEcomCurrency : ecomCurrency
              | async
              | currencyTransform : ecomCurrency
              | async
          }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        class="cursor-pointer"
        *matRowDef="let product; columns: columns"
        [routerLink]="getProductPath(product.synceeId)"
      ></tr>
    </table>
  </div>
  <div
    *ngIf="dataSource && !dataSource.data.length"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="no-data typography-body-1 centered-text"
  >
    {{ 'DASHBOARD.SUPPLIER.TOP_SELLING_PRODUCTS_TABLE.NO_DATA' | translate }}
  </div>
</div>
