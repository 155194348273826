const prefix = 'retailer/';
const billing = 'billing';

export type BillingNavigation = (typeof BillingNavigation)[keyof typeof BillingNavigation];
export const BillingNavigation = {
  DEFAULT: `${prefix}billing`,
  SUBSCRIPTIONS: `${prefix}${billing}/subscription`,
  PLANS: `${prefix}${billing}/plans`,
  CHECKOUT: `${prefix}${billing}/checkout`,
  INVOICES: `${prefix}${billing}/invoices`,
  THANK_YOU: `${prefix}${billing}/thank-you-page`,
  THANK_YOU_WIX: `${prefix}${billing}/wix-thank-you-page`,
  THANK_YOU_WOOCOMMERCE: `${prefix}${billing}/woocommerce-thank-you-page`,
} as const;
