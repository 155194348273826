import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PRICE_RULES } from './import-list-price-rounding-constands';

@Component({
  selector: 'app-import-list-price-rounding',
  templateUrl: './import-list-price-rounding.component.html',
  styleUrls: ['./import-list-price-rounding.component.scss'],
})
export class ImportListPriceRoundingComponent implements OnInit {
  @Output() submitValue = new EventEmitter<string>();

  priceRules = PRICE_RULES;

  @Input() roundingRule: string = this.priceRules[0].rule;
  @Input() hasSaveButton = true;
  @Input() ecomCurrency = 'USD';
  @Input() locked: boolean;

  triggerCalc = new Subject<string>();

  constructor() {}

  ngOnInit(): void {}

  handleRoundingRuleChange(value: string): void {
    this.triggerCalc.next(value);
    if (!this.hasSaveButton) {
      this.submitValue.emit(value);
    }
  }

  submit(): void {
    this.submitValue.emit(this.roundingRule);
  }
}
