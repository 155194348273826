import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProductImageFullCdnPipe, ProductImageThumbnailCdnPipe } from 'app/shared/pipes/product-image-resizer-cdn.pipe';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-product-image-viewer',
  templateUrl: './product-image-viewer.component.html',
  styleUrls: ['./product-image-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    ProductImageThumbnailCdnPipe,
    ProductImageFullCdnPipe,
    MatIconModule,
    MatButtonModule,
  ],
})
export class ProductImageViewerComponent {
  @Input() currentIndex: number;
  @Input() synceeImage = true;
  @Input() imageUrls: string[] = [];

  public isVisible = false;
  env = environment;

  private timeout = null;

  constructor() {}

  @HostListener('click', ['$event'])
  listenToMouseClick(event): void {
    if (event.target.id === 'main-container') {
      this.isVisible = false;
    }
  }

  public open(index: number, images: string[], synceeImage: boolean): void {
    this.isVisible = true;
    this.currentIndex = index;
    this.imageUrls = images;
    this.synceeImage = synceeImage;
  }

  public next(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.currentIndex < this.imageUrls.length - 1) {
        this.currentIndex = this.currentIndex + 1;
      }
    }, 300);
  }

  public previous(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1;
      }
    }, 300);
  }

  public hasNextItem(): boolean {
    return this.currentIndex < this.imageUrls.length - 1;
  }

  public hasPreviousItem(): boolean {
    return this.currentIndex > 0;
  }
}
