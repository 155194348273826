<ng-container *ngFor="let icon of toolbarExtraIcons; let i = index">
  <ng-container [ngSwitch]="icon.type">
    <ng-container *ngSwitchCase="'callback'">
      <button mat-icon-button (click)="icon.payload.callback()">
        <mat-icon
          [badge]="icon.badge?.badge"
          [badgeVariant]="icon.badge?.variant"
          [badgePosition]="icon.badge?.position"
          [svgIcon]="icon.payload.type === 'svg-icon' && icon.icon"
          >{{ icon.payload.type !== 'svg-icon' && icon.icon }}</mat-icon
        >
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'overlay'">
      <button
        mat-icon-button
        (click)="extraOverlayOpenStatuses[i] = !extraOverlayOpenStatuses[i]"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <mat-icon
          [badge]="icon.badge?.badge"
          [badgeVariant]="icon.badge?.variant"
          [badgePosition]="icon.badge?.position"
          >{{ icon.icon }}</mat-icon
        >
      </button>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="extraOverlayOpenStatuses[i]"
        [cdkConnectedOverlayHasBackdrop]="true"
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        (backdropClick)="extraOverlayOpenStatuses[i] = false"
        ><div class="extra-icons-overlay-container custom-elevation-z1">
          <ng-container *ngComponentOutlet="icon.payload.component"></ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
