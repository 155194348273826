<ng-container *ngIf="!!source">
  <div class="image-wrapper">
    <img
      [src]="blobUrl"
      height="128"
      width="100%"
      class="template-image"
      [ngClass]="selected && 'selected'"
      (click)="setSelected.emit(source)"
    />
    <div class="template-radio-wrapper">
      <mat-radio-button [checked]="selected" (click)="setSelected.emit(source)"></mat-radio-button>
    </div>
  </div>
</ng-container>
