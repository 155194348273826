<div>
  <form #settingsForm="ngForm" novalidate>
    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
      <div class="mb-8" *ngIf="isRunning">
        <mat-spinner class="sync-button-spinner" [diameter]="30"></mat-spinner>
      </div>
      <mat-form-field *ngIf="!isRunning" fxFlex="1 0 auto" appearance="fill" class="input-without-padding-except-error">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.SELECT_NODE' | translate }}</mat-label>
        <mat-select
          [disabled]="readonly"
          #fileNodeTree="ngModel"
          id="fileNodeTree"
          name="fileNodeTreeSelect"
          [compareWith]="compareObjects"
          [(ngModel)]="fileNodeTreeSelect"
          required
        >
          <mat-option *ngFor="let node of nodeTree" [value]="node.path">
            <span class="level-{{ node.level }}">{{ node.key }}</span>
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.SELECTING_NODE' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 auto" appearance="fill" class="input-without-padding-except-error">
        <mat-label>{{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.CHARACTER_ENCODING' | translate }}</mat-label>
        <mat-select
          [disabled]="readonly"
          #encoding="ngModel"
          id="encodingSelect"
          name="encodingSelect"
          [(ngModel)]="encodingSelect"
          required
        >
          <mat-option *ngFor="let encoding of characterEncodings" [value]="encoding.name">
            {{ encoding.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'TASKWIZARD.ADDFILES.FILE_SETTINGS.SELECTING_CHARACTER' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
