import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeCustomComponent } from '../../badge-custom/badge-custom.component';
import { PlanBoxAmount } from '../plan-upsell-box.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-plan-upsell-box-pricing',
  templateUrl: './plan-upsell-box-pricing.component.html',
  styleUrls: ['./plan-upsell-box-pricing.component.scss'],
  standalone: true,
  imports: [FlexModule, TranslateModule, BadgeCustomComponent, CommonModule],
})
export class PlanUpsellBoxPricingComponent implements OnChanges {
  @Input() amount: PlanBoxAmount;
  @Input() isAnnual: boolean;
  @Input() trialDays: number;

  protected price: number;
  protected discount: number;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['amount'] && changes['amount']?.currentValue !== undefined) ||
      (changes['isAnnual'] && changes['isAnnual']?.currentValue !== undefined)
    ) {
      const amount = changes['amount']?.currentValue ?? this.amount;
      const isAnnual = changes['isAnnual']?.currentValue ?? this.isAnnual;

      this.price = isAnnual ? amount.annual.perMonth : amount.monthly;
      this.discount = !isAnnual ? 0 : amount.monthly * 12 - amount.annual.perMonth * 12;
    }
  }
}
