import { WeightUnit } from '../../vo/enums/weight-unit';

export class WeightUnitConverter {
  static convertToGram: { [key in WeightUnit]: (value: number) => number } = {
    [WeightUnit.G]: (value) => value,
    [WeightUnit.KG]: (value) => value * 1000,
    [WeightUnit.OZ]: (value) => value * 28.3495,
    [WeightUnit.LB]: (value) => value * 453.592,
  };

  static convertFromGram: { [key in WeightUnit]: (value: number) => number } = {
    [WeightUnit.G]: (value) => value,
    [WeightUnit.KG]: (value) => value * 0.001,
    [WeightUnit.OZ]: (value) => value * 0.035274,
    [WeightUnit.LB]: (value) => value * 0.00220462,
  };
}
