import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class QuantitySelectorComponent implements OnInit {
  @Input() quantity = 1;
  @Input() minQuantity = 1;
  @Input() maxQuantity = 99;
  @Output() quantityChange = new EventEmitter<number>();

  constructor() {}

  decreaseQuantity(): void {
    if (this.quantity <= this.minQuantity) {
      return;
    }
    this.quantity--;
    this.quantityChange.emit(this.quantity);
  }

  increaseQuantity(): void {
    if (this.quantity >= this.maxQuantity) {
      return;
    }
    this.quantity++;
    this.quantityChange.emit(this.quantity);
  }

  ngOnInit(): void {
    if (this.maxQuantity < this.quantity) {
      this.quantity = this.maxQuantity;
      this.quantityChange.emit(this.quantity);
    }
  }
}
