import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { AuthenticationStorage } from 'app/service/authentication/authentication-storage';
import { AuthenticationService } from 'app/service/authentication/authentication.service';
import { BootstrapService } from 'app/service/bootstrap/bootstrap.service';
import { SearchSessionService } from 'app/service/search-session/search-session.service';
import { JwtUtils } from 'app/utils/jwt-utils';
import { LoginVO } from 'app/vo/authentication/login-vo';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TeamService } from '../../service/authentication/team.service';
import { IntercomService } from '../../service/intercom/intercom.service';
import { AdminService } from '../../service/user/admin.service';
import { UserGatewayService } from '../../service/user/user-gateway.service';
import { RemoveSelectedEcom } from '../ecom/ecom.actions';
import { GettingStartedRedirectAction } from '../getting-started/getting-started.actions';
import { UserDetailsChainedToEcomFetch, UserDetailsForceChainedToEcomFetch } from '../user/user.actions';
import {
  AuthActionTypes,
  BasicLogIn,
  LogInFailure,
  LogInSuccess,
  LogoutAction,
  LogoutActionSuccess,
  LogoutActionSuccessDone,
  SocialFacebookLogIn,
  SocialGoogleLogIn,
  SocialRegister,
  UserToUserAuthPayload,
  UserToUserAuthSuccess,
  UserToUserLoginPayload,
  UserToUserLoginStart,
  UserToUserLoginSuccess,
  UserToUserLoginSuccessPayload,
  UserToUserLogoutStart,
  UserToUserLogoutSuccess,
} from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private bootstrapService: BootstrapService,
    private adminService: AdminService,
    private userGatewayService: UserGatewayService,
    private router: Router,
    private teamService: TeamService,
    private searchSessionService: SearchSessionService,
    private intercomService: IntercomService,
    private store: Store<AppState>
  ) {}

  LogIn: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.BASIC_LOGIN),
      map((action: BasicLogIn) => action.payload),
      switchMap((payload) => {
        return this.authService.login({ email: payload.email, password: payload.password, role: payload.role }).pipe(
          map((loginVo) => {
            const parsedData = this.authService.getParsedAuthData(loginVo);
            AuthenticationStorage.storeData(parsedData);
            return parsedData;
          }),
          map((parsedData) => {
            return new LogInSuccess(parsedData);
          }),
          catchError((error) => {
            console.log(error);
            return of(new LogInFailure({ error: error }));
          })
        );
      })
    )
  );

  SocialRegister: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SOCIAL_REGISTER_START),
      map((action: SocialRegister) => action.payload),
      switchMap((payload) => {
        return this.authService
          .socialRegistration({
            email: payload.email,
            userName: payload.userName,
            password: payload.password,
            role: payload.role,
            loginType: payload.loginType,
          })
          .pipe(
            tap((loginVo) => {
              this.saveAuthRespInLocalStorage(loginVo);
            }),
            map((loginVo) => {
              return new LogInSuccess({ ...loginVo, scopes: JwtUtils.getScopes(loginVo.accessToken) });
            }),
            catchError((error) => {
              console.log(error);
              return of(new LogInFailure({ error: error }));
            })
          );
      })
    )
  );

  SocialGoogleLogin: Observable<LogInSuccess | LogInFailure> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.GOOGLE_LOGIN_START),
      map((action: SocialGoogleLogIn) => action.payload),
      switchMap((payload) => {
        return this.authService.socialGoogleLogin(payload.jwt, payload.role).pipe(
          map((loginVo) => {
            const parsedData = this.authService.getParsedAuthData(loginVo);
            AuthenticationStorage.storeData(parsedData);
            return parsedData;
          }),
          map((parsedData) => {
            return new LogInSuccess(parsedData);
          }),
          catchError((error) => {
            console.log(error);
            return of(new LogInFailure({ error: error }));
          })
        );
      })
    )
  );

  SocialFacebookLogin: Observable<LogInSuccess | LogInFailure> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.FACEBOOK_LOGIN_START),
      map((action: SocialFacebookLogIn) => action.payload),
      switchMap((payload) => {
        return this.authService.socialFacebookLogin(payload.code, payload.role).pipe(
          map((loginVo) => {
            const parsedData = this.authService.getParsedAuthData(loginVo);
            AuthenticationStorage.storeData(parsedData);
            return parsedData;
          }),
          map((parsedData) => {
            return new LogInSuccess(parsedData);
          }),
          catchError((error) => {
            console.log(error);
            return of(new LogInFailure({ error: error }));
          })
        );
      })
    )
  );

  LogInSuccess: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.BASIC_LOGIN_SUCCESS),
      tap(() => this.intercomService.shutDownIntercom()),
      tap(() => {
        this.bootstrapService.init();
      }),
      tap(() => {
        this.searchSessionService.patchGuestToUserSession();
      }),
      tap(() => {
        this.store.dispatch(new GettingStartedRedirectAction());
      }),
      map(() => {
        return new UserDetailsChainedToEcomFetch();
      })
    )
  );

  Logout: Observable<LogoutActionSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGOUT),
      map((action: LogoutAction) => action.payload),
      tap(() => {
        this.authService.logoutHandle();
      }),
      map((payload) => new LogoutActionSuccess(payload))
    )
  );

  LogoutSuccess: Observable<LogoutActionSuccessDone> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGOUT_SUCCESS),
      map((action: LogoutAction) => action.payload),
      tap((payload) => {
        const url = payload?.url ?? '/';
        const params = payload?.queryParams ?? undefined;
        const state = payload?.state ?? undefined;
        this.router.navigate([url], { queryParams: params, state: state });
        localStorage.removeItem('loggedInBy');
      }),
      tap(() => {
        this.searchSessionService.createNewGuestSession();
      }),
      map(() => new LogoutActionSuccessDone())
    )
  );

  UserToUserLoginStart: Observable<UserToUserAuthSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.USER_TO_USER_LOGIN_START),
      switchMap((action: UserToUserLoginStart) =>
        this.getUserToUserLoginRequest(action.payload).pipe(
          this.authService.getParsedAuthDataOperator(),
          map((parsedData) => {
            return { ...parsedData, ...action.payload } as UserToUserAuthPayload;
          })
        )
      ),
      map((data) => new UserToUserAuthSuccess(data))
    )
  );

  UserToUserLoginAuthSuccess: Observable<UserToUserLoginSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.USER_TO_USER_AUTH_SUCCESS),
      switchMap((action: UserToUserAuthSuccess) =>
        this.userGatewayService.getUserById(action.payload.userId).pipe(
          map((user) => {
            return {
              user: user,
              navigateTo: action.payload.navigateTo,
              loginType: action.payload.loginType,
            } as UserToUserLoginSuccessPayload;
          })
        )
      ),
      map((data) => new UserToUserLoginSuccess(data))
    )
  );

  UserToUserLoginSuccess: Observable<UserDetailsForceChainedToEcomFetch> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.USER_TO_USER_LOGIN_SUCCESS),
      map((action: UserToUserLoginSuccess) => action.payload),
      tap((data) => this.userToUserLoginSideEffects(data)),
      map(() => new UserDetailsForceChainedToEcomFetch())
    )
  );

  RemoveSelectedEcomOnUserToUserLoginSuccess: Observable<RemoveSelectedEcom> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.USER_TO_USER_LOGIN_SUCCESS),
      map(() => new RemoveSelectedEcom())
    )
  );

  UserToUserLogout: Observable<UserToUserLogoutSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.USER_TO_USER_LOGOUT_START),
      map((action: UserToUserLogoutStart) => new UserToUserLogoutSuccess(action.payload))
    )
  );

  UserToUserLogoutSuccess: Observable<UserDetailsForceChainedToEcomFetch> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.USER_TO_USER_LOGOUT_SUCCESS),
      tap(() => {
        this.userToUserLogoutSideEffects();
      }),
      tap(() => {
        this.router.navigate(['/admin/kiosk']);
      }),
      map(() => new UserDetailsForceChainedToEcomFetch())
    )
  );

  private getUserToUserLoginRequest(loginActionPayload: UserToUserLoginPayload): Observable<LoginVO> {
    return loginActionPayload.loginType === 'ADMIN'
      ? this.authService.loginToUser(loginActionPayload.email)
      : this.teamService.loginToTeamMember(loginActionPayload.email);
  }

  private userToUserLoginSideEffects(data: UserToUserLoginSuccessPayload): void {
    this.authService.removeEcomDataFromLocalStorage();
    this.adminService.selectedUserByAdmin(data.user);
    this.adminService.navigateToUserPageByRole(data.user, data.navigateTo);
    this.authService.clearChatsFromLocalStorage();
    localStorage.setItem('loggedInBy', data.loginType);
  }

  private userToUserLogoutSideEffects(): void {
    this.authService.removeAdminSelectedUserFromSession();
    this.authService.clearChatsFromLocalStorage();
    localStorage.removeItem('loggedInBy');
  }

  private saveAuthRespInLocalStorage(authData: LoginVO): void {
    localStorage.setItem('access_token', authData.accessToken);
    localStorage.setItem('refresh_token', authData.refreshToken);
    localStorage.setItem('expiresIn', JSON.stringify(JwtUtils.getExpiresTimestamp(authData.accessToken)));
  }
}
