<form [formGroup]="form">
  <div class="h4 mb-12">{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.DESTINATION' | translate }}</div>
  <div fxLayoutAlign="start start">
    <!--Destination-->
    <div fxFlex="100" class="pr-20">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.DESTINATION' | translate }}</mat-label>
        <mat-select
          formControlName="destination"
          required
          disableOptionCentering
          panelClass="mat-select-display-change"
          (selectionChange)="handleDestinationChange()"
        >
          <mat-option value="URL">Syncee URL</mat-option>
          <mat-option value="FTP">{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.OWN' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="ftp-conn">
  <app-retailer-export-connect-ftp
    #retailerExportConnectFtpComponent
    [taskID]="currentTaskId"
    *ngIf="destination === 'FTP'"
  ></app-retailer-export-connect-ftp>
</div>

<!--<div *ngIf="destination === 'URL'">{{'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.DOWNLOAD' | translate}} <a href="{{url}}" download>{{url}}</a></div>-->
<copy-to-clipboard
  content="{{ url }}"
  textBefore="{{ 'RETAILER_EXPORT_TASK_WIZARD.DESTINATION.DOWNLOAD' | translate }}"
>
</copy-to-clipboard>

<!--<form *ngIf="destination === 'ftp'" [formGroup]="ftpSettings">-->

<!--<div>-->

<!--<h3>FTP Settings</h3>-->

<!--<div class="settings-group">-->

<!--&lt;!&ndash;Host&ndash;&gt;-->
<!--<mat-form-field appearance="fill">-->
<!--<mat-label>Host</mat-label>-->
<!--<input matInput formControlName="host" required>-->
<!--</mat-form-field>-->

<!--&lt;!&ndash;Port&ndash;&gt;-->
<!--<mat-form-field appearance="fill">-->
<!--<mat-label>Port</mat-label>-->
<!--<input matInput formControlName="port">-->
<!--</mat-form-field>-->

<!--&lt;!&ndash;Root&ndash;&gt;-->
<!--<mat-form-field appearance="fill">-->
<!--<mat-label>Root</mat-label>-->
<!--<input matInput formControlName="root">-->
<!--</mat-form-field>-->

<!--</div>-->

<!--<div class="settings-group">-->

<!--&lt;!&ndash;Username&ndash;&gt;-->
<!--<mat-form-field appearance="fill">-->
<!--<mat-label>Username</mat-label>-->
<!--<input matInput formControlName="username" autocomplete="off" required>-->
<!--</mat-form-field>-->

<!--&lt;!&ndash;Password&ndash;&gt;-->
<!--<mat-form-field appearance="fill">-->
<!--<mat-label>Password</mat-label>-->
<!--<input matInput type="password" formControlName="password" autocomplete="off" required>-->
<!--</mat-form-field>-->

<!--</div>-->

<!--<div class="settings-group">-->
<!--<div class="centered">-->
<!--<mat-checkbox formControlName="passiveMode">Passive mode</mat-checkbox>-->
<!--</div>-->
<!--<div class="centered">-->
<!--<mat-checkbox formControlName="ssl">SSL</mat-checkbox>-->
<!--</div>-->
<!--<div class="centered">-->
<!--<button mat-button color="primary" (click)="connectToFTP()">Connect</button>-->
<!--</div>-->
<!--</div>-->

<!--</div>-->

<!--</form>-->
