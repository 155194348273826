import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmenuComponent } from './submenu.component';
import { SubmenuTabComponent } from './submenu-tab/submenu-tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@NgModule({
  declarations: [SubmenuComponent, SubmenuTabComponent],
  exports: [SubmenuComponent],
  imports: [CommonModule, MatTabsModule, TranslateModule, RouterLink, RouterLinkActive],
})
export class SubmenuModule {}
