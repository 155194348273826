<div class="mapping-table">
  <!--<mat-form-field id="export-type-selector">-->
  <!--<mat-select [(ngModel)]="settings.exportType" [ngModelOptions]="{standalone: true}"-->
  <!--placeholder="Export type" (selectionChange)="exportTypeSelection($event)">-->
  <!--<mat-option value="csv">CSV</mat-option>-->
  <!--<mat-option value="json">JSON</mat-option>-->
  <!--<mat-option value="xml">XML</mat-option>-->
  <!--<mat-option value="xls">XLS</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->
  <div>
    <button mat-button mat-icon-button color="primary" class="addNewCol" (click)="addNewCol()">
      <mat-icon>add</mat-icon>
    </button>
    <div class="table-container">
      <table
        #table
        *ngIf="dataSource"
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropListDropped($event)"
      >
        <ng-container
          *ngFor="let column of columns; let i = index; let last = last"
          [matColumnDef]="getColumnDef(column)"
        >
          <th
            id="{{ i }}"
            mat-header-cell
            *matHeaderCellDef
            cdkDrag
            [cdkDragData]="{ name: column.displayName, columIndex: i }"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div class="drag-drop-place" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div>{{ getDisplayName(column) }}</div>
              <div class="header-action-btns">
                <button mat-icon-button (click)="edit(column)">
                  <mat-icon>settings</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteCol(column)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
            <div class="customPreview" *cdkDragPreview>{{ column.displayName }}</div>
          </th>
          <td valign="top" mat-cell *matCellDef="let row">
            <div class="cell">{{ row[column.keyName + '_' + column.displayName] }}</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
