<div
  fxLayoutAlign="center center"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="22px"
  [fxLayoutGap.lt-lg]="locked ? '12px' : 0"
>
  <mat-form-field
    appearance="fill"
    fxFlex="0 1 240px"
    fxFlex.lt-lg="1 0 0"
    class="select-without-label w-100p-lt-lg"
    [class.mat-form-field-invalid]="hasFieldError"
    [class.input-without-padding]="locked"
  >
    <mat-select
      [placeholder]="'FILTER.FILTER_ITEM.SELECT_FIELD_PLACE' | translate"
      [value]="selectedStructure?.value?.key"
      (valueChange)="handleStructureSelected($event)"
      [disabled]="locked"
    >
      <mat-option *ngFor="let filter of filterStructures" [value]="filter.key">
        {{ filter.name }}
      </mat-option>
    </mat-select>
    <mat-hint class="mat-error" *ngIf="hasFieldError">{{ filterItemError.errors.field.join(' ') }}</mat-hint>
  </mat-form-field>
  <mat-form-field
    appearance="fill"
    fxFlex="0 1 240px"
    fxFlex.lt-lg="1 0 0"
    class="select-without-label w-100p-lt-lg"
    [class.mat-form-field-invalid]="hasConditionError"
    [class.input-without-padding]="locked"
  >
    <ng-container *ngIf="conditionsForSelectedStructure; else noCondition">
      <mat-select
        [placeholder]="'FILTER.FILTER_ITEM.SELECT_CONDITION_PLACE' | translate"
        [value]="condition.value"
        (valueChange)="condition.next($event)"
        [disabled]="locked"
      >
        <mat-option *ngFor="let condition of conditionsForSelectedStructure" [value]="condition.value">
          {{ 'FILTER.FILTER_ITEM.' + condition.value.toUpperCase() | translate }}
        </mat-option>
      </mat-select>
    </ng-container>
    <ng-template #noCondition>
      <mat-select [placeholder]="'FILTER.FILTER_ITEM.SELECT_CONDITION_PLACE' | translate" disabled> </mat-select>
    </ng-template>
    <mat-hint class="mat-error" *ngIf="hasConditionError">{{ filterItemError.errors.condition.join(' ') }}</mat-hint>
  </mat-form-field>
  <div
    fxFlex="1 0 0"
    class="w-100p-lt-lg"
    [matTooltip]="'FILTER.FILTER_ITEM.TOOLTIP' | translate"
    [matTooltipDisabled]="!disableInputOfValue"
    [matTooltipPosition]="'above'"
  >
    <mat-form-field
      appearance="fill"
      class="select-without-label w-100p"
      *ngIf="!existConditions.includes(condition.value); else existCondition"
      [class.mat-form-field-invalid]="hasValueError"
      [class.mat-form-field-disabled]="locked"
      [class.input-without-padding]="locked"
    >
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let chip of chips | async"
          [selectable]="true"
          [removable]="true"
          class="grey-chip"
          (removed)="removedChip(chip)"
        >
          {{ chip }}
          <mat-icon matChipRemove *ngIf="!locked">close</mat-icon>
        </mat-chip>
        <input
          *ngIf="!locked"
          (click)="valueClicked()"
          matInput
          #trigger="matAutocompleteTrigger"
          #searchTermInput
          [placeholder]="disableInputOfValue ? '' : ('FILTER.FILTER_ITEM.FILTER_VALUE' | translate)"
          [disabled]="!selectedStructure?.value || !condition.value || disableInputOfValue"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addChip($event)"
          [matChipInputAddOnBlur]="addOnBlur"
        />
      </mat-chip-list>
      <mat-hint class="mat-error" *ngIf="hasValueError">{{ filterItemError.errors.value.join(' ') }}</mat-hint>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectedAutocomplete($event)"
        (closed)="handleClosed()"
      >
        <mat-option *ngFor="let value of suggestion" [value]="value" [disabled]="chips.value.includes(value)">
          {{ value }}
        </mat-option>
        <div class="load-more mat-option" (click)="handleLoadMore()" *ngIf="hasLoadMore">
          <span fxLayoutAlign="center center" class="mat-option-text">
            {{ 'FILTER.FILTER_ITEM.LOAD_MORE' | translate }}
          </span>
        </div>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <ng-container *ngIf="!locked">
    <button mat-icon-button *showOnBreakpoint="'lt-lg'; variant: 'hide'" (click)="deleteClicked.emit()" class="close">
      <mat-icon class="s-20 item-bottom-margin">close</mat-icon>
    </button>
    <button mat-button *showOnBreakpoint="'lt-lg'" (click)="deleteClicked.emit()" class="close syncee-white-button">
      {{ 'FILTER.FILTER_ITEM.DELETE' | translate }}
    </button>
  </ng-container>
</div>

<ng-template #existCondition>
  <div [class.item-bottom-margin]="!locked" [class.syncee-grey-500-fg]="locked">
    {{ 'FILTER.FILTER_ITEM.THIS_CONDITION' | translate }}
  </div>
</ng-template>
