import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { Observable } from 'rxjs';
import { RestResponse } from '../rest/rest-response';
import { map } from 'rxjs/operators';
import { NotificationService } from '../../main/notification/notification.service';
import { HttpParams } from '@angular/common/http';
import { ScheduleTimeVO } from './taskwizard-update-response';
import { CommentVO } from '../../shared/components/task-comment/task-comment.component';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class TaskwizardService {
  public static DELETE_TASK_FILE_URL = 'TaskService/deleteFile';
  public static PRODUCT_SAMPLE_FIELDS_URL = 'FileProcessService/getSampleProductsFW';
  public static SAVE_MAPPING_URL = 'MappingService/saveMapping';
  public static SUMMARY_DETAILS_URL = 'TaskService/getTaskSummary';
  public static SUMMARY_VU_DETAILS_URL = 'TaskService/getVUTaskSummary';
  public static GET_PREVIEW_PRODUCTS = 'TaskService/preview';

  constructor(private restService: RestService, private notificationService: NotificationService) {}

  deleteTaskFile(taskId, fileId): Observable<boolean> {
    const params = { taskId: taskId, fileId: fileId };
    return this.restService.post(TaskwizardService.DELETE_TASK_FILE_URL, params).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          this.notificationService.success('Delete task file was successful');
          return true;
        } else {
          this.notificationService.error('Error occurred during delete task file');
          return false;
        }
      })
    );
  }

  saveMapping(taskId: number, structure: any, settings: any, runTask = true): Observable<RestResponse> {
    const params = {
      taskId: taskId,
      structure: structure,
      settings: settings,
      runTask: runTask,
    };
    return this.restService.post(TaskwizardService.SAVE_MAPPING_URL, params);
  }

  getPreviewProducts(taskId: number, mapping: string): Observable<any[]> {
    const params = {
      taskId: taskId,
      mapping: mapping,
    };
    return this.restService.post(TaskwizardService.GET_PREVIEW_PRODUCTS, params).pipe(
      map((resp) => {
        return resp.getData();
      })
    );
  }

  getSummaryDetails(taskId: number): Observable<SummaryDetails> {
    return this.restService
      .get(TaskwizardService.SUMMARY_DETAILS_URL, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((rawRes: RestResponse) => {
          const res = rawRes.getFirstData();
          const summDetails = new SummaryDetails();
          summDetails.taskName = res.taskName;
          summDetails.sourceType = res.sourceType;
          summDetails.productCount = res.productCount;
          summDetails.filteredNumber = res.filteredNumber;
          if (!Utils.isNullOrUndefined(res.schedulers)) {
            summDetails.schedulers = new ScheduleTimeVO();
            summDetails.schedulers.type = res.schedulers.type;
            summDetails.schedulers.dates = res.schedulers.dates;
          }
          return summDetails;
        })
      );
  }

  getVUSummaryDetails(taskId: number): Observable<VUSummaryDetails> {
    return this.restService
      .get(TaskwizardService.SUMMARY_VU_DETAILS_URL, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((rawRes: RestResponse) => {
          const res = rawRes.getFirstData();
          const summDetails = new VUSummaryDetails();
          summDetails.taskName = res.taskName;
          summDetails.sourceType = res.sourceType;
          summDetails.variantsCount = res.variantsCount;
          if (!Utils.isNullOrUndefined(res.schedulers)) {
            summDetails.schedulers = new ScheduleTimeVO();
            summDetails.schedulers.type = res.schedulers.type;
            summDetails.schedulers.dates = res.schedulers.dates;
          }
          return summDetails;
        })
      );
  }

  resetMapping(taskId): Observable<RestResponse> {
    return this.saveMapping(taskId, {}, { mappingTemplate: null });
  }

  saveComment(commentVO: CommentVO): Observable<any> {
    return this.restService
      .post('TaskService/saveCommentForTask', commentVO)
      .pipe(map((response) => response.getFirstData()));
  }
}

export class VUSummaryDetails {
  taskName: string;
  sourceType: string;
  variantsCount: number;
  schedulers: ScheduleTimeVO;
}

export class SummaryDetails {
  taskName: string;
  sourceType: string;
  productCount: number;
  filteredNumber: number;
  schedulers: ScheduleTimeVO;
}

export interface ProductField {
  name: string;
  value: string;
}
