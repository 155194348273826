import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../app.state';
import { take, takeUntil } from 'rxjs/operators';
import { BootstrapService } from '../../../../../../service/bootstrap/bootstrap.service';
import { FilterSelectorOption } from '../../../filter-selector/filter-selector.component';
import { CategoryService } from '../../../../../../service/product-search/category.service';
import { SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { CategoryVo } from '../../../../../../vo/category-vo';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-categories-filter',
  templateUrl: './categories-filter.component.html',
  styleUrls: ['./categories-filter.component.scss'],
})
export class CategoriesFilterComponent implements OnInit {
  readonly ROOT_CATEGORY_ID = 1;
  selectedCategory: CategoryVo;
  selectedCategoryId: number;
  selectedCategoryForHeader: CategoryVo;
  parentCategory: CategoryVo;
  categoryTree: CategoryVo;
  options: FilterSelectorOption<number, string>[] = [];
  private categories$: Observable<CategoryVo>;
  private unsubscribeAll: Subject<void>;
  private readonly currentLang: string;

  constructor(
    private store: Store<AppState>,
    private bootstrapService: BootstrapService,
    private categoryService: CategoryService,
    private translateService: TranslateService
  ) {
    this.categories$ = this.store.pipe(
      select((state) => state.categories),
      select((state) => state.categories)
    );
    this.unsubscribeAll = new Subject();
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.subscribeToBootstrapCategories();
  }

  handleCategorySelected(categoryId: number): void {
    const cat = this.categoryService.searchInCategories(this.categoryTree, categoryId);
    if (!Utils.isNullOrUndefined(cat.children) && cat.children.length !== 0) {
      this.handleCategoryWithChildrenSelected(cat);
    } else {
      this.handleCategoryWithoutChildrenSelected(cat);
    }
  }

  handleBackToClicked(): void {
    this.handleCategorySelected(Number(this.parentCategory.id));
  }

  handleViewAllClicked(): void {
    this.handleCategorySelected(Number(this.selectedCategoryForHeader.id));
    this.emitTemporaryFilter();
  }

  getCategoryName(category: CategoryVo): string {
    return this.categoryService.getNameForCategory(category, this.currentLang);
  }

  private subscribeToBootstrapCategories(): void {
    this.bootstrapService.categoriesStored.pipe(takeUntil(this.unsubscribeAll)).subscribe((isStored) => {
      if (isStored) {
        this.getCategories();
      }
    });
  }

  private getCategories(): void {
    this.categories$.pipe(take(1)).subscribe((categories) => {
      this.categoryTree = categories;
      this.handleCategorySelected(Number(categories.id));
    });
  }

  private handleCategoryWithoutChildrenSelected(category: CategoryVo): void {
    this.selectedCategory = category;
    this.selectedCategoryId = Number(category.id);
    this.emitTemporaryFilter();
  }

  private handleCategoryWithChildrenSelected(category: CategoryVo): void {
    this.selectedCategory = category;
    this.selectedCategoryId = Number(category.id);
    this.selectedCategoryForHeader = category;
    this.parentCategory = this.getParentCategory();
    this.options = this.mapCategoriesToOptions(category);
  }

  private mapCategoriesToOptions(categoryTree: CategoryVo): FilterSelectorOption<number, string>[] {
    return categoryTree.children.map((category) => ({
      value: this.getCategoryName(category),
      key: Number(category.id),
    }));
  }

  private emitTemporaryFilter(): void {
    this.store.dispatch(new SetTemporaryFilter({ value: { category: Number(this.selectedCategory.id) } }));
  }

  private getParentCategory(): CategoryVo {
    if (Number(this.selectedCategory.id) === 1) {
      return null;
    }
    return this.categoryService.searchInCategories(this.categoryTree, Number(this.selectedCategory.parent));
  }
}
