<div class="sidebar-selector" *ngIf="!!selectedCategory; else skeleton">
  <ul>
    <li>
      <span
        *ngIf="selectedCategory.id !== 1"
        class="category-item cursor-pointer"
        [ngClass]="{ 'category-item-active h6': selectedCategory.id === topLevelParentCategory.id }"
        (click)="handleCategoryChange(topLevelParentCategory)"
        >{{ topLevelParentCategory.id | categoryNameById | async }}</span
      >
      <ul>
        <ng-container
          *ngTemplateOutlet="Recursion; context: { topChildrenCategories: topLevelParentCategory.children }"
        >
        </ng-container>
      </ul>
    </li>
  </ul>
</div>

<ng-template #Recursion let-topChildrenCategories="topChildrenCategories">
  <li *ngFor="let childCategory of topChildrenCategories">
    <span
      class="category-item cursor-pointer"
      [ngClass]="{ 'category-item-active h6': selectedCategory.id === childCategory.id }"
      (click)="handleCategoryChange(childCategory)"
      >{{ childCategory.id | categoryNameById | async }}</span
    >
    <ul *ngIf="selectedCategoryPath.includes(childCategory.id) && childCategory.children.length > 0">
      <ng-container
        *ngTemplateOutlet="Recursion; context: { topChildrenCategories: childCategory.children }"
      ></ng-container>
    </ul>
  </li>
</ng-template>

<ng-template #skeleton>
  <div fxLayout="column" fxLayoutGap="12px">
    <app-skeleton-text [lines]="1" style="width: min(190px, 40%)"></app-skeleton-text>
    <app-skeleton-text [lines]="1" style="width: min(210px, 45%)"></app-skeleton-text>
    <app-skeleton-text [lines]="1" style="width: min(100px, 20%)"></app-skeleton-text>
    <app-skeleton-text [lines]="1" style="width: min(150px, 30%)"></app-skeleton-text>
  </div>
</ng-template>
