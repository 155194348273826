import { Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MapInputData } from '../../../../../../service/taskwizard/taskwizard-update-response';
import { DroppableInputComponent } from '../../../../../droppable-input/droppable-input.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Dimensions } from '../../../../../../utils/Constants';
import { DimWrapper } from '../../../../../droppable-input/chip';
import { ProdSampleInputVo } from '../../../vo/prod-sample-input-vo';
import { Utils } from 'app/utils/utils';
import { DimSettings } from '../../../../../../service/pim/pim.service';
import { DimensionsSettingsComponent } from '../../../../../pim/settings/dimensions-settings.component';

@Component({
  selector: 'app-dimension-fields',
  templateUrl: './dimension-fields.component.html',
  styleUrls: ['./dimension-fields.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DimensionFieldsComponent implements OnInit {
  dimSettings: DimSettings = { dimUnit: '', dimensions: Dimensions };

  @Input() taskId;
  @Input() comments;
  @Input() widthChip: MapInputData = null;
  @Input() heightChip: MapInputData = null;
  @Input() lengthChip: MapInputData = null;
  @Input() prodSampleInputVo: ProdSampleInputVo;

  @ViewChildren('normalProcess') private inputsNormalProcess: QueryList<DroppableInputComponent>;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    const dimSetting: DimWrapper = this.widthChip.settings.find((setting) => setting instanceof DimWrapper);
    if (!Utils.isNullOrUndefined(dimSetting)) {
      this.dimSettings.dimUnit = dimSetting.dimUnit;
    }
  }

  public getDimensionsInputComponents(): QueryList<DroppableInputComponent> {
    this.setDimSettingsIntoChips();
    return this.inputsNormalProcess;
  }

  public openDimSettingsModal(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = Object.assign({}, this.dimSettings);
    dialogConfig.width = '20%';

    const dialogRef = this.dialog.open(DimensionsSettingsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (!Utils.isNullOrUndefined(result)) {
        this.dimSettings = result;
      }
    });
  }

  private setDimSettingsIntoChips(): void {
    this.inputsNormalProcess.forEach((chipInput) => {
      chipInput.inputOperationsWithChips.settings = chipInput.inputOperationsWithChips.settings.filter(
        (setting) => !(setting instanceof DimWrapper)
      );
      chipInput.inputOperationsWithChips.settings.push(new DimWrapper(this.dimSettings.dimUnit));
    });
  }
}
