import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { SearchSessionMapperService } from 'app/service/search-session/search-session-mapper.service';
import { SearchSessionService } from 'app/service/search-session/search-session.service';
import { AddFilterWithRedirectAction } from 'app/store/product-search/product-search.action';
import { catchError, filter, finalize, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
@Component({
  selector: 'app-home-recent-searches',
  templateUrl: './home-recent-searches.component.html',
  styleUrls: ['./home-recent-searches.component.scss'],
})
export class HomeRecentSearchesComponent implements OnInit, OnDestroy {
  searchTerms: string[] = [];
  hasSessionData: boolean;
  isLoading: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private store: Store<AppState>,
    private searchSessionMapperService: SearchSessionMapperService,
    private searchSessionService: SearchSessionService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getSessionData();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getSessionData(): void {
    this.searchSessionService.searchData$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap(() => (this.isLoading = true)),
        filter((data) => !!data && data.length > 0),
        tap((data) => {
          this.hasSessionData = data.some((x) => x.search_datas.length > 0);
        }),
        switchMap((sessionData) => this.searchSessionMapperService.getSearchTerms(sessionData)),
        catchError(() => of(null)),
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res) => {
        this.searchTerms = res;
        this.isLoading = false;
      });
  }

  handleChipClicked(searchTerm: string): void {
    this.store.dispatch(
      new AddFilterWithRedirectAction({ needRedirect: true, skipLocationChange: true, filter: { search: searchTerm } })
    );
  }
}
