import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginGtmAction } from 'app/service/google-tag-manager/actions/login';
import { GtmManagerService } from 'app/service/google-tag-manager/gtm-manager.service';
import { RequestError } from 'app/service/rest/request-error';
import { RoleTypesEnum } from 'app/vo/roles/roles';
import { AuthenticationDialogResponse } from '../../helpers/authentication-dialog-response';
import { LoginDialogComponent } from '../../login/login-dialog/login-dialog.component';
import { ConfirmPasswordValidator, StrongPasswordValidator } from '../../validators/password.validator';
import { RegistrationDialogComponent } from '../registration-dialog/registration-dialog.component';
import { AuthenticationMethodType } from '../../../../service/google-tag-manager/model/variables/general.variables';

@Component({
  selector: 'app-registration-password-form',
  templateUrl: './registration-password-form.component.html',
  styleUrls: ['./registration-password-form.component.scss'],
})
export class RegistrationPasswordFormComponent implements OnInit, OnDestroy {
  @Input() role: RoleTypesEnum = RoleTypesEnum.RETAILER;
  RETAILER = RoleTypesEnum.RETAILER;
  SUPPLIER = RoleTypesEnum.SUPPLIER;

  @Output() submitClick = new EventEmitter<void>();

  private _form: FormGroup;

  @Output() formChange = new EventEmitter<FormGroup>();

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  public submitEnabled = true;

  constructor(
    private _fb: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<RegistrationDialogComponent, AuthenticationDialogResponse>,
    private gtmManagerService: GtmManagerService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, StrongPasswordValidator]],
      passwordConfirm: ['', [Validators.required, ConfirmPasswordValidator]],
      checkedTerms: [false, Validators.requiredTrue],
    });
    this.formChangeListener();
  }

  successSociaLoginHandle(loginType: AuthenticationMethodType): void {
    this.dialogRef.close({ success: true });
    // this.routerService.navigate(['/']);
    this.gtmManagerService.pushTag(new LoginGtmAction({ authentication_method: loginType }));
  }

  submitHandle(): void {
    if (!this.form.valid) {
      return;
    }
    this.submitClick.emit();
  }

  private enableSubmit(): void {
    this.submitEnabled = true;
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get passwordConfirm(): AbstractControl {
    return this.form.get('passwordConfirm');
  }

  get checkedTerms(): AbstractControl {
    return this.form.get('checkedTerms');
  }

  formChangeListener(): void {
    this.form.valueChanges.subscribe(() => {
      this.formChange.emit(this.form);
    });
  }

  handleLoginError(error?: RequestError): void {
    this.enableSubmit();
  }

  ngOnDestroy(): void {
    this.submitClick.unsubscribe();
  }
}
