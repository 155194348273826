import { Action } from '@ngrx/store';

export enum BootstrapActionTypes {
  USER_DATA_BOOTSTRAP_START = '[Bootstrap] Start User Data Bootstrap',
  USER_DATA_BOOTSTRAP_SUCCESS = '[Bootstrap]User Data Bootstrap Success',
}

export class UserDataBootstrapStart implements Action {
  readonly type = BootstrapActionTypes.USER_DATA_BOOTSTRAP_START;
  constructor() {}
}

export class UserDataBootstrapSuccess implements Action {
  readonly type = BootstrapActionTypes.USER_DATA_BOOTSTRAP_SUCCESS;
  constructor() {}
}

export type USER_DATA_BOOTSTRAP_ALL_TYPES = UserDataBootstrapStart | UserDataBootstrapSuccess;
