<div class="w-100p h-100-p" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
  <app-sub-steps-stepper
    #stepperComponent
    [steps]="steps"
    [defaultStep]="currentStepIndex"
    (stepChange)="handleSubStepChange($event)"
  ></app-sub-steps-stepper>
  <div
    fxFlex="1 0 0"
    class="w-100p personalization-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
  >
    <ng-container *conditionLoading="!isLoading">
      <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
        <app-step-title-description
          [title]="stepTitles[currentStepIndex].title | translate"
          [description]="stepTitles[currentStepIndex].description | translate"
          [privateDetails]="true"
        ></app-step-title-description>
        <ng-container [ngSwitch]="currentStepIndex">
          <div
            class="w-100p"
            [style.max-width]="contentMaxWidth"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="32px"
          >
            <ng-container *ngSwitchCase="0">
              <app-set-up-account-form
                class="w-100p"
                *conditionLoading="value$ | async as value"
                [value]="value"
                [accountType]="[rolesEnum.SUPPLIER]"
                #setUpAccountFormComponent
              ></app-set-up-account-form>
            </ng-container>
            <ng-container *ngSwitchCase="1">
              <div class="w-100p" fxLayout="column" fxLayoutGap="20px">
                <app-user-feedback
                  class="w-100p"
                  #userFeedbackComponent
                  *conditionLoading="bestDescribesCustomSource$ | async as value"
                  [customOptions]="value"
                  [group]="'BUSINESS_DETAILS_SUPPLIER'"
                >
                </app-user-feedback>

                <div fxLayout="column" fxLayoutGap="12px">
                  <h5>
                    {{
                      'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.BUSINESS_DETAILS.PHYSICAL_INVENTORY'
                        | translate
                    }}
                  </h5>

                  <mat-form-field
                    appearance="fill"
                    class="w-100p select-without-label input-without-padding-except-error"
                  >
                    <mat-select
                      [value]="hasOwnInventory"
                      (selectionChange)="handleOwnPhysicalInventoryChange($event.value)"
                      [placeholder]="'GETTING_STARTED.INPUT_LABELS_PLACEHOLDERS.PHYSICAL_INVENTORY_SELECT' | translate"
                    >
                      <mat-option [value]="true"
                        >{{
                          'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.BUSINESS_DETAILS.PHYSICAL_INVENTORY_YES'
                            | translate
                        }}
                      </mat-option>
                      <mat-option [value]="false">
                        {{
                          'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION_SUPPLIER.BUSINESS_DETAILS.PHYSICAL_INVENTORY_NO'
                            | translate
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <app-step-navigation-buttons
      [hasPrevButton]="currentStepIndex > 0"
      (prevClick)="prevClicked()"
      (nextClick)="nextClicked()"
    ></app-step-navigation-buttons>
  </div>
</div>
