import { FileUploadDownloadInputVo } from './file-upload-download-input-vo';

export class SharedUrlUploadInputVo extends FileUploadDownloadInputVo {
  private _restUrl: string;

  get restUrl(): string {
    return this._restUrl;
  }

  set restUrl(value: string) {
    this._restUrl = value;
  }
}
