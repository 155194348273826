import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge-custom',
  templateUrl: 'badge-custom.component.html',
  styleUrls: ['badge-custom.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BadgeCustomComponent implements OnInit {
  constructor() {}

  @Input() badgeSize: BadgeSize = 'md';
  @Input() text: string;
  @Input() bgColor: string;
  @Input() textColor: string;
  @Input() borderRadius = '100px';

  ngOnInit(): void {}
}

export type BadgeSize = 'sm' | 'md' | 'lg' | 'xl';

export interface BadgeProps {
  badgeSize: BadgeSize;
  text: string;
  bgColor: string;
  textColor: string;
  borderRadius: string;
}
