import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-chip-with-autocomplete',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatAutocompleteModule, ReactiveFormsModule, MatIconModule, FlexLayoutModule],
  providers: [{ provide: MatFormFieldControl, useExisting: ChipWithAutocompleteComponent }],
  host: {
    '[id]': 'id',
  },
  templateUrl: './chip-with-autocomplete.component.html',
  styleUrls: ['./chip-with-autocomplete.component.scss'],
})
export class ChipWithAutocompleteComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<any>
{
  // Implementing properties
  static nextId = 0;
  form: FormGroup<{
    items: FormControl<ChipWithAutocompleteItem[] | null>;
  }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'app-chip-with-autocomplete';
  id = `app-chip-with-autocomplete-${ChipWithAutocompleteComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty(): boolean {
    return (
      !this.form.get('items').value ||
      (Array.isArray(this.form.get('items').value) && this.form.get('items').value.length === 0)
    );
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input() userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    if (this._required) {
      this.form.get('items').setValidators(Validators.required);
    }
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): ChipWithAutocompleteItem[] | null {
    if (this.form.get('items').valid) {
      return this.form.get('items').value;
    }
    return null;
  }
  set value(items: ChipWithAutocompleteItem[] | null) {
    this.form.get('items').patchValue(items || []);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched;
  }

  constructor(
    private formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.form = formBuilder.group({
      items: [null],
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.chip-with-autocomplete-container');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(): void {
    if (this.form.get('items').valid) {
      this._focusMonitor.focusVia(this.itemInput, 'program');
    }
  }

  writeValue(items: ChipWithAutocompleteItem[] | null): void {
    this.value = items;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // Properties for logic
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  @Input()
  idProperty: string;

  @Input()
  nameProperty: string;

  @Input()
  items: any[];

  @Input()
  separatorKeysCodes: number[] = [ENTER, COMMA];

  selectedItems: ChipWithAutocompleteItem[] = [];

  selectableItems: ChipWithAutocompleteItem[] = [];

  ngOnInit(): void {
    this.form.get('items').valueChanges.subscribe((value) => {
      this.selectedItems = value;
      this.setSelectableItems();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.items) {
      this.initItems();
      this.setSelectableItems();
    }
  }

  private initItems(): void {
    if (!!this.items) {
      this.items = this.items.map((item) => {
        return { id: item[this.idProperty], name: item[this.nameProperty].toLowerCase() };
      });
    }
  }

  private setSelectableItems(): void {
    if (!this.items || !this.form.get('items')) {
      return;
    }
    this.selectableItems = !!this.form.get('items').value
      ? this.items.filter(
          (item) => this.form.get('items').value.filter((selectedItem) => selectedItem.id === item.id).length === 0
        )
      : this.items;
  }

  filterItems(): void {
    if (this.itemInput.nativeElement.value !== '') {
      this.selectableItems = this.selectableItems.filter(
        (item) =>
          item.name.includes(this.itemInput.nativeElement.value.toLowerCase()) &&
          !this.form.get('items').value.includes(item)
      );
    } else {
      this.setSelectableItems();
    }
  }

  removePlatform(itemToRemove: any): void {
    this.form.get('items').patchValue(this.form.get('items').value.filter((item) => item.id !== itemToRemove.id));
    this.onChange(this.value);
    this.itemInput.nativeElement.value = '';
  }

  itemSelected(event: MatAutocompleteSelectedEvent): void {
    const matchingPlatform = this.items.find((item) => item.id === event.option.value);
    this.form.get('items').patchValue([...this.value, matchingPlatform]);
    this.onChange(this.value);
    this.itemInput.nativeElement.value = '';
  }
}

export interface ChipWithAutocompleteItem {
  id: number;
  name: string;
}
