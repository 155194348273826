import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dimensions, WeightUnits } from '../../../utils/Constants';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CatalogFormVO } from '../../../vo/catalog-form-vo';
import { MappingSettingsService } from '../../../service/mapping-settings-service/mapping-settings.service';
import { CatalogService } from '../../../service/catalog/catalog.service';
import { forkJoin, Observable } from 'rxjs';
import { RetailerCatalogSettingsVO } from '../../marketplace/retailer-catalogs/retailer-catalog-details/retailer-catalog-settings/abstarct-retailer-settings';
import { SHOPRENTER_UPDATE_FIELDS } from '../../marketplace/retailer-catalogs/retailer-update-catalog-dialog.component';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-shoprenter-settings',
  templateUrl: './shoprenter-settings.component.html',
  styleUrls: ['./shoprenter-settings.component.scss'],
})
export class ShoprenterSettingsComponent implements OnInit {
  fieldsToUpdate: any[];
  optionFieldsUpdate: any[] = [];

  fieldsNotToUpdate: any[] = [];

  selectedItemIds: any[];
  fulfillmentServices: any[];
  locations: any[];
  shopData: any = {};
  weightUnit: string;
  dimensions = Dimensions;
  weightUnits = WeightUnits;
  settingsForm: UntypedFormGroup;

  stocks = [
    { id: 1, name: 'Stock 1' },
    { id: 2, name: 'Stock 2' },
    { id: 3, name: 'Stock 3' },
    { id: 4, name: 'Stock 4' },
  ];

  @Input() retailerSettingsVO: RetailerCatalogSettingsVO;
  @Input() taskId: number;
  @Input() showSaveButton = true;
  @Input() rcatalogId: number;
  @Input() comments: any;
  @Input() supplierVO: CatalogFormVO;
  @Output() voChanged: EventEmitter<RetailerCatalogSettingsVO> = new EventEmitter<RetailerCatalogSettingsVO>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mappingSettingsService: MappingSettingsService,
    private catalogService: CatalogService
  ) {}

  ngOnInit(): void {
    this.getFulfillmentServices();
    forkJoin([
      this.getLanguages(),
      this.getTaxes(),
      this.getStockStatuses(),
      this.getVolumeUnits(),
      this.getCurrencies(),
      this.getShopData(),
    ]).subscribe((results) => {
      this.shopData['languages'] = results[0];
      this.shopData['taxes'] = results[1];
      this.shopData['stockStatuses'] = results[2];
      this.shopData['weights'] = results[3].weightClasses;
      this.shopData['lengths'] = results[3].lengthClasses;
      this.shopData['currencies'] = results[4];
      this.shopData['settings'] = results[5];
      this.stocks = this.stocks.filter((item) => item.id <= this.shopData['settings']['config_stock_count'].value);
      this.createForm();
      return true;
    });
  }

  protected getShopData(): Observable<any> {
    return this.catalogService.getShopData(null, this.taskId);
  }

  getLanguages(): Observable<any> {
    return this.catalogService.getLanguages(this.rcatalogId);
  }

  getTaxes(): Observable<any> {
    return this.catalogService.getTaxes(this.rcatalogId);
  }

  getStockStatuses(): Observable<any> {
    return this.catalogService.getStockStatuses(this.rcatalogId);
  }

  getVolumeUnits(): Observable<any> {
    return this.catalogService.getVolumeUnits(this.rcatalogId);
  }

  getCurrencies(): Observable<any> {
    return this.catalogService.getCurrencies(this.rcatalogId);
  }

  private subscribeToFormValueChange(): void {
    this.settingsForm.valueChanges.subscribe(() => {
      this.updateChanges();
    });
  }

  private createForm(): void {
    this.loadFieldsToUpdateOptions();
    this.catalogService.getRetailerCatalogSettings(this.rcatalogId).subscribe((data) => {
      const stockFirst = Utils.isNullOrUndefined(this.shopData['stockStatuses'])
        ? ''
        : this.shopData['stockStatuses'][0].id;
      this.settingsForm = this.formBuilder.group({
        settings: this.formBuilder.group({
          currency: [
            Utils.isNullOrUndefined(this.shopData['currencies'])
              ? ''
              : this.shopData['currencies'].find(
                  (item) => item.code === this.shopData['settings']['config_currency'].value
                ).id,
          ],
          published: [1],
          taxes: [Utils.isNullOrUndefined(this.shopData['taxes']) ? '' : this.shopData['taxes'][0].id],
          backorder: ['supplierDefault'],
          languages: [Utils.isNullOrUndefined(this.shopData['languages']) ? '' : this.shopData['languages'][0].id],
          freeShipping: [false],
          orderable: [1],
          subtractStock: [false],
          weights: [Utils.isNullOrUndefined(this.shopData['weights']) ? '' : this.shopData['weights'][0].id],
          lengths: [Utils.isNullOrUndefined(this.shopData['lengths']) ? '' : this.shopData['lengths'][0].id],
          noStockStatus: [stockFirst],
          inStockStatus: [stockFirst],
          stockSettings: [this.stocks[0].id],
          defaultQtyValue: [9],
          stock1: [stockFirst],
          stock2: [stockFirst],
          stock3: [stockFirst],
          stock4: [stockFirst],
          duplicateMainImage: [false],
        }),
        updateOptions: this.formBuilder.group({
          updateType: ['CRUD_DF_MODE'],
          sendEmail: 'dont',
          productNotInFeed: 'delete',
          productQuantity0: 'qty0',
          variantQtyUpload: 'dont',
        }),
      });

      if (data) {
        this.controls['settings'].patchValue(data);
        this.controls['updateOptions'].patchValue(data);
        this.fieldsToUpdate.forEach((item) => {
          if (data.fieldsToUpdate.includes(item.id)) {
            item.checked = true;
          }
        });
        this.optionFieldsUpdate.forEach((item) => {
          if (data.optionFieldsUpdate.includes(item.id)) {
            item.checked = true;
          }
        });
        this.selectedItemIds = this.fieldsToUpdate.filter((item) => item.checked).map((item) => item.id);
      }
      setTimeout(() => this.updateChanges(), 0);
      this.subscribeToFormValueChange();
    });
  }

  private loadFieldsToUpdateOptions(): void {
    this.fieldsToUpdate = SHOPRENTER_UPDATE_FIELDS;
  }

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};

  updateChanges(): void {
    this.onChange(this.settingsForm.value);
  }

  writeValue(value: any): void {
    if (!Utils.isNullOrUndefined(value)) {
      this.settingsForm.reset(value);
      this.updateChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getRetailerSettingsVO(): RetailerCatalogSettingsVO {
    this.retailerSettingsVO = new RetailerCatalogSettingsVO();
    this.retailerSettingsVO.id = this.rcatalogId;
    // this.settingsVO.updateOptions = this.settingsForm.get('updateOptions').value;
    // tslint:disable-next-line: max-line-length
    // this.settingsVO.updateOptions.fieldsToUpdate = this.fieldsToUpdate.filter(item => item.checked).map(item => item.id);
    this.retailerSettingsVO.settings = {
      ...this.settingsForm.get('settings').value,
      ...this.settingsForm.get('updateOptions').value,
    };
    delete this.retailerSettingsVO.settings.backorder;
    delete this.retailerSettingsVO.settings.shipping;
    this.retailerSettingsVO.settings.fieldsToUpdate = this.fieldsToUpdate
      .filter((item) => item.checked)
      .map((item) => item.id);
    this.retailerSettingsVO.settings.optionFieldsUpdate = this.optionFieldsUpdate
      .filter((item) => item.checked)
      .map((item) => item.id);

    return this.retailerSettingsVO;
  }

  public getFulfillmentServices(): void {
    this.mappingSettingsService.getFulfillmentServicesByTaskId(this.taskId).subscribe((data) => {
      if (!Utils.isNullOrUndefined(data)) {
        this.fulfillmentServices = data.fulfillment_services;
      }
    });
  }

  private getLocations(): void {
    this.mappingSettingsService.getLocations(this.rcatalogId).subscribe((data) => {
      this.locations = data;
      this.createForm();
      this.subscribeToFormValueChange();
    });
  }

  isStepValid(): boolean {
    return true;
  }

  public getSupplierSettingsVO(): CatalogFormVO {
    const settings = this.settingsForm.get('settings').value;
    this.supplierVO = new CatalogFormVO();
    this.supplierVO.id = this.taskId;
    this.supplierVO.settings = {
      backorder: settings.backorder,
      shipping: settings.shipping,
    };

    return this.supplierVO;
  }

  get controls(): { [key: string]: AbstractControl<any, any> } {
    return this.settingsForm.controls;
  }

  saveStep(): Observable<any> {
    const vo = this.getRetailerSettingsVO();
    this.voChanged.emit(vo);
    this.mappingSettingsService.saveRetailerCatalogSettings(vo).subscribe();

    return this.catalogService.updateCatalog(this.getSupplierSettingsVO());
  }
}
