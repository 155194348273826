export class SettingsOutputVo {
  private _fileType: string;
  private _settings: any;

  get fileType(): string {
    return this._fileType;
  }

  set fileType(value: string) {
    this._fileType = value;
  }

  get settings(): any {
    return this._settings;
  }

  set settings(value: any) {
    this._settings = value;
  }

  constructor(fileType: string, settings: any) {
    this.fileType = fileType;
    this.settings = settings;
  }
}
