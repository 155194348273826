export type TaskType = 'shopify_df' | 'jumpseller_df' | 'shoprenter_df' | 'ecwid_df' | 'shopify_uv' | 'jumpseller_uv';

export const URL_BY_TASK_TYPE: Record<TaskType, string> = {
  shopify_df: 'retailer-import-tasks/retailer-task-wizard',
  jumpseller_df: 'retailer-import-tasks/jumpseller-df-task-wizard',
  ecwid_df: 'retailer-import-tasks/ecwid-df-task-wizard',
  shoprenter_df: 'retailer-import-tasks/shoprenter-df-task-wizard',
  shopify_uv: '/retailer-variants-update-tasks/retailer-variants-update-task-wizard',
  jumpseller_uv: '/retailer-jumpseller-vu-task-wizard',
};
