import { googleTagManagerId } from '../../../environments/environment';
import { GtmManagerService } from './gtm-manager.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { VirtualPageViewGtmAction } from './actions/virtual_page_view';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class InitGtmService {
  private navigationEnd$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => event as NavigationEnd)
  );

  constructor(private gtmManagerService: GtmManagerService, private router: Router, private route: ActivatedRoute) {}

  private getRouteSnapshot(url: string): ActivatedRouteSnapshot | null {
    let child = this.route.firstChild;
    let concatenatedPath = '';
    while (child) {
      if (!isEmpty(child.snapshot.url)) {
        child.snapshot.url.forEach((segment) => {
          if (segment.path !== '') {
            concatenatedPath = concatenatedPath.concat('/', segment.path);
          }
        });
      }
      if (child.firstChild) {
        child = child.firstChild;
      } else if (
        '/' + child.snapshot.url.map((snapShotUrl) => snapShotUrl.path).join('/') === url ||
        (!!child.snapshot.data &&
          !!child.snapshot.data['cleanRoute'] &&
          '/' + child.snapshot.data['cleanRoute'] === url) ||
        concatenatedPath === url
      ) {
        return child.snapshot;
      } else {
        return null;
      }
    }
    return null;
  }

  private sendPageViewActionOnNavigation(event: NavigationEnd): void {
    const urlWithoutQueryParams = event.urlAfterRedirects.split('?')[0];
    const snapshot = this.getRouteSnapshot(urlWithoutQueryParams);
    const cleanRoute = snapshot?.data['cleanRoute'];
    const pageTitle = snapshot?.data['pageTitle'];
    this.gtmManagerService.pushTag(
      new VirtualPageViewGtmAction({
        cleaned_page_url:
          !!cleanRoute || cleanRoute === ''
            ? `${location.origin}/${cleanRoute}`
            : `${location.origin}${event.urlAfterRedirects}`,
        page_title: pageTitle,
      })
    );
  }

  private initNavigationEndSubscription(): void {
    this.navigationEnd$.pipe(untilDestroyed(this)).subscribe((event: NavigationEnd): void => {
      this.sendPageViewActionOnNavigation(event);
    });
  }

  public initGtm(): void {
    switch (true) {
      case Utils.isNullOrUndefinedOrLengthZero(googleTagManagerId):
        return;
      default: {
        this.navigationEnd$
          .pipe(
            take(1),
            tap((event: NavigationEnd) => this.sendPageViewActionOnNavigation(event)),
            switchMap(() => this.gtmManagerService.addGtmToDom().pipe(take(1))),
            filter((value: boolean) => value === true)
          )
          .subscribe((): void => {
            this.initNavigationEndSubscription();
          });
      }
    }
  }
}
