import { AfterViewInit, Directive, HostBinding, Inject } from '@angular/core';
import { fromEvent, Observable, pairwise, throttleTime } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { LayoutService } from '../../layout.service';

@Directive({
  selector: '[stickyOnScroll]',
})
export class StickyOnScrollDirective implements AfterViewInit {
  @HostBinding('style.position') private position = 'fixed';
  @HostBinding('style.width') private width = '100%';
  @HostBinding('style.z-index') private zIndex = 9999;
  @HostBinding('style.top.px') private get top(): number {
    return this.isVisible ? 0 : -300;
  }
  @HostBinding('style.transition') private get transition(): string {
    return this.isVisible ? '400ms cubic-bezier(0.1, 0.7, 0.6, 1)' : '400ms linear';
  }
  private isVisible = true;
  private readonly SCROLL_OFFSET_PX = 300;

  constructor(@Inject(DOCUMENT) private document: Document, private layoutService: LayoutService) {}

  ngAfterViewInit(): void {
    this.direction$().subscribe((direction) => this.handleDirection(direction));
  }

  private direction$(): Observable<Position> {
    const elem = this.getOuterContainer();
    return fromEvent(elem, 'scroll').pipe(
      throttleTime(100, undefined, { leading: true, trailing: true }),
      map(() => elem.scrollTop),
      pairwise(),
      map(([y1, y2]) => (y2 < y1 ? { direction: Direction.UP, offset: y1 } : { direction: Direction.DOWN, offset: y1 }))
    );
  }

  private handleDirection(position: Position): void {
    const visible =
      position.direction === Direction.UP || position.offset <= this.SCROLL_OFFSET_PX || position.offset === 0;
    this.layoutService.setStickyHeaderVisible(visible);
    this.isVisible = visible;
  }

  private getOuterContainer(): Element {
    return this.document.getElementsByClassName('outer-container')[0];
  }
}

enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
}

interface Position {
  direction: Direction;
  offset: number;
}
