import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateConditionallyPipe } from '../../pipes/translate-conditionally.pipe';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { CapitalisePipe } from '../../pipes/capitalise.pipe';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { DataListingDialogConfig } from '../dialogs/data-listing-dialog/data-listing-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-data-listing',
  templateUrl: './data-listing.component.html',
  styleUrls: ['./data-listing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateConditionallyPipe,
    FlexModule,
    CustomDividerComponent,
    TranslateModule,
    CapitalisePipe,
  ],
})
export class DataListingComponent implements OnInit {
  @Input() items: DataListingItem[];
  @Input() config: Partial<DataListingDialogConfig>;
  ltMd: boolean;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.getLtMd().subscribe((matches) => (this.ltMd = matches));
  }

  private getLtMd(): Observable<boolean> {
    return this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), untilDestroyed(this));
  }
}

export interface DataListingItem {
  key: string;
  value: string;
}
