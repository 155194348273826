<ng-container *ngIf="variant === 'default'; else button">
  <a
    class="contact-user"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    hasPermissionButton
    [needsPermission]="recipientUserRole === RolesEnum.SUPPLIER"
    [actionType]="CHAT_ACCESS"
    [ecom]="ecom$ | async"
    (click)="handleOpenContactUserDialog($event)"
  >
    <ng-container [ngSwitch]="recipientUserRole">
      <span *ngSwitchCase="RolesEnum.RETAILER" class="contact-user-label">{{
        'CONTACT_USER.CONTACT_RETAILER' | translate
      }}</span>
      <span *ngSwitchCase="RolesEnum.SUPPLIER" class="contact-user-label">{{
        'CONTACT_USER.CONTACT_SUPPLIER' | translate
      }}</span>
      <span *ngSwitchDefault></span>
    </ng-container>

    <mat-icon class="s-20 chat-icon">chat_bubble_outline</mat-icon>
  </a>
</ng-container>

<ng-template #button>
  <button
    mat-button
    class="syncee-white-button"
    (click)="handleOpenContactUserDialog($event)"
    hasPermissionButton
    [needsPermission]="recipientUserRole === RolesEnum.SUPPLIER"
    [actionType]="CHAT_ACCESS"
    [ecom]="ecom$ | async"
    [class.w-100p]="fullWidth"
    buttonWithIcon="chat_bubble_outline"
  >
    {{
      (recipientUserRole === RolesEnum.RETAILER ? 'CONTACT_USER.CONTACT_RETAILER' : 'CONTACT_USER.CONTACT_SUPPLIER')
        | translate
    }}
  </button>
</ng-template>
