import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestResponse } from '../rest/rest-response';
import { Observable } from 'rxjs';
import { RestService } from '../rest/rest.service';
import { NotificationService } from '../../main/notification/notification.service';
import { UrlUploadVo } from '../../main/taskwizard/addfiles/vo/url-upload-vo';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  public static GOOGLE_DRIVE_UPLOAD_URL = 'TaskFileDownloadService/downloadGoogleDriveFile';
  public static DROPBOX_UPLOAD_URL = 'TaskFileDownloadService/downloadDropBoxFile';

  constructor(private restService: RestService, private notificationService: NotificationService) {}

  urlDownload(restApiUrl: string, urlUploadParams: UrlUploadVo): Observable<UrlUploadVo> {
    return this.restService.post(restApiUrl, urlUploadParams).pipe(
      map((res: RestResponse) => {
        if (res.isSuccess()) {
          const result = res.getFirstData();
          const urlUploadVo = new UrlUploadVo();
          urlUploadVo.fileId = result.pmsFile.fileId;
          urlUploadVo.remotePath = result.pmsFile.remotePath;
          urlUploadVo.extension = result.detectedType;
          return urlUploadVo;
        } else {
          this.notificationService.error('Error occurred during download file');
          return null;
        }
      })
    );
  }
}
