import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { map } from 'rxjs/operators';
import { CategoryName, CategoryVo } from '../../vo/category-vo';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flattenCategoriesSelector } from '../../store/category/category.selector';
import { omitNullOrUndefinedOrEmpty } from '../../utils/operator/omit-null-or-undefined-or-empty';

@UntilDestroy()
@Pipe({
  name: 'categoryNameById',
  standalone: true,
})
export class CategoryNameByIdPipe implements PipeTransform {
  private language$ = new BehaviorSubject<string>(this.translateService?.currentLang ?? 'en');

  private flattenCategories$ = this.store.select(flattenCategoriesSelector).pipe(omitNullOrUndefinedOrEmpty());

  constructor(private store: Store<AppState>, private translateService: TranslateService) {
    this.initLanguageChangeSubscription();
  }

  private initLanguageChangeSubscription(): void {
    this.translateService.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((change: LangChangeEvent): void => this.language$.next(change.lang));
  }

  public transform(categoryId: number): Observable<string> {
    return combineLatest([this.flattenCategories$, this.language$]).pipe(
      map(([categories, language]: [CategoryVo[], string]) => [
        categories.find((item: CategoryVo): boolean => item.id === categoryId),
        language,
      ]),
      map(([category, language]: [CategoryVo, string]): string => {
        return (
          category.categoryName.find((categoryName: CategoryName): boolean => categoryName.languageCode === language)
            ?.name ??
          category.categoryName.find(
            (categoryName: CategoryName): boolean => categoryName.languageCode === this.translateService.defaultLang
          ).name
        );
      })
    );
  }
}
