<div class="w-100-p">
  <ng-container *ngIf="!!images && !!images?.length">
    <div
      class="modal-scroll-viewport"
      infiniteScroll
      [infiniteScrollDistance]="4"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="handleIncrementPagination()"
      [infiniteScrollDisabled]="hasError || loading"
    >
      <app-image-selector-image
        *ngFor="let item of images"
        class="item"
        [source]="item"
        [selected]="item === selected"
        (setSelected)="setSelected.emit(item)"
      ></app-image-selector-image>

      <div class="spinner-container" *ngIf="!hasError">
        <app-custom-spinner *ngIf="loading && !!images && !!images?.length"></app-custom-spinner>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading && (!images || !images?.length)">
    <div class="modal-scroll-viewport">
      <app-skeleton-image
        *ngFor="let item of [].constructor(10)"
        class="skeleton-item"
        [borderRadius]="'8px'"
        [width]="'100%'"
        [height]="'128px'"
      ></app-skeleton-image>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && (!images || !images?.length)">
    <app-no-search-results
      titleKey="IMAGE_TEMPLATES.EMPTY.TITLE"
      descriptionKey="IMAGE_TEMPLATES.EMPTY.DESCRIPTION"
    ></app-no-search-results>
  </ng-container>
</div>
