import { Component, Input, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { SupplierLogoFromUseridPipe } from '../../pipes/supplier-logo-from-userid.pipe';

@Component({
  selector: 'app-supplier-icon',
  templateUrl: './supplier-icon.component.html',
  styleUrls: ['./supplier-icon.component.scss'],
  imports: [NgStyle, SupplierLogoFromUseridPipe],
  standalone: true,
})
export class SupplierIconComponent implements OnInit {
  @Input()
  size: string;
  @Input()
  supplierUserId: number;
  @Input()
  supplierCompanyName: string;
  errorPath: string;
  private readonly FALLBACK_IMAGE_PATH = 'assets/images/placeholder-image-50x50.png';

  constructor() {}

  ngOnInit(): void {}

  handleError(): void {
    this.errorPath = this.FALLBACK_IMAGE_PATH;
  }
}
