import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NotificationService } from '../../notification/notification.service';
import { UntypedFormControl, NgModel } from '@angular/forms';
import { SchedueServiceService } from '../../../service/taskwizard/schedue-service.service';
import { TaskwizardUpdateService } from '../../../service/taskwizard/taskwizard-update.service';
import { ScheduleTimeVO } from '../../../service/taskwizard/taskwizard-update-response';
import { Router } from '@angular/router';
import { Constants, SchedulerConstants, USER_ROLES } from '../../../utils/Constants';
import { StepperService } from '../../../service/taskwizard/stepper.service';
import { StepBase } from '../step-base';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UserRole } from '../../kiosk/user-management/user-management.component';
import { ExportTaskwizardUpdateService } from '../../../service/taskwizard/export-taskwizard-update.service';
import { TranslateService } from '@ngx-translate/core';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit, StepBase, AfterViewInit {
  @ViewChildren('dateTimes') private dailyTimePickers: QueryList<NgModel>;
  @Input() sourceType: string;
  @Input() taskId: number;
  @Input() isExport = false;

  @ViewChild('weeklyScheduleInput', { static: false }) weeklyScheduleInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoWeekly', { static: false }) autoWeekly: MatAutocomplete;

  @ViewChild('monthlyScheduleInput', { static: false }) monthlyScheduleInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoMonthly', { static: false }) autoMonthly: MatAutocomplete;

  @ViewChild('dailyScheduleInput', { static: false }) dailyScheduleInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoDaily', { static: false }) autoDaily: MatAutocomplete;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  scheduleHolders: any;

  autoRunDate: Date;

  selectedSchedule = 'daily';

  SCHEDULER_CONSTANTS = SchedulerConstants;
  FILE_UPLOAD_SOURCE_TYPE = Constants.FILE_UPLOAD_CONNECTION_TYPE;
  isFreePlan = false;

  constructor(
    private notification: NotificationService,
    private saveSchedule: SchedueServiceService,
    private taskwUpdateService: TaskwizardUpdateService,
    private etaskwUpdateService: ExportTaskwizardUpdateService,
    private mEcomService: MarketplaceEcomService,
    private stepper: StepperService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.isFreePlan = mEcomService.hasSubscription() && mEcomService.isDataFeedStarterPlan();
    this.scheduleHolders = {
      daily: new ScheduleSelectObject(this.isFreePlan, this.onNotifyCallback.bind(this)),
      weekly: new ScheduleSelectObject(this.isFreePlan, this.onNotifyCallback.bind(this)),
      monthly: new ScheduleSelectObject(this.isFreePlan, this.onNotifyCallback.bind(this)),
    };
  }

  onNotifyCallback(not: string): void {
    this.notification.warning(this.translate.instant(not));
  }

  schedules: any[] = [
    { display: this.translate.instant('TASKWIZARD.SCHEDULE.MANUALLY'), value: this.SCHEDULER_CONSTANTS.MANUALLY },
    { display: this.translate.instant('TASKWIZARD.SCHEDULE.AUTO_RUN'), value: this.SCHEDULER_CONSTANTS.AUTO_RUN },
    { display: this.translate.instant('TASKWIZARD.SCHEDULE.DAILY'), value: this.SCHEDULER_CONSTANTS.DAILY },
    { display: this.translate.instant('TASKWIZARD.SCHEDULE.WEEKLY'), value: this.SCHEDULER_CONSTANTS.WEEKLY },
    { display: this.translate.instant('TASKWIZARD.SCHEDULE.MONTHLY'), value: this.SCHEDULER_CONSTANTS.MONTHLY },
  ];

  weekly_schedules: WeeklySchedule[] = [
    <WeeklySchedule>{ key: 1, display: this.translate.instant('TASKWIZARD.SCHEDULE.MONDAYS') },
    <WeeklySchedule>{ key: 2, display: this.translate.instant('TASKWIZARD.SCHEDULE.TUESDAYS') },
    <WeeklySchedule>{ key: 3, display: this.translate.instant('TASKWIZARD.SCHEDULE.WEDNESDAYS') },
    <WeeklySchedule>{ key: 4, display: this.translate.instant('TASKWIZARD.SCHEDULE.THURSDAYS') },
    <WeeklySchedule>{ key: 5, display: this.translate.instant('TASKWIZARD.SCHEDULE.FRIDAYS') },
    <WeeklySchedule>{ key: 6, display: this.translate.instant('TASKWIZARD.SCHEDULE.SATURDAYS') },
    <WeeklySchedule>{ key: 0, display: this.translate.instant('TASKWIZARD.SCHEDULE.SUNDAYS') },
  ];

  public openAutoCompletePanel(trigger: MatAutocompleteTrigger): void {
    trigger._onChange('');
    trigger.openPanel();
  }

  ngOnInit(): void {
    let updateService = null;
    if (this.isExport) {
      updateService = this.etaskwUpdateService;
    } else {
      updateService = this.taskwUpdateService;
    }

    if (updateService.isUpdate && updateService.data.isScheduling()) {
      this.initToUpdate(updateService.data.scheduling);
    } else {
      switch (localStorage.getItem('actualRole')) {
        case USER_ROLES.SUPPLIER.toString():
          if (this.sourceType != this.FILE_UPLOAD_SOURCE_TYPE) {
            this.selectedSchedule = SchedulerConstants.AUTO_RUN;
          } else {
            this.selectedSchedule = SchedulerConstants.MANUALLY;
          }
          break;
        case USER_ROLES.AGENT.toString():
          this.selectedSchedule = SchedulerConstants.MANUALLY;
          break;
        default:
          this.selectedSchedule = SchedulerConstants.MANUALLY;
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    this.scheduleHolders['weekly'].defaultScheduleList = this.weekly_schedules.map((item) => item.display);
    this.scheduleHolders['weekly'].matAutocomplete = this.autoWeekly;
    this.scheduleHolders['weekly'].scheduleInput = this.weeklyScheduleInput;

    const days = [];
    for (let i = 1; i <= 31; ++i) {
      days.push(i.toString());
    }
    this.scheduleHolders['monthly'].defaultScheduleList = days;
    this.scheduleHolders['monthly'].matAutocomplete = this.autoMonthly;
    this.scheduleHolders['monthly'].scheduleInput = this.monthlyScheduleInput;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const times = [];
    do {
      times.push(`${today.toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit' })}`);
      today.setMinutes(today.getMinutes() + 15);
    } while (today.getMinutes() !== 0 || today.getHours() !== 0);
    this.scheduleHolders['daily'].defaultScheduleList = times;
    this.scheduleHolders['daily'].matAutocomplete = this.autoDaily;
    this.scheduleHolders['daily'].scheduleInput = this.dailyScheduleInput;
  }

  initToUpdate(scheduleTimeVO: ScheduleTimeVO): void {
    this.selectedSchedule = scheduleTimeVO.type;
    switch (this.selectedSchedule) {
      case SchedulerConstants.DAILY:
        for (const dateStr of scheduleTimeVO.dates) {
          const date = new Date(dateStr);
          this.scheduleHolders.daily.selectedSchedules.push(
            `${date.toLocaleTimeString('hu-HU', {
              hour: '2-digit',
              minute: '2-digit',
            })}`
          );
        }
        break;
      case SchedulerConstants.WEEKLY:
        for (const dateStr of scheduleTimeVO.dates) {
          const dayNum = new Date(dateStr).getDay();
          this.scheduleHolders.weekly.selectedSchedules.push(
            this.weekly_schedules.find((item) => item.key === dayNum).display
          );
        }
        break;
      case SchedulerConstants.MONTHLY:
        for (const dateStr of scheduleTimeVO.dates) {
          const dayNum = new Date(dateStr).getDate();
          this.scheduleHolders.monthly.selectedSchedules.push(dayNum);
        }
        break;
      case SchedulerConstants.AUTO_RUN:
        this.autoRunDate = new Date(scheduleTimeVO.dates[0]);
        break;
    }
  }

  isStepValid(): boolean {
    return true;
  }

  saveStep(): Observable<any> {
    const scheduleTimeReq = new ScheduleTimeVO();
    scheduleTimeReq.taskId = this.taskId;
    switch (this.selectedSchedule) {
      case SchedulerConstants.DAILY:
        scheduleTimeReq.type = SchedulerConstants.DAILY;
        for (const dailySchedule of this.scheduleHolders.daily.selectedSchedules) {
          const today = new Date();
          const time = dailySchedule.split(':');
          today.setHours(time[0], time[1]);
          scheduleTimeReq.dates.push(today.toISOString());
        }
        break;
      case SchedulerConstants.WEEKLY:
        scheduleTimeReq.type = SchedulerConstants.WEEKLY;
        for (const weeklySchedule of this.scheduleHolders.weekly.selectedSchedules) {
          const currSchedule = this.weekly_schedules.find((item) => item.display === weeklySchedule);
          const currDate = new Date();
          const currentDay = currDate.getDay();
          const distance = currSchedule.key - currentDay;
          currDate.setDate(currDate.getDate() + distance);
          scheduleTimeReq.dates.push(currDate.toISOString());
        }
        break;
      case SchedulerConstants.MONTHLY:
        scheduleTimeReq.type = SchedulerConstants.MONTHLY;
        for (const montlySchedule of this.scheduleHolders.monthly.selectedSchedules) {
          if (montlySchedule > 28) {
            this.notification.warning(
              'If you choose this day of the month, your task might not run every month of the year!'
            );
          }
          const currDate = new Date();
          currDate.setMonth(0, montlySchedule);
          scheduleTimeReq.dates.push(currDate.toISOString());
        }
        break;
      case SchedulerConstants.AUTO_RUN:
        scheduleTimeReq.type = SchedulerConstants.AUTO_RUN;
        break;
      case SchedulerConstants.MANUALLY:
        scheduleTimeReq.type = SchedulerConstants.MANUALLY;
        break;
    }
    if (!this.isExport) {
      return this.saveSchedule.saveSchedule(scheduleTimeReq);
    } else {
      return this.saveSchedule.saveExportSchedule(scheduleTimeReq);
    }
  }
}

export abstract class WeeklySchedule {
  key: number;
  display: string;
}

export class ScheduleSelectObject {
  defaultScheduleList: string[] = [];
  scheduleCtrl = new UntypedFormControl();
  filteredSchedules: Observable<string[]>;
  matAutocomplete: MatAutocomplete;
  selectedSchedules: string[] = [];
  scheduleInput: ElementRef<HTMLInputElement>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isValueSelected = true;
  isFreePlan = false;
  notifyCallback: (not: string) => void;

  constructor(isFreePlan = false, notifyCallback: (not: string) => void) {
    this.notifyCallback = notifyCallback;
    this.filteredSchedules = this.scheduleCtrl.valueChanges.pipe(
      startWith(null),
      map((schedule: string | null) => this.filterSchedules(schedule))
    );
    this.isFreePlan = isFreePlan;
  }

  private filterSchedules(schedule: string): any {
    return schedule
      ? this._filter(schedule)
      : this.defaultScheduleList.filter((currentSchedule) => !this.selectedSchedules.includes(currentSchedule)).slice();
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      if (input) {
        input.value = '';
      }
      this.scheduleCtrl.setValue(null);
    }
  }

  private openAutocompletePanel(trigger: MatAutocompleteTrigger): void {
    setTimeout(() => {
      trigger._onChange('');
      trigger.openPanel();
    }, 300);
  }

  remove(schedule: string, trigger: MatAutocompleteTrigger): void {
    const index = this.selectedSchedules.indexOf(schedule);

    if (index >= 0) {
      this.selectedSchedules.splice(index, 1);
    }
    if (this.selectedSchedules.length === 0) {
      this.isValueSelected = true;
    }

    trigger._onChange('');
  }

  selected(event: MatAutocompleteSelectedEvent, trigger: MatAutocompleteTrigger): void {
    if (this.isFreePlan && this.selectedSchedules.length > 0) {
      this.notifyCallback('TASKWIZARD.SCHEDULE.START_PLAN_NOTI');
      this.scheduleCtrl.setValue(null);
      return;
    }
    this.isValueSelected = false;
    this.selectedSchedules.push(event.option.viewValue);
    this.scheduleInput.nativeElement.value = '';
    this.scheduleCtrl.setValue(null);
    this.openAutocompletePanel(trigger);
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.defaultScheduleList.filter((schedule) => schedule.toLowerCase().indexOf(filterValue) === 0);
  }
}
