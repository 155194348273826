import { Component, Input, OnInit } from '@angular/core';
import { TaskVoExtended } from '../tasks.component';

@Component({
  selector: 'app-task-badges',
  templateUrl: './task-badges.component.html',
  styleUrls: ['./task-badges.component.scss'],
})
export class TaskBadgesComponent implements OnInit {
  @Input() task: TaskVoExtended;

  constructor() {}

  ngOnInit(): void {}
}
