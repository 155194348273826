import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestResponse } from '../rest/rest-response';
import { map } from 'rxjs/operators';
import { Constants } from '../../utils/Constants';
import { ConnectionStateService } from './connection-state.service';
import { FtpConnectionStateVo } from '../../main/taskwizard/addfiles/vo/ftp-connection-state-vo';
import {
  MapInputData,
  Mapping,
  ProdAttributes,
  ProdIds,
  ProdImages,
  ScheduleTimeVO,
  Task,
  TaskFile,
  TaskFilters,
  TaskwizardUpdateResponse,
} from './taskwizard-update-response';
import {
  ConstantChip,
  CurrencyWrapper,
  DimWrapper,
  EvalWrapper,
  FieldOperation,
  FieldSetting,
  FillTheEmptyWrapper,
  HPCCWrapper,
  LocationWrapper,
  ReplacerWrapper,
  SplitterWrapper,
  VariableChip,
  WeightWrapper,
} from '../../main/droppable-input/chip';
import { Utils } from '../../utils/utils';
import { FilterItem } from '../../main/filter/filter-item/filter-item.component';
import { FilterBox } from '../../main/filter/filter-box';
import { isObject, isString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TaskwizardUpdateService {
  public static EXISTING_TASK_DATA_URL = 'TaskService/getTaskDetails';

  private _isUpdate = false;
  private _data: TaskwizardUpdateResponse = null;
  private chipColors;

  get data(): TaskwizardUpdateResponse {
    return this._data;
  }

  set data(value: TaskwizardUpdateResponse) {
    this._data = value;
  }

  set isUpdate(value: boolean) {
    this._isUpdate = value;
  }

  get isUpdate(): boolean {
    return this._isUpdate;
  }

  constructor(private restService: RestService, private ftpConnState: ConnectionStateService) {}

  init(taskId: number): Observable<TaskwizardUpdateResponse> {
    const params = new HttpParams().set('taskId', taskId.toString());

    return this.restService.get(TaskwizardUpdateService.EXISTING_TASK_DATA_URL, params).pipe(
      map((res: RestResponse) => {
        const result = res.getFirstData();
        this.ftpConnState.currentState = null;
        if (result.task.sourceType === Constants.FTP_CONNECTION_TYPE) {
          const ftpConResult = result.ftpConnection;
          const connFtpStateVO = new FtpConnectionStateVo();
          connFtpStateVO.host = ftpConResult.host;
          connFtpStateVO.port = ftpConResult.port;
          connFtpStateVO.username = ftpConResult.username;
          connFtpStateVO.password = ftpConResult.password;
          connFtpStateVO.passive = ftpConResult.passive;
          connFtpStateVO.isSsl = ftpConResult.isSsl;
          connFtpStateVO.root = ftpConResult.root;
          this.ftpConnState.currentState = connFtpStateVO;
        }
        const response = new TaskwizardUpdateResponse();
        const taskResult = result.task;
        const task = new Task();
        task.taskId = taskResult.taskId;
        task.userId = taskResult.userId;
        task.name = taskResult.name;
        task.sourceType = taskResult.sourceType;
        task.pid = taskResult.pid;
        task.date = taskResult.date;
        task.isDownloadRunning = !Utils.isNullOrUndefined(result['isDownloadRunning'])
          ? result['isDownloadRunning']
          : null;
        response.task = task;
        response.ecomId = result.ecomId;

        const files = new Array<TaskFile>();

        this.chipColors = {};
        result.files.forEach((resultFile, index) => {
          const file = new TaskFile();

          file.fileId = resultFile.fileId;
          file.taskId = resultFile.taskId;
          file.extension = resultFile.extension;
          file.remotepath = resultFile.remotePath;

          file.settings = JSON.parse(resultFile.settings);
          file.nodeTree = resultFile.nodeTree;
          file.date = resultFile.date;
          files.push(file);
          this.chipColors[file.fileId] = {
            color: Constants.CHIP_COLORS[index],
            backgroundColor: Constants.CHIP_BACKGROUND_COLORS[index],
            iconColor: Constants.CHIP_ICON_COLORS[index],
            iconActiveColor: Constants.CHIP_ICON_COLORS_ACTIVE[index],
          };
          // this.chipColors[file.fileId] = Constants.CHIP_BACKGROUND_COLORS[index];
        });

        response.files = files;
        this.data = response;

        if (!Utils.isNullOrUndefined(result.mapping)) {
          console.log(result.mapping);
          const mapping = new Mapping();
          response.mapping = mapping;
          if (!Utils.isNullOrUndefined(result.mapping.settings)) {
            const mappingSettings = JSON.parse(result.mapping.settings);
            if (
              !Utils.isNullOrUndefined(mappingSettings) &&
              !Utils.isNullOrUndefined(mappingSettings.mappingTemplate)
            ) {
              mapping.settings = { variantsOption: mappingSettings.mappingTemplate.variantsOption };
            }
          }
          const imagesUrl = new Array<MapInputData>();
          const imagesAlts = new Array<MapInputData>();
          const respMapping = result.mapping.structure;
          for (const key of Object.keys(respMapping)) {
            if (key.toString().match(/^ID_(.*)/)) {
              for (const fieldItem of respMapping[key]) {
                const fileId = Number(key.replace('ID_', ''));
                const filePlaceholder = files.find((value) => {
                  return value.fileId === fileId;
                });
                const placeholder =
                  Utils.isNullOrUndefined(filePlaceholder) || Utils.isNullOrUndefined(filePlaceholder.remotepath)
                    ? ''
                    : Utils.getFileNameFromPath(filePlaceholder.remotepath);
                mapping.productIds.push(<ProdIds>{
                  id: fileId,
                  value: this.mapMapInputData(fieldItem),
                  placeholder: placeholder,
                });
              }
            } else {
              switch (key) {
                case 'ATTRIBUTES':
                  for (const attribute of respMapping[key]) {
                    mapping.attributes.push(<ProdAttributes>{
                      name: this.mapMapInputData(attribute.name),
                      value: this.mapMapInputData(attribute.value),
                    });
                  }
                  break;
                case 'TAGS':
                  for (const attribute of respMapping[key]) {
                    mapping.tags.push(this.mapMapInputData(attribute));
                  }
                  break;
                case 'QTIES':
                  for (const qty of respMapping[key]) {
                    mapping.quantities.push(this.mapMapInputData(qty));
                  }
                  break;
                case 'QTIES_INC_DEC':
                  for (const qty of respMapping[key]) {
                    mapping.quantitiesIncDec.push(this.mapMapInputData(qty));
                  }
                  break;
                case 'IMAGES':
                  for (const image of respMapping[key]) {
                    imagesUrl.push(this.mapMapInputData(image));
                  }
                  break;
                case 'IMAGES_ALTS':
                  for (const imageAlt of respMapping[key]) {
                    imagesAlts.push(this.mapMapInputData(imageAlt));
                  }
                  break;
                default:
                  if (['WIDTH', 'HEIGHT', 'LENGTH'].includes(key) && mapping.dimSettings.length < 1) {
                    mapping.dimSettings = Utils.isNullOrUndefined(respMapping[key][0].settings)
                      ? []
                      : respMapping[key][0].settings.map((setting) => this.mapFieldSettings(setting));
                  }
                  for (const fieldMapData of respMapping[key]) {
                    mapping.singleFields[key] = this.mapMapInputData(fieldMapData);
                  }
                  break;
              }
            }
          }

          const imgsAraysMaxLength = imagesUrl.length > imagesAlts.length ? imagesUrl.length : imagesAlts.length;

          for (let i = 0; i < imgsAraysMaxLength; ++i) {
            if (i < imagesUrl.length && i < imagesAlts.length) {
              mapping.images.push(<ProdImages>{ url: imagesUrl[i], value: imagesAlts[i] });
            } else if (i >= imagesUrl.length && i < imagesAlts.length) {
              mapping.images.push(<ProdImages>{ url: this.mapMapInputData(null), value: imagesAlts[i] });
            } else {
              mapping.images.push(<ProdImages>{ url: imagesUrl[i], value: this.mapMapInputData(null) });
            }
          }

          if (mapping.quantities.length < 1) {
            mapping.addNewQty();
          }

          if (mapping.quantitiesIncDec.length < 1) {
            mapping.addNewQtyIncDec();
          }

          if (mapping.tags.length < 1) {
            mapping.addNewTag();
          }
          if (mapping.images.length < 1) {
            mapping.addNewProdImage();
          }
          if (mapping.attributes.length < 1) {
            mapping.addNewProductAttribute();
          }
        }

        if (!Utils.isNullOrUndefined(result.schedulers)) {
          const resultSchedulers = result.schedulers;
          const schedulers = new ScheduleTimeVO();
          schedulers.type = resultSchedulers.type;
          schedulers.dates = resultSchedulers.dates;
          response.scheduling = schedulers;
        }

        response.taskFilters = result.taskFilters;
        if (result.rcatalogId) {
          response.rcatalogId = result.rcatalogId;
        }
        response.comments = result.task.comments;
        this.isUpdate = true;
        return response;
      })
    );
  }

  mapTaskFilterItem(obj, structure): FilterItem {
    const currentStructure = structure.find((fieldStructure) => {
      return fieldStructure.key === obj.key;
    });
    return <FilterItem>{
      field: Utils.isNullOrUndefined(currentStructure) ? null : currentStructure.key,
      condition: obj.condition,
      values: obj.values,
      fieldStructures: structure,
    };
  }

  mapMapInputData(obj): MapInputData {
    if (Utils.isNullOrUndefined(obj)) {
      return <MapInputData>{ chips: [], settings: [], operations: [] };
    }
    return <MapInputData>{
      chips: Utils.isNullOrUndefined(obj.chips) ? [] : this.mapChips(obj.chips),
      operations: Utils.isNullOrUndefined(obj.operations)
        ? []
        : obj.operations.map((operation) => this.mapFieldOperations(operation)),
      settings: Utils.isNullOrUndefined(obj.settings)
        ? []
        : obj.settings.map((setting) => this.mapFieldSettings(setting)),
    };
  }

  mapFieldSettings(settings): FieldSetting {
    if (Utils.isNullOrUndefined(settings)) {
      return;
    }
    switch (Object.keys(settings)[0].toString()) {
      case 'currency':
        return new CurrencyWrapper(settings['currency']);
      case 'HPCC':
        return new HPCCWrapper(settings['HPCC']);
      case 'weightUnit':
        return new WeightWrapper(settings['weightUnit']);
      case 'dimUnit':
        return new DimWrapper(settings['dimUnit']);
      case 'location':
        return new LocationWrapper(settings['location']);
      case 'fillTheEmpty':
        return new FillTheEmptyWrapper(settings['fillTheEmpty']);
    }
  }

  mapFieldOperations(operation): FieldOperation {
    if (Utils.isNullOrUndefined(operation)) {
      return;
    }
    switch (Object.keys(operation)[0].toString()) {
      case 'splitter':
        return new SplitterWrapper(operation['splitter']);
      case 'eval':
        return new EvalWrapper(operation['eval']);
      case 'replacer':
        return new ReplacerWrapper(operation['replacer']);
    }
  }

  mapChips(chips): (ConstantChip | VariableChip)[] {
    const finalRes = chips
      .map((chip) => {
        if (typeof chip === 'object' && chip !== null) {
          return this.mapVariableChip(chip);
        }
        return this.mapConstantChip(chip);
      })
      .filter((item) => {
        return !Utils.isNullOrUndefined(item);
      }, []);
    return !Utils.isNullOrUndefined(finalRes) ? finalRes : [];
  }

  mapVariableChip(chip): VariableChip {
    const fileId = chip.path.splice(0, 1)[0];
    if (!this.data.files.some((e) => e.fileId === fileId)) {
      this.data.mapping.isWrongData = true;
      return null;
    }
    const vChip = new VariableChip(
      chip.path.join(' / '),
      fileId,
      Utils.isNullOrUndefined(this.chipColors[fileId]) ? Constants.DEFAULT_CHIP_COLOR : this.chipColors[fileId]
    );
    vChip.operations = Utils.isNullOrUndefined(chip.operations)
      ? []
      : chip.operations.map((operation) => this.mapFieldOperations(operation));
    vChip.settings = Utils.isNullOrUndefined(chip.settings)
      ? []
      : chip.settings.map((setting) => this.mapFieldSettings(setting));
    vChip.altFields = Utils.isNullOrUndefined(chip.alt_fields) ? null : this.mapChips(chip.alt_fields);
    return vChip;
  }

  mapConstantChip(chip): any {
    return new ConstantChip(chip);
  }

  setFilterForRetailerCatalog(catalogFilter, fieldsStructure): any {
    const respTaskFilters = catalogFilter;
    let filterFieldStructures = fieldsStructure;
    if (!Utils.isNullOrUndefined(filterFieldStructures)) {
      filterFieldStructures = Utils.mapTaskFiltersFieldStructure(filterFieldStructures);
    }
    const taskFilters = new TaskFilters();
    taskFilters.fieldsStructure = filterFieldStructures;
    taskFilters.filterBoxes = [];
    taskFilters.operatorBoxes = [];
    respTaskFilters.forEach((respFilterBox) => {
      if (respFilterBox.key !== 'TASK_ID') {
        let filterBox = null;
        switch (true) {
          case isString(respFilterBox):
            taskFilters.operatorBoxes.push(respFilterBox);
            break;
          case Array.isArray(respFilterBox):
            filterBox = new FilterBox();
            filterBox.filterItems = [];
            respFilterBox.forEach((filterItem) => {
              switch (true) {
                case isString(filterItem):
                  filterBox.operator = filterItem;
                  break;
                case isObject(filterItem):
                  filterBox.filterItems.push(this.mapTaskFilterItem(filterItem, filterFieldStructures));
                  break;
              }
            });
            break;
          case isObject(respFilterBox):
            filterBox = new FilterBox();
            filterBox.filterItems = [this.mapTaskFilterItem(respFilterBox, filterFieldStructures)];
            break;
        }
        if (!Utils.isNullOrUndefined(filterBox)) {
          taskFilters.filterBoxes.push(filterBox);
        }
      }
    });

    return taskFilters;
  }
}
