import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchWidgetService } from '../../../../service/search-widget/search-widget.service';
import { Widget, WidgetType } from '../../../../vo/widget';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { WidgetTranslatePipe } from '../../../../shared/pipes/widget-translate.pipe';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { SectionTitleComponent } from '../../../../static/components/section-title/section-title.component';

@UntilDestroy()
@Component({
  selector: 'app-widget-cards-by-widget-type',
  standalone: true,
  imports: [CommonModule, WidgetTranslatePipe, SectionTitleComponent],
  templateUrl: './widget-cards-by-widget-type.component.html',
  styleUrls: ['./widget-cards-by-widget-type.component.scss'],
})
export class WidgetCardsByWidgetTypeComponent implements OnInit {
  @Input() innerClass: string | string[];
  @Input() wrapperClass: string | string[];
  @Input() title: string;
  @Input() widgetType: WidgetType;
  @Input() columns = 5;
  @Input() height = 250;
  @Input() mobileHeight = 140;
  @Output() cardClicked = new EventEmitter<Widget>();
  ltMd: boolean;

  widgets: Widget[];

  constructor(private searchWidgetService: SearchWidgetService, private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.getWidgets();
    this.subscribeToScreenChange();
  }

  private getWidgets(): void {
    this.searchWidgetService
      .getWidgets(this.widgetType, true)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.widgets = res;
      });
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }
}
