import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplierPublicInformationService } from '../../service/supplier-public-information.service';

@Component({
  selector: 'app-supplier-public-information-default',
  templateUrl: './supplier-public-information-default.component.html',
  styleUrls: ['./supplier-public-information-default.component.scss'],
})
export class SupplierPublicInformationDefaultComponent implements OnInit {
  @Input() hasSaveButton: boolean;

  readonly currentYear = new Date().getFullYear();

  descriptionMinValue: number;
  descriptionMaxValue: number;

  constructor(public supplierPublicInformationService: SupplierPublicInformationService) {
    this.descriptionMinValue = this.supplierPublicInformationService.DESCRIPTION_CHARACTER_MIN_LIMIT;
    this.descriptionMaxValue = this.supplierPublicInformationService.DESCRIPTION_CHARACTER_MAX_LIMIT;
  }

  ngOnInit(): void {}

  handleInnerSaveClicked(): void {
    this.save().subscribe();
  }

  save(): Observable<boolean> {
    return this.supplierPublicInformationService.save();
  }
}
