<div class="supplier-rating-display">
  <div fxLayoutAlign="unset center" fxLayoutGap="8px">
    <app-supplier-icon
      size="50px"
      [supplierUserId]="supplierUserId"
      [supplierCompanyName]="supplier.companyName"
    ></app-supplier-icon>
    <div>
      <div>
        {{ 'COMMON_WORDS.BY_LC' | translate }}
        <span (click)="navigateToStorefront()" class="supplier-name">{{ supplier.companyName }}</span>
      </div>
      <div fxLayoutAlign="unset center" fxLayoutGap="8px">
        <app-review
          *ngIf="reviewAverage"
          [iconClasses]="['s-20']"
          [condensed]="true"
          [avgRating]="average"
          [reviewCount]="count"
        ></app-review>

        <app-minimum-order-display-with-dialog
          *onlyLoggedIn
          [supplierPaymentCurrency]="supplier.paymentCurrency"
          [supplierUserId]="supplierUserId"
          [isAutomated]="isAutomated"
        ></app-minimum-order-display-with-dialog>
      </div>
    </div>
  </div>
</div>
