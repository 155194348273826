import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { getSelectedEcomByRole } from '../../store/ecom/ecom.selector';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { SpringPageable } from '../../vo/pagination/spring-pageable';
import { RolesEnum } from 'app/vo/roles/roles';

@Injectable({
  providedIn: 'root',
})
export class RetailerDashboardAnalyticsService {
  private baseUrl = '/Retailer/Analytics';
  constructor(private springRestService: SpringRestService, private store: Store<AppState>) {}

  getTopSuppliers(
    dateFrom: Date,
    dateTo: Date,
    pageable: SpringPageable = { page: 0, size: 3, sort: 'profit,desc' }
  ): Observable<TopSuppliersDto[]> {
    const url = `${this.baseUrl}/TopSuppliers`;
    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) =>
        this.springRestService.get(MicroserviceNames.ORDER, url, { ...requestDto, ...pageable }, true)
      )
    );
  }

  getTopSellingProducts(
    dateFrom: Date,
    dateTo: Date,
    pageable: SpringPageable = { page: 0, size: 3, sort: 'profit,desc' }
  ): Observable<TopSellingProductsDto[]> {
    const url = `${this.baseUrl}/TopSellingProducts`;
    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) =>
        this.springRestService.get(MicroserviceNames.ORDER, url, { ...requestDto, ...pageable }, true)
      )
    );
  }

  getCategorySellingAnalytics(dateFrom: Date, dateTo: Date): Observable<CategorySellingAnalyticsDto[]> {
    const url = `${this.baseUrl}/CategorySellingAnalytics`;

    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) => this.springRestService.get(MicroserviceNames.ORDER, url, requestDto, true))
    );
  }

  getOrderOverviewAnalytics(): Observable<OrderOverviewAnalyticsDto> {
    const url = `${this.baseUrl}/OrderOverviewAnalytics`;

    return this.getRequestDto().pipe(
      switchMap((requestDto) => this.springRestService.get(MicroserviceNames.ORDER, url, requestDto, true))
    );
  }

  getDailyOrderAnalytics(dateFrom: Date, dateTo: Date, timeFrames: number = 10): Observable<TimelineDataSetDto[]> {
    const url = `${this.baseUrl}/DailyOrderAnalytics`;
    return this.getRequestDto(dateFrom, dateTo).pipe(
      switchMap((requestDto) =>
        this.springRestService.get(MicroserviceNames.ORDER, url, { ...requestDto, timeFrames }, true)
      )
    );
  }

  private getRequestDto(fromDate?: Date, toDate?: Date): Observable<RetailerDashboardRequestDto> {
    return this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER)).pipe(
      filter((selectedEcom) => !!selectedEcom),
      take(1),
      map((selectedEcom) => {
        const requestDto = {
          ecomIds: [selectedEcom.id],
          ecomType: selectedEcom.ecomType.toUpperCase(),
        } as RetailerDashboardRequestDto;

        if (fromDate) {
          requestDto.fromDate = fromDate.toISOString();
        }

        if (toDate) {
          requestDto.toDate = toDate.toISOString();
        }

        return requestDto;
      })
    );
  }
}

export interface RetailerDashboardRequestDto {
  ecomIds: number[];
  fromDate: string;
  toDate: string;
  ecomType: string;
}

export interface TopSuppliersDto {
  userId: number;
  ecomId: number;
  orders: number;
  companyName: string;
  productsSold: number;
  profit: number;
  revenue: number;
}

export interface TopSellingProductsDto {
  synceeId: string;
  title: string;
  imageUrl: string;
  companyName: string;
  productsSold: number;
  profit: number;
  revenue: number;
  originalVariantTitle: string;
}

export interface CategorySellingAnalyticsDto {
  categoryId: number;
  categoryName: string;
  quantity: number;
}

export interface OrderOverviewAnalyticsDto {
  totalRevenue: number;
  totalProfit: number;
  averageRevenue: number;
  averageProfit: number;
  maxProfitOnOrder: number;
}

export interface TimelineDataSetDto {
  type: AnalyticsType;
  rangeDtos: TimelineRangeDto[];
}

export interface TimelineRangeDto {
  orders: number;
  productsSold: number;
  revenue: number;
  profit: number;
  from: string;
  to: string;
}

export type AnalyticsType = 'ALIBABA' | 'MARKETPLACE';
