<app-dialog-multi-wrapper
  [title]="text"
  [buttonsAlignment]="'center'"
  [confirmButtonText]="btnOk"
  [closeButtonText]="btnCancel"
  (confirmClick)="onSubmit()"
  (close)="dialogRef.close()"
  [type]="dialogType"
>
  <div class="wrapper">
    <mat-form-field class="w-100p mat-form-field" [appearance]="'fill'">
      <mat-label>{{ placeholder }}</mat-label>
      <input matInput type="text" [(ngModel)]="response" required />
    </mat-form-field>
  </div>
</app-dialog-multi-wrapper>
