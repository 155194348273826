<div class="placeholder-container" *ngIf="!!options?.length">
  <ngx-slick-carousel [config]="config">
    <div ngxSlickItem *ngFor="let placeholder of options" class="item" [style.color]="color">
      {{ placeholder }}
    </div>
  </ngx-slick-carousel>
  <div
    class="wrapper"
    [style.background]="
      'linear-gradient( 0deg, ' +
      backgroundColor +
      ' 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 65%, ' +
      backgroundColor +
      ' 100%)'
    "
  ></div>
</div>
