<div class="product-page-price-display-guest-container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="product-page-price-guest-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon class="s-16">lock</mat-icon>
      <h4>{{ 'GUEST.PRODUCT_PAGE_GUEST_PRICE.UNLOCK_WSP_LABEL' | translate }}</h4>
    </div>
    <div class="typography-body-1">{{ 'GUEST.PRODUCT_PAGE_GUEST_PRICE.UNLOCK_WSP_TEXT' | translate }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <button mat-stroked-button class="syncee-blue-button" (click)="emitRegistrationDialog()">
      {{ 'HEADER.REGISTER' | translate }}
    </button>
    <button mat-flat-button class="syncee-black-flat-button" (click)="emitLoginDialog($event)">
      {{ 'HEADER.LOG_IN' | translate }}
    </button>
  </div>
</div>
