import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  selector: 'app-actions-button',
  templateUrl: './actions-button.component.html',
  styleUrls: ['./actions-button.component.scss'],
  imports: [CommonModule, MatButtonModule, MatIconModule, OverlayModule, MatMenuModule],
})
export class ActionsButtonComponent implements OnInit {
  @Input() label = 'Actions';

  constructor() {}

  ngOnInit(): void {}
}
