<form [formGroup]="replacerForm">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div>{{ 'FIELD_SETTINGS.REPLACE.IF' | translate }}</div>
    <mat-form-field appearance="fill" class="input-without-padding select-without-label">
      <mat-select formControlName="comparator">
        <mat-option *ngFor="let c of comparators" [value]="c.code">{{ c.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="input-without-padding select-without-label" fxFlex="1 0 0">
      <input matInput formControlName="value" (mousedown)="$event.stopPropagation()" />
    </mat-form-field>
    <div>{{ 'FIELD_SETTINGS.REPLACE.REPLACE' | translate }}</div>
    <mat-form-field appearance="fill" class="input-without-padding select-without-label" fxFlex="1 0 0">
      <input matInput formControlName="replace" (mousedown)="$event.stopPropagation()" />
    </mat-form-field>
    <button mat-icon-button>
      <mat-icon (click)="removeSelf()">delete_outline</mat-icon>
    </button>
  </div>
</form>
