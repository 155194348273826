<app-custom-dialog-wrapper>
  <div class="profile-mobile-dialog" fxLayout="column" fxLayoutGap="40px">
    <h3 class="username">{{ 'PROFILE_MENU.HI' | translate }} {{ username | async }}!</h3>
    <div class="list">
      <div
        (click)="onMenuItemClick()"
        [routerLink]="'/setup-guide'"
        class="item s-20"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
      >
        <mat-icon class="mat-icon-color">flag</mat-icon>
        <div>{{ 'PROFILE_MENU.SETUP_GUIDE' | translate }}</div>
      </div>
      <ng-container *ngFor="let center of navigation">
        <ng-container *ngIf="center.showToRoles.includes(role)">
          <div
            (click)="onCenterClick(center.rolesWithAccess, center.link, center.forRole)"
            class="item"
            fxLayoutAlign="space-between center"
          >
            <div>
              {{ center.title | translate }}
            </div>
            <mat-icon class="s-20">keyboard_arrow_right</mat-icon>
          </div>
        </ng-container>
      </ng-container>
      <div (click)="onMenuItemClick()" [routerLink]="'/retailer/favorites/products'" class="item">
        {{ 'PROFILE_MENU.FAVORITES' | translate }}
      </div>
      <div (click)="onMenuItemClick()" [routerLink]="'/referrals'" class="item">
        {{ 'PROFILE_MENU.REFER_BRAND' | translate }}
      </div>
      <div (click)="logout()" class="item">{{ 'PROFILE_MENU.LOGOUT' | translate }}</div>
      <div *isAdminLoggedInToUser (click)="logoutFromUser('ADMIN')" class="item">
        {{ 'PROFILE_MENU.LOGOUT_FROM_USER' | translate }}
      </div>
      <div *isLoggedInByTeamMember (click)="logoutFromUser('TEAM_MEMBER')" class="item">
        {{ 'PROFILE_MENU.LOGOUT_FROM_TEAM_OWNER' | translate }}
      </div>
    </div>
  </div>
  <fuse-sidebar [name]="'mobile-center'" [fullWidth]="true" [hasBackdrop]="false" class="p-0 sidebar">
    <app-mobile-center (close)="close()"></app-mobile-center>
  </fuse-sidebar>
</app-custom-dialog-wrapper>
