import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateConditionallyPipe } from '../../pipes/translate-conditionally.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-select-button',
  standalone: true,
  imports: [CommonModule, TranslateConditionallyPipe, TranslateModule],
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
})
export class SelectButtonComponent<T> implements OnInit {
  @Input() items: SelectButtonItem<T>[];
  @Input() selectedItem: T;
  @Input() translateValue = true;
  @Output() selectedItemChange = new EventEmitter<T>();

  constructor() {}

  ngOnInit(): void {}
}

export interface SelectButtonItem<T> {
  value: T;
  label?: string;
  icon?: string;
}
