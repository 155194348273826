import { CatalogApproveStatus } from '../../service/catalog-sidebar/catalog-sidebar.service';
import { ActionsUnion, RetailerToCatalogActionTypes } from './retailer-to-catalog.actions';

export interface RetailerToCatalogState {
  statuses: CatalogApproveStatus[];
}

const initialState: RetailerToCatalogState = {
  statuses: null,
};

export function retailerToCatalogReducer(state = initialState, action: ActionsUnion): RetailerToCatalogState {
  switch (action.type) {
    case RetailerToCatalogActionTypes.GET_RETAILER_TO_CATALOG_SUCCESS:
      return { ...state, statuses: action.payload };
    default:
      return state;
  }
}
