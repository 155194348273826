import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-stepper-dialog-header',
  templateUrl: './stepper-dialog-header.component.html',
  styleUrls: ['./stepper-dialog-header.component.scss'],
  standalone: true,
  imports: [TranslateModule, FuseSharedModule, MatProgressBarModule],
})
export class StepperDialogHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() currentStepIndex: number;
  @Input() maxStepNumber: number;

  steps;

  ngOnInit(): void {
    this.steps = Array(this.maxStepNumber)
      .fill(1)
      .map((x, i) => i);
  }
}
