import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { SanitizeHtmlPipe } from '../../pipes/sanitize-html.pipe';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  standalone: true,
  imports: [SanitizeHtmlPipe],
})
export class SvgIconComponent implements OnInit {
  @Input() path!: string;
  ngOnInit(): void {}
}
