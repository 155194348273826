import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminCategoriesComponent } from './admin-categories.component';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { EditImageModalComponent } from './edit-image-modal/edit-image-modal.component';
import { EditCategoryDialogComponent } from './edit-category-dialog/edit-category-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { FilterCommonModule } from '../../filter-common/filter-common.module';
import { CategoryImageFromId } from '../../../shared/pipes/category-image-from-id';
import { AddCategoryImageDialogComponent } from './add-category-image-dialog/add-category-image-dialog.component';
import { ngfModule } from 'angular-file';
import { DeleteModalComponent } from 'app/shared/components/dialogs/delete-modal/delete-modal.component';
import { LogoUploadComponent } from 'app/shared/components/logo-upload/logo-upload.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AdminCategoriesComponent,
    EditImageModalComponent,
    EditCategoryDialogComponent,
    AddCategoryImageDialogComponent,
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatChipsModule,
    FilterCommonModule,
    CategoryImageFromId,
    ngfModule,
    DeleteModalComponent,
    LogoUploadComponent,
    MatTooltipModule,
  ],
  exports: [AdminCategoriesComponent],
})
export class AdminCategoriesModule {}
