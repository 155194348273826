import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { NotificationComponent } from './notification.component';
import { NotificationData } from './notification-config';
import { NotificationRef } from './notification-ref';
import { isString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private lastNotification: NotificationRef;

  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  public success(msg: string): NotificationRef;
  public success(data: NotificationData): NotificationRef;
  public success(data: string | NotificationData): NotificationRef {
    if (isString(data)) {
      return this.show(new NotificationData('success', data));
    }
    return this.show(data);
  }

  public warning(msg: string): NotificationRef;
  public warning(data: NotificationData): NotificationRef;
  public warning(data: string | NotificationData): NotificationRef {
    if (isString(data)) {
      return this.show(new NotificationData('warning', data));
    }
    return this.show(data);
  }

  public error(msg: string): NotificationRef;
  public error(data: NotificationData): NotificationRef;
  public error(data: string | NotificationData): NotificationRef {
    if (isString(data)) {
      return this.show(new NotificationData('error', data));
    }
    return this.show(data);
  }

  public information(msg: string): NotificationRef;
  public information(data: NotificationData): NotificationRef;
  public information(data: string | NotificationData): NotificationRef {
    if (isString(data)) {
      return this.show(new NotificationData('information', data));
    }
    return this.show(data);
  }

  private show(data: NotificationData): NotificationRef {
    const positionStrategy = this.overlay.position().global().top(this.getPosition());
    const overlayRef = this.overlay.create({ positionStrategy });
    this.lastNotification = new NotificationRef(overlayRef);
    const injector = this.getInjector(data);
    const notificationPortal = new ComponentPortal(NotificationComponent, null, injector);
    overlayRef.attach(notificationPortal);
    return this.lastNotification;
  }

  public getPosition(): string {
    const lastNotificationIsVisible = this.lastNotification && this.lastNotification.isVisible();
    const position = lastNotificationIsVisible ? this.lastNotification.getPosition().bottom : 0;
    return position + 'px';
  }

  private getInjector(data: NotificationData): PortalInjector {
    const tokens = new WeakMap();
    tokens.set(NotificationData, data);
    tokens.set(NotificationRef, this.lastNotification);
    return new PortalInjector(this.parentInjector, tokens);
  }
}
