import { Component, OnInit } from '@angular/core';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { SearchWidgetService } from 'app/service/search-widget/search-widget.service';
import { Widget, WidgetType } from 'app/vo/widget';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-filter-widget-rows',
  templateUrl: './product-filter-widget-rows.component.html',
  styleUrls: ['./product-filter-widget-rows.component.scss'],
})
export class ProductFilterWidgetRowsComponent implements OnInit {
  widgets: Observable<Widget[]>;

  constructor(
    private searchWidgetService: SearchWidgetService,
    private navigationEventService: NavigationEventService
  ) {}

  ngOnInit(): void {
    this.getWidgets();
  }

  private getWidgets(): void {
    this.widgets = this.searchWidgetService
      .getWidgets(WidgetType.EXPLORE_PRODUCT_CARDS, true)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart));
  }
}
