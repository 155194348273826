<div class="image-selector-container">
  <a [href]="productPath" target="_blank">
    <img
      defaultImage="assets/images/transparent-400x400.png"
      [lazyLoad]="images?.[0] | productImageThumbnailCdn"
      [errorImage]="images?.[0]"
      productImageErrorHandler
      [checkedOriginal]="true"
      [alt]="productName"
      width="200"
      height="200"
    />
  </a>
</div>
