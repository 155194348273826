import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { GeneralButton } from '../../../vo/general-button/general-button';
import { TranslateConditionallyPipe } from '../../pipes/translate-conditionally.pipe';
import { GeneralButtonComponent } from '../general-button/general-button.component';
import { ImageSeo } from '../../../vo/image-seo';
import { Observable } from 'rxjs';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { map, share } from 'rxjs/operators';
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-simple-banner-with-image',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateConditionallyPipe, GeneralButtonComponent],
  templateUrl: './simple-banner-with-image.component.html',
  styleUrls: ['./simple-banner-with-image.component.scss'],
})
export class SimpleBannerWithImageComponent implements OnInit {
  @Input() image: ImageSeo;
  @Input() imageLtMd?: ImageSeo;
  @Input() imageLtSm?: ImageSeo;
  @Input() text: string;
  @Input() translateText = true;
  @Input() button?: GeneralButton;
  @Input() background?: string;
  @Input() containerClasses?: string[];
  imageToUse: Observable<ImageSeo>;
  textTypography: Observable<string>;

  constructor(private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.imageToUse = this.getImageToUse();
    this.textTypography = this.getTitleTypography();
  }

  private breakPoints(): Observable<BreakpointState> {
    return this.screenManagerService.observeBreakpoints([BreakPoint.md, BreakPoint.sm]).pipe(share());
  }

  private getImageToUse(): Observable<ImageSeo> {
    return this.breakPoints().pipe(
      map((state) => {
        if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.sm)] && !!this.imageLtSm) {
          return this.imageLtSm;
        } else if (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.md)] && !!this.imageLtMd) {
          return this.imageLtMd;
        } else {
          return this.image;
        }
      })
    );
  }

  private getTitleTypography(): Observable<string> {
    return this.breakPoints().pipe(
      map((state) => (state.breakpoints[this.screenManagerService.buildQuery(BreakPoint.md)] ? 'h4' : 'h3'))
    );
  }
}

export interface SimpleBannerWithImageOptions {
  image: ImageSeo;
  imageLtMd?: ImageSeo;
  imageLtSm?: ImageSeo;
  text: string;
  translateText: boolean;
  button?: GeneralButton;
  background?: string;
  containerClasses?: string[];
}
