import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { DraggableModule } from '../../draggable/draggable.module';
import { CrossLoginComponent } from '../cross-login/cross-login.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { RetailerLoginComponent } from './retailer-login/retailer-login.component';
import { FormsModule } from '@angular/forms';
import { RetailerLoginFormComponent } from './login-form/login-form.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { RetailerLoginContentComponent } from './retailer-login/retailer-login-content/retailer-login-content.component';
import { SupplierLoginComponent } from './supplier-login/supplier-login.component';
import { SupplierLoginContentComponent } from './supplier-login/supplier-login-content/supplier-login-content.component';
import { CustomDialogWrapperComponent } from 'app/shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { AuthenticationLayoutComponent } from '../authentication-layout/authentication-layout.component';
import { LoginGoogleButtonComponent } from './login-social-buttons/login-google-button/login-google-button.component';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { ForgotPasswordFormComponent } from './forgot-password-dialog/forgot-password-form/forgot-password-form.component';
import { ResetPasswordFormComponent } from './forgot-password-dialog/reset-password-form/reset-password-form.component';
import { AuthenticationDialogBannerComponent } from '../authentication-dialog-banner/authentication-dialog-banner.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [
    CrossLoginComponent,
    LoginDialogComponent,
    RetailerLoginComponent,
    RetailerLoginFormComponent,
    RetailerLoginContentComponent,
    SupplierLoginComponent,
    SupplierLoginContentComponent,
    ForgotPasswordDialogComponent,
    ForgotPasswordFormComponent,
    ResetPasswordFormComponent,
  ],
  exports: [LoginDialogComponent, ForgotPasswordDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    FuseSharedModule,
    RouterModule,
    DraggableModule,
    MatIconModule,
    FormsModule,
    SvgIconComponent,
    MatDialogModule,
    CustomDialogWrapperComponent,
    AuthenticationLayoutComponent,
    LoginGoogleButtonComponent,
    AuthenticationDialogBannerComponent,
    TranslateModule,
    LoadingSpinnerComponent,
  ],
})
export class LoginModule {}
