<app-custom-dialog-wrapper [hideCloseBtnTablet]="false" [hideCloseBtnDesktop]="true">
  <ng-container [ngSwitch]="props.type">
    <app-registration-supplier
      *ngSwitchCase="'supplier'"
      [titleKey]="props.titleKey"
      [descriptionKey]="props.descriptionKey"
    ></app-registration-supplier>
    <app-registration-retailer
      *ngSwitchCase="'retailer'"
      [titleKey]="props.titleKey"
      [descriptionKey]="props.descriptionKey"
    ></app-registration-retailer>
  </ng-container>
</app-custom-dialog-wrapper>
