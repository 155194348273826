export interface Catalog {
  id: string;
  userId: number;
  ecomId: number;
  taskId: null;
  alibabaCatalog: boolean;
  name: string;
  filter: CatalogFilter[];
  rounding: null;
  settings: CatalogSettings;
  isActive: boolean;
  deleted: null;
  date: Date;
}

export interface CatalogFilter {
  key: string;
  condition: string;
  values: string[];
}

export interface CatalogSettings {
  variantQtyUpload: string;
  published: number;
  taxes: boolean;
  backorder: string;
  fulfillment: string;
  weightUnit: string;
  pointOfSale: string;
  locations: number;
  multiLocationInventoryChanges: string;
  sendMail: string;
  productNotInFeed: string;
  productQuantity0: string;
  outVariantDelete: string;
  uploadNewProducts: boolean;
  fieldsToUpdate: string[];
}

export class CatalogFeedSettingsVO {
  id: number;
  updateType: string;
  settings: any;
  platformExceptions?: any;
}
