import { IconOptions, MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOM_SVG_LIST } from './custom-svg-list';
@Injectable({
  providedIn: 'root',
})
export class CustomSvgRegistryService {
  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public registerCustomSvgIcons(): void {
    CUSTOM_SVG_LIST.forEach((customIcon) => {
      this.matIconRegistry.addSvgIcon(
        customIcon.iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(customIcon.url),
        customIcon.options
      );
    });
  }
}

export interface CustomSvgInterface {
  iconName: string;
  url: string;
  options?: IconOptions;
}
