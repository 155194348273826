import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { NgIf } from '@angular/common';
import { HasPermissionDirective } from '../../directives/has-permission.directive';
import { Action } from '../../../service/authorization-ecom/authorization-ecom.service';

@Component({
  selector: 'app-supplier-order-info',
  templateUrl: './supplier-order-info.component.html',
  styleUrls: ['./supplier-order-info.component.scss'],
  standalone: true,
  imports: [TranslateModule, TranslateModule, FlexModule, NgIf, HasPermissionDirective],
})
export class SupplierOrderInfoComponent implements OnInit {
  @Input() data: SupplierOrderInfoData;
  CONTACTS_READ = Action.CONTACTS_READ;

  constructor() {}

  ngOnInit(): void {}
}

export interface SupplierOrderInfoData {
  userId: number;
  approveNeeded: boolean;
  isAutomated: boolean;
  supplierWebsite: string;
  contactEmail: string;
  approveDescription: string;
  hasAccessToContacts: boolean;
  supplierCurrency: string;
}
