import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
  animations: [fuseAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityLogComponent implements OnInit {
  public role = 1;

  constructor() {}

  ngOnInit(): void {
    const roleFromLocal = Number(localStorage.getItem('actualRole'));
    if (roleFromLocal) {
      this.role = roleFromLocal;
    }
  }
}
