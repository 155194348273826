<div fxLayout="column" fxLayoutGap="0" fxLayoutGap.lt-lg="20px">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
      <div class="typography-body-large-1">
        {{
          'IMPORT_LIST.PRICING.' + (isDatafeed ? 'DATAFEED.' : '') + ((priceType | uppercase) + '_DESCRIPTION')
            | translate
        }}
      </div>

      <ng-container
        adminComment
        [adminCommentTaskId]="catalogId"
        [adminCommentComments]="comments"
        adminCommentMappingField="pricing"
        adminCommentStep="pricing"
        adminCommentTaskType="import"
      ></ng-container>
    </div>

    <div fxLayout="row" fxLayout.lt-md="row-reverse" fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-icon class="s-20 mb-16" matTooltip="{{ 'IMPORT_LIST.PRICING.COLLECTION_TOOLTIP' | translate }}"
        >info</mat-icon
      >
      <app-import-list-price-collection-selector
        fxFlex="1 0 auto"
        [collections]="collections"
        (collectionChange)="handleCollectionChange($event)"
      ></app-import-list-price-collection-selector>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="40px">
    <ng-container [formGroup]="form">
      <ng-container formArrayName="priceItemList">
        <div
          *ngFor="let item of priceItemList.controls; let i = index; let last = last; let first = first"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap.lt-md="15px"
          fxLayoutAlign="space-between"
        >
          <app-import-list-price-item
            fxFlex="80"
            [formControl]="$any(form.get('priceItemList')).controls[i]"
            [locked]="locked"
            [isLastRow]="last"
            [isFirstRow]="first"
            [markFormAsTouched]="saveClick"
            (toFieldChange)="toFieldChanged(i, $event)"
            (calculate)="calculateExampleHandler(i)"
            [enabledPercentageOperators]="enabledPercentageOperators"
            [enabledFixOperators]="enabledFixOperators"
            [defaultPercentageOperator]="defaultPercentageOperator"
            [defaultFixOperator]="defaultFixOperator"
            [currency]="ecomCurrency"
          ></app-import-list-price-item>

          <app-import-list-price-actions
            *ngIf="!locked"
            fxFlexAlign.lt-md="end"
            [hideRemoveButton]="priceItemList.controls.length < 2"
            (add)="addNewPriceItem(i + 1)"
            (remove)="removePriceListItem(i)"
          ></app-import-list-price-actions>
        </div>
      </ng-container>
    </ng-container>

    <div
      #examplewrapper
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="0"
      fxLayoutGap.lt-md="10px"
      fxLayoutAlign="space-between center"
    >
      <app-import-list-price-example
        [isDatafeed]="isDatafeed"
        [priceRule]="selectedPriceRule"
        [currency]="ecomCurrency"
        [priceType]="priceType"
      ></app-import-list-price-example>

      <ng-container *ngIf="hasSaveButton">
        <button fxFlexAlign.lt-md="end" mat-button class="syncee-blue-button" (click)="submit()">
          {{ 'IMPORT_LIST_PRICE_SETTINGS.SAVE' | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
