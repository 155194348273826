import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatOptions } from '../chat.component';
import { ChatDialogInput, ChatUser } from '../../../../service/chat/model/chat-models';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
})
export class ChatDialogComponent implements OnInit {
  currUser: ChatUser;
  recipient: ChatUser;

  options: ChatOptions = { hideChannelSide: true, innerChat: true, hideDirectMessageSide: false };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ChatDialogInput,
    private dialogRef: MatDialogRef<ChatDialogComponent>
  ) {}

  ngOnInit(): void {
    this.currUser = this.data.currUser;
    this.recipient = this.data.recipientUser;
  }
}
