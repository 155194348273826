import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ImportListPriceItem } from '../model/import-list-price-item';
import { IMPORT_LIST_PRICE_OPERATOR_TYPE } from '../types/operator.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PERCENTAGE_NEGATIVE_MAX_AMOUNT } from '../../../../utils/Constants';

@UntilDestroy()
@Component({
  selector: 'app-import-list-price-item',
  templateUrl: './import-list-price-item.component.html',
  styleUrls: ['./import-list-price-item.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImportListPriceItemComponent), multi: true }],
})
export class ImportListPriceItemComponent implements OnInit, ControlValueAccessor {
  private _isLastRow = false;
  private _isFirstRow = false;

  @Input() markFormAsTouched: Observable<void>;
  @Input() currency = 'USD';
  @Input() locked: boolean;

  @Input() enabledPercentageOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1, -1];
  @Input() enabledFixOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1, -1];

  @Input() defaultPercentageOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = 1;
  @Input() defaultFixOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = 1;

  @Input() set isFirstRow(value: boolean) {
    this._isFirstRow = value;
    this.handleFirstRowChange();
  }

  get isFirstRow(): boolean {
    return this._isFirstRow;
  }

  @Input() set isLastRow(value: boolean) {
    this._isLastRow = value;
    this.handleLastRowChange();
  }

  get isLastRow(): boolean {
    return this._isLastRow;
  }

  @Output() toFieldChange = new EventEmitter<number>();
  form: FormGroup = null;

  onChange: any = (values: ImportListPriceItemFormFields) => {};
  onTouch: any = () => {};
  @Output() calculate = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.initForm();
    this.listenToFormChanges();
    this.markFormAsTouched?.pipe(untilDestroyed(this))?.subscribe(() => {
      this.form.markAllAsTouched();
    });
  }

  handleFirstRowChange(): void {
    if (this.locked) {
      this.form.get('from').disable();
    } else {
      if (this.form) {
        if (this.isFirstRow) {
          this.form.get('from').setValue('0');
          this.form.get('from').disable();
        } else {
          this.form.get('from').setValue(null);
          this.form.get('from').enable();
        }
      }
    }
  }

  handleLastRowChange(): void {
    if (this.locked) {
      this.form.get('to').disable();
    } else {
      if (this.form) {
        if (this.isLastRow) {
          this.form.get('to').setValue('∞');
          this.form.get('to').disable();
        } else {
          this.form.get('to').setValue(null);
          if (!this.locked) {
            this.form.get('to').enable();
          }
        }
      }
    }
  }

  initForm(): void {
    this.form = new FormGroup({
      from: new FormControl({ value: this.isFirstRow ? 0 : null, disabled: true }, [Validators.required]),
      to: new FormControl({ value: this.isLastRow ? '∞' : null, disabled: this.isLastRow }, [Validators.required]),
      percAmountOperator: new FormControl(this.defaultPercentageOperator, [Validators.required]),
      percentage: new FormControl(0, [Validators.required]),
      fixAmountOperator: new FormControl(this.defaultFixOperator, [Validators.required]),
      fixAmount: new FormControl(0, [Validators.required]),
    });
  }

  listenToFormChanges(): void {
    this.form
      .get('to')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.toFieldChange.emit(value);
      });

    this.form
      .get('percAmountOperator')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value == -1) {
          this.form.controls['percentage'].setValidators([
            Validators.required,
            Validators.max(PERCENTAGE_NEGATIVE_MAX_AMOUNT),
          ]);
          this.form.controls['percentage'].updateValueAndValidity();
          this.form.updateValueAndValidity();
          this.form.controls['percentage'].markAsTouched();
        } else {
          this.form.controls['percentage'].setValidators([Validators.required]);
          this.form.controls['percentage'].updateValueAndValidity();
          this.form.updateValueAndValidity();
          this.form.controls['percentage'].markAsTouched();
        }
      });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((values: ImportListPriceItemFormFields) => {
      this.onChange(this.form.getRawValue());
      this.onTouch();
      this.calculate.emit();
    });
  }

  writeValue(values: ImportListPriceItemFormFields): void {
    this.form.patchValue(values);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  protected readonly PERCENTAGE_NEGATIVE_MAX_AMOUNT = PERCENTAGE_NEGATIVE_MAX_AMOUNT;
}

// tslint:disable-next-line: no-empty-interface
export interface ImportListPriceItemFormFields extends ImportListPriceItem {}
