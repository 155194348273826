import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogsService {
  constructor(private dialog: MatDialog, private translateService: TranslateService) {}

  public confirm(
    title: string = this.translateService.instant('CONFIRMATION_DIALOG.WARNING'),
    message: string,
    btnOkText: string = this.translateService.instant('CONFIRMATION_DIALOG.CONFIRM'),
    btnCancelText: string = this.translateService.instant('CONFIRMATION_DIALOG.CANCEL'),
    width: string = '400px'
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = false;
    config.width = width;
    config.panelClass = 'custom-modal-container';
    config.autoFocus = false;
    config.restoreFocus = false;
    dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.btnOkText = btnOkText;
    dialogRef.componentInstance.btnCancelText = btnCancelText;

    return dialogRef.afterClosed();
  }

  public confirmWithoutContainer(
    title: string,
    message: string,
    titleAlign: string = 'center',
    messageAlign: string = 'center',
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel'
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = false;
    config.width = '400px';
    config.panelClass = 'custom-modal-container';

    dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.titleAlign = titleAlign;
    dialogRef.componentInstance.btnOkText = btnOkText;
    dialogRef.componentInstance.btnCancelText = btnCancelText;

    return dialogRef.afterClosed();
  }

  public confirmWithoutCancel(
    title: string,
    message: string,
    titleAlign: string = 'center',
    messageAlign: string = 'center',
    btnOkText: string = 'Ok',
    width: string = '400px'
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = false;
    config.width = width;
    config.panelClass = 'custom-modal-container';

    dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.titleAlign = titleAlign;
    dialogRef.componentInstance.btnOkText = btnOkText;
    dialogRef.componentInstance.showCancel = false;

    return dialogRef.afterClosed();
  }

  public alibabaConfirm(
    title: string,
    message: string,
    ecologyToken: string,
    btnOkText: string = 'Ok',
    width: string = '400px'
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = false;
    config.width = width;
    config.panelClass = 'custom-modal-container';

    dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.ecologyToken = ecologyToken;
    dialogRef.componentInstance.btnOkText = btnOkText;
    dialogRef.componentInstance.showCancel = false;
    dialogRef.componentInstance.isAlibaba = true;

    return dialogRef.afterClosed();
  }
}
