<ng-container *onlyLoggedIn>
  <div
    class="follow-badge cursor-pointer"
    [ngClass]="{ 'followed-state': followed, 'with-border': hasBorder && !followed, 'large-follow-button': large }"
    (click)="handleFollowClick()"
  >
    <mat-icon class="s-20">{{ isLoading ? 'sync' : followed ? 'check' : 'add' }}</mat-icon>
    {{ followed ? ('FOLLOW_SUPPLIER_BUTTON.FOLLOWING' | translate) : ('FOLLOW_SUPPLIER_BUTTON.FOLLOW' | translate) }}
  </div>
</ng-container>
