<ng-container *ngIf="selectedVariant; else nonAvailableVariant">
  <div fxLayoutAlign="start center" fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="font-size-22 font-weight-600 first-row">
        {{
          prices.costPrice
            | currencyExchangeTo : supplierCurrency : ecomCurrency
            | async
            | currencyTransform : ecomCurrency
            | async
        }}
      </div>
      <div *ngIf="!!prices?.calculatedMargin">
        {{ 'PRODUCT_PAGE.MARGIN' | translate }} {{ prices.calculatedMargin | number : '.1-2' }}%
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="first-row">
        {{ 'PRODUCT_PAGE.RETAIL' | translate }}
        {{
          prices.retailPrice
            | currencyExchangeTo : supplierCurrency : ecomCurrency
            | async
            | currencyTransform : ecomCurrency
            | async
        }}
      </div>
      <div>
        {{ 'PRODUCT_PAGE.PROFIT' | translate }}
        {{
          prices.calculatedProfit
            | currencyExchangeTo : supplierCurrency : ecomCurrency
            | async
            | currencyTransform : ecomCurrency
            | async
        }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #nonAvailableVariant>
  <div class="font-size-22 font-weight-600">{{ 'PRODUCT_PAGE.VARIANT_NOT_AVAILABLE' | translate }}</div>
</ng-template>
