import { NgModule } from '@angular/core';
import { DimensionsSettingsComponent } from './settings/dimensions-settings.component';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DimensionsSettingsComponent],
  imports: [
    // BrowserModule,
    CommonModule,
    FlexModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
  ],
  providers: [],
  exports: [],
})
export class PimModule {}
