import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-tab-nav',
  standalone: true,
  imports: [CommonModule, RouterModule, MatTabsModule, TranslateModule],
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss'],
})
export class TabNavComponent implements OnInit {
  @Output() navigationEvent = new EventEmitter<void>();
  navLinks: TabItem[] = [];
  showNav: boolean;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => (this.showNav = this.getShowNav()));
    this.showNav = this.getShowNav();
    this.navLinks =
      this.route.routeConfig && this.route.routeConfig.children
        ? this.getNavItems(this.route.routeConfig.children)
        : [];
  }

  private getShowNav(): boolean {
    let currentRoute = this.router.routerState.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return !currentRoute.routeConfig.data?.hideTab;
  }

  private getNavItems(routes: Routes): TabItem[] {
    return routes
      .filter((route) => route.data && !(route.data as TabItemRouteDataData)?.hideTab)
      .map(({ path = '', data }) => ({
        link: path,
        title: data.label,
      }));
  }

  tabClicked(url: string, isActive: boolean): void {
    if (isActive) {
      this.navigationEvent.emit();
    }
  }
}

interface TabItem {
  link: string;
  title: string;
}

export interface TabItemRouteDataData {
  hideTab?: boolean;
  label?: string;
}
