<div class="mobile-main-menu-sidebar" fxLayout="column" fxLayoutGap="12px">
  <button mat-icon-button (click)="closeSidebar()" class="close-button"><mat-icon>close</mat-icon></button>
  <app-syncee-logo [width]="'116px'" [height]="'24px'" class="logo" (click)="closeSidebar()"></app-syncee-logo>
  <fuse-navigation
    layout="vertical"
    [navigation]="upperNavigation"
    (itemClicked)="handleItemClicked()"
  ></fuse-navigation>
  <div (click)="handleCategoriesClicked()" class="categories" fxLayoutAlign="space-between center">
    <div>
      <b>{{ 'MOBILE_MAIN_MENU.ALL_CATEGORIES' | translate }}</b>
    </div>
    <mat-icon class="s-20">keyboard_arrow_right</mat-icon>
  </div>
  <fuse-navigation
    layout="vertical"
    [navigation]="navigationFromUpperRow"
    [hasArrow]="true"
    (itemClicked)="handleItemClicked()"
  ></fuse-navigation>
</div>
<fuse-sidebar [name]="sidebarCategoryKey" [fullWidth]="true" [hasBackdrop]="false" class="p-0">
  <app-mobile-main-menu-categories
    [key]="sidebarCategoryKey"
    [previouslyOpenedSidebars]="[sidebarMainKey]"
    [needRedirect]="needRedirect"
    (itemClicked)="handleItemClicked()"
  ></app-mobile-main-menu-categories>
</fuse-sidebar>
