import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VariantVO } from '../../../vo/search-product-vo';
import { FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-product-page-variant-selector',
  templateUrl: './product-page-variant-selector.component.html',
  styleUrls: ['./product-page-variant-selector.component.scss'],
})
export class ProductPageVariantSelectorComponent implements OnInit, OnDestroy {
  @Input() productVariants: VariantVO[];
  @Input() optionNameFields: NameField[];
  @Output() variantChange = new EventEmitter<VariantVO>();
  optionGroups: OptionGroup[] = [];
  form = this.formBuilder.group({
    groups: this.formBuilder.array([]),
  });
  isLoading = true;
  private unsubscribeAll = new Subject<void>();
  private readonly OPTION_VALUE_BASE = 'SHOPIFY_OPTION_VALUE_';
  private readonly OPTION_NAME_BASE = 'SHOPIFY_OPTION_NAME_';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.generateOptionGroups();
    this.addOptionGroupsToArray();
    this.emitValueAtInit();
    this.subscribeToValueChange();
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private emitValueAtInit(): void {
    this.variantChange.emit(this.getVariantFromForm());
  }

  private subscribeToValueChange(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => this.variantChange.emit(this.getVariantFromForm()));
  }

  private addOptionGroup(type: string, name: string, defaultValue: string): void {
    const group = this.formBuilder.group({
      value: [defaultValue],
      type: [type],
      name: [name],
    });
    this.optionGroup.push(group);
  }

  private generateOptionGroups(): void {
    this.productVariants.forEach((variant) => {
      const variantOptionValues = Object.entries(variant).filter(
        ([key, value]): boolean => key.includes(this.OPTION_VALUE_BASE) && !!value && value !== ''
      );
      variantOptionValues.forEach(([fullKey, value]) => {
        const index = Number(fullKey.replace(this.OPTION_VALUE_BASE, '')) - 1;
        if (!this.optionGroups[index]) {
          this.optionGroups[index] = {
            type: fullKey,
            name: this.optionNameFields.find((nameField) => nameField[0] === this.OPTION_NAME_BASE + (index + 1))[1],
            options: new Set<string>(),
          };
        }
        this.optionGroups[index].options.add(value);
      });
    });
    this.optionGroups = this.optionGroups.filter((group) => !!group);
  }

  private addOptionGroupsToArray(): void {
    this.optionGroups.forEach((option) =>
      this.addOptionGroup(option.type, option.name, this.productVariants[0][option.type])
    );
  }

  private getVariantFromForm(): VariantVO {
    const { groups } = this.form.value;
    return this.productVariants.find((variant) => {
      const variantOptionValues = Object.entries(variant).filter(
        ([key, value]): boolean => key.includes(this.OPTION_VALUE_BASE) && !!value && value !== ''
      );
      const optionValuesFromForm = groups.map((group) => [group['type'], group['value']]);
      return isEqual(variantOptionValues, optionValuesFromForm);
    });
  }

  get optionGroup(): FormArray {
    return this.form.controls['groups'] as FormArray;
  }
}

export interface OptionGroup {
  type: string;
  name: string;
  options: Set<string>;
}

export interface Option {
  type: string;
  value: string | number;
}

export type NameField = [string, any];
