<div class="wizard-category-mapping-container" fxLayout="column" fxLayoutGap="24px">
  <ng-container *conditionLoading="displayedItems$ | async">
    <ng-container *ngIf="displayedItems$ | async as displayedItems">
      <app-wizard-category-mapping-header></app-wizard-category-mapping-header>
      <ng-container *ngIf="displayedItems.data as items">
        <app-wizard-category-mapping-list
          [categoryItems]="items"
          (removeItem)="removeSingleMapping.emit($event)"
          (addMapping)="addSingleMapping.emit($event)"
          (showProducts)="showProducts.emit($event)"
        ></app-wizard-category-mapping-list>
        <app-simple-paginator-with-arrows
          [hasBorder]="false"
          [size]="paginator.size"
          [page]="paginator.page"
          [allItem]="paginator.allItemsCount"
          [currentlyShownItem]="items.length"
          [hasSizeSelector]="true"
          [sizes]="pageSizeOptions"
          (sizeChange)="paginator.changePageSize($event)"
          (next)="paginator.paginateNext()"
          (previous)="paginator.paginatePrev()"
        ></app-simple-paginator-with-arrows>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
