import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RestService } from '../../../service/rest/rest.service';
import { BillingService } from '../../../service/billing/billing.service';
import { NgForm } from '@angular/forms';
import { BillingInfoFormComponent } from '../../../shared/components/billing-info-form/billing-info-form.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../notification/notification.service';

@Component({
  selector: 'app-billing-information-change-dialog',
  templateUrl: './billing-information-change-dialog.component.html',
  styleUrls: ['./billing-information-change-dialog.component.scss'],
})
export class BillingInformationChangeDialogComponent implements OnInit {
  @ViewChild('appBilling', { static: true }) appBilling: BillingInfoFormComponent;
  billingSaveInProgress = false;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<BillingInformationChangeDialogComponent>,
    private billingService: BillingService,
    private _translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public billingInfoChanged(isChanged): void {
    this.dialogRef.close(isChanged);
  }

  public saveData(): void {
    this.billingSaveInProgress = true;
    const billingInfo = this.appBilling.billingInfoForm.getRawValue();
    if (this.appBilling.billingInfoForm.valid && billingInfo.zip != 0) {
      this.billingService.saveBillingInformation(billingInfo).subscribe(
        () => {
          this.dialogRef.close(true);
        },
        (error) => {
          this.billingSaveInProgress = false;
          console.log(error);
        }
      );
    } else {
      this.notificationService.error(this._translateService.instant('PROFILE_GLOBAL_MESSAGES.BILLING_INFO_ZIP_ZERO'));
      this.billingSaveInProgress = false;
    }
  }
}
