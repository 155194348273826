import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { getSelectedLanguageCode } from 'app/store/language/language.selector';
import { WidgetButton, WidgetTitle } from 'app/vo/widget';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'widgetTranslatePipe',
  standalone: true,
})
export class WidgetTranslatePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}
  transform(textToTranslate: (WidgetTitle | WidgetButton)[], pipeType: WidgetPipeType): string {
    let currentLang = 'en';
    this.store
      .select(getSelectedLanguageCode)
      .pipe(take(1))
      .subscribe((res) => (currentLang = res));

    const filteredText = textToTranslate.find((x) => x.languageCode === currentLang);

    return filteredText ? filteredText[pipeType] : textToTranslate.find((x) => x.languageCode === 'en')[pipeType];
  }
}

export type WidgetPipeType = 'title' | 'buttonText';
