import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from 'app/service/rest/microservices/spring-rest.service';
import { fetchAllPages } from 'app/utils/operator/fetch-all-pages';
import { SpringPage } from 'app/vo/pagination/spring-page';
import { ShippingRateDto } from 'app/vo/shipping-zones/shipping-rate.dto';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShippingRateRestService {
  constructor(private springRestService: SpringRestService) {}

  createShippingRate(shippingRateData: Omit<ShippingRateDto, 'id' | 'condition'>): Observable<ShippingRateDto> {
    return this.springRestService.post(MicroserviceNames.ORDER, `/v1/Shipping/Rate`, shippingRateData, undefined, true);
  }

  deleteShippingRate(shippingRateID: number): Observable<void> {
    return this.springRestService
      .delete(MicroserviceNames.ORDER, `/v1/Shipping/Rate/${shippingRateID}`, undefined, true)
      .pipe(map(() => {}));
  }

  patchShippingRate(shippingRateID: number, shippingRateData: Partial<ShippingRateDto>): Observable<ShippingRateDto> {
    return this.springRestService.patch(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Rate/${shippingRateID}`,
      shippingRateData,
      undefined,
      true
    );
  }

  getShippingRate(shippingRateID: number): Observable<ShippingRateDto> {
    return this.springRestService.get(MicroserviceNames.ORDER, `/v1/Shipping/Rate/${shippingRateID}`, undefined, true);
  }

  getShippingRates(from: number, size: number): Observable<SpringPage<ShippingRateDto>> {
    return this.springRestService.get(MicroserviceNames.ORDER, `/v1/Shipping/Rate`, { from, size }, true);
  }

  getAllShippingRates(): Observable<ShippingRateDto[]> {
    return this.getShippingRates(0, 100).pipe(fetchAllPages((page, size) => this.getShippingRates(page, size)));
  }

  getRatesForProfileByContinent(
    profileId: number,
    continentId: number,
    page: number,
    size: number
  ): Observable<SpringPage<ShippingRateDto>> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/${profileId}/Rates`,
      {
        page: page,
        size: size,
        continent: continentId,
      },
      true
    );
  }

  getAllShippingRatesForProfileByContinent(
    profileId: number,
    continentId: number,
    pageSize = 100
  ): Observable<ShippingRateDto[]> {
    return this.getRatesForProfileByContinent(profileId, continentId, 0, pageSize).pipe(
      fetchAllPages((page, size) => this.getRatesForProfileByContinent(profileId, continentId, page, size), pageSize)
    );
  }

  getRatesForProfileByLocation(
    profileId: number,
    locationId: number,
    page: number,
    size: number
  ): Observable<SpringPage<ShippingRateDto>> {
    return this.springRestService.get(
      MicroserviceNames.ORDER,
      `/v1/Shipping/Profile/${profileId}/Rates/CountriesLocation/${locationId}`,
      {
        page: page,
        size: size,
      },
      true
    );
  }

  getAllShippingRatesForProfileByLocation(
    profileId: number,
    locationId: number,
    pageSize = 100
  ): Observable<ShippingRateDto[]> {
    return this.getRatesForProfileByLocation(profileId, locationId, 0, pageSize).pipe(
      fetchAllPages((page, size) => this.getRatesForProfileByLocation(profileId, locationId, page, size), pageSize)
    );
  }
}
