<app-filter-selector
  [hasSearch]="true"
  [value]="(productSearchStore$ | async).temporaryFilter?.supplier || (productSearchStore$ | async).filter?.supplier"
  (valueChange)="handleSelectionChange($any($event))"
  [options]="options"
  [searchTerm]="searchTerm"
  (searchTermChange)="handleSearchTermChange($event)"
  (loadMore)="loadMore()"
  [totalCount]="totalNumber"
  [lazyLoad]="true"
></app-filter-selector>
