<div
  fxLayout="row"
  fxLayoutAlign="center center"
  class="icon-container"
  [style.width]="containerSize"
  [style.height]="containerSize"
  [class]="type + '-container'"
>
  <mat-icon [class]="[type, iconSizeClass]">{{ icon }}</mat-icon>
</div>
