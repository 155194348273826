import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyRetailersComponent } from './my-retailers.component';

export const routes: Routes = [
  {
    path: '',
    component: MyRetailersComponent,
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      {
        path: 'list',
        loadChildren: () => import('./my-retailers-list/my-retailers-list.module').then((m) => m.MyRetailersListModule),
        data: { label: 'MY_RETAILERS.TABS.RETAILERS', pageTitle: 'App - My Retailers - Retailers' },
      },
      {
        path: 'my-reviews',
        data: { label: 'MY_RETAILERS.TABS.REVIEWS', pageTitle: 'App - My Retailers - Reviews' },
        loadChildren: () =>
          import('./my-retailers-reviews/my-retailers-reviews.module').then((m) => m.MyRetailersReviewsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyRetailersRoutingModule {}
