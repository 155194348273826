<button
  mat-button
  class="syncee-white-button"
  datePickerDropdown
  [position]="[{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]"
  [dateFrom]="dateFrom || null"
  [dateTo]="dateTo || null"
  (dateFromChange)="dateFromChange.emit($event)"
  (dateToChange)="dateToChange.emit($event)"
  buttonWithIcon="keyboard_arrow_down"
  iconPosition="end"
>
  <div fxLayoutAlign="center center">
    <div>
      {{ selectedLabel | translate : { from: dateFrom?.toLocaleDateString(), to: dateTo?.toLocaleDateString() } }}
    </div>
  </div>
</button>
