import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProductSearchHelper } from '../../../store/product-search/product-search.reducer';
import { SetHelperAction } from '../../../store/product-search/product-search.action';
import { FullMarketplaceFilterVO, MarketplaceFilter } from '../../../vo/search-product-vo';
import { CategoryVo } from '../../../vo/category-vo';
import { CategoryService } from '../../../service/product-search/category.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SuppliersService } from '../../../service/suppliers/suppliers.service';
import { BootstrapService } from '../../../service/bootstrap/bootstrap.service';
import { CountryNameToCodePipe } from '../../pipes/legacy/country-name-to-code.pipe';
import { EXTRA_SHIPPING_FILTERS } from '../../../utils/Constants';
import { Utils } from 'app/utils/utils';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexModule, ExtendedModule, TranslateModule, MatChipsModule, MatIconModule],
  providers: [CountryNameToCodePipe],
})
export class FilterChipsComponent implements OnDestroy, OnChanges {
  @Output() filterRemove = new EventEmitter<keyof FullMarketplaceFilterVO>();
  @Output() clear = new EventEmitter<void>();
  @Input() filter: MarketplaceFilter;
  @Input() hasClearAll = true;
  @Input() hasRemoveChip = true;
  filterChips: FilterChip[] = [];
  private readonly currentLang: string;
  private helperStore$: Observable<Partial<ProductSearchHelper>>;
  private categoryStore$: Observable<CategoryVo>;
  private unsubscribeAll: Subject<void>;
  private omitKeys: (keyof FullMarketplaceFilterVO)[] = ['from', 'size', 'sortOrder', 'sortField'];
  private categoryTree: CategoryVo;

  constructor(
    private store: Store<AppState>,
    private categoryService: CategoryService,
    private translateService: TranslateService,
    private suppliersService: SuppliersService,
    private bootstrapService: BootstrapService,
    private countryNameToCodePipe: CountryNameToCodePipe
  ) {
    this.unsubscribeAll = new Subject();
    this.helperStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.helpers)
    );
    this.categoryStore$ = this.store.pipe(
      select((state) => state.categories),
      select((state) => state.categories)
    );
    this.currentLang = this.translateService.currentLang;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!Utils.isNullOrUndefined(this.categoryTree)) {
      this.handleFilterChange(this.filter);
    } else {
      this.subscribeToBootstrapCategories();
    }
  }

  handleRemove(key: keyof FullMarketplaceFilterVO): void {
    this.filterRemove.emit(key);
  }

  handleClearAll(): void {
    this.clear.emit();
  }

  private subscribeToBootstrapCategories(): void {
    this.bootstrapService.categoriesStored.subscribe((isStored) => {
      if (isStored) {
        this.categoryTree = this.getCategoryTree();
        this.handleFilterChange(this.filter);
      }
    });
  }

  private handleFilterChange(filters: MarketplaceFilter): void {
    if (!Utils.isNullOrUndefined(filters)) {
      this.filterChips = this.mapFilterToChipsArray(filters);
    } else {
      this.filterChips = [];
    }
  }

  private mapFilterToChipsArray(filters: Partial<FullMarketplaceFilterVO>): FilterChip[] {
    const keys = (Object.keys(filters) as Array<keyof FullMarketplaceFilterVO>).filter(
      (key) => !this.omitKeys.includes(key)
    );
    const chipArray: FilterChip[] = [];
    keys.forEach((key) => {
      if (!Utils.isNullOrUndefined(filters[key])) {
        chipArray.push({ key: key, value: this.whiteLabelChipValue(key, filters[key]) });
      }
    });
    return chipArray;
  }

  private whiteLabelChipValue(key: keyof FullMarketplaceFilterVO, value: any): any {
    switch (key) {
      case 'category': {
        return this.whiteLabelCategory(value);
      }
      case 'supplier': {
        return this.whiteLabelSupplier(Number(value));
      }
      case 'approveType': {
        return this.translateService.instant(
          `FILTER_SIDEBAR.SELECTOR.SUPPLIER_TYPE.APPROVE_NEEDED.${value.toUpperCase()}`
        );
      }
      case 'lang': {
        return this.translateService.instant(`GENERAL.LANGUAGES.${value.toUpperCase()}`);
      }
      case 'premium': {
        return this.translateService.instant(`FILTER_SIDEBAR.SELECTOR.SUPPLIER_TYPE.PREMIUM.${value.toUpperCase()}`);
      }
      case 'shipsFrom': {
        return this.translateService.instant(`COUNTRIES.${this.countryNameToCodePipe.transform(value).toUpperCase()}`);
      }
      case 'shipsTo': {
        return this.whiteLabelShipsTo(value);
      }
      case 'shippingType': {
        return this.translateService.instant(`FILTER_SIDEBAR.SELECTOR.SHIPPING_TYPE.${value.toUpperCase()}`);
      }
      case 'stock': {
        return this.translateService.instant(`FILTER_SIDEBAR.SELECTOR.STOCK_STATUS.${value.toUpperCase()}`);
      }
      case 'autoOrder': {
        return this.translateService.instant(`FILTER_SIDEBAR.SELECTOR.AUTO_ORDER.${value.toUpperCase()}`);
      }
      default: {
        return value;
      }
    }
  }

  private whiteLabelCategory(id: number): string {
    let category: CategoryVo;
    this.helperStore$.pipe(take(1)).subscribe((helpers) => {
      if (
        !Utils.isNullOrUndefined(helpers) &&
        !Utils.isNullOrUndefined(helpers.category) &&
        Number(helpers.category.id) === id
      ) {
        category = helpers.category;
      }
    });
    return Utils.isNullOrUndefined(category)
      ? this.getCategoryName(this.searchForCategory(this.categoryTree, id))
      : this.getCategoryName(category);
  }

  private getCategoryTree(): CategoryVo {
    let category: CategoryVo;
    this.categoryStore$.pipe(take(1)).subscribe((cat) => (category = cat));
    return category;
  }

  private searchForCategory(tree: CategoryVo, id: number): CategoryVo {
    return this.categoryService.searchInCategories(tree, id);
  }

  private getCategoryName(category: CategoryVo): string {
    return this.categoryService.getNameForCategory(category, this.currentLang);
  }

  private whiteLabelSupplier(id: number): string {
    return this.getSupplierName(id);
  }

  private whiteLabelShipsTo(value: string): string {
    if (EXTRA_SHIPPING_FILTERS.some((filter) => filter.name === value)) {
      return this.translateService.instant(`CONTINENTS.${value.toUpperCase()}`);
    } else {
      return this.translateService.instant(`COUNTRIES.${this.countryNameToCodePipe.transform(value).toUpperCase()}`);
    }
  }

  private getSupplierName(id: number): string {
    const supplierNameFromStore = this.getSupplierNameFromHelperStore(id);
    if (supplierNameFromStore !== undefined) {
      return supplierNameFromStore;
    } else {
      this.handleSupplierNameNotfound(id);
      return id.toString();
    }
  }

  private handleSupplierNameNotfound(id: number): void {
    this.fetchSupplierName(id).subscribe((name) => {
      // this.store.dispatch(new SetHelperAction({ supplier: { name: name, id } }));
      this.handleFilterChange(this.filter);
    });
  }

  private getSupplierNameFromHelperStore(id: number): string {
    let supplier: string;
    this.helperStore$.pipe(take(1)).subscribe((helpers) => {
      if (
        !Utils.isNullOrUndefined(helpers) &&
        !Utils.isNullOrUndefined(helpers.supplier) &&
        Number(helpers.supplier.id) === id
      ) {
        supplier = helpers.supplier.name;
      }
    });
    return supplier;
  }

  private fetchSupplierName(id: number): Observable<string> {
    return this.suppliersService.getSupplierData(id).pipe(map((data) => data.companyName));
  }
}

interface FilterChip {
  key: keyof FullMarketplaceFilterVO;
  value: any;
}
