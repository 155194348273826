import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-add-item-button',
  templateUrl: './filter-add-item-button.component.html',
  styleUrls: ['./filter-add-item-button.component.scss'],
})
export class FilterAddItemButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
