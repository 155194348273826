import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { Currencies, CurrencyExchange } from 'app/service/currency-service/currency.service';
import { retailerCurrencySelector, selectedCurrenciesSelector } from 'app/store/currency/currency.selector';
import { Observable, Subject, takeUntil, map } from 'rxjs';

@Pipe({
  name: 'currencyExchangeTo',
  standalone: true,
})
export class CurrencyExchangeToPipe implements PipeTransform, OnDestroy {
  private readonly currencyList$: Observable<CurrencyExchange[]>;
  private readonly unsubscribeAll: Subject<void>;
  private readonly THRESHOLD = 5;

  constructor(private store: Store<AppState>) {
    this.currencyList$ = this.store.select(selectedCurrenciesSelector);
    this.unsubscribeAll = new Subject<void>();
  }

  transform(value: number, from: string, to: string): Observable<number> {
    return this.currencyList$.pipe(
      takeUntil(this.unsubscribeAll),
      map((currenciesList) => {
        const exchange = currenciesList?.find((curr) => curr.from === from && curr.to === to);
        const exchangeRate = exchange ? exchange.rate : 1;
        return { value: value * exchangeRate, exchangeValue: exchangeRate };
      }),
      map((values) => {
        return values.exchangeValue > this.THRESHOLD ? Math.floor(values.value) : values.value;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
