<app-card-with-small-icon
  (click)="handleCardClick()"
  [title]="cardData | platformTypeToName : type"
  [description]="cardData.description | translate"
>
  <app-platform-list-logo icon [logoType]="'ICON'" [platform]="cardData"></app-platform-list-logo>
  <div
    afterTitle
    *ngIf="isInstalled && type === 'PLATFORM'; else appBadge"
    class="ml-8 badge typography-body-small-1"
    platformBadge
    [name]="'INSTALLED'"
  >
    {{ 'BADGES.CONNECTED' | translate }}
  </div>
</app-card-with-small-icon>

<ng-template #appBadge>
  <div
    *ngIf="isInstalled && type === 'SYNCEE_APPS'"
    class="badge typography-body-small-1"
    platformBadge
    [name]="'INSTALLED'"
  >
    {{ 'BADGES.INSTALLED' | translate }}
  </div>
</ng-template>
