<app-dialog-multi-wrapper
  [closeButtonText]="modalDeleteClose"
  [confirmButtonText]="modalDeleteConfirm"
  type="danger"
  (confirmClick)="onSubmit()"
  [title]="modalTitle"
>
  <div class="text-center" [style.text-wrap]="'balance'" [innerHTML]="modalSubject"></div>
  <ng-container *ngIf="_hasTextConfirm">
    <div class="my-h4">{{ modalDeleteMessage }}</div>
    <mat-form-field class="width-100 select-without-label" appearance="fill">
      <input name="inputDelete" #inputDel="ngModel" matInput [(ngModel)]="_inputDelete" required deleteRequired />
      <mat-error *ngIf="inputDel.errors?.required">
        {{ 'SHARED_MODULE.DELETE_MODAL.INPUT' | translate }}
      </mat-error>
      <mat-error *ngIf="!inputDel.errors?.required && inputDel.errors?.deleteRequired">
        {{ 'SHARED_MODULE.DELETE_MODAL.WRONG' | translate }}
      </mat-error>
    </mat-form-field>
  </ng-container>
</app-dialog-multi-wrapper>
