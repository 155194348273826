<button
  mat-icon-button
  class="favourite favourite-heart custom-elevation-z1"
  roleOpenRegistration
  [role]="RolesEnum.RETAILER"
  [class.selected]="selected"
  (click)="onFavoriteClicked(selected)"
>
  <mat-icon class="s-20">{{ isLoading ? 'autorenew' : selected ? 'favorite' : 'favorite_border' }}</mat-icon>
</button>
