import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, FlexModule],
})
export class LoadingSpinnerComponent {
  @Input() centered = false;
  @Input() size = 60;
  @Input() message: string;

  constructor() {}
}
