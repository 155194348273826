<ng-template #header_center>
  <div fxLayout="column" fxLayoutGap="12px">
    <h4 class="centered-text report-title">
      {{ 'QUESTION_ANSWER_DIALOG.REPORT_TITLE' | translate }}
    </h4>
    <div class="centered-text">{{ 'QUESTION_ANSWER_DIALOG.REPORT_SUBTITLE' | translate }}</div>
  </div>
</ng-template>

<app-custom-dialog-wrapper [hideCloseBtn]="true" [headerLeftRef]="header_center" [headerCentered]="true">
  <div class="report-container">
    <mat-dialog-content fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngFor="let answer of answers">
        <mat-checkbox (change)="selectAnswer($event, answer)" [checked]="selectedAnswers.isSelected(answer)">
          <div class="checkbox-text">
            {{ 'QUESTION.' + data.questionCode + '.' + answer.answer | translate }}
          </div>
        </mat-checkbox>
        <mat-icon *ngIf="isError && answer.custom && isCustomSelected" class="error-icon">error</mat-icon>
      </div>
      <div class="w-100p">
        <textarea
          *ngIf="isCustomSelected"
          [ngClass]="{ 'custom-answer-text-area-error': isError }"
          class="custom-answer-text-area"
          (input)="handleInputChange($event)"
          [placeholder]="
            isError
              ? ('QUESTION_ANSWER_DIALOG.PLACEHOLDER_ERROR' | translate)
              : ('QUESTION_ANSWER_DIALOG.PLACEHOLDER' | translate)
          "
        ></textarea>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
      <button mat-button mat-dialog-close="">{{ 'QUESTION_ANSWER_DIALOG.CLOSE' | translate }}</button>
      <button
        *ngIf="!isLoading; else spinner"
        mat-button
        class="syncee-blue-button"
        (click)="sendReport()"
        [disabled]="selectedAnswers.isEmpty()"
      >
        {{ 'QUESTION_ANSWER_DIALOG.CONFIRM' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</app-custom-dialog-wrapper>

<ng-template #spinner>
  <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>
