import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { RestService } from '../../../service/rest/rest.service';
import { OnBoardTypes } from '../../../utils/onboardConstants';
import { MatDialog } from '@angular/material/dialog';
import { OnboardDialogComponent, OnboardDialogData } from './onboard-dialog/onboard-dialog.component';
import { I18nBackendJson } from '../../../vo/translation/i18n-backend-json';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { snippetCompletedStepsSelector } from '../../../store/snippet/snippet.selector';
import { MicroserviceNames, SpringRestService } from '../../../service/rest/microservices/spring-rest.service';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class OnboardService implements OnDestroy {
  public removeOnboardPanel = false;
  private _initOnboard = false;
  onBoardEvents = {};
  private _selectedOnboard: OnboardItem = null;
  private _isInitialized = false;
  public initialized = new EventEmitter<void>();
  private completedStepsFromStore: number[] = [];
  private unsubscribeAll: Subject<void>;

  public onboardTypes = OnBoardTypes;

  get initOnboard(): boolean {
    return this._initOnboard;
  }

  set initOnboard(val: boolean) {
    this._initOnboard = val;
  }

  get completedSteps(): any[] {
    return this.completedStepsFromStore;
  }

  constructor(
    private restService: RestService,
    private springRestService: SpringRestService,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {
    this.unsubscribeAll = new Subject<void>();
    this.subscribeToStore();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToStore(): void {
    this.store
      .select(snippetCompletedStepsSelector)
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((steps) => !!steps),
        map((steps) => steps.map((step) => step.snippetsId))
      )
      .subscribe((steps) => (this.completedStepsFromStore = steps));
  }

  public getCompletedSteps(userId = null): Observable<number[]> {
    return new Observable<any>((observe) => {
      this.getDoneSteps(userId).subscribe(
        (elem) => {
          observe.next(elem);
          observe.complete();
        },
        (error) => {
          observe.error(error);
        }
      );
    });
  }

  public setOnBoardEvents(onboardItems): void {
    onboardItems.forEach((elem) => {
      this.onBoardEvents[elem.stepEvent] = elem.id;
    });
  }
  
  public getOnboardTree(version = 2): Observable<OnboardItem[]> {
    return this.springRestService.get(MicroserviceNames.USER, `/Snippet/V${version}/Tree`, undefined, true);
  }

  public getDoneSteps(userId = null): Observable<any> {
    return this.restService
      .get(`PmsSnippetService/getUserSnippets${userId ? '?userId=' + userId : ''}`)
      .pipe(map((response) => response.getData()));
  }

  public deleteOnboardItem(parentId): Observable<any> {
    return this.restService
      .post(`PmsSnippetService/delete`, { id: parentId })
      .pipe(map((response) => response.getData()));
  }

  public updateOnboardItem(onboardItem): Observable<any> {
    return this.restService
      .post(`PmsSnippetService/updateSnippets`, onboardItem)
      .pipe(map((response) => response.getData()));
  }

  public addUserToSnippet(id, userId = null): Observable<any> {
    return this.restService
      .post(
        `PmsSnippetService/addUserToSnippet`,
        Utils.isNullOrUndefined(userId) ? { id: id } : { id: id, userId: userId }
      )
      .pipe(map((response) => response.getData()));
  }

  public addUserToSnippets(snippetIds): Observable<any> {
    const body = { ids: snippetIds };
    return this.restService
      .post(`PmsSnippetService/addUserToSnippets`, body)
      .pipe(map((response) => response.getData()));
  }

  public getOnboardingStatus(): Observable<boolean> {
    return this.restService.get(`PmsSnippetService/getStatus`).pipe(map((response) => response.getFirstData()));
  }

  public setOnboardingStatus(isActive): Observable<any> {
    return this.restService
      .post(`PmsSnippetService/setStatus`, { active: isActive })
      .pipe(map((response) => response.getData()));
  }

  public openOnboardDialog(): Observable<any> {
    const data: OnboardDialogData = {
      selectedOnboard: this.selectedOnboard,
    };
    return this.dialog
      .open<OnboardDialogComponent, OnboardDialogData>(OnboardDialogComponent, {
        width: '1100px',
        data: data,
        autoFocus: false,
        panelClass: 'custom-modal-full-width-lt-md',
      })
      .afterClosed();
  }

  get selectedOnboard(): OnboardItem {
    return this._selectedOnboard;
  }

  set selectedOnboard(value: OnboardItem) {
    if (!this._isInitialized && !Utils.isNullOrUndefined(value)) {
      this._isInitialized = true;
      this._selectedOnboard = value;
      this.initialized.emit();
    } else {
      this._selectedOnboard = value;
    }
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }
}

export interface OnboardItem {
  id: number;
  url: string;
  type: string;
  description: {
    en: string;
    hu: string;
  };
  title: {
    en: string;
    hu: string;
  };
  active: boolean;
  deleteDate: string;
  steps: OnboardItemStep[];
}

export interface OnboardItemStep {
  id: number;
  parent: number;
  url: string;
  description: I18nBackendJson;
  title: I18nBackendJson;
  buttonText: I18nBackendJson;
  active: boolean;
  stepEvent: string;
  orderInSnippet: number;
  continueUrl: string;
  helpCenterUrl: string;
  deleteDate: string;
  videoUrl: string;
}

export type OnboardDialogSpecialContentType = 'review' | 'upsell';

export interface OnboardDialogSpecialContentItem {
  key: number;
  type: OnboardDialogSpecialContentType;
}
