// export const countries =
//     {
//         'Europe': [
//             // 'All',
//             'Albania',
//             'Andorra',
//             'Austria',
//             'Belarus',
//             'Belgium',
//             'Bosnia and Herzegovina',
//             'Bulgaria',
//             'Croatia',
//             'Cyprus',
//             'Czech Republic',
//             'Denmark',
//             'East Germany',
//             'Estonia',
//             'Faroe Islands',
//             'Finland',
//             'France',
//             'Germany',
//             'Gibraltar',
//             'Greece',
//             'Guernsey',
//             'Hungary',
//             'Iceland',
//             'Ireland',
//             'Isle of Man',
//             'Italy',
//             'Jersey',
//             'Latvia',
//             'Liechtenstein',
//             'Lithuania',
//             'Luxembourg',
//             'Macedonia',
//             'Malta',
//             'Metropolitan France',
//             'Moldova',
//             'Monaco',
//             'Montenegro',
//             'Netherlands',
//             'Norway',
//             'Poland',
//             'Portugal',
//             'Romania',
//             'Russia',
//             'San Marino',
//             'Serbia',
//             'Serbia and Montenegro',
//             'Slovakia',
//             'Slovenia',
//             'Spain',
//             'Svalbard and Jan Mayen',
//             'Sweden',
//             'Switzerland',
//             'Ukraine',
//             'Union of Soviet Socialist Republics',
//             'United Kingdom',
//             'Vatican City',
//             'Åland Islands'
//         ],
//         'Americas': [
//            // 'All',
//             'Anguilla',
//             'Antigua and Barbuda',
//             'Argentina',
//             'Aruba',
//             'Bahamas',
//             'Barbados',
//             'Belize',
//             'Bermuda',
//             'Bolivia',
//             'Brazil',
//             'British Virgin Islands',
//             'Canada',
//             'Cayman Islands',
//             'Chile',
//             'Colombia',
//             'Costa Rica',
//             'Cuba',
//             'Dominica',
//             'Dominican Republic',
//             'Ecuador',
//             'El Salvador',
//             'Falkland Islands',
//             'French Guiana',
//             'Greenland',
//             'Grenada',
//             'Guadeloupe',
//             'Guatemala',
//             'Guyana',
//             'Haiti',
//             'Honduras',
//             'Jamaica',
//             'Martinique',
//             'Mexico',
//             'Montserrat',
//             'Netherlands Antilles',
//             'Nicaragua',
//             'Panama',
//             'Paraguay',
//             'Peru',
//             'Puerto Rico',
//             'Saint Barthélemy',
//             'Saint Kitts and Nevis',
//             'Saint Lucia',
//             'Saint Martin',
//             'Saint Pierre and Miquelon',
//             'Saint Vincent and the Grenadines',
//             'Suriname',
//             'Trinidad and Tobago',
//             'Turks and Caicos Islands',
//             'U.S. Virgin Islands',
//             'United States',
//             'Uruguay',
//             'Venezuela'
//         ],
//         'Asia': [
//             // 'All',
//             'Afghanistan',
//             'Armenia',
//             'Azerbaijan',
//             'Bahrain',
//             'Bangladesh',
//             'Bhutan',
//             'Brunei',
//             'Cambodia',
//             'China',
//             'Georgia',
//             'Hong Kong',
//             'India',
//             'Indonesia',
//             'Iran',
//             'Iraq',
//             'Israel',
//             'Japan',
//             'Jordan',
//             'Kazakhstan',
//             'Kuwait',
//             'Kyrgyzstan',
//             'Laos',
//             'Lebanon',
//             'Macau SAR China',
//             'Malaysia',
//             'Maldives',
//             'Mongolia',
//             'Myanmar [Burma]',
//             'Nepal',
//             'Neutral Zone',
//             'North Korea',
//             'Oman',
//             'Pakistan',
//             'Palestinian Territories',
//             'People\'s Democratic Republic of Yemen',
//             'Philippines',
//             'Qatar',
//             'Saudi Arabia',
//             'Singapore',
//             'South Korea',
//             'Sri Lanka',
//             'Syria',
//             'Taiwan',
//             'Tajikistan',
//             'Thailand',
//             'Timor-Leste',
//             'Turkey',
//             'Turkmenistan',
//             'United Arab Emirates',
//             'Uzbekistan',
//             'Vietnam',
//             'Yemen'
//         ],
//         'Oceania': [
//             // 'All',
//             'American Samoa',
//             'Antarctica',
//             'Australia',
//             'Bouvet Island',
//             'British Indian Ocean Territory',
//             'Christmas Island',
//             'Cocos [Keeling] Islands',
//             'Cook Islands',
//             'Fiji',
//             'French Polynesia',
//             'French Southern Territories',
//             'Guam',
//             'Heard Island and McDonald Islands',
//             'Kiribati',
//             'Marshall Islands',
//             'Micronesia',
//             'Nauru',
//             'New Caledonia',
//             'New Zealand',
//             'Niue',
//             'Norfolk Island',
//             'Northern Mariana Islands',
//             'Palau',
//             'Papua New Guinea',
//             'Pitcairn Islands',
//             'Samoa',
//             'Solomon Islands',
//             'South Georgia and the South Sandwich Islands',
//             'Tokelau',
//             'Tonga',
//             'Tuvalu',
//             'U.S. Minor Outlying Islands',
//             'Vanuatu',
//             'Wallis and Futuna'
//         ],
//         'Africa': [
//             // 'All',
//             'Algeria',
//             'Angola',
//             'Benin',
//             'Botswana',
//             'Burkina Faso',
//             'Burundi',
//             'Cameroon',
//             'Cape Verde',
//             'Central African Republic',
//             'Chad',
//             'Comoros',
//             'Congo - Brazzaville',
//             'Congo - Kinshasa',
//             'Côte d’Ivoire',
//             'Djibouti',
//             'Egypt',
//             'Equatorial Guinea',
//             'Eritrea',
//             'Ethiopia',
//             'Gabon',
//             'Gambia',
//             'Ghana',
//             'Guinea',
//             'Guinea-Bissau',
//             'Kenya',
//             'Lesotho',
//             'Liberia',
//             'Libya',
//             'Madagascar',
//             'Malawi',
//             'Mali',
//             'Mauritania',
//             'Mauritius',
//             'Mayotte',
//             'Morocco',
//             'Mozambique',
//             'Namibia',
//             'Niger',
//             'Nigeria',
//             'Rwanda',
//             'Réunion',
//             'Saint Helena',
//             'Senegal',
//             'Seychelles',
//             'Sierra Leone',
//             'Somalia',
//             'South Africa',
//             'Sudan',
//             'Swaziland',
//             'São Tomé and Príncipe',
//             'Tanzania',
//             'Togo',
//             'Tunisia',
//             'Uganda',
//             'Western Sahara',
//             'Zambia',
//             'Zimbabwe'
//         ]
//     };

export interface Continents {
  [continent: string]: CountryNode[];
}

export interface CountryNode {
  name: string;
  code: string;
  children?: CountryNode[];
}

export type Continent = (typeof CONTINENTS)[number];

export const CONTINENTS = ['Europe', 'Asia', 'Africa', 'Oceania', 'North America', 'South America'] as const;

export const newCountries: Continents = {
  Europe: [
    {
      name: 'Åland Islands',
      code: 'AX',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Belarus',
      code: 'BY',
    },
    {
      name: 'Belgium',
      code: 'BE',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Croatia',
      code: 'HR',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'Germany',
      code: 'DE',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Greece',
      code: 'GR',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Holy See',
      code: 'VA',
    },
    {
      name: 'Hungary',
      code: 'HU',
    },
    {
      name: 'Iceland',
      code: 'IS',
    },
    {
      name: 'Ireland',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
    },
    {
      name: 'Italy',
      code: 'IT',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Latvia',
      code: 'LV',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Macedonia',
      code: 'MK',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Moldova',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Netherlands',
      code: 'NL',
    },
    {
      name: 'Norway',
      code: 'NO',
    },
    {
      name: 'Poland',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Republic of Kosovo',
      code: 'XK',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Russia',
      code: 'RU',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Slovakia',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      code: 'CH',
    },
    {
      name: 'Ukraine',
      code: 'UA',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
    },
  ],
  'North America': [
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bonaire',
      code: 'BQ',
    },
    {
      name: 'Virgin Islands (British)',
      code: 'VG',
    },
    {
      name: 'Virgin Islands (U.S.)',
      code: 'VI',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Greenland',
      code: 'GL',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mexico',
      code: 'MX',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
    },
    {
      name: 'Saint Martin (French part)',
      code: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
    },
    {
      name: 'Sint Maarten (Dutch part)',
      code: 'SX',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
    },
    {
      name: 'United States',
      code: 'US',
      children: [
        {
          name: 'Alabama',
          code: 'US-AL',
        },
        {
          name: 'Alaska',
          code: 'US-AK',
        },
        {
          name: 'American Samoa',
          code: 'US-AS',
        },
        {
          name: 'Arizona',
          code: 'US-AZ',
        },
        {
          name: 'Arkansas',
          code: 'US-AR',
        },
        {
          name: 'California',
          code: 'US-CA',
        },
        {
          name: 'Colorado',
          code: 'US-CO',
        },
        {
          name: 'Connecticut',
          code: 'US-CT',
        },
        {
          name: 'Delaware',
          code: 'US-DE',
        },
        {
          name: 'District Of Columbia',
          code: 'US-DC',
        },
        {
          name: 'Federated States Of Micronesia',
          code: 'US-FM',
        },
        {
          name: 'Florida',
          code: 'US-FL',
        },
        {
          name: 'Georgia',
          code: 'US-GA',
        },
        {
          name: 'Guam',
          code: 'US-GU',
        },
        {
          name: 'Hawaii',
          code: 'US-HI',
        },
        {
          name: 'Idaho',
          code: 'US-ID',
        },
        {
          name: 'Illinois',
          code: 'US-IL',
        },
        {
          name: 'Indiana',
          code: 'US-IN',
        },
        {
          name: 'Iowa',
          code: 'US-IA',
        },
        {
          name: 'Kansas',
          code: 'US-KS',
        },
        {
          name: 'Kentucky',
          code: 'US-KY',
        },
        {
          name: 'Louisiana',
          code: 'US-LA',
        },
        {
          name: 'Maine',
          code: 'US-ME',
        },
        {
          name: 'Marshall Islands',
          code: 'US-MH',
        },
        {
          name: 'Maryland',
          code: 'US-MD',
        },
        {
          name: 'Massachusetts',
          code: 'US-MA',
        },
        {
          name: 'Michigan',
          code: 'US-MI',
        },
        {
          name: 'Minnesota',
          code: 'US-MN',
        },
        {
          name: 'Mississippi',
          code: 'US-MS',
        },
        {
          name: 'Missouri',
          code: 'US-MO',
        },
        {
          name: 'Montana',
          code: 'US-MT',
        },
        {
          name: 'Nebraska',
          code: 'US-NE',
        },
        {
          name: 'Nevada',
          code: 'US-NV',
        },
        {
          name: 'New Hampshire',
          code: 'US-NH',
        },
        {
          name: 'New Jersey',
          code: 'US-NJ',
        },
        {
          name: 'New Mexico',
          code: 'US-NM',
        },
        {
          name: 'New York',
          code: 'US-NY',
        },
        {
          name: 'North Carolina',
          code: 'US-NC',
        },
        {
          name: 'North Dakota',
          code: 'US-ND',
        },
        {
          name: 'Northern Mariana Islands',
          code: 'US-MP',
        },
        {
          name: 'Ohio',
          code: 'US-OH',
        },
        {
          name: 'Oklahoma',
          code: 'US-OK',
        },
        {
          name: 'Oregon',
          code: 'US-OR',
        },
        {
          name: 'Palau',
          code: 'US-PW',
        },
        {
          name: 'Pennsylvania',
          code: 'US-PA',
        },
        {
          name: 'Puerto Rico',
          code: 'US-PR',
        },
        {
          name: 'Rhode Island',
          code: 'US-RI',
        },
        {
          name: 'South Carolina',
          code: 'US-SC',
        },
        {
          name: 'South Dakota',
          code: 'US-SD',
        },
        {
          name: 'Tennessee',
          code: 'US-TN',
        },
        {
          name: 'Texas',
          code: 'US-TX',
        },
        {
          name: 'Utah',
          code: 'US-UT',
        },
        {
          name: 'Vermont',
          code: 'US-VT',
        },
        {
          name: 'Virgin Islands',
          code: 'US-VI',
        },
        {
          name: 'Virginia',
          code: 'US-VA',
        },
        {
          name: 'Washington',
          code: 'US-WA',
        },
        {
          name: 'West Virginia',
          code: 'US-WV',
        },
        {
          name: 'Wisconsin',
          code: 'US-WI',
        },
        {
          name: 'Wyoming',
          code: 'US-WY',
        },
      ],
    },
  ],
  'South America': [
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Brazil',
      code: 'BR',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Falkland Islands',
      code: 'FK',
    },
    {
      name: 'French Guiana',
      code: 'GF',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
  ],
  Asia: [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
    },
    {
      name: 'Bahrain',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
    },
    {
      name: 'Cambodia',
      code: 'KH',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
    },
    {
      name: 'Cocos Islands',
      code: 'CC',
    },
    {
      name: 'Georgia',
      code: 'GE',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      code: 'ID',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Iraq',
      code: 'IQ',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Japan',
      code: 'JP',
    },
    {
      name: 'Jordan',
      code: 'JO',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
    },
    {
      name: "Lao People's Democratic Republic",
      code: 'LA',
    },
    {
      name: 'Lebanon',
      code: 'LB',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Malaysia',
      code: 'MY',
    },
    {
      name: 'Maldives',
      code: 'MV',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Myanmar',
      code: 'MM',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'North Korea',
      code: 'KP',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palestine, State of',
      code: 'PS',
    },
    {
      name: 'Philippines',
      code: 'PH',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
    },
    {
      name: 'Singapore',
      code: 'SG',
    },
    {
      name: 'South Korea',
      code: 'KR',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Syrian Arab Republic',
      code: 'SY',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
    },
    {
      name: 'Thailand',
      code: 'TH',
    },
    {
      name: 'Turkey',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
  ],
  Africa: [
    {
      name: 'Algeria',
      code: 'DZ',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Cameroon',
      code: 'CM',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
    },
    {
      name: 'Chad',
      code: 'TD',
    },
    {
      name: 'Comoros',
      code: 'KM',
    },
    {
      name: 'Congo',
      code: 'CG',
    },
    {
      name: 'Congo (Democratic Republic)',
      code: 'CD',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
    },
    {
      name: 'Egypt',
      code: 'EG',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    {
      name: "Côte d'Ivoire",
      code: 'CI',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libya',
      code: 'LY',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Mauritania',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Morocco',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Réunion',
      code: 'RE',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      code: 'SH',
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'South Africa',
      code: 'ZA',
    },
    {
      name: 'South Sudan',
      code: 'SS',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tunisia',
      code: 'TN',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
  ],
  Oceania: [
    {
      name: 'Antarctica',
      code: 'AQ',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
    },
    {
      name: 'American Samoa',
      code: 'AS',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'UM',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
    },
    {
      name: 'Micronesia',
      code: 'FM',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
    },
  ],
};

export const STATES_CODES = [
  'US-AL',
  'US-AK',
  'US-AS',
  'US-AZ',
  'US-AR',
  'US-CA',
  'US-CO',
  'US-CT',
  'US-DE',
  'US-DC',
  'US-FM',
  'US-FL',
  'US-GA',
  'US-GU',
  'US-HI',
  'US-ID',
  'US-IL',
  'US-IN',
  'US-IA',
  'US-KS',
  'US-KY',
  'US-LA',
  'US-ME',
  'US-MH',
  'US-MD',
  'US-MA',
  'US-MI',
  'US-MN',
  'US-MS',
  'US-MO',
  'US-MT',
  'US-NE',
  'US-NV',
  'US-NH',
  'US-NJ',
  'US-NM',
  'US-NY',
  'US-NC',
  'US-ND',
  'US-MP',
  'US-OH',
  'US-OK',
  'US-OR',
  'US-PW',
  'US-PA',
  'US-PR',
  'US-RI',
  'US-SC',
  'US-SD',
  'US-TN',
  'US-TX',
  'US-UT',
  'US-VT',
  'US-VI',
  'US-VA',
  'US-WA',
  'US-WV',
  'US-WI',
  'US-WY',
];
