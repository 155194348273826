<div class="add-to-catalog-menu-container">
  <h4>{{ getDialogTitle() }}</h4>
  <div class="typography-body-1 domain">{{ selectedDomianName }}</div>

  <div *ngIf="!isNewCatalogPanelVisible">
    <div>
      <mat-form-field appearance="fill" class="input-without-padding domain-selector">
        <mat-label>{{
          'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.SELECT_IMPORT_LIST' | translate
        }}</mat-label>
        <mat-select [(ngModel)]="catalogMenuVO.catalog">
          <mat-option *ngFor="let item of catalogOptions" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div id="new-catalog-button-container">
      <button mat-button (click)="showPanel()" class="syncee-light-blue-button" buttonWithIcon="add">
        {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.CREATE_IMPORT_LIST' | translate }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="isNewCatalogPanelVisible">
    <div class="w-100-p" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="16px">
      <div>
        <mat-form-field
          class="input-without-padding"
          appearance="fill"
          [fxHide]="data.createEmptyCatalog && (!catalogOptions || catalogOptions.length === 0) ? 'true' : 'false'"
        >
          <mat-label>{{
            'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.NAME_IMPORT_LIST' | translate
          }}</mat-label>
          <input matInput required [(ngModel)]="catalogMenuVO.name" />
        </mat-form-field>
      </div>

      <app-custom-divider
        *ngIf="!(!catalogOptions || catalogOptions.length === 0)"
        class="w-100-p"
        color="var(--app-syncee-grey-300)"
      ></app-custom-divider>

      <div>
        <div class="typography-body-small-2 margin-description mb-4" fxLayoutAlign="start center" fxLayoutGap="4px">
          <div>
            {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.SET_PRICE_MARGIN' | translate }}
          </div>
          <mat-icon
            [matTooltip]="
              'IMPORT_LIST.IMPORT_LIST_ITEM_PAGE.TABS.PRODUCTS.SUPPLIER_FILTER.BULK_ACTIONS.NEW_CATALOG_DIALOG.MARGIN_TOOLTIP'
                | translate
            "
            class="margin-icon s-20"
            >info</mat-icon
          >
        </div>
        <mat-form-field appearance="fill" class="input-without-padding">
          <mat-label>{{
            'IMPORT_LIST.IMPORT_LIST_ITEM_PAGE.TABS.PRODUCTS.SUPPLIER_FILTER.BULK_ACTIONS.NEW_CATALOG_DIALOG.MARGIN'
              | translate
          }}</mat-label>
          <input matInput type="number" required [min]="1" [(ngModel)]="catalogMenuVO.margin" />
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-error class="margin-too-high" *ngIf="catalogMenuVO.margin > 1000">{{
          'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.MARGIN_HIGH' | translate
        }}</mat-error>
      </div>
      <div fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-checkbox class="update-price" [(ngModel)]="excludeUpdateFieldsCheckBox"></mat-checkbox>
        <div class="typography-body-small-2">
          {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.UPDATE_PRICES' | translate }}
        </div>
      </div>
      <div class="create-new-catalog-error pt-12" *ngIf="hasError">
        {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.NEW_CATALOG_ERROR' | translate }}
      </div>
    </div>
  </ng-container>

  <div id="button-container" fxLayoutAlign="center center" fxLayoutGap="8px">
    <button mat-button mat-dialog-close>
      {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.CLOSE' | translate }}
    </button>
    <button
      mat-button
      class="syncee-black-flat-button"
      (click)="hidePanel()"
      *ngIf="isNewCatalogPanelVisible && !data.createEmptyCatalog"
    >
      {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.BACK' | translate }}
    </button>
    <ng-container *ngIf="!isLoading; else buttonProgress">
      <button
        mat-button
        class="syncee-blue-button"
        [disabled]="!catalogMenuVO.catalog"
        (click)="addToCatalog()"
        *ngIf="!isNewCatalogPanelVisible"
      >
        {{ 'MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.SAVE' | translate }}
      </button>
      <button
        mat-button
        class="syncee-blue-button"
        (click)="createNewCatalog()"
        *ngIf="isNewCatalogPanelVisible"
        [disabled]="catalogMenuVO.margin > 1000"
      >
        {{
          'IMPORT_LIST.IMPORT_LIST_ITEM_PAGE.TABS.PRODUCTS.SUPPLIER_FILTER.BULK_ACTIONS.NEW_CATALOG_DIALOG.ADD_CATALOG'
            | translate
        }}
      </button>
    </ng-container>
  </div>

  <ng-template #buttonProgress>
    <app-loading-spinner></app-loading-spinner>
  </ng-template>
</div>
