import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternationalShippingComponent } from './international-shipping.component';
import { InternationalShippingItemComponent } from './international-shipping-item/international-shipping-item.component';
import { InternationalShippingDialogComponent } from './international-shipping-dialog/international-shipping-dialog.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    InternationalShippingComponent,
    InternationalShippingItemComponent,
    InternationalShippingDialogComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
  ],
  exports: [InternationalShippingComponent, InternationalShippingItemComponent, InternationalShippingDialogComponent],
})
export class InternationalShippingModule {}
