<ng-container [ngSwitch]="allCompleted | async">
  <ng-container *ngSwitchCase="true">
    <div fxLayoutAlign="center center" fxLayoutGap="8px" class="setup-guide toolbar" [routerLink]="'/referrals'">
      <mat-icon>flag</mat-icon>
      <div>{{ 'TOOLBAR.EARN_MORE' | translate }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <div fxLayoutAlign="center center" fxLayoutGap="8px" class="setup-guide toolbar" [routerLink]="'/setup-guide'">
      <mat-icon>flag</mat-icon>
      <div>{{ 'HEADER.SETUP_GUIDE' | translate }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <app-skeleton-image [borderRadius]="'8px'" width="24px" height="24px"></app-skeleton-image>
      <div [style.width]="'60px'">
        <app-skeleton-text [lines]="1"></app-skeleton-text>
      </div>
    </div>
  </ng-container>
</ng-container>
