import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateModule } from '@ngx-translate/core';
import { ChangeRoleModule } from 'app/main/change-role/change-role.module';
import { BannerCtaComponent } from 'app/shared/components/banner-cta/banner-cta.component';
import { BrandsSliderComponent } from 'app/shared/components/brands-slider/brands-slider.component';
import { CategoriesByPreferenceModule } from 'app/shared/components/categories-by-preference/categories-by-preference.module';
import { CategoryCardsComponent } from 'app/shared/components/category-cards/category-cards.component';
import { CategorySidebarSelectorComponent } from 'app/shared/components/category-sidebar-selector/category-sidebar-selector.component';
import { CtaRowComponent } from 'app/shared/components/cta-row/cta-row.component';
import { CustomDividerComponent } from 'app/shared/components/custom-divider/custom-divider.component';
import { FaqColumnsComponent } from 'app/shared/components/faq-columns/faq-columns.component';
import { FeatureCardsRowComponent } from 'app/shared/components/feature-cards-row/feature-cards-row.component';
import { IntegrationsCardGridComponent } from 'app/shared/components/integrations-card-grid/integrations-card-grid.component';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner.component';
import { NeedSubscriptionBadgeComponent } from 'app/shared/components/need-subscription-badge/need-subscription-badge.component';
import { ProductsListSliderBoxComponent } from 'app/shared/components/products-list-slider-box/products-list-slider-box.component';
import { ProductsRowWidgetComponent } from 'app/shared/components/products-row-widget/products-row-widget.component';
import { RecentlyViewedProductsComponent } from 'app/shared/components/recently-viewed-products/recently-viewed-products.component';
import { ReviewsSliderComponent } from 'app/shared/components/reviews-slider/reviews-slider.component';
import { SearchHistoryChipListComponent } from 'app/shared/components/search-history-chip-list/search-history-chip-list.component';
import { StepflowRowComponent } from 'app/shared/components/stepflow-row/stepflow-row.component';
import { SuppliersSliderComponent } from 'app/shared/components/suppliers-slider/suppliers-slider.component';
import { SynceeCtaComponent } from 'app/shared/components/syncee-cta/syncee-cta.component';
import { UniversalBadgeComponent } from 'app/shared/components/universal-badge/universal-badge.component';
import { HasPermissionDirective } from 'app/shared/directives/has-permission.directive';
import { OnlyGuestDirective } from 'app/shared/directives/only-guest.directive';
import { OnlyLoggedInDirective } from 'app/shared/directives/only-logged-in.directive';
import { BucketImageUrlPipe } from 'app/shared/pipes/bucket-image-url.pipe';
import { CurrencyTransformPipe } from 'app/shared/pipes/currency-transform-pipe.pipe';
import { CurrencyExchangePipe } from 'app/shared/pipes/legacy/currency-exchange.pipe';
import { RemoveQueryParamPipe } from 'app/shared/pipes/legacy/remove-query-param.pipe';
import { SanitizeJsonSafeHtmlPipe } from 'app/shared/pipes/sanitize-json-safehtml.pipe';
import { TruncatePipe } from 'app/shared/pipes/truncate.pipe';
import { WidgetTranslatePipe } from 'app/shared/pipes/widget-translate.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { PermissionDirective } from '../../../service/permission/permission.directive';
import { EcomSelectorComponent } from '../../../shared/components/ecom-selector/ecom-selector.component';
import { FilterSelectorRowComponent } from '../../../shared/components/filter-selector-row/filter-selector-row.component';
import { NoSearchResultsComponent } from '../../../shared/components/no-search-results/no-search-results.component';
import { ProductListComponent } from '../../../shared/components/product-list/product-list.component';
import { SearchProductTypeSelectorComponent } from '../../../shared/components/search-product-type-selector/search-product-type-selector.component';
import { SimpleBannerWithImageComponent } from '../../../shared/components/simple-banner-with-image/simple-banner-with-image.component';
import { SkeletonModule } from '../../../shared/components/skeleton/skeleton.module';
import { SliderItemComponent } from '../../../shared/components/slider-item/slider-item.component';
import { SliderComponent } from '../../../shared/components/slider/slider.component';
import { SupplierSimpleItemComponent } from '../../../shared/components/supplier-simple-item/supplier-simple-item.component';
import { SuppliersSliderByFilterComponent } from '../../../shared/components/suppliers-slider-by-filter/suppliers-slider-by-filter.component';
import { ButtonWithIconDirective } from '../../../shared/directives/button-with-icon.directive';
import { CategoryImageFromId } from '../../../shared/pipes/category-image-from-id';
import { CategoryNameByIdPipe } from '../../../shared/pipes/category-name-by-id.pipe';
import { CountryFlagPipe } from '../../../shared/pipes/country_flag.pipe';
import { GetAssetFromStoragePipe } from '../../../shared/pipes/get-asset-from-storage';
import { GetResourceFromStoragePipe } from '../../../shared/pipes/get-resource-from-storage';
import { ProductImageThumbnailCdnPipe } from '../../../shared/pipes/product-image-resizer-cdn.pipe';
import { FilterCommonModule } from '../../filter-common/filter-common.module';
import { FilterModule } from '../../filter/filter.module';
import { ProductCardModule } from '../../product-card/product-card.module';
import { LeftAndRightSectionWithGeneralButtonComponent } from '../../static/components/left-and-right-section-with-general-button/left-and-right-section-with-general-button.component';
import { HomepageGuestComponent } from '../../static/pages/homepage-guest/homepage-guest.component';
import { SupplierCardModule } from '../supplier-card/supplier-card.module';
import { SupplierListModule } from '../supplier-list/supplier-list.module';
import { AddToImportListMenuComponent } from './add-to-import-list-menu/add-to-import-list-menu.component';
import { CategorySidebarComponent } from './category-sidebar/category-sidebar.component';
import { ExploreProductsSearchComponent } from './explore-products-search/explore-products-search.component';
import { ExploreProductsComponent } from './explore-products.component';
import { GuestBrandsSliderComponent } from './guest-landing-page/guest-brands-slider/guest-brands-slider.component';
import { GuestExploreCategoryCardsComponent } from './guest-landing-page/guest-explore-category-cards/guest-explore-category-cards.component';
import { GuestFaqComponent } from './guest-landing-page/guest-faq/guest-faq.component';
import { GuestIntegrationsGridComponent } from './guest-landing-page/guest-integrations-grid/guest-integrations-grid.component';
import { GuestLandingBannerCtaComponent } from './guest-landing-page/guest-landing-banner-cta/guest-landing-banner-cta.component';
import { GuestReviewsSliderComponent } from './guest-landing-page/guest-reviews-slider/guest-reviews-slider.component';
import { GuestSellingPointCardsComponent } from './guest-landing-page/guest-selling-point-cards/guest-selling-point-cards.component';
import { GuestSpotlightCtaComponent } from './guest-landing-page/guest-spotlight-cta/guest-spotlight-cta.component';
import { GuestStepflowComponent } from './guest-landing-page/guest-stepflow/guest-stepflow.component';
import { GuestSynceeLandingCtaComponent } from './guest-landing-page/guest-syncee-landing-cta/guest-syncee-landing-cta.component';
import { HomeFeaturedFilterCardsComponent } from './home-featured-filter-cards/home-featured-filter-cards.component';
import { HomeRecentSearchesComponent } from './home-recent-searches/home-recent-searches.component';
import { HomeSynceeLandingCtaComponent } from './home-syncee-landing-cta/home-syncee-landing-cta.component';
import { MarketplaceViewSelectorComponent } from './marketplace-view-selector/marketplace-view-selector.component';
import { PreferredCategoriesSliderComponent } from './preferred-categories-slider/preferred-categories-slider.component';
import { ProductFilterWidgetRowsComponent } from './product-filter-widget-rows/product-filter-widget-rows.component';
import { RecentSearchProductsBoxComponent } from './recent-search-products-box/recent-search-products-box.component';
import { RecommendedProductsSliderComponent } from './recommended-products-slider/recommended-products-slider.component';
import { RecommendedSuppliersByLocationSliderComponent } from './recommended-suppliers-by-location-slider/recommended-suppliers-by-location-slider.component';
import { RecommendedSuppliersSliderComponent } from './recommended-suppliers-slider/recommended-suppliers-slider.component';
import { PreferenceDialogService } from '../../../service/preference/preference-dialog.service';

@NgModule({
  declarations: [
    ExploreProductsComponent,
    AddToImportListMenuComponent,
    ExploreProductsSearchComponent,
    MarketplaceViewSelectorComponent,
    HomeFeaturedFilterCardsComponent,
    HomeSynceeLandingCtaComponent,
    PreferredCategoriesSliderComponent,
    ProductFilterWidgetRowsComponent,
    GuestExploreCategoryCardsComponent,
    GuestSynceeLandingCtaComponent,
    GuestSpotlightCtaComponent,
    GuestSellingPointCardsComponent,
    GuestLandingBannerCtaComponent,
    GuestReviewsSliderComponent,
    GuestFaqComponent,
    GuestIntegrationsGridComponent,
    GuestStepflowComponent,
    GuestBrandsSliderComponent,
    CategorySidebarComponent,
    HomeRecentSearchesComponent,
    RecommendedProductsSliderComponent,
    RecommendedSuppliersSliderComponent,
    RecommendedSuppliersByLocationSliderComponent,
    RecentSearchProductsBoxComponent,
  ],
  imports: [
    CommonModule,
    // Material
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    MatStepperModule,
    MatSelectModule,
    MatDialogModule,
    MatChipsModule,
    MatIconModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    TranslateModule,

    // App
    FilterModule,
    // Fuse modules
    FuseSharedModule,
    RouterModule,
    OverlayModule,
    PortalModule,
    MatSidenavModule,
    GetResourceFromStoragePipe,
    CategoryImageFromId,
    FilterCommonModule,
    SupplierListModule,
    ProductCardModule,
    SlickCarouselModule,
    SliderComponent,
    SliderItemComponent,
    EcomSelectorComponent,
    SupplierSimpleItemComponent,
    NoSearchResultsComponent,
    FilterSelectorRowComponent,
    ProductListComponent,
    SupplierCardModule,
    CategoryNameByIdPipe,
    CountryFlagPipe,
    ButtonWithIconDirective,
    ChangeRoleModule,
    CategoryCardsComponent,
    CtaRowComponent,
    SynceeCtaComponent,
    ProductsRowWidgetComponent,
    WidgetTranslatePipe,
    OnlyLoggedInDirective,
    OnlyGuestDirective,
    FeatureCardsRowComponent,
    BannerCtaComponent,
    BucketImageUrlPipe,
    ReviewsSliderComponent,
    FaqColumnsComponent,
    IntegrationsCardGridComponent,
    StepflowRowComponent,
    BrandsSliderComponent,
    CategorySidebarSelectorComponent,
    CurrencyTransformPipe,
    RecentlyViewedProductsComponent,
    ProductsListSliderBoxComponent,
    SanitizeJsonSafeHtmlPipe,
    NgxJsonLdModule,
    SkeletonModule,
    SearchProductTypeSelectorComponent,
    PermissionDirective,
    SearchHistoryChipListComponent,
    SuppliersSliderComponent,
    CategoriesByPreferenceModule,
    SuppliersSliderByFilterComponent,
    ProductImageThumbnailCdnPipe,
    SimpleBannerWithImageComponent,
    GetAssetFromStoragePipe,
    HomepageGuestComponent,
    LeftAndRightSectionWithGeneralButtonComponent,
    TruncatePipe,
    RemoveQueryParamPipe,
    CurrencyExchangePipe,
    UniversalBadgeComponent,
    NeedSubscriptionBadgeComponent,
    HasPermissionDirective,
    PermissionDirective,
    MatCheckboxModule,
    LoadingSpinnerComponent,
    CustomDividerComponent,
  ],
  // providers: [PreferenceDialogService],
})
export class ExploreProductsModule {}
