import { SCOPES } from 'app/service/permission/scopes';

export class JwtUtils {
  public static getScopes(token: string): SCOPES[] {
    return JwtUtils.getParsedBody(token).scopes;
  }

  public static getExpiresTimestamp(token: string): number {
    return JwtUtils.getParsedBody(token).exp;
  }

  public static getParsedBody(token: string): JWTPayload {
    return JSON.parse(atob(token.split('.')[1]));
  }
}

interface JWTPayload {
  aud: string;
  exp: number;
  sub: string;
  scopes: SCOPES[];
}
