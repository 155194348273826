export interface ShippingRateConditionDto {
  id: number;
  shippingRateId: number;
  type: ShippingRateType;
  minValue: number;
  maxValue: number;
}

export enum ShippingRateType {
  PRICE = 'PRICE',
  WEIGHT = 'WEIGHT',
  NONE = 'NONE',
}
