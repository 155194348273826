import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-login',
  templateUrl: './supplier-login.component.html',
  styleUrls: ['./supplier-login.component.scss'],
})
export class SupplierLoginComponent implements OnInit {
  // TODO change supplier cover image if needed
  public readonly bannerCoverImage = 'assets/images/backgrounds/supplier-login.webp';
  public readonly bannerTextList: string[] = [
    'AUTHENTICATION.BANNER.LOGIN.SUPPLIER.FIRST',
    'AUTHENTICATION.BANNER.LOGIN.SUPPLIER.SECOND',
    'AUTHENTICATION.BANNER.LOGIN.SUPPLIER.THIRD',
  ];

  constructor() {}

  ngOnInit(): void {}
}
