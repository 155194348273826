<ng-container *ngIf="layoutService.contentHeight | async as height">
  <div
    class="ai-chat-outer-container"
    [style.height.px]="height"
    [style.padding-top.px]="
      ((layoutService.stickyHeaderVisible | async) ? (layoutService.toolbarHeight | async) : 0) + 12
    "
    [style.transition]="
      !(layoutService.stickyHeaderVisible | async)
        ? 'padding-top 400ms cubic-bezier(0.1, 0.7, 0.6, 1)'
        : 'padding-top 400ms linear'
    "
  >
    <div class="ai-chat-container" fxLayout="column">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
