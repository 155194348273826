<h1 mat-dialog-title>{{ 'CATEGORY_MAPPING.PRODUCT_EXAMPLE.FROM' | translate }}{{ categoryName }}</h1>
<mat-dialog-content>
  <ng-container *ngIf="products.length; else noProducts">
    <div *ngFor="let product of products" fxLayoutAlign="start center" class="product-details">
      <div fxFlex="20">
        <img
          [src]="product.IMAGES[0] | productImageThumbnailCdn"
          [productImageErrorHandler]="product.IMAGES[0]"
          [checkedOriginal]="true"
          [alt]="product.TITLE"
          *ngIf="product.IMAGES"
        />
        <img src="{{ getDefaultImage() }}" *ngIf="!product.IMAGES" />
      </div>
      <div fxFlex="80">
        <div>{{ product.TITLE }}</div>
        <div>{{ product.CATEGORY }}</div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button class="syncee-black-flat-button mr-8" [mat-dialog-close]="false">
    {{ 'CATEGORY_MAPPING.PRODUCT_EXAMPLE.CLOSE' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #noProducts>
  <div>{{ 'CATEGORY_MAPPING.PRODUCT_EXAMPLE.NO_PRODUCTS' | translate }}</div>
</ng-template>
