<app-custom-dialog-wrapper [hideCloseBtn]="data.disableClose">
  <div [style.padding-top.px]="data.disableClose ? 40 : 0">
    <app-subscription-upsell
      [selectedEcom]="selectedEcom"
      [descriptionTranslationKey]="descTransKey"
      (laterEvent)="closeDialog()"
      (redirectedEvent)="closeDialog()"
      [variant]="data.variant"
      [hasActionBar]="data.disableClose"
    ></app-subscription-upsell>
  </div>
</app-custom-dialog-wrapper>
