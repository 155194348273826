import { RatingItem } from './rating-item';

export const DEFAULT_RATING_ITEMS: RatingItem[] = [
  {
    value: 1,
    label: 'DEFAULT_RATING_ITEM_LABELS.ONE',
    icon: 'extremely_dissatisfied',
  },
  {
    value: 2,
    label: 'DEFAULT_RATING_ITEM_LABELS.TWO',
    icon: 'dissatisfied',
  },
  {
    value: 3,
    label: 'DEFAULT_RATING_ITEM_LABELS.THREE',
    icon: 'neutral',
  },
  {
    value: 4,
    label: 'DEFAULT_RATING_ITEM_LABELS.FOUR',
    icon: 'satisfied',
  },
  {
    value: 5,
    label: 'DEFAULT_RATING_ITEM_LABELS.FIVE',
    icon: 'very_satisfied',
  },
];
