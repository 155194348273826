<ng-container *ngIf="!idListHasError && !isError; else errorMessage">
  <div *ngIf="supplierCards && supplierCards.length; else skeleton" class="container">
    <ng-container *ngFor="let supplier of supplierCards">
      <div class="supplier-item">
        <app-supplier-card
          [supplier]="supplier"
          [supplierImages]="supplierImages[supplier.userId]"
          [categoryId]="selectedCategoryId"
        >
        </app-supplier-card>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template [ngIf]="(isIdListLoading || isLoading) && from === 0" #progress>
  <app-skeleton-supplier-card-list [numberOfSuppliers]="supplierIds.length"></app-skeleton-supplier-card-list>
</ng-template>

<ng-template #skeleton>
  <app-skeleton-supplier-card-list [numberOfSuppliers]="supplierIds.length"></app-skeleton-supplier-card-list>
</ng-template>

<ng-template #errorMessage>
  <app-error-message *ngIf="isError"></app-error-message>
</ng-template>
