import { FileUploadDownloadInputVo } from '../../taskwizard/addfiles/vo/file-upload-download-input-vo';

export class FileChangeVo extends FileUploadDownloadInputVo {
  private _uploadUrl = null;

  get uploadUrl(): string {
    return this._uploadUrl;
  }

  set uploadUrl(value: string) {
    this._uploadUrl = value;
  }
}
