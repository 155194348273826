<app-left-and-right-section
  [title]="title"
  [description]="description"
  [descriptionSmall]="descriptionSmall"
  [chipText]="chipText"
  [wrapperClass]="wrapperClass"
  [innerClass]="innerClass"
  [position]="position"
>
  <div actions>
    <div class="actions pt-24">
      <app-general-button [config]="button"></app-general-button>
    </div>
  </div>
  <div template><ng-content></ng-content></div>
</app-left-and-right-section>
