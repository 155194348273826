import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Constants } from '../../../../utils/Constants';
import { FileUploadDownloadInputVo } from '../../../taskwizard/addfiles/vo/file-upload-download-input-vo';
import { FileDownloadService } from '../../../../service/taskwizard/file-download.service';
import { SharedUrlUploadInputVo } from '../../../taskwizard/addfiles/vo/shared-url-upload-input-vo';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FtpDialogVO } from '../../../taskwizard/addfiles/vo/ftp-dialog-vo';
import { FtpclientComponent } from '../../../taskwizard/addfiles/ftp/ftpclient.component';
import { FtpUploadResponseVO } from '../../../taskwizard/addfiles/vo/ftp-upload-response-vo';
import { UrlUploadComponent } from '../../../taskwizard/addfiles/url-upload/url-upload.component';
import { FileUploadResponseVo } from '../../../taskwizard/addfiles/vo/file-upload-response-vo';
import { takeUntil } from 'rxjs/operators';
import { UrlSharedComponent } from '../../../taskwizard/addfiles/url-upload/url-shared.component';
import { ShopifyComponent } from '../../../taskwizard/addfiles/shopify/shopify.component';
import { PreviewDownloadedFileComponent } from '../../../taskwizard/addfiles/ftp/preview-downloaded-file.component';
import { Subject } from 'rxjs';
import { EcomInterface } from '../../../taskwizard/addfiles/shopify/ecom-interface';
import { ChooseSettingsModalComponent } from '../../../taskwizard/addfiles/modals/choose-settings-modal.component';
import { SettingsInterface } from '../../../taskwizard/addfiles/file-settings/settings-interface';
import { CsvSettingsInputVo } from '../../../taskwizard/addfiles/vo/csv-settings-input-vo';
import { ExcelSettingsInputVo } from '../../../taskwizard/addfiles/vo/excel-settings-input-vo';
import { ProductNodeTreeVO } from '../../../taskwizard/addfiles/vo/product-node-tree-vo';
import { ProductnodetreeComponent } from '../../../taskwizard/addfiles/file-settings/productnodetree.component';
import { CsvSettingsComponent } from '../../../taskwizard/addfiles/file-settings/csv-settings.component';
import { ExcelSettingsComponent } from '../../../taskwizard/addfiles/file-settings/excel-settings.component';
import { ChangeFileComponent } from '../change-file/change-file.component';
import { FileChangeVo } from '../file-change-vo';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-source-settings-tab',
  templateUrl: './source-settings-tab.component.html',
  styleUrls: ['./source-settings-tab.component.scss'],
})
export class SourceSettingsTabComponent implements OnInit {
  @Output() sourceChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  public static UPLOAD_URL = 'TaskFileUploadService/changeFile';

  @ViewChild('source_container', { read: ViewContainerRef, static: true })
  sourceContainer: ViewContainerRef;

  @ViewChild('settings_container', { read: ViewContainerRef, static: true })
  settingsContainer: ViewContainerRef;

  settingsComponent: SettingsInterface = null;
  _unsubscribeAll: Subject<void> = new Subject();

  hideFileUploadContainer = true;
  hideFileSettingsContainer = true;
  disableFileSettingsSaveBtn = true;

  fileUpload_container = 60;
  ecomComponent: EcomInterface = null;

  saveFile = false;

  hideActionPanel = true;
  conType: string;
  currentTaskID: number;
  currentFileID: number;
  taskFile: any;
  @Input() data: any;

  isSettingsHidden(): boolean {
    return (
      this.hideFileSettingsContainer ||
      [
        Constants.SHOPIFY_CONNECTION_TYPE,
        Constants.SHOPRENTER_CONNECTION_TYPE,
        Constants.JUMPSELLER_CONNECTION_TYPE,
      ].includes(this.conType)
    );
  }

  constructor(private dialog: MatDialog, private cfr: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.conType = this.data.conType;
    const inputFUDVo = new FileUploadDownloadInputVo();
    // inputFUDVo.taskId = this.currentTaskID;
    // this.isExpanded = false;
    this.taskFile = this.data.taskFile;
    this.currentTaskID = this.data.taskFile.taskId;
    const settings = this.taskFile.settings;

    this.currentFileID = this.taskFile.fileId;

    const inputUDVo = this.mapUploadDownloadInputVo(
      this.currentTaskID,
      this.currentFileID,
      this.taskFile.extension,
      this.taskFile.remotePath
    );
    inputUDVo.isUpdate = true;
    switch (this.conType) {
      case Constants.FTP_CONNECTION_TYPE:
        this.initUploadedFileView(inputUDVo);
        break;
      case Constants.FILE_UPLOAD_CONNECTION_TYPE:
        this.initFileUploadComponent(inputUDVo);
        break;
      case Constants.URL_CONNECTION_TYPE:
        this.initURLUploadComponent(inputUDVo);
        break;
      case Constants.SHOPIFY_CONNECTION_TYPE:
        this.initShopDownloadComponent({ type: 'shopify', ecomId: settings['ecomId'] });
        break;
      case Constants.JUMPSELLER_CONNECTION_TYPE:
        this.initShopDownloadComponent({ type: 'jumpseller', ecomId: settings['ecomId'] });
        break;
      case Constants.SHOPRENTER_CONNECTION_TYPE:
        this.initShopDownloadComponent({ type: 'shoprenter', ecomId: settings['ecomId'] });
        break;
      case Constants.DOCS_DRIVE_CONNECTION_TYPE:
      case Constants.GOOGLE_DRIVE_CONNECTION_TYPE:
        this.initURLSharedUploadComponent(
          this.mapSharedDownloadInputVo(
            FileDownloadService.GOOGLE_DRIVE_UPLOAD_URL,
            Constants.URL_EXAMPLES[this.conType],
            this.conType,
            this.currentTaskID,
            this.currentFileID,
            this.taskFile.extension,
            this.taskFile.remotepath
          )
        );
        break;
      case Constants.DROPBOX_CONNECTION_TYPE:
        this.initURLSharedUploadComponent(
          this.mapSharedDownloadInputVo(
            FileDownloadService.DROPBOX_UPLOAD_URL,
            Constants.URL_EXAMPLES[this.conType],
            this.conType,
            this.currentTaskID,
            this.currentFileID,
            this.taskFile.extension,
            this.taskFile.remotepath
          )
        );
        break;
    }
    switch (this.taskFile.extension) {
      case Constants.EXTENSION_JSON:
      case Constants.EXTENSION_XML:
        const settingsInput = this.mapProdNodeTreeSettingsVO(
          this.taskFile.taskId,
          this.taskFile.fileId,
          this.taskFile.extension,
          null,
          !Utils.isNullOrUndefined(settings) ? settings : null,
          !Utils.isNullOrUndefined(settings) ? settings.container : null,
          settings.encoding
        );
        this.initJSONXMLSettings(settingsInput);
        break;
      case Constants.EXTENSION_CSV:
        let csvSettingsInput;
        if (!Utils.isNullOrUndefined(settings)) {
          csvSettingsInput = this.mapCsvSettings(
            this.taskFile.extension,
            settings.firstRowIsHeader,
            settings.startRowNum,
            settings.delimiter,
            settings.enclosure,
            settings.eol,
            settings.encoding
          );
        } else {
          csvSettingsInput = this.mapCsvSettings(this.taskFile.extension);
        }
        this.initCSVSettings(csvSettingsInput);
        break;
      case Constants.EXTENSION_XLS:
      case Constants.EXTENSION_XLSX:
        let excelSettingsInput;
        if (!Utils.isNullOrUndefined(settings)) {
          excelSettingsInput = this.mapExcelSettings(
            this.taskFile.taskId,
            this.taskFile.fileId,
            this.taskFile.extension,
            settings.firstRowIsHeader,
            settings.startRowNum,
            settings.sheet,
            settings.encoding
          );
        } else {
          excelSettingsInput = this.mapExcelSettings(
            this.taskFile.taskId,
            this.taskFile.fileId,
            this.taskFile.extension
          );
        }
        this.initExcelSettings(excelSettingsInput);
        break;
    }
  }

  mapSharedDownloadInputVo(
    restApiUrl: string,
    urlPlaceholder: string,
    urlSourceType: string,
    taskId: number,
    fileId: number = null,
    type: string = null,
    path: string = null
  ): SharedUrlUploadInputVo {
    const inputVo = new SharedUrlUploadInputVo();
    inputVo.placeholder = urlPlaceholder;
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.selectedType = type;
    inputVo.remotePath = path;
    inputVo.restUrl = restApiUrl;
    inputVo.urlSourceType = urlSourceType;
    return inputVo;
  }

  initFTPUploadComponent(isUpdate = false): void {
    console.log('initFTPUploadComponent');
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.width = '80%';
    dialogConfig.data = new FtpDialogVO(this.currentTaskID, this.currentFileID, isUpdate);

    const dialogRef = this.dialog.open(FtpclientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: FtpUploadResponseVO) => {
      if (!result.status && result.deleteTab) {
        return;
      } else if (result.status) {
        this.ftpComponentCallback(result);
      }
    });
  }

  initURLUploadComponent(inputVo: FileChangeVo): void {
    console.log('initURLUploadComponent');
    const instance = this.initSharedUploadFileComponent(UrlUploadComponent);
    instance.callBack.subscribe((resp: FileUploadResponseVo) => this.urlUploadComponentCallback(resp));
    instance.downloadFileActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp: boolean) => (this.saveFile = resp));
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initURLSharedUploadComponent(inputVo: SharedUrlUploadInputVo): void {
    const instance = this.initSharedUploadFileComponent(UrlSharedComponent);
    instance.callBack.subscribe((resp: FileUploadResponseVo) => this.urlUploadComponentCallback(resp));
    instance.downloadFileActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp: boolean) => (this.saveFile = resp));
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initFileUploadComponent(inputVo: FileChangeVo): void {
    inputVo.uploadUrl = SourceSettingsTabComponent.UPLOAD_URL;
    const instance = this.initSharedUploadFileComponent(ChangeFileComponent);
    instance.callBack.subscribe((resp: FileUploadResponseVo) => this.fileUploadComponentCallback(resp));
    instance.downloadFileActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp: boolean) => (this.saveFile = resp));
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initShopDownloadComponent(inputVo): void {
    inputVo['taskId'] = this.currentTaskID;
    const instance = this.initSharedUploadFileComponent(ShopifyComponent);
    instance.inputs = inputVo;
    this.ecomComponent = instance;
    this.fileUpload_container = 100;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
    this.disableFileSettingsSaveBtn = false;
  }

  initUploadedFileView(inputVo: FileUploadDownloadInputVo): void {
    console.log('initUploadedFileView');
    const instance = this.initSharedUploadFileComponent(PreviewDownloadedFileComponent);
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.initFTPUpload.subscribe((resp: boolean) => this.initFTPUploadComponent(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initSharedUploadFileComponent(component: Type<any>): any {
    this.sourceContainer.clear();
    const factory = this.cfr.resolveComponentFactory(component);
    return this.sourceContainer.createComponent(factory).instance;
  }

  urlUploadComponentCallback(fileUploadResponseVO: FileUploadResponseVo): void {
    this.currentFileID = fileUploadResponseVO.fileId;
    this.fileTypeChanged(fileUploadResponseVO.detectedType);
  }

  ftpComponentCallback(fileUploadResponseVO: FileUploadResponseVo): void {
    this.currentFileID = fileUploadResponseVO.fileId;
    const inputVo = this.mapUploadDownloadInputVo(
      this.currentTaskID,
      this.currentFileID,
      fileUploadResponseVO.detectedType,
      fileUploadResponseVO.remotePath
    );
    this.initUploadedFileView(inputVo);
    this.fileTypeChanged(fileUploadResponseVO.detectedType);
    this.hideActionPanel = false;
  }

  fileUploadComponentCallback(fileUploadResponseVO: FileUploadResponseVo): void {
    this.sourceChanged.emit(true);
    this.currentFileID = fileUploadResponseVO.fileId;
    // this.fileTypeChanged(fileUploadResponseVO.detectedType);
  }

  initJSONXMLSettings(prodNodeTreeVO: ProductNodeTreeVO): void {
    const factory = this.cfr.resolveComponentFactory(ProductnodetreeComponent);
    this.settingsContainer.clear();
    const instance = this.settingsContainer.createComponent(factory).instance;
    instance.readonly = true;
    this.settingsComponent = instance;
    instance.inputs = prodNodeTreeVO;
    this.hideFileSettingsContainer = false;
    this.disableFileSettingsSaveBtn = false;
  }

  initCSVSettings(csvSettingsInputVo: CsvSettingsInputVo): void {
    this.settingsContainer.clear();
    const factory = this.cfr.resolveComponentFactory(CsvSettingsComponent);
    const instance = this.settingsContainer.createComponent(factory).instance;
    this.settingsComponent = instance;
    instance.inputs = csvSettingsInputVo;
    this.hideFileSettingsContainer = false;
    this.disableFileSettingsSaveBtn = false;
  }

  fileTypeChanged(fileType): void {
    if (Utils.isNullOrUndefined(this.currentFileID)) {
      return;
    }
    if (!Utils.isNullOrUndefined(this.settingsComponent)) {
      if (this.settingsComponent.getSupportedFileTypes().indexOf(fileType) > -1) {
        this.settingsComponent.refresh(fileType);
        return;
      }
      console.log('fileTypeChanged');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = '400px';
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(ChooseSettingsModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== true) {
          console.log('waaaat');
          this.changeSettings(fileType);
        } else {
          this.settingsComponent.refresh(fileType);
        }
      });
    } else {
      this.changeSettings(fileType);
    }
  }

  changeSettings(fileType): void {
    switch (fileType) {
      case Constants.EXTENSION_CSV:
        const csvInputVo = this.mapCsvSettings(fileType);
        this.initCSVSettings(csvInputVo);
        break;
      case Constants.EXTENSION_JSON:
      case Constants.EXTENSION_XML:
        const prodNodeTreeVO = this.mapProdNodeTreeSettingsVO(this.currentTaskID, this.currentFileID, fileType);
        this.initJSONXMLSettings(prodNodeTreeVO);
        break;
      case Constants.EXTENSION_XLSX:
      case Constants.EXTENSION_XLS:
        const excelInputVo = this.mapExcelSettings(this.currentTaskID, this.currentFileID, fileType);
        this.initExcelSettings(excelInputVo);
        break;
    }
  }

  mapCsvSettings(
    fileType: string,
    firstRowHeader: boolean = null,
    startRowNum: number = null,
    delimiter: string = null,
    enclosure: string = null,
    endofline: string = null,
    encoding = 'UTF-8'
  ): CsvSettingsInputVo {
    const inputVo = new CsvSettingsInputVo();
    inputVo.fileType = fileType;
    inputVo.firstRowIsHeader = firstRowHeader;
    inputVo.startRowNum = startRowNum;
    inputVo.delimiter = delimiter;
    inputVo.enclosure = enclosure;
    inputVo.endofline = endofline;
    inputVo.encoding = encoding;
    return inputVo;
  }

  mapExcelSettings(
    taskId: number,
    fileId: number = null,
    fileType: string,
    firstRowHeader: boolean = null,
    startRowNum: number = null,
    sheet: string = null,
    encoding = 'UTF-8'
  ): ExcelSettingsInputVo {
    const inputVo = new ExcelSettingsInputVo();
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.fileType = fileType;
    inputVo.firstRowIsHeader = firstRowHeader;
    inputVo.startRowNum = startRowNum;
    inputVo.sheet = sheet;
    inputVo.encoding = encoding;
    return inputVo;
  }

  mapUploadDownloadInputVo(
    taskId: number,
    fileId: number = null,
    type: string = null,
    path: string = null
  ): FileChangeVo {
    const inputVo = new FileChangeVo();
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.selectedType = type;
    inputVo.remotePath = path;
    return inputVo;
  }

  mapProdNodeTreeSettingsVO(
    taskId: number,
    fileId: number,
    fileType: string,
    nodeTree: any[] = null,
    settings: any[] = null,
    selectedNode: string[] = null,
    encoding = null
  ): ProductNodeTreeVO {
    const inputVo = new ProductNodeTreeVO();
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.fileType = fileType;
    inputVo.nodeTree = nodeTree;
    inputVo.settings = settings;
    inputVo.selectedNode = selectedNode;
    inputVo.encoding = encoding;
    return inputVo;
  }

  initExcelSettings(excelSettingsInputVo: ExcelSettingsInputVo): void {
    this.settingsContainer.clear();
    const factory = this.cfr.resolveComponentFactory(ExcelSettingsComponent);
    const instance = this.settingsContainer.createComponent(factory).instance;
    this.settingsComponent = instance;
    instance.inputs = excelSettingsInputVo;
    this.hideFileSettingsContainer = false;
    this.disableFileSettingsSaveBtn = false;
  }
}
