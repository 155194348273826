import { Component, Input } from '@angular/core';
import { Params, Router, RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { AlertBaseComponent, AlertBaseType } from '../alert-base/alert-base.component';

@Component({
  selector: 'app-alert-with-button',
  templateUrl: './alert-with-button.component.html',
  styleUrls: ['./alert-with-button.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RouterLink,
    TranslateModule,
    FlexModule,
    MatButtonModule,
    AlertBaseComponent,
  ],
  standalone: true,
})
export class AlertWithButtonComponent {
  @Input() message: string;
  @Input() type: AlertBaseType;
  @Input() readMoreLink?: string;
  @Input() alertButton?: AlertButton;

  constructor(private router: Router) {}

  public handleClick(): void {
    this.router.navigate([this.alertButton.link], { queryParams: this.alertButton.queryParams });
  }
}

export interface AlertButton {
  textKey: string;
  link: string;
  queryParams?: Params;
}
