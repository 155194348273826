import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { AuthorizationEcomService } from 'app/service/authorization-ecom/authorization-ecom.service';

@Directive({
  selector: '[guestOpenRegistration]',
  standalone: true,
})
export class GuestOpenRegistrationDirective implements OnDestroy, AfterViewInit {
  @Input() titleTranslationKey = 'STOREFRONT.GUEST.GENERAL_REGISTRATION_REDIRECT.TITLE';
  @Input() descriptionTranslationKey = 'STOREFRONT.GUEST.GENERAL_REGISTRATION_REDIRECT.DESCRIPTION';
  private unsubscribeAll: Subject<void>;
  private subscription: Subscription;

  constructor(
    private element: ElementRef<HTMLButtonElement>,
    private authManagerService: AuthenticationManagerService,
    private authorizationService: AuthorizationEcomService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngAfterViewInit(): void {
    this.createSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private openRegistration(): void {
    this.authManagerService.emitOpenRegistrationDialog(
      AuthDialogType.RETAILER,
      undefined,
      this.titleTranslationKey,
      this.descriptionTranslationKey
    );
  }

  private createSubscription(): void {
    if (!this.subscription) {
      this.subscription = combineLatest([
        fromEvent(this.element.nativeElement, 'click', { capture: true }),
        this.authorizationService.isAuthenticatedUserOrNot(),
      ])
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(([event, isAuth]) => {
          if (!isAuth) {
            event.stopPropagation();
            this.openRegistration();
          }
        });
    }
  }
}
