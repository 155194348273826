import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { EcomTypeEnum } from '../../../vo/enums/ecom-type-enum';
import { getPlatformByType } from '../../../utils/platforms/platform-config';

@Component({
  selector: 'app-platform-logo',
  templateUrl: './platform-logo.component.html',
  styleUrls: ['./platform-logo.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class PlatformLogoComponent implements OnInit {
  @Input()
  logoType: PlatformLogoType;
  @Input()
  ecomType: EcomTypeEnum;
  @Input()
  height: string;
  @Input()
  width: string;
  path: string;
  alt: string;

  constructor() {}

  ngOnInit(): void {
    this.findImage();
  }

  private findImage(): void {
    this.path = getPlatformByType(this.ecomType).images.logo;
    this.alt = this.ecomType;
  }
}

export type PlatformLogoType = 'FULL' | 'ICON';
