import { IMPORT_LIST_PRICE_OPERATOR_TYPE } from 'app/shared/components/import-list-price-settings/types/operator.type';

export const RETAIL_PERCENTAGE_OPERATORS: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [-1];
export const RETAIL_FIX_OPERATORS: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1, -1];

export const WHOLESALE_PERCENTAGE_OPERATORS: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1];
export const WHOLESALE_FIX_OPERATORS: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = [1];

export const RETAIL_DEFAULT_PERCENTAGE_OPERATOR: IMPORT_LIST_PRICE_OPERATOR_TYPE = -1;
export const RETAIL_DEFAULT_FIX_OPERATOR: IMPORT_LIST_PRICE_OPERATOR_TYPE = -1;

export const WHOLESALE_DEFAULT_PERCENTAGE_OPERATOR: IMPORT_LIST_PRICE_OPERATOR_TYPE = 1;
export const WHOLESALE_DEFAULT_FIX_OPERATOR: IMPORT_LIST_PRICE_OPERATOR_TYPE = 1;
