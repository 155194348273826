import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductSearchState } from './product-search.reducer';
import { MarketplaceFilterSort } from '../../vo/search-product-vo';

const getProductSearchState = createFeatureSelector<ProductSearchState>('productSearch');

export const filterSelector = createSelector(getProductSearchState, (state) => !!state && state.filter);

export const paginatorSelector = createSelector(getProductSearchState, (state) => !!state && state.paginator);

export const searchTypeSelector = createSelector(getProductSearchState, (state) => state?.searchType);

export const filterPaginatorSelector = createSelector(
  getProductSearchState,
  (state) =>
    !!state && ({ sortField: state.filter.sortField, sortOrder: state.filter.sortOrder } as MarketplaceFilterSort)
);
