<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="start center"
  fxLayoutAlign.lt-md="start stretch"
  fxLayoutGap="15px"
  class="bulk-edit-container w-100p"
>
  <button mat-button class="syncee-white-button" (click)="headerService.toggleBulkEdit()">
    {{
      !(headerService.isBulkEditOn | async)
        ? ('CATEGORY_MAPPING_COMPONENT.BULK_EDIT' | translate)
        : ('CATEGORY_MAPPING_COMPONENT.TURN_OFF_BULK_EDIT' | translate)
    }}
  </button>
  <ng-container *ngIf="headerService.isBulkEditOn | async">
    <h5>{{ 'CATEGORY_MAPPING_COMPONENT.SELECTED_CATEGORIES' | translate }} {{ listService.checkedItems.length }}</h5>
    <button mat-button class="syncee-white-button" (click)="handleSelectButtonClick()">
      {{
        listService.checkedItems.length < 1
          ? ('CATEGORY_MAPPING_COMPONENT.SELECT_ALL' | translate)
          : ('CATEGORY_MAPPING_COMPONENT.CLEAR_SELECTION' | translate)
      }}
    </button>
    <div>
      <app-actions-button *ngIf="!!listService.checkedItems.length">
        <button mat-menu-item (click)="headerService.addInBulk()">
          <div>{{ 'CATEGORY_MAPPING_COMPONENT.ADD_IN_BULK' | translate }}</div>
        </button>
        <button mat-menu-item (click)="headerService.removeInBulk()">
          <div>{{ 'CATEGORY_MAPPING_COMPONENT.REMOVE_IN_BULK' | translate }}</div>
        </button>
        <button mat-menu-item (click)="headerService.clearSelectedMapping()">
          <div>{{ 'CATEGORY_MAPPING_COMPONENT.CLEAR_SELECTED_MAPPING' | translate }}</div>
        </button>
      </app-actions-button>
    </div>
  </ng-container>
</div>
