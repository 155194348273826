import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogMultiWrapperComponent } from '../../dialog-multi-wrapper/dialog-multi-wrapper.component';
import { CustomDatePipe } from '../../../pipes/custom-date.pipe';

@Component({
  selector: 'app-subscription-cancelled-dialog',
  templateUrl: './subscription-cancelled-dialog.component.html',
  styleUrls: ['./subscription-cancelled-dialog.component.scss'],
  standalone: true,
  imports: [TranslateModule, DialogMultiWrapperComponent, CustomDatePipe],
})
export class SubscriptionCancelledDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { subscribedUntil: string },
    public dialogRef: MatDialogRef<SubscriptionCancelledDialogComponent>
  ) {}
  subUntil;

  ngOnInit(): void {
    this.subUntil = this.data.subscribedUntil;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
