import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Widget, WidgetFilterData, WidgetType } from '../../vo/widget';
import { Utils } from '../../utils/utils';
import { MarketplaceFilter } from '../../vo/search-product-vo';
import { FilterService } from '../../layout/components/filter/filter.service';
import { FeaturedCategoryTileConfig } from '../../vo/marketplace/featured-categories-tile';

@Injectable({
  providedIn: 'root',
})
export class SearchWidgetService {
  constructor(private restService: RestService, private filterService: FilterService) {}

  public getWidgets(widgetType: WidgetType, isActive: boolean = null): Observable<Widget[]> {
    const rest: GetWidgetParams = { widgetType };
    if (!Utils.isNullOrUndefined(isActive)) {
      rest.isActive = isActive;
    }
    return this.restService.get('SearchWidgetService', rest).pipe(map((res) => res.getData()));
  }

  public putWidgets(widgets: Widget[]): Observable<void> {
    return this.restService.put('SearchWidgetService', widgets).pipe(map((res) => {}));
  }

  public postWidgetImage(id: number, image: File): Observable<void> {
    const raw = { id, image };
    const formData = Utils.objectToFormData(raw, new FormData());
    return this.restService.post('SearchWidgetService/image', formData).pipe(map((res) => {}));
  }

  public createWidget(widget: Widget, image): Observable<void> {
    const raw = { ...widget, image };
    const formData = new FormData();
    formData.append('image', image);
    formData.append('isActive', widget.isActive ? 'true' : 'false');
    formData.append('widgetType', widget.widgetType);
    formData.append('orderNumber', widget.orderNumber.toString());
    widget.filterData.forEach((data, index) => {
      formData.append(`filterData[${index}][fieldName]`, data.fieldName);
      formData.append(`filterData[${index}][fieldValue]`, data.fieldValue);
    });
    widget.titles.forEach((title, index) => {
      formData.append(`titles[${index}][title]`, title.title);
      formData.append(`titles[${index}][languageCode]`, title.languageCode);
    });
    return this.restService.post('SearchWidgetService', formData).pipe(map((res) => {}));
  }

  public mapWidgetFilterDataToMarketplaceFilter(filterData: WidgetFilterData[]): MarketplaceFilter {
    const filter: MarketplaceFilter = {};
    filterData.forEach((data) => {
      filter[data.fieldName as string] = data.fieldValue;
    });
    return this.filterService.normalizeFilterFromStrings(filter);
  }

  public getWidgetTitle(widget: Widget, languageCode: string): string {
    const item = widget.titles.find((title) => title.languageCode === languageCode);
    return item === undefined ? widget.titles.find((title) => title.languageCode === 'en').title : item.title;
  }

  public getWidgetButton(widget: Widget, languageCode: string): string {
    const item = widget.buttons.find((title) => title.languageCode === languageCode);
    return item === undefined ? widget.titles.find((title) => title.languageCode === 'en').title : item.buttonText;
  }
}

interface GetWidgetParams {
  widgetType: string;
  isActive?: boolean;
}

export const FeaturedCategoriesTilesConfig: FeaturedCategoryTileConfig[] = [
  { widgetType: WidgetType.CATEGORY_TILE_LEFT, orientation: 'left', size: 'large' },
  { widgetType: WidgetType.CATEGORY_TILE_RIGHT_BOTTOM, orientation: 'right', size: 'small' },
  { widgetType: WidgetType.CATEGORY_TILE_RIGHT_TOP, orientation: 'right', size: 'small' },
];
