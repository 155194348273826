import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ExportTaskwizardService } from '../../../service/taskwizard/export-taskwizard.service';
import { ExportTaskwizardUpdateService } from '../../../service/taskwizard/export-taskwizard-update.service';
import { ConnectionStateService } from '../../../service/taskwizard/connection-state.service';
import { DestinationComponent } from '../destination/destination.component';
import { ScheduleComponent } from '../../taskwizard/schedule/schedule.component';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-setting-and-summary',
  templateUrl: './setting-and-summary.component.html',
  styleUrls: ['./setting-and-summary.component.scss'],
})
export class SettingAndSummaryComponent implements OnInit {
  @ViewChild('destComponent', { static: false }) destComponent: DestinationComponent;
  @ViewChild('scheduleComp', { static: false }) scheduleComponent: ScheduleComponent;
  @Input() taskId: number;

  taskName = '';
  storeName = '';
  sourceType = '';
  fileType = '';
  exportUrl = '';

  csvSettings: any = null;

  productNumber = '';
  filteredNumber = '';
  scheduleType = '';
  // scheduleTimes: string[] = [''];

  selectedNotification = 'dont';

  destination: string;
  enableComponent = false;
  isLoading = false;

  constructor(
    private exportTaskwizardService: ExportTaskwizardService,
    private etwUpdateService: ExportTaskwizardUpdateService,
    private connStateService: ConnectionStateService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.destination = 'URL';
    if (this.etwUpdateService.isUpdate && !Utils.isNullOrUndefined(this.etwUpdateService.data.task.destination)) {
      this.destination = this.etwUpdateService.data.task.destination;
    }
    this.etwUpdateService.getRestDataFinished().subscribe((resp) => {
      if (resp) {
        this.getDetails();
      }
    });
  }

  public isStepValid(): boolean {
    return true;
  }

  getDetails(): void {
    this.exportTaskwizardService.getSummaryDetails(this.taskId).subscribe(
      (res) => {
        this.connStateService.currentState = res.ftpConnection;
        this.productNumber = res.productCount.toString();
        this.filteredNumber =
          res.filteredNumber !== -1
            ? res.filteredNumber.toString()
            : this.translate.instant('RETAILER_EXPORT_TASK_WIZARD.SUMMARY.NO_DATA_YET');
        this.storeName = res.storeName;
        this.fileType = res.fileType;
        this.csvSettings = res.csvSettings;
        this.exportUrl = res.exportUrl;
        this.isLoading = false;
        this.enableComponent = true;
      },
      () => {
        this.isLoading = false;
        this.enableComponent = true;
      }
    );
  }

  handleDestinationChange(destination: string): void {
    this.destination = destination;
  }

  saveStep(): Observable<any> {
    return forkJoin(this.destComponent.saveStep(), this.scheduleComponent.saveStep());
  }
}
