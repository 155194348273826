import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { QuestionAnswerService } from '../../../../service/question-answer/question-answer.service';
import { AnswerDto } from '../../../../vo/question-answer/answer-dto';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { CachedUserService } from '../../../../service/cached-user/cached-user.service';
import { ReportUserDto } from '../../../../vo/question-answer/report-user-dto';
import { forkJoin, Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationService } from '../../../../main/notification/notification.service';
import { CustomDialogWrapperComponent } from '../custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-question-answer-dialog',
  templateUrl: './question-answer-dialog.component.html',
  styleUrls: ['./question-answer-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    FlexModule,
    MatIconModule,
    NgForOf,
    MatCheckboxModule,
    TranslateModule,
    NgIf,
    NgClass,
    MatProgressSpinnerModule,
    CustomDialogWrapperComponent,
  ],
})
export class QuestionAnswerDialogComponent implements OnInit {
  answers: AnswerDto[];
  selectedAnswers: SelectionModel<AnswerDto> = new SelectionModel<AnswerDto>(true, []);
  customAnswer = '';
  isCustomSelected = false;
  isError = false;
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionAnswerData,
    private questionAnswerService: QuestionAnswerService,
    private cachedUserService: CachedUserService,
    private dialogRef: MatDialogRef<QuestionAnswerDialogComponent>,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.questionAnswerService
      .getAnswersForQuestion(this.data.questionCode)
      .subscribe((answers) => (this.answers = answers));
  }

  selectAnswer(event: MatCheckboxChange, answer: AnswerDto): void {
    if (event.checked) {
      this.selectedAnswers.select(answer);
    } else {
      this.selectedAnswers.deselect(answer);
    }

    this.isCustomSelected = this.selectedAnswers.selected.some((selectedAnswer) => selectedAnswer.custom);
    if (!this.isCustomSelected) {
      this.customAnswer = '';
      this.isError = false;
    }
  }

  sendReport(): void {
    if (!this.customAnswerIsSelectedWithoutText()) {
      this.sendRequests();
    } else {
      this.isError = true;
    }
  }

  private sendRequests(): void {
    this.isLoading = true;
    forkJoin(this.collectRequests()).subscribe(
      () => {
        this.isLoading = false;
        this.notificationService.success('Report successfully sent.');
        this.dialogRef.close();
      },
      () => {
        this.isLoading = false;
        this.notificationService.error('Sorry, there was an error sending your report.');
      }
    );
  }

  private collectRequests(): Observable<void>[] {
    return this.selectedAnswers.selected.map((answer) =>
      this.questionAnswerService.postAnswerForQuestion(this.createReportUserDto(answer))
    );
  }

  handleInputChange(event: any): void {
    this.customAnswer = event.target.value;

    if (!!event.target.value && this.isError) {
      this.isError = false;
    }
  }

  private customAnswerIsSelectedWithoutText(): boolean {
    return this.selectedAnswers.selected.some((answer) => answer.custom) && !this.customAnswer;
  }

  private createReportUserDto(answer: AnswerDto): ReportUserDto {
    return {
      reportedUserId: this.data.reportedUserId,
      reportingUserId: this.cachedUserService.getCachedUser().id,
      answerCode: answer.answer,
      customAnswer: answer.custom ? this.customAnswer : '',
    };
  }
}

export interface QuestionAnswerData {
  questionCode: string;
  reportedUserId: number;
}
