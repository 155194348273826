import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-main-menu-inner-sidebar',
  templateUrl: './mobile-main-menu-inner-sidebar.component.html',
  styleUrls: ['./mobile-main-menu-inner-sidebar.component.scss'],
})
export class MobileMainMenuInnerSidebarComponent implements OnInit, OnDestroy {
  @Input() key: string;
  @Input() previouslyOpenedSidebars: string[] = [];
  @Input() close: Subject<boolean>;
  @Input() customBack: string;
  @Output() customBackClicked = new EventEmitter<void>();
  @Output() openedChanged = new EventEmitter<boolean>();
  private unsubscribeAll: Subject<void>;

  constructor(private fuseSidebarService: FuseSidebarService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.fuseSidebarService
      .getSidebar(this.key)
      .openedChanged.pipe(takeUntil(this.unsubscribeAll))
      .subscribe((open) => this.openedChanged.emit(open));
    this.close.pipe(takeUntil(this.unsubscribeAll)).subscribe((closeAll) => this.closeSidebar(closeAll));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  backClicked(): void {
    if (!!this.customBack) {
      this.customBackClicked.emit();
    } else {
      this.closeSidebar(false);
    }
  }

  closeClicked(): void {
    this.closeSidebar(true);
  }

  private closeSidebar(closeAll: boolean): void {
    this.fuseSidebarService.getSidebar(this.key).close();
    if (closeAll) {
      this.previouslyOpenedSidebars.forEach((key) => this.fuseSidebarService.getSidebar(key).close());
    }
  }
}

export interface MobileMainMenuInnerInputs {
  key: string;
  previouslyOpenedSidebars: string[];
}
