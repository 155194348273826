<div class="authentication-form-content">
  <img class="authentication-syncee-logo-mobile" src="assets/images/logos/syncee-logo.svg" />
  <div class="text-center" fxLayout="column" fxLayoutGap="4px">
    <h2 class="mt-0">{{ 'AUTHENTICATION.RESET_PASSWORD.TITLE' | translate }}</h2>
    <div class="typography-body-1">
      {{ 'AUTHENTICATION.RESET_PASSWORD.SUBTITLE' | translate }}
    </div>
  </div>
  <form [formGroup]="form" class="w-100p" fxLayout="column" fxLayoutGap="24px">
    <mat-form-field class="input-without-padding-except-error" [appearance]="'fill'">
      <mat-label>{{ 'AUTHENTICATION.RESET_PASSWORD.NEW' | translate }}</mat-label>
      <input
        id="password"
        name="password"
        type="password"
        matInput
        placeholder="{{ 'AUTHENTICATION.PASSWORD' | translate }}"
        formControlName="password"
        required
      />
      <mat-error *ngIf="password.errors && !password.errors?.required">
        {{ 'AUTHENTICATION.PW_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="password.errors?.required">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="input-without-padding-except-error" [appearance]="'fill'">
      <mat-label>{{ 'AUTHENTICATION.CONFIRM_PASSWORD' | translate }}</mat-label>
      <input
        id="passwordConfirm"
        name="passwordConfirm"
        type="password"
        matInput
        placeholder="{{ 'AUTHENTICATION.CONFIRM_PASSWORD' | translate }}"
        formControlName="passwordConfirm"
        required
      />
      <mat-error *ngIf="passwordConfirm.errors?.required">{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
      <mat-error *ngIf="passwordConfirm.errors?.passwordsNotMatching">{{
        'AUTHENTICATION.PASSWORD_MUST_MATCH' | translate
      }}</mat-error>
    </mat-form-field>
    <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="submitHandle()">
      {{ 'AUTHENTICATION.RESET_PASSWORD.BUTTON_TEXT' | translate }}
    </button>
  </form>
</div>
