import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { getSelectedLanguageCode } from 'app/store/language/language.selector';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'currencyTransform',
  standalone: true,
})
export class CurrencyTransformPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(value: number, currency: string, maximumFractionDigits?: number): Observable<string> {
    return this.store.select(getSelectedLanguageCode).pipe(
      filter((data) => !!data),
      switchMap((lang) =>
        of(
          new Intl.NumberFormat(languageToLocale[lang], {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: maximumFractionDigits,
          }).format(value)
        )
      )
    );
  }
}

export const languageToLocale = {
  en: 'en-US',
  hu: 'hu-HU',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-fR',
  it: 'it-IT',
  pt: 'pt-PT',
  zh: 'zh-CN',
};
