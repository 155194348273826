<div fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="column" fxLayoutGap="40px">
    <mat-radio-group
      [(ngModel)]="roundingRule"
      (ngModelChange)="handleRoundingRuleChange($event)"
      aria-label="Select an option"
    >
      <mat-radio-button *ngFor="let option of priceRules" [value]="option.rule" [disabled]="locked">
        {{ option.translateKey ? (option.translateKey | translate) : option.label }}
      </mat-radio-button>
    </mat-radio-group>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="0"
      fxLayoutGap.lt-md="10px"
      fxLayoutAlign="space-between center"
    >
      <app-import-list-price-rounding-example
        [triggerCalc]="triggerCalc"
        [currency]="ecomCurrency"
        [defaultRoundingRule]="roundingRule"
      ></app-import-list-price-rounding-example>
      <ng-container *ngIf="hasSaveButton">
        <button fxFlexAlign.lt-md="end" mat-button class="syncee-blue-button" (click)="submit()">
          {{ 'IMPORT_LIST_PRICE_SETTINGS.SAVE' | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
