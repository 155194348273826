<ng-container *ngIf="actions.length === 1">
  <button
    mat-button
    class="syncee-white-button action-button"
    (click)="actions[0].action()"
    [style.min-height]="height + '!important'"
    [style.line-height]="height + '!important'"
    [class.w-100p]="fullWidth"
  >
    {{ buttonText | translate }}
  </button>
</ng-container>
<ng-container *ngIf="actions.length > 1">
  <button
    mat-button
    class="syncee-white-button action-button action-button-with-icon"
    [buttonWithIcon]="'keyboard_arrow_down'"
    [iconPosition]="'end'"
    [matMenuTriggerFor]="menu"
    [style.min-height]="height + '!important'"
    [style.line-height]="height + '!important'"
    [class.w-100p]="fullWidth"
  >
    {{ buttonText | translate }}
  </button>
  <mat-menu #menu="matMenu">
    <button *ngFor="let action of actions" mat-menu-item (click)="action.action()">
      <mat-icon *ngIf="!!action.icon">{{ action.icon }}</mat-icon>
      <span>{{ action.title | translate }}</span>
    </button>
  </mat-menu>
</ng-container>
