<div class="custom-modal-container">
  <ng-container *ngIf="!data.hiddenFilters?.includes('shipsFrom')">
    <app-ships-from-filter
      [value]="filterSelectorDialogLocalState?.shipsFrom"
      (valueChange)="onValueChange({ shipsFrom: $event })"
      (shipsFromChange)="setGtmFilterValue('shipsFrom', $event)"
    ></app-ships-from-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('shipsTo')">
    <app-ships-to-filter
      [value]="filterSelectorDialogLocalState?.shipsTo"
      (valueChange)="onValueChange({ shipsTo: $event })"
      (shipsToChange)="setGtmFilterValue('shipsTo', $event)"
    ></app-ships-to-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('supplier')">
    <app-supplier-filter
      [value]="filterSelectorDialogLocalState?.supplier"
      (valueChange)="onValueChange({ supplier: $event })"
      (supplierChange)="setGtmFilterValue('supplier', $event)"
      [multiple]="true"
    ></app-supplier-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container
    *ngIf="!data.hiddenFilters?.includes('minShippingTime') && !data.hiddenFilters?.includes('maxShippingTime')"
  >
    <app-shipping-time-filter
      [value]="{
        minShippingTime: filterSelectorDialogLocalState.minShippingTime,
        maxShippingTime: filterSelectorDialogLocalState.maxShippingTime
      }"
      (valueChange)="
        onValueChange({ minShippingTime: $event.minShippingTime, maxShippingTime: $event.maxShippingTime })
      "
    >
    </app-shipping-time-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('minPrice') && !data.hiddenFilters?.includes('maxPrice')">
    <app-price-filter
      [value]="{
        minPrice: filterSelectorDialogLocalState?.minPrice,
        maxPrice: filterSelectorDialogLocalState?.maxPrice
      }"
      (valueChange)="
        onValueChange({ minPrice: $event.minPrice, maxPrice: $event.maxPrice });
        setGtmFilterValue('minPrice', $event.minPrice);
        setGtmFilterValue('maxPrice', $event.maxPrice)
      "
    ></app-price-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('lang')">
    <app-product-language-filter
      [value]="filterSelectorDialogLocalState?.lang"
      (valueChange)="onValueChange({ lang: $any($event) })"
      (languageChange)="setGtmFilterValue('lang', $event)"
    ></app-product-language-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('premium')">
    <app-premium-supplier-filter
      [value]="filterSelectorDialogLocalState?.premium"
      (valueChange)="onValueChange({ premium: $event }); setGtmFilterValue('premium', $event)"
    ></app-premium-supplier-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('shippingType')">
    <app-shipping-type-filter
      [value]="filterSelectorDialogLocalState?.shippingType"
      (valueChange)="onValueChange({ shippingType: $event }); setGtmFilterValue('shippingType', $event)"
    ></app-shipping-type-filter>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="!data.hiddenFilters?.includes('approveType')">
    <app-approval-filter
      [value]="filterSelectorDialogLocalState?.approveType"
      (valueChange)="onValueChange({ approveType: $event }); setGtmFilterValue('approveType', $event)"
    ></app-approval-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('stock')">
    <app-stock-status-filter
      [value]="filterSelectorDialogLocalState?.stock"
      (valueChange)="onValueChange({ stock: $event }); setGtmFilterValue('stock', $event)"
    ></app-stock-status-filter>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="!data.hiddenFilters?.includes('sku')">
    <app-sku-filter
      [value]="filterSelectorDialogLocalState?.sku"
      (valueChange)="onValueChange({ sku: $event }); setGtmFilterValue('sku', $event)"
    ></app-sku-filter>
  </ng-container>
</div>
