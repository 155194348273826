import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { User, UserSettings } from './user';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserIdSelector } from '../../store/user/user.selector';
import { NotificationService } from '../../main/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserGatewayService {
  constructor(
    private springRestService: SpringRestService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  getSelectedUser(): Observable<User> {
    return this.store.select(getCurrentUserIdSelector).pipe(switchMap((userId) => this.getUserById(userId)));
  }

  getUser(): Observable<User> {
    return this.springRestService.get(MicroserviceNames.USER, '/User/Me', undefined, true);
  }

  getUserById(userId: number): Observable<User> {
    return this.springRestService
      .get(MicroserviceNames.USER, '/User', { ids: userId }, true)
      .pipe(map((users) => users[0]));
  }

  getUsers(userIds: number[]): Observable<User[]> {
    return this.springRestService.get(MicroserviceNames.USER, '/User', { ids: userIds.join(',') }, true);
  }

  patchUser(userId: number, data: Partial<User>, options: PatchUserOptions = null): Observable<User> {
    return this.springRestService.patch(MicroserviceNames.USER, `/User/${userId}`, data, {}, true).pipe(
      tap(() => {
        if (!!options && options.showNotification && !!options.notificationMessageKey) {
          this.notificationService.success(this.translateService.instant(options.notificationMessageKey));
        }
      })
    );
  }

  patchUserSettings(settingsId: number, value: PatchUserSettings): Observable<UserSettings> {
    return this.springRestService.patch(MicroserviceNames.USER, `/v1/User/Settings/${settingsId}`, value, {}, true);
  }
}

export interface PatchUserOptions {
  showNotification?: boolean;
  notificationMessageKey?: string;
}

export type PatchUserSettings = Partial<Omit<UserSettings, 'id' | 'userId' | 'createdAt' | 'updatedAt'>>;
