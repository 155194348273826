import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-task-wizard-step-locked-warning',
  standalone: true,
  imports: [CommonModule, AlertMultiComponent, TranslateModule],
  templateUrl: './task-wizard-step-locked-warning.component.html',
  styleUrls: ['./task-wizard-step-locked-warning.component.scss'],
})
export class TaskWizardStepLockedWarningComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
