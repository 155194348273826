import { Component, Input, OnInit } from '@angular/core';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { MOBILE_MENU_CATERGORY_ID, MOBILE_MENU_SIDEBAR_ID } from '../mobile-main-menu-sidebar-ids';
import {
  TOOLBAR_UPPER_ROW_CONFIG_LEFT,
  TOOLBAR_UPPER_ROW_CONFIG_RIGHT,
} from '../../toolbar/components/upper-row/upper-row.config';
import { FuseNavigation } from '../../../../../@fuse/types';
import { UpperRowItemWithChildren } from '../../toolbar/components/upper-row/models/upper-row-item-with-children';
import { mobile_menu_upper_navigation } from '../../../../navigation/navigation';
import { UpperRowService } from '../../toolbar/components/upper-row/upper-row.service';
import { profile_menu_navigation } from '../../../../navigation/inner-navigation';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from '../../../../service/user/user';
import { getCurrentUserSelector } from '../../../../store/user/user.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { ROLE_TYPE } from '../../../../vo/roles/roles';

@Component({
  selector: 'app-mobile-main-menu-sidebar',
  templateUrl: './mobile-main-menu-sidebar.component.html',
  styleUrls: ['./mobile-main-menu-sidebar.component.scss'],
})
export class MobileMainMenuSidebarComponent implements OnInit {
  @Input() needRedirect: boolean;
  navigationFromUpperRow: FuseNavigation[];
  role: ROLE_TYPE;
  sidebarId: string;
  upperNavigation = mobile_menu_upper_navigation;
  readonly navigation = profile_menu_navigation;
  readonly sidebarMainKey = MOBILE_MENU_SIDEBAR_ID;
  readonly sidebarCategoryKey = MOBILE_MENU_CATERGORY_ID;

  constructor(
    private fuseSidebarService: FuseSidebarService,
    private upperRowService: UpperRowService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.navigationFromUpperRow = this.mapUpperRowItemToFuseNavigation([
      ...TOOLBAR_UPPER_ROW_CONFIG_RIGHT,
      ...TOOLBAR_UPPER_ROW_CONFIG_LEFT,
    ]);
    this.getRole().subscribe((role) => {
      this.role = role;
    });
  }

  private getRole(): Observable<ROLE_TYPE> {
    return this.getUser().pipe(map((user) => user.role as ROLE_TYPE));
  }

  private getUser(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(filter((user) => !!user));
  }

  private mapUpperRowItemToFuseNavigation(menu: UpperRowItemWithChildren[]): FuseNavigation[] {
    return [
      {
        id: 'mobileMainMenu',
        children: menu.map((item) => this.upperRowService.mapUpperRowItemToFuseNavigationItem(item)),
        type: 'group',
        title: '',
        hideTitle: true,
      },
    ];
  }

  closeSidebar(): void {
    this.fuseSidebarService.getSidebar(this.sidebarMainKey).close();
  }

  handleItemClicked(): void {
    this.closeSidebar();
  }

  handleCategoriesClicked(): void {
    this.openSidebar(this.sidebarCategoryKey);
  }

  private openSidebar(key: string): void {
    this.sidebarId = key;
    this.fuseSidebarService.getSidebar(this.sidebarId).open();
  }
}
