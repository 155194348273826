import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'app-input-placeholder-slider',
  standalone: true,
  imports: [CommonModule, SlickCarouselModule],
  templateUrl: './input-placeholder-slider.component.html',
  styleUrls: ['./input-placeholder-slider.component.scss'],
})
export class InputPlaceholderSliderComponent implements OnInit {
  @Input() options: string[];
  @Input() backgroundColor: string;
  @Input() color: string;
  config: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    vertical: true,
    autoplaySpeed: 2000,
    speed: 500,
    cssEase: 'cubic-bezier(0.85, 0, 0.15, 1)',
  };

  constructor() {}

  ngOnInit(): void {}
}
