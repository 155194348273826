import { EcomVO } from '../../../service/ecom/ecom.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingService } from '../../../service/billing/billing.service';
import { RestService } from '../../../service/rest/rest.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Utils } from '../../../utils/utils';
import { SubscriptionCancelledDialogComponent } from '../../../shared/components/dialogs/subscription-cancelled-dialog/subscription-cancelled-dialog.component';
import { getPlatformByType } from '../../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../../vo/enums/ecom-type-enum';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionHelperService {
  constructor(
    private billingService: BillingService,
    private marketplaceEcomService: MarketplaceEcomService,
    private restService: RestService,
    private dialog: MatDialog
  ) {}

  public isFreeTrialAvailable(ecom: EcomVO): boolean {
    return this.marketplaceEcomService.isFreeTrialAvailable(ecom);
  }

  public showCancelledDialog(subscribedUntil): void {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.data = { subscribedUntil: subscribedUntil };

    this.dialog.open(SubscriptionCancelledDialogComponent, config);
  }

  public checkForBillingAddress(): Observable<boolean> {
    return this.restService.get(this.billingService.getBillingAddressRest).pipe(
      map((res) => {
        const data = !Utils.isNullOrUndefined(res.getData()) ? res.getFirstData() : null;
        return (
          !Utils.isNullOrUndefinedOrLengthZero(data) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.firstName) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.lastName) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.address1) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.city) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.zip)
        );
      })
    );
  }

  public isOnlyMarketplace(ecomType: string): boolean {
    return !getPlatformByType(ecomType as EcomTypeEnum).options.hasDataFeed;
  }
}
